import { CheckoutReportByProductSortType, ClientId, ProductCategoryId } from '@mero/api-sdk';
import { AnalyticsStatsNew, ServiceStats } from '@mero/api-sdk/dist/analytics/analytics2';
import { CheckoutReportByClientSortType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutReportByClientSortType';
import { CheckoutReportByServiceSortType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutReportByServiceSortType';
import { CheckoutSaleOwnerSalesReportTotalsByType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutSaleOwnerSalesReportTotalsByType';
import { ServiceGroupId } from '@mero/api-sdk/dist/services';
import { UserId } from '@mero/api-sdk/dist/users';
import { colors, H1, Row, Spacer, styles as meroStyles, Switch, Column } from '@mero/components';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import Clients from './components/Clients';
import ClientsDowloadSelector from './components/ClientsDowloadSelector';
import Payments from './components/Payments';
import PaymentsDownloadSelector from './components/PaymentsDownloadSelector';
import PaymentsProFilter from './components/PaymentsProFilter';
import Products from './components/Products';
import ProductsDownloadSelector from './components/ProductsDownloadSelector';
import ProductsFilter from './components/ProductsFilter';
import Pros from './components/Pros';
import ProsDownloadSelector from './components/ProsDownloadSelector';
import Services from './components/Services';
import ServicesDownloadSelector from './components/ServicesDownloadSelector';
import ServicesFilter from './components/ServicesFilter';
import Summary from './components/Summary';
import SummaryFilters from './components/SummaryFilter';
import HSpacer from '@mero/components/lib/components/HSpacer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, MenuTabStackParamList, ReportsTabStackParamList } from '../../../../../types';
import ActiveIntervalView, { getIntervals } from './ActiveIntervalView';
import { Interval } from './PageReportsScreen';

const BackIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="icons/back-light" {...props}>
    <Path fill="none" d="M0 0h32v32H0z" />
    <Path d="m13.734 24.921-7.638-8.107a.992.992 0 0 1-.1-.1 1.054 1.054 0 0 1-.282-.677l-.018-.02a1.033 1.033 0 0 1 .042-.284 1.038 1.038 0 0 1 .258-.482.993.993 0 0 1 .1-.09l7.638-8.11a.927.927 0 0 1 1.362 0 1.071 1.071 0 0 1 0 1.451l-6.1 6.478h16.45a.859.859 0 0 1 .859.859v.334a.859.859 0 0 1-.859.859H9.033l6.063 6.438a1.071 1.071 0 0 1 0 1.451.927.927 0 0 1-1.366 0Z" />
  </Svg>
);

type PropsMenu = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MenuTabStackParamList & AuthorizedStackParamList, 'PageReportsScreen'>;

type PropsBottom = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<ReportsTabStackParamList & AuthorizedStackParamList, 'ReportsScreen'>;

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    readonly hideBack: boolean;
    readonly navigateClient: (clientId: ClientId) => void;
    readonly interval?: string;
  };

const ReportsTabsOptions = [
  {
    label: 'summary',
    value: 'summary',
  },
  {
    label: 'clients',
    value: 'clients',
  },
  {
    label: 'services',
    value: 'services',
  },
  {
    label: 'products',
    value: 'products',
  },
  {
    label: 'pros',
    value: 'pros',
  },
  {
    label: 'payment',
    value: 'payment',
  },
] as const;

export const ANALYTICS_PLACEHOLDER = {
  bookings: {
    totalCount: 0,
    totalIncome: 0,
    acceptedStats: {
      count: 0,
      income: 0,
      percent: 0,
    },
    cancelledStats: {
      count: 0,
      income: 0,
      percent: 0,
    },
    noShowStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    pendingStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    finishedStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    futureStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
  },
  clients: {
    totalCount: 0,
    totalIncome: 0,
    averageBookingValue: 0,
    newClientStats: {
      count: 0,
      income: 0,
    },
    existingClientStats: {
      count: 0,
      income: 0,
    },
    newFromProClientStats: {
      count: 0,
      income: 0,
    },
  },
  services: [] as ServiceStats[],
} as const;

const ALL = 'all' as UserId;

type ReportsTabsOptionsType = (typeof ReportsTabsOptions)[number]['value'];

export type ClientFilters = 'all' | 'services' | 'products' | 'memberships';
export type ProsFilters = 'all' | 'services' | 'products' | 'memberships';

export const CheckoutReports: React.FC<Props> = ({ page, authorization, hideBack, interval, navigateClient }) => {
  const { t } = useTranslation('reports');
  const isFocused = useIsFocused();

  const [selectedTab, setSelectedTab] = React.useState<ReportsTabsOptionsType>(ReportsTabsOptions[0].value);
  const goBack = useGoBack();

  const [activeWorker, setActiveWorker] = React.useState<UserId | undefined>();
  const [analytics, setAnalytics] = React.useState<AnalyticsStatsNew>(ANALYTICS_PLACEHOLDER);

  // Clients
  const [clientsFilter, setClientsFilter] = React.useState<ClientFilters>('all');
  const [clientsSort, setClientsSort] = React.useState<
    | {
        readonly by: CheckoutReportByClientSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >();

  // Services
  const [servicesOwnerFilter, setServicesOwnerFilter] = React.useState<UserId>();
  const [servicesGroupFilter, setServicesGroupFilter] = React.useState<ServiceGroupId>();
  const [servicesSort, setServicesSort] = React.useState<
    | {
        readonly by: CheckoutReportByServiceSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >();

  // Pros
  const [prosFilter, setProsFilter] = React.useState<ProsFilters>('all');
  const [prosDetails, setProsDetails] = React.useState<
    CheckoutSaleOwnerSalesReportTotalsByType<MeroUnits.Any> | undefined
  >();
  const [prosSort, setProsSort] = React.useState<
    | {
        readonly by: CheckoutReportByClientSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >();

  // Products
  const [productsCategoryFilter, setProductsCategoryFilter] = React.useState<ProductCategoryId | 'other'>();
  const [productsOwnerFilter, setProductsOwnerFilter] = React.useState<UserId>();
  const [productsSort, setProductsSort] = React.useState<
    | {
        readonly by: CheckoutReportByProductSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >();

  const [paymentOwnerFilter, setPaymentOwnerFilter] = React.useState<UserId>();

  const defaultInterval = React.useMemo(() => {
    const intervals = getIntervals({
      withFirstDay: true,
      withFullCurrentMonth: true,
    });
    if (!interval) {
      return intervals[0];
    }

    const date = DateTime.fromFormat(interval, 'MM/yyyy', { locale: 'ro' });
    return (
      intervals.find((i) => i.value.start.equals(date.startOf('month'))) ?? {
        label: `${date.startOf('month').toFormat('dd MMM')} - ${date.endOf('month').toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start: date.startOf('month'),
          end: date.endOf('month'),
        },
      }
    );
  }, [interval]);

  const [activeInterval, setActiveInterval] = React.useState<Interval>(defaultInterval);
  const [showSelector, setShowSelector] = React.useState(false);

  React.useEffect(() => {
    const getAnalytics = async () => {
      const from = activeInterval.value.start;
      const to = activeInterval.value.end;

      const workerId = page.permissions.statistics.canReadAllStatistics()
        ? page.workers.find((w) => w.user._id === activeWorker)?._id
        : page.workers.find((w) => w.user._id === authorization.user._id)?._id;

      const showAll = page.permissions.statistics.canReadAllStatistics() && page.workers.length === 1;
      const [analyticsAll, prosDetails] = await Promise.all([
        meroApi.analytics.getAnalyticsNew({
          pageId: page.details._id,
          from: from.toJSDate(),
          to: to.toJSDate(),
          workerId,
        }),
        meroApi.checkoutReports.getSalesByOwnerTotalsReport({
          pageId: page.details._id,
          unit: 'RON',
          interval: {
            from: from.toJSDate(),
            to: to.toJSDate(),
          },
          saleOwnerId: activeWorker == ALL || showAll ? undefined : activeWorker,
        }),
      ]);

      setProsDetails(prosDetails);
      setAnalytics(analyticsAll);
    };

    if (isFocused) {
      getAnalytics();
    }
  }, [isFocused, activeWorker, activeInterval, page.details._id]);

  const getFilters = (tab: ReportsTabsOptionsType) => {
    const dateInterval = {
      from: activeInterval.value.start.toJSDate(),
      to: activeInterval.value.end.toJSDate(),
    };

    switch (tab) {
      case 'summary':
        return (
          <SummaryFilters
            onChange={setActiveWorker}
            page={page}
            authorization={authorization}
            activeFilter={activeWorker}
          />
        );
      case 'clients':
        return (
          <ClientsDowloadSelector
            pageId={page.details._id}
            unit="RON"
            itemType={clientsFilter}
            interval={dateInterval}
            sort={clientsSort}
          />
        );
      // return <ClientsFilter activeFilter={clientsFilter} onChange={setClientsFilter} />;
      case 'services':
        return (
          <Row>
            <ServicesDownloadSelector
              pageId={page.details._id}
              unit="RON"
              interval={dateInterval}
              saleOwnerId={servicesOwnerFilter}
              groupId={servicesGroupFilter}
              sort={servicesSort}
            />
            <HSpacer left={16} />
            <ServicesFilter
              activeFilterOwner={servicesOwnerFilter}
              onChangeOwner={setServicesOwnerFilter}
              activeFilterGroup={servicesGroupFilter}
              onChangeGroup={setServicesGroupFilter}
              pageId={page.details._id}
            />
          </Row>
        );
      case 'products':
        return (
          <Row>
            <ProductsDownloadSelector
              pageId={page.details._id}
              unit="RON"
              interval={dateInterval}
              saleOwnerId={productsOwnerFilter}
              categoryId={productsCategoryFilter}
              sort={productsSort}
            />
            <HSpacer left={16} />
            <ProductsFilter
              activeFilterOwner={productsOwnerFilter}
              onChangeOwner={setProductsOwnerFilter}
              activeFilterCategory={productsCategoryFilter}
              onChangeCategory={setProductsCategoryFilter}
              pageId={page.details._id}
            />
          </Row>
        );
      case 'pros':
        return (
          <ProsDownloadSelector
            pageId={page.details._id}
            unit="RON"
            interval={dateInterval}
            itemType={prosFilter}
            sort={prosSort}
          />
        );
      case 'payment':
        return (
          <Row>
            <PaymentsDownloadSelector
              pageId={page.details._id}
              unit="RON"
              interval={dateInterval}
              saleOwnerId={paymentOwnerFilter}
            />
            <HSpacer left={16} />
            <PaymentsProFilter
              activeFilterOwner={paymentOwnerFilter}
              onChangeOwner={setPaymentOwnerFilter}
              pageId={page.details._id}
            />
          </Row>
        );
      // return <ProsFilter activeFilter={prosFilter} onChange={setProsFilter} />;
      default:
        return null;
    }
  };

  const getContent = (tab: ReportsTabsOptionsType) => {
    const dateInterval = {
      from: activeInterval.value.start.toJSDate(),
      to: activeInterval.value.end.toJSDate(),
    };

    switch (tab) {
      case 'summary':
        return prosDetails ? (
          <Summary
            pageId={page.details._id}
            isFutureDate={DateTime.now() < activeInterval.value.end}
            analytics={analytics}
            prosDetails={prosDetails}
          />
        ) : null;
      case 'clients':
        return (
          <Clients
            navigateClient={navigateClient}
            pageId={page.details._id}
            itemType={clientsFilter}
            dateInterval={dateInterval}
            updateSort={setClientsSort}
          />
        );
      case 'services':
        return (
          <Services
            pageId={page.details._id}
            dateInterval={dateInterval}
            groupId={servicesGroupFilter}
            saleOwnerId={servicesOwnerFilter}
            updateSort={setServicesSort}
          />
        );
      case 'products':
        return (
          <Products
            pageId={page.details._id}
            dateInterval={dateInterval}
            categoryId={productsCategoryFilter}
            saleOwnerId={productsOwnerFilter}
            updateSort={setProductsSort}
          />
        );
      case 'pros':
        return (
          <Pros
            activeInterval={activeInterval}
            pageId={page.details._id}
            dateInterval={dateInterval}
            itemType={prosFilter}
            updateSort={setProsSort}
          />
        );
      case 'payment':
        return <Payments pageId={page.details._id} dateInterval={dateInterval} saleOwnerId={paymentOwnerFilter} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Row
        style={{
          height: 91,
          backgroundColor: colors.WHITE,
          shadowColor: colors.BLACK,
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.16,
          shadowRadius: 16,
          elevation: 16,
          zIndex: 1,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Row>
          {hideBack ? (
            <HSpacer left={8} />
          ) : (
            <TouchableOpacity onPress={goBack} style={{ marginLeft: 24 }}>
              <BackIcon />
            </TouchableOpacity>
          )}
          <H1 style={{ paddingHorizontal: 16 }}>{t('title')}</H1>
        </Row>
        <Row style={{ paddingRight: 24 }}>
          <ActiveIntervalView
            withFullCurrentMonth
            activeInterval={activeInterval}
            setActiveInterval={setActiveInterval}
            showFutureDates={true}
          />
        </Row>
      </Row>
      <ScrollView style={{ paddingHorizontal: 24 }}>
        {page.permissions.statistics.canReadAllStatistics() && (
          <>
            <Spacer size="24" />
            <Row alignItems="center" justifyContent="space-between">
              <Column style={{ flex: 1, maxWidth: 740 }}>
                <Switch
                  height={32}
                  textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                  buttons={ReportsTabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                  defaultValue={ReportsTabsOptions[0].value}
                  onChange={(v) => {
                    setSelectedTab(v as ReportsTabsOptionsType);
                  }}
                />
              </Column>
              {getFilters(selectedTab)}
            </Row>
          </>
        )}
        <Spacer size="24" />
        {getContent(selectedTab)}
      </ScrollView>
    </>
  );
};

const CheckoutReportsBottom: React.FC<PropsBottom> = (props) => {
  return (
    <CheckoutReports
      {...props}
      hideBack
      navigateClient={(clientId: ClientId) =>
        props.navigation.navigate('ClientDetails', {
          screen: 'DetailsScreen',
          params: { pageId: props.page.details._id, clientId: clientId },
        })
      }
    />
  );
};

export const CheckoutReportsScreenBottom = pipe(CheckoutReportsBottom, CurrentBusiness, Authorized);

const CheckoutReportsMenu: React.FC<PropsMenu> = (props) => {
  return (
    <CheckoutReports
      {...props}
      hideBack={false}
      interval={props.route.params?.interval}
      navigateClient={(clientId: ClientId) =>
        props.navigation.navigate('ClientDetails', {
          screen: 'DetailsScreen',
          params: { pageId: props.page.details._id, clientId: clientId },
        })
      }
    />
  );
};

export const CheckoutReportsScreen = pipe(CheckoutReportsMenu, CurrentBusiness, Authorized);

import * as React from 'react';
import { KeyboardAvoidingView as RNKeyboardAvoidingView, KeyboardAvoidingViewProps, Platform } from 'react-native';

export const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({ children, behavior, ...props }) => {
  return (
    <RNKeyboardAvoidingView behavior={behavior ?? Platform.OS === 'android' ? 'height' : 'padding'} {...props}>
      {children}
    </RNKeyboardAvoidingView>
  );
};

export default KeyboardAvoidingView;

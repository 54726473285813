import { StripePaymentIntentPreview } from '@mero/api-sdk/dist/payments';
import { SavedCardPreview } from '@mero/api-sdk/dist/payments/savedCardPreview';
import {
  Button,
  Checkbox,
  colors,
  Column,
  D2,
  DismissKeyboard,
  FormCard,
  H1,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import { BillingCompanyId } from '@mero/shared-sdk/dist/payments/discount/billing-company-id';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, ScrollView, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { images } from '../../../../../constants/images';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { SubscriptionInfoProps, withSubscriptionInfo } from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import SplashScreen from '../../../../SplashScreen';
import { withStripeElements } from './withStripeElements';

export type Props = StackScreenProps<SubscriptionStackParamList, 'CardPayment'> &
  CurrentBusinessProps &
  SubscriptionInfoProps & {
    meroCompany: BillingCompanyId;
  };

const NEW_CARD = {
  id: 'newCard',
} as const;

const StripeCardPaymentScreen: React.FC<Props> = ({ navigation, subscriptionInfo }) => {
  const { t } = useTranslation('subscription');
  const stripe = useStripe();
  const stripeElements = useElements();

  const [newCardDetailsComplete, setNewCardDetailsComplete] = React.useState(false);
  const [status, setStatus] = React.useState<StripePaymentIntentPreview | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [savedCards, setSavedCards] = React.useState<SavedCardPreview[]>([]);
  const [selectedCard, setSelectedCard] = React.useState<SavedCardPreview | typeof NEW_CARD>();

  const toast = useToast();
  const goBack = useGoBack();

  const getNewCardStatus = async (stripe: Stripe, stripeElements: StripeElements) => {
    const cardElement = stripeElements.getElement(CardElement);
    if (!cardElement) {
      log.error('Failed to get card element');
      toast.show({
        type: 'error',
        text: t('stripePaymentFailed', { error: '03' }),
      });
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      log.error('Failed to create payment method', error);
      toast.show({
        type: 'error',
        text: t('stripePaymentFailed', { error: '04' }),
      });
      return;
    }

    return paymentMethod?.id;
  };

  const onPay = async () => {
    if (!stripe || !stripeElements || !status) {
      log.error('Failed to get stripe or stripe elements');
      toast.show({
        type: 'error',
        text: t('stripePaymentFailed', { error: '01' }),
      });
      return;
    }
    try {
      setIsLoading(true);
      if (status.type !== 'actionRequired') {
        toast.show({
          type: 'error',
          text: t('stripePaymentFailed', { error: '02' }),
        });
        return;
      }

      const id = selectedCard?.id === NEW_CARD.id ? await getNewCardStatus(stripe, stripeElements) : selectedCard?.id;

      if (!id || !status.intent.clientSecret) {
        log.error('Failed to get payment method id or client secret');
        toast.show({
          type: 'error',
          text: t('stripePaymentFailed', { error: '05' }),
        });
        return;
      }

      const { error: confirmError } = await stripe.confirmCardPayment(status.intent.clientSecret, {
        payment_method: id,
      });

      if (confirmError) {
        log.error('Failed to confirm payment', confirmError);
        toast.show({
          type: 'error',
          text:
            confirmError.type === 'card_error'
              ? t('stripeCardErrorFailed', { error: '06' })
              : t('stripePaymentFailed', { error: '06' }),
        });
        return;
      }

      toast.show({
        type: 'success',
        text: t('stripePaymentSuccess'),
      });
      await AppStorage.setLastPaymentId(status.orderId);
      goBack();
    } catch (error) {
      log.error('Failed to process payment', error);
      toast.show({
        type: 'error',
        text: t('stripePaymentFailed', { error: '07' }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    meroApi.payments
      .listSubscriptionSavedCards({ subscriptionId: subscriptionInfo._id })
      .then((cards) => {
        setSavedCards(cards);
        if (cards.length === 0) {
          setSelectedCard(NEW_CARD);
        }
      })
      .catch((error) => {
        log.error('Failed to get saved cards', error);
        setSelectedCard(NEW_CARD);
      });

    meroApi.payments
      .initSubscriptionStripePayment({
        pageId: subscriptionInfo.pageId,
        subscriptionId: subscriptionInfo._id,
      })
      .then((status) => {
        setStatus(status);
      })
      .catch((error) => {
        log.error('Failed to init payment', error);
        toast.show({
          type: 'error',
          text: t('paymentIntentError'),
        });
        goBack();
      });
  }, []);

  return status ? (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('stripeTitle')} />
      <DismissKeyboard
        style={{
          flex: 1,
          alignSelf: 'stretch',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <KeyboardAvoidingView style={{ flex: 1, alignSelf: 'stretch', paddingHorizontal: 16 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Spacer size={32} />
            <H1 style={{ paddingHorizontal: 8 }}>{t('stripeSelectPayment')}</H1>
            <Spacer size={24} />

            <FormCard dropShaddow rounded paddings="none" style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
              {savedCards.map((card, index) => (
                <React.Fragment key={card.id}>
                  <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setSelectedCard(card)}>
                    <Checkbox value={selectedCard?.id === card.id} onValueChange={() => setSelectedCard(card)} />
                    <Title style={{ fontSize: 17, paddingHorizontal: 12, flex: 1 }}>
                      {card.brand.toUpperCase()} ****{card.digits}
                    </Title>
                    {card.brand === 'visa' ? <Icon type="visa"></Icon> : <Icon type="mastercard"></Icon>}
                  </TouchableOpacity>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                </React.Fragment>
              ))}
              {savedCards.length > 0 && (
                <>
                  <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setSelectedCard(NEW_CARD)}>
                    <Checkbox value={selectedCard?.id === 'newCard'} onValueChange={() => setSelectedCard(NEW_CARD)} />
                    <Title style={{ fontSize: 17, paddingHorizontal: 12, flex: 1 }}>{t('stripeNewCard')}</Title>
                  </TouchableOpacity>
                </>
              )}
              {selectedCard?.id === NEW_CARD.id && (
                <>
                  <Spacer size={16} />
                  <Title>{t('cardDetails')}</Title>
                  <Spacer size={8} />
                  <Column
                    style={{
                      width: '100%',
                      height: 43,
                      paddingVertical: 12,
                      paddingHorizontal: 8,
                      borderRadius: 4,
                      borderWidth: 1,
                      borderColor: colors.GEYSER,
                    }}
                  >
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: '16px',
                            fontFamily: 'open-sans',
                            color: 'black',
                            fontWeight: '400',
                          },
                        },
                      }}
                      onChange={(evt) => setNewCardDetailsComplete(evt.complete)}
                    />
                  </Column>
                </>
              )}
              <Spacer size={24} />
              <Line />
              {status ? (
                <>
                  <Spacer size={32} />
                  <Column style={{ alignItems: 'center' }}>
                    <Title>{t('total')}</Title>
                    <Spacer size={8} />
                    <D2 style={{ fontSize: 32 }}>
                      {t('totalValue', { value: status.type === 'actionRequired' ? status.total.amount : 0 })}
                    </D2>
                  </Column>
                  <Spacer size={32} />
                </>
              ) : null}
              <Button
                text={t('stripeTitle')}
                onClick={onPay}
                disabled={!selectedCard || (selectedCard.id === NEW_CARD.id && !newCardDetailsComplete) || isLoading}
              />
            </FormCard>
            <Spacer size={24} />
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Image source={images.visa} style={[Platform.OS === 'web' && { width: 38, height: 24 }]} />
              <Image source={images.mastercard} style={[Platform.OS === 'web' && { width: 38, height: 24 }]} />
              <Image source={images.secure} style={[Platform.OS === 'web' && { width: 24, height: 24 }]} />
              <Label style={{ color: colors.SHAMROCK, fontFamily: 'open-sans-semibold', paddingLeft: 4 }}>
                {t('securePage')}
              </Label>
            </Row>
          </ScrollView>
        </KeyboardAvoidingView>
      </DismissKeyboard>
    </ModalScreenContainer>
  ) : (
    <SplashScreen />
  );
};

export default pipe(StripeCardPaymentScreen, withStripeElements, withSubscriptionInfo, CurrentBusiness);

import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { Body, FormCard, Icon, sizes, styles as meroStyles, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  readonly note: ClientNoteHistoryRecord;
  readonly onOptionsPress: (note: ClientNoteHistoryRecord) => void;
};

const ClientNotesListItemView: React.FC<Props> = ({ note, onOptionsPress }) => {
  const date = React.useMemo(() => DateTime.fromJSDate(note.date), [note.date]);
  const dateStr = React.useMemo(() => date.toFormat('d LLL yyyy', { locale: 'ro' }), [date]);
  const onOptionsPressCallback = React.useCallback(() => {
    onOptionsPress(note);
  }, [note, onOptionsPress]);

  return (
    <FormCard
      dropShaddow
      style={{
        marginLeft: sizes[16],
        marginRight: sizes[16],
        marginBottom: sizes[16],
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: sizes[16],
        paddingRight: sizes[16],
      }}
      rounded
    >
      <View style={[meroStyles.layout.row, { height: 32, alignItems: 'center' }]}>
        <View style={{ marginRight: sizes[12] }}>
          <Icon type="client-history-note" size={32} />
        </View>
        <View style={{ flex: 1 }}>
          <Title>{dateStr}</Title>
        </View>
        <TouchableOpacity style={{ width: 24, height: 24, alignSelf: 'baseline' }} onPress={onOptionsPressCallback}>
          <Icon type="options-dots" />
        </TouchableOpacity>
      </View>
      <View style={[meroStyles.layout.row, { paddingLeft: 44 }]}>
        <Body>{note.payload.text}</Body>
      </View>
    </FormCard>
  );
};

export default React.memo(ClientNotesListItemView);

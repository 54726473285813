import { ResponsiveImage } from '@mero/api-sdk';
import { colors, Column, Body, Label } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { NewImageType, Status } from './PageGallerySettingsScreen';

const RetryIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 6969">
      <G fill="#fff">
        <Path d="m8.168 10.169-.736.628a4.806 4.806 0 0 1 8.053-2.2l1.134-1.134a6.413 6.413 0 0 0-10.77 3.04l-.529-.642-1.337.802 2.39 3.145 2.836-2.422ZM20.016 13.335l-2.242-3.221-2.79 2.509 1.072 1.192.679-.61a4.805 4.805 0 0 1-8.053 2.2l-1.133 1.134a6.413 6.413 0 0 0 10.754-2.995l.47.638Z" />
      </G>
      <Path data-name="Rectangle 2669" fill="none" d="M0 0h24v24H0z" />
    </G>
  </Svg>
);

const CloseIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7236">
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path
        d="m6.284 16.642 4.644-4.644-4.644-4.643a.758.758 0 1 1 1.072-1.072L12 10.926l4.643-4.642a.758.758 0 0 1 1.072 1.072l-4.643 4.642 4.643 4.643a.758.758 0 0 1-1.072 1.072L12 13.07l-4.644 4.644a.758.758 0 0 1-1.072-1.072Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

type Props = {
  image: NewImageType;
  size: number;
  disabled: boolean;
  onRetry: (image: NewImageType) => void;
  onRemove: (image: NewImageType) => void;
};

const NewImage: React.FC<Props> = ({ image, onRetry, size, onRemove }) => {
  const { t } = useTranslation('gallery');

  return (
    <Column
      key={image._id}
      style={{
        width: size,
        height: size,
        marginLeft: 6,
        marginTop: 6,
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Image source={{ uri: image.uri }} style={{ width: size, height: size }} />
      <Column
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: 4,
          zIndex: 1,
        }}
      >
        <Column
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `rgba(0, 0, 0, 0.5)`,
          }}
        >
          {image.status === Status.LOADING && (
            <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold' }}>{t('isLoading')}</Label>
          )}
          {image.status === Status.FAILED && (
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                backgroundColor: `rgba(0, 0, 0, 0.6)`,
                borderRadius: 16,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => onRetry(image)}
            >
              <RetryIcon />
            </TouchableOpacity>
          )}
          {image.status === Status.TO_BIG && (
            <TouchableOpacity
              style={{
                width: 32,
                height: 32,
                backgroundColor: `rgba(0, 0, 0, 0.6)`,
                borderRadius: 16,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => onRemove(image)}
            >
              <CloseIcon />
            </TouchableOpacity>
          )}
        </Column>
        {image.status === Status.FAILED && (
          <TouchableOpacity style={{ paddingVertical: 4, backgroundColor: '#FBAC40' }} onPress={() => onRetry(image)}>
            <Label style={{ color: colors.WHITE, textAlign: 'center', fontFamily: 'open-sans-semibold' }}>
              {t('error')}
            </Label>
          </TouchableOpacity>
        )}
        {image.status === Status.TO_BIG && (
          <TouchableOpacity style={{ paddingVertical: 4, backgroundColor: '#FF0033' }} onPress={() => onRemove(image)}>
            <Label style={{ color: colors.WHITE, textAlign: 'center', fontFamily: 'open-sans-semibold' }}>
              {t('toBig')}
            </Label>
          </TouchableOpacity>
        )}
      </Column>
    </Column>
  );
};

export default NewImage;

import {
  ClientAppointmentCancelledHistoryType,
  ClientAppointmentCreatedHistoryType,
  ClientBlockedStatusChangedHistoryType,
  ClientFavouriteStatusChangedHistoryType,
  ClientHistoryRecord,
  ClientNoteHistoryType,
  ClientWarnedStatusChangedHistoryType,
  ClientAppointmentModifiedHistoryType,
} from '@mero/api-sdk/dist/clients';
import { Body, colors, FormCard, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatPhoneNumber, formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { AuthContext } from '../../../../contexts/AuthContext';
import { nameGenerator } from '../../../../utils/string';

type Props = {
  readonly record: ClientHistoryRecord;
  readonly now: Date;
};

const ClientHistoryListItemView: React.FC<Props> = ({ record, now }) => {
  const [authState] = AuthContext.useContext();
  return (
    <FormCard
      dropShaddow
      style={{
        marginLeft: sizes[16],
        marginRight: sizes[16],
        marginBottom: sizes[16],
        paddingTop: sizes[16],
        paddingBottom: sizes[16],
        paddingLeft: sizes[16],
        paddingRight: sizes[16],
      }}
      rounded
    >
      <View style={meroStyles.layout.row}>
        <View style={{ marginRight: sizes[12] }}>
          <Icon type="client-history" size={32} />
        </View>
        <View style={{ flex: 1 }}>
          {(() => {
            switch (record.type) {
              case ClientNoteHistoryType.value: {
                return <Body>{record.payload.text}</Body>;
              }
              case ClientAppointmentCreatedHistoryType.value: {
                const start = DateTime.fromJSDate(record.payload.start);
                const startStr = start.toFormat('cccc, dd LLLL yyyy, HH:mm', { locale: 'ro' });
                if (record.payload.createdByClient) {
                  return (
                    <Body>
                      Clientul si-a creat o <Body style={meroStyles.text.semibold}>noua</Body> programare pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                } else if (
                  (authState.type === 'Authorized' && authState.user._id === record.payload.byUser?._id) ||
                  !record.payload.byUser
                ) {
                  return (
                    <Body>
                      Ai creat o <Body style={meroStyles.text.semibold}>noua</Body> programare pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                } else if (record.payload.byUser) {
                  return (
                    <Body>
                      <Body style={meroStyles.text.semibold}>{nameGenerator(record.payload.byUser ?? {}, '')}</Body> (
                      <Body style={{ textDecorationLine: 'underline' }}>
                        {formatPhoneNumber(record.payload.byUser?.phone ?? '')}
                      </Body>
                      ) a creat o <Body style={meroStyles.text.semibold}>noua</Body> programare pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                }
                return null;
              }
              case ClientAppointmentCancelledHistoryType.value: {
                const start = DateTime.fromJSDate(record.payload.start);
                const startStr = start.toFormat('cccc, dd LLLL yyyy, HH:mm', { locale: 'ro' });
                if (record.payload.cancelledByClient) {
                  return (
                    <Body>
                      Clientul si-a <Body style={meroStyles.text.semibold}>anulat</Body> programarea pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                } else if (
                  (authState.type === 'Authorized' && authState.user._id === record.payload.byUser?._id) ||
                  !record.payload.byUser
                ) {
                  return (
                    <Body>
                      Ai <Body style={meroStyles.text.semibold}>anulat</Body> programarea pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                } else if (record.payload.byUser) {
                  return (
                    <Body>
                      <Body style={meroStyles.text.semibold}>{nameGenerator(record.payload.byUser ?? {}, '')}</Body> (
                      <Body style={{ textDecorationLine: 'underline' }}>
                        {formatPhoneNumber(record.payload.byUser?.phone ?? '')}
                      </Body>
                      ) a <Body style={meroStyles.text.semibold}>anulat</Body> programarea pentru ziua de{' '}
                      <Body style={meroStyles.text.semibold}>{startStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                }
                return null;
              }
              case ClientWarnedStatusChangedHistoryType.value: {
                if (record.payload.status) {
                  return <Body>Ai marcat clientul ca avertizat.</Body>;
                } else {
                  return <Body>Ai sters avertizarea clientului.</Body>;
                }
              }
              case ClientBlockedStatusChangedHistoryType.value: {
                if (record.payload.status) {
                  return <Body>Ai blocat clientul.</Body>;
                } else {
                  return <Body>Ai deblocat clientul.</Body>;
                }
              }
              case ClientFavouriteStatusChangedHistoryType.value: {
                if (record.payload.status) {
                  return <Body>Ai setat clientul ca favorit.</Body>;
                } else {
                  return <Body>Ai scos clientul din favoriti.</Body>;
                }
              }
              case ClientAppointmentModifiedHistoryType.value: {
                const oldDate = DateTime.fromJSDate(record.payload.start.from);
                const oldDateStr = oldDate.toFormat('cccc, dd LLLL yyyy, HH:mm', { locale: 'ro' });
                const newDate = DateTime.fromJSDate(record.date);
                const newDateStr = newDate.toFormat('cccc, dd LLLL yyyy, HH:mm', { locale: 'ro' });

                if (authState.type === 'Authorized' && authState.user._id === record.payload.byUser._id) {
                  return (
                    <Body>
                      Ai <Body style={meroStyles.text.bold}>mutat</Body> programarea de{' '}
                      <Body style={meroStyles.text.bold}>{oldDateStr}</Body> pe{' '}
                      <Body style={meroStyles.text.bold}>{newDateStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                } else {
                  return (
                    <Body>
                      {`${record.payload.byUser.firstname} ${record.payload.byUser.lastname} a `}
                      <Body style={meroStyles.text.bold}>mutat</Body> programarea de{' '}
                      <Body style={meroStyles.text.bold}>{oldDateStr}</Body> pe{' '}
                      <Body style={meroStyles.text.bold}>{newDateStr}</Body> - &quot;{record.payload.serviceName}
                      {nameGenerator(record.payload.worker ?? {}, '')
                        ? `, la ${nameGenerator(record.payload.worker ?? {}, '')}`
                        : ''}
                      &quot;.
                    </Body>
                  );
                }
              }
            }
          })()}
        </View>
      </View>
      <View style={[meroStyles.layout.column, { paddingLeft: 44 }]}>
        <SmallBody style={{ fontSize: 12, color: colors.COMET }}>{formatTimeDiff(record.date, now)}</SmallBody>
      </View>
    </FormCard>
  );
};

export default React.memo(ClientHistoryListItemView);

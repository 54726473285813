import { PageBillingDetails } from '@mero/api-sdk';
import { colors, Line, SmallBody, Spacer, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { styles } from './CheckoutCompanyMenu.styles';

export type Props = {
  onChange: () => void;
  onDelete: () => void;
  onDismiss: () => void;
};

const CheckoutCompanyMenu: React.FC<Props> = ({ onChange, onDelete, onDismiss }) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('checkout');

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onChange}>
              <Title>{t('changeCompanyDetails')}</Title>
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDelete}>
              <Title style={{ color: colors.RADICAL_RED }}>{t('deleteCompanyBilling')}</Title>
            </TouchableOpacity>
            <Line />
          </SafeAreaView>
        </View>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default CheckoutCompanyMenu;

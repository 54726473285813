import * as MeroApi from '@mero/api-sdk';
import {
  Body,
  colors,
  HSpacer,
  Icon,
  NotificationCalendarCancelIcon,
  sizes,
  SmallBody,
  styles as meroStyles,
} from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentCancelledByWorkerNotification;
};

const AppointmentCancelledByWorkerNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const appTs = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <NotificationCalendarCancelIcon />
      <View style={styles.notificationBody}>
        <Body>
          Programarea ta la <Body style={meroStyles.text.semibold}>{payload.page.name}</Body> de{' '}
          <Body style={meroStyles.text.semibold}>{appTs.toFormat('cccc d LLL', { locale: 'ro' })}</Body>, ora{' '}
          <Body style={meroStyles.text.semibold}>{appTs.toFormat('H:mm', { locale: 'ro' })}</Body> a fost anulată.
        </Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentCancelledByWorkerNotification;

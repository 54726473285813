import * as React from 'react';
import Svg, { Path, G, Circle, Rect } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationCalendarClockIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#fbac40',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill={backgroundColor} />
    <G transform="translate(-117 -189)">
      <Rect width="18" height="18" transform="translate(124 196)" fill="none" />
      <Path
        d="M9.917,1.75V.583a.583.583,0,0,1,1.167,0V1.75a.583.583,0,0,1-1.167,0Zm-7,.583A.583.583,0,0,0,3.5,1.75V.583a.583.583,0,0,0-1.167,0V1.75A.583.583,0,0,0,2.917,2.333ZM10.5,9.917V8.167H9.917V10.5h1.75V9.917Zm-2.917.292a2.625,2.625,0,1,0,2.625-2.625A2.628,2.628,0,0,0,7.583,10.208Zm6.417,0a3.792,3.792,0,1,1-3.792-3.792A3.792,3.792,0,0,1,14,10.208ZM5.7,12.25H1.167V4.667H12.25V5.7a4.973,4.973,0,0,1,1.167.74V1.167h-1.75V1.75a1.171,1.171,0,0,1-1.55,1.1A1.2,1.2,0,0,1,9.333,1.7V1.167H4.083V1.75a1.171,1.171,0,0,1-1.55,1.1A1.2,1.2,0,0,1,1.75,1.7V1.167H0v12.25H6.435A4.973,4.973,0,0,1,5.7,12.25Z"
        transform="translate(126 198)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationCalendarClockIcon);

import * as React from 'react';

import { StackNavigationProp } from '@react-navigation/stack';

import { AuthContext } from '../../contexts/AuthContext';
import { RootStackParamList } from '../../types';
import SplashScreen from '../SplashScreen';

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'Index'>;
};

export default function IndexScreen({ navigation }: Props): React.ReactElement {
  const [state] = AuthContext.useContext();

  React.useEffect(() => {
    // As Authorized/Anonymous stack may not yet be mounted to navigation - using a timeout
    // TODO: find a better way to solve this (ex: always mount all screens? check auth on each screen level)
    if (state.type === 'Authorized') {
      setTimeout(() => {
        navigation.replace('Authorized', {
          screen: 'Home',
          params: { screen: 'HomeTabs', params: { screen: 'CalendarTab', params: { screen: 'CalendarScreen' } } },
        });
      }, 200);
    } else if (state.type === 'Anonymous') {
      setTimeout(() => {
        navigation.replace('Anonymous', {
          screen: 'SignInScreen',
        });
      }, 200);
    }
  }, [state, navigation]);

  return <SplashScreen />;
}

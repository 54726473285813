import { Order } from '@mero/api-sdk/dist/payments/order';
import {
  MeroHeader,
  colors,
  Column,
  H1,
  Spacer,
  FormCard,
  Button,
  Row,
  Checkbox,
  SmallBody,
  Title,
  Icon,
  useToast,
} from '@mero/components';
import { sizes } from '@mero/components';
import { Platform } from 'expo-modules-core';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity } from 'react-native';
import { StyleSheet } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { SubscriptionInfoProps, withSubscriptionInfo } from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

type Props = StackScreenProps<SubscriptionStackParamList, 'PaymentMethod'> &
  SubscriptionInfoProps & {
    // pass
  };

const SelectedPaymentMethodScreen: React.FC<Props> = ({ subscriptionInfo, navigation, route }: Props) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('subscription');
  const goBack = useGoBack();
  const toast = useToast();

  const [orders, setOrders] = React.useState<Order[]>([]);

  const [, { openChat }] = IntercomContext.useContext();

  const payWithCreditCard = async () => {
    if (!subscriptionInfo) {
      return;
    }

    navigation.navigate('CardPayment', { meroCompany: route.params.meroCompany });
  };

  const downloadEstimate = async () => {
    if (orders.length > 0) {
      try {
        const response = await meroApi.payments.getOrderEstimateUrl({ orderId: orders[0]._id });
        if (Platform.OS === 'web') {
          window.location.href = response.downloadUrl;
        } else {
          await Linking.openURL(response.downloadUrl);
        }
      } catch (error) {
        log.debug('Error downloading estimate', error);
        toast.show({
          type: 'error',
          text: t('downloadEstimateError'),
        });
      }
    }
  };

  const onPressPaymentProof = React.useCallback(() => {
    openChat();
  }, [openChat]);

  const [paymentType, setPaymentType] = React.useState<'card' | 'transfer'>('transfer');

  const getPendingOrders = async () => {
    if (subscriptionInfo) {
      try {
        const response = await meroApi.payments.listPendingOrders(subscriptionInfo._id);
        setOrders(response);
      } catch (error) {
        toast.show({
          type: 'error',
          text: t('errorGettingPendingOrders'),
        });
      }
    }
  };

  React.useEffect(() => {
    getPendingOrders();
  }, []);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('selectPaymentMethod')} />
      <>
        <Column style={[styles.formMargin, styles.flex]}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('selectPaymentMethod')}</H1>
          <Spacer size={32} />
          <FormCard dropShaddow rounded paddings="button">
            <Row>
              <Column>
                <Checkbox
                  value={paymentType === 'card'}
                  color="blue"
                  disabled={false}
                  onValueChange={() => setPaymentType('card')}
                />
              </Column>
              <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={() => setPaymentType('card')}>
                <Title>{t('cardPayment')}</Title>
                <SmallBody style={{ color: colors.COMET }}>{t('cardPaymentInfo')}</SmallBody>
              </TouchableOpacity>
            </Row>
            <Row style={{ paddingTop: 24 }}>
              <Column>
                <Checkbox
                  value={paymentType === 'transfer'}
                  color="blue"
                  disabled={false}
                  onValueChange={() => setPaymentType('transfer')}
                />
              </Column>
              <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={() => setPaymentType('transfer')}>
                <Title>{t('transferPayment')}</Title>
                <SmallBody style={{ color: colors.COMET }}>{t('transferPaymentInfo')}</SmallBody>
              </TouchableOpacity>
            </Row>
          </FormCard>
          {paymentType === 'transfer' && (
            <>
              <Spacer size={32} />
              <Button
                onClick={onPressPaymentProof}
                size="small"
                text={t('uploadProofOfPayment')}
                backgroundColor={colors.ALABASTER}
                color={colors.DARK_BLUE}
              />
            </>
          )}

          <Spacer size={144} />
        </Column>

        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              paymentType === 'card' ? (
                <Button
                  text={t('payOnline')}
                  onClick={payWithCreditCard}
                  RightComponent={() => <Icon type="arrow-right" color={colors.WHITE} />}
                />
              ) : (
                <Button text={t('downloadProforma')} onClick={downloadEstimate} />
              )
            ) : paymentType === 'card' ? (
              <Button
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('payOnline')}
                onClick={payWithCreditCard}
              />
            ) : (
              <Button
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('downloadProforma')}
                onClick={downloadEstimate}
              />
            )}
          </SafeAreaView>
        </FormCard>
      </>
    </ModalScreenContainer>
  );
};

const styles = StyleSheet.create({
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  hrPadding: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  formMargin: {
    paddingTop: sizes[16],
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  list: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  margin: {
    marginHorizontal: 24,
  },
  flex: {
    flex: 1,
  },
});

export default pipe(SelectedPaymentMethodScreen, withSubscriptionInfo);

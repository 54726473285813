import * as React from 'react';
import { Platform } from 'react-native';

type Props = {
  ref: React.RefObject<any>;
  isVisible: boolean;
  onClickOutside: () => void;
  condition?: boolean;
};

export const useClickOutsideWeb = ({ ref, isVisible, onClickOutside, condition = Platform.OS === 'web' }: Props) => {
  React.useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside();
      }
    };

    if (condition && isVisible) {
      document.addEventListener('mousedown', checkIfClickedOutside);
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      }
    };
  }, [isVisible]);
};

import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Camera = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <G transform="translate(-4 -4) translate(7 7)" data-name="Group 6840">
        <Path data-name="Rectangle 2639" transform="translate(-3 -3)" fill="none" d="M0 0H24V24H0z" />
        <G
          transform="translate(-8 -875) translate(9.5 878.5)"
          fill="none"
          stroke={color || '#52577f'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
          data-name="Group 7024"
        >
          <Path d="M14.25 2h-3.1L9.5 0h-4L3.85 2H.75a.75.75 0 00-.75.75v7.5a.75.75 0 00.75.75h13.5a.75.75 0 00.75-.75v-7.5a.75.75 0 00-.75-.75z" />
          <Circle cx={3.5} cy={3.5} r={3.5} transform="translate(4 2.5)" />
          <Circle data-name="layer1" cx={0.25} cy={0.25} r={0.25} transform="translate(12.75 3.75)" />
          <Circle data-name="layer1" cx={1.5} cy={1.5} r={1.5} transform="translate(6 4.5)" />
        </G>
      </G>
    </Svg>
  );
};

export default Camera;

import { HasId } from '@mero/api-sdk';
import { BusinessHours as BusinessHoursType } from '@mero/api-sdk/dist/business';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { DateTime } from 'luxon';
import * as React from 'react';
import { PanResponder, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';

import { CalendarContext, LocalDateObject } from '../../../../contexts/CalendarContext';
import { distributeCards, positionCards } from '../../../../utils/card-board';
import { preZero } from '../../../../utils/time';
import { NormalizedEvent } from '../../NormalizedEvent';
import { AddEventIntentParams } from '../../index';
import { BusinessHours } from '../../mockData';
import { HoverHourCell } from '../CalendarBodySplitView';
import TapHourCell from '../CalendarBodySplitView/TapHourCell';
import CalendarEventView from '../CalendarEvent/CalendarEventView';
import FloatMenu from '../FloatMenu';
import { styles as commonStyles } from '../styles';
import { ActiveHour, HorizontalDirection } from '../types';
import { formatHour, getRelativeTopInDay } from '../utils';

const SWIPE_THRESHOLD = 50;
const REFRESH_TIME = 60 * 1000; // 1 min

interface CalendarBodyProps {
  hourHeight: number;
  containerHeight: number;
  dateRange: LocalDateObject[];
  currentDate: LocalDateObject;
  normalizedEvents: Record<CalendarId, Record<string, NormalizedEvent[] | undefined>>;
  scrollOffsetMinutes: number;
  ampm: boolean;
  showTime: boolean;
  style: ViewStyle;
  hideNowIndicator?: boolean;
  isRTL: boolean;
  onScroll?: () => void;
  onPressCell?: (date: Date, worker: HasId<WorkerId> | undefined) => void;
  onPressEvent?: (event: NormalizedEvent) => void;
  onSwipeHorizontal?: (d: HorizontalDirection) => void;
  activeHours: Record<CalendarId, BusinessHoursType>;
  addEventIntent?: AddEventIntentParams;
  dayHours: number[];
  onAddBooking?: () => void;
  onAddBlockedTime?: () => void;
  onAddCheckout?: () => void;
}

interface WithCellHeight {
  cellHeight: number;
}

const HourGuideColumn = React.memo(
  function HourGuideColumn({ cellHeight, hour, ampm }: WithCellHeight & { hour: number; ampm: boolean }) {
    return (
      <View style={{ height: cellHeight }}>
        <Text style={[commonStyles.guideText, { fontFamily: 'open-sans-semibold' }]}>{formatHour(hour, ampm)}</Text>
      </View>
    );
  },
  () => true,
);

const checkIfIsAllowedInterval = (activeHours: ActiveHour, hour: number, minute: number) => {
  if (!activeHours.active) {
    return false;
  }

  if (activeHours.from.hour > hour) {
    return false;
  }

  if (activeHours.from.hour === hour && activeHours.from.minute > minute) {
    return false;
  }

  if (activeHours.to.hour < hour) {
    return false;
  }

  if (activeHours.to.hour === hour && activeHours.to.minute <= minute) {
    return false;
  }

  return true;
};

const DIVIDE_HOUR = 4;

interface HourCellProps extends WithCellHeight {
  onPress: (d: DateTime) => void;
  date: LocalDateObject;
  selectedTimezone: string;
  hour: number;
  divider?: number;
  activeHours: ActiveHour;
  isActive: boolean;
  selectedTime: string;
  onAddBooking?: () => void;
  onAddBlockedTime?: () => void;
  onAddCheckout?: () => void;
}

function HourCell({
  activeHours,
  cellHeight,
  onPress,
  date,
  selectedTimezone,
  hour,
  divider = DIVIDE_HOUR,
  isActive,
  selectedTime,
  onAddBooking,
  onAddBlockedTime,
  onAddCheckout,
}: HourCellProps) {
  const onPressCallback = React.useCallback(
    (hour: number, minute: number) => {
      onPress(
        DateTime.fromObject(
          {
            year: date.year,
            month: date.month,
            day: date.day,
            hour: hour,
            minute: minute,
            second: 0,
            millisecond: 0,
          },
          { zone: selectedTimezone },
        ),
      );
    },
    [date, onPress],
  );

  return (
    <>
      {Array(DIVIDE_HOUR)
        .fill(1)
        .map((_, index) => {
          const minute = (60 / divider) * index;
          const isAllowedInterval = checkIfIsAllowedInterval(activeHours, hour, minute);
          const cellId = `${date.day}:${date.month}:${date.year}:${preZero(hour)}:${preZero(minute)}`;
          return (
            <FloatMenu
              key={cellId}
              id={cellId}
              onAddBooking={onAddBooking}
              onAddBlockedTime={onAddBlockedTime}
              onAddCheckout={onAddCheckout}
              onPress={() => onPressCallback(hour, minute)}
            >
              <View
                style={[
                  index < divider - 1 ? commonStyles.dateCellSecondary : commonStyles.dateCellPrimary,
                  { height: cellHeight / divider },
                  { backgroundColor: isAllowedInterval ? '#FFFFFF' : '#EFEFEF' },
                ]}
              >
                {Platform.OS === 'web' ? (
                  <HoverHourCell id={cellId} hour={hour} minute={minute} />
                ) : isActive && selectedTime === `${hour}:${minute}` ? (
                  <TapHourCell hour={hour} minute={minute} />
                ) : null}
              </View>
            </FloatMenu>
          );
        })}
    </>
  );
}

const CalendarBody = React.memo(function CalendarBody({
  containerHeight,
  hourHeight,
  dateRange,
  currentDate,
  style = {},
  onPressCell,
  normalizedEvents,
  onPressEvent,
  ampm,
  showTime,
  scrollOffsetMinutes,
  onSwipeHorizontal,
  hideNowIndicator,
  isRTL,
  activeHours,
  onScroll = () => null,
  addEventIntent,
  dayHours,
  onAddBooking,
  onAddBlockedTime,
  onAddCheckout,
}: CalendarBodyProps) {
  const [{ selectedTimezone, selectedCalendars }] = CalendarContext.useContext();
  const scrollView = React.useRef<ScrollView>(null);
  const initialNow = React.useMemo(() => DateTime.now().setZone(selectedTimezone), [selectedTimezone]);
  const [now, setNow] = React.useState(initialNow);
  const [panHandled, setPanHandled] = React.useState(false);

  const [startHour, endHour] = React.useMemo(() => [dayHours.at(0) ?? 0, (dayHours.at(-1) ?? 23) + 1], [dayHours]);

  // For some reason - scrollView reference changes .. once ... scroll to initial position only once
  const [scrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    const current = scrollView.current;
    if (current && !scrolled) {
      setScrolled(true);
      // We add delay here to work correct on React Native
      // see: https://stackoverflow.com/questions/33208477/react-native-android-scrollview-scrollto-not-working
      setTimeout(
        () => {
          try {
            current.scrollTo({
              y: scrollOffsetMinutes
                ? (hourHeight * scrollOffsetMinutes) / 60
                : hourHeight * (now.get('hour') - startHour - 1),
              animated: false,
            });
          } catch (e) {
            // Some sht happens some times (fails with Cannot read properties of null (reading 'scroll'))
          }
        },
        Platform.OS === 'web' ? 0 : 10,
      );
    }
  }, [scrollView.current, startHour]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.fromJSDate(new Date(), { zone: selectedTimezone }));
    }, REFRESH_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const panResponder = React.useMemo(
    () =>
      PanResponder.create({
        // see https://stackoverflow.com/questions/47568850/touchableopacity-with-parent-panresponder
        onMoveShouldSetPanResponder: (_, { dx, dy }) => {
          return dx > 2 || dx < -2 || dy > 2 || dy < -2;
        },
        onPanResponderMove: (_, { dy, dx }) => {
          if (dy < -1 * SWIPE_THRESHOLD || SWIPE_THRESHOLD < dy || panHandled) {
            return;
          }
          if (dx < -1 * SWIPE_THRESHOLD) {
            onSwipeHorizontal && onSwipeHorizontal('LEFT');
            setPanHandled(true);
            return;
          }
          if (dx > SWIPE_THRESHOLD) {
            onSwipeHorizontal && onSwipeHorizontal('RIGHT');
            setPanHandled(true);
            return;
          }
        },
        onPanResponderEnd: () => {
          setPanHandled(false);
        },
      }),
    [panHandled, onSwipeHorizontal],
  );

  const _onPressCell = React.useCallback(
    (date: DateTime) => {
      onPressCell?.(date.toJSDate(), undefined);
    },
    [onPressCell],
  );

  const [boardColumns, setBoardColumns] = React.useState(1);

  const fullCalendarSize = hourHeight * 24;
  const calendarSize = hourHeight * (endHour - startHour);

  return (
    <ScrollView
      style={[
        {
          height: containerHeight - hourHeight * 3,
        },
        style,
      ]}
      ref={scrollView}
      onScroll={onScroll}
      scrollEventThrottle={32}
      {...(Platform.OS !== 'web' ? panResponder.panHandlers : {})}
      showsVerticalScrollIndicator={false}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        const boardColumns = Math.max(
          Math.floor(width / dateRange.length / Math.max(selectedCalendars.length, 1) / 230),
          1,
        );
        setBoardColumns(boardColumns);
      }}
    >
      <View
        style={isRTL ? [styles.bodyRTL] : [styles.body]}
        {...(Platform.OS === 'web' ? panResponder.panHandlers : {})}
      >
        <View style={[commonStyles.hourGuide]}>
          {dayHours.map((hour) => (
            <HourGuideColumn key={hour} cellHeight={hourHeight} hour={hour} ampm={ampm} />
          ))}
        </View>
        {dateRange.map((date: LocalDateObject, index) => {
          const d = DateTime.fromObject(
            {
              year: date.year,
              month: date.month,
              day: date.day,
            },
            {
              zone: selectedTimezone,
            },
          );
          const weekday = d.weekday;

          return (
            <View
              key={`${date.year}-${date.month}-${date.day}_${index}`}
              nativeID="calendar-body"
              style={[{ flex: 1 }, ...(index !== dateRange.length - 1 ? [styles.withBorder] : [])]}
            >
              {selectedCalendars.map((selectedCalendar) => {
                const dayEvents = normalizedEvents[selectedCalendar]
                  ? normalizedEvents[selectedCalendar][LocalDateObject.format(date)] || []
                  : [];
                const calendarActiveHours = activeHours[selectedCalendar] ?? BusinessHours;
                const dayActiveHours = calendarActiveHours[weekday - 1];
                const isActive = addEventIntent?.worker?.calendar._id === selectedCalendar;
                const selectedTime = addEventIntent?.start.toFormat('H:m') ?? '';

                return (
                  <View
                    style={[styles.dayContainer, { width: `${100 / selectedCalendars.length}%` }]}
                    key={`${date.year}-${date.month}-${date.day}_${selectedCalendar}`}
                  >
                    {dayHours.map((hour) => (
                      <HourCell
                        activeHours={dayActiveHours}
                        key={hour}
                        cellHeight={hourHeight}
                        date={date}
                        hour={hour}
                        selectedTimezone={selectedTimezone}
                        onPress={_onPressCell}
                        isActive={isActive}
                        selectedTime={selectedTime}
                        onAddBooking={onAddBooking}
                        onAddBlockedTime={onAddBlockedTime}
                        onAddCheckout={onAddCheckout}
                      />
                    ))}

                    {positionCards(
                      distributeCards(
                        dayEvents.map(
                          (event) =>
                            ({
                              start: event.start.toMillis(),
                              end: event.end.toMillis(),
                              event: event,
                            } as const),
                        ),
                        {
                          minCardSize: 15 * 60 * 1000, // 15 minutes
                        },
                      ),
                      {
                        columns: boardColumns,
                        start: d.set({ hour: startHour, minute: 0 }).toMillis(),
                        end: d.set({ hour: endHour, minute: 0 }).toMillis(),
                        minCardSize: 15 * 60 * 1000, // 15 minutes
                        shrinkCards: true,
                      },
                    ).map((row) => {
                      const event = row.card.event;

                      return (
                        <TouchableOpacity
                          key={`custom-event-${event.start}${event.title}${event.extra.id}`}
                          delayPressIn={20}
                          onPress={() => {
                            if (onPressEvent) {
                              onPressEvent(event);
                            }
                          }}
                          style={{
                            position: 'absolute',
                            left: `${row.x * 100}%`,
                            top: Math.floor(calendarSize * row.y),
                            width: `${row.width * 95}%`,
                            height: Math.floor(calendarSize * row.height),
                            overflow: 'hidden',
                            paddingBottom: 1,
                          }}
                        >
                          <CalendarEventView event={event} showTime={showTime} now={DateTime.now()} />
                        </TouchableOpacity>
                      );
                    })}

                    {LocalDateObject.equals(currentDate, date) && !hideNowIndicator && (
                      <View
                        style={[
                          styles.nowIndicator,
                          { top: fullCalendarSize * (getRelativeTopInDay(now.minus({ hour: startHour })) / 100) },
                        ]}
                      >
                        <View style={{ borderRadius: 2, width: 4, height: 4, backgroundColor: '#080DE0' }} />
                        <View style={{ flex: 1, backgroundColor: '#080DE0', height: 1 }} />
                      </View>
                    )}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  body: {
    flexDirection: 'row',
    flex: 1,
  },
  bodyRTL: {
    flexDirection: 'row-reverse',
    flex: 1,
  },
  withBorder: {
    borderRightWidth: 1,
    borderColor: '#ADADAD',
  },
  nowIndicator: {
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    alignItems: 'center',
  },
  dayContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  weekDay: {
    borderRightWidth: 1,
    borderColor: '#ADADAD',
  },
});

export default CalendarBody;

import { SubscriptionTier } from '@mero/api-sdk';
import { SubscriptionInfo } from '@mero/api-sdk/dist/payments';
import { FormCard, Label, sizes, colors, Spacer, H2s, Body, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  readonly subscriptionInfo: Pick<SubscriptionInfo, 'expires' | 'tier' | 'autoCharge' | 'trialStarted'>;
  readonly now: Date;
  readonly onContactUsPressed: () => void;
};

const SubscriptionValidity: React.FC<Props> = ({ subscriptionInfo, now, onContactUsPressed }) => {
  const isPreTrial = subscriptionInfo.tier === SubscriptionTier.TRIAL && !subscriptionInfo.trialStarted;
  const isTrial = subscriptionInfo.tier === SubscriptionTier.TRIAL && subscriptionInfo.trialStarted;
  const isExpired = subscriptionInfo.expires.getTime() < now.getTime();
  const isAutoChargeOn = subscriptionInfo.autoCharge ?? false;

  const { t } = useTranslation('subscription');
  const expires = React.useMemo(() => DateTime.fromJSDate(subscriptionInfo.expires), [subscriptionInfo.expires]);
  const expirationDate = React.useMemo(() => expires.setLocale('ro').toFormat('dd LLLL yyyy'), [expires]);

  return (
    <FormCard dropShaddow paddings="button" style={{ marginLeft: sizes[16], marginRight: sizes[16] }} rounded>
      {isExpired ? (
        <Label style={{ color: colors.RADICAL_RED }}>{t('expired')}</Label>
      ) : (
        <Label style={{ color: colors.COMET }}>{t('availability')}</Label>
      )}
      <Spacer size={sizes[6]} />
      {isPreTrial || isTrial ? (
        <>
          <H2s>{isPreTrial ? t('trial') : expirationDate}</H2s>
          <Body style={{ color: '#32325D', paddingTop: 8 }}>
            <Trans ns={'subscription'} t={t} i18nKey="trialDescription">
              0
              <Body style={{ color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' }} onPress={onContactUsPressed}>
                1
              </Body>
            </Trans>
          </Body>
        </>
      ) : (
        <>
          <H2s>{expirationDate}</H2s>
          <Body style={{ color: '#32325D', paddingTop: 8 }}>
            {isAutoChargeOn ? (
              <Trans
                ns={'subscription'}
                t={t}
                i18nKey="availabilityWithAutomaticRenewal"
                values={{ date: expirationDate }}
              >
                0 <Title style={{ color: '#32325D' }}></Title>
              </Trans>
            ) : (
              <Trans
                ns={'subscription'}
                t={t}
                i18nKey="availabilityWithoutAutomaticRenewal"
                values={{ date: expirationDate }}
              >
                0 <Title style={{ color: '#32325D' }}></Title>
              </Trans>
            )}
          </Body>
        </>
      )}
    </FormCard>
  );
};

export default SubscriptionValidity;

import { MeroUnits, SubscriptionPriceOffer } from '@mero/api-sdk';
import { colors, Column, FormCard, MeroHeader, Spacer, Title, useShowError, useToast } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import H1 from '@mero/components/lib/components/Text/H1';

import { NavigationProp, RouteProp } from '@react-navigation/native';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import {
  SubscriptionContext,
  SubscriptionInfoProps,
  withSubscriptionInfo,
} from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import { logCatch } from '../../../../../utils/log';
import { styles } from './CompanyBillingDetails.style';
import PageBillingCycleItem from './PageBillingCycleItem';

type Props = SubscriptionInfoProps & {
  navigation: NavigationProp<SubscriptionStackParamList, 'BillingCycleList'>;
  route: RouteProp<SubscriptionStackParamList, 'BillingCycleList'>;
};

const PageBillingCycleList: React.FC<Props> = ({ navigation, route, subscriptionInfo }) => {
  const { t } = useTranslation('subscription');
  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();
  const [, { reloadAsync }] = SubscriptionContext.useContext();

  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedBillingCycle, setSelectedBillingCycle] = React.useState(subscriptionInfo.billingCycle ?? 'monthly');
  const [subscriptionOffers, setSubscriptionOffers] = React.useState<SubscriptionPriceOffer<MeroUnits.Any>[]>([]);

  // Fetch offers from the API
  React.useEffect(() => {
    setIsLoading(true);
    meroApi.pro.subscription
      .getSubscriptionPriceOffers(subscriptionInfo.pageId)
      .then((offers) => {
        setSubscriptionOffers(offers);
        setIsLoading(false);
      })
      .catch(logCatch('getSubscriptionPriceOffers'));
  }, []);

  const saveChanges = async () => {
    if (!selectedBillingCycle) {
      return;
    }

    try {
      setIsLoading(true);

      if (subscriptionInfo.billingCycle !== selectedBillingCycle) {
        await meroApi.pro.subscription
          .updateBillingCycle({
            pageId: subscriptionInfo.pageId,
            billingCycle: selectedBillingCycle,
          })
          .catch(logCatch('updateBillingCycle'));
      }
      reloadAsync({
        pageId: subscriptionInfo.pageId,
        withoutDelay: true,
      });

      goBack();

      toast.show({
        type: 'success',
        text: t('billingCycleSelected'),
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !selectedBillingCycle || isLoading;

  return (
    <>
      <ModalScreenContainer>
        <MeroHeader
          canGoBack={true}
          onBack={goBack}
          TitleComponent={() => <Title style={{ color: colors.COMET }}>{t('billingCycle')}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <ScrollView style={{ paddingHorizontal: 16, paddingBottom: 96 }}>
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 8 }}>{t('billingCycle')}</H1>
          <Spacer size={8} />

          {subscriptionOffers.length > 0 && (
            <>
              {subscriptionOffers.map((offer, index) => (
                <FormCard
                  key={offer.billingCycle}
                  paddings="none"
                  rounded
                  dropShaddow
                  style={{ paddingVertical: 8, zIndex: 1, marginTop: 20 }}
                >
                  <React.Fragment>
                    <Column
                      style={{ paddingVertical: 16, paddingHorizontal: 16, zIndex: subscriptionOffers.length - index }}
                    >
                      <PageBillingCycleItem
                        isSelected={offer.billingCycle === selectedBillingCycle}
                        offer={offer}
                        onPress={setSelectedBillingCycle}
                      />
                    </Column>
                  </React.Fragment>
                </FormCard>
              ))}
            </>
          )}

          <Spacer size={subscriptionOffers.length > 0 ? 32 : 10} />
        </ScrollView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, styles.modalBorderBottom]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button text={t('saveChanges')} onPress={saveChanges} disabled={isDisabled} />
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(PageBillingCycleList, withSubscriptionInfo);

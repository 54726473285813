import { ApiError } from '@mero/api-sdk';
import { PageRoleId, PageRoleOverview } from '@mero/api-sdk/dist/pages';
import { Button, colors, Column, FormCard, H1, Header, Icon, Select, Spacer, useToast } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsInviteStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import PermissionSlider from './PermissionSlider';
import { styles } from './ProProfileDetailsScreen.styles';

export type Props = StackScreenProps<ProsSettingsInviteStackParamList, 'ProInvitePermissions'>;

export const PRO_ACCESS_INDIVIDUAL = 4;

const InviteProPermissionsScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [pageState, { getPageWorkers, getPageMembers }] = CurrentBusinessContext.useContext();

  const { workerId, workerName, serviceIds } = route.params;

  const block = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const scrollRef = React.useRef<ScrollView>(null);

  const [newRole, setNewRole] = React.useState<PageRoleId | undefined>(undefined);
  const [roles, setRoles] = React.useState<{ label: string; value: PageRoleId }[]>([]);
  const [rolesPermissions, setRolesPermissions] = React.useState<PageRoleOverview[]>([]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setRoles(pageState.page.roles.map((role) => ({ label: role.name, value: role.id })));
      setRolesPermissions(pageState.page.roles);

      const defaultRole = (
        pageState.page.roles.find((role) => role.id === PRO_ACCESS_INDIVIDUAL) ?? pageState.page.roles[0]
      ).id;

      setNewRole(defaultRole);
    }
  }, [pageState.type]);

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsLoading(true);
    try {
      await meroApi.invites.inviteWorkerToPage({
        pageId: pageState.page.details._id,
        workerId,
        serviceIds,
        roleId: newRole,
      });

      await Promise.all([getPageWorkers(pageState.page.details._id), getPageMembers(pageState.page.details._id)]);

      navigation.navigate('ProInviteSuccess', { workerName });
    } catch (error) {
      log.error('Failed to update worker details', error);
      toast.show({
        type: 'error',
        text: error instanceof ApiError ? error.message : t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proPermissions')}
      />
      <ScrollView ref={scrollRef}>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('profileDetails')}</H1>
          <Spacer size={24} />
          <FormCard rounded>
            <InputWithLabel label={t('accessType')}>
              <Select items={roles} value={newRole} onChange={(r) => setNewRole(r)} />
            </InputWithLabel>
          </FormCard>
        </Column>
        <Spacer size="16" />
        <PermissionSlider roles={roles} rolesPermissions={rolesPermissions} role={newRole} setRole={setNewRole} />
        <Spacer size={92} />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('sendInvitation')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('sendInvitation')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default InviteProPermissionsScreen;

import { SubscriptionInfo } from '@mero/api-sdk/dist/payments/subscription-info';
import {
  MeroHeader,
  colors,
  Column,
  H1,
  Spacer,
  FormCard,
  Button,
  Row,
  Checkbox,
  SmallBody,
  Title,
} from '@mero/components';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { PriceType } from '../../../ServicesScreen/ServiceScreen';
import { styles } from './ProEditServiceSettingsScreen.styles';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewEditServiceSettings'>;

const AddProEditServiceSettingsScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('pros');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const { serviceId } = route.params;

  const [pageState] = CurrentBusinessContext.useContext();
  const [{ services }, { updateServices }] = AddNewProContext.useContext();

  const block = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const serviceDetails = useMemo(() => {
    const service = services.find((s) => s._id === serviceId);
    if (service) {
      return {
        ...service,
        price: {
          ...service.price,
          type: PriceType[service.price.type],
        },
      };
    }

    return undefined;
  }, [serviceId]);

  const [publicService, setPublicService] = React.useState(!serviceDetails?.private);
  const [manualApproval, setManualApproval] = React.useState(!serviceDetails?.automaticApproval);
  const [subscriptionInfo, setSubscriptionInfo] = React.useState<SubscriptionInfo | undefined>();

  const shouldForceManualApproval = subscriptionInfo?.shouldForceManualApproval;

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsLoading(true);
    const workerServices = services
      .filter((s) => s._id !== serviceId)
      .map((s) => ({
        ...s,
        price: {
          ...s.price,
          type: PriceType[s.price.type],
        },
      }));

    const service = services.find((s) => s._id === serviceId);

    if (service) {
      updateServices([
        {
          ...service,
          isCustom: true,
          private: !publicService,
          automaticApproval: !manualApproval,
        },
        //@ts-expect-error @TODO Price type has a different format...
        ...workerServices,
      ]);
      goBack();
    }
  };

  // React.useEffect(() => {
  //   if (pageState.type === 'Loaded') {
  //     const worker = pageState.page.workers.find((worker) => worker._id === workerId);
  //     if (worker) {
  //       const service = worker.services.find((service) => service._id === serviceId);
  //
  //       setPublicService(!service?.private);
  //       setManualApproval(!service?.automaticApproval);
  //     }
  //     setProDetails(worker ?? null);
  //   }
  // }, [pageState.type]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const getSubscription = async () => {
        try {
          const subscription = await meroApi.payments.getPageSubscription(pageState.page.details._id);
          setSubscriptionInfo(subscription);
        } catch (err) {
          log.error('Failed to get subscription information', err);
        }
      };

      getSubscription();
    }
  }, [pageState.type]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('profileCalendar')} />
      <Column style={[styles.formMargin, styles.flex]}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('profileCalendar')}</H1>
        <Spacer size={32} />
        <FormCard dropShaddow rounded paddings="button">
          <Row style={{ paddingRight: 16 }}>
            <Column>
              <Checkbox
                value={publicService}
                color="blue"
                disabled={false}
                onValueChange={() => setPublicService(!publicService)}
              />
            </Column>
            <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={() => setPublicService(!publicService)}>
              <Title>{t('proServiceSettingsLabel1')}</Title>
              <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription1')}</SmallBody>
            </TouchableOpacity>
          </Row>
          <Row style={{ paddingTop: 24, paddingRight: 16 }}>
            <Column>
              <Checkbox
                value={manualApproval || shouldForceManualApproval}
                color="blue"
                disabled={shouldForceManualApproval}
                onValueChange={() => setManualApproval(!manualApproval)}
              />
            </Column>
            <TouchableOpacity
              style={{ paddingLeft: 12, flex: 1 }}
              onPress={() => setManualApproval(!manualApproval)}
              disabled={shouldForceManualApproval}
            >
              <Title>{t('proServiceSettingsLabel2')}</Title>
              <Column>
                <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription2')}</SmallBody>
              </Column>
            </TouchableOpacity>
          </Row>
        </FormCard>
        <Spacer size={144} />
      </Column>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('done')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('done')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProEditServiceSettingsScreen;

import { Column, colors } from '@mero/components';
import * as React from 'react';
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import log from '../../utils/log';

type Size = {
  width: number;
  height: number;
};

export type Props = {
  onEndReached: () => void | Promise<void>;
  withLoading?: boolean;
  style?: ScrollView['props']['style'];
  contentContainerStyle?: ScrollView['props']['contentContainerStyle'];
  wrapperStyle?: StyleProp<ViewStyle>;
};

const InfiniteScrollView: React.FC<React.PropsWithChildren<Props>> = ({
  onEndReached,
  children,
  withLoading = false,
  style,
  contentContainerStyle,
  wrapperStyle,
}) => {
  const [outerDims, setOuterDims] = React.useState<Size | null>(null);
  const [innerDims, setInnerDims] = React.useState<Size | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: {
    layoutMeasurement: { height: number };
    contentOffset: { y: number };
    contentSize: { height: number };
  }) => {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - 50;
  };

  const triggerOnEndReached = async () => {
    setIsLoading(withLoading);
    try {
      await onEndReached();
    } catch (e) {
      log.error('failed to load more', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (isCloseToBottom(event.nativeEvent)) {
      triggerOnEndReached();
    }
  };

  React.useEffect(() => {
    if (innerDims && outerDims) {
      if (innerDims.height < outerDims.height) {
        triggerOnEndReached();
      }
    }
  }, [innerDims, outerDims]);

  return (
    <View
      style={{ flex: 1 }}
      onLayout={(e) =>
        setOuterDims({
          width: e.nativeEvent.layout.width,
          height: e.nativeEvent.layout.height,
        })
      }
    >
      <ScrollView
        style={style}
        contentContainerStyle={contentContainerStyle}
        onScroll={onScroll}
        scrollEventThrottle={400}
      >
        <View
          onLayout={(e) =>
            setInnerDims({
              width: e.nativeEvent.layout.width,
              height: e.nativeEvent.layout.height,
            })
          }
          style={wrapperStyle}
        >
          {children}
        </View>
        {isLoading && (
          <Column style={{ paddingVertical: 20 }}>
            <ActivityIndicator animating size="large" color={colors.ATHENS_GRAY} />
          </Column>
        )}
      </ScrollView>
    </View>
  );
};

export default InfiniteScrollView;

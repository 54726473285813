import { sizes, colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerWrapperWithShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.06,
    shadowRadius: 8,
    elevation: 16,
    zIndex: 202,
    backgroundColor: '#ffffff',
    maxHeight: 91,
  },
  optionsMenuItem: {
    paddingVertical: sizes[16],
  },
  optionsMenu: {
    position: 'absolute',
    right: 0,
    top: 12,
    minWidth: 300,
    paddingHorizontal: sizes[16],
    backgroundColor: colors.WHITE,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'rgb(233, 236, 239)',
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  optionsStretchContainer: {
    flex: 1,
    minHeight: sizes[32],
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  optionsListContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  openOptionsMenuButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    backgroundColor: colors.WHITE,
    borderRadius: 25,
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  brandIcon: {
    position: 'absolute',
    width: 24,
    height: 24,
    backgroundColor: colors.WHITE,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    right: 0,
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
});

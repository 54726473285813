import { UserId } from '@mero/api-sdk/dist/users';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { Checkbox, colors, Column, H3s, Icon, Line, Spacer } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../../../../hooks/useClickOutsideWeb';

import { AuthorizationProp, meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentPageInfo } from '../../../../../../contexts/CurrentBusiness';
import log from '../../../../../../utils/log';

export type Props = {
  activeFilter?: UserId;
  onChange: (workerId?: UserId) => void;
  page: CurrentPageInfo;
  authorization: AuthorizationProp;
};

const SummaryFilters: React.FC<Props> = ({ activeFilter, onChange, page, authorization }) => {
  const { t } = useTranslation('reports');

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [workers, setWorkers] = React.useState<SavedWorker[]>([]);

  const filtersSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: filtersSelectRef,
    isVisible: isFilterOpen,
    onClickOutside() {
      setIsFilterOpen(false);
    },
  });

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        if (page.permissions.statistics.canReadAllStatistics()) {
          const [workers] = await Promise.all([meroApi.pages.getPageWorkers(page.details._id)]);

          setWorkers(workers);
          if (workers.length === 1) {
            onChange(workers[0].user._id);
          }
        } else {
          const worker = page.workers.find((w) => w.user._id === authorization.user._id);
          if (!worker) {
            return;
          }
          setWorkers([worker]);
          onChange(worker.user._id);
        }
      } catch (error) {
        log.error('Failed to get data', error);
      }
    };

    getData();
  }, [page.details._id]);

  const activeWorker = React.useMemo(
    () => workers.find((worker) => worker.user._id === activeFilter),
    [workers, activeFilter],
  );

  return (
    <Column style={{ position: 'relative' }}>
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        onPress={() => workers.length > 1 && setIsFilterOpen(!isFilterOpen)}
      >
        <Body style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>
          {activeWorker ? `${activeWorker.user.firstname} ${activeWorker.user.lastname}` : t('allPros')}
        </Body>
        <Icon type="down" color={colors.DARK_BLUE} />
      </TouchableOpacity>
      {isFilterOpen && (
        <View
          ref={filtersSelectRef}
          style={{
            position: 'absolute',
            top: 36,
            right: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 2,
          }}
        >
          <ScrollView style={{ maxHeight: 400 }}>
            <Spacer size="16" />
            <H3s>{t('pros')}</H3s>
            <Spacer size="16" />
            <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={flow(closeFilter, () => onChange())}>
              <Checkbox
                color="blue"
                disabled={false}
                value={activeFilter === undefined}
                onValueChange={flow(closeFilter, () => onChange())}
              />
              <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('allPros')}</Body>
            </TouchableOpacity>
            {workers.map((worker) => (
              <>
                <Spacer size="16" />
                <Line />
                <Spacer size="16" />
                <TouchableOpacity
                  style={{ flex: 2, flexDirection: 'row' }}
                  onPress={flow(closeFilter, () => onChange(worker.user._id))}
                >
                  <Checkbox
                    color="blue"
                    disabled={false}
                    value={activeFilter === worker.user._id}
                    onValueChange={flow(closeFilter, () => onChange(worker.user._id))}
                  />
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>
                    {worker.user.firstname} {worker.user.lastname}
                  </Body>
                </TouchableOpacity>
              </>
            ))}
            <Spacer size="16" />
          </ScrollView>
        </View>
      )}
    </Column>
  );
};

export default SummaryFilters;

import { OtherProAppointmentNotificationSettings, OwnAppointmentNotificationSettings } from '@mero/api-sdk';
import {
  MeroHeader,
  colors,
  Spacer,
  H1,
  Body,
  FormCard,
  H3s,
  Line,
  Row,
  Checkbox,
  Column,
  styles as meroStyles,
  Button,
  SafeAreaView,
  useToast,
  useShowError,
  SmallBody,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../components/ModalScreenContainer';

import useGoBack from '../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../../contexts/CurrentBusiness';
import log from '../../../../utils/log';

type PropsSetting = {
  title: string;
  subtitle?: string;
  value: boolean;
  showLine: boolean;
  onChange: (checked: boolean) => void;
};

type OwnSettings = Omit<OwnAppointmentNotificationSettings, 'clientAddedToOwnWaitingList'> & {
  clientAddedToOwnWaitingList: boolean;
};

type OtherSettings = Omit<OtherProAppointmentNotificationSettings, 'clientAddedToWaitingList'> & {
  clientAddedToWaitingList: boolean;
};

const Setting: React.FC<PropsSetting> = ({ value, title, subtitle, onChange, showLine }) => {
  return (
    <TouchableOpacity onPress={() => onChange(!value)} style={{ paddingHorizontal: 24 }}>
      <Row style={{ marginVertical: 16 }}>
        <Checkbox value={value} onValueChange={() => onChange(!value)} />
        <Column style={{ flex: 1, marginLeft: 16 }}>
          <Body style={meroStyles.text.semibold}>{title}</Body>

          {subtitle && <SmallBody style={{ marginTop: 8 }}>{subtitle}</SmallBody>}
        </Column>
      </Row>

      {showLine && <Line />}
    </TouchableOpacity>
  );
};

type Props = CurrentBusinessProps & {
  //pass
};

const NotificationsOptionsScreen: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('notificationOptions');
  const toast = useToast();
  const showError = useShowError();
  const [isLoading, setIsLoading] = React.useState(true);
  const goBack = useGoBack();

  const [ownSettings, setOwnSettings] = React.useState<OwnSettings | null>(null);
  const [otherSettings, setOtherSettings] = React.useState<OtherSettings | null>(null);

  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();

  const hasOtherProsAppointmentAccess = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.notifications.canReceiveOtherProsAppointmentAction();
    }
    return false;
  }, [pageState.type]);

  React.useEffect(() => {
    const loadSettings = async () => {
      try {
        setIsLoading(false);
        const result = await meroApi.notifications.getProNotificationSettings({ pageId: page.details._id });

        setOwnSettings({
          ...result.ownAppointmentNotificationSettings,
          ...(result.ownAppointmentNotificationSettings.clientAddedToOwnWaitingList === undefined
            ? { clientAddedToOwnWaitingList: true }
            : { clientAddedToOwnWaitingList: result.ownAppointmentNotificationSettings.clientAddedToOwnWaitingList }),
        });
        setOtherSettings({
          ...result.otherProAppointmentNotificationSettings,
          ...(result.otherProAppointmentNotificationSettings.clientAddedToWaitingList === undefined
            ? { clientAddedToWaitingList: true }
            : { clientAddedToWaitingList: result.otherProAppointmentNotificationSettings.clientAddedToWaitingList }),
        });
      } catch (error) {
        setOwnSettings(null);
        setOtherSettings(null);
      }
    };
    loadSettings();
  }, []);

  const save = async () => {
    if (!ownSettings || !otherSettings) {
      return;
    }
    try {
      await meroApi.notifications.updateProNotificationSettings({
        pageId: page.details._id,
        otherProAppointmentNotificationSettings: otherSettings,
        ownAppointmentNotificationSettings: ownSettings,
      });
      toast.show({ type: 'success', text: t('changesSaved') });
    } catch (error) {
      log.error('Error while updating notifications', error);
      showError(error, t('saveChangesError'));
    }
  };

  const onChangeOtherSettings = (newSettings: { [setting: string]: boolean }) => {
    setOtherSettings((prev) => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        ...newSettings,
      };
    });
  };

  const onChangeOwnSettings = (newSettings: { [setting: string]: boolean }) => {
    setOwnSettings((prev) => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        ...newSettings,
      };
    });
  };

  if (pageState.type !== 'Loaded') {
    return <></>;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER, flex: 1 }}>
      <MeroHeader title={t('ownOptions')} canGoBack={true} onBack={goBack} />
      <ScrollView style={{ paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
        <Spacer size={24} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('title')}</H1>

        <Spacer size={16} />
        <Body style={{ paddingHorizontal: 8 }}>{t('description')}</Body>

        {ownSettings && otherSettings && (
          <>
            <Spacer size="32" />
            <FormCard rounded dropShaddow paddings="none">
              <H3s style={{ paddingVertical: 16, paddingHorizontal: 24 }}>{t('ownCalendar')}</H3s>
              <Line />

              {pageState.page.permissions.ownNotificationsSettings.canManageOwnAppointments() && (
                <Setting
                  value={ownSettings.ownAppointmentAddedByClient && ownSettings.ownAppointmentCancelledByClient}
                  title={t('clientChangesAppointment')}
                  subtitle={t('clientChangesAppointmentOwnCalendar')}
                  onChange={(checked: boolean) => {
                    onChangeOwnSettings({
                      ownAppointmentAddedByClient: checked,
                      ownAppointmentCancelledByClient: checked,
                    });
                  }}
                  showLine={true}
                />
              )}

              {pageState.page.permissions.ownNotificationsSettings.canManageOwnAppointments() && (
                <Setting
                  value={
                    ownSettings.ownAppointmentAddedByAnotherPro &&
                    ownSettings.ownAppointmentCancelledByAnotherPro &&
                    ownSettings.ownAppointmentModifiedByAnotherPro
                  }
                  title={t('proChangesAppointment')}
                  subtitle={t('proChangesAppointmentOwnCalendar')}
                  onChange={(checked: boolean) => {
                    onChangeOwnSettings({
                      ownAppointmentAddedByAnotherPro: checked,
                      ownAppointmentCancelledByAnotherPro: checked,
                      ownAppointmentModifiedByAnotherPro: checked,
                    });
                  }}
                  showLine={true}
                />
              )}

              {pageState.page.permissions.ownNotificationsSettings.canManageSmsReminder() && (
                <Setting
                  value={ownSettings.manualSmsReminder}
                  title={t('smsReminder')}
                  onChange={(checked: boolean) =>
                    onChangeOwnSettings({
                      manualSmsReminder: checked,
                    })
                  }
                  showLine={true}
                />
              )}

              {pageState.page.permissions.ownNotificationsSettings.canManageOwnWaitingList() && (
                <Setting
                  value={ownSettings.clientAddedToOwnWaitingList}
                  title={t('clientAddedToOwnWaitingList')}
                  onChange={(checked: boolean) => {
                    onChangeOwnSettings({
                      clientAddedToOwnWaitingList: checked,
                    });
                  }}
                  showLine={false}
                />
              )}
              <Spacer size={16} />
            </FormCard>

            {hasOtherProsAppointmentAccess && (
              <>
                <Spacer size={24} />
                <FormCard rounded dropShaddow paddings="none">
                  <H3s style={{ paddingVertical: 16, paddingHorizontal: 24 }}>{t('otherCalendars')}</H3s>
                  <Line />

                  {pageState.page.permissions.ownNotificationsSettings.canManageOthersAppointments() && (
                    <Setting
                      value={otherSettings.appointmentCancelledByClient && otherSettings.appointmentMadeByClient}
                      title={t('clientChangesAppointment')}
                      subtitle={t('clientChangesAppointmentOtherCalendar')}
                      onChange={(checked: boolean) => {
                        onChangeOtherSettings({
                          appointmentCancelledByClient: checked,
                          appointmentMadeByClient: checked,
                        });
                      }}
                      showLine={true}
                    />
                  )}

                  {pageState.page.permissions.ownNotificationsSettings.canManageOthersAppointments() && (
                    <Setting
                      value={
                        otherSettings.appointmentCancelledByAnotherPro &&
                        otherSettings.appointmentMadeByAnotherPro &&
                        otherSettings.appointmentModifiedByAnotherPro
                      }
                      title={t('proChangesAppointment')}
                      subtitle={t('proChangesAppointmentOtherCalendar')}
                      onChange={(checked: boolean) => {
                        onChangeOtherSettings({
                          appointmentCancelledByAnotherPro: checked,
                          appointmentMadeByAnotherPro: checked,
                          appointmentModifiedByAnotherPro: checked,
                        });
                      }}
                      showLine={true}
                    />
                  )}

                  {pageState.page.permissions.ownNotificationsSettings.canManageFulfillRequest() && (
                    <Setting
                      value={otherSettings.appointmentRequestFulfilledByAnotherPro}
                      title={t('proFulfillsRequest')}
                      subtitle={t('proFulfillsRequestDescription')}
                      onChange={(checked: boolean) =>
                        onChangeOtherSettings({
                          appointmentRequestFulfilledByAnotherPro: checked,
                        })
                      }
                      showLine={true}
                    />
                  )}

                  {pageState.page.permissions.ownNotificationsSettings.canManageOthersWaitingList() && (
                    <Setting
                      value={otherSettings.clientAddedToWaitingList}
                      subtitle={t('clientAddedToWaitingListDescription')}
                      title={t('clientAddedToWaitingList')}
                      onChange={(checked: boolean) => {
                        onChangeOtherSettings({
                          clientAddedToWaitingList: checked,
                        });
                      }}
                      showLine={false}
                    />
                  )}
                  <Spacer size={16} />
                </FormCard>
              </>
            )}
          </>
        )}
        <Spacer size={144} />
      </ScrollView>

      <FormCard dropShaddow paddings="button" style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              disabled={isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center', justifyContent: 'flex-end' }}
              text={t('saveChanges')}
              onClick={save}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default CurrentBusiness(NotificationsOptionsScreen);

import { SavedPageBillingDetails } from '@mero/api-sdk';
import { colors, Column, FormCard, MeroHeader, Spacer, Title, Line, useShowError, useToast } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import H1 from '@mero/components/lib/components/Text/H1';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import {
  BillingDetailsContext,
  BillingDetailsProps,
  withBillingDetails,
} from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { PageOnlinePaymentsStackParamList } from '../../../../../types';
import { logCatch } from '../../../../../utils/log';
import BillingDetailItem from '../PageSubscriptionSettingsScreen/BillingDetailItem';
import { styles } from './PageBillingDetailsList.style';

type Props = StackScreenProps<PageOnlinePaymentsStackParamList, 'BillingDetailsList'> &
  CurrentBusinessProps &
  BillingDetailsProps & {
    // pass
  };

const PageBillingDetailsList: React.FC<Props> = ({ navigation, billingDetails, page }) => {
  const { t } = useTranslation('onlinePayments');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const toast = useToast();

  const [, { reloadAsync }] = BillingDetailsContext.useContext();

  const [isLoading, setIsLoading] = React.useState(false);

  const activeSelectedBillingDetails = billingDetails.find((b) => b.usage?.includes('UserPayments'));

  const goToBillingDetails = React.useCallback(
    (billingDetails: SavedPageBillingDetails) => {
      navigation.navigate('BillingDetails', {
        billingId: billingDetails._id,
      });
    },
    [navigation],
  );

  const [selectedBillingDetails, setSelectedBillingDetails] = React.useState(activeSelectedBillingDetails);

  const addNewBillingDetails = React.useCallback(() => {
    navigation.navigate('BillingDetails', { billingId: undefined });
  }, []);

  const saveChanges = async () => {
    if (!selectedBillingDetails) {
      return;
    }
    try {
      setIsLoading(true);
      await meroApi.pro.pageBillingDetails
        .setPageUserPaymentsBilling({
          pageId: selectedBillingDetails.pageId,
          billingDetailsId: selectedBillingDetails._id,
        })
        .catch(logCatch('setPageUserPaymentsBilling'));
      reloadAsync({ pageId: page.details._id });

      const redirectUri =
        Platform.OS === 'web'
          ? `https://${window.location.host}/page/current/online-payments`
          : config.stripeRedirectUri.onlinePayments;

      const oauth = await meroApi.payments.getStripeConnectOauthLink({
        pageId: page.details._id,
        redirectUri,
      });

      if (Platform.OS === 'web') {
        window.location.href = oauth.result;
      } else {
        navigation.navigate('PageOnlinePaymentsStripe', { oauthUrl: oauth.result, title: t('stripe') });
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !selectedBillingDetails || isLoading;

  return (
    <>
      <ModalScreenContainer>
        <MeroHeader
          canGoBack={true}
          onBack={goBack}
          title={t('billingDetails')}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <ScrollView style={{ paddingHorizontal: 16, paddingBottom: 96 }}>
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 8 }}>{t('billingDetails')}</H1>
          <Spacer size={32} />
          {billingDetails.length > 0 && (
            <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 8, zIndex: 1 }}>
              {billingDetails.map((billingDetail, index) => (
                <React.Fragment key={billingDetail._id}>
                  <Column style={{ paddingVertical: 16, paddingHorizontal: 16, zIndex: billingDetails.length - index }}>
                    <BillingDetailItem
                      isSelected={billingDetail._id === selectedBillingDetails?._id}
                      billingDetail={billingDetail}
                      onPress={setSelectedBillingDetails}
                      onEdit={goToBillingDetails}
                    />
                  </Column>
                  {index < billingDetails.length - 1 && <Line />}
                </React.Fragment>
              ))}
            </FormCard>
          )}
          <Spacer size={billingDetails.length > 0 ? 32 : 10} />
          <Button
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            text={t('addNewBillingDetails')}
            onPress={addNewBillingDetails}
          />
        </ScrollView>
        {billingDetails.length > 0 && (
          <FormCard
            dropShaddow
            paddings="button"
            style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
          >
            <SafeAreaView edges={['bottom']}>
              <Button text={t('done')} onPress={saveChanges} disabled={isDisabled} />
            </SafeAreaView>
          </FormCard>
        )}
      </ModalScreenContainer>
    </>
  );
};

export default pipe(PageBillingDetailsList, withBillingDetails, CurrentBusiness);

import { Invoice } from '@mero/api-sdk/dist/payments/invoice';
import { Column, Row, SmallBody, H2s, Label, Icon, sizes } from '@mero/components';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable } from 'react-native';

import { images } from '../../constants/images';

import styles from './styles';

interface InvoiceItemProps {
  invoice: Invoice;
  onPress: () => void;
}

const InvoiceItem: React.FC<InvoiceItemProps> = ({ invoice, onPress }) => {
  const { t } = useTranslation('subscription');

  return (
    <Pressable style={styles.invoiceButton} onPress={onPress}>
      <Row flex={1} style={styles.contentWrapper}>
        <Column style={styles.invoiceIconWrapper}>
          <Icon type="file" size={32} />
        </Column>
        <Column flex={1}>
          <Label style={styles.label}>{t('paid')}</Label>
          <H2s style={styles.text} numberOfLines={1} lineBreakMode="tail">
            {t('invoice')} {invoice.tracking.number}
          </H2s>
          <SmallBody numberOfLines={1} lineBreakMode="tail" style={styles.date}>
            {t('emitedIn')} {DateTime.fromJSDate(invoice.createdAt).toFormat('dd.LL.yyyy')}
          </SmallBody>
        </Column>
      </Row>
      <Icon type="arrow-right" />
    </Pressable>
  );
};

export default InvoiceItem;

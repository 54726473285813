import { SavedPageBillingDetails } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { isCompany } from '@mero/api-sdk/dist/pro/pageBillingDetails/pageBillingDetails/any';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import SplashScreen from '../../screens/SplashScreen';

import log, { logCatch } from '../../utils/log';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

type BillingType = SavedPageBillingDetails['billing']['type'];

export type BillingDetailsContextState = Readonly<
  | {
      type: 'New';
      billingDetails: SavedPageBillingDetails[];
      billingType?: BillingType;
    }
  | {
      type: 'Loading';
      billingDetails: SavedPageBillingDetails[];
      tempSelectedBillingDetails?: SavedPageBillingDetails;
      billingType?: BillingType;
    }
  | {
      type: 'Loaded';
      billingDetails: SavedPageBillingDetails[];
      tempSelectedBillingDetails?: SavedPageBillingDetails;
      billingType?: BillingType;
    }
  | {
      type: 'Failed';
      billingDetails: SavedPageBillingDetails[];
      tempSelectedBillingDetails?: SavedPageBillingDetails;
      billingType?: BillingType;
      error: unknown;
    }
>;

const defaultState = (): BillingDetailsContextState => ({
  type: 'New',
  billingDetails: [],
});

export const BillingDetailsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        billingDetails: SavedPageBillingDetails[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          ...state,
          type: 'Loaded',
          billingDetails: result.billingDetails,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setResult: (
      state,
      result: {
        billingDetails: SavedPageBillingDetails[];
      },
    ) => {
      return {
        ...state,
        billingDetails: result.billingDetails,
      };
    },
    setFailed: (
      state,
      payload: {
        error: unknown;
        billingDetails: SavedPageBillingDetails[];
      },
    ) => {
      return {
        ...state,
        type: 'Failed',
        billingDetails: payload.billingDetails,
        error: payload.error,
      };
    },
    mutate: (s, fn: (s: BillingDetailsContextState) => BillingDetailsContextState): BillingDetailsContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({
      pageId,
      silent = false,
      type,
    }: {
      pageId: PageId;
      silent?: boolean;
      type?: BillingType;
    }) => {
      try {
        log.debug('Start reloading BillingDetails');

        const [billingDetails] = await Promise.all([
          meroApi.pro.pageBillingDetails
            .listPageBillingDetails(pageId)
            .then((r) => (type ? r.filter((b) => b.billing.type === type) : r))
            .catch(logCatch('listPageBillingDetails')),
        ]);

        silent
          ? dispatch.setResult({
              billingDetails,
            })
          : dispatch.trySetResult({
              billingDetails,
            });
      } catch (error) {
        dispatch.setFailed({
          error: error,
          billingDetails: defaultState().billingDetails,
        });
        log.exception(error);
      }
    };

    return {
      reloadAsync: async (payload: { pageId: PageId }): Promise<void> => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({ silent: true, ...payload, type: state.billingType }).catch(log.exception);
            return state;
          }

          return state;
        });
      },
      reload: (payload: { pageId: PageId }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({
              ...payload,
              type: state.billingType,
            }).catch(log.exception);

            return {
              ...state,
              type: 'Loading',
              billingDetails: state.billingDetails,
            };
          }

          return state;
        });
      },
      setTempSelectedBillingDetails: (tempSelectedBillingDetails: SavedPageBillingDetails) => {
        dispatch.mutate((state) => {
          return {
            ...state,
            tempSelectedBillingDetails,
          };
        });
      },
      setBillingType: (billingType: BillingType) => {
        dispatch.mutate((state) => {
          return {
            ...state,
            billingType,
          };
        });
      },
      removeTempSelectedBillingDetails: () => {
        dispatch.mutate((state) => {
          return {
            ...state,
            tempSelectedBillingDetails: undefined,
          };
        });
      },
    };
  },
);

export type BillingDetailsProps = {
  billingDetails: SavedPageBillingDetails[];
  tempSelectedBillingDetails?: SavedPageBillingDetails;
};

type PropsWithBillingDetails<P> = P & BillingDetailsProps;

export function withBillingDetails<P extends BillingDetailsProps>(
  Component: React.ComponentType<P>,
  FailedComponent: React.ComponentType<P> = () => null,
): React.FunctionComponent<Omit<P, keyof BillingDetailsProps>> {
  return function BillingDetailsContextComponent(props) {
    const [state] = BillingDetailsContext.useContext();

    switch (state.type) {
      case 'New': {
        return <SplashScreen />;
      }
      case 'Loading': {
        return <SplashScreen />;
      }
      case 'Loaded': {
        // FIXME: find a type safe way to exclude a property for generic type argument
        const billingDetails: SavedPageBillingDetails[] = state.billingDetails;
        const tempSelectedBillingDetails = state.tempSelectedBillingDetails;

        // @ts-expect-error
        const allProps: PropsWithBillingDetails<P> = {
          ...props,
          billingDetails,
          tempSelectedBillingDetails,
        };

        return <Component {...allProps} />;
      }
      case 'Failed': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <FailedComponent {...allProps} />;
      }
    }
  };
}

const ContextInit: React.FC<
  React.PropsWithChildren<{
    type?: BillingType;
  }>
> = ({ type, children }) => {
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { reload, setBillingType }] = BillingDetailsContext.useContext();

  const pageId = currentBusinessState.type === 'Loaded' ? currentBusinessState.page.details._id : undefined;

  React.useEffect(() => {
    if (type) {
      setBillingType(type);
    }
    if (pageId) {
      reload({ pageId });
    }
  }, [pageId]);

  return <>{children}</>;
};

export const withBillingDetailsContextProvider =
  <P extends object>(type?: BillingType) =>
  (Content: React.ComponentType<P>): React.FC<P> => {
    return function WithBillingDetailsContextProvider(props: P) {
      return (
        <BillingDetailsContext.Provider>
          <ContextInit type={type}>
            <Content {...props} />
          </ContextInit>
        </BillingDetailsContext.Provider>
      );
    };
  };

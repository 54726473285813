import { ClientId } from '@mero/api-sdk';
import { HasOptionalFirstLastName } from '@mero/api-sdk/dist/common';
import { WorkerService } from '@mero/api-sdk/dist/services';
import {
  colors,
  formatDurationInMinutes,
  formatPriceText,
  HSpacer,
  Icon,
  Line,
  SimpleListItem,
  Label,
  sizes,
  SmallBody,
  Spacer,
  Row,
  styles as meroStyles,
} from '@mero/components';
import { formatRangeDuration } from '@mero/shared-components';
import * as t from 'io-ts';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Svg, { SvgProps, Path, G, Circle } from 'react-native-svg';

import Column from '@mero/components/lib/components/Layout/Column';
import Body from '@mero/components/lib/components/Text/Body';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { BookedServicePreview } from '../../contexts/BookingFormContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../types';
import { MembershipServices } from '../BookingDetailsScreen';

export type IconType = 'arrow' | 'remove';
type IconProps = {
  readonly type: IconType;
};

const MembershipIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.359" />
      <Circle cx={1.598} cy={1.598} r={1.598} data-name="layer1" transform="translate(3.732 3.834)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

const ItemIcon: React.FC<IconProps> = ({ type }) => {
  switch (type) {
    case 'arrow': {
      return <Icon type="next" size={24} color={colors.DARK_BLUE} disabled />;
    }
    case 'remove': {
      return <Icon type="close" size={24} color={colors.DARK_BLUE} disabled />;
    }
  }
};

export interface BookedServiceWithWorkerItem {
  readonly type: 'valid' | 'invalid';
  readonly service: BookedServicePreview;
  readonly worker?: HasOptionalFirstLastName;
  readonly workerServices: WorkerService[];
  readonly startTime?: Date;
}

export type BookedServiceWithWorkerListItemProps = BookedServiceWithWorkerItem & {
  readonly onPress?: () => void;
  readonly hasLine?: boolean;
  readonly icon?: IconType;
  readonly onIconPress?: () => void;
  readonly timeZone: Zone;
  readonly memberships: MembershipServices[];
  readonly hasBorder?: boolean;
  readonly clientId?: ClientId;
  readonly style?: {
    container?: StyleProp<ViewStyle>;
    simpleListItem?: StyleProp<ViewStyle>;
    warningLabel?: StyleProp<TextStyle>;
    memberships?: StyleProp<ViewStyle>;
  };
};

const Container: React.FC<
  React.PropsWithChildren<
    Pick<BookedServiceWithWorkerListItemProps, 'onPress' | 'hasBorder'> & { style: StyleProp<ViewStyle> }
  >
> = ({ onPress, hasBorder, style, children }) => {
  if (onPress) {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          hasBorder
            ? {
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
                paddingRight: 0,
                overflow: 'hidden',
              }
            : undefined,
          style,
        ]}
      >
        {children}
      </TouchableOpacity>
    );
  } else {
    return (
      <View
        style={[
          hasBorder
            ? {
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
                paddingRight: 0,
                overflow: 'hidden',
              }
            : undefined,
          style,
        ]}
      >
        {children}
      </View>
    );
  }
};

const BookedServiceWithWorkerListItem: React.FC<BookedServiceWithWorkerListItemProps> = ({
  type,
  service,
  worker,
  workerServices,
  startTime,
  onPress,
  hasLine,
  hasBorder,
  icon,
  onIconPress,
  timeZone,
  memberships,
  style,
  clientId,
}: BookedServiceWithWorkerListItemProps) => {
  const { t: tr } = useTranslation('booking');
  const priceStr = formatPriceText(service.price);
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const [businessState] = CurrentBusinessContext.useContext();

  const startDateTime = React.useMemo(
    () => (startTime ? DateTime.fromJSDate(startTime, { zone: timeZone }) : undefined),
    [startTime, timeZone],
  );

  const warningLabel = React.useMemo(() => {
    if (!worker) {
      return undefined;
    }

    const isWorkerService = workerServices.some((ws) => ws._id === service._id);
    const isPageService =
      businessState.type === 'Loaded' && businessState.page.details.services.some((s) => s._id === service._id);

    if (isWorkerService && !isPageService) {
      return tr('notProvidedByPage');
    }

    if (!isWorkerService && !isPageService) {
      return tr('noLongerAvailable');
    }

    if (!isWorkerService && isPageService) {
      return tr('notProvidedBySelectedPro');
    }

    return undefined;
  }, [businessState, type, workerServices]);

  return (
    <Container onPress={onPress} hasBorder={hasBorder} style={style?.container}>
      <SimpleListItem
        title={service.name}
        subtitle={`${startDateTime !== undefined ? `${startDateTime.toFormat('H:mm', { locale: 'ro' })} -` : ''} ${
          t.number.is(service.durationInMinutes)
            ? formatDurationInMinutes(service.durationInMinutes)
            : formatRangeDuration(service.durationInMinutes.from, service.durationInMinutes.to)
        }${
          type === 'valid'
            ? worker?.firstname || worker?.lastname
              ? ` cu ${worker?.firstname ?? ''} ${worker?.lastname ?? ''}`
              : ''
            : ''
        }`}
        iconPosition="right"
        IconComponent={() => (
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
            }}
          >
            {priceStr !== undefined ? (
              service.price.type === 'fixed' && service.price?.promo ? (
                <Column>
                  <Body style={{ textAlign: 'right' }}>{service.price.promo} lei</Body>
                  <SmallBody
                    style={{
                      textDecorationLine: 'line-through',
                      textDecorationStyle: 'solid',
                      color: colors.COMET,
                      textAlign: 'right',
                    }}
                  >
                    ({service.price.fixed} lei)
                  </SmallBody>
                </Column>
              ) : (
                <Body>{priceStr}</Body>
              )
            ) : null}
            {icon !== undefined ? (
              <>
                <HSpacer left={8} />
                <TouchableOpacity
                  onPress={onIconPress}
                  disabled={onIconPress === undefined}
                  style={{ paddingHorizontal: 8 }}
                >
                  <ItemIcon type={icon} />
                </TouchableOpacity>
              </>
            ) : null}
          </View>
        )}
        alignContent="middle"
        style={style?.simpleListItem}
        paddingBottom={8}
      />
      {Boolean(warningLabel) ? (
        <>
          <Spacer size={8} />
          <SmallBody
            style={[
              {
                fontSize: 12,
                paddingBottom: 8,
              },
              style?.warningLabel,
            ]}
          >
            * {warningLabel?.toLowerCase()}
          </SmallBody>
        </>
      ) : (
        <>
          <Column flexWrap="wrap" style={style?.memberships}>
            {memberships.map((membership) => {
              const membershipItem = membership.items.find((i) => i._id === service._id);
              return (
                <TouchableOpacity
                  key={membership._id}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: 4,
                    paddingRight: 8,
                    backgroundColor: colors.ATHENS_GRAY,
                    borderRadius: 4,
                    marginRight: 8,
                    marginTop: 8,
                    alignSelf: 'flex-start',
                  }}
                  disabled={businessState.type !== 'Loaded' || !clientId}
                  onPress={() => {
                    if (clientId && businessState.type === 'Loaded') {
                      navigation.navigate('ClientDetails', {
                        screen: 'MembershipDetailsScreen',
                        params: {
                          pageId: businessState.page.details._id,
                          clientId,
                          membershipPurchaseId: membership._id,
                          readonly: true,
                        },
                      });
                    }
                  }}
                >
                  <MembershipIcon />
                  <HSpacer left={sizes[4]} />
                  <Label style={[meroStyles.text.semibold, { color: colors.COMET }]}>
                    {membership.name}
                    {membershipItem && membershipItem.quantity !== Infinity
                      ? ` (${tr('membershipLeft', { count: membershipItem.quantity })})`
                      : ` (${tr('membershipLeftUnlimited')})`}
                  </Label>
                </TouchableOpacity>
              );
            })}
          </Column>
          <Spacer size={memberships.length > 0 ? 4 : 8} />
        </>
      )}
      <Spacer size={8} />
      {hasLine ? <Line /> : null}
    </Container>
  );
};

export default BookedServiceWithWorkerListItem;

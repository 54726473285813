import { DefinedString } from '@mero/api-sdk';
import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { AvoidKeyboard, Body, ConfirmBox, H1, ModalOverlay, Spacer, TypeSafeTextInput } from '@mero/components';
import * as React from 'react';
import { ScrollView } from 'react-native';

type Props = {
  readonly note: ClientNoteHistoryRecord;
  readonly onDismiss: () => void;
  readonly onSave: (note: ClientNoteHistoryRecord, newText: DefinedString) => void;
};

const ClientNoteEditModal: React.FC<Props> = ({ note, onDismiss, onSave }) => {
  const [newText, setNewText] = React.useState({
    input: note.payload.text,
    decoded: DefinedString.decode(note.payload.text),
  });

  const decoded = newText.decoded;
  const isValid = decoded._tag === 'Right';

  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: 'Salvează',
    onPress: isValid
      ? () => {
          onSave(note, decoded.right);
        }
      : undefined,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <AvoidKeyboard>
        <ScrollView
          contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          keyboardShouldPersistTaps="handled"
        >
          <ConfirmBox
            type="info"
            headerTitle="Modifică notiță"
            canClose={true}
            onClose={onDismiss}
            leftAction={leftAction}
            rightAction={rightAction}
          >
            <H1>Modifică notiță</H1>
            <Spacer size="8" />
            <Body>Notița va fi vizibilă doar intern, pentru profesioniști</Body>
            <Spacer size="16" />
            <TypeSafeTextInput
              codec={DefinedString}
              value={newText.input}
              onChange={setNewText}
              placeholder="Introdu notița"
              multiline
              numberOfLines={3}
            />
          </ConfirmBox>
        </ScrollView>
      </AvoidKeyboard>
    </ModalOverlay>
  );
};

export default ClientNoteEditModal;

import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { RecaptchaRefProps } from '.';
import config from '../../../../config';
import { RecaptchaWebImpl, Props } from './recaptchaWeb';

export const Recaptcha = React.forwardRef<RecaptchaRefProps, Props>(function RecaptchaWebProviderImpl(
  props: Props,
  ref,
) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.google.recaptchaSiteKey}>
      <RecaptchaWebImpl ref={ref} {...props} />
    </GoogleReCaptchaProvider>
  );
});

import { ServiceId } from '@mero/api-sdk/dist/services';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  serviceId: ServiceId;
};

const DeleteServiceDialog: React.FC<Props> = ({ onSuccess, onCancel, serviceId }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const showError = useShowError();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const cancelDeleteService = () => {
    onCancel();
  };

  const deleteService = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.pages.deletePageService({
          pageId: pageState.page.details._id,
          serviceId: serviceId,
        });
        onSuccess();
      }
    } catch (error) {
      showError(error);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: 'Anulează',
    onPress: cancelDeleteService,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: 'Șterge serviciul',
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : deleteService,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle="Actiune ireversibilă"
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancelDeleteService}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>Confirmă ștergere serviciu</H1>
          <Spacer size="8" />
          <Body>Bifează și confirmă ștergerea</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>Toate detalile serviciului vor fi șterse, programările existente nu vor fi afectate</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>Serviciul nu va mai fi vizibil pe profilul online al locației</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>Serviciul nu va mai fi disponibil pentru programări pentru niciunul din profesioniști</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteServiceDialog;

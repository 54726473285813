import { colors, Column } from '@mero/components';
import * as React from 'react';
import { FlatList, Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './AddServiceMenu.styles';

export const SERVICE_COLORS = ['#C9E6CA', '#FDCBBB', '#FEF9C4', '#B3E0DB', '#BCDFFB', '#F8BCD0', '#E1BDE7'];

export type Props = {
  onSave: (color: string) => void;
  onDismiss: () => void;
  value: string;
};

const ColorMenu: React.FC<Props> = ({ onSave, onDismiss, value }: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <FlatList
            style={{ paddingVertical: 12 }}
            columnWrapperStyle={{
              paddingHorizontal: 4,
            }}
            numColumns={2}
            bounces={false}
            data={SERVICE_COLORS}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={{
                  height: 43,
                  width: '50%',
                  paddingHorizontal: 4,
                  paddingVertical: 4,
                  marginVertical: 4,
                  borderWidth: 1,
                  borderRadius: 4,
                  borderColor: item === value ? colors.GEYSER : colors.WHITE,
                }}
                onPress={() => onSave(item)}
              >
                <Column style={{ height: 34, backgroundColor: item }} />
              </TouchableOpacity>
            )}
          />
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ColorMenu;

import { DefinedTrimedString, PageId } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  Column,
  HSpacer,
  Row,
  SmallBody,
  Spacer,
  styles,
  TypeSafeTextInput,
  useShowError,
  useToast,
} from '@mero/components';
import { isRight } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';
import KeyboardAvoidingView from '../KeyboardAvoidingView';

type Props = AuthorizedProps & {
  pageId: PageId;
};

const CloseIcon = () => (
  <Svg width="18" height="18" viewBox="0 0 18 18">
    <Path d="M0,0H18V18H0Z" fill="none" />
    <Path
      d="M16,6.108,14.892,5,10.5,9.392,6.108,5,5,6.108,9.392,10.5,5,14.892,6.108,16,10.5,11.608,14.892,16,16,14.892,11.608,10.5Z"
      transform="translate(-1 -1)"
      fill="#fff"
    />
  </Svg>
);

const AppSatisfactionSurvey: React.FC<Props> = ({ pageId, authorization }) => {
  const { t } = useTranslation('proUserSatisfaction');
  const { isDesktop } = useMediaQueries();
  const screenWidth = Dimensions.get('screen').width;
  const isSmallPhone = screenWidth <= 375;

  const toast = useToast();
  const showError = useShowError();

  const [showModal, setShowModal] = React.useState(false);
  const [step, setStep] = useState<1 | 2>(1);
  const [grade, setGrade] = React.useState<number>();
  const [feedback, setFeedback] = React.useState({
    input: '',
    decoded: DefinedTrimedString.decode(''),
  });
  const [showErrors, setShowErrors] = React.useState(false);

  React.useEffect(() => {
    const init = async () => {
      if (authorization.isImpersonated) {
        return;
      }
      const response = await meroApi.pro.userSatisfaction.canUserRespond(pageId);
      setShowModal(response.allowed);
    };
    init();
  }, []);

  const addResponse = async () => {
    if (!grade) {
      return;
    }

    // Validate feedback if it exists
    let validatedFeedback: DefinedTrimedString | undefined;
    if (feedback.input !== '') {
      if (isRight(feedback.decoded) && feedback.input.length < 1000) {
        validatedFeedback = feedback.decoded.right;
      } else {
        return setShowErrors(true);
      }
    }

    try {
      await meroApi.pro.userSatisfaction.addResponse({
        pageId: pageId,
        response: {
          grade: grade,
          feedback: validatedFeedback,
        },
      });
      toast.show({
        type: 'success',
        text: t('thankYou'),
      });
    } catch (e) {
      log.error('Error while posting feedback', e);
      return showError(t('errorUnknown'));
    }
    setShowModal(false);
  };

  const skipResponse = async () => {
    try {
      await meroApi.pro.userSatisfaction.addResponse({
        pageId: pageId,
        response: grade !== undefined ? { grade: grade, feedback: undefined } : undefined,
      });
    } catch (e) {
      log.error('Error while posting feedback', e);
      return showError(t('errorUnknown'));
    }
    setShowModal(false);
  };

  return (
    <Modal animationType="slide" transparent={true} visible={showModal}>
      <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
        <Column
          style={{
            width: '100%',
            maxWidth: isDesktop ? 661 : 450,
            paddingHorizontal: isSmallPhone ? 16 : 24,
            paddingVertical: isSmallPhone ? 16 : 20,
            backgroundColor: colors.COMET,
          }}
        >
          <Row>
            <Body style={{ color: colors.WHITE, paddingRight: 10, flex: 1 }}>
              {step === 1 ? t('scoreQuestion') : t('feedbackQuestion')}
            </Body>
            <TouchableOpacity onPress={skipResponse} style={{ paddingTop: 2 }}>
              <CloseIcon />
            </TouchableOpacity>
          </Row>

          {step === 1 ? (
            <>
              <Spacer size={26} />
              <Row style={{ justifyContent: 'space-between' }}>
                <SmallBody
                  style={[styles.text.semibold, { textTransform: 'uppercase', color: colors.WHITE, fontSize: 12 }]}
                >
                  {t('lowProbability')}
                </SmallBody>
                <SmallBody
                  style={[styles.text.semibold, { textTransform: 'uppercase', color: colors.WHITE, fontSize: 12 }]}
                >
                  {t('highProbability')}
                </SmallBody>
              </Row>
              <Spacer size={12} />

              <Row style={{ flexWrap: 'wrap', gap: isSmallPhone ? 8 : 14, justifyContent: 'center' }}>
                {Array.from(Array(11).keys()).map((value, index) => (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      setGrade(index);
                      setStep(2);
                    }}
                    style={{
                      width: 43,
                      height: 43,
                      borderWidth: 1,
                      borderColor: colors.WHITE,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    <Body style={{ color: colors.WHITE }}>{index}</Body>
                  </TouchableOpacity>
                ))}
              </Row>
            </>
          ) : (
            <>
              <Spacer size={16} />
              <TypeSafeTextInput
                autoFocus
                showError={showErrors}
                background={colors.WHITE}
                numberOfLines={3}
                multiline={true}
                codec={DefinedTrimedString}
                value={feedback.input}
                onChange={setFeedback}
                placeholder={t('feedbackPlaceholder')}
              />

              <Spacer size={16} />
              <Row>
                <Button
                  onPress={skipResponse}
                  text={t('close')}
                  size="small"
                  color={colors.WHITE}
                  backgroundColor={colors.COMET}
                  containerStyle={{ flex: 1 }}
                />
                <HSpacer left={20} />
                <Button
                  onPress={addResponse}
                  text={t('send')}
                  size="small"
                  color={colors.DARK_BLUE}
                  backgroundColor={colors.WHITE}
                  containerStyle={{ flex: 1 }}
                />
              </Row>
            </>
          )}
        </Column>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default pipe(AppSatisfactionSurvey, Authorized);

import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.ALABASTER,
  },
  hrPadding: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  formMargin: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  list: {
    flex: 1,
  },
  modalScreen: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

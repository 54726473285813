import {
  Body,
  ConfirmBox,
  H1,
  Line,
  SimpleListItem,
  Spacer,
  colors,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { AuthorizedStackParamList } from '../../../types';

type Props = {
  route: RouteProp<AuthorizedStackParamList, 'DeleteProductScreen'>;
  navigation: StackNavigationProp<AuthorizedStackParamList, 'DeleteProductScreen'>;
};

const DeleteProductScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation('products');
  const showError = useShowError();
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();
  const goBack = useGoBack();

  const [check1, setCheck1] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [check3, setCheck3] = React.useState(false);

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const navigateProductDashboardCallback = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'ProductsTab',
        params: {
          screen: 'ProductsDashboardScreen',
        },
      },
    });
  }, [navigation]);

  const canSave = React.useMemo(() => {
    return check1 && check2 && check3;
  }, [check1, check2, check3]);

  const deleteProduct = async () => {
    if (pageState.type !== 'Loaded' || deleteInProgress) {
      return;
    }

    setDeleteInProgress(true);

    try {
      await meroApi.pro.products.deleteProducts({
        pageId: pageState.page.details._id,
        productIds: [route.params.productId],
      });

      setDeleteInProgress(false);
      reload();
      navigateProductDashboardCallback();
      toast.show({ text: t('deleteProductSuccess'), type: 'success' });
    } catch (error) {
      showError(error);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: goBack,
    flex: 10,
  };

  const rightAction = {
    text: t('deleteProductBtn'),
    ...(canSave ? { onPress: deleteProduct } : {}),
    flex: 15,
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={goBack}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('confirmDeleteProduct')}</H1>
          <Spacer size="16" />
          <Body style={styles.text.semibold}>{t('deleteCheckboxDescription')}</Body>
          <Spacer size={19} />
          <TouchableOpacity onPress={() => setCheck1((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteProductCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Spacer size="16" />
          <Line />
          <Spacer size="16" />

          <TouchableOpacity onPress={() => setCheck2((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteProductCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Spacer size="16" />
          <Line />
          <Spacer size="16" />

          <TouchableOpacity onPress={() => setCheck3((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteProductCheck3')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Spacer size={16} />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </View>
  );
};

export default DeleteProductScreen;

import { DateString, CashRegistryExportFormatType } from '@mero/api-sdk';
import { Body, Column, styles as meroStyles, Line, Spacer, useShowError } from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as types from 'io-ts';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Pressable, TouchableOpacity, View } from 'react-native';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { styles } from './styles';

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'CashRegistryOptionsMenu'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

const CashRegistryOptionsMenuScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('cashRegistry');
  const goBack = useGoBack();
  const showError = useShowError();
  const [state, { update }] = CashRegistryContext.useContext();

  const navigateInitialBalanceCallback = React.useCallback(() => {
    if (state.type === 'Loaded') navigation.navigate('InitialBalance');
  }, [navigation]);

  const [cashRegistryState] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const toDateString = (date: DateTime) => {
    return pipe(
      date.toFormat('yyyy-MM-dd'),
      DateString.decode,
      E.fold(() => {
        throw new Error(`Failed parse DateString`);
      }, types.identity),
    );
  };

  const downloadTransactions = async (format: CashRegistryExportFormatType) => {
    if (
      cashRegistryState.type !== 'Loaded' ||
      pageState.type !== 'Loaded' ||
      !cashRegistryState.cashRegistry ||
      !cashRegistryState.selectedCompany
    ) {
      return;
    }

    try {
      const { downloadUrl } = await meroApi.checkout.exportCashRegistryTransactions({
        pageId: pageState.page.details._id,
        companyId: cashRegistryState.selectedCompany._id,
        cashRegistryId: cashRegistryState.cashRegistry._id,
        fromDate: toDateString(cashRegistryState.activeInterval.value.start),
        toDate: toDateString(cashRegistryState.activeInterval.value.end),
        format: format,
      });

      Linking.openURL(downloadUrl).catch((error) => {
        showError(error.message);
      });
      goBack();
    } catch (error) {
      log.error('Failed to download report', error);
      showError(t('failedToDownloadReport'));
    }
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.stretchContainer}
        onPress={() => {
          goBack();
          update({ isOptionsMenuOpen: false });
        }}
      />
      <Column style={styles.listContainer}>
        <TouchableOpacity
          style={{ paddingHorizontal: 24, paddingVertical: 16 }}
          onPress={() => downloadTransactions('xlsx')}
        >
          <Body style={{ ...meroStyles.text.semibold }}>{t('exportExcel')}</Body>
        </TouchableOpacity>
        <Line />

        <TouchableOpacity
          style={{ paddingHorizontal: 24, paddingVertical: 16 }}
          onPress={() => downloadTransactions('pdf')}
        >
          <Body style={{ ...meroStyles.text.semibold }}>{t('exportPDF')}</Body>
        </TouchableOpacity>
        <Line />

        <TouchableOpacity
          style={{ paddingHorizontal: 24, paddingVertical: 16 }}
          onPress={navigateInitialBalanceCallback}
        >
          <Body style={{ ...meroStyles.text.semibold }}>{t('setInitialBalance')}</Body>
        </TouchableOpacity>
        <Spacer size="48" />
      </Column>
    </View>
  );
};

export default CashRegistryOptionsMenuScreen;

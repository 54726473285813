import * as React from 'react';
import { Animated, View, ViewStyle } from 'react-native';

const ITEM_HEIGHT = 42;
const PICKER_HEIGHT = ITEM_HEIGHT * 3;
const Item = <T,>({ opacity, item }: { opacity: Animated.AnimatedInterpolation<number>; item: Value<T> }) => {
  return (
    <View style={{ height: ITEM_HEIGHT, alignItems: 'center', justifyContent: 'center' }}>
      {item.type === 'string' ? (
        <Animated.Text style={{ opacity, fontSize: ITEM_HEIGHT - 4 }}>{item.label}</Animated.Text>
      ) : (
        <Animated.View style={{ opacity, ...item.style }} />
      )}
    </View>
  );
};

type Value<T> = {
  key: string;
  value: T;
} & ({ type: 'string'; label: string } | { type: 'view'; style: ViewStyle });

export type Props<T> = {
  data: Value<T>[];
  onChange: (item: T) => void;
  scrollEnabled?: boolean;
  initialIndex?: number;
};

//eslint-disable-next-line react/display-name
const CustomPicker = React.forwardRef<Animated.FlatList, Props<any>>(
  ({ data, onChange, scrollEnabled = true, initialIndex = 0 }, ref) => {
    const animatedValue = React.useRef(new Animated.Value(0)).current;
    React.useEffect(() => {
      if (!onChange) {
        return;
      }
      animatedValue.addListener(({ value }) => {
        const y = Math.max(Math.min(value, ITEM_HEIGHT * (data.length - 1)), 0);
        const i = Math.floor(y / ITEM_HEIGHT);
        if (onChange) {
          onChange(data[i]);
        }
      });

      return () => {
        animatedValue.removeAllListeners();
      };
    }, []);

    return (
      <Animated.FlatList
        scrollEnabled={scrollEnabled}
        ref={ref}
        data={data}
        initialScrollIndex={initialIndex}
        getItemLayout={(data, index) => ({ length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index })}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: animatedValue } } }], {
          useNativeDriver: true,
        })}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        keyExtractor={(item) => item.key}
        style={{ height: PICKER_HEIGHT, flexGrow: 0 }}
        contentContainerStyle={{
          paddingVertical: PICKER_HEIGHT / 2 - ITEM_HEIGHT / 2,
        }}
        snapToInterval={ITEM_HEIGHT}
        decelerationRate="fast"
        renderItem={({ item, index }) => {
          const opacity = Animated.divide(animatedValue, ITEM_HEIGHT).interpolate({
            inputRange: [index - 1, index, index + 1],
            outputRange: [0.15, 1, 0.15],
          });
          return <Item opacity={opacity} item={item} />;
        }}
      />
    );
  },
);

export default CustomPicker;

import {
  styles as meroStyles,
  AvoidKeyboard,
  Button,
  FormCard,
  MeroHeader,
  SafeAreaView,
  Spacer,
  Body,
  H1,
} from '@mero/components';
import { capitalize, DateString } from '@mero/shared-sdk/dist/common';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView } from 'react-native';

import ModalScreenContainer from '../../components/ModalScreenContainer';

import { LocalDateObject } from '../../contexts/CalendarContext';
import Calendar from '../MeroCalendar';
import { styles } from './styles';

export type Props = {
  readonly title: string;
  /**
   * Selected date
   */
  readonly selected?: Date;

  readonly minDate?: Date;

  readonly timeZone: Zone;

  readonly onDateSelected?: (selected: Date) => void;

  readonly onClosePress?: () => void;
};

const SelectDateScreen: React.FC<Props> = ({
  title,
  selected,
  minDate,
  timeZone,
  onDateSelected,
  onClosePress,
}: Props) => {
  const { t } = useTranslation('booking');
  const [date, setDate] = React.useState(DateTime.fromJSDate(selected ?? new Date(), { zone: timeZone }));

  const minDateObject: LocalDateObject | undefined = React.useMemo(() => {
    if (minDate === undefined) {
      return undefined;
    }
    return DateTime.fromJSDate(minDate, { zone: timeZone });
  }, [minDate, timeZone]);

  const dateString = React.useMemo(
    (): DateString =>
      pipe(
        date.toFormat('yyyy-MM-dd'),
        DateString.decode,
        E.fold(() => {
          throw new Error(`Failed parse DateString from DateTime (${date.toISO()}), how is that even possible?`);
        }, identity),
      ),
    [date],
  );

  const saveCallback = React.useCallback(() => {
    if (onDateSelected !== undefined && dateString !== undefined) {
      onDateSelected(date.toJSDate());
    }
  }, [onDateSelected, date]);

  const dateSelectedCallback = React.useCallback(
    (d: DateString) => {
      setDate(DateTime.fromFormat(d, 'yyyy-MM-dd', { zone: timeZone }));
    },
    [date, setDate, timeZone],
  );

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']} style={styles.container}>
      <MeroHeader title={title} canClose={onClosePress !== undefined} onClose={onClosePress} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
          <View style={styles.hrPadding}>
            <Spacer size="16" />
            <H1>{title}</H1>
            <Spacer size="24" />
          </View>
          <Calendar selected={dateString} minDate={minDateObject} onDateSelected={dateSelectedCallback} />
          <Spacer size="16" />
        </ScrollView>
      </AvoidKeyboard>
      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}

      <FormCard dropShaddow paddings="button">
        <SafeAreaView edges={['bottom']}>
          <Body>
            <Body style={meroStyles.text.semibold}>{t('selected')}:</Body>{' '}
            {capitalize(date.toFormat('EEE d LLL yyyy', { locale: 'ro' }))}
          </Body>
          <Spacer size="16" />

          <Button text={t('save')} onClick={saveCallback} />
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default SelectDateScreen;

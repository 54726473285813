import { Button, colors, Column, Label, Row, SmallBody, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles';

interface ClientBoostDetailsProps {
  isClaimPending: boolean;
  canClaim: boolean;
  onClaimDialog: () => void;
  onViewCommission: () => void;
  canAcceptCommission: boolean;
}

const ClientBoostDetails: React.FC<ClientBoostDetailsProps> = ({
  isClaimPending,
  onClaimDialog,
  canClaim,
  canAcceptCommission,
  onViewCommission,
}) => {
  const { t } = useTranslation('clients');

  return (
    <>
      <SmallBody style={styles.boostDescription}>{t('boostDescription')}</SmallBody>
      <Spacer size={12} />

      <Row justifyContent="center" alignItems="center">
        {isClaimPending ? (
          <Column style={styles.labelWrapper}>
            <Label style={styles.claimClientDescription}>{t('claimBoostClientProcessing')}</Label>
          </Column>
        ) : canClaim ? (
          <>
            <Column style={styles.labelWrapper}>
              <Button
                size="medium"
                onClick={onClaimDialog}
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                text={t('claimBoostClient')}
              />
            </Column>
          </>
        ) : null}
        {canAcceptCommission && (
          <>
            <Column style={styles.labelWrapper}>
              <Button size="medium" text={t('viewCommission')} onClick={onViewCommission} />
            </Column>
          </>
        )}
      </Row>
    </>
  );
};

export default ClientBoostDetails;

import { useShowError } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { StackScreenProps } from '@react-navigation/stack';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import EnableCheckoutDesktop from './EnableCheckoutDesktop';
import EnableCheckoutMobile from './EnableCheckoutMobile';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutTabStackParamList & AuthorizedStackParamList, 'CheckoutTabScreen'>;

const EnableCheckoutScreen: React.FC<Props> = ({ navigation, page }) => {
  const { isPhone } = useMediaQueries();
  const [checkoutState, { reloadSettings, reloadCompanies }] = CheckoutsContext.useContext();
  const showError = useShowError();
  const { t } = useTranslation('checkout');

  const [isLoading, setIsLoading] = React.useState(false);

  const changeCheckoutSettings = async () => {
    setIsLoading(true);
    try {
      await meroApi.checkout.savePageSettings({
        pageId: page.details._id,
        settings: {
          checkoutEnabled: true,
        },
      });

      reloadSettings(page.details._id);
      reloadCompanies(page.details._id);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onEnableCheckout = React.useCallback(() => {
    if (checkoutState.type === 'Loaded') {
      if (checkoutState.companies.length > 0) {
        changeCheckoutSettings();
      } else {
        navigation.navigate('Menu', { screen: 'PageCheckoutSettingsScreen', params: { screen: 'BillingDetailsList' } });
      }
    }
  }, [checkoutState]);

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      {checkoutState.type === 'Loaded' ? (
        isPhone ? (
          <>
            <EnableCheckoutMobile onEnableCheckout={onEnableCheckout} />
          </>
        ) : (
          <EnableCheckoutDesktop onEnableCheckout={onEnableCheckout} title={t('sales')} />
        )
      ) : null}
    </>
  );
};

export default pipe(EnableCheckoutScreen, CurrentBusiness, Authorized);

import { PageRoleId, PageRoleOverview } from '@mero/api-sdk/dist/pages';
import { Avatar, Button, colors, Column, FormCard, H1, Header, Icon, Select, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import PermissionSlider from './PermissionSlider';
import { styles } from './ProProfileDetailsScreen.styles';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewPermissions'>;

const AddProPermissionsScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [{ role, details }, { updateRole, updateCalendar }] = AddNewProContext.useContext();

  const scrollRef = React.useRef<ScrollView>(null);

  const [newRole, setNewRole] = React.useState<PageRoleId | undefined>(role?.id);
  const [roles, setRoles] = React.useState<{ label: string; value: PageRoleId }[]>([]);
  const [rolesPermissions, setRolesPermissions] = React.useState<PageRoleOverview[]>([]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setRoles(pageState.page.roles.map((role) => ({ label: role.name, value: role.id })));
      setRolesPermissions(pageState.page.roles);
    }
  }, [pageState.type]);

  const saveChanges = async () => {
    const roleDetails = rolesPermissions.find((role) => role.id === newRole);

    if (roleDetails) {
      updateRole(roleDetails);
      updateCalendar({
        isActive: roleDetails.isWorkerRole,
      });
    }

    goBack();
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proPermissions')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar size={32} source={details.profilePhoto} firstname={details.firstname} lastname={details.lastname} />
          </Column>
        )}
      />
      <ScrollView ref={scrollRef}>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('profileDetails')}</H1>
          <Spacer size={24} />
          <FormCard rounded>
            <InputWithLabel label={t('accessType')}>
              {roles.length > 0 && <Select items={roles} value={newRole} onChange={(r) => setNewRole(r)} />}
            </InputWithLabel>
          </FormCard>
        </Column>
        <Spacer size="16" />
        <PermissionSlider roles={roles} rolesPermissions={rolesPermissions} role={newRole} setRole={setNewRole} />
        <Spacer size={92} />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('done')} onClick={saveChanges} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text={t('done')} onClick={saveChanges} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProPermissionsScreen;

import { colors, Header, Icon } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import WebView from 'react-native-webview';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { AuthorizedStackParamList } from '../../../types';

export type Props = StackScreenProps<AuthorizedStackParamList, 'CustomWebview'>;

const CustomWebview: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();

  const { url, title } = route.params;

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
        text={title ?? ''}
      />
      <WebView source={{ uri: url }} style={{ flex: 1 }} />
    </ModalScreenContainer>
  );
};

export default CustomWebview;

import { Button, colors } from '@mero/components';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import React from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../../../config';
import type { Props } from './Map';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Map: React.FC<Props> = ({ coordinates, onChange }) => {
  const { t } = useTranslation('location');

  const mapRef = React.useRef<google.maps.Map>();
  const [showButton, setShowButton] = React.useState(false);

  const onDragEnd = (ev: google.maps.MapMouseEvent) => {
    if (ev.latLng) {
      onChange(ev.latLng.toJSON());
    }
  };

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    mapRef.current = map;
  }, []);

  const onMapDragEnd = () => {
    const bounds = mapRef.current?.getBounds();
    if (bounds && !bounds.contains(coordinates)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const recenter = () => {
    mapRef.current?.setCenter(coordinates);
  };

  return (
    <LoadScript googleMapsApiKey={config.google.maps.web}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={15}
        onLoad={onLoad}
        options={{
          fullscreenControl: false,
        }}
        onDragEnd={onMapDragEnd}
        onBoundsChanged={onMapDragEnd}
        onCenterChanged={onMapDragEnd}
      >
        <Marker position={coordinates} draggable onDragEnd={onDragEnd} />
        {showButton && (
          <Button
            backgroundColor={colors.WHITE}
            color={colors.DARK_BLUE}
            size="small"
            text={t('center')}
            containerStyle={{ position: 'absolute', bottom: 8, left: 8 }}
            padding={16}
            onClick={recenter}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default React.memo(Map);

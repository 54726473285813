import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import BaseView from '../Base';

type Props = React.PropsWithChildren<{
  flex?: number;
  reversed?: boolean;
  flexWrap?: ViewStyle['flexWrap'];
  justifyContent?: ViewStyle['justifyContent'];
  alignItems?: ViewStyle['alignItems'];
  gap?: ViewStyle['gap'];
  style?: StyleProp<ViewStyle>;
}>;

const Column: React.FC<Props> = ({ children, reversed = false, ...props }) => {
  return (
    <BaseView {...props} flexDirection={reversed ? 'column-reverse' : 'column'}>
      {children}
    </BaseView>
  );
};

export default Column;

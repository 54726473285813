import { PhoneNumber } from '@mero/api-sdk';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import SelectClientScreenView from '../../../components/SelectClientScreenView';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { BookingClientSelectContext } from '../../../contexts/BookingClientSelectContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SearchClientsContext } from '../../../contexts/SearchClientsContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';

type SelectClientProps = CurrentBusinessProps &
  AuthorizedProps & {
    navigation: CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList, 'Authorized'>
    >;
    route: RouteProp<BookingStackParamList, 'SelectNewBookingClientScreen'>;
  };

const SelectClientScreen: React.FC<SelectClientProps> = ({ authorization, page, navigation }: SelectClientProps) => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const [{ type: stateType, query, clients }, { init, search, reload, loadMore }] = SearchClientsContext.useContext();
  const isLoading = stateType === 'New' || stateType === 'Loading';

  React.useEffect(() => {
    init({
      pageId: page.details._id,
      minQueryLength: page.permissions.clients.getSearchMinSymbols(),
    });
  }, [init, authorization, page]);

  const [, { set: selectClient }] = BookingClientSelectContext.useContext();

  const selectClientCallback = React.useCallback(
    (client: ClientPreview) => {
      selectClient(client);
      goBack();
    },
    [selectClient, goBack],
  );

  const queryChangeCallback = React.useCallback(
    (q: string): void => {
      search({ pageId: page.details._id, query: { search: q, flagFilter: query.flagFilter } }); // async effect
    },
    [search, query],
  );

  const loadMoreCallback = React.useCallback(() => {
    loadMore();
  }, [loadMore]);

  const addClientCallback = React.useCallback(
    (phone: PhoneNumber | undefined) => {
      navigation.navigate('Authorized', {
        screen: 'NewClientScreen',
        params: {
          phone: phone,
        },
      });
    },
    [navigation],
  );

  const importContactsCallback = React.useCallback(() => {
    navigation.navigate('ImportContactsScreen');
  }, [navigation]);

  const [, { subscribe }] = AppEventsContext.useContext();
  React.useEffect(
    () =>
      subscribe((event) => {
        if (event.type === 'PageClientAdded' && event.pageId === page.details._id) {
          search({
            pageId: event.pageId,
            query: {
              search: event.details.phoneNumber,
              flagFilter: query.flagFilter,
            },
          });
        } else if (event.type === 'PageClientsImported' && event.pageId === page.details._id) {
          search({
            pageId: event.pageId,
            query: {
              search: query.search,
              flagFilter: query.flagFilter,
            },
          });
        }
      }),
    [subscribe, page.details._id, search, query],
  );

  return (
    <SelectClientScreenView
      query={query.search}
      clients={clients}
      onQueryChange={queryChangeCallback}
      onClientSelected={selectClientCallback}
      onBackPressed={goBack}
      onAddClient={addClientCallback}
      onImportContacts={importContactsCallback}
      onLoadMore={loadMoreCallback}
      isLoading={isLoading}
      autoFocus={true}
      onReload={reload}
    />
  );
};

export default pipe(SelectClientScreen, CurrentBusiness, Authorized);

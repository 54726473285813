import { Body, colors, ConfirmBox, H1, SimpleListItem, Spacer, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

export type Props = {
  readonly deleteInProgress: boolean;
  readonly onDismiss: () => void;
  readonly onConfirm: () => void;
};

/**
 * Client removal confirmation modal
 */
const ClientConfirmDeleteModal: React.FC<Props> = ({
  deleteInProgress,
  onDismiss,
  onConfirm,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('clients');
  const [confirmed, setConfirmed] = React.useState(false);

  const toggleConfirmedCallback = React.useCallback(() => {
    setConfirmed(!confirmed);
  }, [confirmed, setConfirmed]);

  const leftAction = {
    text: t('cancel'),
    onPress: deleteInProgress ? undefined : onDismiss,
  };

  const rightAction = {
    text: t('deleteClient'),
    onPress: deleteInProgress || !confirmed ? undefined : onConfirm,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        icon="info"
        headerTitle={t('canNotBeUndoneAction')}
        canClose={!deleteInProgress}
        onClose={deleteInProgress ? undefined : onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>{t('confirmClientDeletion')}</H1>
        <Spacer size="8" />
        <Body>{t('selectAndConfirmDeletion')}</Body>
        <Spacer size="16" />
        <TouchableOpacity onPress={toggleConfirmedCallback} disabled={deleteInProgress}>
          <SimpleListItem
            icon={confirmed ? 'checked' : 'unchecked'}
            iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
            paddingTop={0}
            paddingBottom={0}
          >
            <Body>
              {t('accountDetailsWillBeDeleted')} <Body style={meroStyles.text.semibold}>{t('irreversible')}</Body>
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ClientConfirmDeleteModal;

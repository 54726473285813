import * as React from 'react';
import { useTranslation } from 'react-i18next';

import OptionsMenuModal from '../../../components/OptionsMenuModal';

export type ConfirmUpdateRecurrentBlockedTimeProps = {
  readonly onUpdateOnlyOne: () => void;
  readonly onUpdateAll: () => void;
  readonly onDismiss: () => void;
};

const ConfirmUpdateRecurrentBlockedTime: React.FC<ConfirmUpdateRecurrentBlockedTimeProps> = ({
  onUpdateOnlyOne,
  onUpdateAll,
  onDismiss,
}) => {
  const { t } = useTranslation('blockedTime');

  return (
    <OptionsMenuModal
      title={t('editBlockedTimeTitle')}
      onDismiss={onDismiss}
      menuItems={[
        {
          title: t('editThisBlockedTime'),
          onPress: onUpdateOnlyOne,
          color: 'red',
        },
        {
          title: t('editAllBlockedTimeRecurrences'),
          onPress: onUpdateAll,
          color: 'red',
        },
      ]}
    />
  );
};

export default ConfirmUpdateRecurrentBlockedTime;

import { Column, Row } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Animated, FlatList, Text, TouchableOpacity, useWindowDimensions, ViewToken } from 'react-native';

import { CalendarContext, LocalDateObject } from '../../../../contexts/CalendarContext';
import log from '../../../../utils/log';
import { getDatesInWeek, getWeekDays } from '../utils';
import { HEADER_HEIGHT, styles } from './styles';

type Props = {
  readonly selectedDate: LocalDateObject;
  readonly currentDate: LocalDateObject;
  readonly markedDates?: Record<string, Record<string, unknown>>;
  readonly onDayPress: (d: LocalDateObject) => void;
};

const NUMBER_OF_WEEKS = 4;

const getWeeksBatch = (selectedDate: LocalDateObject, isReverse = false): LocalDateObject[][] => {
  const selected = DateTime.fromObject(
    {
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day,
    },
    { zone: 'UTC' },
  );

  const weeks = Array(NUMBER_OF_WEEKS)
    .fill(1)
    .map((_, i) =>
      getDatesInWeek({
        date: LocalDateObject.unsafeFromPartial(selected.plus({ days: (isReverse ? -1 : 1) * 7 * (i + 1) }).toObject()),
      }),
    );

  if (isReverse) {
    return weeks.reverse();
  }

  return weeks;
};

const getInitialWeeks = (selectedDate: LocalDateObject): LocalDateObject[][] => {
  const currentWeek = getDatesInWeek({ date: selectedDate });
  const prevWeeks = getWeeksBatch(selectedDate, true);
  const nextWeeks = getWeeksBatch(selectedDate);
  return [...prevWeeks, currentWeek, ...nextWeeks];
};

const getNextDate = (currentWeekDates: LocalDateObject[], selectedDate: LocalDateObject): LocalDateObject => {
  const weekday = DateTime.fromObject(selectedDate, { zone: 'UTC' }).weekday;

  return currentWeekDates[weekday - 1];
};

const viewabilityConfig = {
  minimumViewTime: 200,
  viewAreaCoveragePercentThreshold: 100,
};

type Days = {
  readonly days: LocalDateObject[];
  readonly selectedDate: LocalDateObject;
  readonly currentDate: LocalDateObject;
  readonly onDayChange: (d: LocalDateObject) => void;
};

const Days = React.memo(function Days({ days, selectedDate, onDayChange, currentDate }: Days) {
  return (
    <Row
      style={[
        {
          justifyContent: 'space-around',
          paddingTop: 6,
          alignItems: 'center',
          height: HEADER_HEIGHT,
        },
      ]}
    >
      {days.map((day) => {
        const isSelected = LocalDateObject.equals(day, selectedDate);
        const currentDateCmp = LocalDateObject.compare(day, currentDate);
        const isCurrent = currentDateCmp === 0;
        const isCurrentOrAfter = currentDateCmp !== -1;

        return (
          <TouchableOpacity
            key={`${day.year}-${day.month}-${day.day}`}
            onPress={() => {
              onDayChange(day);
            }}
          >
            <Column style={[styles.weekday, ...(isSelected ? [styles.selectedDay] : [])]}>
              <Text
                allowFontScaling={false}
                numberOfLines={1}
                style={[
                  { textAlign: 'center', fontSize: 15, fontWeight: '600' },
                  ...(isSelected
                    ? [styles.selectedDayText]
                    : isCurrent
                    ? [styles.todayDayText]
                    : [styles.normalDayText]),
                  ...(isCurrentOrAfter ? [] : [styles.differentMonthDayText]),
                ]}
              >
                {day.day}
              </Text>
            </Column>
          </TouchableOpacity>
        );
      })}
    </Row>
  );
});

const CalendarHeader: React.FC<Props> = ({ selectedDate, currentDate, onDayPress }) => {
  const windowSize = useWindowDimensions();
  const viewWidth = windowSize.width;
  const initialWeeks = React.useMemo((): LocalDateObject[][] => {
    return [getDatesInWeek({ date: selectedDate })];
  }, []);
  const [weekDates, setWeekDates] = React.useState<LocalDateObject[][]>(initialWeeks);
  const datesWeekRef = React.useRef<FlatList>(null);
  const datesDayRef = React.useRef<FlatList>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const weekDays = React.useMemo(() => getWeekDays().map((day) => day.substring(0, 1)), []);
  const [{ period }] = CalendarContext.useContext();

  const weekdaysStyle = React.useMemo(() => [styles.weekdays], [viewWidth]);

  const onDayChange = React.useCallback(
    (day: LocalDateObject) => {
      onDayPress(day);
    },
    [onDayPress],
  );

  const renderItem = React.useCallback(
    (type: typeof period.type) =>
      function Item(row: { item: LocalDateObject[] }) {
        return (
          <Column style={[{ width: viewWidth - (type === 'week' ? 50 : 0), justifyContent: 'center' }]}>
            <Days days={row.item} selectedDate={selectedDate} onDayChange={onDayChange} currentDate={currentDate} />
          </Column>
        );
      },
    [selectedDate, viewWidth, currentDate, onDayChange],
  );

  const onViewableWeekItemsChanged = React.useRef(({ viewableItems }: { viewableItems: ViewToken[] }) => {
    const viewable = viewableItems.find((i) => i.isViewable);
    if (viewable && typeof viewable.index === 'number' && viewableItems.length === 1) {
      setCurrentIndex(viewable.index);
      datesDayRef.current?.scrollToIndex({ index: viewable.index, animated: false });
    }
  }).current;

  const onViewableDayItemsChanged = React.useRef(({ viewableItems }: { viewableItems: ViewToken[] }) => {
    const viewable = viewableItems.find((i) => i.isViewable);
    if (viewable && typeof viewable.index === 'number' && viewableItems.length === 1) {
      setCurrentIndex(viewable.index);
      datesWeekRef.current?.scrollToIndex({ index: viewable.index, animated: false });
    }
  }).current;

  const scrollAll = (index: number, type?: 'week' | 'day') => {
    if (index === currentIndex) {
      return;
    }
    type === 'week' || type === undefined ? datesWeekRef.current?.scrollToIndex({ index, animated: false }) : null;
    type === 'day' || type === undefined ? datesDayRef.current?.scrollToIndex({ index, animated: false }) : null;
  };

  React.useEffect(() => {
    const nextDate = getNextDate(weekDates[currentIndex], selectedDate);

    if (!LocalDateObject.equals(nextDate, selectedDate)) {
      onDayChange(nextDate);
    }

    if (currentIndex === weekDates.length - 1) {
      const nextWeeks = getWeeksBatch(weekDates[currentIndex][0]);
      setWeekDates([...weekDates, ...nextWeeks]);
    } else if (currentIndex === 0) {
      const prevWeeks = getWeeksBatch(weekDates[currentIndex][0], true);
      setWeekDates([...prevWeeks, ...weekDates]);
      setCurrentIndex(NUMBER_OF_WEEKS);
      scrollAll(NUMBER_OF_WEEKS);
    }
  }, [currentIndex]);

  React.useEffect(() => {
    setWeekDates(getInitialWeeks(selectedDate));
  }, [selectedDate]);

  React.useEffect(() => {
    scrollAll(weekDates.length >= NUMBER_OF_WEEKS ? NUMBER_OF_WEEKS : 0);
  }, [weekDates]);

  return (
    <Column style={[styles.headerStyle, period.type === 'week' && { marginLeft: 50 }]}>
      <Animated.View style={weekdaysStyle}>
        {weekDays.map((day, index) => (
          <Text allowFontScaling={false} key={day + index} style={styles.weekday} numberOfLines={1}>
            {day}
          </Text>
        ))}
      </Animated.View>
      <Column style={[{ width: viewWidth - 50 }, period.type !== 'week' && { height: 0 }]}>
        <FlatList
          ref={datesWeekRef}
          initialScrollIndex={currentIndex}
          onScrollToIndexFailed={(info) => {
            const wait = new Promise((resolve) => setTimeout(resolve, 500));
            log.debug('datesWeekRef onScrollToIndexFailed', info);

            wait.then(() => {
              scrollAll(info.index, 'week');
            });
          }}
          data={weekDates}
          renderItem={renderItem('week')}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          viewabilityConfig={viewabilityConfig}
          onViewableItemsChanged={onViewableWeekItemsChanged}
          keyExtractor={(item) => `${item[0].year}-${item[0].month}-${item[0].day}`}
        />
      </Column>
      <Column style={[{ width: viewWidth }, period.type !== 'day' && { height: 0 }]}>
        <FlatList
          ref={datesDayRef}
          initialScrollIndex={currentIndex}
          onScrollToIndexFailed={(info) => {
            const wait = new Promise((resolve) => setTimeout(resolve, 500));
            log.debug('datesDayRef onScrollToIndexFailed', info);

            wait.then(() => {
              scrollAll(info.index, 'day');
            });
          }}
          data={weekDates}
          renderItem={renderItem('day')}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          viewabilityConfig={viewabilityConfig}
          onViewableItemsChanged={onViewableDayItemsChanged}
          keyExtractor={(item) => `${item[0].year}-${item[0].month}-${item[0].day}`}
        />
      </Column>
    </Column>
  );
};

export default React.memo(CalendarHeader);

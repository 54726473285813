import {
  Icon,
  Spacer,
  TextInput as MeroTextInput,
  Button as MeroButton,
  AvoidKeyboard,
  DismissKeyboard,
  MeroHeader,
  useShowError,
  styles as meroStyles,
  H1,
  Body,
  SmallBody,
  colors,
} from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { WHITE } from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';
import useGoBack from '../../../hooks/useGoBack';

import { AnonymousStackParamList } from '../../../types';
import log from '../../../utils/log';
import { NovaAuthContext } from '../NovaAuthContext';
import { SigningContext } from '../SigningContext';
import { styles } from './styles';

type Props = StackScreenProps<AnonymousStackParamList, 'SignInOtpScreen'>;

const SignInOtpScreen: React.FC<Props> = ({ navigation }) => {
  const [novaAuthState, novaAuthDispatch] = NovaAuthContext.useContext();
  const [, signingDispatch] = SigningContext.useContext();
  const showError = useShowError();

  const { logAction, logEvent } = useAnalytics({
    eventName: 'pro_onboarding_enter_sms_code_screen_shown',
    screenName: 'pro_onboarding_enter_sms_code',
    staticData: {
      trigger_source: 'login',
    },
  });

  const [code, setCode] = React.useState('');
  const [showErrors, setShowErrors] = React.useState(false);
  const validatingCode = novaAuthState.type === 'ValidatingCode';
  const phoneInput = novaAuthState.type === 'New' ? novaAuthState.phone.input : novaAuthState.phone;

  const codeIsValid = /^\d{6,6}$/gi.test(code);

  const validateVerificationCode = () => {
    setShowErrors(true);
    if (codeIsValid && novaAuthState.type === 'CodeSent') {
      const validateCode = async () => {
        try {
          await novaAuthDispatch.confirmSmsOtp({
            code: code,
            otpRequestId: novaAuthState.otpRequestId,
            phone: novaAuthState.phone,
          });
        } catch (e) {
          showError(e, 'Failed to validate SMS code');
        }
      };

      validateCode();
    }
  };

  const goBack = useGoBack(
    React.useCallback(() => {
      navigation.replace('SignInPhoneScreen');
    }, [navigation]),
  );

  const codeNotReceivedCallback = React.useCallback(() => {
    novaAuthDispatch.resetCodeNotReceived();
    // Navigation will be done by effect below
  }, [goBack, novaAuthDispatch]);

  React.useEffect(() => {
    if (novaAuthState.type === 'New') {
      log.debug('SignInOtpScreen: novaAuthState is New, go back');
      goBack();
    } else if (novaAuthState.type === 'Success') {
      log.debug('SignInOtpScreen: novaAuthState is Success, navigate SignInScreen');
      signingDispatch.loginNovaAuth({
        token: novaAuthState.novaToken,
        phone: novaAuthState.phone,
      });

      navigation.replace('SignInScreen');
    } else if (novaAuthState.type === 'CodeSent') {
      logEvent('pro_sms_sent', {
        message_type: 'otp_code',
        provider: 'paid_sms',
      });
    }
  }, [goBack, novaAuthState]);

  React.useEffect(() => {
    if (codeIsValid) {
      validateVerificationCode();
    }
  }, [code]);

  return (
    <ModalScreenContainer>
      <MeroHeader canGoBack onBack={goBack} />
      <AvoidKeyboard style={styles.avoidKeyboard}>
        <DismissKeyboard style={styles.dismissKeyboard}>
          <H1 style={meroStyles.text.alignCenter}>Introdu codul format din 6 cifre</H1>
          <Spacer size={32} />
          <Body style={meroStyles.text.alignCenter}>
            Codul tau a fost <Body style={meroStyles.text.semibold}>trimis prin SMS</Body> la{'\n'}
            <Body style={meroStyles.text.semibold}>{formatPhoneNumber(phoneInput)}</Body>
          </Body>

          <Spacer size={24} />

          <MeroTextInput
            onChange={(code) => setCode(`${code}`)}
            value={code}
            editable={!validatingCode}
            keyboardType="number-pad"
            autoFocus
          />

          {showErrors && !codeIsValid && (
            <>
              <Spacer size="4" />
              <SmallBody style={{ color: colors.RADICAL_RED, alignSelf: 'flex-start' }}>
                Codul introdus este invalid.
              </SmallBody>
            </>
          )}

          <Spacer size={24} />
          <MeroButton
            size="large"
            text={validatingCode ? 'Verificăm codul ...' : 'Verifică cod'}
            onClick={validateVerificationCode}
            disabled={validatingCode}
            RightComponent={() => <Icon type="next" color={WHITE} />}
            containerStyle={{ width: '100%' }}
          />
          <Spacer size="24" />
          <TouchableOpacity onPress={codeNotReceivedCallback}>
            <Body style={[meroStyles.text.semibold, meroStyles.text.link]}>Nu ai primit un cod prin SMS?</Body>
          </TouchableOpacity>
        </DismissKeyboard>
      </AvoidKeyboard>
    </ModalScreenContainer>
  );
};

export default SignInOtpScreen;

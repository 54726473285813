import { MeroUnits, Money, PageGiftCardSettings, PortionPercentScaledNumber, ScaledNumber } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Icon,
  ListItem,
  MeroHeader,
  SmallBody,
  Spacer,
  Text,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image, Linking, Platform, ScrollView, TouchableOpacity } from 'react-native';

import { FlashyLabel } from '../../../../../components/FlashyLabel';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { images } from '../../../../../constants/images';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { GiftCardsNavigationProp } from '../../../../../types';
import { scaledToString } from '../../../../../utils/scaled';
import { styles } from './styles';

type CheckInputProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
};

const CheckInput: React.FC<CheckInputProps> = ({ label, checked = false, disabled = false }) => {
  return (
    <ListItem
      LeftComponent={() => (
        <Icon
          type={checked ? 'checked' : 'unchecked'}
          disabled={disabled}
          size={24}
          color={disabled ? '#A8AABE' : undefined}
        />
      )}
      leftContainerStyle={{ justifyContent: 'flex-start', width: 24 }}
      centerContainerStyle={{ justifyContent: 'flex-start', marginRight: 16, marginLeft: 16 }}
    >
      <Title>{label}</Title>
    </ListItem>
  );
};

type Props = {
  readonly navigation: GiftCardsNavigationProp;
} & CurrentBusinessProps;

const PageGiftCardsSettingsScreen: React.FC<Props> = ({ navigation, page }) => {
  const [pageState, { reloadAsync: reloadPage }] = CurrentBusinessContext.useContext();
  const [onlinePaymentsState, { reloadAsync: reloadOnlinePayments }] = OnlinePaymentsContext.useContext();
  const { t } = useTranslation('giftCards');
  const goBack = useGoBack();
  const toast = useToast();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();

  const stripeConnectionInProgress = React.useRef(false);

  const [isSaving, setIsSaving] = React.useState(false);
  const [giftCardSettings, setGiftCardSettings] = React.useState<PageGiftCardSettings>({
    perSalonEnabled: false,
    universalEnabled: false,
  });

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setGiftCardSettings(pageState.page.details.giftCardSettings);
    }
  }, [pageState.type]);

  const onViewDetailsPresses = () => {
    if (Platform.OS === 'web') {
      Linking.openURL(config.giftCardUrl);
    } else {
      navigation.navigate('CustomWebview', {
        url: config.giftCardUrl,
        title: t('giftCards'),
      });
    }
  };

  const navigateOnlinePaymentsActivationPage = React.useCallback(() => {
    return navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'ActivateOnlinePayments',
      },
    });
  }, [navigation]);

  const toggleSalonGiftCard = () => {
    if (giftCardSettings.perSalonEnabled) {
      setGiftCardSettings({
        ...giftCardSettings,
        perSalonEnabled: false,
      });
      return;
    }

    if (!page.details.hasOnlinePaymentsEnabled) {
      return;
    }

    setGiftCardSettings({
      ...giftCardSettings,
      perSalonEnabled: true,
      universalEnabled: true,
    });
  };

  const toggleUniversalGiftCard = () => {
    setGiftCardSettings({
      ...giftCardSettings,
      universalEnabled: !giftCardSettings.universalEnabled,
    });
  };

  const saveChanges = async () => {
    try {
      await meroApi.pages.updatePageGiftCardSettings({
        pageId: page.details._id,
        settings: {
          ...giftCardSettings,
        },
      });

      reloadPage();
      reloadOnlinePayments({ pageId: page.details._id });
      toast.show({
        type: 'success',
        text: t('changesSaved'),
      });
      goBack();
    } catch (error) {
      showError(error);
    }
  };

  if (onlinePaymentsState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('giftCards')} />
      <ScrollView style={{ flex: 1 }}>
        <Column style={{ paddingHorizontal: 16 }}>
          <Column
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 32,
              paddingBottom: 16,
            }}
          >
            <Image source={images.giftCard} style={[Platform.OS === 'web' && { width: 120, height: 120 }]} />
          </Column>
          <Column style={{ paddingBottom: 36 }}>
            <H1 style={{ textAlign: 'center' }}>{t('giftCards')}</H1>
          </Column>
          <FormCard paddings="none" style={{ paddingHorizontal: 16, paddingVertical: 24 }} rounded dropShaddow>
            <TouchableOpacity onPress={toggleSalonGiftCard} disabled={!page.details.hasOnlinePaymentsEnabled}>
              <CheckInput
                label={t('salonGiftCardTitle')}
                checked={giftCardSettings.perSalonEnabled}
                disabled={!page.details.hasOnlinePaymentsEnabled}
              />
              <Column style={{ paddingLeft: 40 }}>
                <Spacer size={4} />
                <FlashyLabel
                  type="info"
                  text={t('salonGiftCardFees', {
                    feeText: onlinePaymentsState.status.commissionInfo.feeText,
                  })}
                />

                <Spacer size={8} />
                <SmallBody>
                  <Trans ns={'giftCards'} t={t} i18nKey="salonGiftCardSubtitle">
                    0
                  </Trans>
                </SmallBody>
              </Column>
            </TouchableOpacity>
            {!page.details.hasOnlinePaymentsEnabled && (
              <>
                <Spacer size={24} />
                <Button
                  color={colors.DARK_BLUE}
                  backgroundColor={colors.SKY_BLUE}
                  text={t('enableCardPayment')}
                  onPress={navigateOnlinePaymentsActivationPage}
                />
              </>
            )}
          </FormCard>
          <Spacer size={16} />
          <FormCard paddings="none" style={{ paddingVertical: 24 }} rounded dropShaddow>
            <TouchableOpacity
              style={{ paddingHorizontal: 16 }}
              disabled={giftCardSettings.perSalonEnabled}
              onPress={toggleUniversalGiftCard}
            >
              <CheckInput
                label={t('generalGiftCardTitle')}
                checked={giftCardSettings.universalEnabled}
                disabled={giftCardSettings.perSalonEnabled}
              />
              <Column style={{ paddingLeft: 40 }}>
                <FlashyLabel
                  type="info"
                  text={t('salonGiftCardFees', {
                    feeText: onlinePaymentsState.status.commissionInfo.feeText,
                  })}
                />
                <Spacer size={8} />
                <SmallBody>
                  <Trans ns={'giftCards'} t={t} i18nKey="generalGiftCardSubtitle">
                    0
                  </Trans>
                </SmallBody>
              </Column>
            </TouchableOpacity>
            <Spacer size={16} />
          </FormCard>
          <Spacer size={24} />
          <Body style={{ paddingHorizontal: 16, textAlign: 'center' }}>
            <Trans ns={'giftCards'} t={t} i18nKey="giftCardsText">
              0
              <Text link semibold onPress={onViewDetailsPresses}>
                1
              </Text>
            </Trans>
          </Body>
        </Column>

        <Spacer size={126} />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button text={t('saveChanges')} onPress={saveChanges} disabled={isSaving} />
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageGiftCardsSettingsScreen, CurrentBusiness);

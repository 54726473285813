import { ClientId } from '@mero/api-sdk/dist/clients';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { Button, colors, Line, Spacer } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from './ClientEmptyListView';
import ClientMembershipsListItemView from './ClientMembershipsListItemView';

type Props = Readonly<{
  HeaderElement?: React.ReactElement;
  memberships: PurchasedMembership<MeroUnits.Any>[];
  clientId: ClientId;
  onNewMembership: () => void;
}>;

const ClientMembershipsListView: React.FC<Props> = ({ HeaderElement, memberships, clientId, onNewMembership }) => {
  const renderItem = React.useCallback(({ item }: { item: PurchasedMembership<MeroUnits.Any> }) => {
    return <ClientMembershipsListItemView item={item} clientId={clientId} />;
  }, []);

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={memberships}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există abonamente" subtitle="Abonamentele clientului va fi afișat aici">
          <Spacer size="32" />
          <Button
            text="Abonament nou"
            onClick={onNewMembership}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={{ flex: 1, flexWrap: 'wrap', width: 200 }}
          />
        </ClientEmptyListView>
      }
      ItemSeparatorComponent={() => (
        <>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
        </>
      )}
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

const keyExtractor = (item: PurchasedMembership<MeroUnits.Any>): string => item._id;

export default ClientMembershipsListView;

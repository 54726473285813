import { Button, colors, Spacer, Text } from '@mero/components';
import * as React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

import { styles } from './styles';

export type NoContentAction = {
  readonly title: string;
  readonly onPress: () => void;
  readonly type: 'primary' | 'secondary';
};

type NoContentActionViewProps = {
  readonly action: NoContentAction;
};

const NoContentActionView: React.FC<NoContentActionViewProps> = ({ action }: NoContentActionViewProps) => {
  switch (action.type) {
    case 'primary':
      return <Button text={action.title} size="medium" onClick={action.onPress} />;

    case 'secondary':
      return (
        <Button
          text={action.title}
          backgroundColor="#F2F2FE"
          color={colors.DARK_BLUE}
          size="medium"
          onClick={action.onPress}
        />
      );
  }
};

export type NoContentViewProps = React.PropsWithChildren<{
  readonly title?: string;
  readonly subtitle?: string;
  readonly actions?: NoContentAction[];
  readonly style?: StyleProp<ViewStyle>;
  readonly actionsContainerStyle?: StyleProp<ViewStyle>;
}>;

const NoContentView: React.FC<NoContentViewProps> = ({
  title,
  subtitle,
  actions = [],
  style,
  actionsContainerStyle,
}: NoContentViewProps) => {
  return (
    <View style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, style)}>
      <Text h2s style={styles.title}>
        {title}
      </Text>
      <Spacer size="8" />
      {subtitle ? (
        <Text body style={styles.subtitle}>
          {subtitle}
        </Text>
      ) : null}
      {actions.length > 0 ? (
        <View
          style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.actionsContainer, actionsContainerStyle)}
        >
          <Spacer size="32" />
          {actions.map((action, index) => (
            <>
              {index > 0 ? <Spacer key={`action-${index}-spacer`} size="24" /> : null}
              <NoContentActionView key={`action-${index}`} action={action} />
            </>
          ))}
        </View>
      ) : null}
    </View>
  );
};

export default NoContentView;

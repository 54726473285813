import { colors, Column, Line, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import { useEscPressWeb } from '../../../hooks/useEscPressWeb';

import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { styles } from './AddProceedMenu.styles';

export type Props = {
  onSave: () => void;
  onCancel: () => void;
  onClose: () => void;
  onDelete: () => void;
};

export const CloseDraftMenuPhone: React.FC<Props> = ({ onSave, onCancel, onClose, onDelete }) => {
  const { t } = useTranslation('checkout');
  const [formState] = CheckoutFormContext.useContext();

  return (
    <ModalOverlay style={{ zIndex: 10000 }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onClose} />
      <View style={[styles.optionsListContainer, styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSave}>
            <Title>{formState.draftId ? t('updateDraft') : t('saveDraft')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onCancel}>
            <Title style={{ color: colors.RADICAL_RED }}>
              {formState.draftId ? t('cancelDraftChanges') : t('cancelDraft')}
            </Title>
          </TouchableOpacity>
          {formState.draftId && (
            <>
              <Line />
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDelete}>
                <Title style={{ color: colors.RADICAL_RED }}>{t('deleteDraftAction')}</Title>
              </TouchableOpacity>
            </>
          )}
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onClose}>
            <Title>{t('cancel')}</Title>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export const CloseDraftMenuDesktop: React.FC<Props> = ({ onSave, onCancel, onClose, onDelete }) => {
  const { t } = useTranslation('checkout');
  const [formState] = CheckoutFormContext.useContext();

  const viewRef = React.useRef<View>(null);

  useEscPressWeb({
    onPress: onClose,
  });

  useClickOutsideWeb({
    ref: viewRef,
    isVisible: true,
    onClickOutside() {
      onClose();
    },
  });

  return (
    <View
      ref={viewRef}
      style={{
        position: 'absolute',
        top: 50,
        right: 0,
        paddingHorizontal: 16,
        backgroundColor: '#ffffff',
        borderRadius: 16,
        minWidth: 300,
        shadowColor: '#000000',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.16,
        shadowRadius: 16,
        elevation: 16,
        zIndex: 10000,
      }}
    >
      <TouchableOpacity style={styles.optionsMenuItemDesktop} delayPressIn={0} onPress={onSave}>
        <Title>{formState.draftId ? t('updateDraft') : t('saveDraft')}</Title>
      </TouchableOpacity>
      <Line />
      <TouchableOpacity style={styles.optionsMenuItemDesktop} delayPressIn={0} onPress={onCancel}>
        <Title style={{ color: colors.RADICAL_RED }}>
          {formState.draftId ? t('cancelDraftChanges') : t('cancelDraft')}
        </Title>
      </TouchableOpacity>
      {formState.draftId && (
        <>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItemDesktop} delayPressIn={0} onPress={onDelete}>
            <Title style={{ color: colors.RADICAL_RED }}>{t('deleteDraftAction')}</Title>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};

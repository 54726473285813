import { colors, Column } from '@mero/components';
import * as React from 'react';
import { ActivityIndicator } from 'react-native';

export const LoadingComponent = () => (
  <Column
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: colors.ALABASTER,
      opacity: 0.5,
      zIndex: 1001,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ActivityIndicator size="large" color={colors.COMET} />
  </Column>
);

import { HSpacer, Select } from '@mero/components';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { SelectItem } from '@mero/components/lib/components/Select';

export type DayTimeSelectStep = 5 | 10 | 15 | 20 | 30 | 60;

export type DayTime = {
  readonly hour: number;
  readonly minute: number;
};

export type Props = {
  readonly value?: DayTime;
  /**
   * Select options step in minutes
   */
  readonly step?: DayTimeSelectStep;
  /**
   * Select change callback
   */
  readonly onChange?: (time: DayTime) => void;

  readonly style?: StyleProp<ViewStyle>;
};

const DayTimeSelect2: React.FC<Props> = ({ value, step = 15, onChange, style }: Props) => {
  const hour = value?.hour ?? 0;
  const minute = value?.minute ?? 0;

  const hourOptions: SelectItem<number>[] = React.useMemo(
    () =>
      pipe(
        Nea.range(0, 23),
        Nea.map((h) => ({
          value: h,
          label: String(h).padStart(2, '0'),
        })),
      ),
    [],
  );

  const minuteOptions: SelectItem<number>[] = React.useMemo(
    () =>
      pipe(
        Nea.range(0, Math.floor(60 / step) - 1),
        Nea.map((m) => ({
          value: m * step,
          label: String(m * step).padStart(2, '0'),
        })),
      ),
    [],
  );

  const onHourChangeCallback = React.useCallback(
    (hour: number) => {
      if (onChange) {
        onChange({ hour, minute });
      }
    },
    [minute, onChange],
  );

  const onMinuteChangeCallback = React.useCallback(
    (minute: number) => {
      if (onChange) {
        onChange({ hour, minute });
      }
    },
    [hour, onChange],
  );

  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      <Select items={hourOptions} value={hour} onChange={onHourChangeCallback} style={{ flex: 1 }} />
      <HSpacer left={2} right={2} />
      <Select items={minuteOptions} value={minute} onChange={onMinuteChangeCallback} style={{ flex: 1 }} />
    </View>
  );
};

export default DayTimeSelect2;

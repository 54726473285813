import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

const CheckIcon = () => (
  <Svg width={25.697} height={25.697}>
    <G
      transform="translate(11.275 11.276)"
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      data-name="Circle and Icon"
    >
      <Circle cx={8.485} cy={8.485} r={8.485} transform="rotate(-45 -3.314 13.372)" strokeWidth={1.2} />
      <Path d="M-1.501 1.581 1.018 4.1 5.499-.381" strokeWidth={1.5} />
    </G>
  </Svg>
);

export default CheckIcon;

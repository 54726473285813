import {
  MeroHeader,
  colors,
  Column,
  H1,
  Spacer,
  FormCard,
  Button,
  Row,
  Checkbox,
  SmallBody,
  Title,
  Body,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import { getWeekDays } from '../../../components/Calendar/BigCalendar/utils';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import { RADICAL_RED } from '@mero/components/lib/styles/colors';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { ServicesContext } from '../../../contexts/ServicesContext';
import { SubscriptionContext, withSubscriptionContextProvider } from '../../../contexts/SubscriptionContext';
import { styles } from './SelectProScreen.styles';

const ServiceSettingsScreen: React.FC = ({}) => {
  const { t } = useTranslation('pros');

  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const [showErrors, setShowErrors] = React.useState(false);

  const saveChanges = () => {
    if (availableDays.every((d) => !d && publicService)) {
      setShowErrors(true);
      return;
    }
    changeEditInfo({
      private: !publicService,
      automaticApproval: !manualApproval,
      availableDays: availableDays,
    });

    goBack();
  };

  const [{ editInfo }, { changeEditInfo }] = ServicesContext.useContext();
  const [{ subscriptionInfo }] = SubscriptionContext.useContext();

  const [publicService, setPublicService] = React.useState(!editInfo.private);
  const [manualApproval, setManualApproval] = React.useState(!editInfo.automaticApproval);

  const shouldForceManualApproval = subscriptionInfo?.shouldForceManualApproval;
  const [availableDays, setAvailableDays] = React.useState(editInfo.availableDays);

  const changeDay = (index: number) => () => {
    const newDays = availableDays.map((day, idx) => (idx === index ? !day : day));
    setAvailableDays(newDays);
  };
  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title="Setări programări" />
        <ScrollView
          style={{ flex: 1 }}
          // contentContainerStyle={styles.formMargin}
          scrollIndicatorInsets={{ right: 1 }} // This is here to fix scroll bar in the middle: https://github.com/facebook/react-native/issues/26610
        >
          <Column style={[styles.formMargin, styles.flex, { paddingTop: 24 }]}>
            <H1 style={{ paddingHorizontal: 8 }}>Setări programări</H1>
            <Spacer size={32} />
            <FormCard dropShaddow rounded paddings="button">
              <Row style={{ paddingRight: 16 }}>
                <Column>
                  <Checkbox
                    value={publicService}
                    color="blue"
                    disabled={false}
                    onValueChange={() => setPublicService(!publicService)}
                  />
                </Column>
                <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={() => setPublicService(!publicService)}>
                  <Title>{t('proServiceSettingsLabel1')}</Title>
                  <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription1')}</SmallBody>
                </TouchableOpacity>
              </Row>
              <Row style={{ paddingTop: 24, paddingRight: 16 }}>
                <Column>
                  <Checkbox
                    value={manualApproval || shouldForceManualApproval}
                    color="blue"
                    disabled={shouldForceManualApproval}
                    onValueChange={() => setManualApproval(!manualApproval)}
                  />
                </Column>
                <TouchableOpacity
                  style={{ paddingLeft: 12, flex: 1 }}
                  onPress={() => setManualApproval(!manualApproval)}
                  disabled={shouldForceManualApproval}
                >
                  <Title>{t('proServiceSettingsLabel2')}</Title>
                  <Column>
                    <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription2')}</SmallBody>
                  </Column>
                </TouchableOpacity>
              </Row>
            </FormCard>
            {publicService ? (
              <>
                <Spacer size={16} />
                <FormCard dropShaddow rounded paddings="button">
                  <Column>
                    <Body style={{ fontFamily: 'open-sans-semibold' }}>Disponibilitate serviciu</Body>
                    <Spacer size={4} />
                    <SmallBody style={{ color: colors.COMET }}>{t('daySelectorDescription')}</SmallBody>
                    <Spacer size={16} />
                    <Row justifyContent={'space-between'} style={{ paddingTop: 6 }}>
                      {getWeekDays().map((day, index) => (
                        <Column key={day} justifyContent="center" alignItems="center">
                          <Checkbox
                            value={availableDays[index]}
                            color="blue"
                            disabled={false}
                            onValueChange={changeDay(index)}
                          />
                          <SmallBody style={{ paddingTop: 10 }}>{day.slice(0, 3)}</SmallBody>
                        </Column>
                      ))}
                    </Row>
                    {showErrors && availableDays.every((d) => !d) && (
                      <>
                        <Spacer size={2} />
                        <SmallBody style={{ color: RADICAL_RED }}>
                          Alege cel puțin o zi in care vei oferi serviciul
                        </SmallBody>
                      </>
                    )}
                  </Column>
                </FormCard>
              </>
            ) : null}
            <Spacer size={144} />
          </Column>
        </ScrollView>
      </ModalScreenContainer>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text="Salvează modificări" onClick={saveChanges} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text="Salvează modificări"
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </>
  );
};

export default pipe(ServiceSettingsScreen, withSubscriptionContextProvider);

import { SavedPageBillingDetails } from '@mero/api-sdk';
import { Body, Checkbox, colors, Column, Row, SmallBody, styles as meroStyles, Spacer } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { BillingDetailsContext } from '../../../../../contexts/CheckoutSettingsContext';
import { nameGenerator } from '../../../../../utils/string';
import BillingDetailItemMore from './BillingDetailItemMore';
import DeleteBillingDetailsDialog from './DeleteBillingDetailsDialog';

type Props = {
  isSelected: boolean;
  billingDetail: SavedPageBillingDetails;
  onPress: (billingDetail: SavedPageBillingDetails) => void;
  onEdit: (billingDetail: SavedPageBillingDetails) => void;
};

const BillingDetailItem: React.FC<Props> = ({ billingDetail, isSelected, onPress, onEdit }) => {
  const { t } = useTranslation('subscription');

  const [, { reloadAsync }] = BillingDetailsContext.useContext();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handlePress = (active: boolean) => {
    if (active) {
      onPress(billingDetail);
    }
  };

  const billingDetailsFormatted = React.useMemo(() => {
    const name =
      billingDetail.billing.type === 'Company'
        ? billingDetail.billing.name
        : nameGenerator(billingDetail.billing, t('noName'));
    const contact = billingDetail.billing.type === 'Company' ? nameGenerator(billingDetail.billing.contact, '') : '';
    const address = `${billingDetail.billing.address?.address ?? ''}, ${billingDetail.billing.address?.city ?? ''} ${
      billingDetail.billing.type === 'Company'
        ? `${billingDetail.billing.fiscalCode}, ${billingDetail.billing.registrationNumber}`
        : ''
    }`;

    return { name, contact, address };
  }, [billingDetail]);

  const handleOnEdit = () => {
    onEdit(billingDetail);
  };

  const onClose = () => {
    setShowDeleteDialog(false);
  };

  return (
    <>
      <Row style={{ flex: 1 }}>
        <TouchableOpacity style={{ flexDirection: 'row', flex: 1 }} onPress={() => handlePress(!isSelected)}>
          <Checkbox value={isSelected} onValueChange={handlePress} />
          <Column style={{ paddingLeft: 16, flex: 1 }}>
            <Body style={[meroStyles.text.semibold]}>{billingDetailsFormatted.name}</Body>
            <SmallBody>{billingDetailsFormatted.contact}</SmallBody>
            <Spacer size={4} />
            <SmallBody style={{ color: colors.COMET }}>{billingDetailsFormatted.address}</SmallBody>
          </Column>
        </TouchableOpacity>
        <BillingDetailItemMore onEdit={handleOnEdit} onDelete={() => setShowDeleteDialog(true)} canDelete />
      </Row>
      {showDeleteDialog && (
        <DeleteBillingDetailsDialog
          onSuccess={flow(() => reloadAsync({ pageId: billingDetail.pageId }), onClose)}
          onCancel={onClose}
          billingDetailsId={billingDetail._id}
          type={billingDetail.billing.type}
        />
      )}
    </>
  );
};

export default BillingDetailItem;

import * as MeroApi from '@mero/api-sdk';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';

import { GrantResponseEntity } from './GrantResponseEntity';
import { AuthGrantSource, notifyAuthGrantListeners, subscribeAuthGrantChanges } from './auth-grant-listener';

const GRANT_STORAGE_KEY = 'authGrant';

const getGrant: MeroApi.users.GrantStorage['getGrant'] = async () => {
  // TODO: use cookies
  const value = localStorage ? localStorage.getItem(GRANT_STORAGE_KEY) : null;

  return pipe(
    value,
    GrantResponseEntity.decode,
    E.fold(() => undefined, identity),
  );
};

const saveGrant: MeroApi.users.GrantStorage['saveGrant'] = async (grant) => {
  if (localStorage) {
    localStorage.setItem(GRANT_STORAGE_KEY, GrantResponseEntity.encode(grant));

    notifyAuthGrantListeners(grant);
  }
};

const deleteGrant: MeroApi.users.GrantStorage['deleteGrant'] = async () => {
  if (localStorage) {
    localStorage.removeItem(GRANT_STORAGE_KEY);

    notifyAuthGrantListeners(undefined);
  }
};

const mutateGrant: MeroApi.users.GrantStorage['mutateGrant'] = async (fn) => {
  const newGrant = await fn(await getGrant());
  if (newGrant) {
    await saveGrant(newGrant);
  } else {
    await deleteGrant();
  }

  return newGrant;
};

export const meroAuthStorage: MeroApi.users.GrantStorage & AuthGrantSource = {
  getGrant,
  mutateGrant,
  deleteGrant,
  saveGrant,
  subscribeAuthGrantChanges,
};

import { Column } from '@mero/components';
import * as React from 'react';
import { Platform, ViewProps } from 'react-native';

import { useMediaQueries } from '../../hooks/useMediaQueries';

type Props = ViewProps & { position: 'bottom' | 'center' };

const PositionStyle = {
  bottom: {
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    height: '100vh',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
} as const;

const MobileWebModalWrapper: React.FC<Props> = ({ children, ...props }) => {
  const { isPhone } = useMediaQueries();
  const isWeb = Platform.OS === 'web';
  React.useEffect(() => {
    if (isPhone && isWeb) {
      document.body.style.overflow = 'hidden';
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    return () => {
      if (isPhone && isWeb) {
        document.body.style.overflow = 'auto';
      }
    };
  }, []);

  if (isPhone && isWeb) {
    //@ts-expect-error Styling specific for mobile web
    return <Column style={[props.style, PositionStyle[props.position]]}>{children}</Column>;
  }

  return <>{children}</>;
};

export default MobileWebModalWrapper;

import { ClientId, DateInterval } from '@mero/api-sdk';
import { CheckoutClientSalesReportItem } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutClientSalesReportItem';
import { CheckoutClientSalesReportTotals } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutClientSalesReportTotals';
import { CheckoutReportByClientSortType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutReportByClientSortType';
import { PageId } from '@mero/api-sdk/dist/pages';
import { SmallBody, styles as meroStyles, colors } from '@mero/components';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';

import { useIsFocused } from '@react-navigation/native';

import { meroApi } from '../../../../../../contexts/AuthContext';
import navigation from '../../../../../../navigation';
import log from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import Table, { Column } from './Table';

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  itemType: 'all' | 'services' | 'products' | 'memberships';
  navigateClient: (clientId: ClientId) => void;
  updateSort: (sort: { readonly by: CheckoutReportByClientSortType; readonly dir: SortDirection } | undefined) => void;
};

const Clients: React.FC<Props> = ({ pageId, dateInterval, itemType, updateSort, navigateClient }) => {
  const { t } = useTranslation('reports');
  const isFocused = useIsFocused();
  const [reports, setReports] = React.useState<CheckoutClientSalesReportItem<MeroUnits.Any>[]>([]);
  const [total, setTotal] = React.useState<CheckoutClientSalesReportTotals<MeroUnits.Any>>();
  const [nextPage, setNextPage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sort, setSort] = React.useState<
    | {
        readonly by: CheckoutReportByClientSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >(undefined);
  const lastRequest = React.useRef<symbol>(Symbol());
  const loadingNextPage = React.useRef('');

  const getData = async (page?: string) => {
    setIsLoading(true);
    const request = Symbol();
    lastRequest.current = request;
    try {
      const response = await meroApi.checkoutReports.getSalesByClientReport({
        pageId,
        unit: 'RON',
        interval: dateInterval,
        itemType,
        sort,
        page,
      });

      if (request === lastRequest.current) {
        setNextPage(response.next);
        setTotal(response.data.totals);
        setReports((prev) => (typeof page === 'string' ? [...prev, ...response.data.items] : response.data.items));
      }
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortBy = async (field: string, direction: SortDirection) => {
    switch (field) {
      case 'name':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'percentage':
        setSort({ by: 'gross', dir: direction });
        break;
      case 'appointments':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'all':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'services':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'products':
        setSort({ by: 'products-count', dir: direction });
        break;
      case 'memberships':
        setSort({ by: 'memberships-count', dir: direction });
        break;
      case 'net':
        setSort({ by: 'net', dir: direction });
        break;
      case 'gross':
        setSort({ by: 'gross', dir: direction });
        break;
      case 'protocol':
        setSort({ by: 'protocol', dir: direction });
        break;
      case 'discount':
        setSort({ by: 'discount', dir: direction });
        break;
      case 'total':
        setSort({ by: 'total', dir: direction });
        break;
      default:
        getData();
        break;
    }
  };

  const onLoadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      loadingNextPage.current = nextPage;
      getData(nextPage);
    }
  };

  React.useEffect(() => {
    getData();
  }, [itemType, JSON.stringify(sort), JSON.stringify(dateInterval)]);

  // React.useEffect(() => {
  //   if (isFocused) {
  //     getData();
  //   }
  // }, [isFocused]);

  React.useEffect(() => {
    updateSort(sort);
  }, [sort]);

  const { columns, data, fixedRows } = React.useMemo(() => {
    const data = reports.map((report) => ({
      _id: report.client._id,
      name: report.client.firstname ? `${report.client.firstname} ${report.client.lastname ?? ''}` : t('noName'),
      percentage: `${scaledToString(report.percents.total)} %`,
      appointments: report.appointmentsCount,
      services: report.servicesCount,
      products: report.productsCount,
      memberships: report.membershipsCount,
      net: `${scaledToString(report.totals.net.amount)} ${t(report.totals.net.unit)}`,
      gross: `${scaledToString(report.totals.gross.amount)} ${t(report.totals.gross.unit)}`,
      protocol: `${scaledToString(report.totals.protocol.amount)} ${t(report.totals.protocol.unit)}`,
      discount: `${scaledToString(report.totals.discount.amount)} ${t(report.totals.discount.unit)}`,
      total: `${scaledToString(report.totals.total.amount)} ${t(report.totals.total.unit)}`,
    }));

    const totalPlaceholderId = 'total' as ClientId;

    const fixedRows = total
      ? [
          {
            _id: totalPlaceholderId,
            name: t('totalRow'),
            percentage: `100 %`,
            appointments: total.appointmentsCount,
            services: total.servicesCount,
            products: total.productsCount,
            memberships: total.membershipsCount,
            net: `${scaledToString(total.totals.net.amount)} ${t(total.totals.net.unit)}`,
            gross: `${scaledToString(total.totals.gross.amount)} ${t(total.totals.gross.unit)}`,
            protocol: `${scaledToString(total.totals.protocol.amount)} ${t(total.totals.protocol.unit)}`,
            discount: `${scaledToString(total.totals.discount.amount)} ${t(total.totals.discount.unit)}`,
            total: `${scaledToString(total.totals.total.amount)} ${t(total.totals.total.unit)}`,
          },
        ]
      : [];

    const columns: Column<(typeof data)[number]>[] = [
      {
        field: 'name',
        headerName: t('clientColumn'),
        width: '20%',
        sortable: false,
        CustomComponent: ({ children, _id }) => (
          <TouchableOpacity onPress={() => navigateClient(_id)} disabled={_id === totalPlaceholderId}>
            <SmallBody style={[meroStyles.text.semibold, _id !== totalPlaceholderId && { color: colors.DARK_BLUE }]}>
              {children}
            </SmallBody>
          </TouchableOpacity>
        ),
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'percentage',
        headerName: '%',
        width: '7%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'appointments',
        headerName: t('appointmentsColumn'),
        width: '10%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        hidden: itemType !== 'all' && itemType !== 'services',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      // {
      //   field: 'services',
      //   headerName: t(`servicesColumn`),
      //   width: '10%',
      //   sortable: true,
      //   sortingMode: 'server',
      //   sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
      //   hidden: itemType !== 'all' && itemType !== 'services',
      //   style: {
      //     header: {
      //       textAlign: 'right',
      //     },
      //     cell: {
      //       textAlign: 'right',
      //     },
      //     fixedCell: {
      //       fontFamily: 'open-sans-semibold',
      //     },
      //   },
      // },
      {
        field: 'products',
        headerName: t(`productsColumn`),
        width: '10%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        hidden: itemType !== 'all' && itemType !== 'products',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'memberships',
        headerName: t(`membershipsColumn`),
        width: '9%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        hidden: itemType !== 'all' && itemType !== 'memberships',
        // hidden: true,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'net',
        headerName: t('netColumn'),
        width: '12%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('net', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'gross',
        headerName: t('grossColumn'),
        width: '12%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('gross', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'protocol',
        headerName: t('protocolColumn'),
        width: '10%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'discount',
        headerName: t('discountColumn'),
        width: '10%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'total',
        headerName: t('totalColumn'),
        width: '10%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('total', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    return {
      columns,
      data,
      fixedRows,
    };
  }, [reports, total]);

  return (
    <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, zIndex: -1 }}>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        loadMore={onLoadMore}
        fixedRows={fixedRows}
        defaultSortBy="total"
        defaultSortDir="ASC"
      />
    </FormCard>
  );
};

export default Clients;

import { Service, WorkerService } from '@mero/api-sdk/dist/services';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  AvoidKeyboard,
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { IntFromString } from 'io-ts-types';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import { getWeekDays } from '../../../../../components/Calendar/BigCalendar/utils';
import MenuItem from '../../../../../components/MenuItem';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { RADICAL_RED } from '@mero/components/lib/styles/colors';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { WorkerServicesSettingsStackParamList } from '../../../../../types';
import * as BitPacker from '../../../../../utils/bit-packer';
import log from '../../../../../utils/log';
import { DURATION_MIN } from '../../../../../utils/time';
import ColorMenu, { SERVICE_COLORS } from '../../../ServicesScreen/ColorMenu';
import { PriceType, hours, mapColor, minutes, priceTypes } from '../../../ServicesScreen/ServiceScreen';
import { styles } from './WorkerServicesSettingsScreen.styles';

export type Props = StackScreenProps<WorkerServicesSettingsStackParamList, 'WorkerServiceDetails'>;

const INPUT_POSITIONS = {
  serviceName: 50,
  category: 120,
  group: 220,
  description: 300,
  durationInMinutes: 570,
  availableDays: 670,
  fixedPrice: 500,
  discountPrice: 600,
  startPrice: 600,
  endPrice: 600,
} as const;

const WorkerEditServiceScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const isFocused = useIsFocused();

  const goBack = useGoBack();

  const getAvailableDays = (bitBucket: number) => {
    return Array(7)
      .fill(false)
      .map((_, dayOfWeek) => BitPacker.isFlagSet(bitBucket, 1 << dayOfWeek));
  };

  const arrayToBitBucket = (arr: boolean[]) =>
    arr.reduce((acc, v, dayOfWeek) => BitPacker[v ? 'setFlag' : 'unsetFlag'](acc, 1 << dayOfWeek), 0);

  const { workerId, serviceId } = route.params;

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const block = React.useRef(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showColorMenu, setShowColorMenu] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  const [proDetails, setProDetails] = React.useState<SavedWorker | null>(null);
  const [serviceDetails, setServiceDetails] = React.useState<Service | null>(null);

  const [durationHour, setDurationHour] = React.useState(0);
  const [durationMinutes, setDurationMinutes] = React.useState(30);

  const [durationInMinutes, setDurationInMinutes] = React.useState(30);

  const [availableDays, setAvailableDays] = React.useState([true, true, true, true, true, false, false]);

  const [color, setColor] = React.useState(SERVICE_COLORS[0]);

  const [priceType, setPriceType] = React.useState('fixed');
  const [showDiscount, setShowDiscount] = React.useState<boolean>(false);

  const [fixedPrice, setFixedPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const fixedPriceValid = E.isRight(fixedPrice.decoded);

  const [discountPrice, setDiscountPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const discountPriceValid = E.isRight(discountPrice.decoded);

  const [startPrice, setStartPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const startPriceValid = E.isRight(startPrice.decoded);
  const [endPrice, setEndPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const endPriceValid = E.isRight(endPrice.decoded);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);
      if (worker) {
        const service = worker.services.find((service) => service._id === serviceId);
        const activeColor = mapColor(service?.color);

        setServiceDetails(service ?? null);
        setDurationHour(service?.durationInMinutes ? Math.floor(service?.durationInMinutes / 60) : 0);
        setDurationMinutes(service?.durationInMinutes ? service?.durationInMinutes % 60 : 30);
        setDurationInMinutes(service?.durationInMinutes ?? 30);
        setAvailableDays(getAvailableDays(service?.availableDays ?? 0));
        setColor(activeColor && SERVICE_COLORS.includes(activeColor) ? activeColor : SERVICE_COLORS[0]);
        setPriceType(service?.price.type ?? 'fixed');
        setShowDiscount(Boolean(service?.price.type === 'fixed' && service.price.promo));

        if (service?.price.type === 'fixed') {
          setFixedPrice({
            input: `${service.price.fixed}`,
            decoded: IntFromString.decode(`${service.price.fixed}`),
          });

          if (service.price.promo) {
            setDiscountPrice({
              input: `${service.price.promo}`,
              decoded: IntFromString.decode(`${service.price.promo}`),
            });
          }
        } else if (service?.price.type === 'range') {
          setStartPrice({
            input: `${service.price.range.from}`,
            decoded: IntFromString.decode(`${service.price.range.from}`),
          });
          setEndPrice({
            input: `${service.price.range.to}`,
            decoded: IntFromString.decode(`${service.price.range.to}`),
          });
        }
      }
      setProDetails(worker ?? null);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    setDurationInMinutes(durationHour * 60 + durationMinutes);
  }, [durationHour, durationMinutes]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);
      setProDetails(worker ?? null);
    }
  }, [isFocused]);

  const changeDay = (index: number) => () => {
    const newDays = availableDays.map((day, idx) => (idx === index ? !day : day));
    setAvailableDays(newDays);
  };

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const generatePrice = () => {
    if (priceType === 'fixed' && showDiscount) {
      return {
        price: {
          type: PriceType[priceType],
          fixed: +fixedPrice.input,
          promo: +discountPrice.input,
        },
      };
    } else if (priceType === 'fixed') {
      return {
        price: {
          type: PriceType[priceType],
          fixed: +fixedPrice.input,
        },
      };
    } else if (priceType === 'range') {
      return {
        price: {
          type: PriceType[priceType],
          range: {
            from: +startPrice.input,
            to: +endPrice.input,
          },
        },
      };
    }

    return {
      price: {
        type: PriceType.hidden,
      },
    };
  };

  const generatePayload = () => ({
    durationInMinutes,
    availableDays: arrayToBitBucket(availableDays),
    color,
    ...generatePrice(),
  });

  const saveChanges = async () => {
    if (!proDetails || pageState.type !== 'Loaded') {
      return;
    }
    setIsLoading(true);
    try {
      const workerServices = proDetails.services
        .filter((s) => s._id !== serviceId)
        .map((s) => ({
          ...s,
          price: {
            ...s.price,
            type: PriceType[s.price.type],
          },
        }));

      const service = proDetails.services.find((s) => s._id === serviceId);

      if (service) {
        await meroApi.pages.updatePageWorkerServices({
          pageId: pageState.page.details._id,
          workerId: proDetails._id,
          services: [
            //@ts-expect-error @TODO Price type has a different format...
            {
              ...service,
              isCustom: true,
              ...generatePayload(),
            },
            //@ts-expect-error @TODO Price type has a different format...
            ...workerServices,
          ],
        });

        await reloadAsync();

        toast.show({
          type: 'success',
          text: t('savedSuccessfully'),
        });

        goBack();
      }
    } catch (error) {
      log.error('Error updating service', error);
      toast.show({
        type: 'error',
        text: t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetSettings = (services: WorkerService[]) => {
    const service = services.find((service) => service._id === serviceId);
    if (service && proDetails) {
      const activeColor = mapColor(service?.color);

      const workerService = proDetails.services.map((s) => {
        if (s._id === service._id) {
          return service;
        }
        return s;
      });

      setProDetails({
        ...proDetails,
        services: workerService,
      });

      setDurationHour(Math.floor(service.durationInMinutes / 60));
      setDurationMinutes(service.durationInMinutes % 60);
      setDurationInMinutes(service.durationInMinutes);
      setAvailableDays(getAvailableDays(service.availableDays ?? 0));
      setColor(activeColor && SERVICE_COLORS.includes(activeColor) ? activeColor : SERVICE_COLORS[0]);
      setPriceType(service.price.type);
      setShowDiscount(Boolean(service.price.type === 'fixed' && service.price.promo));

      if (service.price.type === 'fixed') {
        setFixedPrice({
          input: `${service.price.fixed}`,
          decoded: IntFromString.decode(`${service.price.fixed}`),
        });

        if (service.price.promo) {
          setDiscountPrice({
            input: `${service.price.promo}`,
            decoded: IntFromString.decode(`${service.price.promo}`),
          });
        }
      } else if (service.price.type === 'range') {
        setStartPrice({
          input: `${service.price.range.from}`,
          decoded: IntFromString.decode(`${service.price.range.from}`),
        });
        setEndPrice({
          input: `${service.price.range.to}`,
          decoded: IntFromString.decode(`${service.price.range.to}`),
        });
      }
    }
  };

  const getPriceInput = () => {
    switch (priceType) {
      case 'fixed':
        return (
          <>
            <Column>
              <Row alignItems="center" style={{ paddingBottom: 8 }}>
                <Title style={{ flex: 1 }}>Valoare (lei)</Title>
                <TouchableOpacity onPress={() => setShowDiscount(!showDiscount)}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>
                    {t('priceOffer')}
                  </SmallBody>
                </TouchableOpacity>
              </Row>
              <TypeSafeTextInput
                codec={IntFromString}
                value={fixedPrice.input}
                showError={showErrors}
                onChange={setFixedPrice}
                keyboardType="numeric"
                placeholder={t('pricePlaceholder')}
                onFocus={() => scrollTo('fixedPrice')}
              />
              {showErrors && !fixedPriceValid && (
                <>
                  <Spacer size={2} />
                  <SmallBody style={{ color: RADICAL_RED }}>{t('priceError')}</SmallBody>
                </>
              )}
            </Column>
            {showDiscount && (
              <>
                <Spacer size={16} />
                <InputWithLabel
                  label={t('discount')}
                  isError={showErrors && !discountPriceValid}
                  errorText={t('discountError')}
                >
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={discountPrice.input}
                    showError={showErrors}
                    onChange={setDiscountPrice}
                    keyboardType="numeric"
                    placeholder={t('discountPlaceholder')}
                    onFocus={() => scrollTo('discountPrice')}
                  />
                </InputWithLabel>
              </>
            )}
          </>
        );
      case 'range':
        return (
          <>
            <InputWithLabel
              label={t('rangePrice')}
              isError={showErrors && (!startPriceValid || !endPriceValid)}
              errorText={startPriceValid ? t('endPriceError') : t('startPriceError')}
            >
              <Row>
                <Column style={{ flex: 2, paddingRight: 4 }}>
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={startPrice.input}
                    showError={showErrors}
                    onChange={setStartPrice}
                    keyboardType="numeric"
                    placeholder={t('startPricePlaceholder')}
                    onFocus={() => scrollTo('startPrice')}
                  />
                </Column>
                <Column style={{ flex: 2, paddingLeft: 4 }}>
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={endPrice.input}
                    showError={showErrors}
                    onChange={setEndPrice}
                    keyboardType="numeric"
                    placeholder={t('endPricePlaceholder')}
                    onFocus={() => scrollTo('endPrice')}
                  />
                </Column>
              </Row>
            </InputWithLabel>
            <Spacer size={16} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={serviceDetails?.name}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={proDetails?.profilePhoto?.thumbnail}
              firstname={proDetails?.user.firstname ?? ''}
              lastname={proDetails?.user.lastname ?? ''}
            />
          </Column>
        )}
      />
      {proDetails && serviceDetails && (
        <AvoidKeyboard style={{ flex: 1 }}>
          <ScrollView>
            <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
              <H1 style={{ paddingHorizontal: 8 }}>{serviceDetails.name}</H1>
              <Body style={{ padding: 8 }}>
                <Trans
                  ns={'pros'}
                  t={t}
                  i18nKey="proEditServiceDescription"
                  values={{
                    name: `${proDetails.user.firstname} ${proDetails.user.lastname}`,
                  }}
                >
                  0<Title style={{ fontFamily: 'open-sans-semibold' }}></Title>2{' '}
                </Trans>
              </Body>
              <Spacer size={32} />
              <FormCard dropShaddow rounded paddings="inputs">
                <InputWithLabel label={t('serviceDuration')}>
                  <Row>
                    <Column style={{ flex: 2, paddingRight: 4 }}>
                      <Select items={hours} value={durationHour} onChange={setDurationHour} />
                    </Column>
                    <Column style={{ flex: 2, paddingLeft: 4 }}>
                      <Select items={minutes} value={durationMinutes} onChange={setDurationMinutes} />
                    </Column>
                  </Row>
                  {showErrors && durationInMinutes < DURATION_MIN && (
                    <>
                      <Spacer size={2} />
                      <SmallBody style={{ color: RADICAL_RED }}>{t('serviceDurationError')}</SmallBody>
                    </>
                  )}
                </InputWithLabel>
                <Spacer size="16" />
                <InputWithLabel label={t('serviceAvailability')}>
                  <Row justifyContent={'space-between'} style={{ paddingTop: 6 }}>
                    {getWeekDays().map((day, index) => (
                      <Column key={day} justifyContent="center" alignItems="center">
                        <Checkbox
                          value={availableDays[index]}
                          color="blue"
                          disabled={false}
                          onValueChange={changeDay(index)}
                        />
                        <SmallBody style={{ paddingTop: 10 }}>{day.slice(0, 3)}</SmallBody>
                      </Column>
                    ))}
                  </Row>
                  {showErrors && availableDays.every((d) => !d) && (
                    <>
                      <Spacer size={2} />
                      <SmallBody style={{ color: RADICAL_RED }}>{t('serviceAvailabilityError')}</SmallBody>
                    </>
                  )}
                </InputWithLabel>
                <Spacer size="16" />
                <InputWithLabel label={t('serviceColor')}>
                  <TouchableOpacity
                    style={{
                      height: 43,
                      justifyContent: 'center',
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 12,
                      borderColor: colors.GEYSER,
                    }}
                    onPress={() => setShowColorMenu(true)}
                  >
                    <Column style={{ backgroundColor: color, height: 15 }} />
                  </TouchableOpacity>
                </InputWithLabel>
                <Spacer size="16" />
              </FormCard>
              <Spacer size="16" />
              <FormCard dropShaddow rounded paddings="inputs">
                <InputWithLabel label={t('priceType')}>
                  <Select items={priceTypes} value={priceType} onChange={(v) => setPriceType(v)} />
                </InputWithLabel>
                <Spacer size="16" />
                {getPriceInput()}
              </FormCard>
              <Spacer size="16" />
              <FormCard dropShaddow rounded paddings="button">
                <MenuItem
                  title={t('profileCalendar')}
                  icon="pro-menu-calendar-settings"
                  onPress={() => navigation.navigate('WorkerServiceSettings', { serviceId, workerId })}
                />
              </FormCard>
              <Spacer size="32" />
              <Column justifyContent="center" alignItems="center">
                <Button
                  expand={false}
                  backgroundColor={colors.ALABASTER}
                  color={colors.DARK_BLUE}
                  text={t('resetService')}
                  onClick={() => {
                    if (pageState.type === 'Loaded') {
                      resetSettings(
                        pageState.page.details.services.map((s) => ({
                          ...s,
                          pageId: pageState.page.details._id,
                        })),
                      );
                    }
                  }}
                />
                <Spacer size="8" />
                <Body style={{ textAlign: 'center' }}>{t('resetServiceDescription')}</Body>
              </Column>
            </Column>
          </ScrollView>
        </AvoidKeyboard>
      )}

      {showColorMenu && (
        <ColorMenu
          value={color}
          onSave={(color: string) => {
            setColor(color);
            setShowColorMenu(false);
          }}
          onDismiss={() => setShowColorMenu(false)}
        />
      )}

      {!showColorMenu && (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={saveChanges} />
            ) : (
              <Button
                disabled={block.current || isLoading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('saveChanges')}
                onClick={saveChanges}
              />
            )}
          </SafeAreaView>
        </FormCard>
      )}
    </ModalScreenContainer>
  );
};

export default WorkerEditServiceScreen;

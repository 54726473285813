import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Column } from '@mero/components';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, Pressable } from 'react-native';

import { useIsFocused } from '@react-navigation/native';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../contexts/AuthContext';
import { promiseDebounce } from '../../../../utils/functions';
import log from '../../../../utils/log';
import PendingApprovalsMobileIcon from './PendingApprovalIcon';

export type Props = {
  onPress: () => void;
  pageId: PageId;
  color?: string;
};

export const PendingApprovalsButton: React.FC<Props> = ({ onPress, pageId, color }) => {
  const [hasPendingApprovals, setHasPendingApprovals] = useState(false);
  const initialRender = useRef(true);
  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();

  useEffect(() => {
    initialRender.current = false;
  }, []);

  useEffect(() => {
    if (isFocused) {
      promiseDebounce(
        'getWorkerWaitingList',
        () =>
          meroApi.calendar
            .fetchPagedWaitingList({ pageId, limit: 1 })
            .then((wl) => setHasPendingApprovals(wl.data.length > 0))
            .catch(log.error),
        1000,
      );
    }
  }, [isFocused]);

  return (
    <Pressable onPress={onPress} style={styles.button}>
      <Column style={!isPhone && styles.webWrapper}>
        <PendingApprovalsMobileIcon height={isPhone ? 36 : 32} width={isPhone ? 36 : 32} color={color} />
      </Column>
      {hasPendingApprovals && <Column style={[styles.badge, { top: isPhone ? 4 : 2, right: isPhone ? 4 : 2 }]} />}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    width: 8,
    height: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.WHITE,
    backgroundColor: colors.RADICAL_RED,
  },
  webWrapper: {
    width: 43,
    height: 43,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.GEYSER,
    borderWidth: 1,
    borderRadius: 43,
  },
  button: { position: 'relative', zIndex: 1 },
});

import { Column, Body, colors } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string;
};

const InvitedProWarning: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation('pros');

  return (
    <Column
      style={{ paddingHorizontal: 12, paddingVertical: 8, backgroundColor: colors.YELLOW_ORANGE, borderRadius: 6 }}
    >
      <Body style={{ color: colors.WHITE }}>{message ?? t('invitedProWarning')}</Body>
    </Column>
  );
};

export default InvitedProWarning;

import { colors, Line, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { styles } from './ItemMenu.styles';

export type Props = {
  onSelect: () => void;
  onDismiss: () => void;
};

const ItemMenu: React.FC<Props> = ({ onDismiss, onSelect }) => {
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation('membership');

  return (
    <ModalOverlay style={isDesktop && { alignItems: 'flex-end' }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom, isDesktop && { width: 500 }]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSelect}>
            <Title style={{ color: colors.RADICAL_RED }}>{t('removeItem')}</Title>
          </TouchableOpacity>
          <Line />
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ItemMenu;

import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H2s,
  HSpacer,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { defaultValue, useCompletedSteps } from '../../../../../hooks/useCompletedSteps';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext, CurrentPageInfo } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, LocationProfileStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

export const MINIMUM_PHOTO_COUNT = 5;
export const MINIMUM_SERVICE_COUNT = 3;
export const MINIMUM_APPOINTMENT_COUNT = 10;
export const DEFAULT_BUSINESS_HOURS = [
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: false,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 0,
    },
    to: {
      hour: 18,
      minute: 0,
    },
    active: false,
    wholeDay: false,
  },
];

type StepCompletedProps = {
  label: string;
  onPress?: () => void;
};
const StepCompleted: React.FC<StepCompletedProps> = ({ label, onPress }) => {
  return (
    <TouchableOpacity style={{ flexDirection: 'row' }} onPress={onPress}>
      <Icon type="checked" color="#2DCE89" />
      <HSpacer left="s" />
      <Title style={{ textDecorationLine: 'line-through', color: '#2DCE89', width: '100%' }}>{label}</Title>
    </TouchableOpacity>
  );
};

type StepNotCompletedProps = {
  label: string;
  description: string;
  open: boolean;
  onOpenChange: (v: boolean) => void;
  buttonLabel: string;
  boost?: number;
  onPress: () => void;
};
const StepNotCompleted: React.FC<StepNotCompletedProps> = ({
  label,
  description,
  open,
  onOpenChange,
  buttonLabel,
  boost = 0,
  onPress,
}) => {
  const { t } = useTranslation('location');

  const [isOpen, setIsOpen] = React.useState(open);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggleOpen = React.useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    onOpenChange(!isOpen);
  }, []);

  return (
    <Row>
      <TouchableOpacity onPress={toggleOpen}>
        {isOpen ? <Icon type="down" color={colors.BLACK} /> : <Icon type={'arrow-right'} color={colors.BLACK} />}
      </TouchableOpacity>
      <HSpacer left="s" />
      <Column style={{ flex: 1 }}>
        <TouchableOpacity onPress={toggleOpen} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Title>{label}</Title>
          {isOpen ? null : (
            <Row>
              <BoostIcon />
              <Title style={{ color: colors.ELECTRIC_VIOLET, fontSize: 12 }}>+{boost}</Title>
            </Row>
          )}
        </TouchableOpacity>
        {isOpen && (
          <>
            <Spacer size={8} />
            <SmallBody>{description}</SmallBody>
            <Spacer size={16} />
            <Button onClick={onPress} text={buttonLabel} />
            <Spacer size={12} />
            {boost > 0 && (
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <BoostIcon />
                <Title style={{ color: colors.ELECTRIC_VIOLET, fontSize: 12 }}>
                  {t('extraBoost', { count: boost })}
                </Title>
              </Row>
            )}
          </>
        )}
      </Column>
    </Row>
  );
};

const BoostIconLarge = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7297">
      <Path data-name="Rectangle 55" fill="none" d="M0 0h24v24H0z" />
      <Path
        d="M10.068 16.638a5.156 5.156 0 0 1-4.649 2.947A5.2 5.2 0 0 1 8.4 14.937l.361.369a4.668 4.668 0 0 0-1.519 2.452 4.659 4.659 0 0 0 2.468-1.485ZM9.263 8.6a6.129 6.129 0 0 0-4.084 1.856A6.517 6.517 0 0 0 4 12.055a3.08 3.08 0 0 1 3.646-.289A15.935 15.935 0 0 1 9.263 8.6ZM12.949 21a6.475 6.475 0 0 0 1.6-1.179 6.1 6.1 0 0 0 1.845-4.1 17.328 17.328 0 0 1-3.163 1.626A3.082 3.082 0 0 1 12.949 21Zm8.013-16.969Q20.507 4 20.069 4C13.454 4 9.822 9.26 8.664 13.215l3.136 3.136c4.094-1.314 9.2-4.8 9.2-11.328v-.042c0-.31-.013-.626-.038-.95ZM16.75 9.667a1.417 1.417 0 1 1 1.417-1.417 1.416 1.416 0 0 1-1.417 1.417Z"
        fill="#7431f7"
      />
    </G>
  </Svg>
);
const BoostIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 6844">
      <Path data-name="Rectangle 55" fill="none" d="M0 0h24v24H0z" />
      <Path
        d="M10 16.137a4.246 4.246 0 0 1-3.828 2.427 4.279 4.279 0 0 1 2.452-3.828l.3.3a3.845 3.845 0 0 0-1.251 2.019A3.837 3.837 0 0 0 9.7 15.836Zm-.666-6.616a5.047 5.047 0 0 0-3.363 1.524A5.367 5.367 0 0 0 5 12.363a2.536 2.536 0 0 1 3-.234 13.123 13.123 0 0 1 1.334-2.608Zm3.036 10.208a5.332 5.332 0 0 0 1.319-.971 5.023 5.023 0 0 0 1.519-3.373 14.27 14.27 0 0 1-2.608 1.344 2.538 2.538 0 0 1-.23 3Zm6.599-13.974q-.375-.026-.736-.026c-5.447 0-8.438 4.332-9.392 7.589l2.583 2.583C14.8 14.819 19 11.947 19 6.571v-.034c0-.255-.011-.516-.031-.782ZM15.5 10.396a1.167 1.167 0 1 1 1.167-1.167 1.166 1.166 0 0 1-1.167 1.167Z"
        fill="#7431f7"
      />
    </G>
  </Svg>
);

const CloseIcon = () => (
  <Svg data-name="Group 5822" width={24} height={24}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path
      d="m6.376 16.68 4.62-4.619-4.62-4.62a.754.754 0 1 1 1.066-1.066l4.62 4.62 4.62-4.62a.754.754 0 0 1 1.065 1.066l-4.62 4.62 4.62 4.619a.754.754 0 0 1-1.066 1.066l-4.62-4.619-4.619 4.62a.754.754 0 1 1-1.065-1.066Z"
      fill="#454f63"
    />
  </Svg>
);

type Props = {
  page: CurrentPageInfo;
  onChange: (status: boolean) => void;
};
const EnableOnlineProfile: React.FC<Props> = ({ page, onChange }) => {
  const { t } = useTranslation('location');
  const { isDesktop } = useMediaQueries();

  const [, { reloadAsync }] = CurrentBusinessContext.useContext();

  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();
  const isFocused = useIsFocused();

  const [isVisible, setIsVisible] = React.useState(true);

  const [open, setOpen] = React.useState(defaultValue);
  const [steps, setSteps] = useCompletedSteps();

  const progress = React.useMemo(() => {
    const totalSteps = Object.keys(steps).length;
    const completedSteps = Object.values(steps).filter((step) => step).length;
    return Math.ceil((100 / totalSteps) * completedSteps);
  }, [steps]);

  const firstUncompletedStep = React.useMemo(() => {
    const uncompletedSteps = Object.entries(steps).filter(([, value]) => !value);
    return uncompletedSteps.length > 0 ? uncompletedSteps[0][0] : undefined;
  }, [steps]);

  const toggleOpen = (step: keyof typeof defaultValue) => (state: boolean) => {
    if (state) {
      setOpen({
        ...defaultValue,
        [step]: state,
      });
    }
  };

  React.useEffect(() => {
    if (firstUncompletedStep) {
      setOpen({
        ...defaultValue,
        [firstUncompletedStep]: true,
      });
    }
  }, [firstUncompletedStep]);

  React.useEffect(() => {
    if (isFocused) {
      reloadAsync();
    }
  }, [isFocused]);

  React.useEffect(() => {
    const updateStepsStatus = async () => {
      try {
        const today = DateTime.utc().startOf('day');
        const from = today.plus({ days: -14 });
        const to = today.plus({ month: 1 });

        const [scheduleChanged, analytics] = await Promise.all([
          AppStorage.getScheduleChanged(),
          meroApi.analytics.getAnalyticsNew({
            pageId: page.details._id,
            from: from.toJSDate(),
            to: to.toJSDate(),
          }),
        ]);

        const updatedSteps = {
          step1: true,
          step2: Boolean(page.details.location),
          step3: page.details.images.length >= MINIMUM_PHOTO_COUNT,
          step4:
            JSON.stringify(page.details.businessHours) !== JSON.stringify(DEFAULT_BUSINESS_HOURS) ||
            scheduleChanged === page.details._id,
          step5: page.details.services.length >= MINIMUM_SERVICE_COUNT,
          step6: analytics.bookings.totalCount >= MINIMUM_APPOINTMENT_COUNT,
        };

        const allStepsCompleted = Object.values(updatedSteps).every((step) => step);

        if (allStepsCompleted) {
          getSavedVisibility();
        } else {
          AppStorage.setPublishBannerHideState(page.details._id, false).catch((error) =>
            log.error('Error saving publish banner hide state', error),
          );
        }
        onChange(allStepsCompleted);
        setSteps(updatedSteps);
      } catch (error) {
        log.debug('Error while updating steps status', error);
      }
    };

    updateStepsStatus();
  }, [page]);

  const getSavedVisibility = () => {
    AppStorage.getPublishBannerHideState(page.details._id)
      .then((state) => setIsVisible((currentState) => currentState && !state))
      .catch((error) => log.error('Error while getting publish banner state', error));
  };

  React.useEffect(() => {
    onChange(!isVisible);
  }, []);

  if (!isVisible) {
    return null;
  }

  const hideBanner = async () => {
    setIsVisible(false);
    await AppStorage.setPublishBannerHideState(page.details._id, true);
  };

  return (
    <>
      <Spacer size={32} />
      {progress < 100 ? (
        <FormCard
          paddings="none"
          style={{
            marginTop: 6,
            paddingHorizontal: 24,
            paddingTop: 24,
            paddingBottom: 32,
            width: '100%',
          }}
        >
          <H2s>{t('enableOnlineProfileTitle')}</H2s>
          <Spacer size={4} />
          <Body>{t('enableOnlineProfileDescription')}</Body>
          {/*<Spacer size={16} />*/}
          {/*<Row>*/}
          {/*  <BoostIconLarge />*/}
          {/*  <HSpacer left="s" />*/}
          {/*  <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.ELECTRIC_VIOLET }}>*/}
          {/*    {t('enableOnlineProfileBoost')}*/}
          {/*  </SmallBody>*/}
          {/*</Row>*/}
          <Spacer size={32} />
          <SmallBody style={{ fontFamily: 'open-sans-bold' }}>
            {t('progress')} <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{progress}%</SmallBody>
          </SmallBody>
          <Spacer size={8} />
          <Row style={{ height: 8, borderRadius: 4, overflow: 'hidden' }}>
            <Column style={{ width: `${progress}%`, backgroundColor: '#2DCE89' }} />
            <Column style={{ width: `${100 - progress}%`, backgroundColor: '#D8D8D8' }} />
          </Row>
          <Spacer size={24} />
          <StepCompleted label={t('enableOnlineProfileStep1')} />
          <Spacer size={12} />
          <Line />
          <Spacer size={12} />
          {steps.step2 ? (
            <StepCompleted
              label={t('enableOnlineProfileStep2')}
              onPress={() =>
                navigation.navigate('Menu', { screen: 'LocationProfileScreen', params: { screen: 'LocationAddress' } })
              }
            />
          ) : (
            <StepNotCompleted
              label={t('enableOnlineProfileStep2')}
              description={t('enableOnlineProfileStep2Description')}
              open={open.step2}
              onOpenChange={toggleOpen('step2')}
              onPress={() =>
                navigation.navigate('Menu', { screen: 'LocationProfileScreen', params: { screen: 'LocationAddress' } })
              }
              buttonLabel={t('enableOnlineProfileStep2Button')}
            />
          )}
          <Spacer size={12} />
          <Line />
          <Spacer size={12} />
          {steps.step3 ? (
            <StepCompleted
              label={t('enableOnlineProfileStep3')}
              onPress={() =>
                navigation.navigate('Menu', {
                  screen: 'LocationProfileScreen',
                  params: { screen: 'LocationGallerySettingsScreen' },
                })
              }
            />
          ) : (
            <StepNotCompleted
              label={t('enableOnlineProfileStep3')}
              description={t('enableOnlineProfileStep3Description')}
              open={open.step3}
              onOpenChange={toggleOpen('step3')}
              onPress={() =>
                navigation.navigate('Menu', {
                  screen: 'LocationProfileScreen',
                  params: { screen: 'LocationGallerySettingsScreen' },
                })
              }
              buttonLabel={t('enableOnlineProfileStep3Button')}
            />
          )}
          <Spacer size={12} />
          <Line />
          <Spacer size={12} />
          {steps.step4 ? (
            <StepCompleted
              label={t('enableOnlineProfileStep4')}
              onPress={() =>
                navigation.navigate('Menu', {
                  screen: 'LocationProfileScreen',
                  params: { screen: 'LocationSchedule', params: { online: true } },
                })
              }
            />
          ) : (
            <StepNotCompleted
              label={t('enableOnlineProfileStep4')}
              description={t('enableOnlineProfileStep4Description')}
              open={open.step4}
              onOpenChange={toggleOpen('step4')}
              onPress={() =>
                navigation.navigate('Menu', {
                  screen: 'LocationProfileScreen',
                  params: { screen: 'LocationSchedule', params: { online: true } },
                })
              }
              buttonLabel={t('enableOnlineProfileStep4Button')}
            />
          )}
          <Spacer size={12} />
          <Line />
          <Spacer size={12} />
          {steps.step5 ? (
            <StepCompleted
              label={t('enableOnlineProfileStep5')}
              onPress={() =>
                navigation.navigate('Home', {
                  screen: 'HomeTabs',
                  params: { screen: 'ServicesTab', params: { screen: isDesktop ? 'ServicesWeb' : 'ServicesMobile' } },
                })
              }
            />
          ) : (
            <StepNotCompleted
              label={t('enableOnlineProfileStep5')}
              description={t('enableOnlineProfileStep5Description')}
              open={open.step5}
              onOpenChange={toggleOpen('step5')}
              onPress={() =>
                navigation.navigate('Home', {
                  screen: 'HomeTabs',
                  params: { screen: 'ServicesTab', params: { screen: isDesktop ? 'ServicesWeb' : 'ServicesMobile' } },
                })
              }
              buttonLabel={t('enableOnlineProfileStep5Button')}
            />
          )}
          <Spacer size={12} />
          <Line />
          <Spacer size={12} />
          {steps.step6 ? (
            <StepCompleted
              label={t('enableOnlineProfileStep6')}
              onPress={() => navigation.navigate('Booking', { screen: 'BookingCreateScreen' })}
            />
          ) : (
            <StepNotCompleted
              label={t('enableOnlineProfileStep6')}
              description={t('enableOnlineProfileStep6Description')}
              open={open.step6}
              onOpenChange={toggleOpen('step6')}
              onPress={() => {
                const workerId = page.workers.length === 1 ? page.workers[0]._id : undefined;
                navigation.navigate('Booking', { screen: 'BookingCreateScreen', params: { workerId } });
              }}
              buttonLabel={t('enableOnlineProfileStep6Button')}
            />
          )}
        </FormCard>
      ) : (
        <FormCard paddings="none" dropShaddow rounded style={{ marginHorizontal: 16, padding: 16, marginBottom: -8 }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <SmallBody style={{ fontFamily: 'open-sans-bold' }}>
              {t('profileCompleted')} <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{progress}%</SmallBody>
            </SmallBody>
            <TouchableOpacity onPress={hideBanner}>
              <CloseIcon />
            </TouchableOpacity>
          </Row>
          <Spacer size={8} />
          <Row style={{ height: 8, borderRadius: 4, overflow: 'hidden' }}>
            <Column style={{ width: `${progress}%`, backgroundColor: '#2DCE89' }} />
            <Column style={{ width: `${100 - progress}%`, backgroundColor: '#D8D8D8' }} />
          </Row>
        </FormCard>
      )}
    </>
  );
};

export default EnableOnlineProfile;

import { ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientUpdateIsBlockedState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Updating';
      readonly clientId: ClientId;
      readonly isBlocked: boolean;
    }
  | {
      readonly type: 'Updated';
      readonly clientId: ClientId;
      readonly isBlocked: boolean;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientUpdateIsBlockedState => ({
  type: 'Ready',
});

export const ClientUpdateIsBlockedContext = createModelContext(
  defaultState(),
  {
    trySetUpdated: (_, payload: { clientId: ClientId; isBlocked: boolean }) => ({
      type: 'Updated',
      clientId: payload.clientId,
      isBlocked: payload.isBlocked,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Updated' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientUpdateIsBlockedState) => ClientUpdateIsBlockedState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      updateClientIsBlocked: (payload: { clientId: ClientId; isBlocked: boolean }) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const updateClientIsBlocked = async () => {
              try {
                await meroApi.clients.updateClientIsBlocked({
                  clientId: payload.clientId,
                  isBlocked: payload.isBlocked,
                });

                dispatch.trySetUpdated({ clientId: payload.clientId, isBlocked: payload.isBlocked });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            updateClientIsBlocked().catch(log.exception);

            return {
              type: 'Updating',
              clientId: payload.clientId,
              isBlocked: payload.isBlocked,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientUpdateIsBlockedContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientUpdateIsBlockedContext.Provider>{children}</ClientUpdateIsBlockedContext.Provider>;
};

export const withClientUpdateIsBlockedContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientUpdateIsBlockedContextProvider(props: P) {
    return (
      <ClientUpdateIsBlockedContextProvider>
        <Content {...props} />
      </ClientUpdateIsBlockedContextProvider>
    );
  };
};

import { DefinedString } from '@mero/api-sdk';
import { ClientHistoryRecordId, ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientHistoryNoteUpdateState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Updating';
      readonly clientId: ClientId;
      readonly noteId: ClientHistoryRecordId;
      readonly text: DefinedString;
    }
  | {
      readonly type: 'Updated';
      readonly clientId: ClientId;
      readonly noteId: ClientHistoryRecordId;
      readonly text: DefinedString;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientHistoryNoteUpdateState => ({
  type: 'Ready',
});

export const ClientHistoryNoteUpdateContext = createModelContext(
  defaultState(),
  {
    trySetUpdated: (_, params: { clientId: ClientId; noteId: ClientHistoryRecordId; text: DefinedString }) => ({
      type: 'Updated',
      clientId: params.clientId,
      noteId: params.noteId,
      text: params.text,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Updated' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientHistoryNoteUpdateState) => ClientHistoryNoteUpdateState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      updateClientNote: (params: { clientId: ClientId; noteId: ClientHistoryRecordId; text: DefinedString }) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const updateNote = async () => {
              try {
                await meroApi.clients.updateClientHistoryNote({
                  clientId: params.clientId,
                  noteId: params.noteId,
                  note: params.text,
                });

                dispatch.trySetUpdated({
                  clientId: params.clientId,
                  noteId: params.noteId,
                  text: params.text,
                });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            updateNote().catch(log.exception);

            return {
              type: 'Updating',
              clientId: params.clientId,
              noteId: params.noteId,
              text: params.text,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientHistoryNoteUpdateContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientHistoryNoteUpdateContext.Provider>{children}</ClientHistoryNoteUpdateContext.Provider>;
};

export const withClientHistoryNoteUpdateContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientHistoryNoteUpdateContextProvider(props: P) {
    return (
      <ClientHistoryNoteUpdateContextProvider>
        <Content {...props} />
      </ClientHistoryNoteUpdateContextProvider>
    );
  };
};

import { createModelContext } from '@mero/components';

import log from '../../utils/log';
import { IntercomHandle, Registration } from './types';
import { addEventListener } from './utils';

export type IntercomState =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Initializing';
    }
  | {
      readonly type: 'Ready';
      readonly registration: Registration;
      readonly unreadConversationCount: number;
      readonly handle: IntercomHandle;
    };

const defaultState = (): IntercomState => ({
  type: 'New',
});

export const IntercomContext = createModelContext(
  defaultState(),
  {
    mutate: (s, fn: (s: IntercomState) => IntercomState): IntercomState => fn(s),
  },
  (dispatch) => {
    return {
      init: (params: { registration: Registration; handle: IntercomHandle }): void => {
        dispatch.mutate((state) => {
          if (
            state.type === 'New' ||
            (state.type === 'Ready' && state.registration.userId !== params.registration.userId)
          ) {
            log.debug(`Going to init intercom for userId ${params.registration.userId}`, params.registration);
            const initAsync = async () => {
              const result = await params.handle
                .shutdown()
                .then(() => params.handle.init(params.registration))
                .then(params.handle.getUnreadConversationCount)
                .then((unreadConversationCount) => ({ registration: params.registration, unreadConversationCount }))
                .catch(() => ({ registration: undefined, unreadConversationCount: 0 }));

              dispatch.mutate((s) => {
                if (s.type === 'Initializing') {
                  if (result.registration) {
                    return {
                      type: 'Ready',
                      registration: result.registration,
                      unreadConversationCount: result.unreadConversationCount,
                      handle: params.handle,
                    };
                  } else {
                    return {
                      type: 'New',
                    };
                  }
                }

                return s;
              });

              addEventListener('IntercomUnreadConversationCountDidChangeNotification', ({ count = 0 }) => {
                dispatch.mutate((s) => {
                  if (s.type === 'Ready') {
                    return {
                      ...s,
                      unreadConversationCount: count,
                    };
                  }

                  return s;
                });
              });
            };

            initAsync().catch(log.exception);

            return {
              type: 'Initializing',
            };
          }

          return state;
        });
      },
      shutdown: (): void => {
        dispatch.mutate((s) => {
          if (s.type === 'Ready') {
            log.debug(`Going to shutdown intercom for userId ${s.registration.userId}`, s.registration);
            s.handle.shutdown().then(log.exception);
          }

          return s;
        });
      },
      reloadUnreadCount: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const getUnreadConversationCountAsync = async () => {
              const result = await state.handle.getUnreadConversationCount();

              dispatch.mutate((s) => {
                if (s.type === 'Ready') {
                  return {
                    ...s,
                    unreadConversationCount: result,
                  };
                }

                return s;
              });
            };

            getUnreadConversationCountAsync().catch(log.exception);
          }

          return state;
        });
      },
      openChat: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            state.handle.showChat();
            // openChat();
          }

          return state;
        });
      },
      toggleChatBubble: (show: boolean): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            if (show) {
              state.handle.showLauncher();
            } else {
              state.handle.hideLauncher();
            }
          }

          return state;
        });
      },
    };
  },
);

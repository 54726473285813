import { colors, H2s, Icon, Label, Row, SmallBody } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { Circle, G, Path, Rect, SvgProps } from 'react-native-svg';

import Column from '@mero/components/lib/components/Layout/Column';
import Spacer from '@mero/components/lib/components/Spacer';

import { FinishedTransaction } from '../../../contexts/CheckoutsContext';
import { PaymentMethod } from './SelectPaymentScreen';

export type PaymentIconProps = {
  type: PaymentMethod;
};

export const CardAndCashIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7660" transform="translate(-15244 5808)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 687" transform="translate(15244 -5808)" />
      <G data-name="Group 7655">
        <Path fill="none" d="M15249-5803h22v22h-22z" data-name="Rectangle 59" />
        <G data-name="mark_email_unread-24px (1)">
          <Path fill="none" d="M15251.754-5801.18h16.5v16.5h-16.5z" data-name="Rectangle 65" />
          <G data-name="Group 7106">
            <G fill="#e9ecef" data-name="Group 7110">
              <G stroke="#52577f" data-name="Rectangle 2690" transform="translate(15258.175 -5796.882)">
                <Rect width={10.079} height={12.039} stroke="none" rx={2} />
                <Rect width={9.079} height={11.039} x={0.5} y={0.5} fill="none" rx={1.5} />
              </G>
              <Path d="m15257.748-5796.557 6.68-3.68-5.15 17.964-1.535-2.27Z" data-name="Path 9080" />
            </G>
            <G fill="#e9ecef" data-name="Group 7109">
              <G stroke="#52577f" data-name="Rectangle 2690" transform="translate(15251.747 -5798.702)">
                <Rect width={9.799} height={12.039} stroke="none" rx={2} />
                <Rect width={8.799} height={11.039} x={0.5} y={0.5} fill="none" rx={1.5} />
              </G>
              <Path d="m15262.29-5786.189-5.41.49 3.91-13.997 1.498 1.306Z" data-name="Path 9081" />
            </G>
            <Path d="m15257.685-5783.817 4.954-16.576.536.164-4.954 16.576Z" data-name="Path 9082" />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);

export const CashIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7659" transform="translate(-15244 5851)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 686" transform="translate(15244 -5851)" />
      <G data-name="Orion_money (1)">
        <Path fill="none" d="M15249-5845.656h22v21.313h-22z" data-name="Rectangle 2760" />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M15251.722-5830.443v-9s1.081-1.133 4.323-1.133 4.107 1.621 7.566 1.621a17.132 17.132 0 0 0 4.323-.676v9.187a8.255 8.255 0 0 1-3.783.676c-2.594 0-4.648-1.621-7.566-1.621a12.106 12.106 0 0 0-4.863.946Z"
        />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M15262.499-5835.282a2.2 2.2 0 0 1-2.185 2.7 3.358 3.358 0 0 1-3.135-2.7 2.2 2.2 0 0 1 2.189-2.7 3.306 3.306 0 0 1 3.131 2.7Z"
        />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M15254.397-5840.468a2.7 2.7 0 0 1-2.675 3.135m0 3.783a2.7 2.7 0 0 1 2.675 2.351m13.538-5.593a2.7 2.7 0 0 1-2.675-2.27m.027 9.241a2.654 2.654 0 0 1 2.648-3.189"
          data-name="layer1"
        />
      </G>
    </G>
  </Svg>
);

export const CardIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7658" transform="translate(-15244 5901)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 685" transform="translate(15244 -5901)" />
      <G fill="none" data-name="Group 7654">
        <Path d="M15249-5896h22v22h-22z" data-name="Rectangle 59" />
        <G transform="translate(15251.75 -5892.562)">
          <Path d="M0 0h17.188v16.5H0z" data-name="Rectangle 2759" />
          <Path
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="m2.422 6.934-.573-1.589a.625.625 0 0 1 .365-.807L12.786.71a.625.625 0 0 1 .807.365l2.265 6.25a.625.625 0 0 1-.365.807l-2.734.99M2.365 6.726l11.7-4.271m-2.164 2.552 1.172-.417"
          />
          <Rect
            width={12.499}
            height={7.812}
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            rx={1}
            transform="translate(.26 6.933)"
          />
          <Path
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M2.344 10.578H5.99m3.124-1.562h1.562m-8.332 3.646h.521m2.083 0h.521m2.083 0h.521m2.083 0h.521"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export const GiftCardIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7663" transform="translate(-15244 5679)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 690" transform="translate(15244 -5679)" />
      <G fill="none" data-name="Group 7657" opacity={0.939}>
        <Path d="M15249-5674h22v22h-22z" data-name="Rectangle 59" />
        <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
          <Path d="M15265.893-5662.207v6.573h-11.786v-6.573m-.982-2.817h13.75v2.817h-13.75ZM15258.001-5665.455v9.821m4-9.821v9.821m-3.45-13.995a1.861 1.861 0 0 0-1.55-.738 2 2 0 0 0-1.87 1.27 1.942 1.942 0 0 0 .547 2.169c1.049.908 4.323 1.473 4.323 1.473a7.906 7.906 0 0 0-1.45-4.174Zm2.9 0a1.861 1.861 0 0 1 1.55-.738 2 2 0 0 1 1.869 1.27 1.942 1.942 0 0 1-.547 2.169c-1.05.908-4.322 1.473-4.322 1.473a7.906 7.906 0 0 1 1.448-4.174Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export const MembershipIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7662" transform="translate(-15244 5722)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 689" transform="translate(15244 -5722)" />
      <G fill="none">
        <Path d="M15249-5717h22v22h-22Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(15251.75 -5712.187)"
        >
          <Path d="M13.512 0v.27a1.351 1.351 0 0 1-2.7 0V0H5.4v.27a1.351 1.351 0 0 1-2.7 0V0H0v11.891h16.215V0ZM9.729 4.324h4.324M9.729 6.486h4.324M9.729 8.648h2.162" />
          <Circle cx={1.375} cy={1.375} r={1.375} data-name="layer1" transform="translate(3.438 3.438)" />
          <Path
            d="M4.863 6.486a2.729 2.729 0 0 0-2.7 2.784v.459h5.4V9.27a2.729 2.729 0 0 0-2.7-2.784Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export const BankTransferIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 7661" transform="translate(-15244 5765)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 688" transform="translate(15244 -5765)" />
      <G fill="none" data-name="Group 7656">
        <Path d="M15249-5760h22v22h-22Z" data-name="Path 8275" />
        <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
          <Path d="m15258.513-5752.835 2.445-2.447a.933.933 0 0 1 1.311 0l.328.328m-2.219 2.22 1.335 1.336" />
          <Path
            d="M15264.261-5753.945a.958.958 0 0 0-1.336-1.336l-7.663 7.638-.807 2.168 2.168-.832 7.638-7.638Z"
            data-name="layer2"
          />
          <Path d="M15261.338-5751.021h6.226v8.571h-15.126v-8.571h6.226m6.882 4.033h-4.008m4.008 2.017h-6.529" />
        </G>
      </G>
    </G>
  </Svg>
);
export const PaymentIcon: React.FC<PaymentIconProps> = ({ type }) => {
  const Icon = React.useMemo(() => {
    switch (type) {
      case 'CardAndCash':
        return CardAndCashIcon;
      case 'Cash':
        return CashIcon;
      case 'Card':
        return CardIcon;
      case 'BankTransfer':
        return BankTransferIcon;
      case 'Membership':
        return MembershipIcon;
      default:
        return function Empty() {
          return <></>;
        };
    }
  }, [type]);

  return (
    <Column>
      <Icon />
    </Column>
  );
};

export type Props = {
  proceed: FinishedTransaction;
  onPress: () => void;
};

const FinishedPreview: React.FC<Props> = ({ proceed, onPress }) => {
  const { t } = useTranslation('checkout');
  const type = React.useMemo(() => {
    if (proceed.paymentTypes.includes('Card') && proceed.paymentTypes.includes('Cash')) {
      return 'CardAndCash';
    }

    if (proceed.paymentTypes.includes('Card')) {
      return 'Card';
    }

    if (proceed.paymentTypes.includes('Cash')) {
      return 'Cash';
    }

    if (proceed.paymentTypes.includes('Membership')) {
      return 'Membership';
    }

    if (proceed.paymentTypes.includes('BankTransfer')) {
      return 'BankTransfer';
    }

    return 'CardAndCash';
  }, [proceed]);
  return (
    <TouchableOpacity key={proceed._id} style={{ flexDirection: 'row', paddingHorizontal: 16 }} onPress={onPress}>
      <PaymentIcon type={type} />
      <Row style={{ flex: 1, paddingLeft: 16 }}>
        <Column style={{ flex: 1, paddingRight: 16 }}>
          <Label
            style={{
              color: proceed.status === 'Finished' ? colors.SHAMROCK : colors.RADICAL_RED,
              fontFamily: 'open-sans-semibold',
            }}
          >
            {t(
              proceed.isProtocol && (proceed.status === 'Finished' || proceed.status === 'Deleted')
                ? proceed.status === 'Finished'
                  ? 'proceedProtocol'
                  : 'proceedProtocolDeleted'
                : proceed.status,
            )}
          </Label>
          <H2s>
            {ScaledNumber.toNumber(proceed.total.total.amount)} {t(proceed.total.total.unit)}
          </H2s>
          <Spacer size={2} />
          <SmallBody>
            {proceed.client
              ? !proceed.client.user.profile.firstname && !proceed.client?.user.profile.lastname
                ? t('noName')
                : proceed.client.user.profile.firstname
              : t('noClient')}{' '}
            {proceed.client?.user.profile.lastname}
          </SmallBody>
          <Spacer size={6} />
          <SmallBody style={{ color: colors.COMET }}>
            {DateTime.fromJSDate(proceed.finishedAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
          </SmallBody>
        </Column>
      </Row>
      <Icon type="next" color={colors.DARK_BLUE} />
    </TouchableOpacity>
  );
};

export default FinishedPreview;

import { parsePhoneNumber } from 'awesome-phonenumber';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ClientBlockedLabel from '../../../../../../components/ClientBlockedLabel';
import ClientBoostedLabel from '../../../../../../components/ClientBoostedLabel';
import ClientWarnedLabel from '../../../../../../components/ClientWarnedLabel';
import { Column, SmallBody, Spacer, Row, H1, Avatar, HSpacer } from '../../../../../../components/shared';

interface ClientProfileInfoProps {
  profilePicture?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  isBlocked: boolean;
  isWarned: boolean;
  isBoost: boolean;
  hideBoostDetails: boolean;
}

const ClientProfileInfo: React.FC<ClientProfileInfoProps> = ({
  profilePicture,
  firstName,
  lastName,
  fullName,
  phone,
  isWarned,
  isBlocked,
  isBoost,
  hideBoostDetails,
}) => {
  const { t } = useTranslation('clients');

  return (
    <>
      <Column alignItems="center">
        <Avatar source={profilePicture} firstname={firstName} lastname={lastName} size={60} />
        <Spacer size={12} />
        <H1>{fullName}</H1>
        {!hideBoostDetails && <SmallBody>{parsePhoneNumber(phone).number?.national}</SmallBody>}
      </Column>
      <Column alignItems="center">
        {isBlocked || isWarned || isBoost ? (
          <>
            <Spacer size="8" />
            <Row>
              {isBlocked ? (
                <>
                  <ClientBlockedLabel />
                  <HSpacer left={8} />
                </>
              ) : null}
              {isWarned ? (
                <>
                  <ClientWarnedLabel />
                  <HSpacer left={8} />
                </>
              ) : null}
              {isBoost ? (
                <>
                  <ClientBoostedLabel />
                  <HSpacer left={8} />
                </>
              ) : null}
            </Row>
            <Spacer size="8" />
          </>
        ) : null}
      </Column>
    </>
  );
};

export default ClientProfileInfo;

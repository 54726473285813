import { Email as EmailType, PhoneNumber } from '@mero/api-sdk';
import { colors, Column, Label, Row, Title } from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import HSpacer from '@mero/components/lib/components/HSpacer';
import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { Email } from './Email';
import { styles } from './styles';

export const CopyIcon = (props: SvgProps) => (
  <Svg width={44} height={44} {...props}>
    <G data-name="Group 7735" transform="translate(-166 -255)">
      <Circle cx={22} cy={22} r={22} fill="#f2f2fe" data-name="Ellipse 693" transform="translate(166 255)" />
      <G fill="none" data-name="Group 7729">
        <Path d="M176 265h24v24h-24z" data-name="Rectangle 2786" />
        <G
          stroke="#080de0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
          data-name="Orion_copy (1)"
        >
          <Path d="M183.733 281.267H180V269h12.267v3.733" />
          <Path d="M183.733 272.733H196V285h-12.267Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export const OpenIcon = (props: SvgProps) => (
  <Svg width={44} height={44} {...props}>
    <G data-name="Group 8004" transform="translate(-166 -255)">
      <Circle cx={22} cy={22} r={22} fill="#f2f2fe" data-name="Ellipse 693" transform="translate(166 255)" />
      <G fill="none" data-name="Group 7729">
        <Path d="M176 265h24v24h-24z" data-name="Rectangle 2786" />
        <G stroke="#080de0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.2}>
          <Path d="M186.571 278.715v6l2.943-4.171" />
          <Path d="m180 276.143 16-6.857L193.429 283l-6.858-4.285Zm16-6.857-9.429 9.429" />
        </G>
      </G>
    </G>
  </Svg>
);

const PhoneIcon = (props: SvgProps) => (
  <Svg width={44} height={44} {...props}>
    <G data-name="Group 7730" transform="translate(-178 -293)">
      <Circle cx={22} cy={22} r={22} fill="#f2f2fe" data-name="Ellipse 692" transform="translate(178 293)" />
      <G data-name="Group 7728">
        <Path
          fill="#080de0"
          d="M204.139 317.047a1.2 1.2 0 0 0-1.627.325c-.6.726-1.327 1.927-4.055-.8s-1.552-3.479-.826-4.08a1.2 1.2 0 0 0 .325-1.627l-1.7-2.6c-.225-.325-.526-.851-1.226-.751a3.417 3.417 0 0 0-2.53 3.375c0 2.253 1.777 5.006 4.2 7.434s5.181 4.18 7.409 4.18a3.628 3.628 0 0 0 3.379-2.5c.075-.5-.425-1-.751-1.226Z"
        />
        <Path fill="none" d="M188 303h24v24h-24z" data-name="Rectangle 2785" />
      </G>
    </G>
  </Svg>
);

export type Props = {
  readonly phone: PhoneNumber;
  readonly email?: EmailType;
  readonly birthday?: Date;
  readonly onDismiss: () => void;
  readonly onCopy: (text: PhoneNumber | EmailType) => void;
  readonly onOpen: (url: string) => void;
  readonly onShare: () => void;
};

/**
 * Options modal screen component for client call intent (phone number pressed on client details page),
 *  where pros can:
 *  - Start phone call
 *  - Copy phone number to clipboard
 *  - Start SMS sending
 *  - Start Whatsapp message sending
 */
const ClientCallOptionsModal: React.FC<Props> = ({
  phone,
  email,
  birthday,
  onOpen,
  onDismiss,
  onCopy,
  onShare,
}: Props): React.ReactElement | null => {
  const onCopyCallback = React.useCallback(
    (text: PhoneNumber | EmailType) => () => {
      onCopy(text);
    },
    [phone, email, onCopy],
  );

  const onSendWhatsappCallback = React.useCallback(() => {
    onShare();
  }, [onShare]);

  const formattedBirthday = React.useMemo(() => {
    if (!birthday) {
      return '';
    }

    const birthdate = DateTime.fromJSDate(birthday);
    return `${birthdate.toFormat('dd.MM.yyyy')} (${Math.floor(birthdate.diffNow('years').years * -1)} ani)`;
  }, [birthday]);
  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          {/*<TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSendEmail}>*/}
          {/*  <SimpleListItem>*/}
          {/*    <Title>Trimite email</Title>*/}
          {/*  </SimpleListItem>*/}
          {/*</TouchableOpacity>*/}
          {/*<Line />*/}
          {email ? (
            <>
              <Email email={email} onCopy={onCopyCallback(email)} onOpen={() => onOpen(`mailto:${email}`)} />
              <Line />
            </>
          ) : null}
          <TouchableOpacity disabled style={styles.optionsMenuItem} delayPressIn={0} onPress={onCopyCallback(phone)}>
            <SimpleListItem>
              <Row>
                <Column style={{ flex: 1 }}>
                  <Label style={{ color: colors.COMET }}>TELEFON</Label>
                  <Title>{formatPhoneNumber(phone)}</Title>
                </Column>
                <TouchableOpacity onPress={() => onOpen(`tel:${phone}`)}>
                  <PhoneIcon />
                </TouchableOpacity>
                <HSpacer left={16} />
                <TouchableOpacity onPress={onCopyCallback(phone)}>
                  <CopyIcon />
                </TouchableOpacity>
              </Row>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          {birthday ? (
            <>
              <TouchableOpacity disabled style={styles.optionsMenuItem} delayPressIn={0}>
                <SimpleListItem>
                  <Row>
                    <Column style={{ flex: 1 }}>
                      <Label style={{ color: colors.COMET }}>DATA NAȘTERII</Label>
                      <Title>{formattedBirthday}</Title>
                    </Column>
                  </Row>
                </SimpleListItem>
              </TouchableOpacity>
              <Line />
            </>
          ) : null}
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onShare}>
            <SimpleListItem>
              <Title style={{ color: colors.DARK_BLUE }}>Distribuie date de contact</Title>
            </SimpleListItem>
          </TouchableOpacity>
          {/*  <Line />*/}
          {/*  <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSendWhatsappCallback}>*/}
          {/*    <SimpleListItem>*/}
          {/*      <Title>Trimite mesaj Whatsapp</Title>*/}
          {/*    </SimpleListItem>*/}
          {/*  </TouchableOpacity>*/}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientCallOptionsModal;

import { colors, Line, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { styles } from './ProfilePhotoMenu.styles';

export type Props = {
  onAddPhoto: () => void;
  onDeletePhoto: () => void;
  onDismiss: () => void;
};

const ProfilePhotoMenu: React.FC<Props> = ({
  onAddPhoto,
  onDeletePhoto,
  onDismiss,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('pros');

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onAddPhoto, onDismiss)}>
              <Title>{t('addPhoto')}</Title>
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onDeletePhoto, onDismiss)}>
              <Title style={{ color: colors.RADICAL_RED }}>{t('deletePhoto')}</Title>
            </TouchableOpacity>
            <Line />
          </SafeAreaView>
        </View>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default ProfilePhotoMenu;

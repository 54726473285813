import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { Button, colors, ConfirmBox, DismissKeyboard, ModalOverlay, Row, Spacer } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { ItemAmount, WithUiKey } from '../../../contexts/CheckoutFormContext';
import { NumberFromString, roundToDecimals } from '../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import SelectPro from './SelectProComponent';

type Props = {
  isEdit: boolean;
  amount: WithUiKey<ItemAmount>;
  onSave: (service: WithUiKey<ItemAmount>) => void;
  onDelete: (service: WithUiKey<ItemAmount>) => void;
  onCancel: () => void;
};

const EditAmount: React.FC<Props> = ({ amount, isEdit, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation('checkout');

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    if (!priceValid || !(localeStringToNumber(price.input) > 0) || !saleOwner) {
      return setShowErrors(true);
    }
    onSave({
      ...amount,
      total: {
        ...amount.total,
        amount: {
          ...amount.total.amount,
          amount: ScaledNumber.fromNumber(localeStringToNumber(price.input), 2),
        },
      },
      saleOwner,
    });
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: save,
  };

  const [price, setPrice] = React.useState({
    input: ScaledNumber.toNumber(amount.total.amount.amount) === 0 ? '' : scaledToString(amount.total.amount.amount),
    decoded: NumberFromString.decode(
      ScaledNumber.toNumber(amount.total.amount.amount) === 0 ? '' : scaledToString(amount.total.amount.amount),
    ),
  });
  const priceValid = E.isRight(price.decoded);

  const [tempPrice, setTempPrice] = React.useState(price.input);

  const [saleOwner, setSaleOwner] = React.useState<CheckoutUserPreview | undefined>(amount.saleOwner);

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={isEdit ? t('changeAmount') : t('addAmount')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{isEdit ? t('changeAmount') : t('addAmount')}</H1>
              <Spacer size={16} />
              <InputWithLabel
                label={t('proceedAmountInput')}
                isError={showErrors && (!priceValid || !(localeStringToNumber(price.input) > 0))}
                errorText={t('proceedAmountError')}
              >
                <TypeSafeTextInput
                  codec={NumberFromString}
                  value={price.input}
                  onChange={flow(numberValidator(price.input), setPrice)}
                  keyboardType="numeric"
                  placeholder={t('pricePlaceholder')}
                  onFocus={() => {
                    setPrice({
                      input: stripLocalThousandsSeparators(price.input),
                      decoded: NumberFromString.decode(roundToDecimals(localeStringToNumber(price.input)).toString()),
                    });
                  }}
                  onBlur={() => {
                    setPrice({
                      input: price.input ? roundToDecimals(localeStringToNumber(price.input)).toLocaleString() : '',
                      decoded: NumberFromString.decode(roundToDecimals(localeStringToNumber(price.input)).toString()),
                    });
                  }}
                  autoFocus
                />
              </InputWithLabel>
              <Spacer size={16} />
              <SelectPro value={saleOwner?._id} onUpdate={setSaleOwner} />
              <Spacer size={24} />
              {isEdit && (
                <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                  <Button
                    padding={24}
                    expand={false}
                    size="medium"
                    backgroundColor={colors.WHITE}
                    color={colors.RADICAL_RED}
                    text={t('delete')}
                    onClick={() => onDelete(amount)}
                  />
                </Row>
              )}
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditAmount;

import { TransitionPreset, TransitionPresets } from '@react-navigation/stack';

export const ShortModalFadeTransition: TransitionPreset = {
  ...TransitionPresets.ModalFadeTransition,
  transitionSpec: {
    ...TransitionPresets.ModalFadeTransition.transitionSpec,
    open: {
      animation: 'timing',
      config: {
        ...TransitionPresets.ModalFadeTransition.transitionSpec.open.config,
        duration: 100,
      },
    },
    close: {
      animation: 'timing',
      config: {
        ...TransitionPresets.ModalFadeTransition.transitionSpec.open.config,
        duration: 100,
      },
    },
  },
};

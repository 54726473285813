import { DateInterval } from '@mero/api-sdk';
import { CheckoutPaymentMethodDailyReportItem } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutPaymentMethodDailyReportItem';
import { CheckoutPaymentMethodReportItem } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutPaymentMethodReportItem';
import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Row, SmallBody, Title } from '@mero/components';
import { MeroUnits, ScaledNumber, UserId } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { PieChart } from 'react-native-chart-kit';

import FormCard from '@mero/components/lib/components/FormCard';
import HSpacer from '@mero/components/lib/components/HSpacer';
import Line from '@mero/components/lib/components/Line';

import { useIsFocused } from '@react-navigation/native';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import Table, { Column } from './Table';

const chartConfig = {
  backgroundGradientFrom: '#1E2923',
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: '#08130D',
  backgroundGradientToOpacity: 0.5,
  color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
  strokeWidth: 2, // optional, default 3
  barPercentage: 0.5,
  useShadowColorFromDataset: false, // optional
};

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  saleOwnerId?: UserId;
};

const CASH_COLOR = '#7431F7';
const CARD_COLOR = '#A27AF1';
const BANK_TRANSFER_COLOR = '#D0BCF8';

const Payments: React.FC<Props> = ({ pageId, dateInterval, saleOwnerId }) => {
  const { t } = useTranslation('reports');
  const isFocused = useIsFocused();
  const [reports, setReports] = React.useState<CheckoutPaymentMethodDailyReportItem<MeroUnits.Any>[]>([]);
  const [payments, setPayments] = React.useState<CheckoutPaymentMethodReportItem<MeroUnits.Any>>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [graphWidth, setGraphWidth] = React.useState<number>(0);

  const getData = async () => {
    setIsLoading(true);
    try {
      const [response, payments] = await Promise.all([
        meroApi.checkoutReports.getPaymentsByTypeDailyReport({
          pageId,
          unit: 'RON',
          interval: dateInterval,
          saleOwnerId,
        }),
        meroApi.checkoutReports.getPaymentsByTypeReport({
          pageId,
          unit: 'RON',
          interval: dateInterval,
          saleOwnerId,
        }),
      ]);

      setPayments(payments);
      setReports(response);
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [JSON.stringify(dateInterval)]);

  // React.useEffect(() => {
  //   if (isFocused) {
  //     getData();
  //   }
  // }, [isFocused]);

  const { columns, data, graphData } = React.useMemo(() => {
    const data = reports.map((report) => ({
      date: DateTime.fromISO(report.date).toFormat('dd.MM.yyyy'),
      cash: `${scaledToString(report.cash.amount)} ${t(report.cash.unit)}`,
      card: `${scaledToString(report.card.amount)} ${t(report.card.unit)}`,
      bankTransfer: `${scaledToString(report.bankTransfer.amount)} ${t(report.bankTransfer.unit)}`,
    }));

    const graphData = payments
      ? [
          {
            name: t('cashColumn'),
            value: ScaledNumber.toNumber(payments.cash.amount),
            color: CASH_COLOR,
          },
          {
            name: t('cardColumn'),
            value: ScaledNumber.toNumber(payments.card.amount),
            color: CARD_COLOR,
          },
          {
            name: t('bankTransferColumn'),
            value: ScaledNumber.toNumber(payments.bankTransfer.amount),
            color: BANK_TRANSFER_COLOR,
          },
        ]
      : [];

    const columns: Column<(typeof data)[number]>[] = [
      {
        field: 'date',
        headerName: t('dateColumn'),
        width: '25%',
        sortable: false,
      },
      {
        field: 'cash',
        headerName: t('cashColumn'),
        width: '25%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
      {
        field: 'card',
        headerName: t('cardColumn'),
        width: '25%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
      {
        field: 'bankTransfer',
        headerName: t('bankTransferColumn'),
        width: '25%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
    ];

    return {
      columns,
      data,
      graphData,
    };
  }, [reports, payments]);

  const calculatePercentage = (cardValue: number, cashValue: number, bankTransferValue: number) => {
    const total = cardValue + cashValue + bankTransferValue;

    if (total === 0)
      return {
        cash: '0 %',
        card: '0 %',
        bankTransfer: '0 %',
      };

    return {
      cash: `${((cashValue * 100) / total).toFixed(2)} %`,
      card: `${((cardValue * 100) / total).toFixed(2)} %`,
      bankTransfer: `${((bankTransferValue * 100) / total).toFixed(2)} %`,
    };
  };

  const cashValue = React.useMemo(() => {
    return payments ? ScaledNumber.toNumber(payments.cash.amount) : 0;
  }, [payments]);
  const cardValue = React.useMemo(() => {
    return payments ? ScaledNumber.toNumber(payments.card.amount) : 0;
  }, [payments]);
  const bankTransferValue = React.useMemo(() => {
    return payments ? ScaledNumber.toNumber(payments.bankTransfer.amount) : 0;
  }, [payments]);

  return (
    <Row style={{ zIndex: -1 }}>
      <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, flex: 1 }}>
        <Title style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 8 }}>{t('paymentDistribution')}</Title>
        <Line color={colors.ATHENS_GRAY} />

        <Row style={{ position: 'relative' }}>
          <View
            onLayout={(ev) => {
              setGraphWidth(ev.nativeEvent.layout.width);
            }}
            style={{ flex: 1 }}
          >
            <PieChart
              data={graphData}
              width={graphWidth}
              height={220}
              chartConfig={chartConfig}
              accessor={'value'}
              backgroundColor={'transparent'}
              paddingLeft={'0'}
              center={[0, 0]}
              hasLegend={false}
            />
          </View>
          <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, padding: 24 }}>
            <Row style={{ alignItems: 'center' }}>
              <View style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: CASH_COLOR }} />
              <HSpacer left={4} />
              <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>
                {t('cashColumn')} - {scaledToString(payments?.cash.amount ?? ScaledNumber.zero())}{' '}
                {t(payments?.card.unit ?? MeroUnits.RON.code)} (
                {calculatePercentage(cardValue, cashValue, bankTransferValue).cash})
              </SmallBody>
            </Row>
            <Row style={{ alignItems: 'center' }}>
              <View style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: CARD_COLOR }} />
              <HSpacer left={4} />
              <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>
                {t('cardColumn')} - {scaledToString(payments?.card.amount ?? ScaledNumber.zero())}{' '}
                {t(payments?.card.unit ?? MeroUnits.RON.code)} (
                {calculatePercentage(cardValue, cashValue, bankTransferValue).card})
              </SmallBody>
            </Row>
            <Row style={{ alignItems: 'center' }}>
              <View style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: BANK_TRANSFER_COLOR }} />
              <HSpacer left={4} />
              <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>
                {t('bankTransferColumn')} - {scaledToString(payments?.bankTransfer.amount ?? ScaledNumber.zero())}{' '}
                {t(payments?.card.unit ?? MeroUnits.RON.code)} (
                {calculatePercentage(cardValue, cashValue, bankTransferValue).bankTransfer})
              </SmallBody>
            </Row>
          </View>
        </Row>
      </FormCard>
      <HSpacer left={16} />
      <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, flex: 1 }}>
        <Table columns={columns} data={data} loading={isLoading} />
      </FormCard>
    </Row>
  );
};

export default Payments;

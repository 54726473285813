import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import WorkerEditServiceScreen from '../screens/Authorized/MenuScreen/screens/WorkerServicesSettingsScreen/WorkerEditServiceScreen';
import WorkerEditServiceSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerServicesSettingsScreen/WorkerEditServiceSettingsScreen';
import WorkerServicesSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerServicesSettingsScreen/WorkerServicesSettingsScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { WorkerServicesSettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<WorkerServicesSettingsStackParamList>();

function WorkerServicesSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="WorkerServices"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="WorkerServices"
        component={WorkerServicesSettingsScreen}
        options={{ title: t('services'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="WorkerServiceDetails"
        component={WorkerEditServiceScreen}
        options={{ title: t('proServices') }}
      />
      <Stack.Screen name="WorkerServiceSettings" component={WorkerEditServiceSettingsScreen} />
    </Stack.Navigator>
  );
}

export default WorkerServicesSettingsStackNavigator;

import { PageRoleId, PageRoleOverview } from '@mero/api-sdk/dist/pages';
import { Body, Checkbox, colors, Column, Row, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Animated, Dimensions } from 'react-native';
import type { Swiper as SwiperType } from 'swiper';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

type Props = {
  role?: PageRoleId;
  setRole: (role: PageRoleId) => void;
  roles: { label: string; value: PageRoleId }[];
  rolesPermissions: PageRoleOverview[];
};

const PermissionSlider: React.FC<Props> = ({ role, rolesPermissions, roles, setRole }) => {
  const { width } = Dimensions.get('window');
  const { isPhone } = useMediaQueries();

  const activateRoleTimeout = React.useRef(0);
  const [swiper, setSwiper] = React.useState<SwiperType | undefined>();

  const screenWidth = isPhone ? width : 500;

  const PADDING_SPACING = 8;
  const SPACING = 22;
  const PERMISSION_LENGTH = screenWidth - PADDING_SPACING * 2 - SPACING * 2;

  React.useEffect(() => {
    if (role) {
      const position = roles.findIndex((r) => r.value === role);
      if (position !== -1) {
        swiper?.slideTo(position, 100);
      }
    }
  }, [role, roles]);

  const roleIndex = React.useMemo(() => roles.findIndex((r) => r.value === role), [role]);

  return (
    <div style={{ width: screenWidth }}>
      <Swiper
        initialSlide={roleIndex === -1 ? 0 : roleIndex}
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={PADDING_SPACING}
        modules={[Controller]}
        controller={{ control: swiper }}
        onSwiper={setSwiper}
        onSlideChange={(swiper) => {
          window.clearTimeout(activateRoleTimeout.current);
          activateRoleTimeout.current = window.setTimeout(() => {
            setRole(rolesPermissions[swiper.activeIndex].id);
          }, 1000);
        }}
      >
        {rolesPermissions.map((item) => {
          return (
            <SwiperSlide key={item.id} style={{ width: PERMISSION_LENGTH, padding: `16px 0` }}>
              <Animated.View
                style={{
                  backgroundColor: colors.WHITE,
                  marginHorizontal: PADDING_SPACING,
                  padding: 24,
                  borderRadius: 6,
                  borderWidth: 1,
                  borderColor: item.id === role ? colors.DARK_BLUE : colors.WHITE,
                  shadowColor: colors.BLACK,
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.15,
                  shadowRadius: 3.84,
                  elevation: 3,
                }}
              >
                <Row style={{ paddingTop: 8 }}>
                  <Column>
                    <Checkbox value={item.id === role} color="blue" disabled={false} />
                  </Column>
                  <Column style={{ paddingLeft: 12, flex: 1 }}>
                    <Title>{item.name}</Title>
                  </Column>
                </Row>
                <Spacer size={16} />
                <Column>
                  {item.permissionsOverview.map((permission, indexPermission) => (
                    <>
                      <Body
                        key={permission.title}
                        style={{
                          textDecorationLine: permission.isActive ? 'none' : 'line-through',
                          color: permission.isActive ? colors.BLACK : colors.CADET_BLUE,
                        }}
                      >
                        {permission.title}
                      </Body>
                      {indexPermission !== item.permissionsOverview.length - 1 && <Spacer size={8} />}
                    </>
                  ))}
                </Column>
              </Animated.View>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default PermissionSlider;

import { LoginButton } from '@mero/components';
import * as Facebook from 'expo-auth-session/providers/facebook';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';

import config from '../../../../config';
import log from '../../../../utils/log';
import { SigningContext } from '../../SigningContext';

WebBrowser.maybeCompleteAuthSession();

export type Props = {
  title?: string;
};

export default function FacebookSignInButton({ title }: Props): React.ReactElement {
  const [signingState, signing] = SigningContext.useContext();
  const signInProgress = signingState.type !== 'Ready';

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: config.facebookAppId,
  });

  React.useEffect(() => {
    if (response?.type === 'success') {
      const accessToken = response.params.access_token;

      const doSignIn = async () => {
        signing.setSignInProgress();
        try {
          await signing.loginFacebook({
            fbAccessToken: accessToken,
          });
        } finally {
          signing.stopSignInProgress();
        }
      };

      doSignIn().catch(log.exception);
    }
  }, [response]);

  return (
    <LoginButton
      text={title ?? 'Continuă cu Facebook'}
      onClick={() => {
        promptAsync();
      }}
      icon="facebook"
      disabled={signInProgress || !request}
    />
  );
}

import { colors, Line, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './AddProceedMenu.styles';

export type Props = {
  onSelect: (type: 'all' | 'one') => void;
  onDismiss: () => void;
};

const DraftOptionsMenu: React.FC<Props> = ({ onDismiss, onSelect }) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('checkout');

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('one')}>
            <Title style={{ color: colors.RADICAL_RED }}>{t('deleteOneByOne')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('all')}>
            <Title style={{ color: colors.RADICAL_RED }}>{t('deleteAll')}</Title>
          </TouchableOpacity>
          <Line />
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default DraftOptionsMenu;

import * as React from 'react';

import BarberStoreWebview from '../screens/Authorized/MenuScreen/screens/Shop/BarberStoreWebview';
import ShopCategoriesScreen from '../screens/Authorized/MenuScreen/screens/Shop/ShopCategoriesScreen';
import ShopFiltersScreen from '../screens/Authorized/MenuScreen/screens/Shop/ShopFiltersScreen';
import ShopProductsScreen from '../screens/Authorized/MenuScreen/screens/Shop/ShopProductsScreen';
import ShopSortScreen from '../screens/Authorized/MenuScreen/screens/Shop/ShopSortScreen';
import { ShopContext } from '../screens/Authorized/MenuScreen/screens/Shop/shopContext';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { ShopStackParamsList } from '../types';

const Stack = createStackNavigator<ShopStackParamsList>();

const ShopNavigator = () => {
  return (
    <ShopContext.Provider>
      <Stack.Navigator screenOptions={{ headerShown: false, gestureResponseDistance: 200 }}>
        <Stack.Screen name="ShopCategories" component={ShopCategoriesScreen} />
        <Stack.Screen name="ShopProducts" component={ShopProductsScreen} />
        <Stack.Screen name="ShopProductsSubcategory" component={ShopProductsScreen} />
        <Stack.Screen name="BarberStore" component={BarberStoreWebview} />
        <Stack.Screen
          name="ShopFilters"
          component={ShopFiltersScreen}
          options={{
            presentation: 'modal',
            ...TransitionPresets.ModalSlideFromBottomIOS,
          }}
        />
        <Stack.Screen
          name="ShopSort"
          component={ShopSortScreen}
          options={{
            presentation: 'modal',
            ...TransitionPresets.ModalSlideFromBottomIOS,
          }}
        />
      </Stack.Navigator>
    </ShopContext.Provider>
  );
};

export default ShopNavigator;

// This line should be imported first!
import './polyfill';

// All the other imports should be done after "polyfill"
import {StatusBar} from 'expo-status-bar';
import {ActionSheetProvider} from '@expo/react-native-action-sheet';
import * as React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';
import * as ScreenOrientation from 'expo-screen-orientation';
import {Platform} from 'react-native';

import './js/constants/globalCss'
import useCachedResources from './js/hooks/useCachedResources';
import useColorScheme from './js/hooks/useColorScheme';

import config from './js/config';
import Navigation from './js/navigation';
import {ToastProvider} from '@mero/components';
import {NavigationContext} from './js/contexts/NavigationContext';
import {useMediaQueries} from './js/hooks/useMediaQueries';
import './js/i18n';
import {PortalProvider} from "@gorhom/portal";
import {AppContext, withAppContextProvider} from "./js/contexts/AppContext";
import {pipe} from "fp-ts/function";
import UpgradeScreen from "./js/components/UpgradeScreen";
import UpdateScreen from "./js/components/UpdateScreen";
import {ForceUpdate} from "./js/forceUpdate";

Sentry.init(config.sentry);

const App: React.FC = () => {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const {isPhone} = useMediaQueries();
  const [forceUpdate, setForceUpdate] = React.useState(false)

  const [appState] = AppContext.useContext();

  React.useEffect(() => {
    ForceUpdate.call = () => {
      setForceUpdate(true)
    }
  }, [])

  React.useEffect(() => {
    if (!isPhone && Platform.OS !== 'web') {
      ScreenOrientation.unlockAsync()
    }
  }, [])

  if (!isLoadingComplete || appState.type === 'New' || appState.type === 'Loading') {
    return null;
  } else {
    return (
      <ActionSheetProvider>
        <SafeAreaProvider>
          <ToastProvider dismissAfterSeconds={5}>
            <NavigationContext.Provider>
              <PortalProvider>
                {forceUpdate ? <UpgradeScreen/> :
                  <>
                    <Navigation colorScheme={colorScheme}/>
                  </>
                }
                <UpdateScreen/>
              </PortalProvider>
            </NavigationContext.Provider>
          </ToastProvider>
          <StatusBar/>
        </SafeAreaProvider>
      </ActionSheetProvider>
    );
  }
}

export default pipe(App, withAppContextProvider);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ContactSupportScreen from '../screens/Authorized/MenuScreen/screens/ContactSupportScreen';
import HelpCenterScreen from '../screens/Authorized/MenuScreen/screens/HelpCenterScreen';
import PagePermissionsSettingsScreen from '../screens/Authorized/MenuScreen/screens/PagePermissionsSettingsScreen';
import PageReportsScreen from '../screens/Authorized/MenuScreen/screens/PageReportsScreen/PageReportsScreen';
import SharePageProfileScreen from '../screens/Authorized/MenuScreen/screens/SharePageProfileScreen';
import WorkerCalendarSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerCalendarSettingsScreen';
import WorkerNotificationsSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerNotificationsSettingsScreen';
import WorkerProfileSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerProfileSettingsScreen';
import WorkerScheduleSettingsScreen from '../screens/Authorized/MenuScreen/screens/WorkerScheduleSettingsScreen';
import { PagePendingClientsScreen } from '../screens/Authorized/PendingListScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { MenuStackParamList } from '../types';
import CheckoutSettingsStackNavigator from './CheckoutSettingsStackNavigator';
import LocationProfileStackNavigator from './LocationProfileStackNavigator';
import MarketingStackNavigator from './MarketingStackNavigator';
import MembershipSettingsStackNavigator from './MembershipSettingsStackNavigator';
import NotificationsSettingsStackNavigator from './NotificationsSettingsStackNavigator';
import PageOnlinePaymentsSettingsStackNavigator from './PageOnlinePaymentsStackNavigator';
import PageReviewsStackNavigator from './PageReviewsStackNavigator';
import ShopNavigator from './ShopNavigator';
import SubscriptionStackNavigator from './SubscriptionStackNavigator';
import WorkerServicesSettingsStackNavigator from './WorkerServicesSettingsStackNavigator';
import { styles } from './styles';

const MenuStack = createStackNavigator<MenuStackParamList>();

const MenuStackNavigator: React.FC = () => {
  const { t } = useTranslation('settings');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <MenuStack.Navigator
      initialRouteName="LocationProfileScreen"
      screenOptions={{
        headerShown: false,
        cardOverlayEnabled: true,
      }}
    >
      <MenuStack.Screen
        name="MarketingSettingsScreen"
        component={MarketingStackNavigator}
        options={{ title: t('marketing') }}
      />
      <MenuStack.Screen
        name="LocationProfileScreen"
        component={LocationProfileStackNavigator}
        options={{ title: t('businessProfileDetails') }}
      />
      <MenuStack.Screen
        name="PagePermissionsSettingsScreen"
        component={PagePermissionsSettingsScreen}
        options={{ title: t('accountPermissions') }}
      />
      <MenuStack.Screen name="PageSubscriptionSettingsScreen" component={SubscriptionStackNavigator} />
      <MenuStack.Screen name="PageCheckoutSettingsScreen" component={CheckoutSettingsStackNavigator} />
      <MenuStack.Screen name="PageMembershipSettingsScreen" component={MembershipSettingsStackNavigator} />

      <MenuStack.Screen name="PageReportsMobileScreen" component={PageReportsScreen} options={{ title: 'Rapoarte' }} />
      <MenuStack.Screen
        name="PagePendingClientsScreen"
        component={PagePendingClientsScreen}
        options={{ title: t('pendingClients') }}
      />
      <MenuStack.Screen
        name="PageReviewsScreen"
        component={PageReviewsStackNavigator}
        options={{ title: t('reviews') }}
      />
      <MenuStack.Screen
        name="PageOnlinePaymentsScreen"
        component={PageOnlinePaymentsSettingsStackNavigator}
        options={{ title: t('onlinePayments') }}
      />
      <MenuStack.Screen
        name="PageNotificationsSettingsScreen"
        component={NotificationsSettingsStackNavigator}
        options={{
          title: t('clientNotifications'),
        }}
      />
      <MenuStack.Screen
        name="WorkerCalendarSettingsScreen"
        component={WorkerCalendarSettingsScreen}
        options={{ title: t('calendarSettings') }}
      />
      <MenuStack.Screen
        name="WorkerNotificationsSettingsScreen"
        component={WorkerNotificationsSettingsScreen}
        options={{ title: t('clientNotifications') }}
      />
      <MenuStack.Screen
        name="WorkerProfileSettingsScreen"
        component={WorkerProfileSettingsScreen}
        options={{ title: t('profileDetails') }}
      />
      <MenuStack.Screen
        name="WorkerScheduleSettingsScreen"
        component={WorkerScheduleSettingsScreen}
        options={{ title: t('schedule') }}
      />
      <MenuStack.Screen
        name="WorkerServicesSettingsScreen"
        component={WorkerServicesSettingsStackNavigator}
        options={{ title: t('personalServices') }}
      />

      <MenuStack.Screen name="HelpCenterScreen" component={HelpCenterScreen} options={{ title: 'Centru de ajutor' }} />
      <MenuStack.Screen
        name="ContactSupportScreen"
        component={ContactSupportScreen}
        options={{ title: t('contact') }}
      />

      <MenuStack.Screen
        name="ShopScreen"
        component={ShopNavigator}
        options={{
          presentation: 'modal',
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
      />

      <MenuStack.Screen
        name="SharePageProfileScreen"
        component={SharePageProfileScreen}
        options={{ title: t('shareProfile') }}
      />
    </MenuStack.Navigator>
  );
};

export default MenuStackNavigator;

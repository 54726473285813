import { Body, ConfirmBox, H1, Icon, ModalOverlay, Row, Spacer, Title, Column } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Platform, Image } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const LocationPermissionDialog: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('location');

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    onSuccess();
  };

  const leftAction = {
    text: t('deleteCancel'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: Platform.OS === 'ios' ? t('iosOpenSettings') : t('androidOpenSettings'),
    onPress: confirm,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('locationPermission')}
          icon="info"
          onClose={cancel}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          {Platform.OS === 'ios' ? (
            <>
              <H1>{t('locationIosPermissionTitle')}</H1>
              <Spacer size={16} />
              <Column>
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationIosStep1">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Image
                    style={{ width: 24, height: 24, marginLeft: 12 }}
                    source={require('../../../../../assets/images/permissions/mero_icon_go_to_settings.png')}
                  />
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationIosStep2">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Image
                    style={{ width: 24, height: 24, marginLeft: 12 }}
                    source={require('../../../../../assets/images/permissions/mero_pro_icon.png')}
                  />
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationIosStep3">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Column style={{ marginLeft: 12 }}>
                    <Icon type="location-services-square" />
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationIosStep4">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Column style={{ marginLeft: 8, justifyContent: 'center' }}>
                    <Icon type="checkmark" color="#3497fd" />
                  </Column>
                </Row>
              </Column>
            </>
          ) : (
            <>
              <H1>{t('locationAndroidPermissionTitle')}</H1>
              <Spacer size={16} />
              <Column>
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationAndroidStep1">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Column style={{ marginLeft: 12 }}>
                    <Icon type="android-settings" />
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationAndroidStep2">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Column style={{ marginLeft: 12 }}>
                    <Icon type="android-apps" />
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationAndroidStep3">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Image
                    style={{ width: 24, height: 24, marginLeft: 12 }}
                    source={require('../../../../../assets/images/permissions/mero_pro_icon.png')}
                  />
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationAndroidStep4">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                </Row>
                <Spacer size={16} />
                <Row alignItems="center">
                  <Body>
                    <Trans ns={'location'} t={t} i18nKey="locationAndroidStep5">
                      0<Title>1</Title>
                    </Trans>
                  </Body>
                  <Column style={{ marginLeft: 12 }}>
                    <Icon type="android-slider" />
                  </Column>
                </Row>
              </Column>
            </>
          )}
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default LocationPermissionDialog;

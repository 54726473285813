import { colors, Body, styles as meroStyles, Row } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { StyleProp, TextStyle, View } from 'react-native';

import { NormalizedAppointmentEvent } from '../../../NormalizedEvent';
import { styles as commonStyles } from '../../styles';
import BoostIcon from '../BoostIcon';
import ClientFlag from '../ClientFlag';
import DiscountIcons from '../DiscountIcons';
import NoShowIcon from '../NoShowIcon';

type Props = {
  readonly event: NormalizedAppointmentEvent;
  readonly showTime: boolean;
  readonly now: DateTime;
};

const AppointmentCalendarEventView: React.FC<Props> = ({ event, showTime, now }) => {
  const eventDuration = React.useMemo(() => event.end.diff(event.start, 'minute'), [event.end, event.start]);
  const now2EndDiff = React.useMemo(() => now.diff(event.end, 'minute').minutes, [now, event.end]);
  const isPastEvent = now2EndDiff > 0;
  const isDeleted = event.extra.isDeleted ?? false;
  const isPendingEvent = event.extra.isPending ?? false;
  const isNotRelevant = isDeleted || isPastEvent;

  const clientIsBlocked = event.extra.clientIsBlocked ?? false;
  const clientIsWarned = event.extra.clientIsWarned ?? false;
  const clientIsBoosted = event.extra.clientIsBoost ?? false;
  const couponTypes = event.extra.couponTypes;
  const isNoShow = event.extra.isNoShow ?? false;
  const hasCheckoutFinished = event.extra.hasCheckoutFinished ?? false;

  // Some dynamic styles
  const pastEventTextStyle = isNotRelevant ? [{ color: '#52577F70' }] : [];
  const eventColor = event.extra.colors && event.extra.colors.length > 0 ? event.extra.colors[0] : undefined;
  const eventStatusTextStyle = event.extra.isPending ? { color: colors.OUTRAGEOUS_ORANGE } : {};

  const getServices = (style: StyleProp<TextStyle>[] = []) => {
    if (event.extra.bookedServices) {
      if (event.extra.bookedServices.length > 2) {
        const [first, second] = event.extra.bookedServices;
        return [first, `${second} (+ încă ${event.extra.bookedServices.length - 2})`].map((s) => (
          <Body key={s} style={[commonStyles.eventClient, ...style, eventStatusTextStyle]}>
            {s}
          </Body>
        ));
      }
      return event.extra.bookedServices.map((s) => (
        <Body key={s} style={[commonStyles.eventClient, ...style, eventStatusTextStyle]}>
          {s}
        </Body>
      ));
    }
    return null;
  };

  const getServicesShort = () => {
    if (event.extra.bookedServices) {
      if (event.extra.bookedServices.length > 1) {
        return (
          <Body
            style={[commonStyles.eventClient, commonStyles.full, pastEventTextStyle, eventStatusTextStyle]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {event.extra.bookedServices[0]}, ...
          </Body>
        );
      } else if (event.extra.bookedServices.length > 0) {
        return (
          <Body
            style={[commonStyles.eventClient, pastEventTextStyle, eventStatusTextStyle]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {event.extra.bookedServices[0]}
          </Body>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const clientFlag =
    clientIsBlocked || clientIsWarned ? (
      <>
        <ClientFlag isBlocked={clientIsBlocked} isWarned={clientIsWarned} />{' '}
      </>
    ) : null;

  return (
    <View
      style={[
        commonStyles.eventCell,
        eventDuration.minutes < 20 ? commonStyles.smallCell : undefined,
        {
          backgroundColor: isNotRelevant ? '#E9ECEF' : isPendingEvent ? colors.WHITE : eventColor ?? '#C9E6CA',
        },
        isPendingEvent
          ? { borderWidth: 1, borderColor: colors.OUTRAGEOUS_ORANGE }
          : {
              borderLeftColor: colors.WHITE,
              borderRightColor: colors.WHITE,
              borderTopColor: colors.WHITE,
              borderBottomColor: colors.WHITE,
            },
        hasCheckoutFinished ? { borderLeftWidth: 4, borderLeftColor: colors.SHAMROCK } : {},
        isDeleted ? { borderLeftWidth: 4, borderLeftColor: colors.RADICAL_RED } : {},
        { height: '100%' },
      ]}
    >
      {eventDuration.minutes <= 20 && showTime ? (
        <>
          <Row style={{ alignItems: 'center' }}>
            <Body style={[commonStyles.eventTitle, pastEventTextStyle, eventStatusTextStyle]}>{event.title} </Body>
            <DiscountIcons couponTypes={couponTypes} />
            <BoostIcon isBoostClient={clientIsBoosted} />
            <NoShowIcon isNoShow={isNoShow} />
            <Body
              style={[commonStyles.full, commonStyles.lineHeight17, pastEventTextStyle]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              <Body style={[meroStyles.text.bold, commonStyles.eventClient, pastEventTextStyle]}>
                {clientFlag}
                {clientFlag ? ' ' : ''}
                {event.extra.client}
                {` `}
              </Body>
              {getServicesShort()}
              {event.extra.note ? (
                <Body style={[commonStyles.eventComment, pastEventTextStyle, eventStatusTextStyle]}>
                  {' '}
                  * {event.extra.note}
                </Body>
              ) : null}
            </Body>
          </Row>
        </>
      ) : eventDuration.minutes <= 30 && showTime ? (
        <>
          <Body style={[commonStyles.eventTitle, pastEventTextStyle, eventStatusTextStyle]}>{event.title}</Body>
          <Body style={[commonStyles.eventTitle, pastEventTextStyle, eventStatusTextStyle]}>
            <Row style={[{ alignItems: 'center', width: '100%' }]}>
              <DiscountIcons couponTypes={couponTypes} />
              <BoostIcon isBoostClient={clientIsBoosted} />
              <NoShowIcon isNoShow={isNoShow} />
              <Body
                style={[commonStyles.full, commonStyles.lineHeight17, pastEventTextStyle]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                <Body
                  style={[meroStyles.text.bold, commonStyles.eventClient, pastEventTextStyle, eventStatusTextStyle]}
                >
                  {clientFlag}
                  {clientFlag ? ' ' : ''}
                  {event.extra.client}{' '}
                </Body>
                {getServicesShort()}
                {event.extra.note ? (
                  <Body style={[commonStyles.eventComment, pastEventTextStyle, eventStatusTextStyle]}>
                    {' '}
                    * {event.extra.note}
                  </Body>
                ) : null}
              </Body>
            </Row>
          </Body>
        </>
      ) : (
        <>
          <Body style={[commonStyles.eventTitle, pastEventTextStyle, eventStatusTextStyle]}>{event.title}</Body>
          <Row style={{ alignItems: 'center' }}>
            <DiscountIcons couponTypes={couponTypes} />
            <BoostIcon isBoostClient={clientIsBoosted} />
            <NoShowIcon isNoShow={isNoShow} />
            <Body style={[commonStyles.full, commonStyles.lineHeight17]} numberOfLines={1} ellipsizeMode="tail">
              <Body style={[meroStyles.text.bold, commonStyles.eventClient, pastEventTextStyle, eventStatusTextStyle]}>
                {clientFlag}
                {clientFlag ? ' ' : ''}
                {event.extra.client}
              </Body>
            </Body>
          </Row>
          {eventDuration.minutes > 30 && getServices(pastEventTextStyle)}
          {event.extra.note ? (
            <Body style={[commonStyles.eventComment, pastEventTextStyle, eventStatusTextStyle]}>
              * {event.extra.note}
            </Body>
          ) : null}
        </>
      )}
    </View>
  );
};

export default React.memo(AppointmentCalendarEventView);

import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.ClientAppointmentPaymentReceivedNotification;
};

const ClientAppointmentPaymentReceivedNotification = ({ notification }: Props): React.ReactElement | null => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_client_accepted" />
      <View style={styles.notificationBody}>
        <Body>
          A fost acceptată plata de la{' '}
          <Body style={meroStyles.text.semibold}>
            {payload.client.firstname} {payload.client.lastname}
          </Body>{' '}
          în valoare de <Body style={meroStyles.text.semibold}>{payload.amount / 100} RON</Body>.
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname}{' '}
          {payload.worker.lastname ?? ''} {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default ClientAppointmentPaymentReceivedNotification;

import { isDefined, PositiveInt } from '@mero/api-sdk';
import { MergedService, mergeServices, SavedService } from '@mero/api-sdk/dist/services';
import { useShowError } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ServicesGroup } from '../../../../../components/GroupedServicesList';
import SelectServiceScreenView from '../../../../../components/SelectServiceScreen';

import { StackScreenProps } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../../../hooks/useEscPressWeb';
import useGoBack from '../../../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { Item, MembershipTemplateContext } from '../../../../../contexts/MembershipTemplateContext';
import { MembershipSettingsStackParamList } from '../../../../../types';
import { getPriceValue } from '../../../../../utils/number';

export const serviceToMembershipItem = (
  service: Pick<SavedService, '_id' | 'name' | 'durationInMinutes' | 'price'>,
): Item => ({
  type: 'Service',
  service: {
    _id: service._id,
    name: service.name as DefinedString,
    durationInMinutes: service.durationInMinutes as PositiveInt,
    price:
      service.price.type === 'fixed'
        ? {
            type: 'Fixed',
            fixed: {
              amount: ScaledNumber.fromNumber(getPriceValue(service.price), 2),
              unit: 'RON',
            },
          }
        : { type: 'Hidden' },
    groupIds: [],
  },
  quantity: {
    type: 'Limited',
    value: 1 as PositiveInt,
  },
  discount: undefined,
  sellingPrice: {
    amount: ScaledNumber.fromNumber(0, 2),
    unit: 'RON',
  },
});

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MembershipSettingsStackParamList, 'SelectMembershipService'>;

const SelectMembershipServiceScreen: React.FC<Props> = ({ page, authorization }) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });
  const showError = useShowError();

  const [membershipTemplateState, { setItem }] = MembershipTemplateContext.useContext();

  const [services, setServices] = React.useState<ServicesGroup[]>([]);

  const selectServiceCallback = React.useCallback(
    (mergedService: MergedService) => {
      const service = page.details.services.find((s) => s._id === mergedService._id);

      if (!service) {
        return;
      }

      setItem(serviceToMembershipItem(service));

      goBack();
    },
    [goBack],
  );

  const getServices = React.useCallback(async () => {
    try {
      const selectedItemIds = membershipTemplateState.items
        .map((item) => (item.type === 'Service' ? item.service._id : undefined))
        .filter(isDefined);
      const groupedServices = await meroApi.pages.getGroupedServices(page.details._id);
      const services = groupedServices.grouped
        .map((group) => ({
          group: group.group,
          services: group.services
            .filter((s) => !selectedItemIds.includes(s._id))
            .map((service) => mergeServices(service, page.details.services))
            .filter(isDefined),
        }))
        .filter((group) => group.services.length > 0);

      setServices(
        groupedServices.others.length > 0
          ? [
              ...services,
              {
                group: {
                  name: 'Alte Servicii',
                },
                services: groupedServices.others
                  .filter((s) => !selectedItemIds.includes(s._id))
                  .map((service) => mergeServices(service, page.details.services))
                  .filter(isDefined),
              },
            ]
          : services,
      );
    } catch (e) {
      showError(e);
    }
  }, [page.details._id]);

  React.useEffect(() => {
    getServices();
  }, [page]);

  return (
    services.length > 0 && (
      <SelectServiceScreenView services={services} onBackPressed={goBack} onServiceSelected={selectServiceCallback} />
    )
  );
};

export default pipe(SelectMembershipServiceScreen, CurrentBusiness, Authorized);

import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';

type SupportedTypes = string | undefined;

type ValidInputState<T> = {
  readonly isValid: true;
  readonly value: T;
};

type InvalidInputState = {
  readonly value: SupportedTypes;
  readonly isValid: false;
  readonly error: t.Errors;
};

type ComputedInputState<T> = ValidInputState<T> | InvalidInputState;

export const useSafeInput = <T extends SupportedTypes>(decoder: t.Decoder<SupportedTypes, T>) => {
  return (initial?: T) => {
    const [value, setValue] = React.useState<SupportedTypes>(initial);

    const state: ComputedInputState<T> = React.useMemo(
      () =>
        pipe(
          value,
          decoder.decode,
          E.fold(
            (e): ComputedInputState<T> => ({
              value,
              isValid: false,
              error: e,
            }),
            (value): ComputedInputState<T> => ({
              isValid: true,
              value,
            }),
          ),
        ),
      [value],
    );

    return [state, setValue] as const;
  };
};

import { apiError, ApiError } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ClientFeedbackDetails } from '@mero/api-sdk/dist/pro/clientFeedback/clientFeedbackDetails';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { AuthContext, meroApi } from '../AuthContext';
import { CalendarEntryContext } from '../CalendarEntryContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

type ClientFeedbackState =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Loading';
      readonly pageId: PageId;
      readonly entryId: AppointmentId;
      readonly occurrenceIndex: number;
    }
  | {
      readonly type: 'Loaded';
      readonly pageId: PageId;
      readonly entryId: AppointmentId;
      readonly occurrenceIndex: number;
      readonly canCreateOrUpdateClientFeedback: boolean;
      readonly clientFeedback: ClientFeedbackDetails | undefined;
    }
  | {
      readonly type: 'Failed';
      readonly error?: ApiError<unknown>;
    };

const defaultState = (): ClientFeedbackState => ({
  type: 'New',
});

export const ClientFeedbackContext = createModelContext(
  defaultState(),
  {
    setLoading: (_, payload: { pageId: PageId; entryId: AppointmentId; occurrenceIndex: number }) => ({
      type: 'Loading',
      pageId: payload.pageId,
      entryId: payload.entryId,
      occurrenceIndex: payload.occurrenceIndex,
    }),
    setLoaded: (
      _,
      payload: {
        pageId: PageId;
        entryId: AppointmentId;
        occurrenceIndex: number;
        canCreateOrUpdateClientFeedback: boolean;
        clientFeedback: ClientFeedbackDetails | undefined;
      },
    ) => {
      return {
        type: 'Loaded',
        pageId: payload.pageId,
        entryId: payload.entryId,
        occurrenceIndex: payload.occurrenceIndex,
        canCreateOrUpdateClientFeedback: payload.canCreateOrUpdateClientFeedback,
        clientFeedback: payload.clientFeedback,
      };
    },
    setFailed: (_, error: ApiError<unknown> | undefined) => ({ type: 'Failed', error: error }),
    mutate: (s, fn: (s: ClientFeedbackState) => ClientFeedbackState): ClientFeedbackState => fn(s),
  },
  (dispatch) => {
    return {
      init: (payload: { pageId: PageId; entryId: AppointmentId; occurrenceIndex: number }): void => {
        dispatch.mutate((_state: ClientFeedbackState) => {
          const { pageId, entryId, occurrenceIndex } = payload;

          const init = async (): Promise<void> => {
            try {
              log.debug(
                `Load client feedback for appointment with id ${entryId} (#${occurrenceIndex}) for pageId ${pageId}`,
              );

              const { clientFeedback, canCreateOrUpdateClientFeedback } =
                await meroApi.pro.clientFeedback.getAppointmentClientFeedback({
                  pageId,
                  appointmentId: entryId,
                  occurrenceIndex,
                });

              log.debug(`ClientFeedback ${entryId} loaded`);

              dispatch.setLoaded({
                pageId,
                entryId,
                occurrenceIndex,
                clientFeedback: clientFeedback,
                canCreateOrUpdateClientFeedback: canCreateOrUpdateClientFeedback,
              });
            } catch (e) {
              log.error(`Failed to fetch client feedback by pageId: ${pageId}, entryId: ${entryId}`, e);
              if (apiError(t.unknown).is(e)) {
                dispatch.setFailed(e);
              } else {
                dispatch.setFailed(undefined);
              }
            }
          };

          init().catch(log.exception);

          return {
            type: 'Loading',
            pageId: pageId,
            entryId: entryId,
            occurrenceIndex: occurrenceIndex,
          };
        });
      },
      reload: (): void => {
        dispatch.mutate((state: ClientFeedbackState) => {
          if (state.type === 'Loaded') {
            const reload = async (): Promise<void> => {
              try {
                const { pageId, entryId, occurrenceIndex } = state;
                log.debug(
                  `Reload client feedback for appointment with id ${entryId} (#${occurrenceIndex}) for pageId ${pageId}`,
                );

                const { clientFeedback, canCreateOrUpdateClientFeedback } =
                  await meroApi.pro.clientFeedback.getAppointmentClientFeedback({
                    pageId: pageId,
                    appointmentId: entryId,
                    occurrenceIndex: occurrenceIndex,
                  });

                log.debug(`ClientFeedback ${state.entryId} loaded`);

                dispatch.setLoaded({
                  ...state,
                  pageId: state.pageId,
                  entryId: state.entryId,
                  occurrenceIndex: state.occurrenceIndex,
                  canCreateOrUpdateClientFeedback: canCreateOrUpdateClientFeedback,
                  clientFeedback: clientFeedback,
                });
              } catch (e) {
                if (apiError(t.unknown).is(e)) {
                  dispatch.setFailed(e);
                } else {
                  log.error(`Failed to fetch calendar entry by entryId: ${state.entryId}`, e);
                  dispatch.setFailed(undefined);
                }
              }
            };

            reload().catch(log.exception);

            return {
              type: 'Loading',
              pageId: state.pageId,
              entryId: state.entryId,
              occurrenceIndex: state.occurrenceIndex,
            };
          } else {
            return state;
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [authState] = AuthContext.useContext();
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [calendarEntryState] = CalendarEntryContext.useContext();
  const [, { init }] = ClientFeedbackContext.useContext();

  const page = currentBusinessState.type === 'Loaded' ? currentBusinessState.page : undefined;
  const user = authState.type === 'Authorized' ? authState.user : undefined;
  const entry = calendarEntryState.type === 'Loaded' ? calendarEntryState.entry : undefined;

  React.useEffect(() => {
    if (page && user && entry) {
      init({
        pageId: page.details._id,
        entryId: entry._id,
        occurrenceIndex: entry.occurrenceIndex,
      });
    }
  }, [init, entry, page, user]);

  return <>{children}</>;
};

export const withClientFeedbackContext = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function withClientFeedbackContext(props: P) {
    return (
      <ClientFeedbackContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </ClientFeedbackContext.Provider>
    );
  };
};

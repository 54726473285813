import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  hrPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
});

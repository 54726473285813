import { PageMemberPreview } from '@mero/api-sdk/dist/pages';
import { SavedWorker, Worker } from '@mero/api-sdk/dist/workers';
import { Avatar, colors, Column, H1, Line, MeroHeader, SmallBody, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import MenuItem from '../../../../../components/MenuItem';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { AuthContext } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsEditStackParamList } from '../../../../../types';
import { styles } from './ProDetailsScreen.styles';

export type Props = StackScreenProps<ProsEditStackParamList, 'ProDetails'>;

const ProDetailsScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation('pros');
  const isFocused = useIsFocused();
  const goBack = useGoBack();

  const { userId } = route.params;

  const [pageState] = CurrentBusinessContext.useContext();
  const [authState] = AuthContext.useContext();

  const [memberDetails, setMemberDetails] = React.useState<PageMemberPreview | null>(null);
  const [workerDetails, setWorkerDetails] = React.useState<SavedWorker | null>(null);
  const [isInvited, setIsInvited] = React.useState(false);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      const member = pageState.page.members.find((m) => m.user._id === userId);
      const worker = pageState.page.workers.find((w) => w.user._id === userId);

      setMemberDetails(member ?? null);
      setWorkerDetails(worker ?? null);
      setIsInvited(worker ? Worker.isInvited(worker, pageState.page.details) : false);
    }
  }, [pageState.type, isFocused]);

  const roleName = memberDetails?.roles[0];

  const isOwnProfile = React.useMemo(() => {
    if (authState.type === 'Authorized') {
      return authState.user._id === userId;
    }

    return true;
  }, [userId, authState]);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER, overflow: 'hidden' }}>
      <MeroHeader canGoBack onBack={goBack} />
      {memberDetails && (
        <ScrollView>
          <Column style={{ paddingBottom: 48 }}>
            <Column style={{ alignItems: 'center', paddingTop: 16, paddingBottom: 32 }}>
              <Avatar
                firstname={memberDetails.user.profile.firstname ?? ''}
                lastname={memberDetails.user.profile.lastname ?? ''}
                source={memberDetails.user.profile.photo?.small}
                size={120}
              />
              <Spacer size={16} />
              <H1>
                {memberDetails.user.profile.firstname} {memberDetails.user.profile.lastname}
              </H1>
              <Spacer size={8} />
              <SmallBody>
                {roleName?.name}
                {isInvited && ` (${t('invited')})`}
              </SmallBody>
            </Column>
            <Column style={[styles.hrPadding, { backgroundColor: colors.WHITE }]}>
              <MenuItem
                title={t('profileDetails')}
                icon="pro-menu-profile-details"
                onPress={() => navigation.push('ProProfile', { userId, workerId: workerDetails?._id })}
              />
              <Line />
              <MenuItem
                title={t('calendarSettings')}
                subtitle={`${t(
                  workerDetails ? 'calendarSettingsDescriptionPositive' : 'calendarSettingsDescriptionNegative',
                )}${workerDetails?.calendar.settings.isPrivate ? ` - ${t('calendarSettingsDescription2')}` : ''}`}
                icon="pro-menu-calendar-settings"
                onPress={() => navigation.navigate('ProCalendar', { userId, workerId: workerDetails?._id })}
              />
              <Line />
              {workerDetails && (
                <>
                  <MenuItem
                    title={t('schedule')}
                    icon="pro-menu-pending-clients"
                    onPress={() => navigation.navigate('ProSchedule', { workerId: workerDetails._id })}
                  />
                  <Line />
                  <MenuItem
                    title={t('servicesCustom')}
                    subtitle={t('activeServices', {
                      count: workerDetails.services.filter((s) => s.pageId === pageState.page.details._id).length,
                    })}
                    icon="pro-menu-services"
                    onPress={() => navigation.navigate('ProServices', { workerId: workerDetails._id })}
                  />
                  <Line />
                </>
              )}
              <MenuItem
                disabled={
                  !(
                    pageState.type === 'Loaded' &&
                    pageState.page.permissions.account.canManagePermissions() &&
                    !isOwnProfile
                  )
                }
                title={t('permissions')}
                subtitle={roleName?.name}
                icon="pro-menu-permissions"
                onPress={() => navigation.navigate('ProPermissions', { userId })}
              />
            </Column>
          </Column>
        </ScrollView>
      )}
    </ModalScreenContainer>
  );
};

export default ProDetailsScreen;

import * as MeroApi from '@mero/api-sdk';
import {
  Body,
  colors,
  HSpacer,
  Icon,
  sizes,
  SmallBody,
  styles as meroStyles,
  NotificationCalendarClockIcon,
} from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentModifiedByAnotherProNotification;
};

const AppointmentModifiedByAnotherProNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const appTs = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={[styles.notificationContainer]}>
      <NotificationCalendarClockIcon />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>
            {payload.modifiedByUser.firstname} {payload.modifiedByUser.lastname} ({payload.modifiedByUser.phone})
          </Body>{' '}
          ți-a modificat programarea{' '}
          {payload.client && (
            <>
              pentru{' '}
              <Body style={meroStyles.text.semibold}>
                {payload.client?.firstname} {payload.client?.lastname}
              </Body>{' '}
            </>
          )}
          <>
            de <Body style={meroStyles.text.semibold}>{appTs.toFormat('EEEE, d LLLL yyyy', { locale: 'ro' })}</Body>
          </>{' '}
          la ora <Body style={meroStyles.text.semibold}>{appTs.toFormat('H:mm', { locale: 'ro' })}.</Body>
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname} {payload.worker.lastname}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentModifiedByAnotherProNotification;

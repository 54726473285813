import { MembershipPurchaseId } from '@mero/api-sdk';
import { AvailableMembershipItems } from '@mero/api-sdk/dist/memberships/membershipAvailableItems';
import { colors, Line, Spacer } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from '../ClientDetailsScreen/components/ClientEmptyListView';

import ClientMembershipsListItemView from './ClientMembershipsListItemView';
import { AvailableMembershipWithItems, SelectedItem } from './SelectMembershipPaymentScreen';

type Props = {
  readonly onSelect: (uiKey: string, membershipId: MembershipPurchaseId) => void;
  readonly selectedItems: SelectedItem;
  readonly HeaderElement?: React.ReactElement;
  readonly memberships: AvailableMembershipWithItems[];
};

const ClientMembershipsListView: React.FC<Props> = ({ onSelect, selectedItems, HeaderElement, memberships }) => {
  const renderItem = React.useCallback(
    ({ item }: { item: AvailableMembershipWithItems }) => {
      return <ClientMembershipsListItemView selectedItems={selectedItems} item={item} onSelect={onSelect} />;
    },
    [selectedItems],
  );

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1 }}
      ListHeaderComponent={HeaderElement}
      data={memberships}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView
          title="Niciun abonament"
          subtitle="Clientul nu are niciun abonament valid sau compatibil"
        />
      }
      ItemSeparatorComponent={() => (
        <>
          <Spacer size={16} />
        </>
      )}
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

const keyExtractor = (item: AvailableMembershipItems<MeroUnits.Any>): string => item.membership._id;

export default ClientMembershipsListView;

import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { price, Zero, Price } from '@mero/api-sdk/dist/services';
import { ConfirmBox, ModalOverlay, Spacer, Text, useShowError, useToast } from '@mero/components';
import { noDiacritics } from '@mero/shared-components';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as Id from 'fp-ts/lib/Identity';
import * as O from 'fp-ts/lib/Option';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateFromISOString } from 'io-ts-types';
import * as React from 'react';
import { Dimensions, Linking, Platform } from 'react-native';

import AddBookingScreenView from '../../../components/AddBookingScreen';
import { BookedServiceWithWorkerItem } from '../../../components/BookedServiceWithWorkerListItem';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';
import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { AuthContext, meroApi } from '../../../contexts/AuthContext';
import { BookingClientSelectContext } from '../../../contexts/BookingClientSelectContext';
import { BookingCreateContext, withBookingCreateContextProvider } from '../../../contexts/BookingCreateContext';
import {
  BookingFormContext,
  addDefaultEventDuration,
  BookedServicePreview,
} from '../../../contexts/BookingFormContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../../../contexts/SelectBookingServiceContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import { splitWithDefault } from '../../../utils/objects';

type BookingCreateScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'BookingCreateScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = CurrentBusinessProps & {
  navigation: BookingCreateScreenNavigationProp;
  route: RouteProp<BookingStackParamList, 'BookingCreateScreen'>;
};

const BookingCreateScreen: React.FC<Props> = ({ page, route, navigation }: Props) => {
  const {
    date,
    workerId: routeWorkerId,
    serviceIds,
    clientId,
    clientFullName,
    clientPhone,
    waitingListId,
  } = route.params || {};

  const dateParam = React.useMemo(
    () =>
      pipe(
        date,
        DateFromISOString.decode,
        E.fold(() => new Date(), identity),
        Id.map((a) => {
          // round to closes future N minutes
          const timePeriod = 1000 * 60 * 5;
          return new Date(Math.round(a.getTime() / timePeriod) * timePeriod);
        }),
      ),
    [date],
  );

  const toast = useToast();
  const showError = useShowError();
  const [, { pushEvent }] = AppEventsContext.useContext();
  const [authState] = AuthContext.useContext();
  const { logEvent } = useAnalytics({ screenName: 'new_booking_screen', eventName: 'screen_shown', onDidMount: false });

  const [
    formState,
    {
      reset: resetBookingForm,
      setStart,
      setEnd,
      setPerformer,
      setRecurrenceRule,
      setNotes,
      setClient,
      addService,
      updateServicesPreview,
      deleteServiceAt,
      confirmOverride,
    },
  ] = BookingFormContext.useContext();
  const { start, end, recurrenceRule, notes, client, services, performer } = formState;

  const [formInitialized, setFormInitialized] = React.useState(false);
  const [bookingAddedEventParams, setBookingAddedEventParams] = React.useState<any>({});

  const startDate = start ?? dateParam;
  const endDate = end ?? addDefaultEventDuration(startDate);

  // Booking client
  const [{ value: newClient }, { set: setNewClient }] = BookingClientSelectContext.useContext();
  const [selectServiceState, { reset: resetServiceSelect, setServicesFilter }] =
    SelectBookingServiceContext.useContext();

  const [selectPerformerState, { reset: resetPerformerSelect, setSelectedPerformer }] =
    SelectBookingPerformerContext.useContext();
  const [createBookingState, { createBooking, reset: resetCreateBooking }] = BookingCreateContext.useContext();
  const [showConfirmOverride, setShowConfirmOverride] = React.useState(false);

  const [, { reload: reloadCalendar }] = CalendarContext.useContext();

  const servicesWithWorker = React.useMemo((): BookedServiceWithWorkerItem[] => {
    return pipe(
      services,
      A.map(
        (s): BookedServiceWithWorkerItem => ({
          type: s.type,
          service: s.service,
          worker: performer?.user,
          workerServices: performer?.services ?? [],
        }),
      ),
    );
  }, [services, performer]);

  const selectClientCallback = React.useCallback(() => {
    navigation.push('SelectNewBookingClientScreen');
  }, [navigation]);

  const removeClientCallback = () => setClient({ type: 'none' });

  const addServiceCallback = React.useCallback(() => {
    navigation.push('SelectNewBookingServiceScreen');
  }, [navigation]);

  const removeServiceCallback = React.useCallback(
    (s: unknown, index: number) => {
      deleteServiceAt(index);
    },
    [deleteServiceAt],
  );

  const selectWorkerCallback = React.useCallback(() => {
    navigation.push('SelectBookingPerformerScreen');
  }, [navigation]);

  const saveBooking = async (override = false): Promise<void> => {
    if (createBookingState.type === 'Ready' || createBookingState.type === 'Failed') {
      if (formState.type === 'Valid') {
        const selectedWorkerId = formState.performer._id;
        const selectedWorker = page.workers.find((w) => w._id === selectedWorkerId);
        if (selectedWorker) {
          // save bookings params for analytics event
          const customerType: 'none' | 'existing' | 'new' = client.type;

          const selectedServices = services.filter((sv) =>
            formState.bookedServiceIds.some((bsvId) => bsvId === sv.service._id),
          );
          const totalPrice = selectedServices.reduce<Price>((sum, sv) => price.sum(sv.service.price, sum), Zero);

          setBookingAddedEventParams({
            place_id: page.details._id,
            place_name: page.details.name,
            place_city: noDiacritics(page.details.location?.city ?? '').toLowerCase(),
            booking_total: totalPrice.fixed ?? 0,
            customer_type: customerType,
          });

          createBooking({
            calendarId: selectedWorker.calendar._id,
            pageId: page.details._id,
            start: formState.start,
            end: formState.end,
            recurrenceRule: formState.recurrenceRule,
            workerId: selectedWorkerId,
            bookedServiceIds: formState.bookedServiceIds,
            note: formState.notes,
            client:
              formState.client.type === 'existing'
                ? { type: 'existing', clientId: formState.client.client._id }
                : formState.client.type === 'new' && !formState.client.isEmpty
                ? {
                    type: 'new',
                    firstname: formState.client.firstname,
                    lastname: formState.client.lastname,
                    phone: formState.client.phone,
                  }
                : undefined,
            override: override,
            waitingListId: waitingListId,
          });
        } else {
          log.error(
            `Failed to select selected worker with id ${selectedWorkerId} before creating appointment on page ${page.details._id}`,
          );
        }
      } else {
        // check for service errors
        const invalidService = formState.services.find((s) => s.type === 'invalid');
        if (invalidService) {
          showError(new Error(`Alege un serviciu oferit de profesionistul selectat pentru a putea salva programarea.`));
        }
      }
    } else {
      log.error(`Cannot save booking: invalid createBookingState with type="${createBookingState.type}"`);
    }
  };

  const saveBookingCallback = React.useCallback(() => {
    saveBooking();
  }, [createBooking, saveBooking]);

  const confirmBookingOverrideCallback = React.useCallback(() => {
    setShowConfirmOverride(false);
    confirmOverride();
    saveBooking(true);
  }, [saveBookingCallback]);

  const sendNotification = React.useCallback(async (appointmentId: AppointmentId, phoneNumber: string) => {
    try {
      if (Platform.OS === 'web' && Dimensions.get('window').width > 768) {
        return;
      }

      const notificationSettings = await meroApi.pages.getPageNotificationSettings({
        pageId: page.details._id,
      });

      if (!notificationSettings.appointmentMadeByPage) {
        return;
      }

      const message = await meroApi.notifications.getRenderedTextForAppointmentCreated({
        appointmentId,
      });

      const separator = Platform.OS === 'ios' ? '&' : '?';
      const url = `sms:${phoneNumber}${separator}body=${encodeURIComponent(message.text)}`;

      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      }
    } catch (error) {
      log.error('Failed to get page notification settings', error);
    }
  }, []);

  const goBackFn = useGoBack();
  const goBack = React.useCallback(() => {
    setFormInitialized(false);
    goBackFn();
  }, [setFormInitialized, goBackFn]);

  useEscPressWeb({
    onPress: goBack,
  });

  // initialize form date
  React.useEffect(() => {
    const initiateBookingForm = async () => {
      try {
        setFormInitialized(true);

        const performer = pipe(
          routeWorkerId,
          O.fromNullable,
          O.chain((id) =>
            pipe(
              page.workers,
              A.findFirst((w) => w._id === id),
            ),
          ),
          O.getOrElseW(() => undefined),
        );

        const client = clientId ? await meroApi.clients.getClientById(clientId).catch(() => undefined) : undefined;

        const services =
          formState.services.length > 0
            ? formState.services.map((s) => s.service)
            : splitWithDefault(serviceIds, [])
                .map((id) => page.details.services.find((s) => s._id === id))
                .filter((s) => s !== undefined)
                .map((s) => s as BookedServicePreview);

        resetBookingForm({
          start: startDate,
          end: end ?? endDate, // set a default for end (??? review this)
          client:
            formState.client.type === 'existing'
              ? formState.client.client
              : client
              ? ({
                  _id: client._id,
                  userId: client.user._id,
                  firstname: client.user.firstname,
                  lastname: client.user.lastname,
                  phone: client.user.phone,
                  photo: client.user.photo,
                  hideBoostDetails: client.hideBoostDetails,
                  isBoost: client.isBoost,
                  isBlocked: client.isBlocked,
                  isFavourite: client.isFavourite,
                  isWarned: client.isWarned,
                } satisfies ClientPreview)
              : undefined,
          newClient: clientPhone && clientFullName ? { phone: clientPhone, fullname: clientFullName } : undefined,
          performer,
          services,
        });

        // set performer context because it is also used by the
        // select service screen to filter by worker
        if (performer) setSelectedPerformer(performer);
      } catch (error) {
        log.error('Failed to initialize booking form', error);
      }
    };
    if (!formInitialized) {
      initiateBookingForm();
    }
  }, [formInitialized, formState, page.workers, startDate, end, endDate, routeWorkerId, setSelectedPerformer]);

  // Select client
  React.useEffect(() => {
    if (newClient !== undefined) {
      setNewClient(undefined); // reset the state so next select will trigger the change
      setClient({
        type: 'existing',
        client: newClient,
      });
    }
  }, [newClient, setClient]);

  // Handle service selection result
  React.useEffect(() => {
    if (selectServiceState.type === 'some') {
      resetServiceSelect();
      addService(selectServiceState.service);
    }
  }, [selectServiceState, addService, resetServiceSelect]);

  // Update services filter to exclude services already selected or not provided by selected worker
  React.useEffect(() => {
    setServicesFilter(
      (w) => performer === undefined || performer._id === w._id, // Show only services performed by current worker
    );
  }, [services, performer, setServicesFilter]);

  // Handle performer selection result
  React.useEffect(() => {
    if (selectPerformerState.type === 'selected') {
      // do not reset the context bc it is also used by select services screen
      // resetPerformerSelect();
      setPerformer(selectPerformerState.selected);
    }
  }, [selectPerformerState, resetPerformerSelect, setPerformer]);

  // Update service details when performer is changed
  React.useEffect(() => {
    updateServicesPreview({ pageServices: page.details.services, workers: page.workers });
  }, [performer, page.details.services, page.workers]);

  // Booking created state handling
  React.useEffect(() => {
    if (createBookingState.type === 'Created') {
      const phone =
        formState.client.type === 'existing'
          ? formState.client.client.phone
          : formState.client.type === 'new'
          ? formState.client.phone
          : undefined;
      if (
        createBookingState.appointmentId &&
        phone &&
        (client.type !== 'existing' || !client.client.hideBoostDetails)
      ) {
        sendNotification(createBookingState.appointmentId, phone);
      }
      resetCreateBooking();
      resetBookingForm({});
      setFormInitialized(false);
      reloadCalendar();
      pushEvent({
        type: 'AppointmentCreated',
        calendarId: createBookingState.calendarId,
        appointmentId: createBookingState.appointmentId,
      });
      goBack();
      toast.show({
        type: 'success',
        text: 'Programarea a fost adaugată',
      });

      logEvent('booking_added', bookingAddedEventParams);
    } else if (createBookingState.type === 'Failed') {
      resetCreateBooking();
      if (createBookingState.isOverride) {
        setShowConfirmOverride(true);
      } else {
        showError(createBookingState.error);
      }
    }
  }, [
    createBookingState,
    resetCreateBooking,
    resetBookingForm,
    setFormInitialized,
    reloadCalendar,
    setShowConfirmOverride,
    goBack,
  ]);

  return (
    <>
      <AddBookingScreenView
        mode="add"
        start={startDate}
        onStartChanged={setStart}
        end={endDate}
        onEndChanged={setEnd}
        client={client}
        onClientChanged={setClient}
        onChangeClientPress={selectClientCallback}
        onSelectClientPress={selectClientCallback}
        onRemoveClientPress={removeClientCallback}
        services={servicesWithWorker}
        performer={performer}
        onAddServicePress={addServiceCallback}
        onRemoveServicePress={removeServiceCallback}
        onSelectWorkerPress={selectWorkerCallback}
        recurrenceRule={recurrenceRule}
        onRecurrenceRuleChanged={setRecurrenceRule}
        notes={notes}
        onNotesChanged={setNotes}
        onClosePress={goBack}
        onSaveBooking={createBookingState.type === 'Creating' ? undefined : saveBookingCallback}
        fromWaitingList={waitingListId !== undefined}
      />
      {showConfirmOverride ? (
        <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ConfirmBox
            type="warn"
            icon="info"
            headerTitle="Actiune Importantă"
            canClose={true}
            onClose={() => {
              setShowConfirmOverride(false);
            }}
            leftAction={{
              text: 'Anulare',
              onPress: () => {
                setShowConfirmOverride(false);
              },
            }}
            rightAction={{
              text: 'Confirmă',
              onPress: confirmBookingOverrideCallback,
            }}
          >
            <Text h1>Confirmă suprapunere programare</Text>
            <Spacer size="8" />
            <Text>Aceasta programare se va suprapune cu altele. Eşti sigur că vrei să continui?</Text>
          </ConfirmBox>
        </ModalOverlay>
      ) : null}
    </>
  );
};

export default pipe(BookingCreateScreen, CurrentBusiness, withBookingCreateContextProvider);

import { StyleSheet } from 'react-native';

export const HEADER_HEIGHT = 70;

export const styles = StyleSheet.create({
  headerStyle: {
    height: HEADER_HEIGHT,
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: -1,
  },
  weekdays: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  withBorder: {
    borderRightWidth: 1,
    borderColor: '#ADADAD',
  },
  weekday: {
    textAlign: 'center',
    color: '#000000',
  },
  selectedDay: {
    borderRadius: 25,
    backgroundColor: '#080DE0',
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
  },
  selectedDayText: {
    color: '#ffffff',
  },
  normalDayText: {
    color: '#000000',
  },
  differentMonthDayText: {
    color: '#ADADAD',
  },
  todayDayText: {
    color: '#080DE0',
  },
});

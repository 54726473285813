import { OnlinePaymentsCommissionInfo } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  Column,
  H1,
  Row,
  Spacer,
  styles as meroStyles,
  HSpacer,
  MeroHeader,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dimensions, Image, ScrollView } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { HomeDrawerParamsList } from '../../../../../types';

export const ListIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <G transform="translate(-126 -558)">
      <Circle cx={12} cy={12} r={12} transform="translate(126 558)" fill="#a27af1" />
      <G transform="translate(-2051 -5268)">
        <Path
          d="M5.885 11.743L4.5 10.358l4.569-4.489L4.5 1.384 5.885 0l5.954 5.869z"
          transform="translate(2180.522 5832)"
          fill="#fff"
        />
        <Path transform="translate(2177 5826)" fill="none" d="M0 0H24V24H0z" />
      </G>
    </G>
  </Svg>
);

type Props = {
  onActivateOnlinePayments: () => void;
  commissionInfo: OnlinePaymentsCommissionInfo;
};

const ActivateOnlinePaymentsDesktop: React.FC<Props> = ({ onActivateOnlinePayments, commissionInfo }) => {
  const { t } = useTranslation('onlinePayments');
  const screenWidth = Dimensions.get('screen').width;
  const navigation = useNavigation<NavigationProp<HomeDrawerParamsList>>();

  const [, { openChat }] = IntercomContext.useContext();
  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <Column style={{ flex: 1 }}>
      <Image
        style={{
          width: screenWidth * 0.75,
          height: screenWidth * 0.75 * 0.38,
          position: 'absolute',
          right: 0,
        }}
        source={require('../../../../../assets/images/payments-background_web.png')}
      />
      <MeroHeader
        onBack={() =>
          navigation.navigate('HomeTabs', {
            screen: 'MenuTab',
            params: {
              screen: 'MenuScreen',
            },
          })
        }
        canGoBack={true}
      />
      <ScrollView contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false} bounces={false}>
        <Column style={{ flex: 1, paddingTop: 120, paddingLeft: 80 }}>
          <Row alignItems="flex-start">
            <Column style={[{ flex: 2 }, screenWidth <= 1150 && { maxWidth: 350 }]}>
              <Spacer size={30} />
              <H1>{t('activateHeader')}</H1>
              <Spacer size={24} />
              <Row>
                <ListIcon width={24} height={24} />
                <Body style={{ paddingLeft: 12, flex: 1 }}>
                  <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments1">
                    <Body style={[meroStyles.text.semibold]}>0</Body>1
                  </Trans>
                </Body>
              </Row>
              <Spacer size={12} />
              <Row>
                <ListIcon width={24} height={24} />
                <Body style={{ paddingLeft: 12, flex: 1 }}>
                  <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments2">
                    <Body style={[, meroStyles.text.semibold]}>0</Body>1
                  </Trans>
                </Body>
              </Row>
              <Spacer size={12} />
              <Row>
                <ListIcon width={24} height={24} />
                <Body style={{ paddingLeft: 12, flex: 1 }}>
                  <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments3">
                    <Body style={[meroStyles.text.semibold]}>0</Body>1
                  </Trans>
                </Body>
              </Row>

              <Spacer size={12} />
              <Row>
                <ListIcon width={24} height={24} />
                <Body style={{ paddingLeft: 12, flex: 1 }}>
                  {t('activatePayments4')}
                  <Body style={[meroStyles.text.semibold]}>{commissionInfo.feeText}</Body>
                </Body>
              </Row>

              <Spacer size={48} />
              <Column style={{ maxWidth: 295 }}>
                <Button text={t('activateButton')} onClick={onActivateOnlinePayments} />
              </Column>
              <Spacer size={24} />
              <Body>
                <Trans ns={'checkout'} t={t} i18nKey="enableCheckoutFooter">
                  0
                  <Body style={[{ color: colors.DARK_BLUE }, meroStyles.text.semibold]} onPress={onContactUsPressed}>
                    1
                  </Body>
                </Trans>
              </Body>
            </Column>
            <HSpacer left={80} />
            <Column style={{ flex: 2 }}>
              <Image
                style={{ width: 440, height: 451 }}
                source={require('../../../../../assets/images/payments_web.png')}
              />
            </Column>
          </Row>
        </Column>
      </ScrollView>
    </Column>
  );
};

export default ActivateOnlinePaymentsDesktop;

import { MonthlyPerformanceReport } from '@mero/api-sdk';
import { PageDetails } from '@mero/api-sdk/dist/pages';
import { MonthlyCountChangeReport } from '@mero/api-sdk/src/analytics/monthlyCountChangeReport';
import {
  colors,
  H1,
  Header,
  Icon,
  SmallBody,
  useShowError,
  styles as meroStyles,
  H2s,
  Label,
  Spacer,
  Body,
  Column,
  Row,
  HSpacer,
  FormCard,
  Line,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import { FlashyLabel } from '../../../components/FlashyLabel';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import H3s from '@mero/components/lib/components/Text/H3s';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../../types';
import log, { logCatch } from '../../../utils/log';

type Props = AuthorizedProps & CurrentBusinessProps & StackScreenProps<AuthorizedStackParamList, 'MonthlyReports'>;

const MonthlyReportsScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation('reports');
  const goBack = useGoBack();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();

  const { shortUrl } = route.params;

  const [reportData, setReportData] = React.useState<MonthlyPerformanceReport | null>(null);
  const [interval, setInterval] = React.useState<{ start: DateTime; end: DateTime } | null>(null);
  const [pageDetails, setPageDetails] = React.useState<PageDetails | null>(null);

  const loadMonthlyReport = React.useCallback(async (code: string) => {
    try {
      const details = await meroApi.notifications
        .getMonthlyReportInfoByShortUrlCode({ code })
        .catch(logCatch('getMonthlyReportInfoByShortUrlCode'));
      const [reportData, page] = await Promise.all([
        meroApi.analytics
          .getMonthlyPerformanceReport({
            pageId: details.pageId,
            ...details.reportInterval,
          })
          .catch(logCatch('getMonthlyPerformanceReport')),
        meroApi.pages.getPageDetails(details.pageId).catch(logCatch('getPageDetails')),
      ]);

      const date = DateTime.fromFormat(`${details.reportInterval.month}/${details.reportInterval.year}`, 'M/yyyy', {
        locale: 'ro',
      });

      setReportData(reportData);
      setInterval({
        start: date.startOf('month').setLocale('ro'),
        end: date.endOf('month').setLocale('ro'),
      });
      setPageDetails(page);
    } catch (error) {
      log.error('Error loading monthly report', error);
      showError(error);
      // goBack();
    }
  }, []);

  React.useEffect(() => {
    loadMonthlyReport(shortUrl);
  }, [shortUrl]);

  const generateProgress = (change: MonthlyCountChangeReport['change']) => {
    if (change.type === 'None') {
      return (
        <FlashyLabel
          style={{ alignSelf: 'auto' }}
          type="custom"
          color="#A8AABE"
          backgroundColor="#E9ECEF"
          text={`▲ 0%`}
        />
      );
    }

    if (change.type === 'Increased') {
      return <FlashyLabel style={{ alignSelf: 'auto' }} type="success" text={`▲ ${change.increase}%`} />;
    }

    if (change.type === 'Decreased') {
      return <FlashyLabel style={{ alignSelf: 'auto' }} type="error" text={`▼ ${change.decrease}%`} />;
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('monthlyReport')}
      />
      {reportData && interval && pageDetails && (
        <ScrollView showsVerticalScrollIndicator={false}>
          <Column style={{ paddingHorizontal: 24 }}>
            <Spacer size={24} />
            <SmallBody>
              {interval.start.toFormat('dd MMMM')} - {interval.end.toFormat('dd MMMM')}
            </SmallBody>
            <Spacer size={6} />
            <H1>{t('monthlyReportTitle', { company: pageDetails.name })}</H1>
            <Spacer size={8} />
            <SmallBody>{t('monthlyReportSubtitle')}</SmallBody>
            <Spacer size={24} />
          </Column>
          <Column style={{ paddingHorizontal: 16 }}>
            {/*<FormCard paddings="none" style={{ padding: 16 }} rounded dropShaddow>*/}
            {/*  <Row justifyContent="space-between">*/}
            {/*    <Column>*/}
            {/*      <Body style={meroStyles.text.semibold}>{t('appointments')}</Body>*/}
            {/*      <Spacer size={4} />*/}
            {/*      <Row alignItems="center">*/}
            {/*        <H2s style={{ fontSize: 24 }}>{reportData.bookingsReport.total.count}</H2s>*/}
            {/*        <HSpacer left={12} />*/}
            {/*        {generateProgress(reportData.bookingsReport.total.change)}*/}
            {/*      </Row>*/}
            {/*    </Column>*/}
            {/*    <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>*/}
            {/*      1 - {interval.end.toFormat('dd MMM yyyy')}*/}
            {/*    </Label>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Line />*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row>*/}
            {/*    <Column flex={1}>*/}
            {/*      <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('finalAppointments')}</Label>*/}
            {/*      <Spacer size={4} />*/}
            {/*      <Row alignItems="center">*/}
            {/*        <H3s>{reportData.bookingsReport.finished.count}</H3s>*/}
            {/*        <HSpacer left={12} />*/}
            {/*        {generateProgress(reportData.bookingsReport.finished.change)}*/}
            {/*      </Row>*/}
            {/*    </Column>*/}
            {/*    <Column flex={1}>*/}
            {/*      <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('canceledAppointments')}</Label>*/}
            {/*      <Spacer size={4} />*/}
            {/*      <Row alignItems="center">*/}
            {/*        <H3s>{reportData.bookingsReport.cancelled.count}</H3s>*/}
            {/*        <HSpacer left={12} />*/}
            {/*        {generateProgress(reportData.bookingsReport.cancelled.change)}*/}
            {/*      </Row>*/}
            {/*    </Column>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row>*/}
            {/*    <Column flex={1}>*/}
            {/*      <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>*/}
            {/*        {t('unconfirmedAppointments')}*/}
            {/*      </Label>*/}
            {/*      <Spacer size={4} />*/}
            {/*      <Row alignItems="center">*/}
            {/*        <H3s>{reportData.bookingsReport.pending.count}</H3s>*/}
            {/*        <HSpacer left={12} />*/}
            {/*        {generateProgress(reportData.bookingsReport.pending.change)}*/}
            {/*      </Row>*/}
            {/*    </Column>*/}
            {/*    <Column flex={1}>*/}
            {/*      <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>{t('missingAppointments')}</Label>*/}
            {/*      <Spacer size={4} />*/}
            {/*      <Row alignItems="center">*/}
            {/*        <H3s>{reportData.bookingsReport.noShow.count}</H3s>*/}
            {/*        <HSpacer left={12} />*/}
            {/*        {generateProgress(reportData.bookingsReport.noShow.change)}*/}
            {/*      </Row>*/}
            {/*    </Column>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={8} />*/}
            {/*</FormCard>*/}
            {/*<Spacer size={16} />*/}
            <FormCard paddings="none" style={{ padding: 16 }} rounded dropShaddow>
              <Row justifyContent="space-between">
                <Column>
                  <Body style={meroStyles.text.semibold}>{t('newClients')}</Body>
                  {/*<Spacer size={4} />*/}
                  {/*<Row alignItems="center">*/}
                  {/*  <H2s style={{ fontSize: 24 }}>{reportData.clientsReport.total.count}</H2s>*/}
                  {/*  <HSpacer left={12} />*/}
                  {/*  {generateProgress(reportData.clientsReport.total.change)}*/}
                  {/*</Row>*/}
                </Column>
                <Label style={[{ color: colors.COMET }, meroStyles.text.semibold]}>
                  1 - {interval.end.toFormat('dd MMM yyyy')}
                </Label>
              </Row>
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
              <Row alignItems="center">
                <SmallBody style={[{ width: '50%' }, meroStyles.text.semibold]}>{t('clientsOnlineMonthly')}</SmallBody>
                <SmallBody style={[{ width: '15%' }, meroStyles.text.alignRight]}>
                  {reportData.clientsReport.newClients.count}
                </SmallBody>
                <SmallBody style={[{ width: '35%' }, meroStyles.text.alignRight]}>
                  {reportData.clientsReport.newClients.income} lei
                </SmallBody>
              </Row>
              <Spacer size={16} />
              <Row alignItems="center">
                <SmallBody style={[{ width: '50%' }, meroStyles.text.semibold]}>{t('clientsOfflineMonthly')}</SmallBody>
                <SmallBody style={[{ width: '15%' }, meroStyles.text.alignRight]}>
                  {reportData.clientsReport.newClientsFromPro.count}
                </SmallBody>
                <SmallBody style={[{ width: '35%' }, meroStyles.text.alignRight]}>
                  {reportData.clientsReport.newClientsFromPro.income} lei
                </SmallBody>
              </Row>
              {/*<Spacer size={16} />*/}
              {/*<Row alignItems="center">*/}
              {/*  <SmallBody style={[{ width: '50%' }, meroStyles.text.semibold]}>*/}
              {/*    {t('returnedClientsMonthly')}*/}
              {/*  </SmallBody>*/}
              {/*  <SmallBody style={[{ width: '15%' }, meroStyles.text.alignRight]}>*/}
              {/*    {reportData.clientsReport.existingClients.count}*/}
              {/*  </SmallBody>*/}
              {/*  <SmallBody style={[{ width: '35%' }, meroStyles.text.alignRight]}>*/}
              {/*    {reportData.clientsReport.existingClients.income} lei*/}
              {/*  </SmallBody>*/}
              {/*</Row>*/}
              <Spacer size={8} />
            </FormCard>
            <Spacer size={32} />
            {/*<Body style={meroStyles.text.alignCenter}>{t('monthlyReportDetails')}</Body>*/}
            {/*<Spacer size={24} />*/}
            <Column style={{ alignSelf: 'center' }}>
              <Button
                expand={false}
                onPress={() => {
                  if (isPhone) {
                    navigation.navigate('Menu', {
                      screen: 'PageReportsMobileScreen',
                      params: { interval: interval.start.toFormat('MM/yyyy') },
                    });
                  } else {
                    navigation.navigate('Home', {
                      screen: 'HomeTabs',
                      params: {
                        screen: 'MenuTab',
                        params: {
                          screen: 'PageReportsScreen',
                          params: { interval: interval.start.toFormat('MM/yyyy') },
                        },
                      },
                    });
                  }
                }}
                text={t('detailedReportsButton')}
              />
            </Column>
          </Column>
          <Spacer size={96} />
        </ScrollView>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(MonthlyReportsScreen, CurrentBusiness, Authorized);

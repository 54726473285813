import { ApiError, Lastname } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ServiceId } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import {
  Avatar,
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  H3s,
  Header,
  Icon,
  normalize,
  Row,
  SearchTextInput,
  SimpleListItem,
  Spacer,
  styles as meroStyles,
  Text,
  Title,
  useToast,
} from '@mero/components';
import { Firstname, StrictPhoneNumber } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import { debounce } from 'throttle-debounce';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { tokenize, match } from '../../../../../components/SelectServiceScreen';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { getPrice } from '../../../ServicesScreen/ServicesMobileScreen';
import { styles } from './ProScheduleScreen.styles';

type SavedServiceSearchText = GroupWithServices['services'][number] & { searchText: string };

type IndexedGroup = Pick<GroupWithServices, 'group'> & { services: SavedServiceSearchText[] };

type ServiceSearchIndex = {
  /**
   * Find services matching query string
   */
  readonly search: (q: string) => GroupWithServices[];
};

export const buildServiceSearchIndex = (groups: GroupWithServices[]): ServiceSearchIndex => {
  const index: IndexedGroup[] = groups.map(({ group, services }) => ({
    group,
    services: services.map(
      (service): SavedServiceSearchText => ({
        ...service,
        searchText: normalize(`${service.name} ${service.description}`),
      }),
    ),
  }));

  return {
    search: (q) => {
      const tokens = tokenize(q);

      if (tokens.length === 0) {
        return groups;
      }

      return index.reduce((acc: IndexedGroup[], { group, services: allServices }: IndexedGroup) => {
        const services = allServices.filter((service) => match(tokens, service.searchText));
        if (services.length > 0) {
          return acc.concat([{ group, services }]);
        } else {
          return acc;
        }
      }, []);
    },
  };
};

export const formatDuration = (duration: number) => {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours} h ${minutes} min`;
  }

  return `${duration} min`;
};

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewServices'>;

const AddProServicesScreen: React.FC<Props> = ({ navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [{ details, services, calendar, businessHours, role }] = AddNewProContext.useContext();

  const block = React.useRef(false);

  const [groupedServices, setGroupedServices] = React.useState<GroupWithServices[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [foundServices, setFoundServices] = React.useState(groupedServices);

  const [query, setQuery] = React.useState<string>('');
  const queryIsEmpty = query.trim() === '';

  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const getGroupedServices = async (pageId: PageId, workerServices: ServiceId[]) => {
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(pageId);

      const grouped = groupedServices.grouped
        .map((group) => ({
          ...group,
          services: group.services.filter((service) => workerServices.includes(service._id)),
        }))
        .filter((group) => group.services.length > 0);
      const others = groupedServices.others.filter((service) => workerServices.includes(service._id));

      const groupedWithOthers = [
        ...grouped,
        ...(others.length === 0
          ? []
          : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: others }]),
      ];

      setGroupedServices(groupedWithOthers);
    } catch (error) {
      log.error('Could not get grouped services', error);
    }
  };

  const index = React.useMemo(() => buildServiceSearchIndex(groupedServices), [groupedServices]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      getGroupedServices(
        pageState.page.details._id,
        services.map((service) => service._id),
      );
    }
  }, [pageState.type, isFocused]);

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery, index]);

  React.useEffect(() => {
    if (services.length === 0) {
      navigation.navigate('ProAddNewServicesSelect', { noServices: true });
    }
  }, [services]);

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    setIsLoading(true);
    try {
      const newUserId = await meroApi.pages.addPageMember({
        pageId: pageState.page.details._id,
        firstname: details.firstname as Firstname,
        lastname: details.lastname as Lastname,
        phone: details.phone as StrictPhoneNumber,
        roles: role ? [role.id] : [],
      });

      if (calendar.isActive) {
        const workerId = await meroApi.pages.createPageMemberWorker({
          pageId: pageState.page.details._id,
          userId: newUserId,
          services,
        });

        const proDetails = await meroApi.pages.getPageWorker({ pageId: pageState.page.details._id, workerId });

        await Promise.all([
          meroApi.calendar.updateSettings({
            pageId: pageState.page.details._id,
            calendarId: proDetails.calendar._id,
            newSettings: {
              ...proDetails.calendar.settings,
              isPrivate: calendar.isPrivate,
              calendarInterval: {
                ...proDetails.calendar.settings.calendarInterval,
                value: calendar.calendarInterval,
              },
              maxAppointmentsPerClient: calendar.maxAppointmentsPerClient,
              minHoursBeforeClientsCanCancelAppointments: calendar.minHoursBeforeClientsCanCancelAppointments,
              minHoursBeforeTodayAppointments: calendar.minHoursBeforeTodayAppointments,
              maxWaitingListDaysPerClient: calendar.maxWaitingListDaysPerClient,
            },
          }),
          meroApi.pages.updateWorkerBusinessHours({
            pageId: pageState.page.details._id,
            workerId: proDetails._id,
            businessHours,
          }),
        ]);
      }

      await reloadAsync();

      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });

      navigation.popToTop();
      goBack();
    } catch (error) {
      log.error('Error creating worker', error);
      if (error instanceof ApiError) {
        toast.show({
          type: 'error',
          text: error.message,
        });
      } else {
        toast.show({
          type: 'error',
          text: t('errorDetails'),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const servicesCounter = React.useMemo(() => {
    return foundServices.reduce((acc, group) => acc + group.services.length, 0);
  }, [foundServices]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proServices')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar size={32} source={details.profilePhoto} firstname={details.firstname} lastname={details.lastname} />
          </Column>
        )}
      />
      <ScrollView>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('proServices')}</H1>
          <Body style={{ padding: 8 }}>{t('proServicesNewDescription')}</Body>
          <Spacer size={16} />
          <SearchTextInput
            placeholder={'Caută după numele serviciului'}
            value={query}
            // autoFocus={autoFocus}
            onChange={setQuery}
          />
          <Spacer size={16} />
          <Row style={{ paddingHorizontal: 8, justifyContent: 'space-between' }}>
            <Title style={{ color: '#32325D' }}>{t('services', { count: servicesCounter })}</Title>
            <TouchableOpacity onPress={() => navigation.navigate('ProAddNewServicesSelect')}>
              <Title style={[meroStyles.text.link]}>{t('selectServices')}</Title>
            </TouchableOpacity>
          </Row>
          <Spacer size={16} />
          {foundServices.length === 0 ? (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 32 }}>
              <H2s>{t('noServicesTitle')}</H2s>
              <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                {t('noServicesDescription')}
              </Body>
            </Column>
          ) : (
            <FormCard rounded paddings="none">
              {foundServices.map((groupedService, groupIndex) => (
                <Column
                  key={groupedService.group._id}
                  style={{
                    paddingHorizontal: 16,
                    paddingTop: 24,
                    paddingBottom: 16,
                    borderBottomWidth: groupIndex < groupedServices.length - 1 ? 1 : 0,
                    borderBottomColor: '#E9ECEF',
                  }}
                >
                  <H3s>{groupedService.group.name}</H3s>
                  <Spacer size={8} />
                  {groupedService.services.map((service, index) => (
                    <TouchableOpacity
                      key={service._id}
                      style={{
                        borderBottomWidth: index < groupedService.services.length - 1 ? 1 : 0,
                        borderBottomColor: '#E9ECEF',
                        paddingVertical: 16,
                      }}
                      onPress={() => navigation.navigate('ProAddNewEditService', { serviceId: service._id })}
                    >
                      <SimpleListItem
                        paddingTop={0}
                        paddingBottom={0}
                        title={service.name}
                        subtitle={
                          <Text smallBody>
                            {formatDuration(service.durationInMinutes)},{' '}
                            {service.price.type === 'fixed' && service.price?.promo ? (
                              <>
                                <Text smallBody>
                                  {service.price.promo} lei{' '}
                                  <Text
                                    smallBody
                                    style={{
                                      textDecorationLine: 'line-through',
                                      textDecorationStyle: 'solid',
                                    }}
                                  >
                                    ({service.price.fixed} lei)
                                  </Text>
                                </Text>
                              </>
                            ) : (
                              <Text smallBody>{getPrice(service.price)}</Text>
                            )}
                          </Text>
                        }
                        IconComponent={() => <Icon type="arrow-right" />}
                        iconPosition="right"
                      />
                    </TouchableOpacity>
                  ))}
                </Column>
              ))}
              <Spacer size={16} />
            </FormCard>
          )}
        </Column>
        <Spacer size="16" />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProServicesScreen;

import { Body, colors, ConfirmBox, H1, ModalOverlay, SimpleListItem, Spacer } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const DisableSmsDialog: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('notifications');

  const [pageState] = CurrentBusinessContext.useContext();

  const [changeInProgress, setChangeInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
  });

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setChangeInProgress(true);
        // await meroApi.pages.deletePageService({
        //   pageId: pageState.page.details._id,
        //   serviceId: serviceId,
        // });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setChangeInProgress(false);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: t('disableSms'),
    onPress: Object.values(checks).some((v) => !v) || changeInProgress ? undefined : confirm,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        headerTitle={t('importantActionTitle')}
        icon="info"
        canClose={!changeInProgress}
        onClose={cancel}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>{t('disableSmsDialogTitle')}</H1>
        <Spacer size="8" />
        <Body>{t('disableSmsDialogDescription')}</Body>
        <Spacer size="16" />
        <TouchableOpacity onPress={toggleCheck('check1')} disabled={changeInProgress}>
          <SimpleListItem icon={checks.check1 ? 'checked' : 'unchecked'} paddingTop={0} paddingBottom={0}>
            <Body style={{ color: colors.NILE_BLUE }}>
              <Trans ns={'notifications'} t={t} i18nKey="disableSmsDialogStep1">
                0<Body style={{ color: colors.NILE_BLUE, fontFamily: 'open-sans-semibold' }}>1</Body>2
              </Trans>
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default DisableSmsDialog;

import * as MeroApi from '@mero/api-sdk';
import { Body, Icon, SmallBody } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import * as React from 'react';
import { Image, View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.GenericProNotification;
};

const GenericProNotification = ({ notification }: Props): React.ReactElement | null => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      {payload.iconUrl ? (
        <Image source={{ uri: payload.iconUrl }} style={{ width: 32, height: 32 }} />
      ) : (
        <Icon type="notification_client_accepted" />
      )}
      <View style={styles.notificationBody}>
        <Body>{payload.text}</Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
    </View>
  );
};

export default GenericProNotification;

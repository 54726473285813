import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  form: { padding: 12 },
  cardItem: { flex: 3 },
  itemTitle: { color: colors.COMET, fontSize: 10, fontFamily: 'open-sans-semibold' },
});

export default styles;

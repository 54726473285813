import { MeroHeader } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import SearchClientsForm, { SearchClientsFormProps } from './SearchClientsForm';
import { styles } from './styles';

export type SelectClientScreenProps = SearchClientsFormProps & {
  /**
   * Page back button pressed
   */
  readonly onBackPressed?: () => void;
};

const SelectClientScreenView: React.FC<SelectClientScreenProps> = ({
  query,
  autoFocus,
  isLoading,
  isReloading,
  onQueryChange,
  clients,
  onBackPressed,
  onClientSelected,
  onAddClient,
  onImportContacts,
  onReload,
  onLoadMore,
}: SelectClientScreenProps) => {
  const { t } = useTranslation('clients');
  const { isPhone } = useMediaQueries();

  return (
    <SafeAreaView edges={['left', 'top', 'right']} style={[styles.container, !isPhone && styles.modalBorder]}>
      <MeroHeader title={t('selectClient')} canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <SearchClientsForm
        query={query}
        onQueryChange={onQueryChange}
        clients={clients}
        onClientSelected={onClientSelected}
        onAddClient={onAddClient}
        onImportContacts={Platform.select({
          ios: onImportContacts,
          android: onImportContacts,
        })}
        onLoadMore={onLoadMore}
        title={t('selectClient')}
        bottomSafeArea
        isLoading={isLoading}
        isReloading={isReloading}
        autoFocus={autoFocus}
        onReload={onReload}
      />
    </SafeAreaView>
  );
};

export default SelectClientScreenView;

import { ClientHistoryRecordId, ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientHistoryNoteDeleteState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Deleting';
      readonly clientId: ClientId;
      readonly noteId: ClientHistoryRecordId;
    }
  | {
      readonly type: 'Deleted';
      readonly clientId: ClientId;
      readonly noteId: ClientHistoryRecordId;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientHistoryNoteDeleteState => ({
  type: 'Ready',
});

export const ClientHistoryNoteDeleteContext = createModelContext(
  defaultState(),
  {
    trySetDeleted: (_, params: { clientId: ClientId; noteId: ClientHistoryRecordId }) => ({
      type: 'Deleted',
      clientId: params.clientId,
      noteId: params.noteId,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Deleted' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientHistoryNoteDeleteState) => ClientHistoryNoteDeleteState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      deleteClientNote: (params: { clientId: ClientId; noteId: ClientHistoryRecordId }) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const deleteNote = async () => {
              try {
                await meroApi.clients.deleteClientHistoryNote({
                  clientId: params.clientId,
                  noteId: params.noteId,
                });

                dispatch.trySetDeleted({
                  clientId: params.clientId,
                  noteId: params.noteId,
                });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            deleteNote().catch(log.exception);

            return {
              type: 'Deleting',
              clientId: params.clientId,
              noteId: params.noteId,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientHistoryNoteDeleteContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientHistoryNoteDeleteContext.Provider>{children}</ClientHistoryNoteDeleteContext.Provider>;
};

export const withClientHistoryNoteDeleteContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientHistoryNoteDeleteContextProvider(props: P) {
    return (
      <ClientHistoryNoteDeleteContextProvider>
        <Content {...props} />
      </ClientHistoryNoteDeleteContextProvider>
    );
  };
};

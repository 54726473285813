import { MembershipTemplateId } from '@mero/api-sdk';
import { MembershipItemDetails } from '@mero/api-sdk/dist/memberships/membershipItemDetails';
import { MembershipTemplateValidity } from '@mero/api-sdk/dist/memberships/membershipTemplateValidity';
import { PageId } from '@mero/api-sdk/dist/pages';
import { SavedService } from '@mero/api-sdk/dist/services';
import { createModelContext } from '@mero/components';
import { AppliedDiscount, MeroUnits, OptionalDefinedString, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';
import { withUiKey, WithUiKey } from '../CheckoutFormContext';

export type MinService = Pick<SavedService, '_id' | 'name' | 'durationInMinutes' | 'price'>;
export type Item = MembershipItemDetails<MeroUnits.Any>;
type MembershipTemplateContextState = {
  status: 'Active' | 'Inactive' | 'Deleted';
  name?: DefinedString;
  description?: OptionalDefinedString;
  items: WithUiKey<Item>[];
  editItems: WithUiKey<Item>[];
  termsAndConditions?: string;
  validFor?: MembershipTemplateValidity;
  discount?: AppliedDiscount<ScaledNumber, MeroUnits.Any>;
  editDiscount?: AppliedDiscount<ScaledNumber, MeroUnits.Any>;
  membershipTemplateId?: MembershipTemplateId;
};

const defaultState = (): MembershipTemplateContextState => ({
  status: 'Active',
  items: [],
  editItems: [],
});

export const MembershipTemplateContext = createModelContext(
  defaultState(),
  {
    update: (state, newState: MembershipTemplateContextState) => {
      return {
        ...state,
        ...newState,
      };
    },
    sync: (state) => {
      return {
        ...state,
        items: state.editItems,
        discount: state.editDiscount,
      };
    },
    resetEdit: (state) => {
      return {
        ...state,
        editItems: state.items,
        editDiscount: state.discount,
      };
    },
    updateDetails: (state, details: Pick<MembershipTemplateContextState, 'name' | 'description' | 'validFor'>) => {
      return {
        ...state,
        ...details,
      };
    },
    setItem: (state, item: Item) => {
      return {
        ...state,
        ...(!state.membershipTemplateId ? { items: [...state.items, withUiKey(item)] } : {}),
        editItems: [...state.editItems, withUiKey(item)],
      };
    },
    updateItem: (state, item: WithUiKey<Item>) => {
      return {
        ...state,
        ...(!state.membershipTemplateId
          ? { items: state.items.map((i) => (i.uiKey === item.uiKey ? { ...i, ...item } : i)) }
          : {}),
        editItems: state.editItems.map((i) => (i.uiKey === item.uiKey ? { ...i, ...item } : i)),
      };
    },
    removeItem: (state, item: WithUiKey<Item>) => {
      return {
        ...state,
        ...(!state.membershipTemplateId ? { items: state.items.filter((i) => i.uiKey !== item.uiKey) } : {}),
        editItems: state.editItems.filter((i) => i.uiKey !== item.uiKey),
      };
    },
    updateDiscount: (state, discount: AppliedDiscount<ScaledNumber, MeroUnits.Any>) => {
      return {
        ...state,
        ...(!state.membershipTemplateId ? { discount } : {}),
        editDiscount: discount,
      };
    },
    setStatus: (state, status: 'Active' | 'Inactive') => {
      return {
        ...state,
        status,
      };
    },
    reset() {
      return defaultState();
    },
    run: (s, f: (s: MembershipTemplateContextState) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    return {
      load: (pageId: PageId, membershipId: MembershipTemplateId): Promise<void> =>
        new Promise((resolve, reject) => {
          dispatch.run(async () => {
            try {
              const memberships = await meroApi.memberships.getMembershipTemplateById({
                pageId,
                membershipTemplateId: membershipId,
              });

              dispatch.update({
                ...memberships,
                items: memberships.items.map(withUiKey),
                editItems: memberships.items.map(withUiKey),
                membershipTemplateId: membershipId,
              });
              resolve();
            } catch (e) {
              log.error('failed to load template', JSON.stringify(e));
              reject(e);
            }
          });
        }),
      setItem: dispatch.setItem,
      updateItem: dispatch.updateItem,
      removeItem: dispatch.removeItem,
      updateDiscount: dispatch.updateDiscount,
      updateDetails: dispatch.updateDetails,
      setStatus: dispatch.setStatus,
      sync: dispatch.sync,
      resetEdit: dispatch.resetEdit,
      reset: dispatch.reset,
    };
  },
);

export const MembershipTemplateContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <MembershipTemplateContext.Provider>{children}</MembershipTemplateContext.Provider>;
};

export const withMembershipTemplateContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithMembershipTemplateContextProvider(props: P) {
    return (
      <MembershipTemplateContextProvider>
        <Content {...props} />
      </MembershipTemplateContextProvider>
    );
  };
};

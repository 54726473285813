import { ServiceGroup } from '@mero/api-sdk/dist/services';
import { Body, colors, ConfirmBox, H1, Line, ModalOverlay, SimpleListItem, Spacer } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  category: ServiceGroup;
};

const DeleteServiceDialog: React.FC<Props> = ({ onSuccess, onCancel, category }) => {
  const { isDesktop } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
  });

  const cancelDeleteCategory = () => {
    onCancel();
  };

  const deleteCategory = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.pages.deleteServiceGroup({
          pageId: pageState.page.details._id,
          groupId: category._id,
        });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: 'Anulează',
    onPress: cancelDeleteCategory,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: 'Șterge categorie',
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : deleteCategory,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        headerTitle="Actiune ireversibilă"
        icon="info"
        canClose={!deleteInProgress}
        onClose={cancelDeleteCategory}
        leftAction={addCategoryLeftAction}
        rightAction={addCategoryRightAction}
        style={[isDesktop && { maxWidth: 468 }]}
      >
        <H1>Confirmă ștergere categorie</H1>
        <Spacer size="8" />
        <Body>Bifează și confirmă ștergerea</Body>
        <Spacer size="16" />
        <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
          <SimpleListItem
            icon={checks.check1 ? 'checked' : 'unchecked'}
            iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
            paddingTop={0}
            paddingBottom={0}
          >
            <Body>
              Toate detaliile categoriei vor fi șterse, programările existente{' '}
              <Body style={{ fontFamily: 'open-sans-semibold' }}>nu vor fi afectate</Body>
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
        <Spacer size={16} />
        <Line />
        <Spacer size={16} />
        <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
          <SimpleListItem
            icon={checks.check2 ? 'checked' : 'unchecked'}
            iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
            paddingTop={0}
            paddingBottom={0}
          >
            <Body>
              Serviciile adăugate în această categorie vor fi mutate în{' '}
              <Body style={{ fontFamily: 'open-sans-semibold' }}>Alte servicii</Body>
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default DeleteServiceDialog;

import { SavedService, ServiceGroupId, ServiceId } from '@mero/api-sdk/dist/services';
import {
  Body,
  Column,
  FormCard,
  H1,
  HSpacer,
  Icon,
  Line,
  Row,
  SearchTextInput,
  SmallBody,
  Title,
  colors,
  styles as meroStyles,
  sizes,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { debounce } from 'throttle-debounce';

import { buildServiceSearchIndex } from '../MenuScreen/screens/ProsDashboardScreen/AddProServicesScreen';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import { SubscriptionContext, withSubscriptionContextProvider } from '../../../contexts/SubscriptionContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  RootStackParamList,
  ServicesDashboardStackParamList,
} from '../../../types';
import log from '../../../utils/log';
import AddCategoryDialog from './AddCategoryDialog';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import DeleteServicesDialog from './DeleteServicesDialog';
import EditCategoryDialog from './EditCategoryDialog';
import ServicesTable from './ServicesTable';
import UpdateServicesCategoryDialog from './UpdateServicesCategoryDialog';
import UpdateServicesGroupDialog from './UpdateServicesGroupDialog';
import { styles } from './styles';

type Props = {
  readonly navigation: CompositeNavigationProp<
    StackNavigationProp<ServicesDashboardStackParamList, 'ServicesWeb'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ServicesTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

export const ALL_GROUP_ID = '0' as ServiceGroupId;
export const OTHER_GROUP_ID = '1' as ServiceGroupId;
export const isCustomCategory = (id: ServiceGroupId | undefined) => id !== ALL_GROUP_ID && id !== OTHER_GROUP_ID;

const ServicesWebScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('services');
  const { isDesktop } = useMediaQueries();
  const isFocused = useIsFocused();
  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();

  const [selectedServices, setSelectedServices] = React.useState<ServiceId[]>([]);

  const [showAddCategory, setShowAddCategory] = React.useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = React.useState(false);
  const [showEditCategory, setShowEditCategory] = React.useState(false);
  const [showCategoryDialog, setShowCategoryDialog] = React.useState(false);
  const [showGroupDialog, setShowGroupDialog] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [hoveredCategory, setHoveredCategory] = React.useState<ServiceGroupId | undefined>();

  const DEFAULT_CATEGORY = {
    _id: ALL_GROUP_ID,
    name: t('allServices'),
  };
  const [selectedCategory, setSelectedCategory] = React.useState<{
    _id: ServiceGroupId;
    name: string;
  }>(DEFAULT_CATEGORY);

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();
  const [{ services, serviceGroups }, { reload, getServiceGroups }] = ServicesContext.useContext();
  const [{ subscriptionInfo }] = SubscriptionContext.useContext();
  const shouldForceManualApproval = subscriptionInfo?.shouldForceManualApproval;

  const groupedWithOthers = React.useMemo(
    () => [
      ...(services.all.length === 0
        ? []
        : [
            {
              group: { name: t('allServices'), _id: ALL_GROUP_ID },
              services: services.all,
            },
          ]),
      ...services.grouped,
      ...(services.others.length === 0
        ? []
        : [{ group: { name: t('otherServices'), _id: OTHER_GROUP_ID }, services: services.others }]),
    ],
    [services],
  );

  const [foundServices, setFoundServices] = React.useState(groupedWithOthers);
  const [servicesCount, setServicesCount] = React.useState(0);
  const [query, setQuery] = React.useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [showAddMenu, setShowAddMenu] = React.useState(false);
  const [showCategoryMenu, setShowCategoryMenu] = React.useState(false);

  const optionsMenuRef = React.useRef<View>(null);
  const addMenuRef = React.useRef<View>(null);
  const categoryMenuRef = React.useRef<View>(null);

  useClickOutsideWeb({
    ref: optionsMenuRef,
    isVisible: showOptionsMenu,
    onClickOutside() {
      setShowOptionsMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  useClickOutsideWeb({
    ref: addMenuRef,
    isVisible: showAddMenu,
    onClickOutside() {
      setShowAddMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  useClickOutsideWeb({
    ref: categoryMenuRef,
    isVisible: showCategoryMenu,
    onClickOutside() {
      setShowCategoryMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  const index = React.useMemo(() => buildServiceSearchIndex(groupedWithOthers), [groupedWithOthers]);

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery, index, selectedCategory]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      reload({ pageId: pageState.page.details._id });
    }
  }, [isFocused, pageState.type]);

  React.useEffect(() => {
    setServicesCount(services.all.length + services.others.length);
  }, [services.all]);

  const editService = React.useCallback(
    (serviceId: SavedService['_id']) => {
      navigation.navigate('Service', { serviceId: serviceId });
    },
    [navigation],
  );

  const navigateMenu = React.useCallback(() => {
    navigation.navigate('MenuTab', { screen: 'MenuScreen' });
  }, [navigation]);

  const addService = React.useCallback(() => {
    navigation.navigate('Service', {
      serviceId: undefined,
      selectedGroupId:
        selectedCategory._id === OTHER_GROUP_ID
          ? 'other'
          : selectedCategory._id === ALL_GROUP_ID
          ? undefined
          : selectedCategory._id,
    });
  }, [navigation, selectedCategory]);

  const addNewCategory = async (groupId: ServiceGroupId) => {
    if (pageState.type === 'Loaded') {
      await getServiceGroups(pageState.page.details._id);
      reload({ pageId: pageState.page.details._id });
    }
    setShowAddCategory(false);
  };

  const cancelDeleteCategory = () => {
    setShowDeleteCategory(false);
  };

  const goToSortingCallback = React.useCallback(() => {
    navigation.navigate('SortServices');
  }, []);

  const deleteCategory = () => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
    setSelectedCategory(DEFAULT_CATEGORY);
    setShowDeleteCategory(false);
  };

  const cancelEditCategory = () => {
    setShowEditCategory(false);
  };

  const editCategory = (newName: string) => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
    setSelectedCategory((prev) => ({ ...prev, name: newName }));
    setShowEditCategory(false);
  };

  const resetSelectedService = () => setSelectedServices([]);

  const selectServices = (serviceIds: ServiceId[]) => {
    const uniqueServiceIds = new Set([...selectedServices, ...serviceIds]);
    setSelectedServices([...uniqueServiceIds]);
  };

  const unselectService = (serviceId: ServiceId) => {
    setSelectedServices((prev) => prev.filter((s) => s !== serviceId));
  };

  const bulkUpdatePageServicesVisibility = async (isPrivate: boolean) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesVisibility({
        pageId: pageState.page.details._id,
        serviceIds: selectedServices,
        isPrivate: isPrivate,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  const bulkUpdatePageServicesAutomaticApproval = async (automaticApproval: boolean) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesAutomaticApproval({
        pageId: pageState.page.details._id,
        serviceIds: selectedServices,
        automaticApproval: automaticApproval,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <Row
          alignItems="center"
          justifyContent="space-between"
          style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 30 }]}
        >
          <Row>
            <TouchableOpacity onPress={navigateMenu}>
              <Icon type="back" disabled={true} size={32} color={colors.BLACK} />
            </TouchableOpacity>
            <HSpacer left={16} />
            <H1>{t('services')}</H1>
          </Row>

          <Row style={{ width: 340 }}>
            <SearchTextInput placeholder={t('searchServices')} value={query} onChange={setQuery} />
          </Row>
        </Row>

        <View style={{ padding: 24 }}>
          <Row alignItems="center" justifyContent="space-between">
            <Body style={meroStyles.text.semibold}>
              {selectedServices.length === 0
                ? t('servicesCount', { count: servicesCount })
                : t('selectedServices', { count: selectedServices.length })}
            </Body>

            <Row>
              {/* Options Menu */}
              {selectedServices.length > 0 && (
                <View style={{ flexDirection: 'column', display: 'flex' }} ref={optionsMenuRef}>
                  <TouchableOpacity
                    style={{ flexDirection: 'row' }}
                    onPress={() => {
                      setShowOptionsMenu((prev) => !prev);
                      setShowAddMenu(false);
                      setShowCategoryMenu(false);
                    }}
                  >
                    <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('selectionOptions')}</Body>
                    <View
                      style={{
                        paddingLeft: 8,
                        justifyContent: 'center',
                      }}
                    >
                      <Icon type="dropdown" color={colors.DARK_BLUE} />
                    </View>
                  </TouchableOpacity>
                  <Column style={{ position: 'relative' }}>
                    {showOptionsMenu && (
                      <Column style={styles.largeOptionsMenu}>
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            setShowOptionsMenu(false);
                            setShowGroupDialog(true);
                          }}
                        >
                          <Title>{t('updateGroupBulkOperation')}</Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            setShowOptionsMenu(false);
                            setShowCategoryDialog(true);
                          }}
                        >
                          <Title>{t('updateCategoryBulkOperation')}</Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            bulkUpdatePageServicesVisibility(false);
                          }}
                        >
                          <Title>{t('listProfileOperation')}</Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            bulkUpdatePageServicesVisibility(true);
                          }}
                        >
                          <Title>{t('unlistProfileOperation')}</Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          disabled={shouldForceManualApproval}
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            bulkUpdatePageServicesAutomaticApproval(false);
                          }}
                        >
                          <Title style={[shouldForceManualApproval && { color: colors.COMET }]}>
                            {t('disableAutomaticApprovalOperation')}
                          </Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          disabled={shouldForceManualApproval}
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            bulkUpdatePageServicesAutomaticApproval(true);
                          }}
                        >
                          <Title style={[shouldForceManualApproval && { color: colors.COMET }]}>
                            {t('enableAutomaticApprovalOperation')}
                          </Title>
                        </TouchableOpacity>
                        <Line />
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            setShowOptionsMenu(false);
                            setShowDeleteConfirmation(true);
                          }}
                        >
                          <Title style={{ color: colors.RADICAL_RED }}>{t('deleteOperation')}</Title>
                        </TouchableOpacity>
                      </Column>
                    )}
                  </Column>
                </View>
              )}

              {/* Sort Menu */}
              {selectedServices.length === 0 && (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      goToSortingCallback();
                      setShowOptionsMenu(false);
                      setShowCategoryMenu(false);
                    }}
                  >
                    <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('sort')}</Body>
                  </TouchableOpacity>
                  <HSpacer left={32} />
                </>
              )}

              {/* Add Menu */}
              {selectedServices.length === 0 && (
                <View style={{ flexDirection: 'column', display: 'flex' }} ref={addMenuRef}>
                  <TouchableOpacity
                    style={{ flexDirection: 'row' }}
                    onPress={() => {
                      setShowAddMenu((prev) => !prev);
                      setShowOptionsMenu(false);
                      setShowCategoryMenu(false);
                    }}
                  >
                    <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('add')}</Body>
                    <View
                      style={{
                        paddingLeft: 8,
                        justifyContent: 'center',
                      }}
                    >
                      <Icon type="dropdown" color={colors.DARK_BLUE} />
                    </View>
                  </TouchableOpacity>
                  <Column style={{ position: 'relative' }}>
                    {showAddMenu && (
                      <Column style={styles.optionsMenu}>
                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            addService();
                            setShowOptionsMenu(false);
                          }}
                        >
                          <Body style={meroStyles.text.semibold}>{t('addService')}</Body>
                        </TouchableOpacity>
                        <Line />

                        <TouchableOpacity
                          style={{ paddingVertical: sizes[16] }}
                          delayPressIn={0}
                          onPress={() => {
                            setShowOptionsMenu(false);
                            setShowAddCategory(true);
                          }}
                        >
                          <Body style={meroStyles.text.semibold}>{t('addCategory')}</Body>
                        </TouchableOpacity>
                      </Column>
                    )}
                  </Column>
                </View>
              )}
            </Row>
          </Row>
        </View>

        <FormCard
          dropShaddow
          paddings="none"
          style={{ flex: 1, marginHorizontal: 24, marginBottom: 24, zIndex: -1 }}
          rounded
        >
          <Row style={{ flex: 1 }}>
            <ScrollView style={{ marginVertical: 24, maxWidth: 313 }}>
              <FormCard paddings="none" rounded dropShaddow style={{ marginHorizontal: 24 }}>
                <Pressable
                  style={hoveredCategory === ALL_GROUP_ID && { backgroundColor: colors.ALABASTER }}
                  onHoverIn={() => setHoveredCategory(ALL_GROUP_ID)}
                  onHoverOut={() => setHoveredCategory(undefined)}
                  onPress={() => {
                    setSelectedServices([]);
                    setSelectedCategory({
                      _id: ALL_GROUP_ID,
                      name: t('allServices'),
                    });
                  }}
                >
                  <Row
                    style={[
                      selectedCategory._id === ALL_GROUP_ID && {
                        backgroundColor: colors.ATHENS_GRAY,
                      },
                      { padding: 16, justifyContent: 'space-between' },
                    ]}
                  >
                    <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                      {t('allServices')} (
                      {foundServices.find((g) => g.group._id === ALL_GROUP_ID)?.services.length ?? 0})
                    </SmallBody>
                    {selectedCategory._id === ALL_GROUP_ID && <Icon type="arrow-right" size={24}></Icon>}
                  </Row>
                </Pressable>
                {serviceGroups.map((group, index) => (
                  <View key={group._id}>
                    <Line />
                    <Pressable
                      style={hoveredCategory === group._id && { backgroundColor: colors.ALABASTER }}
                      onHoverIn={() => setHoveredCategory(group._id)}
                      onHoverOut={() => setHoveredCategory(undefined)}
                      onPress={() => {
                        setSelectedServices([]);
                        setSelectedCategory({
                          _id: group._id,
                          name: group.name,
                        });
                      }}
                    >
                      <Row
                        style={[
                          selectedCategory._id === group._id && {
                            backgroundColor: colors.ATHENS_GRAY,
                          },
                          { padding: 16, justifyContent: 'space-between' },
                        ]}
                      >
                        <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                          {group.name} ({foundServices.find((g) => g.group._id === group._id)?.services.length ?? 0})
                        </SmallBody>
                        {selectedCategory._id === group._id && <Icon type="arrow-right" size={24}></Icon>}
                      </Row>
                    </Pressable>
                  </View>
                ))}
                <Line />
                <Pressable
                  style={hoveredCategory === OTHER_GROUP_ID && { backgroundColor: colors.ALABASTER }}
                  onHoverIn={() => setHoveredCategory(OTHER_GROUP_ID)}
                  onHoverOut={() => setHoveredCategory(undefined)}
                  onPress={() => {
                    setSelectedServices([]);
                    setSelectedCategory({
                      _id: OTHER_GROUP_ID,
                      name: t('otherServices'),
                    });
                  }}
                >
                  <Row
                    style={[
                      selectedCategory._id === OTHER_GROUP_ID && {
                        backgroundColor: colors.ATHENS_GRAY,
                      },
                      { padding: 16, justifyContent: 'space-between' },
                    ]}
                  >
                    <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                      {t('otherServices')} (
                      {foundServices.find((g) => g.group._id === OTHER_GROUP_ID)?.services.length ?? 0})
                    </SmallBody>
                    {selectedCategory._id === OTHER_GROUP_ID && <Icon type="arrow-right" size={24}></Icon>}
                  </Row>
                </Pressable>
              </FormCard>
            </ScrollView>
            <Column style={{ flex: 1, paddingVertical: 24, paddingRight: 24 }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>{selectedCategory.name}</H1>

                {isCustomCategory(selectedCategory._id) && !query && (
                  <TouchableOpacity
                    onPress={() => {
                      setShowCategoryMenu((prev) => !prev);
                      setShowAddMenu(false);
                      setShowOptionsMenu(false);
                    }}
                  >
                    <Icon type="options-dots" />
                  </TouchableOpacity>
                )}
              </Row>
              <Column style={{ position: 'relative', zIndex: 1 }}>
                {showCategoryMenu && (
                  <View style={{ position: 'relative' }} ref={categoryMenuRef}>
                    <Column style={[styles.optionsMenu]}>
                      <TouchableOpacity
                        style={{ paddingVertical: sizes[16] }}
                        delayPressIn={0}
                        onPress={() => setShowEditCategory(true)}
                      >
                        <Body style={meroStyles.text.semibold}>{t('updateCategoryName')}</Body>
                      </TouchableOpacity>
                      <Line />

                      <TouchableOpacity
                        style={{ paddingVertical: sizes[16] }}
                        delayPressIn={0}
                        onPress={() => setShowDeleteCategory(true)}
                      >
                        <Body style={[meroStyles.text.semibold, { color: colors.RADICAL_RED }]}>
                          {t('deleteCategory')}
                        </Body>
                      </TouchableOpacity>
                    </Column>
                  </View>
                )}
              </Column>
              <ServicesTable
                serviceGroupId={selectedCategory._id}
                editService={editService}
                foundServices={foundServices}
                resetSelectedService={resetSelectedService}
                selectServices={selectServices}
                unselectService={unselectService}
                selectedServices={selectedServices}
              />
            </Column>
          </Row>
        </FormCard>
      </ModalScreenContainer>
      {showAddCategory ? (
        <AddCategoryDialog onSuccess={addNewCategory} onCancel={() => setShowAddCategory(false)} />
      ) : null}

      {showDeleteCategory && isCustomCategory(selectedCategory._id) ? (
        <DeleteCategoryDialog category={selectedCategory} onSuccess={deleteCategory} onCancel={cancelDeleteCategory} />
      ) : null}

      {showEditCategory && isCustomCategory(selectedCategory._id) ? (
        <EditCategoryDialog category={selectedCategory} onSuccess={editCategory} onCancel={cancelEditCategory} />
      ) : null}

      {showCategoryDialog && (
        <UpdateServicesCategoryDialog serviceIds={selectedServices} onClose={() => setShowCategoryDialog(false)} />
      )}

      {showGroupDialog && (
        <UpdateServicesGroupDialog serviceIds={selectedServices} onClose={() => setShowGroupDialog(false)} />
      )}

      {showDeleteConfirmation && (
        <DeleteServicesDialog
          onClose={() => {
            setSelectedServices([]);
            setShowDeleteConfirmation(false);
          }}
          serviceIds={selectedServices}
        />
      )}
    </>
  );
};

export default pipe(ServicesWebScreen, withSubscriptionContextProvider);

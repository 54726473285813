import { colors, Header, Icon } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import useGoBack from '../../../../../hooks/useGoBack';

import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import OnlineLink from './OnlineLinkComponent';

const OnlineLinkScreen = () => {
  const { t } = useTranslation('location');

  const [pageState] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('onlineLink')}
      />
      <OnlineLink />
    </ModalScreenContainer>
  );
};

export default OnlineLinkScreen;

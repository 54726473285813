import { Body, Spacer, SafeAreaView, NotificationEmptyScreenIcon, H2s } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import ActivatePushWidget from './ActivatePushWidget';
import { styles } from './styles';

type Props = {
  readonly onNavigatePermissionDenied: () => void;
};

const EmptyNotificationsScreenView: React.FC<Props> = ({ onNavigatePermissionDenied }: Props) => {
  const { t } = useTranslation('notifications');

  return (
    <SafeAreaView style={styles.container} edges={['left', 'top', 'right']}>
      <View style={[{ flex: 1, alignItems: 'center', maxWidth: 480 }, styles.hrPadding]}>
        <Spacer size={96} />
        <NotificationEmptyScreenIcon />
        <Spacer size="32" />
        <H2s>{t('notifications')}</H2s>
        <Spacer size="8" />
        <Body style={{ color: '#2A2E43', textAlign: 'center' }}>{t('emptyNotificationState')}</Body>
      </View>
      <ActivatePushWidget onNavigatePermissionDenied={onNavigatePermissionDenied} />
    </SafeAreaView>
  );
};

export default EmptyNotificationsScreenView;

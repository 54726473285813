import { colors, Column, Row } from '@mero/components';
import { useMemo } from 'react';
import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';

import { CalendarContext, LocalDateObject } from '../../../../contexts/CalendarContext';
import { getDatesInWeek, getWeekDays } from '../utils';
import { styles } from './styles';

type Props = {
  readonly selectedDate: LocalDateObject;
  readonly currentDate: LocalDateObject;
  readonly onDayPress?: (d: LocalDateObject) => void;
};

const CalendarHeaderMobileWeb: React.FC<Props> = ({ selectedDate, currentDate, onDayPress = () => null }) => {
  const weekDates = React.useMemo((): LocalDateObject[] => getDatesInWeek({ date: selectedDate }), [selectedDate]);
  const weekDays = useMemo(() => getWeekDays(), []);
  const [{ period }] = CalendarContext.useContext();

  const onDayChange = React.useCallback(
    (day: LocalDateObject) => {
      onDayPress(day);
    },
    [onDayPress],
  );

  return (
    <Row style={[styles.headerStyle, period.type === 'week' ? { marginLeft: 50 } : { paddingBottom: 16 }]}>
      {weekDays.map((day, index) => {
        const activeDay: LocalDateObject = weekDates[index];
        const isSelected = LocalDateObject.equals(activeDay, selectedDate);
        const currentDateCmp = LocalDateObject.compare(activeDay, currentDate);
        const isCurrentOrAfter = currentDateCmp !== -1;

        const textStyle = [
          styles.weekday,
          isSelected
            ? {
                color: colors.WHITE,
              }
            : {},
          isCurrentOrAfter ? {} : { color: '#ADADAD' },
        ];
        const containerStyle = [
          {
            width: 36,
            height: 36,
          },
          isSelected
            ? {
                borderRadius: 18,
                backgroundColor: colors.DARK_BLUE,
              }
            : {},
        ];

        return (
          <TouchableOpacity key={day + index} style={styles.weekdays} onPress={() => onDayChange(activeDay)}>
            <Column>
              <Text
                allowFontScaling={false}
                style={[styles.weekday, { paddingBottom: 5, fontSize: 13 }]}
                numberOfLines={1}
              >
                {day[0]}
              </Text>
            </Column>
            <Column justifyContent="center" style={containerStyle}>
              <Text
                allowFontScaling={false}
                style={[textStyle, { fontFamily: 'open-sans-semibold', fontSize: 15 }]}
                numberOfLines={1}
              >
                {LocalDateObject.formatDay(weekDates[index], true)}
              </Text>
            </Column>
          </TouchableOpacity>
        );
      })}
    </Row>
  );
};

export default CalendarHeaderMobileWeb;

import { Body, Column, HSpacer, Line, SmallBody, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { AuthContext } from '../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import { PushServerSubscriptionContext } from '../../contexts/PushServerSubscriptionContext';
import log from '../../utils/log';
import { styles } from './styles';

const ProfileDetailsIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G data-name="Group 5927">
      <Path data-name="Path 8454" d="M0 0h24v24H0z" fill="none" transform="translate(-25 -313) translate(25 313)" />
      <Path
        data-name="Path 8455"
        d="M12 2a10 10 0 1010 10A10 10 0 0012 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78a7.925 7.925 0 01-9.86 0zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33a8 8 0 1112.72 0zM12 6a3.5 3.5 0 103.5 3.5A3.491 3.491 0 0012 6zm0 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 11z"
        fill="#52577f"
        transform="translate(-25 -313) translate(25 313)"
      />
    </G>
  </Svg>
);

const SettingsIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <Path data-name="Path 8456" d="M0 0h24v24H0z" fill="none" transform="translate(0 -2) translate(0 2)" />
    <Path
      data-name="Path 8457"
      d="M19.43 12.98a7.793 7.793 0 00.07-.98 7.793 7.793 0 00-.07-.98l2.11-1.65a.5.5 0 00.12-.64l-2-3.46a.5.5 0 00-.44-.25.467.467 0 00-.17.03l-2.49 1a7.306 7.306 0 00-1.69-.98l-.38-2.65A.488.488 0 0014 2h-4a.488.488 0 00-.49.42l-.38 2.65a7.683 7.683 0 00-1.69.98l-2.49-1a.566.566 0 00-.18-.03.5.5 0 00-.43.25l-2 3.46a.493.493 0 00.12.64l2.11 1.65a7.931 7.931 0 00-.07.98 7.931 7.931 0 00.07.98l-2.11 1.65a.5.5 0 00-.12.64l2 3.46a.5.5 0 00.44.25.467.467 0 00.17-.03l2.49-1a7.306 7.306 0 001.69.98l.38 2.65A.488.488 0 0010 22h4a.488.488 0 00.49-.42l.38-2.65a7.683 7.683 0 001.69-.98l2.49 1a.566.566 0 00.18.03.5.5 0 00.43-.25l2-3.46a.5.5 0 00-.12-.64zm-1.98-1.71a5.343 5.343 0 01.05.73c0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68a5.857 5.857 0 01-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43a5.674 5.674 0 01-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68a5.857 5.857 0 011.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43a5.674 5.674 0 011.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2.006 2.006 0 01-2 2z"
      fill="#52577f"
      transform="translate(0 -2) translate(0 2)"
    />
  </Svg>
);

export const DoubleArrowsIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <Path data-name="Path 9078" d="M0 0h24v24H0z" fill="none" />
    <Path data-name="Path 9079" d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99zM21 9l-3.99-4v3H10v2h7.01v3z" fill="#52577f" />
  </Svg>
);

type Props = {
  onClose: () => void;
  navigateHelpCenter: () => void;
  navigateSelectPage: () => void;
  navigateWorkerProfileSettings: () => void;
  navigateNotificationsOptions: () => void;
};

export default function ProfileMenuWeb({
  onClose,
  navigateHelpCenter,
  navigateSelectPage,
  navigateWorkerProfileSettings,
  navigateNotificationsOptions,
}: Props): React.ReactElement | null {
  const { t } = useTranslation('settings');

  const [state] = CurrentBusinessContext.useContext();
  const [, { tryUnsubscribe: tryUnsubscribePush }] = PushServerSubscriptionContext.useContext();
  const [, { signOut }] = AuthContext.useContext();

  const onSignOut = React.useCallback(() => {
    const doit = async (): Promise<void> => {
      try {
        log.debug('Going to remove user notification device...');
        await tryUnsubscribePush();
      } catch (e) {
        log.error('Failed to unregister notification device', e);
      }
      try {
        log.debug('Going to signOut');
        await signOut();
      } catch (e) {
        log.error('Failed to sign out', e);
      }
    };

    doit();
  }, [signOut]);

  if (state.type !== 'Loaded') {
    return null;
  }

  return (
    <Column style={styles.optionsMenu}>
      <TouchableOpacity
        style={styles.optionsMenuItem}
        delayPressIn={0}
        onPress={() => {
          onClose();
          navigateWorkerProfileSettings();
        }}
      >
        <ProfileDetailsIcon />
        <HSpacer left={12} />
        <Body style={meroStyles.text.semibold}>{t('profileDetails')}</Body>
      </TouchableOpacity>
      <Line />

      <TouchableOpacity
        style={[styles.optionsMenuItem, { alignItems: 'flex-start' }]}
        delayPressIn={0}
        onPress={() => {
          onClose();
          navigateSelectPage();
        }}
      >
        <DoubleArrowsIcon />
        <HSpacer left={12} />
        <Column style={{ flex: 1, maxWidth: 284 }}>
          <Body style={meroStyles.text.semibold}>{t('updateLocation')}</Body>
          <SmallBody style={[meroStyles.text.hint, { fontSize: 13 }]}>{state.page.details.name}</SmallBody>
        </Column>
      </TouchableOpacity>
      <Line />
      <TouchableOpacity
        style={styles.optionsMenuItem}
        delayPressIn={0}
        onPress={() => {
          onClose();
          navigateNotificationsOptions();
        }}
      >
        <SettingsIcon />
        <HSpacer left={12} />
        <Body style={meroStyles.text.semibold}>{t('notificationsSettings')}</Body>
      </TouchableOpacity>
      <Line />
      <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={navigateHelpCenter}>
        <Body style={[meroStyles.text.semibold]}>{t('helpCenter')}</Body>
      </TouchableOpacity>
      <Line />
      <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSignOut}>
        <Body style={[meroStyles.text.semibold]}>{t('signOut')}</Body>
      </TouchableOpacity>
    </Column>
  );
}

import { Body, styles as meroStyles, colors, Column } from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  navigateBrand: () => void;
  isPhone: boolean;
};

const BrandAddButton: React.FC<Props> = ({ isPhone, navigateBrand }) => {
  const { t } = useTranslation('products');

  return (
    <Column>
      <TouchableOpacity
        style={[isPhone && { paddingVertical: 12 }, { flexDirection: 'row' }]}
        onPress={() => navigateBrand()}
      >
        <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
          {isPhone ? t('add') : t('addBrand')}
        </Body>
        <View
          style={{
            paddingLeft: 8,
            justifyContent: 'center',
          }}
        ></View>
      </TouchableOpacity>
    </Column>
  );
};

export default BrandAddButton;

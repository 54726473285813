import { colors, Column, SmallBody } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import * as React from 'react';

import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { Props as TypeSafeTextInputProps } from '@mero/components/lib/components/TypeSafeTextInput';

import { styles } from './styles';

export type Props<A extends t.Mixed> = {
  description: string;
  isError: boolean;
} & TypeSafeTextInputProps<A>;

const Nullish = () => void 0;
const TypeSafeTextInputWithDescription = <A extends t.Mixed>(props: Props<A>) => {
  const { description, isError, onFocus = Nullish, onBlur = Nullish, ...rest } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const onFocusCallback = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlurCallback = React.useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Column
      style={[
        {
          borderWidth: isFocused || isError ? 1 : 0,
          borderColor: isFocused ? colors.DARK_BLUE : isError ? colors.RADICAL_RED : colors.GEYSER,
        },
        styles.container,
      ]}
    >
      <Column
        style={[
          {
            borderLeftWidth: isFocused || isError ? 0 : 1,
            borderRightWidth: isFocused || isError ? 0 : 1,
            borderTopWidth: isFocused || isError ? 0 : 1,
          },
          styles.inputContainer,
        ]}
      >
        <TypeSafeTextInput
          onFocus={flow(onFocusCallback, onFocus)}
          onBlur={flow(onBlurCallback, onBlur)}
          {...rest}
          rounded={false}
          withBorder={false}
        />
      </Column>
      <Column
        style={[
          {
            borderLeftWidth: isFocused || isError ? 0 : 1,
            borderRightWidth: isFocused || isError ? 0 : 1,
            borderBottomWidth: isFocused || isError ? 0 : 1,
            borderColor: '#A8AABE',
          },
          styles.description,
        ]}
      >
        <SmallBody style={{ color: colors.WHITE }}>{description}</SmallBody>
      </Column>
    </Column>
  );
};

export default TypeSafeTextInputWithDescription;

import { Text, Button, Spacer, SafeAreaView } from '@mero/components';
import * as Linking from 'expo-linking';
import * as React from 'react';
import { View } from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '../../types';
import { styles } from './styles';

type Props = {
  navigation: StackNavigationProp<RootStackParamList, 'NotFound'>;
};

export default function NotFoundScreen({ navigation }: Props): React.ReactElement {
  const url = Linking.useURL();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <Text h2s>This screen doesn&apos;t exist.</Text>
        {!!url ? (
          <>
            <Spacer size="24" />
            <Text>
              <Text bold>URL: </Text>
              {url}
            </Text>
          </>
        ) : null}
        <Spacer size="32" />
        <Button
          text="Go to home screen"
          onClick={() => {
            navigation.replace('Index');
          }}
        />
      </View>
    </SafeAreaView>
  );
}

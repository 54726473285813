import { Price } from '@mero/api-sdk/dist/services';
import { SavedWorker, Worker } from '@mero/api-sdk/dist/workers';
import {
  Column,
  H1,
  Icon,
  MeroHeader,
  Row,
  Spacer,
  Line,
  colors,
  Body,
  Button,
  Avatar,
  Title,
  SmallBody,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';

import { styles } from '../../../../../components/AddBookingScreen/styles';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import { promiseSequence } from '../../../../../utils/promise';

export const getPrice = (price: Price, discounts = 0): string => {
  switch (price.type) {
    case 'range': {
      return `${price.range.from} - ${price.range.to} lei`;
    }
    case 'fixed': {
      const priceValue = (price.promo ?? price.fixed) - discounts;
      return `${priceValue > 0 ? priceValue : 0} lei`;
    }
    case 'hidden': {
      return `preț variabil`;
    }
    default: {
      return '';
    }
  }
};

const ProsSortScreen: React.FC = ({}) => {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const goBack = useGoBack();

  const [pageState, { reloadAsync: reloadPageAsync }] = CurrentBusinessContext.useContext();

  const [workersOrder, setWorkersOrder] = React.useState<SavedWorker[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const sortWorkers = (index: number, direction: -1 | 1) => () => {
    if ((index === 0 && direction === -1) || (index === workersOrder.length - 1 && direction === 1)) {
      return;
    }

    const newWorkersOrder = [...workersOrder];
    const worker = newWorkersOrder[index];
    newWorkersOrder.splice(index, 1);
    newWorkersOrder.splice(index + direction, 0, worker);
    setWorkersOrder(newWorkersOrder);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    try {
      if (pageState.type === 'Loaded') {
        await promiseSequence(
          workersOrder.map(
            (worker, index) => async () =>
              await meroApi.pages.orderWorker({
                pageId: pageState.page.details._id,
                workerId: worker._id,
                order: index,
              }),
          ),
        );

        await reloadPageAsync();

        toast.show({
          type: 'success',
          text: t('savedSuccessfully'),
        });

        goBack();
      }
    } catch (error) {
      log.error('Error while saving page workers order', error);
      toast.show({
        type: 'error',
        text: t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const workers = (pageState.page.details.workerIds ?? [])
        .map((workerId) => {
          const worker = pageState.page.workers.find((worker) => worker._id === workerId);
          if (worker) {
            return worker;
          }
          return null;
        })
        .filter((worker): worker is SavedWorker => worker !== null);
      setWorkersOrder(workers);
    }
  }, [pageState.type]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canClose onClose={goBack} title={t('sortPros')} />
      <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 86 }}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('team')}</H1>
        <Spacer size={6} />
        <Body style={{ paddingHorizontal: 8 }}>{t('sortProsDescription')}</Body>
        <Column
          style={{
            flex: 1,
            paddingVertical: 24,
            backgroundColor: '#fff',
            marginTop: 24,
            borderRadius: 4,
          }}
        >
          <FlatList
            data={workersOrder}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              const isInvited = pageState.type === 'Loaded' ? Worker.isInvited(item, pageState.page.details) : false;

              return (
                <Row>
                  <Avatar
                    firstname={item.user.firstname ?? ''}
                    lastname={item.user.lastname ?? ''}
                    source={item.profilePhoto?.thumbnail}
                    size={48}
                  />
                  <Column style={{ paddingLeft: 12, flex: 1 }}>
                    <Title style={{ color: '#06115E' }}>
                      {item.user.firstname} {item.user.lastname}
                    </Title>
                    <SmallBody>{isInvited ? t('guest') : t('pro')}</SmallBody>
                  </Column>
                  <Row style={{ paddingRight: 12 }}>
                    {index > 0 && (
                      <TouchableOpacity style={{ paddingRight: 12 }} onPress={sortWorkers(index, -1)}>
                        <Icon type="go-up" color={colors.DARK_BLUE} />
                      </TouchableOpacity>
                    )}
                    {index < workersOrder.length - 1 ? (
                      <TouchableOpacity onPress={sortWorkers(index, 1)}>
                        <Icon type="go-down" color={colors.DARK_BLUE} />
                      </TouchableOpacity>
                    ) : (
                      <Column style={{ width: 24, height: 24 }} />
                    )}
                  </Row>
                </Row>
              );
            }}
            keyExtractor={(item) => item._id}
            ItemSeparatorComponent={() => (
              <>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
              </>
            )}
          />
        </Column>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text="Salvează modificări" onClick={saveChanges} disabled={isLoading} />
          ) : (
            <Button
              disabled={isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text="Salvează modificări"
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProsSortScreen;

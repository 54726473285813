import { ProductBrand, ProductBrandId, ProductId } from '@mero/api-sdk';
import {
  Body,
  Column,
  FormCard,
  Line,
  MeroHeader,
  ModalOverlay,
  Spacer,
  Title,
  colors,
  styles as meroStyles,
  SafeAreaView,
  Button,
  H2s,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Pressable, ScrollView, TouchableOpacity, View, FlatList } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  ProductsTabStackParamList,
  RootStackParamList,
} from '../../../types';
import { getBrandTitle } from './BrandsContent';
import ProductSummaryCard, { convertProductSummaryData } from './ProductSummaryCard';
import { styles } from './styles';

type Props = {
  route: RouteProp<ProductsTabStackParamList, 'BrandProductsListScreen'>;
  navigation: CompositeNavigationProp<
    StackNavigationProp<ProductsTabStackParamList, 'BrandProductsListScreen'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProductsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const BrandProductsListScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();

  const [searchState, { search, loadMore }] = SearchProductsContext.useContext();
  const [state] = ProductsContext.useContext();

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [brand, setBrand] = React.useState<ProductBrand | undefined>();

  React.useEffect(() => {
    if (state.type === 'Loaded') {
      const found = state.brands.find((brand) => brand._id === route.params.brandId);
      if (found) {
        setBrand(found);
        search({ query: { brandId: found._id } });
      }
    }
  }, [state.type]);

  const navigateProductCallback = React.useCallback(
    (productId?: ProductId) => {
      if (productId) {
        navigation.navigate('ProductEdit', { screen: 'ProductMenu', params: { productId } });
      } else {
        navigation.navigate('ProductEdit', { screen: 'ProductScreen', params: { productId } });
      }
    },
    [navigation],
  );

  const navigateBrandCallback = React.useCallback(
    (brandId: ProductBrandId) => {
      navigation.navigate('ProductBrandScreen', { brandId });
    },
    [navigation],
  );

  const navigateDeleteBrandCallback = React.useCallback(
    (brandId: ProductBrandId) => {
      navigation.navigate('DeleteBrandScreen', { brandId });
    },
    [navigation],
  );

  if (!brand) {
    return;
  }

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader
          canGoBack
          onBack={goBack}
          TitleComponent={() => <Title style={{ color: colors.BLACK }}>{brand.name}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          RightComponent={
            <TouchableOpacity
              style={[{ paddingVertical: 12 }, { flexDirection: 'row' }]}
              onPress={() => setShowOptionsMenu(true)}
            >
              <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('options')}</Body>
              <View
                style={{
                  paddingLeft: 8,
                  justifyContent: 'center',
                }}
              ></View>
            </TouchableOpacity>
          }
        />

        {!!searchState.counters.brands[brand._id] && (
          <ScrollView style={{ paddingHorizontal: 16 }}>
            <Spacer size={16} />
            {getBrandTitle(brand.name, t, searchState.query.search)}
            <Spacer size={32} />
            <FormCard dropShaddow paddings="none" rounded style={{ paddingHorizontal: 24 }}>
              <FlatList
                showsVerticalScrollIndicator={false}
                onEndReachedThreshold={0.8}
                data={searchState.result.products}
                onEndReached={loadMore}
                ListFooterComponent={searchState.type === 'Loading' ? <ActivityIndicator size="large" /> : null}
                renderItem={(i) => (
                  <ProductSummaryCard
                    product={convertProductSummaryData(i.item, t)}
                    showLine={i.index !== searchState.counters.brands[brand._id] - 1}
                    openProductDetails={navigateProductCallback}
                    showCategoryLabel={false}
                  />
                )}
              ></FlatList>
            </FormCard>
            <Spacer size={163} />
          </ScrollView>
        )}

        {!searchState.counters.brands[brand._id] && (
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <H2s>Niciun produs</H2s>
            <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
              Produsele adăugate vor apărea aici.
            </Body>
            <Column style={{ paddingTop: 24 }}>
              <Button
                backgroundColor={colors.DARK_BLUE}
                color={colors.ALABASTER}
                size="medium"
                withShadow
                expand={false}
                text="Adaugă produs"
                padding={36}
                onClick={() => navigateProductCallback()}
              />
            </Column>
          </Column>
        )}
      </ModalScreenContainer>

      {showOptionsMenu && (
        <ModalOverlay>
          <MobileWebModalWrapper position="bottom">
            <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
            <Column style={[styles.optionsListContainer]}>
              <SafeAreaView edges={['bottom']}>
                <Column>
                  <TouchableOpacity
                    style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                    delayPressIn={0}
                    onPress={() => {
                      setShowOptionsMenu(false);
                      navigateBrandCallback(brand._id);
                    }}
                  >
                    <Title>{t('editBrand')}</Title>
                  </TouchableOpacity>
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Line />
                  </Column>
                </Column>
                <TouchableOpacity
                  style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                  delayPressIn={0}
                  onPress={() => {
                    setShowOptionsMenu(false);
                    navigateDeleteBrandCallback(brand._id);
                  }}
                >
                  <Title style={{ color: colors.RADICAL_RED }}>{t('deleteBrand')}</Title>
                </TouchableOpacity>
                <Line />
              </SafeAreaView>
            </Column>
          </MobileWebModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default BrandProductsListScreen;

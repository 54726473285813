import {
  Avatar,
  Body,
  Button,
  colors,
  Column,
  H1,
  Header,
  Icon,
  SimpleListItem,
  SmallBody,
  Spacer,
  useShowError,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TextInput, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { LocationProfileStackParamList, RootStackParamList } from '../../../../../types';
import DeleteLocationDialog from './DeleteLocationDialog';

export type Props = StackScreenProps<LocationProfileStackParamList & RootStackParamList, 'DeleteLocation'>;
type Reasons = 'reason1' | 'reason2' | 'reason3' | 'reason4';

const DeleteLocationScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('location');

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedReason, setSelectedReason] = React.useState<Reasons | ''>('');
  const [otherReason, setOtherReason] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  const toggleDeleteDialog = () => {
    if (selectedReason === 'reason4' && !otherReason) {
      return toast.show({
        type: 'error',
        text: t('deleteLocationOtherReasonRequired'),
      });
    }

    if (selectedReason === '') {
      return toast.show({
        type: 'error',
        text: t('deleteLocationReasonRequired'),
      });
    }

    setShowDeleteDialog(!showDeleteDialog);
  };

  const setReason = (reason: Reasons) => () => {
    setSelectedReason(reason);
  };

  const unlistLocation = async () => {
    setIsLoading(true);

    try {
      await meroApi.pages.unsetPageSearchable({ pageId: pageState.page.details._id });
      await reloadAsync();
      goBack();
      toast.show({
        type: 'success',
        text: t('unlistSuccess'),
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = () => {
    toggleDeleteDialog();
    navigation.navigate('Authorized', {
      screen: 'Home',
      params: {
        screen: 'HomeTabs',
        params: {
          screen: 'MenuTab',
          params: {
            screen: 'MenuScreen',
          },
        },
      },
    });
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <Header
          LeftComponent={() => (
            <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
              <Icon type="back" />
            </TouchableOpacity>
          )}
          text={t('confirmDelete')}
          RightComponent={() => (
            <Column style={{ paddingRight: 24 }}>
              <Avatar
                size={32}
                source={pageState.page.details.profilePhoto.thumbnail}
                firstname={pageState.page.details.name}
                lastname={''}
              />
            </Column>
          )}
        />
        <ScrollView style={{ paddingHorizontal: 24, paddingTop: 16, flex: 1 }}>
          <H1>{t('deleteReason')}</H1>
          <Spacer size={24} />
          <TouchableOpacity onPress={setReason('reason1')}>
            <SimpleListItem
              icon={selectedReason === 'reason1' ? 'checked' : 'unchecked'}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('reason1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <TouchableOpacity onPress={setReason('reason2')}>
            <SimpleListItem
              icon={selectedReason === 'reason2' ? 'checked' : 'unchecked'}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('reason2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <TouchableOpacity onPress={setReason('reason3')}>
            <SimpleListItem
              icon={selectedReason === 'reason3' ? 'checked' : 'unchecked'}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('reason3')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <TouchableOpacity onPress={setReason('reason4')}>
            <SimpleListItem
              icon={selectedReason === 'reason4' ? 'checked' : 'unchecked'}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('reason4')}</Body>
              <Spacer size={8} />
              <TextInput
                style={{
                  borderWidth: 1,
                  borderColor: colors.ATHENS_GRAY,
                  backgroundColor: colors.WHITE,
                  padding: 8,
                  borderRadius: 4,
                  height: 100,
                  width: '100%',
                  textAlignVertical: 'top',
                  color: colors.COMET,
                }}
                placeholder={t('otherReasonPlaceholder')}
                numberOfLines={3}
                multiline
                value={otherReason}
                onChangeText={setOtherReason}
                editable={selectedReason === 'reason4'}
              />
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={48} />
          {/*{pageState.page.details.published && (*/}
          {/*  <>*/}
          {/*    <Button text={t('unlistButton')} onClick={unlistLocation} />*/}
          {/*    <Spacer size={12} />*/}
          {/*    <SmallBody style={{ textAlign: 'center' }}>{t('unlistDescription')}</SmallBody>*/}
          {/*    <Spacer size={48} />*/}
          {/*  </>*/}
          {/*)}*/}
          <Button
            text={t('deletePermanentlyButton')}
            backgroundColor={colors.ALABASTER}
            color={colors.RADICAL_RED}
            onClick={toggleDeleteDialog}
          />
          <Spacer size={48} />
        </ScrollView>
      </ModalScreenContainer>
      {showDeleteDialog && (
        <DeleteLocationDialog
          onSuccess={onDelete}
          onCancel={toggleDeleteDialog}
          reason={selectedReason === 'reason4' ? otherReason : selectedReason === '' ? '' : t(selectedReason)}
        />
      )}
    </>
  );
};

export default DeleteLocationScreen;

import React from 'react';
import { View } from 'react-native';

const TAP_DELAY = 300;

type Props = {
  onDoubleClick: () => void;
};

const DoubleTapView: React.FC<React.PropsWithChildren<Props>> = ({ onDoubleClick, ...props }) => {
  const lastClickTimeRef = React.useRef(0);

  const handleResponderGrant = () => {
    const now = new Date().getTime();
    if (lastClickTimeRef.current && now - lastClickTimeRef.current < TAP_DELAY) {
      onDoubleClick();
    }
    lastClickTimeRef.current = now;
  };

  return (
    <View {...props} onStartShouldSetResponder={() => true} onResponderGrant={handleResponderGrant}>
      {props.children}
    </View>
  );
};

export default DoubleTapView;

import {
  CheckoutTransactionPayment,
  CreateCheckoutTransactionItem,
  CreateCheckoutTransactionPayment,
  MeroUnits,
  Money,
  ScaledNumber,
} from '@mero/api-sdk';

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

/**
 * Compute finished transaction payment total
 */
export const computeFinishedTransactionPaymentTotal = <Unit extends MeroUnits.Any>(
  payment: CheckoutTransactionPayment.Any<ScaledNumber, Unit>,
  unit: Unit,
): Money<ScaledNumber, Unit> => {
  if (payment.type === 'Online') {
    return MoneyScaledNumber.zero('RON') as Money<ScaledNumber, Unit>;
  }
  if (payment.type === 'Membership') {
    return payment.items.reduce((total, item) => {
      return MoneyScaledNumber.add(
        total,
        item.type === 'Service' ? item.effectivePrice.amount : MoneyScaledNumber.zero(unit),
      );
    }, MoneyScaledNumber.zero(unit));
  } else {
    return payment.total;
  }
};

/**
 * Compute draft transaction payment total
 */
export const computeDraftTransactionPaymentTotal = <Unit extends MeroUnits.Any>(
  payment: CreateCheckoutTransactionPayment.Any<ScaledNumber, Unit>,
  items: CreateCheckoutTransactionItem.Any<Unit>[],
  unit: Unit,
): Money<ScaledNumber, Unit> => {
  if (payment.type === 'Online') {
    return MoneyScaledNumber.zero('RON') as Money<ScaledNumber, Unit>;
  }
  if (payment.type === 'Membership') {
    const transactionServices = items.flatMap((transactionItem) =>
      transactionItem.type === 'Booking'
        ? transactionItem.items.flatMap((item) => {
            if (item.type === 'Service') {
              return [item];
            }

            return [];
          })
        : transactionItem.type === 'Service'
        ? transactionItem
        : [],
    );

    return payment.items.reduce((total, item) => {
      if (item.type === 'Product') {
        return total;
      }
      const found = transactionServices.find((transactionItem) => transactionItem.service._id === item.service._id);
      return MoneyScaledNumber.add(total, found ? found.total.amount : MoneyScaledNumber.zero(unit));
    }, MoneyScaledNumber.zero(unit));
  } else {
    return payment.total;
  }
};

import { useShowError } from '@mero/components';
import * as React from 'react';

import AddClientScreenView from '../../../components/AddClientScreen';

export type Props = {
  readonly onClose: () => void;
  readonly error: unknown;
};

const EditClientFailedScreenView: React.FC<Props> = ({ onClose, error }: Props) => {
  const showError = useShowError();

  React.useEffect(() => {
    showError(error);
  }, [error]);

  return <AddClientScreenView mode="edit" onClosePressed={onClose} />;
};

export default EditClientFailedScreenView;

import { LoginButton } from '@mero/components';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Platform } from 'react-native';

import config from '../../../../config';
import log from '../../../../utils/log';
import { SigningContext } from '../../SigningContext';

WebBrowser.maybeCompleteAuthSession();

export type Props = {
  title?: string;
};

export default function GoogleSignInButton({ title }: Props): React.ReactElement {
  const [signingState, signing] = SigningContext.useContext();
  const signInProgress = signingState.type !== 'Ready';

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId: Platform.select({
      android: config.google.androidStandaloneAppClientId,
      ios: config.google.iosStandaloneAppClientId,
      default: config.google.clientId,
    }),
  });

  React.useEffect(() => {
    const doSignIn = async () => {
      if (response?.type === 'success') {
        const idToken = response.params.id_token;

        if (idToken) {
          signing.setSignInProgress();
          try {
            await signing.loginGoogle(idToken);
          } finally {
            signing.stopSignInProgress();
          }
        } else {
          log.error('There was no idToken returned', response);
        }
      }
    };

    doSignIn();
  }, [response]);

  return (
    <LoginButton
      icon="google"
      text={title ?? 'Continuă cu Google'}
      onClick={() => {
        promptAsync();
      }}
      disabled={signInProgress || !request}
    />
  );
}

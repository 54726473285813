import {
  PageId,
  ProductCategory,
  ProductBrand,
  DefinedTrimedString,
  ProductCategoryId,
  ProductImage,
  ProductMeasure,
} from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import React from 'react';

import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

type DefaultCategory = {
  type: 'default';
  name: 'active' | 'inactive' | 'other';
};

type CustomCategory = {
  type: 'custom';
  _id: ProductCategoryId;
  name: DefinedTrimedString;
};

export const DefaultCategory: { [key: string]: DefaultCategory } = {
  active: {
    type: 'default',
    name: 'active',
  },
  inactive: {
    type: 'default',
    name: 'inactive',
  },
  other: {
    type: 'default',
    name: 'other',
  },
};

export type SelectedCategory = DefaultCategory | CustomCategory;

export type SelectedImage = {
  readonly image: ProductImage;
  readonly position: number;
};

// TO DO: use the api-sdk type
export type Spec = ProductMeasure.Spec;

type StateNew = {
  readonly type: 'New';
  readonly selectedCategory?: SelectedCategory;
  readonly selectedBrand?: ProductBrand;
  readonly selectedImage?: SelectedImage;
};

type StateInitializing = {
  readonly type: 'Initializing';
  readonly selectedCategory?: SelectedCategory;
  readonly selectedBrand?: ProductBrand;
  readonly selectedImage?: SelectedImage;
};

type StateLoaded = {
  readonly type: 'Loaded';
  readonly categories: ProductCategory[];
  readonly brands: ProductBrand[];
  readonly selectedCategory?: SelectedCategory;
  readonly selectedBrand?: ProductBrand;
  readonly selectedImage?: SelectedImage;
  readonly unitsOfMeasurement: Spec[];
  readonly productDescription?: DefinedTrimedString;
};

type StateFailed = {
  readonly type: 'Failed';
  readonly selectedCategory?: SelectedCategory;
  readonly selectedBrand?: ProductBrand;
  readonly selectedImage?: SelectedImage;
  readonly error: unknown;
};

type State = StateNew | StateInitializing | StateLoaded | StateFailed;

const defaultState = (): State => ({
  type: 'New',
});

export const ProductsContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(state) {
      if (state.type === 'New' || state.type === 'Loaded') {
        return {
          type: 'Initializing',
        };
      }
      return state;
    },
    setLoaded(
      _,
      params: {
        categories: ProductCategory[];
        brands: ProductBrand[];
        selectedCategory?: SelectedCategory;
        unitsOfMeasurement: Spec[];
        selectedBrand?: ProductBrand;
        productDescription?: DefinedTrimedString;
      },
    ) {
      return {
        ...params,
        type: 'Loaded',
      };
    },
    setFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    update(
      state,
      params: Partial<{
        categories: ProductCategory[];
        brands: ProductBrand[];
        selectedCategory: SelectedCategory;
        selectedImage: SelectedImage;
        unitsOfMeasurement: Spec[];
        selectedBrand?: ProductBrand;
        productDescription?: DefinedTrimedString;
      }>,
    ) {
      return {
        ...state,
        ...params,
      };
    },
    reset: defaultState,
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    return {
      init: (pageId: PageId): void => {
        dispatch.run((state) => {
          dispatch.trySetInitializing();

          const init = async (pageId: PageId) => {
            try {
              const [categories, unitsOfMeasurement, brands] = await Promise.all([
                meroApi.pro.products.getAllCategories(pageId),
                meroApi.pro.products.getUnitsOfMeasurement(pageId),
                meroApi.pro.products.getAllBrands({ pageId }),
              ]);
              dispatch.setLoaded({
                ...state,
                unitsOfMeasurement: unitsOfMeasurement ?? [],
                categories: categories ?? [],
                brands: brands ?? [],
              });
            } catch (e) {
              dispatch.setFailed(e);
            }
          };
          init(pageId);
        });
      },
      reloadCategories: (pageId: PageId) =>
        new Promise<void>((resolve) => {
          dispatch.run((state) => {
            if (state.type !== 'Loaded') {
              return state;
            }
            dispatch.trySetInitializing();
            const reloadCategories = async (pageId: PageId) => {
              try {
                const categories = await meroApi.pro.products.getAllCategories(pageId);
                dispatch.setLoaded({
                  ...state,
                  categories,
                });
              } catch (e) {
                dispatch.setFailed(e);
              } finally {
                resolve();
              }
            };

            reloadCategories(pageId);
          });
        }),
      reloadBrands: (pageId: PageId) =>
        new Promise<void>((resolve) => {
          dispatch.run((state) => {
            if (state.type !== 'Loaded') {
              return state;
            }
            dispatch.trySetInitializing();
            const reloadBrands = async (pageId: PageId) => {
              try {
                const brands = await meroApi.pro.products.getAllBrands({ pageId });
                dispatch.setLoaded({
                  ...state,
                  brands,
                });
              } catch (e) {
                dispatch.setFailed(e);
              } finally {
                resolve();
              }
            };

            reloadBrands(pageId);
          });
        }),
      trySetInitializing: dispatch.trySetInitializing,
      update: dispatch.update,
      reset: dispatch.reset,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  React.useEffect(() => {
    const state = async () => {
      if (pageState.type === 'Loaded') {
        init(pageState.page.details._id);
      }
    };
    state();
  }, [pageState.type === 'Loaded' && pageState.page.details._id]);

  return <>{children}</>;
};

export const withProductsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithProductsContextProvider(props: P) {
    return (
      <ProductsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </ProductsContext.Provider>
    );
  };
};

import * as React from 'react';
import Svg, { Path, G, Circle, Rect } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationCalendarCheckIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#2dce89',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill={backgroundColor} />
    <G transform="translate(-117 -189)">
      <Rect width="18" height="18" transform="translate(124 196)" fill="none" />
      <Path
        d="M14,1.167V14H0V1.167H1.75V1.75a1.167,1.167,0,0,0,2.333,0V1.167H9.917V1.75a1.167,1.167,0,0,0,2.333,0V1.167Zm-1.167,3.5H1.167v8.167H12.833ZM11.667.583a.583.583,0,1,0-1.167,0V1.75a.583.583,0,1,0,1.167,0ZM3.5,1.75a.583.583,0,0,1-1.167,0V.583A.583.583,0,0,1,3.5.583Zm.583,6.842.5-.461a9.639,9.639,0,0,1,1.61.965A13.926,13.926,0,0,1,9.754,6.044l.163.373a15.838,15.838,0,0,0-3.481,4.666A24.962,24.962,0,0,0,4.083,8.592Z"
        transform="translate(126 198)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationCalendarCheckIcon);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import InviteProPermissionsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/InviteProPermissionsScreen';
import InviteProScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/InviteProScreen';
import InviteProServicesSelectScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/InviteProServicesSelectScreen';
import InviteProSuccessScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/InviteProSuccessScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { ProsSettingsInviteStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<ProsSettingsInviteStackParamList>();

function ProsSettingsInviteStackNavigator(): React.ReactElement {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <Stack.Navigator
      initialRouteName="ProInvite"
      screenOptions={{
        headerShown: false,
        presentation: 'transparentModal',
      }}
    >
      <Stack.Screen
        name="ProInvite"
        component={InviteProScreen}
        options={{ title: t('inviteProScreen'), cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
      />
      <Stack.Screen
        name="ProInviteServices"
        component={InviteProServicesSelectScreen}
        options={{ title: t('selectServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProInvitePermissions"
        component={InviteProPermissionsScreen}
        options={{ title: t('selectServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProInviteSuccess"
        component={InviteProSuccessScreen}
        options={{ title: t('invitationSent'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default ProsSettingsInviteStackNavigator;

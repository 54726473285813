import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import CompanyScreen from '../screens/Authorized/CheckoutScreen/CompanyScreen';
import EditMembershipScreen from '../screens/Authorized/CheckoutScreen/EditMembershipScreen';
import SelectBookingScreen from '../screens/Authorized/CheckoutScreen/SelectBookingScreen';
import SelectClientScreen from '../screens/Authorized/CheckoutScreen/SelectClientScreen';
import SelectMembershipInstallmentScreen from '../screens/Authorized/CheckoutScreen/SelectMembershipInstallmentScreen';
import SelectMembershipPaymentScreen from '../screens/Authorized/CheckoutScreen/SelectMembershipPaymentScreen';
import SelectMembershipScreen from '../screens/Authorized/CheckoutScreen/SelectMembershipScreen';
import SelectPaymentScreen from '../screens/Authorized/CheckoutScreen/SelectPaymentScreen';
import SelectProductScreen from '../screens/Authorized/CheckoutScreen/SelectProductScreen';
import SelectServiceScreen from '../screens/Authorized/CheckoutScreen/SelectServiceScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { CheckoutSubStackParamList } from '../types';
import { styles } from './styles';

const CheckoutSubStack = createStackNavigator<CheckoutSubStackParamList>();

export function CheckoutSubStackNavigator() {
  const { t } = useTranslation();

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <CheckoutSubStack.Navigator>
      <CheckoutSubStack.Screen
        name="SelectClientScreen"
        component={SelectClientScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('booking:selectClient'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectBookingScreen"
        component={SelectBookingScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:selectBooking'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectServiceScreen"
        component={SelectServiceScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:selectBooking'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectProductScreen"
        component={SelectProductScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:selectBooking'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectMembershipScreen"
        component={SelectMembershipScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:selectMembership'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectMembershipInstallmentScreen"
        component={SelectMembershipInstallmentScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:selectMembership'),
        }}
      />
      <CheckoutSubStack.Screen
        name="EditMembershipScreen"
        component={EditMembershipScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          headerShown: false,
          cardStyle: modalStyle,
          title: t('checkout:editMembership'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectPaymentScreen"
        component={SelectPaymentScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:selectPayment'),
        }}
      />
      <CheckoutSubStack.Screen
        name="SelectMembershipPaymentScreen"
        component={SelectMembershipPaymentScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:selectMembership'),
        }}
      />

      <CheckoutSubStack.Screen
        name="CompanyScreen"
        component={CompanyScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:addNewCompany'),
        }}
      />
    </CheckoutSubStack.Navigator>
  );
}

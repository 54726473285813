import { MeroHeader, SafeAreaView } from '@mero/components';
import * as React from 'react';
import WebView from 'react-native-webview';

import { useEscPressWeb } from '../../../../../hooks/useEscPressWeb';
import useGoBack from '../../../../../hooks/useGoBack';

import { styles } from './styles';

export const ContactSupportScreenUrl = 'https://asistenta.mero.ro/ro/articles/4942975-contacteaza-ne';

type Props = {
  // pass
};

const ContactSupportScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  return (
    <SafeAreaView style={styles.container}>
      <MeroHeader title="Contact MERO Asistență" canGoBack onBack={goBack} />
      <WebView
        style={styles.webView}
        source={{
          uri: ContactSupportScreenUrl,
        }}
        sharedCookiesEnabled={true}
        pullToRefreshEnabled={false}
        domStorageEnabled={true}
      />
    </SafeAreaView>
  );
};

export default ContactSupportScreen;

import { ApiError, apiError } from '@mero/api-sdk';
import { AppointmentId, AppointmentStatus, CalendarId } from '@mero/api-sdk/dist/calendar';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type UpdateBookingStatusContextState =
  | {
      type: 'Ready';
    }
  | {
      type: 'Updating';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
      status: AppointmentStatus;
    }
  | {
      type: 'Updated';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
      status: AppointmentStatus;
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
    };

const defaultState = (): UpdateBookingStatusContextState => ({ type: 'Ready' });

export const UpdateBookingStatusContext = createModelContext(
  defaultState(),
  {
    setUpdating: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId; status: AppointmentStatus }) => ({
      type: 'Updating',
      ...payload,
    }),
    setUpdated: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId; status: AppointmentStatus }) => ({
      type: 'Updated',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown> }) => ({
      type: 'Failed',
      ...payload,
    }),
    reset: () => ({
      type: 'Ready',
    }),
  },
  (dispatch) => {
    return {
      updateBookingStatus: async (payload: {
        calendarId: CalendarId;
        appointmentId: AppointmentId;
        status: AppointmentStatus;
        occurrenceIndex: number;
      }): Promise<void> => {
        try {
          dispatch.setUpdating(payload);
          await meroApi.calendar.updateCalendarAppointmentStatus({
            calendarId: payload.calendarId,
            entryId: payload.appointmentId,
            newStatus: payload.status,
            occurrenceIndex: payload.occurrenceIndex,
          });
          dispatch.setUpdated(payload);
        } catch (e) {
          if (apiError(t.unknown).is(e)) {
            if (e.code === 20) {
              // booking override
            } else {
              log.exception(e);
            }
            dispatch.setFailed({
              error: e,
            });
          } else {
            log.exception(e);
            dispatch.setFailed({
              error: undefined,
            });
          }
        }
      },
      reset: dispatch.reset,
    };
  },
);

export const withUpdateBookingStatusContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithUpdateBookingStatusContextProvider(props: P) {
    return (
      <UpdateBookingStatusContext.Provider>
        <Content {...props} />
      </UpdateBookingStatusContext.Provider>
    );
  };
};

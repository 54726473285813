import {
  MembershipPurchaseId,
  MembershipTemplateId,
  PurchasedMembershipDetails,
  isDefined,
  Numbers,
} from '@mero/api-sdk';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { MembershipTemplateDetails } from '@mero/api-sdk/dist/memberships/membershipTemplateDetails';
import {
  Column,
  H1,
  MeroHeader,
  colors,
  useShowError,
  styles as meroStyles,
  Button,
  DismissKeyboard,
  Spacer,
  Body,
  Row,
  Title,
  SmallBody,
  formatDurationInMinutes,
  Icon,
  Line,
  InputWithLabel,
  HSpacer,
  useToast,
} from '@mero/components';
import { MeroUnits, PositiveInt, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { DiscountPercent } from '@mero/shared-sdk/dist/numbers/discountPercent';
import { flow } from 'fp-ts/function';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { pipe } from 'fp-ts/lib/function';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ItemMenu from '../MenuScreen/screens/PageMembershipSettingsScreen/ItemMenu';
import { AVAILABILITY_LIST } from '../MenuScreen/screens/PageMembershipSettingsScreen/MembershipDetailsScreen';
import {
  calculateItemsDiscount,
  CalculateMembershipItem,
  getItemPrice,
} from '../MenuScreen/screens/PageMembershipSettingsScreen/MembershipItemsScreen';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import SelectDateTimeModal from '../../../components/SelectDateTimeModal';
import TextInput from '../../../components/TextInput';
import { TextInputWithInternalState } from '../../../components/TextInputWithInternalState/TextInputWithInternalState';
import FormCard from '@mero/components/lib/components/FormCard';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Select from '@mero/components/lib/components/Select';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';
import { useSafeInput } from '../../../hooks/useSafeInput';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import {
  CheckoutFormContext,
  MembershipEdit,
  MembershipItem,
  WithUiKey,
  withUiKey,
} from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutSubStackParamList } from '../../../types';
import log from '../../../utils/log';
import { roundToDecimals } from '../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import { PercentageIcon } from './AddProceedComponent';
import DiscountComponent, { MinusIcon, PlusIcon } from './DiscountComponent';
import { styles } from './EditMembershipScreen.styles';
import MembershipPartialPayment from './MembershipPartialPayment';
import MembershipSelectService from './MembershipSelectService';
import SelectPro from './SelectProComponent';

const SESSION_TYPE = [
  {
    value: 'Limited',
    label: 'limited',
  } as const,
  {
    value: 'Unlimited',
    label: 'unlimited',
  } as const,
];

const membershipTemplateToCheckoutItem = (
  template: MembershipTemplateDetails<MeroUnits.Any>,
  user: CheckoutUserPreview,
): MembershipEdit => {
  const total = template.items.reduce(
    (acc, item) => acc + (item.type === 'Service' ? ScaledNumber.toNumber(item.sellingPrice.amount) : 0),
    0,
  );
  return {
    type: 'Membership',
    transactionItemId: undefined,
    membershipTemplate: {
      _id: template._id,
      name: template.name,
      validity:
        template.validFor.type === 'Unlimited'
          ? {
              type: 'Unlimited',
              from: new Date(),
            }
          : {
              type: 'Limited',
              from: new Date(),
              days: template.validFor.days,
            },
      items: template.items.map((item) =>
        item.type === 'Service'
          ? withUiKey({
              type: 'Service',
              service: {
                ...item.service,
                groupIds: [],
              },
              quantity: item.quantity,
              total: {
                amount: item.sellingPrice,
                vatStatus: {
                  type: 'Included',
                },
              },
            })
          : withUiKey(item),
      ) as NonEmptyArray<MembershipItem>,
      discount: template.discount,
    },
    membershipPurchaseId: undefined as unknown as MembershipPurchaseId,
    total: {
      amount: {
        unit: 'RON',
        amount: ScaledNumber.fromNumber(total, 2),
      },
      vatStatus: {
        type: 'Included',
        rate: undefined,
      },
    },
    quantity: Numbers._1,
    saleOwner: user,
  };
};

const membershipPurchaseToCheckoutItem = (
  membership: PurchasedMembershipDetails<MeroUnits.Any>,
  user: CheckoutUserPreview,
): MembershipEdit => {
  const total = membership.template.items.reduce(
    (acc, item) => acc + (item.type === 'Service' ? ScaledNumber.toNumber(item.sellingPrice.amount) : 0),
    0,
  );

  const extractDays = (date1: Date, date2: Date) => {
    const days = DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2)).as('days');
    const months = Math.floor(days / 30);

    return (months > 0 ? months * 30 : 30) as PositiveInt;
  };

  return {
    type: 'Membership',
    transactionItemId: undefined,
    membershipTemplate: {
      _id: membership.template._id,
      name: membership.template.name,
      validity:
        membership.template.validFor.type === 'Unlimited'
          ? {
              type: 'Unlimited',
              from: new Date(),
            }
          : {
              type: 'Limited',
              from: new Date(),
              days: extractDays(membership.template.validFor.from, membership.template.validFor.to),
            },
      items: membership.template.items.map((item) =>
        item.type === 'Service'
          ? withUiKey({
              type: 'Service',
              service: {
                ...item.service,
                groupIds: [],
              },
              quantity: item.quantity,
              total: {
                amount: item.sellingPrice,
                vatStatus: {
                  type: 'Included',
                },
              },
            })
          : withUiKey(item),
      ) as NonEmptyArray<MembershipItem>,
      discount: undefined,
    },
    membershipPurchaseId: undefined as unknown as MembershipPurchaseId,
    total: {
      amount: {
        unit: 'RON',
        amount: ScaledNumber.fromNumber(total, 2),
      },
      vatStatus: {
        type: 'Included',
        rate: undefined,
      },
    },
    quantity: Numbers._1,
    saleOwner: user,
  };
};

const numberValidator = (prev: string) => (next: string) => {
  const parsed = replaceDecimalSeparator(next);
  return localeNumberValidator(parsed) ? localeStringToNumber(parsed).toString() : prev;
};

type Props = StackScreenProps<CheckoutSubStackParamList, 'EditMembershipScreen'> &
  CurrentBusinessProps &
  AuthorizedProps;

const MembershipItemsScreen: React.FC<Props> = ({ page, navigation, authorization, route }: Props) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();
  const { isPhone } = useMediaQueries();

  const [checkoutFormState, { setItem, updateItem: updateCheckoutItem, removeItem: removeCheckoutItem }] =
    CheckoutFormContext.useContext();

  const { params } = route;

  const isNew = typeof params.uiKey === 'undefined';

  const timezone = React.useMemo(() => IANAZone.create('UTC'), []);

  const [isLoading, setIsLoading] = React.useState(true);
  const [showEditDiscount, setShowEditDiscount] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const [membership, setMembership] = React.useState<WithUiKey<MembershipEdit>>();
  const [selectedItem, setSelectedItem] = React.useState<MembershipItem>();
  const [availability, setAvailability] = React.useState(0);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [showPartialPayment, setShowPartialPayment] = React.useState(false);
  const [showSelectService, setShowSelectService] = React.useState(false);

  const explicitPartialPayment = React.useRef(false);

  const [paymentValue, setPaymentValue] = React.useState<number>();

  const [name, setName] = useSafeInput(DefinedString)();

  const loadTemplate = async (membershipId: MembershipTemplateId) => {
    try {
      const membership = await meroApi.memberships
        .getMembershipTemplateById({
          pageId: page.details._id,
          membershipTemplateId: membershipId,
        })
        .catch((error) => {
          log.error('Error getting membership template', JSON.stringify(error));
          throw error;
        });

      const member = page.members.find((m) => m.user._id === authorization.user._id) ?? page.members[0];

      const user = {
        _id: member.user._id,
        phone: member.user.phone ?? '074000000',
        profile: {
          firstname: member.user.profile.firstname,
          lastname: member.user.profile.lastname,
          photo: member.user.profile.photo,
        },
      };
      const item = withUiKey(membershipTemplateToCheckoutItem(membership, user));

      setMembership(item);
      setName(item.membershipTemplate.name);
      setAvailability(
        item.membershipTemplate.validity.type === 'Unlimited'
          ? 0
          : Math.ceil(item.membershipTemplate.validity.days / 30),
      );

      const totalValue = item.membershipTemplate.discount
        ? item.membershipTemplate.discount.type === 'Value'
          ? ScaledNumber.toNumber(item.total.amount.amount) -
            ScaledNumber.toNumber(item.membershipTemplate.discount.value.amount)
          : ScaledNumber.toNumber(item.total.amount.amount) -
            (ScaledNumber.toNumber(item.total.amount.amount) *
              ScaledNumber.toNumber(item.membershipTemplate.discount.percent)) /
              100
        : ScaledNumber.toNumber(item.total.amount.amount);

      if (totalValue > ScaledNumber.toNumber(item.total.amount.amount)) {
        explicitPartialPayment.current = true;
      }

      setPaymentValue(totalValue);
    } catch (error) {
      log.error('Error getting membership template', JSON.stringify(error));

      showError(error);
      goBack();
    } finally {
      setIsLoading(false);
    }
  };

  const loadPurchase = async (membershipId: MembershipPurchaseId) => {
    try {
      const membership = await meroApi.memberships.getPurchasedMembershipById({
        pageId: page.details._id,
        membershipPurchaseId: membershipId,
      });

      const item = withUiKey(membershipPurchaseToCheckoutItem(membership, authorization.user));

      setMembership(item);
      setName(item.membershipTemplate.name);
      setAvailability(
        item.membershipTemplate.validity.type === 'Unlimited'
          ? 0
          : Math.ceil(item.membershipTemplate.validity.days / 30),
      );

      const totalItemsValue = item.membershipTemplate.items.reduce(
        (acc, item) => ScaledNumber.add(acc, item.type === 'Service' ? item.total.amount.amount : ScaledNumber.zero()),
        ScaledNumber.zero(),
      );

      const totalValue = item.membershipTemplate.discount
        ? item.membershipTemplate.discount.type === 'Value'
          ? ScaledNumber.toNumber(totalItemsValue) -
            ScaledNumber.toNumber(item.membershipTemplate.discount.value.amount)
          : ScaledNumber.toNumber(totalItemsValue) -
            (ScaledNumber.toNumber(totalItemsValue) * ScaledNumber.toNumber(item.membershipTemplate.discount.percent)) /
              100
        : ScaledNumber.toNumber(totalItemsValue);

      if (totalValue > ScaledNumber.toNumber(item.total.amount.amount)) {
        explicitPartialPayment.current = true;
      }
      setPaymentValue(ScaledNumber.toNumber(item.total.amount.amount));
    } catch (error) {
      log.error('Error getting membership template', JSON.stringify(error));

      showError(error);
      goBack();
    } finally {
      setIsLoading(false);
    }
  };

  const loadMembershipItem = async (uiKey: string) => {
    const item = checkoutFormState.draft.items.find((item) => item.uiKey === uiKey && item.type === 'Membership') as
      | WithUiKey<MembershipEdit>
      | undefined;
    if (!item) {
      showError(new Error(t('membershipNotFound')));
      goBack();
      return;
    }

    setMembership(item);

    setName(item.membershipTemplate.name);
    setAvailability(
      item.membershipTemplate.validity.type === 'Unlimited' ? 0 : Math.ceil(item.membershipTemplate.validity.days / 30),
    );

    const totalItemsValue = item.membershipTemplate.items.reduce(
      (acc, item) => ScaledNumber.add(acc, item.type === 'Service' ? item.total.amount.amount : ScaledNumber.zero()),
      ScaledNumber.zero(),
    );

    const totalValue = item.membershipTemplate.discount
      ? item.membershipTemplate.discount.type === 'Value'
        ? ScaledNumber.toNumber(totalItemsValue) - ScaledNumber.toNumber(item.membershipTemplate.discount.value.amount)
        : ScaledNumber.toNumber(totalItemsValue) -
          (ScaledNumber.toNumber(totalItemsValue) * ScaledNumber.toNumber(item.membershipTemplate.discount.percent)) /
            100
      : ScaledNumber.toNumber(totalItemsValue);

    if (totalValue > ScaledNumber.toNumber(item.total.amount.amount)) {
      explicitPartialPayment.current = true;
    }

    setPaymentValue(ScaledNumber.toNumber(item.total.amount.amount));

    setIsLoading(false);
  };

  const toggleDatePicker = () => {
    setShowDatePicker((prev) => !prev);
  };

  const togglePartialPayment = () => {
    explicitPartialPayment.current = true;
    setShowPartialPayment((prev) => !prev);
  };

  const toggleSelectService = () => {
    setShowSelectService((prev) => !prev);
  };

  const updateDate = (date: Date) => {
    if (!membership) {
      return;
    }
    setMembership({
      ...membership,
      membershipTemplate: {
        ...membership.membershipTemplate,
        validity: {
          ...membership.membershipTemplate.validity,
          from: date,
        },
      },
    });
  };

  React.useEffect(() => {
    if (typeof params.membershipTemplateId !== 'undefined') {
      if (typeof params.membershipPurchaseId !== 'undefined') {
        loadPurchase(params.membershipPurchaseId);
      } else {
        loadTemplate(params.membershipTemplateId);
      }
    } else if (typeof params.uiKey !== 'undefined') {
      loadMembershipItem(params.uiKey);
    }
  }, [params]);

  const onUpdateDiscount = (value: number, percentage: number, type: 'percentage' | 'value') => {
    if (!membership) {
      return;
    }
    if (type === 'value') {
      const discount = {
        type: 'Value',
        value: {
          unit: 'RON',
          amount: ScaledNumber.fromNumber(value, 2),
        },
      } as const;
      setMembership({
        ...membership,
        membershipTemplate: {
          ...membership.membershipTemplate,
          discount,
        },
        total: {
          ...membership.total,
        },
      });
    }

    if (type === 'percentage') {
      const discount = {
        type: 'Percent',
        percent: ScaledNumber.fromNumber(percentage, 2) as DiscountPercent<ScaledNumber>,
      } as const;
      setMembership({
        ...membership,
        membershipTemplate: {
          ...membership.membershipTemplate,
          discount,
        },
        total: {
          ...membership.total,
        },
      });
    }
  };
  const save = async () => {
    if (!membership || !name.isValid) {
      return;
    }

    if (
      !membership.saleOwner ||
      membership.membershipTemplate.items.some((item) => {
        if (item.type !== 'Service' || item.quantity.type === 'Unlimited') {
          return false;
        }
        const priceNumber = getItemPrice(item.service.price);

        const actualPriceNumber = ScaledNumber.toNumber(item.total.amount.amount) / item.quantity.value;

        return priceNumber > 0 && priceNumber < actualPriceNumber;
      })
    ) {
      setShowErrors(true);
      return;
    }

    const totalValue = paymentValue ?? maxPayValue;

    const newMembership: WithUiKey<MembershipEdit> = {
      ...membership,
      membershipTemplate: {
        ...membership.membershipTemplate,
        name: name.value,
        validity:
          availability > 0
            ? {
                ...membership.membershipTemplate.validity,
                type: 'Limited' as const,
                days: (availability * 30) as PositiveInt,
              }
            : {
                ...membership.membershipTemplate.validity,
                type: 'Unlimited' as const,
              },
        items: membership.membershipTemplate.items,
        discount: membership.membershipTemplate.discount,
      },
      total: {
        ...membership.total,
        amount: {
          ...membership.total.amount,
          amount: ScaledNumber.fromNumber(totalValue, 2),
        },
      },
      saleOwner: membership.saleOwner,
    };

    if (params.uiKey) {
      updateCheckoutItem(newMembership);
    } else if (params.membershipPurchaseId) {
      setItem(newMembership);
    } else {
      setItem(newMembership);
      navigation.popToTop();
    }
    goBack();
  };

  const addItem = (item: MembershipItem) => {
    if (!membership) {
      return;
    }
    setMembership({
      ...membership,
      membershipTemplate: {
        ...membership.membershipTemplate,
        items: [...membership.membershipTemplate.items, item] as unknown as NonEmptyArray<MembershipItem>,
      },
    });
  };

  const updateItem = (item: MembershipItem) => {
    if (!membership) {
      return;
    }
    const items = membership.membershipTemplate.items.map((i) =>
      i.uiKey === item.uiKey ? item : i,
    ) as NonEmptyArray<MembershipItem>;
    setMembership({ ...membership, membershipTemplate: { ...membership.membershipTemplate, items } });
  };

  const totalPrice = React.useMemo(() => {
    return (membership?.membershipTemplate.items ?? []).reduce((acc, item) => {
      if (item.type !== 'Service') {
        return acc;
      }
      const priceNumber =
        getItemPrice(item.service.price) * (item.quantity.type === 'Unlimited' ? 1 : item.quantity.value);
      const sellingPrice = ScaledNumber.toNumber(item.total.amount.amount);

      if (item.quantity.type === 'Unlimited' && priceNumber > ScaledNumber.toNumber(item.total.amount.amount)) {
        return acc + priceNumber;
      }

      if (item.quantity.type !== 'Unlimited' && priceNumber > 0 && priceNumber > sellingPrice) {
        return acc + priceNumber;
      }

      return acc + sellingPrice;
    }, 0);
  }, [membership?.membershipTemplate.items]);

  const itemsDiscount = React.useMemo(
    () =>
      calculateItemsDiscount(
        (membership?.membershipTemplate.items ?? [])
          .map((item): CalculateMembershipItem | undefined =>
            item.type === 'Service'
              ? {
                  type: 'Service',
                  service: {
                    _id: item.service._id,
                    price: item.service.price,
                    durationInMinutes: item.service.durationInMinutes as PositiveInt,
                    name: item.service.name as DefinedString,
                    groupIds: [],
                  },
                  sellingPrice: item.total.amount,
                  quantity: item.quantity,
                }
              : undefined,
          )
          .filter((item) => item) as CalculateMembershipItem[],
      ),
    [membership?.membershipTemplate.items],
  );

  const { maxPayValue, generalDiscount } = React.useMemo(() => {
    if (!membership) {
      return {
        maxPayValue: 0,
        generalDiscount: 0,
      };
    }

    const tempTotal = totalPrice - itemsDiscount;

    if (membership.membershipTemplate.discount) {
      if (membership.membershipTemplate.discount.type === 'Percent') {
        const general = (tempTotal * ScaledNumber.toNumber(membership.membershipTemplate.discount.percent)) / 100;
        return {
          maxPayValue: tempTotal - general,
          generalDiscount: general,
        };
      }
      const general = ScaledNumber.toNumber(membership.membershipTemplate.discount.value.amount);
      return {
        maxPayValue: tempTotal - general,
        generalDiscount: general,
      };
    }

    return {
      maxPayValue: tempTotal,
      generalDiscount: 0,
    };
  }, [totalPrice, itemsDiscount, membership?.membershipTemplate.discount]);

  const removeItem = (item: MembershipItem) => {
    if (!membership) {
      return;
    }

    const items = membership.membershipTemplate.items.filter(
      (i) => i.uiKey !== item.uiKey,
    ) as NonEmptyArray<MembershipItem>;
    setMembership({ ...membership, membershipTemplate: { ...membership.membershipTemplate, items } });
  };

  const { type, percentage, value } = React.useMemo(() => {
    if (!membership || !membership.membershipTemplate.discount) {
      return {
        type: 'value' as const,
        percentage: 0,
        value: 0,
      };
    }

    if (membership.membershipTemplate.discount.type === 'Percent') {
      const percentage = ScaledNumber.toNumber(membership.membershipTemplate.discount.percent);
      return {
        type: 'percentage' as const,
        percentage,
        value: roundToDecimals((percentage / 100) * (maxPayValue + generalDiscount)),
      };
    }

    const value = ScaledNumber.toNumber(membership.membershipTemplate.discount.value.amount);

    return {
      type: 'value' as const,
      percentage: Math.ceil((value / (maxPayValue + generalDiscount)) * 100),
      value,
    };
  }, [membership?.membershipTemplate.discount, maxPayValue, generalDiscount]);

  const startDate = React.useMemo(() => {
    const date = membership?.membershipTemplate.validity.from ?? new Date();
    return DateTime.fromJSDate(date).setLocale('ro').toFormat('dd LLL yyyy');
  }, [membership?.membershipTemplate.validity.from]);

  React.useEffect(() => {
    if (!explicitPartialPayment.current || (paymentValue ?? 0) > maxPayValue) {
      explicitPartialPayment.current = false;
      setPaymentValue(maxPayValue);
    }
  }, [maxPayValue]);

  const discount = membership?.membershipTemplate.discount;
  const hasDiscount = React.useMemo(() => {
    if (!discount) {
      return false;
    }

    if (discount.type === 'Percent' && ScaledNumber.toNumber(discount.percent) === 0) {
      return false;
    }

    if (discount.type === 'Value' && ScaledNumber.toNumber(discount.value.amount) === 0) {
      return false;
    }

    return true;
  }, [discount]);

  const selectedItemIds = React.useMemo(
    () =>
      membership?.membershipTemplate?.items
        .map((i) => (i.type === 'Service' ? i.service._id : undefined))
        .filter(isDefined) ?? [],
    [membership?.membershipTemplate.items],
  );

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.WHITE }}>
        {/*{isLoading && <LoadingComponent />}*/}
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={isNew ? t('steps', { start: 2, end: 2 }) : t('selectMembershipItems')}
        />
        {membership ? (
          <>
            <Column style={{ paddingTop: 16, flex: 1, height: '100%' }}>
              <DismissKeyboard style={{ flex: 1, height: '100%' }}>
                <KeyboardAvoidingView style={{ flex: 1 }}>
                  <ScrollView style={{ borderRadius: 6, flex: 1 }}>
                    <H1 style={{ paddingHorizontal: 24 }}>
                      {isNew ? t('customiseMembership') : t('selectMembershipItems')}
                    </H1>
                    <Spacer size="32" />
                    <Column style={{ paddingHorizontal: 24 }}>
                      <InputWithLabel
                        label={t('membershipName')}
                        isError={showErrors && !name.isValid}
                        errorText={t('membershipNameError')}
                      >
                        <TextInput value={name.value} onChange={setName} placeholder={t('namePlaceholder')} />
                      </InputWithLabel>
                      <Spacer size={16} />
                      <SelectPro
                        value={membership.saleOwner?._id}
                        onUpdate={(saleOwner) => setMembership({ ...membership, saleOwner })}
                      />
                      <Spacer size="16" />
                      <Row style={{ flex: 1 }}>
                        <Column style={{ flex: 1 }}>
                          <InputWithLabel label={t('validFrom')}>
                            <TouchableOpacity onPress={toggleDatePicker}>
                              <TextInput value={startDate} invisibleDisabled={false} />
                            </TouchableOpacity>
                          </InputWithLabel>
                        </Column>
                        <HSpacer left={12} />
                        <Column style={{ flex: 1 }}>
                          <InputWithLabel label={t('availability')}>
                            <Select
                              items={AVAILABILITY_LIST.map((item) => ({
                                label: t(item.label, { count: item.value }),
                                value: item.value,
                              }))}
                              value={availability}
                              onChange={setAvailability}
                            />
                          </InputWithLabel>
                        </Column>
                      </Row>
                    </Column>
                    {membership.membershipTemplate.items.map((item, index) => {
                      if (item.type !== 'Service') {
                        return null;
                      }

                      const priceNumber = ScaledNumber.toNumber(
                        item.service.price.type === 'Fixed'
                          ? item.service.price.fixed.amount
                          : ScaledNumber.fromNumber(0, 2),
                      );
                      const price =
                        priceNumber > 0 ? scaledToString(ScaledNumber.fromNumber(priceNumber, 2)) : t('variablePrice');

                      const actualPriceNumber =
                        ScaledNumber.toNumber(item.total.amount.amount) /
                        (item.quantity.type === 'Unlimited' ? 1 : item.quantity.value);
                      const hasDiscount = priceNumber > actualPriceNumber && item.quantity.type !== 'Unlimited';
                      const discount = hasDiscount
                        ? roundToDecimals(((priceNumber - actualPriceNumber) * 100) / priceNumber)
                        : 0;

                      const isPriceBigger = priceNumber > 0 && priceNumber < actualPriceNumber;

                      return (
                        <React.Fragment key={item.service._id}>
                          <Spacer size="16" />
                          <Column
                            style={{
                              borderWidth: 1,
                              borderRadius: 6,
                              borderColor: colors.GEYSER,
                              paddingVertical: 16,
                              paddingLeft: 16,
                              paddingRight: 8,
                              marginHorizontal: 24,
                            }}
                          >
                            <TouchableOpacity
                              key={index}
                              onPress={() => {
                                setSelectedItem(item);
                              }}
                            >
                              <Row>
                                <Column style={{ flex: 1 }}>
                                  <Title>{item.service.name}</Title>
                                  <SmallBody style={{ color: colors.COMET }}>
                                    {formatDurationInMinutes(item.service.durationInMinutes)}
                                  </SmallBody>
                                </Column>
                                <Column>
                                  <Body>{price}</Body>
                                  {hasDiscount ? (
                                    <SmallBody
                                      style={{
                                        color: colors.RADICAL_RED,
                                        textAlign: 'right',
                                      }}
                                    >
                                      -{discount}%
                                    </SmallBody>
                                  ) : null}
                                </Column>
                                <Column style={{ paddingLeft: 8 }}>
                                  <Icon color={colors.DARK_BLUE} type="next" />
                                </Column>
                              </Row>
                            </TouchableOpacity>
                            <Spacer size="16" />
                            <Line />
                            <Spacer size="16" />
                            <Row>
                              <Column style={{ flex: 1 }}>
                                <InputWithLabel label={t('sessions')}>
                                  <Select
                                    items={SESSION_TYPE.map((item) => ({
                                      label: t(item.label),
                                      value: item.value,
                                    }))}
                                    placeholder={t('membershipDurationSelectPlaceholder')}
                                    value={item.quantity.type}
                                    onChange={(value) => {
                                      if (value === 'Unlimited') {
                                        updateItem({
                                          ...item,
                                          quantity: {
                                            type: 'Unlimited',
                                          },
                                        });
                                      } else {
                                        updateItem({
                                          ...item,
                                          quantity: {
                                            type: 'Limited',
                                            value: 1 as PositiveInt,
                                          },
                                        });
                                      }
                                    }}
                                  />
                                </InputWithLabel>
                              </Column>
                              {item.quantity.type === 'Limited' && (
                                <>
                                  <HSpacer left={16} />
                                  <Column style={{ flex: 1 }}>
                                    <Body style={{ fontFamily: 'open-sans-semibold' }}>{t('quantity')}</Body>
                                    <Spacer size={8} />
                                    <Row
                                      style={{
                                        padding: 8,
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        borderColor: colors.GEYSER,
                                        alignItems: 'center',
                                        width: '100%',
                                      }}
                                    >
                                      <TouchableOpacity
                                        style={{
                                          width: 24,
                                          height: 24,
                                          borderRadius: 12,
                                          backgroundColor: colors.SKY_BLUE,
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                          if (item.quantity.type === 'Unlimited' || item.quantity.value < 2) {
                                            return;
                                          }
                                          updateItem({
                                            ...item,
                                            quantity: {
                                              type: 'Limited' as const,
                                              value: (item.quantity.value - 1) as PositiveInt,
                                            },
                                          });
                                        }}
                                      >
                                        <MinusIcon />
                                      </TouchableOpacity>
                                      <Column style={{ flex: 1, paddingHorizontal: 4 }}>
                                        <TextInputWithInternalState
                                          style={{
                                            flex: 1,
                                            textAlign: 'center',
                                            fontSize: 16,
                                            lineHeight: 22,
                                            fontFamily: 'open-sans',
                                          }}
                                          value={item.quantity.value.toString()}
                                          onBlur={(value) => {
                                            if (item.quantity.type !== 'Limited') {
                                              return;
                                            }
                                            pipe(value, numberValidator(item.quantity.value.toString()), (v) => {
                                              const value = (isNaN(parseInt(v)) ? 1 : parseInt(v)) as PositiveInt;
                                              updateItem({
                                                ...item,
                                                quantity: {
                                                  type: 'Limited',
                                                  value,
                                                },
                                              });
                                            });
                                          }}
                                          keyboardType={'numeric'}
                                        />
                                      </Column>
                                      <TouchableOpacity
                                        style={{
                                          width: 24,
                                          height: 24,
                                          borderRadius: 12,
                                          backgroundColor: colors.SKY_BLUE,
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                          if (item.quantity.type === 'Unlimited') {
                                            return;
                                          }
                                          updateItem({
                                            ...item,
                                            quantity: {
                                              type: 'Limited',
                                              value: (item.quantity.value + 1) as PositiveInt,
                                            },
                                          });
                                        }}
                                      >
                                        <PlusIcon />
                                      </TouchableOpacity>
                                    </Row>
                                  </Column>
                                </>
                              )}
                            </Row>

                            <Spacer size="16" />
                            <Row style={{ flex: 1 }}>
                              <Column style={{ flex: 1 }}>
                                <InputWithLabel
                                  label={
                                    item.quantity.type === 'Limited'
                                      ? t('priceSale', { count: item.quantity.value })
                                      : t('priceSaleUnlimited')
                                  }
                                >
                                  <TextInputWithInternalState
                                    style={{
                                      padding: 8,
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      borderColor: colors.GEYSER,
                                      justifyContent: 'center',
                                      height: 42,
                                    }}
                                    value={scaledToString(item.total.amount.amount)}
                                    onFocus={() =>
                                      stripLocalThousandsSeparators(
                                        ScaledNumber.toNumber(item.total.amount.amount).toLocaleString(),
                                      )
                                    }
                                    onBlur={flow(
                                      numberValidator(ScaledNumber.toNumber(item.total.amount.amount).toString()),
                                      (v) => {
                                        const value = ScaledNumber.fromNumber(parseFloat(v), 2);
                                        updateItem({
                                          ...item,
                                          total: {
                                            ...item.total,
                                            amount: {
                                              ...item.total.amount,
                                              amount: value,
                                            },
                                          },
                                        });
                                      },
                                    )}
                                    keyboardType={'numeric'}
                                  />
                                </InputWithLabel>
                                {item.quantity.type === 'Limited' &&
                                  !(showErrors && isPriceBigger) &&
                                  priceNumber > 0 && (
                                    <>
                                      <Spacer size={8} />
                                      <SmallBody style={{ color: colors.COMET }}>
                                        {t('priceSaleInitial', {
                                          count: item.quantity.value,
                                          sum: priceNumber * item.quantity.value,
                                        })}
                                      </SmallBody>
                                    </>
                                  )}
                                {showErrors && isPriceBigger && (
                                  <>
                                    <Spacer size={8} />
                                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('errorPriceTooBig')}</SmallBody>
                                  </>
                                )}
                              </Column>
                            </Row>
                          </Column>
                        </React.Fragment>
                      );
                    })}

                    {membership.membershipTemplate.items.length > 0 && (
                      <>
                        <Spacer size={24} />
                        <Column style={{ paddingHorizontal: 24 }}>
                          <Button
                            text={t('addItems')}
                            color={colors.DARK_BLUE}
                            backgroundColor={colors.SKY_BLUE}
                            onPress={toggleSelectService}
                          />
                        </Column>
                        <Spacer size={24} />
                        <Column style={{ height: 16, backgroundColor: colors.ALABASTER }} />
                        <Spacer size={24} />
                        {showEditDiscount || membership.membershipTemplate.discount ? (
                          <Column style={{ paddingHorizontal: 24 }}>
                            <DiscountComponent
                              price={maxPayValue + generalDiscount}
                              value={value}
                              percentage={percentage}
                              onUpdate={onUpdateDiscount}
                              type={type}
                            />
                          </Column>
                        ) : (
                          <TouchableOpacity
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              paddingRight: 32,
                              paddingHorizontal: 24,
                            }}
                            onPress={() => {
                              setShowEditDiscount(true);
                            }}
                          >
                            <PercentageIcon />
                            <SmallBody
                              style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE, paddingLeft: 8 }}
                            >
                              {t('addDiscount')}
                            </SmallBody>
                          </TouchableOpacity>
                        )}
                        <Spacer size={24} />
                        <>
                          <Column style={{ paddingHorizontal: 24 }}>
                            <Row>
                              <SmallBody>{t('totalPrice')}</SmallBody>
                              <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                                {scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')}
                              </SmallBody>
                            </Row>
                            {hasDiscount || itemsDiscount > 0 ? (
                              <Row style={{ paddingTop: 12 }}>
                                <SmallBody>{t('discount')}</SmallBody>
                                <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                                  -{scaledToString(ScaledNumber.fromNumber(generalDiscount + itemsDiscount, 2))}{' '}
                                  {t('RON')}
                                </SmallBody>
                              </Row>
                            ) : null}
                            <Spacer size={16} />
                            <Line />
                            <Spacer size={16} />
                            <Row>
                              <Body>{t('total')}</Body>
                              <Body style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                                {scaledToString(ScaledNumber.fromNumber(maxPayValue, 2))} {t('RON')}
                              </Body>
                            </Row>
                          </Column>
                        </>
                        <Spacer size={48} />
                        <Column style={{ paddingHorizontal: 24, alignItems: 'center' }}>
                          <Title>{t('total')}</Title>
                          <Spacer size={8} />
                          <H1 style={{ fontSize: 32 }}>
                            {' '}
                            {scaledToString(ScaledNumber.fromNumber(paymentValue ?? maxPayValue, 2))} {t('RON')}
                          </H1>
                          {paymentValue !== maxPayValue ? (
                            <>
                              <Spacer size={16} />
                              <Row>
                                <Body style={meroStyles.text.semibold}>
                                  {t('totalMembershipPrice', { value: maxPayValue, currency: t('RON') })}
                                </Body>
                                <TouchableOpacity
                                  onPress={() => {
                                    explicitPartialPayment.current = false;
                                    setPaymentValue(maxPayValue);
                                  }}
                                >
                                  <Body style={[{ color: colors.DARK_BLUE, paddingLeft: 8 }, meroStyles.text.semibold]}>
                                    {t('totalPaymentText')}
                                  </Body>
                                </TouchableOpacity>
                              </Row>
                            </>
                          ) : (
                            <Button
                              backgroundColor={colors.WHITE}
                              color={colors.DARK_BLUE}
                              text={t('partialPaymentText')}
                              onPress={togglePartialPayment}
                            />
                          )}
                        </Column>
                        {params.uiKey && (
                          <>
                            <Spacer size={32} />
                            <Button
                              backgroundColor={colors.WHITE}
                              color={colors.RADICAL_RED}
                              text={t('removeMembership')}
                              onPress={() => {
                                removeCheckoutItem(membership);
                                goBack();
                              }}
                            />
                          </>
                        )}
                        <Spacer size={64} />
                        <Spacer size={96} />
                      </>
                    )}

                    {membership.membershipTemplate.items.length === 0 && (
                      <>
                        <Spacer size={16} />
                        <Column style={{ paddingHorizontal: 24 }}>
                          <Body style={{ textAlign: 'center', color: '#2A2E43' }}>{t('noItems')}</Body>
                          <Spacer size={16} />
                          <Button
                            text={t('addItems')}
                            color={colors.DARK_BLUE}
                            backgroundColor={colors.SKY_BLUE}
                            onPress={toggleSelectService}
                          />
                        </Column>
                      </>
                    )}
                  </ScrollView>
                </KeyboardAvoidingView>
              </DismissKeyboard>
            </Column>
            <FormCard
              dropShaddow
              paddings="button"
              style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
            >
              <SafeAreaView edges={['bottom']}>
                {isPhone ? <Button text={t('save')} onPress={save} /> : <Button text={t('save')} onPress={save} />}
              </SafeAreaView>
            </FormCard>
            {selectedItem ? (
              <ItemMenu
                onSelect={flow(
                  () => removeItem(selectedItem),
                  () => setSelectedItem(undefined),
                )}
                onDismiss={() => setSelectedItem(undefined)}
              />
            ) : null}
          </>
        ) : null}
      </ModalScreenContainer>
      {showDatePicker && (
        <SelectDateTimeModal
          timeZone={timezone}
          onClosePress={toggleDatePicker}
          onDateSelected={flow(updateDate, toggleDatePicker)}
          mode="date"
        />
      )}
      {showPartialPayment && (
        <MembershipPartialPayment
          allowZero
          totalPrice={maxPayValue}
          partialPayment={paymentValue ?? maxPayValue}
          onCancel={togglePartialPayment}
          onSave={flow(setPaymentValue, togglePartialPayment)}
        />
      )}
      {showSelectService && (
        <MembershipSelectService
          selectedItemIds={selectedItemIds}
          onCancel={toggleSelectService}
          onSelect={flow(addItem, toggleSelectService)}
        />
      )}
    </>
  );
};

export default pipe(MembershipItemsScreen, CurrentBusiness, Authorized);

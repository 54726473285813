import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import MembershipDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageMembershipSettingsScreen/MembershipDetailsScreen';
import MembershipItemsScreen from '../screens/Authorized/MenuScreen/screens/PageMembershipSettingsScreen/MembershipItemsScreen';
import MembershipSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageMembershipSettingsScreen/MembershipSettingsScreen';
import SelectMembershipServiceScreen from '../screens/Authorized/MenuScreen/screens/PageMembershipSettingsScreen/SelectMembershipServiceScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withMembershipTemplateContextProvider } from '../contexts/MembershipTemplateContext';
import { MembershipSettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<MembershipSettingsStackParamList>();

function MembershipSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('membership');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="MembershipSettings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="MembershipSettings"
        component={MembershipSettingsScreen}
        options={{ title: t('membershipSettings'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="MembershipDetails"
        component={MembershipDetailsScreen}
        options={{ title: t('membershipDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="MembershipItems"
        component={MembershipItemsScreen}
        options={{ title: t('membershipItems'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="SelectMembershipService"
        component={SelectMembershipServiceScreen}
        options={{ title: t('selectService'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default pipe(MembershipSettingsStackNavigator, withMembershipTemplateContextProvider);

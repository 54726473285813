import { OptionalPhoneNumber } from '@mero/api-sdk';
import { UserId } from '@mero/api-sdk/dist/users';
import {
  AvoidKeyboard,
  Body,
  colors,
  Column,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  Row,
  SimpleListItem,
  SmallBody,
  Spacer,
  TextInput,
  useShowError,
} from '@mero/components';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, TouchableOpacity, TextInput as TextInputRN, useWindowDimensions } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import HSpacer from '@mero/components/lib/components/HSpacer';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  userId: UserId;
};

const ClaimDialog: React.FC<Props> = ({ onSuccess, onCancel, userId }) => {
  const { t } = useTranslation('marketing');
  const { width } = useWindowDimensions();

  const [pageState] = CurrentBusinessContext.useContext();

  const showError = useShowError();

  const [inProgress, setInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  });
  const [otherReason, setOtherReason] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [showPhoneError, setShowPhoneError] = React.useState(false);

  const cancel = () => {
    onCancel();
  };

  const getReason = () => {
    if (checks.check1) {
      return t('claimDialogText1');
    }

    if (checks.check2) {
      return t('claimDialogText2');
    }

    if (checks.check3) {
      return t('claimDialogText3');
    }

    return otherReason;
  };

  const action = async () => {
    const phoneInfo = parsePhoneNumber(phoneNumber, { regionCode: phoneNumber.length <= 10 ? 'RO' : undefined });
    if (checks.check2) {
      setShowPhoneError(true);
    }
    if (pageState.type !== 'Loaded' || (checks.check2 && !phoneInfo.valid)) {
      return;
    }
    try {
      setInProgress(true);
      const reason = getReason();

      await meroApi.clients.requestBoostClientClaim({
        pageId: pageState.page.details._id,
        userId,
        reason,
        ...(checks.check2 && { userPhoneNumber: phoneNumber as OptionalPhoneNumber }),
      });
      onSuccess();
    } catch (error) {
      log.error('Failed to claim client', error);
      onCancel();
      showError(error, t('claimFailed'));
    } finally {
      setInProgress(false);
    }
  };

  const leftAction = {
    text: t('cancelClaim'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: t('sendClaim'),
    onPress: Object.values(checks).filter((v) => v).length !== 1 || inProgress ? undefined : action,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      [checkName]: !checks[checkName],
    });
  };

  const isValidPhone = React.useMemo(
    () =>
      !showPhoneError ||
      parsePhoneNumber(phoneNumber, { regionCode: phoneNumber.length <= 10 ? 'RO' : undefined }).valid,
    [showPhoneError, phoneNumber],
  );

  return (
    <Modal animationType="none" transparent={true} visible={true}>
      <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
        <AvoidKeyboard style={{ width: width > 500 ? 500 : width }}>
          <MobileWebModalWrapper position="center">
            <ConfirmBox
              type="info"
              headerTitle={t('claimHeader')}
              icon="info"
              canClose={!inProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
            >
              <H1>{t('claimDialogTitle')}</H1>
              <Spacer size="24" />
              <TouchableOpacity onPress={toggleCheck('check1')} disabled={inProgress}>
                <SimpleListItem
                  icon={checks.check1 ? 'checked' : 'unchecked'}
                  iconColor={inProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
                  paddingTop={0}
                  paddingBottom={0}
                >
                  <Body>{t('claimDialogText1')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
              <TouchableOpacity onPress={toggleCheck('check2')} disabled={inProgress}>
                <SimpleListItem
                  icon={checks.check2 ? 'checked' : 'unchecked'}
                  iconColor={inProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
                  paddingTop={0}
                  paddingBottom={0}
                >
                  <Body>{t('claimDialogText2')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              {checks.check2 && (
                <>
                  <Spacer size={8} />
                  <Row>
                    <HSpacer left={40} />
                    <TextInput
                      styles={{
                        container: {
                          flex: 1,
                          width: undefined,
                        },
                      }}
                      isError={!isValidPhone && showPhoneError}
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      keyboardType="phone-pad"
                      placeholder={t('phonePlaceholder')}
                    />
                  </Row>
                  {!isValidPhone && (
                    <Column style={{ marginLeft: 40 }}>
                      <Spacer size={4} />
                      <SmallBody style={{ color: colors.RADICAL_RED }}>{t('claimDialogText2Error')}</SmallBody>
                    </Column>
                  )}
                  <Spacer size={8} />
                </>
              )}
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
              <TouchableOpacity onPress={toggleCheck('check3')} disabled={inProgress}>
                <SimpleListItem
                  icon={checks.check3 ? 'checked' : 'unchecked'}
                  iconColor={inProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
                  paddingTop={0}
                  paddingBottom={0}
                >
                  <Body>{t('claimDialogText3')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
              <TouchableOpacity onPress={toggleCheck('check4')} disabled={inProgress}>
                <SimpleListItem
                  icon={checks.check4 ? 'checked' : 'unchecked'}
                  iconColor={inProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
                  paddingTop={0}
                  paddingBottom={0}
                >
                  <Body>{t('claimDialogText4')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              {checks.check4 && (
                <>
                  <Spacer size={8} />
                  <TextInputRN
                    style={{
                      borderWidth: 1,
                      borderColor: colors.ATHENS_GRAY,
                      padding: 8,
                      borderRadius: 4,
                      height: 100,
                      width: '100%',
                      textAlignVertical: 'top',
                    }}
                    numberOfLines={3}
                    multiline
                    value={otherReason}
                    onChangeText={setOtherReason}
                  />
                  <Spacer size={8} />
                </>
              )}
            </ConfirmBox>
          </MobileWebModalWrapper>
        </AvoidKeyboard>
      </ModalOverlay>
    </Modal>
  );
};

export default ClaimDialog;

import { Price } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import { SavedService } from '@mero/api-sdk/dist/services/saved-service';
import {
  Text,
  Column,
  H1,
  H3s,
  Icon,
  MeroHeader,
  Row,
  SearchTextInput,
  SimpleListItem,
  Spacer,
  Title,
  Line,
  Button,
  colors,
  H2s,
  Body,
  DismissKeyboard,
  ModalOverlay,
  SafeAreaView,
  styles as meroStyles,
} from '@mero/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { debounce } from 'throttle-debounce';

import { buildServiceSearchIndex } from '../MenuScreen/screens/ProsDashboardScreen/AddProServicesScreen';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import TextSkeleton from '@mero/components/lib/components/TextSkeleton';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  RootStackParamList,
  ServicesDashboardStackParamList,
} from '../../../types';
import AddCategoryDialog from './AddCategoryDialog';
import AddServiceMenu from './AddServiceMenu';
import { styles } from './AddServiceMenu.styles';
import CategoryMenu from './CategoryMenu';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import EditCategoryDialog from './EditCategoryDialog';
import { SERVICE_CHANGED } from './ServiceScreen';

export const formatDuration = (duration: number) => {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours} h ${minutes} min`;
  }

  return `${duration} min`;
};

export const getPrice = (price: Price, discounts = 0): string => {
  switch (price.type) {
    case 'range': {
      return `${price.range.from} - ${price.range.to} lei`;
    }
    case 'fixed': {
      const priceValue = (price.promo ?? price.fixed) - discounts;
      return `${priceValue > 0 ? priceValue : 0} lei`;
    }
    case 'hidden': {
      return `preț ascuns`;
    }
    default: {
      return '';
    }
  }
};

type Props = {
  readonly navigation: CompositeNavigationProp<
    StackNavigationProp<ServicesDashboardStackParamList, 'ServicesMobile'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'MenuTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const ServicesMobileScreen: React.FC<Props> = ({ navigation }) => {
  const { isPhone } = useMediaQueries();
  const isFocused = useIsFocused();
  const { t } = useTranslation('services');

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();
  const [{ services }, { reload, changeEditInfo, resetEditInfo }] = ServicesContext.useContext();

  const servicesListRef = React.useRef<FlatList<GroupWithServices>>(null);

  const [isScreenReady, setIsScreenReady] = React.useState(false);
  const [showAddServiceMenu, setShowAddServiceMenu] = React.useState(false);
  const [showCategoryMenu, setShowCategoryMenu] = React.useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = React.useState(false);
  const [showEditCategory, setShowEditCategory] = React.useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);

  const groupedWithOthers = React.useMemo(
    () => [
      ...services.grouped,
      ...(services.others.length === 0
        ? []
        : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: services.others }]),
    ],
    [services],
  );

  const [foundServices, setFoundServices] = React.useState(groupedWithOthers);

  const [query, setQuery] = React.useState<string>('');
  const queryIsEmpty = query.trim() === '';

  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const [showAddCategory, setShowAddCategory] = React.useState(false);

  const [activeCategory, setActiveCategory] = React.useState<ServiceGroup | undefined>();

  const index = React.useMemo(() => buildServiceSearchIndex(groupedWithOthers), [groupedWithOthers]);

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery, index]);

  /**
   * Effect used to handle the loading screen skeleton.
   */
  const isFirstRunRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    if (isFirstRunRef.current === false) {
      setIsScreenReady(true);
    }
    isFirstRunRef.current = false;
  }, [services]);

  React.useEffect(() => {
    return () => {
      if (pageState.type === 'Loaded') {
        getPageWorkersAndServices(pageState.page.details._id);
      }
    };
  }, []);

  const cancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const addNewCategory = () => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
    setShowAddCategory(false);
  };

  const cancelDeleteCategory = () => {
    setShowDeleteCategory(false);
  };

  const deleteCategory = () => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
    setShowDeleteCategory(false);
  };

  const cancelEditCategory = () => {
    setShowEditCategory(false);
  };

  const editCategory = () => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
    setShowEditCategory(false);
  };

  const servicesCounter = React.useMemo(() => {
    return foundServices.reduce((acc, group) => acc + group.services.length, 0);
  }, [foundServices]);

  const addServiceMenuCallback = React.useCallback(() => {
    setShowAddServiceMenu(!showAddServiceMenu);
  }, [showAddServiceMenu]);

  const showCategoryMenuCallback = React.useCallback(() => {
    setShowCategoryMenu(!showCategoryMenu);
  }, [showCategoryMenu]);

  const goToSortingCallback = React.useCallback(() => {
    navigation.navigate('SortServices');
  }, []);

  const navigateMenu = React.useCallback(() => {
    navigation.navigate('MenuTab', { screen: 'MenuScreen' });
  }, [navigation]);

  const goToBulkOperationsCallback = React.useCallback(() => {
    navigation.navigate('ServicesBulkOperations', { workerId: undefined });
  }, []);

  const editService = (service: SavedService) => () => {
    changeEditInfo({
      workers: [],
      private: service.private,
      automaticApproval: service.automaticApproval,
    });
    navigation.navigate('Service', { serviceId: service._id });
  };

  const checkServiceListChanged = async () => {
    const serviceChanged = await AsyncStorage.getItem(SERVICE_CHANGED);

    if (serviceChanged === 'true') {
      await AsyncStorage.removeItem(SERVICE_CHANGED);
      servicesListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      reload({ pageId: pageState.page.details._id });
    }

    if (!isFocused) {
      setShowAddServiceMenu(false);
      setShowCategoryMenu(false);
    }

    checkServiceListChanged();
  }, [isFocused, pageState.type]);

  const isMobileWeb = isPhone && Platform.OS === 'web';

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader
        canGoBack
        onBack={navigateMenu}
        TitleComponent={() => <Body>Servicii</Body>}
        titleComponentStyle={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 60,
        }}
        RightComponent={
          <Button
            onPress={() => setShowOptionsMenu(true)}
            expand={false}
            text={t('options')}
            backgroundColor={colors.ALABASTER}
            color={colors.DARK_BLUE}
          />
        }
      />

      {isScreenReady ? (
        <>
          {servicesCounter > 0 || query ? (
            <DismissKeyboard style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 8 }}>Servicii</H1>
              <Spacer size={16} />
              <SearchTextInput
                placeholder={'Caută după numele serviciului'}
                value={query}
                // autoFocus={autoFocus}
                onChange={setQuery}
              />
              <Spacer size={16} />
              <ScrollView showsVerticalScrollIndicator={false}>
                {servicesCounter > 0 ? (
                  <>
                    <Row style={{ alignItems: 'center' }}>
                      <Title style={{ color: '#32325D', fontSize: 17, flex: 1 }}>
                        {servicesCounter} {servicesCounter > 1 ? 'Servicii' : 'Serviciu'}
                      </Title>
                      <Column>
                        <TouchableOpacity
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 44,
                            backgroundColor: colors.WHITE,
                            borderRadius: 25,
                            shadowColor: colors.BLACK,
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.15,
                            shadowRadius: 3.84,
                            elevation: 3,
                          }}
                          onPress={addServiceMenuCallback}
                        >
                          <Title style={{ color: colors.DARK_BLUE, paddingLeft: 24, paddingRight: 16 }}>Adaugă</Title>
                          <Column style={{ paddingRight: 12 }}>
                            <Icon type="dropdown" color={colors.DARK_BLUE} />
                          </Column>
                        </TouchableOpacity>
                        <Column style={{ position: 'relative' }}>
                          {showAddServiceMenu && isMobileWeb && (
                            <Column
                              style={{
                                position: 'absolute',
                                right: 0,
                                top: 12,
                                minWidth: 300,
                                backgroundColor: colors.WHITE,
                                borderRadius: 12,
                                borderWidth: 1,
                                borderColor: 'rgb(233, 236, 239)',
                                shadowColor: colors.BLACK,
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.15,
                                shadowRadius: 3.84,
                                elevation: 3,
                              }}
                            >
                              <TouchableOpacity
                                style={styles.optionsMenuItem}
                                delayPressIn={0}
                                onPress={flow(
                                  () => {
                                    resetEditInfo();
                                    navigation.navigate('Service', { serviceId: undefined });
                                  },
                                  () => setShowAddServiceMenu(false),
                                )}
                              >
                                <Title>Adaugă serviciu</Title>
                              </TouchableOpacity>
                              <Line />
                              <TouchableOpacity
                                style={styles.optionsMenuItem}
                                delayPressIn={0}
                                onPress={flow(
                                  () => setShowAddCategory(true),
                                  () => setShowAddServiceMenu(false),
                                )}
                              >
                                <Title>Adaugă categorie</Title>
                              </TouchableOpacity>
                            </Column>
                          )}
                        </Column>
                      </Column>
                    </Row>
                    <Column
                      style={[
                        { flex: 1, paddingVertical: 24, backgroundColor: '#fff', marginTop: 16, borderRadius: 4 },
                        isMobileWeb && { zIndex: -1 },
                      ]}
                    >
                      <Column>
                        {foundServices.map((groupServices, index) => (
                          <React.Fragment key={groupServices.group._id}>
                            <Column
                              style={{
                                zIndex:
                                  showCategoryMenu && isMobileWeb && activeCategory?._id === groupServices.group._id
                                    ? 1
                                    : -1,
                              }}
                            >
                              <Row style={{ paddingHorizontal: 16 }}>
                                <H3s style={{ flex: 1 }}>{groupServices.group.name}</H3s>
                                {groupServices.group._id !== '1' && (
                                  <TouchableOpacity
                                    onPress={flow(
                                      () => groupServices.group,
                                      setActiveCategory,
                                      showCategoryMenuCallback,
                                    )}
                                  >
                                    <Icon type="options-dots" />
                                  </TouchableOpacity>
                                )}
                              </Row>
                              <Column style={{ position: 'relative', zIndex: 1 }}>
                                {showCategoryMenu && isMobileWeb && activeCategory?._id === groupServices.group._id && (
                                  <Column
                                    style={{
                                      position: 'absolute',
                                      right: 0,
                                      top: 12,
                                      minWidth: 300,
                                      backgroundColor: colors.WHITE,
                                      borderRadius: 12,
                                      borderWidth: 1,
                                      borderColor: 'rgb(233, 236, 239)',
                                      shadowColor: colors.BLACK,
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.15,
                                      shadowRadius: 3.84,
                                      elevation: 3,
                                    }}
                                  >
                                    <TouchableOpacity
                                      style={styles.optionsMenuItem}
                                      delayPressIn={0}
                                      onPress={flow(
                                        () => setShowDeleteCategory(true),
                                        () => setShowCategoryMenu(false),
                                      )}
                                    >
                                      <Title style={{ color: colors.RADICAL_RED }}>Șterge categorie</Title>{' '}
                                    </TouchableOpacity>
                                    <Line />
                                    <TouchableOpacity
                                      style={styles.optionsMenuItem}
                                      delayPressIn={0}
                                      onPress={flow(
                                        () => setShowEditCategory(true),
                                        () => setShowCategoryMenu(false),
                                      )}
                                    >
                                      <Title>Modifică nume categorie</Title>
                                    </TouchableOpacity>
                                  </Column>
                                )}
                              </Column>

                              {groupServices.services.length ? (
                                groupServices.services.map((service, index) => (
                                  <TouchableOpacity
                                    key={service._id}
                                    style={{
                                      borderBottomWidth: index < groupServices.services.length - 1 ? 1 : 0,
                                      borderBottomColor: '#E9ECEF',
                                      marginHorizontal: 16,
                                      paddingVertical: 16,
                                    }}
                                    onPress={editService(service)}
                                  >
                                    <SimpleListItem
                                      style={{ borderLeftWidth: 2, borderLeftColor: service.color, paddingLeft: 8 }}
                                      paddingTop={0}
                                      paddingBottom={0}
                                      title={service.name}
                                      subtitle={
                                        <Text smallBody>
                                          {formatDuration(service.durationInMinutes)},{' '}
                                          {service.price.type === 'fixed' && service.price?.promo ? (
                                            <>
                                              <Text smallBody>
                                                {service.price.promo} lei{' '}
                                                <Text
                                                  smallBody
                                                  style={{
                                                    textDecorationLine: 'line-through',
                                                    textDecorationStyle: 'solid',
                                                  }}
                                                >
                                                  ({service.price.fixed} lei)
                                                </Text>
                                              </Text>
                                            </>
                                          ) : (
                                            <Text smallBody>{getPrice(service.price)}</Text>
                                          )}
                                        </Text>
                                      }
                                      IconComponent={() => <Icon type="arrow-right" />}
                                      iconPosition="right"
                                    />
                                  </TouchableOpacity>
                                ))
                              ) : (
                                <Spacer size={24} />
                              )}
                            </Column>
                            {index < foundServices.length - 1 && (
                              <Column style={{ zIndex: -1 }}>
                                <Line />
                                <Spacer size={24} />
                              </Column>
                            )}
                          </React.Fragment>
                        ))}
                      </Column>
                    </Column>
                  </>
                ) : (
                  <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
                    <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <H2s>Niciun rezultat pt. &quot;{query}&quot;</H2s>
                      <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                        Verifică ca termenul de căutare să fie{'\n'}corect și încearcă din nou
                      </Body>
                    </Column>
                  </Column>
                )}
              </ScrollView>
            </DismissKeyboard>
          ) : (
            <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 8 }}>Servicii</H1>
              <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <H2s>Nu există servicii</H2s>
                <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                  Oferă clienților tăi posibilitatea să se programeze la tine 24/7. Creează lista ta de servicii și
                  prețuri.
                </Body>
                <Column style={{ paddingTop: 24 }}>
                  <Button
                    backgroundColor={colors.ALABASTER}
                    color={colors.DARK_BLUE}
                    size="medium"
                    withShadow
                    expand={false}
                    text="+ Adaugă serviciu"
                    padding={36}
                    onClick={() => {
                      resetEditInfo();
                      navigation.navigate('Service', { serviceId: undefined });
                    }}
                  />
                </Column>
              </Column>
            </Column>
          )}
        </>
      ) : (
        <>
          <LoadingScreenSkeleton />
        </>
      )}

      {showAddServiceMenu && !isMobileWeb && (
        <AddServiceMenu
          onDismiss={() => setShowAddServiceMenu(false)}
          onAddService={() => {
            resetEditInfo();
            navigation.navigate('Service', { serviceId: undefined });
          }}
          onAddCategory={() => setShowAddCategory(true)}
        />
      )}

      {showCategoryMenu && !isMobileWeb && (
        <CategoryMenu
          onDismiss={() => setShowCategoryMenu(false)}
          onDeleteCategory={() => setShowDeleteCategory(true)}
          onEditCategory={() => setShowEditCategory(true)}
        />
      )}

      {showAddCategory ? <AddCategoryDialog onSuccess={addNewCategory} onCancel={cancelAddCategory} /> : null}

      {showDeleteCategory && activeCategory ? (
        <DeleteCategoryDialog category={activeCategory} onSuccess={deleteCategory} onCancel={cancelDeleteCategory} />
      ) : null}

      {showEditCategory && activeCategory ? (
        <EditCategoryDialog category={activeCategory} onSuccess={editCategory} onCancel={cancelEditCategory} />
      ) : null}

      {showOptionsMenu && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={() => {
                  setShowOptionsMenu(false);
                  goToBulkOperationsCallback();
                }}
              >
                <Body style={meroStyles.text.semibold}>{t('servicesMultipleActions')}</Body>
              </TouchableOpacity>

              <Line />

              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={goToSortingCallback}>
                <Body style={meroStyles.text.semibold}>{t('sortServices')}</Body>
              </TouchableOpacity>
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}
    </ModalScreenContainer>
  );
};

export default ServicesMobileScreen;

/**
 * Component used to display a skeleton loading
 * screen while services are being fetched.
 */
const LoadingScreenSkeleton = () => {
  return (
    <View style={{ padding: 16 }}>
      <H1 style={{ paddingHorizontal: 8 }}>Servicii</H1>
      <Spacer size={16} />
      <SearchTextInput placeholder={'Caută după numele serviciului'} editable={false} />

      <View style={{ flex: 1, paddingVertical: 24, backgroundColor: '#fff', marginTop: 16, borderRadius: 4 }}>
        <Row style={{ paddingHorizontal: 16 }}>
          <TextSkeleton height="h1" width="fill"></TextSkeleton>
        </Row>
        <View
          style={{
            marginHorizontal: 16,
          }}
        >
          <View
            style={{
              borderLeftWidth: 2,
              borderLeftColor: colors.ATHENS_GRAY,
              paddingLeft: 8,
              marginVertical: 16,
            }}
          >
            <TextSkeleton height="h2" width="long"></TextSkeleton>
            <TextSkeleton height="body" width="short"></TextSkeleton>
          </View>
          <View
            style={{
              borderLeftWidth: 2,
              borderLeftColor: colors.ATHENS_GRAY,
              paddingLeft: 8,
              marginVertical: 16,
            }}
          >
            <TextSkeleton height="h2" width="long"></TextSkeleton>
            <TextSkeleton height="body" width="short"></TextSkeleton>
          </View>
          <View
            style={{
              borderLeftWidth: 2,
              borderLeftColor: colors.ATHENS_GRAY,
              paddingLeft: 8,
              marginVertical: 16,
            }}
          >
            <TextSkeleton height="h2" width="long"></TextSkeleton>
            <TextSkeleton height="body" width="short"></TextSkeleton>
          </View>
        </View>
      </View>
    </View>
  );
};

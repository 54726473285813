import { useToast } from '@mero/components';
import * as React from 'react';

import ImportContactsScreenView from '../../../components/ImportContactsScreenView';

import { CompositeNavigationProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { ImportContactsContext } from '../../../contexts/ImportContactsContext';
import { AuthorizedStackParamList, RootStackParamList } from '../../../types';

type ImportContactsScreenProps = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'ImportContactsScreen'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

const ImportContactsScreenComponent: React.FC<ImportContactsScreenProps> = ({
  page,
  navigation,
}: ImportContactsScreenProps) => {
  const [
    state,
    { init, tryAskPermissions, trySelectAt, tryDeselectAt, trySelectAll, tryDeselectAll, trySearch, tryImportSelected },
  ] = ImportContactsContext.useContext();
  const toast = useToast();
  const [, { pushEvent }] = AppEventsContext.useContext();

  React.useEffect(() => init(page.details._id), [init]);

  const goClientsScreen = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'ClientsTab',
        params: {
          screen: 'ClientsScreen',
        },
      },
    });
  }, [navigation]);

  const goBack = useGoBack(goClientsScreen);

  const askContactsPermissionCallback = React.useCallback(() => {
    tryAskPermissions();
  }, [tryAskPermissions]);

  const importCallback = React.useCallback(() => {
    tryImportSelected();
  }, [tryImportSelected]);

  React.useEffect(() => {
    if (state.type === 'ImportSucceed') {
      pushEvent({ type: 'PageClientsImported', pageId: page.details._id });
      toast.show({
        type: 'success',
        text: `${state.importedCnt} contacte vor fi importate. Vei primi o notificare cand este gata.`,
      });
      goBack();
    }
  }, [goBack, toast, state, page.details._id]);

  return (
    <ImportContactsScreenView
      onClose={goBack}
      state={state}
      selectAt={trySelectAt}
      deselectAt={tryDeselectAt}
      selectAll={trySelectAll}
      search={trySearch}
      deselectAll={tryDeselectAll}
      onAskContactsPermission={askContactsPermissionCallback}
      onImport={importCallback}
      onGoBack={goBack}
    />
  );
};

const ImportContactsScreen: React.FC<ImportContactsScreenProps> = (props: ImportContactsScreenProps) => {
  return (
    <ImportContactsContext.Provider>
      <ImportContactsScreenComponent {...props} />
    </ImportContactsContext.Provider>
  );
};

export default CurrentBusiness(ImportContactsScreen);

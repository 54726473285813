import { MembershipTemplate } from '@mero/api-sdk/dist/memberships/membershipTemplate';
import {
  Body,
  Button,
  colors,
  Column,
  H1,
  H2s,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  SearchTextInput,
  SmallBody,
  Spacer,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { fold } from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList, CheckoutSubStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutSubStackParamList & RootStackParamList & AuthorizedStackParamList, 'SelectMembershipScreen'>;
const SelectMembershipScreen: React.FC<Props> = ({ page, navigation }: Props) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [, { setItem }] = CheckoutFormContext.useContext();

  const [noData, setNoData] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [memberships, setMemberships] = React.useState<MembershipTemplate<MeroUnits.Any>[]>([]);
  const [nextPage, setNextPage] = React.useState<string | undefined>('');
  const loadingNextPage = React.useRef('');

  const getMemberships = async (query?: DefinedString, next?: string) => {
    try {
      loadingNextPage.current = next ?? '';
      const { data: loadedMemberships, next: nextPage } = await meroApi.memberships.getMembershipTemplatesForPage({
        pageId: page.details._id,
        name: query,
        status: 'Active',
        limit: 5,
        page: next,
      });

      const newMemberships = next ? [...memberships, ...loadedMemberships] : loadedMemberships;

      if (!query) setNoData(newMemberships.length === 0);
      setMemberships(newMemberships);
      setNextPage(nextPage);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const onSearch = flow(
    DefinedString.decode,
    fold(() => getMemberships(), getMemberships),
  );

  const loadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      pipe(
        searchQuery,
        DefinedString.decode,
        fold(
          () => getMemberships(undefined, nextPage),
          (query) => getMemberships(query, nextPage),
        ),
      );
    }
  };

  const addMembership = (membership: MembershipTemplate<MeroUnits.Any>) => {
    navigation.navigate('EditMembershipScreen', { membershipTemplateId: membership._id });
  };

  React.useEffect(() => {
    getMemberships();
  }, [isFocused]);

  React.useEffect(() => {
    const timer = window.setTimeout(() => {
      onSearch(searchQuery);
    }, 500);

    return () => window.clearTimeout(timer);
  }, [searchQuery]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('selectMembershipType')} />
      <Column style={{ paddingTop: 16, flex: 1, height: '100%' }}>
        <InfiniteScrollView
          onEndReached={loadMore}
          withLoading
          style={memberships.length === 0 && isLoaded ? { borderRadius: 6, flex: 1 } : undefined}
          contentContainerStyle={memberships.length === 0 && isLoaded ? { flex: 1 } : undefined}
          wrapperStyle={memberships.length === 0 && isLoaded ? { flex: 1 } : undefined}
        >
          <H1 style={{ paddingHorizontal: 24 }}>{t('selectMembershipType')}</H1>
          {!noData && isLoaded && (
            <Column style={{ paddingHorizontal: 24, zIndex: 1 }}>
              <Spacer size={16} />
              <SearchTextInput placeholder={t('searchPlaceholder')} value={searchQuery} onChange={setSearchQuery} />
              <Spacer size={16} />
            </Column>
          )}
          {memberships.length > 0 && isLoaded && (
            <>
              <Column style={{ zIndex: -1 }}>
                {memberships.map((membership) => {
                  const { totalPrice, withDiscount } = (() => {
                    const totalPrice = membership.items.reduce(
                      (acc, item) =>
                        acc + (item.type === 'Service' ? ScaledNumber.toNumber(item.sellingPrice.amount) : 0),
                      0,
                    );
                    const withDiscount =
                      totalPrice -
                      (membership.discount
                        ? membership.discount.type === 'Percent'
                          ? (ScaledNumber.toNumber(membership.discount.percent) * totalPrice) / 100
                          : ScaledNumber.toNumber(membership.discount.value.amount)
                        : 0);

                    return { totalPrice, withDiscount };
                  })();
                  return (
                    <FormCard
                      key={membership._id}
                      rounded
                      dropShaddow
                      paddings="none"
                      style={{ paddingHorizontal: 16, paddingVertical: 16, marginHorizontal: 16, marginBottom: 16 }}
                    >
                      <TouchableOpacity onPress={() => addMembership(membership)}>
                        <Row alignItems="center">
                          <Column style={{ flex: 1 }}>
                            <H2s>{membership.name}</H2s>
                            <Spacer size={4} />
                            <Label
                              style={{
                                color:
                                  membership.status === 'Active'
                                    ? colors.SHAMROCK
                                    : membership.status === 'Deleted'
                                    ? colors.RADICAL_RED
                                    : colors.COMET,
                              }}
                            >
                              {t(membership.status)}
                            </Label>
                          </Column>
                          <Column>
                            <Icon type="next" color={colors.DARK_BLUE} />
                          </Column>
                        </Row>
                        <Spacer size={16} />
                        <Line />
                        <Spacer size={16} />
                        <Row>
                          <Column style={{ flex: 1 }}>
                            <Title>{t('paymentValue')}</Title>
                            <SmallBody>
                              {membership.validFor.type === 'Unlimited'
                                ? t('unlimitedValidity')
                                : t('validityValue', { count: Math.ceil(membership.validFor.days / 30) })}
                            </SmallBody>
                          </Column>
                          <Column>
                            <Title>
                              {scaledToString(ScaledNumber.fromNumber(withDiscount, 2))} {t('RON')}
                            </Title>
                            {withDiscount !== totalPrice ? (
                              <SmallBody
                                style={{
                                  textDecorationLine: 'line-through',
                                  color: colors.COMET,
                                  textAlign: 'right',
                                }}
                              >
                                {scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')}
                              </SmallBody>
                            ) : null}
                          </Column>
                        </Row>
                      </TouchableOpacity>
                    </FormCard>
                  );
                })}
              </Column>
            </>
          )}

          {memberships.length === 0 && isLoaded && (
            <>
              <Column style={{ paddingHorizontal: 24, flex: 1 }} justifyContent="center" alignItems="center">
                <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 24 }}>
                  <H2s>{noData ? t('noMembership') : t('noMembershipSearch')}</H2s>
                  <Spacer size={8} />
                  <Body style={{ textAlign: 'center' }}>
                    {noData ? t('noMembershipDescription') : t('noMembershipSearchDescription')}
                  </Body>
                  {noData && (
                    <>
                      <Spacer size={32} />
                      <Button
                        text={t('addNewTemplate')}
                        onClick={() =>
                          navigation.navigate('Menu', {
                            screen: 'PageMembershipSettingsScreen',
                            params: {
                              screen: 'MembershipDetails',
                            },
                          })
                        }
                      />
                    </>
                  )}
                </Column>
              </Column>
            </>
          )}
        </InfiniteScrollView>
      </Column>
    </ModalScreenContainer>
  );
};

export default pipe(SelectMembershipScreen, CurrentBusiness, Authorized);

import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { colors, Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { styles } from './styles';

export type Props = {
  readonly note: ClientNoteHistoryRecord;
  readonly onDismiss: () => void;
  readonly onEdit: (note: ClientNoteHistoryRecord) => void;
  readonly onDelete: (note: ClientNoteHistoryRecord) => void;
};

/**
 * Options modal screen component for a client note options, where pros can:
 *  - Edit note
 *  - Delete note
 */
const ClientNoteOptionsModal: React.FC<Props> = ({
  note,
  onDismiss,
  onEdit,
  onDelete,
}: Props): React.ReactElement | null => {
  const onEditCallback = React.useCallback(() => {
    onEdit(note);
  }, [note, onEdit]);

  const onDeleteCallback = React.useCallback(() => {
    onDelete(note);
  }, [note, onDelete]);

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onEditCallback}>
            <SimpleListItem>
              <Title>Modifică notiță</Title>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDeleteCallback}>
            <SimpleListItem>
              <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță</Title>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientNoteOptionsModal;

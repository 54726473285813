import { PageId } from '@mero/api-sdk/dist/pages';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithWorkerServices } from '@mero/api-sdk/dist/services/group-with-worker-services';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  H3s,
  Header,
  Icon,
  ModalOverlay,
  Row,
  SimpleListItem,
  Spacer,
  Text,
  Title,
  styles as meroStyles,
  SafeAreaView,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  RootStackParamList,
  WorkerServicesSettingsStackParamList,
} from '../../../../../types';
import { getPrice } from '../../../ServicesScreen/ServicesMobileScreen';
import { styles } from '../ProsDashboardScreen/ProServicesScreen.styles';

const formatDuration = (duration: number) => {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours} h ${minutes} min`;
  }

  return `${duration} min`;
};

export type Props = {
  readonly navigation: CompositeNavigationProp<
    StackNavigationProp<WorkerServicesSettingsStackParamList, 'WorkerServices'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList>
      >
    >
  >;
  readonly route: RouteProp<WorkerServicesSettingsStackParamList, 'WorkerServices'>;
};

const WorkerServicesSettingsScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();

  const { workerId } = route.params;

  const [pageState] = CurrentBusinessContext.useContext();

  const [proDetails, setProDetails] = React.useState<SavedWorker | null>(null);
  const [groupedServices, setGroupedServices] = React.useState<GroupWithWorkerServices[]>([]);
  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);

  const goToBulkOperationsCallback = React.useCallback(() => {
    navigation.navigate('Authorized', {
      screen: 'ServicesBulkOperations',
      params: {
        workerId: workerId,
      },
    });
  }, []);

  const getGroupedServices = async (pageId: PageId) => {
    try {
      const groupedServices = await meroApi.pages.getWorkerGroupedServices({ pageId, workerId });

      setGroupedServices([
        ...groupedServices.grouped.filter((group) => group.services.length > 0),
        ...(groupedServices.others.length === 0
          ? []
          : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: groupedServices.others }]),
      ]);
    } catch {}
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);
      if (worker) {
        getGroupedServices(pageState.page.details._id);
      }
      setProDetails(worker ?? null);
    }
  }, [pageState.type, isFocused]);

  const servicesCounter = React.useMemo(() => {
    return groupedServices.reduce((acc, group) => acc + group.services.length, 0);
  }, [groupedServices]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proServices')}
        RightComponent={() => (
          <Column>
            <Button
              onPress={() => setShowOptionsMenu(true)}
              expand={false}
              text={t('services:options')}
              backgroundColor={colors.ALABASTER}
              color={colors.DARK_BLUE}
            />
          </Column>
        )}
      />
      {proDetails && (
        <ScrollView>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('proServices')}</H1>
            <Body style={{ padding: 8 }}>{t('proServicesNewDescription')}</Body>
            <Spacer size={32} />
            <Row style={{ paddingHorizontal: 8, justifyContent: 'space-between' }}>
              <Title style={{ color: '#32325D' }}>{t('services', { count: servicesCounter })}</Title>
            </Row>
            <Spacer size={16} />
            {groupedServices.length === 0 ? (
              <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 32 }}>
                <H2s>{t('noServicesTitle')}</H2s>
                <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                  {t('noServicesDescription')}
                </Body>
              </Column>
            ) : (
              <FormCard rounded paddings="none">
                {groupedServices.map((groupedService, groupIndex) => (
                  <Column
                    key={groupedService.group._id}
                    style={{
                      paddingHorizontal: 16,
                      paddingTop: 24,
                      paddingBottom: 16,
                      borderBottomWidth: groupIndex < groupedServices.length - 1 ? 1 : 0,
                      borderBottomColor: '#E9ECEF',
                    }}
                  >
                    <H3s>{groupedService.group.name}</H3s>
                    <Spacer size={8} />
                    {groupedService.services.map((service, index) => (
                      <TouchableOpacity
                        key={service._id}
                        style={{
                          borderBottomWidth: index < groupedService.services.length - 1 ? 1 : 0,
                          borderBottomColor: '#E9ECEF',
                          paddingVertical: 16,
                        }}
                        onPress={() =>
                          navigation.navigate('WorkerServiceDetails', { workerId, serviceId: service._id })
                        }
                      >
                        <SimpleListItem
                          paddingTop={0}
                          paddingBottom={0}
                          title={service.name}
                          subtitle={
                            <Text smallBody>
                              {formatDuration(service.durationInMinutes)},{' '}
                              {service.price.type === 'fixed' && service.price?.promo ? (
                                <>
                                  <Text smallBody>
                                    {service.price.promo} lei{' '}
                                    <Text
                                      smallBody
                                      style={{
                                        textDecorationLine: 'line-through',
                                        textDecorationStyle: 'solid',
                                      }}
                                    >
                                      ({service.price.fixed} lei)
                                    </Text>
                                  </Text>
                                </>
                              ) : (
                                <Text smallBody>{getPrice(service.price)}</Text>
                              )}
                            </Text>
                          }
                          IconComponent={() => <Icon type="arrow-right" />}
                          iconPosition="right"
                        />
                      </TouchableOpacity>
                    ))}
                  </Column>
                ))}
                <Spacer size={16} />
              </FormCard>
            )}
          </Column>
          <Spacer size="16" />
        </ScrollView>
      )}

      {showOptionsMenu && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={() => {
                  setShowOptionsMenu(false);
                  goToBulkOperationsCallback();
                }}
              >
                <Body style={meroStyles.text.semibold}>{t('services:servicesMultipleActions')}</Body>
              </TouchableOpacity>
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}
    </ModalScreenContainer>
  );
};

export default WorkerServicesSettingsScreen;

import {
  Button,
  Checkbox,
  Column,
  FormCard,
  H1,
  H3s,
  Header,
  Icon,
  SafeAreaView,
  SmallBody,
  Spacer,
  Title,
  colors,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsStackParamList } from '../../../../../types';
import { styles } from './styles';

export type Props = CurrentBusinessProps & {
  navigation: StackScreenProps<PageOnlinePaymentsStackParamList, 'PageOnlinePaymentsAppointment'>;
};

const PageOnlinePaymentsAppointment: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('onlinePayments');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  const [appointmentPayments, setAppointmentPayments] = React.useState<boolean>(
    onlinePaymentsState.status.type === 'Enabled' &&
      onlinePaymentsState.status.settings.appointmentPayments.type === 'On',
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const save = async () => {
    setIsLoading(true);
    try {
      if (onlinePaymentsState.status.type !== 'Enabled') {
        return;
      }

      await meroApi.pages.updatePageOnlinePaymentsSettings({
        pageId: page.details._id,
        settings: {
          appointmentPayments: appointmentPayments ? { type: 'On' } : { type: 'Off' },
          requireAdvancePayment: onlinePaymentsState.status.settings.requireAdvancePayment,
          advancePaymentTos: onlinePaymentsState.status.settings?.advancePaymentTos,
        },
      });

      await reloadAsync({ pageId: page.details._id });

      toast.show({ type: 'success', text: t('changesSaved') });
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        CenterComponent={() => <Title style={{ fontSize: 14 }}>{t('appointmentPayments')}</Title>}
      />
      <ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('appointmentPaymentsHeader')}</H1>
        <Spacer size={32} />
        <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 24 }}>
          <TouchableOpacity
            style={{ paddingHorizontal: 16, flexDirection: 'row' }}
            onPress={() => setAppointmentPayments((prev) => !prev)}
          >
            <Checkbox value={appointmentPayments} onValueChange={() => setAppointmentPayments((prev) => !prev)} />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('appointmentPaymentsHeader')}</H3s>
              <Spacer size={4} />
              <SmallBody>{t('appointmentPaymentsDescLong')}</SmallBody>
            </Column>
          </TouchableOpacity>
        </FormCard>
        <Spacer size={96} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onPress={save}
              disabled={isLoading}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageOnlinePaymentsAppointment, CurrentBusiness);

import { Select } from '@mero/components';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';

import { SelectItem } from '@mero/components/lib/components/Select';
import { formatDurationInMinutes } from '@mero/components/lib/utils/format';

type SelectStep = 5 | 10 | 15 | 20 | 30 | 60;

/**
 * @param hour day hour value
 * @param minute minut value
 * @param duration duration in minutes
 */
const formatDayTime = (hour: number, minute: number, duration?: number): string => {
  return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}${
    duration !== undefined ? ` (${formatDurationInMinutes(duration, true)})` : ''
  }`;
};

const buildSelectOptions = (
  start: DateTime,
  end: DateTime,
  step: SelectStep,
  showDuration: boolean,
  timeZone: Zone,
): SelectItem<number>[] => {
  const options: SelectItem<number>[] = [];

  const startMillis = start.toMillis();
  let tsMillis = startMillis;
  let ts = start;
  const endMillis = end.toMillis();
  const stepMillis = step * 60_000;
  const stepsInHour = 3_600_000 / stepMillis;

  while (tsMillis <= endMillis) {
    if (ts.minute === 0 && tsMillis + (stepsInHour - 1) * stepMillis < endMillis) {
      // Exact hour and full hour available - push bulk values
      // This may probably not work in all timezones, ex. when dst is half an hour ...
      for (let i = 0; i < 60; i += step) {
        const duration = showDuration ? Math.round((tsMillis - startMillis) / 60_000) : undefined;
        options.push({ label: formatDayTime(ts.hour, i, duration), value: tsMillis });
        tsMillis += stepMillis;
      }
      ts = DateTime.fromMillis(tsMillis, { zone: timeZone });
    } else {
      const duration = showDuration ? Math.round((tsMillis - startMillis) / 60_000) : undefined;
      options.push({ label: formatDayTime(ts.hour, ts.minute, duration), value: tsMillis });
      tsMillis = tsMillis + stepMillis;
      ts = DateTime.fromMillis(tsMillis, { zone: timeZone });
    }
  }

  return options;
};

export type DayTimeSelectProps = {
  /**
   * Select start time
   */
  readonly start: DateTime;
  /**
   * Select end time
   */
  readonly end: DateTime;
  /**
   * TimeZone for times to show
   */
  readonly timeZone: Zone;
  /**
   * Default value
   */
  readonly value?: DateTime;
  /**
   * Select options step in minutes
   */
  readonly step?: SelectStep;
  /**
   * Select change callback
   */
  readonly onChange?: (time: DateTime) => void;
  /**
   * Set to true to show duration
   */
  readonly showDuration?: boolean;

  readonly disabled?: boolean;
};

const DayTimeSelect: React.FC<DayTimeSelectProps> = ({
  start,
  end,
  value,
  step = 15,
  onChange,
  showDuration = false,
  timeZone,
  disabled,
}: DayTimeSelectProps) => {
  // Use primitive values to avoid rebuilding select options when only references changed
  const startTs = start.toMillis();
  const endTs = end.toMillis();

  const selectOptions = React.useMemo(
    () => buildSelectOptions(start, end, step, showDuration, timeZone),
    [startTs, endTs, step, showDuration],
  );

  const onChangeCallback = React.useCallback(
    (ts: number) => {
      if (onChange) {
        onChange(DateTime.fromMillis(ts, { zone: timeZone }));
      }
    },
    [onChange],
  );

  const approxValue: number = React.useMemo(() => {
    const v = value ?? start;
    const duration = Math.round((v.toMillis() - start.toMillis()) / 60_000);
    const ts = start.plus({ minutes: Math.floor(duration / step) * step });

    return ts.toMillis();
  }, [value, start]);

  return <Select items={selectOptions} value={approxValue} onChange={onChangeCallback} editable={!disabled} />;
};

export default DayTimeSelect;

import { PortalHost } from '@gorhom/portal';
import { CheckoutSavedCashRegister } from '@mero/api-sdk';
import { CheckoutCashRegisterDriverDetails } from '@mero/api-sdk/dist/checkout/cashRegisters';
import { SavedCheckoutCompanyWithRegisters } from '@mero/api-sdk/dist/checkout/companies';
import {
  Checkbox,
  colors,
  Column,
  H1,
  H2s,
  MeroHeader,
  SmallBody,
  Spacer,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import Line from '@mero/components/lib/components/Line';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { BillingDetailsProps, withBillingDetails } from '../../../../../contexts/CheckoutSettingsContext';
import { CheckoutsContext } from '../../../../../contexts/CheckoutsContext';
import { CurrentBusinessProps, CurrentBusiness } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { CheckoutSettingsStackParamList } from '../../../../../types';
import EnableCheckoutMobile from '../../../CheckoutScreen/EnableCheckoutMobile';
import { CheckoutCompanyItem } from './CheckoutCompanyItem';

type Props = StackScreenProps<CheckoutSettingsStackParamList, 'CheckoutSettings'> &
  CurrentBusinessProps &
  BillingDetailsProps;

export type CheckoutCompany = Omit<SavedCheckoutCompanyWithRegisters, 'billingDetails'> & {
  billingDetails: NonNullable<SavedCheckoutCompanyWithRegisters['billingDetails']>;
};

const PageCheckoutSettingsScreen: React.FC<Props> = ({ page, navigation }: Props) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [checkoutState, { reloadSettings, reloadCompanies }] = CheckoutsContext.useContext();
  const [, { setCompanies: setCashRegistryCompanies }] = CashRegistryContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [enableCheckout, setEnableCheckout] = React.useState<boolean>(false);
  const [emitReceipt, setEmitReceipt] = React.useState<boolean>(false);
  const [companies, setCompanies] = React.useState<CheckoutCompany[]>([]);
  const [drivers, setDrivers] = React.useState<CheckoutCashRegisterDriverDetails[]>([]);

  const getCompanies = async () => {
    try {
      const [response, drivers] = await Promise.all([
        meroApi.checkout.listPageCompanies({
          pageId: page.details._id,
        }),
        meroApi.checkout.listCashRegisterDrivers({ pageId: page.details._id }),
      ]);
      const newCompanies = response.filter((c): c is CheckoutCompany => !!c.billingDetails);
      setDrivers(drivers);
      setCompanies(newCompanies);
      setCashRegistryCompanies({ pageId: page.details._id, companies: newCompanies });
    } catch (error) {
      showError(error);
    }
  };

  const changeCheckoutSettings = async () => {
    setIsLoading(true);
    try {
      await meroApi.checkout.savePageSettings({
        pageId: page.details._id,
        settings: {
          checkoutEnabled: !enableCheckout,
        },
      });

      setEnableCheckout((prev) => !prev);
      reloadSettings(page.details._id);
      reloadCompanies(page.details._id);
      toast.show({
        text: enableCheckout ? t('checkoutSettingsDeactivated') : t('checkoutSettingsActivated'),
        type: 'success',
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  const getCashRegisterLabel = (cashRegisters: CheckoutSavedCashRegister[]) => {
    if (cashRegisters.length === 0) {
      return t('emitReceipt');
    }

    const cashRegister = cashRegisters[0];
    const driverDetails = drivers.find((driver) => driver.driverId === cashRegister.driver._id);

    return cashRegister.name
      ? `${cashRegister.name}${driverDetails ? ` (${driverDetails.name})` : ''}`
      : driverDetails
      ? driverDetails.name
      : t('emitReceipt');
  };

  React.useEffect(() => {
    if (isFocused) {
      getCompanies();
    }
  }, [isFocused]);

  React.useEffect(() => {
    if (checkoutState.type === 'Loaded') {
      setEnableCheckout(checkoutState.pageSettings.checkoutEnabled);
    }
  }, [checkoutState]);

  const shouldEnableCheckout = checkoutState.type === 'Loaded' && !checkoutState.pageSettings.checkoutEnabled;

  const onEnableCheckout = React.useCallback(async () => {
    if (checkoutState.type === 'Loaded') {
      await changeCheckoutSettings();
      if (checkoutState.companies.length === 0) {
        navigation.navigate('BillingDetailsList');
      }
    }
  }, [checkoutState]);

  if (shouldEnableCheckout) {
    return (
      <View style={{ flex: 1, backgroundColor: colors.WHITE }}>
        <EnableCheckoutMobile onEnableCheckout={onEnableCheckout} onGoBack={goBack} />
      </View>
    );
  }

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        {checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled && (
          <>
            <MeroHeader canGoBack onBack={goBack} title={t('checkoutSettings')} />
            <Column style={{ paddingTop: 16, flex: 1 }}>
              <ScrollView style={{ borderRadius: 6 }}>
                <Spacer size={16} />
                <FormCard dropShaddow rounded paddings="none" style={{ marginHorizontal: 16 }}>
                  <Column>
                    <Spacer size={24} />
                    <TouchableOpacity
                      onPress={changeCheckoutSettings}
                      style={{ paddingHorizontal: 16, flex: 1, flexDirection: 'row' }}
                      disabled={isLoading}
                    >
                      <Checkbox value={enableCheckout} onValueChange={changeCheckoutSettings} disabled={isLoading} />
                      <Column style={{ paddingLeft: 16, flex: 1 }}>
                        <Title>{t('enableCheckoutTitle')}</Title>
                        <Spacer size={6} />
                        <SmallBody>{t('enableCheckoutDescription')}</SmallBody>
                      </Column>
                    </TouchableOpacity>
                    <Spacer size={24} />
                  </Column>
                </FormCard>
                {enableCheckout && (
                  <>
                    <Spacer size={32} />
                    <FormCard paddings="none" style={{ height: '100%' }}>
                      {companies.length > 0 ? (
                        <>
                          <Spacer size={24} />
                          <H1 style={{ paddingHorizontal: 24 }}>{t('checkoutSettings')}</H1>
                          <Spacer size={24} />
                          {companies.map((company, index) => (
                            <Column key={company._id} style={{ paddingHorizontal: 24 }}>
                              <CheckoutCompanyItem
                                page={page.details}
                                drivers={drivers}
                                company={company}
                                cashRegisterLabel={getCashRegisterLabel(company.cashRegisters)}
                                onChange={getCompanies}
                                onEdit={(company) =>
                                  navigation.navigate('BillingDetails', {
                                    type: 'Company',
                                    billingId: company.billingDetails._id,
                                  })
                                }
                                onDelete={getCompanies}
                              />
                              {index < companies.length - 1 && (
                                <>
                                  <Spacer size={16} />
                                  <Line />
                                  <Spacer size={16} />
                                </>
                              )}
                            </Column>
                          ))}
                        </>
                      ) : (
                        <>
                          <Spacer size={32} />
                          <H2s style={{ textAlign: 'center' }}>{t('noCompanies')}</H2s>
                        </>
                      )}
                      <Spacer size={32} />
                      <Column style={{ paddingHorizontal: 24 }}>
                        <Button
                          text={t('selectNewCompany')}
                          backgroundColor={colors.SKY_BLUE}
                          color={colors.DARK_BLUE}
                          onPress={() => navigation.navigate('BillingDetailsList')}
                        />
                      </Column>
                    </FormCard>
                  </>
                )}
              </ScrollView>
            </Column>
          </>
        )}
      </ModalScreenContainer>
      <PortalHost name="CheckoutSettingsScreen" />
    </>
  );
};

export default pipe(PageCheckoutSettingsScreen, withBillingDetails, CurrentBusiness);

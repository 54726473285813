import { colors, styles as meroStyles, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 32,
  },
  subtitle: {
    ...meroStyles.text.semibold,
    color: colors.COMET,
    textTransform: 'uppercase',
    paddingBottom: 16,
  },
  modalBorder: {
    borderRadius: 8,
  },
  hrPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  profileContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.ALABASTER,
    padding: 16,
    flex: 1,
  },
  pagePhotoContainer: {
    backgroundColor: colors.WHITE,
    padding: 2,
    borderRadius: 25,
    marginTop: -41,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  headerWrapperWithShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.06,
    shadowRadius: 8,
    elevation: 16,
    zIndex: 202,
    backgroundColor: '#ffffff',
  },
  optionsMenu: {
    position: 'absolute',
    right: 0,
    top: 12,
    minWidth: 300,
    paddingHorizontal: sizes[16],
    backgroundColor: colors.WHITE,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'rgb(233, 236, 239)',
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  optionsMenuItem: {
    paddingVertical: sizes[16],
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewLabel: {
    borderRadius: 15,
    minWidth: 91,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    flexDirection: 'row',
    backgroundColor: colors.WHITE,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    paddingVertical: 5,
  },
});

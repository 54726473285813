import * as React from 'react';
import { StyleProp, View, ViewStyle, Text, TextStyle, DimensionValue } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Svg, { Defs, RadialGradient, Stop, Rect } from 'react-native-svg';

type Props = {
  width: number | `${number}%`;
  height: number;
  borderRadius: number;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

const GradientBanner: React.FC<React.PropsWithChildren<Props>> = ({
  width,
  height,
  borderRadius,
  containerStyle,
  onPress,
  children,
}) => {
  return (
    <TouchableOpacity
      style={[
        {
          position: 'relative',
          width,
          height,
          //@ts-ignore
          cursor: 'pointer',
        },
        containerStyle,
      ]}
      onPress={onPress}
      disabled={!onPress}
    >
      <Svg width={width} height={height}>
        <Defs>
          <RadialGradient id="grad" cx="0%" cy="30%" r="100%" fx="0%" fy="0%">
            <Stop offset="0%" stopColor="#ECCFF8" stopOpacity="1" />
            <Stop offset="100%" stopColor="#EAF4FF" stopOpacity="1" />
          </RadialGradient>
        </Defs>
        <Rect x="0" y="0" height={height} width={width} rx={borderRadius} fill="url(#grad)" />
      </Svg>
      <View
        style={{
          position: 'absolute',
          left: 16,
          right: 16,
        }}
      >
        {children}
      </View>
    </TouchableOpacity>
  );
};

export default GradientBanner;

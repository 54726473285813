import { Portal } from '@gorhom/portal';
import * as MeroApi from '@mero/api-sdk';
import { CheckoutCashRegisterDriverDetails } from '@mero/api-sdk/dist/checkout/cashRegisters';
import { colors, Column, SmallBody, Spacer, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import Icon from '@mero/components/lib/components/Icon';
import Row from '@mero/components/lib/components/Layout/Row';
import Body from '@mero/components/lib/components/Text/Body';

import { MoreIcon } from '../PageSubscriptionSettingsScreen/BillingDetailItemMore';
import CheckoutCompanyMenu from './CheckoutCompanyMenu';
import DeleteCompanyDialog from './DeleteCompanyDialog';
import EditCashRegisterDialog from './EditCashRegisterDialog';
import { CheckoutCompany } from './PageCheckoutSettingsScreen';

type Props = {
  drivers: CheckoutCashRegisterDriverDetails[];
  onChange: () => void;
  onEdit: (company: CheckoutCompany) => void;
  onDelete: (company: CheckoutCompany) => void;
  company: CheckoutCompany;
  cashRegisterLabel: string;
  page: MeroApi.pages.PageDetails;
};

export const CheckoutCompanyItem: React.FC<Props> = ({
  company,
  cashRegisterLabel,
  onChange,
  onEdit,
  onDelete,
  drivers,
  page,
}) => {
  const { t } = useTranslation('checkout');

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isEditCashRegisterOpen, setIsEditCashRegisterOpen] = React.useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDelete = () => setIsDeleteOpen(!isDeleteOpen);
  const toggleEditCashRegister = () => setIsEditCashRegisterOpen(!isEditCashRegisterOpen);

  return (
    <>
      <Column>
        <Row style={{ flex: 1 }}>
          <Title style={{ flex: 1 }}>{company.billingDetails.name}</Title>
          <TouchableOpacity onPress={toggleMenu}>
            <MoreIcon />
          </TouchableOpacity>
        </Row>
        <Spacer size={4} />
        <SmallBody style={{ color: colors.COMET }}>
          {t('cui')}: {company.billingDetails.fiscalCode}
        </SmallBody>
        <SmallBody style={{ color: colors.COMET }}>
          {t('regNo')}: {company.billingDetails.registrationNumber}
        </SmallBody>
        <SmallBody style={{ color: colors.COMET }}>
          {t('address')}: {company.billingDetails.address.city}, {company.billingDetails.address.address},{' '}
          {company.billingDetails.address.county}
        </SmallBody>
        <Spacer size={12} />
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            paddingHorizontal: 16,
            paddingVertical: 12,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: colors.GEYSER,
          }}
          onPress={toggleEditCashRegister}
        >
          <Column style={{ flex: 1 }}>
            {company.cashRegisters.length > 0 ? (
              <>
                <Title>{t('receiptDialogTitle')}</Title>
                <Body>{cashRegisterLabel}</Body>
              </>
            ) : (
              <Title style={{ color: colors.DARK_BLUE }}>{t('enableReceipt')}</Title>
            )}
          </Column>
          <Icon type="next" color={colors.DARK_BLUE} />
        </TouchableOpacity>
      </Column>
      {isMenuOpen && (
        <Portal hostName="CheckoutSettingsScreen">
          <CheckoutCompanyMenu
            onChange={flow(() => onEdit(company), toggleMenu)}
            onDelete={flow(toggleMenu, toggleDelete)}
            onDismiss={toggleMenu}
          />
        </Portal>
      )}
      {isDeleteOpen && (
        <Portal hostName="CheckoutSettingsScreen">
          <DeleteCompanyDialog
            onSuccess={flow(() => onDelete(company), toggleDelete)}
            onCancel={toggleDelete}
            companyId={company._id}
          />
        </Portal>
      )}
      {isEditCashRegisterOpen && (
        <Portal>
          <EditCashRegisterDialog
            page={page}
            companyId={company._id}
            activeCashRegister={company.cashRegisters[0]}
            drivers={drivers}
            onSuccess={flow(onChange, toggleEditCashRegister)}
            onCancel={toggleEditCashRegister}
          />
        </Portal>
      )}
    </>
  );
};

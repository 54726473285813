import { Body, styles as meroStyles, Icon, colors, Column, Line } from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity, View } from 'react-native';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './styles';

type Props = {
  hasBackground: boolean;
  navigateAddProductCallback: () => void;
  navigateAddCategoryCallback: () => void;
  navigateImportProductsCallback: () => void;
};

const ProductAddButton: React.FC<Props> = ({
  navigateAddProductCallback,
  navigateAddCategoryCallback,
  navigateImportProductsCallback,
}) => {
  const { t } = useTranslation('products');
  const optionsMenuRef = React.useRef<View>(null);
  const { isDesktop } = useMediaQueries();

  const [showAddProductMenu, setShowAddProductMenu] = React.useState(false);

  const addProductMenuCallback = React.useCallback(() => {
    setShowAddProductMenu((prev) => !prev);
  }, []);

  useClickOutsideWeb({
    ref: optionsMenuRef,
    isVisible: showAddProductMenu,
    onClickOutside() {
      setShowAddProductMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  return (
    <View style={{ flexDirection: 'column', display: 'flex', flex: 1 }} ref={optionsMenuRef}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={addProductMenuCallback}>
        <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('add')}</Body>
        <View
          style={{
            paddingLeft: 8,
            justifyContent: 'center',
          }}
        >
          <Icon type="dropdown" color={colors.DARK_BLUE} />
        </View>
      </TouchableOpacity>
      <Column style={{ position: 'relative' }}>
        {showAddProductMenu && (
          <Column style={styles.optionsMenu}>
            <TouchableOpacity
              style={styles.optionsMenuItem}
              delayPressIn={0}
              onPress={() => {
                setShowAddProductMenu(false);
                navigateImportProductsCallback();
              }}
            >
              <Body style={meroStyles.text.semibold}>{t('importProducts')}</Body>
            </TouchableOpacity>
            <Line />

            <TouchableOpacity
              style={styles.optionsMenuItem}
              delayPressIn={0}
              onPress={() => {
                setShowAddProductMenu(false);
                navigateAddProductCallback();
              }}
            >
              <Body style={meroStyles.text.semibold}>{t('addProduct')}</Body>
            </TouchableOpacity>
            <Line />

            <TouchableOpacity
              style={styles.optionsMenuItem}
              delayPressIn={0}
              onPress={() => {
                setShowAddProductMenu(false);
                navigateAddCategoryCallback();
              }}
            >
              <Body style={meroStyles.text.semibold}>{t('addCategory')}</Body>
            </TouchableOpacity>
          </Column>
        )}
      </Column>
    </View>
  );
};

export default ProductAddButton;

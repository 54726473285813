import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  listContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,

    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
  },
  listContainerPadding: {
    paddingLeft: 24,
    paddingTop: 24,
    paddingRight: 24,
  },
  stretchContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  modalScreen: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  formMargin: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  hrPadding: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  headerWrapperWithShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.06,
    shadowRadius: 8,
    elevation: 16,
    zIndex: 202,
    backgroundColor: '#ffffff',
  },
});

import { Portal } from '@gorhom/portal';
import { PageBillingDetails, PageBillingDetailsId } from '@mero/api-sdk';
import { CheckoutCompanyId } from '@mero/api-sdk/dist/checkout/companies';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  billingDetailsId: PageBillingDetailsId;
  type: PageBillingDetails.Any['type'];
};

const DeleteBillingDetailsDialog: React.FC<Props> = ({ onSuccess, onCancel, type, billingDetailsId }) => {
  const { t } = useTranslation('subscription');
  const showError = useShowError();

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState(
    type === 'Company'
      ? {
          check1: false,
          check2: false,
        }
      : {},
  );

  const cancel = () => {
    onCancel();
  };

  const onDelete = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.pro.pageBillingDetails.deletePageBillingDetails({
          pageId: pageState.page.details._id,
          billingDetailsId,
        });
        onSuccess();
      }
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
      showError(error);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const leftAction = {
    text: t('back'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: t('deleteBilling'),
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : onDelete,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <Portal>
      <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
        <MobileWebModalWrapper position="center">
          <ConfirmBox
            type="error"
            headerTitle={t('irreversibleAction')}
            icon="info"
            canClose={!deleteInProgress}
            onClose={cancel}
            leftAction={leftAction}
            rightAction={rightAction}
            style={{
              maxWidth: 400,
            }}
          >
            <H1>{t(`deleteBillingDetails${type}`)}</H1>
            <Spacer size="16" />
            {type === 'Company' && (
              <>
                <Title>{t('checkAndConfirmDelete')}</Title>
                <Spacer size="16" />
                <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
                  <SimpleListItem
                    icon={checks.check1 ? 'checked' : 'unchecked'}
                    iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
                    paddingTop={0}
                    paddingBottom={0}
                  >
                    <Body>
                      <Trans ns={'subscription'} t={t} i18nKey="deleteBillingDetailsCompanyCheck1">
                        0<Title>1</Title>2
                      </Trans>
                    </Body>
                  </SimpleListItem>
                </TouchableOpacity>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
                  <SimpleListItem
                    icon={checks.check2 ? 'checked' : 'unchecked'}
                    iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
                    paddingTop={0}
                    paddingBottom={0}
                  >
                    <Body>
                      <Trans ns={'subscription'} t={t} i18nKey="deleteBillingDetailsCompanyCheck2">
                        0
                      </Trans>
                    </Body>
                  </SimpleListItem>
                </TouchableOpacity>
              </>
            )}
            {type === 'Person' && <Body>{t('deletePerson')}</Body>}
          </ConfirmBox>
        </MobileWebModalWrapper>
      </ModalOverlay>
    </Portal>
  );
};

export default DeleteBillingDetailsDialog;

import { Column, H1, Header, Icon, Line, Row, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import * as colors from '@mero/components/lib/styles/colors';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { useAnalytics } from '../../../../../hooks/useAnalytics';
import useGoBack from '../../../../../hooks/useGoBack';

import { ShopStackParamsList } from '../../../../../types';
import HeaderLogo from './HeaderLogo';
import PageContainer from './PageContainer';
import { ShopContext } from './shopContext';

type Props = StackScreenProps<ShopStackParamsList, 'ShopCategories'>;
const ShopCategoriesScreen: React.FC<Props> = ({ navigation }) => {
  const [shopState, shopDispatch] = ShopContext.useContext();

  const isFocused = useIsFocused();

  const goBack = useGoBack();

  React.useEffect(() => {
    if (isFocused) {
      //reset filters
      shopDispatch.updateSelectedFilters({});
      shopDispatch.updateSearchTerm('');
      shopDispatch.updateSort({ by: 'popularity', direction: 'asc' });
    }
  }, [isFocused]);

  useAnalytics({
    eventName: 'product_marketplace_home_screen_shown',
    screenName: 'product_marketplace_home',
  });

  React.useEffect(() => {
    shopDispatch.init();
  }, []);

  return (
    <PageContainer>
      <Column
        style={{
          backgroundColor: colors.WHITE,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.08,
          shadowRadius: 16,
          elevation: 5,
          zIndex: 1,
        }}
      >
        <SafeAreaView edges={['top']} />
        <Header
          CenterComponent={HeaderLogo}
          LeftComponent={() => (
            <TouchableOpacity style={{ paddingLeft: 16 }} onPress={goBack}>
              <Icon type="back" />
            </TouchableOpacity>
          )}
        />
        {/*<Spacer size="8" />*/}
        {/*<Column style={{ paddingHorizontal: 16 }}>*/}
        {/*  <TextInput*/}
        {/*    placeholder={'Caută produs sau marcă'}*/}
        {/*    rounded={true}*/}
        {/*    background={colors.WHITE}*/}
        {/*    autoFocus={false}*/}
        {/*    isFocused={false}*/}
        {/*    LeftComponent={() => (*/}
        {/*      <View style={{ marginLeft: -10 }}>*/}
        {/*        <Icon type="search" color={colors.DARK_BLUE} size={24} />*/}
        {/*      </View>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*  <Spacer size="16" />*/}
        {/*</Column>*/}
      </Column>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 96 }}>
        <Spacer size="24" />
        <H1 style={{ paddingHorizontal: 24 }}>Alege o categorie</H1>
        <Spacer size="24" />
        <Column style={{ backgroundColor: colors.WHITE, paddingHorizontal: 24 }}>
          {shopState.categories.map((item, index) => (
            <TouchableOpacity
              key={index}
              style={{ paddingTop: 16 }}
              onPress={() => navigation.navigate('ShopProducts', { category: item })}
            >
              <Row>
                <Title style={{ fontSize: 18, flex: 1 }}>{item.title}</Title>
                <Icon type="arrow-right" color={colors.DARK_BLUE} size={24} />
              </Row>
              <Spacer size={20} />
              {index < shopState.categories.length - 1 && <Line />}
            </TouchableOpacity>
          ))}
        </Column>
      </ScrollView>
    </PageContainer>
  );
};

export default ShopCategoriesScreen;

import {
  ApiError,
  CheckoutCashRegistryTransaction,
  CheckoutCashRegistryTransactionType,
  DateString,
  MeroUnits,
  ScaledNumber,
} from '@mero/api-sdk';
import {
  Body,
  Checkbox,
  FormCard,
  H1,
  InputWithLabel,
  MeroHeader,
  Spacer,
  TypeSafeTextInput,
  styles as meroStyles,
  Icon,
  useKeyboardIsOpen,
  Button,
  useToast,
  Column,
  colors,
  Row,
  SmallBody,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { flow, pipe } from 'fp-ts/lib/function';
import { identity } from 'io-ts';
import * as t from 'io-ts';
import { NonEmptyString, NumberFromString } from 'io-ts-types';
import { IANAZone, DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, TouchableOpacity, Platform } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SelectDateTimeModal from '../../../../../components/SelectDateTimeModal';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { RouteProp } from '@react-navigation/native';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../../../../types';
import { PositiveNumberFromString, numberValidator, roundToDecimals } from '../../../../../utils/number';
import { localeStringToNumber, scaledToString, stripLocalThousandsSeparators } from '../../../../../utils/scaled';
import { isMaximumLengthStringBrand } from '../../../../../utils/text';
import { strictLength } from '../PageSubscriptionSettingsScreen/BillingDetails';
import ConfirmTransactionDialog from './ConfirmTransactionDialog';
import DeleteTransactionDialog from './DeleteTransactionDialog';
import { styles } from './styles';

const TIMEZONE = 'Europe/Bucharest';

type Props = {
  route: RouteProp<AuthorizedStackParamList, 'TransactionScreen'>;
};
const TransactionScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('cashRegistry');
  const { isPhone } = useMediaQueries();
  const scrollRef = React.useRef<ScrollView>(null);
  const goBack = useGoBack();
  const isKeyboardOpen = useKeyboardIsOpen();
  const showSubmitButton = !(isKeyboardOpen && Platform.OS === 'android');
  const toast = useToast();
  const now = new Date();

  const timezone = React.useMemo(() => IANAZone.create(TIMEZONE), []);

  const [cashRegistryState, { loadTransactions, update }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [transaction, setTransaction] = React.useState<CheckoutCashRegistryTransaction<MeroUnits.Any> | null>(null);
  const [showDeleteTransaction, setShowDeleteTransaction] = React.useState(false);
  const [showConfirmTransaction, setShowConfirmTransaction] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const [date, setDate] = React.useState(now);
  const [type, setType] = React.useState<CheckoutCashRegistryTransactionType | null>(null);

  const [amount, setAmount] = React.useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });
  const isAmountValid = E.isRight(amount.decoded);

  const [details, setDetails] = React.useState({
    input: '',
    decoded: strictLength(100).decode(''),
  });
  const isDetailsValid = isMaximumLengthStringBrand(100)(details.input);

  const [docNo, setDocNo] = React.useState({
    input: '',
    decoded: NonEmptyString.decode(''),
  });
  const isDocNoValid = E.isRight(docNo.decoded);

  React.useEffect(() => {
    const { transactionId } = route.params;

    if (cashRegistryState.type === 'Loaded' && cashRegistryState.transactionList && transactionId) {
      let transaction: CheckoutCashRegistryTransaction<MeroUnits.Any> | undefined;
      cashRegistryState.transactionList.dailyBreakdown.forEach((day) => {
        const found = day.transactions.find((t) => t._id === transactionId);
        if (found) {
          transaction = found;
        }
      });

      if (!transaction) {
        return;
      }
      const { transactionDate, type, amount, details, docNo } = transaction;
      setTransaction(transaction);
      setDate(transactionDate);
      setType(type);

      const strAmount = scaledToString(amount.amount);
      setAmount({
        input: strAmount,
        decoded: PositiveNumberFromString.decode(stripLocalThousandsSeparators(strAmount)),
      });

      setDetails({ input: details, decoded: strictLength(100).decode(details) });
      setDocNo({ input: docNo, decoded: NonEmptyString.decode(docNo) });
    }
  }, [cashRegistryState]);

  const dateString = React.useMemo(
    (): DateString =>
      pipe(
        DateTime.fromJSDate(date, { zone: timezone }).toFormat('yyyy-MM-dd'),
        DateString.decode,
        E.fold(() => {
          throw new Error(`Failed parse DateString`);
        }, identity),
      ),
    [date],
  );

  const newSold = React.useMemo(() => {
    if (cashRegistryState.type !== 'Loaded' || !cashRegistryState.cashRegistry || !cashRegistryState.transactionList) {
      return;
    }

    const initialAmount = cashRegistryState.transactionList.currentBalance.amount;

    if (!E.isRight(amount.decoded)) {
      return;
    }

    const changedAmount = ScaledNumber.sub(
      ScaledNumber.fromNumber(amount.decoded.right, 2),
      transaction?.amount.amount ?? ScaledNumber.zero(),
    );

    if (ScaledNumber.isZero(changedAmount) || type === null) {
      return;
    }

    const result = { amount: initialAmount, color: colors.BLACK };
    if (type === CheckoutCashRegistryTransactionType.DEBIT) {
      result.amount = ScaledNumber.sub(initialAmount, changedAmount);
    }
    if (type === CheckoutCashRegistryTransactionType.CREDIT) {
      result.amount = ScaledNumber.add(initialAmount, changedAmount);
    }
    result.color = ScaledNumber.lessThan(result.amount, ScaledNumber.zero()) ? colors.RADICAL_RED : colors.BLACK;
    return result;
  }, [amount, type]);

  const saveChanges = async () => {
    if (
      pageState.type !== 'Loaded' ||
      cashRegistryState.type !== 'Loaded' ||
      !cashRegistryState.cashRegistry ||
      !cashRegistryState.selectedCompany ||
      !type
    ) {
      return;
    }

    try {
      if (transaction?._id) {
        await meroApi.checkout.updateCashRegistryTransaction({
          companyId: cashRegistryState.selectedCompany._id,
          cashRegistryId: cashRegistryState.cashRegistry._id,
          pageId: pageState.page.details._id,
          details: details.input,
          docNo: docNo.input,
          transactionDate: date,
          cashRegistryTransactionId: transaction._id,
          amount: {
            amount: ScaledNumber.fromNumber(localeStringToNumber(amount.input), 2),
            unit: MeroUnits.RON.code,
          },
        });
        loadTransactions({
          pageId: pageState.page.details._id,
        });
        toast.show({
          type: 'success',
          text: t('changesSaved'),
        });
        goBack();
      } else {
        await meroApi.checkout.addCashRegistryTransaction({
          companyId: cashRegistryState.selectedCompany._id,
          cashRegistryId: cashRegistryState.cashRegistry._id,
          pageId: pageState.page.details._id,
          type,
          details: details.input,
          docNo: docNo.input,
          transactionDate: date,
          amount: {
            amount: ScaledNumber.fromNumber(localeStringToNumber(amount.input), 2),
            unit: MeroUnits.RON.code,
          },
        });
        loadTransactions({
          pageId: pageState.page.details._id,
        });
        toast.show({
          type: 'success',
          text: t('changesSaved'),
        });
        goBack();
      }
    } catch (error) {
      if (error instanceof ApiError) {
        toast.show({
          type: 'error',
          text: error.message,
        });
      } else {
        toast.show({
          type: 'error',
          text: 'Ceva neprevazut',
        });
      }
    }
  };

  const saveChangeButtonClick = () => {
    if (!isAmountValid || !isDetailsValid || !isDocNoValid) {
      return setShowErrors(true);
    }
    const date1 = DateTime.fromJSDate(date, { zone: timezone });
    const date2 = DateTime.fromJSDate(now, { zone: timezone });

    const differenceInDays = Math.round(date1.diff(date2, 'days').days);
    differenceInDays && differenceInDays < 0 ? setShowConfirmTransaction(true) : saveChanges();
  };

  const cancelDeleteService = () => {
    setShowDeleteTransaction(false);
  };

  const deleteService = () => {
    goBack();
    setShowDeleteTransaction(false);
  };

  if (cashRegistryState.type !== 'Loaded' || !cashRegistryState.cashRegistry) {
    return <></>;
  }

  return (
    <ModalScreenContainer edges={['top', 'bottom']}>
      <MeroHeader
        title={transaction ? t('editTransaction') : t('addTransaction')}
        canGoBack={true}
        onBack={goBack}
        canClose={false}
      />

      <ScrollView ref={scrollRef} contentContainerStyle={styles.formMargin}>
        <View style={styles.hrPadding}>
          <Spacer size="16" />
          <H1>{transaction ? t('editTransaction') : t('addTransaction')}</H1>
        </View>
        <Spacer size="32" />
        <FormCard dropShaddow rounded paddings="inputs">
          {/* Transaction type */}
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={() => !transaction && setType('debit')}
            disabled={!!transaction}
          >
            <Checkbox value={type === 'debit'} onValueChange={() => setType('debit')} disabled={!!transaction} />
            <Body style={[meroStyles.text.semibold, { fontSize: 17, paddingLeft: 18 }]}>{t('debit')}</Body>
          </TouchableOpacity>

          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={() => !transaction && setType('credit')}
            disabled={!!transaction}
          >
            <Checkbox value={type === 'credit'} onValueChange={() => setType('credit')} disabled={!!transaction} />
            <Body style={[meroStyles.text.semibold, { fontSize: 17, paddingLeft: 18 }]}>{t('credit')}</Body>
          </TouchableOpacity>

          {/* Transaction amount */}
          <Spacer size="24" />
          <InputWithLabel label={t('sum')} isError={showErrors && !isAmountValid} errorText={t('amountError')}>
            <TypeSafeTextInput
              value={amount.input}
              codec={PositiveNumberFromString}
              onChange={flow(numberValidator(amount.input), setAmount)}
              placeholder="0.00"
              keyboardType="numeric"
              editable={true}
              showError={showErrors && !isAmountValid}
              onFocus={() => {
                setAmount({
                  input: stripLocalThousandsSeparators(amount.input),
                  decoded: PositiveNumberFromString.decode(
                    roundToDecimals(localeStringToNumber(amount.input)).toString(),
                  ),
                });
              }}
              onBlur={() => {
                setAmount({
                  input: amount.input ? roundToDecimals(localeStringToNumber(amount.input)).toLocaleString() : '',
                  decoded: PositiveNumberFromString.decode(
                    roundToDecimals(localeStringToNumber(amount.input)).toString(),
                  ),
                });
              }}
            />
          </InputWithLabel>

          {/* Transaction details */}
          <Spacer size="16" />
          <InputWithLabel
            label={t('transactionDetails')}
            isError={showErrors && !isDetailsValid}
            errorText={t('detailsError')}
          >
            <TypeSafeTextInput
              value={details.input}
              codec={strictLength(100)}
              onChange={setDetails}
              editable={true}
              showError={showErrors && !isDetailsValid}
              placeholder={t('transactionDetails')}
            />
          </InputWithLabel>

          {/* Document number */}
          <Spacer size="16" />
          <InputWithLabel label={t('documentNumber')} isError={showErrors && !isDocNoValid} errorText={t('docNoError')}>
            <TypeSafeTextInput
              value={docNo.input}
              codec={NonEmptyString}
              onChange={setDocNo}
              editable={true}
              showError={showErrors && !isDocNoValid}
              placeholder={t('enterDocumentNumber')}
            />
          </InputWithLabel>

          {/* Transaction date */}
          <Spacer size="16" />
          <InputWithLabel label={t('dateLabel')}>
            <TouchableOpacity
              style={{
                height: 43,
                justifyContent: 'center',
                borderColor: '#DEE2E6',
                borderWidth: 1,
                borderRadius: 5,
                paddingHorizontal: 12,
              }}
              onPress={() => setShowCalendar(true)}
            >
              <View style={[{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }]}>
                <Body style={{ alignItems: 'center' }}>{dateString}</Body>
                <View style={[{ paddingLeft: 10 }]}>
                  <Icon type="dropdown" rotate={showCalendar} />
                </View>
              </View>
            </TouchableOpacity>
          </InputWithLabel>
        </FormCard>

        {/* Delete transaction button */}
        {transaction && (
          <>
            <Spacer size={24} />
            <Column justifyContent="center" alignItems="center">
              <Button
                onClick={() => setShowDeleteTransaction(true)}
                expand={false}
                backgroundColor={colors.WHITE}
                color={colors.RADICAL_RED}
                text={t('deleteTransaction')}
              />
            </Column>
          </>
        )}
        <Spacer size={163} />
      </ScrollView>

      {showCalendar && (
        <View style={[styles.modalScreen, { zIndex: 10000 }]}>
          <SelectDateTimeModal
            mode="date"
            selected={date}
            onDateSelected={(d) => {
              setDate(d), setShowCalendar(false);
            }}
            onClosePress={() => {
              setShowCalendar(false);
            }}
            maxDate={now}
            timeZone={timezone}
          />
        </View>
      )}
      {showSubmitButton && (
        <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
          <SafeAreaView edges={['bottom']}>
            <Row style={{ justifyContent: 'space-between', flex: 1 }}>
              <Row>
                <SmallBody style={meroStyles.text.semibold}>{t('currentBalance')} </SmallBody>
                <SmallBody>
                  {scaledToString(cashRegistryState.transactionList?.currentBalance.amount ?? ScaledNumber.zero())}{' '}
                </SmallBody>
              </Row>

              {cashRegistryState.transactionList?.currentBalance.amount && newSold && (
                <Row>
                  <SmallBody style={[meroStyles.text.semibold, { color: newSold?.color ?? colors.BLACK }]}>
                    {t('newSold')}{' '}
                  </SmallBody>
                  <SmallBody style={{ color: newSold?.color ?? colors.BLACK }}>
                    {scaledToString(newSold?.amount ?? cashRegistryState.transactionList?.currentBalance.amount)}{' '}
                  </SmallBody>
                </Row>
              )}
            </Row>
            <Spacer size={16} />

            {isPhone ? (
              <Button text={t('saveChanges')} onClick={saveChangeButtonClick} disabled={!type} />
            ) : (
              <Button
                text={t('saveChanges')}
                onClick={saveChangeButtonClick}
                disabled={!type}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
              />
            )}
          </SafeAreaView>
        </FormCard>
      )}

      {showDeleteTransaction && transaction && (
        <DeleteTransactionDialog
          transactionId={transaction._id}
          onSuccess={deleteService}
          onCancel={cancelDeleteService}
        />
      )}

      {showConfirmTransaction && (
        <ConfirmTransactionDialog onSave={saveChanges} onClose={() => setShowConfirmTransaction(false)} />
      )}
    </ModalScreenContainer>
  );
};

export default TransactionScreen;

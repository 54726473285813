import { ClientId } from '@mero/api-sdk/dist/clients';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { PageId } from '@mero/api-sdk/dist/pages';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { FlatList } from 'react-native';

import { Spacer } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ClientStackParamList } from '../../../../../../types';
import ClientMembershipCard from '../ClientMembershipCard';
import styles from './styles';

interface ClientMembershipsListProps {
  HeaderElement?: React.ReactElement;
  memberships: PurchasedMembership<MeroUnits.Any>[];
  clientId: ClientId;
  pageId: PageId;
  onEndReached: () => void;
}

const ClientMembershipsList: React.FC<ClientMembershipsListProps> = ({
  HeaderElement,
  memberships,
  clientId,
  pageId,
  onEndReached,
}) => {
  const navigation = useNavigation<NavigationProp<ClientStackParamList>>();

  const handleMembershipPress = (membership: PurchasedMembership<MeroUnits.Any>) => {
    navigation.navigate('MembershipDetailsScreen', {
      pageId,
      clientId,
      membershipPurchaseId: membership._id,
    });
  };

  const renderItem = React.useCallback(({ item }: { item: PurchasedMembership<MeroUnits.Any> }) => {
    return <ClientMembershipCard onPress={() => handleMembershipPress(item)} item={item} clientId={clientId} />;
  }, []);

  const keyExtractor = (item: PurchasedMembership<MeroUnits.Any>): string => item._id;

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={HeaderElement}
      data={memberships}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      onEndReachedThreshold={16}
      onEndReached={onEndReached}
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există abonamente" subtitle="Abonamentele clientului va fi afișat aici">
          <Spacer size="32" />
        </ClientEmptyListView>
      }
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

export default ClientMembershipsList;

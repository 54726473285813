import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Mero = ({ size = 32, color = WHITE, disabled, onPress }: Props) => (
  <Svg
    width={size}
    height={+size / 1.298}
    viewBox={`0 0 ${size} ${+size / 1.298}`}
    disabled={disabled}
    onPress={onPress}
  >
    <Path
      id="Path_4"
      data-name="Path 4"
      d="M24,20.521a4.336,4.336,0,0,1-3.008,4.412H32a4.336,4.336,0,0,1-3.008-4.412V4.693A4.336,4.336,0,0,1,32,.281H24.009L16.821,15.514,8.057.281H.878A6.86,6.86,0,0,1,4.435,6.324V19.37A5.462,5.462,0,0,1,0,24.933H9.425A5.472,5.472,0,0,1,5.056,19.37V5.2l9.561,16.44,9.392-19.9Z"
      transform="translate(0 -0.281)"
      fill={color}
    />
  </Svg>
);

export default React.memo(Mero);

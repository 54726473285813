import { InviteId, InvitePreview } from '@mero/api-sdk/dist/invites';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export type UserInvitesContextState =
  | {
      readonly type: 'New';
      readonly invites: [];
    }
  | {
      readonly type: 'Loading';
      readonly invites: InvitePreview[];
    }
  | {
      readonly type: 'Loaded';
      readonly invites: InvitePreview[];
    }
  | {
      readonly type: 'Failed';
      readonly error: unknown;
    };

const defaultState = (): UserInvitesContextState => ({
  type: 'New',
  invites: [],
});

export const UserInvitesContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        invites: InvitePreview[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          invites: result.invites,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (_, error: unknown) => {
      return {
        type: 'Failed',
        error: error,
      };
    },
    tryResetError: (state) => {
      if (state.type === 'Failed') {
        return {
          type: 'New',
          invites: [],
        };
      }

      return state;
    },
    mutate: (s, fn: (s: UserInvitesContextState) => UserInvitesContextState): UserInvitesContextState => fn(s),
  },
  (dispatch) => {
    const reload = async () => {
      try {
        log.debug('Start reloading invites');
        const invites = await meroApi.invites.getPendingInvites();

        dispatch.trySetResult({
          invites: invites,
        });
        log.debug(`${invites.length} invites (re)loaded`);
      } catch (error) {
        dispatch.setFailed(error);

        log.exception(error);
      }
    };

    return {
      init: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              invites: [],
            };
          } else {
            return state;
          }
        });
      },
      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              invites: state.invites,
            };
          } else if (state.type === 'Failed') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              invites: [],
            };
          } else if (state.type === 'New') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              invites: [],
            };
          }

          return state;
        });
      },
      removeProcessedInvite: (inviteId: InviteId): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            return {
              ...state,
              invites: state.invites.filter((invite) => invite._id === inviteId),
            };
          } else {
            return state;
          }
        });
      },
      tryResetError: dispatch.tryResetError,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = UserInvitesContext.useContext();

  React.useEffect(() => {
    init();
  }, [init]);

  return <>{children}</>;
};

export const UserInvitesContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return (
    <UserInvitesContext.Provider>
      <ContextInit>{children}</ContextInit>
    </UserInvitesContext.Provider>
  );
};

export const withUserInvitesContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithUserInvitesContextProvider(props: P) {
    return (
      <UserInvitesContextProvider>
        <Content {...props} />
      </UserInvitesContextProvider>
    );
  };
};

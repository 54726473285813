import { colors, Column, Line, Row, Title } from '@mero/components';
import { ResponsiveImage } from '@mero/shared-sdk/dist/assets';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { styles } from './SelectedImageMenu.styles';

export type Props = {
  onOrder: () => void;
  onDelete: () => void;
  onDismiss: () => void;
};

const MoreOptionsMenu: React.FC<Props> = ({ onOrder, onDelete, onDismiss }: Props): React.ReactElement | null => {
  const { t } = useTranslation('gallery');
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <Column style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            <Column>
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onOrder, onDismiss)}>
                <Title>{t('changeOrder')}</Title>
              </TouchableOpacity>
              <Column style={{ paddingHorizontal: 24 }}>
                <Line />
              </Column>
            </Column>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onDelete, onDismiss)}>
              <Title style={{ color: colors.RADICAL_RED }}>{t('multipleDelete')}</Title>
            </TouchableOpacity>
            <Line />
          </SafeAreaView>
        </Column>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default MoreOptionsMenu;

import { colors, Icon, Line, Spacer } from '@mero/components';
import * as React from 'react';
import { View, StyleProp, ViewStyle, StyleSheet, FlatList } from 'react-native';

import TextSkeleton from '@mero/components/lib/components/TextSkeleton';

import HeaderView from './HeaderView';
import ListHeaderView from './ListHeaderView';
import { styles } from './styles';

const itemStyle = StyleSheet.create({
  container: { flexDirection: 'row', paddingTop: 16, paddingBottom: 16, height: 78 },
  textContainer: { display: 'flex', flexDirection: 'column', marginLeft: 16 },
});

const MockItem: React.FC = () => {
  return (
    <View style={itemStyle.container}>
      <Icon type="checked" disabled={true} size={24} color={colors.ATHENS_GRAY} />
      <View style={itemStyle.textContainer}>
        <TextSkeleton width="medium" />
        <Spacer size="4" />
        <TextSkeleton width="long" />
      </View>
    </View>
  );
};

export type LoadingContactsViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly onClose: () => void;
};

const LoadingContactsView: React.FC<LoadingContactsViewProps> = ({ style, onClose }: LoadingContactsViewProps) => {
  const [contacts] = React.useState([1, 2, 3, 4]);

  const renderItem = React.useCallback(() => {
    return <MockItem />;
  }, []);

  return (
    <View style={StyleSheet.compose({ flex: 1 }, style)}>
      <HeaderView onClose={onClose} />
      <FlatList
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, styles.hrPadding)}
        data={contacts}
        keyExtractor={(_, idx) => `${idx}`}
        renderItem={renderItem}
        ListHeaderComponent={
          <ListHeaderView
            contactsCnt={0}
            selectedCnt={0}
            onSelectAll={() => undefined}
            onDeselectAll={() => undefined}
            query=""
            onQueryChange={() => undefined}
            showTitle
            showFooter
          />
        }
        ItemSeparatorComponent={Line}
        ListFooterComponent={Line}
      />
    </View>
  );
};

export default LoadingContactsView;

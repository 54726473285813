export const BusinessHours = [
  {
    from: {
      hour: 9,
      minute: 30,
    },
    to: {
      hour: 17,
      minute: 30,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 10,
      minute: 30,
    },
    to: {
      hour: 14,
      minute: 30,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 8,
      minute: 0,
    },
    to: {
      hour: 16,
      minute: 30,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 30,
    },
    to: {
      hour: 17,
      minute: 30,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 30,
    },
    to: {
      hour: 17,
      minute: 30,
    },
    active: true,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 30,
    },
    to: {
      hour: 17,
      minute: 30,
    },
    active: false,
    wholeDay: false,
  },
  {
    from: {
      hour: 9,
      minute: 30,
    },
    to: {
      hour: 17,
      minute: 30,
    },
    active: false,
    wholeDay: false,
  },
];

import { AppointmentStatus } from '@mero/api-sdk/src/calendar/appointment-status';
import * as React from 'react';
import { View, StyleProp } from 'react-native';

import { Label, Row } from '../../../../../../components/shared';
import * as colors from '@mero/components/src/styles/colors';

import * as icons from '../../../../../../assets/icons';
import styles from './styles';

interface BookingStatusLabelProps {
  icon?: keyof typeof icons;
  appointmentStatus: AppointmentStatus;
  isInPast: boolean;
}

interface AppointmentDataItem {
  text: string;
  textColor: string;
  backgroundColor?: string;
  containerStyle?: {
    borderColor: string;
    borderWidth: number;
  };
}

interface AppointmentData {
  cancelled: AppointmentDataItem;
  accepted: (isInPast: boolean) => AppointmentDataItem;
  noShow: AppointmentDataItem;
  pending: AppointmentDataItem;
  rejected: AppointmentDataItem;
}

const BookingStatusLabel: React.FC<BookingStatusLabelProps> = ({ icon, appointmentStatus, isInPast }) => {
  const APPOINTMENT_STATUS_DATA: AppointmentData = {
    cancelled: {
      text: 'ANULAT',
      textColor: colors.RADICAL_RED,
      backgroundColor: '#FBEBE7',
    },
    accepted: (isInPast: boolean) => ({
      text: isInPast ? 'FINALIZAT' : 'CONFIRMAT',
      textColor: isInPast ? colors.COMET : colors.SHAMROCK,
      backgroundColor: isInPast ? '#E9ECEF' : '#e9faf3',
    }),
    noShow: {
      text: 'NEPREZENTAT',
      textColor: colors.OUTRAGEOUS_ORANGE,
      backgroundColor: '#fae7e3',
    },
    pending: {
      text: 'ÎN AȘTEPTARE',
      textColor: colors.YELLOW_ORANGE,
      containerStyle: styles.waitingContainer,
    },
    rejected: {
      text: 'REFUZAT',
      textColor: colors.RADICAL_RED,
      backgroundColor: '#FBEBE7',
    },
  };

  const appointmentStatusData =
    appointmentStatus === 'accepted'
      ? APPOINTMENT_STATUS_DATA.accepted(isInPast)
      : APPOINTMENT_STATUS_DATA[appointmentStatus] || null;

  if (!appointmentStatusData?.text) return null;

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: appointmentStatusData.backgroundColor || undefined },
        appointmentStatusData.containerStyle,
      ]}
    >
      <Row>
        <Label style={[{ color: appointmentStatusData.textColor }, styles.text]}>{appointmentStatusData?.text}</Label>
      </Row>
    </View>
  );
};

export default BookingStatusLabel;

import { createModelContext } from '@mero/components';
import * as Application from 'expo-application';
import * as Notifications from 'expo-notifications';
import * as React from 'react';

import log from '../../utils/log';
import { AuthContext, meroApi } from '../AuthContext';
import { sendTokenToIntercom } from '../IntercomContext/utils';
import { PushClientSubscriptionContext } from '../PushClientSubscriptionContext';

type State =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Subscribing';
    }
  | {
      readonly type: 'Subscribed';
      readonly token: string;
    }
  | {
      readonly type: 'Unsubscribing';
    }
  | {
      readonly type: 'Unsubscribed';
    };

const defaultState = (): State => ({
  type: 'New',
});

const trySavePushToken = async (pushToken: Notifications.DevicePushToken): Promise<void> => {
  const bundleId = Application.applicationId;

  if (bundleId) {
    switch (pushToken.type) {
      case 'android':
        log.debug('registerUserDevice', pushToken.data, 'platform=android');
        return await meroApi.notifications.registerUserDevice({
          identifier: pushToken.data,
          platform: 'Android',
          bundleId: bundleId,
        });
      case 'ios':
        log.debug('registerUserDevice', pushToken.data, 'platform=ios');
        return await meroApi.notifications.registerUserDevice({
          identifier: pushToken.data,
          platform: 'IOS',
          bundleId: bundleId,
        });
    }
  }
};

export const PushServerSubscriptionContext = createModelContext(
  defaultState(),
  {
    trySetSubscribed: (s, token: string) => {
      if (s.type === 'Subscribing') {
        return {
          type: 'Subscribed',
          token: token,
        };
      }

      return s;
    },
    trySetUnsubscribed: (s) => {
      if (s.type === 'Unsubscribing') {
        return {
          type: 'Unsubscribed',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: State) => State): State => fn(s),
  },
  (dispatch) => {
    return {
      trySubscribe: (pushToken: Notifications.DevicePushToken): void => {
        if (pushToken.type === 'ios' || pushToken.type === 'android') {
          dispatch.mutate((s) => {
            if (s.type === 'New' || s.type === 'Unsubscribed' || s.type === 'Subscribed') {
              const currentToken = s.type === 'Subscribed' ? s.token : undefined;

              if (pushToken.data !== currentToken) {
                const save = async () => {
                  try {
                    const userDevice = await meroApi.notifications.getUserDevice(pushToken.data);
                    if (!userDevice) {
                      log.debug('Token not on server, going to save ...');
                      // user device "gone"? save again
                      await trySavePushToken(pushToken);
                      log.debug(`Token ${JSON.stringify(pushToken.data)} registered`);
                    } else {
                      log.debug(`Token ${JSON.stringify(pushToken.data)} already registered`);
                    }
                  } catch (e) {
                    log.error('Failed to validate push token with server', e);
                  }

                  dispatch.trySetSubscribed(pushToken.data);
                };

                save().catch(log.exception);

                return {
                  type: 'Subscribing',
                };
              } else {
                log.debug(`Token ${pushToken.data} already subscribed, skipping save request`);

                return {
                  type: 'Subscribed',
                  token: pushToken.data,
                };
              }
            } else {
              return s;
            }
          });
        }
      },
      tryUnsubscribe: async (): Promise<void> => {
        return new Promise((resolve, reject) => {
          dispatch.mutate((s) => {
            if (s.type === 'Subscribed') {
              const unsubscribe = async () => {
                log.debug(`Try unregister push token ${s.token}`);

                try {
                  await meroApi.notifications.unregisterUserDevice({ identifier: s.token });
                  log.debug(`Push token ${s.token} unregistered`);
                } catch (e) {
                  // FIXME: ignore this error, do not pollute sentry
                  log.exception(e);
                }

                dispatch.trySetUnsubscribed();
              };

              unsubscribe().then(resolve).catch(reject);

              return {
                type: 'Unsubscribing',
              };
            } else {
              resolve(); // not subscribed, ignore
              return s;
            }
          });
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [authState] = AuthContext.useContext();
  const [clientPushState] = PushClientSubscriptionContext.useContext();
  const [, { trySubscribe: trySubscribePush }] = PushServerSubscriptionContext.useContext();

  /**
   * This effect will send client push subscription token whenever available or updated
   */
  React.useEffect(() => {
    if (
      authState.type === 'Authorized' &&
      clientPushState.type === 'Loaded' &&
      clientPushState.pushSubscriptionStatus.type === 'Subscribed'
    ) {
      const pushToken = clientPushState.pushSubscriptionStatus.pushToken;
      trySubscribePush(pushToken);
      if (pushToken.type === 'ios' || pushToken.type === 'android') {
        sendTokenToIntercom(pushToken.data);
      }
    }
  }, [authState, clientPushState, trySubscribePush]);

  return <>{children}</>;
};

export const PushServerSubscriptionContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return (
    <PushServerSubscriptionContext.Provider>
      <ContextInit>{children}</ContextInit>
    </PushServerSubscriptionContext.Provider>
  );
};

export const withPushServerSubscriptionContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithPushServerSubscriptionContextProvider(props: P) {
    return (
      <PushServerSubscriptionContextProvider>
        <Content {...props} />
      </PushServerSubscriptionContextProvider>
    );
  };
};

import { AppointedDay, CalendarId } from '@mero/api-sdk/dist/calendar';
import { colors, Icon, Row } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Platform, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { LocaleConfig } from 'react-native-calendars';
import Svg, { G, Circle } from 'react-native-svg';

import { meroApi } from '../../../contexts/AuthContext';
import { LocalDateObject } from '../../../contexts/CalendarContext';
import log from '../../../utils/log';

type ProgressCircleProps = {
  strokeWidth?: number;
  radius?: number;
  percent: number;
  color: string;
  text?: string;
  styles?: {
    text?: StyleProp<TextStyle>;
  };
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  percent,
  color,
  text,
  styles,
  strokeWidth = 2,
  radius = 17,
}) => {
  const diameter = radius * 2;
  const svgSize = diameter + strokeWidth;
  const circumference = 2 * Math.PI * radius;

  const progress = (percent / 100) * circumference;

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', width: svgSize, height: svgSize }}>
      <Svg width={svgSize} height={svgSize}>
        <G rotation="-90" origin={`${radius + strokeWidth / 2}, ${radius + strokeWidth / 2}`}>
          <Circle
            stroke={colors.WHITE}
            fill="none"
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference}
          />
          <Circle
            stroke={color}
            fill="none"
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference - progress}
            strokeLinecap="round"
          />
        </G>
      </Svg>
      <Text style={[{ position: 'absolute' }, styles?.text]}>{text}</Text>
    </View>
  );
};

LocaleConfig.locales['ro'] = {
  monthNames: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ],
  monthNamesShort: ['Ian.', 'Feb.', 'Mar.', 'Apr.', 'Mai', 'Iun.', 'Iul.', 'Aug.', 'Sep.', 'Oct.', 'Noi.', 'Dec.'],
  dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
  dayNamesShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  // @ts-expect-error
  today: 'Astăzi',
};
LocaleConfig.defaultLocale = 'ro';

const Arrow = (direction: 'left' | 'right') =>
  direction === 'left' ? (
    <View style={{ marginLeft: -8 }}>
      <Icon type="arrow-left" size={32} />
    </View>
  ) : (
    <View style={{ marginRight: -8 }}>
      <Icon type="arrow-right" size={32} />
    </View>
  );

export type Props = {
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Selected date
   */
  readonly selected?: LocalDateObject;

  readonly minDate?: Date;

  readonly selectedCalendarIds: CalendarId[];

  /**
   * Called when new date is selected
   */
  readonly onDateSelected?: (selected: LocalDateObject) => void;
};

const Theme = {
  arrowColor: colors.DARK_BLUE,
  todayTextColor: colors.DARK_BLUE,
  // Week days header
  textDayHeaderFontSize: 12,
  textDayHeaderFontFamily: 'open-sans-bold',
  textSectionTitleColor: '#757575',
  // Month title section
  textMonthFontSize: 19,
  textMonthFontFamily: 'open-sans',
  monthTextColor: colors.BLACK,
  // calendar days
  dayTextColor: '#393939',
  textDisabledColor: '#C3C3C3',
  selectedDayTextColor: colors.WHITE,
  selectedDayBackgroundColor: colors.DARK_BLUE,
};

export const DatePickerPopup: React.FC<Props> = ({
  style,
  selected,
  selectedCalendarIds,
  minDate,
  onDateSelected = () => undefined,
}: Props) => {
  const [appointedDaysMap, setAppointedDaysMap] = React.useState<Record<string, AppointedDay>>({});
  const [displayedMonth, setDisplayedMonth] = React.useState(
    selected
      ? DateTime.fromObject(selected, { zone: 'utc' }).startOf('day').startOf('month')
      : DateTime.fromJSDate(new Date()).toUTC().startOf('day').startOf('month'),
  );

  /**
   * Effect used to load appointed days from api.
   */
  React.useEffect(() => {
    const asyncLoad = async () => {
      try {
        const today = DateTime.fromJSDate(new Date(), { zone: 'utc' });
        let from = displayedMonth.startOf('week');

        // for current month, only load starting from today
        if (from.diff(today, 'days').days < 0) {
          from = today;
        }
        const to = displayedMonth.startOf('week').plus({ months: 1 }).endOf('week');

        // do not load if entire interval is in past
        if (to.diff(today, 'days').days < 0) return;

        const appointedDays = await Promise.all(
          selectedCalendarIds.map((calendarId) =>
            meroApi.calendar.getAppointedDays({
              calendarId,
              from: from.toJSDate(),
              to: to.toJSDate(),
            }),
          ),
        );

        const newAppointedDaysMap: Record<string, AppointedDay> = {};
        appointedDays.forEach((calendarAppointedDays) => {
          calendarAppointedDays.forEach((day) => {
            const dateKey = DateTime.fromISO(day.date).toFormat('yyyy-MM-dd');
            const current = newAppointedDaysMap[dateKey] ?? { date: day.date, total: 0, used: 0, active: false };
            newAppointedDaysMap[dateKey] = {
              date: day.date,
              used: current.used + day.used,
              total: current.total + day.total,
              active: current.active || day.active,
            };
          });
        });

        setAppointedDaysMap(newAppointedDaysMap);
      } catch (error) {
        log.error('Failed to load appointed days', error);
      }
    };

    asyncLoad();
  }, [selectedCalendarIds, displayedMonth]);

  return (
    <Calendar
      style={style}
      // Initially visible month. Default = Date()
      minDate={minDate?.toISOString()}
      current={selected ? LocalDateObject.format(selected) : undefined}
      theme={Theme}
      onDayPress={onDateSelected}
      monthFormat={'MMMM yyyy'}
      firstDay={1}
      onMonthChange={(date) => {
        setDisplayedMonth(DateTime.fromISO(date.dateString, { zone: 'utc' }).startOf('month'));
      }}
      // Enable the option to swipe between months. Default = false
      enableSwipeMonths={true}
      renderArrow={Arrow}
      dayComponent={({ date, state, onPress }) => {
        const isSelected = date?.dateString === selected;
        // compute percent of usage
        const appointedDay = appointedDaysMap[date?.dateString ?? ''] ?? { date: date?.dateString, used: 0, total: 0 };
        let percent = 0;
        if (appointedDay.used > 0 && appointedDay.total > 0) {
          percent = Math.min((appointedDay.used * 100) / appointedDay.total, 100);
        }

        const progressColor =
          percent < 37.5 ? colors.SHAMROCK : percent < 75 ? colors.YELLOW_ORANGE : colors.RADICAL_RED;

        const parentStyles = {
          alignItems: 'center' as const,
          justifyContent: 'center' as const,
          width: 37,
          height: 37,
          borderWidth: 2,
          borderColor: 'white',
          borderRadius: 100,
          marginVertical: -3,
          ...(isSelected && {
            backgroundColor: colors.DARK_BLUE,
            borderWidth: 0,
          }),
        };

        if (!appointedDay.active) {
          state = 'disabled';
        }

        const textStyles = {
          fontFamily: 'open-sans',
          fontSize: 16,
          color: '#393939',
          ...(state === 'disabled' && {
            color: '#C3C3C3',
          }),
          ...(state === 'today' && {
            color: colors.DARK_BLUE,
          }),
          ...(isSelected && {
            color: colors.WHITE,
          }),
        };

        const baseDeg = percent <= 50 ? 45 : -135;
        const progressStyles = {
          position: 'absolute' as const,
          width: 37,
          height: 37,
          borderWidth: 2,
          borderColor: 'transparent',
          borderTopColor: progressColor,
          borderRightColor: progressColor,
          borderRadius: 100,
          transform: [{ rotateZ: `${baseDeg}deg` }],
        };

        // compute the rotation for the offset layer (45deg base + based on percent)
        // const rotateDeg = baseDeg + percent * 3.6;
        const rotateDeg = percent <= 50 ? baseDeg + percent * 3.6 : baseDeg + (percent - 50) * 3.6;
        const offsetProgressStyles = {
          position: 'absolute' as const,
          width: 39,
          height: 39,
          borderWidth: 4,
          borderColor: 'transparent',
          borderTopColor: 'white',
          borderRightColor: 'white',
          borderRadius: 100,
          transform: [{ rotateZ: `${rotateDeg}deg` }],
        };

        return Platform.OS === 'android' ? (
          <TouchableOpacity onPress={() => onPress && onPress(date)}>
            <Row style={parentStyles}>
              <ProgressCircle
                percent={percent}
                color={progressColor}
                text={date?.day.toString()}
                styles={{ text: textStyles }}
              />
            </Row>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => onPress && onPress(date)}>
            <Row style={parentStyles}>
              {!isSelected && (
                <>
                  <View style={progressStyles}></View>
                  <View style={offsetProgressStyles}></View>
                  {percent > 50 && <View style={[progressStyles, { transform: [{ rotateZ: `45deg` }] }]}></View>}
                </>
              )}
              <Text style={textStyles}>{date?.day}</Text>
            </Row>
          </TouchableOpacity>
        );
      }}
    />
  );
};

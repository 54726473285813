import { StyleSheet } from 'react-native';

import { colors } from '../../../../../../styles';

const styles = StyleSheet.create({
  boostDescription: { textAlign: 'center' },
  labelWrapper: { flex: 2, paddingHorizontal: 6 },
  claimClientDescription: {
    fontFamily: 'open-sans-semibold',
    color: colors.COMET,
    textAlign: 'center',
    paddingVertical: 12,
  },
});

export default styles;

import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { DARK_BLUE } from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const GiftCardSmall = ({ size = 24, color = DARK_BLUE, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-40 -622.545)">
      <G transform="translate(46.131 628.256)">
        <Path
          d="M10.9,2.515V8.384H.838V2.515M0,0H11.738V2.515H0Z"
          transform="translate(0 4.193)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.1"
        />
        <Path
          d="M2.517,4.193v8.384M5.871,4.193v8.384M2.978.63A1.551,1.551,0,0,0,1.679,0,1.677,1.677,0,0,0,.568,2.935c.88.776,3.626,1.258,3.626,1.258A6.824,6.824,0,0,0,2.978.63ZM5.41.63A1.551,1.551,0,0,1,6.709,0,1.677,1.677,0,0,1,7.82,2.935c-.88.776-3.626,1.258-3.626,1.258A6.824,6.824,0,0,1,5.41.63Z"
          transform="translate(1.675 0)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.1"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(GiftCardSmall);

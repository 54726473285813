import {
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  Row,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import { ResponsiveImage } from '@mero/shared-sdk/dist/assets';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Image, FlatList } from 'react-native';

import { styles } from '../../../../../../components/AddBookingScreen/styles';
import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../../contexts/CurrentBusiness';
import { PageGallerySettingsStackParamList } from '../../../../../../types';
import log from '../../../../../../utils/log';

export type Props = StackScreenProps<PageGallerySettingsStackParamList, 'PageGalleryOrder'>;

const PageGalleryOrder: React.FC<Props> = () => {
  const { t } = useTranslation('gallery');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const showError = useShowError();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [images, setImages] = React.useState<ResponsiveImage[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const orderImages = (index: number, direction: -1 | 1) => () => {
    if ((index === 0 && direction === -1) || (index === images.length - 1 && direction === 1)) {
      return;
    }

    const newImagesOrder = [...images];
    const worker = newImagesOrder[index];
    newImagesOrder.splice(index, 1);
    newImagesOrder.splice(index + direction, 0, worker);
    setImages(newImagesOrder);
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsLoading(true);

    try {
      const imageIds = images.map((i) => i._id);
      await meroApi.pages.reorderImages({
        pageId: pageState.page.details._id,
        imageIds,
      });
      reloadAsync();
    } catch (error) {
      showError(error, t('failedToChangeOrder'));
      log.error('Error reordering images', error);
    }

    goBack();
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setImages(pageState.page.details.images);
    }
  }, [pageState]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        text={t('orderTitle')}
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
      />
      <Column style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('orderTitle')}</H1>
        <Spacer size={24} />
        <FormCard paddings="none" style={{ padding: 16, flex: 1, marginBottom: 96 }} dropShaddow rounded>
          <FlatList
            data={images}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              return (
                <Row style={{ alignItems: 'center' }}>
                  <Column style={{ flex: 1 }}>
                    <Image source={{ uri: item.small }} style={{ width: 70, height: 70, borderRadius: 4 }} />
                  </Column>

                  <Row style={{ paddingRight: 12 }}>
                    {index > 0 && (
                      <TouchableOpacity style={{ paddingRight: 12 }} onPress={orderImages(index, -1)}>
                        <Icon type="go-up" color={colors.DARK_BLUE} />
                      </TouchableOpacity>
                    )}
                    {index < images.length - 1 ? (
                      <Column>
                        <TouchableOpacity style={{ alignItems: 'flex-end' }} onPress={orderImages(index, 1)}>
                          <Icon type="go-down" color={colors.DARK_BLUE} />
                        </TouchableOpacity>
                        {index === 0 && (
                          <Column
                            style={{
                              borderRadius: 4,
                              backgroundColor: '#E9ECEF',
                              paddingHorizontal: 8,
                              paddingVertical: 4,
                              marginTop: 16,
                            }}
                          >
                            <Title style={{ fontSize: 12 }}>{t('mainImage').toUpperCase()}</Title>
                          </Column>
                        )}
                      </Column>
                    ) : (
                      <Column style={{ width: 24, height: 24 }} />
                    )}
                  </Row>
                </Row>
              );
            }}
            keyExtractor={(item) => item._id}
            ItemSeparatorComponent={() => (
              <>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
              </>
            )}
          />
        </FormCard>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={saveChanges} disabled={isLoading} />
          ) : (
            <Button
              disabled={isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default PageGalleryOrder;

import { StyleSheet } from 'react-native';

export const HEADER_HEIGHT = 60;

export const styles = StyleSheet.create({
  headerStyle: {
    position: 'relative',
    height: HEADER_HEIGHT,
    backgroundColor: '#ffffff',
  },
  scrollPadStyle: {
    position: 'absolute',
    width: HEADER_HEIGHT - 5,
  },
  weekdays: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffffff',
  },
  weekDates: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'space-between',
    marginLeft: 60,
    marginRight: 15,
    backgroundColor: '#ffffff',
  },
  weekday: {
    width: 32,
    textAlign: 'center',
    color: '#000000',
  },
  selectedDay: {
    borderRadius: 25,
    backgroundColor: '#080DE0',
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
  },
  selectedDayText: {
    color: '#ffffff',
  },
  normalDayText: {
    color: '#172B4D',
  },
  differentMonthDayText: {
    color: '#ADADAD',
  },
  todayDayText: {
    color: '#080DE0',
  },
});

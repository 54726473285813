import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import {
  Body,
  colors,
  H2s,
  Icon,
  Row,
  SearchTextInput,
  SmallBody,
  Title,
  useShowError,
  styles as meroStyles,
  HSpacer,
} from '@mero/components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import { getMeasure } from '../../../utils/products';
import { convertScale, scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import { DefaultProductImage } from './ProductSummaryCard';

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

export type Filters =
  | Parameters<typeof meroApi.memberships.getPagePurchasedMemberships>[0]['query']['status']
  | 'HasDebt';

type Query = Pick<Parameters<typeof meroApi.pro.productSales.getProductSales>[0], 'search' | 'sold'>;

const ProductsTabWeb: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const showError = useShowError();
  const isFocused = useIsFocused();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setFilter] = React.useState<Filters>();
  const [soldProducts, setSoldProducts] = React.useState<ProductSale[]>([]);
  const [nextPage, setNextPage] = React.useState<string>();
  const loadingNextPage = React.useRef('');

  const loadProducts = async (query: Query, shouldAppend: boolean, next?: string) => {
    setIsLoading(true);
    try {
      loadingNextPage.current = next ?? '';
      const { data: products, next: nextPage } = await meroApi.pro.productSales.getProductSales({
        limit: 10,
        pageId: page.details._id,
        page: next,
        ...query,
      });

      setNextPage(nextPage);
      setSoldProducts(shouldAppend ? [...soldProducts, ...products] : products);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const initialRender = React.useRef(true);

  const loadMoreProducts = () => {
    if (checkoutsState.type === 'Loaded' && nextPage && nextPage !== loadingNextPage.current) {
      loadProducts(
        {
          sold: checkoutsState.interval,
          search: searchQuery,
          ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
        },
        true,
        nextPage,
      );
    }
  };

  React.useEffect(() => {
    let timeout: number;
    if (checkoutsState.type === 'Loaded' && isFocused) {
      if (initialRender.current) {
        loadProducts(
          {
            sold: checkoutsState.interval,
            search: searchQuery,
            ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
          },
          false,
        );
        initialRender.current = false;
      } else {
        timeout = window.setTimeout(() => {
          loadProducts(
            {
              sold: checkoutsState.interval,
              search: searchQuery,
              ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
            },
            false,
          );
        }, 500);
      }
    } else if (!isFocused) {
      initialRender.current = true;
    }

    return () => window.clearTimeout(timeout);
  }, [
    searchQuery,
    checkoutsState.type,
    checkoutsState.type === 'Loaded' ? checkoutsState.interval : undefined,
    page.details._id,
    filter,
    isFocused,
  ]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      <InfiniteScrollView onEndReached={loadMoreProducts} withLoading>
        <FormCard dropShaddow paddings="none" rounded style={{ marginHorizontal: 24 }}>
          <Row
            style={{ flex: 1, paddingLeft: 24, paddingRight: 16, paddingVertical: 16, zIndex: 1 }}
            alignItems="center"
          >
            <Column style={{ flex: 1 }}>
              <H2s>{t('products')}</H2s>
            </Column>
            <Column style={{ minWidth: 327 }}>
              <SearchTextInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder={t('searchProductsPlaceholder')}
              />
            </Column>
          </Row>
          <Line />
          {/* Table Header */}
          <Row style={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 12 }}>
            <Column style={{ width: '25%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('productColumn')}</Title>
            </Column>
            <Column style={{ width: '5%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('quantityColumn')}</Title>
            </Column>
            <Column style={{ width: '20%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('clientColumn')}</Title>
            </Column>
            <Column style={{ width: '15%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('saleDateColumn')}</Title>
            </Column>
            <Column style={{ width: '10%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('paymentColumn')}</Title>
            </Column>
            <Column style={{ width: '5%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('vatColumn')}</Title>
            </Column>
            <Column style={{ width: '20%' }}>
              <Title style={{ fontSize: 12, textAlign: 'right', paddingRight: 40 }}>{t('totalColumn')}</Title>
            </Column>
          </Row>
          <Line />
          {/* Table Body - Finished */}
          {soldProducts.map((product, index) => {
            const measure = getMeasure(product.measure);

            return (
              <React.Fragment key={`${product.productId}${product.transactionId}`}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    alignItems: 'center',
                    paddingVertical: 16,
                  }}
                  onPress={() => {
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: {
                        screen: 'ProceedDetailsScreen',
                        params: { checkoutTransactionId: product.transactionId, backMode: 'one' },
                      },
                    });
                  }}
                >
                  <Column style={{ width: '25%' }}>
                    <Row>
                      {product.mainImage?.thumbnail ? (
                        <Image
                          source={{ uri: product.mainImage.thumbnail }}
                          style={{ width: 48, height: 48, borderRadius: 4 }}
                        />
                      ) : (
                        <View style={{ width: 48, height: 48 }}>{DefaultProductImage}</View>
                      )}
                      <Column style={{ paddingLeft: 12, flex: 1 }}>
                        <SmallBody style={[meroStyles.text.semibold]}>{product.name}</SmallBody>
                        {measure || product.code ? (
                          <SmallBody style={{ color: colors.COMET, fontSize: 12, paddingTop: 4 }}>
                            {measure}
                            {measure && product.code ? ` - ` : ''}
                            {product.code}
                          </SmallBody>
                        ) : null}
                      </Column>
                    </Row>
                  </Column>
                  <Column style={{ width: '5%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>{scaledToString(product.quantity)}</SmallBody>
                  </Column>
                  <Column style={{ width: '20%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>
                      {nameGenerator(product.client ?? {}, t('unknownUser'))}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '15%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>
                      {DateTime.fromJSDate(product.createdAt).toFormat('dd MMM. yyyy').toLowerCase()}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '10%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>#{product.code.toUpperCase()}</SmallBody>
                  </Column>
                  <Column style={{ width: '5%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>
                      {scaledToString(convertScale(product.vatRate, 0))}%
                    </SmallBody>
                  </Column>

                  <Row style={{ width: '20%', paddingRight: 4 }} alignItems="flex-end">
                    <Column style={{ flex: 1, paddingRight: 16 }} justifyContent="flex-end">
                      <SmallBody
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {scaledToString(product.total.amount)} {t(product.total.unit)}
                      </SmallBody>
                    </Column>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </Row>
                </TouchableOpacity>
                <Line />
                {index < checkoutsState.finished.data.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {!isLoading && soldProducts.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s>{t('noProductAvailable')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noMembershipSearchDescription')}</Body>
              </Column>
            </>
          )}
        </FormCard>
        <Column style={{ zIndex: -1 }}>
          <Spacer size={64} />
        </Column>
      </InfiniteScrollView>
      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(ProductsTabWeb, CurrentBusiness);

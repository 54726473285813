import { CheckoutTotals } from '@mero/api-sdk/dist/checkout/checkoutTotals';
import {
  Body,
  Button,
  colors,
  Column,
  ConfirmBox,
  DismissKeyboard,
  Line,
  ModalOverlay,
  Row,
  SmallBody,
  Spacer,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DiscountPercent } from '@mero/shared-sdk/dist/numbers/discountPercent';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import H1 from '@mero/components/lib/components/Text/H1';

import { ItemDiscount } from '../../../contexts/CheckoutFormContext';
import { roundToDecimals } from '../../../utils/number';
import { scaledToString } from '../../../utils/scaled';
import DiscountComponent, { getPercentage, getValue } from './DiscountComponent';

type Props = {
  isEdit: boolean;
  discountItem: ItemDiscount;
  onSave: (discount: ItemDiscount) => void;
  onDelete: (discount: ItemDiscount) => void;
  onCancel: () => void;
  calculateTotals: (discount?: ItemDiscount) => CheckoutTotals<ScaledNumber, MeroUnits.Any>;
};

const EditDiscount: React.FC<Props> = ({ isEdit, discountItem, calculateTotals, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation('checkout');

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [discount, setDiscount] = React.useState<ItemDiscount>(discountItem);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    if (
      (discount.type === 'Value' && ScaledNumber.toNumber(discount.value.amount) > 0) ||
      (discount.type === 'Percent' && ScaledNumber.toNumber(discount.percent) > 0)
    ) {
      onSave(discount);
    } else {
      onDelete(discount);
    }
  };

  const updateDiscount = React.useCallback(
    (value: number, percentage: number) => {
      if (discount.type === 'Value') {
        setDiscount({
          type: 'Value',
          value: {
            ...discount.value,
            amount: ScaledNumber.fromNumber(value, 2),
          },
        });
      }

      if (discount.type === 'Percent') {
        setDiscount({
          type: 'Percent',
          percent: ScaledNumber.fromNumber(percentage, 2) as DiscountPercent<ScaledNumber>,
        });
      }
    },
    [discount],
  );

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: save,
  };

  const totals = calculateTotals(discount);

  const { discountPercentage, discountValue } = React.useMemo(() => {
    const totalsWithoutDiscount = calculateTotals();
    const subtotal = ScaledNumber.toNumber(totalsWithoutDiscount.subtotal.amount);

    if (discountItem.type === 'Value') {
      const discountValue = ScaledNumber.toNumber(discountItem.value.amount);
      return {
        discountPercentage: roundToDecimals(getPercentage(subtotal, discountValue)),
        discountValue,
      };
    }

    if (discountItem.type === 'Percent') {
      const discountPercentage = ScaledNumber.toNumber(discountItem.percent);
      return {
        discountPercentage,
        discountValue: roundToDecimals(getValue(subtotal, discountPercentage)),
      };
    }

    return {
      discountPercentage: 0,
      discountValue: 0,
    };
  }, [discountItem, calculateTotals]);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={isEdit ? t('changeDiscount') : t('addDiscount')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{isEdit ? t('changeDiscount') : t('addDiscount')}</H1>
              <Spacer size={16} />
              <DiscountComponent
                price={ScaledNumber.toNumber(totals.subtotal.amount)}
                value={discountValue}
                percentage={discountPercentage}
                onUpdate={updateDiscount}
                type={discountItem.type === 'Percent' ? 'percentage' : 'value'}
              />
              <Column style={{ paddingTop: 24 }}>
                <Row>
                  <SmallBody style={{ width: '70%' }}>{t('subtotal')}</SmallBody>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', width: '30%', textAlign: 'right' }}>
                    {scaledToString(totals.subtotal.amount)} {t(totals.subtotal.unit)}
                  </SmallBody>
                </Row>
                <Row style={{ paddingTop: 12 }}>
                  <SmallBody style={{ width: '70%' }}>{t('discount')}</SmallBody>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', width: '30%', textAlign: 'right' }}>
                    {totals.discount ? scaledToString(totals.discount.amount) : 0}{' '}
                    {t(totals.discount ? totals.discount.unit : 'RON')}
                  </SmallBody>
                </Row>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Row>
                  <Body style={{ width: '70%' }}>{t('total')}</Body>
                  <Body style={{ fontFamily: 'open-sans-semibold', width: '30%', textAlign: 'right' }}>
                    {scaledToString(totals.total.amount)} {t(totals.total.unit)}
                  </Body>
                </Row>
              </Column>
              <Spacer size={24} />
              {isEdit && (
                <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                  <Button
                    padding={24}
                    expand={false}
                    size="medium"
                    backgroundColor={colors.WHITE}
                    color={colors.RADICAL_RED}
                    text={t('delete')}
                    onClick={() => onDelete(discountItem)}
                  />
                </Row>
              )}
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditDiscount;

import { SubscriptionInfo } from '@mero/api-sdk/dist/payments/subscription-info';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  MeroHeader,
  colors,
  Column,
  H1,
  Spacer,
  FormCard,
  Button,
  Row,
  Checkbox,
  SmallBody,
  Title,
  useToast,
  Body,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { getWeekDays } from '../../../../../components/Calendar/BigCalendar/utils';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import { RADICAL_RED } from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsEditStackParamList } from '../../../../../types';
import * as BitPacker from '../../../../../utils/bit-packer';
import log from '../../../../../utils/log';
import { PriceType } from '../../../ServicesScreen/ServiceScreen';
import { styles } from './ProEditServiceSettingsScreen.styles';

export type Props = StackScreenProps<ProsEditStackParamList, 'ProEditServiceSettings'>;

const ProEditServiceSettingsScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('pros');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();

  const { workerId, serviceId } = route.params;

  const block = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showErrors, setShowErrors] = React.useState(false);

  const [proDetails, setProDetails] = React.useState<SavedWorker | null>(null);

  const [publicService, setPublicService] = React.useState(false);
  const [manualApproval, setManualApproval] = React.useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = React.useState<SubscriptionInfo | undefined>();

  const shouldForceManualApproval = subscriptionInfo?.shouldForceManualApproval;

  const [availableDays, setAvailableDays] = React.useState([true, true, true, true, true, true, true]);

  const changeDay = (index: number) => () => {
    const newDays = availableDays.map((day, idx) => (idx === index ? !day : day));
    setAvailableDays(newDays);
  };

  const arrayToBitBucket = (arr: boolean[]) =>
    arr.reduce((acc, v, dayOfWeek) => BitPacker[v ? 'setFlag' : 'unsetFlag'](acc, 1 << dayOfWeek), 0);

  const getAvailableDays = (bitBucket: number) => {
    return Array(7)
      .fill(false)
      .map((_, dayOfWeek) => BitPacker.isFlagSet(bitBucket, 1 << dayOfWeek));
  };

  const saveChanges = async () => {
    if (!proDetails || pageState.type !== 'Loaded') {
      return;
    }
    if (availableDays.every((d) => !d && publicService)) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      const workerServices = proDetails.services
        .filter((s) => s._id !== serviceId)
        .map((s) => ({
          ...s,
          price: {
            ...s.price,
            type: PriceType[s.price.type],
          },
        }));

      const service = proDetails.services.find((s) => s._id === serviceId);

      if (service) {
        await meroApi.pages.updatePageWorkerServices({
          pageId: pageState.page.details._id,
          workerId: proDetails._id,
          services: [
            {
              ...service,
              isCustom: true,
              automaticApproval: !manualApproval,
              private: !publicService,
              availableDays: arrayToBitBucket(availableDays),
              price: {
                ...service.price,
                //@ts-expect-error @TODO Price type has a different format...
                type: PriceType[service.price.type],
              },
            },
            //@ts-expect-error @TODO Price type has a different format...
            ...workerServices,
          ],
        });

        // If there is only one worker on the page, page services and worker services will be kept in sync
        if (pageState.page.workers.length === 1) {
          // Find corresponding page service
          const pageService = pageState.page.details.services.find((s) => s._id === serviceId);

          if (pageService) {
            await meroApi.pages.updatePageService({
              pageId: pageState.page.details._id,
              serviceId,
              workerIds: [pageState.page.workers[0]._id],
              //@ts-ignore
              service: {
                ...pageService,
                automaticApproval: !manualApproval,
                private: !publicService,
                availableDays: arrayToBitBucket(availableDays),
                price: {
                  ...service.price,
                  //@ts-expect-error @TODO Price type has a different format...
                  type: PriceType[service.price.type],
                },
              },
            });
          }
        }

        await getPageWorkersAndServices(pageState.page.details._id);

        toast.show({
          type: 'success',
          text: t('savedSuccessfully'),
        });

        goBack();
      }
    } catch (error) {
      log.error('Error updating service', error);
      toast.show({
        type: 'error',
        text: t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);
      if (worker) {
        const service = worker.services.find((service) => service._id === serviceId);

        setPublicService(!service?.private);
        setManualApproval(!service?.automaticApproval);

        if (service) {
          setAvailableDays(getAvailableDays(service?.availableDays));
        }
      }
      setProDetails(worker ?? null);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const getSubscription = async () => {
        try {
          const subscription = await meroApi.payments.getPageSubscription(pageState.page.details._id);
          setSubscriptionInfo(subscription);
        } catch (err) {
          log.error('Failed to get subscription information', err);
        }
      };

      getSubscription();
    }
  }, [pageState.type]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('profileCalendar')} />
      <Column style={[styles.formMargin, styles.flex]}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('profileCalendar')}</H1>
        <Spacer size={32} />
        <FormCard dropShaddow rounded paddings="button">
          <Row style={{ paddingRight: 16 }}>
            <Column>
              <Checkbox
                value={publicService}
                color="blue"
                disabled={false}
                onValueChange={() => setPublicService(!publicService)}
              />
            </Column>
            <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={() => setPublicService(!publicService)}>
              <Title>{t('proServiceSettingsLabel1')}</Title>
              <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription1')}</SmallBody>
            </TouchableOpacity>
          </Row>
          <Row style={{ paddingTop: 24, paddingRight: 16 }}>
            <Column>
              <Checkbox
                value={manualApproval || shouldForceManualApproval}
                color="blue"
                disabled={shouldForceManualApproval}
                onValueChange={() => setManualApproval(!manualApproval)}
              />
            </Column>
            <TouchableOpacity
              style={{ paddingLeft: 12, flex: 1 }}
              onPress={() => setManualApproval(!manualApproval)}
              disabled={shouldForceManualApproval}
            >
              <Title>{t('proServiceSettingsLabel2')}</Title>
              <Column>
                <SmallBody style={{ color: colors.COMET }}>{t('proServiceSettingsDescription2')}</SmallBody>
              </Column>
            </TouchableOpacity>
          </Row>
        </FormCard>
        {publicService ? (
          <>
            <Spacer size={16} />
            <FormCard dropShaddow rounded paddings="button">
              <Column>
                <Body style={{ fontFamily: 'open-sans-semibold' }}>Disponibilitate serviciu</Body>
                <Spacer size={4} />
                <SmallBody style={{ color: colors.COMET }}>{t('daySelectorDescription')}</SmallBody>
                <Spacer size={16} />
                <Row justifyContent={'space-between'} style={{ paddingTop: 6 }}>
                  {getWeekDays().map((day, index) => (
                    <Column key={day} justifyContent="center" alignItems="center">
                      <Checkbox
                        value={availableDays[index]}
                        color="blue"
                        disabled={false}
                        onValueChange={changeDay(index)}
                      />
                      <SmallBody style={{ paddingTop: 10 }}>{day.slice(0, 3)}</SmallBody>
                    </Column>
                  ))}
                </Row>
                {showErrors && availableDays.every((d) => !d) && (
                  <>
                    <Spacer size={2} />
                    <SmallBody style={{ color: RADICAL_RED }}>
                      Alege cel puțin o zi in care vei oferi serviciul
                    </SmallBody>
                  </>
                )}
              </Column>
            </FormCard>
          </>
        ) : null}
        <Spacer size={144} />
      </Column>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProEditServiceSettingsScreen;

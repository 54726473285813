import { createModelContext } from '@mero/components';

type State = {
  paths: string[];
  previousPath: string;
  currentPath: string;
};

const defaultState = (): State => ({
  paths: [],
  previousPath: '/',
  currentPath: '/',
});

export const NavigationContext = createModelContext(
  defaultState(),
  {
    addWebPath: (state) => {
      const currentPath = location.pathname;
      return {
        paths: [...state.paths, currentPath],
        previousPath: state.currentPath,
        currentPath,
      };
    },
  },
  (dispatch) => {
    return {
      addWebPath: () => {
        dispatch.addWebPath();
      },
    };
  },
);

import { ApiError, CheckoutCashRegistryTransactionId } from '@mero/api-sdk';
import { Body, colors, ConfirmBox, H1, ModalOverlay, SimpleListItem, Spacer, styles, useToast } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  transactionId: CheckoutCashRegistryTransactionId;
};

const DeleteTransactionDialog: React.FC<Props> = ({ onSuccess, onCancel, transactionId }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [cashRegistryState, { loadTransactions }] = CashRegistryContext.useContext();
  const [check, setCheck] = React.useState(false);
  const { t } = useTranslation('cashRegistry');
  const toast = useToast();
  const cancelDeleteTransaction = () => {
    onCancel();
  };

  const onDeleteTransactionClick = async () => {
    if (
      !check ||
      deleteInProgress ||
      pageState.type !== 'Loaded' ||
      cashRegistryState.type !== 'Loaded' ||
      !cashRegistryState.cashRegistry ||
      !cashRegistryState.selectedCompany
    ) {
      return;
    }

    setDeleteInProgress(true);
    try {
      await meroApi.checkout.deleteCashRegistryTransaction({
        pageId: pageState.page.details._id,
        companyId: cashRegistryState.selectedCompany._id,
        cashRegistryId: cashRegistryState.cashRegistry._id,
        cashRegistryTransactionId: transactionId,
      });

      loadTransactions({
        pageId: pageState.page.details._id,
      });
      onSuccess();
    } catch (e) {
      if (e instanceof ApiError) {
        toast.show({
          type: 'error',
          text: e.message,
        });
      }
    }
    setDeleteInProgress(false);
  };

  const deleteTransactionLeftAction = {
    text: t('cancelButton'),
    onPress: cancelDeleteTransaction,
    flex: 10,
  };

  const deleteTransactionRightAction = {
    text: t('deleteTransaction'),
    onPress: onDeleteTransactionClick,
    flex: 15,
  };

  const toggleCheck = () => () => {
    setCheck((prev) => !prev);
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle="Actiune ireversibilă"
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancelDeleteTransaction}
          leftAction={deleteTransactionLeftAction}
          rightAction={deleteTransactionRightAction}
        >
          <H1>{t('confirmDeleteTransaction')}</H1>
          <Spacer size="8" />
          <Body style={styles.text.semibold}>{t('checkboxDescription')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck()} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('modifiedSoldWarning')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteTransactionDialog;

import { WorkerActivityNotification } from '@mero/api-sdk/dist/notifications';
import { Line } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import UserNotification from './UserNotification';

type UserActivityNotificationItemProps = {
  readonly item: WorkerActivityNotification;
  readonly index: number;
  readonly onPress: (item: WorkerActivityNotification, index: number) => void;
  readonly disabled?: boolean;
};

const UserActivityNotificationItem = React.memo(function UserNotificationItemComponent({
  item,
  index,
  onPress,
  disabled,
}: UserActivityNotificationItemProps) {
  return (
    <TouchableOpacity onPress={() => onPress(item, index)} disabled={disabled}>
      <UserNotification notification={item} />
      <Line />
    </TouchableOpacity>
  );
});

export default UserActivityNotificationItem;

import { OnlinePaymentsPayoutSchedule } from '@mero/api-sdk';
import {
  colors,
  Icon,
  Title,
  Spacer,
  H1,
  Header,
  Body,
  Checkbox,
  Column,
  FormCard,
  H3s,
  InputWithLabel,
  Line,
  Select,
  SmallBody,
  SafeAreaView,
  Button,
  useToast,
  useShowError,
} from '@mero/components';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsStackParamList } from '../../../../../types';
import { styles } from './styles';

export type Props = {
  readonly navigation: CurrentBusinessProps &
    StackScreenProps<PageOnlinePaymentsStackParamList, 'PageOnlinePaymentsSchedule'>;
};

const PageOnlinePaymentsSchedule: React.FC<Props> = () => {
  const goBack = useGoBack();
  const { t } = useTranslation('onlinePayments');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const [type, setType] = React.useState<'Manual' | 'Automatic'>('Automatic');
  const [frequency, setFrequency] = React.useState<'Weekly' | 'Daily' | 'Monthly'>('Daily');
  const [dayName, setDayName] = React.useState<OnlinePaymentsPayoutSchedule.Weekly['day']>('monday');
  const [dayNumber, setDayNumber] = React.useState<number>(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [pageState] = CurrentBusinessContext.useContext();
  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  React.useEffect(() => {
    if (
      onlinePaymentsState.type === 'Loaded' &&
      onlinePaymentsState.status.type === 'Enabled' &&
      onlinePaymentsState.status.payout.schedule
    ) {
      const { schedule } = onlinePaymentsState.status.payout;
      setType(schedule.type === 'Manual' ? 'Manual' : 'Automatic');
      if (schedule.type !== 'Manual') {
        setFrequency(schedule.type);
      }
      if (schedule.type === 'Weekly') {
        setDayName(schedule.day);
      }
      if (schedule.type === 'Monthly') {
        setDayNumber(schedule.day ?? 1);
      }
    }
  }, [onlinePaymentsState.type]);
  const save = async () => {
    setIsLoading(true);
    try {
      if (pageState.type !== 'Loaded') {
        return;
      }
      const scheduleType = (
        type === 'Manual'
          ? { type: 'Manual' }
          : type === 'Automatic' && frequency === 'Daily'
          ? {
              type: 'Daily',
            }
          : type === 'Automatic' && frequency === 'Weekly'
          ? {
              type: 'Weekly',
              day: dayName,
            }
          : {
              type: 'Monthly',
              day: dayNumber,
            }
      ) satisfies OnlinePaymentsPayoutSchedule.Any;

      await meroApi.pro.onlinePayments.updatePayoutSchedule({
        pageId: pageState.page.details._id,
        schedule: scheduleType,
      });

      toast.show({ type: 'success', text: t('changesSaved') });
      goBack();
      reloadAsync({ pageId: pageState.page.details._id });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const getPayout = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }

      try {
        const status = await meroApi.pro.onlinePayments.getStatus(pageState.page.details._id);
        if (status.type === 'Enabled' && status.payout.schedule) {
          if (status.payout.schedule.type === 'Manual') {
            setType('Manual');
          } else {
            setType('Automatic');
            setFrequency(status.payout.schedule.type);
          }
        }
      } catch {}
    };
    getPayout();
  }, [pageState]);
  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        CenterComponent={() => <Title style={{ fontSize: 14 }}>{t('payoutFrequency')}</Title>}
      />
      <ScrollView style={{ flex: 1, paddingHorizontal: 24 }}>
        <Spacer size={16} />
        <H1>{t('payoutFrequency')}</H1>
        <Spacer size={8} />
        <Body>{t('payoutFrequencyDesc')}</Body>
        <Spacer size={16} />

        <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 24 }}>
          <TouchableOpacity
            style={{ paddingHorizontal: 16, flexDirection: 'row' }}
            onPress={() => setType('Automatic')}
          >
            <Checkbox value={type === 'Automatic'} onValueChange={() => setType('Automatic')} />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('automatic')}</H3s>
              <Spacer size={12} />
              <InputWithLabel label={t('frequency')}>
                <Select
                  value={frequency}
                  onChange={setFrequency}
                  items={[
                    {
                      label: t('frequency1'),
                      value: 'Daily',
                    },
                    {
                      label: t('frequency2'),
                      value: 'Weekly',
                    },
                    {
                      label: t('frequency3'),
                      value: 'Monthly',
                    },
                  ]}
                />
              </InputWithLabel>

              {type === 'Automatic' && frequency === 'Weekly' && (
                <>
                  <Spacer size={16} />
                  <InputWithLabel label={t('dayName')}>
                    <Select
                      value={dayName}
                      onChange={setDayName}
                      items={[
                        {
                          label: capitalize(t('monday')),
                          value: 'monday',
                        },
                        {
                          label: capitalize(t('tuesday')),
                          value: 'tuesday',
                        },
                        {
                          label: capitalize(t('wednesday')),
                          value: 'wednesday',
                        },
                        {
                          label: capitalize(t('thursday')),
                          value: 'thursday',
                        },
                        {
                          label: capitalize(t('friday')),
                          value: 'friday',
                        },
                        {
                          label: capitalize(t('saturday')),
                          value: 'saturday',
                        },
                        {
                          label: capitalize(t('sunday')),
                          value: 'sunday',
                        },
                      ]}
                    />
                  </InputWithLabel>
                </>
              )}

              {type === 'Automatic' && frequency === 'Monthly' && (
                <>
                  <Spacer size={16} />
                  <InputWithLabel label={t('dayName')}>
                    <Select
                      value={dayNumber}
                      onChange={setDayNumber}
                      items={new Array(31).fill(0).map((n, i) => ({
                        label: t('dayNumberDesc', { day: i + 1 }),
                        value: i + 1,
                      }))}
                    />
                  </InputWithLabel>
                </>
              )}
            </Column>
          </TouchableOpacity>

          <Spacer size={16} />
          <Line />
          <Spacer size={16} />

          <TouchableOpacity style={{ paddingHorizontal: 16, flexDirection: 'row' }} onPress={() => setType('Manual')}>
            <Checkbox value={type === 'Manual'} onValueChange={() => setType('Manual')} />
            <Column style={{ paddingLeft: 12, flex: 1 }}>
              <H3s>{t('Manual')}</H3s>
              <Spacer size={4} />
              <SmallBody>{t('manualDesc')}</SmallBody>
            </Column>
          </TouchableOpacity>
        </FormCard>
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={save}
              disabled={isLoading}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default PageOnlinePaymentsSchedule;

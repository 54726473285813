import { Platform } from 'react-native';

const globalCSS = `
textarea, select, input, button {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}
select {
  background: #ffffff
}

@media only screen and (min-width: 769px) {
  iframe {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.calendar-wrapper {
  display: contents;
}
.calendar {
  width: 100%; 
  overflow: scroll;
  background-color: #FFF;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 50px 1fr;
  // grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.calendar-pro {
  grid-area: 1 / 1 / 2 / 3;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.07));
  z-index: 201;
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  background-color: #FFF;
}
.calendar-hours {
  grid-area: 2 / 1 / 3 / 2;
  z-index: 200;
  width: 50px;
  position: sticky;
  left: 0;
  background-color: #FFF;
}
.calendar-appointments {
  grid-area: 2 / 2 / 3 / 3;
}
table#calendar th {
  position:sticky;
  top:0;
  /* ensure header row sits atop everything else when scrolling down */
  z-index:200;
  background-color: white;
}
table#calendar td {
  background:#fff;
}
/* ensure first header cell sits atop everything else when scrolling right */
table#calendar th:first-child {
  position:sticky;
  left:0;
  z-index:200;
}
/* make first column sticky when scrolling right */
table#calendar td:first-child {
  position:sticky;
  left:0;
}

iframe {
  border: none;
}
`;

(() => {
  if (Platform.OS !== 'web') return;

  const style = document.createElement('style');
  style.textContent = globalCSS;
  document.head.append(style);
})();

import { isDefined } from '@mero/api-sdk';
import { Worker } from '@mero/api-sdk';
import { PageId, PageMemberPreview, PageRoleId, PageRoleOverview } from '@mero/api-sdk/dist/pages';
import { SubscriptionId } from '@mero/api-sdk/dist/payments';
import { SubscriptionSeat } from '@mero/api-sdk/dist/payments/subscription-seat';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Select,
  Spacer,
  useShowError,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import usePromise from '../../../../../hooks/usePromise';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsEditStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import AddSeatDialog from './AddSeatDialog';
import PermissionSlider from './PermissionSlider';
import { styles } from './ProProfileDetailsScreen.styles';

export type Props = StackScreenProps<ProsEditStackParamList, 'ProPermissions'>;

const ProPermissionsScreen: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();
  const { promise, reject, resolve } = usePromise();

  const { userId } = route.params;

  const [pageState, { getPageWorkersAndServices, getPageMembers }] = CurrentBusinessContext.useContext();

  const scrollRef = React.useRef<ScrollView>(null);
  const block = React.useRef(false);

  const [memberDetails, setMemberDetails] = React.useState<PageMemberPreview | null>(null);
  const [workerDetails, setWorkerDetails] = React.useState<SavedWorker | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [role, setRole] = React.useState<PageRoleId | undefined>();
  const [roles, setRoles] = React.useState<{ label: string; value: PageRoleId }[]>([]);
  const [rolesPermissions, setRolesPermissions] = React.useState<PageRoleOverview[]>([]);
  const [addProInfo, setAddProInfo] = React.useState<
    (SubscriptionSeat & { expiresAt: Date; subscriptionId: SubscriptionId }) | null
  >(null);
  const [addNewSeat, setAddNewSeat] = React.useState(false);
  const [showAddSeatDialog, setShowAddSeatDialog] = React.useState(false);

  const checkPageSeats = async (pageId: PageId) => {
    if (pageState.type !== 'Loaded' || !memberDetails) {
      return;
    }
    try {
      const page = pageState.page;
      const subscription = await meroApi.payments.getPageSubscription(pageId);
      log.debug('Info seats', {
        seatsAvailable: subscription?.seatCount ?? 'none',
        seatsUsed: page.workers.length,
      });

      if (!subscription) {
        return;
      }

      const memberWorker = page.workers.find((w) => w.user._id === memberDetails.user._id);
      const hadWorkerRole = isDefined(memberWorker);
      const isInvited = workerDetails ? Worker.isInvited(workerDetails, page.details) : false;

      // pages that are still in trial don't have subscription.seatCount defined
      // so avoid showing the pro-rata modal
      const isSeatRequired = !!subscription.seatCount
        ? hadWorkerRole
          ? subscription.seatCount < page.workers.length
          : subscription.seatCount <= page.workers.length
        : false;

      if (isSeatRequired && !isInvited) {
        const seatInfo = await meroApi.payments.getSubscriptionSeatUpdateInfo({
          subscriptionId: subscription._id,
          newSeats: 1,
        });

        setAddProInfo({
          ...seatInfo.result,
          expiresAt: subscription.expires,
          subscriptionId: subscription._id,
        });
      }
    } catch (error) {
      log.error('Failed to check page seats', error);
      return undefined;
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      checkPageSeats(pageState.page.details._id);

      const member = pageState.page.members.find((m) => m.user._id === userId);
      const worker = pageState.page.workers.find((w) => w.user._id === userId);

      setMemberDetails(member ?? null);
      setWorkerDetails(worker ?? null);
      setRoles(pageState.page.roles.map((role) => ({ label: role.name, value: role.id })));
      setRolesPermissions(pageState.page.roles);

      if (member) {
        setRole(member.roles[0].id);
      }
    }
  }, [pageState.type]);

  const cancelAddNewSeat = () => {
    setShowAddSeatDialog(false);
    reject('addNewSeat');
    goBack();
  };

  const confirmAddNewSeat = () => {
    setAddNewSeat(true);
    setShowAddSeatDialog(false);
    resolve('addNewSeat');
  };

  const canAddSeat = async () => {
    try {
      const selectedRole = rolesPermissions.find((r) => r.id === role);
      if (addNewSeat || addProInfo === null || !selectedRole?.isWorkerRole) {
        return true;
      }

      if (!addProInfo.shouldConfirmNewPrice) {
        await meroApi.payments.updateSubscriptionSeatCount({
          subscriptionId: addProInfo.subscriptionId,
          newSeats: 1,
        });

        return true;
      }

      await promise('addNewSeat', () => {
        setShowAddSeatDialog(true);
      });

      return true;
    } catch {
      return false;
    }
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded' || !memberDetails || !role) {
      return;
    }

    if (!(await canAddSeat())) {
      return;
    }

    setIsLoading(true);
    try {
      await meroApi.pages.replaceUserRoles({
        pageId: pageState.page.details._id,
        userId: memberDetails.user._id,
        roles: [role],
      });

      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });

      await Promise.all([
        getPageWorkersAndServices(pageState.page.details._id),
        getPageMembers(pageState.page.details._id),
      ]);
      goBack();
    } catch (error) {
      log.error('Failed to update worker details', error);
      showError(error, t('errorDetails'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proPermissions')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={memberDetails?.user.profile.photo?.thumbnail}
              firstname={memberDetails?.user.profile.firstname ?? ''}
              lastname={memberDetails?.user.profile.lastname ?? ''}
            />
          </Column>
        )}
      />
      {memberDetails && (
        <ScrollView ref={scrollRef}>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('proPermissions')}</H1>
            <Spacer size={24} />
            <FormCard rounded>
              <InputWithLabel label={t('accessType')}>
                <Select items={roles} value={role} onChange={(r) => setRole(r)} />
              </InputWithLabel>
            </FormCard>
          </Column>
          <Spacer size="16" />
          <PermissionSlider roles={roles} rolesPermissions={rolesPermissions} role={role} setRole={setRole} />
          <Spacer size={92} />
        </ScrollView>
      )}
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading}
              text={memberDetails ? t('saveChanges') : t('save')}
              onClick={saveChanges}
            />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>

      {showAddSeatDialog && addProInfo ? (
        <AddSeatDialog
          onSuccess={confirmAddNewSeat}
          onCancel={cancelAddNewSeat}
          addonPrice={addProInfo.addonPrice}
          totalPrice={addProInfo.proRataPrice}
          expiresAt={addProInfo.expiresAt}
          subscriptionId={addProInfo.subscriptionId}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default ProPermissionsScreen;

import React from 'react';
import {
  StyleProp,
  Text,
  TextProps,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import { BLACK } from '../../../styles/colors';
import Icon from '../Icon';

type HeaderBoxProps = React.PropsWithChildren<{
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
}>;

const HeaderBox: React.FC<HeaderBoxProps & ViewProps> = ({ hasLeftIcon, hasRightIcon, style, children, ...props }) => {
  return (
    <View
      style={[
        {
          height: 50,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 2,
          paddingRight: hasRightIcon ? 8 : 56,
          paddingBottom: 0,
          paddingLeft: hasLeftIcon ? 11 : 59,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

type Props = {
  readonly canGoBack?: boolean;
  readonly onBack?: () => void;
  readonly canClose?: boolean;
  readonly onClose?: () => void;
  readonly title?: string;
  readonly LeftComponent?: React.ReactNode;
  readonly TitleComponent?: React.ComponentType;
  readonly titleComponentStyle?: StyleProp<ViewStyle>;
  readonly RightComponent?: React.ReactNode;
  readonly containerStyle?: StyleProp<ViewStyle>;
};

const MeroHeader: React.FC<Props> = ({
  canGoBack,
  onBack,
  canClose,
  onClose,
  title,
  LeftComponent: CustomLeftComponent,
  TitleComponent,
  titleComponentStyle,
  RightComponent: CustomRightComponent,
  containerStyle,
}) => {
  const LeftComponent = CustomLeftComponent ?? (canGoBack ? <BackButton onClick={onBack} /> : null);
  const RightComponent = CustomRightComponent ?? (canClose ? <CloseButton onClick={onClose} /> : null);
  const titleComponentHorizontalMargin = 0 + (canGoBack ? -25 : 0) + (canClose ? -25 : 0);

  return (
    <HeaderBox hasLeftIcon={!!LeftComponent} hasRightIcon={!!RightComponent} style={containerStyle}>
      <View style={{ alignSelf: 'flex-start' }}>{LeftComponent}</View>
      {TitleComponent ? (
        <View
          style={[
            {
              alignItems: 'center',
              width: '100%',
              marginHorizontal: titleComponentHorizontalMargin,
            },
            titleComponentStyle,
          ]}
        >
          <TitleComponent />
        </View>
      ) : (
        <Title title={title} />
      )}
      <View style={{ alignSelf: 'flex-end' }}>{RightComponent}</View>
    </HeaderBox>
  );
};

const StyledTitle: React.FC<React.PropsWithChildren<TextProps>> = ({ style, children, ...props }) => {
  return (
    <Text
      style={
        [
          {
            fontSize: 14,
            textAlign: 'center',
            lineHeight: 19,
            fontFamily: 'open-sans-semibold',
            color: BLACK,
            flex: 1,
          },
          style || {},
        ] as StyleProp<TextStyle>
      }
      {...props}
    >
      {children}
    </Text>
  );
};

type TitleProps = {
  title?: string;
};

const Title: React.FC<TitleProps> = ({ title }) => {
  return <StyledTitle>{title ?? ''}</StyledTitle>;
};

const TouchableBox: React.FC<React.PropsWithChildren<TouchableOpacityProps>> = ({ style, children, ...props }) => {
  return (
    <TouchableOpacity
      style={[
        {
          padding: 8,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </TouchableOpacity>
  );
};

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <TouchableBox onPress={onClick}>
      <Icon type="back" disabled={true} size={32} color={BLACK} />
    </TouchableBox>
  );
};

type CloseButtonProps = {
  onClick?: () => void;
};

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <View style={{ alignSelf: 'flex-end' }}>
      <TouchableBox onPress={onClick}>
        <Icon type="close" disabled={true} size={32} color={BLACK} />
      </TouchableBox>
    </View>
  );
};

export default MeroHeader;

import { MeroUnits, PortionPercentScaledNumber, Product, ProductStatus, ScaledNumber } from '@mero/api-sdk';
import {
  MeroHeader,
  useToast,
  colors,
  Spacer,
  H1,
  Body,
  Row,
  styles,
  FormCard,
  SafeAreaView,
  Button,
  HSpacer,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, ScrollView } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp, NavigationProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import { ProductEditStackParamList } from '../../../types';
import log from '../../../utils/log';
import { PositiveNumberFromString } from '../../../utils/number';
import { localeStringToNumber, scaledToString } from '../../../utils/scaled';
import ProductPriceCard from './ProductPriceCard';

type Props = {
  route: RouteProp<ProductEditStackParamList, 'ProductPrice'>;
  navigation: NavigationProp<ProductEditStackParamList, 'ProductPrice'>;
};

const ProductPriceScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const toast = useToast();
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();

  const [product, setProduct] = useState<Product | undefined>();
  const [showErrors, setShowErrors] = useState(false);
  const [showDiscountPrice, setShowDiscountPrice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [, { updateProductPrice }] = SelectedProductContext.useContext();
  const [initialPrice, setInitialPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });

  const [finalPrice, setFinalPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });

  const [discountPrice, setDiscountPrice] = useState({
    input: '',
    decoded: PositiveNumberFromString.decode(''),
  });
  const isDiscountLessThanFinalPrice =
    E.isRight(discountPrice.decoded) &&
    E.isRight(finalPrice.decoded) &&
    discountPrice.decoded.right < finalPrice.decoded.right;

  const [vatRate, setVatRate] = useState<number>(19);

  useEffect(() => {
    const init = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }

      const product = await meroApi.pro.products.getProductById({
        pageId: pageState.page.details._id,
        productId: route.params.productId,
      });
      setProduct(product);
      setVatRate(parseFloat(scaledToString(product.price.vatRate)));
    };
    init();
  }, [pageState.type]);

  const updatePrice = async () => {
    if (pageState.type !== 'Loaded' || !product) {
      return;
    }

    const scaledFinalPrice = ScaledNumber.fromStr(finalPrice.input.replace(',', ''));

    if (E.isLeft(scaledFinalPrice)) {
      return setShowErrors(true);
    }

    if (showDiscountPrice && (E.isLeft(discountPrice.decoded) || !isDiscountLessThanFinalPrice)) {
      return setShowErrors(true);
    }

    const discount = ScaledNumber.fromStr(localeStringToNumber(discountPrice.input).toString());

    try {
      setIsLoading(true);

      const newProduct = await meroApi.pro.products.updateProduct({
        pageId: pageState.page.details._id,
        productId: product._id,
        product: {
          name: product.name,
          description: product.description,
          status:
            product.status === 'Active' || product.status === 'Inactive' ? product.status : ProductStatus.Active.code,
          availability: product.availability,
          barcode: product.barcode,
          categoryId: product.category.categoryId,
          brandId: product.brandId,
          price: {
            retailPrice: {
              amount: scaledFinalPrice.right,
              unit: MeroUnits.RON.code,
            },
            discountedPrice: {
              ...product.price.discountedPrice,
              amount: E.isRight(discount) ? discount.right : scaledFinalPrice.right,
            },
            vatRate: PortionPercentScaledNumber.unsafeFromNumber(vatRate, 2),
          },
        },
      });
      updateProductPrice(newProduct.price);
      toast.show({ type: 'success', text: t('priceUpdateSuccess') });
      reload();
      goBack();
    } catch (e) {
      log.info('Failed to update price', e);
    }

    setIsLoading(false);
  };

  return (
    product && (
      <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack={true} title={t('price')} onBack={goBack} canClose={false} />
        <Spacer size="16" />
        <ScrollView style={{ paddingHorizontal: 16, flex: 1 }}>
          <H1>{t('price')}</H1>
          <Spacer size="8" />
          <Row>
            <Body>{t('setPriceFor')}</Body>
            <Body style={styles.text.semibold}> {product.name}</Body>
          </Row>
          <Spacer size="32" />
          <ProductPriceCard
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
            initialPrice={initialPrice}
            setInitialPrice={setInitialPrice}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            showDiscountPrice={showDiscountPrice}
            setShowDiscountPrice={setShowDiscountPrice}
            discountPrice={discountPrice}
            setDiscountPrice={setDiscountPrice}
            vatRate={vatRate}
            setVatRate={setVatRate}
            productPrice={product.price}
          />
        </ScrollView>

        <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button
                text={t('saveChanges')}
                onPress={updatePrice}
                disabled={isLoading}
                RightComponent={() =>
                  isLoading ? (
                    <ActivityIndicator color={colors.COMET} style={{ marginHorizontal: 8 }} />
                  ) : (
                    <HSpacer left={32} />
                  )
                }
              />
            ) : (
              <Button
                text={t('saveChanges')}
                onPress={updatePrice}
                disabled={isLoading}
                RightComponent={() =>
                  isLoading ? (
                    <ActivityIndicator color={colors.COMET} style={{ marginHorizontal: 8 }} />
                  ) : (
                    <HSpacer left={32} />
                  )
                }
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
              />
            )}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
    )
  );
};

export default ProductPriceScreen;

import { Body, Button, colors, Column, H1, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Svg, { Rect, Path } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { ProsSettingsInviteStackParamList } from '../../../../../types';

const SuccessIcon = () => (
  <Svg data-name="Icons/Check-O" width={30.72} height={30.72}>
    <Rect width={30.72} height={30.72} rx={1} fill="none" />
    <Path
      d="M26.208 4.416a.51.51 0 0 0-.576.048L11.52 15.696l-6.432-4.608a.508.508 0 0 0-.624.048l-2.88 2.88a.464.464 0 0 0 0 .672l9.6 10.56a.519.519 0 0 0 .336.144.436.436 0 0 0 .336-.144L29.14 7.008a.545.545 0 0 0 .144-.384.4.4 0 0 0-.192-.336Z"
      fill="#080de0"
    />
  </Svg>
);

export type Props = StackScreenProps<ProsSettingsInviteStackParamList, 'ProInviteSuccess'>;

const InviteProSuccessScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('pros');

  const { workerName } = route.params;

  const goBack = useGoBack();

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Column
          style={{
            width: 72,
            height: 72,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 36,
            backgroundColor: '#E3E4FA',
          }}
        >
          <SuccessIcon />
        </Column>
        <Spacer size={32} />
        <H1 style={{ fontSize: 32 }}>{t('invitationSent')}</H1>
        <Spacer size={16} />
        <Body style={{ padding: 24, textAlign: 'center' }}>
          <Trans ns={'pros'} t={t} i18nKey="invitationSentDescription" values={{ name: workerName }}>
            <Title></Title>1
          </Trans>
        </Body>
        <Spacer size={48} />
        <Button
          expand={false}
          containerStyle={{ alignSelf: 'center' }}
          text={t('ok')}
          onClick={() => {
            navigation.popToTop();
            goBack();
          }}
        />
      </Column>
    </ModalScreenContainer>
  );
};

export default InviteProSuccessScreen;

import { Body, ConfirmBox, H1, ModalOverlay, Spacer } from '@mero/components';
import { FeedbackId } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  feedbackId: FeedbackId;
};

const DeleteReplyDialog: React.FC<Props> = ({ onSuccess, onCancel, feedbackId }) => {
  const { t } = useTranslation('reviews');

  const [pageState] = CurrentBusinessContext.useContext();

  const [actionInProgress, setActionInProgress] = React.useState(false);

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setActionInProgress(true);
        await meroApi.pages.deletePrivateFeedbackReply({
          pageId: pageState.page.details._id,
          feedbackId,
        });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setActionInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancelButton'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('deleteButton'),
    onPress: actionInProgress ? undefined : confirm,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('deleteAction')}
          icon="info"
          canClose={!actionInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('deleteTitle')}</H1>
          <Spacer size="8" />
          <Body>{t('deleteDescription')}</Body>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteReplyDialog;

import { MainCategoryId } from '@mero/api-sdk/dist/shop/main-category-id';
import { Product } from '@mero/api-sdk/dist/shop/product';
import { SubCategoryId } from '@mero/api-sdk/dist/shop/sub-category-id';
import { Column, Icon, Label, Row, SmallBody, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import InfiniteList from '../../../../../components/InfiniteList';
import * as colors from '@mero/components/lib/styles/colors';

import { BATCH_SIZE, ShopContext } from './shopContext';

const StarIcon = () => (
  <Svg width={18} height={18}>
    <G data-name="Group 7356">
      <Path data-name="Rectangle 2719" fill="none" d="M0 0h18v18H0z" />
      <Path
        d="M16.14 7.402a.412.412 0 0 0-.334-.26l-4.447-.62L9.38 2.594a.455.455 0 0 0-.76 0L6.64 6.522l-4.444.62a.412.412 0 0 0-.334.26.367.367 0 0 0 .1.394l3.237 3.04-.759 4.323a.376.376 0 0 0 .172.38.447.447 0 0 0 .445.022L9 13.545l3.946 2.016a.448.448 0 0 0 .445-.022.376.376 0 0 0 .172-.38l-.758-4.323 3.235-3.04a.367.367 0 0 0 .1-.394Z"
        fill="#ffb900"
      />
    </G>
  </Svg>
);

const LIMIT_REVIEWS = 5;

export type Props = {
  onProductPress: (product: Product) => void;
  products: Product[];
  categoryId: MainCategoryId;
  subCategoryId?: SubCategoryId;
};
const ProductsList: React.FC<Props> = ({ products, categoryId, subCategoryId, onProductPress }) => {
  const width = useWindowDimensions().width;
  const [{ hasMore, total }, { onLoadMore }] = ShopContext.useContext();

  const productSize = ((width > 500 ? 500 : width) - 40) / 2;

  const onEndReached = () => {
    if (hasMore) {
      onLoadMore([categoryId]);
    }
  };

  return (
    <Column style={{ paddingHorizontal: 12, flex: 1 }}>
      <InfiniteList
        showsVerticalScrollIndicator={false}
        style={{ paddingTop: subCategoryId ? 16 : 8 }}
        numColumns={2}
        data={products}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => onProductPress(item)}
            style={{
              borderRadius: 12,
              backgroundColor: colors.WHITE,
              width: productSize,
              height: 316,
              marginHorizontal: 4,
              marginBottom: 8,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 5,
              },
              shadowOpacity: 0.08,
              shadowRadius: 16,
            }}
          >
            <Column style={{ height: 167, padding: 8, borderBottomWidth: 1, borderBottomColor: colors.ATHENS_GRAY }}>
              <Image
                style={{ width: '100%', height: '100%', borderRadius: 8 }}
                source={
                  item.pictureUrls.length > 0
                    ? { uri: item.pictureUrls[0] }
                    : require('../../../../../assets/images/noPhotoProduct.png')
                }
              />
            </Column>
            <Column style={{ paddingHorizontal: 8, paddingVertical: 12, flex: 1 }}>
              <Row style={{ alignItems: 'center' }}>
                {item.reviews.count > LIMIT_REVIEWS ? (
                  <>
                    <StarIcon />
                    <SmallBody style={{ marginLeft: 2, fontFamily: 'open-sans-bold', flex: 1 }}>
                      {item.reviews.average.toFixed(2)}
                    </SmallBody>
                  </>
                ) : (
                  <Column style={{ flex: 1 }} />
                )}
                {item.discountPercentage ? (
                  <Label style={{ fontFamily: 'open-sans-semibold', color: colors.RADICAL_RED }}>
                    -{item.discountPercentage}%
                  </Label>
                ) : null}
              </Row>
              <Spacer size="4" />
              <SmallBody style={{ fontFamily: 'open-sans-semibold' }} numberOfLines={3} ellipsizeMode="tail">
                {item.name}
              </SmallBody>
              <Spacer size="8" />
              {item.oldPrice && item.oldPrice !== item.price ? (
                <SmallBody style={{ fontSize: 12, color: colors.COMET, textDecorationLine: 'line-through' }}>
                  {item.oldPrice} lei
                </SmallBody>
              ) : null}
              <Column style={{ flex: 1 }} />
              <Row style={{ alignItems: 'center' }}>
                <Title
                  style={{
                    fontFamily: 'open-sans-semibold',
                    flex: 1,
                    fontSize: 18,
                    color: colors.RADICAL_RED,
                    lineHeight: 24,
                  }}
                >
                  {item.price} lei
                </Title>
                <Icon type="next" color={colors.DARK_BLUE} size={24} />
              </Row>
            </Column>
          </TouchableOpacity>
        )}
        totalPages={total === 0 ? 0 : Math.ceil(total / BATCH_SIZE)}
        onEndReached={onEndReached}
        ListFooterComponent={() => <Spacer size={46} />}
      />
    </Column>
  );
};

export default ProductsList;

import { CheckoutTransactionPreview, Paged, PageId, SavedCheckoutCompany } from '@mero/api-sdk';
import { CheckoutFinishedTransactionsStats } from '@mero/api-sdk/dist/checkout/checkoutFinishedTrasactionsStats';
import { CheckoutPageSettings } from '@mero/api-sdk/dist/checkout/checkoutPageSettings';
import { createModelContext } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';

import log from '../../utils/log';
import { catchTrow } from '../../utils/promise';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type FinishedTransaction = CheckoutTransactionPreview.AnyFinished | CheckoutTransactionPreview.AnyDeleted;

type StateNew = {
  type: 'New';
};

type StateInitializing = {
  type: 'Initializing';
};

export type StateLoaded = {
  type: 'Loaded';
  drafts: Paged<CheckoutTransactionPreview.AnyDraft[]>;
  finished: Paged<FinishedTransaction[]>;
  stats: CheckoutFinishedTransactionsStats<MeroUnits.Any>;
  companies: SavedCheckoutCompany[];
  pageSettings: CheckoutPageSettings;
  interval: {
    from: Date;
    to: Date;
  };
  search?: string;
};

export type StateLoadedRestricted = {
  type: 'LoadedRestricted';
  restrictedPageSettings: CheckoutPageSettings;
};

type State = StateNew | StateInitializing | StateLoaded | StateLoadedRestricted;

const defaultState = (): State => ({
  type: 'New',
});

const lastRequest = {
  drafts: Symbol('drafts'),
  finished: Symbol('finished'),
};

const lastPage = {
  drafts: '',
  finished: '',
};

export const CheckoutsContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(s) {
      if (s.type === 'New') {
        return {
          type: 'Initializing',
        };
      }

      return s;
    },
    setRestrictedLoaded(_, params: { pageSettings: CheckoutPageSettings }) {
      return {
        type: 'LoadedRestricted',
        restrictedPageSettings: params.pageSettings,
      };
    },
    setLoaded(
      _,
      params: {
        drafts: Paged<CheckoutTransactionPreview.AnyDraft[]>;
        finished: Paged<FinishedTransaction[]>;
        stats: CheckoutFinishedTransactionsStats<'RON'>;
        companies: SavedCheckoutCompany[];
        pageSettings: CheckoutPageSettings;
        interval: { from: Date; to: Date };
      },
    ) {
      return {
        type: 'Loaded',
        drafts: params.drafts,
        finished: params.finished,
        stats: params.stats,
        companies: params.companies,
        pageSettings: params.pageSettings,
        interval: params.interval,
      };
    },
    update(
      state,
      params: Partial<{
        drafts: Paged<CheckoutTransactionPreview.AnyDraft[]>;
        finished: Paged<FinishedTransaction[]>;
        stats: CheckoutFinishedTransactionsStats<'RON'>;
        companies: SavedCheckoutCompany[];
        pageSettings: CheckoutPageSettings;
        interval: { from: Date; to: Date };
        search: string;
      }>,
    ) {
      return {
        ...state,
        ...params,
      };
    },
    reset: defaultState,
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const loadCheckoutData = async ({
      pageId,
      from,
      to,
      search,
    }: {
      pageId: PageId;
      from: Date;
      to: Date;
      search?: string;
    }) => {
      const [drafts, completed, stats, companies, pageSettings] = await Promise.all([
        meroApi.checkout
          .listPageDraftTransactions({ pageId })
          .catch((e) => {
            log.error('failed to load listPageDraftTransactions', JSON.stringify(e));
            return { data: [], next: undefined, prev: undefined };
          })
          .catch(catchTrow),
        meroApi.checkout
          .listPageSubmitedTransactions({
            pageId,
            finishedAt: {
              from,
              to,
            },
            search,
          })
          .catch((e) => {
            log.error('failed to load listPageSubmitedTransactions', JSON.stringify(e));
            return { data: [], next: undefined, prev: undefined };
          }),
        meroApi.checkout.getPageFinishedTransactionsStats({
          pageId,
          unit: 'RON',
          finishedAt: { from, to },
        }),
        meroApi.checkout.listPageCompanies({ pageId }).catch((e) => {
          log.error('failed to load listPageCompanies', JSON.stringify(e));
          return [];
        }),
        meroApi.checkout.getPageSettings({ pageId }),
      ]).catch(catchTrow);

      return [drafts, completed, stats, companies, pageSettings] as const;
    };
    return {
      init: (pageId: PageId): void => {
        dispatch.run(async (s) => {
          if (s.type === 'New') {
            dispatch.trySetInitializing();

            const startMonth = DateTime.now().startOf('month').toJSDate();
            const endMonth = DateTime.now().endOf('month').toJSDate();

            try {
              const [drafts, finished, stats, companies, pageSettings] = await loadCheckoutData({
                pageId,
                from: startMonth,
                to: endMonth,
                search: undefined,
              });

              dispatch.setLoaded({
                drafts,
                finished,
                stats,
                companies,
                pageSettings,
                interval: { from: startMonth, to: endMonth },
              });
            } catch (e) {
              dispatch.reset();
              log.error('failed to initialize checkout information', e);
            }
          }
        });
      },

      restrictedLoad: (pageId: PageId): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded' || s.type === 'New' || s.type === 'LoadedRestricted') {
            try {
              const pageSettings = await meroApi.checkout.getPageSettings({ pageId });

              dispatch.setRestrictedLoaded({
                pageSettings,
              });
            } catch (e) {
              dispatch.reset();
              log.error('failed to initialize checkout information', e);
            }
          }
        });
      },

      reload: (pageId: PageId, limits?: { limitDrafts?: number; limitFinished?: number }): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded' || s.type === 'New' || s.type === 'LoadedRestricted') {
            try {
              const startMonth = DateTime.now().startOf('month').toJSDate();
              const endMonth = DateTime.now().endOf('month').toJSDate();

              lastRequest.drafts = Symbol('drafts');
              lastRequest.finished = Symbol('finished');

              const activeRequests = [lastRequest.drafts, lastRequest.finished];

              const [drafts, finished, stats, companies, pageSettings] = await loadCheckoutData({
                pageId,
                ...(s.type === 'Loaded' ? s.interval : { from: startMonth, to: endMonth }),
                ...limits,
                search: s.type === 'Loaded' ? s.search : undefined,
              });

              s.type === 'Loaded'
                ? dispatch.update({
                    drafts: activeRequests.includes(lastRequest.drafts) ? drafts : s.drafts,
                    finished: activeRequests.includes(lastRequest.finished) ? finished : s.finished,
                    stats,
                    companies,
                    pageSettings,
                  })
                : dispatch.setLoaded({
                    drafts,
                    finished,
                    stats,
                    companies,
                    pageSettings,
                    interval: { from: startMonth, to: endMonth },
                  });
            } catch (e) {
              dispatch.reset();
              log.error("failed to initialize checkout information's", e);
            }
          }
        });
      },

      reloadCompanies: (pageId: PageId): Promise<void> =>
        new Promise((resolve) => {
          dispatch.run(async () => {
            try {
              const companies = await meroApi.checkout.listPageCompanies({ pageId });

              dispatch.update({ companies });
              resolve();
            } catch (e) {
              log.error('failed to reload companies', e);
              resolve();
            }
          });
        }),
      reloadSettings: (pageId: PageId): void => {
        dispatch.run(async () => {
          try {
            const pageSettings = await meroApi.checkout.getPageSettings({ pageId });

            dispatch.update({ pageSettings });
          } catch (e) {
            log.error('failed to reload settings', e);
          }
        });
      },
      updateInterval: (interval: { from: Date; to: Date }, pageId: PageId): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            try {
              const [drafts, finished, stats, companies, pageSettings] = await loadCheckoutData({
                pageId,
                ...interval,
                search: s.search,
              });

              dispatch.update({
                drafts,
                finished,
                stats,
                companies,
                pageSettings,
                interval,
              });
            } catch (e) {
              log.error('failed to initialize checkout information', e);
            }
          }
        });
      },
      updateSearch: (pageId: PageId, search?: string): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            try {
              const [drafts, finished, stats, companies, pageSettings] = await loadCheckoutData({
                pageId,
                ...s.interval,
                search,
              });

              dispatch.update({
                drafts,
                finished,
                stats,
                companies,
                pageSettings,
                search,
              });
            } catch (e) {
              log.error('failed to initialize checkout information', e);
            }
          }
        });
      },
      loadMoreFinished: (pageId: PageId): Promise<void> =>
        new Promise((resolve) => {
          dispatch.run(async (s) => {
            if (s.type === 'Loaded' && s.finished.next && s.finished.next !== lastPage.finished) {
              try {
                lastRequest.finished = Symbol('finished');

                const activeRequests = [lastRequest.finished];

                lastPage.finished = s.finished.next;

                const finished = await meroApi.checkout.listPageSubmitedTransactions({
                  pageId,
                  page: {
                    token: s.finished.next,
                  },
                  finishedAt: {
                    from: s.interval.from,
                    to: s.interval.to,
                  },
                  search: s.search,
                });

                activeRequests.includes(lastRequest.finished) &&
                  dispatch.update({
                    finished: {
                      ...finished,
                      data: [...s.finished.data, ...finished.data],
                    },
                  });
              } catch (e) {
                log.error('failed to load more finished', e);
              }
            }
            resolve();
          });
        }),
      reset: dispatch.reset,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init, reload, restrictedLoad }] = CheckoutsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [currentPageId, setCurrentPageId] = React.useState<PageId | undefined>(undefined);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && pageState.page.details._id !== currentPageId) {
      setCurrentPageId(pageState.page.details._id);
      if (!pageState.page.permissions.checkout.hasAny()) {
        return restrictedLoad(pageState.page.details._id);
      }
      currentPageId ? reload(pageState.page.details._id) : init(pageState.page.details._id);
    }
  }, [pageState]);

  return <>{children}</>;
};

export const withCheckoutsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithCheckoutsContextProvider(props: P) {
    return (
      <CheckoutsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </CheckoutsContext.Provider>
    );
  };
};

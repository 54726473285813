import { ProductId, ProductSearchHit } from '@mero/api-sdk';
import { Body, Column, HSpacer, Row, styles, Line, colors } from '@mero/components';
import * as React from 'react';
import { Image, TouchableOpacity, Text } from 'react-native';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import { DefaultProductImage } from './DefaultProductImage';

export const convertProductSummaryData = (
  product: ProductSearchHit,
  t: (text: string) => string,
): ProductSummaryCardProps['product'] => {
  const unit = t(product.price.retailPrice.unit);

  const discountedPrice = `${scaledToString(product.price.discountedPrice.amount)} ${t(unit)}`;

  const price = `${scaledToString(product.price.retailPrice.amount)} ${t(unit)}`;

  return {
    _id: product._id,
    name: product.name,
    measure: getMeasure(product.measure),
    barcode: product.barcode?.value,
    price: price,
    discountedPrice: discountedPrice === price ? undefined : discountedPrice,
    imageUrl: product.image?.thumbnail,
    categoryName: product.categoryName,
  };
};

export type ProductSummaryCardProps = {
  product: {
    _id: ProductId;
    name: string;
    measure: string;
    price: string;
    barcode?: string;
    imageUrl?: string;
    discountedPrice?: string;
    categoryName?: string;
  };
  showLine?: boolean;
  showPrice?: boolean;
  showCategoryLabel?: boolean;
  hasPadding?: boolean;
  openProductDetails: (productId: ProductId) => void;
};

const ProductSummaryCard: React.FC<ProductSummaryCardProps> = ({
  product,
  showLine,
  openProductDetails,
  showPrice = true,
  showCategoryLabel = true,
  hasPadding = true,
}) => {
  const { isPhone } = useMediaQueries();
  const fontSize = isPhone ? 16 : 14;
  const [disabled, setDisabled] = React.useState(true);

  // This is a workaround to prevent the card from being pressed initially
  React.useEffect(() => {
    setDisabled(false);
  }, []);

  return (
    <TouchableOpacity onPress={() => openProductDetails(product._id)} disabled={disabled}>
      <Row style={hasPadding && { paddingVertical: 16 }}>
        {product.imageUrl ? (
          <Image source={{ uri: product.imageUrl }} style={{ width: 47.6, height: 47.6, borderRadius: 4 }} />
        ) : (
          <DefaultProductImage />
        )}
        <HSpacer left={12} />
        <Column style={{ flex: 1 }}>
          <Body style={[styles.text.semibold, { fontSize }]}>{product.name}</Body>
          {showPrice && (
            <Row>
              <Body
                style={[
                  { fontSize },
                  !!product.discountedPrice && {
                    textDecorationLine: 'line-through',
                    color: colors.COMET,
                  },
                ]}
              >
                {product.price}
              </Body>
              {product.discountedPrice && <Body style={[{ fontSize, marginLeft: 8 }]}>{product.discountedPrice}</Body>}
            </Row>
          )}
          <Row>
            <Body style={[styles.text.hint, { fontSize: fontSize - 2 }]}>{`${product.measure}${
              product.barcode ? ` - ${product.barcode}` : ``
            }`}</Body>
          </Row>

          {showCategoryLabel && product.categoryName && (
            <Row>
              <Text
                style={[
                  styles.text.semibold,
                  {
                    borderRadius: 4,
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    marginTop: 8,
                    color: colors.COMET,
                    fontSize: 12,
                    backgroundColor: colors.ATHENS_GRAY,
                  },
                ]}
              >
                {product.categoryName.toUpperCase()}
              </Text>
            </Row>
          )}
        </Column>
      </Row>
      {showLine !== false && <Line />}
    </TouchableOpacity>
  );
};

export default ProductSummaryCard;

import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';

/**
 * Readonly input state allows typescript type narrowing to work for nested fields.
 */
type InputState<T> = {
  readonly input: string;
  readonly decoded: t.Validation<T>;
};

type ValidInputState<T> = {
  readonly input: string;
  readonly isValid: true;
  readonly value: T;
};

type InvalidInputState = {
  readonly input: string;
  readonly isValid: false;
  readonly error: t.Errors;
};

type ComputedInputState<T> = ValidInputState<T> | InvalidInputState;

/**
 * Conventional state for inputs validation
 * Using readonly state type to improve type narrowing for nested fields
 * Example:
 *
 * ```typescript
 * const initialValue = 'asdf',
 * const [email, setEmail] = useInputState({
 *  input: initialValue,
 *  decoded: Email.decode(initialValue)
 * })
 *
 * if (email.isValid) {
 *  console.log('valid email', email.value);
 * }
 * ```
 */
export const useInputState = <T,>(
  initialState: InputState<T>,
): [ComputedInputState<T>, React.Dispatch<React.SetStateAction<InputState<T>>>] => {
  const [innerState, setInnerState] = React.useState<InputState<T>>(initialState);

  const state: ComputedInputState<T> = React.useMemo(
    () =>
      pipe(
        innerState.decoded,
        E.fold(
          (e): ComputedInputState<T> => ({
            input: innerState.input,
            isValid: false,
            error: e,
          }),
          (value): ComputedInputState<T> => ({
            input: innerState.input,
            isValid: true,
            value: value,
          }),
        ),
      ),
    [innerState],
  );

  return [state, setInnerState];
};

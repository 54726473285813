import { ProductGallery } from '@mero/api-sdk';
import {
  Body,
  Checkbox,
  Column,
  Icon,
  MeroHeader,
  Row,
  SafeAreaView,
  Spacer,
  Title,
  colors,
  sizes,
  useShowError,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, Image, Dimensions, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductImagesContext } from '../../../contexts/ProductImagesContext';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import { ProductEditStackParamList } from '../../../types';
import log from '../../../utils/log';

export type Props = StackScreenProps<ProductEditStackParamList, 'ProductImageMenu'>;

const ProductImageMenu: React.FC<Props> = ({ route, navigation }) => {
  const goBack = useGoBack();
  const { width } = Dimensions.get('window');
  const imageWidth = (width > 768 ? 500 : width) - 48;
  const imageHeight = 436;
  const { t } = useTranslation('gallery');
  const showError = useShowError();

  const [state, { update }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();
  const [images, { setImages, removeImage }] = ProductImagesContext.useContext();
  const [, { updateImage }] = SelectedProductContext.useContext();

  const [gallery, setGallery] = React.useState<ProductGallery>([]);

  React.useEffect(() => {
    const init = async () => {
      if (
        state.type === 'Loaded' &&
        pageState.type === 'Loaded' &&
        route.params.productId !== 'undefined' &&
        route.params.productId
      ) {
        const product = await meroApi.pro.products.getProductById({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        });

        if (product) {
          setGallery(product.gallery);

          const selectedImageIndex = product.gallery.findIndex((image) => image.image._id === route.params.imageId);
          if (selectedImageIndex !== -1) {
            update({
              selectedImage: {
                image: product.gallery[selectedImageIndex].image,
                position: selectedImageIndex,
              },
            });
          }
        }
      }
    };
    init();
  }, [state.type, pageState.type]);

  const changeImage = (newPosition: number) => {
    if (state.type !== 'Loaded' || !state.selectedImage) {
      return;
    }
    if (newPosition >= 0 && newPosition < gallery.length) {
      const image = gallery[newPosition].image;
      update({
        selectedImage: {
          image,
          position: newPosition,
        },
      });
      navigation.navigate('ProductImageMenu', { imageId: image._id, productId: route.params.productId });
    }
  };

  const onSetAsMainImage = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const otherImages = gallery
        .filter((item) => item.image._id !== state.selectedImage?.image._id)
        .map((item) => item.image._id);

      const newGallery = await meroApi.pro.products.reorderGalleryImages({
        pageId: pageState.page.details._id,
        imageIds: [route.params.imageId, ...otherImages],
        productId: route.params.productId,
      });
      setGallery(newGallery);

      update({
        selectedImage: {
          image: newGallery[0].image,
          position: 0,
        },
      });
      const sortedGalleryItems = newGallery.sort((item1, item2) => item1.order - item2.order);
      const newImages = sortedGalleryItems.map((element) => element.image);
      setImages(newImages);
    } catch (error) {
      showError(error, t('failedToSetAsMain'));
      log.error('Error while setting as main image', error);
    }
  };

  const onDeleteImage = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      await meroApi.pro.products.deleteGalleryImage({
        pageId: pageState.page.details._id,
        imageId: route.params.imageId,
        productId: route.params.productId,
      });
      goBack();
      if (images.length === 1) {
        updateImage(undefined);
      }
      reload();
      removeImage(route.params.imageId);
    } catch (error) {
      showError(error, t('failedToDeleteImage'));
      log.error('Error while deleting image', error);
    }
  };

  if (state.type !== 'Loaded' || !state.selectedImage) {
    return;
  }
  return (
    <ModalScreenContainer style={{ flex: 1 }}>
      <MeroHeader canGoBack onBack={goBack} title={`${state.selectedImage.position + 1} din ${gallery.length}`} />

      <ScrollView>
        <SafeAreaView edges={['bottom']}>
          <Row style={{ paddingHorizontal: 24, paddingTop: 8 }}>
            <Column style={{ flex: 1, position: 'relative' }}>
              <Image
                source={{ uri: state.selectedImage.image.medium }}
                style={{ width: imageWidth, height: imageHeight, borderRadius: 4 }}
              />
              {state.selectedImage.position === 0 && (
                <Column style={{ position: 'absolute', bottom: 16, right: 16 }}>
                  <Column
                    style={{
                      borderRadius: 4,
                      backgroundColor: colors.ALABASTER,
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                    }}
                  >
                    <Title style={{ fontSize: 12 }}>{t('mainImage').toUpperCase()}</Title>
                  </Column>
                </Column>
              )}

              {state.selectedImage.position < gallery.length - 1 && (
                <TouchableOpacity onPress={() => state.selectedImage && changeImage(state.selectedImage.position + 1)}>
                  <Column
                    style={{
                      position: 'absolute',
                      bottom: imageHeight / 2,
                      right: 16,
                      borderRadius: 50,
                      backgroundColor: colors.WHITE,
                      padding: 4,
                    }}
                  >
                    <Icon type="arrow-right" />
                  </Column>
                </TouchableOpacity>
              )}

              {state.selectedImage.position !== 0 && (
                <TouchableOpacity onPress={() => state.selectedImage && changeImage(state.selectedImage.position - 1)}>
                  <Column
                    style={{
                      position: 'absolute',
                      bottom: imageHeight / 2,
                      left: 16,
                      borderRadius: 50,
                      backgroundColor: colors.WHITE,
                      padding: 4,
                    }}
                  >
                    <Icon type="arrow-left" />
                  </Column>
                </TouchableOpacity>
              )}
            </Column>
          </Row>

          <TouchableOpacity
            style={{ paddingHorizontal: sizes[24], paddingVertical: sizes[16] }}
            delayPressIn={0}
            onPress={onSetAsMainImage}
          >
            <Row alignItems="center">
              <Checkbox
                onValueChange={onSetAsMainImage}
                color="blue"
                value={state.selectedImage.position === 0}
                disabled={state.selectedImage.position === 0}
              />
              <Body style={{ fontSize: 17, color: '#172B4D', marginLeft: 12 }}>{t('setAsMain')}</Body>
            </Row>
          </TouchableOpacity>

          <Spacer size={30} />

          <TouchableOpacity
            style={{ paddingHorizontal: sizes[24], paddingVertical: sizes[16] }}
            delayPressIn={0}
            onPress={onDeleteImage}
          >
            <Row justifyContent="center">
              <Title style={{ color: colors.RADICAL_RED }}>{t('deleteImage')}</Title>
            </Row>
          </TouchableOpacity>
          <Spacer size={48} />
        </SafeAreaView>
      </ScrollView>
    </ModalScreenContainer>
  );
};

export default ProductImageMenu;

import * as MeroApi from '@mero/api-sdk';
import { GrantResponse } from '@mero/api-sdk/dist/users';
import { createModelContext } from '@mero/components';
import { Body, H1, Spacer, styles as meroStyles, Button as MeroButton, colors } from '@mero/components';
import { NovaAuthAPI } from '@mero/nova-auth-api-sdk';
import { isError } from '@mero/shared-sdk/dist/common';
import promiseRetry from 'promise-retry';
import * as React from 'react';
import { Platform } from 'react-native';
import { View } from 'react-native';
import { LoginManager, Settings } from 'react-native-fbsdk-next';

import SplashScreen from '../../screens/SplashScreen';

import BrokenLinkIcon from '../../components/BrokenLinkIcon';
import ModalScreenContainer from '../../components/ModalScreenContainer';

import { useAnalytics } from '../../hooks/useAnalytics';

import { version } from '../../../package.json';
import { AppStorage } from '../../app-storage';
import config from '../../config';
import { ForceUpdate } from '../../forceUpdate';
import log from '../../utils/log';
import { meroAuthStorage } from './authGrantStorage';

type AuthState =
  | {
      type: 'New';
    }
  | {
      type: 'Initializing';
    }
  | {
      type: 'Failed';
      error: unknown;
    }
  | {
      type: 'Anonymous';
    }
  | {
      type: 'Authorized';
      grant: MeroApi.users.GrantResponse;
      user: MeroApi.users.User;
      isImpersonated: boolean;
    };

const defaultState = (): AuthState => ({
  type: 'New',
});

export const authApi = NovaAuthAPI({
  config: {
    appId: '5e58e80e075669e2d4f602e1', // FIXME: move to config
    version: 'v1.0',
    hostname: 'https://nova-authenticator.com/', // FIXME: move to config
  },
});

export const meroApi = MeroApi.novabookerHttpClient({
  apiBaseUrl: config.apiBaseUrl,
  socketBaseUrl: config.socketBaseUrl,
  auth: meroAuthStorage,
  oauthClient: {
    id: config.apiClientId,
    secret: config.apiSecret,
  },
  log: log,
  app: {
    version,
    platform: Platform.OS === 'windows' || Platform.OS === 'macos' ? 'web' : Platform.OS,
    type: 'mero-pro',
    onForceUpdate: () => ForceUpdate.call?.(),
  },
});

export const AuthContext = createModelContext(
  defaultState(),
  {
    setSignedIn: (
      _,
      params: { grant: MeroApi.users.GrantResponse; user: MeroApi.users.User; isImpersonated: boolean },
    ) => {
      return {
        type: 'Authorized',
        grant: params.grant,
        user: params.user,
        isImpersonated: params.isImpersonated,
      };
    },
    trySetFailed: (state, error: unknown) => {
      if (state.type === 'Initializing') {
        return {
          type: 'Failed',
          error: error,
        };
      } else {
        return state;
      }
    },
    setSignedOut: () => {
      return {
        type: 'Anonymous',
      };
    },
    mutate: (s, fn: (s: AuthState) => AuthState): AuthState => fn(s),
  },
  (dispatch) => {
    const trySyncState = async (): Promise<void> => {
      try {
        const grant = await promiseRetry(() => meroAuthStorage.getGrant(), {
          retries: 1,
          minTimeout: 200,
        });

        if (grant) {
          // Check account not removed
          try {
            const user = await promiseRetry(() => meroApi.users.getMyProfile(), {
              retries: 3,
            });

            const impersonatedUserId = await AppStorage.getImpersonatedUserId();

            dispatch.setSignedIn({ grant, user, isImpersonated: impersonatedUserId === user._id });
          } catch (e) {
            log.error(`Failed to fetch my profile on Auth initialization: ${e}`);
            AppStorage.deleteImpersonatedUserId();
            dispatch.trySetFailed(e);
          }
        } else {
          AppStorage.deleteImpersonatedUserId();
          dispatch.setSignedOut();
        }
      } catch (e) {
        log.exception(e);
        await meroAuthStorage.deleteGrant();
      }
    };

    const authGrantChangesListener = (grant: GrantResponse | undefined): void => {
      if (grant) {
        log.debug(`authGrantChangesListener: grant available, trySyncState`);
        trySyncState().catch(log.exception);
      } else {
        log.debug(`authGrantChangesListener: grant NOT available, setSignedOut`);
        dispatch.setSignedOut();
      }
    };

    return {
      init: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            const initAsync = async (): Promise<void> => {
              meroAuthStorage.subscribeAuthGrantChanges(authGrantChangesListener);
              await trySyncState();
            };

            initAsync().catch(log.exception);

            return {
              type: 'Initializing',
            };
          }

          return state;
        });
      },
      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Initializing') {
            trySyncState().catch(log.exception);

            return {
              type: 'Initializing',
            };
          }

          return state;
        });
      },
      signOut: async (): Promise<void> => {
        try {
          await meroAuthStorage.deleteGrant();

          const fbSignout = Platform.select({
            native: async () => {
              Settings.setAppID(config.facebookAppId);
              LoginManager.logOut();
            },
          });

          if (fbSignout) {
            await fbSignout();
          }
          AppStorage.deleteImpersonatedUserId();
        } catch (e) {
          log.error('signOut failed', e);
        }
      },
      signInWith: async (accessToken: string): Promise<void> => {
        log.debug(`Sign in with token ${accessToken}`);
        try {
          const grant: MeroApi.users.GrantResponse = {
            accessToken: accessToken,
            tokenType: 'bearer',
            expiresIn: 3600, // not used, hack it
            state: '',
          };

          await meroAuthStorage.saveGrant(grant);
        } catch {
          log.warn('Failed to sign in using given token');
        }
      },
    };
  },
);

export type AuthorizationProp = {
  grant: MeroApi.users.GrantResponse;
  user: MeroApi.users.User;
  isImpersonated: boolean;
};

export type AuthorizedProps = {
  authorization: AuthorizationProp;
};

type PropsWithAuthorization<P> = P & AuthorizedProps;

export function Authorized<P extends AuthorizedProps>(
  Component: React.ComponentType<P>,
  AnonymousComponent: React.ComponentType<P> = () => null,
  LoadingComponent: React.ComponentType = () => <SplashScreen />,
): React.FunctionComponent<Omit<P, keyof AuthorizedProps>> {
  return function AuthorizedComponent(props) {
    const [state] = AuthContext.useContext();

    switch (state.type) {
      case 'New': {
        return <LoadingComponent />;
      }
      case 'Initializing': {
        return <LoadingComponent />;
      }
      case 'Failed': {
        return <AuthInitFailedScreen />;
      }
      case 'Authorized': {
        const authorization: AuthorizationProp = {
          grant: state.grant,
          user: state.user,
          isImpersonated: state.isImpersonated,
        };
        // FIXME: find a type safe way to exclude a property for generic type argument
        // @ts-expect-error
        const allProps: PropsWithAuthorization<P> = { ...props, authorization: authorization };

        return <Component {...allProps} />;
      }
      case 'Anonymous': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <AnonymousComponent {...allProps} />;
      }
    }
  };
}

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = AuthContext.useContext();

  React.useEffect(() => {
    init();
  }, []);

  return <>{children}</>;
};

export const withAuthContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithAuthContextProvider(props: P) {
    return (
      <AuthContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </AuthContext.Provider>
    );
  };
};

export const AuthInitFailedScreen: React.FC = () => {
  const [state, { signOut, reload }] = AuthContext.useContext();

  const { logEvent } = useAnalytics({
    eventName: 'auth_init_failed_screen_shown',
    screenName: 'auth_init_failed',
    staticData: {
      ...(state.type === 'Failed'
        ? { error_message: isError(state.error) ? state.error.message : `${state.error}` }
        : {}),
    },
  });

  const reloadCallback = React.useCallback(() => {
    reload();
    logEvent('retry_clicked');
  }, [reload, logEvent]);

  return (
    <ModalScreenContainer>
      <View style={[meroStyles.layout.hrPadding24, { flex: 1, justifyContent: 'center', alignItems: 'center' }]}>
        <BrokenLinkIcon />
        <Spacer size={24} />
        <H1 style={meroStyles.text.alignCenter}>Ooops!</H1>
        <Spacer size={8} />
        <Body style={meroStyles.text.alignCenter}>
          Ceva nu a mers bine. Verifică conexiunea la internet și încearcă din nou.
        </Body>
        <Spacer size={24} />
        <MeroButton size="medium" text={'Încearcă din nou'} onClick={reloadCallback} expand={false} padding={32} />
        <Spacer size={24} />
        <MeroButton
          size="medium"
          text={'Ieși din cont'}
          onClick={signOut}
          backgroundColor="transparent"
          color={colors.DARK_BLUE}
          expand={false}
          padding={32}
        />
        <Spacer size="24" />
      </View>
    </ModalScreenContainer>
  );
};

import i18n from 'i18next';
import { intersection } from 'lodash';
import { Platform } from 'react-native';
import Geocoder from 'react-native-geocoding';
import * as unorm from 'unorm';

import config from '../config';
import log from './log';

i18n.on('languageChanged', () => {
  Geocoder.init(Platform.OS === 'web' ? config.google.maps.web : config.google.maps.mobile, {
    language: i18n.language,
  });
});

const COUNTRY_STATE_PREFIXES = {
  Romania: 'Județul',
};

const CHECK_PROPS = [
  'street_number',
  'point_of_interest',
  'route',
  'locality',
  'administrative_area_level_1',
  'country',
];

const STREET_EXCEPTIONS = ['unnamed road'];

export const normalize = (s: string): string =>
  unorm
    .nfd(s) // String.normalize('NFKD') crashes on Android
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const clean = (s: string): string =>
  unorm
    .nfd(s) // String.normalize('NFKD') crashes on Android
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\W/g, '')
    .trim()
    .toLowerCase();

export type GetCoordinates = (params: {
  address?: string;
  city: string;
  county: string;
  country?: keyof typeof COUNTRY_STATE_PREFIXES;
}) => Promise<google.maps.LatLngLiteral | undefined>;

export const getCoordinatesGoogle: GetCoordinates = async ({ address, city, county, country = 'Romania' }) => {
  try {
    const query = `${country}, ${COUNTRY_STATE_PREFIXES[country] ?? ''} ${county},  ${city} ${
      address ? `,${address}` : ''
    }`;

    const response = await Geocoder.from(query);

    const data = response.results.find((result) => {
      const cleanAddress = clean(result.formatted_address);
      const cleanCity = clean(city);

      return cleanAddress.includes(cleanCity) || cleanCity.includes(cleanAddress);
    });

    log.debug('[getCoordinatesGoogle] This are the result from Google', { response, data });

    if (data) {
      return data.geometry.location;
    } else if (response.results.length > 0) {
      return response.results[0].geometry.location;
    }

    throw new Error('No results found using google geocoding');
  } catch (error) {
    if (error instanceof Error) {
      log.error('[getCoordinatesGoogle] Get coordinates from address', error.message);
    }
  }
};

export type ReverseGeoLocation = (args: {
  latitude: number;
  longitude: number;
  locale?: string;
}) => Promise<{ city: string; county: string; address: string; country: string }>;

export const reverseGeocodeGoogle: ReverseGeoLocation = async ({ latitude, longitude }) => {
  const { results } = await Geocoder.from({ latitude, longitude });

  log.debug('[reverseGeocodeGoogle] List of results based on reverse geocoding', results);

  if (results.length > 0) {
    const { address } = results.reduce(
      (acc: { address: (typeof results)[0]; propsFound: number }, address) => {
        const allComponents = address.address_components.map((component) => component.types).flat();
        const propsFound = intersection(allComponents, CHECK_PROPS).length;

        if (propsFound > acc.propsFound) {
          return {
            address,
            propsFound,
          };
        }

        return acc;
      },
      {
        address: results[0],
        propsFound: 0,
      },
    );

    log.debug('[reverseGeocodeGoogle] Selected best result information', { address });

    const data = address.address_components.reduce(
      (acc: { streetNo: string; street: string; city: string; county: string; country: string }, address) => {
        if (address.types.includes('street_number')) {
          return {
            ...acc,
            streetNo: address.short_name,
          };
        } else if (address.types.includes('point_of_interest')) {
          return {
            ...acc,
            street: address.long_name,
          };
        } else if (address.types.includes('route') && !STREET_EXCEPTIONS.includes(address.short_name.toLowerCase())) {
          return {
            ...acc,
            street: address.long_name,
          };
        } else if (address.types.includes('locality')) {
          return {
            ...acc,
            city: address.short_name,
          };
        } else if (address.types.includes('administrative_area_level_1')) {
          return {
            ...acc,
            county: address.long_name
              .replace(/Județul/g, '')
              .replace(/County/g, '')
              .trim(),
          };
        } else if (address.types.includes('country')) {
          return {
            ...acc,
            country: normalize(address.long_name),
          };
        }

        return acc;
      },
      {
        streetNo: '',
        street: '',
        city: '',
        county: '',
        country: '',
      },
    );
    return {
      country: data.country,
      city: data.city,
      county: data.county,
      address: `${data.street ? data.street : ''}${data.streetNo ? ` ${data.streetNo}` : ''}`,
    };
  } else {
    throw new Error('[reverseGeocodeGoogle] No results found');
  }
};

import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.ALABASTER,
    flex: 1,
  },
  avoidKeyboard: {},
  dismissKeyboard: {
    justifyContent: 'center',
    padding: 24,
  },
});

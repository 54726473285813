import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  addButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    backgroundColor: colors.WHITE,
    borderRadius: 25,
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
});

import { Platform } from 'react-native';

import config from '../config';

export type TrackingData = Record<string, string | number | boolean | string[] | number[]>;

export type UserProperties =
  | {
      version: number;
    }
  | TrackingData;

export type Amplitude = {
  setUserProperties: (userId: string, userProperties: UserProperties) => Promise<void>;
  logEvent: (event: string, trackingData: TrackingData) => Promise<void>;
};

export const Amplitude = async (): Promise<Amplitude> => {
  if (Platform.OS === 'web') {
    //eslint-disable-next-line
    const amplitude = require('amplitude-js').default; // Amplitude relies on window, and is not available on SSR
    const project = amplitude.getInstance();
    project.init(config.amplitudeApiKey || '', null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
    });
    return {
      async setUserProperties(userId: string, userProperties: UserProperties): Promise<void> {
        project.setUserId(userId);
        project.setUserProperties(userProperties);
      },
      async logEvent(event: string, trackingData: TrackingData = {}): Promise<void> {
        project.logEvent(event, trackingData);
      },
    };
  } else if (Platform.OS === 'ios' || Platform.OS === 'android') {
    //eslint-disable-next-line
    // const amplitude = require('@amplitude/analytics-react-native'); // Amplitude relies on window, and is not available on SSR
    // amplitude.init(config.amplitudeApiKey || '');
    return {
      async setUserProperties(userId: string, userProperties: UserProperties): Promise<void> {
        // const identifyObj = new amplitude.Identify();
        // amplitude.setUserId(userId);
        // Object.entries(userProperties).forEach(([key, value]) => identifyObj.set(key, value));
        // amplitude.identify(identifyObj);
      },
      async logEvent(event: string, trackingData: TrackingData = {}): Promise<void> {
        // amplitude.track(event, trackingData);
      },
    };
  }

  return {
    async setUserProperties(): Promise<void> {
      //  console.log(userId, userProperties)
    },
    async logEvent(): Promise<void> {
      //   console.log(event, trackingData)
    },
  };
};

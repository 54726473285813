import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  subtitle: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  actionsContainer: {
    width: 192,
  },
});

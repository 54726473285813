import { Product } from '@mero/api-sdk/dist/shop/product';
import { Body, Button, Column, Header, HSpacer, Icon, Row, Spacer, TextInput } from '@mero/components';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { ActivityIndicator, FlatList, Platform, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import * as colors from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import { useAnalytics } from '../../../../../hooks/useAnalytics';
import useGoBack from '../../../../../hooks/useGoBack';

import { ShopStackParamsList } from '../../../../../types';
import PageContainer from './PageContainer';
import ProductsList from './ProductsList';
import { ShopContext } from './shopContext';

const SortLabel = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'price.asc': 'Preț crescător',
  'price.desc': 'Preț descrescător',
  'popularity.asc': 'Popularitate',
  'popularity.desc': 'Popularitate',
  /* eslint-enable @typescript-eslint/naming-convention */
};

type Props = StackScreenProps<ShopStackParamsList, 'ShopProducts' | 'ShopProductsSubcategory'>;
const ShopProductsScreen: React.FC<Props> = ({ navigation, route }) => {
  const { category, subCategory } = route.params;

  const [shopState, shopDispatch] = ShopContext.useContext();

  const goBack = useGoBack();

  const { logEvent } = useAnalytics({
    eventName: 'product_category_screen_shown',
    screenName: 'product_category',
    data: {
      category_name: subCategory ? subCategory.title : category.title,
      parent_category_name: subCategory ? category.title : 'none',
      search_query: shopState.searchTerm || 'none',
      filters: isEmpty(shopState.selectedFilters) ? 'none' : Object.keys(shopState.selectedFilters),
      number_of_search_results: shopState.total,
    },
  });

  const firstRender = React.useRef(true);

  const countFilters = React.useMemo(() => {
    return Object.values(shopState.selectedFilters).reduce((acc, curr) => acc + curr.length, 0);
  }, [shopState.selectedFilters]);

  const categoriesFilters = React.useMemo(() => {
    return subCategory
      ? { mainCategories: [category.id], subCategories: [subCategory.id] }
      : { mainCategories: [category.id] };
  }, [category.id, subCategory?.id]);

  const clearFilters = () => {
    shopDispatch.updateSelectedFilters({});
    shopDispatch.search({
      searchTerm: shopState.searchTerm,
      ...categoriesFilters,
      sort: shopState.sort,
    });
  };

  React.useEffect(() => {
    let timeout: number;
    if (firstRender.current) {
      shopDispatch.search({
        searchTerm: shopState.searchTerm,
        filters: shopState.selectedFilters,
        ...categoriesFilters,
        sort: shopState.sort,
      });
      firstRender.current = false;
    } else if (shopState.searchTerm.length > 2 || shopState.searchTerm.length === 0) {
      timeout = window.setTimeout(() => {
        shopDispatch.search({
          searchTerm: shopState.searchTerm,
          filters: shopState.selectedFilters,
          ...categoriesFilters,
          sort: shopState.sort,
        });
      }, 1000);
    }

    return () => window.clearTimeout(timeout);
  }, [shopState.searchTerm, categoriesFilters]);

  const subcategories = React.useMemo(() => {
    return subCategory ? [] : shopState.categories.find((cat) => cat.id === category.id)?.subCategories ?? [];
  }, [shopState.categories, category]);

  const onProductPress = (product: Product) => {
    logEvent('product_clicked', {
      category_name: subCategory ? subCategory.title : category.title,
      parent_category_name: subCategory ? category.title : 'none',
      product_name: product.name,
      product_price: product.oldPrice ?? product.price,
      ...(product.oldPrice && product.discountPercentage
        ? {
            product_discounted_price: product.price,
            discount_percent: product.discountPercentage,
          }
        : {}),
    });
    if (Platform.OS === 'web') {
      window.open(product.url, '_blank');
    } else {
      navigation.navigate('BarberStore', { url: product.url });
    }
  };

  React.useEffect(() => {
    if (shopState.type === 'Loaded') {
      logEvent('product_category_screen_loaded', {
        category_name: subCategory ? subCategory.title : category.title,
        parent_category_name: subCategory ? category.title : 'none',
        search_query: shopState.searchTerm || 'none',
        filters: isEmpty(shopState.selectedFilters) ? 'none' : Object.keys(shopState.selectedFilters),
        number_of_search_results: shopState.total,
      });
    }
  }, [shopState.type]);

  const title = subCategory ? subCategory.title : category.title;

  return (
    <PageContainer>
      <Column
        style={{
          backgroundColor: colors.WHITE,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.08,
          shadowRadius: 16,
          elevation: 5,
          zIndex: 2,
        }}
      >
        <SafeAreaView edges={['top']} />
        <Header
          text={title}
          LeftComponent={() => (
            <TouchableOpacity
              style={{ paddingLeft: 16 }}
              onPress={() => {
                if (subCategory) {
                  shopDispatch.search({
                    mainCategories: [category.id],
                    searchTerm: shopState.searchTerm,
                    filters: shopState.selectedFilters,
                    sort: shopState.sort,
                  });
                }
                goBack();
              }}
            >
              <Icon type="back" />
            </TouchableOpacity>
          )}
        />
        <Spacer size="8" />
        <Column style={{ paddingHorizontal: 16 }}>
          <TextInput
            placeholder={`Caută în ${title}`}
            rounded={true}
            background={colors.WHITE}
            autoFocus={false}
            isFocused={false}
            value={shopState.searchTerm}
            LeftComponent={() => (
              <View style={{ marginLeft: -10 }}>
                <Icon type="search" color={colors.DARK_BLUE} size={24} />
              </View>
            )}
            onChange={(text) => shopDispatch.updateSearchTerm(text)}
            RightComponent={() =>
              shopState.searchTerm.length > 2 ? (
                <TouchableOpacity
                  style={{
                    paddingTop: 8,
                    marginRight: -15,
                  }}
                  onPress={() => shopDispatch.updateSearchTerm('')}
                >
                  <Icon size={32} color={'#52577F'} type="circle-close" />
                </TouchableOpacity>
              ) : null
            }
          />
          <Spacer size="16" />
          <Row>
            <Button
              padding={8}
              containerStyle={{ borderWidth: 1, borderColor: '#D6DAE2', borderRadius: 32, paddingLeft: 8 }}
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
              expand={false}
              size="small"
              text={countFilters ? `Filtre (${countFilters})` : 'Filtre'}
              RightComponent={() =>
                countFilters ? (
                  <TouchableOpacity
                    style={{ width: 24, height: 24, alignItems: 'center', justifyContent: 'center' }}
                    onPress={clearFilters}
                  >
                    <Icon type="close" color={colors.DARK_BLUE} size={24} />
                  </TouchableOpacity>
                ) : (
                  <Column style={{ width: 24, height: 24, alignItems: 'center', justifyContent: 'center' }}>
                    <Icon type="dropdown" color={colors.DARK_BLUE} size={24} />
                  </Column>
                )
              }
              onClick={() => navigation.navigate('ShopFilters', { category })}
            />
            <HSpacer left={8} />
            <Button
              padding={8}
              containerStyle={{ borderWidth: 1, borderColor: '#D6DAE2', borderRadius: 32, paddingLeft: 8 }}
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
              expand={false}
              size="small"
              text={shopState.sort ? SortLabel[`${shopState.sort.by}.${shopState.sort.direction}`] : 'Sortează'}
              RightComponent={() => (
                <Column style={{ width: 24, height: 24, alignItems: 'center', justifyContent: 'center' }}>
                  <Icon type="dropdown" color={colors.DARK_BLUE} size={24} />
                </Column>
              )}
              onClick={() => navigation.navigate('ShopSort', { category })}
            />
          </Row>
          <Spacer size="16" />
        </Column>
      </Column>
      <Column style={{ flex: 1 }}>
        {subcategories.length > 0 && (
          <>
            {/*<Spacer size="24" />*/}
            <Column
              style={{
                paddingTop: 24,
                // backgroundColor: 'transparent',
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 5,
                },
                shadowOpacity: 0.1,
                shadowRadius: 16,
                elevation: 5,
                zIndex: 1,
                // borderWidth: 1,
              }}
            >
              <FlatList
                contentContainerStyle={{ paddingHorizontal: 16 }}
                data={subcategories}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }) => (
                  <Column style={{ marginBottom: 16 }}>
                    <Button
                      onClick={() => navigation.navigate('ShopProductsSubcategory', { category, subCategory: item })}
                      size="small"
                      backgroundColor={colors.WHITE}
                      color={colors.DARK_BLUE}
                      expand={false}
                      text={item.title}
                      padding={16}
                      withShadow
                    />
                  </Column>
                )}
                ItemSeparatorComponent={() => <HSpacer left={8} />}
              />
            </Column>
          </>
        )}
        {shopState.type === 'Loaded' && shopState.products.length === 0 && (
          <Body style={{ padding: 16 }}>Nu am găsit niciun rezultat</Body>
        )}
        {shopState.type === 'New' || shopState.type === 'Loading' ? (
          <Column style={{ paddingTop: 24, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large"></ActivityIndicator>
          </Column>
        ) : null}
        {shopState.products.length > 0 && (
          <ProductsList
            products={shopState.products}
            categoryId={category.id}
            subCategoryId={subCategory?.id}
            onProductPress={onProductPress}
          />
        )}
      </Column>
    </PageContainer>
  );
};

export default ShopProductsScreen;

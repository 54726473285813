import { Price, ServiceGroupId } from '@mero/api-sdk/dist/services';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import {
  Text,
  Column,
  H1,
  H3s,
  Icon,
  MeroHeader,
  Row,
  SimpleListItem,
  Spacer,
  Line,
  colors,
  Body,
  Button,
  useShowError,
  FormCard,
  SafeAreaView,
} from '@mero/components';
import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { styles } from '../../../components/AddBookingScreen/styles';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';

const formatDuration = (duration: number) => {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours} h ${minutes} min`;
  }

  return `${duration} min`;
};

export const getPrice = (price: Price, discounts = 0): string => {
  switch (price.type) {
    case 'range': {
      return `${price.range.from} - ${price.range.to} lei`;
    }
    case 'fixed': {
      const priceValue = (price.promo ?? price.fixed) - discounts;
      return `${priceValue > 0 ? priceValue : 0} lei`;
    }
    case 'hidden': {
      return `preț variabil`;
    }
    default: {
      return '';
    }
  }
};

const PageSortingServicesScreen: React.FC = ({}) => {
  const { isPhone } = useMediaQueries();
  const showError = useShowError();

  const goBack = useGoBack();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [{ services }, { reload }] = ServicesContext.useContext();

  const formattedServices = React.useMemo(
    () => [
      ...services.grouped,
      { group: { name: 'Alte servicii', _id: 'others' as ServiceGroupId }, services: services.others },
    ],
    [services],
  );

  const [servicesOrder, setServicesOrder] = React.useState(formattedServices);

  const sortService = (group: GroupWithServices, groupIndex: number, serviceIndex: number, direction: -1 | 1) => () => {
    const groupServices = group.services;

    if (serviceIndex === 0 && direction === -1 && groupIndex > 0) {
      //@ts-ignore
      const rearranged = servicesOrder.reduce((acc: typeof formattedServices, g, index) => {
        if (index === groupIndex + direction) {
          const services = [...g.services, groupServices[serviceIndex]];
          return [
            ...acc,
            {
              ...g,
              services,
            },
          ];
        } else if (index === groupIndex) {
          const [, ...rest] = groupServices;
          return [...acc, { ...g, services: rest }];
        }

        return [...acc, g];
      }, []);
      //@ts-ignore
      setServicesOrder(rearranged);
    } else if (serviceIndex === groupServices.length - 1 && direction === 1 && groupIndex < servicesOrder.length - 1) {
      //@ts-ignore
      const rearranged = servicesOrder.reduce((acc, g, index) => {
        if (index === groupIndex + direction) {
          const services = [groupServices[serviceIndex], ...g.services];
          return [
            ...acc,
            {
              ...g,
              services,
            },
          ];
        } else if (index === groupIndex) {
          const rest = groupServices.slice(0, groupServices.length - 1);
          return [...acc, { ...g, services: rest }];
        }

        return [...acc, g];
      }, []);

      //@ts-ignore
      setServicesOrder(rearranged);
    } else {
      //@ts-ignore
      const moved = groupServices.reduce((acc, s, idx) => {
        if (
          (direction < 0 && serviceIndex + direction < 0) ||
          (direction > 0 && serviceIndex + direction > groupServices.length - 1) ||
          (idx !== serviceIndex + direction && idx !== serviceIndex)
        ) {
          return [...acc, s];
        } else if (idx === serviceIndex + direction) {
          return direction > 0 ? [...acc, s, groupServices[serviceIndex]] : [...acc, groupServices[serviceIndex], s];
        }
        return acc;
      }, []);

      const rearranged = servicesOrder.map((gr, idx) => (idx === groupIndex ? { ...gr, services: moved } : gr));

      //@ts-ignore
      setServicesOrder(rearranged);
    }
  };

  const sortGroup = (groupIndex: number, direction: -1 | 1) => () => {
    //@ts-ignore
    const rearranged = servicesOrder.reduce((acc, s, idx) => {
      if (
        (direction < 0 && groupIndex + direction < 0) ||
        (direction > 0 && groupIndex + direction > servicesOrder.length - 1) ||
        (idx !== groupIndex + direction && idx !== groupIndex)
      ) {
        return [...acc, s];
      } else if (idx === groupIndex + direction) {
        if (s.group._id === 'others' || servicesOrder[groupIndex].group._id === 'others') {
          return direction < 0 ? [...acc, s, servicesOrder[groupIndex]] : [...acc, servicesOrder[groupIndex], s];
        }
        return direction > 0 ? [...acc, s, servicesOrder[groupIndex]] : [...acc, servicesOrder[groupIndex], s];
      }
      return acc;
    }, []);

    //@ts-ignore
    setServicesOrder(rearranged);
  };

  const saveChanges = async () => {
    try {
      if (pageState.type === 'Loaded') {
        const otherServicesGroup = servicesOrder.find((grouped) => grouped.group._id === 'others');
        if (!otherServicesGroup) {
          throw new Error(`Could not find 'other' services group`);
        }

        const groupedWithoutOthers = servicesOrder.filter((grouped) => grouped.group._id !== 'others');

        await meroApi.pages.updateGroupedServicesOrder(pageState.page.details._id, {
          //@ts-ignore
          grouped: groupedWithoutOthers.map((grouped) => ({
            groupId: grouped.group._id,
            serviceIds: grouped.services.map((sv) => sv._id),
          })),
          others: otherServicesGroup.services.map((sv) => sv._id),
        });

        await reloadAsync();
        reload({ pageId: pageState.page.details._id });

        goBack();
      }
    } catch (error) {
      showError(error);
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
  }, []);

  React.useEffect(() => {
    setServicesOrder(formattedServices);
  }, [formattedServices]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canClose onClose={goBack} title="Setează ordine servicii" />
      <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
        <H1 style={{ paddingHorizontal: 8 }}>Setează ordine servicii</H1>
        <Spacer size={6} />
        <Body style={{ paddingHorizontal: 8 }}>
          Apasă pe indicatorul din dreptul fiecărei linii pentru a-i schimba ordinea în listă.
        </Body>
        <Column
          style={{
            flex: 1,
            paddingVertical: 24,
            backgroundColor: '#fff',
            marginTop: 24,
            marginBottom: 60,
            borderRadius: 4,
          }}
        >
          <FlatList
            data={servicesOrder}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index: groupIdx }) => (
              <Column>
                <Row style={{ paddingHorizontal: 16 }}>
                  <H3s style={{ flex: 1 }}>{item.group.name}</H3s>
                  {item.group._id !== 'others' && (
                    <Row>
                      <TouchableOpacity style={{ paddingRight: 12 }} onPress={sortGroup(groupIdx, -1)}>
                        <Icon type="go-up" color={colors.COMET} />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={sortGroup(groupIdx, 1)}>
                        <Icon type="go-down" color={colors.COMET} />
                      </TouchableOpacity>
                    </Row>
                  )}
                </Row>
                {item.services.length === 0 && <Spacer size={24} />}
                {item.services.map((service, serviceIdx) => (
                  <Column
                    key={service._id}
                    style={{
                      borderBottomWidth: serviceIdx < item.services.length - 1 ? 1 : 0,
                      borderBottomColor: '#E9ECEF',
                      marginHorizontal: 16,
                      paddingVertical: 16,
                    }}
                  >
                    <SimpleListItem
                      style={{ borderLeftWidth: 2, borderLeftColor: service.color, paddingLeft: 8 }}
                      paddingTop={0}
                      paddingBottom={0}
                      title={service.name}
                      subtitle={
                        <Text smallBody>
                          {formatDuration(service.durationInMinutes)},{' '}
                          {service.price.type === 'fixed' && service.price?.promo ? (
                            <>
                              <Text smallBody>
                                {service.price.promo} lei{' '}
                                <Text
                                  smallBody
                                  style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
                                >
                                  ({service.price.fixed} lei)
                                </Text>
                              </Text>
                            </>
                          ) : (
                            <Text smallBody>{getPrice(service.price)}</Text>
                          )}
                        </Text>
                      }
                      IconComponent={() => (
                        <Row>
                          <TouchableOpacity
                            style={{ marginRight: 12 }}
                            //@ts-ignore
                            onPress={sortService(item, groupIdx, serviceIdx, -1)}
                          >
                            <Icon type="go-up" />
                          </TouchableOpacity>
                          {/*@ts-ignore*/}
                          <TouchableOpacity onPress={sortService(item, groupIdx, serviceIdx, 1)}>
                            <Icon type="go-down" />
                          </TouchableOpacity>
                        </Row>
                      )}
                      iconPosition="right"
                    />
                  </Column>
                ))}
              </Column>
            )}
            keyExtractor={(item) => item.group._id}
            ItemSeparatorComponent={() => (
              <>
                <Line />
                <Spacer size={24} />
              </>
            )}
          />
        </Column>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text="Salvează modificări" onClick={saveChanges} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text="Salvează modificări"
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default PageSortingServicesScreen;

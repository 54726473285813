import { ProductId, ProductSearchHit } from '@mero/api-sdk';
import { Body, Column, H2s, MeroHeader, SearchTextInput, Spacer, Title, colors } from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { RouteProp, CompositeNavigationProp } from '@react-navigation/core';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { StackNavigationProp } from '@react-navigation/stack';

import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  ProductsTabStackParamList,
  RootStackParamList,
} from '../../../types';
import ProductSummaryCard, { convertProductSummaryData } from './ProductSummaryCard';

type Props = {
  route: RouteProp<ProductsTabStackParamList, 'ProductSearchScreen'>;
  navigation: CompositeNavigationProp<
    StackNavigationProp<ProductsTabStackParamList, 'ProductSearchScreen'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProductsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const ProductSearchScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('products');
  const [searchState, { debounceSearch, loadMore, updateSearch }] = SearchProductsContext.useContext();
  const productsListRef = React.useRef<FlatList<ProductSearchHit>>(null);

  const navigateProductEditCallback = React.useCallback(
    (productId: ProductId) => {
      navigation.navigate('ProductEdit', { screen: 'ProductMenu', params: { productId } });
    },
    [navigation],
  );

  React.useEffect(() => {
    productsListRef.current?.scrollToOffset({ animated: false, offset: 0 });
  }, [searchState.query.search]);

  const navigateProductDashboardCallback = React.useCallback(() => {
    navigation.navigate('ProductsDashboardScreen');
  }, [navigation]);

  return (
    <>
      <ModalScreenContainer>
        <MeroHeader
          canGoBack={true}
          onBack={navigateProductDashboardCallback}
          TitleComponent={() => <Title style={{ color: colors.BLACK }}>{t('productsTitle')}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <Spacer size={20} />
        <View style={{ paddingHorizontal: 24, flex: 1 }}>
          <SearchTextInput
            autoFocus={true}
            placeholder={t('searchProducts')}
            value={searchState.query.search}
            onChange={(keyword) => {
              if (keyword.length > 2 || keyword.length === 0) {
                debounceSearch({ query: { search: keyword } });
              } else {
                updateSearch(keyword);
              }
            }}
          />
          <Spacer size={24} />

          {searchState.result.products.length !== 0 && (
            <FlatList
              ref={productsListRef}
              showsVerticalScrollIndicator={false}
              onEndReachedThreshold={0.8}
              data={searchState.result.products}
              onEndReached={loadMore}
              ListFooterComponent={
                searchState.type === 'Loading' ? <ActivityIndicator size="large" /> : <Spacer size={163} />
              }
              renderItem={(i) => (
                <ProductSummaryCard
                  product={convertProductSummaryData(i.item, t)}
                  showLine={i.index !== searchState.result.products.length - 1}
                  openProductDetails={navigateProductEditCallback}
                />
              )}
            />
          )}

          {searchState.result.products.length === 0 && searchState.type === 'Loaded' && (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <H2s>Niciun rezultat</H2s>
              <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                Schimbă termenul de căutare
              </Body>
            </Column>
          )}
        </View>
      </ModalScreenContainer>
    </>
  );
};

export default ProductSearchScreen;

import { GrantResponse } from '@mero/api-sdk/dist/users';

import log from '../../utils/log';

export type AuthGrantListener = (grant: GrantResponse | undefined) => void;

export type AuthGrantSource = {
  readonly subscribeAuthGrantChanges: (listener: AuthGrantListener) => void;
};

const authGrantListeners: { listeners: AuthGrantListener[] } = {
  listeners: [],
};

export const subscribeAuthGrantChanges: AuthGrantSource['subscribeAuthGrantChanges'] = (listener) => {
  authGrantListeners.listeners.push(listener);
};

export const notifyAuthGrantListeners = (grant: GrantResponse | undefined): void => {
  try {
    authGrantListeners.listeners.forEach((listener) => listener(grant));
  } catch (e) {
    log.exception(e);
  }
};

import * as React from 'react';

import { CommonActions, NavigationProp, useNavigation } from '@react-navigation/native';

import { NavigationContext } from '../contexts/NavigationContext';
import { RootStackParamList } from '../types';

type GoBack = () => void;

/**
 * Will pop one screen from the stack, if can go back or use fallback function when can't
 * @param fallback - fallback function, if not passed - will navigate to calendar
 */
const useGoBack = (fallback?: () => void): GoBack => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [{ previousPath }] = NavigationContext.useContext();

  const resetStack = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: 'Authorized' }],
      }),
    );
  };

  const callback = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else if (fallback !== undefined) {
      fallback();
    } else {
      resetStack();
    }
  }, [navigation, fallback, previousPath]);

  return callback;
};

export default useGoBack;

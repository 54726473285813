import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const MyAccountMenu = ({ size = 22.5, color = BLACK, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path
      id="iconmonstr-user-circle-thin"
      d="M11,0A11,11,0,1,1,0,11,11.005,11.005,0,0,1,11,0Zm7.45,17.792a2.318,2.318,0,0,0-1.489-.779c-3.527-.83-3.756-1.376-3.989-1.887a2,2,0,0,1,.188-1.812c1.582-2.986,1.916-4.522.941-6.141A3.439,3.439,0,0,0,11,5.583,3.455,3.455,0,0,0,7.875,7.2c-.977,1.64-.635,3.165.962,6.111a1.994,1.994,0,0,1,.2,1.81c-.243.537-.56,1.091-4,1.894a2.321,2.321,0,0,0-1.488.777,10.077,10.077,0,0,0,14.9,0Zm.6-.717a10.084,10.084,0,1,0-16.092,0,3.489,3.489,0,0,1,1.878-.954c1.856-.424,3.095-.766,3.371-1.377a1.143,1.143,0,0,0-.172-1c-1.757-3.24-2.092-5.088-.944-7.016A4.394,4.394,0,0,1,11,4.667,4.378,4.378,0,0,1,14.887,6.7c1.147,1.906.821,3.762-.917,7.043a1.152,1.152,0,0,0-.164,1c.28.612,1.507.951,3.361,1.374a3.477,3.477,0,0,1,1.878.956Z"
      transform="translate(0.25 0.25)"
      stroke={color}
    />
  </Svg>
);

export default React.memo(MyAccountMenu);

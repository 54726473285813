import * as MeroApi from '@mero/api-sdk';
import { CalendarEntry, AppointmentId } from '@mero/api-sdk/dist/calendar';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { SavedClient } from '@mero/api-sdk/dist/clients';
import { Body, Column, H1, H2s, MeroHeader, Spacer, Title } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext, ItemBooking, withUiKey, WithUiKey } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList, CheckoutSubStackParamList } from '../../../types';
import log from '../../../utils/log';
import CheckoutAppointmentSummary from './CheckoutAppointmentSummary';
import { serviceToCheckoutItem } from './SelectServiceScreen';

export const appointmentToCheckoutItem = (
  appointment: CalendarEntry.Appointment,
  user: CheckoutUserPreview,
  pageWorkers: MeroApi.workers.SavedWorker[],
): Omit<ItemBooking, 'type'> => {
  const workerUser = pageWorkers.find((worker) => worker._id === appointment.payload.worker._id);
  const serviceUser = workerUser
    ? {
        _id: workerUser.user._id,
        phone: workerUser.user.phone,
        profile: {
          firstname: workerUser.user.firstname,
          lastname: workerUser.user.lastname,
          photo: workerUser.profilePhoto,
        },
      }
    : user;

  return {
    calendarId: appointment.calendarId,
    appointmentId: appointment._id,
    worker: {
      _id: appointment.payload.worker._id,
      user: serviceUser,
    },
    start: appointment.start,
    status: appointment.payload.status,
    occurrenceIndex: appointment.occurrenceIndex,
    items: appointment.payload.bookedServices.map((service) =>
      withUiKey({
        type: 'Service',
        ...serviceToCheckoutItem(service, serviceUser),
      }),
    ),
  };
};

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutSubStackParamList & RootStackParamList & AuthorizedStackParamList, 'SelectBookingScreen'>;

const SelectBookingScreen: React.FC<Props> = ({ page, authorization, route }) => {
  const { t } = useTranslation('checkout');

  const goBack = useGoBack();
  const isFocused = useIsFocused();

  const [formState, { setItem, setClient }] = CheckoutFormContext.useContext();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [appointments, setAppointments] = React.useState<CalendarEntry.Appointment[]>([]);
  const [clientDetails, setClientDetails] = React.useState<SavedClient | undefined>();

  const existingBookingItems = React.useMemo(
    () =>
      formState.draft.items
        .filter((item): item is WithUiKey<ItemBooking> => item.type === 'Booking')
        .map((item) => item.appointmentId),
    [formState.draft.items],
  );

  const getBookings = React.useCallback(async (appointmentIds: AppointmentId[]) => {
    try {
      const clientId = route.params?.clientId;

      if (clientId) {
        const [client, appointmentsList] = await Promise.all([
          meroApi.clients.getClientById(clientId),
          meroApi.checkout.getUnpaidAppointments({
            pageId: page.details._id,
            clientId,
          }),
        ]);

        setClientDetails(client);
        setAppointments(appointmentsList.filter((appointment) => !appointmentIds.includes(appointment._id)));
      } else {
        const appointmentsList = await meroApi.checkout.getUnpaidAppointments({
          pageId: page.details._id,
        });

        setAppointments(appointmentsList);
      }
    } catch (error) {
      log.error('Failed to get appointments', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      getBookings(existingBookingItems);
    }
  }, [isFocused, existingBookingItems]);

  const onSelect = React.useCallback((appointment: CalendarEntry.Appointment) => {
    if (!formState.draft.items.some((item) => item.type === 'Booking' && item.appointmentId === appointment._id)) {
      setItem({
        type: 'Booking',
        ...appointmentToCheckoutItem(appointment, authorization.user, page.workers),
      });

      if (appointment.payload.client && appointment.payload.user) {
        const client = appointment.payload.client;
        const user = appointment.payload.user;
        setClient({
          type: 'existing',
          client: {
            _id: client._id,
            pageId: page.details._id,
            user: {
              _id: user._id,
              phone: user.phone,
              profile: {
                firstname: client.firstname,
                lastname: client.lastname,
                photo: user.profile.photo,
              },
            },
          },
        });
      }
    }
    goBack();
  }, []);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader title={t('selectBooking')} canGoBack onBack={goBack} />
      {isLoading ? null : appointments.length === 0 ? (
        <>
          <Column style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 24 }}>
            <H2s style={{ textAlign: 'center' }}>
              {clientDetails
                ? t('noBookingsClient', {
                    firstName: clientDetails.user.firstname,
                    lastName: clientDetails.user.lastname,
                  })
                : t('noBookings')}
            </H2s>
            <Spacer size={8} />
            <Body style={{ textAlign: 'center' }}>{clientDetails ? '' : t('noBookingsDescription')}</Body>
          </Column>
        </>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <Column style={{ paddingHorizontal: 16 }}>
            <Spacer size={16} />
            <H1>{t('selectBooking')}</H1>
            <Spacer size={8} />
            {clientDetails ? (
              <>
                <Body>
                  <Trans
                    ns={'checkout'}
                    i18nKey="selectBookingClientDescription"
                    t={t}
                    values={{
                      firstName: clientDetails.user.firstname,
                      lastName: clientDetails.user.lastname,
                    }}
                  >
                    0<Title>1</Title>
                  </Trans>
                </Body>
              </>
            ) : null}
          </Column>
          <Column>
            {appointments.map((appointment) => (
              <CheckoutAppointmentSummary
                withClient={!clientDetails}
                key={appointment._id}
                appointment={appointment}
                onPress={onSelect}
              />
            ))}
          </Column>
        </ScrollView>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(SelectBookingScreen, CurrentBusiness, Authorized);

import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../styles';

const styles = StyleSheet.create({
  itemRow: { paddingHorizontal: sizes[8], paddingVertical: sizes[12] },
  activeItem: {
    backgroundColor: colors.ATHENS_GRAY,
  },
  text: { marginLeft: 8, width: '85%', fontSize: 14, fontFamily: 'open-sans', flex: 1 },
  container: {
    overflow: 'hidden',
  },
});

export default styles;

import { RecurrenceRule } from '@mero/api-sdk';
import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { BlockedTimeReason } from '@mero/api-sdk/dist/calendar/blocked-time-reason';
import { PageId } from '@mero/api-sdk/dist/pages';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import { ApiError, apiError } from '@mero/shared-sdk/dist/common';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type State =
  | {
      type: 'Ready';
    }
  | {
      type: 'Creating';
    }
  | {
      type: 'Created';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
      isOverride: boolean;
    };

const defaultState = (): State => ({ type: 'Ready' });

export const BlockedTimeCreateContext = createModelContext(
  defaultState(),
  {
    setCreating: () => ({
      type: 'Creating',
    }),
    setCreated: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Created',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown>; isOverride: boolean }) => ({
      type: 'Failed',
      ...payload,
    }),
    reset: () => ({
      type: 'Ready',
    }),
  },
  (dispatch) => {
    return {
      createBlockedTime: (payload: {
        calendarId: CalendarId;
        pageId: PageId;
        start: Date;
        end: Date;
        isFullDay: boolean;
        workerId: WorkerId;
        reason: BlockedTimeReason;
        recurrenceRule?: RecurrenceRule.Any;
        override: boolean;
      }): void => {
        const create = async () => {
          try {
            dispatch.setCreating();

            log.debug('Creating blocked time', payload);

            const appointmentId = await meroApi.calendar.createBlockedTime({
              calendarId: payload.calendarId,
              start: payload.start,
              end: payload.end,
              recurrenceRule: payload.recurrenceRule,
              pageId: payload.pageId,
              workerId: payload.workerId,
              reason: payload.reason,
              override: payload.override,
            });

            dispatch.setCreated({
              calendarId: payload.calendarId,
              appointmentId: appointmentId,
            });

            log.debug(`New blocked time with id ${appointmentId} successfully created`);
          } catch (e) {
            if (apiError(t.unknown).is(e)) {
              if (e.code === 14) {
                // booking override
                dispatch.setFailed({
                  error: e,
                  isOverride: true,
                });
              } else {
                log.exception(e);
                dispatch.setFailed({
                  error: e,
                  isOverride: false,
                });
              }
            } else {
              log.exception(e);
            }
          }
        };

        create();
      },
      reset: dispatch.reset,
    };
  },
);

export const withBlockedTimeCreateContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithBlockedTimeCreateContextProvider(props: P) {
    return (
      <BlockedTimeCreateContext.Provider>
        <Content {...props} />
      </BlockedTimeCreateContext.Provider>
    );
  };
};

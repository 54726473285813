import { Body } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from './styles';

export type SplashScreenProps = {
  readonly text?: string;
};

export const SplashScreen: React.FC<SplashScreenProps> = ({ text }: SplashScreenProps) => {
  return (
    <View style={styles.container}>
      <Body>{text ?? 'Loading ...'}</Body>
    </View>
  );
};

export default SplashScreen;

import { ApiError, apiError, OccurrenceIndex } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { InviteId, InvitePreview } from '@mero/api-sdk/dist/invites';
import { InviteTypeAppointmentRequest } from '@mero/api-sdk/dist/invites/invite-type';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';
import { Dimensions, Linking, Platform } from 'react-native';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type Resolution = 'accept' | 'reject';

type Ready = {
  readonly type: 'Ready';
};

type Processing = {
  readonly type: 'Processing';
  readonly inviteId: InviteId;
  readonly resolution: Resolution;
};

type Processed = {
  readonly type: 'Processed';
  readonly inviteId: InviteId;
  readonly resolution: Resolution;
};

type Failed = {
  readonly type: 'Failed';
  readonly inviteId: InviteId;
  readonly resolution: Resolution;
  readonly error: ApiError<unknown> | undefined;
};

type State = Ready | Processing | Processed | Failed;

const defaultState = (): State => ({
  type: 'Ready',
});

const sendNotification = async (appointmentId: AppointmentId, phoneNumber: string) => {
  try {
    if (Platform.OS === 'web' && Dimensions.get('window').width > 768) {
      return;
    }
    const message = await meroApi.notifications.getRenderedTextForAppointmentDeleted({
      appointmentId,
      occurrenceIndex: 0 as OccurrenceIndex,
    });

    const separator = Platform.OS === 'ios' ? '&' : '?';
    const url = `sms:${phoneNumber}${separator}body=${encodeURIComponent(message.text)}`;

    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    }
  } catch (error) {
    log.error('Failed to get page notification settings', error);
  }
};

export const ProcessInviteContext = createModelContext(
  defaultState(),
  {
    trySetProcessed: (s, payload: { inviteId: InviteId; resolution: Resolution }) => {
      if (s.type === 'Processing' && s.inviteId === payload.inviteId && s.resolution === payload.resolution) {
        return {
          type: 'Processed',
          inviteId: s.inviteId,
          resolution: s.resolution,
        };
      } else {
        return s;
      }
    },
    trySetFailed: (s, payload: { inviteId: InviteId; resolution: Resolution; error: unknown }) => {
      if (s.type === 'Processing' && s.inviteId === payload.inviteId && s.resolution === payload.resolution) {
        return {
          type: 'Failed',
          inviteId: s.inviteId,
          resolution: s.resolution,
          error: apiError(t.unknown).is(payload.error) ? payload.error : undefined,
        };
      } else {
        return s;
      }
    },
    mutate: (s, fn: (s: State) => State): State => fn(s),
    reset: () => ({
      type: 'Ready',
    }),
  },
  (dispatch) => {
    return {
      process: (payload: { invite: InvitePreview; resolution: Resolution }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const process = async (): Promise<void> => {
              try {
                await meroApi.invites.fulfillInvite({
                  inviteId: payload.invite._id,
                  resolution: payload.resolution,
                });
                if (payload.invite.type === InviteTypeAppointmentRequest.value && payload.invite.fromUser.phone) {
                  sendNotification(payload.invite.appointment.appointmentId, payload.invite.fromUser.phone);
                }
                dispatch.trySetProcessed({
                  inviteId: payload.invite._id,
                  resolution: payload.resolution,
                });
              } catch (error: unknown) {
                dispatch.trySetFailed({ inviteId: payload.invite._id, resolution: payload.resolution, error: error });
                throw error;
              }
            };

            process().catch(log.exception);

            return {
              type: 'Processing',
              inviteId: payload.invite._id,
              resolution: payload.resolution,
            };
          } else {
            return state;
          }
        });
      },
      reset: dispatch.reset,
    };
  },
);

export const ProcessInviteContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ProcessInviteContext.Provider>{children}</ProcessInviteContext.Provider>;
};

export const withProcessInviteContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithProcessInviteContextProvider(props: P) {
    return (
      <ProcessInviteContextProvider>
        <Content {...props} />
      </ProcessInviteContextProvider>
    );
  };
};

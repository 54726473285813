import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { Avatar, Icon, SmallBody, Row, Title } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { styles } from './styles';

export type Props = {
  /**
   * Worker name to display
   */
  readonly selected?: SavedWorker;
  /**
   * Item press handler
   */
  readonly onPress?: () => void;

  readonly hasError?: {
    readonly message: string;
  };

  readonly disabled?: boolean;
};

const SelectedPerformerItem: React.FC<Props> = ({ selected, onPress, hasError, disabled }: Props) => {
  const isDisabled = disabled || onPress === undefined;
  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        disabled={isDisabled}
        style={[styles.container, hasError && styles.containerError, isDisabled && styles.containerDisabled]}
      >
        <Row style={styles.performerRow}>
          {selected === undefined ? (
            <>
              <View style={styles.selectProIcon}>
                <Svg width="10" height="11" viewBox="0 0 10 11">
                  <Path
                    id="Union_5"
                    data-name="Union 5"
                    d="M1,11a1,1,0,0,1-1-1V9.565A2.4,2.4,0,0,1,1.959,7.812c1.689-.287,1.689-.8,1.689-1.116v-.51a3.125,3.125,0,0,1-1.013-2.36V2.3A2.2,2.2,0,0,1,5,0,2.2,2.2,0,0,1,7.365,2.3v1.53a3.122,3.122,0,0,1-1.013,2.36V6.7c0,.319,0,.813,1.689,1.116A2.427,2.427,0,0,1,10,9.565V10a1,1,0,0,1-1,1Z"
                    fill="rgba(82,87,127,0.5)"
                  />
                </Svg>
              </View>
              <Title style={styles.title}>Alege profesionist</Title>
              {onPress !== undefined && <Icon type="arrow-right" />}
            </>
          ) : (
            <>
              <Avatar
                size={24}
                firstname={selected.user.firstname ?? ''}
                lastname={selected.user.lastname ?? ''}
                source={selected.profilePhoto?.small}
              />
              <Title style={styles.title}>
                {selected.user.firstname ?? ''} {selected.user.lastname ?? ''}
              </Title>
              {onPress !== undefined && <Icon type="arrow-right" />}
            </>
          )}
        </Row>
      </TouchableOpacity>
      {hasError && <SmallBody style={styles.errorText}>{hasError.message}</SmallBody>}
    </>
  );
};

export default SelectedPerformerItem;

import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  card: {
    marginBottom: sizes[16],
    padding: sizes[16],
  },
  container: { height: 32, alignItems: 'center' },
  iconContainer: { marginRight: sizes[12] },
  dateWrapper: { flex: 1 },
  dotsButton: { width: 24, height: 24, alignSelf: 'baseline' },
  textWrapper: { paddingLeft: 44 },
});

export default styles;

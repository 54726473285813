import { Body, colors, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import { ProceedIcon } from '../../screens/Authorized/CheckoutScreen';

type Props = {
  readonly onPress?: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

const AddCheckoutButton: React.FC<Props> = ({ style, onPress }) => {
  const { t } = useTranslation('checkout');

  return (
    <View style={[{ height: 43 }, style]}>
      <TouchableOpacity onPress={onPress}>
        <View
          style={[
            {
              flexDirection: 'row',
              height: 43,
              borderRadius: 25,
              backgroundColor: colors.WHITE,
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 24,
              width: 'auto',
            },
          ]}
        >
          <ProceedIcon color={colors.DARK_BLUE} />
          <Body style={[{ color: colors.DARK_BLUE, marginLeft: 8 }, meroStyles.text.semibold]} numberOfLines={1}>
            {t('newProceed')}
          </Body>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default AddCheckoutButton;

import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { colors, Line, SimpleListItem } from '@mero/components';
import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

export type Props = {
  /**
   * Worker name to display
   */
  readonly worker: SavedWorker;
  /**
   * Item press handler
   */
  readonly onPress?: () => void;
  /**
   * When set to true will add a horizontal line at the bottom of the element
   */
  readonly hasLine?: boolean;
  /**
   * Custom styles TouchableOpacity container
   */
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Custom styles for SimpleListItem
   */
  readonly itemStyle?: StyleProp<ViewStyle>;
};

const WorkerListItem: React.FC<Props> = ({ worker, onPress, hasLine, style, itemStyle }: Props) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={onPress === undefined} style={style}>
      <SimpleListItem
        title={`${worker.user.firstname ?? ''} ${worker.user.lastname ?? ''}`}
        icon={onPress !== undefined ? 'next' : undefined}
        iconColor={colors.DARK_BLUE}
        iconPosition="right"
        alignContent="middle"
        style={itemStyle}
        image={{
          firstname: worker.user.firstname,
          lastname: worker.user.lastname,
          src: worker.profilePhoto?.medium,
        }}
      />
      {hasLine ? <Line /> : null}
    </TouchableOpacity>
  );
};

export default WorkerListItem;

import { colors } from '@mero/components';
import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

type Props = {
  color?: string;
};

const Marker: React.FC<Props> = ({ color = colors.BLACK }) => (
  <Svg width={48} height={48}>
    <Path data-name="Path 8331" d="M0 0h48v48H0Z" fill="none" />
    <Path
      data-name="Path 8332"
      d="M24 4a13.99 13.99 0 0 0-14 14c0 10.5 14 26 14 26s14-15.5 14-26A13.99 13.99 0 0 0 24 4ZM14 18a10 10 0 0 1 20 0c0 5.76-5.76 14.38-10 19.76C19.84 32.42 14 23.7 14 18Z"
      fill={color}
    />
    <Circle data-name="Ellipse 643" cx={5} cy={5} r={5} transform="translate(19 13)" fill={color} />
  </Svg>
);

export default Marker;

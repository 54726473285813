import { Body, Button, colors, H2s, Icon, Label, Row, SearchTextInput, SmallBody, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { CheckoutDraftsCountContext } from '../../../contexts/CheckoutDraftsCountContext';
import { CheckoutsContext, FinishedTransaction, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import { EditIcon } from './DraftPreview';
import { PaymentIcon } from './FinishedPreview';

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

const CheckoutTabWeb: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [checkoutDraftsCountState, { reload: reloadCount }] = CheckoutDraftsCountContext.useContext();

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      updateSearch(page.details._id, searchQuery);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchQuery, interval]);

  const getType = React.useCallback((proceed: FinishedTransaction) => {
    if (proceed.paymentTypes.includes('Card') && proceed.paymentTypes.includes('Cash')) {
      return 'CardAndCash';
    }

    if (proceed.paymentTypes.includes('Card')) {
      return 'Card';
    }

    if (proceed.paymentTypes.includes('Cash')) {
      return 'Cash';
    }

    if (proceed.paymentTypes.includes('Membership')) {
      return 'Membership';
    }

    if (proceed.paymentTypes.includes('BankTransfer')) {
      return 'BankTransfer';
    }

    return 'CardAndCash';
  }, []);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      <InfiniteScrollView onEndReached={() => loadMoreFinished(page.details._id)} withLoading>
        <FormCard dropShaddow paddings="none" rounded style={{ marginHorizontal: 24, overflow: 'hidden' }}>
          <Row style={{ flex: 1, paddingLeft: 24, paddingRight: 16, paddingVertical: 16 }} alignItems="center">
            <H2s style={{ flex: 1 }}>{t('checkout')}</H2s>
            <Column style={{ minWidth: 327 }}>
              <SearchTextInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder={t('searchCheckoutPlaceholder')}
              />
            </Column>
          </Row>
          <Line />
          {/* Table Header */}
          <Row style={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 12 }}>
            <Column style={{ width: '5%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('type')}</Title>
            </Column>
            <Column style={{ width: '10%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('amount')}</Title>
            </Column>
            <Column style={{ width: '25%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('client')}</Title>
            </Column>
            <Column style={{ width: '10%', paddingRight: 4 }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('id')}</Title>
            </Column>
            <Column style={{ width: '20%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('status')}</Title>
            </Column>
            <Column style={{ width: '30%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('date')}</Title>
            </Column>
          </Row>
          <Line />
          {/* Table Body - Draft */}
          <Column style={{ backgroundColor: colors.SKY_BLUE }}>
            {checkoutsState.drafts.data.slice(0, 2).map((draft, index) => (
              <React.Fragment key={draft._id}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    alignItems: 'center',
                    paddingVertical: 16,
                  }}
                  onPress={() =>
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: {
                        screen: 'AddProceedScreen',
                        params: { type: 'Booking', transactionId: draft._id },
                      },
                    })
                  }
                >
                  <Column style={{ width: '5%' }}>
                    <EditIcon />
                  </Column>
                  <Column style={{ width: '10%', paddingRight: 4 }}>
                    <SmallBody>
                      {scaledToString(draft.total.total.amount)} {t(draft.total.total.unit)}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '25%', paddingRight: 4 }}>
                    <SmallBody>{nameGenerator(draft.client?.user.profile ?? {}, t('unknownUser'))}</SmallBody>
                  </Column>
                  <Column style={{ width: '10%', paddingRight: 4 }}></Column>
                  <Column style={{ width: '20%', paddingRight: 4 }}>
                    <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t(draft.status)}</Label>
                  </Column>
                  <Row style={{ width: '30%', paddingRight: 4 }}>
                    <SmallBody style={{ flex: 1 }}>
                      {DateTime.fromJSDate(draft.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                    </SmallBody>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </Row>
                </TouchableOpacity>
                {(index < checkoutsState.drafts.data.length - 1 || checkoutsState.finished.data.length > 0) && <Line />}
              </React.Fragment>
            ))}
            {checkoutDraftsCountState.type === 'Loaded' && checkoutDraftsCountState.draftsCount > 2 ? (
              <Column style={{ paddingHorizontal: 24, paddingVertical: 16 }}>
                <TouchableOpacity
                  style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}
                  onPress={() =>
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: { screen: 'AllDraftsScreen' },
                    })
                  }
                >
                  <Title
                    style={{
                      color: colors.DARK_BLUE,
                      flex: 1,
                      textAlign: 'right',
                      paddingRight: 22,
                      fontSize: 14,
                    }}
                  >
                    {t('showAllDrafts')}
                  </Title>
                  <Column
                    style={{
                      width: 18,
                      height: 18,
                      backgroundColor: colors.RADICAL_RED,
                      borderRadius: 9,
                      overflow: 'hidden',
                      marginRight: 8,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Label style={{ color: colors.WHITE }}>{checkoutDraftsCountState.draftsCount - 2}</Label>
                  </Column>
                  <Icon type="next" color={colors.DARK_BLUE} />
                </TouchableOpacity>
              </Column>
            ) : null}
          </Column>

          {/* Table Body - Finished */}
          {checkoutsState.finished.data.map((proceed, index) => {
            const type = getType(proceed);

            return (
              <React.Fragment key={proceed._id}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    alignItems: 'center',
                    paddingVertical: 16,
                  }}
                  onPress={() =>
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: {
                        screen: 'ProceedDetailsScreen',
                        params: { checkoutTransactionId: proceed._id, backMode: 'one' },
                      },
                    })
                  }
                >
                  <Column style={{ width: '5%' }}>
                    <PaymentIcon type={type} />
                  </Column>
                  <Column style={{ width: '10%', paddingRight: 4 }}>
                    <SmallBody>
                      {scaledToString(proceed.total.total.amount)} {t(proceed.total.total.unit)}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '25%', paddingRight: 4 }}>
                    <SmallBody>{nameGenerator(proceed.client?.user.profile ?? {}, t('unknownUser'))}</SmallBody>
                  </Column>
                  <Column style={{ width: '10%', paddingRight: 4 }}>
                    <SmallBody>#{proceed.code}</SmallBody>
                  </Column>
                  <Column style={{ width: '20%', paddingRight: 4 }}>
                    <Label
                      style={{
                        fontFamily: 'open-sans-semibold',
                        color: proceed.status === 'Finished' ? colors.SHAMROCK : colors.RADICAL_RED,
                      }}
                    >
                      {t(
                        proceed.isProtocol && (proceed.status === 'Finished' || proceed.status === 'Deleted')
                          ? proceed.status === 'Finished'
                            ? 'proceedProtocol'
                            : 'proceedProtocolDeleted'
                          : proceed.status,
                      )}
                    </Label>
                  </Column>
                  <Row style={{ width: '30%', paddingRight: 4 }}>
                    <SmallBody style={{ flex: 1 }}>
                      {DateTime.fromJSDate(proceed.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                    </SmallBody>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </Row>
                </TouchableOpacity>
                {index < checkoutsState.finished.data.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {checkoutsState.finished.data.length === 0 && checkoutsState.drafts.data.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s>{t('noProceedsAvailable')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noProceedsAvailableDescription')}</Body>
                <Spacer size={24} />
              </Column>
            </>
          )}
        </FormCard>
        <Spacer size={64} />
      </InfiniteScrollView>

      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(CheckoutTabWeb, CurrentBusiness);

import { PageId } from '@mero/api-sdk';
import { ModalOverlay, ConfirmBox, Spacer, H1, InputWithLabel, TextInput, useToast } from '@mero/components';
import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { useSafeInput } from '../../../../../hooks/useSafeInput';

import { meroApi } from '../../../../../contexts/AuthContext';

type Props = {
  onClose: () => void;
  pageId: PageId;
  reload: (params: { pageId: PageId }) => Promise<void>;
};

const RomanianIban = t.string.pipe(
  new t.Type<string, string>(
    'RomanianIban',
    (u): u is string => tt.NonEmptyString.is(u) && /^RO\d{2}[A-Za-z0-9]{20}$/.test(u),
    (input, context) => {
      const nonEmptyStringValidation = tt.NonEmptyString.validate(input, context);
      if (isLeft(nonEmptyStringValidation)) {
        return t.failure(input, context);
      }

      const iban = nonEmptyStringValidation.right;
      if (/^RO\d{2}[A-Za-z0-9]{20}$/.test(iban)) {
        return t.success(iban);
      } else {
        return t.failure(input, context);
      }
    },
    t.identity,
  ),
);

const PageOnlinePaymentsDestination: React.FC<Props> = ({ onClose, pageId, reload }) => {
  const { t } = useTranslation('onlinePayments');
  const toast = useToast();

  const [iban, setIban] = useSafeInput(RomanianIban)();
  const [inProgress, setInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const saveChanges = async () => {
    if (!iban.isValid) {
      return setShowErrors(true);
    }
    try {
      setInProgress(true);
      await meroApi.pro.onlinePayments.updatePayoutDestination({
        pageId: pageId,
        destination: {
          type: 'BankAccount',
          iban: iban.value,
        },
      });
      toast.show({
        type: 'success',
        text: t('updateIBANSuccess'),
      });
      reload({ pageId: pageId });
      onClose();
    } catch (e) {}
    setInProgress(false);
  };

  const leftAction = {
    text: t('cancel'),
    onPress: onClose,
    flex: 10,
  };

  const rightAction = {
    text: t('save'),
    onPress: saveChanges,
    flex: 10,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('bankAccountTitle')}
          canClose={!inProgress}
          onClose={onClose}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('enterBankAccount')}</H1>
          <Spacer size={24} />
          <InputWithLabel label={t('iban')} isError={showErrors && !iban.isValid} errorText={t('ibanError')}>
            <TextInput
              value={iban.value}
              onChange={setIban}
              placeholder={t('ibanPlaceholder')}
              isError={showErrors && !iban.isValid}
            />
          </InputWithLabel>
          <Spacer size={8} />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default PageOnlinePaymentsDestination;

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const LocationServices: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <Path d="M0,0H24V24H0Z" fill="none" />
    <Path
      fill={color}
      d="M15.381,3.923,3.935,8.71a.779.779,0,0,0,.016,1.442l4.164,1.614a.779.779,0,0,1,.444.444l1.606,4.156a.783.783,0,0,0,1.45.023l4.8-11.438A.792.792,0,0,0,15.381,3.923Z"
      transform="translate(1.676 2)"
    />
  </Svg>
);

export default LocationServices;

import { PageId } from '@mero/api-sdk/dist/pages';
import { BusinessCategory } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import { SavedService } from '@mero/api-sdk/dist/services/saved-service';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type GroupedServices = {
  readonly grouped: GroupWithServices[];
  readonly others: SavedService[];
  readonly all: SavedService[];
};

export type ServicesContextState =
  | {
      readonly type: 'New';
      readonly services: GroupedServices;
      readonly serviceGroups: ServiceGroup[];
      readonly businessCategories: BusinessCategory[];
      readonly editInfo: {
        workers: WorkerId[];
        private: boolean;
        automaticApproval: boolean;
        availableDays: boolean[];
      };
    }
  | {
      readonly type: 'Loading';
      readonly services: GroupedServices;
      readonly serviceGroups: ServiceGroup[];
      readonly businessCategories: BusinessCategory[];
      readonly editInfo: {
        workers: WorkerId[];
        private: boolean;
        automaticApproval: boolean;
        availableDays: boolean[];
      };
    }
  | {
      readonly type: 'Loaded';
      readonly services: GroupedServices;
      readonly serviceGroups: ServiceGroup[];
      readonly businessCategories: BusinessCategory[];
      readonly editInfo: {
        workers: WorkerId[];
        private: boolean;
        automaticApproval: boolean;
        availableDays: boolean[];
      };
    }
  | {
      readonly type: 'Failed';
      readonly services: GroupedServices;
      readonly serviceGroups: ServiceGroup[];
      readonly businessCategories: BusinessCategory[];
      readonly editInfo: {
        workers: WorkerId[];
        private: boolean;
        automaticApproval: boolean;
        availableDays: boolean[];
      };
      readonly error: unknown;
    };

const defaultState = (): ServicesContextState => ({
  type: 'New',
  services: {
    grouped: [],
    others: [],
    all: [],
  },
  serviceGroups: [],
  editInfo: {
    workers: [],
    private: false,
    automaticApproval: true,
    availableDays: [true, true, true, true, true, true, true],
  },
  businessCategories: [],
});

export const ServicesContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        services: GroupedServices;
        businessCategories: BusinessCategory[];
        serviceGroups: ServiceGroup[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          services: result.services,
          businessCategories: result.businessCategories,
          serviceGroups: result.serviceGroups,
          editInfo: state.editInfo,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (
      _,
      payload: {
        error: unknown;
        services: GroupedServices;
        businessCategories: BusinessCategory[];
        serviceGroups: ServiceGroup[];
      },
    ) => {
      return {
        type: 'Failed',
        services: payload.services,
        businessCategories: payload.businessCategories,
        serviceGroups: payload.serviceGroups,
        editInfo: defaultState().editInfo,
        error: payload.error,
      };
    },
    tryResetError: (state) => {
      if (state.type === 'Failed') {
        return {
          type: 'Loaded',
          services: state.services,
          businessCategories: state.businessCategories,
          serviceGroups: state.serviceGroups,
          editInfo: defaultState().editInfo,
        };
      }

      return state;
    },
    updateActiveWorkers(state, workers: WorkerId[]) {
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          workers,
        },
      };
    },
    updateEditInfo(state, editInfo: Partial<ServicesContextState['editInfo']>) {
      return {
        ...state,
        editInfo: {
          ...state.editInfo,
          ...editInfo,
        },
      };
    },
    resetEditInfo(state) {
      return {
        ...state,
        editInfo: defaultState().editInfo,
      };
    },
    updateServiceGroups(state, serviceGroups: ServiceGroup[]) {
      return {
        ...state,
        serviceGroups,
      };
    },
    mutate: (s, fn: (s: ServicesContextState) => ServicesContextState): ServicesContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({ pageId, searchTerm }: { pageId: PageId; searchTerm?: string }) => {
      try {
        log.debug('Start reloading services');

        const [groupedServices, businessCategories, serviceGroups, allServices] = await Promise.all([
          meroApi.pages.getGroupedServices(pageId, searchTerm),
          meroApi.pages.getBusinessCategories(),
          meroApi.pages.getServiceGroups(pageId),
          meroApi.pages.getPageServices({ pageId: pageId }),
        ]);

        dispatch.trySetResult({
          services: {
            grouped: groupedServices.grouped,
            others: groupedServices.others,
            all: allServices,
          },
          businessCategories,
          serviceGroups,
        });
      } catch (error) {
        dispatch.setFailed({
          error: error,
          services: defaultState().services,
          businessCategories: defaultState().businessCategories,
          serviceGroups: defaultState().serviceGroups,
        });
        log.exception(error);
      }
    };

    return {
      reload: (payload: { pageId: PageId; searchTerm?: string }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload(payload).catch(log.exception);

            return {
              type: 'Loading',
              services: state.services,
              businessCategories: state.businessCategories,
              serviceGroups: state.serviceGroups,
              editInfo: state.editInfo,
            };
          }

          return state;
        });
      },
      changeActiveWorkers: (workers: WorkerId[]): void => {
        dispatch.updateActiveWorkers(workers);
      },
      changeEditInfo: (editInfo: Partial<ServicesContextState['editInfo']>): void => {
        dispatch.updateEditInfo(editInfo);
      },
      resetEditInfo: () => {
        dispatch.updateEditInfo(defaultState().editInfo);
      },
      getServiceGroups: async (pageId: PageId) => {
        const serviceGroups = await meroApi.pages.getServiceGroups(pageId);
        dispatch.updateServiceGroups(serviceGroups);
      },
    };
  },
);

export const withServicesContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithServicesContextProvider(props: P) {
    return (
      <ServicesContext.Provider>
        <Content {...props} />
      </ServicesContext.Provider>
    );
  };
};

import {
  colors,
  Column,
  MeroHeader,
  Spacer,
  styles as meroStyles,
  H1,
  Row,
  Body,
  Button,
  useShowError,
  BottomNotification,
  Title,
  SmallBody,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { Authorized, AuthorizedProps } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { MarketingContext } from '../../../../../contexts/MarketingContext';
import { MarketingSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

const BoostIcon = (props: SvgProps) => (
  <Svg width={139.863} height={139.863} {...props}>
    <Defs></Defs>
    <G data-name="Group 7660">
      <G data-name="Group 6006">
        <G filter="url(#a)">
          <G fill="#7431f7" data-name="Path 9077">
            <Path d="M69.931 102.363a32.08 32.08 0 0 1-14.942-3.657 32.806 32.806 0 0 1-6.258-4.268 33.901 33.901 0 0 1-5.17-5.624 31.158 31.158 0 0 1-4.435-8.68A32.622 32.622 0 0 1 37.5 69.931 32.325 32.325 0 0 1 46.999 47 32.325 32.325 0 0 1 69.93 37.5a32.325 32.325 0 0 1 22.932 9.499 32.325 32.325 0 0 1 9.5 22.932 32.325 32.325 0 0 1-9.499 22.932 32.325 32.325 0 0 1-22.933 9.5Z" />
            <Path
              fill="#fff"
              d="M69.931 39a30.832 30.832 0 0 0-21.872 9.06A30.832 30.832 0 0 0 39 69.93c0 6.514 2.05 12.906 5.772 17.998 2.891 3.958 6.665 7.225 10.912 9.447a30.579 30.579 0 0 0 14.247 3.487 30.832 30.832 0 0 0 21.872-9.06 30.832 30.832 0 0 0 9.06-21.872 30.832 30.832 0 0 0-9.06-21.872A30.832 30.832 0 0 0 69.931 39m0-3c18.74 0 33.932 15.192 33.932 33.931 0 18.74-15.192 33.932-33.932 33.932-11.262 0-21.243-5.487-27.582-14.165C38.42 84.321 36 77.408 36 69.931 36 51.191 51.192 36 69.931 36Z"
            />
          </G>
        </G>
        <Path fill="none" d="M49.573 49.573h40.718v40.718H49.573Z" data-name="Path 8323" />
        <Path
          fill="#fff"
          d="M65.573 77.368c-1.2 3.132-4.678 5.1-8.348 5.293.173-3.531 2.138-7.138 5.348-8.353l.649.663c-1.949 1.937-2.62 3.517-2.726 4.402a8.367 8.367 0 0 0 4.43-2.666Zm-1.449-14.432a11.005 11.005 0 0 0-7.334 3.325 11.7 11.7 0 0 0-2.117 2.872 5.531 5.531 0 0 1 6.547-.519 28.616 28.616 0 0 1 2.904-5.678Zm6.62 22.26a11.627 11.627 0 0 0 2.876-2.117 10.952 10.952 0 0 0 3.312-7.355 31.118 31.118 0 0 1-5.681 2.921 5.535 5.535 0 0 1-.507 6.551Zm14.389-30.472q-.817-.056-1.604-.056c-11.878 0-18.4 9.446-20.481 16.548l5.632 5.632c7.352-2.36 16.521-8.62 16.521-20.343v-.075c0-.556-.024-1.124-.069-1.706Zm-7.56 10.12a2.544 2.544 0 1 1 2.54-2.544 2.543 2.543 0 0 1-2.54 2.544Z"
        />
      </G>
    </G>
  </Svg>
);

const ListIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6633" transform="translate(-126 -558)">
      <Circle cx={12} cy={12} r={12} fill="#7431f7" data-name="Ellipse 11" transform="translate(126 558)" />
      <G data-name="Group 5794">
        <Path fill="#fff" d="m135.407 575.743-1.385-1.385 4.569-4.489-4.569-4.485 1.385-1.384 5.954 5.869Z" />
        <Path fill="none" d="M126 558h24v24h-24z" data-name="Rectangle 2" />
      </G>
    </G>
  </Svg>
);

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MarketingSettingsStackParamList, 'BoostMarketing'>;

const BoostMarketingScreen: React.FC<Props> = ({
  navigation,
  route,
  page,
  authorization,
}): React.ReactElement | null => {
  const { t } = useTranslation('marketing');
  const goBack = useGoBack();
  const showError = useShowError();
  const insets = useSafeAreaInsets();

  const [, { openChat }] = IntercomContext.useContext();
  const [{ type, pageBoostSettings }, { getPageBoostSettings }] = MarketingContext.useContext();

  const [whyNotDialog, setWhyNotDialog] = React.useState(false);

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  const rightAction = {
    text: t('confirm'),
    onPress: () => {
      setWhyNotDialog(false);
    },
  };

  React.useEffect(() => {
    getPageBoostSettings(page.details._id).catch((e) => {
      log.error('Failed to get page boost settings', e);
      showError(e);
    });
  }, []);

  return (
    <>
      <ModalScreenContainer edges={['left', 'top', 'right']}>
        <MeroHeader title="" canClose onClose={goBack} />
        <Column style={{ flex: 1 }}>
          <ScrollView style={{ paddingHorizontal: 16 }} showsVerticalScrollIndicator={false} bounces={false}>
            <Spacer size={12} />
            <Column alignItems="center">
              <Column>
                <Image
                  style={{ width: 280, height: 280 }}
                  source={require('../../../../../assets/images/boost-marketing.png')}
                />
              </Column>
            </Column>
            <H1 style={{ textAlign: 'center', paddingHorizontal: 24 }}>{t('boostMarketingLabel')}</H1>
            <Spacer size={16} />
            <Row style={{ paddingHorizontal: 8 }}>
              <ListIcon />
              <Body style={{ paddingLeft: 12, flex: 1 }}>
                <Trans ns="marketing" t={t} i18nKey="boostMarketingList1">
                  0
                </Trans>
              </Body>
            </Row>
            <Spacer size={12} />
            <Row style={{ paddingHorizontal: 8 }}>
              <ListIcon />
              <Body style={{ paddingLeft: 12, flex: 1 }}>
                <Trans ns="marketing" t={t} i18nKey="boostMarketingList2">
                  0
                </Trans>
              </Body>
            </Row>
            <Spacer size={12} />
            <Row style={{ paddingHorizontal: 8 }}>
              <ListIcon />
              <Body style={{ paddingLeft: 12, flex: 1 }}>
                <Trans ns="marketing" t={t} i18nKey="boostMarketingList3">
                  0<Body style={meroStyles.text.semibold}>1</Body>
                </Trans>
              </Body>
            </Row>
            <Spacer size={12} />
            <Row style={{ paddingHorizontal: 8 }}>
              <ListIcon />
              <Body style={{ paddingLeft: 12, flex: 1 }}>
                <Trans
                  ns="marketing"
                  t={t}
                  i18nKey="boostMarketingList4"
                  values={{ percentage: pageBoostSettings.defaultValues.commissionPercent }}
                >
                  0<Body style={meroStyles.text.semibold}>1</Body>2
                </Trans>
              </Body>
            </Row>
            {pageBoostSettings.settings.type === 'Unavailable' ? (
              <>
                <Spacer size={24} />
                <Column style={{ backgroundColor: colors.YELLOW_ORANGE, padding: 16, borderRadius: 6 }}>
                  <Title style={{ color: colors.WHITE }}>{t('unavailableBoostMarketingTitle')}</Title>
                  <SmallBody style={{ color: colors.WHITE }}>{t('unavailableBoostMarketingDescription')}</SmallBody>
                </Column>
              </>
            ) : type !== 'New' ? (
              <>
                <Spacer size={48} />
                <Column>
                  <Button
                    text={t('continue')}
                    onClick={() => {
                      if (pageBoostSettings.settings.type === 'Unavailable') {
                        setWhyNotDialog(true);
                      } else {
                        goBack();
                        navigation.navigate('ConfigureBoost');
                      }
                    }}
                  />
                </Column>
              </>
            ) : null}
            <Spacer size={24} />
            <Body style={{ textAlign: 'center' }}>
              <Trans ns={'marketing'} t={t} i18nKey="marketingMoreDetails">
                0
                <Body style={[{ color: colors.DARK_BLUE }, meroStyles.text.semibold]} onPress={onContactUsPressed}>
                  1
                </Body>
              </Trans>
            </Body>
            <Body></Body>
            <Spacer size={96} />
          </ScrollView>
        </Column>
      </ModalScreenContainer>
      {whyNotDialog && (
        <Column style={{ position: 'absolute', left: 0, right: 0, bottom: 0, alignItems: 'center' }}>
          <BottomNotification
            type="info"
            text={t('whyNotDetails')}
            style={{ paddingBottom: insets.bottom + 16, maxWidth: 500 }}
            leftAction={{
              text: '',
              onPress: () => null,
            }}
            rightAction={rightAction}
          />
        </Column>
      )}
    </>
  );
};

export default pipe(BoostMarketingScreen, CurrentBusiness, Authorized);

import { MeroHeader } from '@mero/components';
import * as React from 'react';

export type HeaderViewProps = {
  readonly onClose: () => void;
};

const HeaderView: React.FC<HeaderViewProps> = ({ onClose }: HeaderViewProps) => {
  return <MeroHeader title="Importă contacte" onClose={onClose} canClose={onClose !== undefined} />;
};

export default HeaderView;

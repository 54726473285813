import * as React from 'react';

import config from '../../../../config';
import MenuScreenWebView from '../MenuScreenWebView';

const PagePermissionsSettingsScreen: React.FC = ({}) => {
  return <MenuScreenWebView baseUrl={`${config.proUrl}/drawer/page/permissions`} title="Conturi și permisiuni" />;
};

export default PagePermissionsSettingsScreen;

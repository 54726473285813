import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withCheckoutNotificationsCountContextProvider } from '../contexts/CheckoutDraftsCountContext';
import { withCheckoutFormContextProvider } from '../contexts/CheckoutFormContext';
import { CombineCheckoutStackParamList } from '../types';
import { CheckoutMembershipStackNavigator } from './CheckoutMembershipStackNavigator';
import { CheckoutStackNavigator } from './CheckoutStackNavigator';
import { CheckoutSubStackNavigator } from './CheckoutSubStackNavigator';
import { styles } from './styles';

const CheckoutStack = createStackNavigator<CombineCheckoutStackParamList>();

export const CombineCheckoutStackNavigator = pipe(
  function AddCheckoutStackNavigator() {
    const { t } = useTranslation();

    const { isPhone, isDesktop } = useMediaQueries();

    const modalStyle = isPhone
      ? styles.phoneModalCard
      : Platform.OS === 'web'
      ? styles.desktopModalCard
      : styles.tabletModalCard;

    return (
      <CheckoutStack.Navigator
        screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'transparentModal' }}
      >
        <CheckoutStack.Group
          screenOptions={{
            cardStyle: isDesktop ? styles.desktopModalCheckout : modalStyle,
            ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          }}
        >
          <CheckoutStack.Screen
            name="CheckoutStack"
            component={CheckoutStackNavigator}
            options={{
              headerShown: false,
              ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : {}),
            }}
          />
          <CheckoutStack.Screen
            name="CheckoutSubStack"
            component={CheckoutSubStackNavigator}
            options={{
              headerShown: false,
              ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
            }}
          />
          <CheckoutStack.Screen
            name="CheckoutMembershipStack"
            component={CheckoutMembershipStackNavigator}
            options={{
              headerShown: false,
              ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
            }}
          />
        </CheckoutStack.Group>
      </CheckoutStack.Navigator>
    );
  },
  withCheckoutFormContextProvider,
  withCheckoutNotificationsCountContextProvider,
);

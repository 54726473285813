import * as t from 'io-ts';
import { Platform } from 'react-native';

export type PlatformVersion = {
  readonly major: number;
  readonly minor?: number;
  readonly patch?: number;
};

export function tryParsePlatformVersion(version: string | number): PlatformVersion | undefined {
  if (t.string.is(version)) {
    const m = version.match(/^(\d+)(\.(\d+))?(\.(\d+))?$/i);
    if (m) {
      const major = parseInt(m[1]);
      const minor = m[3] ? parseInt(m[3]) : undefined;
      const patch = m[5] ? parseInt(m[5]) : undefined;

      if (Number.isFinite(major)) {
        return {
          major: major,
          minor: Number.isFinite(minor) ? minor : undefined,
          patch: Number.isFinite(patch) ? patch : undefined,
        };
      }
    }
  } else if (t.number.is(version)) {
    return { major: version };
  }

  return undefined;
}

export const PlatformVersionParsed: PlatformVersion | undefined = tryParsePlatformVersion(Platform.Version);

import { SmallBody, Row, Column, colors, Line, HSpacer } from '@mero/components';
import { SortDirection } from '@mero/shared-sdk';
import * as React from 'react';
import { ActivityIndicator, FlatList, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
import { FlatListProps } from 'react-native/Libraries/Lists/FlatList';

import { LoadingComponent } from '../../../../../../components/LoadingComponent';

export const UnsortedIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <G data-name="Group 5658">
      <Path fill="none" d="M18 0v18H0V0z" data-name="Rectangle 2" />
      <Path d="m5.846 10.672.743-.744L9 12.382l2.41-2.454.743.744L9 13.872Z" />
      <Path d="m12.154 7.327-.743.744L9 5.617 6.59 8.071l-.743-.744L9 4.127Z" data-name="iconmonstr-arrow-63" />
    </G>
  </Svg>
);

export const AscSortedIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <G data-name="Group 7681">
      <Path fill="none" d="M0 18V0h18v18z" data-name="Rectangle 2" />
      <Path d="m5.846 10.328.743.744 2.412-2.454 2.409 2.454.743-.744L9 7.128Z" />
    </G>
  </Svg>
);

export const DescSortedIcon = (props: SvgProps) => (
  <Svg width={18} height={18} {...props}>
    <G data-name="Group 7681">
      <Path fill="none" d="M18 0v18H0V0z" data-name="Rectangle 2" />
      <Path d="m5.846 7.672.743-.744L9 9.382l2.41-2.454.743.744L9 10.872Z" />
    </G>
  </Svg>
);
export type Primitives = string | number | boolean;

export type Column<T extends Record<string, Primitives>> = {
  field: keyof T;
  headerName: string;
  width: `${number}%`;
  minWidth?: number;
  hidden?: boolean;
  CustomComponent?: React.FC<React.PropsWithChildren<T>>;
  style?: {
    header?: React.ComponentProps<typeof SmallBody>['style'];
    cell?: React.ComponentProps<typeof SmallBody>['style'];
    fixedCell?: React.ComponentProps<typeof SmallBody>['style'];
  };
} & (
  | ({
      sortable: true;
    } & (
      | {
          sortingMode: 'client';
          sortComparator?: (direction: SortDirection) => (v1: T, v2: T) => number;
        }
      | {
          sortingMode: 'server';
          sortComparator: (direction: SortDirection) => () => Promise<void>;
        }
    ))
  | { sortable: false }
);

export type Props<T extends Record<string, Primitives>> = {
  columns: Column<T>[];
  data: T[];
  fixedRows?: T[];
  loading?: boolean;
  loadMore?: (sortBy?: keyof T, sortDir?: SortDirection) => void;
  defaultSortBy?: keyof T;
  defaultSortDir?: SortDirection;
  ListEmptyComponent?: FlatListProps<T>['ListEmptyComponent'];
  style?: {
    table?: FlatListProps<T>['style'];
    container?: FlatListProps<T>['contentContainerStyle'];
    fixedRow?: FlatListProps<T>['contentContainerStyle'];
    row?: FlatListProps<T>['contentContainerStyle'];
    header?: FlatListProps<T>['contentContainerStyle'];
  };
};

const Table = <T extends Record<string, Primitives>>({
  columns,
  data,
  fixedRows,
  loading,
  loadMore,
  defaultSortBy,
  defaultSortDir,
  ListEmptyComponent,
  style = {},
}: Props<T>): React.ReactElement => {
  const [sortColumn, setSortColumn] = React.useState<keyof T | undefined>(defaultSortBy);
  const [sortOrder, setSortOrder] = React.useState<SortDirection | undefined>(defaultSortDir);
  const [processedData, setProcessedData] = React.useState<T[]>(data);
  const [isLoading, setIsLoading] = React.useState(loading);
  const [columnWidths, setColumnWidths] = React.useState<Record<keyof T, number>>();

  const handleSort = (column: Column<T>) => {
    if (!column.sortable) {
      return;
    }
    if (sortColumn === column.field && sortOrder === 'ASC') {
      setSortOrder('DESC');
    } else if (sortColumn === column.field && sortOrder === 'DESC') {
      setSortColumn(undefined);
      setSortOrder(undefined);
    } else {
      setSortColumn(column.field);
      setSortOrder('ASC');
    }
  };

  const serverSort = async (sortFn: () => Promise<void>) => {
    if (sortColumn && sortOrder) {
      setIsLoading(true);
      await sortFn();
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setProcessedData(data);
  }, [data]);

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    if (sortColumn && sortOrder) {
      const column = columns.find((column) => column.field === sortColumn);
      if (!column || !column.sortable) {
        return;
      }
      if (column.sortingMode === 'client') {
        const sortFunction = column.sortComparator
          ? column.sortComparator(sortOrder)
          : defaultSortFunction(sortColumn, sortOrder);

        const sortedData = [...processedData].sort(sortFunction);
        setProcessedData(sortedData);
      } else if (column.sortingMode === 'server' && column.sortable) {
        serverSort(column.sortComparator(sortOrder));
      }
    } else {
      setProcessedData(data);
    }
  }, [sortColumn, sortOrder]);

  const defaultSortFunction = (accessor: keyof T, direction: SortDirection) => (a: T, b: T) =>
    a[accessor] > b[accessor] ? (direction === 'ASC' ? 1 : -1) : direction === 'DESC' ? -1 : 1;

  const renderHeader = () => (
    <>
      <Row style={[{ paddingVertical: 12, paddingLeft: 16 }, style.header]}>
        {columns
          .filter((c) => c.hidden !== true)
          .map((column) => (
            <TouchableOpacity
              key={column.field.toString()}
              style={{
                width: columnWidths?.[column.field] ?? 0,
                flexDirection: 'row',
                alignItems: 'center',
                paddingRight: 16,
              }}
              onPress={() => handleSort(column)}
              disabled={!column.sortable}
            >
              <SmallBody style={[{ fontFamily: 'open-sans-semibold', flex: 1 }, column.style?.header]}>
                {column.headerName}
              </SmallBody>
              {column.sortable && <HSpacer left={4} />}
              {column.sortable &&
                (column.field === sortColumn ? (
                  sortOrder === 'ASC' ? (
                    <AscSortedIcon />
                  ) : (
                    <DescSortedIcon />
                  )
                ) : (
                  <UnsortedIcon />
                ))}
            </TouchableOpacity>
          ))}
      </Row>
      <Line color={colors.ATHENS_GRAY} />
    </>
  );

  const renderItem = ({ item, index }: { item: T; index: number }) => (
    <Row
      style={[
        {
          paddingVertical: 24,
          paddingLeft: 16,
        },
        ...(fixedRows && index < fixedRows.length
          ? [{ backgroundColor: colors.ATHENS_GRAY }, style.fixedRow]
          : [style.row]),
      ]}
    >
      {columns
        .filter((c) => c.hidden !== true)
        .map(({ CustomComponent, ...column }) => (
          <Column key={column.field.toString()} style={{ width: columnWidths?.[column.field] ?? 0, paddingRight: 16 }}>
            <SmallBody style={[column.style?.cell, fixedRows && index < fixedRows.length && column.style?.fixedCell]}>
              {CustomComponent ? <CustomComponent {...item}>{item[column.field]}</CustomComponent> : item[column.field]}
            </SmallBody>
          </Column>
        ))}
    </Row>
  );

  const renderFooter = () => {
    if (!isLoading) return null;
    return (
      <Column style={{ paddingVertical: 20 }}>
        <ActivityIndicator animating size="large" color={colors.ATHENS_GRAY} />
      </Column>
    );
  };

  return (
    <View
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;

        const actualWidth = width - 16;

        const totalMinWidth = columns.reduce((acc, curr) => acc + (curr.minWidth ?? 0), 0);
        const isMinWidthRequired = actualWidth < totalMinWidth;

        const calculatedWidths = columns.reduce((acc, column) => {
          const widthPercentage = parseFloat(column.width) / 100;
          const effectiveWidth = isMinWidthRequired ? column.minWidth ?? 0 : actualWidth * widthPercentage;
          return {
            ...acc,
            [column.field]: Math.max(effectiveWidth, column.minWidth ?? 0),
          };
        }, {} as Record<keyof T, number>);

        setColumnWidths(calculatedWidths);
      }}
    >
      {columnWidths ? (
        <ScrollView horizontal showsHorizontalScrollIndicator={Platform.OS === 'web'}>
          <FlatList
            style={style.table}
            contentContainerStyle={style.container}
            data={fixedRows ? [...fixedRows, ...processedData] : processedData}
            ListHeaderComponent={renderHeader}
            stickyHeaderIndices={Array.isArray(fixedRows) ? [fixedRows.length] : undefined}
            keyExtractor={(_, index) => index.toString()}
            renderItem={renderItem}
            extraData={data}
            ItemSeparatorComponent={() => <Line color={colors.ATHENS_GRAY} />}
            ListFooterComponent={renderFooter}
            ListEmptyComponent={loading ? null : ListEmptyComponent}
            onEndReached={loadMore ? () => loadMore(sortColumn, sortOrder) : undefined}
            onEndReachedThreshold={0.5}
          />
        </ScrollView>
      ) : (
        <LoadingComponent />
      )}
    </View>
  );
};

export default Table;

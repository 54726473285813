import { BusinessCategory } from '@mero/api-sdk/dist/services';
import { useShowError } from '@mero/components';
import { fold } from 'fp-ts/Either';
import { identity } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';
import base64 from 'react-native-base64';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../../hooks/useAnalytics';
import useGoBack from '../../../../hooks/useGoBack';

import { AuthContext, Authorized, AuthorizedProps } from '../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import {
  NewPlaceDetails,
  OnboardingContext,
  withOnboardingContextProvider,
} from '../../../../contexts/OnboardingContext';
import { AuthorizedStackParamList, OnboardingStackParamList, RootStackParamList } from '../../../../types';
import NativeOnboardingScreenView from './NativeOnboardingScreenView';

type Props = AuthorizedProps & {
  readonly navigation: CompositeNavigationProp<
    CompositeNavigationProp<
      StackNavigationProp<OnboardingStackParamList, 'OnboardingScreen'>,
      StackNavigationProp<AuthorizedStackParamList, 'Onboarding'>
    >,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

export const jsonEncode = (data: Record<string, unknown>) =>
  pipe(data, JSON.stringify, encodeURIComponent, base64.encode);
export const jsonDecode = <I, A>(data: string, type: t.Decoder<I, A>) =>
  pipe(
    data,
    base64.decode,
    decodeURIComponent,
    JSON.parse,
    type.decode,
    fold(() => {
      throw new Error('Invalid query data');
    }, identity),
  );

const NativeOnboardingScreen: React.FC<Props> = ({ authorization, navigation }) => {
  const showError = useShowError();
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [onboardingState] = OnboardingContext.useContext();
  const [, { signOut }] = AuthContext.useContext();

  const { logEvent } = useAnalytics({
    eventName: 'pro_onboarding_account_details_screen_shown',
    screenName: 'pro_onboarding_account_details',
  });

  /**
   * Loaded state for CurrentBusinessContext means there is already a page user have access to
   */
  const canGoBack = currentBusinessState.type === 'Loaded';
  const goBack = useGoBack();
  const onGoBack = React.useCallback(() => {
    if (canGoBack) {
      goBack();
    } else {
      signOut();
    }
  }, [goBack, canGoBack, signOut]);
  const businessCategories: BusinessCategory[] | undefined =
    onboardingState.type === 'Loaded' || onboardingState.type === 'Saving' || onboardingState.type === 'Failed'
      ? onboardingState.businessCategories
      : undefined;

  const readonly = onboardingState.type !== 'Loaded';

  const onSubmit = React.useCallback(
    (place: NewPlaceDetails): void => {
      if (onboardingState.type === 'Loaded') {
        navigation.navigate('OnboardingQuestion1Screen', {
          d: jsonEncode(place),
        });
      } else {
        showError(
          new Error(`Cannot save onboarding form: unexpected state type ${onboardingState.type}`),
          'Nu se poate crea contul la moment, vă rugăm să verificați datele si încercați in cateva minute',
        );
      }
    },
    [onboardingState, showError],
  );

  return (
    <NativeOnboardingScreenView
      goBack={onGoBack}
      defaultValue={{
        firstname: authorization.user.profile.firstname,
        lastname: authorization.user.profile.lastname,
        phone: authorization.user.phone,
        email: authorization.user.email,
      }}
      businessCategories={businessCategories}
      readonly={readonly}
      onSubmit={onSubmit}
    />
  );
};

export default pipe(NativeOnboardingScreen, Authorized, withOnboardingContextProvider);

import { Portal } from '@gorhom/portal';
import { Body, Button, Column, H1, LogoIcon, SafeAreaView, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView } from 'react-native';

import config from '../../config';
import { AppContext } from '../../contexts/AppContext';
import log from '../../utils/log';

const UpdateScreen: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('general');

  const openStore = () => {
    if (Platform.OS === 'ios') {
      const iosUrl = `itms-apps://itunes.apple.com/app/${config.stores.ios}`;
      Linking.openURL(iosUrl).catch(log.error);
    } else if (Platform.OS === 'android') {
      const androidUrl = `market://details?id=${config.stores.android}`;
      Linking.openURL(androidUrl).catch(log.error);
    }
  };

  return (
    <Portal>
      <Column style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        <SafeAreaView style={{ flex: 1, paddingHorizontal: 24 }}>
          <ScrollView
            contentContainerStyle={{ alignItems: 'center' }}
            showsVerticalScrollIndicator={false}
            bounces={false}
          >
            <Spacer size={98} />
            <LogoIcon />
            <Spacer size={32} />
            <H1 style={{ textAlign: 'center' }}>{t('upgradeAppTitle')}</H1>
            <Spacer size={8} />
            <Body style={{ textAlign: 'center' }}>{t('upgradeAppDescription')}</Body>
            <Spacer size={24} />
            <Button padding={54} text={t('upgradeAppButton')} onPress={openStore} />
          </ScrollView>
        </SafeAreaView>
      </Column>
    </Portal>
  );
};

export default UpdateScreen;

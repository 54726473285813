import { Button, colors } from '@mero/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { getMinutes } from '../../../utils/time';
import { styles } from './AddServiceMenu.styles';
import CustomPicker from './CustomPicker';

const minutes = getMinutes(5, [1]);

const hours = [...Array(24).keys()].map((i) => {
  const start = i;
  return {
    key: `hour-${i}`,
    value: i,
    label: start < 10 ? `0${start}h` : `${start}h`,
    type: 'string' as const,
  };
});
export type Props = {
  onSave: (data: { hour: number; minute: number }) => void;
  onDismiss: () => void;
  data: {
    hour: number;
    minute: number;
  };
};

const SelectDurationMenu: React.FC<Props> = ({ onDismiss, data, onSave }: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  const hourIndex = hours.findIndex((h) => h.value === data.hour);
  const minuteIndex = minutes.findIndex((m) => m.value === data.minute);

  const [hour, setHour] = React.useState(hours[hourIndex < 0 ? 0 : hourIndex]);
  const [minute, setMinute] = React.useState(minutes[minuteIndex < 0 ? 0 : minuteIndex]);

  const save = () => {
    onSave({
      hour: hour.value,
      minute: minute.value,
    });
  };

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              paddingVertical: 12,
              borderBottomWidth: 1,
              borderBottomColor: colors.GEYSER,
            }}
          >
            <CustomPicker
              initialIndex={hourIndex < 0 ? 0 : hourIndex}
              data={hours}
              onChange={(item) => {
                if (item.value === hour.value) {
                  return;
                }
                setHour(item);
              }}
            />
            <CustomPicker
              initialIndex={minuteIndex < 0 ? 0 : minuteIndex}
              data={minutes}
              onChange={(item) => {
                if (item.value === minute.value) {
                  return;
                }
                setMinute(item);
              }}
            />
          </View>
          <View style={{ alignItems: 'center' }}>
            <Button
              expand={false}
              onClick={save}
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
              text="Confirm"
            />
          </View>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default SelectDurationMenu;

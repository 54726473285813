import { ProductId, ProductSearchHit, ProductStatus } from '@mero/api-sdk';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { InventoryId, ProductAvailability } from '@mero/api-sdk/dist/pro';
import {
  Body,
  colors,
  Column,
  H1,
  H2s,
  MeroHeader,
  SearchTextInput,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import { Positive, ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, Keyboard, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext, ItemProduct } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext, withSearchProductsContextProvider } from '../../../contexts/ProductsSearchContext';
import { AuthorizedStackParamList, RootStackParamList, CheckoutSubStackParamList } from '../../../types';
import log from '../../../utils/log';
import ProductSummaryCard, { convertProductSummaryData } from './ProductSummaryCard';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutSubStackParamList & RootStackParamList & AuthorizedStackParamList, 'SelectProductScreen'>;

export const productToCheckoutItem = (
  product: ProductSearchHit,
  user: CheckoutUserPreview,
  inventory: InventoryId,
): Omit<ItemProduct, 'type'> => {
  const existingStock = ScaledNumber.toNumber(product.stockDescription.quantity);
  const quantity =
    existingStock >= 1 || existingStock === 0
      ? (ScaledNumber.fromNumber(1, 2) as Positive<ScaledNumber>)
      : (product.stockDescription.quantity as Positive<ScaledNumber>);
  return {
    product: {
      _id: product._id,
      name: product.name,
      price: product.price,
      barcode: product.barcode,
      measure: product.measure,
      categoryId: product.categoryId,
    },
    quantity,
    transactionItemId: product._id,
    total: {
      amount: {
        ...product.price.retailPrice,
        amount: ScaledNumber.mul(product.price.discountedPrice.amount, quantity),
      },
      vatStatus: {
        type: 'Included',
        rate: product.price.vatRate,
      },
    },
    inventoryId: inventory,
    saleOwner: user,
    availability: ProductAvailability.OfflineRetail.value,
  };
};

const SelectProductScreen: React.FC<Props> = ({ page, authorization, route }) => {
  const { t } = useTranslation('products');
  const [searchState, { debounceSearch, loadMore }] = SearchProductsContext.useContext();
  const productsListRef = React.useRef<FlatList<ProductSearchHit>>(null);

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });
  const showError = useShowError();
  const isKeyboardOpen = useKeyboardIsOpen();

  const { workerId } = route.params ?? {};

  const [, { setItem }] = CheckoutFormContext.useContext();

  React.useEffect(() => {
    productsListRef.current?.scrollToOffset({ animated: false, offset: 0 });
  }, [searchState.query.search]);

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const selectProductCallback = async (productId: ProductId) => {
    const product = searchState.result.products.find((p) => p._id === productId);
    const inventories = await meroApi.pro.inventories.getAllInventories(page.details._id).catch(showError);
    if (!product || !inventories || inventories.length === 0) {
      log.error('Product or inventories not found', { product, inventories });
      return;
    }

    const worker = workerId ? page.workers.find((w) => w._id === workerId) : undefined;
    const member =
      (worker
        ? page.members.find((m) => m.user._id === worker?.user._id)
        : page.members.find((m) => m.user._id === authorization.user._id)) ?? page.members[0];

    const user = {
      _id: member.user._id,
      phone: member.user.phone ?? '074000000',
      profile: {
        firstname: member.user.profile.firstname,
        lastname: member.user.profile.lastname,
        photo: member.user.profile.photo,
      },
    };

    setItem({
      type: 'Product',
      ...productToCheckoutItem(product, user, inventories[0]._id),
    });
    goBack();
  };

  return (
    <>
      <ModalScreenContainer>
        <MeroHeader
          canGoBack={true}
          onBack={goBack}
          TitleComponent={() => <Title style={{ color: colors.BLACK }}>{t('selectProduct')}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <Spacer size={20} />
        <View style={{ paddingHorizontal: 24, flex: 1 }}>
          <H1>{t('selectProduct')}</H1>
          <Spacer size={16} />
          <SearchTextInput
            autoFocus={true}
            placeholder={t('searchProducts')}
            value={searchState.query.search}
            onChange={(keyword) => {
              debounceSearch({ query: { search: keyword } });
            }}
          />
          <Spacer size={24} />

          {searchState.result.products.length !== 0 && (
            <FlatList
              ref={productsListRef}
              showsVerticalScrollIndicator={false}
              onEndReachedThreshold={0.8}
              data={searchState.result.products}
              onEndReached={loadMore}
              onScrollEndDrag={dismissKeyboardCallback}
              ListFooterComponent={
                searchState.type === 'Loading' ? <ActivityIndicator size="large" /> : <Spacer size={163} />
              }
              renderItem={(i) => (
                <ProductSummaryCard
                  product={convertProductSummaryData(i.item, t)}
                  showLine={i.index !== searchState.result.products.length - 1}
                  openProductDetails={selectProductCallback}
                />
              )}
            />
          )}

          {searchState.result.products.length === 0 && searchState.type === 'Loaded' && (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <H2s>Niciun rezultat</H2s>
              <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                Schimbă termenul de căutare
              </Body>
            </Column>
          )}
        </View>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(
  SelectProductScreen,
  withSearchProductsContextProvider({ stockValue: 'positive', status: ProductStatus.Active.code }),
  CurrentBusiness,
  Authorized,
);

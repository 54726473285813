import { PositiveInt, ProductAvailability } from '@mero/api-sdk';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import {
  Body,
  Button,
  colors,
  Column,
  ConfirmBox,
  DismissKeyboard,
  ModalOverlay,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import HSpacer from '@mero/components/lib/components/HSpacer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { CheckoutFormContext, ItemProduct, WithUiKey } from '../../../contexts/CheckoutFormContext';
import { NumberFromString, roundToDecimals } from '../../../utils/number';
import { getMeasure } from '../../../utils/products';
import {
  localeNumberValidator,
  localeStringToNumber,
  multiplyScaled,
  positiveScaledNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import DiscountComponent, { MinusIcon, PlusIcon } from './DiscountComponent';
import SelectPro from './SelectProComponent';

type Props = {
  product: WithUiKey<ItemProduct>;
  onSave: (product: WithUiKey<ItemProduct>) => void;
  onDelete: (product: WithUiKey<ItemProduct>) => void;
  onCancel: () => void;
};

const EditProduct: React.FC<Props> = ({ product, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation('checkout');
  const [formState] = CheckoutFormContext.useContext();

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [saleOwner, setSaleOwner] = React.useState<CheckoutUserPreview | undefined>(product.saleOwner);
  const [availability, setAvailability] = React.useState<ProductAvailability.Any>(
    product.availability ?? ProductAvailability.OfflineRetail.value,
  );

  const quantityTimer = React.useRef(0);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    if (!priceValid || !saleOwner) {
      return setShowErrors(true);
    }

    onSave({
      ...product,
      availability: availability,
      total: {
        ...product.total,
        amount: {
          ...product.total.amount,
          amount: ScaledNumber.mul(
            ScaledNumber.fromNumber(localeStringToNumber(price.input), 2),
            ScaledNumber.fromNumber(quantity, product.quantity.scale ?? 2),
          ),
        },
        discount: {
          type: 'Value',
          value: {
            amount: ScaledNumber.fromNumber(discount, 2),
            unit: 'RON',
          },
        },
      },
      quantity: positiveScaledNumber(quantity, product.quantity.scale ?? 2),
      saleOwner,
    });
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: save,
  };

  const [price, setPrice] = React.useState({
    input:
      ScaledNumber.toNumber(product.total.amount.amount) === 0
        ? ''
        : scaledToString(ScaledNumber.div(product.total.amount.amount, product.quantity, 2)),
    decoded: NumberFromString.decode(
      ScaledNumber.toNumber(product.total.amount.amount) === 0
        ? ''
        : scaledToString(ScaledNumber.div(product.total.amount.amount, product.quantity, 2)),
    ),
  });
  const priceValid = E.isRight(price.decoded);

  const [quantity, setQuantity] = React.useState(ScaledNumber.toNumber(product.quantity));
  const [tempQuantity, setTempQuantity] = React.useState(roundToDecimals(quantity, 2).toLocaleString() ?? '1');

  const updateQuantity = (value: string = tempQuantity) => {
    window.clearTimeout(quantityTimer.current);

    const parsedValue = roundToDecimals(localeStringToNumber(value));

    if (parsedValue < 0.01 && isNaN(parsedValue)) {
      return;
    }

    setQuantity(parsedValue);
    setTempQuantity(parsedValue.toLocaleString());
  };

  const incrementValue = () => {
    updateQuantity((quantity + 1).toLocaleString());
  };

  const decrementValue = () => {
    if (quantity < 1.01) {
      return;
    }
    updateQuantity((quantity - 1).toLocaleString());
  };

  const { discountValue, discountPercentage } = React.useMemo(() => {
    if (!product.total.discount) {
      return {
        discountValue: 0,
        discountPercentage: 0,
      };
    }
    const total = ScaledNumber.toNumber(product.total.amount.amount);
    if (product.total.discount.type === 'Value') {
      const value = ScaledNumber.toNumber(product.total.discount.value.amount);
      return {
        discountValue: value,
        discountPercentage: roundToDecimals((value * 100) / total),
      };
    }

    if (product.total.discount.type === 'Percent') {
      const percentage = product.total.discount.percent.value;
      return {
        discountValue: roundToDecimals((percentage * total) / 100),
        discountPercentage: percentage,
      };
    }

    return {
      discountValue: 0,
      discountPercentage: 0,
    };
  }, [product.total.discount]);

  const initialPrice = React.useMemo(() => product.product.price.retailPrice, []);

  const measure = React.useMemo(() => getMeasure(product.product.measure), []);

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  const quantityValidator = (prev: number | string) => (next: string) => {
    const parsed = localeStringToNumber(replaceDecimalSeparator(next));
    return (isNaN(parsed) || parsed < 0.01 ? prev : parsed).toLocaleString();
  };

  const availabilityItems = [
    {
      label: t('Consumable'),
      value: 'Consumable',
    },
    {
      label: t('OfflineRetail'),
      value: 'OfflineRetail',
    },
  ];

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={t('changeProduct')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{t('changeProduct')}</H1>
              <Spacer size={16} />
              <Row
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
              >
                <Column style={{ flex: 1 }}>
                  <Title>{product.product.name}</Title>
                  {measure || product.product.barcode ? (
                    <SmallBody>
                      {measure}
                      {measure && product.product.barcode ? ` - ` : ''}
                      {product.product.barcode?.value}
                    </SmallBody>
                  ) : null}
                </Column>
                <Body>
                  {scaledToString(initialPrice.amount)} {t(initialPrice.unit)}
                </Body>
              </Row>
              <Spacer size={16} />
              <SelectPro value={saleOwner?._id} onUpdate={setSaleOwner} />
              <Spacer size={16} />
              <InputWithLabel label={t('selectAvailability')}>
                <Select
                  items={availabilityItems}
                  value={ProductAvailability.toStr(availability)}
                  onChange={(value) => {
                    if (value === 'Consumable') {
                      setAvailability(ProductAvailability.Consumable.value);
                    } else if (value === 'OfflineRetail') {
                      setAvailability(ProductAvailability.OfflineRetail.value);
                    }
                  }}
                />
              </InputWithLabel>
              <Spacer size={16} />

              <Row style={{ alignItems: 'center' }}>
                <Column flex={1}>
                  <InputWithLabel
                    label={t('unitPrice')}
                    isError={showErrors && !priceValid}
                    errorText={t('priceError')}
                  >
                    <TypeSafeTextInput
                      codec={NumberFromString}
                      value={price.input}
                      onChange={flow(numberValidator(price.input), setPrice)}
                      keyboardType="numeric"
                      placeholder={t('pricePlaceholder')}
                      onFocus={() => {
                        setPrice({
                          input: stripLocalThousandsSeparators(price.input),
                          decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                        });
                      }}
                      onBlur={() => {
                        setPrice({
                          input: price.input
                            ? scaledToString(
                                ScaledNumber.fromNumber(
                                  localeStringToNumber(price.input),
                                  product.total.amount.amount.scale || 2,
                                ),
                              )
                            : '',
                          decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                        });
                      }}
                    />
                  </InputWithLabel>
                </Column>
                <HSpacer left={40} />
                <Column style={{ flex: 1 }}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('quantity')}</SmallBody>
                  <Spacer size={8} />
                  <Row
                    style={{
                      padding: 8,
                      borderWidth: 1,
                      borderRadius: 4,
                      borderColor: colors.GEYSER,
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={decrementValue}
                    >
                      <MinusIcon />
                    </TouchableOpacity>
                    <Column style={{ flex: 1, paddingHorizontal: 4 }}>
                      <TextInput
                        style={{ flex: 1, textAlign: 'center', fontSize: 16, lineHeight: 22, fontFamily: 'open-sans' }}
                        value={tempQuantity}
                        onChangeText={setTempQuantity}
                        onBlur={flow(() => tempQuantity, quantityValidator(quantity), updateQuantity)}
                        keyboardType={'numeric'}
                      />
                    </Column>
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={incrementValue}
                    >
                      <PlusIcon />
                    </TouchableOpacity>
                  </Row>
                </Column>
              </Row>
              <Spacer size={16} />
              <DiscountComponent
                price={
                  priceValid
                    ? ScaledNumber.toNumber(
                        multiplyScaled(ScaledNumber.fromNumber(localeStringToNumber(price.input), 2), quantity),
                      )
                    : 0
                }
                value={discountValue}
                percentage={discountPercentage}
                onUpdate={setDiscount}
                type={product.total.discount?.type === 'Percent' ? 'percentage' : 'value'}
              />
              <Spacer size={24} />
              <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                <Button
                  padding={24}
                  expand={false}
                  size="medium"
                  backgroundColor={colors.WHITE}
                  color={colors.RADICAL_RED}
                  text={t('delete')}
                  onPress={() => onDelete(product)}
                />
              </Row>
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditProduct;

import { MembershipTemplate } from '@mero/api-sdk/dist/memberships/membershipTemplate';
import {
  Column,
  H1,
  MeroHeader,
  colors,
  useShowError,
  useToast,
  Spacer,
  SearchTextInput,
  Row,
  Title,
  Icon,
  H2s,
  Body,
  Button,
  Line,
  Label,
  SmallBody,
  styles as meroStyles,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { fold } from 'fp-ts/lib/Either';
import { flow, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import InfiniteScrollView from '../../../../../components/InfiniteScrollView';
import { LoadingComponent } from '../../../../../components/LoadingComponent';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CheckoutsContext } from '../../../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { MembershipTemplateContext } from '../../../../../contexts/MembershipTemplateContext';
import { MembershipSettingsStackParamList, MenuStackParamList } from '../../../../../types';
import { scaledToString } from '../../../../../utils/scaled';
import EnableCheckoutMobile from '../../../CheckoutScreen/EnableCheckoutMobile';
import { styles } from './MembershipSettingsScreen.styles';
import SearchFilter from './SearchFilter';

const ProMembershipMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <Path fill={props.fill} d="M0 0h32v32H0Z" data-name="Path 8275" />
    <G
      fill={props.fill}
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.3}
      transform="translate(2 -3)"
    >
      <Path d="M21.654 10v.393a1.965 1.965 0 1 1-3.931 0V10H9.862v.393a1.965 1.965 0 0 1-3.931 0V10H2v17.3h23.585V10ZM16.151 16.289h6.289m-6.289 3.145h6.289m-6.289 3.144h3.145" />
      <Circle cx={2} cy={2} r={2} data-name="layer1" transform="translate(7 15)" />
      <Path
        d="M9.076 19.434a3.97 3.97 0 0 0-3.93 4.049v.668h7.86v-.668a3.97 3.97 0 0 0-3.93-4.049Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

const Query = t.partial({
  name: DefinedString,
  status: t.union([t.literal('Active'), t.literal('Inactive')]),
});

export type Filters = Parameters<typeof meroApi.memberships.getMembershipTemplatesForPage>[0]['status'];

type Props = StackScreenProps<MenuStackParamList & MembershipSettingsStackParamList, 'MembershipSettings'> &
  CurrentBusinessProps;

const MembershipSettingsScreen: React.FC<Props> = ({ page, navigation }: Props) => {
  const { t } = useTranslation('membership');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();
  const showError = useShowError();
  const [checkoutState, { reloadSettings, reloadCompanies }] = CheckoutsContext.useContext();

  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setFilter] = React.useState<Filters>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [templateMemberships, setTemplateMemberships] = React.useState<MembershipTemplate<MeroUnits.Any>[]>([]);
  const [, { reset }] = MembershipTemplateContext.useContext();
  const [nextPage, setNextPage] = React.useState<string | undefined>('');
  const loadingNextPage = React.useRef('');

  const getMemberships = async (query?: { name?: DefinedString; status?: 'Active' | 'Inactive' }, next?: string) => {
    try {
      loadingNextPage.current = next ?? '';
      const { data: memberships, next: nextPage } = await meroApi.memberships.getMembershipTemplatesForPage({
        limit: 5,
        pageId: page.details._id,
        ...query,
        page: next,
      });

      const newMemberships = next ? [...templateMemberships, ...memberships] : memberships;
      if (!query) setNoData(newMemberships.length === 0);
      setTemplateMemberships(newMemberships);
      setNextPage(nextPage);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const onSearch = flow(
    Query.decode,
    fold(() => getMemberships(), getMemberships),
  );

  const loadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      pipe(
        { name: searchQuery || undefined, status: filter },
        Query.decode,
        fold(
          () => getMemberships(undefined, nextPage),
          (query) => getMemberships(query, nextPage),
        ),
      );
    }
  };

  const changeCheckoutSettings = async () => {
    try {
      await meroApi.checkout.savePageSettings({
        pageId: page.details._id,
        settings: {
          checkoutEnabled: true,
        },
      });

      reloadSettings(page.details._id);
      reloadCompanies(page.details._id);
    } catch (error) {
      showError(error);
    } finally {
    }
  };

  const onEnableCheckout = React.useCallback(() => {
    if (checkoutState.type === 'Loaded') {
      if (checkoutState.companies.length > 0) {
        changeCheckoutSettings();
      } else {
        navigation.navigate('PageCheckoutSettingsScreen', { screen: 'BillingDetailsList' });
      }
    }
  }, [checkoutState]);

  React.useEffect(() => {
    reset();
    getMemberships();
  }, [isFocused]);

  React.useEffect(() => {
    const timer = window.setTimeout(() => {
      onSearch({ name: searchQuery || undefined, status: filter });
    }, 500);

    return () => window.clearTimeout(timer);
  }, [searchQuery, filter]);

  const shouldEnableCheckout = checkoutState.type === 'Loaded' && !checkoutState.pageSettings.checkoutEnabled;

  if (shouldEnableCheckout) {
    return (
      <View style={{ flex: 1, backgroundColor: colors.WHITE }}>
        <EnableCheckoutMobile onEnableCheckout={onEnableCheckout} onGoBack={goBack} />
      </View>
    );
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      {/*{!isLoaded && <LoadingComponent />}*/}
      <MeroHeader
        canGoBack
        onBack={goBack}
        title={shouldEnableCheckout ? t('salePoint') : t('types')}
        // RightComponent={
        //   <TouchableOpacity
        //     style={{ justifyContent: 'center', height: 50, marginRight: 16 }}
        //     onPress={() => console.log('order')}
        //   >
        //     <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('order')}</SmallBody>
        //   </TouchableOpacity>
        // }
      />
      {checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled && (
        <Column style={{ paddingTop: 16, flex: 1, height: '100%' }}>
          <InfiniteScrollView
            onEndReached={loadMore}
            withLoading
            style={templateMemberships.length === 0 && isLoaded ? { flex: 1 } : undefined}
            contentContainerStyle={templateMemberships.length === 0 && isLoaded ? { flex: 1 } : undefined}
            wrapperStyle={templateMemberships.length === 0 && isLoaded ? { flex: 1 } : undefined}
          >
            <H1 style={{ paddingHorizontal: 24 }}>{t('types')}</H1>
            {!noData && isLoaded && (
              <Column style={{ paddingHorizontal: 24, zIndex: 1, flex: 1 }}>
                <Spacer size={16} />
                <SearchTextInput placeholder={t('searchPlaceholder')} value={searchQuery} onChange={setSearchQuery} />
                <Spacer size={16} />
                <Row alignItems="center">
                  <SearchFilter activeFilter={filter} onChange={setFilter} />
                  <Column style={{ flex: 1 }} justifyContent="flex-end" />
                  <TouchableOpacity style={styles.addButton} onPress={() => navigation.navigate('MembershipDetails')}>
                    <Title style={{ color: colors.DARK_BLUE, paddingHorizontal: 24 }}>{t('add')}</Title>
                  </TouchableOpacity>
                </Row>
              </Column>
            )}
            {templateMemberships.length > 0 && isLoaded && (
              <>
                <Spacer size={16} />
                <Column style={{ zIndex: -1 }}>
                  {templateMemberships.map((membership) => {
                    const { totalPrice, withDiscount } = (() => {
                      const totalPrice = membership.items.reduce(
                        (acc, item) =>
                          acc + (item.type === 'Service' ? ScaledNumber.toNumber(item.sellingPrice.amount) : 0),
                        0,
                      );
                      const withDiscount =
                        totalPrice -
                        (membership.discount
                          ? membership.discount.type === 'Percent'
                            ? (ScaledNumber.toNumber(membership.discount.percent) * totalPrice) / 100
                            : ScaledNumber.toNumber(membership.discount.value.amount)
                          : 0);

                      return { totalPrice, withDiscount };
                    })();
                    return (
                      <FormCard
                        key={membership._id}
                        rounded
                        dropShaddow
                        paddings="none"
                        style={{ paddingHorizontal: 16, paddingVertical: 16, marginHorizontal: 16, marginBottom: 16 }}
                      >
                        <TouchableOpacity
                          onPress={() => navigation.navigate('MembershipDetails', { membershipId: membership._id })}
                        >
                          <Row alignItems="center">
                            <Column style={{ flex: 1 }}>
                              <H2s>{membership.name}</H2s>
                              <Spacer size={4} />
                              <Label
                                style={[
                                  {
                                    color:
                                      membership.status === 'Active'
                                        ? colors.SHAMROCK
                                        : membership.status === 'Deleted'
                                        ? colors.RADICAL_RED
                                        : colors.COMET,
                                  },
                                  meroStyles.text.semibold,
                                ]}
                              >
                                {t(membership.status)}
                              </Label>
                            </Column>
                            <Column>
                              <Icon type="next" color={colors.DARK_BLUE} />
                            </Column>
                          </Row>
                          <Spacer size={16} />
                          <Line />
                          <Spacer size={16} />
                          <Row>
                            <Column style={{ flex: 1 }}>
                              <Title>{t('paymentValue')}</Title>
                              <SmallBody>
                                {membership.validFor.type === 'Unlimited'
                                  ? t('unlimitedValidity')
                                  : t('validityValue', { count: Math.ceil(membership.validFor.days / 30) })}
                              </SmallBody>
                            </Column>
                            <Column>
                              <Title>
                                {scaledToString(ScaledNumber.fromNumber(withDiscount, 2))} {t('RON')}
                              </Title>
                              {withDiscount !== totalPrice ? (
                                <SmallBody
                                  style={{
                                    textDecorationLine: 'line-through',
                                    color: colors.COMET,
                                    textAlign: 'right',
                                  }}
                                >
                                  {scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')}
                                </SmallBody>
                              ) : null}
                            </Column>
                          </Row>
                        </TouchableOpacity>
                      </FormCard>
                    );
                  })}
                </Column>
              </>
            )}

            {templateMemberships.length === 0 && isLoaded && (
              <>
                <Column style={{ paddingHorizontal: 24, flex: 1 }} justifyContent="center" alignItems="center">
                  <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 24 }}>
                    <H2s style={{ textAlign: 'center' }}>
                      {noData ? t('noMembershipsAvailable') : t('noMembershipSearchType')}
                    </H2s>
                    <Spacer size={8} />
                    <Body style={{ textAlign: 'center' }}>
                      {noData ? t('noMembershipDescription') : t('noMembershipSearchDescription')}
                    </Body>
                    {noData && (
                      <>
                        <Spacer size={32} />
                        <Button text={t('addNewTemplate')} onClick={() => navigation.navigate('MembershipDetails')} />
                      </>
                    )}
                  </Column>
                </Column>
              </>
            )}
          </InfiniteScrollView>
        </Column>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(MembershipSettingsScreen, CurrentBusiness);

import { Body, Icon, Row, SmallBody } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { styles } from './styles';

export type Props = {
  /**
   * Item press handler
   */
  readonly onPress?: () => void;

  readonly hasError?: {
    readonly message: string;
  };
};

const SelectedServiceItem: React.FC<Props> = ({ onPress, hasError }: Props) => {
  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        disabled={onPress === undefined}
        style={[styles.container, hasError && styles.containerError]}
      >
        <Row style={styles.serviceRow}>
          <Body style={styles.title}>Alege serviciu</Body>
          <Icon type="arrow-right" />
        </Row>
      </TouchableOpacity>
      {hasError && <SmallBody style={styles.errorText}>{hasError.message}</SmallBody>}
    </>
  );
};

export default SelectedServiceItem;

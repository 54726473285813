import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  Label,
  Line,
  MeroHeader,
  Row,
  SafeAreaView,
  Select,
  SmallBody,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { PageNotificationsContext } from '../../../../../contexts/PageNotificationsContext';
import { NotificationsSettingsStackParamList } from '../../../../../types';
import { styles } from '../PageOnlinePaymentsSettingsScreen/styles';

type Props = StackScreenProps<NotificationsSettingsStackParamList, 'NotificationsSettingsManualMethod'>;

enum SelectMethod {
  GLOBAL = 'global',
  INDIVIDUAL = 'individual',
}

const SelectManualDeliveryMethodScreen: React.FC<Props> = () => {
  const [pageState] = CurrentBusinessContext.useContext();

  const [notificationsState, { updateState }] = PageNotificationsContext.useContext();

  const { t } = useTranslation('notifications');

  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const block = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedMethod, setSelectedMethod] = React.useState<'global' | 'individual'>(notificationsState.type);
  const [selectedUser, setSelectedUser] = React.useState<UserId | undefined>(
    notificationsState.type === 'global' ? notificationsState.userId : undefined,
  );
  const [membersList, setMembersList] = React.useState<{ label: string; value: UserId }[]>([]);

  const changeSelectedMethod = React.useCallback(
    (value: SelectMethod) => () => {
      setSelectedMethod(value);
    },
    [],
  );

  const saveChanges = () => {
    if (selectedMethod === SelectMethod.INDIVIDUAL) {
      updateState({ type: 'individual' });
      goBack();
      return;
    }

    if (selectedUser) {
      updateState({ type: 'global', userId: selectedUser });
      goBack();
      return;
    }
  };

  const getUsers = React.useCallback(async (pageId: PageId) => {
    const members = await meroApi.pages.getUsersForGlobalManualSms({ pageId });

    setMembersList(
      members.map((member) => ({
        label: `${member.user.profile.firstname} ${member.user.profile.lastname} (${member.roles[0].name})`,
        value: member.user._id,
      })),
    );
  }, []);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getUsers(pageState.page.details._id);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (membersList.length) {
      setSelectedUser(selectedUser ? selectedUser : membersList[0].value);
    }
  }, [membersList]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t('deliveryMethod')} />
        <ScrollView style={{ flex: 1, paddingHorizontal: 16, marginBottom: 96 }}>
          <H1 style={{ paddingTop: 16, paddingHorizontal: 8 }}>{t('deliveryMethod')}</H1>
          <Spacer size={8} />
          <Body style={{ paddingHorizontal: 8 }}>{t('deliveryMethodDescription')}</Body>
          <Spacer size={24} />
          <FormCard dropShaddow paddings="none" rounded style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
            <Row>
              <Column>
                <Checkbox
                  value={selectedMethod === SelectMethod.GLOBAL}
                  color="blue"
                  disabled={false}
                  onValueChange={changeSelectedMethod(SelectMethod.GLOBAL)}
                />
              </Column>
              <TouchableOpacity
                style={{ paddingLeft: 16, flex: 1 }}
                onPress={changeSelectedMethod(SelectMethod.GLOBAL)}
              >
                <H3s>{t('semiAutomaticGlobal')}</H3s>
                <Spacer size={4} />
                <SmallBody>{t('semiAutomaticGlobalDescription')}</SmallBody>
                <Spacer size={8} />
                <Title>{t('semiAutomaticGlobalSelector')}</Title>
                <Spacer size={8} />
                <Select items={membersList} value={selectedUser} onChange={setSelectedUser} />
              </TouchableOpacity>
            </Row>

            <Spacer size={16} />
            <Line />
            <Spacer size={16} />
            <Row>
              <Column>
                <Checkbox
                  value={selectedMethod === SelectMethod.INDIVIDUAL}
                  color="blue"
                  disabled={false}
                  onValueChange={changeSelectedMethod(SelectMethod.INDIVIDUAL)}
                />
              </Column>
              <TouchableOpacity
                style={{ paddingLeft: 16, flex: 1 }}
                onPress={changeSelectedMethod(SelectMethod.INDIVIDUAL)}
              >
                <H3s>{t('semiAutomaticIndividual')}</H3s>
                <Spacer size={4} />
                <SmallBody>{t('semiAutomaticIndividualDescription')}</SmallBody>
                <Spacer size={8} />
                <Label style={{ color: colors.RADICAL_RED, fontFamily: 'open-sans-semibold' }}>
                  {t('semiAutomaticIndividualWarning')}
                </Label>
              </TouchableOpacity>
            </Row>
          </FormCard>
        </ScrollView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button disabled={block.current || isLoading} text={t('okSimple')} onClick={saveChanges} />
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
    </>
  );
};

export default SelectManualDeliveryMethodScreen;

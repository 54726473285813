import { AnyPurchasedMembershipHistoryRecord } from '@mero/api-sdk/dist/memberships/purchasedMembershipHistoryRecord';
import { colors, Line, Spacer } from '@mero/components';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from './ClientEmptyListView';
import MembershipHistoryListItemView from './MembershipHistoryListItemView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly data: AnyPurchasedMembershipHistoryRecord[];
};

const MembershipHistoryListView: React.FC<Props> = ({ HeaderElement, data }) => {
  const renderItem = React.useCallback(({ item }: { item: AnyPurchasedMembershipHistoryRecord }) => {
    return <MembershipHistoryListItemView item={item} />;
  }, []);

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={data}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      windowSize={11}
      ListEmptyComponent={<ClientEmptyListView title="Nu istoric" subtitle="Istoricul clientului va fi afișat aici" />}
      ItemSeparatorComponent={() => (
        <>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
        </>
      )}
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

const keyExtractor = (item: AnyPurchasedMembershipHistoryRecord): string => item._id;

export default MembershipHistoryListView;

import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  itemTitle: { fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 10 },
  content: {
    marginBottom: sizes[16],
    paddingHorizontal: sizes[24],
    borderBottomWidth: 1,
    borderBottomColor: colors.ATHENS_GRAY,
    paddingBottom: sizes[16],
  },
});

export default styles;

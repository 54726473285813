import * as React from 'react';
import Svg, { G, Rect, Ellipse } from 'react-native-svg';

import { colors } from '../../../../styles';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const OptionsDots = ({ size = 24, color = colors.DARK_BLUE, backgroundColor = 'none', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-2177 -5826)">
      <Rect width="24" height="24" transform="translate(2177 5826)" fill={backgroundColor} />
      <G transform="translate(1864 3668)">
        <Ellipse cx="2" cy="1.919" rx="2" ry="1.919" transform="translate(317 2168)" fill={color} opacity="0.9" />
        <Ellipse cx="2" cy="1.919" rx="2" ry="1.919" transform="translate(330 2168)" fill={color} opacity="0.9" />
        <Ellipse cx="2" cy="1.919" rx="2" ry="1.919" transform="translate(323.5 2168)" fill={color} opacity="0.9" />
      </G>
    </G>
  </Svg>
);

export default React.memo(OptionsDots);

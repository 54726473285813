import { colors } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import {
  FlatList,
  FlatListProps,
  RefreshControlProps,
  RefreshControl as RefreshControlRN,
  ActivityIndicator,
} from 'react-native';

const RefreshControl: React.FC<RefreshControlProps> = (props) => {
  return <RefreshControlRN colors={[colors.DARK_BLUE]} tintColor={colors.DARK_BLUE} {...props} />;
};
declare module 'react' {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type Info = { distanceFromEnd: number; nextPage: number };

export type InfiniteListRef = { resetNextPage: () => void; flatList: FlatList } | null;

export type Props<T> = Omit<FlatListProps<T>, 'onEndReached'> & {
  totalPages: number;
  onEndReached: (info: Info) => void;
};

const InfiniteList = <T,>(props: Props<T>, ref: React.Ref<InfiniteListRef>): React.ReactElement => {
  const flatListRef = React.useRef<FlatList>(null);

  const [nextPage, setNextPage] = React.useState(1);
  const { onEndReached, data, totalPages, onRefresh, refreshing, ...rest } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const onLoadMore = async (info: { distanceFromEnd: number }) => {
    if (nextPage < totalPages && !isLoading) {
      setIsLoading(true);
      await onEndReached({ ...info, nextPage });
      setNextPage(nextPage + 1);
      setIsLoading(false);
    }
  };

  const resetNextPage = () => {
    setNextPage(1);
  };

  React.useImperativeHandle<InfiniteListRef, InfiniteListRef>(
    ref,
    () =>
      flatListRef.current !== null
        ? {
            resetNextPage,
            flatList: flatListRef.current,
          }
        : null,
    [],
  );

  return (
    <FlatList
      ref={flatListRef}
      data={data}
      ListFooterComponent={isLoading ? <ActivityIndicator size="large" /> : null}
      onEndReached={onLoadMore}
      onEndReachedThreshold={0.8}
      refreshControl={
        onRefresh && typeof refreshing !== 'undefined' && refreshing !== null ? (
          <RefreshControl refreshing={refreshing} onRefresh={flow(() => setNextPage(1), onRefresh)} />
        ) : undefined
      }
      {...rest}
    />
  );
};

export default React.forwardRef(InfiniteList);

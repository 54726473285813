import { H1, Spacer, styles as meroStyles, Body } from '@mero/components';
import * as React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import SignInHeader from '../../../components/SignInHeader';
import AppleSignInButton from '../components/AppleSignInButton';
import FacebookSignInButton from '../components/FacebookSignInButton';
import GoogleSignInButton from '../components/GoogleSignInButton';
import PhoneSignInButton from '../components/PhoneSignInButton';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';

import { AppContext } from '../../../contexts/AppContext';
import { AnonymousStackParamList, RootStackParamList } from '../../../types';
import { PlatformVersionParsed } from '../../../utils/platform';
import SplashScreen from '../../SplashScreen';
import { SigningContext } from '../SigningContext';
import { styles } from './styles';

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AnonymousStackParamList, 'SignInScreen'>,
    StackNavigationProp<RootStackParamList, 'Anonymous'>
  >;
};

const SignInScreen: React.FC<Props> = ({ navigation }) => {
  const [signingState] = SigningContext.useContext();
  const signInProgress = signingState.type !== 'Ready';
  const [appState] = AppContext.useContext();

  const { logAction, logEvent } = useAnalytics({
    eventName: 'pro_onboarding_login_screen_shown',
    screenName: 'pro_onboarding_login',
  });

  const phoneSignInCallback = React.useCallback(() => {
    logEvent('pro_authentication_started', { selected_method: 'phone' });
    navigation.navigate('SignInPhoneScreen');
  }, [navigation]);

  const signUpCallback = React.useCallback(() => {
    navigation.replace('SignUpScreen');
  }, [navigation]);

  React.useEffect(() => {
    if (signingState.type === 'NotRegistered') {
      navigation.navigate('SignUpPhoneScreen');
    }
  }, [signingState]);

  if (signInProgress) {
    return <SplashScreen />;
  }

  return (
    <ModalScreenContainer>
      <SignInHeader />
      <View style={styles.contentContainer}>
        <H1 style={meroStyles.text.alignCenter}>Autentificare profesioniști</H1>
        <Spacer size={8} />
        <Body style={meroStyles.text.alignCenter}>Gestionează-ți afacerea, clienții și</Body>
        <Body style={meroStyles.text.alignCenter}>programările din contul tău</Body>
        <Spacer size={32} />
        <View
          style={{
            width: '100%',
            maxWidth: 480,
          }}
        >
          {appState.type === 'Loaded' && appState.featureFlags.authenticationWithFacebook?.enabled ? (
            <>
              <FacebookSignInButton />
              <Spacer size={16} />
            </>
          ) : null}
          <GoogleSignInButton />
          {Platform.select({
            ios:
              PlatformVersionParsed && PlatformVersionParsed.major >= 13 ? (
                <>
                  <Spacer size={16} />
                  <AppleSignInButton />
                </>
              ) : null,
          })}
          <Spacer size={16} />
          <PhoneSignInButton onPress={phoneSignInCallback} disabled={signInProgress} />
        </View>
        <Spacer size={48} />
        <View style={{ flexDirection: 'row' }}>
          <Body>Nu ai cont de profesionist? </Body>
          <TouchableOpacity onPress={signUpCallback}>
            <Body style={meroStyles.text.link}>Crează cont</Body>
          </TouchableOpacity>
        </View>
      </View>
    </ModalScreenContainer>
  );
};

export default SignInScreen;

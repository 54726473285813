import * as React from 'react';
import Svg, { Path, Rect, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Calendar: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(0 1)">
      <Rect width="24" height="24" transform="translate(0 -1)" fill="none" />
      <G transform="translate(0)">
        <Path
          d="M3,23a2,2,0,0,1-2-2V4.25a2,2,0,0,1,2-2H5.25V.75a.75.75,0,0,1,1.5,0v1.5h4.5V.75a.75.75,0,0,1,1.5,0v1.5h4.5V.75a.75.75,0,1,1,1.5,0v1.5H21a2,2,0,0,1,2,2V21a2,2,0,0,1-2,2ZM2.5,4.25V21a.5.5,0,0,0,.5.5H21a.5.5,0,0,0,.5-.5V4.25a.5.5,0,0,0-.5-.5H18.75v1.5a.75.75,0,1,1-1.5,0V3.75h-4.5v1.5a.75.75,0,1,1-1.5,0V3.75H6.75v1.5a.75.75,0,1,1-1.5,0V3.75H3A.5.5,0,0,0,2.5,4.25Zm8,14.5V16.5h3v2.25Zm-5.251,0V16.5h3v2.25ZM15.75,15V12.75h3V15ZM10.5,15V12.75h3V15ZM5.25,15V12.75h3V15Zm10.5-3.75V9h3v2.25Zm-5.25,0V9h3v2.25Zm-5.251,0V9h3v2.25Z"
          transform="translate(0)"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Calendar);

import {
  colors,
  Column,
  Icon,
  Row,
  SmallBody,
  Title,
  styles as meroStyles,
  MeroHeader,
  FormCard,
  Checkbox,
  Body,
  Spacer,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../../contexts/CurrentBusiness';
import { SelectedImageContext } from '../../../../../../contexts/SelectedImageContext';
import { PageGallerySettingsStackParamList } from '../../../../../../types';
import log from '../../../../../../utils/log';
import { styles } from './SelectedImageMenu.styles';

export type Props = StackScreenProps<PageGallerySettingsStackParamList, 'SelectedImageMenu'>;

const SelectedImageMenu: React.FC<Props> = ({ route, navigation }): React.ReactElement | null => {
  const { t } = useTranslation('gallery');
  const { width } = Dimensions.get('window');
  const goBack = useGoBack();
  const imageWidth = (width > 768 ? 500 : width) - 48;
  const imageHeight = 436;
  const showError = useShowError();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [selectedImage, { updateImage, setMainImage }] = SelectedImageContext.useContext();

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && !selectedImage) {
      const image = pageState.page.details.images.find((image) => image._id === route.params.imageId);
      if (!image) {
        return goBack();
      }

      updateImage({
        image,
        position: pageState.page.details.images.indexOf(image),
      });
    }
  }, [pageState]);

  const goToImageAttachedServices = (imageId: string) => {
    navigation.navigate('AttachedServices', { imageId });
  };

  const onDeleteImage = async () => {
    if (pageState.type !== 'Loaded' || !selectedImage) {
      return;
    }

    try {
      await meroApi.pages.deleteImage({ pageId: pageState.page.details._id, imageId: selectedImage.image._id });
      reloadAsync();
      goBack();
    } catch (error) {
      showError(error, t('failedToDeleteImage'));
      log.error('Error while deleting image', error);
    }
  };

  const onSetAsMainImage = async () => {
    if (pageState.type !== 'Loaded' || !selectedImage) {
      return;
    }

    try {
      const otherImages = pageState.page.details.images
        .filter((i) => i._id !== selectedImage.image._id)
        .map((i) => i._id);
      await meroApi.pages.reorderImages({
        pageId: pageState.page.details._id,
        imageIds: [selectedImage.image._id, ...otherImages],
      });
      setMainImage();
      reloadAsync();
    } catch (error) {
      showError(error, t('failedToSetAsMain'));
      log.error('Error while setting as main image', error);
    }
  };

  const changeImage = (newPosition: number) => {
    if (pageState.type !== 'Loaded' || !selectedImage) {
      return;
    }

    if (newPosition >= 0 && newPosition < pageState.page.details.images.length) {
      const image = pageState.page.details.images[newPosition];
      updateImage({
        image,
        position: newPosition,
      });
      navigation.navigate('SelectedImageMenu', { imageId: image._id });
    }
  };

  if (!selectedImage || pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ flex: 1 }}>
      <MeroHeader
        canGoBack
        onBack={goBack}
        title={`${selectedImage.position + 1} din ${pageState.page.details.images.length}`}
      />

      <ScrollView>
        <SafeAreaView edges={['bottom']}>
          <Row style={{ paddingHorizontal: 24, paddingTop: 8 }}>
            <Column style={{ flex: 1, position: 'relative' }}>
              <Image
                source={{ uri: selectedImage.image.medium }}
                style={{ width: imageWidth, height: imageHeight, borderRadius: 4 }}
              />
              {selectedImage.position === 0 && (
                <Column style={{ position: 'absolute', bottom: 16, right: 16 }}>
                  <Column
                    style={{
                      borderRadius: 4,
                      backgroundColor: colors.ALABASTER,
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                    }}
                  >
                    <Title style={{ fontSize: 12 }}>{t('mainImage').toUpperCase()}</Title>
                  </Column>
                </Column>
              )}

              {selectedImage.position < pageState.page.details.images.length - 1 && (
                <TouchableOpacity onPress={() => changeImage(selectedImage.position + 1)}>
                  <Column
                    style={{
                      position: 'absolute',
                      bottom: imageHeight / 2,
                      right: 16,
                      borderRadius: 50,
                      backgroundColor: colors.WHITE,
                      padding: 4,
                    }}
                  >
                    <Icon type="arrow-right" />
                  </Column>
                </TouchableOpacity>
              )}

              {selectedImage.position !== 0 && (
                <TouchableOpacity onPress={() => changeImage(selectedImage.position - 1)}>
                  <Column
                    style={{
                      position: 'absolute',
                      bottom: imageHeight / 2,
                      left: 16,
                      borderRadius: 50,
                      backgroundColor: colors.WHITE,
                      padding: 4,
                    }}
                  >
                    <Icon type="arrow-left" />
                  </Column>
                </TouchableOpacity>
              )}
            </Column>
          </Row>

          <TouchableOpacity
            style={[styles.optionsMenuItem]}
            onPress={() => goToImageAttachedServices(selectedImage.image._id)}
          >
            <Column
              style={{
                paddingLeft: 19,
                paddingRight: 13,
                paddingVertical: 16,
                borderWidth: 1,
                borderColor: colors.GEYSER,
                borderRadius: 6,
              }}
            >
              <Row style={{ justifyContent: 'space-between', padding: 0 }}>
                <Title>{t('imageAttachedServices')}</Title>
                <Icon type="arrow-right" />
              </Row>
              {!selectedImage.image.services?.length ? (
                <SmallBody style={[meroStyles.text.hint]}>{t('chooseServices')}</SmallBody>
              ) : selectedImage.image.services.length === 1 ? (
                <SmallBody style={[meroStyles.text.hint]}>{t('selectedServices_one')}</SmallBody>
              ) : (
                <SmallBody style={[meroStyles.text.hint]}>
                  {t('selectedServices_many', {
                    count: selectedImage.image.services?.length,
                  })}
                </SmallBody>
              )}
            </Column>
          </TouchableOpacity>

          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSetAsMainImage}>
            <Row alignItems="center">
              <Checkbox
                color="blue"
                value={selectedImage.position === 0}
                onValueChange={onSetAsMainImage}
                disabled={selectedImage.position === 0}
              />
              <Body style={{ fontSize: 17, color: '#172B4D', marginLeft: 12 }}>{t('setAsMain')}</Body>
            </Row>
          </TouchableOpacity>

          <Spacer size={30} />

          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDeleteImage}>
            <Row justifyContent="center">
              <Title style={{ color: colors.RADICAL_RED }}>{t('deleteImage')}</Title>
            </Row>
          </TouchableOpacity>
          <Spacer size={48} />
        </SafeAreaView>
      </ScrollView>
    </ModalScreenContainer>
  );
};

export default SelectedImageMenu;

import { Button } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  addImages: () => void;
};

const UploadImageButton: React.FC<Props> = ({ addImages }) => {
  const { t } = useTranslation('gallery');

  return (
    <>
      <Button expand={false} containerStyle={{ alignSelf: 'center' }} text={t('addImages')} onClick={addImages} />
    </>
  );
};

export default UploadImageButton;

import { Body, Button, colors, H1, H2s, H3s, Icon, Label, Row, SearchTextInput, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import HSpacer from '@mero/components/lib/components/HSpacer';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { CheckoutDraftsCountContext } from '../../../contexts/CheckoutDraftsCountContext';
import { CheckoutsContext, FinishedTransaction, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import DraftPreview from './DraftPreview';
import FinishedPreview from './FinishedPreview';
import { ProceedIcon } from './index';

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

const CheckoutTabMobile: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [checkoutDraftsCountState, { reload: reloadCount }] = CheckoutDraftsCountContext.useContext();

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      updateSearch(page.details._id, searchQuery);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchQuery, interval]);

  const navigateCashRegistryCallback = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'CashRegistryScreen',
      },
    });
  }, [navigation]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      <InfiniteScrollView onEndReached={() => loadMoreFinished(page.details._id)}>
        <Spacer size={24} />
        <Row style={{ paddingLeft: 24, paddingRight: 16 }}>
          <H1 style={{ flex: 1 }}>{t('checkout')}</H1>
          {page.details.isCashRegistryEnabled && (
            <TouchableOpacity onPress={navigateCashRegistryCallback}>
              <Title style={{ color: colors.DARK_BLUE }} numberOfLines={1}>
                {t('cashRegistry:title')}
              </Title>
            </TouchableOpacity>
          )}
        </Row>
        <Spacer size={16} />
        <Column style={{ paddingHorizontal: 16 }}>
          <SearchTextInput value={searchQuery} onChange={setSearchQuery} placeholder={t('searchCheckoutPlaceholder')} />
        </Column>
        <Spacer size={24} />
        {checkoutsState.drafts.data.length > 0 && (
          <>
            <Column style={{ backgroundColor: colors.SKY_BLUE }}>
              <Spacer size={16} />
              {checkoutsState.drafts.data.slice(0, 2).map((draft, index) => (
                <React.Fragment key={draft._id}>
                  <DraftPreview
                    draft={draft}
                    onPress={() =>
                      navigation.navigate('CombineCheckout', {
                        screen: 'CheckoutStack',
                        params: {
                          screen: 'AddProceedScreen',
                          params: { type: 'Booking', transactionId: draft._id },
                        },
                      })
                    }
                  />

                  <Spacer size={16} />
                  {index < checkoutsState.drafts.data.length - 1 && (
                    <>
                      <Line />
                      <Spacer size={16} />
                    </>
                  )}
                </React.Fragment>
              ))}
              {checkoutDraftsCountState.type === 'Loaded' && checkoutDraftsCountState.draftsCount > 2 ? (
                <Column style={{ paddingHorizontal: 16 }}>
                  <TouchableOpacity
                    style={{ flexDirection: 'row', flex: 1, paddingBottom: 16, alignItems: 'center' }}
                    onPress={() =>
                      navigation.navigate('CombineCheckout', {
                        screen: 'CheckoutStack',
                        params: { screen: 'AllDraftsScreen' },
                      })
                    }
                  >
                    <HSpacer left={48} />
                    <Title style={{ color: colors.DARK_BLUE, flex: 1, fontSize: 14 }}>{t('showAllDrafts')}</Title>
                    <Column
                      style={{
                        width: 18,
                        height: 18,
                        backgroundColor: colors.RADICAL_RED,
                        borderRadius: 9,
                        overflow: 'hidden',
                        marginRight: 8,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Label style={{ color: colors.WHITE }}>{checkoutDraftsCountState.draftsCount - 2}</Label>
                    </Column>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </TouchableOpacity>
                </Column>
              ) : null}
            </Column>
            <Spacer size={32} />
          </>
        )}
        {checkoutsState.finished.data.length > 0 && (
          <>
            <H3s style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, paddingHorizontal: 24 }}>
              {t('finished', { count: checkoutsState.finished.data.length })}
            </H3s>
            <Spacer size={16} />
          </>
        )}
        {checkoutsState.finished.data.map((proceed, index) => (
          <React.Fragment key={proceed._id}>
            <FinishedPreview
              proceed={proceed}
              onPress={() =>
                navigation.navigate('CombineCheckout', {
                  screen: 'CheckoutStack',
                  params: {
                    screen: 'ProceedDetailsScreen',
                    params: { checkoutTransactionId: proceed._id, backMode: 'one' },
                  },
                })
              }
            />
            <Spacer size={16} />
            {index < checkoutsState.finished.data.length - 1 && (
              <>
                <Line />
                <Spacer size={16} />
              </>
            )}
          </React.Fragment>
        ))}
        {checkoutsState.drafts.data.length === 0 && checkoutsState.finished.data.length === 0 && (
          <>
            <Spacer size={24} />
            <Column
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 24,
                paddingBottom: 32,
              }}
            >
              <H2s>{t('noProceedsAvailable')}</H2s>
              <Spacer size={8} />
              <Body style={{ textAlign: 'center' }}>{t('noProceedsAvailableDescription')}</Body>
            </Column>
          </>
        )}
      </InfiniteScrollView>
      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(CheckoutTabMobile, CurrentBusiness);

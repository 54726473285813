import { Body, ConfirmBox, DismissKeyboard, ModalOverlay, Row, Spacer, useShowError } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardAvoidingView from '../../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../../components/MobileWebModalWrapper';
import TextInput from '../../../../components/TextInput';
import H1 from '@mero/components/lib/components/Text/H1';

type Props = {
  note: string;
  onSave: (note: string) => Promise<void>;
  onCancel: () => void;
};

const EditAmount: React.FC<Props> = ({ note, onSave, onCancel }) => {
  const { t } = useTranslation('clients');
  const showError = useShowError();

  const [actionInProgress, setActionInProgress] = React.useState(false);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = async () => {
    setActionInProgress(true);
    try {
      await onSave(tempNote);
    } catch (error) {
      showError(error);
    } finally {
      setActionInProgress(false);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: actionInProgress ? undefined : save,
  };

  const [tempNote, setTempNote] = React.useState(note);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={note ? t('changeNotes') : t('addNotes')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{note ? t('changeNotes') : t('addNotes')}</H1>
              <Spacer size={8} />
              <Body>{t('addNotesDescription')}</Body>
              <Spacer size={16} />
              <TextInput multiline numberOfLines={3} value={tempNote} onChange={setTempNote} />
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditAmount;

import { AvoidKeyboard, MeroHeader } from '@mero/components';
import * as React from 'react';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../components/ModalScreenContainer';

type Props = {
  readonly isEmbed?: boolean;
  readonly onClose?: () => void;
};

const TermsOfServiceScreenView: React.FC<Props> = ({ isEmbed = false, onClose }) => {
  return (
    <ModalScreenContainer>
      {!isEmbed && <MeroHeader canClose onClose={onClose} />}
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ paddingHorizontal: 24, paddingBottom: 24 }}>
          <div style={{ fontFamily: 'open-sans', fontSize: 16, color: '#525f7f', lineHeight: 1.5 }}>
            <h1>Termeni și condiții Utilizatori Tip Profesionist MERO</h1>
            <p>
              Acești{' '}
              <b>
                <i>Termeni și condiții</i>
              </b>{' '}
              de utilizare servicii site și aplicație mobilă MERO, împreună cu Politica noastră de confidențialitate și
              prelucrarea datelor personale și Termenii și condițiile site-ului (în continuare denumiți și Termenii)
              reprezintă termenii și condițiile în care MERO oferă serviciile destinate utilizatorilor profesioniști pe
              care le punem la dispoziție prin intermediul site-ului MERO (mero.ro) și al aplicației mobile MERO.
            </p>
            <p>
              Utilizarea serviciilor noastre este condiționată de acceptarea termenilor și condițiilor, astfel cum sunt
              acestea prezentate mai jos:
            </p>
            <p>
              <b>1. Definiții și interpretare</b>
            </p>
            <p>
              <b>
                <i>&quot;Date personale&quot;</i>
              </b>{' '}
              sau
              <b>
                <i> &quot;Date cu caracter personal&quot;</i>
              </b>{' '}
              înseamnă orice date referitoare la persoana fizică, care pot conduce la identificare, inclusiv coordonate
              de contact, date de identificare, vârstă și alte caracteristici fizice, date bancare, informații
              referitoare la preferințele personale etc.
            </p>
            <p>
              <b>
                <i>&quot;Utilizator Profesionist&quot;</i>
              </b>{' '}
              înseamnă utilizatorul profesionist - salon sau prestator de servicii înfrumusețare și sănătate - al
              site-ului MERO (mero.ro) (&quot;site-ul&quot;), și după caz a aplicației mobile aferente, a conținutului,
              produselor și serviciilor acestuia, destinate profesioniștilor din domeniul înfrumusețare și sănătate.
            </p>
            <p>
              <b>
                <i>&quot;Utilizator Client&quot;</i>
              </b>{' '}
              înseamnă persoana care se înregistrează pe site-ul MERO (mero.ro) (&quot;site-ul&quot;) sau în aplicația
              MERO pentru a beneficia de și a utiliza produsele și serviciile de programări online la prestatorii de
              servicii utilizatori profesioniști și orice alte servicii puse la dispoziția sa, în această calitate, prin
              intermediul site-ului sau a aplicației;
            </p>
            <p>
              <b>
                <i>&quot;MERO&quot;</i>
              </b>{' '}
              sau{' '}
              <b>
                <i>&quot;Noi&quot;</i>
              </b>
              ,{' '}
              <b>
                <i>&quot;Platforma&quot;</i>
              </b>
              ,{' '}
              <b>
                <i>&quot;Site-ul&quot;</i>
              </b>{' '}
              se referă la S.C. MERO PROGRAMARI S.R.L., o persoană juridică română, având J40/13322/2022 și CUI
              RO3760174, cu sediul în loc. Bucuresti, Str. Vasile Lascar nr. 202, Ap. C.0.2 și societățile aferente,
              reprezentată legal de d-na administrator Trif Andreea Maria;
            </p>
            <p>
              <b>
                <i>&quot;Termeni&quot;</i>
              </b>{' '}
              se referă la acești Termeni și condiții pentru utilizatori, împreună cu alți termeni și condiții relevante
              găsite în Termenii și condițiile site-ului și în Politica de confidențialitate.
            </p>
            <p>
              <b>
                <i>&quot;Profesionist&quot;</i>
              </b>{' '}
              sau{' '}
              <b>
                <i>&quot;Salon&quot;</i>
              </b>
              : se referă la profesioniștii înregistrați în cadrul MERO ca utilizatori profesionist, la care pot face
              programări online prin intermediul platformei sau aplicației MERO.
            </p>
            <p>
              Putem modifica Termenii în orice moment fără notificare către dvs. și, cu excepția cazului în care se
              prevede contrariul, toate versiunile anterioare ale Termenilor vor fi înlocuite cu cea mai recentă
              versiune. Sunteți responsabil (ă) de revizuirea celei mai recente versiuni a Termenilor și de cunoașterea
              oricăror modificări.
            </p>
            <p>
              Utilizarea continuă a serviciilor noastre și a site-ul nostru după o modificare a Termenilor sau după
              implementarea oricărei alte politici noi constituie acceptarea unei astfel de schimbări sau a unei
              politici. Veți deveni legat de cea mai recentă versiune a Termenilor de fiecare dată când vizitați site-ul
              nostru sau veți folosi serviciile noastre.
            </p>
            <p>
              <b>2. Serviciile noastre dedicate Utilizatorilor Tip Profesionist </b>
            </p>
            <p>MERO asigură clienților de tip utilizator profesionist următoarele servicii:</p>
            <ul>
              <li>
                listarea și asigurarea vizibilității pe platforma MERO, în site și pe aplicația mobilă disponibilă spre
                descărcare în Appstore / Play store, prin crearea unui profil în cadrul platformei care poate fi
                personalizat după preferințe de către utilizatorul profesionist;
              </li>
              <li>
                facilitarea, coordonarea și gestionarea serviciului de programări online efectuate de utilizatorii
                clienți, precum și asigurarea unui canal de comunicare directă între utilizatorii profesioniști și
                utilizatorii clienți, în ceea ce privește programările online și gestionarea acestora;
              </li>
              <li>
                la cerere, facilitarea efectuării de plăți pentru servicii programate prin intermediul MERO de către
                utilizatorii clienți în favoarea utilizatorilor profesioniști, prin intermediul furnizorilor de
                e-payment colaboratori ai MERO;
              </li>
              <li>
                la cerere, trimiterea de mesaje tip SMS în mod automat către utilizatorii clienți ori clienți care încă
                nu sunt utilizatori clienți MERO însă pentru care utilizatorul profesionist a adăugat programări ori i-a
                adăugat în baza de date proprie pusă la dispoziție de MERO
              </li>
              <li>
                dupa caz, oferirea de servicii precum achiziționarea și utilizarea de Carduri Cadou, prin care
                utilizatorul client va putea face programări la servicii ale utilzatorului profesionst și a căror plată
                va fi efectuată parțial sau total cu un cod unic aparținând Cardului Cadou;
              </li>
              <li>
                la cerere, după caz, oferirea de servicii de marketing adaptate pe specificul domeniului de
                înfrumusețare și sănătate;
              </li>
            </ul>
            <p>
              <b>3. Condiții de achiziție și prestare a serviciilor</b>
            </p>
            <p>
              <b>3.1. Achiziția serviciilor</b>
            </p>
            <p>
              Serviciile oferite de MERO pot fi achiziționate de către utilizatorii tip profesionist în una dintre
              următoarele forme:
            </p>
            <ul>
              <li>
                Crearea unui cont și achiziționarea pachetului destinat utilizatorilor tip profesionist direct de pe
                site/ din aplicație;
                <br />
                În acest caz, serviciile se achiziționează odată cu crearea contului de utilizator profesioniști și
                acceptarea specifică a termenilor și condițiilor de prestare a serviciilor, prin bifarea căsuței
                dedicate.
              </li>
              <li>
                Încheierea unui contract personalizat în formă scrisă sau electronică între utilizatorul profesionist și
                MERO, care va cuprinde toate condițiile specifice de prestare a serviciilor; <br />
                În acest caz, contractul se consideră încheiat la momentul la care contractul este semnat de ambele
                părți, începerea prestării serviciilor putând fi condiționată de efectuarea anumitor plăți sau
                realizarea altor prestații în avans de către utilizatorul tip profesionist.
              </li>
            </ul>
            <p>
              <b>3.2. Contul de utilizator tip profesionist</b>
            </p>
            <p>
              Pentru a fi posibilă prestarea serviciilor conform prezentelor termeni și condiții, este necesar ca
              utilizatorul să își înregistreze un cont dedicat de utilizator profesionist în cadrul site-ului și/sau
              aplicației.
            </p>
            <p>
              Prin intermediul contului dedicat, utilizatorul profesionist are posibilitatea de a-și personaliza
              profilul listat pe site și în aplicația MERO, pentru a oferi informații customizate despre serviciile sale
              utilizatorilor clienți și a asigura posibilitatea utilizatorilor clienți de a efectua și gestiona în mod
              cât mai facil și eficient programările online.
            </p>
            <p>
              Crearea și configurarea contului se realizează accesând opțiunea de creare cont din cadrul site-ului sau
              aplicației MERO, și completând informațiile de bază referitoare la utilizator- inclusiv datele de contact
              și parola solicitate pentru configurare.
            </p>
            <p>
              După configurarea contului, utilizatorul tip profesionist va primi acces la mai multe opțiuni suplimentare
              de personalizare a profilului, inclusiv în ceea ce privește definirea serviciilor oferite și a prețurilor
              aplicabile, opțiunea de adăugare a orelor de program.
            </p>
            <p>
              Toate datele confidențiale transmise către MERO vor fi tratate în conformitate cu procedurile noastre
              stricte de manipulare a datelor, consultați Politica noastră de confidențialitate.
            </p>
            <p>
              MERO nu își asumă răspunderea pentru informațiile eronate, inexacte, denumirile ofensatoare, contrare
              bunelor moravuri, cu tentă sexuală și/sau pornografică, furnizate de către utilizatorii profesioniști în
              cadrul secțiunii dedicate contului și profilului acestora.
            </p>
            <p>
              În cazul în care, cu ocazia înregistrării sau în orice moment ulterior, se constată că utilizatorul
              profesionist a introdus, în mod intenționat, date sau denumiri ofensatoare, contrare bunelor moravuri, cu
              tentă sexuală și/sau pornografică, sau date eronate sau date de identificare ale unei alte persoane sau
              entități în scopul sau cu consecința de a induce în eroare reprezentanții MERO sau utilizatorii clienți cu
              privire la prestatorul serviciilor și condițiile de prestare a acestuia, MERO este în drept să dezactiveze
              de îndată contul, și după caz să sesizeze autoritățile în drept.
            </p>
            <p>
              Utilizatorul profesionist se obligă să publice prin intermediul contului și profilului asociat doar
              informații corecte și exacte în legătură cu serviciile oferite și condițiile de prestare a acestora,
              asigurând respectarea dispozițiilor legale incidente în materia protecției consumatorului. Utilizatorul
              este unicul responsabil pentru conținutul publicat prin intermediul MERO și acuratețea informațiilor
              referitoare la serviciile oferite, inclusiv informațiile referitoare la prețurile practicate, eventuale
              discounturi și oferte speciale, calitatea și alte condiții referitoare la prestarea serviciilor.
            </p>
            <p>
              <b>3.3. Testare gratuită/ Free trial</b>
            </p>
            <p>
              MERO oferă utilizatorilor profesioniști acces gratuit și nerestricționat la toate serviciile, pentru o
              perioadă de 14 zile, în cadrul unei testări gratuite/free trial.
            </p>
            <p>
              Activarea testării gratuite/free trial-ului se realizează automat, prin urmarea pașilor menționați la
              secțiunea 3.2.- Cont tip profesionist. În acest interval, utilizatorul tip profesionist beneficiază de
              toate serviciile MERO.
            </p>
            <p>
              De asemenea, utilizatorul beneficiază de atenția și asistența consultanților MERO pentru identificarea
              celor mai potrivite servicii pentru nevoile acestora
            </p>
            <p>
              Prețul serviciilor se stabilește în mod personalizat, în funcție de numărul de angajați/profesioniști și
              saloane pentru care se solicită prestarea serviciilor.
            </p>
            <p>
              Utilizatorul profesionist este obligat să opteze pentru una dintre variantele de facturare până la finalul
              perioadei de testare gratuită/free trial.
            </p>
            <p>
              <b>3.4. Facturarea și plata serviciilor</b>
            </p>
            <p>
              Utilizatorul profesionist agreează că a luat la cunoștință și va achita contravaloarea serviciilor
              oferite, conform sumarului prezentat în contul de profesionist la secțiunea Abonament și facturare.
            </p>
            <p>
              Contravaloarea abonamentului cu privire la organizarea programărilor se stabilește în funcție de numărul
              de angajați/profesioniști pentru care se vor putea efectua programări, și se plătește în avans.
            </p>
            <p>
              În cazul în care în utilizatorul profesionist modifică numărul de profesioniști care acceptă programări,
              costul abonamentului se va schimba începând cu următoarea perioadă contractată. Utilizatorul profesionist
              datorează echivalentul costului pentru profesioniștii adiționali aferent numărului de zile începând cu
              prima zi de la adăugarea noilor profesioniști, până la perioada nouă perioadă de abonament contractată.
            </p>
            <p>
              În cazul în care utilizatorul profesionist va opta pentru servicii de marketing ce implică un comision,
              acesta va fi calculat după realizarea programării. Comisionul se aplică din valoarea primei programări a
              unui utilizator client nou, care nu a fost anulată ori marcată ca neprezentat, adus prin promovarea
              profilului său pe MERO în secțiunile specifice. În cazul serviciilor promovate cu interval de preț
              comisionul se va calcula pe baza valorii de mijloc a intervalului. În cazul serviciilor promovate cu preț
              variabil (ascuns) se va considera un comision minim. Aceste servicii se achită lunar pentru luna
              anterioară, indiferent de perioada abonamentului plătită în avans.
            </p>
            <p>
              În cazul în care utilizatorul profesionist optează pentru comunicări prin mesaje trimise automat de tip
              SMS, numărul acestora se calculează retroactiv și se achită lunar pentru luna anterioară, indiferent de
              perioada abonamentului plătită în avans.
            </p>
            <p>
              Utilizatorul profesionist va achita contravaloarea serviciilor oferite, fie online prin card, fie prin
              ordin de plata bancar. Scadența obligației de plată este dată de expirarea perioadei de testare gratuită
              sau după caz așa cum se prevede mai sus în funcție de serviciul ori pachetul de servicii pentru care se
              optează.
            </p>
            <p>
              Pentru evitarea oricărui dubiu, în cazul în care, la finalul perioadei contractate anterior, utilizatorul
              profesionist nu optează pentru un alt pachet sau o altă periodicitate a serviciilor, se consideră că
              aceștia datorează contravaloarea serviciilor pentru același pachet și periodicitate contractată anterior.
            </p>
            <p>MERO va emite factura aferentă serviciilor contractate, conform condițiilor convenite. </p>
            <p>
              <u>
                Neachitarea contravalorii serviciilor până la data scadenței echivalează cu o renunțare implicită la
                serviciile oferite de MERO, contul MERO al utilizatorului tip profesionist fiind automat dezactivat la
                momentul expirării termenului de plată. Acest fapt nu exonerează utilizatorul de tip profesionist de la
                plata serviciilor datorate, MERO având dreptul de a recurge la orice mijloace pentru recuperarea sumelor
                datorate.
              </u>
            </p>
            <p>
              <b>3.5. Dezactivarea contului. Reactivarea contului</b>
            </p>
            <p>
              Contul unui utilizator tip profesionist MERO este dezactivat, iar serviciile aferente nu mai sunt
              prestate, în următoarele situații:
            </p>
            <ul>
              <li>în cazul în care utilizatorul profesionist încalcă Termenii și condițiile;</li>
              <li>
                în cazul în care utilizatorul profesionist nu mai achită serviciile, acest fapt echivalând cu o
                renunțare implicită a acestuia la prestarea serviciilor;
              </li>
              <li>în cazul în care utilizatorul profesionist solicită renunțarea la serviciile MERO;</li>
              <li>
                în cazul în care MERO, din orice motiv, nu mai poate presta serviciile, pentru un interval de timp egal
                cu durata pentru care utilizatorul profesionist a achitat în avans serviciile;
              </li>
            </ul>
            <p>
              Utilizatorul profesionist poate solicita reactivarea unui cont dezactivat, iar în măsura în care datele
              pot fi recuperate și punerea în repunerea în funcțiune a contului se poate opera, MERO are dreptul de a
              solicita pe lângă achitarea unei noi perioade de abonament și o taxă de reactivare.
            </p>
            <p>
              <b>3.6. Condiții de prestare a serviciilor</b>
            </p>
            <p>
              MERO asigură serviciile aferente platformei și aplicației, astfel cum sunt acestea stabilite la pct. 2 de
              mai sus, în limitele și cu respectarea condițiilor prevăzute în continuare:
            </p>
            <ul>
              <li>
                utilizatorul profesionist este unicul responsabil pentru prestarea efectivă, conformă și la termen a
                serviciilor care fac obiectul programărilor efectuate prin intermediul platformei;
              </li>
              <li>
                Utilizatorul profesionist este obligat să adauge în contul său toate programările viitoare, obținute
                prin orice alte surse ca de exemplu, însă fără a se limita la telefon, mesaje, rețele de socializare.
              </li>
              <li>
                Adăugarea programărilor trebuie să se facă în cel mai scurt timp de la stabilirea acestora cu clienții
                finali, indiferent dacă acești clienți sunt sau nu utilizatori MERO, pentru a evita suprapunerile în
                calendarul(le) pus(se) la dispoziție pe MERO.
              </li>
              <li>
                utilizatorul profesionist este obligat să administreze contul și profilul dedicate în mod diligent, și
                să publice prin intermediul acestuia doar informații exacte și reale în ceea ce privește condițiile de
                prestare a serviciilor, administrarea și regulile privind efectuarea programărilor etc.; MERO are
                dreptul ca, atunci când constată nerespectarea acestei obligații, să solicite și să obțină înlăturarea
                materialelor sau informațiilor în cauză;
              </li>
              <li>
                MERO își rezervă dreptul de a lista și de a menține publice doar profilele care întrunesc permanent
                toate condițiile de calitate ale platformei, atât timp utilizatorul profesionist nu încalcă condițiile
                enumerate la acest punct
              </li>
              <li>
                utilizatorul își stabilește propriile reguli în ceea ce privește condițiile de anulare sau modificare a
                unei programări online, fiind obligat să le pună la dispoziție într-o manieră accesibilă utilizatorilor
                clienți, MERO nefiind responsabil pentru consecințele nerespectării condițiilor inițiale ale unei
                programări de către niciuna dintre părți;
              </li>
              <li>
                utilizatorul profesionist este obligat să respecte toate dispozițiile Termenilor și condițiilor,
                recunoscând în mod expres că orice încălcare a acestora dă dreptul MERO de a-i dezactiva în mod automat
                contul, fără nicio notificare prealabilă;
              </li>
              <li>
                utilizatorul are obligația de a utiliza platforma și aplicația MERO exclusiv în scopurile, în limitele
                și în condițiile prevăzute de aceasta, respectiv pentru gestionarea programărilor online efectuate de
                utilizatorii clienți și a serviciilor conexe;
              </li>
              <li>
                utilizatorul este obligat să respecte dispozițiile legale incidente în materia drepturilor de
                proprietate intelectuală; în acest sens, se obligă să nu publice și să nu utilizeze în niciun fel în
                legătură cu platforma și aplicația MERO, însemne, documente, înscrisuri ori alte asemenea, cu privire la
                care nu deține un drept de proprietate intelectuală sau, în lipsa acestui drept, cu privire la care
                drepturile de proprietate intelectuală sunt deținute de un terț;
              </li>
              <li>
                orice servicii suplimentare față de cele standard, respectiv orice servicii menționate în prezentele ca
                fiind servicii la cerere, vor fi convenite și taxate în mod distinct, conform tarifelor afișate pe site
                sau în cadrul aplicației sau, în lipsă ori după caz, conform convenției scrise dintre părți;
              </li>
              <li>
                utilizatorul profesionist este obligat să asigure prelucrarea datelor personale la care, în calitate sa
                de operator, obține acces prin intermediul site-ului și/sau aplicației în conformitate cu dispozițiile
                legale aplicabile în materia prelucrării datelor cu caracter personal, și după caz să respecte toate
                instrucțiunile MERO în ceea ce privește prelucrarea datelor personale cu privire la care utilizatorul
                profesionist are calitatea de împuternicit; utilizatorul va informa în mod complet, conform
                dispozițiilor legale incidente, utilizatorii clienți și orice alte categorii de persoane vizate în ceea
                ce privește prelucrarea datelor acestora cu caracter personal, cu privire la care utilizatorul
                profesionist are calitatea de operator;
              </li>
              <li>
                utilizatorul are obligația de a verifica site-ul și aplicația MERO, accesând secțiunea Termeni și
                condiții, pentru a se menține la curent cu varianta actualizată a acestora; orice modificări privind
                condițiile de prestare a serviciilor vor fi notificate de către MERO prin afișarea pe site sau prin
                intermediul emailului;
              </li>
            </ul>
            <p>
              MERO are dreptul de a modifica în mod unilateral în orice moment condițiile de prestare a serviciilor,
              notificând și publicând forma actualizată a acestora utilizatorilor prin intermediul site-ului și a
              aplicației, după caz, la secțiunea dedicată Termeni și condiții; noile condiții vor fi aplicabile începând
              cu următoarea perioadă pentru care utilizatorul optează pentru serviciile oferite. Utilizatorul nu se
              poate opune acestor modificări, având doar dreptul de a nu reînnoi pentru o nouă perioadă accesul la
              aceste servicii.
            </p>
            <p>
              <b>4. Limitarea răspunderii</b>
            </p>
            <p>
              Utilizatorul înțelege și acceptă expres faptul că MERO furnizează exclusiv serviciile menționate la pct. 2
              din cuprinsul Termenilor și Condițiilor, nefiind responsabili pentru prestarea efectivă a serviciilor
              programate, condițiile de prestare a acestor servicii, acuratețea descrierii serviciilor și a altor
              informații postate de utilizatorii clienți sau utilizatorii profesioniști, utilizatorii care accesează
              serviciile, funcționarea optimă a serviciilor, platformei și aplicației pe echipamentele utilizatorilor,
              respectarea dispozițiilor legale de către utilizatori și alte asemenea, utilizatorul apelând la prestarea
              serviciilor pe propria sa răspundere, fiind pe deplin informat cu privire la cele de mai sus. De asemenea,
              MERO nu este responsabil pentru disfuncționalități, întreruperi, suspendări, îngreunări sau erori
              intervenite în funcționarea platformei, site-ului sau aplicației aferente, cu excepția cazului în care
              este identificată o legătură clară de cauzalitate între acestea și o acțiune sau inacțiune a MERO,
              săvârșită cu culpă gravă; în orice astfel de caz însă, răspunderea MERO este limitată la valoarea
              serviciilor achitate în avans de către utilizatorul client, conform opțiunii în vigoare la data apariției
              neconformității.
            </p>
            <p>
              Cu toate acestea, MERO va realiza în mod diligent toate demersurile necesare pentru asigurarea
              funcționării optime a platformei și aplicației și asigurarea prestării serviciilor în bune condiții.
            </p>
            <p>
              MERO nu este responsabil pentru încălcarea oricărei dispoziții legale ori contractuale de către
              utilizatorii profesioniști în relația cu utilizatorii clienți sau în relația utilizatorilor profesioniști
              cu terții, ori pentru orice diferend, ori prejudiciu cauzat utilizatorilor clienți sau terților în
              legătură cu serviciile care fac obiectul programărilor online efectuate prin intermediul MERO. În acest
              sens, utilizatorii profesioniști se obligă să apere și să despăgubească, după caz, integral MERO în ceea
              ce privește orice acțiune de atragere a răspunderii în sarcina MERO de către un utilizator client sau
              terț, în legătură cu serviciile oferite de utilizatorii profesioniști, respectiv pentru orice daune,
              pierderi, cheltuieli sau costuri inclusiv, fără a se limita la orice daune directe, indirecte, incidentale
              sau consecutive, pierderi de profit sau oportunitate în legătură cu acestea.
            </p>
            <p>
              <b>5. Proprietate intelectuală</b>
            </p>
            <p>
              Site-ul, conținutul său, denumirea comercială, brandul MERO, codul sursă și toate elementele implicate în
              funcționarea acestuia sunt proprietatea exclusivă a MERO, prevăzută de legislația aferentă drepturilor de
              proprietate intelectuală. Permisiunea de a utiliza site-ul, respectiv permiterea accesului la acesta și
              funcționalitățile sale nu implică și nu poate fi considerată a reprezenta o licență ori o cesiune sub
              orice formă, exclusivă ori neexclusivă, a drepturilor de proprietate intelectuală aferente.
            </p>
            <p>
              Orice utilizare în nume propriu a brandului, site-ului sau a oricărui element care se află în proprietatea
              exclusivă a MERO atrage răspunderea utilizatorului profesionist pentru întregul prejudiciu cauzat MERO.
            </p>
            <p>
              Orice publicare de conținut ( informații, documente, logouri sau alte înscrisuri) de către utilizatorii
              profesioniști va respecta legislația privind drepturile de autor și drepturile de proprietate intelectuală
              incidentă, utilizatorii profesioniști fiind unicii responsabili pentru asigurarea respectării
              dispozițiilor legale în materie în ceea ce privește conținutul publicat, precum și pentru întregul
              prejudiciu cauzat titularilor drepturilor de proprietate intelectuală sau după caz, MERO.
            </p>
            <p>
              Prin publicarea de conținut pe profilul și contul dedicate, utilizatorii profesioniști acordă o licență
              neexclusivă, pe durată nedeterminată și nelimitată spațial în favoarea MERO, în ceea ce privește adaptarea
              și retransmiterea conținutului publicat, în conformitate cu cerințele tehnice ale site-ului și ale
              aplicației.
            </p>
            <p>
              <b>6. Alte dispoziții</b>
            </p>
            <p>
              <b>6.1. Separabilitate</b>
            </p>
            <p>
              În cazul în care oricare dintre prevederile prezentului contract va fi declarată nulă, părțile convin și
              declară expres faptul că celelalte dispoziții vor rămâne în continuare valabile.
            </p>
            <p>
              <b>6.2. Lege aplicabilă și competență</b>
            </p>
            <p>
              Termenii și condițiile pentru utilizatori vor fi guvernați și interpretați în conformitate cu legea din
              Romania, iar orice litigii referitoare la acești Termeni de utilizare vor fi supuși jurisdicției exclusive
              a instanțelor competente din Romania.
            </p>
          </div>
        </ScrollView>
      </AvoidKeyboard>
    </ModalScreenContainer>
  );
};

export default TermsOfServiceScreenView;

import { ApiError, SavedCheckoutCompany } from '@mero/api-sdk';
import { Body, Checkbox, Column, H2s, Row, styles as meroStyles, Line, Spacer, useToast } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import useGoBack from '../../../../../hooks/useGoBack';

import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { styles } from './styles';

type Props = {
  //pass
};

const CashRegistryCompanyMenuScreen: React.FC<Props> = ({}) => {
  const { t } = useTranslation('cashRegistry');
  const goBack = useGoBack();
  const toast = useToast();

  const [cashRegistryState, { setSelectedCompany, update }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const onChangeCompany = (company: SavedCheckoutCompany) => {
    if (pageState.type === 'Loaded') {
      try {
        setSelectedCompany({ pageId: pageState.page.details._id, company });
        goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          toast.show({
            type: 'error',
            text: error.message,
          });
        }
      }
    }
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.stretchContainer}
        onPress={() => {
          goBack();
          update({ isCompanyMenuOpen: false });
        }}
      />
      <Column style={{ ...styles.listContainer, ...styles.listContainerPadding }}>
        <H2s>{t('chooseCashRegister')}</H2s>
        {cashRegistryState.type === 'Loaded' &&
          cashRegistryState.selectedCompany &&
          cashRegistryState.companies.length > 0 &&
          cashRegistryState.companies.map((company, index) => (
            <View key={company._id}>
              <TouchableOpacity style={{ marginVertical: 16 }} onPress={() => onChangeCompany(company)}>
                <Row>
                  <Checkbox
                    value={cashRegistryState.selectedCompany?._id === company._id}
                    onValueChange={() => onChangeCompany(company)}
                  />
                  <Body style={{ ...meroStyles.text.semibold, marginLeft: 16 }}>{company.billingDetails?.name}</Body>
                </Row>
              </TouchableOpacity>
              {index !== cashRegistryState.companies.length - 1 && <Line />}
            </View>
          ))}
        <Spacer size="48" />
      </Column>
    </View>
  );
};

export default CashRegistryCompanyMenuScreen;

import { H1, H3s, Row, SearchTextInput, Spacer, Body, styles } from '@mero/components';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';

type ListHeaderViewProps = {
  readonly contactsCnt: number;
  readonly selectedCnt: number;
  readonly onSelectAll: () => void;
  readonly onDeselectAll: () => void;
  readonly query: string;
  readonly onQueryChange: (q: string) => void;
  readonly showTitle?: boolean;
  readonly showFooter?: boolean;
};

const ListHeaderView: React.FC<ListHeaderViewProps> = ({
  contactsCnt,
  selectedCnt,
  onSelectAll,
  onDeselectAll,
  query,
  onQueryChange,
  showTitle = false,
  showFooter = false,
}: ListHeaderViewProps) => {
  const { t } = useTranslation();

  return (
    <View>
      <Spacer size="8" />
      {showTitle ? (
        <>
          <H1>{t('clients:importContacts')}</H1>
          <Spacer size="24" />
        </>
      ) : null}
      <SearchTextInput placeholder={i18next.t('clients:searchNameOrPhone')} value={query} onChange={onQueryChange} />
      {showFooter ? (
        <>
          <Spacer size="16" />
          <Row>
            <Row style={{ flex: 1 }}>
              <H3s>{`${contactsCnt}`} contacte</H3s>
            </Row>
            <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
              {contactsCnt > 0 && contactsCnt === selectedCnt ? (
                <TouchableOpacity style={{ paddingTop: 8, paddingBottom: 8 }} onPress={onDeselectAll}>
                  <Body style={[styles.text.link, styles.text.semibold]}>Deselectează tot</Body>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity style={{ paddingTop: 8, paddingBottom: 8 }} onPress={onSelectAll}>
                  <Body style={[styles.text.link, styles.text.semibold]}>Selectează tot</Body>
                </TouchableOpacity>
              )}
            </Row>
          </Row>
        </>
      ) : null}
      <Spacer size="24" />
    </View>
  );
};

export default ListHeaderView;

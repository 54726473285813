import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionItem';
import { Body, colors, Column, H1, H2, H3s, Line, SmallBody, Spacer, Title } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';
import Icon from '@mero/components/lib/components/Icon';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './AddProceedMenu.styles';

export type Props = {
  withDescription?: boolean;
  onSelect: (type: CreateCheckoutTransactionItem.Any<MeroUnits.Any>['type']) => void;
  onDismiss: () => void;
};

const AddProceedMenu: React.FC<Props> = ({ onDismiss, onSelect, withDescription = true }) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('checkout');

  return isPhone ? (
    <ModalOverlay style={{ zIndex: 10000 }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <Column style={styles.optionsMenuItem}>
            <H3s>{t('proceedWith')}</H3s>
            <SmallBody>{t('proceedWithDescription')}</SmallBody>
          </Column>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Booking')}>
            <Title>{t('proceedBooking')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Service')}>
            <Title>{t('proceedService')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Product')}>
            <Title>{t('proceedProduct')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Membership')}>
            <Title>{t('proceedMembership')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => onSelect('MembershipInstallment')}
          >
            <Title>{t('proceedMembershipInstallment')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Amount')}>
            <Title>{t('proceedAmount')}</Title>
          </TouchableOpacity>
          <Line />
        </SafeAreaView>
      </View>
    </ModalOverlay>
  ) : (
    <ModalOverlay style={{ zIndex: 10000, alignItems: 'center', justifyContent: 'center' }}>
      <Column style={{ backgroundColor: 'transparent', width: 500, paddingVertical: 48 }}>
        <Column style={{ flex: 1, backgroundColor: colors.WHITE, borderRadius: 8, overflow: 'hidden' }}>
          <MeroHeader canClose onClose={onDismiss} title={t('pickCheckout')} />
          <Column style={{ paddingHorizontal: 24 }}>
            <Spacer size={24} />
            <H1>{t('pickCheckout')}</H1>
            {withDescription && (
              <>
                <Spacer size={8} />
                <Body>{t('proceedWithDescription')}</Body>
              </>
            )}
            <Spacer size={24} />
          </Column>
          <FormCard dropShaddow rounded paddings="none" style={{ marginHorizontal: 16 }}>
            <TouchableOpacity style={styles.optionsMenuItemLarge} delayPressIn={0} onPress={() => onSelect('Booking')}>
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedBooking')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItemLarge} delayPressIn={0} onPress={() => onSelect('Service')}>
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedService')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItemLarge} delayPressIn={0} onPress={() => onSelect('Product')}>
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedProduct')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
            <Line />
            <TouchableOpacity
              style={styles.optionsMenuItemLarge}
              delayPressIn={0}
              onPress={() => onSelect('Membership')}
            >
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedMembership')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
            <Line />
            <TouchableOpacity
              style={styles.optionsMenuItemLarge}
              delayPressIn={0}
              onPress={() => onSelect('MembershipInstallment')}
            >
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedMembershipInstallment')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItemLarge} delayPressIn={0} onPress={() => onSelect('Amount')}>
              <H2 style={{ fontFamily: 'open-sans-semibold' }}>{t('proceedAmount')}</H2>
              <Icon type="next" color={colors.DARK_BLUE} />
            </TouchableOpacity>
          </FormCard>
          <Spacer size={24} />
        </Column>
      </Column>
    </ModalOverlay>
  );
};

export default AddProceedMenu;

import { BusinessHours } from '@mero/api-sdk/dist/business';
import {
  Avatar,
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  Header,
  Icon,
  Label,
  Line,
  Row,
  Select,
  Spacer,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import { getWeekDays } from '../../../../../components/Calendar/BigCalendar/utils';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import { styles } from './ProScheduleScreen.styles';

const getIntervals = (start: number, end: number) => {
  const intervals = [];
  for (let i = start; i <= end; i++) {
    for (let j = 0; j < 60 / 15; j++) {
      intervals.push({
        label: `${i < 10 ? `0${i}` : i}:${j * 15 < 10 ? `0${j}` : j * 15}`,
        value: `${i}:${j * 15}`,
      });
    }
  }
  return intervals;
};

const intervals = getIntervals(0, 23);

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewSchedule'>;

const AddProScheduleScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [addProState, { updateBusinessHours }] = AddNewProContext.useContext();

  const [businessHours, setBusinessHours] = React.useState<BusinessHours>(addProState.businessHours);

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    updateBusinessHours(businessHours);
    goBack();
  };

  const toggleCheckbox = (index: number) => () => {
    const newBusinessHours = [...businessHours];
    newBusinessHours[index] = {
      ...newBusinessHours[index],
      active: !newBusinessHours[index].active,
    };
    setBusinessHours(newBusinessHours);
  };

  const startTimeChanged = (index: number) => (value: string) => {
    const newBusinessHours = [...businessHours];
    const [hour, minute] = value.split(':').map((v) => parseInt(v, 10));
    newBusinessHours[index] = {
      ...newBusinessHours[index],
      from: {
        hour,
        minute,
      },
    };

    if (
      hour > newBusinessHours[index].to.hour ||
      (hour === newBusinessHours[index].to.hour && minute > newBusinessHours[index].to.minute)
    ) {
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        to: {
          hour,
          minute,
        },
      };
    }

    setBusinessHours(newBusinessHours);
  };

  const endTimeChanged = (index: number) => (value: string) => {
    const newBusinessHours = [...businessHours];
    const [hour, minute] = value.split(':').map((v) => parseInt(v, 10));
    newBusinessHours[index] = {
      ...newBusinessHours[index],
      to: {
        hour,
        minute,
      },
    };

    if (
      hour < newBusinessHours[index].from.hour ||
      (hour === newBusinessHours[index].from.hour && minute < newBusinessHours[index].from.minute)
    ) {
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        from: {
          hour,
          minute,
        },
      };
    }

    setBusinessHours(newBusinessHours);
  };

  const weekDays = React.useMemo(() => getWeekDays(), []);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proSchedule')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={addProState.details.profilePhoto}
              firstname={addProState.details.firstname}
              lastname={addProState.details.lastname}
            />
          </Column>
        )}
      />
      <ScrollView>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('proSchedule')}</H1>
          <Body style={{ padding: 8 }}>{t('proScheduleNewDescription')}</Body>
          <Spacer size={24} />
          <FormCard rounded paddings="none">
            <H3s style={{ padding: 16 }}>{t('proSchedule')}</H3s>
            <Line />
            {businessHours.map((day, index) => {
              return (
                <Column key={index} style={{ paddingHorizontal: 16 }}>
                  <Row style={{ height: 76, alignItems: 'center' }}>
                    <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={toggleCheckbox(index)}>
                      <Checkbox
                        color="blue"
                        disabled={false}
                        value={day.active}
                        onValueChange={toggleCheckbox(index)}
                      />
                      <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{weekDays[index]}</Body>
                    </TouchableOpacity>
                    <Row
                      style={{
                        paddingLeft: 12,
                        alignItems: 'center',
                        flex: 3,
                        justifyContent: 'flex-end',
                      }}
                    >
                      {day.active ? (
                        <>
                          <Select
                            items={intervals}
                            style={{ flex: 2 }}
                            value={`${day.from.hour}:${day.from.minute}`}
                            onChange={startTimeChanged(index)}
                          />
                          <Column style={{ width: 8 }} />
                          <Select
                            items={intervals}
                            style={{ flex: 2 }}
                            value={`${day.to.hour}:${day.to.minute}`}
                            onChange={endTimeChanged(index)}
                          />
                        </>
                      ) : (
                        <Label style={{ textAlign: 'right' }}>{t('free')}</Label>
                      )}
                    </Row>
                  </Row>
                  {index < businessHours.length - 1 && <Line />}
                </Column>
              );
            })}
          </FormCard>
        </Column>
        <Spacer size="16" />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('done')} onClick={saveChanges} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text={t('done')} onClick={saveChanges} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProScheduleScreen;

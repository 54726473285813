import { ImportProductsResponse, PageId, apiError } from '@mero/api-sdk';
import {
  Body,
  Button,
  Column,
  FormCard,
  H1,
  H2,
  H3s,
  HSpacer,
  Line,
  MeroHeader,
  Row,
  SmallBody,
  Spacer,
  colors,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import { Asset } from 'expo-asset';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import * as types from 'io-ts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, View } from 'react-native';
import { Platform, ScrollView, Share } from 'react-native';
import { Circle, G, Path, Rect, Svg } from 'react-native-svg';
import { v4 as uuidv4 } from 'uuid';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { AuthorizedStackParamList } from '../../../types';
import log from '../../../utils/log';

type Props = {
  route: RouteProp<AuthorizedStackParamList, 'ImportProductsScreen'>;
  navigation: StackNavigationProp<AuthorizedStackParamList, 'ImportProductsScreen'>;
};

export enum Status {
  LOADING,
  FAILED,
  TOO_BIG,
  SUCCESS,
}

export type NewFileType = {
  _id: string;
  status: Status;
  uri: string;
};

const SuccessIcon = () => (
  <Svg width="120" height="120" viewBox="0 0 120 120">
    <G transform="translate(-109 -198)">
      <G transform="translate(118.854 207.853)">
        <Circle cx="43" cy="43" r="43" transform="translate(7.147 7.147)" fill="#5ade9c" />
        <G fill="none" stroke="#2e4369" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <Circle cx="43" cy="43" r="43" transform="translate(7.147 93.147) rotate(-90)" />
          <Path d="M21,35.057,33.886,47.945,56.835,25" transform="translate(13.379 15.112)" />
        </G>
      </G>
      <Rect width="120" height="120" transform="translate(109 198)" fill="none" />
    </G>
  </Svg>
);

const ErrorIcon = () => (
  <Svg width="88" height="88" viewBox="0 0 88 88">
    <G id="Orion_check-validate-circle" transform="translate(-6.147 -6.147)">
      <Circle cx="43" cy="43" r="43" transform="translate(7.147 7.147)" fill="#f05f7c" />
      <Circle
        cx="43"
        cy="43"
        r="43"
        transform="translate(7.147 93.147) rotate(-90)"
        fill="none"
        stroke="#2e4369"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <Path
        data-name="layer1"
        d="M54,20,20,54m34,0L20,20"
        transform="translate(13.148 13.145)"
        fill="none"
        stroke="#2e4369"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
      />
    </G>
  </Svg>
);

const ProgressIcon = () => (
  <Svg width="120" height="120" viewBox="0 0 120 120">
    <G transform="translate(-110 -198)">
      <G transform="translate(125 213)">
        <Path d="M43,0A43,43,0,1,1,0,43,43,43,0,0,1,43,0Z" transform="translate(2 2)" fill="#fbac40" />
        <Path
          d="M41.864,2A42.711,42.711,0,0,0,8.881,17.523,42.8,42.8,0,0,1,69.14,77.782,42.8,42.8,0,0,0,41.864,2Z"
          transform="translate(2.936)"
          fill="#000064"
          opacity="0.15"
        />
        <Path
          d="M46.12,55.226a2.828,2.828,0,0,1-1.35-.341L29,45.516V13.853a2.853,2.853,0,0,1,5.707,0V42.109l12.766,7.752a2.853,2.853,0,0,1-1.352,5.364Z"
          transform="translate(11.519 3.84)"
          fill="#000064"
          opacity="0.15"
        />
        <G
          fill="none"
          stroke="#3b3f5c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        >
          <Circle cx="43" cy="43" r="43" transform="translate(2 2)" />
          <Path d="M32,10V42.813L50.546,52.8" transform="translate(12.799 3.413)" />
        </G>
      </G>
      <Rect width="120" height="120" transform="translate(110 198)" fill="none" />
    </G>
  </Svg>
);

const files = {
  sample: require('../../../assets/files/import_products_template.xlsx'),
};

const ImportProductsScreen: React.FC<Props> = () => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const [isUploading, setIsUploading] = React.useState(false);
  const showError = useShowError();
  const [pageState] = CurrentBusinessContext.useContext();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [importResponse, setImportResponse] = React.useState<ImportProductsResponse | undefined>();
  const [, { reload }] = SearchProductsContext.useContext();
  const [, { reloadCategories, reloadBrands }] = ProductsContext.useContext();
  const [error, setError] = useState<string | undefined>();

  const convertMbToBytes = (mb: number) => mb * 1024 * 1024;

  const uploadFile = async (file: NewFileType, pageId: PageId) => {
    setIsUploading(true);

    if (file.status !== Status.LOADING) {
      return;
    }

    log.debug(`Uploading file`);
    const fileUri = Platform.OS === 'ios' ? file.uri.replace('file://', '') : file.uri;
    const response = await fetch(fileUri);
    const blob = await response.blob();

    try {
      const importResponse = await meroApi.pro.products.importProducts({
        pageId,
        file: { platform: Platform.OS, blob, uri: fileUri },
      });

      setImportResponse(importResponse);

      log.info(
        `Import complete: total rows: ${importResponse.totalCount}, skipped rows: ${importResponse.skippedCount}`,
      );
      reloadBrands(pageId);
      reloadCategories(pageId);
      reload();
    } catch (error) {
      log.debug('Failed to upload file', error);
      if (apiError(types.unknown).is(error)) {
        setError(error.message);
      }
    }
    setIsUploading(false);
  };

  const downloadSample = async () => {
    const downloadedFileName = 'model_import_produse_mero.xlsx';
    try {
      if (Platform.OS === 'web') {
        const link = document.createElement('a');

        link.href = files.sample;
        link.download = downloadedFileName;
        link.click();
      } else {
        const localUri = Asset.fromModule(files.sample).uri;

        const downloadUri = `${FileSystem.documentDirectory}${downloadedFileName}`;

        const { uri } = await FileSystem.downloadAsync(localUri, downloadUri);
        await Share.share({
          url: uri,
        });
      }
    } catch (error) {
      toast.show({ type: 'error', text: t('failedToDownloadSampleFile') });
      log.error('Error while downloading sample file', error);
    }
  };

  const singleSelect = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    return result.canceled ? [] : result.assets;
  };

  const importFile = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const result = await singleSelect();

      if (result.length === 1) {
        const asset = result[0];
        const timestamp = new Date().getTime();
        const newFile = {
          _id: Platform.OS === 'web' ? uuidv4() : `${timestamp}-${asset}`,
          uri: asset.uri,
          status: (asset.file?.size ?? 0) > convertMbToBytes(20) ? Status.TOO_BIG : Status.LOADING,
        };

        await uploadFile(newFile, pageState.page.details._id);
      }
    } catch (error) {
      log.error('Error while importing file', error);
      showError(error, t('failedToImportFile'));
    }
  };

  return (
    <>
      {!isUploading && !importResponse && !error ? (
        <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
          <MeroHeader title={t('importProducts')} canGoBack={false} canClose={true} onClose={goBack} />

          <ScrollView style={{ padding: 16, flex: 1 }}>
            <Spacer size={24} />
            <H1 style={{ marginLeft: 8 }}>{t('importProducts')}</H1>

            <Spacer size={16} />
            <FormCard paddings="none" dropShaddow>
              <View style={{ paddingVertical: 24, paddingHorizontal: 16 }}>
                <H3s>{t('importFirstStep')}</H3s>
                <Spacer size={8} />

                <Body>{t('importFirstStepDescription')}</Body>
                <Body style={styles.text.semibold}>{t('importFirstStepWarning')}</Body>
                <Spacer size={24} />

                <Button
                  text={t('downloadSampleXLSXFile')}
                  expand={true}
                  backgroundColor={'#F2F2FE'}
                  color={colors.DARK_BLUE}
                  withShadow={true}
                  onPress={downloadSample}
                />
              </View>

              <Line />

              <View style={{ paddingVertical: 24, paddingHorizontal: 16 }}>
                <H3s>{t('importSecondStep')}</H3s>
                <Spacer size={8} />

                <Body>{t('importSecondStepDescription')}</Body>
                <Spacer size={24} />

                <Button
                  text={t('importCompletedFile')}
                  expand={true}
                  backgroundColor={'#F2F2FE'}
                  color={colors.DARK_BLUE}
                  withShadow={true}
                  onPress={importFile}
                />
              </View>
            </FormCard>
            <Spacer size={isPhone ? 200 : 74} />
          </ScrollView>
        </ModalScreenContainer>
      ) : null}

      {/* All rows were imported successfully */}
      {importResponse && importResponse.skippedCount === 0 ? (
        <ModalScreenContainer edges={['top', 'bottom']} style={[{ backgroundColor: colors.ALABASTER }]}>
          <MeroHeader title={t('importProducts')} canGoBack={false} canClose={true} onClose={goBack} />
          <View style={{ padding: 16, flex: 1, justifyContent: 'center' }}>
            <Column style={{ alignItems: 'center', justifyContent: 'center' }}>
              <SuccessIcon />
              <Spacer size={40} />
              <H1>{t('importIsReady')}</H1>
              <Spacer size={32} />

              <Row>
                <Column>
                  <SmallBody style={styles.text.semibold}>{t('processed')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20 }]}>{importResponse.totalCount}</H2>
                </Column>

                <HSpacer right={42} />
                <Column>
                  <SmallBody style={[styles.text.semibold, { color: '#5ade9c' }]}>{t('added')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20, color: '#5ade9c' }]}>
                    {importResponse.totalCount - importResponse.skippedCount}
                  </H2>
                </Column>

                <HSpacer right={42} />
                <Column>
                  <SmallBody style={[styles.text.semibold, { color: colors.RADICAL_RED }]}>{t('errors')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20, color: colors.RADICAL_RED }]}>
                    {importResponse.skippedCount}
                  </H2>
                </Column>
              </Row>
            </Column>
            <Spacer size={isPhone ? 200 : 74} />
          </View>
        </ModalScreenContainer>
      ) : null}

      {/* Some rows had errors, some were imported */}
      {importResponse && importResponse.skippedCount > 0 ? (
        <ModalScreenContainer edges={['top', 'bottom']} style={[{ backgroundColor: colors.ALABASTER }]}>
          <MeroHeader title={t('importProducts')} canGoBack={false} canClose={true} onClose={goBack} />
          <ScrollView style={{ padding: 16, flex: 1 }}>
            <Column style={{ alignItems: 'center', justifyContent: 'center' }}>
              <SuccessIcon />
              <Spacer size={40} />
              <H1>{t('importIsReady')}</H1>
              <Spacer size={32} />

              <Row>
                <Column>
                  <SmallBody style={styles.text.semibold}>{t('processed')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20 }]}>{importResponse.totalCount}</H2>
                </Column>

                <HSpacer right={42} />
                <Column>
                  <SmallBody style={[styles.text.semibold, { color: '#5ade9c' }]}>{t('added')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20, color: '#5ade9c' }]}>
                    {importResponse.totalCount - importResponse.skippedCount}
                  </H2>
                </Column>

                <HSpacer right={42} />
                <Column>
                  <SmallBody style={[styles.text.semibold, { color: colors.RADICAL_RED }]}>{t('errors')}</SmallBody>
                  <H2 style={[styles.text.bold, { fontSize: 20, color: colors.RADICAL_RED }]}>
                    {importResponse.skippedCount}
                  </H2>
                </Column>
              </Row>
            </Column>

            <Spacer size={8} />
            <Line />
            <View style={{ padding: 24 }}>
              <Body style={{ color: colors.RADICAL_RED, textAlign: 'center' }}>
                {importResponse.skippedCount > 1
                  ? t('importFailDetailsMessageMany', { skippedCount: importResponse.skippedCount })
                  : t('importFailDetailsMessageSingle')}
              </Body>
              <Spacer size={32} />
              <Button
                text={t('downloadErrorFile')}
                backgroundColor="#FFE1E1"
                color={colors.RADICAL_RED}
                onPress={() => {
                  importResponse.downloadUrl &&
                    Linking.openURL(importResponse.downloadUrl).catch((error) => {
                      showError(error);
                    });
                }}
              />

              <Spacer size={8} />
              <Button
                text={t('importCompletedFile')}
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                onPress={importFile}
              />
            </View>
            <Spacer size={isPhone ? 200 : 74} />
          </ScrollView>
        </ModalScreenContainer>
      ) : null}

      {/* No rows were imported, something wrong with the file format */}
      {!importResponse && error ? (
        <ModalScreenContainer edges={['top', 'bottom']} style={[{ backgroundColor: colors.ALABASTER }]}>
          <MeroHeader title={t('importProducts')} canGoBack={false} canClose={true} onClose={goBack} />
          <View style={{ padding: 16, flex: 1, justifyContent: 'center' }}>
            <Column style={{ alignItems: 'center' }}>
              <ErrorIcon />
              <Spacer size={40} />
              <H1 style={{ color: colors.RADICAL_RED }}>{t('invalidFileFormat')}</H1>
              <Spacer size={12} />

              <Body style={{ color: colors.RADICAL_RED, paddingHorizontal: 16, textAlign: 'center' }}>{error}</Body>
              <Spacer size={32} />
            </Column>
            <Column style={{ alignSelf: 'center' }}>
              <Button
                padding={24}
                text={t('retry')}
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                onPress={() => setError(undefined)}
                expand={false}
                withShadow={true}
              />
            </Column>
          </View>
        </ModalScreenContainer>
      ) : null}

      {isUploading && !importResponse ? (
        <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
          <MeroHeader title={t('importProducts')} canGoBack={false} canClose={true} onClose={goBack} />
          <View style={{ padding: 16, flex: 1, justifyContent: 'center' }}>
            <Column style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
              <ProgressIcon />
              <Spacer size={40} />
              <H1>{t('importInProgress')}</H1>
              <Spacer size={8} />
              <Body>{t('importInProgressDescription')}</Body>
              <Spacer size={isPhone ? 200 : 74} />
            </Column>
          </View>
        </ModalScreenContainer>
      ) : null}
    </>
  );
};

export default ImportProductsScreen;

import {
  Column,
  Spacer,
  Button,
  H1,
  Body,
  colors,
  FormCard,
  H3s,
  Line,
  Row,
  SmallBody,
  MeroHeader,
  styles,
  useToast,
  ModalOverlay,
  SafeAreaView,
  Icon,
  HSpacer,
  LogoIcon,
} from '@mero/components';
import * as Clipboard from 'expo-clipboard';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity, Image, Share, Modal, Pressable, View, Linking } from 'react-native';

import { FlashyLabel } from '../../../../../components/FlashyLabel';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { MenuStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

type Props = StackScreenProps<MenuStackParamList, 'SharePageProfileScreen'>;

const SHARE_QUOTE = `Niciodată nu a fost mai simplu să îţi faci o programare!
Intră acum pe profilul meu de MERO și convinge-te!`;
const SHARE_HASHTAG = '#MERO';

const SharePageProfileScreen: React.FC<Props> = () => {
  const { t } = useTranslation('location');
  const goBack = useGoBack();
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [base64String, setBase64String] = React.useState<string>();

  const [showInstructionsModal, setShowInstructionsModal] = React.useState(false);
  const qrCode = React.useMemo(() => `data:image/png;base64,${base64String}`, [base64String]);
  const downloadedFileName = React.useMemo(() => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    return `QR-${pageState.page.details.slug}.png`;
  }, [pageState]);

  React.useEffect(() => {
    const generateQrCode = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }
      try {
        const base64String = await meroApi.pages.generateMarketplaceShareQr({
          pageId: pageState.page.details._id,
          applyLogo: true,
        });

        setBase64String(base64String);
      } catch (e) {
        log.error('Failed to generate the page QR code', e);
      }
    };
    generateQrCode();
  }, [pageState.type]);

  const shareQrPNGFile = async () => {
    if (!base64String || !downloadedFileName) return;

    try {
      if (Platform.OS === 'web' && navigator.share) {
        const file = new File([qrCode], downloadedFileName, { type: 'image/png' });
        await navigator.share({
          files: [file],
        });
      } else {
        try {
          const downloadUri = `${FileSystem.documentDirectory}${downloadedFileName}`;
          await FileSystem.writeAsStringAsync(downloadUri, base64String, {
            encoding: FileSystem.EncodingType.Base64,
          });

          const fileInfo = await FileSystem.getInfoAsync(downloadUri);
          if (fileInfo.exists) {
            log.info(`PNG file was saved successfully at: ${downloadUri}`);
            await Sharing.shareAsync(fileInfo.uri, {
              mimeType: 'image/png',
            });
          } else {
            throw new Error();
          }
        } catch (e) {
          toast.show({
            text: t('shareQRFail'),
            type: 'error',
          });
        }
      }
    } catch (e) {
      log.error('Failure while sharing QR', e);
    }
  };

  const downloadQrPNGFile = async () => {
    if (!base64String || !downloadedFileName) return;

    try {
      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = qrCode;
        link.download = downloadedFileName;
        link.click();
        toast.show({
          text: t('downloadQRSuccess'),
          type: 'success',
        });
      }

      if (Platform.OS !== 'web') {
        const { status: existingStatus } = await MediaLibrary.getPermissionsAsync();
        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
          const { status } = await MediaLibrary.requestPermissionsAsync(true);
          finalStatus = status;
        }

        if (finalStatus !== 'granted') {
          log.debug('Media library access permission not granted');
          setShowInstructionsModal(true);
          return;
        }

        log.debug('Media library access permission granted');

        const downloadUri = `${FileSystem.documentDirectory}${downloadedFileName}`;
        await FileSystem.writeAsStringAsync(downloadUri, base64String, {
          encoding: FileSystem.EncodingType.Base64,
        });

        const fileInfo = await FileSystem.getInfoAsync(downloadUri);
        if (fileInfo.exists) {
          await MediaLibrary.createAssetAsync(fileInfo.uri);

          toast.show({
            text: t('downloadQRToGallerySuccess'),
            type: 'success',
          });
          log.info(`PNG file was saved successfully in the gallery`);
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      log.error('Error while downloading QR', e);
      toast.show({
        text: t('downloadQRFailed'),
        type: 'error',
      });
    }
  };

  const copyToClipboard = (value: string) => async () => {
    if (value) {
      await Clipboard.setStringAsync(value);
      toast.show({
        type: 'success',
        text: t('copiedLink'),
      });
    }
  };

  if (pageState.type !== 'Loaded') {
    return null;
  }

  const pageUrl = `${config.marketPlaceUrl}/p/${pageState.page.details.slug}`;

  const share = (value: string) => async () => {
    if (Platform.OS === 'web' && !navigator.share) {
      const pageUrl = encodeURIComponent(value);
      const shareQuote = encodeURIComponent(SHARE_QUOTE);
      const shareTag = encodeURIComponent(SHARE_HASHTAG);
      const shareUrl = `https://www.facebook.com/dialog/share?app_id=${config.facebookAppId}&display=popup&href=${pageUrl}&quote=${shareQuote}&hashtag=${shareTag}`;
      window.open(shareUrl, '', 'height=300,width=600');
    } else {
      try {
        await Share.share({
          message: value,
        });
      } catch {}
    }
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t('shareProfile')} />
        <ScrollView style={{ paddingTop: 16, flex: 1 }}>
          <H1 style={{ paddingHorizontal: 16 }}>{t('shareProfile')}</H1>

          <Spacer />
          {qrCode && (
            <FormCard rounded paddings="none" dropShaddow style={{ marginHorizontal: 16, paddingVertical: 16 }}>
              <H3s style={{ paddingHorizontal: 16 }}>{t('meroQrCode')}</H3s>
              <Spacer size={16} />
              <Line />
              <Row style={{ flex: 1, justifyContent: 'center', paddingVertical: 8 }}>
                <Image source={{ uri: qrCode }} resizeMode="contain" style={{ width: 220, height: 220 }} />
              </Row>
              <Line />
              <Row style={{ paddingHorizontal: 16, paddingTop: 16 }}>
                {Platform.OS !== 'web' && (
                  <Column style={{ flex: 2, paddingRight: 6 }}>
                    <Button
                      text={t('share')}
                      size="small"
                      backgroundColor="#F2F2FE"
                      color={colors.DARK_BLUE}
                      onPress={shareQrPNGFile}
                    />
                  </Column>
                )}
                <Column style={{ flex: 2, paddingLeft: 6 }}>
                  <Button text={t('download')} size="small" onPress={downloadQrPNGFile} />
                </Column>
              </Row>
            </FormCard>
          )}
          <>
            <Spacer size={24} />
            <FormCard rounded paddings="none" dropShaddow style={{ marginHorizontal: 16 }}>
              <Row style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
                <H3s>{t('linkTitle')}</H3s>
                {pageState.page.details.isSearchable ? (
                  <FlashyLabel text={t('searchable')} type="success" />
                ) : (
                  <FlashyLabel text={t('nonSearchable')} type="custom" color="#FBAC40" backgroundColor="#FFF7EB" />
                )}
              </Row>
              <Line />

              <Column style={{ padding: 16 }}>
                <Spacer size={4} />
                <SmallBody>{t('linkDescription')}</SmallBody>
                <Spacer size={12} />
                <TouchableOpacity
                  style={{ alignItems: 'center', flexDirection: 'row' }}
                  onPress={copyToClipboard(pageUrl)}
                >
                  <Body style={styles.text.semibold}>{pageUrl.replace(/^http(s)?\:\/\//, '')}</Body>
                </TouchableOpacity>
              </Column>
              <Line />

              <Row style={{ padding: 16 }}>
                <Column style={{ flex: 2, paddingRight: 6 }}>
                  <Button
                    text={t('share')}
                    size="small"
                    onPress={share(pageUrl)}
                    backgroundColor="#F2F2FE"
                    color={colors.DARK_BLUE}
                  />
                </Column>
                <Column style={{ flex: 2, paddingLeft: 6 }}>
                  <Button text={t('copyToClipboard')} size="small" onPress={copyToClipboard(pageUrl)} />
                </Column>
              </Row>
            </FormCard>
          </>
          <Spacer size={48} />
        </ScrollView>
      </ModalScreenContainer>

      {showInstructionsModal === true && (
        <Modal animationType="none" transparent={true} visible={true}>
          <ModalOverlay>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Column
                style={[
                  {
                    width: 345,
                    paddingHorizontal: 24,
                    paddingBottom: 24,
                    paddingTop: 20,
                    backgroundColor: 'white',
                    borderRadius: 12,
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <TouchableOpacity onPress={() => setShowInstructionsModal(false)} style={{ marginLeft: 'auto' }}>
                  <Icon type="close" />
                </TouchableOpacity>
                <H1>{t('allowAccessToGallery')}</H1>
                <Spacer size={24} />
                <Row>
                  <Body>{t('open')}</Body>
                  <Body style={[styles.text.semibold]}>{t('settings')}</Body>
                  <HSpacer left={12} />
                  <Icon type={'android-settings'} />
                </Row>
                <Spacer size={16} />

                <Row>
                  <Body>{t('clickOn')}</Body>
                  <Body style={[styles.text.semibold]}>{t('apps')}</Body>
                  <HSpacer left={12} />
                  <Icon type="android-apps" />
                </Row>
                <Spacer size={16} />

                <Row>
                  <Body>{t('clickOn')}</Body>
                  <Body style={[styles.text.semibold]}>{t('meroPro')}</Body>
                  <HSpacer left={12} />
                  <LogoIcon size={24} />
                </Row>
                <Spacer size={16} />

                <Row>
                  <Body>{t('clickOn')}</Body>
                  <Body style={[styles.text.semibold]}>{t('mediaLibrary')}</Body>
                  <HSpacer left={12} />
                </Row>
                <Spacer size={16} />

                <Row>
                  <Body>{t('clickOn')}</Body>
                  <Body style={[styles.text.semibold]}>{t('fullAccess')}</Body>
                  <HSpacer left={12} />
                  <Icon type="android-slider" />
                </Row>

                <Spacer size={32} />
                <Button
                  text={t('openSettings')}
                  onPress={() => {
                    Linking.openSettings();
                    setShowInstructionsModal(false);
                  }}
                />
              </Column>
            </View>
          </ModalOverlay>
        </Modal>
      )}
    </>
  );
};

export default SharePageProfileScreen;

import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function SendIcon() {
  return (
    <Svg width={32} height={32} viewBox="-18 489.95 32 32">
      <G data-name="Group 8004">
        <Path d="M-18 505.9a16 16 0 110 .1z" fill="#f2f2fe" fillRule="evenodd" data-name="Ellipse 693" />
        <G data-name="Group 7729">
          <G
            data-name="Orion_paper-plane"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="#080de0"
            fill="transparent"
          >
            <Path d="M-3.138 507.365v4.78l2.345-3.323" data-name="layer2" />
            <Path
              d="M-8.374 505.317l12.748-5.464-2.049 10.927-5.463-3.415-5.236-2.048zm12.748-5.464l-7.512 7.512"
              data-name="layer1"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SendIcon;

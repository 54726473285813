import { useToast } from '@mero/components';
import * as Notifications from 'expo-notifications';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import OnlineLinkScreen from '../screens/Authorized/CalendarScreen/OnlineLinkScreen';
import ClientEditScreen from '../screens/Authorized/ClientEditScreen';
import ClientSalesReportScreen from '../screens/Authorized/ClientSalesReportScreen';
import CreateLocationScreen from '../screens/Authorized/CreateLocationScreen';
import CustomWebview from '../screens/Authorized/CustomWebview';
import ImportContactsScreen from '../screens/Authorized/ImportContactsScreen';
import ManualSmsReminderScreen from '../screens/Authorized/ManualSmsReminderScreen';
import CashRegistryCompanyMenuScreen from '../screens/Authorized/MenuScreen/screens/CashRegistryScreen/CashRegistryCompanyMenuScreen';
import CashRegistryOptionsMenuScreen from '../screens/Authorized/MenuScreen/screens/CashRegistryScreen/CashRegistryOptionsMenuScreen';
import InitialBalanceScreen from '../screens/Authorized/MenuScreen/screens/CashRegistryScreen/InitialBalanceScreen';
import TransactionScreen from '../screens/Authorized/MenuScreen/screens/CashRegistryScreen/TransactionScreen';
import MonthlyReportsScreen from '../screens/Authorized/MonthlyReports';
import NewClientScreen from '../screens/Authorized/NewClientScreen';
import NoAccessScreen from '../screens/Authorized/NoAccessScreen';
import NotificationsOptionsScreen from '../screens/Authorized/NotificationsScreen/NativeNotificationsScreen/NotificationsOptionsScreen';
import PendingListScreen from '../screens/Authorized/PendingListScreen';
import DeleteBrandScreen from '../screens/Authorized/ProductsScreen/DeleteBrandScreen';
import DeleteCategoryScreen from '../screens/Authorized/ProductsScreen/DeleteCategoryScreen';
import DeleteProductScreen from '../screens/Authorized/ProductsScreen/DeleteProductScreen';
import ImportProductsScreen from '../screens/Authorized/ProductsScreen/ImportProductsScreen';
import ProductBrandScreen from '../screens/Authorized/ProductsScreen/ProductBrandScreen';
import ProductCategoryScreen from '../screens/Authorized/ProductsScreen/ProductCategoryScreen';
import SelectAccountScreen from '../screens/Authorized/SelectAccountScreen';
import PageSortingServicesScreen from '../screens/Authorized/ServicesScreen/PageSortingServicesScreen';
import SelectProScreen from '../screens/Authorized/ServicesScreen/SelectProScreen';
import ServiceScreen from '../screens/Authorized/ServicesScreen/ServiceScreen';
import ServiceSettingsScreen from '../screens/Authorized/ServicesScreen/ServiceSettings';
import ServicesBulkOperationsScreen from '../screens/Authorized/ServicesScreen/ServicesBulkOperationsScreen';
import ChatRedirectScreen from '../screens/ChatRedirectScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBookingClientSelectContextProvider } from '../contexts/BookingClientSelectContext';
import { withCalendarContextProvider } from '../contexts/CalendarContext';
import { withCashRegistryContextProvider } from '../contexts/CashRegistryContext';
import { CheckoutsContext, withCheckoutsContextProvider } from '../contexts/CheckoutsContext';
import { withCurrentBusinessContextProvider } from '../contexts/CurrentBusiness';
import { withIntercomContextProvider } from '../contexts/IntercomContext';
import { withOnlinePaymentsContextProvider } from '../contexts/OnlinePaymentsContext';
import { withProductsContextProvider } from '../contexts/ProductsContext';
import { withSearchProductsContextProvider } from '../contexts/ProductsSearchContext';
import { withServicesContextProvider } from '../contexts/ServicesContext';
import { withUserNotificationsCountContextProvider } from '../contexts/UserNotificationsCountContext';
import { AuthorizedStackParamList } from '../types';
import log from '../utils/log';
import BookingStackNavigator from './BookingStackNavigator';
import ChangePhoneStack from './ChangePhoneStackNavigator';
import ClientDetailsStackNavigator from './ClientDetailsStackNavigator';
import { CombineCheckoutStackNavigator } from './CombineCheckoutStackNavigator';
import HomeDrawerNavigator from './HomeDrawerNavigator';
import MenuStackNavigator from './MenuStackNavigator';
import OnboardingStackNavigator from './OnboardingStackNavigator';
import ProductEditStackNavigator from './ProductEditStackNavigator';
import ProsEditStackNavigator from './ProsEditStackNavigator';
import { styles } from './styles';
import { ShortModalFadeTransition } from './utils';

const AuthorizedStack = createStackNavigator<AuthorizedStackParamList>();

const AuthorizedStackNavigatorComponent: React.FC = () => {
  const toast = useToast();
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation();

  const [checkoutState] = CheckoutsContext.useContext();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  // Listen for push notifications when app is in foreground and show notifications
  React.useEffect(() => {
    const subscription = Notifications.addNotificationReceivedListener((notification) => {
      log.debug('Got push notification in foreground', notification);
      const { body } = notification.request.content;

      if (body) {
        toast.show({
          type: 'info',
          text: body,
        });
      }
    });

    return () => {
      try {
        Notifications.removeNotificationSubscription(subscription);
      } catch {
        // pass
      }
    };
  }, [toast]);
  console.log(isDesktop);

  return (
    <AuthorizedStack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false, cardOverlayEnabled: true, presentation: 'transparentModal' }}
    >
      <AuthorizedStack.Group>
        <AuthorizedStack.Screen name="Home" component={HomeDrawerNavigator} options={{ title: 'Home' }} />
      </AuthorizedStack.Group>

      <AuthorizedStack.Group
        screenOptions={{
          ...(isPhone ? TransitionPresets.ModalTransition : TransitionPresets.ModalFadeTransition),
        }}
      >
        <AuthorizedStack.Screen name="Booking" component={BookingStackNavigator} options={{ title: 'Booking' }} />
        <AuthorizedStack.Screen
          name="Onboarding"
          component={OnboardingStackNavigator}
          options={{ title: t('login:onboarding') }}
        />
        <AuthorizedStack.Screen name="NoAccess" component={NoAccessScreen} options={{ title: t('pros:noAccess') }} />
      </AuthorizedStack.Group>
      <AuthorizedStack.Group
        screenOptions={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
        }}
      >
        <AuthorizedStack.Screen
          name="Menu"
          component={MenuStackNavigator}
          options={{ title: 'Meniu', cardStyle: modalStyle }}
        />
        <AuthorizedStack.Screen
          name="ClientDetails"
          component={ClientDetailsStackNavigator}
          options={{
            title: t('clients:clientDetails'),
            cardStyle: isDesktop ? styles.desktopModalCardLarge : modalStyle,
          }}
        />
        <AuthorizedStack.Screen
          name="NotificationsOptionsScreen"
          component={NotificationsOptionsScreen}
          options={{ title: t('notificationOptions:title'), cardStyle: modalStyle }}
        />
        <AuthorizedStack.Screen name="ClientSalesReportScreen" component={ClientSalesReportScreen} />
        <AuthorizedStack.Screen
          name="ClientEditScreen"
          component={ClientEditScreen}
          options={{ title: t('clients:changeClientDetails'), cardStyle: modalStyle }}
        />
      </AuthorizedStack.Group>
      <AuthorizedStack.Group
        screenOptions={{
          cardStyle: modalStyle,
          ...(isPhone ? TransitionPresets.ModalTransition : TransitionPresets.ModalFadeTransition),
        }}
      >
        <AuthorizedStack.Screen
          name="NewClientScreen"
          component={NewClientScreen}
          options={{
            ...ShortModalFadeTransition,
            cardStyle: modalStyle,
            title: t('clients:addClient'),
          }}
        />
        <AuthorizedStack.Screen
          name="ImportContactsScreen"
          component={ImportContactsScreen}
          options={{ title: t('clients:importContacts') }}
        />
        <AuthorizedStack.Screen
          name="TransactionScreen"
          component={TransactionScreen}
          options={{ title: t('cashRegistry:title') }}
        />

        <AuthorizedStack.Screen
          name="ProductEdit"
          component={ProductEditStackNavigator}
          options={{
            title: t('products:productsTitle'),
            ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          }}
        />

        <AuthorizedStack.Screen
          name="ImportProductsScreen"
          component={ImportProductsScreen}
          options={{
            title: t('products:importProducts'),
          }}
        />

        <AuthorizedStack.Screen
          name="ProductCategoryScreen"
          component={ProductCategoryScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            presentation: 'transparentModal',
            cardOverlayEnabled: true,
            title: t('products:productCategory'),
          }}
        />

        <AuthorizedStack.Screen
          name="ProductBrandScreen"
          component={ProductBrandScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            presentation: 'transparentModal',
            cardOverlayEnabled: true,
            title: t('products:productBrand'),
          }}
        />

        <AuthorizedStack.Screen
          name="DeleteCategoryScreen"
          component={DeleteCategoryScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            presentation: 'transparentModal',
            cardOverlayEnabled: true,
            title: t('products:deleteCategory'),
          }}
        />

        <AuthorizedStack.Screen
          name="DeleteProductScreen"
          component={DeleteProductScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            presentation: 'transparentModal',
            cardOverlayEnabled: true,
            title: t('products:deleteProduct'),
          }}
        />

        <AuthorizedStack.Screen
          name="DeleteBrandScreen"
          component={DeleteBrandScreen}
          options={{
            ...TransitionPresets.ModalFadeTransition,
            presentation: 'transparentModal',
            cardOverlayEnabled: true,
            title: t('products:deleteBrand'),
          }}
        />
      </AuthorizedStack.Group>
      {(checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled) ||
      (checkoutState.type === 'LoadedRestricted' && checkoutState.restrictedPageSettings.checkoutEnabled) ? (
        <AuthorizedStack.Group>
          <AuthorizedStack.Screen name="CombineCheckout" component={CombineCheckoutStackNavigator} />
        </AuthorizedStack.Group>
      ) : null}
      <AuthorizedStack.Screen name={'ChangePhone'} component={ChangePhoneStack} />
      <AuthorizedStack.Screen
        name="SelectAccount"
        component={SelectAccountScreen}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
          title: t('settings:selectAccount'),
        }}
      />
      <AuthorizedStack.Screen
        name="CashRegistryCompanyMenu"
        component={CashRegistryCompanyMenuScreen}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
          title: t('cashRegistry:chooseCashRegister'),
        }}
      />

      <AuthorizedStack.Screen
        name="CashRegistryOptionsMenu"
        component={CashRegistryOptionsMenuScreen}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
          title: t('cashRegistry:options'),
        }}
      />

      <AuthorizedStack.Screen
        name="InitialBalance"
        component={InitialBalanceScreen}
        options={{
          ...TransitionPresets.ModalFadeTransition,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
          title: t('cashRegistry:options'),
        }}
      />
      <AuthorizedStack.Screen
        name="SortServices"
        component={PageSortingServicesScreen}
        options={{
          title: t('services:services'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="Service"
        component={ServiceScreen}
        options={{
          title: t('services:services'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="SelectPro"
        component={SelectProScreen}
        options={{
          title: t('services:services'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="Settings"
        component={ServiceSettingsScreen}
        options={{
          title: t('services:services'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="ServicesBulkOperations"
        component={ServicesBulkOperationsScreen}
        options={{
          title: t('services:services'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />

      <AuthorizedStack.Screen
        name="ProsEdit"
        component={ProsEditStackNavigator}
        options={{
          title: t('products:productsTitle'),
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
        }}
      />

      <AuthorizedStack.Screen
        name="CreateLocation"
        component={CreateLocationScreen}
        options={{
          title: t('location:addLocation'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="OnlineLink"
        component={OnlineLinkScreen}
        options={{
          title: t('calendar:onlineLink'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />

      <AuthorizedStack.Screen
        name="ManualSmsReminder"
        component={ManualSmsReminderScreen}
        options={{
          title: t('notifications:messageReminder'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="PendingList"
        component={PendingListScreen}
        options={{
          title: t('notifications:messageReminder'),
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="MonthlyReports"
        component={MonthlyReportsScreen}
        options={{
          ...(isPhone ? TransitionPresets.ModalTransition : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen
        name="CustomWebview"
        component={CustomWebview}
        options={{
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          cardStyle: modalStyle,
        }}
      />
      <AuthorizedStack.Screen name="ChatRedirectScreen" component={ChatRedirectScreen} />
    </AuthorizedStack.Navigator>
  );
};

export default pipe(
  AuthorizedStackNavigatorComponent,
  withOnlinePaymentsContextProvider,
  withCashRegistryContextProvider,
  withSearchProductsContextProvider(),
  withProductsContextProvider,
  withCheckoutsContextProvider,
  withBookingClientSelectContextProvider,
  withCalendarContextProvider,
  withIntercomContextProvider,
  withCurrentBusinessContextProvider,
  withUserNotificationsCountContextProvider,
  withServicesContextProvider,
);

import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import CardPaymentScreen from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/CardPaymentScreen';
import PageBillingCycleList from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/PageBillingCycleList';
import PageBillingDetailsList from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/PageBillingDetailsList';
import PageBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/PageBillingDetailsScreen';
import PageSubscriptionSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/PageSubscriptionSettingsScreen';
import SelectedPaymentMethodScreen from '../screens/Authorized/MenuScreen/screens/PageSubscriptionSettingsScreen/SelectPaymentMethodScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../contexts/CheckoutSettingsContext';
import { withSubscriptionContextProvider } from '../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<SubscriptionStackParamList>();

function SubscriptionStackNavigator(): React.ReactElement {
  const { t } = useTranslation('subscription');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="Subscription"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="Subscription"
        component={PageSubscriptionSettingsScreen}
        options={{ title: t('subscriptionAndBilling'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetailsList"
        component={PageBillingDetailsList}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingCycleList"
        component={PageBillingCycleList}
        options={{ title: t('billingCycle'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetails"
        component={PageBillingDetailsScreen}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PaymentMethod"
        component={SelectedPaymentMethodScreen}
        options={{ title: t('selectPaymentMethod'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name={'CardPayment'}
        component={CardPaymentScreen}
        options={{ title: t('cardPayment'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default pipe(SubscriptionStackNavigator, withSubscriptionContextProvider, withBillingDetailsContextProvider());

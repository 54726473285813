import { Email } from '@mero/api-sdk';
import { FirstnameInput, Lastname, OptionalLastname } from '@mero/api-sdk/dist/common';
import { colors, SelectButton, styles as meroStyles } from '@mero/components';
import { StrictPhoneNumberParsed, StrictPhoneNumber } from '@mero/shared-sdk/dist/common';
import { flow } from 'fp-ts/function';
import * as Ap from 'fp-ts/lib/Apply';
import * as E from 'fp-ts/lib/Either';
import * as ioTs from 'io-ts';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, ScrollView, Platform } from 'react-native';

import AvoidKeyboard from '@mero/components/lib/components/AvoidKeyboard';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Spacer from '@mero/components/lib/components/Spacer';
import H1 from '@mero/components/lib/components/Text/H1';
import SmallBody from '@mero/components/lib/components/Text/SmallBody';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { OptionalRemark, ClientDetails, DateCodec } from '../../contexts/ClientCreateContext';
import log from '../../utils/log';
import ModalScreenContainer from '../ModalScreenContainer';
import SelectDateTimeModal from '../SelectDateTimeModal';
import { styles } from './styles';

export const ClientDetailsOut = ioTs.intersection(
  [
    ioTs.type({
      phoneNumber: StrictPhoneNumber,
      firstname: FirstnameInput,
      showRemarkOnCalendar: ioTs.boolean,
    }),
    ioTs.partial({
      lastname: Lastname,
      remark: OptionalRemark,
      email: Email.JSON,
      birthday: DateCodec,
    }),
  ],
  'ClientDetails',
);

export type ClientDetailsOut = ioTs.TypeOf<typeof ClientDetailsOut>;

export type Props = {
  /**
   * Partial client details to prefill the form
   * Using output type to accept invalid data
   */
  readonly clientDetails?: Partial<ioTs.OutputOf<typeof ClientDetails>>;
  /**
   * View mode, Add new client vs Edit existing client
   */
  readonly mode: 'add' | 'edit';
  /**
   * Page close button pressed
   */
  readonly onClosePressed?: () => void;
  /**
   * Page back button pressed
   */
  readonly onBackPressed?: () => void;

  readonly onSubmit?: (clientDetails: ClientDetailsOut) => void;
};

const AddClientScreen: React.FC<Props> = ({ clientDetails, mode, onBackPressed, onClosePressed, onSubmit }: Props) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('clients');

  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(false);

  /**
   * Client first name field
   */
  const [firstname, setFirstname] = React.useState({
    input: clientDetails?.firstname ?? '',
    decoded: FirstnameInput.decode(clientDetails?.firstname ?? ''),
  });
  const firstnameValid = E.isRight(firstname.decoded);

  /**
   * Client last name field
   */
  const [lastname, setLastname] = React.useState({
    input: clientDetails?.lastname ?? '',
    decoded: OptionalLastname.decode(clientDetails?.lastname ?? ''),
  });
  const lastnameValid = E.isRight(lastname.decoded);

  /**
   * Client phone number
   */
  const [phoneNumber, setPhoneNumber] = React.useState({
    input: clientDetails?.phoneNumber ?? '',
    decoded: StrictPhoneNumberParsed.decode(clientDetails?.phoneNumber ?? ''),
  });
  const phoneNumberValid = E.isRight(phoneNumber.decoded);

  /**
   * Client notes
   */
  const [remark, setNotes] = React.useState({
    input: clientDetails?.remark ?? '',
    decoded: OptionalRemark.decode(clientDetails?.remark ?? ''),
  });

  const [email, setEmail] = React.useState({
    input: clientDetails?.email,
    decoded: ioTs.union([ioTs.undefined, Email.JSON]).decode(clientDetails?.email),
  });
  const emailValid = E.isRight(email.decoded);

  const [birthday, setBirthday] = React.useState(clientDetails?.birthday);
  const [showSelectDate, setShowSelectDate] = React.useState(false);

  const now = React.useMemo(() => DateTime.now().toJSDate(), []);
  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
  const formattedBirthday = React.useMemo(
    () => (birthday ? DateTime.fromJSDate(birthday).setLocale('ro').toFormat('dd.LL.yyyy') : t('birthdayPlaceholder')),
    [birthday],
  );

  /**
   * Settings flag: show remark on calendar/bookings
   */
  const [showRemarkOnCalendar, setShowRemarkOnCalendar] = React.useState(clientDetails?.showRemarkOnCalendar ?? false);

  // Hide submit button on Android only
  const showSubmitButton = !(isKeyboardOpen && Platform.OS === 'android');

  const submitCallback = React.useCallback(() => {
    setShowErrors(true);

    const outDetails = Ap.sequenceS(E.either)({
      phoneNumber: phoneNumber.decoded,
      firstname: firstname.decoded,
      lastname: lastname.decoded,
      remark: remark.decoded,
      email: email.input ? email.decoded : ioTs.undefined.decode(undefined),
      showRemarkOnCalendar: E.right(showRemarkOnCalendar),
    });

    if (E.isRight(outDetails) && onSubmit !== undefined) {
      onSubmit({
        ...outDetails.right,
        birthday,
      });
    }
  }, [
    firstname.decoded,
    lastname.decoded,
    phoneNumber.decoded,
    remark.decoded,
    email.decoded,
    birthday,
    showRemarkOnCalendar,
    onSubmit,
  ]);

  const toggleShowRemarksOnCalendar = React.useCallback(() => {
    setShowRemarkOnCalendar(!showRemarkOnCalendar);
  }, [showRemarkOnCalendar, setShowRemarkOnCalendar]);

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const scrollRef = React.useRef<ScrollView>(null);

  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  const scrollTo = (y: number): void => {
    setScrollToY(y);
  };

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const pageTitle: string = React.useMemo(() => {
    switch (mode) {
      case 'add': {
        return t('addClient');
      }
      case 'edit': {
        return t('changeClientDetails');
      }
    }
  }, [mode]);

  const submitButtomText: string = React.useMemo(() => {
    switch (mode) {
      case 'add': {
        return t('addClient');
      }
      case 'edit': {
        return t('saveChanges');
      }
    }
  }, [mode]);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader
        title={pageTitle}
        canGoBack={onBackPressed !== undefined}
        onBack={onBackPressed}
        canClose={onClosePressed !== undefined}
        onClose={onClosePressed}
      />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          ref={scrollRef}
          contentContainerStyle={styles.formMargin}
          onScrollEndDrag={dismissKeyboardCallback}
          scrollIndicatorInsets={{ right: 1 }} // This is here to fix scroll bar in the middle: https://github.com/facebook/react-native/issues/26610
        >
          <View style={styles.hrPadding}>
            <Spacer size="16" />
            <H1 style={meroStyles.text.alignCenter}>{pageTitle}</H1>
          </View>
          <Spacer size="32" />
          <FormCard dropShaddow rounded paddings="inputs">
            <InputWithLabel
              label={t('mobilePhone')}
              isError={showErrors && !phoneNumberValid}
              errorText={t('mobilePhoneError')}
            >
              <TypeSafeTextInput
                codec={StrictPhoneNumberParsed}
                value={phoneNumber.input}
                showError={showErrors}
                onChange={setPhoneNumber}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
                placeholder={t('mobilePhonePlaceholder')}
                onFocus={() => scrollTo(100)}
                editable={mode === 'add'}
              />
            </InputWithLabel>
            <Spacer size="6" />
            <SmallBody style={{ color: colors.COMET }}>{t('mobilePhoneInfo')}</SmallBody>
            <Spacer size="16" />
            <InputWithLabel
              label={t('firstName')}
              isError={showErrors && !firstnameValid}
              errorText={t('firstNameError')}
            >
              <TypeSafeTextInput
                codec={FirstnameInput}
                value={firstname.input}
                onChange={setFirstname}
                textContentType="givenName"
                placeholder={t('firstNamePlaceholder')}
                onFocus={() => scrollTo(220)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('lastName')} isError={showErrors && !lastnameValid} errorText={t('lastNameError')}>
              <TypeSafeTextInput
                codec={OptionalLastname}
                value={lastname.input}
                onChange={setLastname}
                textContentType="familyName"
                placeholder={t('lastNamePlaceholder')}
                onFocus={() => scrollTo(300)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label={t('email')}
              isError={showErrors && !emailValid && !!email.input}
              errorText={t('emailError')}
            >
              <TypeSafeTextInput
                codec={Email.JSON}
                value={email.input}
                onChange={setEmail}
                textContentType="emailAddress"
                placeholder={t('emailPlaceholder')}
                keyboardType="email-address"
                onFocus={() => scrollTo(400)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('birthday')}>
              <SelectButton
                text={formattedBirthday}
                onPress={() => {
                  setShowSelectDate(true);
                }}
              />
            </InputWithLabel>
            {/*<Spacer size="16" />*/}
            {/*<InputWithLabel label={t('observations')}>*/}
            {/*  <TypeSafeTextInput*/}
            {/*    codec={OptionalRemark}*/}
            {/*    value={remark.input}*/}
            {/*    onChange={setNotes}*/}
            {/*    placeholder={t('observationsPlaceholder')}*/}
            {/*    multiline*/}
            {/*    numberOfLines={3}*/}
            {/*    onFocus={() => scrollTo(411)}*/}
            {/*  />*/}
            {/*</InputWithLabel>*/}
            {/* <Spacer size="16" />
            <TouchableOpacity onPress={toggleShowRemarksOnCalendar}>
              <ListItem
                LeftComponent={() => (
                  <Icon
                    type={showRemarkOnCalendar ? 'checked' : 'unchecked'}
                    disabled
                    size={24}
                    color={colors.DARK_BLUE}
                  />
                )}
                leftContainerStyle={{ justifyContent: 'flex-start', width: 24 }}
                centerContainerStyle={{ justifyContent: 'flex-start', marginLeft: 12 }}
              >
                <Body>Afișează observațiile în programările acestui client</Body>
              </ListItem>
            </TouchableOpacity> */}
          </FormCard>
          <Spacer size={144} />
        </ScrollView>
      </AvoidKeyboard>
      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      {showSubmitButton ? (
        <FormCard
          dropShaddow
          paddings="button"
          style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button text={submitButtomText} onClick={submitCallback} />
          </SafeAreaView>
        </FormCard>
      ) : null}
      {showSelectDate && (
        <View style={{ flex: 1, position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
          <SelectDateTimeModal
            mode="date"
            withYear
            selected={birthday}
            maxDate={now}
            onDateSelected={flow(setBirthday, () => setShowSelectDate(false))}
            onClosePress={() => {
              setShowSelectDate(false);
            }}
            timeZone={timeZone}
          />
        </View>
      )}
    </ModalScreenContainer>
  );
};

export default AddClientScreen;

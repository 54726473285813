import { ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientUpdateIsWarnedState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Updating';
      readonly clientId: ClientId;
      readonly isWarned: boolean;
    }
  | {
      readonly type: 'Updated';
      readonly clientId: ClientId;
      readonly isWarned: boolean;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientUpdateIsWarnedState => ({
  type: 'Ready',
});

export const ClientUpdateIsWarnedContext = createModelContext(
  defaultState(),
  {
    trySetUpdated: (_, payload: { clientId: ClientId; isWarned: boolean }) => ({
      type: 'Updated',
      clientId: payload.clientId,
      isWarned: payload.isWarned,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Updated' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientUpdateIsWarnedState) => ClientUpdateIsWarnedState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      updateClientIsWarned: (payload: { clientId: ClientId; isWarned: boolean }) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const updateClientIsWarned = async () => {
              try {
                await meroApi.clients.updateClientIsWarned({
                  clientId: payload.clientId,
                  isWarned: payload.isWarned,
                });

                dispatch.trySetUpdated({ clientId: payload.clientId, isWarned: payload.isWarned });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            updateClientIsWarned().catch(log.exception);

            return {
              type: 'Updating',
              clientId: payload.clientId,
              isWarned: payload.isWarned,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientUpdateIsWarnedContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientUpdateIsWarnedContext.Provider>{children}</ClientUpdateIsWarnedContext.Provider>;
};

export const withClientUpdateIsWarnedContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientUpdateIsWarnedContextProvider(props: P) {
    return (
      <ClientUpdateIsWarnedContextProvider>
        <Content {...props} />
      </ClientUpdateIsWarnedContextProvider>
    );
  };
};

import { useActionSheet } from '@expo/react-native-action-sheet';
import { PhoneNumber } from '@mero/api-sdk';
import {
  AvoidKeyboard,
  Body,
  Button as MeroButton,
  colors,
  DismissKeyboard,
  H1,
  Icon,
  MeroHeader,
  SafeAreaView,
  Spacer,
  styles as meroStyles,
  TextInput as MeroTextInput,
  useShowError,
  useToast,
} from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as it from 'io-ts';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import config from '../../../../../config';
import { authApi, meroApi } from '../../../../../contexts/AuthContext';
import { ChangePhoneParamsList } from '../../../../../types';
import { jsonDecode } from '../../../OnboardingScreen/NativeOnboardingScreen';
import { styles } from './OtpScreen.styles';

const INITIAL_TIME = 60;

export type Props = StackScreenProps<ChangePhoneParamsList, 'Otp'>;

const OtpScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation('changePhone');

  const { d } = route.params;

  const { showActionSheetWithOptions } = useActionSheet();
  const showError = useShowError();
  const toast = useToast();
  const goBack = useGoBack();

  const { otpAuthId, recaptchaToken, phone } = React.useMemo(() => {
    try {
      return jsonDecode(
        d,
        it.type({
          otpAuthId: it.string,
          recaptchaToken: it.string,
          phone: PhoneNumber,
        }),
      );
    } catch (e) {
      goBack();
      return { otpAuthId: '', recaptchaToken: '', phone: '' as PhoneNumber };
    }
  }, [d]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [timer, setTimer] = React.useState(INITIAL_TIME);
  const [errMsg, setErrMsg] = React.useState('');
  const [authId, setAuthId] = React.useState<string>(otpAuthId);

  const onBackPressed = React.useCallback(() => navigation.goBack(), [navigation]);

  const resendVerificationCode = async () => {
    try {
      const res = await authApi.authentications().createSmsOtpCodeRequest({
        userPhone: phone,
        recaptchaSiteKey: config.google.recaptchaSiteKey,
        recaptchaToken,
        recaptchaAction: '',
      });

      setAuthId(res.auth._id);
    } catch (error) {
      showError(error, 'Nu am putut retrimite codul de verificare');
    }
  };

  const validateVerificationCode = async () => {
    setIsLoading(true);
    try {
      const token = await authApi.authentications().confirmSmsOtp({ _id: authId, smsOtp: otpCode });
      await meroApi.users.updatePhoneNumber({ token });

      navigation.popToTop();
      goBack();
      toast.show({
        text: t('successPhoneUpdated'),
        type: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      showError(error, t('errorInvalidOtp'));
    } finally {
      setIsLoading(false);
    }
  };

  const startInterval = () => {
    setTimer(INITIAL_TIME);
    return window.setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
  };

  React.useEffect(() => {
    const interval = startInterval();

    return () => {
      window.clearInterval(interval);
    };
  }, [authId]);

  React.useEffect(() => {
    if (otpCode.length === 6 && /^\d{6,6}$/gi.test(otpCode)) {
      validateVerificationCode();
    }
  }, [otpCode]);

  return (
    <SafeAreaView edges={['left', 'top', 'right']} style={styles.container}>
      <MeroHeader canGoBack={true} onBack={onBackPressed} />
      <AvoidKeyboard style={styles.avoidKeyboard}>
        <DismissKeyboard style={styles.dismissKeyboard}>
          <H1 style={meroStyles.text.alignCenter}>{t('otpDescription')}</H1>
          <Spacer size={12} />
          <Body style={meroStyles.text.alignCenter}>
            <Trans
              ns="changePhone"
              t={t}
              i18nKey="otpSentTo"
              values={{
                phone,
              }}
            >
              0<Body style={meroStyles.text.semibold}>1</Body>2<Body style={meroStyles.text.semibold}>{phone}</Body>
            </Trans>
          </Body>
          <Spacer size={24} />

          <MeroTextInput
            onChange={flow(setOtpCode, () => setErrMsg(''))}
            value={otpCode}
            placeholder={t('otpPlaceholder')}
            editable={!isLoading}
            keyboardType="number-pad"
            background={colors.WHITE}
          />

          <Spacer size={12} />
          {errMsg.length > 0 && (
            <>
              <Body style={[meroStyles.text.alignCenter, { color: colors.RADICAL_RED }]}>{errMsg}</Body>
              <Spacer size={10} />
            </>
          )}

          <MeroButton
            size="large"
            text={isLoading ? t('otpButtonLoading') : t('otpButton')}
            onClick={validateVerificationCode}
            disabled={isLoading}
            RightComponent={() => <Icon type="next" color={colors.WHITE} />}
          />
          <Spacer size={24} />

          <View>
            {timer > 0 && <Body style={{ textAlign: 'center' }}>{t('otpTimer', { timer })}</Body>}
            {timer === 0 && (
              <Body
                style={[meroStyles.text.semibold, meroStyles.text.link, meroStyles.text.alignCenter]}
                onPress={async () =>
                  showActionSheetWithOptions(
                    {
                      options: [t('otpResend'), t('otpCancel')],
                      cancelButtonIndex: 1,
                    },
                    (buttonIndex) => {
                      if (buttonIndex === 0) {
                        resendVerificationCode();
                      }
                    },
                  )
                }
              >
                {t('otpNotReceived')}
              </Body>
            )}
          </View>
        </DismissKeyboard>
      </AvoidKeyboard>
    </SafeAreaView>
  );
};

export default OtpScreen;

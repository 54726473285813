import { OnlinePaymentsCommissionInfo } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  Column,
  H1,
  Row,
  Spacer,
  styles as meroStyles,
  MeroHeader,
  SafeAreaView,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dimensions, Image, ScrollView } from 'react-native';

import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { ListIcon } from './ActivateOnlinePaymentsDesktop';

type Props = {
  onActivateOnlinePayments: () => void;
  onGoBack: () => void;
  commissionInfo: OnlinePaymentsCommissionInfo;
};

const ActivateOnlinePaymentsMobile: React.FC<Props> = ({ onActivateOnlinePayments, onGoBack, commissionInfo }) => {
  const { t } = useTranslation('onlinePayments');
  const screenWidth = Dimensions.get('screen').width;

  const [, { openChat }] = IntercomContext.useContext();
  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <Column style={{ flex: 1 }}>
      <SafeAreaView edges={['top']} />
      <MeroHeader onBack={onGoBack} canGoBack={true} containerStyle={{ position: 'absolute', top: 32, zIndex: 1000 }} />
      <Image
        style={{ width: screenWidth, height: screenWidth * 1.04, position: 'absolute', top: 0, zIndex: -1 }}
        source={require('../../../../../assets/images/payments-background_mobile.png')}
      />
      <ScrollView
        style={{ paddingHorizontal: 24, flex: 1, paddingTop: 24 }}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <Column style={{ alignItems: 'center' }}>
          <Image
            style={{ width: 345, height: 257 }}
            source={require('../../../../../assets/images/payments_mobile.png')}
          />
        </Column>
        <Spacer size={14} />
        <Column alignItems="center">
          <Spacer size={4} />
          <H1 style={{ textAlign: 'center' }}>{t('activateHeader')}</H1>
          <Spacer size={24} />
        </Column>
        <Row>
          <ListIcon width={24} height={24} />
          <Body style={{ paddingLeft: 12, flex: 1 }}>
            <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments1">
              <Body style={[meroStyles.text.semibold]}>0</Body>1
            </Trans>
          </Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon width={24} height={24} />
          <Body style={{ paddingLeft: 12, flex: 1 }}>
            <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments2">
              <Body style={[, meroStyles.text.semibold]}>0</Body>1
            </Trans>
          </Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon width={24} height={24} />
          <Body style={{ paddingLeft: 12, flex: 1 }}>
            <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments3">
              <Body style={[meroStyles.text.semibold]}>0</Body>1
            </Trans>
          </Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon width={24} height={24} />
          <Body style={{ paddingLeft: 12, flex: 1 }}>
            <Trans ns={'onlinePayments'} t={t} i18nKey="activatePayments4">
              <Body style={[meroStyles.text.semibold]}>0</Body>1
            </Trans>
          </Body>
        </Row>
        <Spacer size={48} />
        <Button expand text={t('activateButton')} onClick={onActivateOnlinePayments} />
        <Spacer size={24} />
        <Body style={{ textAlign: 'center' }}>
          <Trans ns={'checkout'} t={t} i18nKey="enableCheckoutFooter">
            0
            <Body style={[{ color: colors.DARK_BLUE }, meroStyles.text.semibold]} onPress={onContactUsPressed}>
              1
            </Body>
          </Trans>
        </Body>
        <Spacer size={64} />
      </ScrollView>
    </Column>
  );
};

export default ActivateOnlinePaymentsMobile;

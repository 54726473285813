import {
  CheckoutProductSalesReportItem,
  CheckoutProductSalesReportTotals,
  CheckoutReportByProductSortType,
  DateInterval,
  ProductCategoryId,
  ScaledNumber,
} from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import FormCard from '@mero/components/lib/components/FormCard';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import Table, { Column } from './Table';

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  saleOwnerId?: UserId;
  categoryId?: ProductCategoryId | 'other';
  updateSort: (sort: { readonly by: CheckoutReportByProductSortType; readonly dir: SortDirection } | undefined) => void;
};

const Products: React.FC<Props> = ({ pageId, dateInterval, saleOwnerId, categoryId, updateSort }) => {
  const { t } = useTranslation('reports');
  const [reports, setReports] = React.useState<CheckoutProductSalesReportItem<MeroUnits.Any>[]>([]);
  const [total, setTotal] = React.useState<CheckoutProductSalesReportTotals<MeroUnits.Any>>();
  const [nextPage, setNextPage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sort, setSort] = React.useState<
    | {
        readonly by: CheckoutReportByProductSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >(undefined);
  const lastRequest = React.useRef<symbol>(Symbol());
  const loadingNextPage = React.useRef('');

  const getData = async (page?: string) => {
    setIsLoading(true);
    const request = Symbol();
    lastRequest.current = request;
    try {
      const response = await meroApi.checkoutReports.getSalesByProductReport({
        pageId,
        unit: 'RON',
        interval: dateInterval,
        saleOwnerId,
        categoryId,
        sort,
        page,
      });

      if (request === lastRequest.current) {
        setTotal(response.data.totals);
        setNextPage(response.next);
        setReports((prev) => (typeof page === 'string' ? [...prev, ...response.data.items] : response.data.items));
      }
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortBy = async (field: string, direction: SortDirection) => {
    switch (field) {
      case 'name':
        setSort({ by: 'total', dir: direction });
        break;
      case 'charged':
        setSort({ by: 'total', dir: direction });
        break;
      case 'net':
        setSort({ by: 'net', dir: direction });
        break;
      case 'protocol':
        setSort({ by: 'protocol', dir: direction });
        break;
      case 'gross':
        setSort({ by: 'gross', dir: direction });
        break;
      case 'discount':
        setSort({ by: 'discount', dir: direction });
        break;
      case 'total':
        setSort({ by: 'total', dir: direction });
        break;
      default:
        setSort(undefined);
        break;
    }
  };

  const onLoadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      loadingNextPage.current = nextPage;
      getData(nextPage);
    }
  };

  React.useEffect(() => {
    getData();
  }, [saleOwnerId, categoryId, JSON.stringify(sort), JSON.stringify(dateInterval)]);

  React.useEffect(() => {
    updateSort(sort);
  }, [sort]);

  const { columns, data, fixedRows } = React.useMemo(() => {
    const data = reports.map((report) => ({
      name: report.product.name,
      charged: report.count,
      net: `${scaledToString(report.totals.net.amount)} ${t(report.totals.net.unit)}`,
      quantity: ScaledNumber.toNumber(report.quantity),
      protocol: `${scaledToString(report.totals.protocol.amount)} ${t(report.totals.protocol.unit)}`,
      gross: `${scaledToString(report.totals.gross.amount)} ${t(report.totals.gross.unit)}`,
      discount: `${scaledToString(report.totals.discount.amount)} ${t(report.totals.discount.unit)}`,
      total: `${scaledToString(report.totals.total.amount)} ${t(report.totals.total.unit)}`,
      percentage: `${scaledToString(report.percents.total)} %`,
    }));

    const fixedRows = total
      ? [
          {
            name: t('totalRow'),
            charged: total.count,
            net: `${scaledToString(total.totals.net.amount)} ${t(total.totals.net.unit)}`,
            quantity: ScaledNumber.toNumber(total.quantity),
            gross: `${scaledToString(total.totals.gross.amount)} ${t(total.totals.gross.unit)}`,
            protocol: `${scaledToString(total.totals.protocol.amount)} ${t(total.totals.protocol.unit)}`,
            discount: `${scaledToString(total.totals.discount.amount)} ${t(total.totals.discount.unit)}`,
            total: `${scaledToString(total.totals.total.amount)} ${t(total.totals.total.unit)}`,
            percentage: `100 %`,
          },
        ]
      : [];

    const columns: Column<(typeof data)[number]>[] = [
      {
        field: 'name',
        headerName: t('productNameColumn'),
        width: '20%',
        sortable: false,
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'percentage',
        headerName: '%',
        width: '6%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'quantity',
        headerName: t('quantityColumn'),
        width: '7%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'charged',
        headerName: t('chargedColumn'),
        width: '9%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'net',
        headerName: t('netColumn'),
        width: '11%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('net', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'protocol',
        headerName: t('protocolColumn'),
        width: '11%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'gross',
        headerName: t('grossColumn'),
        width: '12%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('gross', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'discount',
        headerName: t('discountColumn'),
        width: '12%',
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'total',
        headerName: t('totalColumn'),
        width: '12%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('total', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    return {
      columns,
      data,
      fixedRows,
    };
  }, [reports, total]);

  return (
    <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, zIndex: -1 }}>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        loadMore={onLoadMore}
        fixedRows={fixedRows}
        defaultSortBy="total"
        defaultSortDir="ASC"
      />
    </FormCard>
  );
};

export default Products;

import * as React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

import NoContentView from '../NoContentView';
import HeaderView from './HeaderView';

export type ImportFailedViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly onGoBack: () => void;
  readonly onClose: () => void;
};

const ImportFailedView: React.FC<ImportFailedViewProps> = ({ style, onGoBack, onClose }: ImportFailedViewProps) => {
  return (
    <>
      <HeaderView onClose={onClose} />
      <NoContentView
        title="Erroare"
        subtitle="S-a produs o eroare la importarea contactelor"
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>({ flex: 1 }, style)}
        actions={[
          {
            type: 'primary',
            title: 'Înapoi',
            onPress: onGoBack,
          },
        ]}
      />
    </>
  );
};

export default ImportFailedView;

import { Body, colors, Icon } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  readonly placeholder?: string | null;
  readonly onPress?: () => void;
  readonly disabled?: boolean;
};

const SearchInputButton: React.FC<Props> = ({ placeholder, onPress, disabled }) => {
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        borderRadius: 42,
        borderWidth: 1,
        borderColor: colors.GEYSER,
        paddingLeft: 16,
        paddingRight: 16,
        paddingVertical: 4,
      }}
      onPress={onPress}
      disabled={disabled}
    >
      <Icon type="search" color={colors.COMET} />
      <Body style={{ color: colors.COMET, marginLeft: 12 }}>{placeholder}</Body>
    </TouchableOpacity>
  );
};

export default SearchInputButton;

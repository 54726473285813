import {
  Button,
  colors,
  DismissKeyboard,
  FormCard,
  Icon,
  Line,
  SafeAreaView,
  Spacer,
  Text,
  useKeyboardIsOpen,
} from '@mero/components';
import i18next from 'i18next';
import * as React from 'react';
import { FlatList, StyleSheet, ViewStyle, TouchableOpacity, View, Keyboard } from 'react-native';

import { BasicContact, ImportContactsState } from '../../contexts/ImportContactsContext';
import ModalScreenContainer from '../ModalScreenContainer';
import ApiUnavailableView from './ApiUnavailableView';
import HeaderView from './HeaderView';
import ImportFailedView from './ImportFailedView';
import ImportInProgressView from './ImportInProgressView';
import ImportSucceedView from './ImportSucceedView';
import ListHeaderView from './ListHeaderView';
import LoadingContactsView from './LoadingContactsView';
import PermissionDeniedView from './PermissionDeniedView';
import PermissionUndeterminedView from './PermissionUndeterminedView';
import { styles } from './styles';

const contactItemKeyExtractor = (item: [BasicContact, number]): string => `${item[0].phone}#${item[1]}`;

type ContactItemProps = {
  readonly item: [BasicContact, number];
  readonly selected: boolean;
  readonly onPress: (item: [BasicContact, number], selected: boolean) => void;
};

const itemStyle = StyleSheet.create({
  container: { flexDirection: 'row', paddingTop: 16, paddingBottom: 16, height: 78 },
  textContainer: { display: 'flex', flexDirection: 'column', marginLeft: 16 },
});

const ContactItemView: React.FC<ContactItemProps> = ({ item, selected, onPress }: ContactItemProps) => {
  const title = item[0].name || 'Fără nume';

  return (
    <TouchableOpacity
      onPress={() => {
        onPress(item, selected);
      }}
      style={itemStyle.container}
    >
      <Icon type={selected ? 'checked' : 'unchecked'} disabled={true} size={24} color={colors.DARK_BLUE} />
      <View style={itemStyle.textContainer}>
        <Text title>{title}</Text>
        <Spacer size="4" />
        <Text smallBody>{item[0].phone}</Text>
      </View>
    </TouchableOpacity>
  );
};

const ContactItem = React.memo(ContactItemView);

type ImportcontactsScreenContentViewProps = {
  readonly state: ImportContactsState;
  readonly selectAt: (index: number) => void;
  readonly deselectAt: (index: number) => void;
  readonly selectAll: () => void;
  readonly deselectAll: () => void;
  readonly search: (q: string) => void;
  readonly onAskContactsPermission?: () => void;
  readonly onImport?: () => void;
  readonly onGoBack: () => void;
  readonly onClose: () => void;
};

const ImportContactsScreenContentView: React.FC<ImportcontactsScreenContentViewProps> = ({
  state,
  selectAt,
  deselectAt,
  selectAll,
  deselectAll,
  search,
  onAskContactsPermission,
  onImport,
  onClose,
  onGoBack,
}: ImportcontactsScreenContentViewProps) => {
  switch (state.type) {
    case 'New': {
      return <LoadingContactsView onClose={onClose} />;
    }
    case 'Initializing': {
      return <LoadingContactsView onClose={onClose} />;
    }
    case 'Unavailable': {
      return (
        <ApiUnavailableView
          style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, styles.hrPadding)}
          onGoBack={onGoBack}
          onClose={onClose}
        />
      );
    }
    case 'NoPermissions': {
      switch (state.status) {
        case 'undetermined': {
          return (
            <PermissionUndeterminedView
              style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, styles.hrPadding)}
              onAskPermissions={onAskContactsPermission}
              onClose={onClose}
            />
          );
        }
        case 'denied': {
          return (
            <PermissionDeniedView
              style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, styles.hrPadding)}
              onClose={onClose}
            />
          );
        }
      }
    }
    case 'Ready': {
      const { contacts, query, results, selectedMap, selectedCnt } = state;

      const isKeyboardOpen = useKeyboardIsOpen();
      const dismissKeyboardCallback = React.useCallback(() => {
        Keyboard.dismiss();
      }, [Keyboard]);

      const toggleSelected = React.useCallback(
        (item: [BasicContact, number], selected: boolean) => {
          if (selected) {
            deselectAt(item[1]);
          } else {
            selectAt(item[1]);
          }
        },
        [selectAt, deselectAt],
      );

      const renderItem = React.useCallback(
        ({ item }: { item: [BasicContact, number] }) => {
          return <ContactItem item={item} selected={!!selectedMap[item[1]]} onPress={toggleSelected} />;
        },
        [selectedMap, toggleSelected],
      );

      const showTitle = !isKeyboardOpen && query.trim() === '';

      return (
        <>
          {showTitle ? <HeaderView onClose={onClose} /> : null}
          <DismissKeyboard style={styles.hrPadding}>
            <ListHeaderView
              contactsCnt={contacts.length}
              selectedCnt={selectedCnt}
              onSelectAll={selectAll}
              onDeselectAll={deselectAll}
              query={query}
              onQueryChange={search}
              showTitle={showTitle}
              showFooter={showTitle}
            />
          </DismissKeyboard>
          <FlatList
            style={[styles.hrPadding, { flex: 1 }]}
            data={results}
            keyboardShouldPersistTaps="handled"
            keyExtractor={contactItemKeyExtractor}
            renderItem={renderItem}
            ItemSeparatorComponent={Line}
            ListFooterComponent={Line}
            onScrollEndDrag={dismissKeyboardCallback}
            removeClippedSubviews
            windowSize={11}
          />
          <FormCard dropShaddow={true}>
            <SafeAreaView edges={['bottom']} mode="padding">
              <Button
                // text={selectedCnt ? `Importă ${selectedCnt} contacte` : 'Importă contacte'}
                text={
                  selectedCnt > 0
                    ? i18next.t('clients:importNContactsAction', { count: selectedCnt })
                    : i18next.t('clients:importContacts')
                }
                disabled={selectedCnt === 0}
                onClick={onImport}
              />
            </SafeAreaView>
          </FormCard>
        </>
      );
    }
    case 'ImportingContacts': {
      return (
        <ImportInProgressView importedCnt={state.importedCnt} importingCnt={state.importingCnt} onClose={onClose} />
      );
    }
    case 'ImportSucceed': {
      return <ImportSucceedView importedCnt={state.importedCnt} onClose={onClose} />;
    }
    case 'Failed': {
      // FIXME: handle this case
      return <ImportFailedView onGoBack={onGoBack} onClose={onClose} />;
    }
  }
};

type ImportContactsScreenViewProps = {
  readonly state: ImportContactsState;
  readonly selectAt: (index: number) => void;
  readonly deselectAt: (index: number) => void;
  readonly selectAll: () => void;
  readonly deselectAll: () => void;
  readonly search: (q: string) => void;
  readonly onAskContactsPermission?: () => void;
  readonly onImport?: () => void;
  readonly onGoBack: () => void;
  readonly onClose: () => void;
};

const ImportContactsScreenView: React.FC<ImportContactsScreenViewProps> = ({
  state,
  selectAt,
  deselectAt,
  selectAll,
  deselectAll,
  search,
  onAskContactsPermission,
  onImport,
  onGoBack,
  onClose,
}: ImportContactsScreenViewProps) => {
  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <ImportContactsScreenContentView
        state={state}
        selectAt={selectAt}
        deselectAt={deselectAt}
        deselectAll={deselectAll}
        search={search}
        selectAll={selectAll}
        onAskContactsPermission={onAskContactsPermission}
        onImport={onImport}
        onGoBack={onGoBack}
        onClose={onClose}
      />
    </ModalScreenContainer>
  );
};

export default ImportContactsScreenView;

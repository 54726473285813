import { Category } from '@mero/api-sdk/dist/shop/category';
import { Filters as FiltersType } from '@mero/api-sdk/dist/shop/filters';
import { MainCategoryId } from '@mero/api-sdk/dist/shop/main-category-id';
import { Product } from '@mero/api-sdk/dist/shop/product';
import { Sort } from '@mero/api-sdk/dist/shop/sort';
import { SubCategoryId } from '@mero/api-sdk/dist/shop/sub-category-id';
import { createModelContext } from '@mero/components';
import { isEmpty } from 'lodash';

import { meroApi } from '../../../../../contexts/AuthContext';

type Props = {
  total: number;
  categories: Category[];
  products: Product[];
  filters: FiltersType;
  selectedFilters: FiltersType;
  searchTerm: string;
  sort?: Sort;
  hasMore: boolean;
};

export type State =
  | ({ type: 'New' } & Props)
  | ({ type: 'Loading' } & Props)
  | ({ type: 'Reloading' } & Props)
  | ({ type: 'Loaded' } & Props)
  | ({ type: 'Error'; error: Error } & Props);

const defaultState = (): State => ({
  type: 'New',
  categories: [],
  products: [],
  filters: {},
  selectedFilters: {},
  searchTerm: '',
  hasMore: true,
  total: 0,
  sort: {
    by: 'popularity',
    direction: 'asc',
  },
});

export const BATCH_SIZE = 10;

export const ShopContext = createModelContext(
  defaultState(),
  {
    setLoading: (state, payload: Partial<Props>) => {
      return {
        ...state,
        ...payload,
        type: 'Loading',
      };
    },
    setReloading: (state, payload: Partial<Props>) => {
      return {
        ...state,
        ...payload,
        type: 'Reloading',
      };
    },
    setError: (state, payload: Partial<Props> & { error: Error }) => {
      return {
        ...state,
        ...payload,
        type: 'Error',
        error: payload.error,
      };
    },
    trySetResult: (state, result: Partial<Props>) => {
      if (state.type === 'Loading' || state.type === 'Reloading') {
        return {
          ...state,
          ...result,
          type: 'Loaded',
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },

    setResults: (state, payload: Partial<Props>) => {
      return {
        ...state,
        ...payload,
      };
    },

    run: (state, fn: (state: State) => void) => {
      fn(state);

      return state;
    },
    mutate: (state, fn: (state: State) => State) => fn(state),
  },
  (dispatch) => {
    return {
      init: () => {
        dispatch.run(async (state) => {
          try {
            dispatch.setLoading({ categories: [] });
            const result = await meroApi.shop.searchCategories();

            dispatch.trySetResult({
              categories: result.categories,
            });
          } catch (error: any) {
            dispatch.setError({ categories: state.categories, error });
          }
        });
      },
      loadCategories: () => {
        dispatch.run(async (state) => {
          try {
            const result = await meroApi.shop.searchCategories();
            dispatch.setResults({
              categories: result.categories,
            });
          } catch (error: any) {
            dispatch.setError({ categories: state.categories, error });
          }
        });
      },
      search: (params: {
        searchTerm: string;
        filters?: FiltersType;
        page?: number;
        sort?: Sort;
        mainCategories?: MainCategoryId[];
        subCategories?: SubCategoryId[];
      }) => {
        dispatch.run(async (state) => {
          const { searchTerm, filters, page = 0, sort } = params;
          try {
            dispatch.setLoading({ products: [] });

            if (isEmpty(filters)) {
              const [searchResponse, filtersResponse] = await Promise.all([
                meroApi.shop.search({
                  query: searchTerm,
                  filters,
                  mainCategories: params.mainCategories,
                  subCategories: params.subCategories,
                  offset: page * BATCH_SIZE,
                  size: BATCH_SIZE,
                  sort,
                }),
                meroApi.shop.filters({
                  query: searchTerm,
                  mainCategories: params.mainCategories,
                  subCategories: params.subCategories,
                }),
              ]);

              dispatch.trySetResult({
                total: searchResponse.total,
                products: searchResponse.results,
                filters: filtersResponse.filters,
                sort,
                hasMore: searchResponse.results.length === BATCH_SIZE,
              });
            } else {
              const searchResponse = await meroApi.shop.search({
                query: searchTerm,
                filters,
                mainCategories: params.mainCategories,
                subCategories: params.subCategories,
                offset: page * BATCH_SIZE,
                size: BATCH_SIZE,
                sort,
              });

              dispatch.trySetResult({
                total: searchResponse.total,
                products: searchResponse.results,
                searchTerm,
                sort,
                hasMore: searchResponse.results.length === BATCH_SIZE,
              });
            }
          } catch (error: any) {
            dispatch.setError({ searchTerm, filters, sort, products: state.products, error });
          }
        });
      },
      updateSelectedFilters: (selectedFilters: FiltersType) => {
        dispatch.mutate((s) => ({
          ...s,
          selectedFilters,
        }));
      },
      updateSort: (sort?: Sort) => {
        dispatch.mutate((s) => ({
          ...s,
          sort,
        }));
      },
      updateSearchTerm: (searchTerm: string) => {
        dispatch.mutate((s) => ({
          ...s,
          searchTerm,
        }));
      },
      onLoadMore: (mainCategories: MainCategoryId[], subCategories?: SubCategoryId[]) => {
        dispatch.run(async (state) => {
          if (state.type !== 'Loaded') return;

          try {
            dispatch.setReloading({ products: state.products });
            const response = await meroApi.shop.search({
              query: state.searchTerm,
              filters: state.selectedFilters,
              mainCategories,
              subCategories,
              offset: state.products.length,
              size: BATCH_SIZE,
              sort: state.sort,
            });

            const hasMore = response.results.length < response.total;

            dispatch.trySetResult({
              products: [...state.products, ...response.results],
              hasMore,
            });
          } catch (error: any) {
            dispatch.setError({ products: state.products, error });
          }
        });
      },
    };
  },
);

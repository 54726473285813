import { Latitude, Longitude } from '@mero/api-sdk';
import { CityName, DistrictName, AreaName } from '@mero/api-sdk/dist/business';
import {
  Avatar,
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Label,
  normalize,
  Row,
  Select,
  SmallBody,
  Spacer,
  TextInput,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { Location as LocationType } from '@mero/shared-sdk';
import { openSettings } from 'expo-linking';
import * as Location from 'expo-location';
import { PermissionStatus } from 'expo-modules-core';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, ScrollView, TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { LocationProfileStackParamList } from '../../../../../types';
import { getCoordinatesGoogle, reverseGeocodeGoogle } from '../../../../../utils/location';
import log from '../../../../../utils/log';
import { styles } from '../ProsDashboardScreen/ProProfileDetailsScreen.styles';
import LocationPermissionDialog from './LocationPermissionDialog';
import Map from './Map';
import MapPlaceholder from './MapPlaceholder';

const MAX_DISTANCE = 500;

const INPUT_POSITIONS = {
  address: 250,
} as const;

const LocationIcon = () => (
  <Svg data-name="near_me-24px (1)" width={24} height={24}>
    <Path data-name="Path 8470" d="M0 0h24v24H0Z" fill="none" />
    <Path
      data-name="Path 8471"
      d="M17.057 5.923 5.611 10.71a.779.779 0 0 0 .016 1.442l4.164 1.614a.779.779 0 0 1 .444.444l1.606 4.156a.783.783 0 0 0 1.45.023l4.8-11.438a.792.792 0 0 0-1.034-1.028Z"
      fill="#080de0"
    />
  </Svg>
);

const WarningIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 5885">
      <Path data-name="Rectangle 55" fill="none" d="M0 0h24v24H0z" />
      <G data-name="Group 7116">
        <Path
          d="M10.976 14.677h1.85v1.85h-1.85Zm0-7.4h1.85v5.551h-1.85Zm.916-4.627a9.252 9.252 0 1 0 9.258 9.252 9.247 9.247 0 0 0-9.258-9.252Zm.009 16.653a7.4 7.4 0 1 1 7.4-7.4 7.4 7.4 0 0 1-7.399 7.4Z"
          fill="#fbac40"
        />
        <Path data-name="Rectangle 1487" fill="none" d="M0 0h24v24H0z" />
      </G>
    </G>
  </Svg>
);

export type Props = StackScreenProps<LocationProfileStackParamList, 'LocationAddress'>;

const rad = function (x: number) {
  return (x * Math.PI) / 180;
};

const getDistance = function (p1: google.maps.LatLngLiteral, p2: google.maps.LatLngLiteral) {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

const LocationAddressScreen: React.FC<Props> = () => {
  const { t } = useTranslation('location');
  const { isPhone } = useMediaQueries();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();

  const block = React.useRef(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [mapCoordinates, setMapCoordinates] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [mapLoading, setMapLoading] = React.useState(false);

  const [showLocationPermission, setShowLocationPermission] = React.useState(false);

  const [address, setAddress] = React.useState({
    input: '',
    decoded: NonEmptyString.decode(''),
  });
  const addressValid = E.isRight(address.decoded);

  const [district, setDistrict] = React.useState('');
  const [city, setCity] = React.useState<CityName>();
  const [area, setArea] = React.useState<DistrictName>();

  const [districts, setDistricts] = React.useState<{ label: DistrictName; value: DistrictName }[]>([]);
  const [cities, setCities] = React.useState<{ label: CityName; value: CityName }[]>([]);
  const [areas, setAreas] = React.useState<{ label: AreaName; value: AreaName }[]>([]);

  const [referencePoint, setReferencePoint] = React.useState('');

  const getInitialData = React.useCallback(async () => {
    const districts = await meroApi.business.getDistricts();
    setDistricts(districts.map((d) => ({ label: d, value: d })));
  }, []);

  const getData = React.useCallback(async (location: LocationType) => {
    const districts = await meroApi.business.getDistricts();
    setDistricts(districts.map((d) => ({ label: d, value: d })));
    const { district, city, area } = location;
    if (district) {
      const districtValue = districts.find((d) => normalize(d) === normalize(district));
      if (districtValue) {
        setDistrict(districtValue);
        if (city) {
          const cities = await meroApi.business.getCities({ district: districtValue });
          setCities(cities.map((c) => ({ label: c, value: c })));
          const cityValue = cities.find((c) => normalize(c) === normalize(city));
          if (cityValue) {
            setCity(cityValue);
            const areas = await meroApi.business.getAreas({ city: cityValue });
            setAreas(areas.map((a) => ({ label: a, value: a })));
            if (area) {
              const areaValue = areas.find((a) => normalize(a) === normalize(area));
              if (areaValue) {
                setArea(areaValue);
              }
            }
          }
        }
      }
    }

    setAddress({
      input: location.address,
      decoded: NonEmptyString.decode(location.address),
    });
    setReferencePoint(location.extraInfo ?? '');
    setCoordinates({
      lat: location.lat,
      lng: location.long,
    });
  }, []);

  React.useEffect(() => {
    const getCities = async () => {
      const cities = await meroApi.business.getCities({ district });
      setCities(cities.map((c) => ({ label: c, value: c })));
    };

    if (district) {
      getCities().catch((error) => log.error('Failed to get cities', error));
    } else {
      setCities([]);
    }
    setCity(undefined);
  }, [district]);

  React.useEffect(() => {
    if (!city) {
      return;
    }
    const getAreas = async () => {
      const areas = await meroApi.business.getAreas({ city: city });
      setAreas(areas.map((a) => ({ label: a, value: a })));
    };

    if (city) {
      getAreas().catch((error) => log.error('Failed to get areas', error));
    } else {
      setAreas([]);
    }
    setArea('');
  }, [city]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && pageState.page.details.location) {
      getData(pageState.page.details.location).catch((error) => showError(error));
    } else if (pageState.type === 'Loaded') {
      getInitialData().catch((error) => showError(error, t('failedToLoadDistricts')));
    }
  }, [pageState.type]);

  React.useEffect(() => {
    let debounce: number;

    if (
      pageState.type === 'Loaded' &&
      pageState.page.details.location &&
      city === pageState.page.details.location.city &&
      district === pageState.page.details.location.district &&
      address.input === pageState.page.details.location.address
    ) {
      return;
    }

    if (city && district && addressValid) {
      setMapLoading(true);
      debounce = window.setTimeout(async () => {
        const newCoordinates = await getCoordinatesGoogle({ address: address.input, city, county: district });
        setCoordinates(newCoordinates);
        setMapCoordinates(newCoordinates);
        setMapLoading(false);
      }, 3000);
    }

    return () => window.clearTimeout(debounce);
  }, [city, district, address.input]);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded' || !addressValid || !city || !district) {
      return;
    }

    if (!coordinates || !mapCoordinates || !Latitude.JSON.is(coordinates.lat) || !Longitude.JSON.is(coordinates.lng)) {
      showError(new Error(t('noLocation')), t('noLocation'));
      return;
    }

    if (Math.abs(getDistance(coordinates, mapCoordinates)) > MAX_DISTANCE) {
      showError(
        new Error(t('locationTooFar', { count: MAX_DISTANCE / 1000 })),
        t('locationTooFar', { count: MAX_DISTANCE / 1000 }),
      );
      return;
    }

    block.current = true;
    setIsLoading(true);

    try {
      await meroApi.pages.updatePageAddress({
        pageId: pageState.page.details._id,
        location: {
          city,
          district,
          area,
          extraInfo: referencePoint,
          address: address.input,
          long: coordinates.lng,
          lat: coordinates.lat,
        },
      });

      await reloadAsync();

      goBack();

      toast.show({
        type: 'success',
        text: t('saveChangesSuccess'),
      });
    } catch (error) {
      showError(error, t('saveChangesError'));
    } finally {
      block.current = false;
      setIsLoading(false);
    }
  };

  const toggleLocationPermission = () => {
    setShowLocationPermission(!showLocationPermission);
  };

  const requestLocationPermission = async () => {
    try {
      const { status } = await Location.getForegroundPermissionsAsync();

      if (status === PermissionStatus.GRANTED) {
        setIsLoading(true);
        const location = await Location.getCurrentPositionAsync();
        const address = await reverseGeocodeGoogle(location.coords);

        setCoordinates({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });

        const districtsValue = districts.length ? districts.map((d) => d.value) : await meroApi.business.getDistricts();
        if (!districts.length) {
          setDistricts(districtsValue.map((d) => ({ label: d, value: d })));
        }
        const { county: reverseDistrict, city: reverseCity } = address;
        if (reverseDistrict) {
          const districtValue = districtsValue.find((d) => normalize(d) === normalize(reverseDistrict));
          if (districtValue) {
            setDistrict(districtValue);
            if (reverseCity) {
              const citiesValue = await meroApi.business.getCities({ district: districtValue });
              setCities(citiesValue.map((c) => ({ label: c, value: c })));
              const cityValue = citiesValue.find((c) => normalize(c) === normalize(reverseCity));
              if (cityValue) {
                setCity(cityValue);
                const areasValue = await meroApi.business.getAreas({ city: cityValue });
                setAreas(areasValue.map((a) => ({ label: a, value: a })));
              }
            }
          }
        }

        setAddress({
          input: address.address,
          decoded: NonEmptyString.decode(address.address),
        });

        return;
      }

      if (status === PermissionStatus.UNDETERMINED) {
        await Location.requestForegroundPermissionsAsync();
      } else if (Platform.OS !== 'web') {
        toggleLocationPermission();
      }
    } catch (error) {
      log.error('Failed to request location permission', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <Header
          LeftComponent={() => (
            <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
              <Icon type="back" />
            </TouchableOpacity>
          )}
          text={t('address')}
          RightComponent={() => (
            <Column style={{ paddingRight: 24 }}>
              <Avatar
                size={32}
                source={pageState.page.details.profilePhoto.thumbnail}
                firstname={pageState.page.details.name}
                lastname={''}
              />
            </Column>
          )}
        />

        <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16 }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 8 }}>
            <H1>{t('address')}</H1>
            <TouchableOpacity
              onPress={requestLocationPermission}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              {isLoading ? <ActivityIndicator size={24} /> : <LocationIcon />}
              <Body
                style={{
                  paddingLeft: 4,
                  fontFamily: 'open-sans-semibold',
                  color: isLoading ? colors.CADET_BLUE : colors.DARK_BLUE,
                }}
              >
                {t('getLocation')}
              </Body>
            </TouchableOpacity>
          </Row>
          <Spacer size={32} />
          <FormCard paddings={'none'} style={{ padding: 16 }} dropShaddow rounded>
            <InputWithLabel label={t('county')} isError={showErrors && !district} errorText={t('countyError')}>
              <Select
                placeholder={t('countyPlaceholder')}
                items={districts}
                value={district}
                onChange={setDistrict}
                isError={showErrors && !district}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('city')} isError={showErrors && !city} errorText={t('cityError')}>
              <Select
                placeholder={district ? t('cityPlaceholder2') : t('cityPlaceholder1')}
                items={cities}
                value={city}
                onChange={setCity}
                isError={showErrors && !city}
              />
            </InputWithLabel>
            <Spacer size="16" />
            {areas.length > 0 && (
              <>
                <InputWithLabel label="Sector" isError={showErrors && !area} errorText="Te rugăm să alegi un sector">
                  <Select
                    placeholder={city ? 'Alege sector' : 'Alege oraș mai întâi'}
                    items={areas}
                    value={area}
                    onChange={setArea}
                    isError={showErrors && !area}
                  />
                </InputWithLabel>
                <Spacer size="16" />
              </>
            )}
            <InputWithLabel label={t('address')} isError={showErrors && !addressValid} errorText={t('addressError')}>
              <TypeSafeTextInput
                editable={Boolean(city && district)}
                codec={NonEmptyString}
                value={address.input}
                showError={showErrors}
                onChange={setAddress}
                placeholder={t('addressPlaceholder')}
                onFocus={() => scrollTo('address')}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('referencePoint')}>
              <TextInput
                withBorder
                value={referencePoint}
                onChange={setReferencePoint}
                placeholder={t('referencePointPlaceholder')}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <Title>{t('map')}</Title>
            <Spacer size="4" />
            {coordinates ? <SmallBody>{t('mapDescription')}</SmallBody> : <SmallBody>{t('mapDescription2')}</SmallBody>}
            <Spacer size="12" />
            {coordinates ? (
              <>
                <Spacer size={4} />
                <Column style={{ backgroundColor: '#FEF6EB', paddingHorizontal: 8, paddingVertical: 18 }}>
                  <Row>
                    <WarningIcon />
                    <Column style={{ paddingLeft: 8, flex: 1 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-bold' }}>{t('markerTitle')}</SmallBody>
                      <Spacer size={4} />
                      <SmallBody>{t('markerDescription')}</SmallBody>
                    </Column>
                  </Row>
                </Column>
                <Spacer size={16} />
              </>
            ) : null}
            {coordinates ? (
              <Column style={{ width: '100%', height: 185, position: 'relative' }}>
                <Map coordinates={coordinates} onChange={setCoordinates} />
                {mapLoading ? (
                  <Column
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: `rgba(0, 0, 0, 0.5)`,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <Label style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold' }}>{t('isLoading')}</Label>
                  </Column>
                ) : null}
              </Column>
            ) : (
              <MapPlaceholder />
            )}
          </FormCard>
          <Spacer size={117} />
        </ScrollView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button disabled={block.current || isLoading} text={t('saveChanges')} onPress={saveChanges} />
            ) : (
              <Button
                disabled={block.current || isLoading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('saveChanges')}
                onPress={saveChanges}
              />
            )}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
      {showLocationPermission && (
        <LocationPermissionDialog onSuccess={openSettings} onCancel={toggleLocationPermission} />
      )}
    </>
  );
};

export default LocationAddressScreen;

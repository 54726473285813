import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ProductDescriptionScreen from '../screens/Authorized/ProductsScreen/ProductDescriptionScreen';
import ProductGalleryScreen from '../screens/Authorized/ProductsScreen/ProductGalleryScreen';
import ProductImageMenu from '../screens/Authorized/ProductsScreen/ProductImageMenu';
import ProductMenu from '../screens/Authorized/ProductsScreen/ProductMenuScreen';
import ProductPriceScreen from '../screens/Authorized/ProductsScreen/ProductPriceScreen';
import ProductScreen from '../screens/Authorized/ProductsScreen/ProductScreen';
import ProductStockScreen from '../screens/Authorized/ProductsScreen/ProductStockScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withProductImagesContext } from '../contexts/ProductImagesContext';
import { withSelectedProductContext } from '../contexts/SelectedProductContext';
import { ProductEditStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<ProductEditStackParamList>();

function ProductEditStackNavigator(): React.ReactElement {
  const { t } = useTranslation('products');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="ProductScreen"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProductScreen"
        component={ProductScreen}
        options={{ title: t('products:editProduct'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProductImageMenu"
        component={ProductImageMenu}
        options={{ title: t('products:productGallery'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProductGalleryScreen"
        component={ProductGalleryScreen}
        options={{ title: t('products:productGallery'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProductDescriptionScreen"
        component={ProductDescriptionScreen}
        options={{
          title: t('products:productDescription'),
        }}
      />
      <Stack.Screen
        name="ProductMenu"
        component={ProductMenu}
        options={{
          title: t('products:nameColumn'),
        }}
      />
      <Stack.Screen
        name="ProductPrice"
        component={ProductPriceScreen}
        options={{
          title: t('products:price'),
        }}
      />
      <Stack.Screen
        name="ProductStock"
        component={ProductStockScreen}
        options={{
          title: t('products:manageInventory'),
        }}
      />
    </Stack.Navigator>
  );
}

export default pipe(ProductEditStackNavigator, withProductImagesContext, withSelectedProductContext);

import { ClientPreview } from '@mero/api-sdk/dist/clients';
import {
  AvoidKeyboard,
  Button,
  DismissKeyboard,
  Icon,
  Column,
  Row,
  Line,
  ModalOverlay,
  SearchTextInput,
  SimpleListItem,
  Spacer,
  colors,
  H3s,
  Body,
  H2s,
  styles as meroStyles,
  SafeAreaView,
  SmallBody,
} from '@mero/components';
import { PhoneNumber, PhoneNumberParser } from '@mero/shared-sdk/dist/common';
import * as E from 'fp-ts/lib/Either';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, FlatList, Pressable, TouchableOpacity, StyleProp, ViewStyle, StyleSheet } from 'react-native';

import { ClientsFlagFilter, ClientsSearchResult } from '../../contexts/SearchClientsContext';
import AddClientListItem from '../AddClientListItem';
import ClientListItem from '../ClientListItem';
import { styles } from './styles';

type ClientFilterOption = {
  title: string;
  type: ClientsFlagFilter;
};

const ClientFilterOptions: { [k in ClientsFlagFilter]: ClientFilterOption } = {
  all: {
    type: 'all',
    title: 'Toți clienții',
  },
  blocked: {
    type: 'blocked',
    title: 'Blocați',
  },
  warned: {
    type: 'warned',
    title: 'Avertizați',
  },
};

type AddImportButtonsProps = {
  readonly onAddClient?: () => void;
  readonly onImportContacts?: () => void;
};

const AddImportButtons: React.FC<AddImportButtonsProps> = ({
  onAddClient,
  onImportContacts,
}: AddImportButtonsProps) => {
  return (
    <>
      {onAddClient !== undefined ? (
        <>
          <Button
            text="+ Adaugă client nou"
            backgroundColor="#F2F2FE"
            color={colors.DARK_BLUE}
            size="medium"
            onClick={onAddClient}
          />
          <Spacer size="24" />
        </>
      ) : null}
      {onImportContacts !== undefined ? (
        <Button text="+ Importă din contacte" size="medium" onClick={onImportContacts} />
      ) : null}
    </>
  );
};

type NoClientsProps = {
  readonly onAddClient?: () => void;
  readonly onImportContacts?: () => void;
};

const NoClients: React.FC<NoClientsProps> = ({ onAddClient, onImportContacts }: NoClientsProps) => {
  const { t } = useTranslation('clients');
  return (
    <DismissKeyboard
      style={{
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        paddingLeft: 48,
        paddingRight: 48,
      }}
    >
      <View style={{ maxWidth: 480, alignSelf: 'center' }}>
        <H2s style={meroStyles.text.alignCenter}>{t('noClients')}</H2s>
        <Spacer size="8" />
        <Body style={meroStyles.text.alignCenter}>{t('clientShownInfo')}</Body>
        <Spacer size="32" />
        <View style={{ width: 192, alignSelf: 'center' }}>
          <AddImportButtons onAddClient={onAddClient} onImportContacts={onImportContacts} />
        </View>
      </View>
    </DismissKeyboard>
  );
};

type TypeToSearchProps = {
  readonly clientsCount: number;
  readonly onAddClient?: () => void;
  readonly onImportContacts?: () => void;
};

const TypeToSearch: React.FC<TypeToSearchProps> = ({
  clientsCount,
  onAddClient,
  onImportContacts,
}: TypeToSearchProps) => {
  const { t } = useTranslation('clients');
  return (
    <DismissKeyboard
      style={{
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      <View style={{ maxWidth: 480, alignSelf: 'center' }}>
        <H2s style={meroStyles.text.alignCenter}>{clientsCount === 1 ? '1 client' : `${clientsCount} clienți`}</H2s>
        <Spacer size="8" />
        <Body style={meroStyles.text.alignCenter}>{t('searchRequirement')}</Body>
        <Spacer size="32" />
        <View style={{ width: 192, alignSelf: 'center' }}>
          <AddImportButtons onAddClient={onAddClient} onImportContacts={onImportContacts} />
        </View>
      </View>
    </DismissKeyboard>
  );
};
type NotFoundProps = {
  readonly query: string;
  readonly flagFilter: ClientsFlagFilter;
  readonly onAddClient?: (phone: PhoneNumber) => void;
};

/**
 * PhoneNumberParser validation disabled!
 * TODO: add validation back to PhoneNumber/PhoneNumberParser and use that type
 */
const isPhoneNumberLike = (s: string) => /[+\d]+/.test(s);

const NotFound: React.FC<NotFoundProps> = ({ query, flagFilter, onAddClient }: NotFoundProps) => {
  const { t } = useTranslation('clients');
  const r = PhoneNumberParser.decode(query);

  const queryIsPhoneNumber = E.isRight(r) && isPhoneNumberLike(query);
  const addClientCallback = React.useCallback(() => {
    if (E.isRight(r) && onAddClient !== undefined) {
      onAddClient(r.right);
    }
  }, [query]);

  return (
    <DismissKeyboard
      style={{
        flex: 1,
      }}
    >
      {queryIsPhoneNumber ? (
        <View style={styles.hrPadding}>
          <AddClientListItem phone={query} onPress={addClientCallback} />
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            alignContent: 'center',
            paddingTop: 70,
            paddingLeft: 48,
            paddingRight: 48,
            maxWidth: 480,
            alignSelf: 'center',
          }}
        >
          {flagFilter === 'all' ? (
            <>
              <H2s style={meroStyles.text.alignCenter}>{t('noResultsFor')}</H2s>
              <H2s style={meroStyles.text.alignCenter}>&quot;{query}&quot;</H2s>
              <Spacer size="8" />
              <Body style={meroStyles.text.alignCenter}>{t('checkSearch')}</Body>
            </>
          ) : flagFilter === 'blocked' ? (
            <>
              <H2s style={meroStyles.text.alignCenter}>{t('noneBlocked')}</H2s>
              <Spacer size="8" />
              <Body style={meroStyles.text.alignCenter}>{t('blockedInfo')}</Body>
            </>
          ) : flagFilter === 'warned' ? (
            <>
              <H2s style={meroStyles.text.alignCenter}>{t('noneWarned')}</H2s>
              <Spacer size="8" />
              <Body style={meroStyles.text.alignCenter}>{t('warnedInfo')}</Body>
            </>
          ) : null}
        </View>
      )}
    </DismissKeyboard>
  );
};

export type FlagFilterOption = {
  title: string;
};

type FlagFilterOptionsProps = {
  readonly onDismiss: () => void;
  readonly onOptionSelected: (option: ClientFilterOption) => void;
  readonly bottomSafeArea?: boolean;
};

const FlagFilterOptionsScreen = ({ onDismiss, onOptionSelected, bottomSafeArea }: FlagFilterOptionsProps) => {
  const selectCallback = (option: ClientFilterOption) => () => {
    if (onOptionSelected) {
      onOptionSelected(option);
    }
  };

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={selectCallback(ClientFilterOptions.all)}
        >
          <SimpleListItem>
            <Body>{ClientFilterOptions.all.title}</Body>
          </SimpleListItem>
        </TouchableOpacity>

        <Line />

        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={selectCallback(ClientFilterOptions.blocked)}
        >
          <SimpleListItem>
            <Body>{ClientFilterOptions.blocked.title}</Body>
          </SimpleListItem>
        </TouchableOpacity>

        <Line />

        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={selectCallback(ClientFilterOptions.warned)}
        >
          <SimpleListItem>
            <Body>{ClientFilterOptions.warned.title}</Body>
          </SimpleListItem>
        </TouchableOpacity>
        {bottomSafeArea ? <SafeAreaView edges={['bottom']} /> : null}
      </View>
    </ModalOverlay>
  );
};

type AddClientOptionsProps = {
  readonly onAddClient: () => void;
  readonly onImportContacts: () => void;
  readonly onDismiss: () => void;
  readonly bottomSafeArea?: boolean;
};

const AddClientOptionsScreen = ({
  onAddClient,
  onImportContacts,
  onDismiss,
  bottomSafeArea,
}: AddClientOptionsProps) => {
  const { t } = useTranslation('clients');
  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onAddClient}>
          <SimpleListItem>
            <Body>{t('addClient')}</Body>
          </SimpleListItem>
        </TouchableOpacity>
        <Line />
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onImportContacts}>
          <SimpleListItem>
            <Body>{t('importContacts')}</Body>
          </SimpleListItem>
        </TouchableOpacity>
        {bottomSafeArea ? <SafeAreaView edges={['bottom']} /> : null}
      </View>
    </ModalOverlay>
  );
};

const clientPreviewKeyExtractor = (client: ClientPreview): string => client._id;

export type SearchClientsFormProps = {
  /**
   * Search query text
   */
  readonly query: string;

  /**
   * Autofocus search input
   */
  readonly autoFocus?: boolean;

  /**
   * Loading state flag
   */
  readonly isLoading: boolean;

  /**
   * List is reloading on user request (pull to refresh)
   */
  readonly isReloading?: boolean;
  /**
   * Query change callback
   */
  readonly onQueryChange?: (query: string) => void;

  readonly flagFilter?: ClientsFlagFilter;
  /**
   * When true - show flag filter button (All, Blocked, Warned)
   */
  readonly showFlagFilter?: boolean;
  /**
   * Flag change callback
   */
  readonly onFlagFilterChange?: (flag: ClientsFlagFilter) => void;
  /**
   * List of clients matching query
   */
  readonly clients: ClientsSearchResult;
  /**
   * Client performer selected
   */
  readonly onClientSelected?: (client: ClientPreview) => void;
  /**
   * User pressed add client button
   * If callback not provided - addClient button will be disabled
   */
  readonly onAddClient?: (phone?: PhoneNumber) => void;
  /**
   * User pressed import contacts.
   * If callback not provided - import contacts button will be disabled
   */
  readonly onImportContacts?: () => void;

  readonly onReload?: () => void;

  /**
   * Scrolled to the end of the list, load more
   */
  readonly onLoadMore?: () => void;

  /**
   * Form title
   */
  readonly title?: string;

  /**
   * Container style
   */
  readonly style?: StyleProp<ViewStyle>;

  /**
   * View has bottom safe are, should compensate
   */
  readonly bottomSafeArea?: boolean;
};

type ItemProps = {
  item: ClientPreview;
  onClientSelected?: (client: ClientPreview) => void;
};

/**
 * Using this wrapper component to avoid re-renders caused by dynamic `onPress` handler function
 * which have new reference every time
 */
const Item: React.FC<ItemProps> = React.memo(function ItemComponent({ item, onClientSelected }: ItemProps) {
  return (
    <ClientListItem
      client={item}
      onPress={() => {
        if (onClientSelected !== undefined) {
          onClientSelected(item);
        }
      }}
    />
  );
});

const SearchClientsForm = ({
  query,
  autoFocus = false,
  isReloading = false,
  onQueryChange,
  flagFilter = 'all',
  showFlagFilter,
  onFlagFilterChange,
  clients,
  onClientSelected,
  onAddClient,
  onImportContacts,
  onReload,
  onLoadMore,
  style,
  bottomSafeArea,
}: SearchClientsFormProps): React.ReactElement => {
  const { t } = useTranslation('clients');

  const queryLength = query.trim().length;
  const queryIsEmpty = queryLength === 0;

  const dismissKeyboardCallback = React.useCallback(() => {
    Keyboard.dismiss();
  }, [Keyboard]);

  const [showFlagFilterOptions, setShowFlagFilterOptions] = React.useState(false);

  const flagFilterOption = React.useMemo(() => ClientFilterOptions[flagFilter], [flagFilter]);

  const flagFilterSelectedCallback = React.useCallback(
    (ff: ClientFilterOption) => {
      setShowFlagFilterOptions(false);
      if (onFlagFilterChange) {
        onFlagFilterChange(ff.type);
      }
    },
    [onFlagFilterChange, setShowFlagFilterOptions],
  );

  const [showAddClientOptions, setShowAddClientOptions] = React.useState(false);

  // Avoid passing invalid parameter as onAddClient first argument when passed as
  const onAddClientNoPhone = onAddClient ? () => onAddClient() : undefined;

  const addClientMenuCallback = React.useCallback(() => {
    if (onAddClient !== undefined) {
      // calling explictely without arguments, inner components may pass some wrong data here
      if (onImportContacts !== undefined) {
        dismissKeyboardCallback();
        setShowAddClientOptions(true);
      } else {
        onAddClient();
      }
    }
  }, [onAddClient, onImportContacts, setShowAddClientOptions]);

  // const showTitle = !isKeyboardOpen && queryIsEmpty;

  const resultsListRef = React.useRef<FlatList<ClientPreview>>(null);
  // Scroll list to top on query change
  React.useEffect(() => {
    resultsListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
  }, [query, resultsListRef, resultsListRef.current]);

  return (
    <AvoidKeyboard style={StyleSheet.compose({ flex: 1 }, style)}>
      {/* Header */}
      {clients.type !== 'NoClientsExists' ? (
        <DismissKeyboard>
          <View style={styles.hrPadding}>
            <Spacer size="8" />
            {/* {showTitle && title !== undefined ? (
              <>
                <Spacer size="8" />
                <H1>{title}</H1>
                <Spacer size="24" />
              </>
            ) : null} */}
            <SearchTextInput
              placeholder={t('searchNameOrPhone')}
              value={query}
              autoFocus={autoFocus}
              onChange={onQueryChange}
            />
            {showFlagFilter ? (
              <>
                <Spacer size="16" />
                <Row style={{ justifyContent: 'center' }}>
                  <Column>
                    <H3s>
                      {flagFilter === 'all'
                        ? t('clients')
                        : flagFilter === 'blocked'
                        ? t('blockedClients')
                        : flagFilter === 'warned'
                        ? t('warnClients')
                        : ''}
                      {clients.type === 'ClientsResult' && clients.clientsCount !== undefined
                        ? ` (${clients.clientsCount})`
                        : ''}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 1, flexDirection: 'row-reverse' }}>
                    <TouchableOpacity
                      style={{ flexDirection: 'row' }}
                      onPress={() => {
                        dismissKeyboardCallback();
                        setShowFlagFilterOptions(true);
                      }}
                    >
                      <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
                        {flagFilterOption?.title ?? t('filter')}
                      </SmallBody>
                      <View
                        style={{
                          paddingLeft: 8,
                          justifyContent: 'center',
                        }}
                      >
                        <Icon type="dropdown" color={colors.DARK_BLUE} />
                      </View>
                    </TouchableOpacity>
                  </Column>
                </Row>
              </>
            ) : null}
            <Spacer size="8" />
          </View>
        </DismissKeyboard>
      ) : null}
      {/* Body */}
      {clients.type === 'NoClientsFound' ? (
        <NotFound query={query} flagFilter={flagFilter} onAddClient={onAddClient} />
      ) : clients.type === 'NoClientsExists' ? (
        <NoClients onAddClient={onAddClientNoPhone} onImportContacts={onImportContacts} />
      ) : clients.clients.length === 0 &&
        clients.clientsCount !== undefined &&
        clients.clientsCount > 0 &&
        queryLength < 3 ? (
        <TypeToSearch
          clientsCount={clients.clientsCount}
          onAddClient={onAddClientNoPhone}
          onImportContacts={onImportContacts}
        />
      ) : (
        <FlatList
          ref={resultsListRef}
          style={{ flex: 1 }}
          contentContainerStyle={styles.hrPadding}
          data={clients.clients}
          keyExtractor={clientPreviewKeyExtractor}
          keyboardShouldPersistTaps="handled"
          ListHeaderComponent={
            queryIsEmpty && onAddClient ? <AddClientListItem phone={query} onPress={addClientMenuCallback} /> : null
          }
          renderItem={({ item }) => <Item item={item} onClientSelected={onClientSelected} />}
          ItemSeparatorComponent={Line}
          ListFooterComponent={
            clients.clients.length > 0 ? (
              <SafeAreaView edges={['bottom']}>
                <Line />
              </SafeAreaView>
            ) : null
          }
          onScrollEndDrag={dismissKeyboardCallback}
          onEndReached={onLoadMore}
          onEndReachedThreshold={0.9}
          windowSize={11}
          refreshing={isReloading}
          onRefresh={onReload}
        />
      )}

      {showFlagFilterOptions ? (
        <FlagFilterOptionsScreen
          onDismiss={() => {
            setShowFlagFilterOptions(false);
          }}
          onOptionSelected={flagFilterSelectedCallback}
          bottomSafeArea={bottomSafeArea}
        />
      ) : null}

      {showAddClientOptions ? (
        <AddClientOptionsScreen
          onAddClient={() => {
            setShowAddClientOptions(false);
            if (onAddClient) {
              onAddClient();
            }
          }}
          onImportContacts={() => {
            setShowAddClientOptions(false);
            if (onImportContacts) {
              onImportContacts();
            }
          }}
          onDismiss={() => {
            setShowAddClientOptions(false);
          }}
          bottomSafeArea={bottomSafeArea}
        />
      ) : null}
    </AvoidKeyboard>
  );
};

export default SearchClientsForm;

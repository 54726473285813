import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import {
  Body,
  colors,
  H2s,
  Icon,
  Row,
  SearchTextInput,
  SmallBody,
  Title,
  useShowError,
  styles as meroStyles,
  H1,
  Label,
  HSpacer,
} from '@mero/components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, Defs, ClipPath, Rect, G, Path } from 'react-native-svg';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import { LoadingComponent } from '../../../components/LoadingComponent';
import { MembershipIcon } from '../ClientDetailsScreen/components/ClientMembershipsListItemView';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import MembershipSearchFilter from './MembershipSearchFilter';
import { Filters } from './MembershipsTabWeb';

const DefaultProductImage = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <Defs>
      <ClipPath id="a">
        <Rect width={32} height={32} fill="#fff" data-name="Rectangle 2810" rx={8} transform="translate(2986 1353)" />
      </ClipPath>
    </Defs>
    <G clipPath="url(#a)" data-name="Mask Group 208" transform="translate(-2986 -1353)">
      <Path
        fill="rgba(233,236,239,0.5)"
        d="M2986.753 1353h30.232c.416 0 .753.568.753 1.27v29.2c0 .7-.337 1.27-.753 1.27h-30.232c-.416 0-.753-.568-.753-1.27v-29.2c0-.702.337-1.27.753-1.27Z"
        data-name="Path 9163"
      />
      <G data-name="Group 7970">
        <Path fill="none" d="M2993 1360h18v18h-18z" data-name="Rectangle 2809" />
      </G>
      <G fill="none" stroke="#cdcfdd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
        <Path d="m3008.751 1365.243-6.751-2.992-6.749 2.992v7.514l6.749 2.994 6.751-2.994Z" />
        <Path
          d="m2995.251 1365.243 6.749 2.992 6.751-2.992m-9.811-1.642 6.749 2.992m-3.689 1.642v7.514"
          data-name="layer2"
        />
        <Path d="m3006.95 1368.91-2.7 1.192v2.542l2.7-1.192Z" />
      </G>
    </G>
  </Svg>
);

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

type Query = Pick<Parameters<typeof meroApi.pro.productSales.getProductSales>[0], 'search' | 'sold'>;

const ProductsTabMobile: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const showError = useShowError();
  const isFocused = useIsFocused();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setFilter] = React.useState<Filters>();

  const [soldProducts, setSoldProducts] = React.useState<ProductSale[]>([]);
  const [nextPage, setNextPage] = React.useState<string>();
  const loadingNextPage = React.useRef('');

  const loadProducts = async (query: Query, shouldAppend: boolean, next?: string) => {
    setIsLoading(true);
    try {
      loadingNextPage.current = next ?? '';
      const { data: products, next: nextPage } = await meroApi.pro.productSales.getProductSales({
        limit: 10,
        pageId: page.details._id,
        page: next,
        ...query,
      });

      setNextPage(nextPage);
      setSoldProducts(shouldAppend ? [...soldProducts, ...products] : products);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const initialRender = React.useRef(true);

  const loadMoreProducts = () => {
    if (checkoutsState.type === 'Loaded' && nextPage && nextPage !== loadingNextPage.current) {
      loadProducts(
        {
          sold: checkoutsState.interval,
          search: searchQuery,
          ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
        },
        true,
        nextPage,
      );
    }
  };

  React.useEffect(() => {
    let timeout: number;
    if (checkoutsState.type === 'Loaded' && isFocused) {
      if (initialRender.current) {
        loadProducts(
          {
            sold: checkoutsState.interval,
            search: searchQuery,
            ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
          },
          false,
        );
        initialRender.current = false;
      } else {
        timeout = window.setTimeout(() => {
          loadProducts(
            {
              sold: checkoutsState.interval,
              search: searchQuery,
              ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
            },
            false,
          );
        }, 500);
      }
    } else if (!isFocused) {
      initialRender.current = true;
    }

    return () => window.clearTimeout(timeout);
  }, [
    searchQuery,
    checkoutsState.type,
    checkoutsState.type === 'Loaded' ? checkoutsState.interval : undefined,
    page.details._id,
    filter,
    isFocused,
  ]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      <InfiniteScrollView onEndReached={loadMoreProducts} withLoading>
        <Spacer size={24} />
        <Row style={{ paddingLeft: 24, paddingRight: 16, alignItems: 'center' }}>
          <H1 style={{ flex: 1 }}>{t('products')}</H1>
        </Row>
        <Spacer size={16} />
        <Row style={{ paddingHorizontal: 16, zIndex: 1 }}>
          <Column style={{ flex: 1 }}>
            <SearchTextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder={t('searchProductsPlaceholder')}
            />
          </Column>
        </Row>
        <Spacer size={24} />
        <Column>
          {soldProducts.map((product, index) => {
            const measure = getMeasure(product.measure);
            return (
              <React.Fragment key={`${product.productId}${product.transactionId}`}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    paddingVertical: 16,
                  }}
                  onPress={() => {
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: {
                        screen: 'ProceedDetailsScreen',
                        params: { checkoutTransactionId: product.transactionId, backMode: 'one' },
                      },
                    });
                  }}
                >
                  <Column>
                    {product.mainImage?.thumbnail ? (
                      <Image
                        source={{ uri: product.mainImage.thumbnail }}
                        style={{ width: 32, height: 32, borderRadius: 4 }}
                      />
                    ) : (
                      <DefaultProductImage />
                    )}
                  </Column>
                  <Column style={{ flex: 1, paddingLeft: 16 }}>
                    <Row>
                      <Column style={{ flex: 1 }}>
                        <H2s>{product.name}</H2s>
                        <Spacer size={2} />
                        <SmallBody>
                          {scaledToString(product.quantity)} x {measure} - {scaledToString(product.total.amount)}{' '}
                          {t(product.total.unit)}
                        </SmallBody>
                        <Spacer size={6} />
                        <SmallBody style={{ color: colors.COMET }}>
                          {DateTime.fromJSDate(product.createdAt).toFormat('dd MMM. yyyy').toLowerCase()},{' '}
                          {nameGenerator(product.client ?? {}, t('unknownUser'))}
                        </SmallBody>
                        <Spacer size={8} />
                        <Column
                          style={{
                            paddingHorizontal: 8,
                            paddingVertical: 4,
                            backgroundColor: '#E9ECEF',
                            alignSelf: 'flex-start',
                            borderRadius: 4,
                          }}
                        >
                          <SmallBody style={[{ color: colors.COMET, fontSize: 12 }, meroStyles.text.semibold]}>
                            {t('paymentId', { value: product.code }).toUpperCase()}
                          </SmallBody>
                        </Column>
                      </Column>
                      <Column>
                        <Icon type="next" color={colors.DARK_BLUE} />
                      </Column>
                    </Row>
                  </Column>
                </TouchableOpacity>
                {index < checkoutsState.finished.data.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {!isLoading && soldProducts.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s style={{ textAlign: 'center' }}>{t('noProductAvailable')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noMembershipSearchDescription')}</Body>
              </Column>
            </>
          )}
        </Column>
      </InfiniteScrollView>
      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(ProductsTabMobile, CurrentBusiness);

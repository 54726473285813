import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: colors.WHITE,
  },
  body: {
    flex: 1,
  },
  bodyContent: {
    alignItems: 'center',
  },
  footer: {},
});

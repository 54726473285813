import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  modalBorder: {
    borderRadius: 8,
  },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  hrPadding: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  list: {
    flex: 1,
  },
  modalScreen: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

import {
  H1,
  MeroHeader,
  colors,
  useShowError,
  Spacer,
  DismissKeyboard,
  Button,
  Column,
  Checkbox,
  Body,
  Title,
  SmallBody,
  Icon,
  Row,
  useToast,
} from '@mero/components';
import { PositiveInt, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { flow, pipe } from 'fp-ts/lib/function';
import * as ts from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Select from '@mero/components/lib/components/Select';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useInputState } from '../../../../../hooks/useInputState';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { MembershipTemplateContext } from '../../../../../contexts/MembershipTemplateContext';
import { MembershipSettingsStackParamList } from '../../../../../types';
import { scaledToString } from '../../../../../utils/scaled';
import { styles } from '../ProsDashboardScreen/ProProfileDetailsScreen.styles';
import DeleteMembershipDialog from './DeleteMembershipDialog';
import { getItemPrice } from './MembershipItemsScreen';

const ItemsIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 5929">
      <Path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G data-name="Group 5949">
        <Path fill="none" d="M4 4h24v24H4z" data-name="Rectangle 1485" />
      </G>
      <G
        fill="none"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <Path d="M12.667 9.983H26m-13.333 6H26m-13.333 6H26M6 9.983l1.333 1.333 1.6-2.633M6 15.983l1.333 1.333 1.6-2.633M6 21.983l1.333 1.333 1.6-2.667" />
      </G>
    </G>
  </Svg>
);

export const AVAILABILITY_LIST = Array.from({ length: 13 }, (_, i) => ({
  label: i === 0 ? 'unlimitedAvailability' : 'availabilityValue',
  value: i,
}));

type Props = StackScreenProps<MembershipSettingsStackParamList, 'MembershipDetails'> & CurrentBusinessProps;

const MembershipDetailsScreen: React.FC<Props> = ({ page, navigation, route }: Props) => {
  const { t } = useTranslation('membership');
  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();
  const { isPhone } = useMediaQueries();

  const { membershipId } = route.params ?? {};

  const [showErrors, setShowErrors] = React.useState(false);

  const [membershipState, { load, updateDetails, setStatus }] = MembershipTemplateContext.useContext();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [name, setName] = useInputState({
    input: '',
    decoded: DefinedString.decode(''),
  });
  const [description, setDescription] = useInputState({
    input: '',
    decoded: ts.string.decode(''),
  });
  const [availability, setAvailability] = React.useState(0);

  const deleteMembership = async () => {
    setShowDeleteDialog(true);
  };

  const hideDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteSuccess = () => {
    goBack();
  };

  const onNext = () => {
    if (!name.isValid) {
      return setShowErrors(true);
    }

    updateDetails({
      name: name.value,
      description: description.isValid ? (description.value as DefinedString) : undefined,
      validFor:
        availability === 0 ? { type: 'Unlimited' } : { type: 'Limited', days: (availability * 30) as PositiveInt },
    });

    navigation.navigate('MembershipItems', membershipId ? { membershipId } : undefined);
  };

  const toggleStatus = (status?: boolean) => {
    const statusValue = typeof status === 'boolean' ? status : membershipState.status !== 'Active';
    setStatus(statusValue ? 'Active' : 'Inactive');
  };

  const save = async () => {
    if (!name.isValid) {
      return setShowErrors(true);
    }

    if (membershipState.status === 'Deleted') {
      return;
    }

    if (
      membershipState.editItems.some((item) => {
        if (item.type !== 'Service' || item.quantity.type === 'Unlimited') {
          return false;
        }
        const priceNumber = getItemPrice(item.service.price);

        const actualPriceNumber = ScaledNumber.toNumber(item.sellingPrice.amount) / item.quantity.value;

        return priceNumber > 0 && priceNumber < actualPriceNumber;
      })
    ) {
      return;
    }

    const details = {
      name: name.value,
      description: description.isValid ? (description.value as DefinedString) : undefined,
      validFor:
        availability === 0
          ? { type: 'Unlimited' as const }
          : { type: 'Limited' as const, days: (availability * 30) as PositiveInt },
    };

    updateDetails(details);

    setIsLoading(true);
    try {
      if (membershipId) {
        await meroApi.memberships.updateMembershipTemplate({
          pageId: page.details._id,
          membershipTemplateId: membershipId,
          template: {
            ...membershipState,
            ...details,
            //@ts-expect-error @FIXME
            items: membershipState.items.filter((item) => item.type === 'Service' && item.quantity.type !== 'NotSet'),
            status: membershipState.status,
            termsAndConditions: membershipState.termsAndConditions as DefinedString,
            discount: membershipState.discount,
          },
        });
        toast.show({
          type: 'success',
          text: t('membershipTypeSaved'),
        });
      }
      navigation.popToTop();
      navigation.replace('MembershipSettings');
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setName({
      input: membershipState.name ?? '',
      decoded: DefinedString.decode(membershipState.name),
    });
    setDescription({
      input: membershipState.description ?? '',
      decoded: ts.string.decode(membershipState.description),
    });

    setAvailability(
      membershipState.validFor?.type === 'Unlimited' ? 0 : Math.floor((membershipState.validFor?.days ?? 0) / 30),
    );
  }, [membershipState.name, membershipState.validFor, membershipState.description]);

  React.useEffect(() => {
    if (membershipId && !membershipState.name) {
      load(page.details._id, membershipId)
        .catch((error) => {
          showError(error);
          goBack();
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const { totalPrice, withDiscount } = React.useMemo(() => {
    const totalPrice = membershipState.items.reduce(
      (acc, item) => acc + (item.type === 'Service' ? ScaledNumber.toNumber(item.sellingPrice.amount) : 0),
      0,
    );

    const withDiscount =
      totalPrice -
      (membershipState.discount
        ? membershipState.discount.type === 'Percent'
          ? (ScaledNumber.toNumber(membershipState.discount.percent) * totalPrice) / 100
          : ScaledNumber.toNumber(membershipState.discount.value.amount)
        : 0);

    return { totalPrice, withDiscount };
  }, [membershipState.items]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        {/*{isLoading && <LoadingComponent />}*/}
        <MeroHeader canGoBack onBack={goBack} title={t(membershipId ? 'changeMembershipType' : 'addMembershipType')} />
        <DismissKeyboard style={{ paddingTop: 16, flex: 1, height: '100%' }}>
          <KeyboardAvoidingView style={{ flex: 1 }}>
            <ScrollView style={{ borderRadius: 6, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 16 }}>
                {t(membershipId ? 'changeMembershipType' : 'membershipTypeDetails')}
              </H1>
              <Spacer size="32" />
              <FormCard
                rounded
                dropShaddow
                paddings="none"
                style={{ paddingHorizontal: 16, paddingVertical: 24, marginHorizontal: 16 }}
              >
                {membershipId ? (
                  <>
                    <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={() => toggleStatus()}>
                      <Checkbox
                        color="blue"
                        disabled={false}
                        value={membershipState.status === 'Active'}
                        onValueChange={toggleStatus}
                      />
                      <Column style={{ paddingLeft: 12 }}>
                        <Title>{t('Active')}</Title>
                        <SmallBody style={{ color: colors.COMET }}>{t('activateDescription')}</SmallBody>
                      </Column>
                    </TouchableOpacity>
                    <Spacer size={24} />
                  </>
                ) : null}
                <InputWithLabel label={t('name')} isError={showErrors && !name.isValid} errorText={t('nameError')}>
                  <TypeSafeTextInput
                    codec={DefinedString}
                    value={name.input}
                    onChange={setName}
                    placeholder={t('namePlaceholder')}
                  />
                </InputWithLabel>
                <Spacer size={16} />
                <InputWithLabel label={t('description')}>
                  <TypeSafeTextInput
                    codec={ts.string}
                    value={description.input}
                    onChange={setDescription}
                    placeholder={t('description')}
                    multiline
                    numberOfLines={3}
                  />
                </InputWithLabel>
                <Spacer size={16} />
                <InputWithLabel label={t('availability')}>
                  <Select
                    items={AVAILABILITY_LIST.map((item) => ({
                      label: t(item.label, { count: item.value }),
                      value: item.value,
                    }))}
                    value={availability}
                    onChange={setAvailability}
                  />
                </InputWithLabel>
              </FormCard>
              {membershipId ? (
                <>
                  <Spacer size="24" />
                  <FormCard
                    rounded
                    dropShaddow
                    paddings="none"
                    style={{ paddingHorizontal: 16, paddingVertical: 16, marginHorizontal: 16 }}
                  >
                    <TouchableOpacity
                      style={{ flexDirection: 'row' }}
                      onPress={() => navigation.navigate('MembershipItems', { membershipId })}
                    >
                      <Column>
                        <ItemsIcon />
                      </Column>
                      <Column style={{ paddingLeft: 12, flex: 1 }}>
                        <Title>{t('items')}</Title>
                        <Row alignItems="center">
                          <Body style={{ color: colors.COMET }}>
                            {t('itemsPrice', { value: withDiscount.toLocaleString(), currency: t('RON') })}
                            {` `}
                          </Body>
                          {totalPrice !== withDiscount ? (
                            <SmallBody
                              style={{
                                textDecorationLine: 'line-through',
                                color: colors.COMET,
                              }}
                            >
                              ({scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')})
                            </SmallBody>
                          ) : null}
                        </Row>
                      </Column>
                      <Column>
                        <Icon type="next" color={colors.DARK_BLUE} />
                      </Column>
                    </TouchableOpacity>
                  </FormCard>
                  <Spacer size="24" />
                  <Button
                    backgroundColor={colors.ALABASTER}
                    color={colors.RADICAL_RED}
                    text={t('deleteMembershipButton')}
                    onClick={deleteMembership}
                  />
                </>
              ) : null}
              <Spacer size={96} />
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button text={t(membershipId ? 'saveChanges' : 'continue')} onClick={membershipId ? save : onNext} />
            ) : (
              <Button text={t(membershipId ? 'saveChanges' : 'continue')} onClick={membershipId ? save : onNext} />
            )}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
      {showDeleteDialog && membershipId ? (
        <DeleteMembershipDialog
          membershipId={membershipId}
          onSuccess={flow(onDeleteSuccess, hideDeleteDialog)}
          onCancel={hideDeleteDialog}
        />
      ) : null}
    </>
  );
};

export default pipe(MembershipDetailsScreen, CurrentBusiness);

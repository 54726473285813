import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CalendarToday = ({ size = 24, color = BLACK, disabled, onPress }: Props) => (
  <Svg
    id="Icons_24px_Black_Calendar"
    data-name="Icons/24px/Black/Calendar"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    disabled={disabled}
    onPress={onPress}
  >
    <G id="calendar-date" transform="translate(0 0)">
      <Path
        id="Shape"
        d="M23,23H1a1,1,0,0,1-1-1V4A1,1,0,0,1,1,3H6V1A1,1,0,0,1,8,1V3h8V1a1,1,0,1,1,2,0V3h5a1,1,0,0,1,1,1V22A1,1,0,0,1,23,23ZM2,7V21H22V7Zm4.5,7A2.5,2.5,0,1,1,9,11.5,2.5,2.5,0,0,1,6.5,14Z"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(CalendarToday);

import * as React from 'react';
import Svg, { Rect, Path, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Apple: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G id="Group_4265" data-name="Group 4265" transform="translate(-40 -566)">
      <Rect id="bg" width="32" height="32" transform="translate(40 566)" fill="none" />
      <Path
        id="Path_3230"
        data-name="Path 3230"
        d="M10.02,8.4a8.553,8.553,0,0,1-1.739-.378,8.312,8.312,0,0,0-2.244-.378A4.419,4.419,0,0,0,2.432,9.461,7.525,7.525,0,0,0,1.02,14.175a12.637,12.637,0,0,0,1.866,6.353Q4.751,23.78,6.667,23.78a4.806,4.806,0,0,0,1.664-.429,4.816,4.816,0,0,1,1.815-.4,5.868,5.868,0,0,1,1.891.378,5.556,5.556,0,0,0,1.79.4q1.639,0,3.252-2.445a13.514,13.514,0,0,0,.908-1.6,13.664,13.664,0,0,0,.63-1.55,3.853,3.853,0,0,1-1.992-1.588,4.809,4.809,0,0,1-.882-2.824,4.349,4.349,0,0,1,.832-2.6,8.785,8.785,0,0,1,1.437-1.513,6.76,6.76,0,0,0-.655-.706q-.328-.3-.655-.555A4.68,4.68,0,0,0,14,7.545a7.144,7.144,0,0,0-2.193.429A6.852,6.852,0,0,1,10.02,8.4Zm3.857-5.092q0-.1-.013-.227a1.774,1.774,0,0,0-.063-.3,4.982,4.982,0,0,0-3.164,1.878A5.715,5.715,0,0,0,9.667,7.9a8.307,8.307,0,0,0,1.286-.2,4.066,4.066,0,0,0,1.387-.908A5.435,5.435,0,0,0,13.5,5.049,4.651,4.651,0,0,0,13.877,3.309Z"
        transform="translate(45.98 568.22)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(Apple);

import { StrictPhoneNumberParsed } from '@mero/api-sdk';
import {
  Button as MeroButton,
  Icon,
  Spacer,
  CheckboxLayout,
  AvoidKeyboard,
  DismissKeyboard,
  MeroHeader,
  useShowError,
  TypeSafeTextInput,
  InputWithLabel,
  SmallBody,
  styles as meroStyles,
  H1,
  colors,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import * as React from 'react';
import { View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { Recaptcha, RecaptchaRefProps } from '../components/Recaptcha';
import { WHITE } from '@mero/components/lib/styles/colors';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';
import useGoBack from '../../../hooks/useGoBack';

import config from '../../../config';
import { AnonymousStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import { NovaAuthContext } from '../NovaAuthContext';
import { SigningContext } from '../SigningContext';
import { styles } from './styles';

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AnonymousStackParamList, 'SignUpPhoneScreen'>,
    StackNavigationProp<RootStackParamList, 'Anonymous'>
  >;
};

const SignUpPhoneScreen: React.FC<Props> = ({ navigation }) => {
  const recaptchaRef = React.createRef<RecaptchaRefProps>();
  const [signingState, signing] = SigningContext.useContext();
  const [state, dispatch] = NovaAuthContext.useContext();
  const showError = useShowError();
  const { logAction, logEvent } = useAnalytics({
    eventName: 'pro_onboarding_enter_phone_screen_shown',
    screenName: 'pro_onboarding_enter_phone',
    staticData: {
      trigger_source: 'register',
    },
  });

  const [agreeWithTerms, setAgreeWithTerms] = React.useState(false);
  const [agreeWithPersonalData, setAgreeWithPersonalData] = React.useState(false);
  const agree = agreeWithTerms && agreeWithPersonalData;

  const isSignedIn = signingState.type === 'Ready' && signingState.novaToken !== undefined;

  const phoneInput = state.type === 'New' ? state.phone.input : state.phone;
  const [showErrors, setShowErrors] = React.useState(false);
  const phoneNumberIsInvalid = state.type === 'New' && E.isLeft(state.phone.decoded);

  const sendingCode = state.type === 'SendingCode';
  const signInProgress = signingState.type === 'SignInProgress';
  const canRegisterUsingToken = isSignedIn && !signInProgress && agree;

  const sendVerificationCode = (recaptchaToken: string) => {
    setShowErrors(true);

    const phone =
      state.type === 'New' && E.isRight(state.phone.decoded)
        ? state.phone.decoded.right
        : state.type === 'CodeSent'
        ? state.phone
        : undefined;

    if (phone && agree) {
      const sendCode = async () => {
        try {
          await dispatch.sendVerificationCode({
            phone,
            recaptchaSiteKey: config.google.recaptchaSiteKey,
            recaptchaToken,
            recaptchaAction: '',
          });

          navigation.navigate('SignUpOtpScreen');
        } catch (e) {
          showError(e, 'Failed to send SMS code');
        }
      };

      sendCode();
    }
  };

  const registerUsingToken = () => {
    if (signingState.type === 'Ready' && signingState.novaToken !== undefined) {
      log.debug('SignUpPhoneScreen: signingState.type === Ready and novaToken is defined, registering...');
      signing.registerNovaAuth({
        novaToken: signingState.novaToken.token,
        firstName: '',
        lastName: '',
      });
    }
  };

  const openToS = () => {
    navigation.navigate('TermsOfServiceScreen');
  };

  const openPrivacyTerms = () => {
    navigation.navigate('PrivacyPolicyScreen');
  };

  const goBack = useGoBack(
    React.useCallback(() => {
      navigation.navigate('SignUpScreen');
    }, [navigation]),
  );

  React.useEffect(() => {
    // Not registered error should redirect here
    if (signingState.type === 'NotRegistered') {
      signing.stopNotRegistered();
    }
  }, [signingState]);

  React.useEffect(() => {
    try {
      if (state.type === 'CodeSent') {
        logEvent('pro_sms_sent', {
          message_type: 'otp_code',
          provider: 'paid_sms',
        });
      }
    } catch (error) {
      log.error('Failed to log event', error);
    }
  }, [state]);

  /**
   * Callback used to send sms code when
   * recaptcha token is ready.
   */
  const onRecaptchaToken = React.useCallback(
    (token: string) => {
      sendVerificationCode(token);
    },
    [sendVerificationCode],
  );

  return (
    <ModalScreenContainer>
      <MeroHeader canGoBack onBack={goBack} />
      <AvoidKeyboard style={styles.avoidKeyboard}>
        <DismissKeyboard style={styles.dismissKeyboard}>
          <H1 style={meroStyles.text.alignCenter}>Introdu numărul tău de telefon</H1>
          <Spacer size={32} />
          <View style={{ width: '100%', maxWidth: 480 }}>
            <InputWithLabel
              label="Telefon mobil"
              errorText="Introdu un număr de telefon valid"
              isError={showErrors && phoneNumberIsInvalid}
              highlightLabelOnError={false}
            >
              <TypeSafeTextInput
                value={phoneInput}
                codec={StrictPhoneNumberParsed}
                placeholder="Introdu nr. tău de telefon"
                onChange={dispatch.setPhone}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
                editable={!sendingCode && !isSignedIn}
                showError={showErrors}
              />
            </InputWithLabel>
            <Spacer size={24} />
            <CheckboxLayout value={agreeWithTerms} onValueChange={setAgreeWithTerms}>
              <SmallBody>
                Sunt de acord cu{' '}
                <SmallBody style={meroStyles.text.link} onPress={openToS}>
                  termenii și condițiile
                </SmallBody>{' '}
                de utilizare
              </SmallBody>
              {showErrors && !agreeWithTerms && (
                <>
                  <Spacer size="4" />
                  <SmallBody style={{ color: colors.RADICAL_RED, fontSize: 12 }}>
                    Trebuie să accepți termenii și condițiile
                  </SmallBody>
                </>
              )}
            </CheckboxLayout>
            <Spacer size={18} />
            <CheckboxLayout value={agreeWithPersonalData} onValueChange={setAgreeWithPersonalData}>
              <SmallBody>
                Sunt de acord cu prelucrarea datelor personale conform{' '}
                <SmallBody style={meroStyles.text.link} onPress={openPrivacyTerms}>
                  politicii de confidențialitate
                </SmallBody>
              </SmallBody>
              {showErrors && !agreeWithPersonalData && (
                <>
                  <Spacer size="4" />
                  <SmallBody style={{ color: colors.RADICAL_RED, fontSize: 12 }}>
                    Trebuie să accepți politica de confidențialitate
                  </SmallBody>
                </>
              )}
            </CheckboxLayout>
            <Spacer size={32} />
            {isSignedIn ? (
              <MeroButton
                size="large"
                text={'Continuă'}
                onClick={registerUsingToken}
                disabled={!canRegisterUsingToken}
                RightComponent={() => <Icon type="next" color={WHITE} />}
                containerStyle={{ width: '100%' }}
              />
            ) : (
              <MeroButton
                size="large"
                text={sendingCode ? 'Trimitem codul de verificare ...' : 'Trimite cod verificare'}
                onClick={() => recaptchaRef.current?.run()}
                RightComponent={() => <Icon type="next" color={WHITE} />}
                containerStyle={{ width: '100%' }}
              />
            )}
          </View>
          <Recaptcha ref={recaptchaRef} onToken={onRecaptchaToken}></Recaptcha>
        </DismissKeyboard>
      </AvoidKeyboard>
    </ModalScreenContainer>
  );
};

export default SignUpPhoneScreen;

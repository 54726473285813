import * as React from 'react';
import { Pressable } from 'react-native';

import { Column, Label, Row, Title } from '../../../../../../components/shared';

import { sizes } from '../../../../../../styles';
import CopyIcon from '../CopyIcon';
import SendIcon from '../SendIcon';
import WhatsAppIcon from '../WhatsAppIcon';
import styles from './styles';

interface ClientDetailsListItemProps {
  label: string;
  value?: string;
  onWhatAppPress?: () => void;
  onSendPress?: () => void;
  onCopyPress?: () => void;
}

const ClientDetailsListItem: React.FC<ClientDetailsListItemProps> = ({
  label,
  value,
  onWhatAppPress,
  onSendPress,
  onCopyPress,
}) => {
  if (!value) {
    return null;
  }

  return (
    <Row style={styles.content}>
      <Column flex={1}>
        <Label style={styles.itemTitle}>{label}</Label>
        <Row justifyContent="space-between" alignItems="center" gap={8}>
          <Title numberOfLines={1} ellipsizeMode={'tail'}>
            {value}
          </Title>
          <Row gap={sizes[12]}>
            {onSendPress && (
              <Pressable onPress={onSendPress}>
                <SendIcon />
              </Pressable>
            )}
            {onWhatAppPress && (
              <Pressable onPress={onWhatAppPress}>
                <WhatsAppIcon />
              </Pressable>
            )}
            {onCopyPress && (
              <Pressable onPress={onCopyPress}>
                <CopyIcon />
              </Pressable>
            )}
          </Row>
        </Row>
      </Column>
    </Row>
  );
};

export default ClientDetailsListItem;

import {
  Body,
  colors,
  ConfirmBox,
  H1,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  styles as meroStyles,
} from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  endDate: DateTime;
};

const CancelBoostDialog: React.FC<Props> = ({ onSuccess, onCancel, endDate }) => {
  const { t } = useTranslation('marketing');

  const [pageState] = CurrentBusinessContext.useContext();

  const [changeInProgress, setChangeInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
  });

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    try {
      if (pageState.type === 'Loaded') {
        await meroApi.boost.disableAutomaticReactivation(pageState.page.details._id);
        onSuccess();
      }
    } catch (error) {
    } finally {
      setChangeInProgress(false);
    }
  };

  const leftAction = {
    text: t('close'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: t('cancelBoost'),
    onPress: Object.values(checks).some((v) => !v) || changeInProgress ? undefined : confirm,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="info"
        headerTitle={t('confirmCancelBoost')}
        icon="info"
        canClose={!changeInProgress}
        onClose={cancel}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>{t('confirmCancelBoostDescription')}</H1>
        <Spacer size="8" />
        <Body style={[{ textTransform: 'uppercase' }, meroStyles.text.semibold]}>
          {t('cancelBoostEnd', { date: endDate.toFormat('dd.MM.yyyy') })}
        </Body>
        <Spacer size="16" />
        <TouchableOpacity onPress={toggleCheck('check1')} disabled={changeInProgress}>
          <SimpleListItem icon={checks.check1 ? 'checked' : 'unchecked'} paddingTop={0} paddingBottom={0}>
            <Body style={{ color: colors.NILE_BLUE }}>
              <Trans
                ns={'marketing'}
                t={t}
                i18nKey="cancelBoostCheck1"
                values={{ date: endDate.toFormat('dd.MM.yyyy') }}
              >
                0
              </Trans>
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default CancelBoostDialog;

import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as React from 'react';

type SelectBookingPerformerState =
  | {
      type: 'none';
      workersFilter: (w: SavedWorker) => boolean;
    }
  | {
      type: 'selected';
      selected: SavedWorker;
      workersFilter: (w: SavedWorker) => boolean;
    };

const defaultValue = (): SelectBookingPerformerState => ({
  type: 'none',
  workersFilter: () => true,
});

export const SelectBookingPerformerContext = createModelContext(
  defaultValue(),
  {
    reset: defaultValue,
    setSelectedPerformer: (state, selected: SavedWorker) => ({
      type: 'selected',
      selected,
      workersFilter: state.workersFilter,
    }),
    setWorkersFilter: (state, workersFilter: (w: SavedWorker) => boolean) => ({
      ...state,
      workersFilter,
    }),
  },
  (dispatch) => ({
    reset: dispatch.reset,
    setSelectedPerformer: dispatch.setSelectedPerformer,
    setWorkersFilter: dispatch.setWorkersFilter,
  }),
);

export const withSelectBookingPerformerContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithSelectBookingPerformerContextProvider(props: P) {
    return (
      <SelectBookingPerformerContext.Provider>
        <Content {...props} />
      </SelectBookingPerformerContext.Provider>
    );
  };
};

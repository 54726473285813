import { MeroHeader, SafeAreaView } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { BackHandler } from 'react-native';
import { Platform } from 'react-native';

import { styles } from '../../../components/AddBookingScreen/styles';
import MeroWebView, { WebViewHandle } from '../../../components/MeroWebView';
import { WHITE } from '@mero/components/lib/styles/colors';

import { useFocusEffect, useIsFocused } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import log from '../../../utils/log';

type Props = CurrentBusinessProps &
  AuthorizedProps & {
    readonly baseUrl: string;
    readonly title: string;
    readonly onClose?: () => void;
  };

const MenuScreenWebView: React.FC<Props> = ({ page, authorization, baseUrl, title, onClose }: Props) => {
  const source = React.useMemo(() => (Platform.OS === 'web' ? 'web' : 'app'), []);
  const [webViewCanGoBack, setWebViewCanGoBack] = React.useState(false);
  const currentPageId = page.details._id;
  const [prevPageId, setPrevPageId] = React.useState(currentPageId);
  const [pageId, setPageId] = React.useState(page.details._id);
  const isFocused = useIsFocused();
  const screenGoBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const [, { reloadAsync: reloadBusinessAsync }] = CurrentBusinessContext.useContext();

  const [focusState, setFocusState] = React.useState(isFocused);

  const webViewRef = React.useRef<WebViewHandle>(null);

  const webViewGoBack = React.useCallback(() => {
    webViewRef.current?.goBack();
  }, [webViewRef]);

  const goBack = React.useCallback(() => {
    if (webViewCanGoBack) {
      webViewGoBack();
    } else {
      screenGoBack();
      if (onClose) onClose();
    }
  }, [webViewCanGoBack, webViewGoBack, screenGoBack]);

  React.useEffect(() => {
    // avoid reloading on first load
    if (prevPageId !== currentPageId) {
      setPrevPageId(currentPageId);
      webViewRef.current?.reload();
    }
  }, [currentPageId, prevPageId]);

  // TODO: move this to webview?
  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (webViewCanGoBack) {
          webViewGoBack();
          return true;
        } else {
          return false;
        }
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [webViewCanGoBack]),
  );

  // Update webview only when in foreground
  React.useEffect(() => {
    if (isFocused && pageId !== page.details._id) {
      setPageId(page.details._id);
    }
  }, [isFocused, pageId, page.details._id]);

  // Reload some info when menu screen lost focus
  React.useEffect(() => {
    if (focusState !== isFocused) {
      setFocusState(isFocused);

      if (!isFocused) {
        log.debug('MenuScreen lost focus, reload team');
        reloadBusinessAsync();
      }
    }
  }, [focusState, isFocused, pageId]);

  return React.useMemo(
    () => (
      <SafeAreaView style={[{ flex: 1, backgroundColor: WHITE }, !isPhone && styles.modalBorder]}>
        <MeroHeader title={title} canGoBack onBack={goBack} />
        <MeroWebView
          ref={webViewRef}
          key={`${pageId}-${authorization.grant.accessToken}`}
          style={{ flex: 1 }}
          containerStyle={{ flex: 1, backgroundColor: WHITE }}
          source={{
            uri: `${baseUrl}${baseUrl.indexOf('?') !== -1 ? '&' : '?'}hideLayout=true&pageId=${encodeURIComponent(
              pageId,
            )}&source=${source}`,
          }}
          handleBack={true}
          onCanGoBackChange={setWebViewCanGoBack}
        />
      </SafeAreaView>
    ),
    [pageId, authorization.grant.accessToken, webViewRef, setWebViewCanGoBack],
  );
};

export default pipe(MenuScreenWebView, Authorized, CurrentBusiness);

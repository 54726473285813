import { CalendarEntry } from '@mero/api-sdk';
import { AppointmentStatus, colors, Column, Line, Row, SmallBody, Spacer, Title } from '@mero/components';
import { H1, H2s } from '@mero/components';
import { formatDurationInMinutes, formatPhoneNumber } from '@mero/shared-components';
import * as luxon from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import Icon from '@mero/components/lib/components/Icon';

import { styles } from './CheckoutAppointmentSummary.styles';

export type AppointmentDate = {
  readonly date: Date;
  timezone?: string;
  size?: 'small' | 'large';
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const AppointmentDate: React.FC<AppointmentDate> = ({ date, timezone, size = 'small' }) => {
  const [dateDisplayStr, setDateDisplayStr] = React.useState('');

  React.useEffect(() => {
    // format start date
    const luxonDate = luxon.DateTime.fromJSDate(date).setLocale('ro').setZone(timezone);
    const dayOfWeek = capitalizeFirstLetter(luxonDate.toFormat('EEE').substr(0, 2).concat('.'));
    const dayAndMonth = luxonDate.toFormat('d MMM');
    const startHour = luxonDate.toFormat('HH:mm');

    if (size === 'small') {
      setDateDisplayStr(`${dayOfWeek} ${dayAndMonth}`);
    } else {
      setDateDisplayStr(`${dayOfWeek} ${dayAndMonth} - ${startHour}`);
    }
  }, []);

  if (size === 'small') return <H2s>{dateDisplayStr}</H2s>;

  return <H1>{dateDisplayStr}</H1>;
};

export type Props = {
  appointment: CalendarEntry.Appointment;
  withClient: boolean;
  onPress: (appointment: CalendarEntry.Appointment) => void;
};

export default function CheckoutAppointmentSummary({ appointment, onPress, withClient }: Props): React.ReactElement {
  const { t } = useTranslation('checkout');
  const [workerNameText, setWorkerNameText] = React.useState('');

  React.useEffect(() => {
    setWorkerNameText(`${appointment.payload.worker.firstname} ${appointment.payload.worker.lastname ?? ''}`);
  }, []);

  return (
    <>
      <TouchableOpacity style={styles.appointmentInfoContainer} onPress={() => onPress(appointment)}>
        {withClient && (
          <Row
            style={{
              paddingHorizontal: 16,
              paddingVertical: 8,
              backgroundColor: colors.ATHENS_GRAY,
              alignItems: 'center',
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <Title style={{ fontSize: 14 }}>
              {appointment.payload.client?.firstname
                ? `${appointment.payload.client.firstname}${
                    appointment.payload.client.lastname ? ` ${appointment.payload.client.lastname} ` : ''
                  }`
                : t('noClient')}
            </Title>
            <SmallBody>
              {appointment.payload.user?.phone ? ` - ${formatPhoneNumber(appointment.payload.user.phone)}` : ''}
            </SmallBody>
          </Row>
        )}
        <Spacer size="16" />
        <Column style={styles.appointmentInfoCard}>
          <Row style={{ alignItems: 'center' }}>
            <Column style={{ flex: 1 }}>
              <AppointmentStatus status={appointment.payload.status} inPast />
              <Spacer size="4" />
              <AppointmentDate date={appointment.start} />
            </Column>
            <Icon type="next" color={colors.DARK_BLUE} />
          </Row>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          {appointment.payload.bookedServices.map((sv) => (
            <React.Fragment key={sv._id}>
              <View style={[styles.container]}>
                {/* info */}
                <View style={[styles.infoContainer]}>
                  <Title>{sv.name}</Title>

                  <SmallBody>{workerNameText}</SmallBody>
                  <Spacer size="6" />
                  <SmallBody style={styles.dateLabel}>
                    {typeof sv.durationInMinutes !== 'undefined'
                      ? formatDurationInMinutes(sv.durationInMinutes)
                      : '0 min'}
                  </SmallBody>
                </View>

                {/* prices */}
                <View style={[styles.priceContainer]}>
                  {sv.price.type === 'hidden' && <Title>Preț variabil</Title>}
                  {sv.price.type === 'range' && (
                    <Title>
                      {sv.price.range.from} - {sv.price.range.to} lei
                    </Title>
                  )}
                  {sv.price.type === 'fixed' ? (
                    sv.price.promo ? (
                      <>
                        <Title style={{ color: colors.RADICAL_RED }}>{sv.price.promo} lei</Title>
                        <SmallBody
                          style={{ color: colors.COMET, fontFamily: 'open-sans', textDecorationLine: 'line-through' }}
                        >
                          {sv.price.fixed} lei
                        </SmallBody>
                      </>
                    ) : (
                      <Title>{sv.price.fixed} lei</Title>
                    )
                  ) : null}
                </View>
              </View>
              {appointment.payload.bookedServices.length > 1 && <Spacer size={16} />}
            </React.Fragment>
          ))}
        </Column>
      </TouchableOpacity>
      <Spacer size={16} />
    </>
  );
}

import { AppointmentRequestInvitePreview } from '@mero/api-sdk/dist/invites';
import { Body, SmallBody, styles as meroStyles, HSpacer, colors, Spacer, Button, Avatar, Line } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { styles } from './styles';

type Props = {
  readonly invite: AppointmentRequestInvitePreview;
  readonly onViewDetails: (request: AppointmentRequestInvitePreview) => void;
  readonly onReject: (request: AppointmentRequestInvitePreview) => void;
  readonly disabled?: boolean;
};

const AppointmentRequestInviteView: React.FC<Props> = ({ invite, onViewDetails, onReject, disabled }: Props) => {
  const { t } = useTranslation('notifications');

  const appTs = DateTime.fromJSDate(invite.appointment.start, { zone: invite.toWorker.calendar.settings.timezone });

  return (
    <View style={styles.horizontalPadding}>
      <View style={[styles.verticalPadding, styles.row]}>
        <Avatar
          source={invite.fromUser.profile.photo?.small}
          firstname={invite.fromUser.profile.firstname ?? ''}
          lastname={invite.fromUser.profile.lastname ?? ''}
        />
        <View style={styles.notificationBody}>
          <Body>
            <Body style={meroStyles.text.semibold}>
              {invite.fromUser.profile.firstname} {invite.fromUser.profile.lastname}
            </Body>{' '}
            vrea o programare în{' '}
            <Body style={meroStyles.text.semibold}>{appTs.toFormat('d LLL yyyy', { locale: 'ro' })}</Body> ora{' '}
            <Body style={meroStyles.text.semibold}>{appTs.toFormat('H:mm', { locale: 'ro' })}</Body>.
          </Body>
          <SmallBody style={styles.timeText}>
            {formatTimeDiff(invite.createdAt, new Date())} · {invite.toWorker.user.firstname}{' '}
            {invite.toWorker.user.lastname}
          </SmallBody>
          <Spacer size="12" />
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Button
              size="small"
              text={t('viewDetails')}
              containerStyle={{ flex: 1, maxWidth: 200 }}
              onClick={() => onViewDetails(invite)}
              disabled={disabled}
            />
            <HSpacer left={8} />
            <Button
              size="small"
              text={t('reject')}
              containerStyle={{ flex: 1, maxWidth: 200 }}
              color={disabled ? colors.COMET : colors.DARK_BLUE}
              backgroundColor={colors.SKY_BLUE}
              disabledBackgroundColor={colors.SKY_BLUE}
              onClick={() => onReject(invite)}
              disabled={disabled}
            />
          </View>
        </View>
      </View>
      <Line />
    </View>
  );
};

export default AppointmentRequestInviteView;

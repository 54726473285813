import { StrictPhoneNumberParsed } from '@mero/api-sdk';
import {
  Button as MeroButton,
  Spacer,
  Icon,
  AvoidKeyboard,
  DismissKeyboard,
  MeroHeader,
  useShowError,
  styles as meroStyles,
  H1,
  InputWithLabel,
  TypeSafeTextInput,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import * as React from 'react';
import { View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { Recaptcha, RecaptchaRefProps } from '../components/Recaptcha';
import { WHITE } from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';
import useGoBack from '../../../hooks/useGoBack';

import config from '../../../config';
import { AnonymousStackParamList } from '../../../types';
import { NovaAuthContext } from '../NovaAuthContext';
import { SigningContext } from '../SigningContext';
import { styles } from './styles';

type Props = StackScreenProps<AnonymousStackParamList, 'SignInPhoneScreen'>;

const SignInPhoneScreen: React.FC<Props> = ({ navigation }) => {
  const recaptchaRef = React.createRef<RecaptchaRefProps>();
  const [signingState] = SigningContext.useContext();
  const [state, dispatch] = NovaAuthContext.useContext();
  const showError = useShowError();
  const sendingCode = state.type === 'SendingCode';

  const [showErrors, setShowErrors] = React.useState(false);
  const phoneNumberIsInvalid = state.type === 'New' && E.isLeft(state.phone.decoded);

  const { logAction, logEvent } = useAnalytics({
    eventName: 'pro_onboarding_enter_phone_screen_shown',
    screenName: 'pro_onboarding_enter_phone',
    staticData: {
      trigger_source: 'login',
    },
  });

  const phoneInput = state.type === 'New' ? state.phone.input : state.phone;

  const sendVerificationCode = (recaptchaToken: string) => {
    setShowErrors(true);
    const phone =
      state.type === 'New' && E.isRight(state.phone.decoded)
        ? state.phone.decoded.right
        : state.type === 'CodeSent'
        ? state.phone
        : undefined;

    if (phone) {
      const sendCode = async () => {
        try {
          await dispatch.sendVerificationCode({
            phone,
            recaptchaSiteKey: config.google.recaptchaSiteKey,
            recaptchaToken,
            recaptchaAction: '',
          });

          navigation.navigate('SignInOtpScreen');
        } catch (e) {
          showError(e, 'Failed to send SMS code');
        }
      };

      sendCode();
    }
  };

  const goBack = useGoBack(
    React.useCallback(() => {
      navigation.navigate('SignInScreen');
    }, [navigation]),
  );

  React.useEffect(() => {
    if (signingState.type === 'NotRegistered') {
      navigation.navigate('SignUpPhoneScreen');
    }
  }, [signingState]);

  /**
   * Callback used to send sms code when
   * recaptcha token is ready.
   */
  const onRecaptchaToken = React.useCallback(
    (token: string) => {
      sendVerificationCode(token);
    },
    [sendVerificationCode],
  );

  return (
    <ModalScreenContainer>
      <MeroHeader canGoBack onBack={goBack} />
      <AvoidKeyboard style={styles.avoidKeyboard}>
        <DismissKeyboard style={styles.dismissKeyboard}>
          <H1 style={meroStyles.text.alignCenter}>Introdu numărul tău de telefon</H1>
          <Spacer size={32} />
          <View style={{ width: '100%', maxWidth: 480 }}>
            <InputWithLabel
              label="Telefon mobil"
              errorText="Introdu un număr de telefon valid"
              isError={showErrors && phoneNumberIsInvalid}
              highlightLabelOnError={false}
            >
              <TypeSafeTextInput
                value={phoneInput}
                codec={StrictPhoneNumberParsed}
                placeholder="Introdu nr. tău de telefon"
                onChange={dispatch.setPhone}
                textContentType="telephoneNumber"
                keyboardType="phone-pad"
                editable={!sendingCode}
                showError={showErrors}
              />
            </InputWithLabel>
            <Spacer size={24} />
            <MeroButton
              size="large"
              text={sendingCode ? 'Trimitem codul de verificare ...' : 'Trimite cod verificare'}
              onClick={() => recaptchaRef.current?.run()}
              RightComponent={() => <Icon type="next" color={WHITE} />}
              containerStyle={{ width: '100%' }}
            />
          </View>
          <Recaptcha ref={recaptchaRef} onToken={onRecaptchaToken}></Recaptcha>
        </DismissKeyboard>
      </AvoidKeyboard>
    </ModalScreenContainer>
  );
};

export default SignInPhoneScreen;

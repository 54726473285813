import { CouponType } from '@mero/api-sdk';
import { colors, Icon } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

type Props = {
  readonly couponTypes: CouponType[];
};

const DiscountIcons: React.FC<Props> = ({ couponTypes = [] }) => {
  return (
    <>
      {(couponTypes.includes(CouponType.GIFTCARD) || couponTypes.includes(CouponType.VOUCHER)) && (
        <View style={{ marginRight: 4 }}>
          <Icon type="calendar-gift-card" color={colors.BLACK} />
        </View>
      )}
      {couponTypes.some(
        (couponType) =>
          couponType === CouponType.DISCOUNT ||
          ![CouponType.GIFTCARD, CouponType.VOUCHER, CouponType.DISCOUNT].includes(couponType),
      ) && (
        <View style={{ marginRight: 4 }}>
          <Icon type="calendar-discount" color={colors.BLACK} />
        </View>
      )}
    </>
  );
};

export default DiscountIcons;

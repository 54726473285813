import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  horizontalPadding: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  verticalPadding: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  row: {
    flexDirection: 'row',
  },
  notificationBody: {
    flexDirection: 'column',
    flex: 1,
    marginLeft: 16,
  },
  timeText: {
    marginTop: 6,
    color: colors.COMET,
    fontSize: 12,
    lineHeight: 17,
  },
});

import { ServiceGroupId, ServiceId } from '@mero/api-sdk/dist/services';
import {
  ModalOverlay,
  DismissKeyboard,
  ConfirmBox,
  H1,
  Spacer,
  Select,
  useToast,
  useShowError,
} from '@mero/components';
import { isNull } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import log from '../../../utils/log';

type Props = {
  serviceIds: ServiceId[];
  onClose: () => void;
};
const UpdateServicesGroupDialog: React.FC<Props> = ({ onClose, serviceIds }) => {
  const toast = useToast();
  const showError = useShowError();
  const { t } = useTranslation('services');

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();
  const [group, setGroup] = React.useState<ServiceGroupId | undefined>();

  const [{ serviceGroups }, { reload }] = ServicesContext.useContext();

  const bulkUpdateGroupBulkOperation = async (groupId: ServiceGroupId | undefined) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesGroup({
        pageId: pageState.page.details._id,
        serviceIds: serviceIds,
        groupId: groupId === '' ? undefined : groupId,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);

      onClose();
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  const cancelGroupLeftAction = {
    text: t('cancel'),
    onPress: () => onClose,
    flex: 10,
  };

  const groups = React.useMemo(
    () => [
      ...serviceGroups.map((group) => ({ value: group._id, label: group.name })),
      { value: '' as ServiceGroupId, label: 'Alte servicii' },
    ],
    [serviceGroups],
  );

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={t('updateServicesGroup')}
              canClose={true}
              onClose={onClose}
              leftAction={cancelGroupLeftAction}
              rightAction={{
                text: t('save'),
                onPress: () => bulkUpdateGroupBulkOperation(group),
                flex: 15,
              }}
              style={{
                width: 375,
              }}
            >
              <H1>{t('updateServicesGroup')}</H1>
              <Spacer size={24} />
              <Select
                placeholder={t('groupLabel')}
                items={groups}
                value={group}
                onChange={(v) => setGroup(isNull(v) ? undefined : v)}
              />
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default UpdateServicesGroupDialog;

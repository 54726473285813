import { InventoryId, InventoryOperation, ProductImage, ProductStatus, SavedInventoryOperation } from '@mero/api-sdk';
import {
  Body,
  Button,
  Checkbox,
  Column,
  ConfirmBox,
  DismissKeyboard,
  FormCard,
  H1,
  H3s,
  HSpacer,
  Line,
  MeroHeader,
  ModalOverlay,
  Row,
  SafeAreaView,
  SmallBody,
  Spacer,
  colors,
  formatTimeDiff,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, KeyboardAvoidingView, ScrollView, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, Circle, G, Path } from 'react-native-svg';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp, NavigationProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import { ProductEditStackParamList } from '../../../types';
import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';
import AdjustProductStockScreen from './AdjustProductStockScreen';

type Props = {
  route: RouteProp<ProductEditStackParamList, 'ProductStock'>;
  navigation: NavigationProp<ProductEditStackParamList, 'ProductStock'>;
};

const HistoryIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Group 5901" {...props}>
    <Circle cx={16} cy={16} r={16} fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path fill="none" d="M7 7h18v18H7z" data-name="Rectangle 2638" />
    </G>
    <G data-name="Group 6849">
      <G data-name="Group 6848">
        <G data-name="Group 6847">
          <Path
            fill="#52577f"
            d="M22.75 14.59h-5.085l2.055-2.115a5.279 5.279 0 0 0-7.41-.075 5.156 5.156 0 0 0 0 7.343 5.265 5.265 0 0 0 7.41 0 4.885 4.885 0 0 0 1.53-3.668h1.5a7.006 7.006 0 0 1-1.98 4.717 6.79 6.79 0 0 1-9.54 0 6.63 6.63 0 0 1-.015-9.435 6.74 6.74 0 0 1 9.488 0L22.75 9.25ZM16.375 13v3.188L19 17.75l-.54.908-3.21-1.908V13Z"
            data-name="Path 9066"
          />
        </G>
      </G>
    </G>
  </Svg>
);

const ProductStockScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const LIMIT = 10;
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const [pageState] = CurrentBusinessContext.useContext();
  const [product, { update: updateProduct }] = SelectedProductContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();

  const [operations, setOperations] = useState<SavedInventoryOperation[]>([]);
  const [next, setNext] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(false);
  const [inventoryId, setInventoryId] = useState<InventoryId | undefined>();
  const [isStockManagementEnabled, setIsStockManagementEnabled] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDisableStockWarning, setshowDisableStockWarning] = useState(false);
  const [isAdjustEnabled, setIsAdjustEnabled] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }

      const pageId = pageState.page.details._id;
      const inventories = await meroApi.pro.inventories.getAllInventories(pageId);

      if (!inventories.length) {
        return;
      }

      try {
        const [product, stock, isStockManagementEnabled] = await Promise.all([
          meroApi.pro.products.getProductById({
            pageId: pageId,
            productId: route.params.productId,
          }),
          meroApi.pro.inventories.getProductInventoryStock({
            pageId: pageId,
            productId: route.params.productId,
            inventoryId: inventories[0]._id,
          }),
          meroApi.pro.inventories.getProductStatus({
            pageId: pageId,
            productId: route.params.productId,
          }),
        ]);

        let image: ProductImage | undefined;
        if (product.gallery.length) {
          const sortedGallery = product.gallery.sort((image1, image2) => image1.order - image2.order);
          image = sortedGallery[0].image;
        }
        updateProduct({
          details: product,
          stock: ScaledNumber.toNumber(stock.quantity),
          image: image,
        });
        setInventoryId(inventories[0]._id);
        setIsStockManagementEnabled(isStockManagementEnabled);
        setIsAdjustEnabled(isStockManagementEnabled);
      } catch (e) {
        log.info('Failed to fetch product stock', e);
      }
    };
    init();
  }, [pageState]);

  useEffect(() => {
    loadOperations();
  }, [pageState, inventoryId, product?.stock]);

  const loadOperations = async () => {
    if (pageState.type !== 'Loaded' || !inventoryId) {
      return;
    }

    try {
      const result = await meroApi.pro.inventories.getProductOperationsHistory({
        pageId: pageState.page.details._id,
        productId: route.params.productId,
        inventoryId: inventoryId,
        limit: LIMIT,
        page: next,
      });

      setOperations(result.data);
      setNext(result.next);
    } catch (e) {
      log.info('Failed to fetch product stock operations', e);
    }
  };

  const loadMore = async () => {
    if (pageState.type !== 'Loaded' || !inventoryId || next === undefined) {
      return;
    }

    const pageId = pageState.page.details._id;

    setIsLoading(true);
    const result = await meroApi.pro.inventories.getProductOperationsHistory({
      pageId: pageId,
      productId: route.params.productId,
      inventoryId: inventoryId,
      limit: LIMIT,
      page: next,
    });

    setOperations((prev) => [...prev, ...result.data]);
    setNext(result.next);
    setIsLoading(false);
  };

  const updateStockManagementEnabled = async () => {
    if (pageState.type !== 'Loaded' || !inventoryId) {
      return;
    }

    setshowDisableStockWarning(false);

    try {
      setIsLoading(true);
      setIsAdjustEnabled(isStockManagementEnabled);

      await meroApi.pro.inventories.updateStockManagementStatus({
        pageId: pageState.page.details._id,
        productId: route.params.productId,
        isStockManagementEnabled: isStockManagementEnabled,
      });

      toast.show({
        type: 'success',
        text: isStockManagementEnabled ? t('activateStockSuccess') : t('deactivateStockSuccess'),
      });
      reload();
    } catch (e) {
      log.info('Failed to update product stock status', e);
      showError(t('unknownError'));
    }
    setIsLoading(false);
    await loadOperations();
  };

  const onSave = async () => {
    if (!isStockManagementEnabled) {
      return setshowDisableStockWarning(true);
    }
    await updateStockManagementEnabled();
  };

  const getOperationText = (operation: SavedInventoryOperation): string => {
    if (InventoryOperation.isStockManagementStatusUpdate(operation)) {
      return operation.newStatus ? t('activatedStockManagement') : t('deactivatedStockManagement');
    }

    const adjustment =
      operation.type === 'StockIncrease' || operation.type === 'TransactionCancelledConsumption'
        ? `+${ScaledNumber.toNumber(operation.adjustmentQuantity)}`
        : `-${ScaledNumber.toNumber(operation.adjustmentQuantity)}`;

    let text = '';
    if (InventoryOperation.isStockDecrease(operation) || InventoryOperation.isStockIncrease(operation)) {
      const reason: string | { type: 'Other'; value: string } = operation.reason;
      const strReason = typeof reason === 'string' ? t(reason) : reason.value;

      text = operation.isStockManagementEnabled
        ? t('affectedAdjustedStockValue', { change: adjustment })
        : t('unaffectedAdjustedStockValue', { change: adjustment });
      text += `. ${t('reason')}: ${strReason}`;
    } else if (InventoryOperation.isTransactionStockConsumption(operation)) {
      text = operation.isStockManagementEnabled
        ? t('affectedTransactionConsumption', { change: adjustment })
        : t('unaffectedTransactionConsumption', { change: adjustment });
    } else if (InventoryOperation.isTransactionCancelledConsumption(operation)) {
      text = operation.isStockManagementEnabled
        ? t('affectedCancelledTransactionConsumption', { change: adjustment })
        : t('unaffectedCancelledTransactionConsumption', { change: adjustment });
    }

    return text;
  };

  const isArchived = product?.details.status === ProductStatus.Inactive.code;

  if (!product) {
    return;
  }

  return (
    <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack={true} title={t('manageInventory')} onBack={goBack} canClose={false} />

      <ScrollView>
        <View style={{ paddingHorizontal: 16 }}>
          <Spacer size="16" />
          <H1>{t('manageInventory')}</H1>

          <Spacer size="8" />
          <Body>
            <Body>{t('setPriceFor')}</Body>
            <Body style={styles.text.semibold}> {product.details.name}</Body>
          </Body>

          <Spacer size={32} />
          <FormCard dropShaddow rounded paddings="none">
            <Row justifyContent="space-between" style={{ padding: 16 }}>
              <Body style={styles.text.bold}>{t('currentStock')}</Body>
              <Body>{t('pieces', { stock: product.stock.toLocaleString() ?? 0 })}</Body>
            </Row>
            <Line />
            <TouchableOpacity
              style={{ flex: 2, flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 24 }}
              onPress={() => setIsStockManagementEnabled(!isStockManagementEnabled)}
              disabled={isArchived}
            >
              <Checkbox
                value={isStockManagementEnabled}
                onValueChange={() => setIsStockManagementEnabled(!isStockManagementEnabled)}
                disabled={isArchived}
              />
              <Body style={[styles.text.semibold, { fontSize: 16, paddingLeft: 12 }]}>{t('activateStock')}</Body>
            </TouchableOpacity>

            {isAdjustEnabled && (
              <Button
                size="medium"
                text={t('adjustStock')}
                expand={true}
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                containerStyle={{ marginHorizontal: 16, marginBottom: 24 }}
                onPress={() => setShowDialog(true)}
                disabled={isArchived}
              />
            )}
          </FormCard>

          <Spacer size={48} />
        </View>

        {!!operations.length && (
          <View style={{ backgroundColor: colors.WHITE, paddingHorizontal: 32 }}>
            <FlatList
              data={operations}
              ListHeaderComponent={() => (
                <H3s style={{ fontSize: 20, marginTop: 24, marginBottom: 16 }}>{t('history')}</H3s>
              )}
              renderItem={({ item }) => (
                <TouchableOpacity style={{ flexDirection: 'row', paddingVertical: 16 }}>
                  <HistoryIcon />
                  <HSpacer left={12} />
                  <Column style={{ flex: 1 }}>
                    <Body style={{ color: '#32325D' }}>
                      <Body style={[styles.text.semibold, { color: '#32325D' }]}>
                        {nameGenerator(item.createdBy.profile, t('noName'))} ({item.createdBy.profile.phone})
                      </Body>
                      {getOperationText(item)}
                    </Body>
                    <SmallBody
                      style={{
                        marginTop: 6,
                        color: colors.COMET,
                        fontSize: 12,
                        lineHeight: 17,
                      }}
                    >
                      {formatTimeDiff(item.createdAt, new Date())}
                    </SmallBody>
                  </Column>
                </TouchableOpacity>
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.8}
              ListFooterComponent={isLoading ? <ActivityIndicator size="large" /> : null}
              ItemSeparatorComponent={() => <Line color={colors.ATHENS_GRAY} />}
            />
          </View>
        )}
        <Spacer size={83} />
      </ScrollView>

      {inventoryId && showDialog && (
        <AdjustProductStockScreen
          measure={product.details.measure}
          productId={route.params.productId}
          inventoryId={inventoryId}
          goBack={() => setShowDialog(false)}
        />
      )}

      {showDisableStockWarning && (
        <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
          <DismissKeyboard>
            <MobileWebModalWrapper position="center">
              <KeyboardAvoidingView>
                <ConfirmBox
                  type="warn"
                  headerTitle={t('importantActions')}
                  canClose={true}
                  onClose={() => setshowDisableStockWarning(false)}
                  leftAction={{
                    text: t('cancelButton'),
                    onPress: () => setshowDisableStockWarning(false),
                    flex: 10,
                  }}
                  rightAction={{
                    text: t('save'),
                    onPress: updateStockManagementEnabled,
                    flex: 15,
                  }}
                  style={{
                    width: 375,
                  }}
                >
                  <H1>{t('disableStockWarning')}</H1>
                  <Spacer size={16} />
                  <Body style={[styles.text.semibold]}>{t('disableStockWarningMessage')}</Body>
                </ConfirmBox>
              </KeyboardAvoidingView>
            </MobileWebModalWrapper>
          </DismissKeyboard>
        </ModalOverlay>
      )}

      <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onPress={onSave} />
          ) : (
            <Button text={t('saveChanges')} onPress={onSave} expand={false} containerStyle={{ alignSelf: 'center' }} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProductStockScreen;

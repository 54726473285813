import { Avatar, colors, Icon, SafeAreaView, Text } from '@mero/components';
import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Svg, { Rect, G, Path, SvgProps } from 'react-native-svg';

import { BoxIcon } from '../screens/Authorized/ProductsScreen/ProductsMenuScreen';

import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AuthContext } from '../contexts/AuthContext';
import { CheckoutsContext } from '../contexts/CheckoutsContext';
import { CurrentBusinessContext, CurrentBusinessProps } from '../contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeDrawerParamsList, RootStackParamList } from '../types';

type Props = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
    CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList, 'Authorized'>
    >
  >;
  // Using partial as route params may be undefined
  route: RouteProp<Partial<HomeDrawerParamsList>>;
  unseenNotificationsCount?: number;
  draftsCount?: number;
};

const CalendarIcon = ({ color = '#fff' }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24">
      <Rect id="Rectangle_1517" data-name="Rectangle 1517" width="24" height="24" fill="none" />
      <G id="calendar_1_" transform="translate(1 1)">
        <Path
          id="Union_3"
          data-name="Union 3"
          d="M2,23a2,2,0,0,1-2-2V4.25a2,2,0,0,1,2-2H4.25V.75a.75.75,0,0,1,1.5,0v1.5h4.5V.75a.75.75,0,0,1,1.5,0v1.5h4.5V.75a.75.75,0,1,1,1.5,0v1.5H20a2,2,0,0,1,2,2V21a2,2,0,0,1-2,2ZM1.5,4.25V21a.5.5,0,0,0,.5.5H20a.5.5,0,0,0,.5-.5V4.25a.5.5,0,0,0-.5-.5H17.749v1.5a.75.75,0,1,1-1.5,0V3.75h-4.5v1.5a.75.75,0,1,1-1.5,0V3.75H5.75v1.5a.75.75,0,1,1-1.5,0V3.75H2A.5.5,0,0,0,1.5,4.25Zm8,14.5V16.5h3v2.25Zm-5.25,0V16.5h3v2.25ZM14.75,15V12.75h3V15ZM9.5,15V12.75h3V15ZM4.25,15V12.75h3V15Zm10.5-3.75V9h3v2.25Zm-5.25,0V9h3v2.25Zm-5.25,0V9h3v2.25Z"
          transform="translate(0)"
          fill={color}
        />
      </G>
    </Svg>
  );
};

const CheckoutIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 7145">
      <G
        stroke={props.color ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      >
        <Path d="M6.416 12.997h6.386m-6.386 3.189h4.789m4.79-3.194h1.595m-1.596 3.194h1.595M2.434 2v20.394l2.386-1.591 2.42 1.6 2.386-1.6 2.395 1.591 2.386-1.591 2.393 1.591 2.387-1.591 2.394 1.591V2M16.8 22.404h0Zm-4.78 0h0ZM1 2h22" />
        <Path d="m9.732 7.683 1.634 1.634 2.909-2.906" data-name="layer1" />
      </G>
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

const ReportsIcon = ({ color = '#fff' }) => (
  <Svg width={25.5} height={25.811}>
    <G
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      data-name="Group 7829"
    >
      <Path d="M18.417 1.061h3.619V4.68" />
      <Path d="m3.464 12.261 7.055-6.4 4.7 2.4 7.059-7.2m-1.568 24v-16h3.136v16" data-name="layer1" />
      <Path d="M14.076 25.061v-9.047h3.619v9.047" />
      <Path d="M7.805 25.061V13.3h3.619v11.761" data-name="layer1" />
      <Path d="M1.655 25.061v-5.428h3.619v5.428" data-name="layer2" />
      <Path d="M24.75 25.061h-24" data-name="layer1" />
    </G>
  </Svg>
);

const LargeScreenMenu: React.FC<Props> = ({ page, navigation, route, unseenNotificationsCount, draftsCount }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const [checkoutState] = CheckoutsContext.useContext();
  const [authState] = AuthContext.useContext();

  const isCalendarFocused =
    route.name === 'HomeTabs' && (route.params?.screen === 'CalendarTab' || route.params === undefined);
  const isClientsFocused = route.name === 'HomeTabs' && route.params?.screen === 'ClientsTab';
  const isCheckoutFocus = route.name === 'HomeTabs' && route.params?.screen === 'CheckoutTab';
  const isNotificationsFocused = route.name === 'HomeTabs' && route.params?.screen === 'NotificationsTab';
  const isReportsFocused = route.name === 'HomeTabs' && route.params?.screen === 'ReportsTab';
  const isMenuFocused = route.name === 'HomeTabs' && route.params?.screen === 'MenuTab';
  const isProductsFocused = route.name === 'HomeTabs' && route.params?.screen === 'ProductsTab';

  const navigateCalendarCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'CalendarTab',
      params: {
        screen: 'CalendarScreen',
      },
    });
  }, [navigation]);

  const navigateClientsCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'ClientsTab',
      params: {
        screen: 'ClientsScreen',
      },
    });
  }, [navigation]);

  const navigateProductsCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'ProductsTab',
      params: {
        screen: 'ProductsDashboardScreen',
      },
    });
  }, [navigation]);

  const navigateCheckoutCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'CheckoutTab',
      params: {
        screen: 'CheckoutTabScreen',
      },
    });
  }, [navigation]);

  const navigateNotificationsCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'NotificationsTab',
      params: {
        screen: 'NotificationsScreen',
      },
    });
  }, [navigation]);

  const navigateReportsCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'ReportsTab',
      params: {
        screen: 'ReportsScreen',
      },
    });
  }, [navigation]);

  const navigateMenuCallback = React.useCallback(() => {
    navigation.navigate('HomeTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'MenuScreen',
      },
    });
  }, [navigation]);

  const navigateSelectAccountCallback = React.useCallback(() => {
    navigation.navigate('SelectAccount');
  }, [navigation]);

  const isWorker = React.useMemo(() => {
    if (authState.type !== 'Authorized' || pageState.type !== 'Loaded') {
      return false;
    }

    return (
      pageState.page.workers.some((worker) => worker.user._id === authState.user._id) ||
      page.permissions.proProfiles.canManageAllProfiles()
    );
  }, [pageState, authState]);

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.logo}>
          <Icon type="mero-menu" />
        </View>
        <TouchableOpacity
          style={styles.action}
          accessibilityRole="button"
          accessibilityState={isCalendarFocused ? { selected: true } : {}}
          onPress={navigateCalendarCallback}
        >
          <CalendarIcon color={isCalendarFocused ? colors.WHITE : '#999999'} />
        </TouchableOpacity>
        {pageState.type === 'Loaded' && pageState.page.permissions.clients.canListAllClients() ? (
          <TouchableOpacity
            style={styles.action}
            accessibilityRole="button"
            accessibilityState={isClientsFocused ? { selected: true } : {}}
            onPress={navigateClientsCallback}
          >
            <Icon type="clients_menu" color={isClientsFocused ? colors.WHITE : '#999999'} disabled={true} />
          </TouchableOpacity>
        ) : null}
        {pageState.type === 'Loaded' &&
        pageState.page.permissions.checkout.canWriteAll() &&
        pageState.page.permissions.page.canManageMemberships() ? (
          <TouchableOpacity
            style={styles.action}
            accessibilityRole="button"
            accessibilityState={isCheckoutFocus ? { selected: true } : {}}
            onPress={navigateCheckoutCallback}
          >
            <CheckoutIcon color={isCheckoutFocus ? colors.WHITE : '#999999'} disabled={true} />

            {checkoutState.type === 'Loaded' &&
            checkoutState.pageSettings.checkoutEnabled &&
            draftsCount !== undefined &&
            draftsCount > 0 ? (
              <View style={styles.badge}>
                <Text style={styles.badgeText}>{`${draftsCount}`}</Text>
              </View>
            ) : null}
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          style={styles.action}
          accessibilityRole="button"
          accessibilityState={isNotificationsFocused ? { selected: true } : {}}
          onPress={navigateNotificationsCallback}
        >
          <Icon type="notifications_menu" color={isNotificationsFocused ? colors.WHITE : '#999999'} disabled={true} />
          {unseenNotificationsCount !== undefined && unseenNotificationsCount > 0 ? (
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{`${unseenNotificationsCount}`}</Text>
            </View>
          ) : null}
        </TouchableOpacity>
        {pageState.type === 'Loaded' && pageState.page.permissions.products.canManageAll() ? (
          <TouchableOpacity
            style={styles.action}
            accessibilityRole="button"
            accessibilityState={isProductsFocused ? { selected: true } : {}}
            onPress={navigateProductsCallback}
          >
            <BoxIcon color={isProductsFocused ? colors.WHITE : '#999999'} width={32} height={32} disabled={true} />
          </TouchableOpacity>
        ) : null}
        {pageState.type === 'Loaded' &&
        isWorker &&
        (page.permissions.statistics.canReadOwnStatistics() || page.permissions.statistics.canReadAllStatistics()) ? (
          <TouchableOpacity
            style={styles.action}
            accessibilityRole="button"
            accessibilityState={isReportsFocused ? { selected: true } : {}}
            onPress={navigateReportsCallback}
          >
            <ReportsIcon color={isReportsFocused ? colors.WHITE : '#999999'} />
          </TouchableOpacity>
        ) : null}
        <View style={styles.account}>
          <TouchableOpacity
            style={styles.accountWrapper}
            accessibilityRole="button"
            accessibilityState={isMenuFocused ? { selected: true } : {}}
            onPress={navigateMenuCallback}
            onLongPress={navigateSelectAccountCallback}
          >
            <Avatar source={page.details.profilePhoto.small} firstname={page.details.name} lastname="" size={31} />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: 60,
    backgroundColor: colors.BLACK,
    paddingVertical: 24,
  },
  safeArea: {
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    paddingBottom: 15,
    backgroundColor: colors.BLACK,
  },
  action: {
    paddingVertical: 15,
    position: 'relative',
  },
  badge: {
    width: 18,
    height: 18,
    backgroundColor: colors.RADICAL_RED,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -6,
    top: 8,
  },
  badgeText: {
    color: colors.WHITE,
    fontSize: 13,
  },
  account: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: colors.BLACK,
  },
  accountWrapper: {
    borderRadius: 16,
    borderWidth: 1,
    borderColor: '#ffffff',
  },
});

export default LargeScreenMenu;

import * as React from 'react';
import { Pressable, Text } from 'react-native';

import {
  MenuItem,
  MenuItemKey,
} from '../../../screens/Authorized/ClientDetailsScreen/ClientDetailsLoadedScreenView/ClientDetailsLoadedScreenViewWeb';

import Icon from '../../Icon';
import FormCard from '../FormCard';
import Row from '../Layout/Row';
import styles from './styles';

interface ListMenuProps {
  activeItem: string;
  items: MenuItem[];
  onItemPress: (key: MenuItemKey) => void;
}

const ListMenu: React.FC<ListMenuProps> = ({ activeItem, onItemPress, items }) => {
  return (
    <FormCard paddings="none" rounded dropShaddow style={styles.container}>
      {items.map((item, index) => (
        <Pressable key={index} onPress={() => onItemPress(item.key)}>
          <Row
            flex={1}
            justifyContent="space-between"
            alignItems="center"
            style={[activeItem === item.key && styles.activeItem, styles.itemRow]}
          >
            <Icon name={item.icon} size={24} />
            <Text style={styles.text}>{item.value}</Text>
            {activeItem === item.key && <Icon name="Arrow" size={24} />}
          </Row>
        </Pressable>
      ))}
    </FormCard>
  );
};

export default ListMenu;

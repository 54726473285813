import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import AddProceedScreen from '../screens/Authorized/CheckoutScreen/AddProceedScreen';
import AddProceedSuccessScreen from '../screens/Authorized/CheckoutScreen/AddProceedSuccessScreen';
import AllDraftsScreen from '../screens/Authorized/CheckoutScreen/AllDraftsScreen';
import DeleteDraftsScreen from '../screens/Authorized/CheckoutScreen/DeleteDraftsScreen';
import PaymentScreen from '../screens/Authorized/CheckoutScreen/PaymentScreen';
import ProceedDetailsScreen from '../screens/Authorized/CheckoutScreen/ProceedDetailsScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { CheckoutStackParamList } from '../types';
import { styles } from './styles';

const CheckoutStack = createStackNavigator<CheckoutStackParamList>();

export function CheckoutStackNavigator() {
  const { t } = useTranslation();

  const { isPhone, isDesktop } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <CheckoutStack.Navigator initialRouteName="AddProceedScreen">
      <CheckoutStack.Screen
        name="AddProceedScreen"
        component={AddProceedScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: isDesktop ? styles.desktopModalCardCheckout : modalStyle,
          title: t('checkout:newProceed'),
        }}
      />
      <CheckoutStack.Screen
        name="PaymentScreen"
        component={PaymentScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: isDesktop ? styles.desktopModalCardCheckout : modalStyle,
          title: t('checkout:selectPayment'),
        }}
      />
      <CheckoutStack.Screen
        name="ProceedDetailsScreen"
        component={ProceedDetailsScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: isDesktop ? styles.desktopModalCardCheckout : modalStyle,
          title: t('checkout:proceed'),
        }}
      />
      <CheckoutStack.Screen
        name="AddProceedSuccessScreen"
        component={AddProceedSuccessScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: isDesktop ? styles.desktopModalCardCheckout : modalStyle,
          title: t('checkout:allDrafts'),
        }}
      />
      <CheckoutStack.Screen
        name="AllDraftsScreen"
        component={AllDraftsScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:allDrafts'),
        }}
      />
      <CheckoutStack.Screen
        name="DeleteDraftsScreen"
        component={DeleteDraftsScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:deleteDrafts'),
        }}
      />
    </CheckoutStack.Navigator>
  );
}

import { colors } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import CircleButton from '@mero/components/lib/components/CircleButton';
import Icon from '@mero/components/lib/components/Icon';
import Line from '@mero/components/lib/components/Line';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import SmallBody from '@mero/components/lib/components/Text/SmallBody';
import Title from '@mero/components/lib/components/Text/Title';

export type Props = {
  readonly phone?: string;
  readonly onPress?: () => void;
  /**
   * When set to true will add a horizontal line at the bottom of the element
   */
  readonly hasLine?: boolean;
};

const AddClientListItem: React.FC<Props> = ({ phone, onPress }: Props) => {
  const cleanPhone = phone?.trim();

  return (
    <>
      <TouchableOpacity onPress={onPress} disabled={onPress === undefined}>
        <SimpleListItem
          IconComponent={() => (
            <CircleButton>
              <Icon type="plus" color={colors.DARK_BLUE} />
            </CircleButton>
          )}
          alignContent="middle"
        >
          <Title style={{ color: colors.DARK_BLUE }}>Adaugă client</Title>
          {cleanPhone ? <SmallBody style={{ color: colors.DARK_BLUE }}>{cleanPhone}</SmallBody> : null}
        </SimpleListItem>
      </TouchableOpacity>
      <Line />
    </>
  );
};

export default AddClientListItem;

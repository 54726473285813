import {
  InvitePreview,
  InviteTypeAppointmentRequest,
  InviteTypeClientRequest,
  InviteTypeWork,
} from '@mero/api-sdk/dist/invites';
import * as React from 'react';

import AppointmentRequestInviteView from './AppointmentRequestInviteView';
import ClientJoinInviteView from './ClientJoinInviteView';
import WorkInviteView from './WorkInviteView';

type Props = {
  readonly invite: InvitePreview;
  readonly onApprove: (invite: InvitePreview) => void;
  readonly onReject: (invite: InvitePreview) => void;
  readonly disabled?: boolean;
};

const InviteItemView: React.FC<Props> = ({ invite, onApprove, onReject, disabled }: Props) => {
  switch (invite.type) {
    case InviteTypeWork.value: {
      return <WorkInviteView invite={invite} onApprove={onApprove} onReject={onReject} disabled={disabled} />;
    }
    case InviteTypeClientRequest.value: {
      return <ClientJoinInviteView invite={invite} onApprove={onApprove} onReject={onReject} disabled={disabled} />;
    }
    case InviteTypeAppointmentRequest.value: {
      return (
        <AppointmentRequestInviteView
          invite={invite}
          onViewDetails={onApprove}
          onReject={onReject}
          disabled={disabled}
        />
      );
    }
  }
};

export default InviteItemView;

import { AnalyticsStatsNew, ServiceStats } from '@mero/api-sdk/dist/analytics/analytics2';
import { CheckoutSaleOwnerSalesReportTotalsByType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutSaleOwnerSalesReportTotalsByType';
import { UserId } from '@mero/api-sdk/dist/users';
import {
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Label,
  Line,
  MeroHeader,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import Svg, { G, Circle, Path } from 'react-native-svg';

import DisabledSelect from '../../../../../components/DisabledSelect';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import H3s from '@mero/components/lib/components/Text/H3s';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { AuthContext, Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CheckoutsContext } from '../../../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, MenuStackParamList } from '../../../../../types';
import { scaledToString } from '../../../../../utils/scaled';
import ActiveIntervalView, { getIntervals } from './ActiveIntervalView';

const AppointmentsIcon = () => (
  <Svg width={40} height={40}>
    <G data-name="Group 6006" transform="translate(-235 -484)">
      <Circle data-name="Ellipse 15" cx={20} cy={20} r={20} transform="translate(235 484)" fill="#52577f" />
      <Path data-name="Path 8323" d="M243 492h24v24h-24Z" fill="none" />
      <Path
        data-name="Path 8325"
        d="M262 496h-1v-2h-2v2h-8v-2h-2v2h-1a1.991 1.991 0 0 0-1.99 2l-.01 14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-14a2.006 2.006 0 0 0-2-2Zm0 16h-14v-10h14Zm0-12h-14v-2h14Zm-7 5h5v5h-5Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

const MoneyIcon = () => (
  <Svg width={40} height={40}>
    <G data-name="Group 6006">
      <Path data-name="Path 8328" d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0Z" fill="#2dce89" />
      <Path data-name="Path 8326" d="M8 8h24v24H8Z" fill="none" />
      <Path
        data-name="Path 8327"
        d="M14.5 18h-2v7h2Zm6 0h-2v7h2Zm8.5 9H10v2h19Zm-2.5-9h-2v7h2Zm-7-6.74L24.71 14H14.29l5.21-2.74m0-2.26L10 14v2h19v-2Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

const CalendarIcon = () => (
  <Svg width={40} height={40}>
    <G data-name="Group 6006">
      <Path data-name="Path 8328" d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0Z" fill="#a27af1" />
      <G data-name="schedule-24px (1)">
        <Path data-name="Path 8329" d="M8 8h24v24H8Z" fill="none" />
        <Path
          data-name="Path 8330"
          d="M19.99 10A10 10 0 1 0 30 20a10 10 0 0 0-10.01-10ZM20 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm.5-13H19v6l5.25 3.15.75-1.23-4.5-2.67Z"
          fill="#fff"
        />
      </G>
    </G>
  </Svg>
);

const ANALYTICS_PLACEHOLDER = {
  bookings: {
    totalCount: 0,
    totalIncome: 0,
    acceptedStats: {
      count: 0,
      income: 0,
      percent: 0,
    },
    cancelledStats: {
      count: 0,
      income: 0,
      percent: 0,
    },
    noShowStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    pendingStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    finishedStats: {
      count: 0,
      percent: 0,
      income: 0,
    },
    futureStats: {
      count: 0,
      income: 0,
      percent: 0,
    },
  },
  clients: {
    totalCount: 0,
    totalIncome: 0,
    averageBookingValue: 0,
    newClientStats: {
      count: 0,
      income: 0,
    },
    existingClientStats: {
      count: 0,
      income: 0,
    },
    newFromProClientStats: {
      count: 0,
      income: 0,
    },
  },
  services: [] as ServiceStats[],
} as const;

export type Interval = {
  label: string;
  id: string;
  value: {
    start: DateTime;
    end: DateTime;
  };
};

const ALL = 'all' as UserId;
const EMPTY_SCALAR = ScaledNumber.fromNumber(0, 2);

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MenuStackParamList & AuthorizedStackParamList, 'PageReportsMobileScreen'>;

const PageReportsScreen: React.FC<Props> = ({ page, authorization, route }) => {
  const { t } = useTranslation('reports');

  const isFocused = useIsFocused();

  const [pageState] = CurrentBusinessContext.useContext();
  const [authState] = AuthContext.useContext();

  const [checkoutState, { reloadSettings, reloadCompanies }] = CheckoutsContext.useContext();

  const hasCheckoutActive =
    checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled && checkoutState.companies.length > 0;

  const { interval } = route.params ?? {};

  const [workers, setWorkers] = React.useState<{ label: string; value: UserId | undefined }[]>([]);
  const [activeWorker, setActiveWorker] = React.useState<UserId | undefined>();
  const [analytics, setAnalytics] = React.useState<AnalyticsStatsNew>(ANALYTICS_PLACEHOLDER);
  const [prosDetails, setProsDetails] = React.useState<
    CheckoutSaleOwnerSalesReportTotalsByType<MeroUnits.Any> | undefined
  >();
  const [futureSales, setFutureSales] = React.useState(0);

  const defaultInterval = React.useMemo(() => {
    const intervals = getIntervals({
      withFirstDay: true,
      withFullCurrentMonth: true,
    });
    if (!interval) {
      return intervals[0];
    }

    const date = DateTime.fromFormat(interval, 'MM/yyyy', { locale: 'ro' });
    return (
      intervals.find((i) => i.value.start.equals(date.startOf('month'))) ?? {
        label: `${date.startOf('month').toFormat('dd MMM')} - ${date.endOf('month').toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start: date.startOf('month'),
          end: date.endOf('month'),
        },
      }
    );
  }, [interval]);

  const [activeInterval, setActiveInterval] = React.useState<Interval>(defaultInterval);

  const goBack = useGoBack();

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && authState.type === 'Authorized') {
      if (pageState.page.permissions.statistics.canReadAllStatistics()) {
        setWorkers([
          { label: t('all'), value: ALL },
          ...pageState.page.workers.map((w) => ({
            label: `${w.user.firstname} ${w.user.lastname}`,
            value: w.user._id,
          })),
        ]);
        if (pageState.page.workers.length === 1) {
          setActiveWorker(pageState.page.workers[0].user._id);
        }
      } else {
        const worker = pageState.page.workers.find((w) => w.user._id === authState.user._id);

        setWorkers([
          { label: t('all'), value: ALL },
          { label: `${worker?.user.firstname} ${worker?.user.lastname}`, value: worker?.user._id },
        ]);
        setActiveWorker(worker?.user._id);
      }
    }
  }, [pageState.type, authState.type]);

  React.useEffect(() => {
    const getAnalytics = async () => {
      if (pageState.type === 'Loaded') {
        const from = activeInterval.value.start;
        const to = activeInterval.value.end;

        const workerId = page.permissions.statistics.canReadAllStatistics()
          ? page.workers.find((w) => w.user._id === activeWorker)?._id
          : page.workers.find((w) => w.user._id === authorization.user._id)?._id;

        const [analyticsAll, prosDetails] = await Promise.all([
          meroApi.analytics.getAnalyticsNew({
            pageId: pageState.page.details._id,
            from: from.toJSDate(),
            to: to.toJSDate(),
            workerId,
          }),
          meroApi.checkoutReports.getSalesByOwnerTotalsReport({
            pageId: pageState.page.details._id,
            unit: 'RON',
            interval: {
              from: from.toJSDate(),
              to: to.toJSDate(),
            },
            saleOwnerId: activeWorker == ALL ? undefined : activeWorker,
          }),
        ]);

        setFutureSales(analyticsAll.bookings.futureStats.income);
        setProsDetails(prosDetails);
        setAnalytics(analyticsAll);
      }
    };

    getAnalytics();
  }, [activeInterval, activeWorker, pageState.type]);

  const isFutureDate = React.useMemo(() => {
    const now = DateTime.now();
    return activeInterval.value.end > now;
  }, [activeInterval]);

  React.useEffect(() => {
    if (isFocused) {
      reloadSettings(page.details._id);
      reloadCompanies(page.details._id);
    }
  }, [isFocused]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t('title')} />
        {workers.length > 0 && (
          <ScrollView>
            <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 8 }}>{t('title')}</H1>
              <Spacer size={16} />
              <Row>
                <Column style={{ flex: 2, marginRight: 8, backgroundColor: '#ffffff' }}>
                  {workers.length > 2 ? (
                    <Select
                      items={workers}
                      onChange={setActiveWorker}
                      value={activeWorker}
                      placeholderColor={colors.BLACK}
                      selectStyle={{ color: colors.BLACK }}
                    />
                  ) : (
                    <DisabledSelect label={workers[1]?.label} />
                  )}
                </Column>
                <Column style={{ flex: 2, marginLeft: 8, backgroundColor: '#ffffff' }}>
                  <ActiveIntervalView
                    withFullCurrentMonth
                    activeInterval={activeInterval}
                    setActiveInterval={setActiveInterval}
                    showFutureDates={true}
                    withArrows={false}
                  />
                  {/*<Select*/}
                  {/*  items={intervals}*/}
                  {/*  onChange={setActiveInterval}*/}
                  {/*  value={activeInterval}*/}
                  {/*  selectStyle={{ marginRight: 16 }}*/}
                  {/*/>*/}
                </Column>
              </Row>
              <Spacer size={16} />
              {hasCheckoutActive ? (
                <FormCard rounded paddings="none" dropShaddow>
                  <Spacer size={16} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <Title>{t('allAppointments')}</Title>
                    <Spacer size={4} />
                    <H2s style={{ fontSize: 24 }}>{analytics.bookings.totalCount}</H2s>
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                    <Row>
                      <Column style={{ flex: 2 }}>
                        <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                          {t('finalAppointments')}
                        </Label>
                        <Spacer size={4} />
                        <H3s>{analytics.bookings.finishedStats.count}</H3s>
                      </Column>
                      <Column style={{ flex: 2 }}>
                        <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                          {t('canceledAppointments')}
                        </Label>
                        <Spacer size={4} />
                        <H3s>{analytics.bookings.cancelledStats.count}</H3s>
                      </Column>
                    </Row>
                    <Spacer size={16} />
                    <Row justifyContent="space-between">
                      <Column style={{ flex: 2 }}>
                        <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                          {t('missingAppointments')}
                        </Label>
                        <Spacer size={4} />
                        <H3s>{analytics.bookings.noShowStats.count}</H3s>
                      </Column>
                      <Column style={{ flex: 2 }}>
                        <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                          {t('unconfirmedAppointments')}
                        </Label>
                        <Spacer size={4} />
                        <H3s>{analytics.bookings.pendingStats.count}</H3s>
                      </Column>
                    </Row>
                    <>
                      <Spacer size={16} />
                      <Row justifyContent="space-between">
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('futureAppointments')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>{analytics.bookings.futureStats.count}</H3s>
                        </Column>
                      </Row>
                    </>
                    {/*<Column style={{flex: 2}}>*/}
                    {/*  <Label style={{fontFamily: 'open-sans-semibold', color: colors.COMET}}>*/}
                    {/*    {t('products')}*/}
                    {/*  </Label>*/}
                    {/*  <Spacer size={4}/>*/}
                    {/*  <H3s>*/}
                    {/*    {scaledToString(prosDetails.products.total.amount)} {t(prosDetails.products.total.unit)}*/}
                    {/*  </H3s>*/}
                    {/*</Column>*/}
                  </Column>
                  <Spacer size={16} />
                  <Line />
                  <Column style={{ backgroundColor: colors.ALABASTER }}>
                    <Spacer size={16} />
                    <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                      <SmallBody style={{ flex: 1, fontFamily: 'open-sans-semibold', paddingLeft: 6 }}>
                        {t('rangeValue')}
                      </SmallBody>
                      <Row>
                        <SmallBody style={{ textAlign: 'right' }}>
                          {analytics.clients.averageBookingValue} {t('currency').toLowerCase()}
                        </SmallBody>
                      </Row>
                    </Row>
                    <Spacer size={16} />
                  </Column>
                </FormCard>
              ) : (
                <FormCard rounded paddings="none" dropShaddow>
                  <Spacer size={16} />
                  <Row style={{ paddingHorizontal: 16 }}>
                    <Column style={{ flex: 1 }}>
                      <H2s>{analytics.bookings.totalCount}</H2s>
                      <Label>{t('allAppointments')}</Label>
                    </Column>
                  </Row>
                  <Spacer size={16} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <Line />
                  </Column>
                  <Spacer size={22} />
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                      {t('finalAppointments')}
                    </SmallBody>
                    <Row style={{ flex: 1 }}>
                      <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                        {analytics.bookings.finishedStats.count}
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.finishedStats.percent}%
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.finishedStats.income} {t('currency').toLowerCase()}
                      </SmallBody>
                    </Row>
                  </Row>
                  <Spacer size={16} />
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                      {t('canceledAppointments')}
                    </SmallBody>
                    <Row style={{ flex: 1 }}>
                      <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                        {analytics.bookings.cancelledStats.count}
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.cancelledStats.percent}%
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.cancelledStats.income} {t('currency').toLowerCase()}
                      </SmallBody>
                    </Row>
                  </Row>
                  <Spacer size={16} />
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                      {t('missingAppointments')}
                    </SmallBody>
                    <Row style={{ flex: 1 }}>
                      <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                        {analytics.bookings.noShowStats.count}
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.noShowStats.percent}%
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.noShowStats.income} {t('currency').toLowerCase()}
                      </SmallBody>
                    </Row>
                  </Row>
                  <Spacer size={16} />
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                      {t('unconfirmedAppointments')}
                    </SmallBody>
                    <Row style={{ flex: 1 }}>
                      <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                        {analytics.bookings.pendingStats.count}
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.pendingStats.percent}%
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.pendingStats.income} {t('currency').toLowerCase()}
                      </SmallBody>
                    </Row>
                  </Row>
                  <Spacer size={16} />
                  {isFutureDate && (
                    <>
                      <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                          {t('futureAppointments')}
                        </SmallBody>
                        <Row style={{ flex: 1 }}>
                          <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                            {analytics.bookings.futureStats.count}
                          </SmallBody>
                          <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                            {analytics.bookings.futureStats.percent}%
                          </SmallBody>
                          <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                            {analytics.bookings.futureStats.income} {t('currency').toLowerCase()}
                          </SmallBody>
                        </Row>
                      </Row>
                      <Spacer size={16} />
                    </>
                  )}
                  <Line />
                  <Column style={{ backgroundColor: colors.ALABASTER }}>
                    <Spacer size={16} />
                    <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                      <SmallBody style={{ flex: 1, fontFamily: 'open-sans-semibold', paddingLeft: 6 }}>
                        {t('rangeValue')}
                      </SmallBody>
                      <Row>
                        <SmallBody style={{ textAlign: 'right' }}>
                          {analytics.clients.averageBookingValue} {t('currency').toLowerCase()}
                        </SmallBody>
                      </Row>
                    </Row>
                    <Spacer size={16} />
                  </Column>
                </FormCard>
              )}
              {!prosDetails ? null : (
                <>
                  <Spacer size={24} />
                  <FormCard rounded dropShaddow paddings="none">
                    <Spacer size={16} />
                    <Column style={{ paddingHorizontal: 16 }}>
                      <Title>{t('revenue')}</Title>
                      <Spacer size={4} />
                      <H2s style={{ fontSize: 24 }}>
                        {scaledToString(prosDetails.all.total.amount)} {t(prosDetails.all.total.unit)}
                      </H2s>
                      <Spacer size={16} />
                      <Line />
                      <Spacer size={16} />
                      <Row>
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('services')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>
                            {scaledToString(prosDetails.services.total.amount)} {t(prosDetails.services.total.unit)}
                          </H3s>
                        </Column>
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('membership')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>
                            {scaledToString(prosDetails.memberships.total.amount)}{' '}
                            {t(prosDetails.memberships.total.unit)}
                          </H3s>
                        </Column>
                      </Row>
                      <Spacer size={16} />
                      <Row>
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('amounts')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>
                            {scaledToString(prosDetails.amounts.total.amount)} {t(prosDetails.amounts.total.unit)}
                          </H3s>
                        </Column>
                      </Row>
                      {/*<Spacer size={16}/>*/}
                      {/*<Row justifyContent="space-between">*/}
                      {/*  <Column style={{flex: 2}}>*/}
                      {/*    <Label style={{fontFamily: 'open-sans-semibold', color: colors.COMET}}>*/}
                      {/*      {t('membership')}*/}
                      {/*    </Label>*/}
                      {/*    <Spacer size={4}/>*/}
                      {/*    <H3s>*/}
                      {/*      {scaledToString(prosDetails.memberships.total.amount)}{' '}*/}
                      {/*      {t(prosDetails.memberships.total.unit)}*/}
                      {/*    </H3s>*/}
                      {/*  </Column>*/}
                      {/*  <Column style={{flex: 2}}>*/}
                      {/*    <Label style={{fontFamily: 'open-sans-semibold', color: colors.COMET}}>*/}
                      {/*      {t('products')}*/}
                      {/*    </Label>*/}
                      {/*    <Spacer size={4}/>*/}
                      {/*    <H3s>*/}
                      {/*      {scaledToString(prosDetails.products.total.amount)} {t(prosDetails.products.total.unit)}*/}
                      {/*    </H3s>*/}
                      {/*  </Column>*/}
                      {/*</Row>*/}
                    </Column>
                    <Spacer size={16} />
                    <Line />
                    <Column style={{ paddingHorizontal: 16, backgroundColor: colors.ALABASTER }}>
                      <Spacer size={16} />
                      <Row justifyContent="space-between">
                        {/*<Column style={{ flex: 2 }}>*/}
                        {/*  <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>*/}
                        {/*    {t('giftCards')}*/}
                        {/*  </Label>*/}
                        {/*  <Spacer size={4} />*/}
                        {/*  <H3s>*/}
                        {/*    {scaledToString(EMPTY_SCALAR)} {t(prosDetails.all.total.unit)}*/}
                        {/*  </H3s>*/}
                        {/*</Column>*/}
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('revenueMissed')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>
                            {analytics.bookings.cancelledStats.income + analytics.bookings.noShowStats.income}{' '}
                            {t(prosDetails.all.total.unit)}
                          </H3s>
                        </Column>
                        <Column style={{ flex: 2 }}>
                          <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                            {t('revenueFuture')}
                          </Label>
                          <Spacer size={4} />
                          <H3s>
                            {futureSales} {t(prosDetails.all.total.unit)}
                          </H3s>
                        </Column>
                      </Row>
                      <Spacer size={16} />
                    </Column>
                  </FormCard>
                </>
              )}
              {!prosDetails ? null : (
                <>
                  <Spacer size={24} />
                  <FormCard rounded dropShaddow paddings="none">
                    <Spacer size={16} />
                    <Column style={{ paddingHorizontal: 16 }}>
                      <Title>{t('clients')}</Title>
                      <Spacer size={4} />
                      <H2s style={{ fontSize: 24 }}>
                        {analytics.clients.newClientStats.count +
                          analytics.clients.newFromProClientStats.count +
                          analytics.clients.existingClientStats.count}
                      </H2s>
                      <Spacer size={16} />
                      <Line />
                      <Spacer size={16} />
                      <Row>
                        <Column style={{ flex: 2 }}>
                          <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                            {t('newClientsOnline')}
                          </SmallBody>
                          <Spacer size={4} />
                          <H3s>{analytics.clients.newClientStats.count}</H3s>
                        </Column>
                        <Column style={{ flex: 2 }}>
                          <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                            {t('newClientsOffline')}
                          </SmallBody>
                          <Spacer size={4} />
                          <H3s>{analytics.clients.newFromProClientStats.count}</H3s>
                        </Column>
                      </Row>
                      <Spacer size={16} />
                      <Row justifyContent="space-between">
                        <Column style={{ flex: 2 }}>
                          <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                            {t('returnedClients')}
                          </SmallBody>
                          <Spacer size={4} />
                          <H3s>{analytics.clients.existingClientStats.count}</H3s>
                        </Column>
                        <Column style={{ flex: 2 }}></Column>
                      </Row>
                    </Column>
                    <Spacer size={16} />
                  </FormCard>
                </>
              )}
              {/*<Spacer size={24} />*/}
              {/*<FormCard rounded paddings="none" dropShaddow>*/}
              {/*  <Spacer size={16} />*/}
              {/*  <Row style={{ paddingHorizontal: 16 }}>*/}
              {/*    <Column style={{ flex: 1 }}>*/}
              {/*      <H2s>{analytics.totalIncome}%</H2s>*/}
              {/*      <Label>{t('occupancy')}</Label>*/}
              {/*    </Column>*/}
              {/*    <CalendarIcon />*/}
              {/*  </Row>*/}
              {/*  <Spacer size={16} />*/}
              {/*  <Column style={{ paddingHorizontal: 16 }}>*/}
              {/*    <Line />*/}
              {/*  </Column>*/}
              {/*  <Spacer size={22} />*/}
              {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
              {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('allocatedTime')}</SmallBody>*/}
              {/*    <Row style={{ flex: 2 }}>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  <Spacer size={16} />*/}
              {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
              {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('reservedTime')}</SmallBody>*/}
              {/*    <Row style={{ flex: 2 }}>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  <Spacer size={16} />*/}
              {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
              {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('blockedTime')}</SmallBody>*/}
              {/*    <Row style={{ flex: 2 }}>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  <Spacer size={16} />*/}
              {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
              {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('unreservedTime')}</SmallBody>*/}
              {/*    <Row style={{ flex: 2 }}>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
              {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
              {/*    </Row>*/}
              {/*  </Row>*/}
              {/*  <Spacer size={32} />*/}
              {/*</FormCard>*/}
              <Spacer size={24} />
              {hasCheckoutActive ? null : (
                <FormCard rounded paddings="none" dropShaddow>
                  <Spacer size={16} />
                  <Title style={{ paddingHorizontal: 16 }}>{t('top10Services')}</Title>
                  <Spacer size={24} />
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', flex: 2, color: '#52577F' }}>
                      {t('service')}
                    </Label>
                    <Row style={{ flex: 2 }}>
                      <Label
                        style={{ fontFamily: 'open-sans-semibold', flex: 2, textAlign: 'right', color: '#52577F' }}
                      >
                        {t('reservations')}
                      </Label>
                      <Label
                        style={{ fontFamily: 'open-sans-semibold', flex: 2, textAlign: 'right', color: '#52577F' }}
                      >
                        {t('value')}
                      </Label>
                    </Row>
                  </Row>
                  <Spacer size={12} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    {analytics.services.map((service, index) => (
                      <React.Fragment key={index}>
                        <Row key={index} style={{ alignItems: 'flex-start' }}>
                          <Row style={{ flex: 2, paddingRight: 12 }}>
                            <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{index + 1}. </SmallBody>
                            <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{service.name}</SmallBody>
                          </Row>
                          <Row style={{ flex: 2 }}>
                            <SmallBody style={{ flex: 2, textAlign: 'right' }}>{service.count}</SmallBody>
                            <SmallBody style={{ flex: 2, textAlign: 'right' }}>
                              {service.income} {t('currency').toLowerCase()}
                            </SmallBody>
                          </Row>
                        </Row>
                        <Spacer size={16} />
                      </React.Fragment>
                    ))}
                  </Column>
                  <Spacer size={48} />
                </FormCard>
              )}
            </Column>
            <Spacer size={96} />
          </ScrollView>
        )}
      </ModalScreenContainer>
    </>
  );
};

export default pipe(PageReportsScreen, CurrentBusiness, Authorized);

import { Dimensions, Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';

export type MediaQueries = {
  readonly isDesktop: boolean;
  readonly isPhone: boolean;
};

export const useMediaQueries = (): MediaQueries => {
  const generateMediaQuery = (size: number, queryParam: 'minWidth' | 'maxWidth') => {
    const screenWidth = Dimensions.get('screen').width;
    return Platform.OS === 'web'
      ? useMediaQuery({ [queryParam]: size })
      : queryParam === 'minWidth'
      ? screenWidth >= size
      : screenWidth <= size;
  };

  const isLargeScreen = generateMediaQuery(1025, 'minWidth');
  const isSmallScreen = generateMediaQuery(769, 'minWidth');
  const isTablet = generateMediaQuery(481, 'minWidth');
  const isPhone = generateMediaQuery(480, 'maxWidth');

  return {
    isDesktop: isLargeScreen || isSmallScreen,
    isPhone: (isPhone || isTablet) && !(isSmallScreen || isLargeScreen),
  };
};

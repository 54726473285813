import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CheckoutMenu: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="127 737 24 24" disabled={disabled} onPress={onPress}>
    <G>
      <Path
        d="m136.98 743.933 1.635 1.634 2.91-2.91"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="transparent"
      />
      <Path
        d="M129.3 738.25v20.399l2.387-1.591 2.42 1.596 2.385-1.596 2.395 1.59 2.386-1.59 2.394 1.59 2.387-1.59 2.393 1.59V738.25m-4.78 20.404v-.005.005Zm-4.78 0v-.005.005ZM128.25 738.25h22.005"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="transparent"
      />
      <Path
        d="M133.664 749.247h6.386m-6.386 3.194h4.79m4.79-3.194h1.595m-1.595 3.194h1.595"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke={color}
        fill="transparent"
      />
    </G>
  </Svg>
);

export default React.memo(CheckoutMenu);

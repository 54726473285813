import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionItem';
import { Line, Title } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import AddProceedMenu from './AddProceedMenu';
import { styles } from './AddProceedMenu.styles';

export type Props = {
  onSelect: (type: CreateCheckoutTransactionItem.Any<MeroUnits.Any>['type']) => void;
  onDismiss: () => void;
};

const ItemsMenu: React.FC<Props> = ({ onDismiss, onSelect }) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('checkout');

  return isPhone ? (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom, !isPhone && { width: 500 }]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Booking')}>
            <Title>{t('proceedBooking')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Service')}>
            <Title>{t('proceedService')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Product')}>
            <Title>{t('proceedProduct')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Membership')}>
            <Title>{t('proceedMembership')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => onSelect('MembershipInstallment')}
          >
            <Title>{t('proceedMembershipInstallment')}</Title>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onSelect('Amount')}>
            <Title>{t('proceedAmount')}</Title>
          </TouchableOpacity>
          <Line />
        </SafeAreaView>
      </View>
    </ModalOverlay>
  ) : (
    <AddProceedMenu onDismiss={onDismiss} onSelect={onSelect} withDescription={false} />
  );
};

export default ItemsMenu;

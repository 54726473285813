import { PositiveInt } from '@mero/api-sdk';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { is } from '@mero/api-sdk/dist/pro/products/product/productStatus/productStatus';
import {
  Body,
  Button,
  colors,
  Column,
  ConfirmBox,
  DismissKeyboard,
  formatDurationInMinutes,
  ModalOverlay,
  Row,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import HSpacer from '@mero/components/lib/components/HSpacer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { ItemService, WithUiKey } from '../../../contexts/CheckoutFormContext';
import { NumberFromString, roundToDecimals } from '../../../utils/number';
import {
  divideScaled,
  localeNumberValidator,
  localeStringToNumber,
  multiplyScaled,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import { sequence } from '../../../utils/sequence';
import DiscountComponent, { MinusIcon, PlusIcon } from './DiscountComponent';
import SelectPro from './SelectProComponent';

const PositiveNumberString = sequence(NumberFromString, PositiveInt.JSON);

type Props = {
  isBooking: boolean;
  service: WithUiKey<ItemService>;
  onSave: (service: WithUiKey<ItemService>) => void;
  onDelete: (service: WithUiKey<ItemService>) => void;
  onCancel: () => void;
};

const EditService: React.FC<Props> = ({ service, isBooking, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation('checkout');

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [saleOwner, setSaleOwner] = React.useState<CheckoutUserPreview | undefined>(service.saleOwner);
  const quantityTimer = React.useRef(0);

  const [price, setPrice] = React.useState({
    input:
      ScaledNumber.toNumber(service.total.amount.amount) === 0
        ? ''
        : scaledToString(divideScaled(service.total.amount.amount, service.quantity ?? 1)),
    decoded: NumberFromString.decode(
      ScaledNumber.toNumber(service.total.amount.amount) === 0
        ? ''
        : scaledToString(divideScaled(service.total.amount.amount, service.quantity ?? 1)),
    ),
  });
  const priceValid = E.isRight(price.decoded);

  const [quantity, setDiscountQuantity] = React.useState(service.quantity ?? 1);
  const [tempQuantity, setTempQuantity] = React.useState(roundToDecimals(quantity, 0).toString() ?? '1');

  const updateQuantity = (value: string = tempQuantity) => {
    window.clearTimeout(quantityTimer.current);

    const parsedValue = localeStringToNumber(value);
    const computedValue = PositiveInt.JSON.is(parsedValue) ? parsedValue : (1 as PositiveInt);

    setDiscountQuantity(computedValue);
    setTempQuantity(computedValue.toString());
  };

  const incrementValue = () => {
    updateQuantity((quantity + 1).toString());
  };

  const decrementValue = () => {
    updateQuantity((quantity - 1).toString());
  };

  const updateTempQuantity = (value: string) => {
    setTempQuantity(value);
    window.clearTimeout(quantityTimer.current);
    quantityTimer.current = window.setTimeout(() => {
      updateQuantity(value);
    }, 500);
  };

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    if (!priceValid || !saleOwner) {
      return setShowErrors(true);
    }

    onSave({
      ...service,
      total: {
        ...service.total,
        amount: {
          ...service.total.amount,
          amount: multiplyScaled(ScaledNumber.fromNumber(localeStringToNumber(price.input), 2), quantity ?? 1),
        },
        discount: {
          type: 'Value',
          value: {
            amount: ScaledNumber.fromNumber(discount, 2),
            unit: 'RON',
          },
        },
      },
      quantity,
      saleOwner,
    });
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: save,
  };

  const { discountValue, discountPercentage } = React.useMemo(() => {
    if (!service.total.discount) {
      return {
        discountValue: 0,
        discountPercentage: 0,
      };
    }
    const total = ScaledNumber.toNumber(service.total.amount.amount);
    if (service.total.discount.type === 'Value') {
      const value = ScaledNumber.toNumber(service.total.discount.value.amount);
      return {
        discountValue: value,
        discountPercentage: roundToDecimals((value * 100) / total),
      };
    }

    if (service.total.discount.type === 'Percent') {
      const percentage = service.total.discount.percent.value;
      return {
        discountValue: roundToDecimals((percentage * total) / 100),
        discountPercentage: percentage,
      };
    }

    return {
      discountValue: 0,
      discountPercentage: 0,
    };
  }, [service.total.discount]);

  const priceText = React.useMemo(() => {
    if (service.service.price.type === 'Fixed') {
      return `${scaledToString(service.service.price.fixed.amount)} lei`;
    }

    if (service.service.price.type === 'Range') {
      return `${
        service.service.price.range.from ? ScaledNumber.toNumber(service.service.price.range.from.amount) : 0
      } - ${service.service.price.range.to ? ScaledNumber.toNumber(service.service.price.range.to.amount) : 0} lei`;
    }

    if (service.service.price.type === 'Hidden') {
      return t('hiddenPrice');
    }

    return '0 lei';
  }, [service.service.price.type]);

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  const quantityValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={t('changeService')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{t('changeService')}</H1>
              <Spacer size={16} />
              <Row
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
              >
                <Column style={{ flex: 1 }}>
                  <Title>{service.service.name}</Title>
                  <SmallBody>{formatDurationInMinutes(service.service.durationInMinutes)}</SmallBody>
                </Column>
                <Body>{priceText}</Body>
              </Row>
              <Spacer size={16} />
              <SelectPro value={saleOwner?._id} onUpdate={setSaleOwner} />
              <Spacer size={16} />
              <Row style={{ alignItems: 'center' }}>
                <Column style={{ flex: 1 }}>
                  <InputWithLabel
                    label={t('unitPrice')}
                    isError={showErrors && !priceValid}
                    errorText={t('priceError')}
                  >
                    <TypeSafeTextInput
                      codec={NumberFromString}
                      value={price.input}
                      onChange={flow(numberValidator(price.input), setPrice)}
                      keyboardType="numeric"
                      placeholder={t('pricePlaceholder')}
                      onFocus={() => {
                        setPrice({
                          input: stripLocalThousandsSeparators(price.input),
                          decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                        });
                      }}
                      onBlur={() => {
                        setPrice({
                          input: price.input
                            ? scaledToString(
                                ScaledNumber.fromNumber(
                                  localeStringToNumber(price.input),
                                  service.total.amount.amount.scale || 2,
                                ),
                              )
                            : '',
                          decoded: NumberFromString.decode(stripLocalThousandsSeparators(price.input)),
                        });
                      }}
                    />
                  </InputWithLabel>
                </Column>
                <HSpacer left={42} />
                <Column style={{ flex: 1 }}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('quantity')}</SmallBody>
                  <Spacer size={8} />
                  <Row
                    style={{
                      padding: 8,
                      borderWidth: 1,
                      borderRadius: 4,
                      borderColor: colors.GEYSER,
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={decrementValue}
                    >
                      <MinusIcon />
                    </TouchableOpacity>
                    <Column style={{ flex: 1, paddingHorizontal: 4 }}>
                      <TextInput
                        style={{ flex: 1, textAlign: 'center', fontSize: 16, lineHeight: 22, fontFamily: 'open-sans' }}
                        value={tempQuantity}
                        onChangeText={flow(quantityValidator(tempQuantity), updateTempQuantity)}
                        onBlur={() => updateQuantity()}
                        keyboardType={'numeric'}
                      />
                    </Column>
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={incrementValue}
                    >
                      <PlusIcon />
                    </TouchableOpacity>
                  </Row>
                </Column>
              </Row>
              <Spacer size={16} />
              <DiscountComponent
                price={
                  priceValid
                    ? ScaledNumber.toNumber(
                        multiplyScaled(ScaledNumber.fromNumber(localeStringToNumber(price.input), 2), quantity ?? 1),
                      )
                    : 0
                }
                value={discountValue}
                percentage={discountPercentage}
                onUpdate={setDiscount}
                type={service.total.discount?.type === 'Percent' ? 'percentage' : 'value'}
              />
              <Spacer size={24} />
              <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                <Button
                  padding={24}
                  expand={false}
                  size="medium"
                  backgroundColor={colors.WHITE}
                  color={colors.RADICAL_RED}
                  text={t('delete')}
                  onPress={() => onDelete(service)}
                />
              </Row>
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditService;

import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import {
  Body,
  colors,
  H2s,
  Icon,
  Row,
  SearchTextInput,
  SmallBody,
  Title,
  useShowError,
  styles as meroStyles,
  H1,
  Label,
  HSpacer,
} from '@mero/components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import { LoadingComponent } from '../../../components/LoadingComponent';
import { MembershipIcon } from '../ClientDetailsScreen/components/ClientMembershipsListItemView';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import MembershipSearchFilter from './MembershipSearchFilter';
import { Filters } from './MembershipsTabWeb';

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

type Query = Parameters<typeof meroApi.memberships.getPagePurchasedMemberships>[0]['query'];

const MembershipsTabMobile: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const showError = useShowError();
  const isFocused = useIsFocused();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setFilter] = React.useState<Filters>();

  const [purchaseMemberships, setPurchaseMemberships] = React.useState<PurchasedMembership<MeroUnits.Any>[]>([]);
  const [totalPayments, setTotalPayments] = React.useState<Money<ScaledNumber, MeroUnits.Any>>();
  const [totalSellingPrice, setTotalSellingPrice] = React.useState<Money<ScaledNumber, MeroUnits.Any>>();
  const initialRender = React.useRef(true);
  const [nextPage, setNextPage] = React.useState<string>();
  const loadingNextPage = React.useRef('');

  const loadMemberships = React.useCallback(
    async (query: Query, next?: string) => {
      setIsLoading(true);
      try {
        loadingNextPage.current = next ?? '';
        const [{ data: memberships, next: nextPage }, { totalPayments, totalSellingPrice }] = await Promise.all([
          meroApi.memberships.getPagePurchasedMemberships({
            limit: 10,
            pageId: page.details._id,
            query,
            page: next,
          }),
          meroApi.memberships.getPagePurchasedMembershipsTotals({
            pageId: page.details._id,
            query,
          }),
        ]);

        setNextPage(nextPage);
        setTotalPayments(totalPayments);
        setTotalSellingPrice(totalSellingPrice);
        setPurchaseMemberships(next ? [...purchaseMemberships, ...memberships] : memberships);
      } catch (error) {
        showError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [page.details._id],
  );

  const loadMoreMemberships = () => {
    if (checkoutsState.type === 'Loaded' && nextPage && nextPage !== loadingNextPage.current) {
      loadMemberships(
        {
          purchasedAfter: checkoutsState.interval.from,
          purchasedBefore: checkoutsState.interval.to,
          search: searchQuery,
          ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
        },
        nextPage,
      );
    }
  };

  React.useEffect(() => {
    let timeout: number;
    if (checkoutsState.type === 'Loaded' && isFocused) {
      if (initialRender.current) {
        loadMemberships({
          purchasedAfter: checkoutsState.interval.from,
          purchasedBefore: checkoutsState.interval.to,
          search: searchQuery,
          ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
        });
        initialRender.current = false;
      } else {
        timeout = window.setTimeout(() => {
          loadMemberships({
            purchasedAfter: checkoutsState.interval.from,
            purchasedBefore: checkoutsState.interval.to,
            search: searchQuery,
            ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
          });
        }, 500);
      }
    } else if (!isFocused) {
      initialRender.current = true;
    }

    return () => window.clearTimeout(timeout);
  }, [
    searchQuery,
    checkoutsState.type,
    checkoutsState.type === 'Loaded' ? checkoutsState.interval : undefined,
    page.details._id,
    filter,
    isFocused,
  ]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      <InfiniteScrollView onEndReached={loadMoreMemberships} withLoading>
        <Spacer size={24} />
        <Row style={{ paddingLeft: 24, paddingRight: 16, alignItems: 'center' }}>
          <H1 style={{ flex: 1 }}>{t('memberships')}</H1>
          {totalPayments ? (
            <Body style={meroStyles.text.semibold}>
              {scaledToString(totalPayments.amount)} {t(totalPayments.unit)}
            </Body>
          ) : null}
        </Row>
        <Spacer size={16} />
        <Row style={{ paddingHorizontal: 16, zIndex: 1 }}>
          <Column style={{ flex: 1 }}>
            <SearchTextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder={t('searchMembershipPlaceholder')}
            />
          </Column>
          <HSpacer left={8} />
          <MembershipSearchFilter activeFilter={filter} onChange={setFilter} />
        </Row>
        <Spacer size={24} />
        <Column>
          {purchaseMemberships.map((membership, index) => {
            const total = membership.template.items.reduce(
              (acc, item) =>
                ScaledNumber.add(acc, item.type === 'Service' ? item.sellingPrice.amount : ScaledNumber.zero()),
              ScaledNumber.zero(),
            );

            const hasDebt = !ScaledNumber.equals(membership.debt.amount, ScaledNumber.zero());

            const { status, color } = (() => {
              if (!membership) {
                return { status: '', color: colors.COMET };
              }
              const status = PurchasedMembership.getMembershipStatus({
                status: membership.status,
                validFor: membership.template.validFor,
              });

              return {
                status: status,
                color:
                  status === 'Cancelled' || status === 'Expired'
                    ? colors.RADICAL_RED
                    : status === 'Active'
                    ? colors.SHAMROCK
                    : colors.COMET,
              };
            })();

            return (
              <React.Fragment key={membership._id}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    paddingVertical: 16,
                  }}
                  onPress={() => {
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutMembershipStack',
                      params: {
                        screen: 'ClientMembershipDetailsScreen',
                        params: { membershipPurchaseId: membership._id, userId: membership.user._id },
                      },
                    });
                  }}
                >
                  <Column>
                    <MembershipIcon />
                  </Column>
                  <Column style={{ flex: 1, paddingLeft: 16 }}>
                    <Row>
                      <Column style={{ flex: 1 }}>
                        <Label style={[{ color: color }, meroStyles.text.semibold]}>{t(status)}</Label>
                        <H2s>{membership.template.name}</H2s>
                        <Spacer size={6} />
                        <SmallBody>
                          {scaledToString(total)} {t('RON')} (
                          {DateTime.fromJSDate(membership.template.validFor.from)
                            .setLocale('ro')
                            .toFormat('dd MMM yyyy')}{' '}
                          -{' '}
                          {membership.template.validFor.type === 'Unlimited'
                            ? t('Unlimited')
                            : DateTime.fromJSDate(membership.template.validFor.to)
                                .setLocale('ro')
                                .toFormat('dd MMM yyyy')}
                          )
                        </SmallBody>
                      </Column>
                      <Column>
                        <Icon type="next" color={colors.DARK_BLUE} />
                      </Column>
                    </Row>
                    {hasDebt && (
                      <Row
                        style={{
                          backgroundColor: colors.OUTRAGEOUS_ORANGE,
                          paddingHorizontal: 12,
                          paddingVertical: 4,
                          borderRadius: 4,
                          marginTop: 12,
                        }}
                      >
                        <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>
                          {t('debtAmount')}
                        </Label>
                        <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
                          {scaledToString(membership.debt.amount)} {t(membership.debt.unit)}
                        </Label>
                      </Row>
                    )}
                  </Column>
                </TouchableOpacity>
                {index < checkoutsState.finished.data.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {!isLoading && purchaseMemberships.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s style={{ textAlign: 'center' }}>{t('noMembershipSearch')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noMembershipSearchDescription')}</Body>
              </Column>
            </>
          )}
        </Column>
      </InfiniteScrollView>
      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(MembershipsTabMobile, CurrentBusiness);

import {
  Email,
  OptionalEmail,
  FirstnameInput,
  Lastname,
  StrictPhoneNumberParsed,
  includesMultipleCategoryKeywords,
} from '@mero/api-sdk';
import { BusinessCategory, BusinessCategoryId } from '@mero/api-sdk/dist/services';
import {
  AvoidKeyboard,
  Button,
  colors,
  FormCard,
  H1,
  MeroHeader,
  SafeAreaView,
  Spacer,
  useKeyboardIsOpen,
  styles as meroStyles,
  InputWithLabel,
  TypeSafeTextInput,
  Select,
  Title,
  Body,
  Row,
  Column,
  SmallBody,
  normalize,
  useToast,
} from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../components/ModalScreenContainer';

import { useInputState } from '../../../../hooks/useInputState';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import config from '../../../../config';
import { NewPlaceDetails, PlaceName, CityName } from '../../../../contexts/OnboardingContext';
import { styles } from './styles';

type Props = {
  readonly readonly?: boolean;
  readonly goBack?: () => void;
  readonly businessCategories?: BusinessCategory[];
  readonly defaultValue?: {
    readonly name?: string;
    readonly cityName?: string;
    readonly firstname?: string;
    readonly lastname?: string;
    readonly categoryId?: BusinessCategoryId;
    readonly phone?: string;
    readonly email?: string;
  };
  readonly onSubmit: (place: NewPlaceDetails) => void;
};

const NativeOnboardingScreenView: React.FC<Props> = ({
  readonly = false,
  goBack,
  businessCategories,
  defaultValue,
  onSubmit,
}) => {
  const { t } = useTranslation('login');
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(false);

  /**
   * Place (Company) name
   */
  const [placeName, setPlaceName] = useInputState({
    input: defaultValue?.name ?? '',
    decoded: PlaceName.decode(defaultValue?.name ?? ''),
  });

  /**
   * Business category
   */
  const [categoryId, setCategoryId] = React.useState<BusinessCategoryId | undefined>(defaultValue?.categoryId);
  const categoryIdIsValid = categoryId !== undefined;

  /**
   * City name
   */
  const [cityName, setCityName] = useInputState({
    input: defaultValue?.cityName ?? '',
    decoded: CityName.decode(defaultValue?.cityName ?? ''),
  });

  /**
   * Client first name field
   */
  const [firstname, setFirstname] = useInputState({
    input: defaultValue?.firstname ?? '',
    decoded: FirstnameInput.decode(defaultValue?.firstname ?? ''),
  });

  /**
   * Client last name field
   */
  const [lastname, setLastname] = useInputState({
    input: defaultValue?.lastname ?? '',
    decoded: Lastname.decode(defaultValue?.lastname ?? ''),
  });

  /**
   * Client phone number
   */
  const [phone, setPhone] = useInputState({
    input: defaultValue?.phone ?? '',
    decoded: StrictPhoneNumberParsed.decode(defaultValue?.phone ?? ''),
  });

  /**
   * Client email
   */
  const [email, setEmail] = useInputState({
    input: defaultValue?.email ?? '',
    decoded: OptionalEmail.JSON.decode(defaultValue?.email ?? ''),
  });

  const [editPersonalDetails, setEditPersonalDetails] = React.useState(false);
  const personalDetailsAreGrouped = React.useMemo(
    () =>
      firstname.isValid &&
      lastname.isValid &&
      phone.isValid &&
      email.isValid &&
      email.value !== undefined &&
      !editPersonalDetails,
    [editPersonalDetails],
  );

  const formIsValid =
    placeName.isValid &&
    cityName.isValid &&
    categoryIdIsValid &&
    firstname.isValid &&
    lastname.isValid &&
    phone.isValid &&
    email.isValid;

  // Hide submit button on Android only
  const showSubmitButton = !(isKeyboardOpen && Platform.OS === 'android');

  const submitCallback = React.useCallback(() => {
    setShowErrors(true);

    if (
      includesMultipleCategoryKeywords({
        name: placeName.input,
        type: 'Page',
      }).includesMultipleCategoryKeywords
    ) {
      toast.show({
        type: 'error',
        text: t('invalidProfileNameError'),
      });
      return;
    }

    if (formIsValid) {
      onSubmit({
        name: placeName.value,
        cityName: cityName.value,
        firstname: firstname.value,
        lastname: lastname.value,
        categoryId: categoryId,
        phone: phone.value,
        email: email.value,
      });
    }
  }, [setShowErrors, formIsValid, onSubmit, placeName, cityName, firstname, lastname, categoryId, phone, email]);

  const editable = !readonly && businessCategories !== undefined;
  const categoryItems = React.useMemo(() => {
    if (businessCategories !== undefined) {
      return businessCategories.map((category) => ({
        value: category._id,
        label: category.name,
      }));
    } else {
      return [];
    }
  }, [businessCategories]);

  const generateLink = (name: string) => {
    const normalized = normalize(name).replace(/[^a-zA-Z0-9]/g, '-');
    return `${config.marketPlaceUrl}/${normalized}`;
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }} edges={['top', 'left', 'right']}>
      <MeroHeader title={t('accountConfiguration')} canGoBack={!!goBack} onBack={goBack} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={styles.hrPadding}
          // onScrollEndDrag={dismissKeyboardCallback}
          scrollIndicatorInsets={{ right: 1 }} // This is here to fix scroll bar in the middle: https://github.com/facebook/react-native/issues/26610
        >
          <Spacer size="16" />
          <H1 style={meroStyles.text.alignCenter}>{t('addBusinessDetails')}</H1>
          <Spacer size="32" />

          <InputWithLabel
            label={t('placeName')}
            isError={showErrors && !placeName.isValid}
            errorText={t('placeNameError')}
            highlightLabelOnError={false}
          >
            <TypeSafeTextInput
              rounded={false}
              codec={PlaceName}
              value={placeName.input}
              showError={showErrors}
              onChange={setPlaceName}
              textContentType="organizationName"
              placeholder={t('placeNamePlaceholder')}
              editable={editable}
              background={colors.WHITE}
              styles={{
                container: {
                  borderBottomEndRadius: 0,
                  borderBottomStartRadius: 0,
                  borderBottomWidth: 0,
                },
              }}
              // onFocus={() => scrollTo(300)}
            />
            <Column
              style={{
                backgroundColor: colors.COMET,
                paddingHorizontal: 12,
                paddingVertical: 4,
                borderBottomEndRadius: 4,
                borderBottomStartRadius: 4,
              }}
            >
              <SmallBody style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold', fontSize: 12 }}>
                {placeName.input ? t('placeNameHint2', { value: generateLink(placeName.input) }) : t('placeNameHint1')}
              </SmallBody>
            </Column>
          </InputWithLabel>

          <Spacer size="16" />

          <InputWithLabel
            label={t('category')}
            isError={showErrors && !categoryIdIsValid}
            errorText={t('categoryError')}
            highlightLabelOnError={false}
          >
            <Select
              items={categoryItems}
              value={categoryId}
              placeholder={t('categoryPlaceholder')}
              editable={editable}
              onChange={setCategoryId}
              isError={showErrors && !categoryIdIsValid}
            />
          </InputWithLabel>

          <Spacer size="16" />

          <InputWithLabel
            label={t('cityName')}
            isError={showErrors && !cityName.isValid}
            errorText={t('cityNameError')}
            highlightLabelOnError={false}
          >
            <TypeSafeTextInput
              codec={CityName}
              value={cityName.input}
              showError={showErrors}
              onChange={setCityName}
              textContentType="addressCity"
              placeholder={t('cityNamePlaceholder')}
              editable={editable}
              background={colors.WHITE}
              // onFocus={() => scrollTo(300)}
            />
          </InputWithLabel>

          <Spacer size="16" />

          {!personalDetailsAreGrouped && (
            <>
              <InputWithLabel
                label={t('firstName')}
                isError={showErrors && !firstname.isValid}
                errorText={t('firstNameError')}
                highlightLabelOnError={false}
              >
                <TypeSafeTextInput
                  codec={FirstnameInput}
                  value={firstname.input}
                  showError={showErrors}
                  onChange={setFirstname}
                  textContentType="givenName"
                  placeholder={t('firstNamePlaceholder')}
                  editable={editable}
                  background={colors.WHITE}
                  // onFocus={() => scrollTo(220)}
                />
              </InputWithLabel>

              <Spacer size="16" />
            </>
          )}

          {!personalDetailsAreGrouped && (
            <>
              <InputWithLabel
                label={t('lastName')}
                isError={showErrors && !lastname.isValid}
                errorText={t('lastNameError')}
                highlightLabelOnError={false}
              >
                <TypeSafeTextInput
                  codec={Lastname}
                  value={lastname.input}
                  showError={showErrors}
                  onChange={setLastname}
                  textContentType="familyName"
                  placeholder={t('lastNamePlaceholder')}
                  editable={editable}
                  background={colors.WHITE}
                  // onFocus={() => scrollTo(300)}
                />
              </InputWithLabel>

              <Spacer size="16" />
            </>
          )}

          {!personalDetailsAreGrouped && (
            <>
              <InputWithLabel
                label={t('businessPhone')}
                isError={showErrors && !phone.isValid}
                errorText={t('businessPhoneError')}
                highlightLabelOnError={false}
              >
                <TypeSafeTextInput
                  codec={StrictPhoneNumberParsed}
                  value={phone.input}
                  showError={showErrors}
                  onChange={setPhone}
                  textContentType="telephoneNumber"
                  keyboardType="phone-pad"
                  placeholder={t('businessPhonePlaceholder')}
                  editable={editable}
                  background={colors.WHITE}
                  // onFocus={() => scrollTo(100)}
                />
              </InputWithLabel>

              <Spacer size="16" />

              <InputWithLabel
                label={t('email')}
                isError={showErrors && !email.isValid}
                errorText={t('emailError')}
                highlightLabelOnError={false}
              >
                <TypeSafeTextInput
                  codec={Email.JSON}
                  value={email.input}
                  showError={showErrors}
                  onChange={setEmail}
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  placeholder={t('emailPlaceholder')}
                  editable={editable}
                  background={colors.WHITE}
                  // onFocus={() => scrollTo(100)}
                />
              </InputWithLabel>
            </>
          )}

          {personalDetailsAreGrouped && (
            <>
              <FormCard rounded>
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>
                      {firstname.input} {lastname.input}
                    </Title>
                  </Column>
                  <Column>
                    <TouchableOpacity
                      onPress={() => {
                        setEditPersonalDetails(true);
                      }}
                    >
                      <Title style={meroStyles.text.link}>{t('change')}</Title>
                    </TouchableOpacity>
                  </Column>
                </Row>
                <Body>{formatPhoneNumber(phone.input)}</Body>
                <Body style={{ color: colors.COMET }}>{email.input}</Body>
              </FormCard>
            </>
          )}

          <Spacer size={144} />
        </ScrollView>
      </AvoidKeyboard>
      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      {showSubmitButton ? (
        <FormCard
          dropShaddow
          paddings="button"
          style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button text={t('continue')} onClick={submitCallback} disabled={!editable} />
          </SafeAreaView>
        </FormCard>
      ) : null}
    </ModalScreenContainer>
  );
};

export default NativeOnboardingScreenView;

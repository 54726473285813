import {
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  H3s,
  Label,
  Line,
  Body,
  MeroHeader,
  Row,
  SmallBody,
  Spacer,
  Button,
  BottomNotification,
  useShowError,
} from '@mero/components';
import * as Clipboard from 'expo-clipboard';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, Share, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { G, Path } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { SKY_BLUE } from '@mero/components/src/styles/colors';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import config from '../../../../../config';
import { AppContext } from '../../../../../contexts/AppContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { MarketingContext } from '../../../../../contexts/MarketingContext';
import { MarketingSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { scaledToString } from '../../../../../utils/scaled';
import CancelBoostDialog from './CancelBoostDialog';

const SHARE_QUOTE = `Niciodată nu a fost mai simplu să îţi faci o programare!
Intră acum pe profilul meu de MERO și convinge-te!`;
const SHARE_HASHTAG = '#MERO';

const BoostIcon: React.FC<{ isActive: boolean }> = ({ isActive }) => (
  <Svg width={40} height={40}>
    <G data-name="Group 7065">
      <Path
        data-name="Path 9077"
        d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0Z"
        fill={isActive ? '#7431F7' : '#a8aabe'}
      />
      <Path data-name="Path 8323" d="M8 8h24v24H8Z" fill="none" />
      <Path
        d="M17.428 24.38a5.458 5.458 0 0 1-4.922 3.123 5.5 5.5 0 0 1 3.156-4.921l.382.391a4.941 4.941 0 0 0-1.607 2.595 4.932 4.932 0 0 0 2.613-1.575Zm-.852-8.5a6.487 6.487 0 0 0-4.323 1.96 6.9 6.9 0 0 0-1.247 1.689 3.26 3.26 0 0 1 3.859-.306 16.867 16.867 0 0 1 1.712-3.347Zm3.9 13.121a6.853 6.853 0 0 0 1.7-1.248 6.455 6.455 0 0 0 1.952-4.335 18.342 18.342 0 0 1-3.348 1.721 3.263 3.263 0 0 1-.302 3.858Zm8.484-17.965q-.482-.033-.946-.033c-7 0-10.845 5.568-12.072 9.754l3.32 3.32C23.596 22.686 29 18.996 29 12.086v-.045c0-.328-.014-.663-.04-1.005Zm-4.454 5.967a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

const ClipboardIcon = () => (
  <Svg width={32} height={32}>
    <G data-name="Group 5926">
      <Path data-name="Rectangle 59" fill="none" d="M0 0h32v32H0z" />
      <Path data-name="Path 8335" d="M7 7h18v18H7Z" fill="none" />
      <Path
        data-name="Path 8336"
        d="M19 7.75h-9a1.5 1.5 0 0 0-1.5 1.5v10.5H10V9.25h9Zm2.25 3H13a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h8.25a1.5 1.5 0 0 0 1.5-1.5v-10.5a1.5 1.5 0 0 0-1.5-1.5Zm0 12H13v-10.5h8.25Z"
        fill="#080de0"
      />
    </G>
  </Svg>
);

export type Props = StackScreenProps<MarketingSettingsStackParamList, 'MarketingSettings'>;

const MarketingSettingsScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('marketing');
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [pageState] = CurrentBusinessContext.useContext();
  const [appState] = AppContext.useContext();
  const { boost } = route.params || {};

  const [{ pageBoostSettings }, { getPageBoostSettings }] = MarketingContext.useContext();
  const [whyNotDialog, setWhyNotDialog] = React.useState(false);
  const [cancelDialog, setCancelDialog] = React.useState(false);

  const copyToClipboard = (value: string) => async () => {
    if (value) {
      await Clipboard.setString(value);
    }
  };

  const share = (value: string) => async () => {
    if (Platform.OS === 'web' && !navigator.share) {
      const pageUrl = encodeURIComponent(value);
      const shareQuote = encodeURIComponent(SHARE_QUOTE);
      const shareTag = encodeURIComponent(SHARE_HASHTAG);
      const shareUrl = `https://www.facebook.com/dialog/share?app_id=${config.facebookAppId}&display=popup&href=${pageUrl}&quote=${shareQuote}&hashtag=${shareTag}`;
      window.open(shareUrl, '', 'height=300,width=600');
    } else {
      try {
        await Share.share({
          message: value,
        });
      } catch {}
    }
  };

  const open = (value: string) => async () => {
    try {
      await Linking.openURL(value);
    } catch {}
  };

  const activeSince = React.useMemo(() => {
    //@ts-expect-error
    const settingsActiveSince = pageBoostSettings?.activeSince;
    if (typeof settingsActiveSince === 'string') {
      const date = DateTime.fromISO(settingsActiveSince);
      return date.isValid ? date.toFormat('dd.MM.yy') : '-';
    } else if (settingsActiveSince instanceof Date) {
      const date = DateTime.fromJSDate(settingsActiveSince);
      return date.isValid ? date.toFormat('dd.MM.yy') : '-';
    }

    return '-';
    //@ts-expect-error
  }, [pageBoostSettings.activeSince]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getPageBoostSettings(pageState.page.details._id).catch((e) => {
        log.error('Failed to get page boost settings', e);
        showError(e);
      });
    }
  }, [pageState, isFocused]);
  if (pageState.type !== 'Loaded') {
    return null;
  }

  const pageUrl = `${config.marketPlaceUrl}/p/${pageState.page.details.slug}`;
  const rightAction = {
    text: t('confirm'),
    onPress: () => {
      setWhyNotDialog(false);
    },
  };

  const isBoostActive =
    pageBoostSettings.settings.type === 'Active' &&
    DateTime.fromJSDate(pageBoostSettings.settings.start) < DateTime.local();
  const hideEnableBoost =
    pageBoostSettings.settings.type === 'Active' &&
    (!pageBoostSettings.settings.automaticReactivation ||
      DateTime.fromJSDate(pageBoostSettings.settings.start) > DateTime.local());

  const generateBoostText = () => {
    if (pageBoostSettings.settings.type === 'Active') {
      const start = DateTime.fromJSDate(pageBoostSettings.settings.start);
      if (start > DateTime.local()) {
        return pageBoostSettings.settings.budget
          ? t('boostWillStart', {
              date: start.setLocale('ro').toFormat('dd MMM yyyy'),
              value: scaledToString(pageBoostSettings.settings.budget.amount),
              currency: t(pageBoostSettings.settings.budget.unit),
            })
          : t('boostWillStartNoBudget', { date: start.setLocale('ro').toFormat('dd MMM yyyy') });
      }

      if (!pageBoostSettings.settings.automaticReactivation) {
        return pageBoostSettings.settings.budget
          ? t('boostWillEnd', {
              date: DateTime.fromJSDate(pageBoostSettings.settings.activePromotionInterval.to)
                .setLocale('ro')
                .toFormat('dd MMM yyyy'),
              value: scaledToString(pageBoostSettings.settings.budget.amount),
              currency: t(pageBoostSettings.settings.budget.unit),
            })
          : t('boostWillEndNoBudget', {
              date: DateTime.fromJSDate(pageBoostSettings.settings.activePromotionInterval.to)
                .setLocale('ro')
                .toFormat('dd MMM yyyy'),
            });
      }

      return pageBoostSettings.settings.budget
        ? t('boostWillRenew', {
            date: DateTime.fromJSDate(pageBoostSettings.settings.activePromotionInterval.to)
              .setLocale('ro')
              .toFormat('dd MMM yyyy'),
            value: scaledToString(pageBoostSettings.settings.budget.amount),
            currency: t(pageBoostSettings.settings.budget.unit),
          })
        : t('boostWillRenewNoBudget', {
            date: DateTime.fromJSDate(pageBoostSettings.settings.activePromotionInterval.to)
              .setLocale('ro')
              .toFormat('dd MMM yyyy'),
          });
    }

    if (pageBoostSettings.settings.type === 'Inactive') {
      return t('boostInactive');
    }

    return t('boostUnavailable');
  };

  const showBoost =
    boost === 'enabled' || (appState.type === 'Loaded' && appState.featureFlags.boost?.enabled === true);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t('title')} />
        <Column style={{ paddingTop: 16, flex: 1, paddingHorizontal: 16 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <H1 style={{ paddingHorizontal: 24 }}>{t('title')}</H1>
            {showBoost && (
              <>
                <Spacer size={24} />
                <FormCard rounded dropShaddow paddings="none" style={{ paddingVertical: 16 }}>
                  <TouchableOpacity
                    style={{ flexDirection: 'row', paddingHorizontal: 16 }}
                    onPress={() => navigation.navigate('Boost')}
                  >
                    <Column style={{ flex: 1 }}>
                      <Row style={{ alignItems: 'center' }}>
                        <Column
                          style={{
                            height: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: isBoostActive ? colors.SHAMROCK : '#A8AABE',
                            marginRight: 6,
                          }}
                        ></Column>
                        <SmallBody>{isBoostActive ? t('activated') : t('deactivated')}</SmallBody>
                      </Row>
                      <Spacer size={6} />
                      <H2s>{t('boost')}</H2s>
                      <Spacer size={2} />
                      <SmallBody>{generateBoostText()}</SmallBody>
                      <Spacer size={32} />
                      <Row>
                        <Column style={{ flex: 3 }}>
                          <Label style={{ color: colors.COMET }}>{t('totalValue')}</Label>
                          <H2s>
                            {pageBoostSettings.totalValueFromBoostBookings} {t('currency')}
                          </H2s>
                        </Column>
                        <Column
                          style={{ flex: 3, borderLeftWidth: 1, borderLeftColor: colors.ATHENS_GRAY, paddingLeft: 12 }}
                        >
                          <Label style={{ color: colors.COMET }}>{t('newClients')}</Label>
                          <H2s>{pageBoostSettings.newClientBookingsCount}</H2s>
                        </Column>
                        <Column
                          style={{ flex: 3, borderLeftWidth: 1, borderLeftColor: colors.ATHENS_GRAY, paddingLeft: 12 }}
                        >
                          <Label style={{ color: colors.COMET }}>{t('cost')}</Label>
                          <H2s>
                            {pageBoostSettings.cost} {t('currency')}
                          </H2s>
                        </Column>
                      </Row>
                    </Column>
                    <Column>
                      <BoostIcon isActive={isBoostActive} />
                    </Column>
                  </TouchableOpacity>
                  <Spacer size={16} />
                  {hideEnableBoost ? (
                    <>
                      <Line />
                      <Spacer size={16} />
                      <Button
                        size="medium"
                        backgroundColor={colors.SKY_BLUE}
                        color={colors.DARK_BLUE}
                        onPress={() => navigation.navigate('Boost')}
                        text={t('checkBoostReport')}
                      />
                    </>
                  ) : (
                    <>
                      <Line />
                      <Spacer size={16} />
                      <Column style={{ paddingHorizontal: 16 }}>
                        {pageBoostSettings.settings.type === 'Active' ? (
                          <>
                            <Button
                              size="medium"
                              backgroundColor={colors.SKY_BLUE}
                              color={colors.DARK_BLUE}
                              onPress={() => navigation.navigate('Boost')}
                              text={t('checkBoostReport')}
                            />
                            <Spacer size={16} />
                            <Button
                              size="medium"
                              color={colors.DARK_BLUE}
                              backgroundColor={colors.WHITE}
                              text={t('cancelBoostButton')}
                              onPress={() => setCancelDialog(true)}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              size="medium"
                              text={t('enableBoostButton')}
                              onPress={() => navigation.navigate('BoostMarketing')}
                            />
                            <Spacer size={16} />
                            <Button
                              size="medium"
                              backgroundColor={colors.SKY_BLUE}
                              color={colors.DARK_BLUE}
                              onPress={() => navigation.navigate('Boost')}
                              text={t('checkBoostReport')}
                            />
                          </>
                        )}
                      </Column>
                    </>
                  )}
                </FormCard>
              </>
            )}
            <Spacer size={24} />
            {pageState.page.details.published && (
              <FormCard rounded paddings="none" dropShaddow>
                <Spacer size={16} />
                <H3s style={{ color: '#32325D', fontFamily: 'open-sans-semibold', paddingHorizontal: 16 }}>
                  {pageState.page.details.name}
                </H3s>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <Body style={{ fontFamily: 'open-sans-semibold' }}>{t('onlineLink')}</Body>
                  <Spacer size={8} />
                  <Row style={{ alignItems: 'center' }}>
                    <Body style={{ flex: 1 }}>{pageUrl.replace(/^http(s)?\:\/\//, '')}</Body>
                    <TouchableOpacity onPress={copyToClipboard(pageUrl)}>
                      <ClipboardIcon />
                    </TouchableOpacity>
                  </Row>
                  <Spacer size={16} />
                  <Row>
                    <Column style={{ flex: 2, paddingRight: 6 }}>
                      <Button text={t('share')} size="small" onClick={share(pageUrl)} />
                    </Column>
                    <Column style={{ flex: 2, paddingLeft: 6 }}>
                      <Button
                        text={t('viewProfile')}
                        backgroundColor="#F2F2FE"
                        color={colors.DARK_BLUE}
                        size="small"
                        onClick={open(pageUrl)}
                      />
                    </Column>
                  </Row>
                  <Spacer size={24} />
                </Column>
              </FormCard>
            )}
            <Spacer size={96} />
          </ScrollView>
        </Column>
        {whyNotDialog && (
          <Column style={{ position: 'absolute', left: 0, right: 0, bottom: 0, alignItems: 'center' }}>
            <BottomNotification
              type="info"
              text={t('whyNotDetails')}
              style={{ paddingBottom: insets.bottom + 16, maxWidth: 500 }}
              leftAction={{
                text: '',
                onPress: () => null,
              }}
              rightAction={rightAction}
            />
          </Column>
        )}
      </ModalScreenContainer>
      {cancelDialog && (
        <CancelBoostDialog
          onSuccess={() => {
            getPageBoostSettings(pageState.page.details._id).catch((e) => {
              showError(e);
            });
            setCancelDialog(false);
          }}
          onCancel={() => setCancelDialog(false)}
          endDate={DateTime.fromJSDate(
            pageBoostSettings.settings.type === 'Active'
              ? pageBoostSettings.settings.activePromotionInterval.to
              : new Date(),
          )}
        />
      )}
    </>
  );
};

export default MarketingSettingsScreen;

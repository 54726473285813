import { OnlinePaymentsStatus, PageId, PortionPercentScaledNumber, ServicesPercentItem } from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type OnlinePaymentsContextState =
  | {
      readonly type: 'New';
      readonly selectedServices: ServicesPercentItem[];
      readonly status: OnlinePaymentsStatus;
    }
  | {
      readonly type: 'Loading';
      readonly selectedServices: ServicesPercentItem[];
      readonly status: OnlinePaymentsStatus;
    }
  | {
      readonly type: 'Loaded';
      readonly selectedServices: ServicesPercentItem[];
      readonly status: OnlinePaymentsStatus;
    }
  | {
      readonly type: 'Failed';
      readonly selectedServices: ServicesPercentItem[];
      readonly status: OnlinePaymentsStatus;
      readonly error: unknown;
    };

const defaultState = (): OnlinePaymentsContextState => ({
  type: 'New',
  selectedServices: [],
  status: {
    type: 'Disabled',
    commissionInfo: {
      feeText: '',
    },
  },
});

export const OnlinePaymentsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        selectedServices?: ServicesPercentItem[];
        status: OnlinePaymentsStatus;
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          selectedServices: result.selectedServices ?? state.selectedServices,
          status: result.status,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (
      state,
      payload: {
        error: unknown;
        status?: OnlinePaymentsStatus;
      },
    ) => {
      return {
        type: 'Failed',
        selectedServices: state.selectedServices,
        error: payload.error,
        status: payload.status ?? state.status,
      };
    },
    updateSelectedServices: (s, selectedServices: ServicesPercentItem[]) => ({ ...s, selectedServices }),
    mutate: (s, fn: (s: OnlinePaymentsContextState) => OnlinePaymentsContextState): OnlinePaymentsContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({ pageId }: { pageId: PageId; silent?: boolean }) => {
      try {
        log.debug('Start reloading payment settings');

        const status = await meroApi.pro.onlinePayments.getStatus(pageId);
        const selectedServices =
          status.type === 'Enabled' && status.settings.requireAdvancePayment.type === 'SpecificServices'
            ? status.settings.requireAdvancePayment.servicesMap
            : [];

        dispatch.trySetResult({
          selectedServices,
          status: status,
        });
      } catch (error) {
        dispatch.setFailed({
          error: error,
        });
        log.exception(error);
      }
    };

    return {
      reloadAsync: async (payload: { pageId: PageId }): Promise<void> => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({ pageId: payload.pageId }).catch(log.exception);

            return {
              ...state,
              type: 'Loading',
            };
          }

          return state;
        });
      },

      reload: (payload: { pageId: PageId }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload(payload).catch(log.exception);

            return {
              type: 'Loading',
              selectedServices: state.selectedServices,
              status: state.status,
            };
          }

          return state;
        });
      },
      updateSelectedServices: dispatch.updateSelectedServices,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { reload }] = OnlinePaymentsContext.useContext();

  const pageId = currentBusinessState.type === 'Loaded' ? currentBusinessState.page.details._id : undefined;

  React.useEffect(() => {
    if (pageId) {
      reload({ pageId });
    }
  }, [pageId]);

  return <>{children}</>;
};

export const withOnlinePaymentsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithOnlinePaymentsContextProvider(props: P) {
    return (
      <OnlinePaymentsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </OnlinePaymentsContext.Provider>
    );
  };
};

import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Identity = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <G fill="none">
        <Path data-name="Path 8275" d="M0 0h24v24H0z" transform="translate(3 3) translate(-3 -3)" />
        <G
          transform="translate(3 3) translate(2.25 3.937)"
          stroke={color || '#52577f'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.1}
        >
          <Path d="M11.055 0v.221a1.106 1.106 0 01-2.211 0V0H4.422v.221a1.106 1.106 0 01-2.211 0V0H0v9.729h13.267V0zM7.96 3.538h3.538M7.96 5.307h3.538M7.96 7.076h1.769" />
          <Circle data-name="layer1" cx={1.199} cy={1.199} r={1.199} transform="translate(2.799 2.876)" />
          <Path
            data-name="layer1"
            d="M3.98 5.307a2.233 2.233 0 00-2.21 2.277v.376h4.422v-.376A2.233 2.233 0 003.98 5.307z"
          />
        </G>
      </G>
    </Svg>
  );
};

export default Identity;

import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function WhatsAppIcon() {
  return (
    <Svg width={32} height={32} viewBox="242 308.95 32 32">
      <G data-name="Group 7740">
        <Path d="M242 324.9a16 16 0 110 .1z" fill="#f2f2fe" fillRule="evenodd" data-name="Ellipse 694" />
        <G data-name="Group 7731">
          <Path
            d="M251 332.034l.989-3.613a6.956 6.956 0 01-.93-3.485c.002-3.842 3.129-6.97 6.97-6.97a6.933 6.933 0 014.932 2.045 6.93 6.93 0 012.04 4.932c-.003 3.843-3.13 6.97-6.971 6.97a6.975 6.975 0 01-3.334-.849l-3.696.97zm3.867-2.232c.982.584 1.92.933 3.16.933a5.804 5.804 0 005.796-5.793 5.792 5.792 0 00-5.791-5.798 5.802 5.802 0 00-5.796 5.793c0 1.304.381 2.28 1.023 3.302l-.585 2.138 2.193-.575zm6.674-3.202c-.043-.073-.16-.116-.334-.204-.174-.087-1.03-.508-1.19-.566-.16-.058-.276-.088-.393.087-.116.174-.45.567-.551.683-.102.116-.204.13-.378.043-.174-.087-.735-.27-1.4-.864a5.243 5.243 0 01-.97-1.207c-.1-.174-.01-.269.077-.355.078-.078.174-.204.261-.306.089-.1.118-.173.176-.29.058-.116.03-.218-.014-.305s-.393-.944-.537-1.293c-.142-.34-.286-.294-.392-.3l-.335-.005a.638.638 0 00-.464.218c-.16.175-.61.596-.61 1.453 0 .858.625 1.686.712 1.802.087.116 1.228 1.875 2.975 2.63.415.179.74.286.993.367.417.132.797.113 1.097.069.335-.05 1.03-.422 1.176-.828.145-.408.145-.757.101-.83z"
            fill="#080de0"
            fillRule="evenodd"
            data-name="iconmonstr-whatsapp-1"
          />
        </G>
      </G>
    </Svg>
  );
}

export default WhatsAppIcon;

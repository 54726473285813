import { AutomaticBoostStatus, AutomaticBoostDefaultValues } from '@mero/api-sdk';
import { AnalyticsBoostGlobalStats, AnalyticsBoostStats } from '@mero/api-sdk/dist/analytics';
import { BoostClient } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export type ContextState = (
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Loading';
    }
  | {
      readonly type: 'Loaded';
    }
  | {
      readonly type: 'Failed';
      readonly error: unknown;
    }
) & {
  readonly pageBoostSettings: {
    settings: AutomaticBoostStatus;
    defaultValues: AutomaticBoostDefaultValues;
  } & AnalyticsBoostGlobalStats;
  readonly analytics?: AnalyticsBoostStats;
  readonly clients: {
    readonly new: BoostClient[];
    readonly claimed: BoostClient[];
  };
};

const defaultState = (): ContextState => ({
  type: 'New',
  pageBoostSettings: {
    settings: {
      type: 'Inactive',
    },
    defaultValues: {
      commissionPercent: 30,
      minBudget: {
        unit: 'RON',
        amount: {
          value: 10000,
          scale: 2,
        },
      },
      minCommissionValue: {
        unit: 'RON',
        amount: {
          value: 1500,
          scale: 2,
        },
      },
    } as AutomaticBoostDefaultValues,
    profit: 0,
    cost: 0,
    totalBoostBookingsCount: 0,
    totalValueFromBoostBookings: 0,
    newClientBookingsCount: 0,
  },
  clients: {
    new: [],
    claimed: [],
  },
});

export const MarketingContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        pageBoostSettings: ContextState['pageBoostSettings'];
        analytics: AnalyticsBoostStats;
        clients: ContextState['clients'];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          pageBoostSettings: result.pageBoostSettings,
          analytics: result.analytics,
          clients: result.clients,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setPageBoostSettings: (
      state,
      payload: {
        pageBoostSettings: AutomaticBoostStatus;
        defaultValues: AutomaticBoostDefaultValues;
        globalStats: AnalyticsBoostGlobalStats;
      },
    ) => {
      return {
        ...state,
        type: 'Loaded',
        pageBoostSettings: {
          ...payload.globalStats,
          settings: {
            ...state.pageBoostSettings.settings,
            ...payload.pageBoostSettings,
          },
          defaultValues: {
            ...state.pageBoostSettings.defaultValues,
            ...payload.defaultValues,
          },
        },
      };
    },

    setPageBoostIntervalStats: (
      state,
      payload: { analytics: ContextState['analytics']; clients: ContextState['clients'] },
    ) => {
      return {
        ...state,
        analytics: payload.analytics,
        clients: payload.clients,
      };
    },
    setFailed: (
      _,
      payload: {
        error: unknown;
      } & ContextState,
    ) => {
      return {
        type: 'Failed',
        error: payload.error,
        pageBoostSettings: payload.pageBoostSettings,
        analytics: payload.analytics,
        clients: payload.clients,
      };
    },
    mutate: (s, fn: (s: ContextState) => ContextState): ContextState => fn(s),
  },
  (dispatch) => {
    const reload = async (payload: { pageId: PageId; from: Date; to: Date }) => {
      try {
        log.debug('Start reloading marketing context');

        const [pageBoostSettings, defaultValues, globalStats, analytics, newClients, claimedClients] =
          await Promise.all([
            meroApi.boost.getAutomaticBoostStatus(payload.pageId),
            meroApi.boost.getBoostDefaultValues(),
            meroApi.analytics.getPageBoostGlobalStats({ pageId: payload.pageId }),
            meroApi.analytics.getPageBoostStats({ pageId: payload.pageId, from: payload.from, to: payload.to }),
            meroApi.clients.getBoostClients({
              pageId: payload.pageId,
              period: { from: payload.from, to: payload.to },
              type: 'new',
            }),
            meroApi.clients.getBoostClients({
              pageId: payload.pageId,
              period: { from: payload.from, to: payload.to },
              type: 'claimed',
            }),
          ]);

        dispatch.trySetResult({
          pageBoostSettings: { settings: pageBoostSettings, defaultValues, ...globalStats },
          analytics,
          clients: {
            new: newClients,
            claimed: claimedClients,
          },
        });
      } catch (error) {
        dispatch.setFailed({
          error: error,
          ...defaultState(),
        });
        log.exception(error);
      }
    };

    return {
      reload: (payload: { pageId: PageId; from?: Date; to?: Date }): void => {
        dispatch.mutate((state) => {
          const fromDate = payload.from || DateTime.fromJSDate(new Date()).startOf('month').toJSDate();
          const toDate = payload.to || DateTime.fromJSDate(new Date()).endOf('month').toJSDate();
          if (state.type !== 'Loading') {
            reload({
              pageId: payload.pageId,
              from: fromDate,
              to: toDate,
            }).catch(log.exception);

            return {
              type: 'Loading',
              pageBoostSettings: state.pageBoostSettings,
              analytics: state.analytics,
              clients: state.clients,
            };
          }

          return state;
        });
      },

      getPageBoostSettings: async (pageId: PageId) => {
        const [pageBoostSettings, defaultValues, globalStats] = await Promise.all([
          meroApi.boost.getAutomaticBoostStatus(pageId),
          meroApi.boost.getBoostDefaultValues(),
          meroApi.analytics.getPageBoostGlobalStats({ pageId }),
        ]);

        dispatch.setPageBoostSettings({ pageBoostSettings, defaultValues, globalStats });
      },

      getPageBoostIntervalStats: async (payload: { pageId: PageId; from?: Date; to?: Date }) => {
        const fromDate =
          payload.from || DateTime.fromJSDate(new Date(), { zone: 'Europe/Bucharest' }).startOf('month').toJSDate();
        const toDate =
          payload.to || DateTime.fromJSDate(new Date(), { zone: 'Europe/Bucharest' }).endOf('month').toJSDate();

        const [analytics, newClients, claimedClients] = await Promise.all([
          meroApi.analytics.getPageBoostStats({ pageId: payload.pageId, from: fromDate, to: toDate }),
          meroApi.clients.getBoostClients({
            pageId: payload.pageId,
            period: { from: fromDate, to: toDate },
            type: 'new',
          }),
          meroApi.clients.getBoostClients({
            pageId: payload.pageId,
            period: { from: fromDate, to: toDate },
            type: 'claimed',
          }),
        ]);

        dispatch.setPageBoostIntervalStats({
          analytics,
          clients: {
            new: newClients,
            claimed: claimedClients,
          },
        });
      },
    };
  },
);

export const withMarketingContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithMarketingContextProvider(props: P) {
    return (
      <MarketingContext.Provider>
        <Content {...props} />
      </MarketingContext.Provider>
    );
  };
};

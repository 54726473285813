import { Body, colors, ConfirmBox, H1, Icon, Line, ModalOverlay, Row, SimpleListItem, Spacer } from '@mero/components';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, TouchableOpacity, View } from 'react-native';

import { styles } from '../../../../../components/AddBookingScreen/styles';
import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import SelectDateTimeModal from '../../../../../components/SelectDateTimeModal';

import { Interval } from './PageReportsScreen';

type Props = {
  intervals: Interval[];
  activeInterval: Interval;
  onSave: (interval: Interval) => void;
  onCancel: () => void;
  showFutureDates?: boolean;
};

const SelectInterval: React.FC<Props> = ({ intervals, onSave, onCancel, activeInterval, showFutureDates = true }) => {
  const { t } = useTranslation('reports');

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [selectedInterval, setSelectedInterval] = React.useState<Interval>(activeInterval);
  const defaultCustom = React.useMemo(() => {
    return (
      intervals.find((i) => i.id === 'custom') ??
      (() => {
        const now = DateTime.utc().setLocale('ro');

        const time = now.minus({ month: intervals.length }).endOf('day');

        const start = time.startOf('month');
        const end = time.endOf('month');

        return {
          label: t('customInterval'),
          id: 'custom',
          value: {
            start,
            end,
          },
        };
      })()
    ).value;
  }, [intervals]);
  const [customStartDate, setCustomStartDate] = React.useState<DateTime>(defaultCustom.start);
  const [customEndDate, setCustomEndDate] = React.useState<DateTime>(defaultCustom.end);
  const [showSelectorStart, setShowSelectorStart] = React.useState(false);
  const [showSelectorEnd, setShowSelectorEnd] = React.useState(false);

  const cancel = () => {
    onCancel();
  };

  const save = async () => {
    if (selectedInterval.id === 'custom') {
      onSave({
        ...selectedInterval,
        label: `${customStartDate.toFormat('dd MMM')} - ${customEndDate.toFormat('dd MMM')}`,
        value: {
          start: customStartDate,
          end: customEndDate,
        },
      });
    } else {
      onSave(selectedInterval);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('save'),
    onPress: save,
    flex: 15,
  };

  const changeInterval = (interval: Interval) => () => {
    setSelectedInterval(interval);
  };

  const timezone = React.useMemo(() => IANAZone.create('UTC'), []);
  const now = React.useMemo(() => DateTime.utc().setLocale('ro').startOf('day'), []);

  const minDateStart = React.useMemo(() => now.minus({ years: 1 }), [now]);
  const maxDateStart = React.useMemo(() => now.minus({ day: 1 }), [now]);

  const minDateEnd = React.useMemo(() => minDateStart.plus({ day: 1 }), [minDateStart]);
  const maxDateEnd = React.useMemo(() => now, [now]);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('interval')}
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('interval')}</H1>
          <Spacer size="24" />
          <FlatList
            bounces={false}
            data={intervals}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={changeInterval(item)} disabled={deleteInProgress}>
                <SimpleListItem
                  icon={item.id === selectedInterval.id ? 'checked' : 'unchecked'}
                  iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
                  paddingTop={0}
                  paddingBottom={0}
                >
                  <Body>{item.id === 'custom' ? t('customInterval') : item.label}</Body>
                </SimpleListItem>
                {item.id === 'custom' && selectedInterval.id === 'custom' ? (
                  <>
                    <Spacer size={12} />
                    <Row style={{ flex: 1 }}>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          height: 47,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: '#DEE2E6',
                          // backgroundColor: '#E9ECEF',
                          alignItems: 'center',
                          paddingHorizontal: 14,
                          flex: 2,
                          marginRight: 4,
                        }}
                        onPress={() => setShowSelectorStart(true)}
                      >
                        <Body style={{ flex: 1 }} numberOfLines={1} ellipsizeMode="tail">
                          {customStartDate.toFormat('dd LLL yyyy')}
                        </Body>
                        <Icon type="dropdown" />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          height: 47,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: '#DEE2E6',
                          // backgroundColor: '#E9ECEF',
                          alignItems: 'center',
                          paddingHorizontal: 14,
                          flex: 2,
                          marginLeft: 4,
                        }}
                        onPress={() => setShowSelectorEnd(true)}
                      >
                        <Body style={{ flex: 1 }} numberOfLines={1} ellipsizeMode="tail">
                          {customEndDate.toFormat('dd LLL yyyy')}
                        </Body>
                        <Icon type="dropdown" />
                      </TouchableOpacity>
                    </Row>
                  </>
                ) : null}
              </TouchableOpacity>
            )}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={() => (
              <>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
              </>
            )}
          />
        </ConfirmBox>
        {showSelectorStart
          ? Platform.select({
              // ios: (
              //   <DateTimePickerModal
              //     isVisible
              //     date={DateTime.fromFormat(customStartDate.toFormat('dd LLL yyyy'), 'dd LLL yyyy', {
              //       locale: 'ro',
              //     }).toJSDate()}
              //     mode="date"
              //     minimumDate={minDateStart.toJSDate()}
              //     maximumDate={maxDateStart.toJSDate()}
              //     is24Hour={true}
              //     themeVariant="light"
              //     onConfirm={(date) => {
              //       const start = DateTime.fromFormat(
              //         DateTime.fromJSDate(date).toFormat('dd LLL yyyy'),
              //         'dd LLL yyyy',
              //         { zone: timezone },
              //       );
              //       if (start > customEndDate) {
              //         setCustomEndDate(start.plus({ day: 1 }));
              //       }
              //       setCustomStartDate(start);
              //       setShowSelectorStart(false);
              //     }}
              //     onCancel={() => {
              //       setShowSelectorStart(false);
              //     }}
              //     locale="ro_RO"
              //   />
              // ),
              default: (
                <View style={styles.modalScreen}>
                  <SelectDateTimeModal
                    mode="date"
                    selected={customStartDate.toJSDate()}
                    onDateSelected={(date) => {
                      const start = DateTime.fromFormat(
                        DateTime.fromJSDate(date).toUTC().toFormat('dd LLL yyyy'),
                        'dd LLL yyyy',
                        { zone: timezone },
                      ).startOf('day');
                      if (start >= customEndDate) {
                        setCustomEndDate(start.plus({ hours: 23, minutes: 59, seconds: 59 }));
                      }
                      setCustomStartDate(start);
                      setShowSelectorStart(false);
                    }}
                    onClosePress={() => {
                      setShowSelectorStart(false);
                    }}
                    minDate={minDateStart.toJSDate()}
                    maxDate={showFutureDates ? undefined : now.toJSDate()}
                    timeZone={timezone}
                  />
                </View>
              ),
            })
          : null}
        {showSelectorEnd
          ? Platform.select({
              // ios: (
              //   <DateTimePickerModal
              //     isVisible
              //     date={DateTime.fromFormat(customEndDate.toFormat('dd LLL yyyy'), 'dd LLL yyyy', {
              //       locale: 'ro',
              //     }).toJSDate()}
              //     // date={customEndDate.toJSDate()}
              //     mode="date"
              //     minimumDate={minDateEnd.toJSDate()}
              //     maximumDate={maxDateEnd.toJSDate()}
              //     is24Hour={true}
              //     themeVariant="light"
              //     onConfirm={(date) => {
              //       const end = DateTime.fromFormat(DateTime.fromJSDate(date).toFormat('dd LLL yyyy'), 'dd LLL yyyy', {
              //         zone: timezone,
              //       });
              //       if (end < customStartDate) {
              //         setCustomStartDate(end.plus({ day: -1 }));
              //       }
              //       setCustomEndDate(end);
              //       setShowSelectorEnd(false);
              //     }}
              //     onCancel={() => {
              //       setShowSelectorEnd(false);
              //     }}
              //     locale="ro_RO"
              //   />
              // ),
              default: (
                <View style={styles.modalScreen}>
                  <SelectDateTimeModal
                    mode="date"
                    selected={customEndDate.toJSDate()}
                    onDateSelected={(date) => {
                      const end = DateTime.fromFormat(
                        DateTime.fromJSDate(date).toUTC().toFormat('dd LLL yyyy'),
                        'dd LLL yyyy',
                        { zone: timezone },
                      ).endOf('day');
                      if (end <= customStartDate) {
                        setCustomStartDate(end.minus({ hours: 23, minutes: 59, seconds: 59 }));
                      }
                      setCustomEndDate(end);
                      setShowSelectorEnd(false);
                    }}
                    onClosePress={() => {
                      setShowSelectorEnd(false);
                    }}
                    minDate={minDateEnd.toJSDate()}
                    maxDate={showFutureDates ? undefined : now.toJSDate()}
                    timeZone={timezone}
                  />
                </View>
              ),
            })
          : null}
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default SelectInterval;

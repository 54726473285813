import { sizes, colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  optionsMenu: {
    position: 'absolute',
    right: 0,
    top: 12,
    minWidth: 300,
    backgroundColor: colors.WHITE,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'rgb(233, 236, 239)',
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  optionsMenuItem: {
    padding: sizes[16],
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

import {
  Body,
  Button,
  colors,
  Column,
  H1,
  Label,
  Row,
  Spacer,
  styles as meroStyles,
  MeroHeader,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dimensions, Image, ScrollView } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { IntercomContext } from '../../../contexts/IntercomContext';

const ListIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6633" transform="translate(-126 -558)">
      <Circle cx={12} cy={12} r={12} fill="#2dce89" data-name="Ellipse 11" transform="translate(126 558)" />
      <G data-name="Group 5794">
        <Path fill="#fff" d="m135.407 575.743-1.385-1.385 4.569-4.489-4.569-4.485 1.385-1.384 5.954 5.869Z" />
        <Path fill="none" d="M126 558h24v24h-24z" data-name="Rectangle 2" />
      </G>
    </G>
  </Svg>
);

type Props = {
  onEnableCheckout: () => void;
  onGoBack?: () => void;
};

const EnableCheckoutMobile: React.FC<Props> = ({ onEnableCheckout, onGoBack }) => {
  const { t } = useTranslation('checkout');
  const { isPhone, isDesktop } = useMediaQueries();
  const screenWidth = isPhone ? Dimensions.get('screen').width : 500;

  const [, { openChat }] = IntercomContext.useContext();
  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <Column style={[{ flex: 1 }, isPhone && { paddingTop: 32 }]}>
      {onGoBack ? (
        <MeroHeader onBack={onGoBack} canGoBack={true} title={t('checkoutSettings')} />
      ) : (
        <Spacer size={32} />
      )}
      <Image
        style={[
          {
            width: screenWidth,
            height: screenWidth * 1.04,
            position: 'absolute',
            top: 0,
            zIndex: -1,
          },
          isDesktop && { borderTopLeftRadius: 8, borderTopRightRadius: 8 },
        ]}
        source={require('../../../assets/images/checkout-background_mobile.png')}
      />
      <ScrollView style={{ paddingHorizontal: 24, flex: 1 }} showsVerticalScrollIndicator={false} bounces={false}>
        <Column style={{ alignItems: 'center' }}>
          <Image style={{ width: 305, height: 217 }} source={require('../../../assets/images/membership_mobile.png')} />
        </Column>
        <Spacer size={16} />
        <Column alignItems="center">
          <Column>
            <Column
              style={{
                paddingHorizontal: 8,
                paddingVertical: 4,
                backgroundColor: '#E9FAF3',
                borderRadius: 4,
                alignSelf: 'flex-start',
              }}
            >
              <Label style={[meroStyles.text.semibold, { color: colors.SHAMROCK }]}>{t('enableCheckoutLabel')}</Label>
            </Column>
          </Column>
          <Spacer size={4} />
          <H1 style={{ textAlign: 'center' }}>{t('enableCheckoutHeader')}</H1>
          <Spacer size={24} />
        </Column>
        <Row>
          <ListIcon />
          <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint1')}</Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon />
          <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint2')}</Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon />
          <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint3')}</Body>
        </Row>
        <Spacer size={12} />
        <Row>
          <ListIcon />
          <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint4')}</Body>
        </Row>
        <Spacer size={48} />
        <Button expand text={t('enableCheckoutButton')} onClick={onEnableCheckout} />
        <Spacer size={24} />
        <Body style={{ textAlign: 'center' }}>
          <Trans ns={'checkout'} t={t} i18nKey="enableCheckoutFooter">
            0
            <Body style={[{ color: colors.DARK_BLUE }, meroStyles.text.semibold]} onPress={onContactUsPressed}>
              1
            </Body>
          </Trans>
        </Body>
        <Spacer size={64} />
      </ScrollView>
    </Column>
  );
};

export default EnableCheckoutMobile;

import { colors } from '@mero/components';
import { Dimensions, Platform, StyleSheet } from 'react-native';

const { height } = Dimensions.get('window');
export const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    ...(Platform.OS === 'web' && { maxHeight: height }),
    backgroundColor: colors.WHITE,
  },
  roundedCorners: {
    borderRadius: 8,
  },
});

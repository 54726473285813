import { MergedService, ServiceGroup } from '@mero/api-sdk/dist/services';
import { Spacer, H3s } from '@mero/components';
import { Zone } from 'luxon';
import * as React from 'react';
import { FlatList, FlatListProps } from 'react-native';

import MergedServiceListItem from '../MergedServiceListItem';

export type ServiceGroupPreview = Pick<ServiceGroup, 'name'>;

export interface ServicesGroup {
  group: ServiceGroupPreview;
  services: MergedService[];
}

export const groupServices = (
  services: MergedService[],
  groups: ServiceGroup[],
  defaultGroupName: string,
): ServicesGroup[] => {
  // TODO: optimize this grouping
  return groups
    .map(
      (group): ServicesGroup => ({
        group: group,
        services: services.filter((s) => s.groupIds?.some((id) => id === group._id) ?? false),
      }),
    )
    .concat([
      {
        group: {
          name: defaultGroupName,
        },
        services: services.filter((s) => (s.groupIds?.length ?? 0) === 0),
      },
    ])
    .filter((group) => group.services.length > 0);
};

type GroupItemProps = {
  readonly group: ServiceGroupPreview;
  readonly topMargin?: boolean;
};

const GroupItem: React.FC<GroupItemProps> = ({ group, topMargin }: GroupItemProps) => (
  <>
    {topMargin ? <Spacer size="32" /> : null}
    <H3s>{group.name}</H3s>
    <Spacer size="8" />
  </>
);

type ListItem =
  | {
      readonly type: 'group';
      readonly group: ServiceGroupPreview;
    }
  | {
      readonly type: 'service';
      readonly service: MergedService;
      readonly timeZone: Zone;
    };

const flattenServices = (groups: ServicesGroup[], timeZone: Zone): ListItem[] =>
  groups.reduce(
    (acc: ListItem[], { group, services }) =>
      acc
        .concat([{ type: 'group', group: group }])
        .concat(services.map((service) => ({ type: 'service', service, timeZone }))),
    [],
  );

type ItemProps = {
  readonly item: ListItem;
  readonly index: number;
  readonly onServiceSelected?: (service: MergedService) => void;
};

const Item: React.FC<ItemProps> = ({ item, index, onServiceSelected }: ItemProps) => {
  switch (item.type) {
    case 'group':
      return <GroupItem group={item.group} topMargin={index !== 0} />;
    case 'service':
      return (
        <MergedServiceListItem
          service={item.service}
          checkIcon="next"
          onPress={() => {
            if (onServiceSelected !== undefined) {
              onServiceSelected(item.service);
            }
          }}
          hasLine
          timeZone={item.timeZone}
        />
      );
  }
};

export type Props = {
  readonly style?: FlatListProps<unknown>['style'];
  readonly services: ServicesGroup[];
  readonly timeZone: Zone;
  readonly onServiceSelected?: (service: MergedService) => void;
  readonly onScrollEndDrag?: () => void;
  readonly ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
};

const GroupedServicesList: React.FC<Props> = ({
  style,
  services,
  timeZone,
  onServiceSelected,
  onScrollEndDrag,
  ListFooterComponent,
}: Props) => {
  const flatServices = React.useMemo(() => flattenServices(services, timeZone), services);

  return (
    <>
      <FlatList<ListItem>
        contentContainerStyle={{ paddingLeft: 24, paddingRight: 24 }}
        style={style}
        data={flatServices}
        renderItem={({ item, index }) => <Item item={item} index={index} onServiceSelected={onServiceSelected} />}
        keyExtractor={(_, idx) => `${idx}`}
        onScrollEndDrag={onScrollEndDrag}
        ListFooterComponent={ListFooterComponent}
      />
    </>
  );
};

export default React.memo(GroupedServicesList);

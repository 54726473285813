import { colors, Line, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { styles } from './MembershipOptionsMenu.styles';

export type Props = {
  onSell: () => void;
  onCancel: () => void;
  onDismiss: () => void;
  isActive: boolean;
};

const MembershipOptionsMenu: React.FC<Props> = ({ onSell, onCancel, onDismiss, isActive }) => {
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation('clients');

  return (
    <ModalOverlay style={isDesktop && { alignItems: 'flex-end' }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom, isDesktop && { width: 500 }]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onSell}>
            <Title>{t('sellAgainMembership')}</Title>
          </TouchableOpacity>
          <Line />
          {isActive && (
            <>
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onCancel}>
                <Title style={{ color: colors.RADICAL_RED }}>{t('cancelMembership')}</Title>
              </TouchableOpacity>
              <Line />
            </>
          )}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default MembershipOptionsMenu;

import { ProductImage } from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import {
  NewImageType,
  Status,
} from '../../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/PageGallerySettingsScreen';

type ProductImagesState = (ProductImage | NewImageType)[];

const defaultState = (): ProductImagesState => [];

export const ProductImagesContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    setImages(_state, newImages: ProductImagesState) {
      return newImages;
    },
    removeImage(state, imageId: string) {
      return state.filter((img) => img._id !== imageId);
    },
    appendImages(state, newImages: ProductImagesState) {
      return [...state, ...newImages];
    },
    setNewImage(state, payload: { imageId: string; newImage: ProductImage }) {
      return state.map((img) => {
        if (img._id === payload.imageId) {
          return payload.newImage;
        }

        return img;
      });
    },
    setImageStatus(state, payload: { imageId: string; status: Status }) {
      return state.map((img) => {
        if (img._id === payload.imageId) {
          return {
            ...img,
            status: payload.status,
          };
        }

        return img;
      });
    },
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      setImages: dispatch.setImages,
      removeImage: dispatch.removeImage,
      appendImages: dispatch.appendImages,
      setNewImage: dispatch.setNewImage,
      setImageStatus: dispatch.setImageStatus,
    };
  },
);

export const withProductImagesContext = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithProductImagesContext(props: P) {
    return (
      <ProductImagesContext.Provider>
        <Content {...props} />
      </ProductImagesContext.Provider>
    );
  };
};

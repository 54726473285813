import {
  Body,
  Button,
  colors,
  Column,
  Line,
  Spacer,
  SafeAreaView,
  styles as meroStyles,
  SmallBody,
  Title,
  LogoIcon,
  H1,
  Row,
  HSpacer,
} from '@mero/components';
import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../components/ModalScreenContainer';

import CheckIcon from './CheckIcon';

type Props = {
  readonly onNavigateNext?: () => void;
  readonly onDownloadApp?: () => void;
};

const NativeOnboardingSuccessScreenView: React.FC<Props> = ({ onNavigateNext, onDownloadApp }) => {
  return (
    <ModalScreenContainer edges={[]}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[
          meroStyles.layout.padding24,
          { alignItems: 'center', justifyContent: 'flex-start', height: '100%' },
        ]}
        // onScrollEndDrag={dismissKeyboardCallback}
        scrollIndicatorInsets={{ right: 1 }} // This is here to fix scroll bar in the middle: https://github.com/facebook/react-native/issues/26610
      >
        <SafeAreaView style={{ flex: 1, maxWidth: 320, alignItems: 'center' }}>
          <Spacer size="32" />
          <LogoIcon />
          <Spacer size="32" />
          <H1 style={[meroStyles.text.alignCenter]}>Descarcă aplicația{`\n`}MERO Pro</H1>
          <Spacer size="8" />
          <Body style={[meroStyles.text.alignCenter]}>
            Beneficiază de cea mai bună experiență {'\n'} cu aplicația dedicată profesioniștilor
          </Body>
          <Spacer size="32" />
          <Column>
            <Row>
              <CheckIcon />
              <HSpacer left={8} />
              <Title>Notificări programări</Title>
            </Row>
            <Spacer size="12" />
            <Line />
            <Spacer size="12" />
            <Row>
              <CheckIcon />
              <HSpacer left={8} />
              <Title>Gestionare rapidă programări</Title>
            </Row>
            <Spacer size="12" />
            <Line />
            <Spacer size="12" />
            <Row>
              <CheckIcon />
              <HSpacer left={8} />
              <Title>Cele mai recente actualizări</Title>
            </Row>
            <Spacer size="12" />
            <Line />
            <Spacer size="12" />
          </Column>
          <Spacer size="32" />
          <Button text="Descarcă aplicația" onClick={onDownloadApp} />
          <Spacer size="32" />
          <TouchableOpacity onPress={onNavigateNext}>
            <Title style={[meroStyles.text.link, meroStyles.text.alignCenter]}>Continuă la varianta web</Title>
          </TouchableOpacity>
          <Spacer size="32" />
        </SafeAreaView>
      </ScrollView>
    </ModalScreenContainer>
  );
};

export default NativeOnboardingSuccessScreenView;

import { createModelContext } from '@mero/components';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { Platform } from 'react-native';

import log from '../../utils/log';
import { AppContext } from '../AppContext';
import { meroApi } from '../AuthContext';

type StateNew = {
  type: 'New';
};

type StateInitializing = {
  type: 'Initializing';
};

type StateLoaded = {
  type: 'Loaded';
  unseenCount: number;
};

type State = StateNew | StateInitializing | StateLoaded;

const defaultState = (): State => ({
  type: 'New',
});

export const UserNotificationsCountContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(s) {
      if (s.type === 'New') {
        return {
          type: 'Initializing',
        };
      }

      return s;
    },
    setLoaded(
      _,
      params: {
        unseenCount: number;
      },
    ) {
      return {
        type: 'Loaded',
        unseenCount: params.unseenCount,
      };
    },
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    return {
      init: (): void => {
        dispatch.run(async (s) => {
          if (s.type === 'New') {
            dispatch.trySetInitializing();
            try {
              const unseenCount = await meroApi.notifications.countUnseen({ type: 'worker' });

              dispatch.setLoaded({ unseenCount });
            } catch (e) {
              log.error('failed to initialize user notifications count', e);
            }
          }
        });
      },
      reloadUnseen: (): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            const unseenCount = await meroApi.notifications.countUnseen({
              type: 'worker',
            });
            log.debug('User notifications loaded, unseen: ', unseenCount);

            if (Platform.OS === 'android' || Platform.OS === 'ios') {
              try {
                await Notifications.setBadgeCountAsync(unseenCount);
              } catch (e) {
                log.error('Failed to setBadgeCountAsync', e);
              }
            }
            dispatch.setLoaded({ unseenCount });
          } else {
            log.warn(`Cannot reload unseen notifications count: state === ${s.type}, expected Loaded`);
          }
        });
      },
      markAsSeen: (): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            dispatch.setLoaded({ unseenCount: 0 });
            log.debug('Mark all worker notifications as seen');

            await meroApi.notifications.markAsSeen({
              type: 'worker',
            });

            if (Platform.OS === 'android' || Platform.OS === 'ios') {
              await Promise.all([
                Notifications.dismissAllNotificationsAsync().catch((e) => {
                  log.error('Failed to dismiss all notifications from tray', e);
                }),
                Notifications.setBadgeCountAsync(0).catch((e) => {
                  log.error('Failed to setBadgeCountAsync(0)', e);
                }),
              ]);
            }
          } else {
            log.warn(`Cannot mark notifications as seen: state === ${s.type}, expected Loaded`);
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [appState] = AppContext.useContext();
  const [, { init, reloadUnseen }] = UserNotificationsCountContext.useContext();

  React.useEffect(() => {
    let interval: number | null = null;
    if (appState.type === 'Loaded' && appState.featureFlags.notificationsRefresh?.enabled && Platform.OS === 'web') {
      interval = window.setInterval(() => {
        reloadUnseen();
      }, appState.featureFlags.notificationsRefresh.value);
    }

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [appState]);

  React.useEffect(() => {
    init();
  }, [init]);

  return <>{children}</>;
};

export const withUserNotificationsCountContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithUserNotificationsCountContextProvider(props: P) {
    return (
      <UserNotificationsCountContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </UserNotificationsCountContext.Provider>
    );
  };
};

import { Body, colors, H2s, SafeAreaView } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, Pressable } from 'react-native';

import { styles } from '../../screens/Authorized/CheckoutScreen/AddProceedMenu.styles';

import Button from '@mero/components/lib/components/Button';
import Column from '@mero/components/lib/components/Layout/Column';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import Spacer from '@mero/components/lib/components/Spacer';

import config from '../../config';
import { AppContext } from '../../contexts/AppContext';
import log from '../../utils/log';
import MeroIcon from './MeroIcon';

const UpdateScreen: React.FC = () => {
  const { t } = useTranslation('general');

  const [show, setShow] = React.useState(false);

  const [appState] = AppContext.useContext();

  const openStore = () => {
    if (Platform.OS === 'ios') {
      const iosUrl = `itms-apps://itunes.apple.com/app/${config.stores.ios}`;
      Linking.openURL(iosUrl).catch(log.error);
    } else if (Platform.OS === 'android') {
      const androidUrl = `market://details?id=${config.stores.android}`;
      Linking.openURL(androidUrl).catch(log.error);
    }
  };

  React.useEffect(() => {
    if (appState.type === 'Loaded' && appState.shouldUpdateApp) {
      setShow(true);
    }
  }, [appState]);

  const onDismiss = () => {
    setShow(false);
  };

  return show ? (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <Column style={{ backgroundColor: colors.WHITE }}>
        <SafeAreaView edges={['bottom']} style={{ justifyContent: 'center', alignItems: 'center', padding: 24 }}>
          <MeroIcon />
          <Spacer size={16} />
          <H2s style={{ textAlign: 'center' }}>{t('updateAppTitle')}</H2s>
          <Spacer size={8} />
          <Body style={{ textAlign: 'center' }}>{t('updateAppDescription')}</Body>
          <Spacer size={32} />
          <Column style={{ alignSelf: 'center' }}>
            <Button expand={false} padding={24} onPress={openStore} text={t('updateAppButton')} />
          </Column>
          <Spacer size={16} />
          <Column style={{ alignSelf: 'center' }}>
            <Button
              expand={false}
              onPress={onDismiss}
              text={t('updateCancelButton')}
              backgroundColor={colors.WHITE}
              color={colors.COMET}
            />
          </Column>
        </SafeAreaView>
      </Column>
    </ModalOverlay>
  ) : null;
};

export default UpdateScreen;

import { StyleSheet } from 'react-native';

import { WHITE } from '@mero/components/lib/styles/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
  avoidKeyboard: {
    flex: 1,
  },
  dismissKeyboard: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 32,
    paddingRight: 24,
    paddingBottom: 32,
    paddingLeft: 24,
  },
});

import { PageId } from '@mero/api-sdk';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { Checkbox, colors, H1, H2s, Label, SafeAreaView, SmallBody, useShowError } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';

import { styles } from '../../../components/AddClientScreen/styles';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import Spacer from '@mero/components/lib/components/Spacer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutDraftsCountContext } from '../../../contexts/CheckoutDraftsCountContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutStackParamList } from '../../../types';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & AuthorizedStackParamList, 'DeleteDraftsScreen'>;

const DeleteDraftsScreen: React.FC<Props> = ({ navigation, page }) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();

  const [drafts, setDrafts] = React.useState<CheckoutTransactionPreview.AnyDraft[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [checkoutDraftsCountState] = CheckoutDraftsCountContext.useContext();
  const [checkedDrafts, setCheckedDrafts] = React.useState<Record<CheckoutTransactionId, boolean>>({});

  const toggleCheck = (draftId: CheckoutTransactionId) => (value?: boolean) => {
    setCheckedDrafts((checkedDrafts) => ({ ...checkedDrafts, [draftId]: value ?? !checkedDrafts[draftId] }));
  };

  const deleteDrafts = async () => {
    setIsLoading(true);
    try {
      await meroApi.checkout.deleteTransactions({
        pageId: page.details._id,
        transactionIds: Object.entries(checkedDrafts)
          .filter(([, checked]) => checked)
          .map(([draftId]) => draftId as CheckoutTransactionId),
      });

      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const load = async (pageId: PageId, limit: number) => {
      try {
        const drafts = await meroApi.checkout.listPageDraftTransactions({ pageId, page: { limit } });

        setDrafts(drafts.data);
      } catch (error) {
        showError(error);
      }
    };

    if (checkoutDraftsCountState.type === 'Loaded') {
      load(page.details._id, checkoutDraftsCountState.draftsCount);
    }
  }, [checkoutDraftsCountState.type]);

  const selectedCount = React.useMemo(() => Object.values(checkedDrafts).filter(Boolean).length, [checkedDrafts]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canClose onClose={goBack} title={t('deleteDrafts')} />
        <FlatList
          ListHeaderComponent={() => (
            <>
              <Spacer size={36} />
              <H1 style={{ paddingHorizontal: 24 }}>{t('allDrafts')}</H1>
              <Spacer size={32} />
            </>
          )}
          data={drafts}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={{ flexDirection: 'row', paddingHorizontal: 24 }}
              onPress={() => toggleCheck(item._id)()}
            >
              <Checkbox value={checkedDrafts[item._id]} onValueChange={toggleCheck(item._id)} />
              <Column style={{ flex: 1, paddingLeft: 16 }}>
                <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t(item.status)}</Label>
                <H2s>
                  {ScaledNumber.toNumber(item.total.total.amount)} {t(item.total.total.unit)}
                </H2s>
                <Spacer size={2} />
                <SmallBody>
                  {item.client ? item.client.user.profile.firstname : t('noClient')}{' '}
                  {item.client?.user.profile.lastname}
                </SmallBody>
                <Spacer size={6} />
                <SmallBody style={{ color: colors.COMET }}>
                  {DateTime.fromJSDate(item.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                </SmallBody>
              </Column>
            </TouchableOpacity>
          )}
          ItemSeparatorComponent={() => (
            <Column style={{ paddingHorizontal: 24 }}>
              <Spacer size={16} />
              <Line />
              <Spacer size={16} />
            </Column>
          )}
        />
      </ModalScreenContainer>
      <FormCard
        dropShaddow
        paddings="button"
        style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button
            backgroundColor={colors.RADICAL_RED}
            disabledBackgroundColor="#FFE1E1"
            text={t('deleteDraftsButton')}
            onClick={deleteDrafts}
            disabled={selectedCount === 0 || isLoading}
          />
        </SafeAreaView>
      </FormCard>
    </>
  );
};

export default pipe(DeleteDraftsScreen, CurrentBusiness, Authorized);

import { RecurrenceRule } from '@mero/api-sdk';
import { BlockedTimeReason } from '@mero/api-sdk/dist/calendar/blocked-time-reason';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Body,
  CheckboxLayout,
  colors,
  H1,
  Icon,
  SelectButton,
  SmallBody,
  TypeSafeTextInput,
  styles as meroStyles,
  SimpleListItem,
  useToast,
} from '@mero/components';
import { capitalize } from '@mero/shared-sdk/dist/common';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, ScrollView, TouchableOpacity, Platform } from 'react-native';

import AvoidKeyboard from '@mero/components/lib/components/AvoidKeyboard';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import RecurrenceText from '@mero/components/lib/components/Mero/RecurrenceText';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Spacer from '@mero/components/lib/components/Spacer';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import {
  FullDayByReasonType,
  isDayStart,
  isRecurrentReason,
  isValidReason,
} from '../../contexts/BlockedTimeFormContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import log from '../../utils/log';
import BlockedTimeReasonOptionsScreen from '../BlockedTimeReasonOptionsScreen';
import ModalScreenContainer from '../ModalScreenContainer';
import RecurrenceRuleEditScreen from '../RecurrenceRuleEditScreen';
import { default as RecurrenceRuleOptionsScreen } from '../RecurrenceRuleOptionsScreen';
import SelectDateTimeModal from '../SelectDateTimeModal';
import SelectedPerformerItem from '../SelectedPerformerItem';
import { styles } from './styles';

const TimeSelectStep = 5;

export type Props = {
  readonly mode: 'add' | 'edit';

  readonly timezone: IANAZone;
  /**
   * Booking start time
   */
  readonly start: DateTime;
  /**
   * Callback executed when user selects new day time
   */
  readonly onStartChanged?: (date: DateTime) => void;

  /**
   * Booking end time
   */
  readonly end: DateTime;

  /**
   * Callback executed when user selects new booking end time
   */
  readonly onEndChanged?: (date: DateTime) => void;

  readonly isFullDay: boolean;

  readonly onIsFullDayChanged?: (isFullDay: boolean) => void;

  readonly recurrenceRule?: RecurrenceRule.Any;

  readonly onRecurrenceRuleChanged?: (newRule: RecurrenceRule.Any | undefined) => void;

  readonly performer?: SavedWorker;

  readonly showPerformerSelect?: boolean;

  readonly onSelectWorkerPress?: () => void;

  readonly reason?: BlockedTimeReason;

  readonly onReasonChanged?: (reason: BlockedTimeReason) => void;

  readonly onRemoveBlockedTimePress?: () => void;

  /**
   * Page close button pressed
   */
  readonly onClosePress?: () => void;
  readonly onBackPress?: () => void;

  readonly onSaveBlockedTime?: () => void;
};

const AddBlockedTimeScreen: React.FC<Props> = ({
  mode,
  timezone,
  start: inStart,
  onStartChanged,
  end,
  onEndChanged,
  isFullDay,
  onIsFullDayChanged,
  performer,
  showPerformerSelect = true,
  reason,
  onReasonChanged,
  onSelectWorkerPress,
  recurrenceRule,
  onRecurrenceRuleChanged,
  onRemoveBlockedTimePress,
  onClosePress,
  onBackPress,
  onSaveBlockedTime,
}: Props) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('blockedTime');
  const toast = useToast();
  const [pageState] = CurrentBusinessContext.useContext();

  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(false);

  // ScrollView reference, for field auto scrolling
  const scrollRef = React.useRef<ScrollView>(null);
  // Target ScrollView scroll value (scroll to focused field to be visible)
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  // Round start time to minutes
  const start = React.useMemo(
    () =>
      DateTime.fromMillis(
        Math.floor(inStart.toMillis() / (TimeSelectStep * 60 * 1000)) * (TimeSelectStep * 60 * 1000),
        { zone: inStart.zone },
      ),
    [inStart.toMillis(), TimeSelectStep],
    // DateTime.fromMillis doesn't apply timezone correctly
  ).setZone(inStart.zone);

  const startDate = React.useMemo(() => start.toJSDate(), [start]);
  // As fullDay end date is the next calendar day - use prev day endDate
  const endDate = React.useMemo(
    () => (isFullDay && isDayStart(end) ? end.minus({ days: 1 }).endOf('day') : end).toJSDate(),
    [end, isFullDay],
  );

  const differenceInDays = React.useMemo(() => end.diff(start, 'days').days, [start, end]);

  const [now] = React.useState(DateTime.now().setZone(inStart.zone).toJSDate());
  const [showSelectStartTimeScreen, setShowSelectStartTimeScreen] = React.useState(false);
  const [showSelectEndTimeScreen, setShowSelectEndTimeScreen] = React.useState(false);

  const formIsValid = performer !== undefined && isValidReason(reason);

  const recurrenceRuleIsPresent = recurrenceRule !== undefined;
  // Recurrence rule options select modal
  const [showRecurrenceOptions, setShowRecurrenceOptions] = React.useState(false);
  // Custom recurrence rule edit modal
  const [showRecurrenceEditForm, setShowRecurrenceEditForm] = React.useState(false);
  // Blocked time reason options select modal
  const [showReasonOptions, setShowReasonOptions] = React.useState(false);

  // For some reason types - 'full day' checkbox should be disabled
  const canChangeIsFullDay = reason ? FullDayByReasonType[reason.type] === undefined : false;

  const showSubmitButton = !(
    (isKeyboardOpen && Platform.OS === 'android') ||
    showRecurrenceOptions ||
    showReasonOptions ||
    showSelectStartTimeScreen ||
    showRecurrenceEditForm
  );

  const canEditReason = mode === 'add';
  const reasonLabelStr = React.useMemo(() => {
    if (reason !== undefined) {
      switch (reason.type) {
        case 'break': {
          return t('reasonBreak');
        }
        case 'dayOff': {
          return t('reasonDayOff');
        }
        case 'vacation': {
          return t('reasonVacation');
        }
        case 'sickLeave': {
          return t('reasonSickLeave');
        }
        case 'training': {
          return t('reasonTraining');
        }
        case 'custom': {
          return t('reasonCustom');
        }
      }
    } else {
      return t('selectReason');
    }
  }, [reason]);

  const startTimeStr = React.useMemo(
    () =>
      capitalize(
        isFullDay
          ? start.toFormat('EEEE d LLL', { locale: 'ro' })
          : start.toFormat('EEEE d LLL HH:mm', { locale: 'ro' }),
      ),
    [start, isFullDay],
  );

  const endTimeStr = React.useMemo(
    () =>
      capitalize(
        isFullDay
          ? (isDayStart(end) ? end.minus({ days: 1 }) : end).toFormat('EEEE d LLL', { locale: 'ro' })
          : end.toFormat('EEEE d LLL HH:mm', { locale: 'ro' }),
      ),
    [end, isFullDay],
  );

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const setStartTimeCallback = React.useCallback(
    (date: Date) => {
      setShowSelectStartTimeScreen(false);

      if (onStartChanged) {
        const newDate = DateTime.fromJSDate(date).setZone(timezone).set({ second: 0, millisecond: 0 });

        onStartChanged(newDate);
      }
    },
    [setShowSelectStartTimeScreen, timezone, onStartChanged],
  );

  const setEndTimeCallback = React.useCallback(
    (date: Date) => {
      setShowSelectEndTimeScreen(false);

      if (onEndChanged) {
        const newDate = DateTime.fromJSDate(date).setZone(timezone).set({ second: 0, millisecond: 0 });

        onEndChanged(newDate);
      }
    },
    [onEndChanged],
  );

  const setIsFullDayCallback = React.useCallback(
    (isFullDay: boolean) => {
      if (onIsFullDayChanged) {
        onIsFullDayChanged(isFullDay);
      }
    },
    [onIsFullDayChanged],
  );

  const toggleIsFullDayCallback = React.useCallback(() => {
    setIsFullDayCallback(!isFullDay);
  }, [isFullDay, setIsFullDayCallback]);

  const recurrenceOptionSelectedCallback = React.useCallback(
    (newRule: RecurrenceRule.Any | undefined | 'custom') => {
      setShowRecurrenceOptions(false);
      if (newRule === 'custom') {
        setShowRecurrenceEditForm(true);
      } else {
        if (onRecurrenceRuleChanged) {
          onRecurrenceRuleChanged(newRule);
        }
      }
    },
    [onRecurrenceRuleChanged, setShowRecurrenceEditForm],
  );

  const editRecurrenceRuleCallback = React.useCallback(() => {
    if (RecurrenceRule.Predefined.is(recurrenceRule)) {
      setShowRecurrenceOptions(true);
    } else {
      setShowRecurrenceEditForm(true);
    }
  }, [recurrenceRule, setShowRecurrenceOptions, setShowRecurrenceEditForm]);

  const reasonOptionSelectedCallback = React.useCallback(
    (newReason: BlockedTimeReason) => {
      setShowReasonOptions(false);

      if (onReasonChanged) {
        onReasonChanged(newReason);
      }
    },
    [onRecurrenceRuleChanged, setShowRecurrenceEditForm],
  );

  const isValidRecurrenceRule = React.useCallback(() => {
    const recurrenceDays = recurrenceRule
      ? (recurrenceRule.repeatEvery.unit === 'day'
          ? 1
          : recurrenceRule.repeatEvery.unit === 'days'
          ? 1
          : recurrenceRule.repeatEvery.unit === 'week'
          ? 7
          : recurrenceRule.repeatEvery.unit === 'weeks'
          ? 7
          : Infinity) * recurrenceRule.repeatEvery.value
      : Infinity;

    if (differenceInDays <= recurrenceDays) {
      return true;
    }

    toast.show({
      type: 'error',
      text: t('recurrenceRuleError'),
    });
    return false;
  }, [differenceInDays, recurrenceRule]);

  const saveBlockedTimeCallback = React.useCallback(() => {
    if (formIsValid && onSaveBlockedTime && isValidRecurrenceRule()) {
      onSaveBlockedTime();
    } else {
      setShowErrors(true);
    }
  }, [setShowErrors, formIsValid, onSaveBlockedTime, isValidRecurrenceRule]);

  // Scroll to requested position
  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const canWriteAllBlockedTime = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.bookings.canWriteAllBlockedTime();
    }

    return false;
  }, [pageState.type]);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader
        canGoBack={onBackPress !== undefined}
        onBack={onBackPress}
        canClose={onClosePress !== undefined}
        onClose={onClosePress}
        title={mode === 'add' ? t('createBlockedTimeTitle') : t('editBlockedTimeTitle')}
      />
      <SafeAreaView edges={['bottom']} style={{ flex: 1, flexDirection: 'column' }}>
        <AvoidKeyboard
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <ScrollView style={{ flex: 1, flexGrow: 1 }} ref={scrollRef} onScrollEndDrag={dismissKeyboardCallback}>
            <View style={styles.hrPadding}>
              <Spacer size="16" />
              <H1>{mode === 'add' ? t('createBlockedTimeTitle') : t('editBlockedTimeTitle')}</H1>

              <Spacer size="24" />

              {/* Will hide worker sction if there is no worker selection and cannot select one */}
              {showPerformerSelect ? (
                <>
                  <InputWithLabel label={t('proLabel')}>
                    <SelectedPerformerItem
                      selected={performer}
                      onPress={onSelectWorkerPress}
                      hasError={performer === undefined && showErrors ? { message: t('selectPro') } : undefined}
                      disabled={mode === 'edit' || !canWriteAllBlockedTime}
                    />
                  </InputWithLabel>

                  <Spacer size="16" />
                </>
              ) : null}

              <InputWithLabel
                label={t('reasonLabel')}
                highlightLabelOnError={false}
                isError={showErrors && !isValidReason(reason)}
                errorText={reason?.type === 'custom' ? t('setCustomReasonTextError') : t('selectReasonError')}
              >
                <SelectButton
                  text={reasonLabelStr}
                  onPress={() => {
                    setShowReasonOptions(true);
                  }}
                  disabled={!canEditReason}
                />

                {reason?.type === 'custom' ? (
                  <>
                    <Spacer size="16" />

                    <TypeSafeTextInput
                      codec={DefinedString}
                      autoFocus
                      value={reason.reason}
                      onChange={(value) => {
                        reasonOptionSelectedCallback({
                          type: 'custom',
                          reason: pipe(
                            value.decoded,
                            E.getOrElseW(() => value.input),
                          ),
                        });
                      }}
                      editable={canEditReason}
                    />
                  </>
                ) : null}
              </InputWithLabel>

              {reason !== undefined && reason.type !== 'break' ? (
                <>
                  <Spacer size="24" />
                  <CheckboxLayout value={isFullDay} onValueChange={setIsFullDayCallback} disabled={!canChangeIsFullDay}>
                    <TouchableOpacity onPress={toggleIsFullDayCallback} disabled={!canChangeIsFullDay}>
                      <Body style={{ textAlignVertical: 'center', paddingVertical: 4 }}>{t('fullDay')}</Body>
                    </TouchableOpacity>
                  </CheckboxLayout>
                </>
              ) : undefined}

              <Spacer size="16" />

              <SelectButton
                text={startTimeStr}
                onPress={() => {
                  setShowSelectStartTimeScreen(true);
                }}
              />

              <Spacer size="16" />

              <SelectButton
                text={endTimeStr}
                onPress={() => {
                  setShowSelectEndTimeScreen(true);
                }}
              />

              <Spacer size="16" />

              {recurrenceRuleIsPresent && (
                <>
                  <InputWithLabel label={t('repeat')}>
                    <TouchableOpacity onPress={editRecurrenceRuleCallback}>
                      <SimpleListItem icon="arrow-right" iconPosition="right" style={{ paddingTop: 8 }}>
                        <RecurrenceText recurrenceRule={recurrenceRule} />
                      </SimpleListItem>
                    </TouchableOpacity>
                  </InputWithLabel>
                  <Spacer size="12" />
                </>
              )}

              {!recurrenceRuleIsPresent && isRecurrentReason(reason) && (
                <>
                  <TouchableOpacity
                    onPress={editRecurrenceRuleCallback}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Icon type="recurring" />
                    <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link]}>Repetare</SmallBody>
                  </TouchableOpacity>
                </>
              )}

              <Spacer size="24" />

              {mode === 'edit' && onRemoveBlockedTimePress !== undefined ? (
                <>
                  <Button
                    size="large"
                    text={t('cancelBlockedTimeButton')}
                    backgroundColor="transparent"
                    color={colors.RADICAL_RED}
                    onClick={onRemoveBlockedTimePress}
                  />
                </>
              ) : null}
              <Spacer size={128} />
            </View>
          </ScrollView>
        </AvoidKeyboard>
      </SafeAreaView>

      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      {showSubmitButton ? (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button
                text={t('saveChanges')}
                onClick={saveBlockedTimeCallback}
                disabled={onSaveBlockedTime === undefined}
              />
            ) : (
              <Button
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('saveChanges')}
                onClick={saveBlockedTimeCallback}
                disabled={onSaveBlockedTime === undefined}
              />
            )}
          </SafeAreaView>
        </FormCard>
      ) : null}

      {showSelectStartTimeScreen
        ? Platform.select({
            // ios: (
            //   <DateTimePickerModal
            //     isVisible
            //     date={startDate}
            //     mode={isFullDay ? 'date' : 'datetime'}
            //     is24Hour={true}
            //     minuteInterval={TimeSelectStep}
            //     themeVariant="light"
            //     onConfirm={setStartTimeCallback}
            //     onCancel={() => {
            //       setShowSelectStartTimeScreen(false);
            //     }}
            //     locale="ro_RO"
            //   />
            // ),
            default: (
              <View style={styles.modalScreen}>
                <SelectDateTimeModal
                  mode={isFullDay ? 'date' : 'datetime'}
                  selected={startDate}
                  minDate={now}
                  onDateSelected={setStartTimeCallback}
                  onClosePress={() => {
                    setShowSelectStartTimeScreen(false);
                  }}
                  timeZone={timezone}
                  timeStepMinutes={TimeSelectStep}
                />
              </View>
            ),
          })
        : null}

      {showSelectEndTimeScreen
        ? Platform.select({
            // ios: (
            //   <DateTimePickerModal
            //     isVisible
            //     date={endDate}
            //     mode={isFullDay ? 'date' : 'datetime'}
            //     is24Hour={true}
            //     minuteInterval={TimeSelectStep}
            //     themeVariant="light"
            //     onConfirm={setEndTimeCallback}
            //     onCancel={() => {
            //       setShowSelectEndTimeScreen(false);
            //     }}
            //     locale="ro_RO"
            //   />
            // ),
            default: (
              <View style={styles.modalScreen}>
                <SelectDateTimeModal
                  mode={isFullDay ? 'date' : 'datetime'}
                  selected={endDate}
                  minDate={now}
                  onDateSelected={setEndTimeCallback}
                  onClosePress={() => {
                    setShowSelectEndTimeScreen(false);
                  }}
                  timeZone={timezone}
                  timeStepMinutes={TimeSelectStep}
                />
              </View>
            ),
          })
        : null}

      {showRecurrenceOptions ? (
        <RecurrenceRuleOptionsScreen
          differenceInDays={differenceInDays}
          recurrenceRule={recurrenceRule}
          onOptionSelected={recurrenceOptionSelectedCallback}
          onDismiss={() => {
            setShowRecurrenceOptions(false);
          }}
        />
      ) : null}

      {showReasonOptions ? (
        <BlockedTimeReasonOptionsScreen
          reason={reason}
          onOptionSelected={reasonOptionSelectedCallback}
          onDismiss={() => {
            setShowReasonOptions(false);
          }}
        />
      ) : null}

      {showRecurrenceEditForm ? (
        <RecurrenceRuleEditScreen
          recurrenceRule={recurrenceRule}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          onBackPressed={() => {
            setShowRecurrenceEditForm(false);
          }}
          onSave={(newRule) => {
            setShowRecurrenceEditForm(false);
            if (onRecurrenceRuleChanged) {
              onRecurrenceRuleChanged(newRule);
            }
          }}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default AddBlockedTimeScreen;

import { AnyReminderInfo } from '@mero/api-sdk';
import { ReminderId } from '@mero/api-sdk/dist/notifications/reminder-id';
import {
  Button,
  colors,
  Column,
  FormCard,
  HSpacer,
  Icon,
  Label,
  Row,
  SmallBody,
  Spacer,
  Title,
  styles as meroStyles,
} from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

const CalendarIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 6242">
      <Path
        fill="#52577f"
        d="M4.62 20.924a1.639 1.639 0 0 1-1.631-1.647V5.49A1.638 1.638 0 0 1 4.62 3.842h1.834V2.607a.612.612 0 1 1 1.223 0v1.235h3.669V2.607a.612.612 0 1 1 1.223 0v1.235h3.669V2.607a.611.611 0 1 1 1.222 0v1.235h1.835a1.638 1.638 0 0 1 1.632 1.648v13.787a1.639 1.639 0 0 1-1.63 1.647ZM4.212 5.49v13.787a.41.41 0 0 0 .408.412h14.676a.41.41 0 0 0 .407-.412V5.49a.41.41 0 0 0-.407-.411h-1.835v1.233a.611.611 0 1 1-1.222 0V5.077h-3.67v1.235a.612.612 0 1 1-1.223 0V5.077H7.677v1.235a.612.612 0 1 1-1.223 0V5.077H4.62a.41.41 0 0 0-.408.413Zm6.523 11.935v-1.852h2.446v1.852Zm-4.281 0v-1.852H8.9v1.852Zm8.561-3.087V12.49h2.446v1.852Zm-4.28 0V12.49h2.446v1.852Zm-4.281 0V12.49H8.9v1.852Zm8.561-3.087V9.399h2.446v1.852Zm-4.28 0V9.399h2.446v1.852Zm-4.281 0V9.399H8.9v1.852Z"
        data-name="Union 3"
      />
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2491" />
    </G>
  </Svg>
);

const ReviewIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 6242">
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2491" />
      <Path
        stroke="#52577f"
        d="m12 17.098 5.423 3.3-1.439-6.211 4.791-4.18-6.309-.543L12 3.607 9.535 9.464l-6.309.543 4.791 4.179-1.439 6.207Z"
        data-name="Path 9156"
      />
    </G>
  </Svg>
);

const SmsIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 7731">
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2786" />
      <Path
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m4.393 20.5 5.6-2.055a11.047 11.047 0 0 0 2.442.268c4.943 0 8.933-3.186 8.933-7.146s-3.993-7.146-8.935-7.146S3.5 7.607 3.5 11.567a6.571 6.571 0 0 0 2.71 5.121Z"
      />
    </G>
  </Svg>
);

const WhatsAppIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 7731">
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2786" />
      <G stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
        <Path d="M11.608 3.943a8 8 0 0 0-6.737 11.625l-.849 4.122a.311.311 0 0 0 .376.366l4.04-.957a8 8 0 1 0 3.17-15.156Zm4.82 12.419" />
        <Path d="m16.019 13.597-1.609-.426a.634.634 0 0 0-.593.145l-.393.37a.621.621 0 0 1-.637.124 8.387 8.387 0 0 1-2.77-2.256.507.507 0 0 1 .046-.6l.343-.41a.519.519 0 0 0 .074-.562l-.676-1.413a.591.591 0 0 0-.415-.316.637.637 0 0 0-.522.118 2.493 2.493 0 0 0-1.046 1.474c-.114 1.041.369 2.352 2.2 3.927 2.111 1.82 3.8 2.061 4.9 1.814a2.65 2.65 0 0 0 1.435-1.155.517.517 0 0 0 .055-.494.584.584 0 0 0-.4-.336Z" />
      </G>
    </G>
  </Svg>
);

type Props = {
  appointment: AnyReminderInfo;
  onMenuPress: (appointment: AnyReminderInfo) => void;
  onSendSms: (phone: string, body: string, reminderId: ReminderId) => void;
  onSendWhatsApp: (phone: string, body: string, reminderId: ReminderId) => void;
};
const AppointmentCard: React.FC<Props> = ({ appointment, onSendSms, onSendWhatsApp, onMenuPress }) => {
  const { t } = useTranslation('notifications');

  const formattedStartDate = React.useMemo(
    () => capitalize(DateTime.fromJSDate(appointment.start).setLocale('ro').toFormat('EEEE d LLL HH:mm')),
    [appointment.start],
  );

  return (
    <>
      <FormCard style={{ paddingHorizontal: 16 }} paddings="none" rounded dropShaddow>
        <Spacer size={16} />
        <Row>
          <Column>{appointment.messageType === 'ask_for_review' ? <ReviewIcon /> : <CalendarIcon />}</Column>
          <Column style={{ paddingLeft: 8, flex: 1 }}>
            <Row>
              <Column style={{ flex: 1 }}>
                <Title>{formattedStartDate}</Title>
                <Spacer size={2} />
                <SmallBody>{appointment.bookedServices.map(({ name }) => name).join(', ')}</SmallBody>
                <SmallBody style={{ color: colors.COMET }}>
                  {appointment.user.firstname} {appointment.user.lastname}
                </SmallBody>
              </Column>
              <TouchableOpacity onPress={() => onMenuPress(appointment)}>
                <Icon type="options-dots" />
              </TouchableOpacity>
            </Row>
            <Spacer size={12} />
            <Column>
              <Row>
                {appointment.messageType === 'ask_for_review' && (
                  <Column
                    style={{
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                      marginRight: 4,
                      backgroundColor: colors.ATHENS_GRAY,
                      alignSelf: 'flex-start',
                      borderRadius: 4,
                    }}
                  >
                    <Label style={{ color: colors.COMET }}>{t('reviewNotification')}</Label>
                  </Column>
                )}
                {appointment.messageType === 'booking_reminder' && (
                  <Column
                    style={{
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                      marginRight: 4,
                      backgroundColor: colors.ATHENS_GRAY,
                      alignSelf: 'flex-start',
                      borderRadius: 4,
                    }}
                  >
                    <Label style={{ color: colors.COMET }}>{t('reminderNotification')}</Label>
                  </Column>
                )}
                {appointment.manualSMSStatus?.type === 'sent' && (
                  <Column
                    style={{
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                      marginRight: 4,
                      backgroundColor: '#E9FAF3',
                      alignSelf: 'flex-start',
                      borderRadius: 4,
                    }}
                  >
                    <Label style={{ color: colors.SHAMROCK }}>{t('messageSent')}</Label>
                  </Column>
                )}
              </Row>
              <Spacer size={16} />
            </Column>
          </Column>
        </Row>
        {appointment.manualSMSStatus?.type !== 'sent' && (
          <>
            <Row>
              <Column style={{ flex: 1 }}>
                <Button
                  backgroundColor="#F2F2FE"
                  color={colors.DARK_BLUE}
                  onPress={() => onSendSms(appointment.user.phone, appointment.text, appointment.reminderId)}
                  size="small"
                  CenterComponent={() => (
                    <Row alignItems="center" justifyContent="center" flex={1}>
                      <SmsIcon color={colors.DARK_BLUE} />
                      <HSpacer left={4} />
                      <SmallBody
                        ellipsizeMode="tail"
                        numberOfLines={1}
                        style={[{ color: colors.DARK_BLUE, fontSize: 13 }, meroStyles.text.semibold]}
                      >
                        {t('sendSmsManually')}
                      </SmallBody>
                    </Row>
                  )}
                />
              </Column>
              <HSpacer left={8} />
              <Column style={{ flex: 1 }}>
                <Button
                  onPress={() => onSendWhatsApp(appointment.user.phone, appointment.text, appointment.reminderId)}
                  size="small"
                  CenterComponent={() => (
                    <Row alignItems="center" justifyContent="center" flex={1}>
                      <WhatsAppIcon color={colors.SHAMROCK} />
                      <HSpacer left={4} />
                      <SmallBody
                        ellipsizeMode="tail"
                        numberOfLines={1}
                        style={[{ color: colors.SHAMROCK, fontSize: 13 }, meroStyles.text.semibold]}
                      >
                        {t('sendWhatsAppManually')}
                      </SmallBody>
                    </Row>
                  )}
                  color={colors.SHAMROCK}
                  backgroundColor="#E8FBEF"
                />
              </Column>
            </Row>
            <Spacer size={16} />
          </>
        )}
      </FormCard>
    </>
  );
};

export default AppointmentCard;

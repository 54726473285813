import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

import { SHAMROCK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Secure = ({ size = 24, color = SHAMROCK, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_6200" data-name="Group 6200" transform="translate(-227 -941)">
      <G id="Group_6198" data-name="Group 6198" transform="translate(45 -169)">
        <Path
          id="layer1"
          d="M19.25,7.875h-.281V4.219a4.219,4.219,0,1,0-8.437,0V7.875H10.25A2.25,2.25,0,0,0,8,10.125V15.75A2.25,2.25,0,0,0,10.25,18h9a2.25,2.25,0,0,0,2.25-2.25V10.125A2.25,2.25,0,0,0,19.25,7.875ZM11.656,4.219a3.094,3.094,0,1,1,6.188,0V7.875H11.656Zm3.656,10.125a.562.562,0,0,1-1.125,0V11.531a.562.562,0,1,1,1.125,0Z"
          transform="translate(179 1113)"
          fill={color}
        />
        <Rect
          id="Rectangle_2476"
          data-name="Rectangle 2476"
          width="24"
          height="24"
          transform="translate(182 1110)"
          fill="none"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Secure);

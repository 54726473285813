import { PublicFeedbackDetailsWithAppointment } from '@mero/api-sdk';
import {
  AvoidKeyboard,
  Body,
  colors,
  ConfirmBox,
  H1,
  ModalOverlay,
  SmallBody,
  Spacer,
  TypeSafeTextInput,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  review: PublicFeedbackDetailsWithAppointment;
};

const ReplyDialog: React.FC<Props> = ({ onSuccess, onCancel, review }) => {
  const { t } = useTranslation('reviews');

  const width = useWindowDimensions().width;

  const [pageState] = CurrentBusinessContext.useContext();

  const [showErrors, setShowErrors] = React.useState(false);
  const [saveInProgress, setSaveInProgress] = React.useState(false);

  const message = React.useMemo(() => review.replies?.find((r) => r.message)?.message ?? '', [review.replies]);

  const [replyText, setReplyText] = React.useState({
    input: message,
    decoded: NonEmptyString.decode(message),
  });
  const replyTextValid = E.isRight(replyText.decoded);

  const cancel = () => {
    onCancel();
  };

  const save = async () => {
    try {
      if (replyTextValid && pageState.type === 'Loaded') {
        setSaveInProgress(true);
        await meroApi.pages.updatePrivateFeedbackReply({
          pageId: pageState.page.details._id,
          feedbackId: review._id,
          reply: replyText.input,
        });
        onSuccess();
      } else {
        setShowErrors(true);
      }
    } catch (error) {
    } finally {
      setSaveInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancelButton'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: message ? t('changeReplyButton') : t('addReplyButton'),
    onPress: save,
    flex: 15,
  };
  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <AvoidKeyboard style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
          <ConfirmBox
            type="info"
            headerTitle={message ? t('changeReply') : t('addReply')}
            canClose={!saveInProgress}
            onClose={cancel}
            leftAction={addCategoryLeftAction}
            rightAction={addCategoryRightAction}
          >
            <H1>{message ? t('changeReply') : t('addReply')}</H1>
            <Spacer size="8" />
            <Body>{t('replyDialogDescription')}</Body>
            <Spacer size="16" />
            <TypeSafeTextInput
              multiline={true}
              numberOfLines={3}
              codec={NonEmptyString}
              value={replyText.input}
              showError={showErrors}
              onChange={setReplyText}
              placeholder={t('replyPlaceholder')}
            />
            {showErrors && !replyTextValid && (
              <>
                <Spacer size={2} />
                <SmallBody style={{ color: colors.RADICAL_RED }}>{t('replyError')}</SmallBody>
              </>
            )}
            <Spacer size="8" />
          </ConfirmBox>
        </AvoidKeyboard>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default ReplyDialog;

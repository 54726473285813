import React from 'react';
import { Svg, Path, G, Rect } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

import { colors } from '../../styles';

const Arrow = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <G id="Group_5794" data-name="Group 5794" transform="translate(-2177 -5826)">
        <Path
          id="iconmonstr-arrow-63"
          d="M5.885,11.743,4.5,10.358,9.069,5.869,4.5,1.384,5.885,0l5.954,5.869Z"
          transform="translate(2180.522 5832)"
          fill={color || colors.DARK_BLUE}
        />
        <Rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width={size}
          height={size}
          transform="translate(2177 5826)"
          fill="none"
        />
      </G>
    </Svg>
  );
};

export default Arrow;

import * as MeroApi from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { colors, Icon, Line, Row, sizes, Spacer } from '@mero/components';
import * as luxon from 'luxon';
import * as React from 'react';
import { Pressable, View } from 'react-native';

import { FormCard, Title } from '../../../../../../components/shared';
import UserAppointmentRecurrenceRule from '../../../components/UserAppointmentList/UserAppointmentRecurrenceRule';
import UserAppointmentSummary from '../../../components/UserAppointmentList/UserAppointmentSummary';

import { capitalizeFirstLetter } from '../../../../../../utils/text';
import BookingBoostLabel from '../BookingBoostLabel';
import BookingStatusLabel from '../BookingStatusLabel';
import styles from './styles';

interface ClientBookingCardProps {
  readonly appointment: MeroApi.calendar.UserAppointment;
  readonly onPress: (appointment: UserAppointment) => void;
  readonly isBoost?: boolean;
}

const ClientBookingCard: React.FC<ClientBookingCardProps> = ({ appointment, onPress, isBoost = false }) => {
  const onPressCallback = React.useCallback(() => {
    onPress(appointment);
  }, [appointment, onPress]);

  const luxonDate = luxon.DateTime.fromJSDate(appointment.start).setLocale('ro').setZone(appointment.timezone);
  const bookingDate = luxonDate.toFormat('EEEE d MMM');

  const isInPast = appointment.start.getTime() < Date.now();

  return (
    <FormCard dropShaddow style={styles.container} rounded paddings="none">
      <Row flex={1}>
        {isInPast && appointment.status === 'accepted' && <View style={styles.acceptedBar} />}
        <Pressable onPress={onPressCallback} style={styles.button}>
          <View style={styles.innerContainer}>
            <View style={styles.header}>
              <View style={styles.contentWrapper}>
                <View style={styles.titleWrapper}>
                  <Title style={styles.title}>{capitalizeFirstLetter(bookingDate)}</Title>
                  <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE}></Icon>
                </View>
                <Spacer size="4" />
                <Row style={styles.labelWrapper}>
                  <BookingStatusLabel appointmentStatus={appointment.status} isInPast={isInPast} />
                  {isBoost && <BookingBoostLabel />}
                </Row>
                {!!appointment.recurrenceRule && (
                  <>
                    <Spacer size="4"></Spacer>
                    <UserAppointmentRecurrenceRule
                      recurrenceRule={appointment.recurrenceRule}
                    ></UserAppointmentRecurrenceRule>
                  </>
                )}
              </View>
            </View>

            <Spacer size="16" />
            <Line color="#E9ECEF" />
            <Spacer size="16" />

            <UserAppointmentSummary appointment={appointment}></UserAppointmentSummary>
          </View>
        </Pressable>
      </Row>
    </FormCard>
  );
};

export default ClientBookingCard;

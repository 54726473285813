import { colors, Column } from '@mero/components';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  isNoShow: boolean;
};
const NoShowIcon: React.FC<Props> = ({ isNoShow }) =>
  isNoShow ? (
    <Column style={{ marginRight: 4 }}>
      <Svg width={11} height={11}>
        <Path
          d="M5.5 0A5.5 5.5 0 1 0 11 5.5 5.5 5.5 0 0 0 5.5 0Zm2.107 8.229L5.5 6.143 3.415 8.25l-.644-.644L4.855 5.5 2.75 3.415l.644-.644L5.5 4.853 7.58 2.75l.649.643L6.145 5.5 8.25 7.58Z"
          fill={colors.BLACK}
        />
      </Svg>
    </Column>
  ) : null;

export default NoShowIcon;

import * as Application from 'expo-application';
import * as IntentLauncher from 'expo-intent-launcher';
import * as React from 'react';
import { StyleProp, ViewStyle, Linking, StyleSheet, Platform } from 'react-native';

import NoContentView, { NoContentAction } from '../NoContentView';
import HeaderView from './HeaderView';

export type PermissionDeniedViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly onClose: () => void;
};

const PermissionDeniedView: React.FC<PermissionDeniedViewProps> = ({ style, onClose }: PermissionDeniedViewProps) => {
  const actionTitle = 'Deschide setările telefonului';
  const actions: NoContentAction[] =
    Platform.OS === 'ios'
      ? [
          {
            type: 'primary',
            title: actionTitle,
            onPress: () => {
              Linking.openURL('app-settings:');
            },
          },
        ]
      : Platform.OS === 'android' && Platform.Version >= 26
      ? [
          {
            type: 'primary',
            title: actionTitle,
            onPress: () => {
              /**
               * https://forums.expo.io/t/api-to-open-app-settings/5290/15
               * https://stackoverflow.com/questions/32366649/any-way-to-link-to-the-android-notification-settings-for-my-app/45192258#45192258
               */
              IntentLauncher.startActivityAsync(IntentLauncher.ActivityAction.APPLICATION_SETTINGS, {
                data: `package:${Application.applicationId}`,
              });
            },
          },
        ]
      : [];

  return (
    <>
      <HeaderView onClose={onClose} />
      <NoContentView
        title="Permite accesul la contacte"
        subtitle="Accesul la contacte este restricționat și trebuie activat din setările telefonului."
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>({ flex: 1 }, style)}
        actions={actions}
        actionsContainerStyle={{ width: 244 }}
      />
    </>
  );
};

export default PermissionDeniedView;

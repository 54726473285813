import { CheckoutTransactionPayment } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment';
import { Button, colors, ConfirmBox, DismissKeyboard, ModalOverlay, Row, Spacer } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { NumberFromString } from '../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';

export type BankTransfer = CheckoutTransactionPayment.BankTransfer<ScaledNumber, MeroUnits.Any>;

type Props = {
  isEdit: boolean;
  amount: BankTransfer;
  onSave: (payment: BankTransfer) => void;
  onDelete: (payment: BankTransfer) => void;
  onCancel: () => void;
};

const EditBankTransfer: React.FC<Props> = ({ amount, isEdit, onSave, onCancel, onDelete }) => {
  const { t } = useTranslation('checkout');

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    if (!valueValid) {
      return setShowErrors(true);
    }
    onSave({
      ...amount,
      total: {
        ...amount.total,
        amount: ScaledNumber.fromNumber(localeStringToNumber(value.input), 2),
      },
    });
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: save,
  };

  const [value, setValue] = React.useState({
    input: scaledToString(amount.total.amount),
    decoded: NumberFromString.decode(scaledToString(amount.total.amount)),
  });
  const valueValid = E.isRight(value.decoded);

  const numberValidator =
    (prev: string) =>
    <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
      const parsed = replaceDecimalSeparator(next.input);
      return localeNumberValidator(parsed)
        ? { input: parsed, decoded: next.decoded }
        : { input: prev, decoded: next.decoded };
    };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={isEdit ? t('changeBankTransfer') : t('addBankTransfer')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{isEdit ? t('changeBankTransfer') : t('addBankTransfer')}</H1>
              <Spacer size={16} />
              <InputWithLabel
                label={t('bankTransferValue')}
                isError={showErrors && !valueValid}
                errorText={t('bankTransferValueError')}
              >
                <TypeSafeTextInput
                  codec={NumberFromString}
                  value={value.input}
                  onChange={flow(numberValidator(value.input), setValue)}
                  keyboardType="numeric"
                  placeholder={t('bankTransferValuePlaceholder')}
                  onFocus={() => {
                    setValue({
                      input: stripLocalThousandsSeparators(value.input),
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(value.input)),
                    });
                  }}
                  onBlur={() => {
                    setValue({
                      input: value.input
                        ? scaledToString(
                            ScaledNumber.fromNumber(localeStringToNumber(value.input), amount.total.amount.scale || 2),
                          )
                        : '',
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(value.input)),
                    });
                  }}
                  autoFocus
                />
              </InputWithLabel>
              <Spacer size={24} />
              {isEdit && (
                <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                  <Button
                    padding={24}
                    expand={false}
                    size="medium"
                    backgroundColor={colors.WHITE}
                    color={colors.RADICAL_RED}
                    text={t('delete')}
                    onClick={() => onDelete(amount)}
                  />
                </Row>
              )}
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default EditBankTransfer;

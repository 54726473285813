import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { Platform } from 'react-native';

import log from '../../utils/log';
import { AppContext } from '../AppContext';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

type StateNew = {
  type: 'New';
};

type StateInitializing = {
  type: 'Initializing';
};

type StateLoaded = {
  type: 'Loaded';
  draftsCount: number;
};

type State = StateNew | StateInitializing | StateLoaded;

const defaultState = (): State => ({
  type: 'New',
});

export const CheckoutDraftsCountContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(s) {
      if (s.type === 'New') {
        return {
          type: 'Initializing',
        };
      }

      return s;
    },
    setLoaded(
      _,
      params: {
        draftsCount: number;
      },
    ) {
      return {
        type: 'Loaded',
        draftsCount: params.draftsCount,
      };
    },
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    return {
      init: (pageId: PageId): void => {
        dispatch.run(async (s) => {
          if (s.type === 'New') {
            dispatch.trySetInitializing();
            try {
              const draftsCount = await meroApi.checkout.getPageDraftTransactionsCount({ pageId });

              dispatch.setLoaded({ draftsCount });
            } catch (e) {
              log.error('failed to initialize user notifications count', e);
            }
          }
        });
      },
      reload: (pageId: PageId): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            const draftsCount = await meroApi.checkout.getPageDraftTransactionsCount({ pageId });
            log.debug('Drafts count reloaded', draftsCount);

            dispatch.setLoaded({ draftsCount });
          } else {
            log.warn(`Cannot reload unseen notifications count: state === ${s.type}, expected Loaded`);
          }
        });
      },
      markAsSeen: (): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            dispatch.setLoaded({ draftsCount: 0 });
            log.debug('Mark all worker notifications as seen');

            await meroApi.notifications.markAsSeen({
              type: 'worker',
            });

            if (Platform.OS === 'android' || Platform.OS === 'ios') {
              await Promise.all([
                Notifications.dismissAllNotificationsAsync().catch((e) => {
                  log.error('Failed to dismiss all notifications from tray', e);
                }),
                Notifications.setBadgeCountAsync(0).catch((e) => {
                  log.error('Failed to setBadgeCountAsync(0)', e);
                }),
              ]);
            }
          } else {
            log.warn(`Cannot mark notifications as seen: state === ${s.type}, expected Loaded`);
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [appState] = AppContext.useContext();
  const [, { init, reload }] = CheckoutDraftsCountContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [currentPageId, setCurrentPageId] = React.useState<PageId | undefined>(undefined);

  React.useEffect(() => {
    let interval: number | null = null;
    if (
      appState.type === 'Loaded' &&
      appState.featureFlags.notificationsRefresh?.enabled &&
      Platform.OS === 'web' &&
      pageState.type === 'Loaded' &&
      pageState.page.permissions.checkout.hasAny() &&
      currentPageId !== pageState.page.details._id
    ) {
      reload(pageState.page.details._id);
      setCurrentPageId(pageState.page.details._id);
      interval = window.setInterval(() => {
        reload(pageState.page.details._id);
      }, appState.featureFlags.notificationsRefresh.value);
    }

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [appState, pageState]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && pageState.page.permissions.checkout.hasAny()) {
      init(pageState.page.details._id);
    }
  }, [pageState.type]);

  return <>{children}</>;
};

export const withCheckoutNotificationsCountContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithCheckoutNotificationsCountContextProvider(props: P) {
    return (
      <CheckoutDraftsCountContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </CheckoutDraftsCountContext.Provider>
    );
  };
};

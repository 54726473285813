import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ProsMobileScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProsMobileScreen';
import ProsWebScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProsWebScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { ProsDashboardStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<ProsDashboardStackParamList>();

function ProsDashboardStackNavigator(): React.ReactElement {
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="ProsWeb"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ProsWeb" component={ProsWebScreen} options={{ title: t('pros') }} />
      <Stack.Screen
        name="ProsMobile"
        component={ProsMobileScreen}
        options={{ title: t('pros'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default ProsDashboardStackNavigator;

import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationScissorsIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#2dce89',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" fill={backgroundColor} />
    <G transform="translate(-144 -1296.372)">
      <Path
        d="M7.289,6.737C5.183,7.751,2.741,8.931,2.147,9.216,1.027,9.755,0,8.34,0,8.34L5.31,5.779ZM12.1,10.91a2.87,2.87,0,0,1-1.251-.29A2.648,2.648,0,0,1,9.33,7.677c.158-.641-.179-.728-.694-.977L.036,2.539s1.03-1.412,2.149-.87C2.873,2,5.634,3.334,8.013,4.488l.6-.287c.515-.248.852-.335.7-.975A2.647,2.647,0,0,1,10.827.287,2.879,2.879,0,0,1,12.072,0a2.35,2.35,0,0,1,2.452,2.3A2.766,2.766,0,0,1,12.94,4.712c-.985.495-1.676.109-2.655.585l-.305.148.335.164c.978.478,1.669.095,2.653.592a2.765,2.765,0,0,1,1.579,2.406,2.35,2.35,0,0,1-2.451,2.3ZM10.547,3.143a1.378,1.378,0,0,0,1.874.481,1.387,1.387,0,0,0,.8-1.767,1.379,1.379,0,0,0-1.875-.481A1.387,1.387,0,0,0,10.547,3.143ZM8.339,5.34a.455.455,0,1,0-.455.455A.454.454,0,0,0,8.339,5.34Zm4.107,1.947a1.378,1.378,0,0,0-1.876.476,1.386,1.386,0,0,0,.8,1.769,1.378,1.378,0,0,0,1.875-.477,1.385,1.385,0,0,0-.8-1.769Z"
        transform="translate(158.528 1303.1) rotate(45)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationScissorsIcon);

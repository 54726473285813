import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import PageReviewsScreen from '../screens/Authorized/MenuScreen/screens/PageReviewsScreen/PageReviewsScreen';
import PageReviewsContainer from '../screens/Authorized/MenuScreen/screens/PageReviewsScreen/PageReviewsScreen';
import ReviewSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageReviewsScreen/ReviewSettingsScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { PageReviewsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<PageReviewsStackParamList>();

function PageReviewsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('reviews');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <Stack.Navigator
      initialRouteName="Reviews"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Reviews" component={PageReviewsContainer} options={{ title: t('reviews') }} />
      <Stack.Screen name="Settings" component={ReviewSettingsScreen} options={{ title: t('reviews') }} />
    </Stack.Navigator>
  );
}

export default PageReviewsStackNavigator;

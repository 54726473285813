import { Icon, HSpacer, Title, Label, SmallBody, sizes, colors } from '@mero/components';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { styles } from './styles';

export type MenuIcon =
  | 'pro-menu-marketing'
  | 'pro-menu-payments'
  | 'pro-menu-places'
  | 'pro-menu-reports'
  | 'pro-menu-reviews'
  | 'pro-menu-services'
  | 'pro-menu-subscription'
  | 'pro-menu-team'
  | 'pro-menu-page-details'
  | 'pro-menu-profile-details'
  | 'pro-menu-photo-gallery'
  | 'pro-menu-permissions'
  | 'pro-menu-client-notifications'
  | 'pro-menu-pending-clients'
  | 'pro-menu-calendar-settings'
  | 'pro-menu-giftcards'
  | 'pro-menu-location';

export type MenuItemProps = {
  readonly icon?: MenuIcon | React.ReactElement;
  readonly title: string;
  readonly titleLabel?: string;
  readonly subtitle?: string;
  readonly subtitleColor?: string;
  readonly badge?: string;
  readonly onPress?: () => void;
  readonly disabled?: boolean;
  readonly hideNextIcon?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  titleLabel,
  subtitle,
  subtitleColor,
  icon,
  badge,
  onPress,
  disabled,
  hideNextIcon,
}: MenuItemProps) => {
  const getIcon = React.useCallback(() => {
    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <Icon type={icon} disabled />;
    }

    return icon;
  }, [icon]);

  const MenuIcon = getIcon();

  return (
    <TouchableOpacity style={styles.menuItem} onPress={onPress} disabled={disabled}>
      {icon !== undefined ? (
        <>
          {MenuIcon}
          <HSpacer left={16} />
        </>
      ) : undefined}
      <View style={styles.menuTextContainer}>
        <View style={styles.menuTitleContainer}>
          <Title style={styles.menuTitle}>{title}</Title>
          {titleLabel ? (
            <View style={styles.menuTitleLabelContainer}>
              <Label style={styles.menuTitleLabel}>{titleLabel}</Label>
            </View>
          ) : null}
        </View>
        {subtitle ? (
          <View>
            <SmallBody style={{ color: subtitleColor }}>{subtitle}</SmallBody>
          </View>
        ) : null}
      </View>
      {badge !== undefined ? (
        <View style={styles.menuItemBadge}>
          <SmallBody style={styles.menuItemBadgeText}>{badge}</SmallBody>
        </View>
      ) : null}
      {!disabled && !hideNextIcon && <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />}
    </TouchableOpacity>
  );
};

export default MenuItem;

import { Body, colors, Icon, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

type Props = {
  readonly onPress?: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

const AddBlockedTimeButton: React.FC<Props> = ({ style, onPress }) => {
  return (
    <View style={[{ height: 43 }, style]}>
      <TouchableOpacity onPress={onPress}>
        <View
          style={[
            {
              flexDirection: 'row',
              height: 43,
              borderRadius: 25,
              backgroundColor: colors.WHITE,
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 24,
              width: 'auto',
            },
          ]}
        >
          <Icon type="calendar-block" size={24} color={colors.DARK_BLUE} />
          <Body style={[{ color: colors.DARK_BLUE, marginLeft: 8 }, meroStyles.text.semibold]} numberOfLines={1}>
            Blochează timp
          </Body>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default AddBlockedTimeButton;

import { ClientHistoryRecord } from '@mero/api-sdk/dist/clients';
import { colors } from '@mero/components';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from './ClientEmptyListView';
import ClientHistoryListItemView from './ClientHistoryListItemView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly history: ClientHistoryRecord[];
  readonly now: Date;
};

const ClientHistoryListView: React.FC<Props> = ({ HeaderElement, history, now }) => {
  const renderItem = React.useCallback(
    ({ item }: { item: ClientHistoryRecord }) => {
      return <ClientHistoryListItemView record={item} now={now} />;
    },
    [now],
  );

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={history}
      keyboardShouldPersistTaps="handled"
      keyExtractor={clientHistoryItemKeyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există istoric" subtitle="Istoricul clientului va fi afișat aici" />
      }
    />
  );
};

const clientHistoryItemKeyExtractor = (record: ClientHistoryRecord): string => record._id;

export default ClientHistoryListView;

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import OptionsMenuModal from '../../../components/OptionsMenuModal';

type Props = {
  readonly onDeleteOnlyOne: () => void;
  readonly onDeleteAll: () => void;
  readonly onDismiss: () => void;
};

const ConfirmDeleteRecurrentBlockedTime: React.FC<Props> = ({ onDeleteOnlyOne, onDeleteAll, onDismiss }) => {
  const { t } = useTranslation('blockedTime');

  return (
    <OptionsMenuModal
      title={t('cancelRecurrentTitle')}
      onDismiss={onDismiss}
      menuItems={[
        {
          title: t('cancelThisBlockedTime'),
          onPress: onDeleteOnlyOne,
          color: 'red',
        },
        {
          title: t('cancelAllBlockedTimeRecurrences'),
          onPress: onDeleteAll,
          color: 'red',
        },
      ]}
    />
  );
};

export default ConfirmDeleteRecurrentBlockedTime;

import { PositiveNumber } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  ConfirmBox,
  DismissKeyboard,
  Line,
  ModalOverlay,
  Row,
  SmallBody,
  Spacer,
  TextInput,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';

import log from '../../../utils/log';
import { NumberFromString } from '../../../utils/number';
import { scaledToString, stripLocalThousandsSeparators } from '../../../utils/scaled';
import { sequence } from '../../../utils/sequence';

type Props =
  | {
      allowZero?: boolean;
      totalPrice: number;
      partialPayment: number;
      onSave: (payment: number) => void;
      onCancel: () => void;
    } & (
      | {
          withDelete: true;
          onDelete: () => void;
        }
      | { withDelete?: false }
    );

const PositiveNumberWithZero = t.refinement(
  t.number,
  (input): input is t.Branded<number, { readonly PositiveNumber: unique symbol }> => input >= 0,
  'PositiveNumberWithZero',
);

type PositiveNumberWithZero = t.TypeOf<typeof PositiveNumberWithZero>;

const MaxValueNumber = (limit: number) =>
  t.refinement(
    t.number,
    (input): input is t.Branded<number, { readonly MaxValueNumber: unique symbol }> => input <= limit,
    'MaxValueNumber',
  );

const MembershipPartialPayment: React.FC<Props> = ({
  allowZero = false,
  totalPrice,
  partialPayment,
  onSave,
  onCancel,
  ...rest
}) => {
  const { t } = useTranslation('checkout');

  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const cancel = React.useCallback(() => {
    onCancel();
  }, []);

  const save = () => {
    onSave(price);
  };

  const [tempPrice, setTempPrice] = React.useState(partialPayment.toLocaleString());

  const [price, setPrice] = React.useState(partialPayment as PositiveNumber | PositiveNumberWithZero);
  const [isChanging, setIsChanging] = React.useState(false);

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
  };

  const rightAction = {
    text: t('save'),
    onPress: price && !isChanging ? save : undefined,
  };

  const numberValidator =
    (prev: PositiveNumber | PositiveNumberWithZero) =>
    (next: string): PositiveNumber | PositiveNumberWithZero => {
      const decoder = sequence(
        NumberFromString,
        MaxValueNumber(totalPrice),
        allowZero ? PositiveNumberWithZero : PositiveNumber.JSON,
      );

      return pipe(
        next,
        decoder.decode,
        E.getOrElseW((e) => {
          log.debug('Error while parsing number', e);
          return prev;
        }),
      );
    };

  const updatePrice = (value: PositiveNumber | PositiveNumberWithZero) => {
    setPrice(value);
    setTempPrice(value.toLocaleString());
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView style={{ flex: 1, justifyContent: 'center' }}>
            <ConfirmBox
              type="info"
              headerTitle={t('partialPaymentText')}
              canClose={!actionInProgress}
              onClose={cancel}
              leftAction={leftAction}
              rightAction={rightAction}
              style={{ width: 375 }}
            >
              <H1>{t('addPartialPaymentDescriptioin')}</H1>
              <Spacer size={16} />
              <InputWithLabel label={t('proceedAmountInput')} isError={showErrors} errorText={t('proceedAmountError')}>
                <TextInput
                  value={tempPrice}
                  onChange={setTempPrice}
                  keyboardType={'numeric'}
                  autoFocus
                  onFocus={() => pipe(tempPrice, stripLocalThousandsSeparators, () => setIsChanging(true))}
                  onBlur={() => pipe(tempPrice, numberValidator(price), updatePrice, () => setIsChanging(false))}
                />
              </InputWithLabel>
              <Spacer size={16} />
              <Row>
                <SmallBody style={{ width: '60%', color: colors.COMET }}>{t('totalMembership')}</SmallBody>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', width: '40%', textAlign: 'right' }}>
                  {scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')}
                </SmallBody>
              </Row>
              <Spacer size={12} />
              <Row>
                <SmallBody style={{ width: '60%', color: colors.COMET }}>{t('payMembershipAmount')}</SmallBody>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', width: '40%', textAlign: 'right' }}>
                  {scaledToString(ScaledNumber.fromNumber(price ? price : partialPayment, 2))} {t('RON')}
                </SmallBody>
              </Row>
              <Spacer size={12} />
              <Line />
              <Spacer size={12} />
              <Row>
                <Body style={{ width: '60%', color: colors.COMET }}>{t('remainingPayMembership')}</Body>
                <Body style={{ fontFamily: 'open-sans-semibold', width: '40%', textAlign: 'right' }}>
                  {scaledToString(ScaledNumber.fromNumber(totalPrice - (price ? price : partialPayment), 2))} {t('RON')}
                </Body>
              </Row>
              {rest.withDelete && (
                <>
                  <Spacer size={24} />
                  <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                    <Button
                      padding={24}
                      expand={false}
                      size="medium"
                      backgroundColor={colors.WHITE}
                      color={colors.RADICAL_RED}
                      text={t('delete')}
                      onClick={rest.onDelete}
                    />
                  </Row>
                </>
              )}
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default MembershipPartialPayment;

import { DefinedString } from '@mero/api-sdk';
import { AvoidKeyboard, Body, ConfirmBox, H1, ModalOverlay, Spacer, TypeSafeTextInput } from '@mero/components';
import * as React from 'react';
import { ScrollView } from 'react-native';

type Props = {
  readonly remark?: DefinedString;
  readonly onDismiss: () => void;
  readonly onAdd: (text: DefinedString) => void;
};

const ClientRemarkModal: React.FC<Props> = ({ remark, onDismiss, onAdd }) => {
  const [text, setText] = React.useState({
    input: remark ?? '',
    decoded: DefinedString.decode(remark ?? ''),
  });

  const decoded = text.decoded;
  const isValid = decoded._tag === 'Right';

  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: remark ? 'Modifică' : 'Adaugă',
    onPress: isValid
      ? () => {
          onAdd(decoded.right);
        }
      : undefined,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <AvoidKeyboard>
        <ScrollView
          contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          keyboardShouldPersistTaps="handled"
        >
          <ConfirmBox
            type="info"
            headerTitle={remark ? 'Modifică observație' : 'Adaugă observație'}
            canClose={true}
            onClose={onDismiss}
            leftAction={leftAction}
            rightAction={rightAction}
          >
            <H1>{remark ? 'Modifică observație' : 'Adaugă observație'}</H1>
            <Spacer size="8" />
            <Body>Observația va fi vizibilă doar intern, pentru profesioniști</Body>
            <Spacer size="16" />
            <TypeSafeTextInput
              codec={DefinedString}
              value={text.input}
              onChange={setText}
              placeholder="Introdu observația"
              multiline
              numberOfLines={3}
            />
          </ConfirmBox>
        </ScrollView>
      </AvoidKeyboard>
    </ModalOverlay>
  );
};

export default ClientRemarkModal;

import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  listContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    paddingLeft: 24,
    paddingTop: 24,
    paddingRight: 24,
    paddingBottom: 34,
  },
  stretchContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
});

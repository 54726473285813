import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  menuItem: {
    height: 64,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.WHITE,
  },
  menuTextContainer: {
    flex: 1,
  },
  menuTitleContainer: {
    flexDirection: 'row',
  },
  menuTitle: {
    fontSize: 18,
  },
  menuTitleLabelContainer: {
    marginLeft: 12,
    backgroundColor: '#A27AF1',
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 4,
    height: 17,
    alignSelf: 'center',
  },
  menuTitleLabel: {
    color: colors.WHITE,
    fontFamily: 'open-sans-bold',
    fontSize: 9,
    lineHeight: 13,
  },
  menuItemBadge: {
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: colors.RADICAL_RED,
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 9,
    height: 17,
    minWidth: 17,
    paddingLeft: 5,
    paddingRight: 5,
  },
  menuItemBadgeText: {
    color: colors.WHITE,
    fontSize: 13,
    lineHeight: 17,
    textAlign: 'center',
  },
});

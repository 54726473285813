import { colors, Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { styles } from './styles';

export type Props = {
  readonly onDismiss: () => void;
  readonly onEdit: () => void;
  readonly onDelete: () => void;
};

const ClientRemarkOptionsModal: React.FC<Props> = ({
  onDismiss,
  onEdit,
  onDelete,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onEdit}>
            <SimpleListItem>
              <Title>Modifică observație</Title>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDelete}>
            <SimpleListItem>
              <Title style={{ color: colors.RADICAL_RED }}>Șterge observație</Title>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientRemarkOptionsModal;

import { ApiError, StrictPhoneNumber, StrictPhoneNumberParsed } from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import * as common from '@mero/shared-sdk';
import * as React from 'react';

import { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { authApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type NovaAuthState =
  /**
   * Initial state for Nova authentication flow
   */
  | {
      readonly type: 'New';
      readonly phone: ValueIO<StrictPhoneNumber>;
    }
  /**
   * Sending code in progress ...
   */
  | {
      readonly type: 'SendingCode';
      readonly phone: common.PhoneNumber;
    }
  /**
   * OTP Code sent to user, user must enter OTP code and submit
   */
  | {
      readonly type: 'CodeSent';
      readonly phone: common.PhoneNumber;
      readonly otpRequestId: string;
    }
  /**
   * User has requested to send SMS himself, waiting server to receive code
   */
  | {
      readonly type: 'PendingCode';
      readonly phone: common.PhoneNumber;
      readonly otpRequestId: string;
    }
  /**
   * Code validation in progress
   */
  | {
      readonly type: 'ValidatingCode';
      readonly phone: common.PhoneNumber;
      readonly otpRequestId: string;
      readonly code: string;
    }
  | {
      readonly type: 'Success';
      readonly novaToken: string;
      readonly phone: common.PhoneNumber;
    };

const defaultState = (): NovaAuthState => ({
  type: 'New',
  phone: {
    input: '',
    decoded: StrictPhoneNumberParsed.decode(''),
  },
});

export const NovaAuthContext = createModelContext(
  defaultState(),
  {
    setPhone(_, phone: ValueIO<StrictPhoneNumber>) {
      return {
        type: 'New',
        phone: phone,
      };
    },
    setSendingCode(_, phone: common.PhoneNumber) {
      return {
        type: 'SendingCode',
        phone: phone,
      };
    },
    setCodeSent(
      _,
      payload: {
        phone: common.PhoneNumber;
        otpRequestId: string;
      },
    ) {
      return {
        type: 'CodeSent',
        phone: payload.phone,
        otpRequestId: payload.otpRequestId,
      };
    },
    setPendingCode(
      _,
      payload: {
        phone: common.PhoneNumber;
        otpRequestId: string;
      },
    ) {
      return {
        type: 'PendingCode',
        phone: payload.phone,
        otpRequestId: payload.otpRequestId,
      };
    },
    setValidatingCode(
      _,
      payload: {
        phone: common.PhoneNumber;
        otpRequestId: string;
        code: string;
      },
    ) {
      return {
        type: 'ValidatingCode',
        phone: payload.phone,
        otpRequestId: payload.otpRequestId,
        code: payload.code,
      };
    },
    setSuccess(_, params: { novaToken: string; phone: common.PhoneNumber }) {
      return {
        type: 'Success',
        novaToken: params.novaToken,
        phone: params.phone,
      };
    },
    mutate: (s, fn: (s: NovaAuthState) => NovaAuthState): NovaAuthState => fn(s),
  },
  (dispatch) => {
    return {
      async sendVerificationCode(params: {
        phone: common.PhoneNumber;
        recaptchaSiteKey: string;
        recaptchaToken: string;
        recaptchaAction: string;
      }) {
        dispatch.setSendingCode(params.phone);
        try {
          const res = await authApi.authentications().createSmsOtpCodeRequest({
            userPhone: params.phone,
            recaptchaSiteKey: params.recaptchaSiteKey,
            recaptchaToken: params.recaptchaToken,
            recaptchaAction: params.recaptchaAction,
          });

          const otpRequestId = res.auth._id;

          dispatch.setCodeSent({ phone: params.phone, otpRequestId });
        } catch (e) {
          log.debug('Failed to send verification code', e);
          dispatch.setPhone({
            input: params.phone,
            decoded: StrictPhoneNumberParsed.decode(params.phone),
          });
          throw new ApiError(
            'Codul de verificare nu a putut fi trimis. Verifică numărul și încearcă din nou.',
            'errors',
            -1,
            undefined,
          );
        }
      },
      async confirmSmsOtp(payload: { phone: common.PhoneNumber; otpRequestId: string; code: string }) {
        dispatch.setValidatingCode(payload);
        try {
          const novaToken: string = await authApi.authentications().confirmSmsOtp({
            _id: payload.otpRequestId,
            smsOtp: payload.code,
          });

          dispatch.setSuccess({ novaToken, phone: payload.phone });
        } catch (e) {
          log.debug('Failed to validate phone with code', e);
          dispatch.setCodeSent({
            phone: payload.phone,
            otpRequestId: payload.otpRequestId,
          });
          throw new ApiError('Codul introdus e greșit sau expirat. Solicită un nou cod.', 'errors', -1, undefined);
        }
      },
      resetCodeNotReceived: (): void => {
        dispatch.mutate((state) => {
          const phone = state.type === 'New' ? state.phone.input : state.phone;

          return {
            type: 'New',
            phone: { input: phone, decoded: StrictPhoneNumberParsed.decode(phone) },
          };
        });
      },
      setPhone: dispatch.setPhone,
    };
  },
);

export const withNovaAuthContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithNovaAuthContextProvider(props: P) {
    return (
      <NovaAuthContext.Provider>
        <Content {...props} />
      </NovaAuthContext.Provider>
    );
  };
};

import { PageId, PortionPercentScaledNumber, ScaledNumber } from '@mero/api-sdk';
import { ServiceId } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import {
  Body,
  H1,
  Header,
  Icon,
  Spacer,
  colors,
  SearchTextInput,
  FormCard,
  Column,
  Checkbox,
  H3s,
  SmallBody,
  Select,
  Row,
  SafeAreaView,
  Button,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsStackParamList } from '../../../../../types';
import { getPrice } from '../../../ServicesScreen/ServicesMobileScreen';
import { formatDuration } from '../ProsDashboardScreen/AddProServicesScreen';
import { generatePercentageWithStep } from './PageOnlinePaymentsAdvancedPayment';
import { styles } from './styles';

const DEFAULT_PERCENTAGE = 5;

export type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<PageOnlinePaymentsStackParamList, 'PageOnlinePaymentsServiceSettings'>;

const PageOnlinePaymentsServiceSettings: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('onlinePayments');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const [{ selectedServices: saveSelectedServices }, { updateSelectedServices }] = OnlinePaymentsContext.useContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [query, setQuery] = React.useState<string>();
  const [services, setServices] = React.useState<GroupWithServices[]>([]);
  const [selectedServices, setSelectedServices] = React.useState<
    Record<ServiceId, { isSelected: boolean; percentage: number }>
  >({});

  const toggleServices = (servicesIds: ServiceId[], newValue?: boolean) => {
    const newSelectedServices = { ...selectedServices };
    servicesIds.forEach((serviceId) => {
      newSelectedServices[serviceId] = {
        isSelected: newValue ?? !selectedServices[serviceId]?.isSelected,
        percentage: DEFAULT_PERCENTAGE,
      };
    });
    setSelectedServices(newSelectedServices);
  };

  const changePercentage = (serviceId: ServiceId) => (value: number) => {
    setSelectedServices((prev) => ({
      ...prev,
      [serviceId]: {
        ...(prev[serviceId] ?? { isSelected: true, percentage: DEFAULT_PERCENTAGE }),
        percentage: value,
      },
    }));
  };

  const getGroupedServices = async (pageId: PageId, searchTerm?: string) => {
    setIsLoading(true);
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(pageId, searchTerm);

      setServices([
        ...groupedServices.grouped
          .map((g) => ({
            ...g,
            services: g.services.filter((s) => s.price.type !== 'hidden'),
          }))
          .filter((g) => g.services.length > 0),
        ...(groupedServices.others.length === 0
          ? []
          : [
              {
                group: { name: 'Alte servicii', _id: '1' } as ServiceGroup,
                services: groupedServices.others.filter((s) => s.price.type !== 'hidden'),
              },
            ]),
      ]);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const save = () => {
    const servicesWithPercentage = Object.entries(selectedServices)
      .filter(([_, { isSelected }]) => isSelected)
      .map(([serviceId, { percentage }]) => ({
        serviceId: serviceId as ServiceId,
        minPercent: ScaledNumber.fromNumber(percentage, 2) as PortionPercentScaledNumber,
      }));
    updateSelectedServices(servicesWithPercentage);
    goBack();
  };

  React.useEffect(() => {
    setSelectedServices(
      saveSelectedServices.reduce(
        (acc, service) => ({
          ...acc,
          [service.serviceId]: {
            isSelected: true,
            percentage: ScaledNumber.toNumber(service.minPercent),
          },
        }),
        {},
      ),
    );
  }, [saveSelectedServices]);

  React.useEffect(() => {
    const queryTimeout = window.setTimeout(() => {
      getGroupedServices(page.details._id, query);
    }, 500);

    return () => {
      window.clearTimeout(queryTimeout);
    };
  }, [query]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('advancedServices')}
      />
      <ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('advancedServices')}</H1>
        <Spacer size={8} />
        <Body style={{ paddingHorizontal: 8 }}>{t('advancedServicesDescription')}</Body>
        <Spacer size={16} />
        <SearchTextInput placeholder={t('advancedServicesSearchPlaceholder')} onChange={setQuery} />
        <Spacer size={16} />
        <FormCard paddings="none" style={{ paddingHorizontal: 16 }} rounded dropShaddow>
          {services.map((groupedService, groupIndex) => {
            const allServicesSelected = groupedService.services.every(
              (service) => selectedServices[service._id] && selectedServices[service._id].isSelected,
            );
            const servicesIds = groupedService.services.map((service) => service._id);
            return (
              <Column
                key={groupedService.group._id}
                style={{
                  paddingTop: 24,
                  paddingBottom: 16,
                  borderBottomWidth: groupIndex < services.length - 1 ? 1 : 0,
                  borderBottomColor: '#E9ECEF',
                }}
              >
                <TouchableOpacity
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                  onPress={() => toggleServices(servicesIds, !allServicesSelected)}
                >
                  <Checkbox
                    value={allServicesSelected}
                    onValueChange={() => toggleServices(servicesIds, !allServicesSelected)}
                  />
                  <H3s style={{ paddingLeft: 12 }}>
                    {groupedService.group.name} ({groupedService.services.length})
                  </H3s>
                </TouchableOpacity>
                {groupedService.services.map((service) => (
                  <Row style={{ flex: 1, paddingTop: 24 }} key={service._id}>
                    <TouchableOpacity
                      style={{ flexDirection: 'row', flex: 1 }}
                      onPress={() => toggleServices([service._id])}
                    >
                      <Checkbox
                        value={selectedServices[service._id] && selectedServices[service._id].isSelected}
                        onValueChange={() => toggleServices([service._id])}
                      />
                      <Column style={{ flex: 1 }}>
                        <Body style={{ fontSize: 17, paddingLeft: 12 }}>{service.name}</Body>
                        <SmallBody style={{ paddingLeft: 12 }}>
                          {formatDuration(service.durationInMinutes)},{' '}
                          {service.price.type === 'fixed' && service.price?.promo ? (
                            <>
                              <SmallBody>
                                {service.price.promo} lei{' '}
                                <SmallBody
                                  style={{
                                    textDecorationLine: 'line-through',
                                    textDecorationStyle: 'solid',
                                  }}
                                >
                                  ({service.price.fixed} lei)
                                </SmallBody>
                              </SmallBody>
                            </>
                          ) : (
                            <SmallBody>{getPrice(service.price)}</SmallBody>
                          )}
                        </SmallBody>
                      </Column>
                    </TouchableOpacity>
                    {selectedServices[service._id] && selectedServices[service._id].isSelected && (
                      <Column style={{ width: 90 }}>
                        <Select
                          value={selectedServices[service._id].percentage}
                          onChange={changePercentage(service._id)}
                          items={generatePercentageWithStep()}
                        />
                      </Column>
                    )}
                  </Row>
                ))}
              </Column>
            );
          })}
        </FormCard>
        <Spacer size={96} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onPress={save}
              disabled={isLoading}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageOnlinePaymentsServiceSettings, CurrentBusiness, Authorized);

import { ProductMeasure, RecurrenceEndCriteria, RecurrenceInterval, RecurrenceRule } from '@mero/api-sdk';
import { DateTime } from 'luxon';

import { scaledToString } from './scaled';

export const getMeasure = (measure: ProductMeasure.Any) => {
  if (measure.unit === 'buc') {
    return `${measure.unit}.`;
  }

  return `${scaledToString(measure.value)} ${measure.unit}`;
};

export const updateOrAdd = <K, T extends { _id: K }>(array: T[], item: T, onFound: (i: T, n: T) => T) => {
  const index = array.findIndex((i) => i._id === item._id);

  if (index === -1) {
    return [...array, item];
  }

  return array.map((i) => (i._id === item._id ? onFound(i, item) : i));
};

export const formatRecurrenceRule = (rule: RecurrenceRule.Any | undefined): string => {
  if (rule) {
    const endsOnStr = formatRecurrenceEndCriteria(rule.endsOn);
    return `Se repetă ${formatRecurrenceInterval(rule.repeatEvery)}${endsOnStr ? `, ${endsOnStr}` : ''}`;
  } else {
    return 'Nu se repetă';
  }
};

export const formatRecurrenceEndCriteria = (endsOn: RecurrenceEndCriteria.Any): string | undefined => {
  if (RecurrenceEndCriteria.Indefinite.is(endsOn)) {
    return undefined;
  } else if (RecurrenceEndCriteria.OnDate.is(endsOn)) {
    return `până la ${DateTime.fromJSDate(endsOn.date).toFormat('d LLL yyyy', { locale: 'ro' })}`;
  } else {
    return `de ${endsOn.times} ori`;
  }
};

export const formatRecurrenceInterval = (interval: RecurrenceInterval.Any): string => {
  if (interval.unit === 'day' || interval.unit === 'days') {
    return interval.value > 1 ? `la fiecare ${interval.value} zile` : 'zilnic';
  } else if (interval.unit === 'week' || interval.unit === 'weeks') {
    return interval.value > 1 ? `la fiecare ${interval.value} săptămâni` : 'săptămânal';
  } else if (interval.unit === 'month' || interval.unit === 'months') {
    return interval.value > 1 ? `la fiecare ${interval.value} luni` : 'lunar';
  } else if (interval.unit === 'year' || interval.unit === 'years') {
    return interval.value > 1 ? `la fiecare ${interval.value} ani` : 'anual';
  } else {
    return `la fiecare ${interval.value} ${interval.unit}`;
  }
};

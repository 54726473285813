import { ImageServiceRef, PageImage } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { ResponsiveImage } from '@mero/shared-sdk';
import * as React from 'react';

type SelectedImageContextState = { image: PageImage; position: number } | null;

const defaultState = (): SelectedImageContextState => null;

export const SelectedImageContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    updateImage(_state, payload: { image: ResponsiveImage; position: number }) {
      return {
        image: payload.image,
        position: payload.position,
      };
    },
    setMainImage(state) {
      if (state) {
        return {
          image: state.image,
          position: 0,
        };
      }
      return state;
    },
    updateImageServices(state, payload: { servicesRefs: ImageServiceRef[] }) {
      if (!state) {
        return state;
      }
      return {
        ...state,
        image: {
          ...state.image,
          services: payload.servicesRefs,
        },
      };
    },
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      updateImageServices: dispatch.updateImageServices,
      updateImage: dispatch.updateImage,
      setMainImage: dispatch.setMainImage,
    };
  },
);

export const withSelectedImageContext = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSelectedImageContext(props: P) {
    return (
      <SelectedImageContext.Provider>
        <Content {...props} />
      </SelectedImageContext.Provider>
    );
  };
};

import { MeroHeader, SafeAreaView } from '@mero/components';
import * as React from 'react';
import WebView from 'react-native-webview';

import { useEscPressWeb } from '../../../../../hooks/useEscPressWeb';
import useGoBack from '../../../../../hooks/useGoBack';

import { styles } from './styles';

export const HelpCenterScreenUrl = 'https://asistenta.mero.ro/ro/';

type Props = {
  // pass
};

const HelpCenterScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  return (
    <SafeAreaView style={styles.container}>
      <MeroHeader title="Centru de ajutor" canGoBack onBack={goBack} />
      <WebView
        style={styles.webView}
        source={{
          uri: HelpCenterScreenUrl,
        }}
        sharedCookiesEnabled={true}
        pullToRefreshEnabled={false}
        domStorageEnabled={true}
      />
    </SafeAreaView>
  );
};

export default HelpCenterScreen;

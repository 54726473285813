import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  hrPadding: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  list: {
    flex: 1,
  },
});

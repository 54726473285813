import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

type BookingClientSelectContextState = {
  readonly value: ClientPreview | undefined;
};

const defaultValue = (): BookingClientSelectContextState => ({ value: undefined });

/**
 * Select client for booking form
 */
export const BookingClientSelectContext = createModelContext(
  defaultValue(),
  {
    set: (_, value: ClientPreview | undefined) => ({
      value: value,
    }),
  },
  (dispatch) => ({ set: dispatch.set }),
);

export const BookingClientSelectContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <BookingClientSelectContext.Provider>{children}</BookingClientSelectContext.Provider>;
};

export const withBookingClientSelectContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithBookingClientSelectContextProvider(props: P) {
    return (
      <BookingClientSelectContextProvider>
        <Content {...props} />
      </BookingClientSelectContextProvider>
    );
  };
};

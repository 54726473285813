import { CalendarEntry } from '@mero/api-sdk/dist/calendar';
import { DateTime } from 'luxon';
import * as React from 'react';

import { NormalizedEvent } from '../../../NormalizedEvent';
import AppointmentCalendarEntryView from './AppointmentCalendarEntryView';
import BlockedTimeCalendarEntryView from './BlockedTimeCalendarEntryView';
import Tooltip from './Tooltip';

type Props = {
  readonly event: NormalizedEvent;
  readonly showTime: boolean;
  readonly now: DateTime;
};

const CalendarEventView: React.FC<Props> = ({ event, ...rest }) => {
  switch (event.type) {
    case CalendarEntry.Type.Appointment.VALUE: {
      return (
        <Tooltip event={event} now={rest.now}>
          <AppointmentCalendarEntryView event={event} {...rest} />
        </Tooltip>
      );
    }
    case CalendarEntry.Type.BlockedTime.VALUE: {
      return <BlockedTimeCalendarEntryView event={event} {...rest} />;
    }
  }
};

export default CalendarEventView;

import { PublicFeedbackDetailsWithAppointment } from '@mero/api-sdk';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import {
  colors,
  Column,
  H1,
  MeroHeader,
  Row,
  Body,
  Icon,
  H2s,
  SmallBody,
  Select,
  Spacer,
  styles,
} from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, NativeScrollEvent, Platform, ScrollView, TouchableOpacity } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext, CurrentPageInfo } from '../../../../../contexts/CurrentBusiness';
import { PageReviewsStackParamList } from '../../../../../types';
import DeleteReplyDialog from './DeleteReplyDialog';
import ReplyDialog from './ReplyDialog';
import Review, { AnonymousProfile } from './Review';
import ReviewMenu from './ReviewMenu';
import SelectStarsFilterMenu from './SelectStarsFilterMenu';

const LOAD_MORE_REVIEWS = 10;
const REVIEWS_SCORE_LIMIT = 5;

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) => {
  const paddingToBottom = 20;
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
};

export const StarIcon = () => (
  <Svg width={14.321} height={13.219}>
    <Path
      d="M14.3 5.012a.412.412 0 0 0-.334-.26l-4.447-.62L7.54.204a.455.455 0 0 0-.76 0L4.8 4.132l-4.444.62a.412.412 0 0 0-.334.26.367.367 0 0 0 .1.394l3.237 3.04-.759 4.323a.376.376 0 0 0 .172.38.447.447 0 0 0 .445.022l3.943-2.016 3.946 2.016a.448.448 0 0 0 .445-.022.376.376 0 0 0 .172-.38l-.758-4.323 3.235-3.04a.367.367 0 0 0 .1-.394Z"
      fill="#ffb900"
    />
  </Svg>
);

const StarsToScore = {
  allStars: undefined,
  oneStar: 1,
  twoStar: 2,
  threeStar: 3,
  fourStar: 4,
  fiveStar: 5,
} as const;

const STARS_FILTERS = ['allStars', 'oneStar', 'twoStar', 'threeStar', 'fourStar', 'fiveStar'] as const;
export type StarFilter = (typeof STARS_FILTERS)[number];

export type Props = StackScreenProps<PageReviewsStackParamList, 'Reviews'> & {
  page: CurrentPageInfo;
};

const PageReviewsScreen: React.FC<Props> = ({ page, navigation }) => {
  const { t } = useTranslation('reviews');

  const goBack = useGoBack();

  const [visibleReviews, setVisibleReviews] = React.useState<PublicFeedbackDetailsWithAppointment[]>([]);
  const counterReviews = React.useRef<number>(LOAD_MORE_REVIEWS);
  const filterEnabled = React.useRef<boolean>(false);
  const [isSelectClosedIos, setIsSelectClosedIos] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  const [showStarsFilter, setShowStarsFilter] = React.useState(false);

  const [selectedWorker, setSelectedWorker] = React.useState<WorkerId | undefined>(undefined);
  const [filteredReviews, setFilteredReviews] = React.useState<StarFilter>('allStars');
  const [selectedReview, setSelectedReview] = React.useState<PublicFeedbackDetailsWithAppointment | undefined>(
    undefined,
  );
  const initialLoad = React.useRef<boolean>(true);
  const [showMenu, setShowMenu] = React.useState(false);
  const [showReplyDialog, setShowReplyDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const [scoreCounts, setScoreCounts] = React.useState<Record<number, number>>({});

  const loadReviewsScoreCounts = async () => {
    try {
      const scoreCount = await meroApi.pages.getPageFeedbackScoreCounts({
        pageId: page.details._id,
        workerId: selectedWorker,
      });
      setScoreCounts(scoreCount);
    } catch {}
  };

  const loadReviews = async (initialLoad = false) => {
    if (counterReviews.current < page.details.feedback.total || initialLoad) {
      try {
        const reviewsList = await meroApi.pages.getPagePrivateFeedback({
          pageId: page.details._id,
          limit: counterReviews.current + LOAD_MORE_REVIEWS,
          score: StarsToScore[filteredReviews],
          workerId: selectedWorker,
        });
        if (reviewsList.length > visibleReviews.length) {
          counterReviews.current += LOAD_MORE_REVIEWS;
        }
        setVisibleReviews(reviewsList);
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleStarsFilter = () => {
    setShowStarsFilter((prev) => !prev);
  };

  React.useEffect(() => {
    if (filteredReviews !== 'allStars') {
      filterEnabled.current = true;
      counterReviews.current = LOAD_MORE_REVIEWS;
      loadReviewsScoreCounts();
      loadReviews(true);
    } else if (filterEnabled.current) {
      counterReviews.current = LOAD_MORE_REVIEWS;
      loadReviewsScoreCounts();
      loadReviews(true);
      filterEnabled.current = false;
    }
  }, [filteredReviews]);

  React.useEffect(() => {
    if ((Platform.OS !== 'ios' || isSelectClosedIos) && !initialLoad.current) {
      counterReviews.current = LOAD_MORE_REVIEWS;
      loadReviewsScoreCounts();
      loadReviews(true);
    }
  }, [selectedWorker, isSelectClosedIos]);

  React.useEffect(() => {
    loadReviewsScoreCounts();
    loadReviews(true);
    initialLoad.current = false;
  }, []);

  const workers = React.useMemo(
    () => [
      {
        label: t('allPros'),
        value: undefined,
      },
      ...page.workers.map((worker) => ({
        label: `${worker.user.firstname} ${worker.user.lastname}`,
        value: worker._id,
      })),
    ],
    [page.workers],
  );

  const navigateReviewSettings = React.useCallback(() => {
    navigation.navigate('Settings');
  }, [navigation]);

  const clearSelectedReview = React.useCallback(() => {
    setSelectedReview(undefined);
  }, []);

  const addReply = (review: PublicFeedbackDetailsWithAppointment) => () => {
    setSelectedReview(review);
    setShowReplyDialog(true);
  };

  const showReviewMenu = (review: PublicFeedbackDetailsWithAppointment) => () => {
    setSelectedReview(review);
    setShowMenu(true);
  };

  const hideReviewMenu = () => {
    setShowMenu(false);
  };

  const changeReply = () => {
    setShowReplyDialog(true);
  };

  const hideReplyDialog = () => {
    setShowReplyDialog(false);
  };

  const deleteReply = () => {
    setShowDeleteDialog(true);
  };
  const hideDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  // @TODO this should be moved to BE
  const calculateAverage = React.useMemo(() => {
    const total = Object.values(scoreCounts).reduce((acc, curr) => acc + curr, 0);
    const average = Object.entries(scoreCounts).reduce((acc, [score, count]) => acc + count * Number(score), 0);
    const roundedAverage = Math.round((average / total) * 100) / 100;
    return isNaN(roundedAverage) ? 0 : roundedAverage;
  }, [scoreCounts]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader
        canGoBack
        onBack={goBack}
        title={t('reviews')}
        // Uncomment when BE is released
        // RightComponent={
        //   page.permissions.reviews.canManageSettings() && (
        //     <TouchableOpacity
        //       style={{ justifyContent: 'center', height: 50, marginRight: 22 }}
        //       onPress={navigateReviewSettings}
        //     >
        //       <Body style={[styles.text.semibold, { color: colors.DARK_BLUE, fontSize: 14 }]}>{t('options')}</Body>
        //     </TouchableOpacity>
        //   )
        // }
      />
      {isLoading || visibleReviews.length > 0 || filterEnabled.current ? (
        <ScrollView
          style={{ paddingTop: 16, flex: 1, paddingBottom: 48 }}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              loadReviews();
            }
          }}
          scrollEventThrottle={400}
          contentContainerStyle={{ flex: visibleReviews.length === 0 && filterEnabled.current ? 1 : undefined }}
        >
          <H1 style={{ paddingHorizontal: 24 }}>{t('reviews')}</H1>
          <Column style={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 24 }}>
            {workers.length > 1 && (
              <>
                <Select
                  value={selectedWorker}
                  onChange={setSelectedWorker}
                  items={workers}
                  onOpen={() => setIsSelectClosedIos(false)}
                  onClose={() => setIsSelectClosedIos(true)}
                />
                <Spacer size={24} />
              </>
            )}

            <Row style={{ alignItems: 'center' }}>
              <Row style={{ alignItems: 'center', flex: 1 }}>
                {filteredReviews !== 'allStars' ? (
                  scoreCounts[StarsToScore[filteredReviews]] >= 0 ? (
                    <>
                      <StarIcon />
                      <Body style={{ fontFamily: 'open-sans-bold', paddingLeft: 8, paddingRight: 4 }}>
                        {StarsToScore[filteredReviews].toFixed(1)}
                      </Body>
                      <Body style={{ color: colors.BLACK }}>
                        {t('totalReviews', { count: scoreCounts[StarsToScore[filteredReviews]] })}
                      </Body>
                    </>
                  ) : null
                ) : (
                  <>
                    <StarIcon />
                    <Body style={{ fontFamily: 'open-sans-bold', paddingLeft: 8, paddingRight: 4 }}>
                      {selectedWorker ? calculateAverage : page.details.feedback.score.toFixed(2)}
                    </Body>
                    <Body style={{ color: colors.BLACK }}>
                      {t('totalReviews', { count: Object.values(scoreCounts).reduce((a, b) => a + b, 0) })}
                    </Body>
                  </>
                )}
              </Row>
              <TouchableOpacity onPress={toggleStarsFilter} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <SmallBody style={{ color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' }}>
                  {t(filteredReviews)}
                </SmallBody>
                <Column style={{ width: 24, height: 24, justifyContent: 'center', alignItems: 'center' }}>
                  <Icon type="dropdown" color={colors.DARK_BLUE} />
                </Column>
              </TouchableOpacity>
            </Row>
            {page.details.feedback.total < REVIEWS_SCORE_LIMIT && (
              <SmallBody style={{ color: colors.COMET, paddingTop: 8 }}>
                {t('notEnoughReviews', { value: REVIEWS_SCORE_LIMIT })}
              </SmallBody>
            )}
          </Column>
          {isLoading ? (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator />
            </Column>
          ) : visibleReviews.length === 0 && filterEnabled.current ? (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 46 }}>
              <Body style={{ textAlign: 'center' }}>{t('noReviews')}</Body>
            </Column>
          ) : (
            <Column style={{ paddingHorizontal: 24 }}>
              {visibleReviews.map((review) => (
                <Column
                  key={review._id}
                  style={{ backgroundColor: colors.WHITE, padding: 16, borderRadius: 8, marginBottom: 16 }}
                >
                  <Review
                    id={review._id}
                    firstname={!review.isAnonymous && review.user.firstname ? review.user.firstname : 'Recenzie'}
                    lastname={!review.isAnonymous && review.user.lastname ? review.user.lastname : 'Anonimizata'}
                    image={review.isAnonymous ? AnonymousProfile.medium : review.user.profilePhoto?.medium}
                    score={review.feedback.score}
                    review={review.feedback.review}
                    replies={review.replies}
                    clientAppointment={review.isAnonymous ? undefined : review.clientAppointment}
                    onSelect={showReviewMenu(review)}
                    onAddReply={addReply(review)}
                    canAddReply={page.permissions.reviews.canManageAllReviews()}
                  />
                </Column>
              ))}
            </Column>
          )}
        </ScrollView>
      ) : (
        <>
          {workers.length > 1 && (
            <>
              <Spacer size={16} />
              <H1 style={{ paddingHorizontal: 24 }}>{t('reviews')}</H1>
              <Column style={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 24 }}>
                <Select
                  value={selectedWorker}
                  onChange={setSelectedWorker}
                  items={workers}
                  onOpen={() => setIsSelectClosedIos(false)}
                  onClose={() => setIsSelectClosedIos(true)}
                />
                <Spacer size={24} />
              </Column>
            </>
          )}
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 46 }}>
            <H2s>{t('noReviewsTitle')}</H2s>
            <Body style={{ textAlign: 'center', color: colors.COMET, paddingTop: 8 }}>{t('noReviewsText')}</Body>
          </Column>
        </>
      )}

      {showStarsFilter && (
        <SelectStarsFilterMenu
          list={[...STARS_FILTERS.filter((item) => item !== 'allStars'), 'allStars']}
          onSelect={setFilteredReviews}
          onDismiss={toggleStarsFilter}
        />
      )}

      {selectedReview && showMenu && (
        <ReviewMenu onDeleteReply={deleteReply} onChangeReply={changeReply} onDismiss={hideReviewMenu} />
      )}
      {selectedReview && showReplyDialog && (
        <ReplyDialog
          onSuccess={flow(hideReplyDialog, clearSelectedReview, () => loadReviews(true))}
          onCancel={flow(hideReplyDialog, clearSelectedReview)}
          review={selectedReview}
        />
      )}
      {selectedReview && showDeleteDialog && (
        <DeleteReplyDialog
          onSuccess={flow(hideDeleteDialog, clearSelectedReview, () => loadReviews(true))}
          onCancel={flow(hideDeleteDialog, clearSelectedReview)}
          feedbackId={selectedReview._id}
        />
      )}
    </ModalScreenContainer>
  );
};

const PageReviewsContainer: React.FC<Omit<Props, 'page'>> = (props) => {
  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      reloadAsync();
    }
  }, []);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return <PageReviewsScreen {...props} page={pageState.page} />;
};
export default PageReviewsContainer;

/**
 * HoC used to wrap a component in Stripe Elements
 * if it needs to display Stripe UI.
 */
import { BillingCompanyId } from '@mero/api-sdk';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';

import config from '../../../../../config';

export function withStripeElements<P extends JSX.IntrinsicAttributes & { meroCompany: BillingCompanyId }>(
  Comp: React.FC<P>,
) {
  return function StripeWrapper(props: P) {
    // init stripe
    const stripePromise = loadStripe(config.stripe[props.meroCompany]);

    return <Elements stripe={stripePromise}>{<Comp {...props} />}</Elements>;
  };
}

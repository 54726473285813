import { ApiError, DefinedTrimedString, ProductCategory } from '@mero/api-sdk';
import {
  DismissKeyboard,
  ConfirmBox,
  H1,
  Spacer,
  InputWithLabel,
  TypeSafeTextInput,
  Body,
  useShowError,
  useToast,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { RouteProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { AuthorizedStackParamList } from '../../../types';

type Props = {
  route: RouteProp<AuthorizedStackParamList, 'ProductCategoryScreen'>;
};

const ProductCategoryScreen: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('products');
  const showError = useShowError();
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();
  const [state, { reloadCategories, update }] = ProductsContext.useContext();
  const [category, setCategory] = React.useState<ProductCategory | undefined>();

  const [showErrors, setShowErrors] = React.useState(false);

  const [name, setName] = React.useState({
    input: '',
    decoded: DefinedTrimedString.decode(''),
  });
  const isNameValid = E.isRight(name.decoded);

  React.useEffect(() => {
    if (state.type === 'Loaded') {
      const found = state.categories.find((category) => category._id === route.params.categoryId);
      if (found) {
        setCategory(found);
        setName({
          input: found.name,
          decoded: DefinedTrimedString.decode(found.name),
        });
      }
    }
  }, [state.type]);

  const createOrUpdateCategory = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    if (!E.isRight(name.decoded)) {
      return setShowErrors(true);
    }

    try {
      const newCategory = category
        ? await meroApi.pro.products.updateCategory({
            pageId: pageState.page.details._id,
            categoryId: category._id,
            name: name.decoded.right,
          })
        : await meroApi.pro.products.createCategory({
            pageId: pageState.page.details._id,
            name: name.decoded.right,
          });
      await reloadCategories(pageState.page.details._id);
      reload();
      update({
        selectedCategory: {
          type: 'custom',
          _id: newCategory._id,
          name: newCategory.name,
        },
      });
      // toast.show({
      //   type: 'success',
      //   text: !category ? t('addCategorySuccess') : t('updateCategorySuccess'),
      // });
      goBack();
    } catch (e) {
      if (e instanceof ApiError && e.code === 134) {
        toast.show({
          type: 'error',
          text: t('duplicateCategoryName'),
        });
      } else {
        showError(e);
      }
    }
  };

  const cancelLeftAction = {
    text: t('cancel'),
    onPress: goBack,
    flex: 10,
  };

  const addRightAction = {
    text: category ? t('save') : t('add'),
    onPress: createOrUpdateCategory,
    flex: 15,
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={category ? t('editCategory') : t('addCategory')}
              canClose={true}
              onClose={goBack}
              leftAction={cancelLeftAction}
              rightAction={addRightAction}
              style={{
                width: 375,
              }}
            >
              <H1>{category ? t('editCategory') : t('addCategory')}</H1>
              <Spacer size={8} />

              <Body>{t('categoryDescription')}</Body>
              <Spacer size={8} />
              <InputWithLabel label={''} isError={showErrors && !isNameValid} errorText={t('categoryNameError')}>
                <TypeSafeTextInput
                  value={name.input}
                  codec={DefinedTrimedString}
                  onChange={setName}
                  editable={true}
                  showError={showErrors}
                  placeholder={t('categoryPlaceholder')}
                />
              </InputWithLabel>
              <Spacer size={8} />
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </View>
  );
};

export default ProductCategoryScreen;

import { ClientId } from '@mero/api-sdk/dist/clients';
import { useShowError } from '@mero/components';
import * as React from 'react';

import ClientDetailsLoadingScreenView from './ClientDetailsLoadingScreenView';

export type Props = {
  readonly onBack: () => void;
  readonly clientId: ClientId;
};

const ClientDetailsNotFoundScreenView: React.FC<Props> = ({ onBack }: Props) => {
  const showError = useShowError();

  React.useEffect(() => {
    showError('Clientul nu a fost gasit');
  }, []);

  return <ClientDetailsLoadingScreenView onBack={onBack} />;
};

export default ClientDetailsNotFoundScreenView;

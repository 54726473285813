import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  editIcon: {
    position: 'absolute',
    width: 24,
    height: 24,
    backgroundColor: colors.ALABASTER,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    right: 0,
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
});

import { CustomizedNotificationTemplateId, CustomizedNotificationType } from '@mero/api-sdk';
import { Body, colors, Column, FormCard, H1, Icon, Line, MeroHeader, SmallBody, Spacer, Title } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { useShowError } from '@mero/components/lib/hooks';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { NotificationsSettingsStackParamList } from '../../../../../types';
import { LABELS } from './NotificationsEditTemplateScreen';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<NotificationsSettingsStackParamList, 'NotificationsMessagesFormat'>;

export type DefaultTemplate = {
  _id?: CustomizedNotificationTemplateId;
  template: string;
  text: string;
  type: CustomizedNotificationType;
  estimatedMessageLength: number;
};

export const MESSAGE_LENGTH = 160;

const NotificationsMessagesFormatScreen: React.FC<Props> = ({ navigation, page }) => {
  const { t } = useTranslation('notifications');

  const goBack = useGoBack();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [messages, setMessages] = React.useState<DefaultTemplate[]>([]);

  const getTemplates = async () => {
    try {
      const [templates, customTemplates] = await Promise.all([
        meroApi.notifications.getAllNotificationTemplatesForPage({ pageId: page.details._id }),
        meroApi.notifications.getPageCustomizedNotificationTemplates(page.details._id),
      ]);
      setMessages(
        templates.map((t) => {
          const saved = customTemplates.find((c) => c.type === t.type);
          if (saved) {
            return {
              ...t,
              _id: saved._id,
            };
          }

          return t;
        }),
      );
    } catch (error) {
      showError(error);
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      getTemplates();
    }
  }, [isFocused]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t('messagesFormat')} />
        <ScrollView style={{ flex: 1, paddingHorizontal: 16, paddingBottom: 96 }}>
          <Spacer size={16} />
          <H1>{t('messagesFormat')}</H1>
          <Spacer size={24} />
          <FormCard dropShaddow paddings="none" rounded>
            <Spacer size={8} />
            {messages.map((template, index) => (
              <React.Fragment key={template._id ?? index}>
                <Spacer size={16} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <Title>{t(LABELS[template.type])}</Title>
                  <Spacer size={12} />
                  <TouchableOpacity
                    style={{
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      borderTopWidth: 1,
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      flexDirection: 'row',
                      borderColor: colors.GEYSER,
                      padding: 12,
                    }}
                    onPress={() =>
                      navigation.navigate(
                        'NotificationsEditTemplate',
                        template._id ? { type: template.type, id: template._id } : { type: template.type },
                      )
                    }
                  >
                    <Body style={{ flex: 1 }}>{template.text}</Body>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </TouchableOpacity>
                  <Column
                    style={{
                      backgroundColor:
                        template.estimatedMessageLength <= MESSAGE_LENGTH ? colors.SHAMROCK : colors.YELLOW_ORANGE,
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      paddingHorizontal: 12,
                      paddingVertical: 4,
                    }}
                  >
                    <SmallBody
                      style={{
                        color: colors.WHITE,
                        fontSize: 12,
                        fontFamily: 'open-sans-semibold',
                      }}
                    >
                      <SmallBody
                        style={{
                          color: colors.WHITE,
                          fontSize: 12,
                          fontFamily: 'open-sans-semibold',
                        }}
                      >
                        {t('countCharacters', {
                          total: template.estimatedMessageLength,
                          limit: MESSAGE_LENGTH,
                          smsCount: Math.ceil(template.estimatedMessageLength / MESSAGE_LENGTH),
                        }).toLocaleUpperCase()}
                      </SmallBody>
                    </SmallBody>
                  </Column>
                  <Spacer size={16} />
                </Column>
                {index < messages.length - 1 && <Line />}
              </React.Fragment>
            ))}
            <Spacer size={8} />
          </FormCard>
        </ScrollView>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(NotificationsMessagesFormatScreen, CurrentBusiness, Authorized);

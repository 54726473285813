import { Checkbox, colors, Column, H3s, Icon, Line, Spacer } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';

import { Filters } from './MembershipsTabWeb';

const FilterIcon = (props: SvgProps) => (
  <Svg width={17.156} height={16.9} {...props}>
    <G fill="none" stroke="#202020" strokeLinecap="round" strokeWidth={1.5} transform="rotate(90 12.2 10.15)">
      <Path
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M4.8 12.627V21.6m5.916-1.434V21.6h0m0-15.656v9.832m5.484-3.533V21.6"
      />
      <Path d="M4.8 8.4a2 2 0 1 1-2 2 2 2 0 0 1 2-2Z" />
      <Circle
        cx={2}
        cy={2}
        r={2}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-name="layer1"
        transform="translate(14.2 8.037)"
      />
      <Circle
        cx={2}
        cy={2}
        r={2}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        data-name="layer1"
        transform="translate(8.5 16)"
      />
      <Path strokeLinejoin="round" strokeMiterlimit={10} d="M4.8 6v2.037M16.2 6v2.018" data-name="layer2" />
    </G>
  </Svg>
);

export type Props = {
  activeFilter?: Filters;
  onChange: (filter?: Filters) => void;
};

const MembershipSearchFilter: React.FC<Props> = ({ activeFilter, onChange }) => {
  const { t } = useTranslation('membership');

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const filtersSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: filtersSelectRef,
    isVisible: isFilterOpen,
    onClickOutside() {
      setIsFilterOpen(false);
    },
  });

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  return (
    <Column style={{ position: 'relative', zIndex: 2 }}>
      <TouchableOpacity
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: 43,
          height: 43,
          borderRadius: 21.5,
          borderColor: colors.GEYSER,
          borderWidth: 1,
        }}
        onPress={() => setIsFilterOpen(!isFilterOpen)}
      >
        <FilterIcon />
      </TouchableOpacity>
      {isFilterOpen && (
        <View
          ref={filtersSelectRef}
          style={{
            position: 'absolute',
            top: 46,
            right: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 100,
          }}
        >
          <Spacer size="16" />
          <H3s>{t('filters')}</H3s>
          <Spacer size="16" />
          <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={flow(closeFilter, () => onChange())}>
            <Checkbox
              color="blue"
              disabled={false}
              value={!activeFilter}
              onValueChange={flow(closeFilter, () => onChange())}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('allTypes')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Active'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Active'}
              onValueChange={flow(closeFilter, () => onChange('Active'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('active')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Consumed'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Consumed'}
              onValueChange={flow(closeFilter, () => onChange('Consumed'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('consumed')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Cancelled'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Cancelled'}
              onValueChange={flow(closeFilter, () => onChange('Cancelled'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('cancelled')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Expired'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Expired'}
              onValueChange={flow(closeFilter, () => onChange('Expired'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('expired')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('HasDebt'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'HasDebt'}
              onValueChange={flow(closeFilter, () => onChange('HasDebt'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('HasDebt')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
        </View>
      )}
    </Column>
  );
};

export default MembershipSearchFilter;

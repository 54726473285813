import {
  Button,
  Checkbox,
  Column,
  FormCard,
  H1,
  Header,
  HSpacer,
  Icon,
  Line,
  Row,
  Spacer,
  Title,
} from '@mero/components';
import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import * as colors from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { ShopStackParamsList } from '../../../../../types';
import PageContainer from './PageContainer';
import { ShopContext } from './shopContext';

const SORT = [
  {
    label: 'Popularitate',
    value: {
      by: 'popularity',
      direction: 'asc',
    } as const,
  },
  {
    label: 'Preț descrescător',
    value: {
      by: 'price',
      direction: 'desc',
    } as const,
  },
  {
    label: 'Preț crescător',
    value: {
      by: 'price',
      direction: 'asc',
    } as const,
  },
];

type Props = StackScreenProps<ShopStackParamsList, 'ShopSort'>;
const ShopSortScreen: React.FC<Props> = ({ navigation, route }) => {
  const { category, subCategory } = route.params;
  const [shopState, shopDispatch] = ShopContext.useContext();
  const goBack = useGoBack();

  const [localSort, setLocalSort] = React.useState(shopState.sort);

  const categoriesFilters = React.useMemo(() => {
    return subCategory
      ? { mainCategories: [category.id], subCategories: [subCategory.id] }
      : { mainCategories: [category.id] };
  }, [category.id, subCategory?.id]);

  const applySort = () => {
    shopDispatch.updateSort(localSort);
    shopDispatch.search({
      ...categoriesFilters,
      searchTerm: shopState.searchTerm,
      filters: shopState.selectedFilters,
      sort: localSort,
    });
    navigation.goBack();
  };

  return (
    <PageContainer>
      <Column
        style={{
          backgroundColor: colors.WHITE,
        }}
      >
        <SafeAreaView edges={['top']} />
        <Header
          text="Sortare"
          RightComponent={() => (
            <TouchableOpacity style={{ paddingRight: 16 }} onPress={goBack}>
              <Icon type="close" />
            </TouchableOpacity>
          )}
        />
      </Column>
      <ScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: colors.WHITE, marginBottom: 96 }}>
        <Spacer size="16" />
        <Row style={{ paddingHorizontal: 24 }}>
          <H1 style={{ flex: 1 }}>Sortează după</H1>
        </Row>
        <Column style={{ paddingHorizontal: 24 }}>
          {SORT.map((item) => (
            <TouchableOpacity key={item.label} style={{ paddingTop: 16 }} onPress={() => setLocalSort(item.value)}>
              <Row>
                <Checkbox value={item.value.by === localSort?.by && item.value.direction === localSort?.direction} />
                <HSpacer left={16} />
                <Title>{item.label}</Title>
              </Row>
              <Spacer size="16" />
              <Line />
            </TouchableOpacity>
          ))}
        </Column>
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={{ position: 'absolute', left: 0, bottom: 0, right: 0 }}>
        <SafeAreaView edges={['bottom']}>
          <Button text="Sortează" onClick={applySort} />
        </SafeAreaView>
      </FormCard>
    </PageContainer>
  );
};

export default ShopSortScreen;

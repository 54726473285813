import { useShowError } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  RootStackParamList,
} from '../../../../../types';
import ActivateOnlinePaymentsDesktop from './ActivateOnlinePaymentsDesktop';
import ActivateOnlinePaymentsMobile from './ActivateOnlinePaymentsMobile';

type Props = {
  readonly navigation: CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'ActivateOnlinePayments'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >
  >;
};
const ActivateOnlinePaymentsScreen: React.FC<Props> = ({ navigation }) => {
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const { t } = useTranslation('onlinePayments');

  const [pageState] = CurrentBusinessContext.useContext();
  const [onlinePaymentsState] = OnlinePaymentsContext.useContext();

  const onGoBack = React.useCallback(() => {
    navigation.navigate('MenuTab', {
      screen: 'MenuScreen',
    });
  }, [navigation]);

  const onActivateOnlinePayments = async () => {
    try {
      if (pageState.type !== 'Loaded') {
        return;
      }
      navigation.navigate('Menu', {
        screen: 'PageOnlinePaymentsScreen',
        params: {
          screen: 'BillingDetailsList',
        },
      });
    } catch (error) {
      showError(error, t('activateError'));
    }
  };

  React.useEffect(() => {
    if (onlinePaymentsState.type === 'Loaded') {
      if (onlinePaymentsState.status.type === 'Enabled') {
        navigation.navigate('HomeTabs', {
          screen: 'MenuTab',
          params: {
            screen: 'MenuScreen',
          },
        });
        navigation.navigate('Menu', {
          screen: 'PageOnlinePaymentsScreen',
        });
      }
    }
  }, [onlinePaymentsState.status.type]);

  if (pageState.type !== 'Loaded' || onlinePaymentsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {isPhone ? (
        <>
          <ActivateOnlinePaymentsMobile
            onGoBack={onGoBack}
            onActivateOnlinePayments={onActivateOnlinePayments}
            commissionInfo={onlinePaymentsState.status.commissionInfo}
          />
        </>
      ) : (
        <ActivateOnlinePaymentsDesktop
          onActivateOnlinePayments={onActivateOnlinePayments}
          commissionInfo={onlinePaymentsState.status.commissionInfo}
        />
      )}
    </>
  );
};

export default pipe(ActivateOnlinePaymentsScreen, withBillingDetailsContextProvider('Company'));

import * as React from 'react';

export const defaultValue = {
  step1: true,
  step2: true,
  step3: true,
  step4: true,
  step5: true,
  step6: true,
};
export const useCompletedSteps = () => {
  const [steps, setSteps] = React.useState(defaultValue);

  return [steps, setSteps] as const;
};

import { ClientJoinInvitePreview } from '@mero/api-sdk/dist/invites';
import { Avatar, Body, Button, HSpacer, SmallBody, Spacer, styles as meroStyles, colors, Line } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { styles } from './styles';

type Props = {
  readonly invite: ClientJoinInvitePreview;
  readonly onApprove: (request: ClientJoinInvitePreview) => void;
  readonly onReject: (request: ClientJoinInvitePreview) => void;
  readonly disabled?: boolean;
};

const ClientJoinInviteView: React.FC<Props> = ({ invite, onApprove, onReject, disabled }: Props) => {
  const { t } = useTranslation('notifications');

  return (
    <View style={styles.horizontalPadding}>
      <View style={[styles.verticalPadding, styles.row]}>
        <Avatar
          source={invite.fromUser.profile.photo?.small}
          firstname={invite.fromUser.profile.firstname ?? ''}
          lastname={invite.fromUser.profile.lastname ?? ''}
        />
        <View style={styles.notificationBody}>
          <Body>
            <Body style={meroStyles.text.semibold}>
              {invite.fromUser.profile.firstname} {invite.fromUser.profile.lastname}
            </Body>{' '}
            vrea să se înscrie la{' '}
            <Body style={meroStyles.text.semibold}>
              {invite.toWorker.user.firstname} {invite.toWorker.user.lastname}
            </Body>{' '}
            pe profilul său privat
          </Body>
          <SmallBody style={styles.timeText}>
            {formatTimeDiff(invite.createdAt, new Date())} · {invite.fromUser.phone}
          </SmallBody>
          <Spacer size="12" />
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Button
              size="small"
              text={t('confirm')}
              containerStyle={{ flex: 1, maxWidth: 200 }}
              onClick={() => onApprove(invite)}
              disabled={disabled}
            />
            <HSpacer left={8} />
            <Button
              size="small"
              text={t('reject')}
              containerStyle={{ flex: 1, maxWidth: 200 }}
              color={disabled ? colors.COMET : colors.DARK_BLUE}
              backgroundColor={colors.SKY_BLUE}
              disabledBackgroundColor={colors.SKY_BLUE}
              onClick={() => onReject(invite)}
              disabled={disabled}
            />
          </View>
        </View>
      </View>
      <Line />
    </View>
  );
};

export default ClientJoinInviteView;

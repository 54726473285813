import * as React from 'react';
import Svg, { Path, Circle, Rect, G } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationCalendarCancelIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#f5365c',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle id="Mask" cx="16" cy="16" r="16" fill={backgroundColor} />
    <G transform="translate(-59 8)">
      <Rect width="18" height="18" transform="translate(66 -1)" fill="none" />
      <Path
        d="M-9654.583-6843.792a3.793,3.793,0,0,1,3.79-3.792,3.793,3.793,0,0,1,3.792,3.792,3.791,3.791,0,0,1-3.792,3.792A3.791,3.791,0,0,1-9654.583-6843.792Zm1.167,0a2.629,2.629,0,0,0,2.623,2.626,2.63,2.63,0,0,0,2.626-2.626,2.629,2.629,0,0,0-2.626-2.624A2.628,2.628,0,0,0-9653.416-6843.792Zm-7.585,3.21v-12.251h1.751v.531a1.2,1.2,0,0,0,.783,1.159,1.172,1.172,0,0,0,1.549-1.105v-.584h5.252v.531a1.2,1.2,0,0,0,.783,1.159,1.173,1.173,0,0,0,1.55-1.105v-.584h1.75v5.269a5.005,5.005,0,0,0-1.166-.739v-1.028h-11.083v7.582h4.526a5.037,5.037,0,0,0,.74,1.168Zm8.416-1.969,1.231-1.246-1.248-1.237.55-.55,1.247,1.232,1.233-1.249.553.555-1.229,1.241,1.248,1.232-.555.555-1.244-1.229-1.236,1.246Zm1.5-9.7v-1.166a.584.584,0,0,1,.584-.584.582.582,0,0,1,.582.584v1.166a.58.58,0,0,1-.582.582A.583.583,0,0,1-9651.084-6852.25Zm-7.584-1.166c0-.323,1.169-.323,1.169,0v1.166a.583.583,0,0,1-.585.582C-9658.406-6851.667-9658.668-6851.927-9658.668-6853.416Z"
        transform="translate(9729 6855)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationCalendarCancelIcon);

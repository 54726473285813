import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Platform.OS === 'web' ? colors.WHITE : colors.ALABASTER,
    height: '100%',
  },
  avoidKeyboard: {
    flex: 1,
  },
  dismissKeyboard: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
});

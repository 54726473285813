import { HSpacer, SmallBody } from '@mero/components';
import * as React from 'react';
import { LayoutChangeEvent, View } from 'react-native';

import { styles } from './styles';

type Props = {
  LeftComponent?: React.ComponentType;
  CenterComponent?: React.ComponentType;
  RightComponent?: React.ComponentType;
  text?: string;
  backgroundColor?: string;
  withShadow?: boolean;
  height?: number;
};

const Header: React.FC<Props> = ({
  LeftComponent,
  CenterComponent,
  RightComponent,
  text,
  backgroundColor,
  withShadow,
  height = 50,
}: Props) => {
  const [leftComponentWidth, setLeftComponentWidth] = React.useState(0);
  const [rightComponentWidth, setRightComponentWidth] = React.useState(0);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);

  const setWidth =
    (dispatch: React.Dispatch<number>) =>
    ({ nativeEvent }: LayoutChangeEvent) => {
      const { width } = nativeEvent.layout;
      dispatch(Math.round(width));
    };

  const { left, right } = React.useMemo(() => {
    if (leftComponentWidth && rightComponentWidth) {
      return {
        left: leftComponentWidth > rightComponentWidth ? 0 : rightComponentWidth - leftComponentWidth,
        right: leftComponentWidth > rightComponentWidth ? leftComponentWidth - rightComponentWidth : 0,
      };
    } else {
      return {
        left: !leftComponentWidth ? rightComponentWidth : 0,
        right: !rightComponentWidth ? leftComponentWidth : 0,
      };
    }
  }, [leftComponentWidth, rightComponentWidth]);

  return (
    <View
      onLayout={setWidth(setWrapperWidth)}
      style={[
        styles.base,
        { backgroundColor: backgroundColor ?? 'transparent', height: height },
        withShadow ? styles.withShadow : {},
      ]}
    >
      {LeftComponent && (
        <View onLayout={setWidth(setLeftComponentWidth)}>
          <LeftComponent />
        </View>
      )}
      <HSpacer left={left} right={0} />
      {CenterComponent && !text ? (
        <View style={styles.wrapper}>
          <CenterComponent />
        </View>
      ) : (
        <SmallBody style={styles.text}>{text}</SmallBody>
      )}
      <HSpacer left={0} right={right} />
      {RightComponent && (
        <View onLayout={setWidth(setRightComponentWidth)}>
          <RightComponent />
        </View>
      )}
    </View>
  );
};

export default Header;

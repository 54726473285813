import {
  Avatar,
  FormCard,
  LogoIcon,
  Spacer,
  Text,
  useAppState,
  SafeAreaView,
  Icon,
  MeroHeader,
} from '@mero/components';
import * as React from 'react';
import { TouchableOpacity, ScrollView } from 'react-native';

import { StackScreenProps } from '@react-navigation/stack';

import { AuthContext } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../contexts/IntercomContext';
import { AuthorizedStackParamList } from '../../../types';
import { styles } from './styles';

export type Props = StackScreenProps<AuthorizedStackParamList, 'NoAccess'>;

const NoAccessScreen: React.FC<Props> = ({ navigation }: Props) => {
  const [pageState, { reload }] = CurrentBusinessContext.useContext();
  const [, { signOut }] = AuthContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();

  const page = pageState.type === 'NoAccess' ? pageState.page : null;

  const title = page?.name ?? '';
  const titleParts = title.split(/\s+/g);

  const signOutCallback = React.useCallback(() => {
    signOut();
  }, [signOut]);

  // Reload current business every time app becomes active
  const appState = useAppState();
  const [lastState, setLastState] = React.useState(appState);
  React.useEffect(() => {
    if (lastState === 'background' && appState === 'active') {
      reload();
    }
    setLastState(appState);
  }, [appState, lastState, setLastState]);

  const onPageIconPressed = React.useCallback(() => {
    navigation.navigate('SelectAccount');
  }, [navigation]);

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      //@ts-expect-error
      navigation.replace('Home');
    }
  }, [pageState.type]);

  if (!page) {
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <MeroHeader
        TitleComponent={() => (
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={onPageIconPressed}>
            <Avatar
              firstname={titleParts[0] ?? ''}
              lastname={titleParts[1] ?? ''}
              size={32}
              source={page.images[0]?.small}
            />
            <Text smallBody semibold numberOfLines={1} style={{ paddingLeft: 8, paddingRight: 12 }}>
              {page.name}
            </Text>
            <Icon type="dropdown" />
          </TouchableOpacity>
        )}
      />
      <ScrollView style={styles.body} contentContainerStyle={styles.bodyContent}>
        <Spacer size="48" />
        <LogoIcon />
        <Spacer size="32" />
        <Text h1 center>
          Nu ai access la {pageState.type === 'NoAccess' ? pageState.page.name : ''}
        </Text>
        <Spacer size="8" />
        <Text center>
          Ai întrebări sau putem să te ajutăm în{'\n'} vreun fel?{' '}
          <Text link semibold style={{ textAlignVertical: 'center' }} onPress={onContactUsPressed}>
            Contactează-ne
          </Text>
        </Text>
      </ScrollView>
    </SafeAreaView>
  );
};

export default NoAccessScreen;

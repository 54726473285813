import {
  AppliedDiscountScaledNumber,
  BillingCycle,
  isSome,
  MeroCurrency,
  MeroUnits,
  ScaledNumber,
  SubscriptionPriceOffer,
} from '@mero/api-sdk';
import {
  Body,
  Checkbox,
  Column,
  Row,
  SmallBody,
  styles as meroStyles,
  Spacer,
  FlashyLabel,
  colors,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

type Props = {
  isSelected: boolean;
  offer: SubscriptionPriceOffer<MeroUnits.Any>;
  onPress: (billingCycle: BillingCycle) => void;
};

const PageBillingCycleItem: React.FC<Props> = ({ isSelected, offer, onPress }) => {
  const { t } = useTranslation('subscription');

  const handlePress = (active: boolean) => {
    if (active) {
      onPress(offer.billingCycle);
    }
  };

  return (
    <>
      <Row style={{ flex: 1 }}>
        <TouchableOpacity style={{ flexDirection: 'row', flex: 1 }} onPress={() => handlePress(!isSelected)}>
          <Checkbox value={isSelected} onValueChange={handlePress} />
          <Column style={{ paddingLeft: 16, flex: 1 }}>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Body style={[meroStyles.text.bold]}>{offer.title}</Body>
              {isSome(offer.bonus) && (
                <FlashyLabel
                  key={offer.bonus.title}
                  type="custom"
                  color={offer.bonus.color}
                  backgroundColor={offer.bonus.backgroundColor}
                  text={offer.bonus.title}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                />
              )}
            </Row>
            <Spacer size={6} />
            <SmallBody>{offer.description}</SmallBody>
            <Spacer size={4} />
            {isSome(offer.discount) ? (
              <>
                <Body style={[meroStyles.text.semibold]}>
                  {ScaledNumber.toStr(
                    AppliedDiscountScaledNumber.applyTo(
                      offer.price,
                      offer.discount,
                      MeroCurrency[offer.price.unit].exponent,
                    ).amount,
                  )}{' '}
                  {t(offer.price.unit)} (TVA inclus) / {offer.title}
                </Body>
                <Body
                  style={[
                    meroStyles.text.strikethrough,
                    meroStyles.text.smallBody,
                    {
                      color: colors.COMET,
                    },
                  ]}
                >
                  {ScaledNumber.toStr(offer.price.amount)} {t(offer.price.unit)} (TVA inclus) / {offer.title}
                </Body>
              </>
            ) : (
              <Body style={[meroStyles.text.semibold]}>
                {ScaledNumber.toStr(offer.price.amount)} {t(offer.price.unit)} (TVA inclus) / {offer.title}
              </Body>
            )}
          </Column>
        </TouchableOpacity>
      </Row>
    </>
  );
};

export default PageBillingCycleItem;

import { colors, Icon } from '@mero/components';
import * as React from 'react';

type Props = {
  readonly isBlocked: boolean | undefined;
  readonly isWarned: boolean | undefined;
};

const ClientFlag: React.FC<Props> = ({ isBlocked = false, isWarned = false }) => {
  if (isBlocked) {
    return <Icon type="client-blocked" color={colors.BLACK} />;
  } else if (isWarned) {
    return <Icon type="client-warned" color={colors.BLACK} />;
  } else {
    return null;
  }
};

export default ClientFlag;

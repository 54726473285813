import { CalendarEntryDetails, optionull } from '@mero/api-sdk';
import { AppointmentId, CalendarEntry, CalendarId } from '@mero/api-sdk/dist/calendar';
import { Body, useShowError, useToast } from '@mero/components';
import * as Ap from 'fp-ts/lib/Apply';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/lib/Option';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateFromISOString, NumberFromString } from 'io-ts-types';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import AddBlockedTimeScreenView from '../../../components/AddBlockedTimeScreen';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import {
  BlockedTimeDeleteContext,
  withBlockedTimeDeleteContextProvider,
} from '../../../contexts/BlockedTimeDeleteContext';
import {
  BlockedTimeFormContext,
  addDefaultEventDuration,
  withBlockedTimeFormContextProvider,
  isFullDay as getIsFullDay,
} from '../../../contexts/BlockedTimeFormContext';
import {
  BlockedTimeUpdateContext,
  withBlockedTimeUpdateContextProvider,
} from '../../../contexts/BlockedTimeUpdateContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import {
  CalendarEntryContext,
  HasCalendarEntryState,
  withCalendarEntryContext,
} from '../../../contexts/CalendarEntryContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import ConfirmDeleteBlockedTimeModal from './ConfirmDeleteBlockedTimeModal';
import ConfirmDeleteRecurrentBlockedTime from './ConfirmDeleteRecurrentBlockedTime';
import ConfirmOverrideBlockedTimeModal from './ConfirmOverrideBlockedTimeModal';
import ConfirmUpdateRecurrentBlockedTime from './ConfirmUpdateRecurrentBlockedTime';

type EditoBookingScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'BlockedTimeEditScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = CurrentBusinessProps &
  AuthorizedProps & {
    navigation: EditoBookingScreenNavigationProp;
    route: RouteProp<BookingStackParamList, 'BlockedTimeEditScreen'>;
  };

type ComponentProps = Props & HasCalendarEntryState;

const EditBlockedTimeComponent = withCalendarEntryContext(
  ({ calendarEntryState: state, route, page, navigation }: ComponentProps) => {
    type Params = Partial<{
      calendarId: CalendarId;
      calendarEntryId: AppointmentId;
      start: DateTime;
    }>;

    const params: Params = React.useMemo(
      () =>
        pipe(
          Ap.sequenceS(E.Applicative)({
            calendarId: CalendarId.decode(route.params.calendarId),
            calendarEntryId: AppointmentId.decode(route.params.calendarEntryId),
            start: pipe(
              route.params.start,
              optionull(DateFromISOString).decode,
              E.map((d) => (d ? DateTime.fromJSDate(d) : d)),
            ),
          }),
          E.fold(() => ({}), identity),
        ),
      [route.params],
    );

    const showError = useShowError();
    const toast = useToast();
    const [, { pushEvent, subscribe: subscribeAppEvents }] = AppEventsContext.useContext();

    const blockedTime: CalendarEntryDetails.BlockedTime | undefined =
      state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.BlockedTime.VALUE ? state.entry : undefined;

    // FIXME: use calendar timezone
    const timezone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
    const renderTs = DateTime.now().setZone(timezone);

    const [, { reload: reloadCalendar }] = CalendarContext.useContext();
    const [, { reload: reloadCalendarEntry }] = CalendarEntryContext.useContext();

    const [formState, { reset: resetBlockedTimeForm, setStart, setEnd, setIsFullDay, setRecurrenceRule, setReason }] =
      BlockedTimeFormContext.useContext();
    const [formInitialized, setFormInitialized] = React.useState(false);

    const [updateState, { updateBlockedTime, reset: resetUpdateBlockedTime }] = BlockedTimeUpdateContext.useContext();
    const [deleteState, { deleteCalendarEntry, reset: resetDeleteCalendarEntryState }] =
      BlockedTimeDeleteContext.useContext();

    const {
      start: formStart,
      end: formEnd,
      isFullDay,
      recurrenceRule: formRecurrenceRule,
      performer: performer,
      reason: formReason,
    } = formState;

    const { startDate, endDate, recurrenceRule, reason } = formInitialized
      ? // Use form data when form is initialilzed
        {
          startDate: formStart ?? renderTs,
          endDate: formEnd ?? addDefaultEventDuration(formStart ?? renderTs),
          recurrenceRule: formRecurrenceRule,
          reason: formReason,
        }
      : // While form not initialized - use data from loaded entry or params
        {
          startDate: blockedTime?.start ? DateTime.fromJSDate(blockedTime.start) : params.start ?? renderTs,
          endDate: blockedTime?.end
            ? DateTime.fromJSDate(blockedTime.end)
            : addDefaultEventDuration(formStart ?? renderTs),
          recurrenceRule: blockedTime?.recurrent ? blockedTime?.recurrenceRule : undefined,
          reason: blockedTime?.payload.reason,
        };

    const shouldConfirmRecurrentUpdate = blockedTime && blockedTime.recurrent;
    const [showRecurrentUpdateOptions, setShowRecurrentUpdateOptions] = React.useState(false);
    const [recurrentUpdateOption, setRecurrentUpdateOption] = React.useState<'undetermined' | 'once' | 'all'>(
      'undetermined',
    );

    const [showConfirmOverride, setShowConfirmOverride] = React.useState(false);

    const [showRecurrentDeleteOptions, setShowRecurrentDeleteOptions] = React.useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    const navigateCalendar = React.useCallback(() => {
      navigation.navigate('Home', {
        screen: 'HomeTabs',
        params: { screen: 'CalendarTab', params: { screen: 'CalendarScreen' } },
      });
    }, [navigation]);

    const goBack = useGoBack(navigateCalendar);

    /**
     * Save blocked time
     */
    const saveBlockedTime = ({ override = false, onlyOnce }: { override?: boolean; onlyOnce: boolean }): void => {
      if (
        formState.type === 'Valid' &&
        (updateState.type === 'Ready' || updateState.type === 'Failed') &&
        blockedTime
      ) {
        updateBlockedTime({
          calendarId: blockedTime.calendarId,
          appointmentId: blockedTime._id,
          occurrenceIndex: blockedTime.occurrenceIndex,
          start: formState.start.toJSDate(),
          end: formState.end.toJSDate(),
          recurrenceRule: formState.recurrenceRule,
          reason: formState.reason,
          override: override,
          onlyOnce: onlyOnce,
        });
      } else {
        toast.show({
          type: 'error',
          text: 'Please fill in all the required fields',
        });
      }
    };

    type SaveBlockedTimeArgs = {
      readonly recurrentUpdateOption: 'undetermined' | 'once' | 'all';
    };

    /**
     * Trigger recurrent override or save blocked time if not recurrent
     */
    const saveBlockedTimeCallback = ({ recurrentUpdateOption }: SaveBlockedTimeArgs) => {
      if (shouldConfirmRecurrentUpdate && recurrentUpdateOption === 'undetermined') {
        setShowRecurrentUpdateOptions(true);
      } else {
        saveBlockedTime({
          onlyOnce: recurrentUpdateOption !== 'all',
        });
      }
    };

    const cancelConfirmBlockedTimeOverrideCallback = () => {
      setShowConfirmOverride(false);
      setRecurrentUpdateOption('undetermined');
    };

    /**
     * Confirm blocked time save with override
     */
    const confirmBlockedTimeOverrideCallback = React.useCallback(() => {
      setShowConfirmOverride(false);
      saveBlockedTime({ onlyOnce: recurrentUpdateOption !== 'all', override: true });
    }, [setShowConfirmOverride, saveBlockedTime]);

    /**
     * Trigger blocked time delete confirmation
     */
    const deleteBlockedTimeCallback = React.useCallback(() => {
      if (blockedTime) {
        if (blockedTime.recurrent && blockedTime.recurrenceRule !== undefined) {
          setShowRecurrentDeleteOptions(true);
        } else {
          setShowConfirmDelete(true);
        }
      }
    }, [blockedTime, setShowRecurrentDeleteOptions, setShowConfirmDelete]);

    /**
     * Delete only current blocked time slot
     */
    const confirmDeleteBlockedTimeOnce = React.useCallback(() => {
      if (blockedTime !== undefined) {
        deleteCalendarEntry({
          calendarId: blockedTime.calendarId,
          calendarEntryId: blockedTime._id,
          occurrenceIndex: blockedTime.occurrenceIndex,
          onlyOnce: true,
        });
      }
    }, [blockedTime, deleteCalendarEntry]);

    /**
     * Delete current and all upcoming blocked time slots
     */
    const confirmDeleteBlockedTimeAll = React.useCallback(() => {
      if (blockedTime !== undefined) {
        deleteCalendarEntry({
          calendarId: blockedTime.calendarId,
          calendarEntryId: blockedTime._id,
          occurrenceIndex: blockedTime.occurrenceIndex,
          onlyOnce: false,
        });
      }
    }, [blockedTime, deleteCalendarEntry]);

    // Initialize the form
    React.useEffect(() => {
      // Initialize once
      if (!formInitialized && blockedTime) {
        setFormInitialized(true);

        const start = DateTime.fromJSDate(blockedTime.start);
        const end = DateTime.fromJSDate(blockedTime.end);

        resetBlockedTimeForm({
          timezone: timezone,
          start: start,
          end: end,
          isFullDay: getIsFullDay(start, end, timezone),
          recurrenceRule: blockedTime.recurrent ? blockedTime.recurrenceRule : undefined,
          performer: pipe(
            page.workers,
            A.findFirst((w) => w.calendar._id === blockedTime.calendarId),
            O.getOrElseW(() => undefined),
          ),
          reason: blockedTime.payload.reason,
          allWorkers: page.workers,
        });
      }
    }, [formInitialized, blockedTime]);

    // Go back if not a blocked time slot
    React.useEffect(() => {
      if (state.type == 'Loaded' && state.entry.type !== CalendarEntry.Type.BlockedTime.VALUE) {
        goBack();
      }
    }, [state, goBack]);

    // Handle update action states
    React.useEffect(() => {
      if (updateState.type === 'Updated') {
        resetBlockedTimeForm({});
        resetUpdateBlockedTime();
        reloadCalendarEntry();
        reloadCalendar();
        setRecurrentUpdateOption('undetermined');
        if (blockedTime) {
          pushEvent({
            type: 'AppointmentUpdated',
            calendarId: blockedTime.calendarId,
            appointmentId: blockedTime._id,
          });
        }
        goBack();
        toast.show({
          type: 'success',
          text: 'Timpul blocat a fost modificat',
        });
      } else if (updateState.type === 'Failed') {
        resetUpdateBlockedTime();
        if (updateState.isOverride) {
          setShowConfirmOverride(true);
        } else if (updateState.error) {
          showError(updateState.error);
          setRecurrentUpdateOption('undetermined');
        } else {
          toast.show({
            type: 'error',
            text: 'Încercarea de a modifica timpul blocat a eșuat',
          });
          setRecurrentUpdateOption('undetermined');
        }
      }
    }, [updateState]);

    // Handle delete action states
    React.useEffect(() => {
      if (deleteState.type === 'Deleted') {
        resetDeleteCalendarEntryState();
        reloadCalendar();
        pushEvent({
          type: 'AppointmentDeleted',
          calendarId: deleteState.calendarId,
          appointmentId: deleteState.calendarEntryId,
        });
        toast.show({
          type: 'success',
          text: 'Timpul blocat a fost șters',
        });
        // Will dismiss by app event handler
      } else if (deleteState.type === 'Failed') {
        resetDeleteCalendarEntryState();
        if (deleteState.error) {
          showError(deleteState.error);
        } else {
          toast.show({
            type: 'error',
            text: 'Incercarea de a șterge timpul blocat a eșuat',
          });
        }
      }
    }, [deleteState, resetDeleteCalendarEntryState, goBack, toast, goBack]);

    React.useEffect(
      () =>
        subscribeAppEvents((event) => {
          switch (event.type) {
            case 'AppointmentDeleted': {
              if (
                state.type === 'Loaded' &&
                state.entry.calendarId === event.calendarId &&
                state.entry._id === event.appointmentId
              ) {
                goBack();
              }
              return;
            }
          }
        }),
      [subscribeAppEvents, state],
    );

    if (state.type !== 'Loaded' || state.entry.type === CalendarEntry.Type.BlockedTime.VALUE) {
      return (
        <>
          <AddBlockedTimeScreenView
            mode="edit"
            timezone={timezone}
            start={startDate}
            onStartChanged={setStart}
            end={endDate}
            onEndChanged={setEnd}
            isFullDay={isFullDay}
            onIsFullDayChanged={setIsFullDay}
            onClosePress={goBack}
            performer={performer}
            showPerformerSelect={performer !== undefined && page.workers.length > 1}
            recurrenceRule={recurrenceRule}
            onRecurrenceRuleChanged={setRecurrenceRule}
            reason={reason}
            onReasonChanged={setReason}
            onSaveBlockedTime={
              updateState.type === 'Updating'
                ? undefined
                : () =>
                    saveBlockedTimeCallback({
                      recurrentUpdateOption,
                    })
            }
            onRemoveBlockedTimePress={deleteBlockedTimeCallback}
          />

          {showConfirmOverride ? (
            <ConfirmOverrideBlockedTimeModal
              onDismiss={cancelConfirmBlockedTimeOverrideCallback}
              onConfirm={confirmBlockedTimeOverrideCallback}
            />
          ) : null}

          {showRecurrentUpdateOptions ? (
            <ConfirmUpdateRecurrentBlockedTime
              onUpdateAll={() => {
                setShowRecurrentUpdateOptions(false);
                // Save user choice, to avoid infinite loop with confirm override
                setRecurrentUpdateOption('all');
                saveBlockedTimeCallback({
                  recurrentUpdateOption: 'all',
                });
              }}
              onUpdateOnlyOne={() => {
                setShowRecurrentUpdateOptions(false);
                // Save user choice, to avoid infinite loop with confirm override
                setRecurrentUpdateOption('once');
                saveBlockedTimeCallback({
                  recurrentUpdateOption: 'once',
                });
              }}
              onDismiss={() => {
                setShowRecurrentUpdateOptions(false);
              }}
            />
          ) : null}

          {showConfirmDelete ? (
            <ConfirmDeleteBlockedTimeModal
              deleteInProgress={false}
              onDismiss={() => {
                setShowConfirmDelete(false);
              }}
              onConfirm={confirmDeleteBlockedTimeOnce}
            />
          ) : null}

          {showRecurrentDeleteOptions ? (
            <ConfirmDeleteRecurrentBlockedTime
              onDeleteAll={confirmDeleteBlockedTimeAll}
              onDeleteOnlyOne={confirmDeleteBlockedTimeOnce}
              onDismiss={() => {
                setShowRecurrentDeleteOptions(false);
              }}
            />
          ) : null}
        </>
      );
    } else {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Body>Not a blocked time slot</Body>
        </View>
      );
    }
  },
);

const BlockedTimeEditScreen: React.FC<Props> = ({ authorization, page, route, navigation }) => {
  return (
    <EditBlockedTimeComponent
      pageId={page.details._id}
      calendarEntryId={route.params.calendarEntryId}
      occurrenceIndex={pipe(
        route.params.occurrenceIndex,
        NumberFromString.decode,
        E.getOrElse(() => 0),
      )}
      authorization={authorization}
      page={page}
      route={route}
      navigation={navigation}
    />
  );
};

export default pipe(
  BlockedTimeEditScreen,
  withBlockedTimeFormContextProvider,
  withBlockedTimeUpdateContextProvider,
  withBlockedTimeDeleteContextProvider,
  CurrentBusiness,
  Authorized,
);

import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerWrapperWithShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.06,
    shadowRadius: 8,
    elevation: 16,
    zIndex: 202,
    backgroundColor: '#ffffff',
    maxHeight: 91,
  },
  webAddProsActions: {
    position: 'absolute',
    right: 0,
    top: 12,
    minWidth: 300,
    backgroundColor: colors.WHITE,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'rgb(233, 236, 239)',
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
    paddingTop: sizes[16],
    paddingBottom: sizes[16],
  },
});

import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  phoneModalCard: {
    height: '100%',
  },
  desktopModalCard: {
    width: 500,
    height: '100%',
    paddingVertical: 48,
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
  },
  desktopModalCardLarge: {
    height: Dimensions.get('window').height,
    backgroundColor: 'transparent',
  },
  desktopModalCardScreenLarge: {
    // width: Dimensions.get('window').width,
    maxWidth: 940,
    alignSelf: 'flex-end',
    height: Dimensions.get('window').height,
    backgroundColor: 'transparent',
  },
  desktopModalCardPaddingLess: {
    width: 500,
    height: '100%',
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
  },
  desktopModalCheckout: {
    width: '100%',
    height: '100%',
    marginHorizontal: 'auto',
  },
  desktopModalCardCheckout: {
    height: '100%',
    width: '100%',
    alignItems: 'flex-end',
  },
  tabletModalCard: {
    width: 500,
    paddingVertical: 48,
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  tabletModalCardPaddingLess: {
    width: 500,
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  desktopSignInModalCard: {
    width: 400,
    height: '100%',
    paddingVertical: 48,
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
  },

  tabletSignInModalCard: {
    width: 400,
    paddingVertical: 48,
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
});

import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import { AppContext } from '../../../../../contexts/AppContext';
import { SubscriptionStackParamList } from '../../../../../types';
import SplashScreen from '../../../../SplashScreen';
import StripeCardPaymentScreen from './StripeCardPaymentScreen';

export type Props = StackScreenProps<SubscriptionStackParamList, 'CardPayment'>;

const CardPaymentScreen: React.FC<Props> = (props) => {
  const [appState] = AppContext.useContext();

  const [cardPaymentMethod, setCardPaymentMethod] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (appState.type === 'Loaded') {
      setCardPaymentMethod(
        appState.featureFlags.selectivePaymentMethod?.enabled
          ? appState.featureFlags.selectivePaymentMethod?.value
          : 'stripe',
      );
    }
  }, [appState]);

  switch (cardPaymentMethod) {
    case 'stripe': {
      return <StripeCardPaymentScreen {...props} meroCompany={props.route.params.meroCompany} />;
    }
    default: {
      return <SplashScreen />;
    }
  }
};

export default pipe(CardPaymentScreen);

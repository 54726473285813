import { AvoidKeyboard, MeroHeader } from '@mero/components';
import * as React from 'react';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../components/ModalScreenContainer';

type Props = {
  readonly isEmbed?: boolean;
  readonly onClose?: () => void;
};

const PrivacyPolicyScreenView: React.FC<Props> = ({ isEmbed = false, onClose }) => {
  return (
    <ModalScreenContainer>
      {!isEmbed && <MeroHeader canClose onClose={onClose} />}
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ paddingHorizontal: 24, paddingBottom: 24 }}>
          <div style={{ fontFamily: 'open-sans', fontSize: 16, color: '#525f7f', lineHeight: 1.5 }}>
            <h1>Politica de confidențialitate</h1>

            <div>
              <p>Stimați Vizitatori,</p>
              <p>
                Vă mulțumim că folosiți MERO. Vă rugăm să citiți cu atenție Termenii și condițiile aplicabile tipului de
                cont pentru care ați optat și această Politică de prelucrarea a datelor cu caracter personal și
                confidențialitate, pentru a fi pe deplin informați cu privire la condițiile în care prelucrăm și
                asigurăm siguranța datelor personale pe care ni le furnizați.
              </p>
              <p>
                MERO.ro este o platformă care are următoarele funcționalități: agendă pentru programări, sistem de
                păstrare a datelor despre client (nume, prenume și număr de telefon) și alte caracteristici
                suplimentare.
              </p>
              <p>
                Vă informăm că, pentru a asigura prestarea serviciilor MERO, datele dumneavoastră cu caracter personal
                vor fi prelucrate de către noi, MERO PROGRAMARI S.R.L., cu sediul social în București, Str. Vasile
                Lascăr, Nr. 202, ap. C.0.2, înregistrată la Oficiul Național al Registrului Comerțului Cluj cu nr.
                J40/13322/2022, Cod Unic de Înregistrare RO37670174, și societățile aferente, care deține siteul{' '}
                <a href="www.mero.ro">www.mero.ro</a> și aplicația mobilă MERO, în conformitate cu dispozițiile
                <i>
                  Regulamentului privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
                  caracter personal și privind libera circulație a acestor date nr. 679/2016 ( în continuare RGPD)
                </i>
                , și a legislației naționale (Legea nr. 190/2018 și reglementările aplicabile în materia protecției
                datelor) și europene aplicabile în domeniul protecției datelor.
              </p>
              <p>
                <b>I. Definiții:</b>
              </p>
              <ul>
                <li>
                  <i>
                    <b>Site</b>
                  </i>{' '}
                  înseamnă <b>www.mero.ro</b>, proprietatea operatorului de date personale MERO PROGRAMARI S.R.L, cu
                  sediul social în România, București, Str. Vasile Lascăr, Nr. 202, ap. C.0.2, și societățile aferente;
                </li>
                <li>
                  <i>
                    <b>Aplicație</b>
                  </i>{' '}
                  înseamnă aplicația noastră mobilă,
                  <i>
                    <b>MERO</b>
                  </i>
                  , disponibilă în Appstore;
                </li>
                <li>
                  <i>
                    <b>Vizitator</b>
                  </i>{' '}
                  înseamnă persoana fizică care accesează Siteul, vizitează spațiile MERO PROGRAMARI S.R.L sau este
                  interesat sau solicită informații referitoare la produsele Operatorului;
                </li>
                <li>
                  <i>
                    <b>Utilizator Client</b>
                  </i>{' '}
                  înseamnă persoana fizică căreia i se prestează servicii de către Operator, prin intermediul siteului
                  sau prin alte modalități,
                </li>
                <li>
                  <i>
                    <b>Utilizator Profesionist</b>
                  </i>{' '}
                  înseamnă persoana juridică care se expune pe platforma Operatorului pentru a fi contactată de
                  Utilizatorii clienți;
                </li>
                <li>
                  <i>
                    <b>Profil public MERO</b>
                  </i>{' '}
                  înseamnă pagina asociată unui Utilizator Profesionist și, după caz, a altor profesioniști afiliați,
                  unde Utilizatorii Clienți pot efectua programări în cadrul platformei MERO ori pot contacta
                  Utilizatorul Profesionist.
                </li>
                <li>
                  <i>
                    <b>Date cu caracter personal</b>
                  </i>{' '}
                  înseamnă orice informații privind o persoană fizică identificată sau identificabilă; o persoană fizică
                  identificabilă este o persoană care poate fi identificată direct sau indirect, prin corelarea unor
                  elemente de identificare, cum ar fi nume, prenume, fotografie, vârstă, sex, naționalitate, etnie,
                  adresă de domiciliu/reședință, adresă de e-mail, adresă IP, ocupație, cont bancar, orientare politică,
                  religie, semnătură etc;
                </li>
                <li>
                  <i>
                    <b>Categorii speciale de date personale</b>
                  </i>{' '}
                  înseamnă datele cu caracter personal care dezvăluie originea rasială sau etnică, opiniile politice,
                  confesiunea religioasă sau convingerile filozofice sau apartenența la sindicate, prelucrarea de date
                  genetice, de date biometrice pentru identificarea unică a unei persoane fizice, de date privind
                  sănătatea sau de date privind viața sexuală sau orientarea sexuală ale unei persoane fizice;
                </li>
                <li>
                  <i>
                    <b>Date personale referitoare la condamnări penale și infracțiuni</b>
                  </i>
                  înseamnă o categorie specială de date personale care privesc condamnările penale aplicate persoanei
                  vizate, respectiv infracțiunile săvârșite de persoana vizată sau măsurile de securitate conexe;
                </li>
                <li>
                  <i>
                    <b>Prelucrare</b>
                  </i>{' '}
                  înseamnă orice operaţiune sau set de operaţiuni efectuate asupra datelor cu caracter personal sau
                  asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar
                  fi colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea,
                  extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la
                  dispoziţie în orice alt mod, alinierea sau combinarea, restricţionarea, ştergerea sau distrugerea;
                </li>
                <li>
                  <i>
                    <b>Operator</b>
                  </i>{' '}
                  înseamnă persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau
                  împreună cu altele stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal; în
                  acest caz, MERO PROGRAMARI S.R.L, inclusiv prin intermediul siteului www.mero.ro are calitatea de
                  operator;
                </li>
                <li>
                  <i>
                    <b>Persoană vizată</b>
                  </i>{' '}
                  înseamnă persoana fizică ale cărei date cu caracter personal sunt prelucrate;
                </li>
                <li>
                  <i>
                    <b>Împuternicit</b>
                  </i>{' '}
                  înseamnă persoană fizică sau juridică, autoritatea publică, agenția sau alt organism care prelucrează
                  datele cu caracter personal în numele operatorului;
                </li>
                <li>
                  <i>
                    <b>RGPD/GDPR</b>
                  </i>{' '}
                  înseamnă Regulamentul UE 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea
                  datelor cu caracter personal și privind libera circulație a acestor date;
                </li>
                <li>
                  <i>
                    <b>Autoritatea de supraveghere</b>
                  </i>{' '}
                  înseamnă o autoritate independentă instituită într-un stat membru al Uniunii Europene, responsabilă cu
                  monitorizarea aplicării RGPD;
                </li>
                <li>
                  <i>
                    <b>SEE</b>
                  </i>{' '}
                  înseamnă Spațiului Economic European (incluzând statele membre ale Uniunii Europene, Islanda, Norvegia
                  și Liechtenstein).
                </li>
              </ul>
              <p>
                <b>
                  II. Drepturile dumneavoastră și obligațiile noastre în ceea ce privește prelucrarea datelor personale:
                </b>
              </p>
              <ol>
                <li>
                  Aveți dreptul de a solicita Operatorului (MERO PROGRAMARI S.R.L) în ceea ce privește datele
                  dumneavoastră cu caracter personal,{' '}
                  <u>
                    <b>accesul</b>
                  </u>{' '}
                  la aceste date, ceea ce înseamnă că aveți dreptul de a obține o confirmare că se prelucrează date cu
                  privire la persoana dumneavoastră, respectiv informații despre cum sunt prelucrate datele (scopurile
                  prelucrării, categoriile de date vizate, destinatarii sau categoriile de destinatari cărora datele
                  le-au fost sau urmează să le fie divulgate, perioada pentru care datele vor fi stocate, sau criteriile
                  utilizate pentru a stabili perioada etc).
                </li>
                <li>
                  Aveți dreptul de a solicita Operatorului
                  <u>
                    <b>rectificarea</b>
                  </u>{' '}
                  fără întârziere a datelor cu caracter personal care vă privesc, respectiv completarea datelor cu
                  caracter personal care au fost incomplete, inclusiv prin furnizarea unei declarații suplimentare.
                </li>
                <li>
                  Aveți dreptul de a solicita Operatorului
                  <u>
                    <b>ștergerea datelor</b>
                  </u>{' '}
                  cu caracter personal care vă privesc, în cazul în care se aplică unul din următoarele motive:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopului pentru care au fost
                      colectate sau prelucrate
                    </li>
                    <li>
                      V-ați retras consimțământul, atunci când acesta a fost temeiul legal al prelucrării și nu există
                      niciun alt temei juridic pentru prelucrare
                    </li>
                    <li>
                      Vă opuneți prelucrării în temeiul art. 21, alin. 1 din regulamentul 679/2016 și nu există motive
                      legitime care să prevaleze în ceea ce privește prelucrarea sau opoziția la prelucrare se
                      realizează în temeiul art. 21, alin. 2 din regulamentul UE 679/2016.
                    </li>
                    <li>Datele cu caracter personal au fost prelucrate ilegal.</li>
                    <li>
                      Datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care ne revine
                      în temeiul dreptului Uniunii sau al dreptului intern sub incidența căruia se află Operatorul.
                    </li>
                    <li>
                      Datele cu caracter personal au fost colectate în vederea oferirii de servicii ale societății
                      informaționale menționate la art. 8, alin. 1 din RGPD.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>
                    <b>Dreptul la restricționarea prelucrării</b>
                  </u>{' '}
                  poate fi exercitat de către dumneavoastră dacă se contestă exactitatea datelor pe o anumită perioadă,
                  suficientă pentru verificarea datelor, dacă prelucrarea este ilegală dar nu se dorește ștergerea
                  datelor doar restricționarea, în cazul în care Operatorul nu mai are nevoie de datele personale în
                  vederea prelucrării însă dumneavoastră le solicitați pentru apărarea unui drept în instanță, dacă
                  v-ați opus prelucrării pentru intervalul de timp cât se verifică dacă interesul nostru legitim
                  prevalează asupra drepturilor dumneavoastră.
                </li>
                <li>
                  Aveți{' '}
                  <u>
                    <b>dreptul la opoziție</b>
                  </u>
                  , ceea ce înseamnă că aveți dreptul de a vă opune prelucrării atunci când aceasta deservește un
                  interes public sau un interes legitim al nostru sau când datele dumneavoastră sunt prelucrate în scop
                  de marketing direct.
                </li>
                <li>
                  <u>
                    <b>Dreptul la portabilitatea datelor</b>
                  </u>{' '}
                  se referă la dreptul dumneavoastră de a primi datele personale într-un format structurat, care poate
                  fi citit automat, respectiv la dreptul ca acestea să fie trimise direct unui alt Operator, la
                  solicitarea dumneavoastră. Dreptul la portabilitatea datelor se aplică atunci când prelucrarea s-a
                  bazat pe consimțământul dumneavoastră sau pe temei contractual, iar prelucrarea s-a efectuat prin
                  mijloace automate.
                </li>
                <li>
                  Aveți
                  <u>
                    <b>
                      dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrare automatizată, inclusiv
                      profilarea
                    </b>
                  </u>
                  , care produce efecte juridice asupra dumneavoastră sau vă afectează în mod similar, cu excepția
                  cazului în care o astfel de prelucrare este necesară pentru executarea contractului dintre noi, este
                  permisă de lege sau are la bază consimțământul explicit al dumneavoastră.
                </li>
                <li>
                  În măsura în care datele personale vor fi prelucrate pe durata derulării contractului sau în baza
                  consimțământului dumneavoastră, aveți
                  <u>
                    <b>dreptul de a vă retrage acest consimțământ</b>
                  </u>
                  oricând, fără ca aceasta să conducă la vreo consecință negativă în sarcina dumneavoastră.
                </li>
                <li>
                  Aveți
                  <u>
                    <b>
                      dreptul de a depune o plângere la Autoritatea Națională de Supraveghere a prelucrării datelor cu
                      Caracter Personal sau la instanțele de judecată
                    </b>
                  </u>
                  , în cazul în care considerați că v-am încălcat vreun drept cu privire la datele dumneavoastră
                  personale.
                </li>
              </ol>
              <p>
                <b>
                  Toate aceste drepturi, le puteți exercita printr-o cerere scrisă adresată Operatorului MERO PROGRAMARI
                  S.R.L., datată și semnată, comunicată:
                </b>
              </p>
              <ul>
                <li>Prin email la adresa: contact@mero.ro;</li>
              </ul>
              <p>
                <b>Sau</b>
              </p>
              <ul>
                <li>Prin email la adresa: contact@mero.ro;</li>
              </ul>
              <p>
                Vă vom răspunde într-un termen de 1 lună de la primirea cererii, cu mențiunea că acesta poate fi
                prelungit cu încă 2 luni, caz în care veți fi notificat cu privire la respectiva prelungire în termenul
                de o lună de la înregistrarea cererii.
              </p>
              <p>
                De asemenea, ne asumăm obligația de a lua toate măsurile necesare, organizatorice și tehnice pentru
                securizarea datelor dumneavoastră personale pe care le gestionăm și de a vă informa de îndată în caz de
                distrugere, pierdere, modificare, divulgare sau accesare neautorizată a datelor cu caracter personal.
              </p>
              <p>
                <b>III. Datele personale prelucrate de Operator:</b>
              </p>
              <p>
                <b>1. Vizitatorii siteului:</b>
              </p>
              <p>
                Datele personale ale Vizitatorilor siteului www.mero.ro sunt procesate prin simpla accesare a Site-ului,
                situație în care se colectează, conform politicii de cookies practicată de mero.ro prin intermediul
                serviciilor Google Analytics, Amplitude, Facebook Pixel următoarele date: IP, locatie, preferințe si
                interacțiunea cu paginile vizitate. În această situație, datele sunt prelucrate pentru a vă asigura o
                cât mai bună experiență online, dar și în scop de marketing. Temeiul legal al procesării este
                reprezentat atât de consimțământului Vizitatorului, conform politicii de cookies agreată de
                dumneavoastră pe site-ul mero.ro, consimțământ care poate fi retras oricând fără vreo consecință
                negativă pentru Vizitator, inclusiv prin setarea corespunzătoare a browserului de internet, cât și de
                interesul nostru legitim de a asigura buna funcționare a siteului.
              </p>
              <p>
                <b>2. Utilizatorii clienți:</b>
              </p>
              <p>
                <u>2.1. Crearea și configurarea contului de utilizator client</u>
              </p>
              <p>
                În situația în care un vizitator dorește să realizeze o rezervare online prin intermediul siteului
                acesta trebuie să își creeze un cont de utilizator client în cadrul site-ului sau aplicației.
              </p>
              <p>
                În acest caz, în vederea creării contului și autentificării în cadrul acestuia, utilizatorul va
                introduce datele sale de contact constând în nume, prenume și număr de telefon sau coordonatele contului
                său Facebook, Google sau Apple, permițând astfel accesul la datele sale de contact și de identificare
                (nume, prenume, locație, imagini) utilizate în legătură cu conturile mai sus menționate. De asemenea, în
                ambele cazuri, odată cu crearea contului vor fi prelucrate și datele personale constând în parola de
                acces aleasă de către utilizator pentru contul MERO.
              </p>
              <p>
                Datele personale sunt în acest caz prelucrate în scopul asigurării serviciilor noastre, în temeiul legal
                constând în încheierea și executarea convenției dintre noi, părțile privind prestarea serviciilor
                conform Termenilor și condițiilor aplicabile, dublat de temeiul consimțământului persoanei vizate
                utilizator pentru prelucrarea datelor sale personale.
              </p>
              <p>
                <u>2.2. Programările online</u>
              </p>
              <p>
                Dacă un utilizator client dorește să realizeze o programare online, acesta trebuie să se autentifice în
                contul său, conform pct. 2.1. și să selecteze serviciul, data și ora pentru care se dorește efectuarea
                programării. În acest caz, vor fi prelucrate datele sale personale constând în datele aferente
                profilului său, după caz, numărul de telefon, numele și prenumele, precum și date privind preferințele
                sale de servicii și programării efectuate.
              </p>
              <p>
                Prin crearea contului și efectuarea programării pentru serviciile unui anumit utilizator profesionist,
                utilizatorul client își exprimă acordul implicit pentru transmiterea coordonatelor sale de contact- nume
                prenume și număr de telefon sau coordonate ale profiului Facebook, Google, Apple, utilizatorului
                profesionist selectat de acesta, acesta din urmă fiind responsabil privind prelucrarea datelor cu
                caracter personal ale utilizatorului client în conformitatea cu dispozițiile legale în vigoare.
              </p>
              <p>
                Temeiul legal principal al prelucrării datelor în cadrul acestui proces este unul de natură
                contractuală, constând în executarea convenției prin care vă oferim serviciile noastre și intenția
                dumneavoastră de a intra într-un potențial contract cu unul dintre utilizatorii profesioniști, dublat de
                consimțământul dumneavoastră, exprimat implicit prin autentificarea pe platforma noastră și efectuarea
                programării.
              </p>
              <p>
                Consimțământul poate fi retras în acest caz în orice moment, fără vreo consecință negativă pentru
                dumneavoastră, printr-o simplă cererea adresată nouă la coordonatele de contact disponibile pe site, cu
                mențiunea că nefurnizarea datelor personale ante-menționate poate conduce la imposibilitatea de a vă
                furniza serviciile de rezervare online convenite.
              </p>
              <p>
                Scopul prelucrării datelor îl reprezintă asigurarea serviciilor de rezervare online în cele mai bune
                condiții, respectiv oferirea unei experiențe cât mai plăcute pe platforma noastră.
              </p>
              <p>
                Menționăm că putem solicita acordul pentru trimiterea utilizatorilor clienți de notificări push privind
                contul sau aplicația, si serviciile oferite, respectiv programările efectuate și statusul acestora. Dacă
                vizitatorii doresc să renunțe la primirea acestor tipuri de comunicații, le pot dezactiva în setările
                dispozitivului.
              </p>
              <p>
                <u>2.3. Recenzii și testimoniale</u>
              </p>
              <p>
                Clienții au posibilitatea de a scrie și publica recenzii (păreri) referitoare la activitatea
                profesioniștilor din cadrul platformei și experiența acestora în legătură cu serviciile oferite de
                aceștia. În acest caz, prin publicarea unei recenzii/păreri clienții își exprimă implicit consimțământul
                privind prelucrarea datelor personale constând în nume, prenume și date referitoare la preferințele
                acestora sau orice alte date personale rezultând din cuprinsul recenziei/opiniei publicate, respective
                pentru publicarea acestora în cadrul site-ului/a platformei și aplicației.
              </p>
              <p>
                <u>2.4. Plata online</u>
              </p>
              <p>
                În cazul în care clientul dorește să plătească online servicii contractate prin intermediul platformei
                noastre, vom prelucra următoarele date: numele și prenumele/denumirea clientului, adresa, adresa de
                e-mail, numărul de telefon, I.P., banca emitentă a cardului cu care se face plata, numărul cardului
                anonimizat partial, tipul cardului.
              </p>
              <p>
                Datele personale vor fi prelucrate în acest caz în temeiul consimțământului dumneavoastră, exprimat prin
                optarea achitării sumelor prin plată online, dublat de executarea convenției de prestare a serviciilor
                de către noi și de temeiul interesului nostru legitim constând în verificarea și păstrarea unei evidențe
                a plăților și după caz a îndeplinirii obligațiilor de plată, coroborat cu motivul bunei funcționări a
                platformei MERO și a serviciilor oferite, scopul prelucrării fiind prestarea în cât mai bune condiții a
                serviciilor oferite prin intermediul MERO, respectiv scop organizatoric.
              </p>
              <p>
                <b>3. Utilizatorii profesioniști:</b>
              </p>
              <p>
                <u>3.1. Crearea și configurarea contului de profesionist</u>
              </p>
              <p>
                În situația în care un profesionist (salon, cabinet sau profesionist independent) dorește să folosească
                platforma MERO pentru gestionarea programărilor și a salonului și să aibă un Profil public MERO, pentru
                a-și expune și promova serviciile pe platforma noastră și a acorda posibilitatea potențialilor clienți
                pentru efectuarea de programări online, acesta se va putea autentifica fie cu numărul de telefon, fie
                folosind contul de pe platforma Facebook, Google, Apple, situație în care, dacă datele de contact
                menționate aparțin în concret sau sunt asociate unei persoane fizice, vom prelucra datele acesteia cu
                caracter personal mai sus menționate.
              </p>
              <p>
                Astfel, în cazul în care profesionistul dorește să devină clientul nostru și se autentifică cu numărul
                de telefon, vom procesa datele personale constând în numele și prenumele său și numărul de telefon, iar
                în cazul în care persoana juridică dorește să se autentifice folosind contul de pe platforma Facebook,
                Google, Apple, vom prelucra următoarele date: adresă de e-mail/număr de telefon pentru autentificare,
                nume și prenume, imagine de profil. Totodată, prin publicarea profilului MERO, implicit locației de
                desfășurare a serviciilor în cadrul platformei cu ocazia configurării contului, profesionistul persoană
                fizică (sau după caz reprezentantul acestuia ) acceptă prelucrarea datelor sale personale privind adresa
                sa de domiciliu, în măsura în care serviciile se prestează de la domiciliul său, iar în cazul în care
                facturarea și plata serviciilor prestate de către noi se realizează din contul său personal, vor fi
                prelucrate și datele personale referitoare la contul bancar.
              </p>
              <p>
                În acest sens, datele personale ale profesioniștilor vor fi prelucrate în baza temeiului legal constând
                în încheierea si executarea unui contract, respectiv a convenției de prestare a serviciilor oferite prin
                intermediul platformei, dublat de temeiul consimțământului și a interesului legitim al MERO de a
                asigurarea bunei funcționări a platformei și a desfășurării serviciilor pe care aceasta le oferă în cele
                mai bune condiții.
              </p>
              <p>
                Datele personale menționate mai sus vor fi prelucrate în scopul prestării în bune condiții a serviciilor
                contractate de utilizatori - atât profesioniști cât și clienți, inclusiv în scopul încheierii
                contractului dintre părți, al facturării serviciilor, și al gestionării serviciilor asigurate de noi,
                MERO, precum și în scopul îndeplinirii obligațiilor noastre legale de raportare către autoritățile
                publice.
              </p>
              <p>
                <u>3.2. Profesioniștii afiliați unui utilizator profesionist</u>
              </p>
              <p>
                În cadrul contului dedicat utilizatorilor profesioniști,
                <u>
                  aveți posibilitatea de a introduce datele mai multor specialiști care activează în cadrul
                  salonului/cabinetului dumneavoastră
                </u>
                , pentru a acorda posibilitatea clienților de a se programa direct la un anumit profesionist. În acest
                caz, vor fi prelucrate datele cu caracter personal ale acestor profesionisti, constând, în funcție de
                opțiunea acestora, în nume, prenume, număr de telefon, imaginea acestor persoane (poza) și în unele
                situații informațiile aferente profilului de Facebook.
              </p>
              <p>
                Menționăm și că
                <u>datele personale ale specialiștilor persoane fizice</u> vor putea fi afișate în cadrul MERO, pentru a
                facilita contactarea lor în mod direct- inclusiv în afara platformei.
              </p>
              <p>
                În toate situațiile de mai sus, datele personale ale profesioniști care sunt afiliați unui anumit
                salon/cabinet vor fi prelucrate în baza consimțământului specialiștilor, la a cărui obținere în
                condițiile prevăzute de GDPR se obligă în mod expres utilizatorii profesioniști, care garantează expres
                obținerea acestuia și care vor furniza la cererea MERO dovada obținerii consimțământului, precum și în
                temeiul legal constând în realizarea de demersuri în vederea încheierii unui contract între utilizatorii
                profesioniști sau, după caz, profesioniștii afiliați, și utilizatorii clienți, respectiv în temeiul
                încheierii și executării convenției dintre MERO și utilizatorii profesioniști și a interesul legitim al
                MERO constând în asigurarea bunei funcționări a platformei și a desfășurării serviciilor pe care aceasta
                le oferă în cele mai bune condiții.
              </p>
              <p>
                Toate datele personale menționate mai sus vor fi prelucrate în scopul prestării în bune condiții a
                serviciilor contractate de utilizatori - atât profesioniști cât și clienți, respectiv al gestionării
                serviciilor asigurate de noi, MERO, precum și în scopul îndeplinirii obligațiilor noastre legale de
                raportare către autoritățile publice.
              </p>
              <p>
                Consimțământul exprimat privind prelucrarea datelor personale poate fi retras în orice moment, printr-o
                simplă cerere adresată nouă la coordonatele de contact menționate în cuprinsul prezentei politici, cu
                mențiunea că în cazul retragerii consimțământului pentru prelucrarea anumitor date personale absolut
                necesare pentru prestarea serviciior poate conduce la imposibilitatea prestării acestora, în tot sau în
                parte.
              </p>
              <p>
                <u>3.3. Gestionarea programărilor și evidența clienților</u>
              </p>
              <p>
                MERO oferă profesioniștilor care aleg să se înregistreze în cadrul platformei și să utilizeze serviciile
                oferite prin intermediul acesteia posibilitatea de a-și organiza și gestiona programările, prin
                intermediul unei agende virtuale.
              </p>
              <p>
                În acest caz însă, răspunderea pentru prelucrarea datelor personale aparținând altor persoane fizice
                decât cele ale utilizatorilor înregistrați ca și clienți în cadrul platformei MERO aparține exclusiv
                profesioniștilor, aceștia fiind obligați să informeze persoanele vizate cu privire la introducerea
                datelor acestora în cadrul platformei MERO și condițiile de prelucrare a acestor date.
              </p>
              <p>
                <b>4. Asistență și chat:</b>
              </p>
              <p>
                MERO oferă utilizatorilor clienți și profesioniști suport în legătură cu utilizarea platformei și a
                celorlalte servicii oferite ori a unor funcționalități conexe acestora, prin accesarea coordonatelor de
                contact afișate pe site sau prin intermediul secțiunii de chat.
              </p>
              <p>
                Prin accesarea secțiunii de chat sau contactarea reprezentanților noștri pentru asistență, persoanele
                vizate își exprimă în mod expres consimțământul pentru prelucrarea datelor cu caracter personal constând
                în coordonatele de contact, IP-ul ( în cazul aplicației chat) și alte date personale furnizate în mod
                voluntar de către persoana vizată.
              </p>
              <p>
                De asemenea, este posibil ca în vederea acordării serviciilor de suport solicitate prin oricare dintre
                mijloacele de mai sus, MERO să dobândească acces la date cu caracter personal furnizate în mod voluntar,
                spontan de către utilizatori, date care vor fi în acest caz prelucrate exclusiv în temeiul
                consimțământului implicit al utilizatorului și în scopul facilitării suportului solicitat, fără ca ele
                să fie stocate în vreun mod de MERO.
              </p>
              <p>
                <b>IV. Transmiterea datelor cu caracter personal prelucrate de MERO</b>
              </p>
              <p>
                Datele cu caracter personal prelucrate de noi, MERO, în condițiile prevăzute de prezenta politică, pot
                fi transmise către terțe entități, după cum urmează:
              </p>
              <ul>
                <li>
                  Datele utilizatorilor clienți pot fi transmise utilizatorilor profesioniști, în vederea validării
                  programării, prestării altor servicii oferite de MERO și asigurarea contactului direct dintre clienți
                  și profesioniști;
                </li>
                <li>
                  Datele utilizatorilor clienți și ale utilizatorilor profesioniști pot fi transmsie colaboratorilor
                  noștri, inclusiv prestatorilor de servicii de asistență (inclusiv servicii asisnteță de tip localizare
                  sau chat);
                </li>
                <li>
                  De asemenea, datele personale prelucrate conform prezentei politici pot fi transmise autorităților
                  publice în vederea îndeplinirii obligațiilor noastre legale de raportare, precum și potențialilor
                  investitori sau cumpărători ai MERO;
                </li>
              </ul>
              <p>
                <b>V. Durata și condițiile de prelucrare. Securitatea datelor personale</b>
              </p>
              <p>
                Datele cu caracter personal indicate mai sus vor fi prelucrate de către MERO doar pentru perioada
                necesară îndeplinirii scopului pentru care datele au fost colectate, perioadă care se suprapune cu
                perioada în care sunt prestate serviciile, însă ne rezervăm dreptul ca o parte dintre aceste date să fie
                prelucrate și ulterior încetării prestării serviciilor, pentru un interval de timp necesar îndeplinirii
                obligațiilor noastre legale de raportare pentru autorități și pentru exercitarea potențială a
                drepturilor noastre rezultând din contract, inclusiv în fața instanțelor de judecată. Precizăm că datele
                dumneavoastră personale nu vor face obiectul unui proces decizional automatizat. De asemenea, noi MERO
                PROGRAMARI S.R.L., deținători ai MERO, ne obligăm să garantăm securitatea datelor dumneavoastră cu
                caracter personal, prin măsuri optime de ordin tehnic și organizatoric pentru securitizarea prelucrării
                datelor personale, conform GDPR.
              </p>
              <p>
                <b>VI. Modificări ale politicii de prelucrare a datelor personale și confidențialitate</b>
              </p>
              <p>
                Serviciile oferite de MERO evoluează în permanență, iar forma și tipul serviciilor oferite se pot
                modifica uneori fără aviz prealabil. Din acest motiv, ne rezervăm dreptul de a schimba sau a adăuga
                informații la această politică de confidențialitate, iar orice revizuiri de conținut vor fi postate pe
                platforma noastră sau vor fi trimise printr-o notificare push prin intermediul aplicației și vor fi în
                vigoare de la data notificării.
              </p>
            </div>
          </div>
        </ScrollView>
      </AvoidKeyboard>
    </ModalScreenContainer>
  );
};

export default PrivacyPolicyScreenView;

import { CalendarEntry, PageMemberPreview, ReviewScore, SavedWorker } from '@mero/api-sdk';
import { ClientFeedbackId } from '@mero/api-sdk/dist/pro/clientFeedback/clientFeedbackId';
import {
  Avatar,
  Body,
  Column,
  FormCard,
  H1,
  HSpacer,
  Line,
  MeroHeader,
  Row,
  SafeAreaView,
  SmallBody,
  Spacer,
  TextInput,
  colors,
  sizes,
  styles as meroStyles,
  Button,
  AvoidKeyboard,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import { NumberFromString } from 'io-ts-types';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { Circle, G, Path, Svg } from 'react-native-svg';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import StarRating from '../../../components/StarRating';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AuthContext, meroApi } from '../../../contexts/AuthContext';
import { HasCalendarEntryState, withCalendarEntryContext } from '../../../contexts/CalendarEntryContext';
import { ClientFeedbackContext, withClientFeedbackContext } from '../../../contexts/ClientFeedbackContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';

const OutlinedCheckIcon = () => (
  <Svg width={26} height={26} viewBox="0 0 26 26">
    <G
      id="Orion_checked-circle"
      transform="translate(11.275 11.275)"
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <Circle
        id="layer2"
        cx="8.485"
        cy="8.485"
        r="8.485"
        transform="translate(-10.426 1.573) rotate(-45)"
        strokeWidth="1.2"
      />
      <Path id="layer1" d="M21,26.962l2.519,2.519L28,25" transform="translate(-22.501 -25.381)" strokeWidth="1.5" />
    </G>
  </Svg>
);
type FeedbacktoBookingScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'ClientFeedbackScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = {
  navigation: FeedbacktoBookingScreenNavigationProp;
  route: RouteProp<BookingStackParamList, 'ClientFeedbackScreen'>;
} & CurrentBusinessProps;

type ComponentProps = Props & HasCalendarEntryState;

const ClientFeedbackComponent = withClientFeedbackContext(
  withCalendarEntryContext(
    ({ calendarEntryState: state, route, navigation, page }: ComponentProps): React.ReactElement => {
      const goBack = useGoBack();
      const { t } = useTranslation('booking');
      const { isPhone } = useMediaQueries();

      const [authState] = AuthContext.useContext();
      const [clientFeedbackState] = ClientFeedbackContext.useContext();
      const [score, setScore] = React.useState(route.params.selectedScore ?? 0);
      const [review, setReview] = React.useState<string>('');
      const [clientFeedbackId, setClientFeedbackId] = React.useState<ClientFeedbackId | undefined>();
      const [requiredReviewError, setRequiredReviewError] = React.useState(false);

      const selfMember: PageMemberPreview | undefined = React.useMemo(() => {
        if (authState.type === 'Authorized') {
          return page.members.find((w) => w.user._id === authState.user._id);
        }
        return undefined;
      }, [authState.type, page.members]);

      const localWorker: SavedWorker | undefined = React.useMemo(() => {
        if (state.type === 'Loaded') {
          return page.workers.find((w) => w._id === state.entry.payload.worker._id);
        }
        return undefined;
      }, [state.type, page.workers]);

      React.useEffect(() => {
        if (score > 3 || review.trim() !== '') {
          setRequiredReviewError(false);
        }
      }, [review, score]);

      React.useEffect(() => {
        if (clientFeedbackState.type !== 'Loaded' || !clientFeedbackState.clientFeedback) {
          return;
        }
        setScore(clientFeedbackState.clientFeedback.feedback.score);
        setReview(clientFeedbackState.clientFeedback.feedback.review ?? '');
        setClientFeedbackId(clientFeedbackState.clientFeedback._id);
      }, [clientFeedbackState.type]);

      const saveClientFeedback = async () => {
        if (
          state.type !== 'Loaded' ||
          state.entry.type !== CalendarEntry.Type.Appointment.VALUE ||
          clientFeedbackState.type !== 'Loaded'
        ) {
          return;
        }

        if (score <= 3 && review.trim() === '') {
          setRequiredReviewError(true);
        }

        const decodedScore = ReviewScore.decode(score);
        if (E.isLeft(decodedScore)) {
          return;
        }

        try {
          if (!clientFeedbackId) {
            await meroApi.pro.clientFeedback.createClientFeedback({
              pageId: state.pageId,
              appointmentId: state.entry._id,
              occurrenceIndex: state.entry.occurrenceIndex,
              feedback: {
                score: decodedScore.right,
                review: review,
              },
            });
          } else {
            await meroApi.pro.clientFeedback.updateClientFeedback({
              clientFeedbackId: clientFeedbackId,
              pageId: state.pageId,
              feedback: {
                score: decodedScore.right,
                review: review,
              },
            });
          }

          goBack();
        } catch (e) {
          log.error('Failed to save client feedback', e);
        }
      };

      if (
        state.type !== 'Loaded' ||
        state.entry.type !== CalendarEntry.Type.Appointment.VALUE ||
        clientFeedbackState.type !== 'Loaded' ||
        authState.type !== 'Authorized'
      ) {
        return <></>;
      }

      return (
        <ModalScreenContainer edges={['left', 'top', 'right']}>
          <MeroHeader title={t('describeExperience')} onClose={goBack} canClose={true} />
          <SafeAreaView edges={['bottom']} style={{ flex: 1, flexDirection: 'column' }}>
            <AvoidKeyboard
              style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <ScrollView style={{ flex: 1, flexGrow: 1 }}>
                <View style={{ paddingLeft: sizes[24], paddingRight: sizes[24] }}>
                  <Spacer size={24} />

                  <Column style={{ alignItems: 'center' }}>
                    <Body>
                      {DateTime.fromJSDate(state.entry.start).setLocale('ro').toFormat('dd MMMM yyyy')} - {t('pro')}{' '}
                      {nameGenerator(localWorker?.user ?? {}, t('noName'))}
                    </Body>
                    <Spacer size={12} />
                    <StarRating score={score} setScore={setScore} gap={24} />
                    <Spacer size={24} />
                  </Column>

                  <Line />
                  <Spacer size={24} />

                  <Row>
                    <Avatar
                      size={24}
                      firstname={selfMember?.user.profile.firstname ?? ''}
                      lastname={selfMember?.user.profile.lastname ?? ''}
                      source={selfMember?.user.profile.photo?.thumbnail}
                    />
                    <HSpacer left={12} />
                    <Body style={meroStyles.text.semibold}>
                      {nameGenerator(selfMember?.user.profile ?? {}, t('noName'))}
                    </Body>
                  </Row>

                  <H1 style={{ marginVertical: 16 }}>
                    {t('decribeYourExperienceWith')} {state.entry.payload.client?.firstname}{' '}
                    {state.entry.payload.client?.lastname}
                  </H1>

                  <TextInput
                    numberOfLines={3}
                    isError={requiredReviewError}
                    multiline={true}
                    value={review}
                    onChange={setReview}
                    placeholder={t('reviewDescription')}
                  />
                  {requiredReviewError && (
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('requiredReviewError')}</SmallBody>
                  )}

                  <Spacer size={16} />
                  <Column style={{ gap: 16 }}>
                    <Row>
                      <OutlinedCheckIcon />
                      <Body style={{ width: '100%', marginLeft: 8 }}>{t('reviewCheck1')}</Body>
                    </Row>

                    <Row>
                      <OutlinedCheckIcon />
                      <Body style={{ width: '100%', marginLeft: 8 }}>{t('reviewCheck2')}</Body>
                    </Row>

                    <Row>
                      <OutlinedCheckIcon />
                      <Body style={{ width: '100%', marginLeft: 8 }}>{t('reviewCheck3')}</Body>
                    </Row>
                  </Column>

                  <Spacer size={32} />
                  {isPhone ? (
                    <Button text={t('send')} onPress={saveClientFeedback} />
                  ) : (
                    <Button
                      expand={false}
                      containerStyle={{ alignSelf: 'center' }}
                      text={t('send')}
                      onPress={saveClientFeedback}
                    />
                  )}
                  <Spacer size={32} />
                </View>
              </ScrollView>
            </AvoidKeyboard>
          </SafeAreaView>
        </ModalScreenContainer>
      );
    },
  ),
);

const ClientFeedbackScreen: React.FC<Props> = ({ page, route, navigation }) => {
  return (
    <ClientFeedbackComponent
      pageId={page.details._id}
      calendarEntryId={route.params.calendarEntryId}
      occurrenceIndex={pipe(
        route.params.occurrenceIndex,
        NumberFromString.decode,
        E.getOrElse(() => 0),
      )}
      page={page}
      route={route}
      navigation={navigation}
    />
  );
};

export default pipe(ClientFeedbackScreen, CurrentBusiness);

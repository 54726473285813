import { ModalOverlay, SafeAreaView, Title, Line } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity } from 'react-native';

import { styles } from './styles';

export type ConfirmUpdateAndAcceptBookingProps = {
  readonly onUpdate: () => void;
  readonly onUpdateAndAccept: () => void;
  readonly onDismiss: () => void;
};

const ConfirmUpdateAndAcceptBooking: React.FC<ConfirmUpdateAndAcceptBookingProps> = ({
  onUpdate,
  onUpdateAndAccept: onUpdateAndApprove,
  onDismiss,
}) => {
  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <SafeAreaView edges={['bottom']} style={styles.optionsListContainer}>
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onUpdate}>
          <Title>Salvează modificări</Title>
        </TouchableOpacity>
        <Line />
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onUpdateAndApprove}>
          <Title>Salvează și confirmă programarea</Title>
        </TouchableOpacity>
      </SafeAreaView>
    </ModalOverlay>
  );
};

export default ConfirmUpdateAndAcceptBooking;

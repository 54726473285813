import { DefinedString } from '@mero/api-sdk';
import { Body, colors, ConfirmBox, H1, Line, ModalOverlay, SimpleListItem, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const ConfirmUnlistingDialog: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('location');

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
  });

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setRequestInProgress(true);
    try {
      await meroApi.pages.unsetPageSearchable({ pageId: pageState.page.details._id });
      await reloadAsync();
      onSuccess();
    } catch (err) {
      log.error('Error while unlisting page', err);
      setRequestInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('unlistingCancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('unlistingConfirm'),
    onPress: Object.values(checks).some((v) => !v) || requestInProgress ? undefined : confirm,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="warn"
          headerTitle={t('importantAction')}
          icon="info"
          canClose={!requestInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('unlistingTitle')}</H1>
          <Spacer size="16" />
          <Body style={{ fontFamily: 'open-sans-semibold' }}>{t('unlistingDescription')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={requestInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={requestInProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('unlistingCheckbox1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={requestInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={requestInProgress ? colors.SANTAS_GRAY : colors.DARK_BLUE}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('unlistingCheckbox2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default ConfirmUnlistingDialog;

import {
  Body,
  ConfirmBox,
  H1,
  Line,
  Row,
  SimpleListItem,
  Spacer,
  colors,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { NavigationProp, RouteProp } from '@react-navigation/native';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { DefaultCategory, ProductsContext } from '../../../contexts/ProductsContext';
import { AuthorizedStackParamList } from '../../../types';

type Props = {
  navigation: NavigationProp<AuthorizedStackParamList, 'DeleteCategoryScreen'>;
  route: RouteProp<AuthorizedStackParamList, 'DeleteCategoryScreen'>;
};

const DeleteCategoryScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const showError = useShowError();
  const toast = useToast();
  const [, { update, reloadCategories }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [check1, setCheck1] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const navigateProductsCallback = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: { screen: 'ProductsTab', params: { screen: 'ProductsDashboardScreen' } },
    });
  }, [navigation]);

  const canSave = React.useMemo(() => {
    return check1 && check2;
  }, [check1, check2]);

  const deleteCategory = async () => {
    if (deleteInProgress || pageState.type !== 'Loaded') {
      return;
    }

    setDeleteInProgress(true);

    try {
      await meroApi.pro.products.deleteCategory({
        pageId: pageState.page.details._id,
        categoryId: route.params.categoryId,
      });

      setDeleteInProgress(false);
      update({ selectedCategory: DefaultCategory.active });
      reloadCategories(pageState.page.details._id);
      navigateProductsCallback();
      toast.show({ text: t('deleteCategorySuccess'), type: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: navigateProductsCallback,
    flex: 10,
  };

  const rightAction = {
    text: t('deleteCategory'),
    ...(canSave ? { onPress: deleteCategory } : {}),
    flex: 15,
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={navigateProductsCallback}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('confirmDeleteCategory')}</H1>
          <Spacer size="16" />
          <Body style={styles.text.semibold}>{t('deleteCheckboxDescription')}</Body>
          <Spacer size={19} />
          <TouchableOpacity onPress={() => setCheck1((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCategoryCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Spacer size="16" />
          <Line />
          <Spacer size="16" />

          <TouchableOpacity onPress={() => setCheck2((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCategoryCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </View>
  );
};

export default DeleteCategoryScreen;

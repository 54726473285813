import { SubscriptionTier } from '@mero/api-sdk';
import {
  Avatar,
  Button,
  colors,
  FormCard,
  LogoIcon,
  Spacer,
  Text,
  useAppState,
  SafeAreaView,
  Icon,
  MeroHeader,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Platform, TouchableOpacity, ScrollView } from 'react-native';

import { CompositeNavigationProp } from '@react-navigation/core';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AuthorizedProps, Authorized, AuthContext } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../contexts/IntercomContext';
import { AuthorizedStackParamList, HomeDrawerParamsList, RootStackParamList } from '../../../types';
import { styles } from './styles';

export type Props = CurrentBusinessProps &
  AuthorizedProps & {
    navigation: CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >;
  };

const SubscriptionExpiredScreen: React.FC<Props> = ({ page: { details, subscription }, navigation }: Props) => {
  const title = details.name ?? '';
  const titleParts = title.split(/\s+/g);
  const isFocused = useIsFocused();

  const [, { reload, reloadAsync }] = CurrentBusinessContext.useContext();
  const [, { signOut }] = AuthContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();

  const signOutCallback = React.useCallback(() => {
    signOut();
  }, [signOut]);

  // Reload current business every time app becomes active
  const appState = useAppState();
  const [lastState, setLastState] = React.useState(appState);
  React.useEffect(() => {
    if (lastState === 'background' && appState === 'active') {
      reload();
    }
    setLastState(appState);
  }, [appState, lastState, setLastState]);

  React.useEffect(() => {
    if (isFocused) {
      reloadAsync();
    }
  }, [isFocused]);

  const onPageIconPressed = React.useCallback(() => {
    navigation.navigate('SelectAccount');
  }, [navigation]);

  const onSubscribeNowPressed = React.useCallback(() => {
    navigation.navigate('Menu', { screen: 'PageSubscriptionSettingsScreen', params: { screen: 'Subscription' } });
  }, [navigation]);

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  const wasTrial = subscription?.tier === SubscriptionTier.TRIAL;

  return (
    <SafeAreaView style={styles.container}>
      <MeroHeader
        TitleComponent={() => (
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }} onPress={onPageIconPressed}>
            <Avatar
              firstname={titleParts[0] ?? ''}
              lastname={titleParts[1] ?? ''}
              size={32}
              source={details.images[0]?.small}
            />
            <Text smallBody semibold numberOfLines={1} style={{ paddingLeft: 8, paddingRight: 12 }}>
              {details.name}
            </Text>
            <Icon type="dropdown" />
          </TouchableOpacity>
        )}
      />
      <ScrollView style={styles.body} contentContainerStyle={styles.bodyContent}>
        <Spacer size="48" />
        <LogoIcon />
        <Spacer size="32" />
        <Text h1 center>
          {wasTrial ? 'Perioada de evaluare\ngratuită a expirat' : 'Acces restricționat'}
        </Text>
        <Spacer size="8" />
        <Text center>
          {wasTrial
            ? 'Pentru a putea continua să folosești\nplatforma abonează-te'
            : 'Achită cu cardul online pentru a\nrestabili instant accesul la cont'}
        </Text>
        <Spacer size="48" />
        {Platform.OS !== 'ios' ? (
          <>
            <Button
              size="medium"
              text={wasTrial ? 'Abonează-te acum' : 'Vezi detalii plată'}
              containerStyle={{ maxWidth: 192 }}
              onClick={onSubscribeNowPressed}
            />
            {wasTrial ? (
              <>
                <Spacer size="16" />
                <Text smallBody color={colors.COMET}>
                  Poți anula abonamentul oricând
                </Text>
              </>
            ) : null}
          </>
        ) : null}
        <Spacer size={90} />
        <Spacer size="8" />
        <Text center>
          Ai întrebări sau putem să te ajutăm în{'\n'} vreun fel?{' '}
          <Text link semibold style={{ textAlignVertical: 'center' }} onPress={onContactUsPressed}>
            Contactează-ne
          </Text>
        </Text>
      </ScrollView>
    </SafeAreaView>
  );
};

export default pipe(SubscriptionExpiredScreen, Authorized, CurrentBusiness);

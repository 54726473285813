import { Paged } from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { ClientHistoryRecord, ClientId, ClientProfile } from '@mero/api-sdk/dist/clients';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { PageId } from '@mero/api-sdk/dist/pages/page-id';
import { createModelContext } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';

import log, { logCatch } from '../../utils/log';
import { meroApi } from '../AuthContext';

type PageAppointmentsWithTotals = Paged<UserAppointment[]> & {
  readonly futureBookingsCount: number;
  readonly pastBookingsCount: number;
};

export type ClientDetailsContextState =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Loading';
      readonly clientId: ClientId;
      readonly prevClient?: ClientProfile;
    }
  | {
      readonly type: 'Loaded';
      readonly clientId: ClientId;
      readonly client: ClientProfile;
      readonly history: ClientHistoryRecord[];
      readonly appointments: PageAppointmentsWithTotals;
      readonly memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
    }
  | {
      readonly type: 'NotFound';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly clientId: ClientId;
      readonly error: unknown;
    };

const defaultState = (): ClientDetailsContextState => ({
  type: 'New',
});

export const ClientDetailsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      params: {
        clientId: ClientId;
        client: ClientProfile;
        history: ClientHistoryRecord[];
        appointments: PageAppointmentsWithTotals;
        memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          clientId: params.clientId,
          client: params.client,
          history: params.history,
          appointments: params.appointments,
          memberships: params.memberships,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    update(
      state,
      params: Partial<{
        clientId: ClientId;
        client: ClientProfile;
        history: ClientHistoryRecord[];
        appointments: PageAppointmentsWithTotals;
        memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
      }>,
    ) {
      return {
        ...state,
        ...params,
      };
    },
    trySetNotFound: (state, clientId: ClientId) => {
      if (state.type === 'Loading' && clientId === state.clientId) {
        return {
          type: 'NotFound',
          clientId: clientId,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (state, params: { clientId: ClientId; error: unknown }) => {
      return {
        type: 'Failed',
        clientId: params.clientId,
        error: params.error,
      };
    },
    mutate: (s, fn: (s: ClientDetailsContextState) => ClientDetailsContextState): ClientDetailsContextState => fn(s),
    run: (s, f: (s: ClientDetailsContextState) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const reload = async (clientId: ClientId, pageId: PageId) => {
      try {
        log.debug('Start (re)loading client details');

        const client = await meroApi.clients.getClientProfileById(clientId);

        if (client) {
          const [history, appointments, memberships] = await Promise.all([
            meroApi.clients.getClientHistory(clientId).catch(logCatch('getClientHistory')),
            meroApi.calendar
              .fetchPagedClientUserAppointments({ clientId, limit: 10 })
              .catch(logCatch('fetchPagedClientUserAppointments')),
            meroApi.memberships
              .getUserPurchasedMembershipsByPro({ pageId, userId: client.user._id, limit: 10 })
              .catch(logCatch('getUserPurchasedMembershipsByPro')),
          ]);

          dispatch.trySetResult({
            clientId: clientId,
            client,
            history: history,
            appointments: {
              ...appointments,
              futureBookingsCount: appointments.futureBookingsCount ?? 0,
              pastBookingsCount: appointments.pastBookingsCount ?? 0,
            },
            memberships,
          });
        } else {
          dispatch.trySetNotFound(clientId);
        }
      } catch (error) {
        dispatch.setFailed({
          clientId: clientId,
          error: error,
        });

        log.exception(error);
      }
    };

    return {
      loadMoreAppointments: async (clientId: ClientId) =>
        new Promise<void>((resolve) => {
          dispatch.run(async (state) => {
            if (state.type === 'Loaded' && state.appointments.next) {
              try {
                const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
                  clientId,
                  limit: 10,
                  page: state.appointments.next,
                });

                dispatch.update({
                  appointments: {
                    ...appointments,
                    data: [...state.appointments.data, ...appointments.data],
                    futureBookingsCount: state.appointments.futureBookingsCount,
                    pastBookingsCount: state.appointments.pastBookingsCount,
                  },
                });
              } catch (error) {
                log.error('Failed to load more appointments', error);
              } finally {
                resolve();
              }
            }
          });
        }),
      reloadMemberships: async (clientId: ClientId, pageId: PageId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const memberships = await meroApi.memberships.getUserPurchasedMembershipsByPro({
                pageId,
                userId: state.client.user._id,
                limit: 10,
              });

              dispatch.update({ memberships });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
      loadMoreMemberships: async (clientId: ClientId, pageId: PageId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded' && state.memberships.next) {
            try {
              const memberships = await meroApi.memberships.getUserPurchasedMembershipsByPro({
                pageId,
                userId: state.client.user._id,
                limit: 10,
                page: state.memberships.next,
              });

              dispatch.update({
                memberships: {
                  ...memberships,
                  data: [...state.memberships.data, ...memberships.data],
                },
              });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
      reload: (clientId: ClientId, pageId: PageId): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' || state.type === 'New' || state.type === 'Failed') {
            reload(clientId, pageId).catch(log.exception);

            return {
              type: 'Loading',
              clientId: clientId,
              prevClient: state.type === 'Loaded' && state.clientId === clientId ? state.client : undefined,
            };
          }

          return state;
        });
      },
    };
  },
);

export type ClientDetailsContextProviderProps = React.PropsWithChildren<{
  readonly clientId: ClientId;
  readonly pageId: PageId;
}>;

const ContextInit: React.FC<ClientDetailsContextProviderProps> = ({ clientId, pageId, children }) => {
  const [, { reload }] = ClientDetailsContext.useContext();

  React.useEffect(() => {
    reload(clientId, pageId);
  }, [reload, clientId]);

  return <>{children}</>;
};

export const ClientDetailsContextProvider: React.FC<ClientDetailsContextProviderProps> = ({ children, ...props }) => {
  return (
    <ClientDetailsContext.Provider>
      <ContextInit {...props}>{children}</ContextInit>
    </ClientDetailsContext.Provider>
  );
};

import { ApiError, apiError } from '@mero/api-sdk';
import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type State =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Deleting';
      readonly calendarId: CalendarId;
      readonly calendarEntryId: AppointmentId;
    }
  | {
      readonly type: 'Deleted';
      readonly calendarId: CalendarId;
      readonly calendarEntryId: AppointmentId;
    }
  | {
      readonly type: 'Failed';
      readonly error?: ApiError<unknown>;
    };

const defaultState = (): State => ({
  type: 'Ready',
});

export const BookingDeleteContext = createModelContext(
  defaultState(),
  {
    setDeleting: (_, payload: { calendarId: CalendarId; calendarEntryId: AppointmentId }) => ({
      type: 'Deleting',
      calendarId: payload.calendarId,
      calendarEntryId: payload.calendarEntryId,
    }),
    setDeleted: (_, payload: { calendarId: CalendarId; calendarEntryId: AppointmentId }) => ({
      type: 'Deleted',
      calendarId: payload.calendarId,
      calendarEntryId: payload.calendarEntryId,
    }),
    setFailed: (_, error: ApiError<unknown> | undefined) => ({
      type: 'Failed',
      error: error,
    }),
    reset: () => ({
      type: 'Ready',
    }),
  },
  (dispatch) => {
    return {
      deleteCalendarEntry: async (payload: {
        calendarId: CalendarId;
        calendarEntryId: AppointmentId;
        occurrenceIndex: number;
        onlyOnce: boolean;
        reason: string;
      }) => {
        try {
          dispatch.setDeleting(payload);
          log.debug(
            `Load calendar entry with id ${payload.calendarEntryId} (#${payload.occurrenceIndex}) for calendarId ${payload.calendarId} (onlyOnce: ${payload.onlyOnce})`,
          );

          await meroApi.calendar.cancelAppointment({
            calendarId: payload.calendarId,
            entryId: payload.calendarEntryId,
            occurrenceIndex: payload.occurrenceIndex,
            onlyOnce: payload.onlyOnce,
            reason: payload.reason,
          });

          dispatch.setDeleted(payload);
        } catch (e) {
          if (apiError(t.unknown).is(e)) {
            dispatch.setFailed(e);
          } else {
            dispatch.setFailed(undefined);
            log.exception(e);
          }
        }
      },
      reset: dispatch.reset,
    };
  },
);

export const withBookingDeleteContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithBookingDeleteContextProvider(props: P) {
    return (
      <BookingDeleteContext.Provider>
        <Content {...props} />
      </BookingDeleteContext.Provider>
    );
  };
};

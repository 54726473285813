import { PageDetails } from '@mero/api-sdk';
import { SimpleListItem } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

export function PageItem({
  page,
  onPress,
  selected,
  separator,
}: {
  page: PageDetails;
  onPress: () => void;
  selected: boolean;
  separator: boolean;
}): React.ReactElement {
  const pageNameParts = (page.name ?? '').split(/\s+/g);
  return (
    <>
      <TouchableOpacity delayPressIn={0} onPress={onPress}>
        <SimpleListItem
          icon={selected ? 'checked' : 'unchecked'}
          title={page.name}
          subtitle={page.location?.address}
          image={{
            src: page.profilePhoto.medium,
            firstname: pageNameParts[0],
            lastname: pageNameParts[1],
          }}
          alignContent="middle"
        />
      </TouchableOpacity>
      {!!separator && <View key={`${page._id}-separator`} style={{ height: 1, backgroundColor: '#E0E0E0' }} />}
    </>
  );
}

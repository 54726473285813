import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.WorkerInviteNotification;
};

const WorkerInviteNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_client_accepted" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>{payload.page.name}</Body> te invită să te alături echipei lor.
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())}
          {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default WorkerInviteNotification;

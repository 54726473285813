import { useShowError } from '@mero/components';
import * as React from 'react';

import ClientDetailsLoadingScreenView from './ClientDetailsLoadingScreenView';

export type Props = {
  readonly onBack: () => void;
  readonly error: unknown;
};

const ClientDetailsFailedScreenView: React.FC<Props> = ({ onBack, error }: Props) => {
  const showError = useShowError();

  React.useEffect(() => {
    showError(error);
  }, [error]);

  return <ClientDetailsLoadingScreenView onBack={onBack} />;
};

export default ClientDetailsFailedScreenView;

import {
  Body,
  ConfirmBox,
  H1,
  Line,
  Row,
  SimpleListItem,
  Spacer,
  colors,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { NavigationProp, RouteProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { AuthorizedStackParamList } from '../../../types';

type Props = {
  navigation: NavigationProp<AuthorizedStackParamList, 'DeleteBrandScreen'>;
  route: RouteProp<AuthorizedStackParamList, 'DeleteBrandScreen'>;
};

const DeleteBrandScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('products');
  const showError = useShowError();
  const toast = useToast();
  const [state, { reloadBrands, update }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [check1, setCheck1] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const canSave = React.useMemo(() => {
    return check1 || check2;
  }, [check1, check2]);

  const navigateProductsCallback = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: { screen: 'ProductsTab', params: { screen: 'ProductsDashboardScreen' } },
    });
  }, [navigation]);

  const deleteBrand = async () => {
    if (deleteInProgress || pageState.type !== 'Loaded' || state.type !== 'Loaded') {
      return;
    }

    setDeleteInProgress(true);

    try {
      await meroApi.pro.products.deleteBrand({
        pageId: pageState.page.details._id,
        brandId: route.params.brandId,
      });

      toast.show({ text: t('deleteBrandSuccess'), type: 'success' });
      setDeleteInProgress(false);
      update({ selectedBrand: state.brands[0] });
      reloadBrands(pageState.page.details._id);
      navigateProductsCallback();
    } catch (error) {
      showError(error);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: goBack,
    flex: 10,
  };

  const rightAction = {
    text: t('deleteBrand'),
    ...(canSave ? { onPress: () => deleteBrand() } : {}),
    flex: 15,
  };

  if (state.type !== 'Loaded') {
    return;
  }

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle="Actiune ireversibilă"
          icon="info"
          canClose={!deleteInProgress}
          onClose={goBack}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('confirmDeleteBrand')}</H1>
          <Spacer size="16" />
          <Body style={styles.text.semibold}>{t('deleteCheckboxDescription')}</Body>
          <Spacer size={19} />
          <TouchableOpacity onPress={() => setCheck1((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteBrandCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Spacer size="16" />
          <Line />
          <Spacer size="16" />

          <TouchableOpacity onPress={() => setCheck2((prev) => !prev)} disabled={deleteInProgress}>
            <SimpleListItem
              icon={check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteBrandCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </View>
  );
};

export default DeleteBrandScreen;

import {
  colors,
  MeroHeader,
  H3s,
  HSpacer,
  Spacer,
  H1,
  FormCard,
  Line,
  Column,
  Checkbox,
  Row,
  SmallBody,
  styles,
  Body,
  Button,
  SafeAreaView,
  useToast,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { PageReviewsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

type Props = StackScreenProps<PageReviewsStackParamList, 'Settings'>;

const ReviewSettingsScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  const { t } = useTranslation('reviews');
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [pageState, { updatePageDetails }] = CurrentBusinessContext.useContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hideWorkerReviews, setHideWorkerReviews] = React.useState(false);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && pageState.page.details.hideWorkerReviews !== undefined) {
      setHideWorkerReviews(pageState.page.details.hideWorkerReviews);
    }
  }, [pageState.type]);

  const save = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      setIsLoading(true);

      await meroApi.pages.updateHideWorkerReviews({
        pageId: pageState.page.details._id,
        hideWorkerReviews: hideWorkerReviews,
      });

      updatePageDetails(pageState.page.details._id);
      goBack();
      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });
    } catch (e) {
      log.error('Error while saving review settings', e);
    }

    setIsLoading(false);
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('ownReviewsSettings')} RightComponent={<HSpacer left={32} />} />

      <Spacer size={16} />
      <H1 style={{ marginHorizontal: 24 }}>{t('reviewsSettings')}</H1>
      <Spacer size={32} />

      <FormCard style={{ marginHorizontal: 16 }} paddings="none" dropShaddow>
        <H3s style={{ padding: 16 }}>{t('proReviews')}</H3s>
        <Line />
        <TouchableOpacity
          onPress={() => setHideWorkerReviews((prev) => !prev)}
          style={{
            flex: 1,
            flexDirection: 'row',
            marginHorizontal: 16,
            marginVertical: 24,
          }}
        >
          <Checkbox value={!hideWorkerReviews} onValueChange={() => setHideWorkerReviews((prev) => !prev)} />
          <HSpacer left={12} />
          <Column style={{ flex: 1 }}>
            <Body style={styles.text.semibold}>{t('enableProOwnReviews')}</Body>
            <SmallBody>{t('enableProOwnReviewsDescription')}</SmallBody>
          </Column>
        </TouchableOpacity>
      </FormCard>

      <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onPress={save} disabled={isLoading} />
          ) : (
            <Button
              text={t('saveChanges')}
              containerStyle={{ alignSelf: 'center' }}
              onPress={save}
              disabled={isLoading}
              expand={false}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ReviewSettingsScreen;

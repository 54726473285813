import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  inputContainer: {
    borderBottomWidth: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderColor: colors.GEYSER,
  },
  description: {
    backgroundColor: '#A8AABE',
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
});

import { PageId } from '@mero/api-sdk';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { colors, H1, SmallBody, styles as meroStyles, useShowError } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import Spacer from '@mero/components/lib/components/Spacer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutDraftsCountContext } from '../../../contexts/CheckoutDraftsCountContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutStackParamList } from '../../../types';
import DeleteDraftDialog from './DeleteDraftDialog';
import DraftOptionsMenu from './DraftOptionsMenu';
import DraftPreview from './DraftPreview';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & AuthorizedStackParamList, 'AllDraftsScreen'>;

const AllDraftsScreen: React.FC<Props> = ({ navigation, page }) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const showError = useShowError();
  const isFocused = useIsFocused();

  const [drafts, setDrafts] = React.useState<CheckoutTransactionPreview.AnyDraft[]>([]);
  const [checkoutDraftsCountState, { reload: reloadCount }] = CheckoutDraftsCountContext.useContext();
  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const deleteCheckout = async (type: 'all' | 'one') => {
    if (type === 'all') {
      setShowDeleteDialog(true);
    } else {
      navigation.navigate('DeleteDraftsScreen');
    }
  };

  React.useEffect(() => {
    const load = async (pageId: PageId, limit: number) => {
      try {
        const drafts = await meroApi.checkout.listPageDraftTransactions({ pageId, page: { limit } });

        setDrafts(drafts.data);
      } catch (error) {
        showError(error);
      }
    };

    if (checkoutDraftsCountState.type === 'Loaded') {
      load(page.details._id, checkoutDraftsCountState.draftsCount);
    }
  }, [checkoutDraftsCountState]);

  React.useEffect(() => {
    if (isFocused) {
      reloadCount(page.details._id);
    }
  }, [isFocused]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('allDrafts')}
          RightComponent={
            <TouchableOpacity
              style={{ justifyContent: 'center', height: 50, marginRight: 16 }}
              onPress={() => setShowOptionsMenu(true)}
            >
              <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('options')}</SmallBody>
            </TouchableOpacity>
          }
        />
        <FlatList
          ListHeaderComponent={() => (
            <>
              <Spacer size={36} />
              <H1 style={{ paddingHorizontal: 24 }}>{t('allDrafts')}</H1>
              <Spacer size={32} />
            </>
          )}
          data={drafts}
          renderItem={({ item }) => (
            <Column style={{ backgroundColor: colors.SKY_BLUE, paddingVertical: 16, paddingHorizontal: 8 }}>
              <DraftPreview
                draft={item}
                onPress={() => navigation.navigate('AddProceedScreen', { type: 'Booking', transactionId: item._id })}
              />
            </Column>
          )}
          ItemSeparatorComponent={() => (
            <Column style={{ backgroundColor: colors.SKY_BLUE }}>
              <Line />
            </Column>
          )}
        />
      </ModalScreenContainer>
      {showOptionsMenu && (
        <DraftOptionsMenu
          onDismiss={() => setShowOptionsMenu(false)}
          onSelect={flow(deleteCheckout, () => setShowOptionsMenu(false))}
        />
      )}
      {showDeleteDialog && (
        <DeleteDraftDialog
          onSuccess={goBack}
          onCancel={() => setShowDeleteDialog(false)}
          checkoutTransactionId={drafts.map((d) => d._id)}
        />
      )}
    </>
  );
};

export default pipe(AllDraftsScreen, CurrentBusiness, Authorized);

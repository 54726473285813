import { FixedPrice, SavedService, ServiceGroup, ServiceGroupId, ServiceId } from '@mero/api-sdk/dist/services';
import { Price } from '@mero/api-sdk/dist/services';
import { Body, Column, H2s, HSpacer, Line, Row, SmallBody, Spacer, colors, styles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionValue, FlatList, Pressable, TouchableOpacity } from 'react-native';
import Svg, { G, Circle, Path } from 'react-native-svg';

import { DARK_BLUE, COMET } from '@mero/components/lib/styles/colors';

import { mapColor } from './ServiceScreen';
import { formatDuration } from './ServicesMobileScreen';

export type CheckedIconProps = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CheckedIcon: React.FC<CheckedIconProps> = ({ size = 24, disabled = false, color = DARK_BLUE, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-126 -558)">
      <Circle cx="12" cy="12" r="12" transform="translate(126 558)" fill={color} />
      <Path
        d="M5.76,11.681l3.094,3.094L16.588,7.04"
        transform="translate(126.99 559.21)"
        fill="none"
        stroke="#fff"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

type UncheckedIconProps = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const UncheckedIcon: React.FC<UncheckedIconProps> = ({ size = 24, disabled = false, color = COMET, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-126 -558)">
      <G transform="translate(126 558)" fill="none" stroke={color} strokeWidth="1.5">
        <Circle cx="12" cy="12" r="12" stroke="none" />
        <Circle cx="12" cy="12" r="11.25" fill="none" />
      </G>
    </G>
  </Svg>
);

type UnselectAllIconProps = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};
const UnselectAllIcon: React.FC<UnselectAllIconProps> = ({ size = 24, disabled = false, color = COMET, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" disabled={disabled} onPress={onPress}>
    <G transform="translate(-126 -557.524)">
      <G transform="translate(126 557.524)" fill="none" stroke={color} strokeWidth="1.2">
        <Circle cx="7" cy="7" r="7" stroke="none" />
        <Circle cx="7" cy="7" r="6.4" fill="none" />
      </G>
      <line
        id="Line_700"
        data-name="Line 700"
        x2="6"
        transform="translate(130 564.524)"
        fill="none"
        stroke={color}
        strokeWidth="1.2"
      />
    </G>
  </Svg>
);

export type CustomCheckboxProps = {
  value?: boolean;
  color?: 'blue' | 'red';
  disabled?: boolean;
  onValueChange?: (checked: boolean) => void;
  unselectedIcon?: 'empty' | 'minus';
  isHovered: boolean;
};
const CustomCheckbox = ({
  value = false,
  disabled,
  onValueChange,
  unselectedIcon = 'empty',
  isHovered,
}: CustomCheckboxProps): React.ReactElement => {
  const onClick = React.useCallback(() => {
    const newValue = !value;

    if (onValueChange) {
      onValueChange(newValue);
    }
  }, [value, onValueChange]);

  return (
    <Pressable onPress={onClick} disabled={disabled}>
      {value ? (
        <CheckedIcon disabled={true} color={DARK_BLUE} size={14} />
      ) : unselectedIcon === 'empty' ? (
        <UncheckedIcon disabled={disabled} color={!isHovered ? '#A8AABE' : COMET} size={14} />
      ) : (
        <UnselectAllIcon disabled={disabled} color={!isHovered ? '#A8AABE' : COMET} size={14} />
      )}
    </Pressable>
  );
};

type ServiceRow = {
  readonly _id: ServiceId;
  readonly name: string;
  readonly automaticApproval: boolean;
  readonly durationInMinutes: number;
  readonly promoPrice?: string;
  readonly price: string;
  readonly description: string;
  readonly color: string;
  readonly private: boolean;
};

export type Primitives = string | number | boolean | Price;

type Column<T extends Record<string, Primitives>> = {
  field: keyof T;
  headerName: string;
  width: DimensionValue;
  hidden?: boolean;
  style: {
    header: React.ComponentProps<typeof SmallBody>['style'];
    cell: React.ComponentProps<typeof SmallBody>['style'];
  };
};

type Props = {
  readonly serviceGroupId: ServiceGroupId;
  readonly editService: (serviceId: SavedService['_id']) => void;
  readonly foundServices: { group: ServiceGroup; services: SavedService[] }[];
  readonly selectedServices: ServiceId[];
  readonly resetSelectedService: () => void;
  readonly selectServices: (serviceIds: ServiceId[]) => void;
  readonly unselectService: (serviceId: ServiceId) => void;
};

const ServicesTable: React.FC<Props> = ({
  serviceGroupId,
  editService,
  foundServices,
  selectedServices,
  resetSelectedService,
  selectServices,
  unselectService,
}) => {
  const { t } = useTranslation('services');
  const [hoveredRow, setHoveredRow] = React.useState<SavedService['_id'] | 'header' | undefined>();

  const tableRef = React.useRef<FlatList<ServiceRow>>(null);

  const { data, columns } = React.useMemo(() => {
    let data: ServiceRow[] = [];
    const services = foundServices.find(({ group }) => group._id === serviceGroupId)?.services ?? [];

    data = services.map((service) => {
      let price;
      let promoPrice;
      switch (service.price.type) {
        case 'range': {
          price = `${service.price.range.from} - ${service.price.range.to} lei`;
          break;
        }
        case 'fixed': {
          const servicePrice = service.price as FixedPrice;
          price = `${servicePrice.fixed} lei`;
          promoPrice = servicePrice.promo ? `${servicePrice.promo} lei` : undefined;
          break;
        }
        case 'hidden': {
          price = `preț ascuns`;
        }
      }
      return {
        _id: service._id,
        name: service.name,
        description: service.description,
        durationInMinutes: service.durationInMinutes,
        automaticApproval: service.automaticApproval,
        private: service.private,
        price: price,
        promoPrice: promoPrice,
        color: service.color,
      };
    });

    const columns: Column<ServiceRow>[] = [
      {
        field: 'name',
        headerName: t('name'),
        width: '70%',
        style: {
          header: {
            justifyContent: 'flex-start',
          },
          cell: {
            textAlign: 'left',
            paddingLeft: 0,
          },
        },
      },
      {
        field: 'durationInMinutes',
        headerName: t('durationInMinutes'),
        width: '15%',
        style: {
          header: {
            justifyContent: 'flex-end',
          },
          cell: {
            textAlign: 'right',
            paddingLeft: 0,
          },
        },
      },
      {
        field: 'price',
        headerName: t(`price`),
        width: `15%`,
        style: {
          header: {
            justifyContent: 'flex-end',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
    ];

    return {
      columns: columns,
      data: data,
    };
  }, [foundServices, serviceGroupId]);

  const renderHeader = () => (
    <Pressable onHoverIn={() => setHoveredRow('header')} onHoverOut={() => setHoveredRow(undefined)}>
      {data.length > 0 && (
        <Row style={[{ paddingVertical: 12, paddingRight: 12, backgroundColor: colors.WHITE }]}>
          {columns
            .filter((c) => !c.hidden)
            .map((column, index) => (
              <TouchableOpacity
                disabled={data.length === 0}
                key={column.field.toString()}
                onPress={() => {
                  if (data.every(({ _id }) => selectedServices.includes(_id))) {
                    resetSelectedService();
                  } else {
                    selectServices(data.map((row) => row._id));
                  }
                }}
                style={[
                  column.style.header,
                  {
                    width: column.width,
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: index !== columns.length - 1 ? 16 : 0,
                  },
                ]}
              >
                {column.field === 'name' && (
                  <>
                    <HSpacer left={12} />
                    <CustomCheckbox
                      isHovered={hoveredRow === 'header'}
                      disabled={data.length === 0}
                      unselectedIcon={selectedServices.length === 0 ? 'empty' : 'minus'}
                      value={!!selectedServices.length && data.every(({ _id }) => selectedServices.includes(_id))}
                      onValueChange={() => {
                        if (data.every(({ _id }) => selectedServices.includes(_id))) {
                          resetSelectedService();
                        } else {
                          selectServices(data.map((row) => row._id));
                        }
                      }}
                    />
                    <HSpacer left={13} />
                  </>
                )}
                <SmallBody
                  style={[
                    { fontFamily: 'open-sans-semibold' },
                    column.style?.header,
                    data.length === 0 && column.field === 'name' && { color: colors.COMET },
                  ]}
                >
                  {column.headerName}
                </SmallBody>
              </TouchableOpacity>
            ))}
        </Row>
      )}
      <Line color={colors.ATHENS_GRAY} />
    </Pressable>
  );

  const renderItem = ({ item }: { item: ServiceRow; index: number }) => (
    <Pressable
      onPress={() => editService(item['_id'])}
      onHoverIn={() => setHoveredRow(item['_id'])}
      onHoverOut={() => setHoveredRow(undefined)}
      style={hoveredRow === item['_id'] && { backgroundColor: colors.ALABASTER }}
    >
      <Row
        style={{
          paddingVertical: 16,
          paddingRight: 12,
          alignItems: 'center',
        }}
      >
        {columns
          .filter((c) => !c.hidden)
          .map((column, index) => (
            <Column
              key={column.field.toString()}
              style={{ width: column.width, paddingRight: index !== columns.length - 1 ? 16 : 0 }}
            >
              {column.field === 'name' && (
                <Row alignItems="center">
                  <Row
                    style={{ width: 12, height: 24, borderLeftColor: mapColor(item['color']), borderLeftWidth: 3 }}
                  />
                  <CustomCheckbox
                    isHovered={hoveredRow === item['_id']}
                    value={selectedServices.includes(item['_id'])}
                    onValueChange={() => {
                      if (selectedServices.includes(item['_id'])) {
                        unselectService(item['_id']);
                      } else {
                        selectServices([item['_id']]);
                      }
                    }}
                  />
                  <HSpacer left={13} />
                  <Column style={{ flex: 1 }}>
                    <SmallBody style={[styles.text.semibold, column.style?.cell, { marginRight: 2 }]}>
                      {item[column.field]}
                    </SmallBody>
                    <SmallBody style={[column.style?.cell]}>
                      {!item['automaticApproval'] && (
                        <SmallBody style={[styles.text.hint, { fontSize: 12 }]}>{t('manualApproval')}</SmallBody>
                      )}
                      {!item['automaticApproval'] && item['private'] && (
                        <SmallBody style={[styles.text.hint, { fontSize: 12 }]}> - </SmallBody>
                      )}
                      {item['private'] && (
                        <SmallBody style={[styles.text.hint, { fontSize: 12 }]}>{t('private')}</SmallBody>
                      )}
                    </SmallBody>
                  </Column>
                </Row>
              )}

              {column.field === 'durationInMinutes' && (
                <SmallBody style={[column.style?.cell]}>{formatDuration(item[column.field])}</SmallBody>
              )}

              {column.field === 'price' && item['promoPrice'] && (
                <>
                  <SmallBody style={[column.style?.cell]}>{item['promoPrice']}</SmallBody>
                  <SmallBody
                    style={[
                      column.style?.cell,
                      styles.text.hint,
                      { fontSize: 12, textDecorationLine: 'line-through', textDecorationStyle: 'solid' },
                    ]}
                  >
                    {item['price']}
                  </SmallBody>
                </>
              )}

              {column.field === 'price' && !item['promoPrice'] && (
                <SmallBody style={[column.style?.cell]}>{item['price']}</SmallBody>
              )}
            </Column>
          ))}
      </Row>
    </Pressable>
  );

  return (
    <>
      <Spacer size={data.length === 0 ? 16 : 12} />
      <FlatList
        ref={tableRef}
        data={data}
        ListHeaderComponent={renderHeader}
        renderItem={renderItem}
        stickyHeaderIndices={[0]}
        ListEmptyComponent={() => (
          <>
            <Spacer size={24} />
            <Column
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 24,
                paddingBottom: 32,
              }}
            >
              <H2s style={{ textAlign: 'center' }}>{t('noServices')}</H2s>
              <Spacer size={8} />
              <Body style={{ textAlign: 'center' }}>{t('noServicesDescription')}</Body>
            </Column>
          </>
        )}
        ItemSeparatorComponent={() => <Line color={colors.ATHENS_GRAY} />}
      />
    </>
  );
};

export default ServicesTable;

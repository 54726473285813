import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ClientMembershipDetailsScreen from '../screens/Authorized/CheckoutScreen/ClientMembershipDetailsScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { CheckoutMembershipStackParamList } from '../types';
import { styles } from './styles';

const CheckoutMembershipStack = createStackNavigator<CheckoutMembershipStackParamList>();

export function CheckoutMembershipStackNavigator() {
  const { t } = useTranslation();

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <CheckoutMembershipStack.Navigator>
      <CheckoutMembershipStack.Screen
        name="ClientMembershipDetailsScreen"
        component={ClientMembershipDetailsScreen}
        options={{
          headerShown: false,
          ...(isPhone ? TransitionPresets.ModalFadeTransition : {}),
          cardStyle: modalStyle,
          title: t('checkout:editMembershipPayment'),
        }}
      />
    </CheckoutMembershipStack.Navigator>
  );
}

import { UserId } from '@mero/api-sdk/dist/users';
import * as t from 'io-ts';

export type ReadonlyRecursively<T> = T extends Record<string, unknown>
  ? { readonly [K in keyof T]: ReadonlyRecursively<T[K]> }
  : T;

export const DecodedToken = t.type({
  _id: UserId,
});

export type DecodedToken = t.TypeOf<typeof DecodedToken>;

import { clientPreview, ClientPreview } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { Eq } from 'fp-ts/lib/Eq';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import log, { logCatch } from '../../utils/log';
import { AuthContext, meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type ClientsSearchResult =
  | {
      /**
       * No clients exists on the account
       */
      readonly type: 'NoClientsExists';
    }
  | {
      /**
       * No clients found for user input
       */
      readonly type: 'NoClientsFound';
    }
  | {
      readonly type: 'ClientsResult';
      /**
       * Clients array may still be empty, which doesn't mean there are no clients found or exists,
       * maybe the query is just too short for autocomplete
       */
      readonly clients: ClientPreview[];
      /**
       * Total clients count
       */
      readonly clientsCount: number | undefined;
    };

const EmptyClientsResult = {
  type: 'ClientsResult' as const,
  clients: [],
  clientsCount: undefined,
};

export type ClientsFlagFilter = 'all' | 'blocked' | 'warned';

const ClientFlagFilter2Flag: {
  [k in ClientsFlagFilter]: { isBlocked?: boolean; isWarned?: boolean; isFavourite?: boolean };
} = {
  all: {},
  blocked: {
    isBlocked: true,
  },
  warned: {
    isWarned: true,
  },
};

type ClientsQuery = {
  readonly search: string;
  readonly flagFilter: ClientsFlagFilter;
};

const clientsQuery: Eq<ClientsQuery> = {
  equals: (a, b) => a.search === b.search && a.flagFilter === b.flagFilter,
};

type SearchClientsState =
  | {
      readonly type: 'New';
      readonly query: ClientsQuery;
      readonly minQueryLength: number;
      readonly clients: ClientsSearchResult;
    }
  | {
      readonly type: 'Loading';
      readonly pageId: PageId;
      readonly query: ClientsQuery;
      readonly minQueryLength: number;
      readonly clients: ClientsSearchResult;
      readonly hasMore: boolean;
    }
  | {
      readonly type: 'Loaded';
      readonly pageId: PageId;
      readonly query: ClientsQuery;
      readonly minQueryLength: number;
      readonly clients: ClientsSearchResult;
      readonly hasMore: boolean;
    }
  | {
      readonly type: 'Failed';
      readonly pageId: PageId;
      readonly query: ClientsQuery;
      readonly minQueryLength: number;
      readonly clients: ClientsSearchResult;
      readonly hasMore: boolean;
      readonly error: unknown;
    };

const defaultState = (): SearchClientsState => ({
  type: 'New',
  query: {
    search: '',
    flagFilter: 'all',
  },
  minQueryLength: 3,
  clients: EmptyClientsResult,
});

const PageLimit = 24;
const SortBy = 'user.fullname';

export const SearchClientsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        pageId: PageId;
        query: ClientsQuery;
        clients: ClientsSearchResult;
        hasMore: boolean;
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === result.pageId &&
        clientsQuery.equals(result.query, state.query)
      ) {
        // this is result for same query
        return {
          type: 'Loaded',
          pageId: result.pageId,
          query: result.query,
          clients: result.clients,
          hasMore: result.hasMore,
          minQueryLength: state.minQueryLength,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    trySetFailed: (
      state,
      payload: {
        pageId: PageId;
        query: ClientsQuery;
        error: unknown;
      },
    ) => {
      if (
        state.type === 'Loading' &&
        state.pageId === payload.pageId &&
        clientsQuery.equals(payload.query, state.query)
      ) {
        // this is result for same query
        return {
          type: 'Failed',
          pageId: payload.pageId,
          query: payload.query,
          clients: state.clients,
          hasMore: false, // state.hasMore, // FIXME:
          minQueryLength: state.minQueryLength,
          error: payload.error,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    tryResetFailed: (state) => {
      if (state.type === 'Failed') {
        return {
          type: 'Loaded',
          pageId: state.pageId,
          query: state.query,
          clients: state.clients,
          hasMore: state.hasMore,
          minQueryLength: state.minQueryLength,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    mutate: (s, fn: (s: SearchClientsState) => SearchClientsState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    log.debug('Init SearchClientsContext methods');
    type SearchFn = (pageId: PageId, query: ClientsQuery, minQueryLength: number) => Promise<void>;

    const search: SearchFn = async (pageId, query, minQueryLength: number): Promise<void> => {
      try {
        const cleanQuery = query.search.trim();
        const unrestrictedResults = cleanQuery.length >= minQueryLength;

        log.debug('search clients for page', pageId, 'query', query);

        const countQuery = {
          pageId: pageId,
          search: unrestrictedResults ? query.search : '', // hide matches count
          ...ClientFlagFilter2Flag[query.flagFilter],
        };

        const [savedClients, clientsCount] = await Promise.all([
          unrestrictedResults
            ? meroApi.clients
                .search({
                  ...countQuery,
                  limit: PageLimit,
                  sortBy: SortBy,
                  autocomplete: true,
                })
                .catch(logCatch('clients.search'))
            : Promise.resolve([]),
          meroApi.clients.count(countQuery).catch((e) => {
            log.error(`Failed to count clients for query ${JSON.stringify(countQuery)}`, e);
            return undefined;
          }),
        ]);

        const hasMore = savedClients.length >= PageLimit;
        log.debug(
          'found',
          savedClients.length,
          '/',
          clientsCount,
          'clients for page',
          pageId,
          'query',
          query,
          'hasMore',
          hasMore,
        );

        const clients = savedClients.map(clientPreview.fromClient);

        const clientsResult: ClientsSearchResult =
          clients.length > 0 || (clientsCount ?? 0) > 0
            ? {
                type: 'ClientsResult',
                clients: clients,
                clientsCount: clientsCount,
              }
            : cleanQuery.length === 0 && query.flagFilter === 'all'
            ? { type: 'NoClientsExists' }
            : { type: 'NoClientsFound' };

        dispatch.trySetResult({
          pageId,
          query,
          clients: clientsResult,
          hasMore,
        });
      } catch (e) {
        log.exception(e);
        dispatch.trySetFailed({
          pageId,
          query,
          error: e,
        });
      }
    };

    const debounceSearch = debounce(250, search);

    return {
      init: (payload: { pageId: PageId; minQueryLength: number }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            const query = { search: '', flagFilter: 'all' as const };

            debounceSearch(payload.pageId, query, payload.minQueryLength);

            return {
              type: 'Loading',
              pageId: payload.pageId,
              query: query,
              clients: state.clients,
              minQueryLength: payload.minQueryLength,
              hasMore: false,
            };
          } else {
            return state;
          }
        });
      },
      search: (payload: { pageId: PageId; query: ClientsQuery }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loading' || state.type === 'Loaded') {
            debounceSearch(payload.pageId, payload.query, state.minQueryLength);

            return {
              type: 'Loading',
              pageId: payload.pageId,
              query: payload.query,
              clients:
                state.clients.type === 'NoClientsFound'
                  ? {
                      type: 'ClientsResult',
                      clients: [],
                      clientsCount: 0,
                    }
                  : state.clients,
              minQueryLength: state.minQueryLength,
              hasMore: state.hasMore,
            };
          } else {
            return state;
          }
        });
      },
      loadMore: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' && state.clients.type === 'ClientsResult' && state.hasMore) {
            const { pageId, query, clients } = state;
            const loadMore = async (): Promise<void> => {
              try {
                log.debug('loading more clients for page', pageId, 'query', query, 'offset', clients.clients.length);
                const moreSavedClients = await meroApi.clients.search({
                  pageId: pageId,
                  search: query.search,
                  ...ClientFlagFilter2Flag[query.flagFilter],
                  offset: clients.clients.length,
                  limit: PageLimit,
                  sortBy: SortBy,
                  autocomplete: true,
                });
                log.debug(
                  'loaded',
                  moreSavedClients.length,
                  'more clients for page',
                  pageId,
                  'query',
                  query,
                  moreSavedClients.length,
                );
                const moreClients = moreSavedClients.map(clientPreview.fromClient);

                dispatch.trySetResult({
                  pageId,
                  query,
                  clients: {
                    type: 'ClientsResult',
                    clients: clients.clients.concat(moreClients),
                    clientsCount: clients.clientsCount, // no total recount while loading more results
                  },
                  hasMore: moreClients.length >= PageLimit,
                });
              } catch (e) {
                log.exception(e);
                dispatch.trySetFailed({
                  pageId,
                  query,
                  error: e,
                });
              }
            };

            loadMore();

            return {
              type: 'Loading',
              pageId: state.pageId,
              query: state.query,
              clients: state.clients,
              minQueryLength: state.minQueryLength,
              hasMore: state.hasMore,
            };
          } else {
            return state;
          }
        });
      },
      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            debounceSearch(state.pageId, state.query, state.minQueryLength);

            return {
              type: 'Loading',
              pageId: state.pageId,
              query: state.query,
              clients: state.clients,
              minQueryLength: state.minQueryLength,
              hasMore: state.hasMore,
            };
          }

          return state;
        });
      },
      tryResetFailed: dispatch.tryResetFailed,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [authState] = AuthContext.useContext();
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { init }] = SearchClientsContext.useContext();

  const page = currentBusinessState.type === 'Loaded' ? currentBusinessState.page : undefined;
  const user = authState.type === 'Authorized' ? authState.user : undefined;

  React.useEffect(() => {
    if (page && user) {
      init({
        pageId: page.details._id,
        minQueryLength: page.permissions.clients.getSearchMinSymbols(),
      });
    }
  }, [init, page, user]);

  return <>{children}</>;
};

export const withSearchClientsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSearchClientsContextProvider(props: P) {
    return (
      <SearchClientsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </SearchClientsContext.Provider>
    );
  };
};

import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import PageBillingDetailsList from '../screens/Authorized/MenuScreen/screens/PageCheckoutSettingsScreen/PageBillingDetailsList';
import PageBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageCheckoutSettingsScreen/PageBillingDetailsScreen';
import PageCheckoutSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageCheckoutSettingsScreen/PageCheckoutSettingsScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../contexts/CheckoutSettingsContext';
import { CheckoutSettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<CheckoutSettingsStackParamList>();

function CheckoutSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('checkout');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="CheckoutSettings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CheckoutSettings"
        component={PageCheckoutSettingsScreen}
        options={{ title: t('checkoutSettings'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetailsList"
        component={PageBillingDetailsList}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetails"
        component={PageBillingDetailsScreen}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default pipe(CheckoutSettingsStackNavigator, withBillingDetailsContextProvider('Company'));

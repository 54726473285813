import {
  StockIncreaseReason,
  StockDecreaseReason,
  DefinedTrimedString,
  ProductId,
  InventoryId,
  ProductMeasure,
  StockDescription,
} from '@mero/api-sdk';
import {
  ModalOverlay,
  DismissKeyboard,
  ConfirmBox,
  H1,
  SmallBody,
  Body,
  styles,
  Spacer,
  Row,
  Column,
  colors,
  TypeSafeTextInput,
  HSpacer,
  Select,
  useToast,
} from '@mero/components';
import { ScaledNumber, NonNegative, Positive } from '@mero/shared-sdk';
import * as E from 'fp-ts/lib/Either';
import { flow } from 'fp-ts/lib/function';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, TextInput, TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import log, { logCatch } from '../../../utils/log';
import { roundToDecimals } from '../../../utils/number';
import { localeStringToNumber, replaceDecimalSeparator, scaledToString } from '../../../utils/scaled';
import { MinusIcon, PlusIcon } from '../CheckoutScreen/DiscountComponent';

type Props = {
  measure: ProductMeasure.Any;
  productId: ProductId;
  inventoryId: InventoryId;
  goBack: () => void;
};

const EmptyStockDescription: StockDescription = {
  stock: ScaledNumber.fromNumber(0, 2) as NonNegative<ScaledNumber>,
  measureUnit: 'buc' as StockDescription['measureUnit'],
  measureValue: ScaledNumber.fromNumber(1, 2) as Positive<ScaledNumber>,
  quantity: ScaledNumber.fromNumber(0, 2) as NonNegative<ScaledNumber>,
} satisfies StockDescription;

const AdjustProductStockScreen: React.FC<Props> = ({ measure, inventoryId, productId, goBack }) => {
  const { t } = useTranslation('products');
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { updateStock }] = SelectedProductContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();

  const [tempAdjustment, setTempAdjustment] = useState('0');
  const [adjustment, setAdjustment] = useState(0);

  const [reason, setReason] = useState<string | undefined>();

  const [currentStock, setCurrentStock] = useState<StockDescription>(EmptyStockDescription);
  const [newTempValue, setNewTempValue] = useState('0');
  const [newValue, setNewValue] = useState(0);

  const [showErrors, setShowErrors] = useState(false);

  const [otherReason, setOtherReason] = React.useState({
    input: '',
    decoded: DefinedTrimedString.decode(''),
  });
  const isOtherReasonValid = (reason === 'Other' && E.isRight(otherReason.decoded)) || reason !== 'Other';

  const computedCurrentStock = React.useMemo(
    () => ScaledNumber.toNumber(ScaledNumber.div(currentStock.stock, measure.value, 2)),
    [currentStock, measure],
  );

  const computeRoundValue = (value: number) => {
    const multiplied = ScaledNumber.mul(ScaledNumber.fromNumber(value, 2), measure.value);
    const number = ScaledNumber.round(multiplied, measure.exponent) as NonNegative<ScaledNumber>;
    return number;
  };

  useEffect(() => {
    const init = async () => {
      if (pageState.type !== 'Loaded') {
        return;
      }

      try {
        const stock = await meroApi.pro.inventories.getProductInventoryStock({
          productId: productId,
          inventoryId: inventoryId,
          pageId: pageState.page.details._id,
        });

        setCurrentStock(stock);

        const computedStock = ScaledNumber.toNumber(ScaledNumber.div(stock.stock, measure.value, 2));

        setNewTempValue(computedStock.toLocaleString());
        setNewValue(computedStock);
      } catch (e) {
        log.info('Failed to fetch product stock', e);
      }
    };
    init();
  }, [pageState.type]);

  const adjustStock = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    if (newValue < 0 || !reason || !isOtherReasonValid) {
      return setShowErrors(true);
    }

    try {
      if (adjustment < 0) {
        const finalReason =
          reason === 'Other'
            ? ({ type: 'Other', value: otherReason.input } as StockDecreaseReason.Other)
            : (reason as StockDecreaseReason.All);

        const newStock = computeRoundValue(newValue);

        await meroApi.pro.inventories
          .updateProductStockValue({
            pageId: pageState.page.details._id,
            productId: productId,
            inventoryId: inventoryId,
            oldStockValue: currentStock.stock,
            newStockValue: newStock,
            type: 'StockDecrease',
            adjustmentValue: ScaledNumber.fromNumber(
              adjustment * -1 * ScaledNumber.toNumber(measure.value),
              measure.exponent,
            ) as Positive<ScaledNumber>,
            reason: finalReason,
          })
          .catch(logCatch('updateProductStockValue - StockDecrease'));
      } else {
        const finalReason =
          reason === 'Other'
            ? ({ type: 'Other', value: otherReason.input } as StockIncreaseReason.Other)
            : (reason as StockIncreaseReason.All);

        const newStock = computeRoundValue(newValue);

        await meroApi.pro.inventories
          .updateProductStockValue({
            pageId: pageState.page.details._id,
            productId: productId,
            inventoryId: inventoryId,
            oldStockValue: currentStock.stock,
            newStockValue: newStock,
            type: 'StockIncrease',
            adjustmentValue: ScaledNumber.fromNumber(
              adjustment * ScaledNumber.toNumber(measure.value),
              measure.exponent,
            ) as Positive<ScaledNumber>,
            reason: finalReason,
          })
          .catch(logCatch('updateProductStockValue - StockIncrease'));
      }

      updateStock(newValue);
      toast.show({
        type: 'success',
        text: t('stockUpdateSuccess'),
      });
      reload();
      goBack();
    } catch (error) {
      log.info('Failed adjusting the product stock value', error);
    }
  };

  const updateNewValue = (value: string = newTempValue) => {
    const parsedValue = roundToDecimals(localeStringToNumber(value));

    setNewValue(parsedValue);
    setNewTempValue(parsedValue.toLocaleString());

    const computedValue = roundToDecimals(parsedValue - computedCurrentStock);
    setAdjustment(computedValue);
    setTempAdjustment(computedValue.toLocaleString());
  };

  const decrementAdjustment = () => {
    setAdjustment((prev) => roundToDecimals(prev - 1));
    setTempAdjustment(roundToDecimals(adjustment - 1).toLocaleString());

    const computedValue = roundToDecimals(computedCurrentStock + (adjustment - 1));
    setNewValue(computedValue);
    setNewTempValue(computedValue.toLocaleString());
  };

  const incrementAdjustment = () => {
    setAdjustment((prev) => roundToDecimals(prev + 1));
    setTempAdjustment(roundToDecimals(adjustment + 1).toLocaleString());

    const computedValue = roundToDecimals(computedCurrentStock + adjustment + 1);
    setNewValue(computedValue);
    setNewTempValue(computedValue.toLocaleString());
  };

  const updateAdjustment = (value: string) => {
    const parsedValue = roundToDecimals(localeStringToNumber(value));

    setTempAdjustment(parsedValue.toLocaleString());
    setAdjustment(parsedValue);

    const computedValue = roundToDecimals(computedCurrentStock + parsedValue);
    setNewValue(computedValue);
    setNewTempValue(computedValue.toLocaleString());
  };

  const numberValidator = (prev: number | string) => (next: string) => {
    const parsed = localeStringToNumber(next);
    return (isNaN(parsed) ? prev : parsed).toLocaleString();
  };

  const reasonItems = useMemo(() => {
    if (adjustment < 0) {
      return StockDecreaseReason.All.list().map((reason) => ({
        label: t(reason),
        value: reason,
      }));
    } else {
      return StockIncreaseReason.All.list().map((reason) => ({
        label: t(reason),
        value: reason,
      }));
    }
  }, [adjustment]);

  const addRightAction = {
    text: t('save'),
    onPress: newValue < 0 || newValue === computedCurrentStock ? undefined : adjustStock,
    flex: 15,
  };

  const cancelLeftAction = {
    text: t('cancelButton'),
    onPress: goBack,
    flex: 10,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={t('adjustStock')}
              canClose={true}
              onClose={goBack}
              leftAction={cancelLeftAction}
              rightAction={addRightAction}
              style={{
                width: 375,
              }}
            >
              <H1>{t('adjustStock')}</H1>
              <Spacer size={8} />
              <Body style={styles.text.semibold}>
                {t('currentStock')}:{' '}
                <Body>
                  {computedCurrentStock.toLocaleString()} buc.{' '}
                  <Body style={{ color: colors.COMET }}>
                    {t('totalQuantity', { quantity: scaledToString(currentStock.stock), unit: measure.unit })}
                  </Body>
                </Body>
              </Body>
              <Spacer size={24} />

              <Row>
                <Column style={{ flex: 1 }}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('adjustment')}</SmallBody>
                  <Spacer size={8} />
                  <Row
                    style={{
                      padding: 8,
                      borderWidth: 1,
                      borderRadius: 4,
                      borderColor: colors.GEYSER,
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={decrementAdjustment}
                    >
                      <MinusIcon />
                    </TouchableOpacity>
                    <Column style={{ flex: 1, paddingHorizontal: 4 }}>
                      <TextInput
                        style={{ flex: 1, textAlign: 'center', fontSize: 16, lineHeight: 22, fontFamily: 'open-sans' }}
                        value={tempAdjustment}
                        keyboardType={'numeric'}
                        onChangeText={setTempAdjustment}
                        onBlur={flow(() => tempAdjustment, numberValidator(adjustment), updateAdjustment)}
                        editable={true}
                      />
                    </Column>
                    <TouchableOpacity
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        backgroundColor: colors.SKY_BLUE,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={incrementAdjustment}
                    >
                      <PlusIcon />
                    </TouchableOpacity>
                  </Row>
                  <Spacer size={8} />
                  <SmallBody style={{ color: colors.COMET }}>
                    {t('adjustmentQuantity', {
                      quantity: scaledToString(computeRoundValue(adjustment)),
                      unit: measure.unit,
                    })}
                  </SmallBody>
                </Column>

                <HSpacer left={41} />
                <Column style={{ flex: 1 }}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('newValue')}</SmallBody>
                  <Spacer size={8} />
                  <Column
                    style={{
                      flex: 1,
                      padding: 8,
                      borderWidth: 1,
                      borderRadius: 4,
                      borderColor: colors.GEYSER,
                      justifyContent: 'center',
                      height: 42,
                    }}
                  >
                    <TextInput
                      style={{ flex: 1, fontSize: 16, lineHeight: 22, fontFamily: 'open-sans', paddingHorizontal: 6 }}
                      value={newTempValue}
                      onBlur={flow(() => newTempValue, numberValidator(newValue), updateNewValue)}
                      onChangeText={setNewTempValue}
                      keyboardType={'numeric'}
                      editable={true}
                    />
                  </Column>
                  <Spacer size={8} />
                  <SmallBody style={{ color: colors.COMET }}>
                    {t('newStockQuantity', {
                      quantity: scaledToString(computeRoundValue(newValue)),
                      unit: measure.unit,
                    })}
                  </SmallBody>
                </Column>
              </Row>

              {newValue < 0 && (
                <>
                  <Spacer size={8} />
                  <Body style={{ color: colors.RADICAL_RED }}>{t('stockMustBeGreaterThanZero')}</Body>
                </>
              )}

              <Spacer size={18} />
              <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('reason')}</SmallBody>

              <Spacer size={8} />
              <Select
                placeholder={t('chooseReason')}
                items={reasonItems}
                value={reason}
                onChange={(v) => setReason(v)}
                isError={showErrors && !reason}
              />

              {reason === 'Other' && (
                <Column>
                  <Spacer />
                  <SmallBody
                    style={[
                      { fontFamily: 'open-sans-semibold' },
                      showErrors && !isOtherReasonValid && { color: colors.RADICAL_RED },
                    ]}
                  >
                    {t('otherReason')}
                  </SmallBody>
                  <Spacer size={8} />
                  <TypeSafeTextInput
                    value={otherReason.input}
                    codec={DefinedTrimedString}
                    onChange={setOtherReason}
                    editable={true}
                    showError={showErrors && !isOtherReasonValid}
                    placeholder={t('otherReasonPlaceholder')}
                  />
                  {showErrors && !isOtherReasonValid && (
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('requiredOtherText')}</SmallBody>
                  )}
                </Column>
              )}
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};
export default AdjustProductStockScreen;

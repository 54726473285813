import * as React from 'react';
import Svg, { Rect, Circle, Path, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CircleClose: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(10.425 10.427)">
      <Rect id="bg" width={size} height={size} transform="translate(-10.425 -10.427)" fill="none" />
      <Circle
        cx="8.485"
        cy="8.485"
        r="8.485"
        transform="translate(-6.911 -6.912)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <Path
        d="M27.222,21,21,27.223m6.223,0L21,21"
        transform="translate(-22.536 -22.538)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </G>
  </Svg>
);

export default React.memo(CircleClose);

import {
  Avatar,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { styles } from './ProCalendarSettingsScreen.styles';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewCalendar'>;

const AddProCalendarSettingsScreen: React.FC<Props> = () => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const [{ details, calendar, role }, { updateCalendar }] = AddNewProContext.useContext();

  const scrollRef = React.useRef<ScrollView>(null);

  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  const [isActive, setIsActive] = React.useState(calendar.isActive);
  const [isPrivate, setIsPrivate] = React.useState(calendar.isPrivate);
  const [calendarInterval, setCalendarInterval] = React.useState(calendar.calendarInterval);
  const [maxAppointmentsPerClient, setMaxAppointmentsPerClient] = React.useState(calendar.maxAppointmentsPerClient);
  const [minHoursBeforeClientsCanCancelAppointments, setMinHoursBeforeClientsCanCancelAppointments] = React.useState(
    calendar.minHoursBeforeClientsCanCancelAppointments,
  );
  const [minHoursBeforeTodayAppointments, setMinHoursBeforeTodayAppointments] = React.useState(
    calendar.minHoursBeforeTodayAppointments,
  );
  const [maxWaitingListDaysPerClient, setMaxWaitingListDaysPerClient] = React.useState(
    calendar.maxWaitingListDaysPerClient,
  );

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const toggleIsActive = () => {
    setIsActive(!isActive);
  };

  const toggleIsPrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const saveChanges = async () => {
    updateCalendar({
      isActive,
      isPrivate,
      calendarInterval,
      maxAppointmentsPerClient,
      minHoursBeforeClientsCanCancelAppointments,
      minHoursBeforeTodayAppointments,
      maxWaitingListDaysPerClient,
    });
    goBack();
  };

  const calendarAvailabilities = React.useMemo(
    () =>
      new Array(49).fill(2).map((v, i) => ({
        value: v + i,
        label: t('calendarAvailabilityWeek', { count: v + i }),
      })),
    [],
  );

  const waitingListDaysPerClient = React.useMemo(
    () => [
      ...new Array(10).fill(0).map((v, i) => ({
        value: i + 1,
        label: i === 0 ? t('waitingListDayPerClientLabel') : t('waitingListDaysPerClientLabel', { count: i + 1 }),
      })),
    ],
    [],
  );

  const appointmentsPerClient = React.useMemo(
    () =>
      new Array(30).fill(1).map((v, i) => ({
        value: v + i,
        label: t('appointmentPerClientLabel', { count: v + i }),
      })),
    [],
  );

  const cancelHours = React.useMemo(
    () =>
      new Array(24).fill(1).map((v, i) => ({
        value: v + i,
        label: t('cancelHourLabel', { count: v + i }),
      })),
    [],
  );

  const hoursBeforeAppointment = React.useMemo(
    () => [
      ...new Array(25).fill(0).map((v, i) => ({
        value: v + i,
        label: t('beforeAppointmentLabel', { count: v + i }),
      })),
      {
        value: 48,
        label: t('beforeAppointmentLabel', { count: 48 }),
      },
    ],
    [],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('calendarSettings')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar size={32} source={details.profilePhoto} firstname={details.firstname} lastname={details.lastname} />
          </Column>
        )}
      />
      <ScrollView ref={scrollRef}>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('calendarSettings')}</H1>
          <Spacer size={24} />

          <FormCard rounded>
            <Row style={{ paddingTop: 8 }}>
              <Column>
                <Checkbox value={isActive} color="blue" disabled={role?.isWorkerRole} onValueChange={toggleIsActive} />
              </Column>
              <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={toggleIsActive} disabled>
                <Title>{t('isActiveTitle')}</Title>
                <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{t('isActiveDescription')}</SmallBody>
              </TouchableOpacity>
            </Row>
            {isActive && (
              <>
                <Spacer size={12} />
                <Row style={{ paddingTop: 8 }}>
                  <Column>
                    <Checkbox value={isPrivate} color="blue" disabled={false} onValueChange={toggleIsPrivate} />
                  </Column>
                  <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={toggleIsPrivate}>
                    <Title>{t('isPrivateTitle')}</Title>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{t('isPrivateDescription')}</SmallBody>
                  </TouchableOpacity>
                </Row>
              </>
            )}
          </FormCard>
          {isActive && (
            <>
              <Spacer size={16} />
              <FormCard paddings="none" rounded>
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel label={t('calendarIntervalTitle')}>
                    <Select
                      items={calendarAvailabilities}
                      value={calendarInterval}
                      onChange={(v) => setCalendarInterval(v)}
                    />
                  </InputWithLabel>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                    {t('calendarIntervalDescription')}
                  </SmallBody>
                </Column>
                <Spacer size={24} />
                <Line />
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel label={t('maxAppointmentsPerClientTitle')}>
                    <Select
                      items={appointmentsPerClient}
                      value={maxAppointmentsPerClient}
                      onChange={(v) => setMaxAppointmentsPerClient(v)}
                    />
                  </InputWithLabel>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                    {t('maxAppointmentsPerClientDescription')}
                  </SmallBody>
                </Column>
                <Spacer size={24} />
                <Line />
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel label={t('minHoursBeforeClientsCanCancelAppointmentsTitle')}>
                    <Select
                      items={cancelHours}
                      value={minHoursBeforeClientsCanCancelAppointments}
                      onChange={(v) => setMinHoursBeforeClientsCanCancelAppointments(v)}
                    />
                  </InputWithLabel>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                    {t('minHoursBeforeClientsCanCancelAppointmentsDescription')}
                  </SmallBody>
                </Column>
                <Spacer size={24} />
                <Line />
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel label={t('minHoursBeforeTodayAppointmentsTitle')}>
                    <Select
                      items={hoursBeforeAppointment}
                      value={minHoursBeforeTodayAppointments}
                      onChange={(v) => setMinHoursBeforeTodayAppointments(v)}
                    />
                  </InputWithLabel>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                    {t('minHoursBeforeTodayAppointmentsDescription')}
                  </SmallBody>
                </Column>
                <Spacer size={24} />
                <Line />
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 16 }}>
                  <InputWithLabel label={t('maxWaitingListDaysPerClientTitle')}>
                    <Select
                      items={waitingListDaysPerClient}
                      value={maxWaitingListDaysPerClient}
                      onChange={(v) => setMaxWaitingListDaysPerClient(v)}
                    />
                  </InputWithLabel>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                    {t('maxWaitingListDaysPerClientDescription')}
                  </SmallBody>
                </Column>
                <Spacer size={24} />
              </FormCard>
            </>
          )}
        </Column>
        <Spacer size="16" />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('done')} onClick={saveChanges} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text={t('done')} onClick={saveChanges} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProCalendarSettingsScreen;

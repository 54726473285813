import * as React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

import NoContentView from '../NoContentView';
import HeaderView from './HeaderView';

export type ApiUnavailableViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly onGoBack: () => void;
  readonly onClose: () => void;
};

const ApiUnavailableView: React.FC<ApiUnavailableViewProps> = ({
  style,
  onGoBack,
  onClose,
}: ApiUnavailableViewProps) => {
  return (
    <>
      <HeaderView onClose={onClose} />
      <NoContentView
        title="API Contacte indisponibil"
        subtitle="API-ul pentru accesarea agendei nu este disponibil pe acest dispozitiv, ne pare rău"
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>({ flex: 1 }, style)}
        actions={[
          {
            type: 'primary',
            title: 'Înapoi',
            onPress: onGoBack,
          },
        ]}
        actionsContainerStyle={{ width: 224 }}
      />
    </>
  );
};

export default ApiUnavailableView;

import { DefinedString } from '@mero/api-sdk';
import { ClientHistoryRecordId, ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientHistoryNoteCreateState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Creating';
      readonly clientId: ClientId;
      readonly text: DefinedString;
    }
  | {
      readonly type: 'Created';
      readonly clientId: ClientId;
      readonly noteId: ClientHistoryRecordId;
      readonly text: DefinedString;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientHistoryNoteCreateState => ({
  type: 'Ready',
});

export const ClientHistoryNoteCreateContext = createModelContext(
  defaultState(),
  {
    trySetCreated: (_, params: { clientId: ClientId; noteId: ClientHistoryRecordId; text: DefinedString }) => ({
      type: 'Created',
      clientId: params.clientId,
      noteId: params.noteId,
      text: params.text,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Created' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientHistoryNoteCreateState) => ClientHistoryNoteCreateState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      createClientNote: (params: { clientId: ClientId; text: DefinedString }) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const crateNote = async () => {
              try {
                const { _id } = await meroApi.clients.createClientHistoryNote({
                  clientId: params.clientId,
                  note: params.text,
                });

                dispatch.trySetCreated({
                  clientId: params.clientId,
                  noteId: _id,
                  text: params.text,
                });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            crateNote().catch(log.exception);

            return {
              type: 'Creating',
              clientId: params.clientId,
              text: params.text,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientHistoryNoteCreateContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientHistoryNoteCreateContext.Provider>{children}</ClientHistoryNoteCreateContext.Provider>;
};

export const withClientHistoryNoteCreateContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientHistoryNoteCreateContextProvider(props: P) {
    return (
      <ClientHistoryNoteCreateContextProvider>
        <Content {...props} />
      </ClientHistoryNoteCreateContextProvider>
    );
  };
};

import { BoostClient } from '@mero/api-sdk/dist/clients';
import { Avatar, Body, Button, colors, Column, Icon, Label, Row, SmallBody, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { AuthorizedStackParamList } from '../../../../../types';
import ClaimDialog from './ClaimDialog';

const ClientCard: React.FC<{ client: BoostClient; withButtons: boolean; onUpdate: () => void }> = ({
  client,
  withButtons,
  onUpdate,
}) => {
  const { t } = useTranslation('marketing');

  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const [showClaimDialog, setShowClaimDialog] = React.useState(false);

  const toggleClaimDialog = () => {
    setShowClaimDialog(!showClaimDialog);
  };

  const updateClient = () => {
    onUpdate();
    setShowClaimDialog(false);
  };

  const canShowClientDetails = withButtons && client.client;

  return (
    <>
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        disabled={!canShowClientDetails}
        onPress={() => {
          if (client.client) {
            navigation.navigate('ClientDetails', {
              screen: 'DetailsScreen',
              params: {
                pageId: client.page._id,
                clientId: client.client._id,
              },
            });
          }
        }}
      >
        <Avatar
          firstname={client.user.firstname ?? ''}
          lastname={client.user.lastname ?? ''}
          source={client.user.photo?.thumbnail}
        />
        <Column style={{ paddingHorizontal: 16, flex: 1 }}>
          <Body style={{ fontFamily: 'open-sans-semibold' }}>
            {client.user.firstname} {client.user.lastname}
          </Body>
          <Spacer size={2} />
          {client.claim?.status.type === 'pending' ? (
            <SmallBody style={{ fontSize: 11, color: '#52577F' }}>{t('claimPending')}</SmallBody>
          ) : (
            <SmallBody style={{ color: '#52577F' }}>
              {client.hideBoostDetails ? t('dummyPhone') : client.user.phone}
            </SmallBody>
          )}
        </Column>
        {canShowClientDetails && <Icon type="arrow-right" color={colors.DARK_BLUE} />}
      </TouchableOpacity>
      {showClaimDialog && (
        <ClaimDialog onSuccess={updateClient} onCancel={toggleClaimDialog} userId={client.user._id} />
      )}
    </>
  );
};

export default ClientCard;

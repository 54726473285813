import { ApiError } from '@mero/api-sdk';
import { SavedService, ServiceGroupId } from '@mero/api-sdk/dist/services';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import {
  MeroHeader,
  colors,
  Column,
  Row,
  SmallBody,
  Line,
  Button,
  Title,
  useToast,
  Select,
  TextInput,
} from '@mero/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as E from 'fp-ts/Either';
import { IntFromString, NonEmptyString } from 'io-ts-types';
import { isNull } from 'lodash';
import * as React from 'react';
import { Keyboard, ScrollView, TouchableOpacity } from 'react-native';

import MenuItem from '../../../components/MenuItem';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import AvoidKeyboard from '@mero/components/lib/components/AvoidKeyboard';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Spacer from '@mero/components/lib/components/Spacer';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';
import { RADICAL_RED } from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import { AuthorizedStackParamList } from '../../../types';
import * as BitPacker from '../../../utils/bit-packer';
import log from '../../../utils/log';
import { promiseTimeout } from '../../../utils/promise';
import { getMinutes, DURATION_MIN } from '../../../utils/time';
import AddCategoryDialog from './AddCategoryDialog';
import ColorMenu, { SERVICE_COLORS } from './ColorMenu';
import DeleteServiceDialog from './DeleteServiceDialog';
import SelectDurationMenu from './SelectDurationMenu';
import { styles } from './ServiceScreen.styles';

export enum PriceType {
  hidden,
  fixed,
  range,
}

export const priceTypes: { label: string; value: 'fixed' | 'range' | 'hidden' }[] = [
  {
    label: 'Preț fix',
    value: 'fixed',
  },
  {
    label: 'Preț variabil',
    value: 'range',
  },
  {
    label: 'Preț ascuns',
    value: 'hidden',
  },
];

export type Props = StackScreenProps<AuthorizedStackParamList, 'Service'>;

export const mapColor = (color?: string) => {
  switch (color?.toUpperCase()) {
    case '#A5D6A7':
      return '#C9E6CA';
    case '#FFAB91':
      return '#FDCBBB';
    case '#FFF59D':
      return '#FEF9C4';
    case '#80CBC4':
      return '#B3E0DB';
    case '#90CAF9':
      return '#BCDFFB';
    case '#F48FB1':
      return '#F8BCD0';
    case '#CE93D8':
      return '#E1BDE7';
    default:
      return color;
  }
};

export const minutes = getMinutes(5, [1]);
export const hours = [...Array(24).keys()].map((i) => {
  const start = i;
  return {
    key: `hour-${i}`,
    value: i,
    label: start < 10 ? `0${start}h` : `${start}h`,
    type: 'string' as const,
  };
});

export const SERVICE_CHANGED = 'SERVICE_CHANGED';

const INPUT_POSITIONS = {
  serviceName: 50,
  category: 120,
  group: 220,
  description: 300,
  durationInMinutes: 570,
  fixedPrice: 500,
  discountPrice: 600,
  startPrice: 600,
  endPrice: 600,
} as const;

const ServiceScreen: React.FC<Props> = ({ navigation, route }) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const { serviceId, selectedGroupId } = route.params ?? {};

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();
  const [
    { serviceGroups, businessCategories, editInfo },
    { reload, getServiceGroups, changeEditInfo, changeActiveWorkers, resetEditInfo },
  ] = ServicesContext.useContext();

  const [selectedPros, setSelectedPros] = React.useState<WorkerId[]>([]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setSelectedPros(pageState.page.workers.filter((w) => editInfo.workers.includes(w._id)).map((w) => w._id));
    }
  }, [pageState]);

  const scrollRef = React.useRef<ScrollView>(null);
  const block = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [service, setService] = React.useState<SavedService | undefined>(undefined);

  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(false);

  const [showColorMenu, setShowColorMenu] = React.useState(false);
  const [showSelectDurationMenu, setShowSelectDurationMenu] = React.useState(false);

  const [serviceName, setServiceName] = React.useState({
    input: '',
    decoded: NonEmptyString.decode(''),
  });
  const serviceNameValid = E.isRight(serviceName.decoded);

  const getAvailableDays = (bitBucket: number) => {
    return Array(7)
      .fill(false)
      .map((_, dayOfWeek) => BitPacker.isFlagSet(bitBucket, 1 << dayOfWeek));
  };

  const [description, setDescription] = React.useState('');

  const arrayToBitBucket = (arr: boolean[]) =>
    arr.reduce((acc, v, dayOfWeek) => BitPacker[v ? 'setFlag' : 'unsetFlag'](acc, 1 << dayOfWeek), 0);

  const [fixedPrice, setFixedPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });

  const fixedPriceValid = E.isRight(fixedPrice.decoded);

  const [showDiscount, setShowDiscount] = React.useState<boolean>(false);

  const [discountPrice, setDiscountPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const discountPriceValid = E.isRight(discountPrice.decoded);

  const [startPrice, setStartPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const startPriceValid = E.isRight(startPrice.decoded);

  const [endPrice, setEndPrice] = React.useState({
    input: '',
    decoded: IntFromString.decode(''),
  });
  const endPriceValid = E.isRight(endPrice.decoded);

  const [priceType, setPriceType] = React.useState<keyof typeof PriceType>('fixed');

  const [durationInMinutes, setDurationInMinutes] = React.useState(30);

  const [durationHour, setDurationHour] = React.useState(0);
  const [durationMinutes, setDurationMinutes] = React.useState(30);

  const activeColor = mapColor();
  const [color, setColor] = React.useState(
    activeColor && SERVICE_COLORS.includes(activeColor) ? activeColor : SERVICE_COLORS[0],
  );

  const [showAddCategory, setShowAddCategory] = React.useState(false);

  const [showDeleteService, setShowDeleteService] = React.useState(false);

  const { hour, minute } = React.useMemo(() => {
    const minute = durationInMinutes % 60;
    const hour = (durationInMinutes - minute) / 60;

    return {
      hour,
      minute,
    };
  }, [durationInMinutes]);

  const scrollToPosition = (inputName: keyof typeof INPUT_POSITIONS): void => {
    scrollRef?.current?.scrollTo({ y: INPUT_POSITIONS[inputName], animated: true });
  };

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const checkIfFormValid = (): boolean => {
    if (!serviceNameValid) {
      scrollToPosition('serviceName');
      return false;
    }

    if (!category) {
      scrollToPosition('category');
      return false;
    }

    if (group === '1') {
      scrollToPosition('group');
      return false;
    }

    if (durationInMinutes < DURATION_MIN) {
      scrollToPosition('durationInMinutes');
      return false;
    }

    if (priceType === 'fixed' && showDiscount && (!fixedPriceValid || !discountPriceValid)) {
      scrollToPosition('discountPrice');
      return false;
    }

    if (priceType === 'fixed' && !fixedPriceValid) {
      scrollToPosition('fixedPrice');
      return false;
    }

    if (priceType === 'range' && (!startPriceValid || !endPriceValid)) {
      scrollToPosition('startPrice');
      return false;
    }

    return true;
  };

  const generatePrice = () => {
    if (priceType === 'fixed' && showDiscount) {
      return {
        price: {
          type: PriceType[priceType],
          fixed: +fixedPrice.input,
          promo: +discountPrice.input,
        },
      };
    } else if (priceType === 'fixed') {
      return {
        price: {
          type: PriceType[priceType],
          fixed: +fixedPrice.input,
        },
      };
    } else if (priceType === 'range') {
      return {
        price: {
          type: PriceType[priceType],
          range: {
            from: +startPrice.input,
            to: +endPrice.input,
          },
        },
      };
    }

    return {
      price: {
        type: PriceType[priceType],
      },
    };
  };

  const generatePayload = () => ({
    categoryId: category,
    name: serviceName.input,
    description,
    durationInMinutes,
    private: editInfo.private,
    availableDays: arrayToBitBucket(editInfo.availableDays),
    automaticApproval: editInfo.automaticApproval,
    groupIds: group ? [group] : undefined,
    color,
    ...generatePrice(),
  });

  const saveChanges = async () => {
    if (checkIfFormValid() && pageState.type === 'Loaded') {
      block.current = true;
      setIsLoading(true);

      try {
        if (service) {
          await meroApi.pages.updatePageService({
            pageId: pageState.page.details._id,
            //@ts-ignore
            serviceId: service._id,
            //@ts-ignore
            service: {
              ...service,
              ...generatePayload(),
            },
            workerIds: editInfo.workers,
          });

          // If there is only one worker on the page, page services and worker services will be kept in sync
          if (pageState.page.workers.length === 1) {
            // Find corresponding worker service
            const worker = pageState.page.workers.find((w) => w._id === pageState.page.workers[0]._id);
            const workerNewServices = worker?.services.map((s) => {
              if (s._id === serviceId) {
                return {
                  ...s,
                  isCustom: true,
                  ...generatePayload(),
                };
              } else {
                return {
                  ...s,
                  price: {
                    ...s.price,
                    type: PriceType[s.price.type],
                  },
                };
              }
            });

            if (workerNewServices && worker) {
              await meroApi.pages.updatePageWorkerServices({
                pageId: pageState.page.details._id,
                workerId: worker._id,
                //@ts-expect-error
                services: workerNewServices,
              });
            }
          }
          await promiseTimeout(700);
          await getPageWorkersAndServices(pageState.page.details._id);
        } else {
          await meroApi.pages.createPageService({
            pageId: pageState.page.details._id,
            //@ts-ignore
            service: {
              default: false,
              ...generatePayload(),
            },
            workerIds: editInfo.workers,
          });
          await promiseTimeout(700);
          await getPageWorkersAndServices(pageState.page.details._id);
        }
        await AsyncStorage.setItem(SERVICE_CHANGED, 'true');
        toast.show({ type: 'success', text: 'Serviciul a fost salvat cu success' });
        resetEditInfo();
        goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          toast.show({ type: 'error', text: error.payload.message });
        } else {
          toast.show({ type: 'error', text: 'Nu am reușit să salvez serviciul, încearcă din nou' });
        }
      } finally {
        block.current = false;
        setIsLoading(false);
      }
    } else {
      setShowErrors(true);
    }
  };

  const getPriceInput = () => {
    switch (priceType) {
      case 'fixed':
        return (
          <>
            <Column>
              <Row alignItems="center" style={{ paddingBottom: 8 }}>
                <Title style={{ flex: 1 }}>Valoare (lei)</Title>
                <TouchableOpacity onPress={() => setShowDiscount(!showDiscount)}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>Promoție</SmallBody>
                </TouchableOpacity>
              </Row>
              <TypeSafeTextInput
                codec={IntFromString}
                value={fixedPrice.input}
                showError={showErrors && !fixedPriceValid}
                onChange={setFixedPrice}
                keyboardType="numeric"
                placeholder="Introdu prețul"
                onFocus={() => scrollTo('fixedPrice')}
                // RightComponent={() => (
                //   <Column>
                //     <SmallBody>Lei</SmallBody>
                //   </Column>
                // )}
              />
              {showErrors && !fixedPriceValid && (
                <>
                  <Spacer size={2} />
                  <SmallBody style={{ color: RADICAL_RED }}>Trebuie să introduci preț pentru serviciu</SmallBody>
                </>
              )}
            </Column>
            {showDiscount && (
              <>
                <Spacer size={16} />
                <InputWithLabel
                  label="Preț promoțional (lei)"
                  isError={showErrors && !discountPriceValid}
                  errorText="Te rugăm să introduci preț pentru promoție"
                >
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={discountPrice.input}
                    showError={showErrors && !discountPriceValid}
                    onChange={setDiscountPrice}
                    keyboardType="numeric"
                    placeholder="Introdu prețul"
                    onFocus={() => scrollTo('discountPrice')}
                    // RightComponent={() => (
                    //   <Column>
                    //     <SmallBody>Lei</SmallBody>
                    //   </Column>
                    // )}
                  />
                </InputWithLabel>
              </>
            )}
          </>
        );
      case 'range':
        return (
          <>
            <InputWithLabel
              label="Valoare (lei)"
              isError={showErrors && (!startPriceValid || !endPriceValid)}
              errorText={startPriceValid ? 'Trebuie să introduci un preț maxim' : 'Trebuie să introduci un preț minim'}
            >
              <Row>
                <Column style={{ flex: 2, paddingRight: 4 }}>
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={startPrice.input}
                    showError={showErrors && !startPriceValid}
                    onChange={setStartPrice}
                    keyboardType="numeric"
                    placeholder="Prețul minim"
                    onFocus={() => scrollTo('startPrice')}
                  />
                </Column>
                <Column style={{ flex: 2, paddingLeft: 4 }}>
                  <TypeSafeTextInput
                    codec={IntFromString}
                    value={endPrice.input}
                    showError={showErrors && !endPriceValid}
                    onChange={setEndPrice}
                    keyboardType="numeric"
                    placeholder="Prețul maxim"
                    onFocus={() => scrollTo('endPrice')}
                    // RightComponent={() => (
                    //   <Column>
                    //     <SmallBody>Lei</SmallBody>
                    //   </Column>
                    // )}
                  />
                </Column>
              </Row>
            </InputWithLabel>
            <Spacer size={16} />
          </>
        );
      default:
        return null;
    }
  };

  const categories = React.useMemo(
    () => businessCategories.map((category) => ({ value: category._id, label: category.name })),
    [businessCategories],
  );

  const [category, setCategory] = React.useState(service?.categoryId);

  const cancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const addNewCategory = async (groupId: ServiceGroupId) => {
    if (pageState.type === 'Loaded') {
      await getServiceGroups(pageState.page.details._id);
      setGroup(groupId);
    }
    setShowAddCategory(false);
  };

  const cancelDeleteService = () => {
    setShowDeleteService(false);
  };

  const deleteService = () => {
    goBack();
    setShowDeleteService(false);
  };

  // const calculateDurationInMinutes = (type: 'hour' | 'minute') =>

  const groups = React.useMemo(
    () => [
      ...serviceGroups.map((group) => ({ value: group._id, label: group.name })),
      { value: '' as ServiceGroupId, label: 'Alte servicii' },
    ],
    [serviceGroups],
  );

  const [group, setGroup] = React.useState<ServiceGroupId | undefined>(
    //@ts-ignore
    service?.groupIds
      ? service?.groupIds[0]
      : serviceId
      ? ('' as ServiceGroupId)
      : (undefined as unknown as ServiceGroupId),
  );

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (service && pageState.type === 'Loaded') {
      const workers = pageState.page.workers
        //@ts-ignore
        .filter((w) => w.services.some((s) => s._id === service._id))
        .map((w) => w._id);

      changeEditInfo({
        workers,
        private: service.private,
        automaticApproval: service.automaticApproval,
        availableDays: getAvailableDays(service.availableDays),
      });
    }
  }, [service]);

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const loadService = (service: SavedService) => {
    setServiceName({
      input: service.name,
      decoded: NonEmptyString.decode(service.name),
    });
    setCategory(service.categoryId);
    setDescription(service.description);
    setFixedPrice({
      input: service.price.type === 'fixed' ? `${service.price.fixed}` : '',
      decoded: IntFromString.decode(service.price.type === 'fixed' ? `${service.price.fixed}` : ''),
    });
    setShowDiscount(Boolean(service.price.type === 'fixed' && service.price.promo));
    setDiscountPrice({
      input: service.price.type === 'fixed' && service.price.promo ? `${service.price.promo}` : '',
      decoded: IntFromString.decode(
        service.price.type === 'fixed' && service.price.promo ? `${service.price.promo}` : '',
      ),
    });
    setStartPrice({
      input: service.price.type === 'range' ? `${service.price.range.from}` : '',
      decoded: IntFromString.decode(service.price.type === 'range' ? `${service.price.range.from}` : ''),
    });
    setEndPrice({
      input: service.price.type === 'range' ? `${service.price.range.to}` : '',
      decoded: IntFromString.decode(service.price.type === 'range' ? `${service.price.range.to}` : ''),
    });
    setPriceType(service.price.type);
    setDurationHour(Math.floor(service.durationInMinutes / 60));
    setDurationMinutes(service.durationInMinutes % 60);
    setDurationInMinutes(service.durationInMinutes);

    const activeColor = mapColor(service.color);
    setColor(activeColor && SERVICE_COLORS.includes(activeColor) ? activeColor : SERVICE_COLORS[0]);
    setGroup(service.groupIds ? service.groupIds[0] : ('' as ServiceGroupId));

    setService(service);
  };

  React.useEffect(() => {
    setDurationInMinutes(durationHour * 60 + durationMinutes);
  }, [durationHour, durationMinutes]);

  React.useEffect(() => {
    // Add new service
    if (!serviceId && pageState.type === 'Loaded') {
      if (pageState.page.workers.length === 1) {
        changeActiveWorkers([pageState.page.workers[0]._id]);
      }
      if (selectedGroupId) {
        setGroup(selectedGroupId === 'other' ? ('' as ServiceGroupId) : selectedGroupId);
      }
    }
    // Edit existing service
    else if (serviceId && pageState.type === 'Loaded') {
      const service = pageState.page.details.services.find((s) => s._id === serviceId);
      if (service) {
        loadService(service);
      }
    }
  }, [serviceId, pageState.type]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={service?._id ? service.name : 'Adaugă serviciu'} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          ref={scrollRef}
          contentContainerStyle={styles.formMargin}
          onScrollEndDrag={dismissKeyboardCallback}
          scrollIndicatorInsets={{ right: 1 }} // This is here to fix scroll bar in the middle: https://github.com/facebook/react-native/issues/26610
        >
          <Spacer size="24" />
          <FormCard dropShaddow rounded paddings="inputs">
            <InputWithLabel
              label="Denumire serviciu / procedură"
              isError={showErrors && !serviceNameValid}
              errorText="Trebuie să introduci un nume pentru serviciu"
            >
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={serviceName.input}
                showError={showErrors}
                onChange={setServiceName}
                placeholder="Introdu numele serviciului"
                onFocus={() => scrollTo('serviceName')}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label="Tip serviciu"
              isError={showErrors && !category}
              errorText="Trebuie să selectezi tipul serviciului"
            >
              {categories.length > 0 && (
                <Select
                  placeholder={'Alege tipul serviciului'}
                  items={categories}
                  value={category}
                  onChange={(v) => setCategory(isNull(v) ? undefined : v)}
                />
              )}
            </InputWithLabel>
            <Spacer size="16" />
            <Column>
              <Row alignItems="center" style={{ paddingBottom: 8 }}>
                <Title style={{ flex: 1 }}>Categorie</Title>
                <TouchableOpacity onPress={() => setShowAddCategory(true)}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>
                    Adaugă categorie
                  </SmallBody>
                </TouchableOpacity>
              </Row>
              {groups.length > 0 && (
                <Select placeholder={'Alege categorie'} items={groups} value={group} onChange={setGroup} />
              )}
              {showErrors && group === '1' && (
                <>
                  <Spacer size={2} />
                  <SmallBody style={{ color: RADICAL_RED }}>Trebuie să selectezi categoria serviciului</SmallBody>
                </>
              )}
            </Column>
            <Spacer size="16" />
            <InputWithLabel label="Descriere">
              <TextInput
                value={description}
                onChange={setDescription}
                placeholder={'Descrierea ajută în luarea unei decizii și setarea corectă a așteptărilor clienților'}
                multiline
                numberOfLines={3}
                onFocus={() => scrollTo('description')}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label="Culoare în calendarul de programări">
              <TouchableOpacity
                style={{
                  height: 43,
                  justifyContent: 'center',
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 12,
                  borderColor: colors.GEYSER,
                }}
                onPress={() => setShowColorMenu(true)}
              >
                <Column style={{ backgroundColor: color, height: 15 }} />
              </TouchableOpacity>
            </InputWithLabel>
            <Spacer size="16" />
          </FormCard>
          <Spacer size="16" />
          <FormCard dropShaddow rounded paddings="inputs">
            <InputWithLabel label="Durată serviciu">
              <Row>
                <Column style={{ flex: 2, paddingRight: 4 }}>
                  <Select items={hours} value={durationHour} onChange={setDurationHour} />
                </Column>
                <Column style={{ flex: 2, paddingLeft: 4 }}>
                  <Select items={minutes} value={durationMinutes} onChange={setDurationMinutes} />
                </Column>
              </Row>
              {showErrors && durationInMinutes < DURATION_MIN && (
                <>
                  <Spacer size={2} />
                  <SmallBody style={{ color: RADICAL_RED }}>
                    Te rugăm să alegi o durată mai mare de sau egală cu 1 minute
                  </SmallBody>
                </>
              )}
            </InputWithLabel>
          </FormCard>
          <Spacer size="16" />
          <FormCard dropShaddow rounded paddings="inputs">
            <InputWithLabel label="Tip preț">
              <Select items={priceTypes} value={priceType} onChange={(v) => setPriceType(v)} />
            </InputWithLabel>
            <Spacer size="16" />
            {getPriceInput()}
          </FormCard>
          <Spacer size="16" />
          <FormCard dropShaddow rounded paddings="button">
            <MenuItem
              title="Profesioniști"
              subtitle={
                pageState.type === 'Loaded'
                  ? `${editInfo.workers.length} din ${pageState.page.workers.length} profesionisti`
                  : ''
              }
              subtitleColor={editInfo.workers.length ? undefined : colors.RADICAL_RED}
              icon="pro-menu-team"
              onPress={() =>
                navigation.navigate('SelectPro', {
                  serviceName: serviceName.input,
                  serviceId: serviceId ?? 'new',
                  //@ts-ignore
                  categoryName: categories.find((c) => c.value === category)?.label ?? '',
                })
              }
            />
            <Line />
            <MenuItem
              title="Setări programări"
              icon="pro-menu-calendar-settings"
              onPress={() => navigation.navigate('Settings', { serviceId: serviceId ?? 'new' })}
            />
          </FormCard>
          <Spacer size="16" />
          {!!service?._id && (
            <Column justifyContent="center" alignItems="center">
              <Button
                onClick={() => setShowDeleteService(true)}
                expand={false}
                backgroundColor={colors.ALABASTER}
                color={colors.RADICAL_RED}
                text="Șterge serviciu"
              />
            </Column>
          )}
          <Spacer size={144} />
        </ScrollView>
      </AvoidKeyboard>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading}
              text={service ? 'Salvează modificări' : 'Salvează'}
              onClick={saveChanges}
            />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={service ? 'Salvează modificări' : 'Salvează'}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>

      {showColorMenu && (
        <ColorMenu
          value={color}
          onSave={(color: string) => {
            setColor(color);
            setShowColorMenu(false);
          }}
          onDismiss={() => setShowColorMenu(false)}
        />
      )}
      {showSelectDurationMenu && (
        <SelectDurationMenu
          data={{
            hour,
            minute,
          }}
          onSave={(data: { hour: number; minute: number }) => {
            setDurationInMinutes(data.hour * 60 + data.minute);
            setShowSelectDurationMenu(false);
          }}
          onDismiss={() => setShowSelectDurationMenu(false)}
        />
      )}

      {showAddCategory ? <AddCategoryDialog onSuccess={addNewCategory} onCancel={cancelAddCategory} /> : null}

      {showDeleteService && service ? (
        <DeleteServiceDialog serviceId={service._id as any} onSuccess={deleteService} onCancel={cancelDeleteService} />
      ) : null}
    </ModalScreenContainer>
  );
};

export default ServiceScreen;

import * as React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

import NoContentView from '../NoContentView';
import HeaderView from './HeaderView';

export type PermissionUndeterminedViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly onAskPermissions?: () => void;
  readonly onClose: () => void;
};

const PermissionUndeterminedView: React.FC<PermissionUndeterminedViewProps> = ({
  style,
  onAskPermissions,
  onClose,
}: PermissionUndeterminedViewProps) => {
  return (
    <>
      <HeaderView onClose={onClose} />
      <NoContentView
        title="Permite accesul la contacte"
        subtitle="Pentru importul de contacte avem nevoie de permisiunea ta. Doar contactele selectate se vor importa."
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>({ flex: 1 }, style)}
        actions={[
          {
            type: 'primary',
            title: 'Permite accesul',
            onPress: onAskPermissions ?? ((): void => undefined),
          },
        ]}
      />
    </>
  );
};

export default PermissionUndeterminedView;

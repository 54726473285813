import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { MeroHeader, Spacer, H1, SafeAreaView } from '@mero/components';
import * as React from 'react';
import { FlatList } from 'react-native';

import ModalScreenContainer from '../ModalScreenContainer';
import WorkerListItem from '../WorkerListItem';
import { styles } from './styles';

export type Props = {
  /**
   * List of the workers available for given service
   */
  readonly workers: SavedWorker[];
  /**
   * Page back button pressed
   */
  readonly onBackPressed?: () => void;
  /**
   * Service performer selected
   */
  readonly onPerformerSelected?: (performer: SavedWorker) => void;
};

const SelectBookingPerformerScreen: React.FC<Props> = ({ workers, onBackPressed, onPerformerSelected }: Props) => {
  return (
    <ModalScreenContainer>
      <MeroHeader title="Alege specialist" canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <Spacer size="16" />
      <H1 style={styles.hrPadding}>Alege specialist</H1>
      <Spacer size="8" />
      <FlatList
        data={workers}
        style={styles.list}
        contentContainerStyle={styles.hrPadding}
        renderItem={({ item }) => {
          return (
            <WorkerListItem
              worker={item}
              onPress={() => {
                if (onPerformerSelected !== undefined) {
                  onPerformerSelected(item);
                }
              }}
              hasLine
            />
          );
        }}
        keyExtractor={(_, index) => `${index}`}
        ListFooterComponent={<SafeAreaView edges={['bottom']} />}
      />
    </ModalScreenContainer>
  );
};

export default SelectBookingPerformerScreen;

import {
  AvoidKeyboard,
  Body,
  Checkbox,
  colors,
  Column,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  Row,
  SimpleListItem,
  Spacer,
  TextInput,
  SmallBody,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

export type Props = Readonly<{
  canClose: boolean;
  onClose: () => void;
  leftCallback: () => void;
  rightCallback: (value: string) => void;
}>;
const ConfirmDeleteBooking: React.FC<Props> = ({ canClose, onClose, rightCallback, leftCallback }) => {
  const { t } = useTranslation('booking');
  const { width } = useWindowDimensions();

  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });
  const [otherReason, setOtherReason] = React.useState('');

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      check1: false,
      check2: false,
      check3: false,
      [checkName]: !checks[checkName],
    });
  };

  const canSave = React.useMemo(() => {
    if (checks.check3) {
      return otherReason.length >= 10;
    }

    return checks.check1 || checks.check2;
  }, [checks, otherReason]);

  const getReason = () => {
    if (checks.check1) {
      return t('cancelBookingReason1');
    }

    if (checks.check2) {
      return t('cancelBookingReason2');
    }

    if (checks.check3) {
      return otherReason;
    }

    return '';
  };

  const leftAction = {
    text: 'Închide',
    onPress: !canClose ? undefined : leftCallback,
    flex: 10,
  };

  const rightAction = {
    text: 'Anulează programare',
    onPress:
      !canClose || !canSave
        ? undefined
        : () => {
            const reason = getReason();
            rightCallback(reason);
          },
    flex: 15,
  };

  return (
    <Modal animationType="none" transparent={true} visible={true}>
      <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
        <AvoidKeyboard style={{ width: width > 500 ? 500 : width }}>
          <MobileWebModalWrapper position="center">
            <ConfirmBox
              type="error"
              icon="info"
              headerTitle="Actiune ireversibilă"
              canClose={canClose}
              onClose={onClose}
              leftAction={leftAction}
              rightAction={rightAction}
            >
              <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
                <H1>{t('cancelBookingTitle')}</H1>
                <Spacer size="8" />
                <Body>{t('cancelBookingMessage')}</Body>
                <Spacer size="16" />
                <TouchableOpacity onPress={toggleCheck('check1')} disabled={!canClose}>
                  <SimpleListItem
                    icon={checks.check1 ? 'checked' : 'unchecked'}
                    iconColor={colors.RADICAL_RED}
                    paddingTop={0}
                    paddingBottom={0}
                  >
                    <Body>{t('cancelBookingReason1')}</Body>
                  </SimpleListItem>
                </TouchableOpacity>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <TouchableOpacity onPress={toggleCheck('check2')} disabled={!canClose}>
                  <SimpleListItem
                    icon={checks.check2 ? 'checked' : 'unchecked'}
                    iconColor={colors.RADICAL_RED}
                    paddingTop={0}
                    paddingBottom={0}
                  >
                    <Body>{t('cancelBookingReason2')}</Body>
                  </SimpleListItem>
                </TouchableOpacity>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Row>
                  <Checkbox
                    onValueChange={toggleCheck('check3')}
                    value={checks.check3}
                    disabled={!canClose}
                    color="red"
                  />
                  <Column style={{ flex: 1, paddingLeft: 12 }}>
                    <TouchableOpacity onPress={toggleCheck('check3')} disabled={!canClose}>
                      <Body>{t('cancelBookingReason3')}</Body>
                    </TouchableOpacity>
                    {checks.check3 && (
                      <>
                        <Spacer size={8} />
                        <TextInput
                          placeholder={t('cancelBookingReason3Placeholder')}
                          numberOfLines={3}
                          multiline
                          value={otherReason}
                          onChange={setOtherReason}
                          editable={checks.check3}
                          isError={otherReason.length < 10 && otherReason.length > 0}
                        />
                        {otherReason.length < 10 && otherReason.length > 0 && (
                          <>
                            <Spacer size={2} />
                            <SmallBody style={{ color: colors.RADICAL_RED }}>
                              {t('cancelBookingReason3Error')}
                            </SmallBody>
                          </>
                        )}
                      </>
                    )}
                  </Column>
                </Row>
              </ScrollView>
            </ConfirmBox>
          </MobileWebModalWrapper>
        </AvoidKeyboard>
      </ModalOverlay>
    </Modal>
  );
};

export default ConfirmDeleteBooking;

import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentMadeByAnotherProNotification;
};

export const getDiff = (start: DateTime, end: DateTime): string => {
  const diffObj = start.diff(end, ['hours', 'minutes']).negate().toObject();
  return [
    diffObj.hours ? `${(diffObj.hours ?? 0).toString().padStart(2, '0')}h` : '',
    diffObj.hours || diffObj.minutes ? `${(diffObj.minutes ?? 0).toString().padStart(2, '0')}min` : '',
  ]
    .filter(Boolean)
    .join(' ');
};

const AppointmentMadeByAnotherProNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const startDate = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });
  const endDate = DateTime.fromJSDate(payload.appointment.end, { zone: payload.appointment.timezone });

  return (
    <View style={[styles.notificationContainer]}>
      <Icon type="notification_calendar" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>
            {payload.addedByUser.firstname} {payload.addedByUser.lastname} ({payload.addedByUser.phone})
          </Body>{' '}
          ți-a adăugat o programare{' '}
          {payload.client && (
            <>
              pentru{' '}
              <Body style={meroStyles.text.semibold}>
                {payload.client?.firstname} {payload.client?.lastname}
              </Body>
              ,{' '}
            </>
          )}
          <Body style={meroStyles.text.semibold}>{startDate.toFormat('EEEE, d LLLL yyyy', { locale: 'ro' })}</Body> la
          ora <Body style={meroStyles.text.semibold}>{startDate.toFormat('H:mm', { locale: 'ro' })}.</Body>
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname} {payload.worker.lastname} (
          {getDiff(startDate, endDate)})
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentMadeByAnotherProNotification;

import { colors, Column, Line, styles as meroStyle, Spacer } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, G, Path, Ellipse } from 'react-native-svg';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';

export const MoreIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6841">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2" />
      <G fill="#080de0" data-name="Group 5789" transform="translate(-313 -2158)">
        <Ellipse cx={2} cy={1.919} opacity={0.9} rx={2} ry={1.919} transform="translate(317 2168)" />
        <Ellipse cx={2} cy={1.919} data-name="Oval" opacity={0.9} rx={2} ry={1.919} transform="translate(330 2168)" />
        <Ellipse cx={2} cy={1.919} data-name="Oval" opacity={0.9} rx={2} ry={1.919} transform="translate(323.5 2168)" />
      </G>
    </G>
  </Svg>
);

type Props = {
  onEdit: () => void;
  onDelete: () => void;
  canDelete: boolean;
};

const BillingDetailItemMore: React.FC<Props> = ({ onEdit, onDelete, canDelete }) => {
  const { t } = useTranslation('subscription');

  const [isOpen, setIsOpen] = React.useState(false);

  const ref = React.useRef<View>(null);
  useClickOutsideWeb({
    ref,
    isVisible: isOpen,
    onClickOutside() {
      setIsOpen(false);
    },
  });

  return (
    <Column style={{ position: 'relative', zIndex: 4 }}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setIsOpen(!isOpen)}>
        <MoreIcon />
      </TouchableOpacity>
      {isOpen && (
        <View
          ref={ref}
          style={{
            position: 'absolute',
            top: 36,
            right: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 100,
          }}
        >
          <TouchableOpacity style={{ paddingVertical: 16 }} onPress={flow(onEdit, () => setIsOpen(false))}>
            <Body style={[meroStyle.text.semibold, { color: colors.DARK_BLUE }]}>{t('editBillingDetails')}</Body>
          </TouchableOpacity>
          {canDelete && (
            <>
              <Line />
              <TouchableOpacity style={{ paddingVertical: 16 }} onPress={flow(onDelete, () => setIsOpen(false))}>
                <Body style={[meroStyle.text.semibold, { color: colors.RADICAL_RED }]}>
                  {t('deleteBillingDetails')}
                </Body>
              </TouchableOpacity>
            </>
          )}
        </View>
      )}
    </Column>
  );
};

export default BillingDetailItemMore;

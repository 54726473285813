import { colors, Line, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './AddServiceMenu.styles';

export type Props = {
  onEditCategory: () => void;
  onDeleteCategory: () => void;
  onDismiss: () => void;
};

const CategoryMenu: React.FC<Props> = ({
  onEditCategory,
  onDeleteCategory,
  onDismiss,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            <TouchableOpacity
              style={styles.optionsMenuItem}
              delayPressIn={0}
              onPress={flow(onDeleteCategory, onDismiss)}
            >
              <Title style={{ color: colors.RADICAL_RED }}>Șterge categorie</Title>
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onEditCategory, onDismiss)}>
              <Title>Modifică nume categorie</Title>
            </TouchableOpacity>
            <Line />
          </SafeAreaView>
        </View>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default CategoryMenu;

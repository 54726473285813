import { OnlinePaymentsPayoutSettings, PageId } from '@mero/api-sdk';
import { PageMonthlyInvoiceArchive } from '@mero/api-sdk/dist/payments/monthly-invoice-archive';
import {
  Body,
  colors,
  Column,
  H1,
  H2s,
  H3s,
  Header,
  Icon,
  Line,
  Row,
  sizes,
  SmallBody,
  Button,
  Spacer,
  Switch,
  styles as meroStyles,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, TouchableOpacity, View, Image } from 'react-native';

import { FlashyLabel } from '../../../../../components/FlashyLabel';
import { LoadingComponent } from '../../../../../components/LoadingComponent';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';

import useGoBack from '../../../../../hooks/useGoBack';

import { images } from '../../../../../constants/images';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsContext } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { OnlinePaymentsNavigationProp } from '../../../../../types';
import log from '../../../../../utils/log';
import DisableOnlinePaymentDialog from './DisableOnlinePaymentDialog';
import PageOnlinePaymentsDestination from './PageOnlinePaymentsDestination';
import { styles } from './styles';

type Props = {
  navigation: OnlinePaymentsNavigationProp;
};

export const getPayoutFrequency = (schedule: OnlinePaymentsPayoutSettings['schedule'], t: TFunction) => {
  switch (schedule?.type) {
    case 'Manual':
    case 'Daily':
      return t(schedule.type);
    case 'Weekly':
      return schedule.day ? t(schedule.type, { day: t(schedule.day) }) : t('weeklyNoDay');
    case 'Monthly':
      return t(schedule.type, { day: schedule.day });
  }
};

const PageOnlinePaymentsSettingsScreen: React.FC<Props> = ({ navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('onlinePayments');
  const showError = useShowError();

  const [pageState, { reloadAsync: pageReloadAsync }] = CurrentBusinessContext.useContext();
  const stripeConnectionInProgress = React.useRef(false);
  const toast = useToast();
  const isFocused = useIsFocused();

  const [billingDetailsState] = BillingDetailsContext.useContext();
  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  const [onlinePaymentInvoices, setOnlinePaymentInvoices] = React.useState<PageMonthlyInvoiceArchive[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<'settings' | 'invoices'>('invoices');
  const [showDestinationModal, setShowDestinationModal] = React.useState(false);
  const [showDisabledToast, setShowDisabledToast] = React.useState(true);
  const [showDisableDialog, setShowDisableDialog] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [stripeCode, setStripeCode] = React.useState<string>();

  const billingDetails = React.useMemo(() => {
    return billingDetailsState.billingDetails.find((billingDetail) => billingDetail.usage?.includes('UserPayments'));
  }, [billingDetailsState.billingDetails]);

  const toggleDisableDialog = () => {
    setShowDisableDialog(!showDisableDialog);
  };

  const connectStripeWeb = async (code: string) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      setIsLoading(true);
      await meroApi.payments.connectStripeAccount({
        pageId: pageState.page.details._id,
        code,
      });

      await meroApi.pages.updateOnlinePaymentsStatus({ pageId: pageState.page.details._id, enabled: true });
      reloadAsync({ pageId: pageState.page.details._id });
      pageReloadAsync();

      toast.show({
        type: 'success',
        text: t('activationSuccess'),
      });
    } catch (error) {
      log.error('Failed to connect stripe', error);
      showError(error, t('connectStripeError'));
    } finally {
      setIsLoading(false);
    }
  };

  const connectStripeApp = async () => {
    if (pageState.type !== 'Loaded' || stripeConnectionInProgress.current) {
      return;
    }
    setIsLoading(true);
    try {
      const code = await AppStorage.getStripeCode();
      if (!code) {
        return;
      }
      stripeConnectionInProgress.current = true;
      await meroApi.payments.connectStripeAccount({
        pageId: pageState.page.details._id,
        code,
      });

      await meroApi.pages.updateOnlinePaymentsStatus({ pageId: pageState.page.details._id, enabled: true });
      reloadAsync({ pageId: pageState.page.details._id });
      pageReloadAsync();

      toast.show({
        type: 'success',
        text: t('activationSuccess'),
      });
      await AppStorage.deleteStripeCode();
    } catch (error) {
      log.error('Failed to connect stripe', error);
      showError(error, t('connectStripeError'));
    } finally {
      stripeConnectionInProgress.current = false;
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      if (Platform.OS === 'web') {
        const params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
          const code = params.get('code');
          if (code) {
            connectStripeWeb(code);
          }
        }
      } else {
        connectStripeApp();
      }
    }
  }, [pageState.type, stripeCode]);

  React.useEffect(() => {
    if (isFocused) {
      AppStorage.getStripeCode().then(setStripeCode);
    }
  }, [isFocused]);

  const navigateBillingDetails = React.useCallback(() => {
    navigation.navigate('BillingDetailsList');
  }, [navigation]);

  const navigateSchedule = React.useCallback(() => {
    navigation.navigate('PageOnlinePaymentsSchedule');
  }, [navigation]);

  const navigateAppointmentSetting = React.useCallback(() => {
    navigation.navigate('PageOnlinePaymentsAppointment');
  }, [navigation]);

  const navigateGiftcards = React.useCallback(() => {
    navigation.navigate('GiftCards');
  }, [navigation]);

  const downloadInvoice = (url: string) => async () => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      showError(error, t('downloadInvoiceError'));
    }
  };

  const getOnlinePaymentData = async (pageId: PageId) => {
    try {
      if (pageState.type !== 'Loaded') {
        return;
      }
      const invoices = await meroApi.payments.getPageMonthlyInvoiceArchives({ pageId });
      setOnlinePaymentInvoices(invoices.result);
    } catch (error) {
      showError(error, t('getInvoicesError'));
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getOnlinePaymentData(pageState.page.details._id);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (onlinePaymentsState.type === 'Loaded' && onlinePaymentsState.status.type === 'Disabled' && !isLoading) {
      navigation.navigate('Home', {
        screen: 'HomeTabs',
        params: {
          screen: 'ActivateOnlinePayments',
        },
      });
    }
  }, [onlinePaymentsState.status]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.WHITE }}>
        {isLoading && <LoadingComponent />}
        <Header
          LeftComponent={() => (
            <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
              <Icon type="back" />
            </TouchableOpacity>
          )}
          CenterComponent={() => <Title style={{ fontSize: 14 }}>{t('title')}</Title>}
        />
        <ScrollView>
          <Spacer size={23} />
          <H1 style={{ paddingHorizontal: 24 }}>{t('title')}</H1>

          <Spacer size={33} />
          <View style={{ paddingHorizontal: 16, gap: 16 }}>
            {onlinePaymentsState.status.type === 'Enabled' &&
              onlinePaymentsState.status.settings.appointmentPayments?.type === 'Off' &&
              onlinePaymentsState.status.settings.requireAdvancePayment.type === 'Off' &&
              onlinePaymentsState.status.settings.giftCards.perSalonEnabled === false &&
              showDisabledToast && (
                <Row
                  style={{
                    paddingVertical: 8,
                    paddingHorizontal: 16,
                    backgroundColor: colors.YELLOW_ORANGE,
                    borderRadius: 4,
                  }}
                >
                  <SmallBody style={{ color: colors.WHITE, flex: 1 }}>{t('inactiveSettings')}</SmallBody>
                  <TouchableOpacity style={{ paddingTop: 2 }} onPress={() => setShowDisabledToast(false)}>
                    <Icon type="close" width={18} height={18} size={18} color={colors.WHITE} />
                  </TouchableOpacity>
                </Row>
              )}
            <TouchableOpacity style={styles.borderContainer} onPress={navigateAppointmentSetting}>
              <Column style={{ flex: 1 }}>
                {onlinePaymentsState.status.type === 'Enabled' &&
                onlinePaymentsState.status.settings.appointmentPayments?.type === 'On' ? (
                  <FlashyLabel type="success" text={t('active')} />
                ) : (
                  <FlashyLabel type="info" text={t('inactive')} />
                )}
                <Spacer size={6} />
                <Title>{t('appointmentPayments')}</Title>
                <SmallBody>{t('appointmentPaymentsDesc')}</SmallBody>
              </Column>
              <Icon type="arrow-right" />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.borderContainer}
              onPress={() => navigation.navigate('PageOnlinePaymentsAdvancedPayment')}
            >
              <Column style={{ flex: 1 }}>
                {onlinePaymentsState.status.type === 'Enabled' &&
                onlinePaymentsState.status.settings.requireAdvancePayment.type === 'Off' ? (
                  <FlashyLabel type="info" text={t('inactive')} />
                ) : (
                  <FlashyLabel type="success" text={t('active')} />
                )}
                <Spacer size={6} />
                <Title>{t('requireAdvancePayment')}</Title>
                <SmallBody>{t('requireAdvancePaymentDesc')}</SmallBody>
              </Column>
              <Icon type="arrow-right" />
            </TouchableOpacity>

            <TouchableOpacity style={styles.borderContainer} onPress={navigateGiftcards}>
              <Column style={{ flex: 1 }}>
                {onlinePaymentsState.status.type === 'Enabled' &&
                onlinePaymentsState.status.settings.giftCards.perSalonEnabled ? (
                  <FlashyLabel type="success" text={t('active')} />
                ) : (
                  <FlashyLabel type="info" text={t('inactive')} />
                )}
                <Spacer size={6} />
                <Title>{t('giftCards')}</Title>
                <SmallBody>{t('giftCardsDesc')}</SmallBody>
              </Column>
              <Icon type="arrow-right" />
            </TouchableOpacity>
          </View>
          <Spacer size={32} />
          <Button
            onPress={toggleDisableDialog}
            backgroundColor={colors.WHITE}
            color={colors.RADICAL_RED}
            size="small"
            text={t('deactivateOnlinePayment')}
          />
          <Spacer size={48} />
          <Row style={{ paddingHorizontal: 16 }}>
            <Switch
              height={sizes[32]}
              textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
              buttons={[
                { value: 'invoices', label: t('invoices') },
                {
                  value: 'settings',
                  label: t('settings'),
                },
              ]}
              defaultValue="invoices"
              onChange={setSelectedTab}
            />
          </Row>

          <Column style={{ backgroundColor: colors.WHITE, paddingHorizontal: 24, paddingBottom: 55 }}>
            {selectedTab === 'invoices' &&
              onlinePaymentInvoices.length > 0 &&
              onlinePaymentInvoices.map((invoice, i) => (
                <React.Fragment key={invoice._id}>
                  <TouchableOpacity style={{ paddingVertical: 16, flexDirection: 'row', alignItems: 'center' }}>
                    <Column style={{ padding: 4 }}>
                      <Image source={images.invoice} style={{ width: 24, height: 24 }} />
                    </Column>
                    <Title style={{ paddingLeft: 16, flex: 1 }}>
                      {DateTime.fromObject({ year: invoice._tag.year, month: invoice._tag.month })
                        .setLocale('ro')
                        .toFormat('MMMM yyyy')}
                    </Title>
                    <Button
                      onClick={downloadInvoice(invoice.downloadUrl)}
                      backgroundColor={colors.WHITE}
                      color={colors.DARK_BLUE}
                      expand={false}
                      text={t('download')}
                    />
                  </TouchableOpacity>
                  {i !== onlinePaymentInvoices.length - 1 && <Line />}
                </React.Fragment>
              ))}

            {selectedTab === 'invoices' && onlinePaymentInvoices.length === 0 && (
              <>
                <Spacer size={62} />
                <H2s style={{ textAlign: 'center' }}>{t('noBills')}</H2s>
                <Body style={{ textAlign: 'center' }}>{t('noBillsText')}</Body>
              </>
            )}

            {selectedTab === 'settings' && (
              <Column style={{ gap: 16 }}>
                <H3s style={{ paddingTop: 24 }}>{t('settings')}</H3s>
                {billingDetails && billingDetails.billing.type === 'Company' && (
                  <TouchableOpacity style={styles.borderContainer} onPress={navigateBillingDetails}>
                    <Column style={{ flex: 1 }}>
                      <Title>{t('billingInfo')}</Title>
                      <SmallBody>{billingDetails.billing.name}</SmallBody>
                      <SmallBody style={{ color: colors.COMET }}>{billingDetails.billing.contact.email}</SmallBody>
                    </Column>
                    <Icon type="arrow-right" />
                  </TouchableOpacity>
                )}

                <Row style={styles.borderContainer} /*onPress={() => setShowDestinationModal(true)}*/>
                  <Column style={{ flex: 1 }}>
                    <Title>{t('bankAccount')}</Title>
                    {onlinePaymentsState.status?.type === 'Enabled' &&
                    onlinePaymentsState.status?.payout.destination ? (
                      <SmallBody style={{ color: colors.COMET }}>
                        ****{onlinePaymentsState.status.payout.destination.last4}
                      </SmallBody>
                    ) : (
                      <SmallBody style={{ color: colors.COMET }}>{t('addBankAccount')}</SmallBody>
                    )}
                  </Column>
                  {/*<Icon type="arrow-right" />*/}
                </Row>

                <TouchableOpacity style={styles.borderContainer} onPress={navigateSchedule}>
                  <Column style={{ flex: 1 }}>
                    <Title>{t('payoutFrequency')}</Title>
                    {onlinePaymentsState.status?.type === 'Enabled' && onlinePaymentsState.status?.payout.schedule && (
                      <SmallBody>{getPayoutFrequency(onlinePaymentsState.status.payout.schedule, t)}</SmallBody>
                    )}
                  </Column>
                  <Icon type="arrow-right" />
                </TouchableOpacity>
              </Column>
            )}
          </Column>
        </ScrollView>
      </ModalScreenContainer>

      {showDestinationModal && pageState.type === 'Loaded' && (
        <PageOnlinePaymentsDestination
          pageId={pageState.page.details._id}
          onClose={() => setShowDestinationModal(false)}
          reload={reloadAsync}
        />
      )}
      {showDisableDialog && (
        <DisableOnlinePaymentDialog onSuccess={toggleDisableDialog} onCancel={toggleDisableDialog} />
      )}
    </>
  );
};

export default PageOnlinePaymentsSettingsScreen;

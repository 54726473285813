import { EventType } from './types';

export const addEventListener = (
  event: EventType,
  callback: (response: { count?: number; visible: boolean }) => void,
): void => {
  // default, empty implementation
};

export const handlePushMessage = (): void => {
  // default, empty implementation
};

export const sendTokenToIntercom = async (deviceToken: string): Promise<void> => {
  // default, empty implementation
};

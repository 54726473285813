import { MergedService } from '@mero/api-sdk/dist/services';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';

/**
 * Optional pre-filter for services, ex: to filter out services already selected
 * @returns true to keep the service for selection
 */
type ServicesFilter = (w: SavedWorker, s: MergedService) => boolean;

type SelectServiceState =
  | {
      type: 'none';
      service: undefined;
      servicesFilter: ServicesFilter;
    }
  | {
      type: 'some';
      service: MergedService;
      servicesFilter: (w: SavedWorker, s: MergedService) => boolean;
    };

const defaultValue = (): SelectServiceState => ({
  type: 'none',
  service: undefined,
  servicesFilter: () => true,
});

export const SelectBookingServiceContext = createModelContext(
  defaultValue(),
  {
    reset: defaultValue,
    setSelectedService: (state, s: MergedService) => ({
      type: 'some',
      service: s,
      servicesFilter: state.servicesFilter,
    }),
    setServicesFilter: (state, servicesFilter: (w: SavedWorker, s: MergedService) => boolean) => ({
      ...state,
      servicesFilter,
    }),
  },
  (dispatch) => ({
    reset: dispatch.reset,
    setSelectedService: dispatch.setSelectedService,
    setServicesFilter: dispatch.setServicesFilter,
  }),
);

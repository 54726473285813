/* eslint-disable @typescript-eslint/naming-convention */
import ro from './ro';

const en: { [N in keyof typeof ro]: { [K in string]: string } } = {
  ...ro,
  clients: {
    importContacts: 'Import contacts',
    importNContactsAction: 'Import {{count}} contact',
    importNContactsAction_plural: 'Import {{count}} contacts',
    searchNameOrPhone: 'Search by name or phone number',
  },
};

export default en;

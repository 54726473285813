import { colors } from '@mero/components';
import { Platform } from 'expo-modules-core';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  appointmentInfoContainer: {
    // paddingHorizontal: 16,
    paddingBottom: 24,
    ...(Platform.OS !== 'ios' && { overflow: 'hidden' }),
    borderRadius: 4,
    backgroundColor: colors.WHITE,
    marginTop: 16,
    marginBottom: 12,
    marginHorizontal: 16,

    shadowColor: '#000',

    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,

    elevation: 3,
  },
  appointmentInfoCard: {
    paddingHorizontal: 16,
    backgroundColor: colors.WHITE,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-around',
    backgroundColor: colors.WHITE,
  },
  infoContainer: {
    flex: 1,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: colors.COMET,
  },
  giftCard: {
    alignItems: 'center',
    paddingTop: 16,
  },
  giftCardText: {
    paddingLeft: 8,
    flex: 1,
  },
  discount: {
    alignItems: 'center',
    paddingTop: 16,
  },
  discountText: {
    paddingLeft: 8,
    flex: 1,
  },
});

import { parseStrictPhoneNumber } from '@mero/api-sdk';
import { Firstname, Lastname, PhoneNumber } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as Contacts from 'expo-contacts';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { debounce } from 'throttle-debounce';
import * as unorm from 'unorm';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

const normalize = (s: string): string =>
  unorm
    .nfkd(s) // String.normalize('NFKD') crashes on Android
    .replace(/[^\w\s]/g, '')
    .trim()
    .toLowerCase();

export const BasicContact = t.intersection(
  [
    t.type({
      phone: PhoneNumber,
      searchText: t.string,
    }),
    t.partial({
      firstname: Firstname,
      lastname: Lastname,
      name: t.string,
      imageUrl: t.string,
    }),
  ],
  'BasicContact',
);

export type BasicContact = t.TypeOf<typeof BasicContact>;

export type ImportContactsState =
  | {
      /**
       * Initial state
       */
      type: 'New';
    }
  | {
      /**
       * Initializing context: checking permissions, loading contacts if allowed
       */
      type: 'Initializing';
      pageId: PageId;
    }
  | {
      /**
       * No contacts access permissions granted, or denied
       */
      type: 'NoPermissions';
      status: 'undetermined' | 'denied';
      pageId: PageId;
    }
  | {
      /**
       * Contacts API not available on this device
       */
      type: 'Unavailable';
      pageId: PageId;
    }
  | {
      /**
       * Contacts loaded, ready to import
       */
      type: 'Ready';
      pageId: PageId;
      /**
       * List of all contacts (and their indexes, to be referenced from filtered [results] list)
       */
      contacts: [BasicContact, number][];
      /**
       * User filter query
       */
      query: string;
      /**
       * Filtered results list to be show to user
       * index is the same as in original contacts list
       */
      results: [BasicContact, number][];
      /**
       * Indexes of the contacts selected for import
       */
      selectedMap: { [k in number]: boolean };
      /**
       * Selected contacts count
       */
      selectedCnt: number;
    }
  | {
      /**
       * Contact imports in progress
       */
      type: 'ImportingContacts';
      pageId: PageId;
      /**
       * Total number of contacts to be imported
       */
      importingCnt: number;
      /**
       * Number of contacts already imported
       */
      importedCnt: number;
    }
  | {
      type: 'ImportSucceed';
      pageId: PageId;
      /**
       * Number of contacts imported
       */
      importedCnt: number;
    }
  | {
      /**
       * Contacts import failed
       */
      type: 'Failed';
      pageId: PageId;
    };

const defaultState = (): ImportContactsState => ({
  type: 'New',
});

const ImportFields = [
  Contacts.Fields.ID,
  Contacts.Fields.Name,
  Contacts.Fields.FirstName,
  Contacts.Fields.LastName,
  Contacts.Fields.PhoneNumbers,
  Contacts.Fields.Image, // Can we use this?
];

export const ImportContactsContext = createModelContext(
  defaultState(),
  {
    trySetUnavailable: (state) => {
      if (state.type === 'Initializing') {
        return {
          type: 'Unavailable',
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetNoPermissions: (state, status: 'undetermined' | 'denied') => {
      if (state.type === 'Initializing') {
        return {
          type: 'NoPermissions',
          status: status,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetReady: (state, contacts: [BasicContact, number][]) => {
      if (state.type === 'Initializing' || state.type === 'NoPermissions') {
        return {
          type: 'Ready',
          contacts: contacts,
          query: '',
          results: contacts,
          selectedMap: {},
          selectedCnt: 0,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetResults: (state, payload: { query: string; results: [BasicContact, number][] }) => {
      if (state.type === 'Ready') {
        return {
          type: 'Ready',
          contacts: state.contacts,
          query: payload.query,
          results: payload.results,
          selectedMap: state.selectedMap,
          selectedCnt: state.selectedCnt,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySelectAt: (state, index: number) => {
      if (state.type === 'Ready') {
        const selectedMap = Object.fromEntries(Object.entries(state.selectedMap).concat([[`${index}`, true]]));
        const selectedCnt = Object.keys(selectedMap).length;

        return {
          type: 'Ready',
          contacts: state.contacts,
          query: state.query,
          results: state.results,
          selectedMap: selectedMap,
          selectedCnt: selectedCnt,
          pageId: state.pageId,
        };
      }

      return state;
    },
    tryDeselectAt: (state, index: number) => {
      if (state.type === 'Ready') {
        const indexStr = `${index}`;
        const selectedMap = Object.fromEntries(
          Object.entries(state.selectedMap).filter(([index]) => index !== indexStr),
        );
        const selectedCnt = Object.keys(selectedMap).length;

        return {
          type: 'Ready',
          contacts: state.contacts,
          query: state.query,
          results: state.results,
          selectedMap: selectedMap,
          selectedCnt: selectedCnt,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySelectAll: (state) => {
      if (state.type === 'Ready') {
        return {
          type: 'Ready',
          contacts: state.contacts,
          query: '', // as all contacts are selected (not only filtered) - resetting the query
          results: state.results,
          selectedMap: Object.fromEntries(new Array(state.contacts.length).fill(true).map((it, idx) => [idx, it])),
          selectedCnt: state.contacts.length,
          pageId: state.pageId,
        };
      }

      return state;
    },
    tryDeselectAll: (state) => {
      if (state.type === 'Ready') {
        return {
          type: 'Ready',
          contacts: state.contacts,
          query: '', // as all contacts are deselected (not only filtered) - resetting the query
          results: state.results,
          selectedMap: {},
          selectedCnt: 0,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetImporting: (state, payload: { importingCnt: number; importedCnt: number }) => {
      if (state.type === 'Ready' || state.type === 'ImportingContacts') {
        return {
          type: 'ImportingContacts',
          importingCnt: payload.importingCnt,
          importedCnt: payload.importedCnt,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetSucceed: (state, payload: { importedCnt: number }) => {
      if (state.type === 'ImportingContacts') {
        return {
          type: 'ImportSucceed',
          importedCnt: payload.importedCnt,
          pageId: state.pageId,
        };
      }

      return state;
    },
    trySetFailed: (state) => {
      if (state.type !== 'New') {
        return {
          type: 'Failed',
          pageId: state.pageId,
        };
      }

      return state;
    },
    mutate: (s, fn: (s: ImportContactsState) => ImportContactsState): ImportContactsState => fn(s),
  },
  (dispatch) => {
    /**
     * Best effort contact name mapping
     * Will use firstName or lastName when one of them is available
     * or will try to split name field into firstName/lastName
     */
    const mapName = (
      firstName: string | undefined,
      lastName: string | undefined,
      name: string | undefined,
    ): [string | undefined, string | undefined] => {
      if (firstName || lastName) {
        return [firstName || undefined, lastName || undefined];
      } else if (name) {
        const parts = name.split(' ').filter((part) => part.trim() !== '');
        return [parts[0] || undefined, parts.slice(1).join(' ') || undefined];
      } else {
        return [undefined, undefined];
      }
    };

    const mapContact = (contact: Contacts.Contact): BasicContact[] => {
      if (contact.phoneNumbers) {
        return contact.phoneNumbers
          .map((pn): BasicContact[] => {
            // TODO: improve default country code detection
            const phone = pipe(
              parseStrictPhoneNumber(pn?.digits ?? pn?.number, pn?.countryCode ?? 'ro'),
              E.getOrElseW(() => undefined),
            );

            if (phone && phone.length >= 10) {
              const [firstName, lastName] = mapName(contact.firstName, contact.lastName, contact.name);

              const fullName = firstName
                ? `${firstName}${lastName ? ` ${lastName}` : ''}`
                : lastName
                ? lastName
                : undefined;

              return pipe(
                {
                  phone: phone,
                  firstname: firstName,
                  lastname: lastName,
                  name: fullName,
                  searchText: `${fullName ? normalize(fullName) : ''} ${phone}`,
                  imageUrl: contact.image?.uri,
                },
                BasicContact.decode,
                E.fold(
                  () => [],
                  (c) => [c],
                ),
              );
            } else {
              return [];
            }
          })
          .reduce((acc, pns): BasicContact[] => acc.concat(pns), []);
      }

      return [];
    };

    const mapContacts = (contacts: Contacts.Contact[]): [BasicContact, number][] =>
      contacts
        .map(mapContact)
        .reduce((acc, c): BasicContact[] => acc.concat(c), [])
        .sort((a, b): -1 | 0 | 1 => {
          if (a.firstname) {
            if (b.firstname) {
              return a.firstname < b.firstname ? -1 : a.firstname > b.firstname ? 1 : 0;
            } else {
              return 1;
            }
          } else if (b.firstname) {
            return -1;
          } else {
            return 0;
          }
        })
        .map((c, idx): [BasicContact, number] => [c, idx]);

    const getContactsAsync = async (): Promise<[BasicContact, number][]> => {
      const { data } = await Contacts.getContactsAsync({
        fields: ImportFields,
      });

      log.debug(`Loaded ${data.length} contacts`);
      const mappedContacts = mapContacts(data);
      log.debug(`Mapped ${data.length} contacts to ${mappedContacts.length} contacts`);

      return mappedContacts;
    };

    const findContacts = (contacts: [BasicContact, number][], query: string): [BasicContact, number][] => {
      const cleanQuery = query.trim().toLowerCase();
      if (cleanQuery === '') {
        return contacts;
      } else {
        const parts = cleanQuery.split(/\s+/gi);

        return contacts.filter((c) => parts.every((p) => c[0].searchText.indexOf(p) !== -1));
      }
    };

    const trySearch = (state: ImportContactsState, query: string): void => {
      if (state.type === 'Ready') {
        log.debug(`Search for "${query}" in contacts`);
        const results = findContacts(state.contacts, query);
        log.debug(`Found ${results.length} results for "${query}" in contacts`);
        dispatch.trySetResults({ query, results });
      }
    };

    const debounceTrySearch = debounce(250, trySearch);

    return {
      init: (pageId: PageId): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            // Initialize context async
            const initAsync = async (): Promise<void> => {
              log.debug('Initialize ImportContactsContext, check contacts API available');

              if (await Contacts.isAvailableAsync()) {
                log.debug('Checking contacts access permissions:');
                const { status } = await Contacts.getPermissionsAsync();

                if (status === 'granted') {
                  log.debug('Contacts permissions granted, load contacts');
                  const mappedContacts = await getContactsAsync();

                  dispatch.trySetReady(mappedContacts);
                } else {
                  log.debug(`Contacts permissions not granted (${status})`);
                  dispatch.trySetNoPermissions(status);
                }
              } else {
                log.debug('Contacts API not available on this device');
                dispatch.trySetUnavailable();
              }
            };

            initAsync().catch(log.exception);

            return {
              type: 'Initializing',
              pageId: pageId,
            };
          } else {
            return state;
          }
        });
      },
      trySearch: (query: string): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            debounceTrySearch(state, query);

            return {
              type: 'Ready',
              contacts: state.contacts,
              query: query,
              results: state.results,
              selectedMap: state.selectedMap,
              selectedCnt: state.selectedCnt,
              pageId: state.pageId,
            };
          } else {
            return state;
          }
        });
      },
      tryAskPermissions: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'NoPermissions') {
            const askAsync = async (): Promise<void> => {
              const { status } = await Contacts.requestPermissionsAsync();

              if (status === 'granted') {
                log.debug('Contacts permission granted, load contacts');
                const mappedContacts = await getContactsAsync();
                dispatch.trySetReady(mappedContacts);
              } else {
                log.debug(`contacts permissions not granted (${status})`);
              }
            };

            askAsync().catch(log.exception);
          }

          return state;
        });
      },
      tryImportSelected: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready' && state.selectedCnt > 0) {
            const importing = state.contacts.filter((_, index) => !!state.selectedMap[index]);
            const importingCnt = importing.length;
            log.debug(`Going to import ${importing.length} contacts`);

            const importAsync = async (): Promise<void> => {
              try {
                await meroApi.clients.importClients({
                  pageId: state.pageId,
                  clientData: importing.map(([c]) => ({
                    phone: c.phone,
                    firstname: c.firstname,
                    lastname: c.lastname,
                  })),
                });

                dispatch.trySetSucceed({ importedCnt: importingCnt });
                log.debug('Contacts import succeed');
              } catch (e) {
                // FIXME: set error state
                throw e;
              }
            };

            importAsync().catch(log.exception);

            return {
              type: 'ImportingContacts',
              importingCnt: importingCnt,
              importedCnt: 0,
              pageId: state.pageId,
            };
          } else {
            return state;
          }
        });
      },
      trySelectAll: dispatch.trySelectAll,
      tryDeselectAll: dispatch.tryDeselectAll,
      trySelectAt: dispatch.trySelectAt,
      tryDeselectAt: dispatch.tryDeselectAt,
    };
  },
);

import { WorkerSearchItem } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  AvoidKeyboard,
  Body,
  Button,
  colors,
  Column,
  DismissKeyboard,
  H1,
  Header,
  Icon,
  Line,
  Row,
  SearchTextInput,
  SmallBody,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FlatList, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { ProsSettingsInviteStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';

export type Props = StackScreenProps<ProsSettingsInviteStackParamList, 'ProInvite'>;

const InviteProScreen: React.FC<Props> = ({ navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<WorkerSearchItem[]>([]);

  const getSearchResults = async (query: string) => {
    try {
      if (query.length >= 3 && pageState.type === 'Loaded') {
        const result = await meroApi.pages.searchWorkersToInvite({ search: query });
        setSearchResults(result);
      }
    } catch (error) {
      log.error('Failed to get pros list', error);
      toast.show({
        type: 'error',
        text: t('searchError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    let timeout: number;

    if (query.length >= 3) {
      setIsLoading(true);
      timeout = window.setTimeout(() => {
        getSearchResults(query);
      }, 1000);
    } else if (query.length === 0) {
      setIsLoading(false);
      setSearchResults([]);
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [query]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        text={t('inviteProScreen')}
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
      />
      <DismissKeyboard style={{ flex: 1 }}>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
          {query.length === 0 && (
            <>
              <H1 style={{ paddingHorizontal: 8 }}>{t('inviteProScreen')}</H1>
              <Spacer size={16} />
              <Body style={{ padding: 8, textAlign: 'center' }}>{t('inviteProScreenDescription')}</Body>
              <Spacer size={16} />
            </>
          )}
          <SearchTextInput
            autoFocus
            keyboardType="phone-pad"
            placeholder={t('searchByNumber')}
            value={query}
            onChange={setQuery}
          />
          {searchResults.length > 0 && (
            <AvoidKeyboard style={{ flex: 1 }}>
              <FlatList
                showsVerticalScrollIndicator={false}
                style={{ marginTop: 24 }}
                data={searchResults}
                renderItem={({ item }) => {
                  return (
                    <Row>
                      <Avatar
                        firstname={item.user.firstname ?? ''}
                        lastname={item.user.lastname ?? ''}
                        source={item.profilePhoto.thumbnail}
                        size={48}
                      />
                      <Column style={{ paddingLeft: 12, flex: 1 }}>
                        <Title style={{ color: '#06115E' }}>
                          {item.user.firstname} {item.user.lastname}
                        </Title>
                        {item.ownPageInfo && (
                          <SmallBody style={{ color: '#222222', fontSize: 12, fontWeight: 'bold', letterSpacing: 1 }}>
                            {item.ownPageInfo.name.toUpperCase()}
                          </SmallBody>
                        )}
                        <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{item.user.phone}</SmallBody>
                      </Column>
                      <Column style={{ paddingRight: 12 }}>
                        <Button
                          padding={24}
                          size="small"
                          expand={false}
                          containerStyle={{ alignSelf: 'center' }}
                          text={t('invite')}
                          onClick={() =>
                            navigation.navigate('ProInviteServices', {
                              workerId: item._id,
                              workerName: `${item.user.firstname} ${item.user.lastname}`,
                            })
                          }
                        />
                      </Column>
                    </Row>
                  );
                }}
                keyExtractor={(item) => item._id}
                ItemSeparatorComponent={() => (
                  <>
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                  </>
                )}
              />
            </AvoidKeyboard>
          )}
          {searchResults.length === 0 && query.length >= 3 && !isLoading && (
            <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Body style={{ padding: 8, textAlign: 'center' }}>
                <Trans ns={'pros'} t={t} i18nKey="noProFound" values={{ number: query }}>
                  0<Title style={{ fontFamily: 'open-sans-semibold' }}></Title>2
                </Trans>
              </Body>
              <Spacer size={24} />
              <Button
                backgroundColor="#E3E4FA"
                color={colors.DARK_BLUE}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('contact')}
                onClick={openChat}
              />
            </Column>
          )}
        </Column>
      </DismissKeyboard>
    </ModalScreenContainer>
  );
};

export default InviteProScreen;

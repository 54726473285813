import { HasId, HasOptionalFirstLastName, ProfileImage, UserId } from '@mero/api-sdk';
import { WorkerId } from '@mero/api-sdk';
import { BusinessHours } from '@mero/api-sdk/dist/business';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import * as S from 'fp-ts/string';

/**
 * Worker preview type used in calendar view
 */
export type CalendarWorkerPreview = {
  readonly _id: WorkerId;
  readonly calendar: HasId<CalendarId>;
  readonly user: HasId<UserId> & HasOptionalFirstLastName;
  readonly profilePhoto?: ProfileImage;
  readonly businessHours: BusinessHours;
};

const OptionalStringEq: Eq.Eq<string | undefined> = Eq.contramap<O.Option<string>, string | undefined>(O.fromNullable)(
  O.getEq(S.Eq),
);

const eqProfileImage: Eq.Eq<ProfileImage> = Eq.struct<ProfileImage>({
  _id: S.Eq,
  thumbnail: S.Eq,
  small: S.Eq,
  medium: S.Eq,
  large: S.Eq,
  original: S.Eq,
});

const eqProfileImageOptional: Eq.Eq<ProfileImage | undefined> = {
  equals: (x, y) => (x === undefined || y === undefined ? x === y : eqProfileImage.equals(x, y)),
};

const eq: Eq.Eq<CalendarWorkerPreview> = Eq.struct<CalendarWorkerPreview>({
  _id: WorkerId,
  calendar: Eq.struct<HasId<CalendarId>>({ _id: S.Eq }),
  user: Eq.struct<HasId<UserId> & HasOptionalFirstLastName>({
    _id: S.Eq,
    firstname: OptionalStringEq,
    lastname: OptionalStringEq,
  }),
  profilePhoto: eqProfileImageOptional,
  businessHours: BusinessHours,
});

export const CalendarWorkerPreview = {
  ...eq,
  array: A.getEq(eq),
};

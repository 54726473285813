import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AuthorizationCodeRequestNotification;
};

const AuthorizationCodeRequestNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  return (
    <View style={[styles.notificationContainer]}>
      <Icon type="notification_client_accepted" />
      <View style={styles.notificationBody}>
        <Body>{payload.text}</Body>
      </View>
    </View>
  );
};

export default AuthorizationCodeRequestNotification;

import {
  DefinedTrimedString,
  Product,
  ProductBarcode,
  ProductImage,
  ProductMeasure,
  ProductSavedPrice,
} from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import React from 'react';

type State = {
  readonly details: Product;
  readonly image?: ProductImage;
  readonly stock: number;
} | null;

const defaultState = (): State => null;

export const SelectedProductContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    updateProductDetails(
      state: State,
      payload: { name: DefinedTrimedString; measure: ProductMeasure.Any; barcode?: ProductBarcode },
    ) {
      if (state) {
        return {
          ...state,
          details: {
            ...state.details,
            ...payload,
          },
        };
      }
      return state;
    },
    updateProductPrice(state: State, price: ProductSavedPrice) {
      if (state) {
        return {
          ...state,
          details: {
            ...state.details,
            price: price,
          },
        };
      }
      return state;
    },
    updateStock(state: State, stock: number) {
      if (state) {
        return {
          ...state,
          stock: stock,
        };
      }
      return state;
    },
    updateImage(state: State, image: ProductImage | undefined) {
      if (state) {
        return {
          ...state,
          image: image,
        };
      }
      return state;
    },
    update(_state: State, payload: State) {
      return payload;
    },
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      updateStock: dispatch.updateStock,
      updateProductDetails: dispatch.updateProductDetails,
      updateImage: dispatch.updateImage,
      updateProductPrice: dispatch.updateProductPrice,
      update: dispatch.update,
    };
  },
);

export const withSelectedProductContext = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSelectedProductContext(props: P) {
    return (
      <SelectedProductContext.Provider>
        <Content {...props} />
      </SelectedProductContext.Provider>
    );
  };
};

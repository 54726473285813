import { ConfirmBox, H1, ModalOverlay, Spacer, Body, useShowError } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import log from '../../../utils/log';

type Props = {
  onSuccess: () => Promise<void>;
  onCancel: () => void;
};

const CancelPendingItem: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('booking');
  const showError = useShowError();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const cancel = () => {
    onCancel();
  };

  const onDelete = async () => {
    setDeleteInProgress(true);
    try {
      await onSuccess();
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
      showError(error);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('delete'),
    onPress: onDelete,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
          style={{
            maxWidth: 400,
          }}
        >
          <H1>{t('cancelPendingListTitle')}</H1>
          <Spacer size="8" />
          <Body>{t('cancelPendingListDescription')}</Body>
          <Spacer size="16" />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default CancelPendingItem;

import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.ClientAppointmentMadeNotification;
};

const ClientAppointmentMadeNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const date = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_calendar" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>
            {payload.client.firstname} {payload.client.lastname}
          </Body>{' '}
          și-a făcut o programare pentru{' '}
          <Body style={meroStyles.text.semibold}>{date.toFormat('EEEE, d LLLL yyyy', { locale: 'ro' })}</Body> la ora{' '}
          <Body style={meroStyles.text.semibold}>{date.toFormat('H:mm', { locale: 'ro' })}</Body> la{' '}
          <Body style={meroStyles.text.semibold}>
            {payload.worker.firstname} {payload.worker.lastname}
          </Body>{' '}
          la {payload.page.name}.{' '}
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.GIFTCARD) ? (
            <Body> Un card cadou a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.DISCOUNT) ? (
            <Body> Un cod de reducere a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.VOUCHER) ? (
            <Body> Un voucher a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) =>
            [MeroApi.CouponType.GIFTCARD, MeroApi.CouponType.DISCOUNT, MeroApi.CouponType.VOUCHER].every(
              (type) => type !== coupon.type,
            ),
          ) ? (
            <Body> Un cod de reducere a fost adăugat.</Body>
          ) : null}
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname}{' '}
          {payload.worker.lastname ?? ''} {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default ClientAppointmentMadeNotification;

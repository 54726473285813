import { RecurrenceRule } from '@mero/api-sdk';
import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { BlockedTimeReason } from '@mero/api-sdk/dist/calendar/blocked-time-reason';
import { createModelContext } from '@mero/components';
import { ApiError, apiError } from '@mero/shared-sdk/dist/common';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type State =
  | {
      type: 'Ready';
    }
  | {
      type: 'Updating';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Updated';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
      isOverride: boolean;
    };

const defaultState = (): State => ({ type: 'Ready' });

export const BlockedTimeUpdateContext = createModelContext(
  defaultState(),
  {
    reset: () => ({
      type: 'Ready',
    }),
    setUpdating: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Updating',
      ...payload,
    }),
    setUpdated: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Updated',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown>; isOverride: boolean }) => ({
      type: 'Failed',
      ...payload,
    }),
  },
  (dispatch) => {
    return {
      updateBlockedTime: async (payload: {
        calendarId: CalendarId;
        appointmentId: AppointmentId;
        occurrenceIndex: number;
        start: Date;
        end: Date;
        recurrenceRule?: RecurrenceRule.Any;
        reason: BlockedTimeReason;
        override: boolean;
        onlyOnce: boolean;
      }): Promise<void> => {
        try {
          dispatch.setUpdating({
            calendarId: payload.calendarId,
            appointmentId: payload.appointmentId,
          });

          log.debug('Updating blocked time', payload);

          await meroApi.calendar.updateBlockedTime({
            ...payload,
            recurrent: payload.recurrenceRule !== undefined,
          });

          dispatch.setUpdated({
            calendarId: payload.calendarId,
            appointmentId: payload.appointmentId,
          });
        } catch (e) {
          if (apiError(t.unknown).is(e)) {
            if (e.code === 20) {
              // booking override
              dispatch.setFailed({
                error: e,
                isOverride: true,
              });
            } else {
              log.exception(e);
              dispatch.setFailed({
                error: e,
                isOverride: false,
              });
            }
          } else {
            log.exception(e);
          }
        }
      },
      reset: dispatch.reset,
    };
  },
);

export const withBlockedTimeUpdateContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithBlockedTimeUpdateContextProvider(props: P) {
    return (
      <BlockedTimeUpdateContext.Provider>
        <Content {...props} />
      </BlockedTimeUpdateContext.Provider>
    );
  };
};

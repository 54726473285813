import { Body, colors, ConfirmBox, H1, Line, ModalOverlay, SimpleListItem, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const DeleteWorkerProfileDialog: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('pros');

  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const allConfirmed = Object.values(checks).every((v) => v);

  const leftAction = {
    text: t('cancel'),
    onPress: onCancel,
    flex: 10,
  };

  const rightAction = {
    text: t('confirmDeleteWorkerCalendarButton'),
    onPress: allConfirmed ? onSuccess : undefined,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose
          onClose={onCancel}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('confirmDeleteWorkerCalendar')}</H1>
          <Spacer size="8" />
          <Body>{t('checkToConfirm')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteWorkerCalendarCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteWorkerCalendarCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns={'pros'} t={t} i18nKey="deleteWorkerCalendarCheck3">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteWorkerProfileDialog;

import * as React from 'react';
import Svg, { Path, Rect, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Previous: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G transform="translate(2201 5850) rotate(180)">
      <Path
        d="M5.885,11.743,4.5,10.358,9.069,5.869,4.5,1.384,5.885,0l5.954,5.869Z"
        transform="translate(2182.522 5832)"
        fill={color}
      />
      <Rect width="24" height="24" transform="translate(2177 5826)" fill="none" />
    </G>
  </Svg>
);

export default React.memo(Previous);

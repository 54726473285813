import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { ConfirmBox, H1, ModalOverlay, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import Body from '@mero/components/lib/components/Text/Body';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import log from '../../../utils/log';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  checkoutTransactionId: CheckoutTransactionId | CheckoutTransactionId[];
};

const DeleteDraftDialog: React.FC<Props> = ({ onSuccess, onCancel, checkoutTransactionId }) => {
  const { t } = useTranslation('checkout');

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const cancel = () => {
    onCancel();
  };

  const onDelete = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.checkout.deleteTransactions({
          pageId: pageState.page.details._id,
          transactionIds: Array.isArray(checkoutTransactionId) ? checkoutTransactionId : [checkoutTransactionId],
        });
        onSuccess();
      }
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
    } finally {
      setDeleteInProgress(false);
    }
  };

  const leftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const rightAction = {
    text: t('deleteDraft'),
    onPress: deleteInProgress ? undefined : onDelete,
    flex: 15,
  };

  const count = Array.isArray(checkoutTransactionId) ? checkoutTransactionId.length : 1;

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('importantAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={leftAction}
          rightAction={rightAction}
          style={{
            maxWidth: 400,
          }}
        >
          <H1>{t('confirmDeleteDraft', { count })}</H1>
          <Spacer size="8" />
          <Body>{t('confirmDeleteDraftDescription', { count })}</Body>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteDraftDialog;

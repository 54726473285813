import { colors, Column } from '@mero/components';
import * as React from 'react';

import Marker from './Marker';

const MapPlaceholder: React.FC = () => {
  return (
    <Column
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F4F5F7',
        width: '100%',
        height: 185,
      }}
    >
      <Marker color={colors.ATHENS_GRAY} />
    </Column>
  );
};

export default MapPlaceholder;

import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

import { OUTRAGEOUS_ORANGE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ClientWarned: React.FC<Props> = ({ size = 11, color = OUTRAGEOUS_ORANGE, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 11 11" disabled={disabled} onPress={onPress}>
    <G transform="translate(-172 -4)">
      <Path
        d="M5.5,1,0,11.083H11ZM5.042,4.667h.917V7.875H5.042ZM5.5,9.823a.573.573,0,1,1,.573-.573A.573.573,0,0,1,5.5,9.823Z"
        transform="translate(172 3.458)"
        fill={color}
      />
      <Rect width="11" height="11" transform="translate(172 4)" fill="none" />
    </G>
  </Svg>
);

export default React.memo(ClientWarned);

import React from 'react';
import { Svg, Path, G, Rect } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Calendar = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <G id="Linecons_small-calendar" transform="translate(3 4)">
        <Rect
          id="Rectangle_2891"
          data-name="Rectangle 2891"
          width="24"
          height="24"
          transform="translate(-3 -4)"
          fill="none"
        />
        <G id="calendar_1_" transform="translate(2.5 1.117)">
          <Path
            id="Union_3"
            data-name="Union 3"
            d="M1.182,13.765A1.19,1.19,0,0,1,0,12.567V2.543a1.189,1.189,0,0,1,1.182-1.2H2.511v-.9a.443.443,0,1,1,.887,0v.9H6.057v-.9a.443.443,0,1,1,.887,0v.9H9.6v-.9a.443.443,0,1,1,.886,0v.9h1.33A1.189,1.189,0,0,1,13,2.543V12.567a1.19,1.19,0,0,1-1.182,1.2ZM.887,2.543V12.567a.3.3,0,0,0,.3.3H11.818a.3.3,0,0,0,.3-.3V2.543a.3.3,0,0,0-.3-.3h-1.33v.9a.443.443,0,1,1-.886,0v-.9H6.943v.9a.443.443,0,1,1-.887,0v-.9H3.4v.9a.443.443,0,1,1-.887,0v-.9H1.182A.3.3,0,0,0,.887,2.543Zm4.727,8.678V9.874H7.386v1.347Zm-3.1,0V9.874H4.284v1.347Zm6.2-2.244V7.63h1.773V8.977Zm-3.1,0V7.63H7.386V8.977Zm-3.1,0V7.63H4.284V8.977Zm6.2-2.244V5.386h1.773V6.733Zm-3.1,0V5.386H7.386V6.733Zm-3.1,0V5.386H4.284V6.733Z"
            transform="translate(0)"
            fill={color || '#222'}
          />
        </G>
      </G>
    </Svg>
  );
};

export default Calendar;

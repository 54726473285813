import { BusinessCategoryId, ServiceId } from '@mero/api-sdk/dist/services';
import {
  ModalOverlay,
  DismissKeyboard,
  ConfirmBox,
  H1,
  Spacer,
  Select,
  useToast,
  useShowError,
} from '@mero/components';
import { isNull } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import log from '../../../utils/log';

type Props = {
  serviceIds: ServiceId[];
  onClose: () => void;
};
const UpdateServicesCategoryDialog: React.FC<Props> = ({ onClose, serviceIds }) => {
  const toast = useToast();
  const showError = useShowError();
  const { t } = useTranslation('services');

  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();

  const [{ businessCategories }] = ServicesContext.useContext();
  const [category, setCategory] = React.useState<BusinessCategoryId | undefined>();

  const bulkUpdateCategoryBulkOperation = async (categoryId: BusinessCategoryId) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesCategory({
        pageId: pageState.page.details._id,
        serviceIds: serviceIds,
        categoryId: categoryId,
      });

      getPageWorkersAndServices(pageState.page.details._id);
      onClose();
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  const cancelCategoryLeftAction = {
    text: t('cancel'),
    onPress: onClose,
    flex: 10,
  };

  const categories = React.useMemo(
    () => businessCategories.map((category) => ({ value: category._id, label: category.name })),
    [businessCategories],
  );

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={t('updateServicesCategory')}
              canClose={true}
              onClose={onClose}
              leftAction={cancelCategoryLeftAction}
              rightAction={{
                text: t('save'),
                onPress: () => category && bulkUpdateCategoryBulkOperation(category),
                flex: 15,
              }}
              style={{
                width: 375,
              }}
            >
              <H1>{t('updateServicesCategory')}</H1>
              <Spacer size={24} />
              <Select
                placeholder={t('categoryLabel')}
                items={categories}
                value={category}
                onChange={(v) => setCategory(isNull(v) ? undefined : v)}
              />
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
    </ModalOverlay>
  );
};

export default UpdateServicesCategoryDialog;

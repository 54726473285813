import * as React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

import NoContentView from '../NoContentView';
import HeaderView from './HeaderView';

export type ImportInProgressViewProps = {
  readonly style?: StyleProp<ViewStyle>;
  readonly importedCnt: number;
  readonly importingCnt: number;
  readonly onClose: () => void;
};

const ImportInProgressView: React.FC<ImportInProgressViewProps> = ({
  style,
  importedCnt,
  importingCnt,
  onClose,
}: ImportInProgressViewProps) => {
  return (
    <>
      <HeaderView onClose={onClose} />
      <NoContentView
        title="Se importă ..."
        subtitle={`${importedCnt} din ${importingCnt} contacte importate cu succes`}
        style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>({ flex: 1 }, style)}
      />
    </>
  );
};

export default ImportInProgressView;

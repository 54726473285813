import { RecurrenceRule } from '@mero/api-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import Body from '@mero/components/lib/components/Text/Body';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { styles } from './styles';

export type Props = Readonly<{
  /**
   * Selected RecurrenceRule
   */
  recurrenceRule?: RecurrenceRule.Any;
  onDismiss: () => void;
  onOptionSelected?: (newRule: RecurrenceRule.Any | undefined | 'custom') => void;
  differenceInDays: number;
}>;

const RecurrenceRuleOptionsScreen: React.FC<Props> = ({
  recurrenceRule,
  onDismiss,
  onOptionSelected: onOptionsSelected,
  differenceInDays,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('booking');

  const selectCallback = (newRule: RecurrenceRule.Any | undefined | 'custom') => () => {
    if (onOptionsSelected) {
      onOptionsSelected(newRule);
    }
  };

  const isNone = recurrenceRule === undefined;
  const isDaily = RecurrenceRule.Predefined.DailyIndefinite.is(recurrenceRule);
  const isWeekly = RecurrenceRule.Predefined.WeeklyIndefinite.is(recurrenceRule);
  const isEvery2Weeks = RecurrenceRule.Predefined.Every2WeeksIndefinite.is(recurrenceRule);
  const isMonthly = RecurrenceRule.Predefined.MonthlyIndefinite.is(recurrenceRule);
  const isCustom = !(isNone || isDaily || isWeekly || isEvery2Weeks || isMonthly);

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={selectCallback(undefined)}>
            <SimpleListItem icon={isNone ? 'checked' : 'unchecked'}>
              <Body>{t('doesntRepeat')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          {differenceInDays <= 1 && (
            <>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={selectCallback(RecurrenceRule.Predefined.DAILY_INDEFINITE)}
              >
                <SimpleListItem icon={isDaily ? 'checked' : 'unchecked'}>
                  <Body>{t('daily')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              <Line />
            </>
          )}
          {differenceInDays <= 7 && (
            <>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={selectCallback(RecurrenceRule.Predefined.WEEKLY_INDEFINITE)}
              >
                <SimpleListItem icon={isWeekly ? 'checked' : 'unchecked'}>
                  <Body>{t('weekly')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              <Line />
            </>
          )}
          {differenceInDays <= 14 && (
            <>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={selectCallback(RecurrenceRule.Predefined.EVERY_2_WEEKS_INDEFINITE)}
              >
                <SimpleListItem icon={isEvery2Weeks ? 'checked' : 'unchecked'}>
                  <Body>{t('every2Weeks')}</Body>
                </SimpleListItem>
              </TouchableOpacity>
              <Line />
            </>
          )}
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback(RecurrenceRule.Predefined.MONTHLY_INDEFINITE)}
          >
            <SimpleListItem icon={isMonthly ? 'checked' : 'unchecked'}>
              <Body>{t('monthly')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={selectCallback('custom')}>
            <SimpleListItem icon={isCustom ? 'checked' : 'unchecked'}>
              <Body>{t('custom')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default RecurrenceRuleOptionsScreen;

import * as React from 'react';
import { View } from 'react-native';
import Svg, { Defs, Polygon, LinearGradient, Stop, Rect } from 'react-native-svg';

import { useMediaQueries } from '../../hooks/useMediaQueries';

const ContainerWithBackground: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const { isPhone } = useMediaQueries();

  // Avoid rendering "invisible" images on phones
  if (isPhone) {
    return <>{children}</>;
  } else {
    return (
      <View style={{ flex: 1, height: '100%' }}>
        <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#172b4d' }}>
          <View
            style={{
              flexDirection: 'column',
              flex: 1,
              backgroundColor: 'blue',
            }}
          >
            <Svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{ flex: 1, position: 'absolute' }}
            >
              <Defs>
                <LinearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                  <Stop offset="0%" stopColor="#5f5be5" />
                  <Stop offset="100%" stopColor="#9474ef" />
                </LinearGradient>
              </Defs>
              <Rect x={0} y={0} width="100" height="100" fill="url(#grad1)" />
            </Svg>
            <Svg
              width="100%"
              height="30"
              viewBox="0 0 2560 100"
              style={{ bottom: 0, position: 'absolute' }}
              preserveAspectRatio="none"
            >
              <Polygon fill="#172b4d" points="2560 0 2560 100 0 100" />
            </Svg>
          </View>
          <View style={{ flex: 1 }} />
        </View>
        <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>{children}</View>
      </View>
    );
  }
};

export default ContainerWithBackground;

import { SafeAreaView } from '@mero/components';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Edge } from 'react-native-safe-area-context';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { styles } from './styles';

type Props = React.PropsWithChildren<{
  /**
   * Additional styles to apply to container view (SafeArea)
   */
  readonly style?: StyleProp<ViewStyle>;
  /**
   * SafeArea edges
   */
  readonly edges?: ReadonlyArray<Edge>;
}>;

/**
 * Container view for a modal screen.
 * Contains safe area paddings.
 * On web has rounded corners and.
 */
const ModalScreenContainer: React.FunctionComponent<Props> = ({ style, edges, children }) => {
  const { isPhone, isDesktop } = useMediaQueries();

  if (isPhone) {
    // Fullscreen view on phones
    return (
      <SafeAreaView style={[styles.screenContainer, style]} edges={edges}>
        {children}
      </SafeAreaView>
    );
  } else {
    // Modal view on desktop or tablets - no safe area
    return <View style={[styles.screenContainer, styles.roundedCorners, style]}>{children}</View>;
  }
};

export default ModalScreenContainer;

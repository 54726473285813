import { isDefined, PositiveInt } from '@mero/api-sdk';
import { MembershipItemService } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionItem/membership';
import { MergedService, mergeServices, SavedService, ServiceId } from '@mero/api-sdk/dist/services';
import { colors, Column, ModalOverlay, SafeAreaView, useShowError } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useWindowDimensions } from 'react-native';

import { ServicesGroup } from '../../../components/GroupedServicesList';
import { SelectService } from '../../../components/SelectServiceScreen';
import SelectServiceScreen from '../../../components/SelectServiceScreen';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { MembershipItem, WithUiKey, withUiKey } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { getPriceValue } from '../../../utils/number';

export type EditServiceItemMembership = WithUiKey<
  Omit<MembershipItemService<MeroUnits.Any>, 'quantity'> & {
    quantity: { type: 'NotSet' } | MembershipItemService<MeroUnits.Any>['quantity'];
  }
>;

export const serviceToMembershipItem = (
  service: Pick<SavedService, '_id' | 'name' | 'durationInMinutes' | 'price'>,
): MembershipItem =>
  withUiKey({
    type: 'Service',
    service: {
      _id: service._id,
      name: service.name as DefinedString,
      durationInMinutes: service.durationInMinutes as PositiveInt,
      price:
        service.price.type === 'fixed'
          ? {
              type: 'Fixed',
              fixed: {
                amount: ScaledNumber.fromNumber(getPriceValue(service.price), 2),
                unit: 'RON',
              },
            }
          : { type: 'Hidden' },
      groupIds: [],
    },
    quantity: {
      type: 'Limited',
      value: 1 as PositiveInt,
    },
    total: {
      vatStatus: {
        type: 'Included',
        rate: undefined,
      },
      amount: {
        amount: ScaledNumber.fromNumber(0, 2),
        unit: 'RON',
      },
    },
  });

type Props = CurrentBusinessProps & {
  onSelect: (service: MembershipItem) => void;
  onCancel: () => void;
  selectedItemIds: ServiceId[];
};

const MembershipSelectService: React.FC<Props> = ({ page, selectedItemIds, onCancel, onSelect }) => {
  const { isPhone } = useMediaQueries();
  const width = useWindowDimensions().width;

  useEscPressWeb({
    onPress: onCancel,
  });
  const showError = useShowError();

  const [services, setServices] = React.useState<ServicesGroup[]>([]);

  const selectServiceCallback = React.useCallback((mergedService: MergedService) => {
    const service = page.details.services.find((s) => s._id === mergedService._id);

    if (!service) {
      return;
    }

    onSelect(serviceToMembershipItem(service));
  }, []);

  const getServices = React.useCallback(async () => {
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(page.details._id);
      const services = groupedServices.grouped
        .map((group) => ({
          group: group.group,
          services: group.services
            .filter((s) => !selectedItemIds.includes(s._id))
            .map((service) => mergeServices(service, page.details.services))
            .filter(isDefined),
        }))
        .filter((group) => group.services.length > 0);

      setServices(
        groupedServices.others.length > 0
          ? [
              ...services,
              {
                group: {
                  name: 'Alte Servicii',
                },
                services: groupedServices.others
                  .filter((s) => !selectedItemIds.includes(s._id))
                  .map((service) => mergeServices(service, page.details.services))
                  .filter(isDefined),
              },
            ]
          : services,
      );
    } catch (e) {
      showError(e);
    }
  }, [page.details._id]);

  React.useEffect(() => {
    getServices();
  }, [page]);

  return services.length > 0 ? (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <Column
        style={
          isPhone
            ? { width, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: colors.WHITE }
            : { width: 500, flex: 1 }
        }
      >
        {isPhone ? (
          <SafeAreaView style={{ flex: 1 }}>
            <SelectService services={services} onBackPressed={onCancel} onServiceSelected={selectServiceCallback} />
          </SafeAreaView>
        ) : (
          <SelectServiceScreen services={services} onBackPressed={onCancel} onServiceSelected={selectServiceCallback} />
        )}
      </Column>
    </ModalOverlay>
  ) : null;
};

export default pipe(MembershipSelectService, CurrentBusiness);

import { Checkbox, colors, Column, H3s, Icon, Line, Spacer } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';

import { Filters } from './MembershipSettingsScreen';

export type Props = {
  activeFilter?: Filters;
  onChange: (filter?: Filters) => void;
};

const SearchFilter: React.FC<Props> = ({ activeFilter, onChange }) => {
  const { t } = useTranslation('membership');

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const filtersSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: filtersSelectRef,
    isVisible: isFilterOpen,
    onClickOutside() {
      setIsFilterOpen(false);
    },
  });

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  return (
    <Column style={{ position: 'relative', zIndex: 2 }}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setIsFilterOpen(!isFilterOpen)}>
        <Body style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>{t('filters')}</Body>
        <Icon type="down" color={colors.DARK_BLUE} />
      </TouchableOpacity>
      {isFilterOpen && (
        <View
          ref={filtersSelectRef}
          style={{
            position: 'absolute',
            top: 36,
            left: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 100,
          }}
        >
          <Spacer size="16" />
          <H3s>{t('filters')}</H3s>
          <Spacer size="16" />
          <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={flow(closeFilter, () => onChange())}>
            <Checkbox
              color="blue"
              disabled={false}
              value={!activeFilter}
              onValueChange={flow(closeFilter, () => onChange())}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('allTypes')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Active'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Active'}
              onValueChange={flow(closeFilter, () => onChange('Active'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('active')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity
            style={{ flex: 2, flexDirection: 'row' }}
            onPress={flow(closeFilter, () => onChange('Inactive'))}
          >
            <Checkbox
              color="blue"
              disabled={false}
              value={activeFilter === 'Inactive'}
              onValueChange={flow(closeFilter, () => onChange('Inactive'))}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('inactive')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
        </View>
      )}
    </Column>
  );
};

export default SearchFilter;

import { StyleSheet } from 'react-native';

import { colors } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    height: 20,
    paddingHorizontal: 6,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e8defa',
    flexDirection: 'row',
    gap: 6,
  },
  text: {
    fontSize: 10,
    fontFamily: 'open-sans-semibold',
    color: '#7431f7',
  },
  waitingContainer: {
    borderWidth: 1,
    borderColor: colors.YELLOW_ORANGE,
  },
});

export default styles;

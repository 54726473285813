import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Star = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <Path data-name="Path 8287" d="M0 0h24v24H0z" fill="none" />
      <Path
        data-name="Path 8288"
        d="M17 7.308l-5.393-.455L9.5 2 7.392 6.86 2 7.308l4.095 3.467-1.23 5.154L9.5 13.194l4.635 2.734-1.222-5.154zm-7.5 4.515l-2.82 1.664.75-3.138-2.49-2.11 3.285-.279L9.5 5.006l1.282 2.962 3.285.279-2.49 2.111.75 3.138z"
        transform="translate(2.5 3.036)"
        fill={color || '#52577f'}
      />
    </Svg>
  );
};

export default Star;

import * as React from 'react';
import { View, Image } from 'react-native';

import * as colors from '../../../styles/colors';
import { styles as text } from '../../../styles/text';
import Icon from '../Icon';
import Body from '../Text/Body';

type AvatarProps = {
  size?: number;
  source?: string;
  firstname: string;
  lastname: string;
  color?: string;
  backgroundColor?: string;
  withBorder?: boolean;
};

const Avatar: React.FC<AvatarProps> = ({
  size = 48,
  source,
  firstname,
  lastname,
  color = colors.COMET,
  backgroundColor = '#E9ECEF',
  withBorder = false,
}: AvatarProps) => {
  const txt = React.useMemo(
    () => `${firstname[0] ?? ''}${lastname[0] ?? ''}`.toUpperCase().trim(),
    [firstname, lastname],
  );

  if (source) {
    return (
      <Image
        style={[
          {
            width: size,
            height: size,
            borderRadius: Math.floor(size / 2),
          },
          withBorder
            ? {
                borderWidth: 1,
                borderColor: '#EEF0F2',
              }
            : {},
        ]}
        source={{
          uri: source,
        }}
      />
    );
  } else {
    const fontSize = Math.floor(size / 2);
    const lineHeight = Math.round(fontSize * 1.25);

    return (
      <View
        style={[
          {
            width: size,
            height: size,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: backgroundColor,
            borderRadius: Math.floor(size / 2),
          },
          withBorder
            ? {
                borderWidth: 1,
                borderColor: '#EEF0F2',
              }
            : {},
        ]}
      >
        {txt !== '' ? <Body style={[text.bold, { fontSize, lineHeight, color }]}>{txt}</Body> : <Icon type="user" />}
      </View>
    );
  }
};

export default Avatar;

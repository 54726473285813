import { DefinedTrimedString } from '@mero/api-sdk';
import {
  MeroHeader,
  colors,
  Spacer,
  H1,
  Body,
  FormCard,
  Title,
  TextInput,
  DismissKeyboard,
  SafeAreaView,
  Button,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { ProductEditStackParamList } from '../../../types';
import { styles } from './styles';

type Props = {
  route: RouteProp<ProductEditStackParamList, 'ProductDescriptionScreen'>;
};

const ProductDescriptionScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const [state, { update }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [description, setDescription] = React.useState<string>('');
  const [name, setName] = React.useState<string | undefined>();

  React.useEffect(() => {
    const init = async () => {
      if (
        state.type === 'Loaded' &&
        pageState.type === 'Loaded' &&
        route.params.productId &&
        route.params.productId !== 'undefined'
      ) {
        const product = await meroApi.pro.products.getProductById({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        });
        setDescription(product.description?.toString() ?? state.productDescription ?? '');
        setName(product.name);
      }
    };
    init();
  }, [state.type, pageState.type]);

  const saveDescription = () => {
    const decoded = DefinedTrimedString.decode(description);
    update({ productDescription: E.isRight(decoded) ? decoded.right : undefined });
    goBack();
  };
  if (state.type !== 'Loaded') {
    return;
  }

  return (
    <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader title={t('productDescription')} canGoBack={true} onBack={goBack} canClose={false} />

      <Spacer size="16" />
      <DismissKeyboard>
        <View style={{ paddingHorizontal: 16 }}>
          <H1>{t('productDescription')}</H1>
          <Spacer size="8" />

          <Body>
            {name ? `${t('existingProductDescriptionInstruction')} ${name}` : t('newProductDescriptionInstruction')}
          </Body>

          <Spacer size={24} />
          <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 24, paddingHorizontal: 16 }}>
            <Title>{t('productDescription')}</Title>
            <Spacer size={8} />
            <TextInput
              numberOfLines={10}
              multiline={true}
              value={description}
              onChange={setDescription}
              placeholder={t('chooseProductDescriprion')}
            />
          </FormCard>
          <Spacer size={96} />
        </View>
      </DismissKeyboard>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('ready')} onClick={saveDescription} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('ready')}
              onClick={saveDescription}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProductDescriptionScreen;

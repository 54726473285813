import { Body, Button, colors, FormCard, H3s, Icon, Spacer, styles, useAppState } from '@mero/components';
import * as React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { PushClientSubscriptionContext } from '../../../../contexts/PushClientSubscriptionContext';
import log from '../../../../utils/log';

type Props = {
  readonly onNavigatePermissionDenied: () => void;
};

/**
 * Widget to ask user for push notifications subscription
 */
const ActivatePushWidget: React.FC<Props> = ({ onNavigatePermissionDenied }: Props) => {
  const { isPhone } = useMediaQueries();

  const appState = useAppState();
  const [prevAppState, setPrevAppState] = React.useState(appState);
  const [pushSubscriptionState, { reload, trySubscribeDevice, dismissPermissionsPrompt }] =
    PushClientSubscriptionContext.useContext();

  const isLoaded = pushSubscriptionState.type === 'Loaded';
  const isDenied = isLoaded && pushSubscriptionState.pushSubscriptionStatus.type === 'Denied';
  const isUndetermined = isLoaded && pushSubscriptionState.pushSubscriptionStatus.type === 'Undetermined';
  const shouldActivatePush = isDenied || isUndetermined;
  const isSupportedPlatform = Platform.select({ ios: true, android: true, default: false });
  const showWidget = isSupportedPlatform && shouldActivatePush;

  const { logDidMount } = useAnalytics({
    eventName: 'notifications_permission_status_reported',
    screenName: 'notifications',
    onDidMount: false,
  });

  React.useEffect(() => {
    if (appState === 'active' && prevAppState !== 'active') {
      reload();
    }
    setPrevAppState(appState);
  }, [appState, prevAppState, reload, setPrevAppState]);

  React.useEffect(() => {
    if (isLoaded) {
      logDidMount({
        data: {
          is_visible: showWidget,
          push_permission_status: pushSubscriptionState.type,
        },
      });
    }
  }, [isLoaded]);

  const activatePushCallback = React.useCallback(() => {
    if (isDenied) {
      log.debug('Activate push: current status is denied');
      onNavigatePermissionDenied();
    } else if (isUndetermined) {
      log.debug('Activate push: current status is undetermined');
      trySubscribeDevice();
    }
  }, [isDenied, isUndetermined, trySubscribeDevice, onNavigatePermissionDenied]);

  // const activatePushDismissCallback = React.useCallback(() => {
  //   dismissPermissionsPrompt();
  // }, [dismissPermissionsPrompt]);

  return showWidget ? (
    <FormCard
      dropShaddow
      rounded
      style={{
        marginLeft: 16,
        marginTop: 12,
        marginRight: 16,
        marginBottom: 12,
        paddingLeft: 8,
        paddingTop: 8,
        paddingRight: 8,
        paddingBottom: 8,
        backgroundColor: colors.COMET,
      }}
    >
      {/*<View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>*/}
      {/*  <TouchableOpacity onPress={activatePushDismissCallback}>*/}
      {/*    <Icon type="close" />*/}
      {/*  </TouchableOpacity>*/}
      {/*</View>*/}
      <View style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
        <H3s style={[styles.text.alignCenter, { color: colors.WHITE }]}>Nu rata nici o programare</H3s>
        <Spacer size="8" />
        <Body style={[styles.text.alignCenter, { color: colors.WHITE }]}>
          Activează notificările push și primește actualizări ale programărilor în timp real
        </Body>
        <Spacer size="24" />
        {isPhone ? (
          <Button
            text="Activează notificările"
            backgroundColor={colors.WHITE}
            color={colors.DARK_BLUE}
            onClick={activatePushCallback}
          />
        ) : (
          <Button
            expand={false}
            containerStyle={{ alignSelf: 'center' }}
            text="Activează notificările"
            backgroundColor={colors.WHITE}
            color={colors.DARK_BLUE}
            onClick={activatePushCallback}
          />
        )}
      </View>
    </FormCard>
  ) : null;
};

export default ActivatePushWidget;

import { ScaledNumber, MeroUnits, ApiError } from '@mero/api-sdk';
import {
  ModalOverlay,
  ConfirmBox,
  H1,
  InputWithLabel,
  Spacer,
  TypeSafeTextInput,
  Body,
  Icon,
  useToast,
  DismissKeyboard,
} from '@mero/components';
import { DateString } from '@mero/shared-sdk/dist/common';
import * as E from 'fp-ts/lib/Either';
import { flow, pipe } from 'fp-ts/lib/function';
import { identity } from 'io-ts';
import * as t from 'io-ts';
import { DateTime, IANAZone } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';

import { KeyboardAvoidingView } from '../../../../../components/KeyboardAvoidingView';
import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import SelectDateTimeModal from '../../../../../components/SelectDateTimeModal';
import { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList } from '../../../../../types';
import { NumberFromString } from '../../../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../../../utils/scaled';
import { styles } from './styles';

const TIMEZONE = 'Europe/Bucharest';

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'InitialBalance'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

export const numberValidator =
  (prev: string) =>
  <A extends t.Mixed>(next: ValueIO<t.TypeOf<A>>) => {
    const parsed = replaceDecimalSeparator(next.input);
    return localeNumberValidator(parsed)
      ? { input: parsed, decoded: next.decoded }
      : { input: prev, decoded: next.decoded };
  };

const InitialBalanceScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('cashRegistry');
  const toast = useToast();
  const goBack = useGoBack();

  const [cashRegistryState, { update, loadTransactions }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const [showErrors, setShowErrors] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const title = !isEditMode ? t('addInitialBalance') : t('setInitialBalance');

  const [amount, setAmount] = React.useState({ input: '', decoded: NumberFromString.decode('') });
  const isAmountValid = E.isRight(amount.decoded);

  const now = new Date();
  const [date, setDate] = React.useState(now);
  const [showCalendar, setShowCalendar] = React.useState(false);

  const timezone = React.useMemo(() => IANAZone.create(TIMEZONE), []);

  const navigateCashRegistryCallback = React.useCallback(() => {
    if (cashRegistryState.type === 'Loaded')
      navigation.navigate('Home', {
        screen: 'HomeTabs',
        params: {
          screen: 'CashRegistryScreen',
        },
      });
  }, [navigation]);

  React.useEffect(() => {
    if (cashRegistryState.type === 'Loaded' && cashRegistryState.cashRegistry) {
      setIsEditMode(true);
      const amountStr = scaledToString(cashRegistryState.cashRegistry.initialBalance.amount.amount);
      setAmount({
        input: stripLocalThousandsSeparators(amountStr),
        decoded: NumberFromString.decode(stripLocalThousandsSeparators(amountStr)),
      });
      setDate(cashRegistryState.cashRegistry.initialBalance.date);
    }
  }, [cashRegistryState]);

  const dateString = React.useMemo(
    (): DateString =>
      pipe(
        DateTime.fromJSDate(date, { zone: timezone }).toFormat('yyyy-MM-dd'),
        DateString.decode,
        E.fold(() => {
          throw new Error(`Failed parse DateString`);
        }, identity),
      ),
    [date],
  );

  const initializeCashRegistry = async () => {
    if (pageState.type !== 'Loaded' || cashRegistryState.type !== 'Loaded' || !cashRegistryState.selectedCompany) {
      return;
    }

    if (!isAmountValid) {
      return setShowErrors(true);
    }

    try {
      const cashRegistry =
        isEditMode && cashRegistryState.cashRegistry
          ? await meroApi.checkout.updateCashRegistry({
              pageId: pageState.page.details._id,
              companyId: cashRegistryState.selectedCompany._id,
              cashRegistryId: cashRegistryState.cashRegistry._id,
              initialBalanceDate: date,
              initialBalanceAmount: {
                amount: ScaledNumber.fromNumber(localeStringToNumber(amount.input), 2),
                unit: MeroUnits.RON.code,
              },
            })
          : await meroApi.checkout.initializeCashRegistry({
              pageId: pageState.page.details._id,
              companyId: cashRegistryState.selectedCompany._id,
              timezone: TIMEZONE,
              initialBalanceDate: date,
              initialBalanceAmount: {
                amount: ScaledNumber.fromNumber(localeStringToNumber(amount.input), 2),
                unit: MeroUnits.RON.code,
              },
            });
      update({
        cashRegistry,
        isCompanyMenuOpen: false,
        isOptionsMenuOpen: false,
      });
      loadTransactions({ pageId: pageState.page.details._id });
      navigateCashRegistryCallback();
    } catch (e) {
      if (e instanceof ApiError) {
        toast.show({
          type: 'error',
          text: e.message,
        });
      }
    }
  };

  const cancelLeftAction = {
    text: t('cancelButton'),
    onPress: goBack,
    flex: 10,
  };

  const addRightAction = {
    text: isEditMode ? t('save') : t('addButton'),
    onPress: initializeCashRegistry,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
      <DismissKeyboard>
        <MobileWebModalWrapper position="center">
          <KeyboardAvoidingView>
            <ConfirmBox
              type="info"
              headerTitle={title}
              canClose={true}
              onClose={goBack}
              leftAction={cancelLeftAction}
              rightAction={addRightAction}
              style={{
                width: 375,
              }}
            >
              <H1>{title}</H1>
              <Spacer size={24} />
              <InputWithLabel
                label={t('intialBalance')}
                isError={showErrors && !isAmountValid}
                errorText={t('initialBalanceError')}
              >
                <TypeSafeTextInput
                  value={amount.input}
                  codec={NumberFromString}
                  onChange={flow(numberValidator(amount.input), setAmount)}
                  keyboardType="numeric"
                  editable={true}
                  placeholder="0.00"
                  showError={showErrors}
                  onFocus={() => {
                    setAmount({
                      input: stripLocalThousandsSeparators(amount.input),
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(amount.input)),
                    });
                  }}
                  onBlur={() => {
                    setAmount({
                      input: amount.input
                        ? scaledToString(ScaledNumber.fromNumber(localeStringToNumber(amount.input), 2))
                        : '',
                      decoded: NumberFromString.decode(stripLocalThousandsSeparators(amount.input)),
                    });
                  }}
                />
              </InputWithLabel>

              <Spacer size={16} />
              <InputWithLabel label={t('date')}>
                <TouchableOpacity
                  style={{
                    height: 43,
                    justifyContent: 'center',
                    borderColor: '#DEE2E6',
                    borderWidth: 1,
                    borderRadius: 5,
                    paddingHorizontal: 12,
                  }}
                  onPress={() => setShowCalendar(true)}
                >
                  <View style={[{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }]}>
                    <Body style={{ alignItems: 'center' }}>{dateString}</Body>
                    <View style={[{ paddingLeft: 10 }]}>
                      <Icon type="dropdown" rotate={showCalendar} />
                    </View>
                  </View>
                </TouchableOpacity>
              </InputWithLabel>
            </ConfirmBox>
          </KeyboardAvoidingView>
        </MobileWebModalWrapper>
      </DismissKeyboard>
      {showCalendar && (
        <View style={styles.modalScreen}>
          <SelectDateTimeModal
            mode="date"
            selected={date}
            onDateSelected={(d) => {
              setDate(d), setShowCalendar(false);
            }}
            onClosePress={() => {
              setShowCalendar(false);
            }}
            timeZone={timezone}
            maxDate={now}
          />
        </View>
      )}
    </ModalOverlay>
  );
};

export default InitialBalanceScreen;

import { CheckoutTransactionPreview } from '@mero/api-sdk';
import { colors, H2s, Icon, Label, Row, SmallBody } from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import Column from '@mero/components/lib/components/Layout/Column';
import Spacer from '@mero/components/lib/components/Spacer';

export const EditIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Images/Avatar without border" {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" transform="translate(0 .5)" />
      <G fill="#52577f">
        <Path d="m11.273 18.224 5.654-5.654 2.989 2.99-5.653 5.655ZM21.543 13.98a1.953 1.953 0 0 0-.265-2.76 2.269 2.269 0 0 0-1.588-.718 1.758 1.758 0 0 0-1.172.454Z" />
        <Path d="M10.862 18.883 10 22.498l3.615-.843Zm6.589-6.831.556-.556 3 3-.556.556Z" data-name="layer1" />
      </G>
    </G>
  </Svg>
);

export type Props = {
  draft: CheckoutTransactionPreview.AnyDraft;
  onPress: () => void;
};
const DraftPreview: React.FC<Props> = ({ draft, onPress }) => {
  const { t } = useTranslation('checkout');

  return (
    <TouchableOpacity key={draft._id} style={{ flexDirection: 'row', paddingHorizontal: 16 }} onPress={onPress}>
      <EditIcon />
      <Row style={{ flex: 1, paddingLeft: 16 }}>
        <Column style={{ flex: 1, paddingRight: 16 }}>
          <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t(draft.status)}</Label>
          <H2s>
            {ScaledNumber.toNumber(draft.total.total.amount)} {t(draft.total.total.unit)}
          </H2s>
          <Spacer size={2} />
          <SmallBody>
            {draft.client
              ? !draft.client.user.profile.firstname && !draft.client?.user.profile.lastname
                ? t('noName')
                : draft.client.user.profile.firstname
              : t('noClient')}{' '}
            {draft.client?.user.profile.lastname}
          </SmallBody>
          <Spacer size={6} />
          <SmallBody style={{ color: colors.COMET }}>
            {DateTime.fromJSDate(draft.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
          </SmallBody>
        </Column>
      </Row>
      <Icon type="next" color={colors.DARK_BLUE} />
    </TouchableOpacity>
  );
};

export default DraftPreview;

import { Body } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

import { AuthContext } from '../../contexts/AuthContext';
import { MeroWebViewProps } from './MeroWebView';
import { styles } from './styles';

export type WebViewHandle = {
  goBack: () => void;
  stopLoading: () => void;
};

const MeroWebView = React.forwardRef(function MeroWebViewComponent(
  props: MeroWebViewProps,
  ref: React.ForwardedRef<WebViewHandle>,
): React.ReactElement {
  const [authState] = AuthContext.useContext();

  if (authState.type !== 'Authorized') {
    return (
      <View style={props.style}>
        <Body style={styles.title}>Not Authorized</Body>
      </View>
    );
  }

  const authenticateUri = (uri: string): string => {
    if (/^http(s)?\:\/\/[^\/]*\.mero\.ro(\/.*)?$/gi.test(uri)) {
      // Add authentication only to mero.ro domains
      const authQueryParam = /http(s)?\:\/\/[^\/]*\.mero\.ro\/onboarding/gi.test(uri)
        ? `authGrant=${encodeURIComponent(JSON.stringify(authState.grant))}`
        : `nvb=${encodeURIComponent(authState.grant.accessToken)}`;

      const hasQuery = uri.indexOf('?') !== -1;

      return `${uri}${hasQuery ? '' : '?'}&${authQueryParam}`;
    } else {
      return uri;
    }
  };

  const [refreshKey, setRefreshKey] = React.useState(0);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const reload = (): void => {
    setRefreshKey(refreshKey + 1);
  };

  React.useImperativeHandle(ref, () => ({
    goBack: () => {
      // pass
    },
    stopLoading: () => {
      // pass
    },
    reload: reload,
  }));

  return (
    <iframe
      ref={iframeRef}
      key={refreshKey}
      style={{ flex: 1, padding: 0, border: 0 }}
      src={props.source ? authenticateUri(props.source.uri) : undefined}
    />
  );
});

export default MeroWebView;

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Plus = ({ size = 18, color = BLACK, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path id="iconmonstr-plus-2" d={`M${size},7.5H10.5V0h-3V7.5H0v3H7.5V18h3V10.5H18Z`} fill={color} />
  </Svg>
);

export default React.memo(Plus);

import { Body, Button, H1, Icon, ModalOverlay, Spacer } from '@mero/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs from 'dayjs';
import * as StoreReview from 'expo-store-review';
import * as React from 'react';
import { Linking, Modal, Platform, View } from 'react-native';

import config from '../../config';
import { AuthContext } from '../../contexts/AuthContext';
import { isAfterOrTheSame } from '../Calendar/BigCalendar/utils';
import { styles } from './styles';

const APP_REVIEW = 'app-review-request';
const APP_DELAY_REVIEW = 'app-review-delay';

type Props = {
  isPaid: boolean;
};

const AppReviewRequestScreen: React.FC<Props> = ({ isPaid }) => {
  const [authState] = AuthContext.useContext();
  const [showModal, setShowModal] = React.useState(false);

  const onRequestClose = async (delayReview = true) => {
    setShowModal(false);
    if (delayReview) {
      await AsyncStorage.setItem(APP_DELAY_REVIEW, 'true');
    } else {
      await AsyncStorage.removeItem(APP_DELAY_REVIEW);
    }
  };

  const requestReview = async () => {
    onRequestClose(false);
    if (Platform.OS === 'ios') {
      await Linking.openURL(config.appStoreLink);
    } else {
      await StoreReview.requestReview();
    }
  };

  const showReviewNotification = async () => {
    const value = await AsyncStorage.getItem(APP_REVIEW);
    const delayed = await AsyncStorage.getItem(APP_DELAY_REVIEW);
    const canBeDisplayed = !value || isAfterOrTheSame(dayjs(), dayjs(value).add(delayed === 'true' ? 1 : 3, 'months'));

    if (canBeDisplayed && (await StoreReview.hasAction()) && (await StoreReview.isAvailableAsync())) {
      await AsyncStorage.setItem(APP_REVIEW, dayjs().format('YYYY-MM-DD'));
      setShowModal(true);
    }
  };

  React.useEffect(() => {
    if (authState.type === 'Authorized' && isPaid) {
      showReviewNotification();
    }
  }, [authState.type]);

  return (
    <Modal animationType="slide" transparent={true} visible={showModal} onRequestClose={() => onRequestClose()}>
      <ModalOverlay>
        <View style={styles.container}>
          <View
            style={[
              {
                alignItems: 'center',
                width: '100%',
                maxWidth: 450,
                padding: 24,
                backgroundColor: 'white',
              },
              styles.modalBody,
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                maxWidth: 148,
                width: '100%',
                paddingVertical: 12,
                justifyContent: 'space-between',
              }}
            >
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <View key={`star_${index}`} style={{ alignItems: 'center' }}>
                    <Icon type="star" size="medium" backgroundColor="#FBAC40" />
                    <Spacer size={10} />
                  </View>
                ))}
            </View>
            <Spacer size="m" />
            <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>Îți place aplicația MERO PRO?</H1>
            <Spacer size="xs" />
            <Body style={{ textAlign: 'center', maxWidth: 327 }}>
              Lasă-ne o recenzie ca să ne ajuți să facem aplicația MERO Pro și mai bună!
            </Body>
            <Spacer size="l" />
            <Button containerStyle={{ maxWidth: 178 }} text="Adaugă recenzie" onClick={requestReview} />
            <Spacer size="m" />
            <Button
              containerStyle={{ maxWidth: 178 }}
              backgroundColor="transparent"
              color="#52577F"
              text="Mai târziu"
              onClick={onRequestClose}
            />
          </View>
        </View>
      </ModalOverlay>
    </Modal>
  );
};

export default AppReviewRequestScreen;

import { H3s, ModalOverlay, SafeAreaView, Title, Spacer, SmallBody, Line, colors } from '@mero/components';
import * as React from 'react';
import { Pressable, View, TouchableOpacity } from 'react-native';

import { styles } from './styles';

export type ConfirmUpdateRecurrentBookingProps = {
  readonly onUpdateOnlyOne: () => void;
  readonly onUpdateAll: () => void;
  readonly onDismiss: () => void;
};

const ConfirmUpdateRecurrentBooking: React.FC<ConfirmUpdateRecurrentBookingProps> = ({
  onUpdateOnlyOne,
  onUpdateAll,
  onDismiss,
}) => {
  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <SafeAreaView edges={['bottom']} style={styles.optionsListContainer}>
        <View style={styles.optionsMenuItem}>
          <Spacer size="8" />
          <H3s>Modificare programare recurentă</H3s>
          <Spacer size="6" />
          <SmallBody style={{ color: colors.COMET }}>
            Clientul va fi notificat de această modificare prin SMS sau în aplicație, după caz.
          </SmallBody>
        </View>
        <Line />
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onUpdateOnlyOne}>
          <Title>Modifică doar această programare</Title>
        </TouchableOpacity>
        <Line />
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onUpdateAll}>
          <Title>Modifică această programare și toate repetările viitoare</Title>
        </TouchableOpacity>
      </SafeAreaView>
    </ModalOverlay>
  );
};

export default ConfirmUpdateRecurrentBooking;

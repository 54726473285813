import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { Body, Icon, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { FormCard, Row } from '../../../../../../components/shared';

import styles from './styles';

interface ClientNoteCardProps {
  readonly note: ClientNoteHistoryRecord;
  readonly onOptionsPress: (note: ClientNoteHistoryRecord) => void;
}

const ClientNoteCard: React.FC<ClientNoteCardProps> = ({ note, onOptionsPress }) => {
  const date = React.useMemo(() => DateTime.fromJSDate(note.date), [note.date]);
  const dateStr = React.useMemo(() => date.toFormat('d LLL yyyy', { locale: 'ro' }), [date]);
  const onOptionsPressCallback = React.useCallback(() => {
    onOptionsPress(note);
  }, [note, onOptionsPress]);

  return (
    <FormCard dropShaddow style={styles.card} rounded>
      <Row style={styles.container}>
        <View style={styles.iconContainer}>
          <Icon type="client-history-note" size={32} />
        </View>
        <View style={styles.dateWrapper}>
          <Title>{dateStr}</Title>
        </View>
        <TouchableOpacity style={styles.dotsButton} onPress={onOptionsPressCallback}>
          <Icon type="options-dots" />
        </TouchableOpacity>
      </Row>
      <Row style={styles.textWrapper}>
        <Body>{note.payload.text}</Body>
      </Row>
    </FormCard>
  );
};

export default ClientNoteCard;

import React from 'react';

import { Amplitude, TrackingData } from '../analytics/amplitude';

type UseAnalytics = {
  eventName: string;
  screenName: string;
  staticData?: TrackingData;
  data?: TrackingData;
  onDidMount?: boolean;
};

export type Func = () => void;

type OnClickEvent = {
  action: Func;
  eventName: string;
  data?: TrackingData;
};

export type LogAction = (args: OnClickEvent) => Func;

type UseAnalyticsReturn = {
  logEvent: (e: string, data?: TrackingData) => void;
  logAction: LogAction;
  logDidMount: (args?: { data?: TrackingData }) => void;
};

// init libs
let amplitudeJs: Amplitude | undefined;
Amplitude().then((lib) => (amplitudeJs = lib));

export const useAnalytics = ({
  eventName,
  screenName,
  staticData = {},
  data = {},
  onDidMount = true,
}: UseAnalytics): UseAnalyticsReturn => {
  const renderLogged = React.useRef<boolean>(false);

  React.useEffect(() => {
    const init = async () => {
      if (onDidMount) {
        const trackingData = { ...staticData, ...data };

        amplitudeJs?.logEvent(eventName, trackingData);
        renderLogged.current = true;
      }
    };

    init();
  }, []);

  const logEvent = async (eventName: string, data: TrackingData) => {
    const trackingData = { screen_name: screenName, ...{ ...staticData, ...data } };
    amplitudeJs?.logEvent(eventName, trackingData);
  };

  const delayEventLog = async (eventName: string, data: TrackingData) =>
    logEvent(eventName, { screen_name: screenName, ...{ ...staticData, ...data } });

  return {
    logEvent: (eventName, data = {}): void => {
      logEvent(eventName, { screen_name: screenName, ...{ ...staticData, ...data } });
    },
    logAction:
      ({ action, eventName, data = {} }): Func =>
      () => {
        action();
        delayEventLog(eventName, data);
      },
    logDidMount: ({ data = {} } = {}) => {
      if (!renderLogged.current) {
        logEvent(eventName, { ...staticData, ...data });
        renderLogged.current = true;
      }
    },
  };
};

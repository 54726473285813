import * as React from 'react';
import { Linking } from 'react-native';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../../hooks/useAnalytics';

import config from '../../../../config';
import { AuthorizedStackParamList, OnboardingStackParamList, RootStackParamList } from '../../../../types';
import OnboardingSuccessScreenView from './OnboardingSuccessScreenView';

type Props = {
  readonly navigation: CompositeNavigationProp<
    CompositeNavigationProp<
      StackNavigationProp<OnboardingStackParamList, 'OnboardingSuccessScreen'>,
      StackNavigationProp<AuthorizedStackParamList, 'Onboarding'>
    >,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

const NativeOnboardingSuccessScreen: React.FC<Props> = ({ navigation }) => {
  const { logEvent } = useAnalytics({
    eventName: 'pro_onboarding_account_created_screen_shown',
    screenName: 'pro_onboarding_register',
  });

  const navigateCalendar = React.useCallback(() => {
    navigation.navigate('Home', {
      screen: 'HomeTabs',
      params: {
        screen: 'CalendarTab',
        params: {
          screen: 'CalendarScreen',
        },
      },
    });
  }, [navigation]);

  const navigateDownloadApp = React.useCallback(() => {
    Linking.openURL(config.appDownloadUrl);
  }, []);

  return <OnboardingSuccessScreenView onNavigateNext={navigateCalendar} onDownloadApp={navigateDownloadApp} />;
};

export default NativeOnboardingSuccessScreen;

import { AppointmentId, UserAppointment } from '@mero/api-sdk/dist/calendar';
import { Spacer } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList, View } from 'react-native';

import { SmallBody } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientBookingCard from '../ClientBookingCard';
import styles from './styles';

interface ClientBookingsListProps {
  readonly HeaderElement?: React.ReactElement;
  readonly totals: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly appointments: UserAppointment[];
  readonly boostAppointmentId?: AppointmentId;
  readonly now: DateTime;
  readonly onItemPress: (appointment: UserAppointment) => void;
  readonly onEndReached: () => void;
}

interface GroupedAppointments {
  readonly future: UserAppointment[];
  readonly past: UserAppointment[];
}

interface SectionData {
  readonly title: string;
  readonly data: UserAppointment[];
}

const ClientBookingsList: React.FC<ClientBookingsListProps> = ({
  HeaderElement,
  totals,
  appointments,
  now,
  onItemPress,
  onEndReached,
  boostAppointmentId,
}) => {
  const { t } = useTranslation('clients');

  const groupedAppointments: GroupedAppointments = React.useMemo(() => {
    return appointments.reduce<GroupedAppointments>(
      (acc, item) => {
        if (item.start.getTime() < now.toMillis()) {
          acc.past.push(item);
        } else {
          acc.future.push(item);
        }
        return acc;
      },
      { future: [], past: [] },
    );
  }, [appointments, now]);

  const sectionsData: SectionData[] = React.useMemo(() => {
    const data: SectionData[] = [];

    if (groupedAppointments.future.length > 0) {
      data.push({
        title: `${t('upcoming')} (${totals.futureBookingsCount})`,
        data: groupedAppointments.future,
      });
    }

    if (groupedAppointments.past.length > 0) {
      data.push({
        title: `${t('inThePast')} (${totals.pastBookingsCount})`,
        data: groupedAppointments.past,
      });
    }

    return data;
  }, [groupedAppointments]);

  const keyExtractor = (appointment: UserAppointment): string => `${appointment._id}${appointment.occurrenceIndex}`;

  const renderSectionHeader = ({ section: { title } }: { section: SectionData }) => (
    <View>
      <Spacer size="16" />
      <SmallBody style={styles.sectionTitle}>{title}</SmallBody>
      <Spacer size="16" />
    </View>
  );

  return (
    <SectionList
      contentContainerStyle={styles.contentContainer}
      style={styles.container}
      ListHeaderComponent={HeaderElement}
      sections={sectionsData}
      keyExtractor={keyExtractor}
      onEndReachedThreshold={16}
      indicatorStyle="default"
      onEndReached={onEndReached}
      scrollEnabled
      renderSectionHeader={renderSectionHeader}
      renderItem={({ item }) => {
        return <ClientBookingCard appointment={item} onPress={onItemPress} isBoost={boostAppointmentId === item._id} />;
      }}
      keyboardShouldPersistTaps="handled"
      stickySectionHeadersEnabled={false}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există programări" subtitle="Programările clientului vor fi afișate aici" />
      }
    />
  );
};

export default ClientBookingsList;

import * as React from 'react';

import log from '../utils/log';

const usePromise = () => {
  const resolvers = React.useRef<Record<string, () => void>>({});
  const rejects = React.useRef<Record<string, () => void>>({});

  const promise = (name: string, action: () => void): Promise<void> =>
    new Promise((resolve, reject) => {
      action();
      resolvers.current[name] = resolve;
      rejects.current[name] = reject;
    });

  const resolve = (name: string) => {
    if (resolvers.current[name]) {
      resolvers.current[name]();

      delete resolvers.current[name];
      delete rejects.current[name];
    } else {
      log.error(`Invalid promise handler ${name}`, {
        resolvers: Object.keys(resolvers.current),
        rejects: Object.keys(rejects.current),
      });
    }
  };

  const reject = (name: string) => {
    if (rejects.current[name]) {
      rejects.current[name]();

      delete resolvers.current[name];
      delete rejects.current[name];
    } else {
      log.error(`Invalid promise handler ${name}`, {
        resolvers: Object.keys(resolvers.current),
        rejects: Object.keys(rejects.current),
      });
    }
  };

  React.useEffect(() => {
    return () => {
      // Cleanup promises and reject all remaining
      Object.values(rejects.current).forEach((r) => r());
    };
  }, []);

  return { promise, reject, resolve };
};

export default usePromise;

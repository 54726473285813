import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ProCalendarSettingsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProCalendarSettingsScreen';
import ProDetailsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProDetailsScreen';
import ProEditServiceScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProEditServiceScreen';
import ProEditServiceSettingsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProEditServiceSettingsScreen';
import ProProfileDetailsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProProfileDetailsScreen';
import ProScheduleScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProScheduleScreen';
import ProServicesScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProServicesScreen';
import ProServicesSelectScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProServicesSelectScreen';
import ProPermissionsScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProsPermissionsScreen';
import ProsSortScreen from '../screens/Authorized/MenuScreen/screens/ProsDashboardScreen/ProsSortScreen';

import { TransitionPresets, createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { ProsEditStackParamList } from '../types';
import ProsSettingsAddNewStackNavigator from './ProsSettingsAddNewStackNavigator';
import ProsSettingsInviteStackNavigator from './ProsSettingsInviteStackNavigator';
import { styles } from './styles';

const Stack = createStackNavigator<ProsEditStackParamList>();

function ProsEditStackNavigator(): React.ReactElement {
  const { t } = useTranslation('pros');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <Stack.Navigator
      initialRouteName="ProDetails"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="SortPros"
        component={ProsSortScreen}
        options={{ title: t('sortPros'), cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
      />
      <Stack.Screen
        name="ProDetails"
        component={ProDetailsScreen}
        options={{ title: t('profileDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProAddNewStack"
        component={ProsSettingsAddNewStackNavigator}
        options={{ cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProProfile"
        component={ProProfileDetailsScreen}
        options={{ title: t('profileDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProCalendar"
        component={ProCalendarSettingsScreen}
        options={{ title: t('profileCalendar'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProSchedule"
        component={ProScheduleScreen}
        options={{ title: t('proSchedule'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProServices"
        component={ProServicesScreen}
        options={{ title: t('proServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProEditService"
        component={ProEditServiceScreen}
        options={{ title: t('proServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProEditServiceSettings"
        component={ProEditServiceSettingsScreen}
        options={{ title: t('profileCalendar'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="ProPermissions"
        component={ProPermissionsScreen}
        options={{ title: t('proPermissions'), cardStyle: modalStyle }}
      />

      <Stack.Group screenOptions={{ presentation: 'transparentModal' }}>
        <Stack.Screen
          name="ProServicesSelect"
          component={ProServicesSelectScreen}
          options={{ title: t('selectServices'), cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
        />
      </Stack.Group>

      <Stack.Screen
        name="ProInviteStack"
        component={ProsSettingsInviteStackNavigator}
        options={{ cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
      />
    </Stack.Navigator>
  );
}

export default ProsEditStackNavigator;

import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  avoidKeyboard: {
    flex: 1,
  },
  dismissKeyboard: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 32,
    paddingRight: 24,
    paddingBottom: 32,
    paddingLeft: 24,
  },
});

import { RecurrenceRule } from '@mero/api-sdk';
import { BlockedTimeReason } from '@mero/api-sdk/dist/calendar/blocked-time-reason';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as Ap from 'fp-ts/lib/Apply';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';

import log from '../../utils/log';

export const addDefaultEventDuration = (ts: DateTime): DateTime => ts.plus({ milliseconds: 3600000 });
export const subDefaultEventDuration = (ts: DateTime): DateTime => ts.minus({ milliseconds: 3600000 });

const DEFAULT_TIMEZONE = 'Europe/Bucharest';

type ValidBlockedForm = {
  readonly type: 'Valid';
  readonly timezone: Zone | string;
  readonly start: DateTime;
  readonly end: DateTime;
  readonly isFullDay: boolean;
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly performer: SavedWorker;
  readonly reason: BlockedTimeReason;
  readonly allWorkers: SavedWorker[];
};

export type InvalidBlockedTimeForm = {
  readonly type: 'Invalid';
  readonly timezone: Zone | string;
  readonly start?: DateTime;
  readonly end?: DateTime;
  readonly isFullDay: boolean;
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly performer?: SavedWorker;
  readonly reason?: BlockedTimeReason;
  readonly allWorkers: SavedWorker[];
};

export type BlockedTimeFormContextState = InvalidBlockedTimeForm | ValidBlockedForm;

export const isValidReason = (reason: BlockedTimeReason | undefined): reason is Exclude<typeof reason, undefined> =>
  reason !== undefined && (reason.type !== 'custom' || reason.reason.trim().length > 0);

const BlockedReasonCanRepeat: { [K in BlockedTimeReason['type']]: boolean } = {
  break: true,
  dayOff: true,
  vacation: false,
  sickLeave: false,
  training: true,
  custom: true,
};

/**
 * Reset isFullTime based on reason, undefined keeps current option
 */
export const FullDayByReasonType: { [K in BlockedTimeReason['type']]: boolean | undefined } = {
  break: false,
  dayOff: true,
  vacation: undefined,
  sickLeave: undefined,
  training: undefined,
  custom: undefined,
};

export const isRecurrentReason = (reason: BlockedTimeReason | undefined): reason is Exclude<typeof reason, undefined> =>
  reason !== undefined && BlockedReasonCanRepeat[reason.type];

const validate = (state: BlockedTimeFormContextState): BlockedTimeFormContextState =>
  pipe(
    Ap.sequenceT(O.Monad)(
      O.fromNullable(state.start),
      pipe(
        O.fromNullable(state.end),
        O.filter((end) => !state.start || end >= state.start),
      ),
      pipe(state.performer, O.fromNullable),
      pipe(state.reason, O.fromNullable, O.filter(isValidReason)),
    ),
    O.map(
      ([start, end, performer, reason]): ValidBlockedForm => ({
        type: 'Valid',
        performer,
        timezone: state.timezone,
        start: state.isFullDay ? start.startOf('day') : start,
        end: state.isFullDay ? (isDayStart(end) ? end : end.startOf('day').plus({ days: 1 })) : end,
        isFullDay: FullDayByReasonType[reason.type] ?? state.isFullDay,
        recurrenceRule: isRecurrentReason(reason) ? state.recurrenceRule : undefined,
        allWorkers: state.allWorkers,
        reason,
      }),
    ),
    O.getOrElseW(() => state),
  );

/**
 * @param date to check, must be provided in the timezone needed
 * @returns true if {@link date} is at day start, loosely (checking only hour and minute)
 */
export const isDayStart = (date: DateTime): boolean => date.hour === 0 && date.minute === 0;

export const isFullDay = (start: DateTime, end: DateTime, tz: Zone | string): boolean => {
  const startT = start.setZone(tz);
  const endT = end.setZone(tz);

  return isDayStart(startT) && end > start && isDayStart(endT);
};

const defaultState = (): BlockedTimeFormContextState => ({
  type: 'Invalid',
  timezone: DEFAULT_TIMEZONE,
  performer: undefined,
  isFullDay: false,
  allWorkers: [],
});

export const BlockedTimeFormContext = createModelContext(
  defaultState(),
  {
    reset: (
      _,
      payload: {
        readonly timezone?: Zone | string;
        readonly start?: DateTime;
        readonly end?: DateTime;
        readonly isFullDay?: boolean;
        readonly recurrenceRule?: RecurrenceRule.Any;
        readonly performer?: SavedWorker;
        readonly reason?: BlockedTimeReason;
        readonly allWorkers?: SavedWorker[];
      },
    ) => {
      const timezone = payload.timezone ?? DEFAULT_TIMEZONE;
      const start = payload.start?.setZone(timezone);
      const end = payload.end?.setZone(timezone);
      const isFullDay = payload.isFullDay ?? false;

      return validate({
        type: 'Invalid',
        timezone: timezone,
        start: start,
        end: end,
        isFullDay: isFullDay,
        recurrenceRule: payload.recurrenceRule,
        performer: payload.performer,
        reason: payload.reason,
        allWorkers: payload.allWorkers ?? [],
      });
    },
    setStart: (state, start: DateTime) => {
      const newStart = state.isFullDay ? start.setZone(state.timezone).startOf('day') : start.setZone(state.timezone);
      const newEnd =
        state.end === undefined
          ? undefined
          : state.end > newStart
          ? state.isFullDay && !isDayStart(state.end)
            ? state.end.startOf('day').plus({ days: 1 })
            : state.end
          : addDefaultEventDuration(newStart);

      log.debug(
        `BlockedTimeFormContext.setStart: ${start.toISO()}, newStart: ${newStart.toISO()}, newEnd: ${newEnd?.toISO()}`,
      );

      return validate({
        ...state,
        type: 'Invalid',
        start: newStart,
        end: newEnd,
      });
    },
    setEnd: (state, end: DateTime) => {
      const newEnd = state.isFullDay
        ? end.setZone(state.timezone).startOf('day').plus({ days: 1 })
        : end.setZone(state.timezone);

      const newStart =
        state.start === undefined
          ? undefined
          : state.start < end
          ? state.isFullDay && !isDayStart(state.start)
            ? state.start?.startOf('day')
            : state.start
          : subDefaultEventDuration(newEnd);

      log.debug(
        `BlockedTimeFormContext.setEnd: ${end.toISO()}, newEnd: ${newEnd.toISO()}, newStart: ${newStart?.toISO()}`,
      );

      return validate({
        ...state,
        type: 'Invalid',
        start: newStart,
        end: newEnd,
      });
    },
    setIsFullDay: (state, isFullDay: boolean) => {
      log.debug(`BlockedTimeFormContext.setIsFullDay: ${isFullDay}`);

      const newStart = isFullDay ? state.start?.startOf('day') : state.start;
      const newEnd = state.end
        ? isFullDay
          ? isDayStart(state.end)
            ? state.end
            : state.end.startOf('day').plus({ days: 1 })
          : isDayStart(state.end)
          ? state.end.minus({ days: 1 }).endOf('day')
          : state.end
        : undefined;

      return validate({
        ...state,
        type: 'Invalid',
        isFullDay,
        start: newStart,
        end: newEnd,
        reason: state.reason
          ? isFullDay
            ? state.reason.type === 'break'
              ? { type: 'dayOff' }
              : state.reason
            : state.reason.type === 'dayOff'
            ? { type: 'break' }
            : state.reason
          : undefined,
      });
    },
    setPerformer: (state, performer: SavedWorker) =>
      validate({
        ...state,
        type: 'Invalid',
        performer,
      }),
    setRecurrenceRule: (state, recurrenceRule: RecurrenceRule.Any | undefined) => ({
      ...state,
      recurrenceRule,
    }),
    setReason: (state, reason: BlockedTimeReason) =>
      validate({
        ...state,
        reason,
        isFullDay: FullDayByReasonType[reason.type] ?? (reason.type !== state.reason?.type ? false : state.isFullDay),
      }),
    confirmOverride: (state) => ({
      ...state,
      override: true,
    }),
  },
  (dispatch) => {
    return {
      reset: dispatch.reset,
      setStart: dispatch.setStart,
      setEnd: dispatch.setEnd,
      setIsFullDay: dispatch.setIsFullDay,
      setPerformer: dispatch.setPerformer,
      setRecurrenceRule: dispatch.setRecurrenceRule,
      setReason: dispatch.setReason,
      confirmOverride: dispatch.confirmOverride,
    };
  },
);

export const withBlockedTimeFormContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithBlockedTimeFormContextProvider(props: P) {
    return (
      <BlockedTimeFormContext.Provider>
        <Content {...props} />
      </BlockedTimeFormContext.Provider>
    );
  };
};

import { RangePrice } from '@mero/api-sdk/dist/services';

export const BACK_REF = 'https://pro1.mero.ro/404?hideLayout=true';

export const distributeAmount = (
  amount: number,
  firstValue: number,
  totalSlots: number,
  position: number,
  exclude: number[],
) => {
  const remainingAmount = amount - firstValue;
  const remainingSlots = totalSlots - 1 - exclude.length;

  const amountPerSlot = remainingAmount / remainingSlots;
  // const remainder = remainingAmount % remainingSlots;

  // const distributeRemainder = (index: number) => (index < remainder ? 1 : 0);

  return Array.from({ length: totalSlots }, (_, index) =>
    exclude.includes(index) ? 0 : index === position ? firstValue : amountPerSlot,
  );
};

export const findAllIndexes = <T>(array: T[], condition: (element: T) => boolean): number[] => {
  return array.reduce((acc, element, index) => {
    if (condition(element)) {
      return [...acc, index];
    }
    return acc;
  }, [] as number[]);
};

export const formatRangePrice = (price: RangePrice['range']): string =>
  price.from === price.to ? (price.from ?? 'preț variabil').toString() : `${price.from}-${price.to}`;

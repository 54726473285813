import * as React from 'react';
import { Pressable, Text, TextStyle, View } from 'react-native';
import { StyleProp, ViewStyle } from 'react-native';

import { DARK_BLUE, SANTAS_GRAY, WHITE } from '../../../styles/colors';

type ButtonSize = 'large' | 'medium' | 'login' | 'small';

const ButtonSizeValues: { [K in ButtonSize]: number } = {
  large: 51,
  medium: 44,
  login: 44,
  small: 32,
};

const ButtonBorderRadius: { [K in ButtonSize]: number } = {
  large: 25,
  medium: 22,
  login: 22,
  small: 16,
};

const ButtonFontSize: { [K in ButtonSize]: number } = {
  large: 16,
  medium: 14,
  login: 16,
  small: 14,
};

const PaddingValuesRightIcon: { [K in ButtonSize]: number } = {
  large: 16,
  medium: 16,
  login: 12,
  small: 8,
};

const PaddingValuesLeftIcon: { [K in ButtonSize]: number } = {
  large: 48,
  medium: 48,
  login: 36,
  small: 24,
};

const paddingValue = (size: ButtonSize, hasRightIcon: boolean, hasLeftIcon: boolean): number => {
  if (hasRightIcon || !hasLeftIcon) {
    return PaddingValuesRightIcon[size];
  } else {
    return PaddingValuesLeftIcon[size];
  }
};

type Props =
  | {
      text?: string;
      backgroundColor?: string;
      color?: string;
      /**
       * @deprecated use onPress instead
       */
      onClick?: () => void;
      onPress?: () => void;
      disabled?: boolean;
      disabledBackgroundColor?: string;
      disabledColor?: string;
      LeftComponent?: React.ComponentType;

      RightComponent?: React.ComponentType;
      withShadow?: boolean;
      size?: ButtonSize;
      containerStyle?: StyleProp<ViewStyle>;
      testID?: string;
      expand?: boolean;
      padding?: number;
    } & (
      | {
          text: string;
        }
      | { text?: undefined; CenterComponent: React.ComponentType }
    );

const Button: React.FC<Props> = ({
  size = 'large',
  backgroundColor = DARK_BLUE,
  color = WHITE,
  onClick,
  onPress,
  disabled,
  disabledBackgroundColor,
  disabledColor,
  LeftComponent,
  RightComponent,
  withShadow = false,
  containerStyle,
  testID,
  expand = true,
  padding,
  ...rest
}: Props) => {
  const hasLeftIcon = !!LeftComponent;
  const hasRightIcon = !!RightComponent;

  const computedStyle: ViewStyle = React.useMemo(
    () => ({
      borderRadius: ButtonBorderRadius[size],
      alignItems: 'center',
      height: ButtonSizeValues[size],
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      opacity: 1,
      backgroundColor: disabled ? disabledBackgroundColor ?? SANTAS_GRAY : backgroundColor,
      paddingTop: 0,
      paddingRight: padding ? padding : paddingValue(size, hasRightIcon, hasLeftIcon),
      paddingBottom: 0,
      paddingLeft: padding ? padding : paddingValue(size, hasLeftIcon, hasRightIcon),
      ...(withShadow
        ? {
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 5,
            },
            shadowOpacity: 0.15,
            shadowRadius: 3.84,
            elevation: 3,
          }
        : {}),
    }),
    [
      size,
      ButtonBorderRadius,
      ButtonSizeValues,
      disabled,
      disabledBackgroundColor,
      SANTAS_GRAY,
      backgroundColor,
      padding,
      paddingValue,
      hasRightIcon,
      hasLeftIcon,
      withShadow,
    ],
  );

  const computedTextStyle: TextStyle = React.useMemo(
    () => ({
      color: disabled ? disabledColor ?? WHITE : color,
      fontSize: ButtonFontSize[size],
      flex: expand ? 1 : undefined,
      textAlign: 'center',
      fontFamily: 'open-sans-semibold',
    }),
    [disabled, WHITE, color, ButtonFontSize, size, expand],
  );

  const CenterComponent =
    typeof rest.text === 'undefined'
      ? rest.CenterComponent
      : () => (
          <Text style={computedTextStyle} numberOfLines={1} ellipsizeMode="tail">
            {rest.text}
          </Text>
        );

  return (
    <Pressable
      onPress={onPress ?? onClick}
      disabled={disabled}
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.4 : 1,
        },
        containerStyle,
      ]}
      testID={testID}
    >
      <View style={computedStyle}>
        {LeftComponent && <LeftComponent />}
        <CenterComponent />
        {RightComponent && <RightComponent />}
      </View>
    </Pressable>
  );
};

export default Button;

import ReactGA from 'react-ga4';

import config from '../config';
import log from '../utils/log';

type GoogleAnalytics = {
  init: () => void;
};

export const googleAnalytics = ((): GoogleAnalytics => {
  let initialized = false;
  const _init = () => {
    if (!initialized) {
      log.debug(`Google Analytics init ${config.google.analytics}`);
      ReactGA.initialize(config.google.analytics);
      ReactGA.gtag('consent', 'update', {
        analytics_storage: 'granted',
        functionality_storage: 'granted',
      });
      initialized = true;
    }
  };

  return {
    init: () => _init(),
  };
})();

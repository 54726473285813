import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import AttachedServicesScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/AttachedServicesScreen';
import PageGalleryOrderScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/PageGalleryOrderScreen';
import PageGallerySettingsScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/PageGallerySettingsScreen';
import PageMultipleDeleteScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/PageMultipleDeleteScreen';
import SelectedImageMenu from '../screens/Authorized/MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/SelectedImageMenu';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withSelectedImageContext } from '../contexts/SelectedImageContext';
import { PageGallerySettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<PageGallerySettingsStackParamList>();

function PageGallerySettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('gallery');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="PageGallerySettings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="PageGallerySettings"
        component={PageGallerySettingsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageGalleryOrder"
        component={PageGalleryOrderScreen}
        options={{ title: t('orderTitle'), cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
      />
      <Stack.Screen
        name="PageMultipleDelete"
        component={PageMultipleDeleteScreen}
        options={{ title: t('multipleDelete'), cardStyle: modalStyle, ...TransitionPresets.ModalSlideFromBottomIOS }}
      />
      <Stack.Screen
        name="AttachedServices"
        component={AttachedServicesScreen}
        options={{ title: t('imageAttachedServices'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="SelectedImageMenu"
        component={SelectedImageMenu}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default pipe(PageGallerySettingsStackNavigator, withSelectedImageContext);

import { ProductCategory, ProductCategoryId, ProductId, ProductStatus } from '@mero/api-sdk';
import {
  Body,
  Column,
  FormCard,
  Line,
  MeroHeader,
  ModalOverlay,
  Spacer,
  Title,
  colors,
  styles as meroStyles,
  SafeAreaView,
  Button,
  H2s,
  HSpacer,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  ProductsTabStackParamList,
  RootStackParamList,
} from '../../../types';
import { getBrandTitle } from './BrandsContent';
import ProductSummaryCard, { convertProductSummaryData } from './ProductSummaryCard';
import { styles } from './styles';

type Props = {
  route: RouteProp<ProductsTabStackParamList, 'CategoryProductsListScreen'>;
  navigation: CompositeNavigationProp<
    StackNavigationProp<ProductsTabStackParamList, 'CategoryProductsListScreen'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProductsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const CategoryProductsListScreen: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();

  const [searchState, { search, loadMore }] = SearchProductsContext.useContext();
  const [state] = ProductsContext.useContext();

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [category, setCategory] = React.useState<ProductCategory | 'noCategory' | 'inactive' | undefined>();
  const isCustomCategory = category !== 'inactive' && category !== 'noCategory';

  const productsCounter = React.useMemo(() => {
    if (!['Loading', 'Loaded'].includes(searchState.type) || !category) {
      return 0;
    }
    if (category === 'inactive') {
      return searchState.counters.inactive;
    }
    if (category === 'noCategory') {
      return searchState.counters.noCategory;
    }
    return searchState.counters.categories[category._id] ?? 0;
  }, [searchState.type]);

  React.useEffect(() => {
    if (state.type !== 'Loaded') {
      return;
    }
    const currentCategory = route.params.category;
    if (currentCategory === 'inactive') {
      setCategory('inactive');
      return search({ query: { status: ProductStatus.Inactive.code } });
    }

    if (currentCategory === 'noCategory') {
      setCategory('noCategory');
      return search({ query: { categoryId: 'none', status: ProductStatus.Active.code } });
    }

    const found = state.categories.find((category) => category._id === currentCategory);
    if (found) {
      setCategory(found);
      search({ query: { categoryId: found._id, status: ProductStatus.Active.code } });
    }
  }, [state.type]);

  const navigateProductCallback = React.useCallback(
    (productId?: ProductId) => {
      if (productId) {
        navigation.navigate('ProductEdit', { screen: 'ProductMenu', params: { productId } });
      } else {
        navigation.navigate('ProductEdit', { screen: 'ProductScreen' });
      }
    },
    [navigation],
  );

  const navigateCategoryCallback = React.useCallback(
    (categoryId: ProductCategoryId) => {
      navigation.navigate('ProductCategoryScreen', { categoryId });
    },
    [navigation],
  );

  const navigateDeleteCategoryCallback = React.useCallback(
    (categoryId: ProductCategoryId) => {
      navigation.navigate('DeleteCategoryScreen', { categoryId });
    },
    [navigation],
  );

  if (!category) {
    return;
  }

  const categoryName = !isCustomCategory ? t(category) : category.name;

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader
          canGoBack
          onBack={goBack}
          TitleComponent={() => <Title style={{ color: colors.BLACK }}>{categoryName}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          RightComponent={
            isCustomCategory ? (
              <TouchableOpacity
                style={[{ paddingVertical: 12 }, { flexDirection: 'row' }]}
                onPress={() => setShowOptionsMenu(true)}
              >
                <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('options')}</Body>
                <View
                  style={{
                    paddingLeft: 8,
                    justifyContent: 'center',
                  }}
                ></View>
              </TouchableOpacity>
            ) : (
              <HSpacer right={48} />
            )
          }
        />

        {productsCounter !== 0 && (
          <InfiniteScrollView style={{ paddingHorizontal: 16 }} onEndReached={loadMore}>
            <Spacer size={16} />
            {getBrandTitle(categoryName, t, searchState.query.search)}
            <Spacer size={32} />
            <FormCard dropShaddow paddings="none" rounded style={{ paddingHorizontal: 24 }}>
              {searchState.result.products.map((product, index) => (
                <ProductSummaryCard
                  key={product._id}
                  product={convertProductSummaryData(product, t)}
                  showLine={index !== productsCounter - 1}
                  openProductDetails={navigateProductCallback}
                  showCategoryLabel={false}
                />
              ))}
            </FormCard>
            <Spacer size={163} />
          </InfiniteScrollView>
        )}

        {productsCounter === 0 && searchState.type === 'Loaded' && (
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <H2s>Niciun produs</H2s>
            <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
              Produsele adăugate vor apărea aici.
            </Body>
            <Column style={{ paddingTop: 24 }}>
              <Button
                backgroundColor={colors.DARK_BLUE}
                color={colors.ALABASTER}
                size="medium"
                withShadow
                expand={false}
                text="Adaugă produs"
                padding={36}
                onClick={() => navigateProductCallback()}
              />
            </Column>
          </Column>
        )}
      </ModalScreenContainer>

      {showOptionsMenu && (
        <ModalOverlay>
          <MobileWebModalWrapper position="bottom">
            <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
            <Column style={[styles.optionsListContainer]}>
              <SafeAreaView edges={['bottom']}>
                <Column>
                  <TouchableOpacity
                    style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                    delayPressIn={0}
                    onPress={() => {
                      setShowOptionsMenu(false);
                      isCustomCategory && navigateCategoryCallback(category._id);
                    }}
                  >
                    <Title>{t('updateCategory')}</Title>
                  </TouchableOpacity>
                  <Line />

                  <TouchableOpacity
                    style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                    delayPressIn={0}
                    onPress={() => {
                      setShowOptionsMenu(false);
                      isCustomCategory && navigateDeleteCategoryCallback(category._id);
                    }}
                  >
                    <Title style={{ color: colors.RADICAL_RED }}>{t('deleteCategory')}</Title>
                  </TouchableOpacity>
                </Column>
                <Line />
              </SafeAreaView>
            </Column>
          </MobileWebModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default CategoryProductsListScreen;

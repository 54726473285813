import { createModelContext, useAppState } from '@mero/components';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { Platform } from 'react-native';

import { AppStorage } from '../../app-storage';
import log from '../../utils/log';
import { PushSubscriptionStatus } from './push-subscription-status';

type State =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Initializing';
    }
  | {
      readonly type: 'Loaded';
      readonly pushSubscriptionStatus: PushSubscriptionStatus;
      readonly promptDismissed: boolean;
    };

const defaultState = (): State => ({
  type: 'New',
});

const initPushSubscriptionStatus = async (): Promise<PushSubscriptionStatus> => {
  if (Constants.isDevice && Platform.select({ ios: true, android: true, default: false })) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    log.debug('push permissions status', existingStatus);

    if (existingStatus === 'granted') {
      const pushToken = await Notifications.getDevicePushTokenAsync();
      log.debug('push token', pushToken.data);

      return {
        type: 'Subscribed',
        pushToken: pushToken,
      };
    } else if (existingStatus === 'denied') {
      // pass, ass permission
      return {
        type: 'Denied',
      };
    } else if (existingStatus === 'undetermined') {
      // pass, ask permissions
      return {
        type: 'Undetermined',
      };
    } else {
      // FIXME: why TS doesn't check this exhaustively (maybe because it's an enum)
      return {
        type: 'NotSupported',
      };
    }
  } else {
    log.debug('push notifications not supported, not a physical device');
    return {
      type: 'NotSupported',
    };
  }
};

export const PushClientSubscriptionContext = createModelContext(
  defaultState(),
  {
    setLoaded(
      _,
      params: {
        pushSubscriptionStatus: PushSubscriptionStatus;
        promptDismissed: boolean;
      },
    ) {
      return {
        type: 'Loaded',
        pushSubscriptionStatus: params.pushSubscriptionStatus,
        promptDismissed: params.promptDismissed,
      };
    },
    trySetPushToken: (s, pushToken: Notifications.DevicePushToken) => {
      if (s.type === 'Loaded') {
        return {
          type: 'Loaded',
          pushSubscriptionStatus: {
            type: 'Subscribed',
            pushToken: pushToken,
          },
          promptDismissed: s.promptDismissed,
        };
      }

      return s;
    },
    mutate: (s, fn: (s: State) => State): State => fn(s),
  },
  (dispatch) => {
    const registerForPushNotificationsAsync = async (): Promise<Notifications.DevicePushToken | undefined> => {
      let token: Notifications.DevicePushToken | undefined = undefined;
      if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }

        if (finalStatus !== 'granted') {
          log.debug('push notification permission not granted');
          return;
        }

        log.debug('push notifications permission granted, get token and subscribe');
        token = await Notifications.getDevicePushTokenAsync();
        log.debug('got push token', token);
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        }).catch(log.error);
      }

      return token;
    };

    const reload = async (): Promise<void> => {
      try {
        const [pushSubscriptionStatus, notificationsDismissedAt] = await Promise.all([
          initPushSubscriptionStatus(),
          AppStorage.getNotificationsDismissedAt(),
        ]);

        dispatch.setLoaded({
          pushSubscriptionStatus,
          promptDismissed:
            notificationsDismissedAt !== undefined &&
            notificationsDismissedAt.getTime() > new Date().getTime() - 2592000000, // dismissed less than 30 days ago
        });
      } catch (e) {
        log.error('Failed to init push subscription status', e);
        dispatch.setLoaded({
          pushSubscriptionStatus: {
            type: 'NotSupported',
          },
          promptDismissed: true,
        });
      }
      // FIXME: Posh token fetching could theoretically run veeery long, do it in background (https://docs.expo.io/versions/latest/sdk/notifications/)
    };

    return {
      init: (): void => {
        dispatch.mutate((s) => {
          if (s.type === 'New') {
            log.debug('Init PushClientSubscriptionContext');

            reload()
              .then(() => {
                // Subscribe for push token updates
                Notifications.addPushTokenListener((pushToken) => {
                  dispatch.trySetPushToken(pushToken);
                });
              })
              .catch(log.exception);

            return {
              type: 'Initializing',
            };
          } else {
            log.debug('Cannot init PushClientSubscriptionContext: state not New');
            return s;
          }
        });
      },
      reload: (): void => {
        dispatch.mutate((s) => {
          if (s.type === 'Loaded') {
            reload().catch(log.exception);

            return {
              type: 'Initializing',
            };
          } else {
            log.debug(`Cannot reload, expecting Loaded state, got: ${s.type}`);
            return s;
          }
        });
      },
      trySubscribeDevice: (): void => {
        dispatch.mutate((s) => {
          if (s.type === 'Loaded') {
            if (s.pushSubscriptionStatus.type === 'Undetermined') {
              const subscribe = async (): Promise<void> => {
                const token = await registerForPushNotificationsAsync();

                if (token) {
                  log.debug('Got push token');

                  dispatch.setLoaded({
                    pushSubscriptionStatus: {
                      type: 'Subscribed',
                      pushToken: token,
                    },
                    promptDismissed: true,
                  });
                } else {
                  log.warn('Failed to get push token');
                }
              };

              subscribe().catch(log.exception);
            }
          }

          return s;
        });
      },
      dismissPermissionsPrompt: (): void => {
        dispatch.mutate((s) => {
          if (s.type === 'Loaded') {
            AppStorage.setNotificationsDismissedAt(new Date()).catch(log.error);

            dispatch.setLoaded({
              pushSubscriptionStatus: s.pushSubscriptionStatus,
              promptDismissed: true,
            });
          } else {
            log.warn('Cannot dismiss permissions prompt: state !== Loaded');
          }

          return s;
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = PushClientSubscriptionContext.useContext();

  const appState = useAppState();
  const [, { reload: reloadPushSubscription }] = PushClientSubscriptionContext.useContext();

  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Reload push subscription status whenever app becomes active
   */
  React.useEffect(() => {
    if (appState === 'active') {
      log.debug('App became active, reload PushClientSubscriptionContext');
      reloadPushSubscription();
    }
  }, [appState, reloadPushSubscription]);

  return <>{children}</>;
};

export const PushClientSubscriptionContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return (
    <PushClientSubscriptionContext.Provider>
      <ContextInit>{children}</ContextInit>
    </PushClientSubscriptionContext.Provider>
  );
};

export const withPushClientSubscriptionContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithPushClientSubscriptionContextProvider(props: P) {
    return (
      <PushClientSubscriptionContextProvider>
        <Content {...props} />
      </PushClientSubscriptionContextProvider>
    );
  };
};

import { Column } from '@mero/components';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { styles } from './PageContainer.styles';

type Props = React.PropsWithChildren<{
  /**
   * Additional styles to apply to container view (SafeArea)
   */
  readonly style?: StyleProp<ViewStyle>;
}>;

/**
 * Container view for a modal screen.
 * Contains safe area paddings.
 * On web has rounded corners and.
 */
const PageContainer: React.FunctionComponent<Props> = ({ style, children }) => {
  const { isPhone } = useMediaQueries();

  if (isPhone) {
    // Fullscreen view on phones
    return <Column style={[styles.screenContainer, style]}>{children}</Column>;
  } else {
    // Modal view on desktop or tablets - no safe area
    return <Column style={[styles.screenContainer, styles.roundedCorners, style]}>{children}</Column>;
  }
};

export default PageContainer;

import { SavedCheckoutCompany } from '@mero/api-sdk';
import { colors } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import MeroHeader from '@mero/components/lib/components/MeroHeader';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutStackParamList, CheckoutSubStackParamList } from '../../../types';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & CheckoutSubStackParamList, 'CompanyScreen'>;
const CompanyScreen: React.FC<Props> = ({ page }) => {
  const [, { reloadCompanies }] = CheckoutsContext.useContext();
  const [formState, { setCompany }] = CheckoutFormContext.useContext();

  const { t } = useTranslation('checkout');

  const goBack = useGoBack();

  const onChange = async (company?: SavedCheckoutCompany) => {
    await reloadCompanies(page.details._id);
    // if (company) {
    //   setCompany(company);
    // }
    goBack();
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canClose onClose={goBack} title={t('addNewCompany')} />
        {/*<CompanyComponent page={page} onSave={onChange} onDelete={onChange} />*/}
      </ModalScreenContainer>
    </>
  );
};

export default pipe(CompanyScreen, CurrentBusiness, Authorized);

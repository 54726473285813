import { Button, Spacer, H1, styles as meroStyles, Body } from '@mero/components';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as React from 'react';

import ModalScreenContainer from '../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AppStorage } from '../../app-storage';
import { AuthContext } from '../../contexts/AuthContext';
import { RootStackParamList } from '../../types';
import { decodeToken } from '../../utils/jwt';
import log from '../../utils/log';
import { DecodedToken } from '../../utils/types';

type Props = {
  readonly navigation: StackNavigationProp<RootStackParamList, 'ImpersonateScreen'>;
  readonly route: RouteProp<RootStackParamList, 'ImpersonateScreen'>;
};

const ImpersonateScreen: React.FC<Props> = ({ navigation, route }): React.ReactElement => {
  const [, authDispatch] = AuthContext.useContext();

  const token = route.params?.token;

  React.useEffect(() => {
    route.params?.pageId && AppStorage.setCurrentUserPageId(route.params?.pageId);
  }, [route]);

  const goHome = React.useCallback(() => {
    navigation.replace('Index');
  }, [navigation]);

  React.useEffect(() => {
    let isMounted = true;

    const login = async (token: string) => {
      try {
        await authDispatch.signInWith(token);
        if (isMounted) {
          goHome();
        }

        const user = pipe(
          decodeToken(token),
          DecodedToken.decode,
          E.getOrElseW(() => null),
        );
        if (user) {
          await AppStorage.setImpersonatedUserId(user._id);
        }
      } catch (error) {
        log.error('Failed to login with token', error);
      }
    };

    if (token) {
      login(token);
    }

    return () => {
      isMounted = false;
    };
  }, [token]);

  return (
    <ModalScreenContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
      <H1 style={meroStyles.text.alignCenter}>Autentificare Implicita</H1>
      <Spacer size="m" />
      {!token && <Body style={meroStyles.text.alignCenter}>Tokenul nu a fost specificat</Body>}
      <Spacer size="m" />
      <Button text="Acasă" size="small" onClick={goHome} />
    </ModalScreenContainer>
  );
};

export default ImpersonateScreen;

import { ClientProfile } from '@mero/api-sdk/dist/clients';
import {
  Avatar,
  Body,
  colors,
  Column,
  ConfirmBox,
  H1,
  HSpacer,
  Row,
  SimpleListItem,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SmallBody from '@mero/components/lib/components/Text/SmallBody';

import config from '../../../../config';
import { meroApi } from '../../../../contexts/AuthContext';
import log from '../../../../utils/log';

export type Props = {
  readonly client: Pick<ClientProfile, 'boostStatus' | 'pageId'> & {
    user: Pick<ClientProfile['user'], '_id' | 'firstname' | 'lastname' | 'photo'>;
  };
  readonly onDismiss: () => void;
  readonly onConfirm: () => void;
};

/**
 * Client removal confirmation modal
 */
const ClientViewCommissionModal: React.FC<Props> = ({
  client,
  onDismiss,
  onConfirm,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('clients');

  const showError = useShowError();

  const [inProcess, setInProcess] = React.useState(false);

  const [checks, setChecks] = React.useState({
    check1: false,
  });

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  const leftAction = {
    text: t('close'),
    onPress: inProcess ? undefined : onDismiss,
  };

  const submit = async () => {
    setInProcess(true);
    try {
      await meroApi.clients.acceptBoostClientCommission({
        pageId: client.pageId,
        userId: client.user._id,
      });

      await onConfirm();
    } catch (error) {
      log.error('Error while confirming commission', error);
      onDismiss();
      showError(error, t('enableCommissionError'));
    } finally {
      setInProcess(false);
    }
  };

  const rightAction = {
    text: t('confirm'),
    onPress:
      Object.values(checks).some((v) => !v) ||
      inProcess ||
      !(client.boostStatus.isBoost && client.boostStatus.boostCost !== undefined)
        ? undefined
        : submit,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="info"
        headerTitle={t('claimBoostClientTitle')}
        canClose={!inProcess}
        onClose={inProcess ? undefined : onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
        style={{ maxWidth: 343 + 32 }}
      >
        <H1>{t('acceptClaim')}</H1>
        <Spacer size="8" />
        <Body>{t('acceptClaimDescription')}</Body>
        <Spacer size="24" />
        <Row>
          <Avatar
            firstname={client.user.firstname ?? ''}
            lastname={client.user.lastname ?? ''}
            size={48}
            source={client.user.photo?.thumbnail}
          />
          <HSpacer left={16} />
          <Column>
            <Title numberOfLines={1} ellipsizeMode="tail">
              {`${client.user.firstname ?? ''} ${client.user.lastname ?? ''}`.trim() || 'Fără nume'}{' '}
            </Title>
            <Spacer size={2} />
            {client.boostStatus.isBoost && client.boostStatus.boostCost !== undefined ? (
              <SmallBody style={{ color: colors.COMET }} numberOfLines={1} ellipsizeMode="tail">
                {t('boostDetails', { profit: client.boostStatus.boostProfit ?? 0, cost: client.boostStatus.boostCost })}
              </SmallBody>
            ) : null}
          </Column>
        </Row>
        <Spacer size="24" />
        <TouchableOpacity onPress={toggleCheck('check1')} disabled={inProcess}>
          <SimpleListItem
            icon={checks.check1 ? 'checked' : 'unchecked'}
            iconColor={inProcess ? colors.SANTAS_GRAY : colors.DARK_BLUE}
            paddingTop={0}
            paddingBottom={0}
          >
            <Body>
              {client.boostStatus.isBoost && client.boostStatus.boostCost !== undefined && (
                <Trans
                  ns={'clients'}
                  t={t}
                  i18nKey="acceptClaimCheck1"
                  values={{ amount: client.boostStatus.boostCost }}
                >
                  0<Title>1</Title>2
                  <Body
                    style={{ color: colors.DARK_BLUE, textDecorationLine: 'underline' }}
                    onPress={() => Linking.openURL(config.tosLinkUrl)}
                  >
                    3
                  </Body>
                </Trans>
              )}
            </Body>
          </SimpleListItem>
        </TouchableOpacity>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ClientViewCommissionModal;

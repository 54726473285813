import { sizes, colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  optionsListContainer: {
    marginTop: 0,
    paddingHorizontal: 4,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  optionsMenu: {
    flex: 1,
    flexDirection: 'column',
    maxWidth: 420,
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
    paddingTop: sizes[16],
    paddingBottom: sizes[16],
  },
  optionsStretchContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
});

import { colors, Label, Row, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export type Props = {
  style?: StyleProp<ViewStyle>;
  LeftComponent?: React.ReactNode;
  RightComponent?: React.ReactNode;
} & (
  | {
      type: 'info' | 'warning' | 'error' | 'success';
    }
  | { type: 'custom'; color: string; backgroundColor: string }
) &
  (
    | {
        text: string;
      }
    | {
        text?: undefined;
        CenterComponent: React.ReactNode;
      }
  );

export const FlashyLabel: React.FC<Props> = ({ LeftComponent, RightComponent, style, ...props }) => {
  const { color, backgroundColor } = React.useMemo(() => {
    switch (props.type) {
      case 'info':
        return { color: colors.COMET, backgroundColor: '#eaecef' };
      case 'warning':
        return { color: colors.OUTRAGEOUS_ORANGE, backgroundColor: '#FBEBE7' };
      case 'error':
        return { color: colors.RADICAL_RED, backgroundColor: '#FAE6EA' };
      case 'success':
        return { color: colors.SHAMROCK, backgroundColor: '#E9FAF3' };
      case 'custom':
        return { color: props.color, backgroundColor: props.backgroundColor };
    }
  }, [props.type]);

  const CenterComponent =
    typeof props.text === 'undefined' ? (
      props.CenterComponent
    ) : (
      <Label style={[meroStyles.text.semibold, { marginLeft: 2, color }]}>{props.text}</Label>
    );

  return (
    <Row
      style={[
        {
          alignSelf: 'flex-start',
          backgroundColor,
          paddingHorizontal: 8,
          paddingVertical: 4,
          borderRadius: 4,
          alignItems: 'center',
        },
        style,
      ]}
    >
      {LeftComponent}
      {CenterComponent}
      {RightComponent}
    </Row>
  );
};

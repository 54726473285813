import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { UserId } from '@mero/api-sdk/dist/users';
import { InputWithLabel } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@mero/components/lib/components/Select';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { nameGenerator } from '../../../utils/string';

export type Props = AuthorizedProps &
  CurrentBusinessProps & {
    value?: UserId;
    onUpdate: (value: CheckoutUserPreview) => void;
  };
const SelectPro: React.FC<Props> = ({ page, value, onUpdate }) => {
  const { t } = useTranslation('checkout');

  const pros = React.useMemo(
    () =>
      page?.members.map((worker) => ({
        label: nameGenerator(worker.user.profile, t('unknownUser')),
        value: worker.user._id,
      })),
    [page],
  );

  const selectPro = React.useCallback(
    (userId: UserId) => {
      const selectedPro = page.members.find((pro) => pro.user._id === userId);

      if (selectedPro) {
        onUpdate({
          _id: selectedPro.user._id,
          // FIXME: checkout request will fail for (legacy) page members that have no phone number
          phone: selectedPro.user.phone ?? '',
          profile: {
            firstname: selectedPro.user.profile.firstname,
            lastname: selectedPro.user.profile.lastname,
            photo: selectedPro.user.profile.photo,
          },
        });
      }
    },
    [pros],
  );

  React.useEffect(() => {
    if (pros?.length > 0 && !value) {
      selectPro(pros[0].value);
    }
  }, [pros]);

  return (
    <InputWithLabel label={t('selectPro')}>
      <Select items={pros} value={value} onChange={selectPro} />
    </InputWithLabel>
  );
};

export default pipe(SelectPro, CurrentBusiness, Authorized);

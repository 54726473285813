import { colors, Column, Row, SmallBody, Spacer } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, TextInput } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

import { roundToDecimals } from '../../../utils/number';
import { localeNumberValidator, localeStringToNumber, replaceDecimalSeparator } from '../../../utils/scaled';

export const MinusIcon = (props: SvgProps) => (
  <Svg width={10.5} height={1.313} {...props}>
    <Path fill="#080de0" d="M9.844 1.313H.656a.656.656 0 1 1 0-1.312h9.187a.656.656 0 1 1 0 1.313Z" />
  </Svg>
);

export const PlusIcon = (props: SvgProps) => (
  <Svg width={10.5} height={10.5} {...props}>
    <Path
      fill="#080de0"
      d="M9.844 4.594H5.906V.656a.656.656 0 1 0-1.312 0v3.938H.656a.656.656 0 1 0 0 1.312h3.938v3.937a.656.656 0 1 0 1.312 0V5.906h3.937a.656.656 0 1 0 0-1.312Z"
    />
  </Svg>
);

const ArrowsIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 9078" />
    <Path fill="#52577f" d="M6.99 11 3 15l3.99 4v-3H14v-2H6.99ZM21 9l-3.99-4v3H10v2h7.01v3Z" data-name="Path 9079" />
  </Svg>
);

export const getValue = (price: number, percentage: number) => {
  return (percentage * price) / 100;
};

export const getPercentage = (price: number, value: number) => {
  return (value * 100) / price;
};

const stringToNumber = (value: string, topLimit: number, bottomLimit: number) => {
  const parsedValue = localeStringToNumber(value);

  if (isNaN(parsedValue)) {
    return bottomLimit;
  }

  if (parsedValue > topLimit) {
    return topLimit;
  }

  if (parsedValue < bottomLimit) {
    return bottomLimit;
  }

  return roundToDecimals(parsedValue);
};

export type Props = {
  price: number;
  type: 'percentage' | 'value';
  percentage: number;
  value: number;
  onUpdate: (value: number, percentage: number, type: 'value' | 'percentage') => void;
};
const DiscountComponent: React.FC<Props> = ({ percentage, type, price, value, onUpdate }) => {
  const { t } = useTranslation('checkout');

  const [discountType, setDiscountType] = React.useState<'percentage' | 'value'>(type);
  const [discountPercentage, setDiscountPercentage] = React.useState(percentage ?? 0);
  const [tempDiscountPercentage, setTempDiscountPercentage] = React.useState(
    roundToDecimals(percentage, 0).toString() ?? '0',
  );
  const discountPercentageTimer = React.useRef(0);

  const [discountValue, setDiscountValue] = React.useState(value ?? 0);
  const [tempDiscountValue, setTempDiscountValue] = React.useState(roundToDecimals(value, 2).toString() ?? '0');
  const discountValueTimer = React.useRef(0);

  const priceTimer = React.useRef(0);

  const updateDiscountPercentage = (value: string = tempDiscountPercentage) => {
    setDiscountType('percentage');
    window.clearTimeout(discountPercentageTimer.current);

    const parsedValue = stringToNumber(value, 100, 0);

    setDiscountPercentage(parsedValue);
    setTempDiscountPercentage(parsedValue.toLocaleString());

    const computedValue = roundToDecimals(getValue(price, parsedValue));
    setDiscountValue(computedValue);
    setTempDiscountValue(computedValue.toLocaleString());
  };

  const updateTempDiscountPercentage = (value: string) => {
    setTempDiscountPercentage(value);
    window.clearTimeout(discountPercentageTimer.current);
    discountPercentageTimer.current = window.setTimeout(() => {
      updateDiscountPercentage(value);
    }, 500);
  };

  const updateDiscountValue = (value: string = tempDiscountValue) => {
    setDiscountType('value');
    window.clearTimeout(discountValueTimer.current);

    const parsedValue = stringToNumber(value, price, 0);

    setDiscountValue(parsedValue);
    setTempDiscountValue(parsedValue.toLocaleString());

    const computedValue = roundToDecimals(getPercentage(price, parsedValue));
    setDiscountPercentage(computedValue);
    setTempDiscountPercentage(computedValue.toLocaleString());
  };

  const updateTempDiscountValue = (value: string) => {
    setTempDiscountValue(value);
    window.clearTimeout(discountValueTimer.current);
    discountValueTimer.current = window.setTimeout(() => {
      updateDiscountValue(value);
    }, 500);
  };

  const incrementValue = (type: 'percentage' | 'value') => () => {
    if (type === 'percentage') {
      updateDiscountPercentage((discountPercentage + 1).toString());
    } else {
      updateDiscountValue((discountValue + 1).toString());
    }
  };

  const decrementValue = (type: 'percentage' | 'value') => () => {
    if (type === 'percentage') {
      updateDiscountPercentage((discountPercentage - 1).toLocaleString());
    } else {
      updateDiscountValue((discountValue - 1).toLocaleString());
    }
  };

  React.useEffect(() => {
    window.clearTimeout(priceTimer.current);
    priceTimer.current = window.setTimeout(() => {
      discountType === 'value' ? updateDiscountValue() : updateDiscountPercentage();
    }, 500);
  }, [price, discountType]);

  React.useEffect(() => {
    onUpdate(discountValue, discountPercentage, discountType);
  }, [discountPercentage, discountValue]);

  const numberValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  return (
    <Row style={{ alignItems: 'center' }}>
      <Column style={{ flex: 1 }}>
        <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('discountValue')}</SmallBody>
        <Spacer size={8} />
        <Column
          style={{
            flex: 1,
            padding: 8,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: colors.GEYSER,
            justifyContent: 'center',
            height: 42,
          }}
        >
          <TextInput
            style={{ flex: 1, fontSize: 16, lineHeight: 22, fontFamily: 'open-sans', paddingHorizontal: 6 }}
            value={tempDiscountValue}
            onChangeText={setTempDiscountValue}
            onBlur={flow(() => numberValidator(discountValue.toLocaleString())(tempDiscountValue), updateDiscountValue)}
            keyboardType={'numeric'}
          />
        </Column>
      </Column>
      <Column style={{ paddingHorizontal: 8, marginTop: 29 }}>
        <ArrowsIcon />
      </Column>
      <Column style={{ flex: 1 }}>
        <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{t('discountPercentage')}</SmallBody>
        <Spacer size={8} />
        <Row
          style={{
            padding: 8,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: colors.GEYSER,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TouchableOpacity
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              backgroundColor: colors.SKY_BLUE,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={decrementValue('percentage')}
          >
            <MinusIcon />
          </TouchableOpacity>
          <Column style={{ flex: 1, paddingHorizontal: 4 }}>
            <TextInput
              style={{ flex: 1, textAlign: 'center', fontSize: 16, lineHeight: 22, fontFamily: 'open-sans' }}
              value={tempDiscountPercentage}
              onChangeText={setTempDiscountPercentage}
              onBlur={flow(
                () => numberValidator(discountPercentage.toLocaleString())(tempDiscountPercentage),
                updateDiscountPercentage,
              )}
              keyboardType={'numeric'}
            />
          </Column>
          <TouchableOpacity
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              backgroundColor: colors.SKY_BLUE,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={incrementValue('percentage')}
          >
            <PlusIcon />
          </TouchableOpacity>
        </Row>
      </Column>
    </Row>
  );
};

export default DiscountComponent;

import { Body, H2s, sizes, Spacer, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

type Props = React.PropsWithChildren<{
  readonly title: string;
  readonly subtitle?: string;
  readonly style?: StyleProp<ViewStyle>;
}>;

const ClientEmptyListView: React.FC<Props> = ({ title, subtitle, style, children }) => {
  return (
    <View
      style={[
        {
          paddingTop: sizes[32],
          paddingRight: sizes[16],
          paddingBottom: sizes[48],
          paddingLeft: sizes[16],
          flexDirection: 'column',
          alignItems: 'center',
        },
        style,
      ]}
    >
      <H2s style={meroStyles.text.alignCenter}>{title}</H2s>
      {subtitle ? (
        <>
          <Spacer size="8" />
          <Body style={meroStyles.text.alignCenter}>{subtitle}</Body>
        </>
      ) : null}
      {children}
    </View>
  );
};

export default ClientEmptyListView;

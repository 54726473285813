import * as React from 'react';

type Props<T> = {
  data: T[];
  renderItem: ({ item }: { item: T }) => React.ReactNode;
  keyExtractor: (item: T) => string;
  ItemSeparatorComponent?: () => React.ReactNode;
};

const StaticFlatList = <T,>({ data, renderItem, keyExtractor, ItemSeparatorComponent }: Props<T>) => {
  const lastElementIndex = data.length - 1;
  return (
    <>
      {data.map((item, index) => (
        <React.Fragment key={keyExtractor(item)}>
          {renderItem({ item })}
          {index !== lastElementIndex && ItemSeparatorComponent ? ItemSeparatorComponent() : null}
        </React.Fragment>
      ))}
    </>
  );
};

export default StaticFlatList;

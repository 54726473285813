import { ClientId } from '@mero/api-sdk';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { colors, Column, H2s, Icon, Label, Row, SmallBody, Spacer, styles as meroStyles } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import Svg, { Circle, G, Path } from 'react-native-svg';

import { FormCard } from '../../../../../../components/shared';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ClientStackParamList } from '../../../../../../types';
import { scaledToString } from '../../../../../../utils/scaled';
import styles from './styles';

export const MembershipIcon = () => (
  <Svg width={32} height={32}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" transform="translate(0 .5)" />
      <G fill="none">
        <Path d="M5.5 6h21v21h-21Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(8.125 10.594)"
        >
          <Path d="M12.9 0v.258a1.29 1.29 0 1 1-2.58 0V0H5.159v.258a1.29 1.29 0 0 1-2.58 0V0H0v11.35h15.478V0ZM9.287 4.127h4.127M9.287 6.191h4.127M9.287 8.254h2.064" />
          <Circle cx={1.398} cy={1.398} r={1.398} data-name="layer1" transform="translate(3.265 3.355)" />
          <Path
            d="M4.643 6.191a2.605 2.605 0 0 0-2.578 2.657v.443h5.159v-.439a2.605 2.605 0 0 0-2.581-2.661Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

type ClientMembershipCardProps = {
  readonly clientId: ClientId;
  readonly item: PurchasedMembership<MeroUnits.Any>;
  readonly onPress: () => void;
};

const ClientMembershipCard: React.FC<ClientMembershipCardProps> = ({ onPress, item }) => {
  const { t } = useTranslation('clients');
  const navigation = useNavigation<NavigationProp<ClientStackParamList>>();

  const { status, color } = React.useMemo(() => {
    if (!item) {
      return { status: '', color: colors.COMET };
    }
    const status = PurchasedMembership.getMembershipStatus({
      status: item.status,
      validFor: item.template.validFor,
    });

    return {
      status: status,
      color:
        status === 'Cancelled' || status === 'Expired'
          ? colors.RADICAL_RED
          : status === 'Active'
          ? colors.SHAMROCK
          : colors.COMET,
    };
  }, [item]);
  const hasDebt = React.useMemo(() => ScaledNumber.toNumber(item.debt.amount) > 0, [item.debt]);

  return (
    <FormCard dropShaddow style={styles.container} rounded paddings="none">
      <Pressable style={styles.button} onPress={onPress}>
        <Column>
          <MembershipIcon />
        </Column>
        <Column style={styles.content}>
          <Row>
            <Column flex={1}>
              <Label style={[{ color }, meroStyles.text.semibold]}>{t(status)}</Label>
              <H2s>{item.template.name}</H2s>
              <Spacer size={6} />
              <SmallBody>
                {DateTime.fromJSDate(item.template.validFor.from).setLocale('ro').toFormat('dd MMM yyyy')} -{' '}
                {item.template.validFor.type === 'Unlimited'
                  ? t('Unlimited')
                  : DateTime.fromJSDate(item.template.validFor.to).setLocale('ro').toFormat('dd MMM yyyy')}
              </SmallBody>
            </Column>
            <Column>
              <Icon type="next" color={colors.DARK_BLUE} />
            </Column>
          </Row>
          {hasDebt && (
            <Row style={styles.debtWrapper}>
              <Label style={styles.debtAmount}>{t('debtAmount')}</Label>
              <Label style={[styles.debtAmountValue, meroStyles.text.semibold]}>
                {scaledToString(item.debt.amount)} {t(item.debt.unit)}
              </Label>
            </Row>
          )}
        </Column>
      </Pressable>
    </FormCard>
  );
};

export default ClientMembershipCard;

import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientNoteCard from '../ClientNoteCard';
import styles from './styles';

interface ClientNotesListProps {
  readonly HeaderElement?: React.ReactElement;
  readonly notes: ClientNoteHistoryRecord[];
  readonly onNoteOptionsPress: (note: ClientNoteHistoryRecord) => void;
}

const ClientNotesList: React.FC<ClientNotesListProps> = ({ HeaderElement, notes, onNoteOptionsPress }) => {
  const renderItem = React.useCallback(({ item }: { item: ClientNoteHistoryRecord }) => {
    return <ClientNoteCard note={item} onOptionsPress={onNoteOptionsPress} />;
  }, []);

  const keyExtractor = (note: ClientNoteHistoryRecord): string => note._id;

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={HeaderElement}
      data={notes}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există notițe" subtitle="Notițele clientului vor fi afișate aici" />
      }
    />
  );
};

export default ClientNotesList;

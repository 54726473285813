import * as MeroApi from '@mero/api-sdk';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export const GrantResponseEntity = new t.Type<MeroApi.users.GrantResponse, string, unknown>(
  'GrantResponseEntity',
  MeroApi.users.GrantResponse.is,
  (i, context) =>
    pipe(
      i,
      t.string.decode,
      E.chain((s) => {
        try {
          return MeroApi.users.GrantResponse.decode(JSON.parse(s));
        } catch {
          return t.failure(i, context, `failed to parse GrantResponseEntity JSON from: ${s.substr(0, 64)}`);
        }
      }),
    ),
  ({ tokenType, expiresIn, accessToken, state, refreshToken }) => {
    // avoid "ghost" fields
    const entity: MeroApi.users.GrantResponse = {
      tokenType,
      expiresIn,
      accessToken,
      state,
      refreshToken,
    };

    return JSON.stringify(entity);
  },
);

export type GrantResponseEntity = t.TypeOf<typeof GrantResponseEntity>;

import { Line, SmallBody, Spacer, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { styles } from './AddProMenu.styles';

export type Props = {
  onAddPro: () => void;
  onInvitePro: () => void;
  onDismiss: () => void;
};

const AddProMenu: React.FC<Props> = ({ onAddPro, onInvitePro, onDismiss }: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('pros');

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onAddPro}>
              <Title>{t('addPro')}</Title>
            </TouchableOpacity>
            <Line />
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onInvitePro}>
              <Title>{t('invitePro')}</Title>
              <Spacer size={2} />
              <SmallBody>{t('inviteProSubtitle')}</SmallBody>
            </TouchableOpacity>
            <Line />
          </SafeAreaView>
        </View>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default AddProMenu;

import { SavedWorker, WorkerId, Worker } from '@mero/api-sdk/dist/workers';
import { MeroHeader, colors, SimpleListItem, Line, FormCard, H1, Spacer, Body, Button } from '@mero/components';
import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import { AuthorizedStackParamList } from '../../../types';
import { styles } from './SelectProScreen.styles';

type WorkerItemProps = {
  isInvited: boolean;
  worker: SavedWorker;
  onPress: (worker: SavedWorker) => void;
  checked: boolean;
};

const WorkerItem: React.FC<WorkerItemProps> = React.memo(function WorkerItemComponent({
  isInvited,
  worker,
  onPress,
  checked,
}: WorkerItemProps) {
  return (
    <TouchableOpacity onPress={() => onPress(worker)}>
      <SimpleListItem
        title={`${worker.user.firstname ?? ''} ${worker.user.lastname ?? ''}`}
        subtitle={isInvited ? 'Profesionist invitat' : 'Profesionist'}
        icon={checked ? 'checked' : 'unchecked'}
        iconColor={colors.DARK_BLUE}
        iconPosition="left"
        alignContent="middle"
        image={{
          firstname: worker.user.firstname,
          lastname: worker.user.lastname,
          src: worker.profilePhoto?.medium,
        }}
      />
    </TouchableOpacity>
  );
});

const workerItemKey = (item: SavedWorker): string => item._id;

const WorkerItemSeparator: React.FC = () => <Line />;

export type Props = StackScreenProps<AuthorizedStackParamList, 'SelectPro'>;

const SelectProScreen: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [businessState] = CurrentBusinessContext.useContext();
  const [serviceState, { changeActiveWorkers, reload }] = ServicesContext.useContext();

  const { categoryName, serviceName } = route.params;

  const toggleSelectAllCallback = () => {
    if (businessState.type !== 'Loaded') {
      return;
    }
    if (isAllSelected) {
      setSelectedPros([]);
    } else {
      setSelectedPros(businessState.page.workers.map((w) => w._id));
    }
  };

  const saveChanges = () => {
    changeActiveWorkers(selectedPros);
    goBack();
  };

  const addPro = (workerId: SavedWorker) => {
    setSelectedPros([...selectedPros, workerId._id]);
  };

  const removePro = (workerId: SavedWorker) => {
    setSelectedPros(selectedPros.filter((w) => w !== workerId._id));
  };

  const [selectedPros, setSelectedPros] = React.useState<WorkerId[]>([]);

  const isAllSelected =
    businessState.type === 'Loaded' ? selectedPros.length === businessState.page.workers.length : true;

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      reload({ pageId: pageState.page.details._id });
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (businessState.type === 'Loaded' && serviceState.type === 'Loaded') {
      setSelectedPros(
        businessState.page.workers.filter((w) => serviceState.editInfo.workers.includes(w._id)).map((w) => w._id),
      );
    }
  }, [businessState.type, serviceState.type]);

  if (businessState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title="Echipă" />
      <ScrollView bounces={false} showsVerticalScrollIndicator={false} style={[styles.formMargin, styles.flex]}>
        <H1 style={{ paddingHorizontal: 8 }}>Echipă</H1>
        <Spacer size={6} />
        <Body style={{ paddingHorizontal: 8, paddingBottom: 32, paddingTop: 8 }}>
          Alege cine poate primi programări pentru{' '}
          <Body style={{ fontFamily: 'open-sans-bold' }}>{serviceName ?? categoryName}</Body>
        </Body>

        <FormCard dropShaddow rounded paddings="button">
          {businessState.page.workers.length > 1 && (
            <>
              <TouchableOpacity onPress={toggleSelectAllCallback}>
                <SimpleListItem
                  title="Toți profesioniștii"
                  icon={isAllSelected ? 'checked' : 'unchecked'}
                  iconColor={colors.DARK_BLUE}
                  iconPosition="left"
                  alignContent="middle"
                />
              </TouchableOpacity>
              <Line />
            </>
          )}
          {businessState.page.workers.map((worker, index) => {
            const isActive = selectedPros.includes(worker._id);
            const isInvited =
              businessState.type === 'Loaded' ? Worker.isInvited(worker, businessState.page.details) : false;
            return (
              <>
                <WorkerItem
                  key={worker._id}
                  worker={worker}
                  isInvited={isInvited}
                  onPress={isActive ? removePro : addPro}
                  checked={isActive}
                />
                {index !== businessState.page.workers.length - 1 && <WorkerItemSeparator />}
              </>
            );
          })}
        </FormCard>
        <Spacer size={144} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text="OK" onClick={saveChanges} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text="OK" onClick={saveChanges} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default SelectProScreen;

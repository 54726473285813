import { ServiceGroup } from '@mero/api-sdk/dist/services';
import {
  Body,
  colors,
  ConfirmBox,
  DismissKeyboard,
  H1,
  ModalOverlay,
  SmallBody,
  Spacer,
  TypeSafeTextInput,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { KeyboardAvoidingView } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: (newName: string) => void;
  onCancel: () => void;
  category: ServiceGroup;
};

const EditCategoryDialog: React.FC<Props> = ({ onSuccess, onCancel, category }) => {
  const { isDesktop } = useMediaQueries();
  const [pageState] = CurrentBusinessContext.useContext();

  const [showErrors, setShowErrors] = React.useState(false);
  const [saveInProgress, setSaveInProgress] = React.useState(false);

  const [categoryName, setCategoryName] = React.useState({
    input: category.name,
    decoded: NonEmptyString.decode(category.name),
  });
  const categoryNameValid = E.isRight(categoryName.decoded);

  const cancelEditCategory = () => {
    onCancel();
  };

  const editCategory = async () => {
    try {
      if (categoryNameValid && pageState.type === 'Loaded') {
        setSaveInProgress(true);
        await meroApi.pages.updateServiceGroup({
          pageId: pageState.page.details._id,
          groupId: category._id,
          name: categoryName.input,
        });
        onSuccess(categoryName.input);
      } else {
        setShowErrors(true);
      }
    } catch (error) {
    } finally {
      setSaveInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: 'Anulează',
    onPress: cancelEditCategory,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: 'Modifică',
    onPress: editCategory,
    flex: 15,
  };
  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="center">
        <DismissKeyboard
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KeyboardAvoidingView
            style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}
          >
            <ConfirmBox
              type="info"
              headerTitle="Modifică nume categorie"
              canClose={!saveInProgress}
              onClose={cancelEditCategory}
              leftAction={addCategoryLeftAction}
              rightAction={addCategoryRightAction}
              style={[isDesktop && { maxWidth: 368 }]}
            >
              <H1>Modifică nume categorie</H1>
              <Spacer size="8" />
              <Body>Categoria te ajuta să organizezi serviciile similare în același grup.</Body>
              <Spacer size="16" />
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={categoryName.input}
                showError={showErrors}
                onChange={setCategoryName}
                placeholder="Introdu numele categoriei"
              />
              {showErrors && !categoryNameValid && (
                <>
                  <Spacer size={2} />
                  <SmallBody style={{ color: colors.RADICAL_RED }}>
                    Te rugăm să introduci nume pentru categorie
                  </SmallBody>
                </>
              )}
              <Spacer size="8" />
            </ConfirmBox>
          </KeyboardAvoidingView>
        </DismissKeyboard>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default EditCategoryDialog;

import { BusinessHours as BusinessHoursType } from '@mero/api-sdk/dist/business';
import { CalendarId } from '@mero/api-sdk/dist/calendar';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Text,
  Icon,
  HSpacer,
  Row,
  Column,
  Avatar,
  SimpleListItem,
  colors,
  Line,
  H3s,
  Spacer,
  Checkbox,
  Body,
} from '@mero/components';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, TouchableOpacity, View } from 'react-native';
import { LocaleConfig } from 'react-native-calendars';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';

import { useIsFocused } from '@react-navigation/native';

import { useClickOutsideWeb } from '../../../../hooks/useClickOutsideWeb';
import { useEscPressWeb } from '../../../../hooks/useEscPressWeb';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { ViewMode } from '../..';
import { AuthorizedProps, Authorized } from '../../../../contexts/AuthContext';
import { CalendarContext, LocalDateObject } from '../../../../contexts/CalendarContext';
import { CurrentBusinessProps, CurrentBusiness, CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import { CalendarScreenNavigationProp } from '../../../../types';
import Header from '../Header';
import { formatReadableDate, formatReadableWeek, getWorkingHours } from '../utils';
import { PendingApprovalsButton } from './PendingApprovalsButton';

const FiltersIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 7752">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 2738" />
      <G stroke={props.color ?? '#080de0'} strokeLinecap="round" strokeWidth={1.5} transform="rotate(90 13.49 17.647)">
        <Path
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M5.149 13.793v10.538m6.948-1.685v1.685h0m0-18.387v11.547m6.441-4.149v10.989"
        />
        <Path d="M5.149 8.828A2.349 2.349 0 1 1 2.8 11.177a2.349 2.349 0 0 1 2.349-2.349Z" />
        <Circle
          cx={2.349}
          cy={2.349}
          r={2.349}
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="layer1"
          transform="translate(16.189 8.402)"
        />
        <Circle
          cx={2.349}
          cy={2.349}
          r={2.349}
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="layer1"
          transform="translate(9.494 17.754)"
        />
        <Path strokeLinejoin="round" strokeMiterlimit={10} d="M5.149 6.01v2.392M18.538 6.01v2.371" data-name="layer2" />
      </G>
    </G>
  </Svg>
);

LocaleConfig.locales['ro'] = {
  monthNames: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ],
  monthNamesShort: ['Ian.', 'Feb.', 'Mart.', 'Apr.', 'Mai', 'Iun.', 'Iul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
  dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
  dayNamesShort: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm'],
  // FIXME: is this required and not covered by @types?
  // today: 'Astăzi',
};
LocaleConfig.defaultLocale = 'ro';

type WorkerItemProps = {
  worker: SavedWorker;
  onPress: (worker: SavedWorker) => void;
  checked: boolean;
  activeHours: BusinessHoursType;
  selectedDate: LocalDateObject;
  disabled?: boolean;
  disabledText?: string;
};

const WorkerItem: React.FC<WorkerItemProps> = React.memo(function WorkerItemComponent({
  worker,
  onPress,
  checked,
  activeHours,
  selectedDate,
  disabled,
  disabledText,
}) {
  const { t } = useTranslation('calendar');
  return (
    <TouchableOpacity onPress={() => onPress(worker)} disabled={disabled}>
      <SimpleListItem
        title={`${worker.user.firstname ?? ''} ${worker.user.lastname ?? ''}`}
        subtitle={disabled ? (disabledText ? disabledText : t('expired')) : getWorkingHours(activeHours, selectedDate)}
        icon={checked && !disabled ? 'checked' : 'unchecked'}
        iconColor={disabled ? colors.ATHENS_GRAY : colors.DARK_BLUE}
        iconPosition="left"
        alignContent="middle"
        image={{
          firstname: worker.user.firstname,
          lastname: worker.user.lastname,
          src: worker.profilePhoto?.medium,
        }}
      />
    </TouchableOpacity>
  );
});

type ModeItemProps = {
  mode: ViewMode;
  onPress: (m: ViewMode) => void;
  checked: boolean;
};

const ModeItem: React.FC<ModeItemProps> = React.memo(function WorkerItemComponent({ mode, onPress, checked }) {
  return (
    <TouchableOpacity onPress={() => onPress(mode)}>
      <SimpleListItem
        title={mode.label}
        icon={checked ? 'checked' : 'unchecked'}
        iconColor={colors.DARK_BLUE}
        iconPosition="left"
        alignContent="middle"
      />
    </TouchableOpacity>
  );
});

const workerItemKey = (item: SavedWorker): string => item._id;

const WorkerItemSeparator: React.FC = () => <Line />;

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    readonly navigation: CalendarScreenNavigationProp;
    readonly activeHours: Record<CalendarId, BusinessHoursType>;
    readonly activeMode: ViewMode;
    readonly modes: ViewMode[];
    readonly setMode: (m: ViewMode) => void;
    readonly isCalendarOpen?: boolean;
    readonly selectedDate: LocalDateObject;
    readonly onChangeDate?: (d: Date) => void;
    readonly onCalendarStateChange?: (v: boolean) => void;
    readonly onAddToDate: (v: number) => () => void;
  };

const CalendarDropdown: React.FC<Props> = ({
  page,
  authorization,
  navigation,
  activeHours,
  activeMode,
  modes,
  setMode,
  isCalendarOpen = false,
  onCalendarStateChange = () => null,
  selectedDate,
  onAddToDate,
}) => {
  const [
    { slices, selectedCalendars, includeDeleted, activeOnly, showOnlyWorkingHours, hasFinishedCheckoutTransactions },
    {
      addSelectedCalendar,
      removeSelectedCalendar,
      setMultipleCalendars,
      toggleIncludeDeleted,
      toggleHasFinishedCheckoutTransactions,
      toggleOnlyWorkingHours,
      setActiveOnly,
    },
  ] = CalendarContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  const { isPhone } = useMediaQueries();
  const isFocused = useIsFocused();
  const { t } = useTranslation('calendar');

  const userId = authorization.user._id;

  const team = React.useMemo(
    () =>
      pipe(
        page.workers,
        A.sort({
          compare: (a: SavedWorker) => (a.user._id === userId ? -1 : 1),
          equals: (a: SavedWorker, b: SavedWorker) => a._id === b._id,
        }),
      ),
    [page.workers, userId],
  );

  const selectedMember = team.find((m) => selectedCalendars.includes(m.calendar._id)) ?? team[0];

  const [workerSelectVisible, setWorkerSelectVisible] = React.useState(false);
  const workerSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: workerSelectRef,
    isVisible: workerSelectVisible,
    onClickOutside() {
      setIsNoneSelected(false);
      setWorkerSelectVisible(false);
    },
  });

  const [modeVisible, setModeVisible] = React.useState(false);
  const modeSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: modeSelectRef,
    isVisible: modeVisible,
    onClickOutside() {
      setModeVisible(false);
    },
  });

  const [settingsVisible, setSettingsVisible] = React.useState(false);
  const settingsSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: settingsSelectRef,
    isVisible: settingsVisible,
    onClickOutside() {
      setSettingsVisible(false);
    },
  });

  const closeAllMenus = () => {
    setIsNoneSelected(false);
    setWorkerSelectVisible(false);
    setModeVisible(false);
    setSettingsVisible(false);
  };

  const toggleWorkerMenu = () => {
    setIsNoneSelected(false);
    setWorkerSelectVisible(!workerSelectVisible);
  };
  const toggleModeMenu = () => setModeVisible(!modeVisible);

  const toggleSettingsMenu = () => setSettingsVisible(!settingsVisible);

  useEscPressWeb({
    onPress() {
      setWorkerSelectVisible(false);
      setModeVisible(false);
      onCalendarStateChange(false);
    },
  });

  const isSelectAllPro = team.length === selectedCalendars.length;

  const toggleOnlyWorkingHoursCallback = React.useCallback(() => {
    toggleOnlyWorkingHours();
  }, []);

  /**
   * This is set to true when users deselects all workers, event default worker will be selected -
   * won't show any selection in UI
   * Next time when a worker is selected, this will be set to false
   */
  const [isNoneSelected, setIsNoneSelected] = React.useState(false);

  const workerSelectedCallback = React.useCallback(
    (worker: SavedWorker) => {
      if (!selectedCalendars.includes(worker.calendar._id) || activeOnly) {
        if (isNoneSelected || activeOnly) {
          setMultipleCalendars({
            calendarIds: [worker.calendar._id],
            calendarSettings: worker.calendar.settings,
            activeOnly: false,
          });
        } else {
          addSelectedCalendar({
            calendarId: worker.calendar._id,
            calendarSettings: worker.calendar.settings,
          });
        }
      } else {
        removeSelectedCalendar(worker.calendar._id);
      }

      setIsNoneSelected(false);
    },
    [selectedCalendars, isNoneSelected, setMultipleCalendars, addSelectedCalendar, removeSelectedCalendar],
  );

  const toggleSelectAll = () => {
    const firstWorker = team[0];

    if (firstWorker) {
      if (isSelectAllPro && !activeOnly) {
        setIsNoneSelected(true);
        setMultipleCalendars({
          calendarIds: [firstWorker.calendar._id],
          calendarSettings: firstWorker.calendar.settings,
        });
      } else {
        setIsNoneSelected(false);
        setMultipleCalendars({
          calendarIds: team.filter((w) => !w.ownPageExpired).map((worker) => worker.calendar._id),
          calendarSettings: firstWorker.calendar.settings,
          period: 'day',
          activeOnly: false,
        });
      }
    }
  };

  const toggleActiveOnlyCallback = React.useCallback(() => {
    if (!activeOnly) {
      const authWorker = pipe(
        team,
        A.findFirst((w) => w.user._id === authorization.user._id && !w.ownPageExpired),
        O.getOrElseW(() => undefined),
      );
      const firstWorker = pipe(
        team,
        A.head,
        O.getOrElseW(() => undefined),
      );
      const worker = authWorker ?? firstWorker;

      if (worker) {
        setIsNoneSelected(false);
        setMultipleCalendars({
          calendarIds: pipe(
            team,
            A.filter((w) => !w.ownPageExpired),
            A.map((w) => w.calendar._id),
          ),
          calendarSettings: worker.calendar.settings,
          period: 'day',
          activeOnly: true,
        });
      }
    } else {
      setActiveOnly(false);
    }
  }, [activeOnly]);

  const toggleDeletedCallback = React.useCallback(toggleIncludeDeleted, []);
  const toggleHasFinishedCheckoutTransactionsCallback = React.useCallback(toggleHasFinishedCheckoutTransactions, []);

  React.useEffect(() => {
    const navigation = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowRight') {
        onAddToDate(activeMode.value === 'day' ? 1 : 7)();
      } else if (event.key === 'ArrowLeft') {
        onAddToDate(activeMode.value === 'day' ? -1 : -7)();
      }
    };

    const shouldSubscribe = Platform.OS === 'web' && !isPhone && isFocused;

    if (shouldSubscribe) {
      document.addEventListener('keydown', navigation);
    }

    return () => {
      if (shouldSubscribe) {
        document.removeEventListener('keydown', navigation);
      }
    };
  }, [isFocused, onAddToDate, activeMode]);

  const showWorkerSelect = selectedMember && !isPhone && page.permissions.bookings.canReadAllBookings();
  const hideFilters = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return false;
    }

    return true;
  }, [pageState]);

  const activeOnlyPros = React.useMemo(() => {
    if (activeOnly) {
      return Object.values(slices)
        .filter((s) => s?.activeDailyBounds)
        .map((s) => s?.worker._id);
    }

    return [];
  }, [activeOnly, slices]);

  return (
    <View style={{ position: 'relative' }}>
      <Header
        height={isPhone ? undefined : 91}
        backgroundColor="#ffffff"
        LeftComponent={() =>
          showWorkerSelect ? (
            <TouchableOpacity onPress={toggleWorkerMenu}>
              <Row
                alignItems="center"
                style={[
                  { marginLeft: 16 },
                  {
                    height: 43,
                    borderColor: '#DEE2E6',
                    borderWidth: 1,
                    borderRadius: 5,
                    paddingHorizontal: 12,
                    paddingVertical: 8,
                    width: 180,
                  },
                ]}
              >
                {selectedCalendars.length === 1 && (
                  <Column>
                    <Avatar
                      size={24}
                      firstname={selectedMember.user.firstname ?? ''}
                      lastname={selectedMember.user.lastname ?? ''}
                      source={selectedMember.profilePhoto?.medium}
                    />
                  </Column>
                )}
                {selectedCalendars.length > 1 ? (
                  <Text style={{ flex: 1 }} numberOfLines={1}>
                    {activeOnly
                      ? t('activePros')
                      : isSelectAllPro
                      ? t('allPros')
                      : t('selectedPro', { count: selectedCalendars.length })}
                  </Text>
                ) : (
                  <Text style={{ paddingLeft: 8, flex: 1 }} numberOfLines={1}>
                    {selectedMember.user.firstname || selectedMember.user.lastname}
                  </Text>
                )}

                <Column style={[{ paddingLeft: 6 }]}>
                  <Icon type="dropdown" rotate={workerSelectVisible} />
                </Column>
              </Row>
            </TouchableOpacity>
          ) : null
        }
        CenterComponent={() =>
          isPhone ? (
            <TouchableOpacity
              onPress={() => {
                onCalendarStateChange(!isCalendarOpen);
              }}
            >
              <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
                <Text fontFamily="open-sans-semibold" style={{ alignItems: 'center' }}>
                  {activeMode.value === 'day' ? formatReadableDate(selectedDate) : formatReadableWeek(selectedDate)}
                </Text>
                <View style={[{ paddingLeft: 10 }]}>
                  <Icon type="dropdown" rotate={isCalendarOpen} />
                </View>
              </View>
            </TouchableOpacity>
          ) : (
            <Row>
              <TouchableOpacity
                style={{
                  width: 43,
                  height: 43,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: '#DEE2E6',
                  borderWidth: 1,
                  borderRadius: 5,
                }}
                onPress={flow(onAddToDate(activeMode.value === 'day' ? -1 : -7), closeAllMenus)}
              >
                <Icon type={'arrow-left'} color={'#000000'} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  height: 43,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: '#DEE2E6',
                  borderWidth: 1,
                  borderRadius: 5,
                  marginHorizontal: 8,
                  paddingHorizontal: 12,
                }}
                onPress={() => {
                  onCalendarStateChange(!isCalendarOpen);
                }}
              >
                <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
                  <Text fontFamily="open-sans-semibold" style={{ alignItems: 'center' }}>
                    {activeMode.value === 'day' ? formatReadableDate(selectedDate) : formatReadableWeek(selectedDate)}
                  </Text>
                  <View style={[{ paddingLeft: 10 }]}>
                    <Icon type="dropdown" rotate={isCalendarOpen} />
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: 43,
                  height: 43,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: '#DEE2E6',
                  borderWidth: 1,
                  borderRadius: 5,
                }}
                onPress={flow(onAddToDate(activeMode.value === 'day' ? 1 : 7), closeAllMenus)}
              >
                <Icon type={'arrow-right'} color={'#000000'} />
              </TouchableOpacity>
            </Row>
          )
        }
        RightComponent={() =>
          isPhone ? (
            <Row>
              {!isCalendarOpen &&
                pageState.type === 'Loaded' &&
                (pageState.page.permissions.bookings.canManageAllBookingRequests() ||
                  pageState.page.permissions.bookings.canManageOwnBookingRequests()) && (
                  <PendingApprovalsButton
                    pageId={pageState.page.details._id}
                    onPress={() => navigation?.navigate('PendingList')}
                  />
                )}
              {isCalendarOpen || hideFilters ? null : (
                <HSpacer left={16} right={16}>
                  <TouchableOpacity
                    onPress={() => {
                      if (navigation) {
                        navigation.openDrawer();
                      }
                    }}
                  >
                    <FiltersIcon />
                  </TouchableOpacity>
                </HSpacer>
              )}
            </Row>
          ) : (
            <Row style={{ alignItems: 'center' }}>
              {pageState.type === 'Loaded' &&
                (pageState.page.permissions.bookings.canManageAllBookingRequests() ||
                  pageState.page.permissions.bookings.canManageOwnBookingRequests()) && (
                  <PendingApprovalsButton
                    color={colors.BLACK}
                    pageId={pageState.page.details._id}
                    onPress={() => navigation?.navigate('PendingList')}
                  />
                )}
              <HSpacer left={16} />
              <TouchableOpacity style={{ marginRight: 16 }} onPress={toggleSettingsMenu}>
                <Column
                  style={{
                    width: 43,
                    height: 43,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: colors.GEYSER,
                    borderWidth: 1,
                    borderRadius: 43,
                  }}
                >
                  <FiltersIcon color={colors.BLACK} />
                </Column>
              </TouchableOpacity>
              <TouchableOpacity onPress={toggleModeMenu}>
                <Row
                  alignItems="center"
                  style={[
                    { marginRight: 16 },
                    {
                      height: 43,
                      borderColor: '#DEE2E6',
                      borderWidth: 1,
                      borderRadius: 5,
                      paddingHorizontal: 12,
                      paddingVertical: 8,
                      width: 180,
                    },
                  ]}
                >
                  <Text style={{ paddingLeft: 8, flex: 1 }} numberOfLines={1}>
                    {activeMode.label}
                  </Text>
                  <Column style={[{ paddingLeft: 6 }]}>
                    <Icon type="dropdown" rotate={modeVisible} />
                  </Column>
                </Row>
              </TouchableOpacity>
            </Row>
          )
        }
      />
      {workerSelectVisible && (
        <View
          ref={workerSelectRef}
          style={{
            position: 'absolute',
            top: 75,
            left: 16,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            maxHeight: 360,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 1,
          }}
        >
          <FlatList
            bounces={false}
            data={team}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              const isChecked = selectedCalendars.includes(item.calendar._id);
              return (
                <>
                  {index === 0 && team.length > 1 && (
                    <>
                      <TouchableOpacity onPress={toggleSelectAll}>
                        <SimpleListItem
                          title={t('allPros')}
                          subtitle={page.details.name}
                          icon={isSelectAllPro && !activeOnly ? 'checked' : 'unchecked'}
                          iconColor={colors.DARK_BLUE}
                          iconPosition="left"
                          alignContent="middle"
                        />
                      </TouchableOpacity>
                      <Line />
                      <TouchableOpacity onPress={toggleActiveOnlyCallback}>
                        <SimpleListItem
                          title={t('activePros')}
                          icon={activeOnly ? 'checked' : 'unchecked'}
                          iconColor={colors.DARK_BLUE}
                          iconPosition="left"
                          alignContent="middle"
                        />
                      </TouchableOpacity>
                      <Line />
                    </>
                  )}

                  <WorkerItem
                    selectedDate={selectedDate}
                    activeHours={activeHours[item.calendar._id]}
                    worker={item}
                    onPress={workerSelectedCallback}
                    checked={activeOnly ? activeOnlyPros.includes(item._id) : isChecked && !isNoneSelected}
                    disabled={item.ownPageExpired}
                  />
                </>
              );
            }}
            keyExtractor={workerItemKey}
            ItemSeparatorComponent={WorkerItemSeparator}
          />
        </View>
      )}
      {modeVisible && (
        <View
          ref={modeSelectRef}
          style={{
            position: 'absolute',
            top: 75,
            right: 16,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 2,
          }}
        >
          <FlatList
            bounces={false}
            data={modes}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <ModeItem
                mode={item}
                onPress={(mode: ViewMode) => {
                  setMode(mode);
                  toggleModeMenu();
                }}
                checked={activeMode.value === item.value}
              />
            )}
            ItemSeparatorComponent={WorkerItemSeparator}
            keyExtractor={(item) => item.value}
          />
        </View>
      )}
      {settingsVisible && (
        <View
          ref={settingsSelectRef}
          style={{
            position: 'absolute',
            top: 75,
            right: 120,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 2,
          }}
        >
          <Spacer size="16" />
          <H3s>{t('displayAppointments')}</H3s>
          <Spacer size="16" />
          <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={toggleDeletedCallback}>
            <Checkbox color="blue" disabled={false} value={includeDeleted} onValueChange={toggleDeletedCallback} />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('includeDeleted')}</Body>
          </TouchableOpacity>
          {page.permissions.checkout.hasAny() && (
            <>
              <Spacer size="16" />
              <Line />
              <Spacer size="16" />
              <TouchableOpacity
                style={{ flex: 2, flexDirection: 'row' }}
                onPress={toggleHasFinishedCheckoutTransactionsCallback}
              >
                <Checkbox
                  color="blue"
                  disabled={false}
                  value={!hasFinishedCheckoutTransactions}
                  onValueChange={toggleHasFinishedCheckoutTransactionsCallback}
                />
                <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>
                  {t('hasFinishedCheckoutTransactions')}
                </Body>
              </TouchableOpacity>
            </>
          )}
          <Spacer size="24" />
          <H3s>{t('displaySchedule')}</H3s>
          <Spacer size="24" />
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleOnlyWorkingHoursCallback}>
            <Checkbox
              color="blue"
              disabled={false}
              value={!showOnlyWorkingHours}
              onValueChange={toggleOnlyWorkingHoursCallback}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>{t('completeSchedule')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleOnlyWorkingHoursCallback}>
            <Checkbox
              color="blue"
              disabled={false}
              value={showOnlyWorkingHours}
              onValueChange={toggleOnlyWorkingHoursCallback}
            />
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12, flex: 1 }}>{t('workingHours')}</Body>
          </TouchableOpacity>
          <Spacer size="24" />
        </View>
      )}
    </View>
  );
};

export default pipe(CalendarDropdown, CurrentBusiness, Authorized);

import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import * as colors from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Recurring = ({ size = 24, color = colors.DARK_BLUE, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(1.98 -2.41)">
      <Path
        d="M6.185,12.583l-.736.628a4.806,4.806,0,0,1,8.053-2.2l1.134-1.134a6.413,6.413,0,0,0-10.77,3.04l-.529-.642L2,13.077l2.39,3.145L7.226,13.8Z"
        fill={color}
      />
      <Path
        d="M27.813,28.167l-2.242-3.221-2.79,2.509,1.072,1.192.679-.61a4.805,4.805,0,0,1-8.053,2.2l-1.133,1.134A6.413,6.413,0,0,0,26.1,28.376l.47.638Z"
        transform="translate(-9.78 -12.418)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(Recurring);

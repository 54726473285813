import { colors, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { styles } from './styles';

export type Props = {
  readonly onDismiss: () => void;
  readonly onEdit: () => void;
  readonly onDelete: () => void;
};

const FeedbackOptionsModal: React.FC<Props> = ({ onDismiss, onEdit, onDelete }: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('booking');

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <SafeAreaView
        edges={['bottom']}
        style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottomOptions]}
      >
        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={() => {
            onEdit();
            onDismiss();
          }}
        >
          <Title>{t('editFeedback')}</Title>
        </TouchableOpacity>
        <Line />
        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={() => {
            onDelete();
            onDismiss();
          }}
        >
          <Title style={{ color: colors.RADICAL_RED }}>{t('deleteFeedback')}</Title>
        </TouchableOpacity>
      </SafeAreaView>
    </ModalOverlay>
  );
};

export default FeedbackOptionsModal;

import { MeroHeader, SafeAreaView } from '@mero/components';
import * as React from 'react';
import WebView from 'react-native-webview';

import { StackScreenProps } from '@react-navigation/stack';

import { ShopStackParamsList } from '../../../../../types';
import { styles } from './BarberStoreWebview.styles';
import PageContainer from './PageContainer';

type Props = StackScreenProps<ShopStackParamsList, 'BarberStore'>;

const BarberStoreWebview: React.FC<Props> = ({ navigation, route }: Props) => {
  return (
    <PageContainer>
      <SafeAreaView edges={['top']} />
      <MeroHeader title="Barber Store" canGoBack onBack={navigation.goBack} />
      <WebView
        style={styles.webView}
        source={{
          uri: route.params.url,
        }}
        sharedCookiesEnabled={true}
        pullToRefreshEnabled={false}
        domStorageEnabled={true}
      />
    </PageContainer>
  );
};

export default BarberStoreWebview;

import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import DeleteLocationScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/DeleteLocationScreen';
import LocationAddressScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/LocationAddressScreen';
import LocationProfileDetailsScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/LocationProfileDetailsScreen';
import LocationProfileScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/LocationProfileScreen';
import LocationScheduleScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/LocationScheduleScreen';
import OnlineLinkScreen from '../screens/Authorized/MenuScreen/screens/LocationProfile/OnlineLinkScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withMarketingContextProvider } from '../contexts/MarketingContext';
import { LocationProfileStackParamList } from '../types';
import PageGallerySettingsStackNavigator from './PageGallerySettingsStackNavigator';
import { styles } from './styles';

const Stack = createStackNavigator<LocationProfileStackParamList>();

const LocationProfileStackNavigator: React.FC = () => {
  const { t } = useTranslation('location');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="LocationMenu"
      screenOptions={{
        headerShown: false,
        cardOverlayEnabled: true,
      }}
    >
      <Stack.Screen
        name="LocationMenu"
        component={LocationProfileScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="LocationProfile"
        component={LocationProfileDetailsScreen}
        options={{ title: t('locationDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="LocationGallerySettingsScreen"
        component={PageGallerySettingsStackNavigator}
        options={{ title: t('photoGallery') }}
      />
      <Stack.Screen
        name="LocationAddress"
        component={LocationAddressScreen}
        options={{ title: t('address'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="LocationSchedule"
        component={LocationScheduleScreen}
        options={{ title: t('schedule'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="OnlineLink"
        component={OnlineLinkScreen}
        options={{ title: t('onlineLink'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="DeleteLocation"
        component={DeleteLocationScreen}
        options={{ title: t('confirmDelete'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
};

export default pipe(LocationProfileStackNavigator, withMarketingContextProvider);

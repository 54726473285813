import { SavedClient } from '@mero/api-sdk/dist/clients';
import {
  Avatar,
  Body,
  Button,
  colors,
  FormCard,
  H1,
  HSpacer,
  Icon,
  Line,
  sizes,
  Spacer,
  Title,
  styles as meroStyles,
  MeroHeader,
  SmallBody,
} from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

import ClientBlockedLabel from '../../../components/ClientBlockedLabel';
import ClientWarnedLabel from '../../../components/ClientWarnedLabel';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import TextSkeleton from '@mero/components/lib/components/TextSkeleton';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

export type Props = {
  readonly client?: SavedClient;
  readonly onBack: () => void;
};

const ClientDetailsLoadingScreenView: React.FC<Props> = ({ client, onBack }: Props) => {
  const { firstname, lastname } = client?.user ?? {};
  const { isPhone } = useMediaQueries();

  const fullName = React.useMemo(
    () => `${firstname ?? ''} ${lastname ?? ''}`.trim() || undefined,
    [firstname, lastname],
  );

  return (
    <ModalScreenContainer>
      <MeroHeader
        canGoBack
        onBack={onBack}
        title={fullName}
        RightComponent={
          <View style={{ justifyContent: 'center', height: 50, marginRight: 8 }}>
            <SmallBody style={[meroStyles.text.semibold, { color: colors.COMET }]}>Opțiuni</SmallBody>
          </View>
        }
      />

      <View style={meroStyles.layout.padding16}>
        <View style={meroStyles.layout.row}>
          <Avatar source={client?.user.photo?.medium} firstname={firstname ?? ''} lastname={lastname ?? ''} size={60} />
          <HSpacer left={sizes[16]} />
          <View style={[meroStyles.layout.column, { minHeight: 60, justifyContent: 'center' }]}>
            {fullName ? (
              <H1 style={{ marginBottom: sizes[4] }}>{fullName}</H1>
            ) : (
              <TextSkeleton height="h1" width="medium" />
            )}
            {/* <Title>
              X programări<Body> (Xxx Lei)</Body>
            </Title> */}
            {client?.isBlocked || client?.isWarned ? (
              <>
                <Spacer size="8" />
                <View style={meroStyles.layout.row}>
                  {client.isBlocked ? (
                    <>
                      <ClientBlockedLabel />
                      <HSpacer left={8} />
                    </>
                  ) : null}
                  {client.isWarned ? (
                    <>
                      <ClientWarnedLabel />
                      <HSpacer left={8} />
                    </>
                  ) : null}
                </View>
              </>
            ) : null}
          </View>
        </View>
      </View>
      <View style={meroStyles.layout.padding16}>
        <FormCard dropShaddow rounded paddings="none">
          <View style={meroStyles.layout.padding16}>
            <View style={[meroStyles.layout.row]}>
              <View style={[meroStyles.layout.column, { flex: 1, minHeight: 20 }]}>
                <Title>Telefon</Title>
                <Spacer size="4" />
                {client?.user.phone ? <Body>{client?.user.phone}</Body> : <TextSkeleton width="medium" />}
              </View>
              <View style={meroStyles.layout.column}>
                <Icon type="options-dots" color={colors.COMET} />
              </View>
            </View>
            <Spacer size="24" />
            <View>
              <Title>Observații</Title>
              <Spacer size="4" />
              {client?.remark !== undefined ? <Body>{client.remark}</Body> : <TextSkeleton width="long" />}
            </View>
          </View>
          <Line />
          <View style={[meroStyles.layout.padding16, meroStyles.layout.row, { justifyContent: 'center' }]}>
            <View style={{ flex: 4 }} />
            <HSpacer left={sizes[8]} />
            {isPhone ? (
              <Button size="medium" text="Programare nouă" containerStyle={{ flex: 5 }} disabled />
            ) : (
              <Button
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                size="medium"
                text="Programare nouă"
                disabled
              />
            )}
          </View>
        </FormCard>
      </View>
    </ModalScreenContainer>
  );
};

export default ClientDetailsLoadingScreenView;

import { Email, PhoneNumber, StrictPhoneNumberParsed } from '@mero/api-sdk';
import {
  Avatar,
  Button,
  colors,
  Column,
  DismissKeyboard,
  FormCard,
  H1,
  Header,
  Icon,
  SmallBody,
  Spacer,
  TextInput,
  useShowError,
  useToast,
} from '@mero/components';
import { DefinedString } from '@mero/shared-sdk';
import * as ImagePicker from 'expo-image-picker';
import * as E from 'fp-ts/Either';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { EditIcon } from '../ProsDashboardScreen/ProProfileDetailsScreen';
import { styles } from '../ProsDashboardScreen/ProProfileDetailsScreen.styles';

const INPUT_POSITIONS = {
  name: 50,
  phone: 150,
  email: 250,
} as const;

const DESCRIPTION_LIMIT = 300;

const LocationProfileDetailsScreen: React.FC = () => {
  const { t } = useTranslation('location');
  const { isPhone } = useMediaQueries();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();

  const block = React.useRef(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showPhotoMenu, setShowPhotoMenu] = React.useState(false);
  const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>(undefined);

  const [name, setName] = React.useState({
    input: '',
    decoded: DefinedString.decode(''),
  });
  const nameValid = E.isRight(name.decoded);

  const [phone, setPhone] = React.useState({
    input: '',
    decoded: StrictPhoneNumberParsed.decode(''),
  });
  const phoneValid = E.isRight(phone.decoded);

  const [email, setEmail] = React.useState('');

  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setProfilePhoto(pageState.page.details.profilePhoto.thumbnail);
      setName({
        input: pageState.page.details.name ?? '',
        decoded: DefinedString.decode(pageState.page.details.name),
      });
      setPhone({
        input: pageState.page.details.phoneNo,
        decoded: StrictPhoneNumberParsed.decode(pageState.page.details.phoneNo),
      });
      setEmail(pageState.page.details.email ?? '');
      setDescription(pageState.page.details.description ?? '');
    }
  }, [pageState.type]);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  const togglePhotoMenu = () => {
    setShowPhotoMenu(!showPhotoMenu);
  };

  const addPhoto = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setProfilePhoto(result.assets[0].uri);
    }
  };

  const deletePhoto = () => {
    setProfilePhoto(undefined);
  };

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded' || !nameValid || !phoneValid) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    block.current = true;

    try {
      const promises = [];

      if (profilePhoto && profilePhoto !== pageState.page.details.profilePhoto.thumbnail) {
        const profilePhotoUri = Platform.OS === 'ios' ? profilePhoto.replace('file://', '') : profilePhoto;
        const response = await fetch(profilePhotoUri);
        const blob = await response.blob();

        promises.push(
          meroApi.pages.updatePageProfilePhoto({
            pageId: pageState.page.details._id,
            profileImage: { platform: Platform.OS, blob, uri: profilePhotoUri },
          }),
        );
      }

      promises.push(
        meroApi.pages.updatePageInfoSimple({
          pageId: pageState.page.details._id,
          name: name.input as DefinedString,
          phoneNo: phone.input as PhoneNumber,
          email: email as Email,
          description,
        }),
      );

      await Promise.all(promises);
      await reloadAsync();

      goBack();

      toast.show({
        type: 'success',
        text: t('saveChangesSuccess'),
      });
    } catch (error) {
      showError(error, t('saveChangesError'));
    } finally {
      setIsLoading(false);
      block.current = false;
    }
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <Header
          LeftComponent={() => (
            <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
              <Icon type="back" />
            </TouchableOpacity>
          )}
          text={t('locationDetails')}
          RightComponent={() => (
            <Column style={{ paddingRight: 24 }}>
              <Avatar
                size={32}
                source={pageState.page.details.profilePhoto.thumbnail}
                firstname={pageState.page.details.name}
                lastname={''}
              />
            </Column>
          )}
        />
        <DismissKeyboard
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KeyboardAvoidingView
            style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}
          >
            <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 8 }}>{t('locationDetails')}</H1>
              <Spacer size={32} />
              <FormCard paddings={'none'} style={{ padding: 16 }} dropShaddow rounded>
                <Column style={{ alignItems: 'center' }}>
                  <TouchableOpacity onPress={addPhoto}>
                    <Avatar size={60} source={profilePhoto} firstname={pageState.page.details.name} lastname={''} />
                    <Column style={styles.editIcon}>
                      <EditIcon />
                    </Column>
                  </TouchableOpacity>
                  <Spacer size={12} />
                  <SmallBody style={{ color: colors.COMET, textAlign: 'center' }}>{t('editPhotoDetails')}</SmallBody>
                </Column>
                <Spacer size={32} />
                <InputWithLabel label={t('name')} isError={showErrors && !nameValid} errorText={t('nameError')}>
                  <TypeSafeTextInput
                    codec={DefinedString}
                    value={name.input}
                    showError={showErrors && !nameValid}
                    onChange={setName}
                    placeholder={t('namePlaceholder')}
                    onFocus={() => scrollTo('name')}
                  />
                </InputWithLabel>
                <Spacer size="16" />
                <InputWithLabel label={t('phone')} isError={showErrors && !phoneValid} errorText={t('phoneError')}>
                  <TypeSafeTextInput
                    codec={StrictPhoneNumberParsed}
                    value={phone.input}
                    showError={showErrors && !phoneValid}
                    onChange={setPhone}
                    placeholder={t('phonePlaceholder')}
                    onFocus={() => scrollTo('phone')}
                    keyboardType={'phone-pad'}
                  />
                </InputWithLabel>
                <Spacer size="16" />
                <InputWithLabel label={t('email')}>
                  <TextInput
                    withBorder
                    value={email}
                    onChange={setEmail}
                    placeholder={t('emailPlaceholder')}
                    onFocus={() => scrollTo('email')}
                  />
                </InputWithLabel>
                <Spacer size="16" />
                <InputWithLabel label={t('description')}>
                  <TextInput
                    withBorder
                    multiline
                    numberOfLines={3}
                    value={description}
                    onChange={setDescription}
                    placeholder={t('descriptionPlaceholder')}
                    textInputProps={{
                      maxLength: DESCRIPTION_LIMIT,
                    }}
                  />
                </InputWithLabel>
              </FormCard>
              <Spacer size={117} />
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={saveChanges} />
            ) : (
              <Button
                disabled={block.current || isLoading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('saveChanges')}
                onClick={saveChanges}
              />
            )}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
      {/*{showPhotoMenu && (*/}
      {/*  <ProfilePhotoMenu onDismiss={togglePhotoMenu} onAddPhoto={addPhoto} onDeletePhoto={deletePhoto} />*/}
      {/*)}*/}
    </>
  );
};

export default LocationProfileDetailsScreen;

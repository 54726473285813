import { colors, Column, H1, Spacer } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutStackParamList, HomeTabsParamsList } from '../../../types';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & HomeTabsParamsList, 'AddProceedSuccessScreen'>;

const SuccessIcon = (props: SvgProps) => (
  <Svg width={120} height={120} {...props}>
    <G data-name="Group 6356">
      <G transform="translate(9.854 9.853)">
        <Circle cx={43} cy={43} r={43} fill="#5ade9c" transform="translate(7.147 7.147)" />
        <Circle
          cx={43}
          cy={43}
          r={43}
          fill="none"
          stroke="#2e4369"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          transform="rotate(-90 50.147 43)"
        />
        <Path
          fill="none"
          stroke="#2e4369"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m34.379 50.169 12.886 12.888 22.949-22.945"
          data-name="stroke"
        />
      </G>
      <Path fill="none" d="M0 0h120v120H0z" data-name="Rectangle 2527" />
    </G>
  </Svg>
);

const AddProceedSuccessScreen: React.FC<Props> = ({ navigation, route, page }): React.ReactElement | null => {
  const { t } = useTranslation('checkout');
  const { isDesktop } = useMediaQueries();

  const [, { reset }] = CheckoutFormContext.useContext();

  React.useEffect(() => {
    reset();
  }, []);

  return (
    <ModalScreenContainer style={isDesktop && { width: 500, borderRadius: 0, alignSelf: 'flex-end' }}>
      <MeroHeader
        canClose
        onClose={() => {
          navigation.popToTop();
          navigation.goBack();
        }}
        containerStyle={{ backgroundColor: colors.ALABASTER }}
      />
      <SafeAreaView
        edges={['bottom']}
        style={{
          flex: 1,
          flexDirection: 'column',
          backgroundColor: colors.ALABASTER,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SuccessIcon />
        <Spacer size={24} />
        <H1>{t('paymentSuccess')}</H1>
        <Spacer size={48} />
        <Column style={{ paddingHorizontal: 24 }}>
          <Button
            expand={false}
            padding={24}
            onClick={() => {
              navigation.popToTop();
              navigation.navigate('ProceedDetailsScreen', {
                checkoutTransactionId: route.params.checkoutTransactionId,
                backMode: 'all',
              });
            }}
            text={t('viewDetails')}
          />
        </Column>
      </SafeAreaView>
    </ModalScreenContainer>
  );
};

export default pipe(AddProceedSuccessScreen, CurrentBusiness, Authorized);

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ChangePhoneNumberScreen from '../screens/Authorized/MenuScreen/screens/ChangePhoneNumber/ChangePhoneNumberScreen';
import OtpScreen from '../screens/Authorized/MenuScreen/screens/ChangePhoneNumber/OtpScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { ChangePhoneParamsList } from '../types';
import { styles } from './styles';

const CheckoutStack = createStackNavigator<ChangePhoneParamsList>();

function ChangePhoneStack() {
  const { t } = useTranslation('changePhone');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <CheckoutStack.Navigator screenOptions={{ headerShown: false, presentation: 'transparentModal' }}>
      <CheckoutStack.Screen
        name="ChangePhoneNumber"
        component={ChangePhoneNumberScreen}
        options={{
          cardStyle: modalStyle,
          headerShown: false,
          title: t('changePhone'),
        }}
      />
      <CheckoutStack.Screen
        name="Otp"
        component={OtpScreen}
        options={{
          cardStyle: modalStyle,
          headerShown: false,
          title: t('changePhone'),
        }}
      />
    </CheckoutStack.Navigator>
  );
}

export default ChangePhoneStack;

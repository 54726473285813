import { Filters as FiltersType } from '@mero/api-sdk/dist/shop/filters';
import {
  Button,
  Checkbox,
  Column,
  FormCard,
  H1,
  H3s,
  Header,
  HSpacer,
  Icon,
  Line,
  Row,
  Spacer,
  Title,
} from '@mero/components';
import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import * as colors from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../contexts/AuthContext';
import { ShopStackParamsList } from '../../../../../types';
import PageContainer from './PageContainer';
import { ShopContext } from './shopContext';

type Props = StackScreenProps<ShopStackParamsList, 'ShopFilters'>;
const ShopFiltersScreen: React.FC<Props> = ({ navigation, route }) => {
  const { category, subCategory } = route.params;

  const [shopState, shopDispatch] = ShopContext.useContext();
  const goBack = useGoBack();

  const selectedFiltersObject = React.useMemo(() => {
    return Object.entries(shopState.selectedFilters).reduce((acc, [category, items]) => {
      const itemsObject = items.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {} as Record<string, boolean>);
      return {
        ...acc,
        [category]: itemsObject,
      };
    }, {} as Record<string, Record<string, boolean>>);
  }, [shopState.selectedFilters]);

  const [localFilters, setLocalFilters] = React.useState(selectedFiltersObject);
  const [productsCount, setProductsCount] = React.useState(0);

  const filtersList = React.useMemo(
    () =>
      Object.entries(shopState.filters)
        .map(([category, items]) => ({ category, items }))
        .filter(({ items }) => items.length > 1),
    [shopState.filters],
  );

  const resetFilters = React.useCallback(() => {
    setLocalFilters({});
  }, []);

  const toggleFilter = React.useCallback(
    (category: string, item: string) => () => {
      const value = localFilters[category] ? !localFilters[category][item] : true;
      const newFilters = {
        ...localFilters,
        [category]: {
          ...localFilters[category],
          [item]: value,
        },
      };

      setLocalFilters(newFilters);
    },
    [localFilters],
  );

  const categoriesFilters = React.useMemo(() => {
    return subCategory
      ? { mainCategories: [category.id], subCategories: [subCategory.id] }
      : { mainCategories: [category.id] };
  }, [category.id, subCategory?.id]);

  const convertFilters = React.useCallback((filters: Record<string, Record<string, boolean>>) => {
    return Object.entries(filters).reduce((acc, [category, items]) => {
      const itemsArray = Object.entries(items)
        .filter(([, value]) => value)
        .map(([item]) => item);
      return {
        ...acc,
        [category]: itemsArray,
      };
    }, {} as FiltersType);
  }, []);

  const applyFilters = () => {
    const filters = convertFilters(localFilters);
    shopDispatch.updateSelectedFilters(filters);
    shopDispatch.search({
      ...categoriesFilters,
      searchTerm: shopState.searchTerm,
      filters,
      sort: shopState.sort,
    });
    navigation.goBack();
  };

  React.useEffect(() => {
    const getProductsCount = async () => {
      const filters = convertFilters(localFilters);
      const response = await meroApi.shop.search({
        ...categoriesFilters,
        query: shopState.searchTerm,
        filters,
        size: 1,
      });
      setProductsCount(response.total);
    };

    getProductsCount();
  }, [localFilters]);

  return (
    <PageContainer>
      <Column
        style={{
          backgroundColor: colors.WHITE,
        }}
      >
        <SafeAreaView edges={['top']} />
        <Header
          text="Filtre"
          RightComponent={() => (
            <TouchableOpacity style={{ paddingRight: 16 }} onPress={goBack}>
              <Icon type="close" />
            </TouchableOpacity>
          )}
        />
      </Column>
      <Column style={{ backgroundColor: colors.WHITE, marginBottom: 96, flex: 1 }}>
        <Spacer size="16" />
        <Row style={{ paddingHorizontal: 24 }}>
          <H1 style={{ flex: 1 }}>Filtre</H1>
          <Button
            expand={false}
            text="Resetează"
            size="small"
            backgroundColor={colors.WHITE}
            color={colors.DARK_BLUE}
            onClick={resetFilters}
          />
        </Row>
        <Spacer size="24" />
        <FlatList
          data={filtersList}
          renderItem={({ item }) => (
            <Column style={{ paddingHorizontal: 24, paddingBottom: 32 }}>
              <H3s>{item.category}</H3s>
              {item.items.map((props) => (
                <TouchableOpacity key={props} style={{ paddingTop: 16 }} onPress={toggleFilter(item.category, props)}>
                  <Row>
                    <Checkbox
                      value={localFilters[item.category] ? localFilters[item.category][props] : false}
                      onValueChange={toggleFilter(item.category, props)}
                    />
                    <HSpacer left={16} />
                    <Title>{props}</Title>
                  </Row>
                  <Spacer size="16" />
                  <Line />
                </TouchableOpacity>
              ))}
            </Column>
          )}
          keyExtractor={(item) => item.category}
        />
      </Column>
      <FormCard dropShaddow paddings="button" style={{ position: 'absolute', left: 0, bottom: 0, right: 0 }}>
        <SafeAreaView edges={['bottom']}>
          <Button onClick={applyFilters} text={`Vezi produse (${productsCount})`} />
        </SafeAreaView>
      </FormCard>
    </PageContainer>
  );
};

export default ShopFiltersScreen;

import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

const ProceedIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

type Props = {
  readonly onPress?: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

const AddProceedButton: React.FC<Props> = ({ style, onPress }) => {
  const { isPhone } = useMediaQueries();

  return (
    <View
      style={[
        style,
        {
          justifyContent: isPhone ? 'flex-end' : 'center',
          alignItems: isPhone ? 'flex-end' : 'center',
          flexDirection: isPhone ? 'column' : 'row-reverse',
          flex: 1,
          paddingRight: 24,
          paddingBottom: 24,
          position: 'absolute',
          bottom: 0,
          right: 0,
        },
      ]}
      pointerEvents="box-none"
    >
      <TouchableOpacity onPress={onPress}>
        <View
          style={[
            {
              height: 52,
              width: 52,
              borderRadius: 25,
              backgroundColor: '#080DE0',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          <ProceedIcon />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default AddProceedButton;

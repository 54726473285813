import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, ro } from './resources';

export const resources = {
  en,
  ro,
} as const;

i18n.use(initReactI18next).init({
  returnNull: false,
  lng: 'ro',
  fallbackLng: 'ro',
  debug: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources,
});

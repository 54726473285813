import { Email as EmailType } from '@mero/api-sdk';
import { colors, Column, Label, Row, SimpleListItem, Title, HSpacer } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { styles } from './styles';

export const CopyIcon = (props: SvgProps) => (
  <Svg width={44} height={44} {...props}>
    <G data-name="Group 7735" transform="translate(-166 -255)">
      <Circle cx={22} cy={22} r={22} fill="#f2f2fe" data-name="Ellipse 693" transform="translate(166 255)" />
      <G fill="none" data-name="Group 7729">
        <Path d="M176 265h24v24h-24z" data-name="Rectangle 2786" />
        <G
          stroke="#080de0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.2}
          data-name="Orion_copy (1)"
        >
          <Path d="M183.733 281.267H180V269h12.267v3.733" />
          <Path d="M183.733 272.733H196V285h-12.267Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export const OpenIcon = (props: SvgProps) => (
  <Svg width={44} height={44} {...props}>
    <G data-name="Group 8004" transform="translate(-166 -255)">
      <Circle cx={22} cy={22} r={22} fill="#f2f2fe" data-name="Ellipse 693" transform="translate(166 255)" />
      <G fill="none" data-name="Group 7729">
        <Path d="M176 265h24v24h-24z" data-name="Rectangle 2786" />
        <G stroke="#080de0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.2}>
          <Path d="M186.571 278.715v6l2.943-4.171" />
          <Path d="m180 276.143 16-6.857L193.429 283l-6.858-4.285Zm16-6.857-9.429 9.429" />
        </G>
      </G>
    </G>
  </Svg>
);

type Props = {
  email: EmailType;
  onCopy: () => void;
  onOpen: () => void;
};

export const Email: React.FC<Props> = ({ email, onCopy, onOpen }) => (
  <TouchableOpacity disabled style={styles.optionsMenuItem} delayPressIn={0}>
    <SimpleListItem>
      <Row>
        <Column style={{ flex: 1 }}>
          <Label style={{ color: colors.COMET }}>EMAIL</Label>
          <Title>{email}</Title>
        </Column>
        <a href={`mailto:${email}`}>
          <OpenIcon />
        </a>
        <HSpacer left={16} />
        <TouchableOpacity onPress={onCopy}>
          <CopyIcon />
        </TouchableOpacity>
      </Row>
    </SimpleListItem>
  </TouchableOpacity>
);

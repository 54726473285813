import { UserId } from '@mero/api-sdk/dist/users';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  userId: UserId;
};

const DeleteMemberDialog: React.FC<Props> = ({ onSuccess, onCancel, userId }) => {
  const { t } = useTranslation('pros');
  const showError = useShowError();

  const [pageState, { getPageWorkersAndServices, getPageMembers }] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const cancelDeleteWorker = () => {
    onCancel();
  };

  const deleteWorker = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.pages.deletePageMember({
          pageId: pageState.page.details._id,
          userId,
        });
        await Promise.all([
          getPageWorkersAndServices(pageState.page.details._id),
          getPageMembers(pageState.page.details._id),
        ]);
        onSuccess();
      }
    } catch (error) {
      cancelDeleteWorker();
      showError(error);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancelDeleteWorker,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('deleteWorker'),
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : deleteWorker,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancelDeleteWorker}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('confirmDelete')}</H1>
          <Spacer size="8" />
          <Body>{t('checkToConfirm')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns={'pros'} t={t} i18nKey="deleteCheck3">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteMemberDialog;

import { PhoneNumber } from '@mero/api-sdk';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { colors, useShowError } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Platform } from 'react-native';

import SearchClientsForm from '../../../components/SelectClientScreenView/SearchClientsForm';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import {
  SearchClientsContext,
  ClientsFlagFilter,
  withSearchClientsContextProvider,
} from '../../../contexts/SearchClientsContext';
import {
  AuthorizedStackParamList,
  ClientsTabStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  RootStackParamList,
} from '../../../types';

type CalendarScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<ClientsTabStackParamList, 'ClientsScreen'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'ClientsTab'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList>
      >
    >
  >
>;

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    navigation: CalendarScreenNavigationProp;
  };

const ClientsScreen: React.FC<Props> = ({ page, authorization, navigation }) => {
  const [state, { init, search, reload, loadMore, tryResetFailed }] = SearchClientsContext.useContext();
  const showError = useShowError();
  const [, { subscribe }] = AppEventsContext.useContext();
  const isLoading = state.type === 'New' || state.type === 'Loading';
  const { query, clients } = state;
  const pageId = page.details._id;

  const selectClientCallback = React.useCallback(
    (client: ClientPreview) => {
      navigation.navigate('ClientDetails', {
        screen: 'DetailsScreen',
        params: {
          pageId: pageId,
          clientId: client._id,
        },
      });
    },
    [navigation, pageId],
  );

  const queryChangeCallback = React.useCallback(
    (q: string): void => {
      search({ pageId: pageId, query: { search: q, flagFilter: query.flagFilter } }); // async effect
    },
    [pageId, query.flagFilter, search],
  );

  const loadMoreCallback = React.useCallback(() => {
    loadMore();
  }, [loadMore]);

  const addClientCallback = React.useCallback(
    (phone: PhoneNumber | undefined) => {
      navigation.navigate('NewClientScreen', { phone: phone });
    },
    [navigation],
  );

  const importContactsCallback = React.useCallback(() => {
    navigation.navigate('ImportContactsScreen');
  }, [navigation]);

  const flagFilterChangeCallback = React.useCallback(
    (option: ClientsFlagFilter) => {
      search({ pageId: pageId, query: { search: query.search, flagFilter: option } }); // async effect
    },
    [pageId, query.search, search],
  );

  React.useEffect(
    () =>
      subscribe((event) => {
        if (event.type === 'PageClientAdded' && event.pageId === pageId) {
          search({
            pageId: event.pageId,
            query: {
              search: event.details.phoneNumber,
              flagFilter: 'all',
            },
          });
        } else if (event.type === 'PageClientsImported' && event.pageId === pageId) {
          search({
            pageId: event.pageId,
            query: {
              search: query.search,
              flagFilter: query.flagFilter,
            },
          });
        } else if (event.type === 'PageClientUpdated' && event.pageId === pageId) {
          search({
            pageId: event.pageId,
            query: {
              search: query.search,
              flagFilter: query.flagFilter,
            },
          });
        } else if (event.type === 'PageClientDeleted' && event.pageId === pageId) {
          search({
            pageId: event.pageId,
            query: {
              search: query.search,
              flagFilter: query.flagFilter,
            },
          });
        }
      }),
    [subscribe, search, pageId, query.search, query.flagFilter],
  );

  // Init list loading
  React.useEffect(() => {
    init({
      pageId: pageId,
      minQueryLength: page.permissions.clients.getSearchMinSymbols(),
    });
  }, [init, authorization.user._id, pageId, page.details.people]);

  // Reload when selected page changed
  React.useEffect(() => {
    if ((state.type === 'Loading' || state.type === 'Loaded') && state.pageId !== pageId) {
      search({
        pageId: pageId,
        query: state.query,
      });
    } else if (state.type === 'Failed') {
      showError(state.error);
      tryResetFailed();
    }
  }, [state, pageId]);

  return (
    <SearchClientsForm
      query={query.search}
      clients={clients}
      onQueryChange={queryChangeCallback}
      onClientSelected={selectClientCallback}
      onAddClient={addClientCallback}
      onImportContacts={Platform.select({
        ios: importContactsCallback,
        android: importContactsCallback,
      })}
      onLoadMore={loadMoreCallback}
      flagFilter={query.flagFilter}
      showFlagFilter
      onFlagFilterChange={flagFilterChangeCallback}
      style={{ flex: 1, paddingTop: 44, backgroundColor: colors.WHITE }}
      isLoading={isLoading}
      onReload={reload}
    />
  );
};

export default pipe(ClientsScreen, withSearchClientsContextProvider, CurrentBusiness, Authorized);

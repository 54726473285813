import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { SHAMROCK } from '@mero/components/src/styles/colors';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    marginBottom: sizes[16],
    flex: 1,
  },
  labelWrapper: {
    gap: 8,
  },
  acceptedBar: {
    backgroundColor: colors.SHAMROCK,
    width: 10,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  contentWrapper: {
    flex: 1,
  },
  title: {
    fontFamily: 'open-sans-bold',
    fontSize: 17,
  },
  titleWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    padding: 16,
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconContainer: {
    paddingTop: 12,
  },
});

export default styles;

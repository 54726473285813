import { Body, colors, Icon, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

type Props = {
  readonly onPress?: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

const AddBookingButton: React.FC<Props> = ({ style, onPress }) => {
  return (
    <View style={[{ height: 52 }, style]}>
      <TouchableOpacity onPress={onPress} delayPressIn={0}>
        <View
          style={[
            {
              flexDirection: 'row',
              height: 52,
              borderRadius: 25,
              backgroundColor: colors.DARK_BLUE,
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 24,
              width: 'auto',
            },
          ]}
        >
          <Icon type="plus-screw-small" size={32} color={colors.WHITE} />
          <Body style={[{ color: colors.WHITE, marginLeft: 4 }, meroStyles.text.semibold]} numberOfLines={1}>
            Programare nouă
          </Body>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default AddBookingButton;

import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import SelectBookingPerformerScreenView from '../../../components/SelectBookingPerformerScreen';

import { CompositeNavigationProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';

type Props = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    CompositeNavigationProp<
      StackNavigationProp<BookingStackParamList, 'SelectBookingPerformerScreen'>,
      StackNavigationProp<AuthorizedStackParamList, 'Booking'>
    >,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

const SelectBookingPerformerScreen: React.FC<Props> = ({ page }: Props) => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const [, { setSelectedPerformer }] = SelectBookingPerformerContext.useContext();

  const selectWorkerCallback = React.useCallback(
    (selected: SavedWorker) => {
      setSelectedPerformer(selected);
      goBack();
    },
    [goBack, setSelectedPerformer],
  );

  return (
    <SelectBookingPerformerScreenView
      workers={page.workers}
      onBackPressed={goBack}
      onPerformerSelected={selectWorkerCallback}
    />
  );
};

export default pipe(SelectBookingPerformerScreen, CurrentBusiness);

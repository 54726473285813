import { DefinedTrimedString } from '@mero/api-sdk';
import { useShowError, useToast } from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { pipe, identity } from 'fp-ts/lib/function';
import * as React from 'react';

import AddClientScreenView from '../../../components/AddClientScreen';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { ClientDetails } from '../../../contexts/ClientCreateContext';
import { ClientCreateContext, withClientCreateContextProvider } from '../../../contexts/ClientCreateContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList } from '../../../types';

type NewClientScreenProps = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'NewClientScreen'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
  route: RouteProp<AuthorizedStackParamList, 'NewClientScreen'>;
};

const NewClientScreen: React.FC<NewClientScreenProps> = ({ page, route }: NewClientScreenProps) => {
  const showError = useShowError();
  const toast = useToast();
  const [, { pushEvent }] = AppEventsContext.useContext();
  const [state, { tryReset, createClient }] = ClientCreateContext.useContext();
  const inProgress = state.type === 'Creating';

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const addClientCallback = React.useCallback(
    (clientDetails: ClientDetails): void => {
      createClient({ pageId: page.details._id, details: clientDetails });
    },
    [page, createClient],
  );

  React.useEffect(() => {
    if (state.type === 'Created') {
      tryReset();
      pushEvent({
        type: 'PageClientAdded',
        pageId: state.pageId,
        clientId: state.clientId,
        details: state.details,
      });
      goBack();
    } else if (state.type === 'Failed') {
      tryReset();
      if (state.error) {
        showError(state.error);
      } else {
        toast.show({
          type: 'error',
          text: 'Incercarea de a crea clientul nou a eșuat',
        });
      }
    }
  }, [state, tryReset, goBack]);

  return (
    <AddClientScreenView
      clientDetails={{
        phoneNumber: pipe(
          route.params?.phone,
          DefinedTrimedString.decode,
          E.fold(() => undefined, identity),
        ),
      }}
      mode="add"
      onSubmit={inProgress ? undefined : addClientCallback}
      onClosePressed={goBack}
    />
  );
};

export default pipe(NewClientScreen, withClientCreateContextProvider, CurrentBusiness);

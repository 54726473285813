import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

import { COMET } from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProfileNotifications = ({ size = 32, color = COMET, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(-25 -309)">
      <Rect width="32" height="32" transform="translate(25 309)" fill="none" />
      <G transform="translate(29 313)">
        <Path d="M0,0H24V24H0Z" fill="none" />
        <Path
          d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5ZM7.58,4.08,6.15,2.65A10.425,10.425,0,0,0,2.03,10.5h2A8.446,8.446,0,0,1,7.58,4.08ZM19.97,10.5h2a10.489,10.489,0,0,0-4.12-7.85L16.43,4.08A8.5,8.5,0,0,1,19.97,10.5Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(ProfileNotifications);

import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import PageBillingDetailsList from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageBillingDetailsList';
import PageBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageBillingDetailsScreen';
import PageGiftCardsSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageGiftCardsSettingsScreen';
import PageOnlinePaymentsAdvancedPayment from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsAdvancedPayment';
import PageOnlinePaymentsAppointment from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsAppointment';
import PageOnlinePaymentsBalanceScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsBalanceScreen';
import PageOnlinePaymentsBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsBillingDetailsScreen';
import PageOnlinePaymentsSchedule from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsSchedule';
import PageOnlinePaymentsServiceSettings from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsServiceSettings';
import PageOnlinePaymentsSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsSettingsScreen';
import PageOnlinePaymentsStripeScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsStripeScreen';
import PageOnlinePaymentsTermsAndConditions from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsTermsAndConditions';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../contexts/CheckoutSettingsContext';
import { PageOnlinePaymentsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<PageOnlinePaymentsStackParamList>();

function PageOnlinePaymentsSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('onlinePayments');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="PageOnlinePaymentsSettings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="PageOnlinePaymentsSettings"
        component={PageOnlinePaymentsSettingsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetailsList"
        component={PageBillingDetailsList}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetails"
        component={PageBillingDetailsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsBillingDetails"
        component={PageOnlinePaymentsBillingDetailsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsStripe"
        component={PageOnlinePaymentsStripeScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsAdvancedPayment"
        component={PageOnlinePaymentsAdvancedPayment}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsServiceSettings"
        component={PageOnlinePaymentsServiceSettings}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsTermsAndConditions"
        component={PageOnlinePaymentsTermsAndConditions}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="PageOnlinePaymentsSchedule"
        component={PageOnlinePaymentsSchedule}
        options={{ title: t('payoutFrequency'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="PageOnlinePaymentsAppointment"
        component={PageOnlinePaymentsAppointment}
        options={{ title: t('payoutFrequency'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="GiftCards"
        component={PageGiftCardsSettingsScreen}
        options={{ title: t('giftCards'), cardStyle: modalStyle }}
      />

      <Stack.Screen
        name="PageOnlinePaymentsBalance"
        component={PageOnlinePaymentsBalanceScreen}
        options={{
          title: t('accountBalance:onlinePaymentsBalance'),
          cardStyle: modalStyle,
        }}
      />
    </Stack.Navigator>
  );
}

export default pipe(PageOnlinePaymentsSettingsStackNavigator, withBillingDetailsContextProvider('Company'));

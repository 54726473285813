import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as Sentry from 'sentry-expo';

import config from '../config';

export const LogLevel = t.union(
  [t.literal('error'), t.literal('warn'), t.literal('info'), t.literal('debug')],
  'LogLevel',
);

const LogLevelPriority: { [k in LogLevel]: number } = {
  error: 0,
  warn: 10,
  info: 20,
  debug: 30,
};

const shouldLog = (level: LogLevel, atLevel: LogLevel): boolean => LogLevelPriority[level] <= LogLevelPriority[atLevel];

export type LogLevel = t.TypeOf<typeof LogLevel>;

export type LogFn = <Data extends any[]>(msg: string, ...data: Data) => void;

export type Logger = {
  error: LogFn;
  exception: (e: unknown) => void;
  warn: LogFn;
  info: LogFn;
  debug: LogFn;
};

const nop = (): void => {
  // pass
};

const ts = (): string => new Date().toISOString();

const logger = (logLevel: LogLevel): Logger => ({
  error: shouldLog('error', logLevel)
    ? (message, ...data) => {
        console.error(`${ts()} error: ${message}`, ...data);
      }
    : nop,
  exception: shouldLog('error', logLevel)
    ? (e: unknown) => {
        console.error(e);
        try {
          Sentry.Native.captureException(e);
        } catch (err) {
          console.error(err);
        }
      }
    : nop,
  warn: shouldLog('warn', logLevel)
    ? (message, ...data) => {
        console.warn(`${ts()} warn: ${message}`, ...data);
      }
    : nop,
  info: shouldLog('info', logLevel)
    ? (message, ...data) => {
        console.info(`${ts()} info: ${message}`, ...data);
      }
    : nop,
  debug: shouldLog('debug', logLevel)
    ? (message, ...data) => {
        console.debug(`${ts()} debug: ${message}`, ...data);
      }
    : nop,
});

/**
 * TODO: use consoleLogger function
 */
const log = logger(
  pipe(
    config.logLevel,
    LogLevel.decode,
    E.getOrElse((): LogLevel => 'error'),
  ),
);

export const logCatch =
  (message = 'Request failed') =>
  (error: unknown): never => {
    log.error(message, JSON.stringify(error));
    throw error;
  };

export default log;

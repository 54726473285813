import { ServiceId } from '@mero/api-sdk/dist/services';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  useShowError,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import log from '../../../utils/log';

type Props = {
  onClose: () => void;
  serviceIds: ServiceId[];
};

const DeleteServicesDialog: React.FC<Props> = ({ onClose, serviceIds }) => {
  const { t } = useTranslation('services');
  const toast = useToast();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = ServicesContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const cancelDeleteService = () => {
    onClose();
  };

  const bulkDeletePageServices = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    setDeleteInProgress(true);
    try {
      const { totalCount, deletedCount } = await meroApi.pages.bulkDeletePageServices({
        pageId: pageState.page.details._id,
        serviceIds: serviceIds,
      });

      reload({ pageId: pageState.page.details._id });
      onClose();
      if (totalCount - deletedCount > 0) {
        toast.show({
          type: 'warning',
          text: t('deleteServicesPartialSuccess', { count: totalCount - deletedCount }),
        });
      } else {
        toast.show({
          type: 'success',
          text: t('deleteServicesSuccess'),
        });
      }
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(e);
    }
    setDeleteInProgress(false);
  };

  const leftAction = {
    text: 'Anulează',
    onPress: cancelDeleteService,
    flex: 10,
  };

  const rightAction = {
    text: 'Șterge serviciile',
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : bulkDeletePageServices,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancelDeleteService}
          leftAction={leftAction}
          rightAction={rightAction}
          style={{
            width: isPhone ? 375 : 468,
          }}
        >
          <H1>{t('confirmDeleteServices')}</H1>
          <Spacer size="8" />
          <Body>{t('checkDeleteServices')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteServicesCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteServicesCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteServicesCheck3')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteServicesDialog;

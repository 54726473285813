import { PageBillingDetails, SavedPageBillingDetails } from '@mero/api-sdk';
import { colors, Column, FormCard, MeroHeader, Spacer, Title, Line, useShowError, useToast } from '@mero/components';
import { flow, pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import H1 from '@mero/components/lib/components/Text/H1';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import {
  BillingDetailsContext,
  BillingDetailsProps,
  withBillingDetails,
} from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { SubscriptionContext, withSubscriptionInfo } from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import { logCatch } from '../../../../../utils/log';
import AddBillingDetailsMenu from './AddBillingDetailsMenu';
import BillingDetailItem from './BillingDetailItem';
import { styles } from './CompanyBillingDetails.style';

type Props = StackScreenProps<SubscriptionStackParamList, 'BillingDetailsList'> &
  CurrentBusinessProps &
  BillingDetailsProps & {
    // pass
  };

const PageBillingDetailsList: React.FC<Props> = ({ navigation, route, billingDetails, page }) => {
  const { t } = useTranslation('subscription');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const toast = useToast();

  const [, { reloadAsync }] = SubscriptionContext.useContext();
  const [, { reloadAsync: reloadAsyncBillingDetails }] = BillingDetailsContext.useContext();

  const [showAddMenu, setShowAddMenu] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const activeSelectedBillingDetails = React.useMemo(
    () => billingDetails.find((b) => b.usage?.includes('MeroSubscription')),
    [billingDetails],
  );

  const goToBillingDetails = React.useCallback((billingDetails: SavedPageBillingDetails) => {
    navigation.navigate('BillingDetails', {
      type: billingDetails.billing.type,
      billingId: billingDetails._id,
      ...route.params,
    });
  }, []);

  const [selectedBillingDetails, setSelectedBillingDetails] = React.useState(activeSelectedBillingDetails);

  const addNewBillingDetails = React.useCallback((type: PageBillingDetails.Any['type']) => {
    navigation.navigate('BillingDetails', { type, ...route.params });
  }, []);

  const saveChanges = async () => {
    if (!selectedBillingDetails) {
      return;
    }
    try {
      setIsLoading(true);
      await meroApi.pro.pageBillingDetails
        .setPageMeroSubscriptionBilling({
          pageId: selectedBillingDetails.pageId,
          billingDetailsId: selectedBillingDetails._id,
        })
        .catch(logCatch('setPageMeroSubscriptionBilling'));
      reloadAsync({ pageId: page.details._id, withoutDelay: true });
      reloadAsyncBillingDetails({ pageId: page.details._id });

      if (route.params?.then === 'payWithCard') {
        navigation.replace('CardPayment', { meroCompany: route.params.meroCompany });
      } else if (route.params?.then === 'choosePaymentMethod') {
        navigation.replace('PaymentMethod', { meroCompany: route.params.meroCompany });
      } else {
        goBack();
      }

      toast.show({
        type: 'success',
        text: t('billingDetailsSelected'),
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = !selectedBillingDetails || isLoading;

  return (
    <>
      <ModalScreenContainer>
        <MeroHeader
          canGoBack={true}
          onBack={goBack}
          TitleComponent={() => <Title style={{ color: colors.BLACK }}>{t('selectBillingDetails')}</Title>}
          titleComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <ScrollView style={{ paddingHorizontal: 16, paddingBottom: 96 }}>
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 8 }}>{t('selectBillingDetails')}</H1>
          <Spacer size={32} />
          {billingDetails.length > 0 && (
            <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 8, zIndex: 1 }}>
              {billingDetails.map((billingDetail, index) => (
                <React.Fragment key={billingDetail._id}>
                  <Column style={{ paddingVertical: 16, paddingHorizontal: 16, zIndex: billingDetails.length - index }}>
                    <BillingDetailItem
                      isSelected={billingDetail._id === selectedBillingDetails?._id}
                      billingDetail={billingDetail}
                      onPress={setSelectedBillingDetails}
                      onEdit={goToBillingDetails}
                    />
                  </Column>
                  {index < billingDetails.length - 1 && <Line />}
                </React.Fragment>
              ))}
            </FormCard>
          )}
          <Spacer size={billingDetails.length > 0 ? 32 : 10} />
          <Button
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            text={t('addNewBillingDetails')}
            onPress={() => setShowAddMenu(true)}
          />
        </ScrollView>
        {billingDetails.length > 0 && (
          <FormCard
            dropShaddow
            paddings="button"
            style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
          >
            <SafeAreaView edges={['bottom']}>
              <Button text={t('done')} onPress={saveChanges} disabled={isDisabled} />
            </SafeAreaView>
          </FormCard>
        )}
      </ModalScreenContainer>
      {showAddMenu && (
        <AddBillingDetailsMenu
          onAdd={flow(addNewBillingDetails, () => setShowAddMenu(false))}
          onDismiss={() => setShowAddMenu(false)}
        />
      )}
    </>
  );
};

export default pipe(PageBillingDetailsList, withBillingDetails, CurrentBusiness);

import { ProductCategory, ProductCategoryId } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { Checkbox, colors, Column, H3s, Icon, Line, Spacer } from '@mero/components';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../../../../hooks/useClickOutsideWeb';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';

export type Props = {
  activeFilterOwner?: UserId;
  activeFilterCategory?: ProductCategoryId | 'other';
  onChangeOwner: (owner?: UserId) => void;
  onChangeCategory: (category?: ProductCategoryId | 'other') => void;
  pageId: PageId;
};

const ProductsFilter: React.FC<Props> = ({
  activeFilterOwner,
  activeFilterCategory,
  onChangeOwner,
  onChangeCategory,
  pageId,
}) => {
  const { t } = useTranslation('reports');

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [workers, setWorkers] = React.useState<SavedWorker[]>([]);
  const [categories, setCategories] = React.useState<ProductCategory[]>([]);

  const filtersSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: filtersSelectRef,
    isVisible: isFilterOpen,
    onClickOutside() {
      setIsFilterOpen(false);
    },
  });

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  React.useEffect(() => {
    const getData = async () => {
      try {
        const [workers, categories] = await Promise.all([
          meroApi.pages.getPageWorkers(pageId),
          meroApi.pro.products.getAllCategories(pageId),
        ]);

        setWorkers(workers);
        setCategories(categories);
      } catch (error) {
        log.error('Failed to get data', error);
      }
    };

    getData();
  }, [pageId]);

  return (
    <Column style={{ position: 'relative' }}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setIsFilterOpen(!isFilterOpen)}>
        <Body style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>{t('filters')}</Body>
        <Icon type="down" color={colors.DARK_BLUE} />
      </TouchableOpacity>
      {isFilterOpen && (
        <View
          ref={filtersSelectRef}
          style={{
            position: 'absolute',
            top: 36,
            right: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 2,
          }}
        >
          <ScrollView style={{ maxHeight: 400 }}>
            <Spacer size="16" />
            <H3s>{t('pros')}</H3s>
            <Spacer size="16" />
            <TouchableOpacity
              style={{ flex: 2, flexDirection: 'row' }}
              onPress={flow(closeFilter, () => onChangeOwner())}
            >
              <Checkbox
                color="blue"
                disabled={false}
                value={activeFilterOwner === undefined}
                onValueChange={flow(closeFilter, () => onChangeOwner())}
              />
              <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('allPros')}</Body>
            </TouchableOpacity>
            {workers.map((worker) => (
              <>
                <Spacer size="16" />
                <Line />
                <Spacer size="16" />
                <TouchableOpacity
                  style={{ flex: 2, flexDirection: 'row' }}
                  onPress={flow(closeFilter, () => onChangeOwner(worker.user._id))}
                >
                  <Checkbox
                    color="blue"
                    disabled={false}
                    value={activeFilterOwner === worker.user._id}
                    onValueChange={flow(closeFilter, () => onChangeOwner(worker.user._id))}
                  />
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>
                    {worker.user.firstname} {worker.user.lastname}
                  </Body>
                </TouchableOpacity>
              </>
            ))}
            <Spacer size="24" />
            <H3s>{t('groups')}</H3s>
            <Spacer size="16" />
            <TouchableOpacity
              style={{ flex: 2, flexDirection: 'row' }}
              onPress={flow(closeFilter, () => onChangeCategory())}
            >
              <Checkbox
                color="blue"
                disabled={false}
                value={activeFilterCategory === undefined}
                onValueChange={flow(closeFilter, () => onChangeCategory())}
              />
              <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('allGroups')}</Body>
            </TouchableOpacity>
            {categories.map((category) => (
              <>
                <Spacer size="16" />
                <Line />
                <Spacer size="16" />
                <TouchableOpacity
                  style={{ flex: 2, flexDirection: 'row' }}
                  onPress={flow(closeFilter, () => onChangeCategory(category._id))}
                >
                  <Checkbox
                    color="blue"
                    disabled={false}
                    value={activeFilterCategory === category._id}
                    onValueChange={flow(closeFilter, () => onChangeCategory(category._id))}
                  />
                  <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{category.name}</Body>
                </TouchableOpacity>
              </>
            ))}
            <Spacer size="16" />
          </ScrollView>
        </View>
      )}
    </Column>
  );
};

export default ProductsFilter;

import { ClientId } from '@mero/api-sdk/dist/clients';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type ClientDeleteState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Deleting';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Deleted';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientDeleteState => ({
  type: 'Ready',
});

export const ClientDeleteContext = createModelContext(
  defaultState(),
  {
    trySetDeleted: (_, clientId: ClientId) => ({
      type: 'Deleted',
      clientId: clientId,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Deleted' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientDeleteState) => ClientDeleteState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      deleteClient: (clientId: ClientId) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const deleteClient = async () => {
              try {
                await meroApi.clients.deleteClient(clientId);

                dispatch.trySetDeleted(clientId);
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            deleteClient().catch(log.exception);

            return {
              type: 'Deleting',
              clientId: clientId,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientDeleteContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientDeleteContext.Provider>{children}</ClientDeleteContext.Provider>;
};

export const withClientDeleteContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithClientDeleteContextProvider(props: P) {
    return (
      <ClientDeleteContextProvider>
        <Content {...props} />
      </ClientDeleteContextProvider>
    );
  };
};

import * as MeroApi from '@mero/api-sdk';
import { Body, colors, HSpacer, Icon, sizes, SmallBody, styles as meroStyles } from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentCancelledByClientNotification;
};

const AppointmentCancelledByClientNotification = ({ notification }: Props): React.ReactElement | null => {
  const payload = notification.payload;
  const { firstname, lastname } = payload.client;

  const appTs = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_calendar_cancel" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>
            {firstname} {lastname}
          </Body>{' '}
          și-a anulat programarea de{' '}
          <Body style={meroStyles.text.semibold}>{appTs.toFormat('EEEE, d LLLL yyyy', { locale: 'ro' })}</Body> ora{' '}
          <Body style={meroStyles.text.semibold}>{appTs.toFormat('H:mm', { locale: 'ro' })}</Body>{' '}
          {payload.worker && (
            <>
              la{' '}
              <Body style={meroStyles.text.semibold}>
                {payload.worker.firstname} {payload.worker.lastname}
              </Body>{' '}
            </>
          )}
          de la {payload.page.name}. <Body style={meroStyles.text.semibold}>Motiv</Body>: &quot;{payload.reason}&quot;.
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker?.firstname} {payload.worker?.lastname}
        </SmallBody>
      </View>
      {notification.payload.appointment.calendarId ? (
        <>
          <HSpacer left={8} />
          <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
        </>
      ) : null}
    </View>
  );
};

export default AppointmentCancelledByClientNotification;

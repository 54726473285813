import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    borderColor: colors.GEYSER,
    borderWidth: 1,
    borderRadius: sizes[6],
    overflow: 'hidden',
  },
  containerError: {
    borderColor: colors.RADICAL_RED,
  },
  containerDisabled: {
    backgroundColor: colors.ATHENS_GRAY,
  },
  performerRow: {
    flexDirection: 'row',
    paddingTop: sizes[12],
    paddingRight: sizes[8],
    paddingBottom: sizes[12],
    paddingLeft: sizes[16],
  },
  selectProIcon: {
    width: 24,
    height: 24,
    backgroundColor: '#E9ECEF',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: 12,
  },
  errorText: {
    color: colors.RADICAL_RED,
    borderBottomLeftRadius: sizes[6],
    borderBottomRightRadius: sizes[6],
    paddingHorizontal: sizes[12],
    paddingVertical: sizes[4],
    minHeight: 14,
  },
});

import { ReminderId } from '@mero/api-sdk/dist/notifications/reminder-id';
import { AnyReminderInfo } from '@mero/api-sdk/dist/notifications/reminder-info';
import { PageId } from '@mero/api-sdk/dist/pages';
import {
  colors,
  H1,
  H2s,
  H3s,
  Header,
  Icon,
  sizes,
  Spacer,
  styles as meroStyles,
  Switch,
  useShowError,
} from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Column from '@mero/components/lib/components/Layout/Column';
import Body from '@mero/components/lib/components/Text/Body';

import { useIsFocused } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import log from '../../../utils/log';
import AppointmentCard from './AppointmentCard';
import Menu from './Menu';

type Types = 'booking_reminder' | 'ask_for_review';

const Buttons: { label: string; value: Types }[] = [
  {
    label: 'bookingReminder',
    value: 'booking_reminder',
  },
  {
    label: 'askForReview',
    value: 'ask_for_review',
  },
];

const ManualSmsReminderScreen: React.FC = () => {
  const { t } = useTranslation('notifications');

  const [pageState] = CurrentBusinessContext.useContext();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [showMenu, setShowMenu] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState<AnyReminderInfo | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<Types>('booking_reminder');
  const [isLoading, setIsLoading] = React.useState(true);

  const [notifications, setNotifications] = React.useState<Record<Types, AnyReminderInfo[]>>({
    booking_reminder: [],
    ask_for_review: [],
  });

  const onResend = React.useCallback(() => {
    if (selectedAppointment) {
      sendSMS(selectedAppointment.user.phone, selectedAppointment.text);
    }
  }, [selectedAppointment]);

  const onDelete = React.useCallback(async () => {
    if (selectedAppointment && pageState.type === 'Loaded') {
      try {
        await meroApi.notifications.markManualSmsReminderAsDeleted({ reminderId: selectedAppointment.reminderId });
        await getAppointmentList(pageState.page.details._id);
      } catch (error) {
        showError(error);
      }
    }
  }, [selectedAppointment]);

  const onDismiss = React.useCallback(() => {
    setShowMenu(false);
  }, []);

  const goBack = useGoBack();

  const getAppointmentList = React.useCallback(async (pageId: PageId) => {
    try {
      const appointments = await meroApi.notifications.getUpcomingManualSmsReminders({ pageId });

      const reviews = appointments.filter((item) => item.messageType === 'ask_for_review');
      const reminders = appointments.filter((item) => item.messageType === 'booking_reminder');

      setNotifications({
        booking_reminder: reminders,
        ask_for_review: reviews,
      });
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (isFocused && pageState.type === 'Loaded') {
      getAppointmentList(pageState.page.details._id);
    }
  }, [isFocused, pageState.type]);

  const NoBookingReminders = React.useMemo(
    () => (
      <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 32 }}>
        <H2s style={{ textAlign: 'center' }}>{t('noBookingRemindersTitle')}</H2s>
        <Spacer size={8} />
        <Body style={{ textAlign: 'center' }}>{t('noBookingRemindersDescription')}</Body>
      </Column>
    ),
    [],
  );

  const NoAskForReviews = React.useMemo(
    () => (
      <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 32 }}>
        <H2s style={{ textAlign: 'center' }}>{t('noAskForReviewsTitle')}</H2s>
        <Spacer size={8} />
        <Body style={{ textAlign: 'center' }}>{t('noAskForReviewsDescription')}</Body>
      </Column>
    ),
    [],
  );

  const emptyState = React.useMemo(
    () =>
      ({
        ask_for_review: NoAskForReviews,
        booking_reminder: NoBookingReminders,
      } as const),
    [],
  );

  const sendSMS = React.useCallback(async (phoneNumber: string, message: string, reminderId?: ReminderId) => {
    const separator = Platform.OS === 'ios' ? '&' : '?';
    const url = `sms:${phoneNumber}${separator}body=${encodeURIComponent(message)}`;
    try {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
        if (reminderId) {
          await meroApi.notifications.markManualSmsReminderAsSent({ reminderId });
          if (pageState.type === 'Loaded') {
            await getAppointmentList(pageState.page.details._id);
          }
        }
      } else {
        log.debug('Unable to send SMS. Please check if the device has SMS capabilities.');
      }
    } catch (error) {
      log.error('Failed to send SMS', error);
    }
  }, []);

  const sendWhatsApp = React.useCallback(async (phoneNumber: string, message: string, reminderId?: ReminderId) => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    try {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
        if (reminderId) {
          await meroApi.notifications.markManualSmsReminderAsSent({ reminderId });
          if (pageState.type === 'Loaded') {
            await getAppointmentList(pageState.page.details._id);
          }
        }
      } else {
        log.debug('Unable to send WhatsApp message. Please check if the device has WhatsApp installed.');
      }
    } catch (error) {
      log.error('Failed to send WhatsApp message', error);
    }
  }, []);

  const notificationsGrouped = React.useMemo(() => {
    return notifications[selectedTab]
      .filter((a) => a.manualSMSStatus?.type !== 'deleted')
      .reduce(
        (acc: { older: AnyReminderInfo[]; today: AnyReminderInfo[]; upcoming: AnyReminderInfo[] }, appointment) => {
          const appointmentDate = DateTime.fromJSDate(appointment.start);
          const today = DateTime.local();

          if (appointmentDate.hasSame(today, 'day') && appointmentDate.hasSame(today, 'month')) {
            return {
              ...acc,
              today: [...acc.today, appointment],
            };
          }

          if (appointmentDate < today) {
            return {
              ...acc,
              older: [...acc.older, appointment],
            };
          }

          return {
            ...acc,
            upcoming: [...acc.upcoming, appointment],
          };
        },
        { older: [], today: [], upcoming: [] },
      );
  }, [selectedTab, notifications]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
        text={t('messageReminder')}
      />
      <ScrollView
        style={{ paddingHorizontal: 16 }}
        contentContainerStyle={notifications[selectedTab].length === 0 && { flex: 1 }}
      >
        <Spacer size={24} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('messageReminder')}</H1>
        <Spacer size={32} />
        <Switch
          height={sizes[32]}
          textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
          buttons={Buttons.map((item) => ({
            value: item.value,
            label: t(item.label, { count: notifications[item.value].length }),
          }))}
          defaultValue={selectedTab}
          onChange={(value) => {
            setSelectedTab(value as Types);
          }}
        />
        {notifications[selectedTab].length > 0 && (
          <>
            {notificationsGrouped.older.length > 0 && (
              <>
                <Spacer size={32} />
                <H3s style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('olderAppointments')}</H3s>
                {notificationsGrouped.older.map((item, index) => (
                  <React.Fragment key={index}>
                    <Spacer size={16} />
                    <AppointmentCard
                      appointment={item}
                      onSendSms={sendSMS}
                      onSendWhatsApp={sendWhatsApp}
                      onMenuPress={(item) => {
                        setSelectedAppointment(item);
                        setShowMenu(true);
                      }}
                    />
                  </React.Fragment>
                ))}
              </>
            )}
            {notificationsGrouped.today.length > 0 && (
              <>
                <Spacer size={32} />
                <H3s style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('todayAppointments')}</H3s>
                {notificationsGrouped.today.map((item, index) => (
                  <React.Fragment key={index}>
                    <Spacer size={16} />
                    <AppointmentCard
                      appointment={item}
                      onSendSms={sendSMS}
                      onSendWhatsApp={sendWhatsApp}
                      onMenuPress={(item) => {
                        setSelectedAppointment(item);
                        setShowMenu(true);
                      }}
                    />
                  </React.Fragment>
                ))}
              </>
            )}
            {notificationsGrouped.upcoming.length > 0 && (
              <>
                <Spacer size={32} />
                <H3s style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('nextAppointments')}</H3s>
                {notificationsGrouped.upcoming.map((item, index) => (
                  <React.Fragment key={index}>
                    <Spacer size={16} />
                    <AppointmentCard
                      appointment={item}
                      onSendSms={sendSMS}
                      onSendWhatsApp={sendWhatsApp}
                      onMenuPress={(item) => {
                        setSelectedAppointment(item);
                        setShowMenu(true);
                      }}
                    />
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )}
        {notifications[selectedTab].length === 0 && !isLoading && emptyState[selectedTab]}
        <Spacer size={32} />
      </ScrollView>
      {showMenu && selectedAppointment ? (
        <Menu
          message={selectedAppointment.text}
          manualSMSSent={selectedAppointment.manualSMSStatus?.type === 'sent'}
          onResend={onResend}
          onDelete={onDelete}
          onDismiss={onDismiss}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default ManualSmsReminderScreen;

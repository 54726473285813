import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { MeroHeader, colors, Icon, Row, H1, Spacer, FormCard, H3s, styles, Line } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Svg, { G, Path, SvgProps, Circle } from 'react-native-svg';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SelectedProductsTabContext } from '../../../contexts/SelectedProductsTabContext';
import { MenuTabStackParamList, ProductsTabStackParamList } from '../../../types';

type Props = AuthorizedProps &
  StackScreenProps<MenuTabStackParamList & ProductsTabStackParamList, 'ProductsMenuScreen'>;

export const BoxIcon = (props: SvgProps) => (
  <Svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24" {...props}>
    <G
      transform="translate(1.001 1.001)"
      fill="none"
      stroke={props.color ?? '#202020'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.3"
    >
      <Path d="M20,5.989,11,2,2,5.989V16.008L11,20l9-3.989Z" />
      <Path d="M2,10.19l9,3.989,9-3.989M6.919,8l9,3.989M11,14.179V24.2" transform="translate(0 -4.2)" />
      <Path d="M45.6,31.6,42,33.19v3.39l3.6-1.59Z" transform="translate(-28.002 -20.721)" />
    </G>
  </Svg>
);

export const LabelTagIcon = (props: SvgProps) => (
  <Svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24" {...props}>
    <Path id="Path_8333" data-name="Path 8333" d="M0,0H24V24H0Z" fill="none" />
    <G
      transform="translate(0.381 0.381)"
      fill="none"
      stroke={props.color ?? '#202020'}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    >
      <Path d="M9.717,20.238,3,13.521,13.521,3l6.3.416.416,6.3Z" />
      <Circle data-name="layer2" cx="1.5" cy="1.5" r="1.5" transform="translate(15 5.238)" />
      <Path
        d="M23.358,21.4l3.358,3.358m-6.717,0,2.526,2.526m-.862-4.22,1.694,1.694"
        transform="translate(-11.948 -12.931)"
      />
    </G>
  </Svg>
);

export const HandTruckIcon = (props: SvgProps) => (
  <Svg width={props.width ?? 19.36} height={props.height ?? 17.401} viewBox="0 0 19.36 17.401" {...props}>
    <G
      transform="translate(-2.4 -5.3)"
      fill="none"
      stroke={props.color ?? '#202020'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    >
      <Path
        id="layer2"
        d="M12.311,22.266l6.828-2.452L21,20.435m-11.794-.31L5.483,10.1H3"
        transform="translate(0 -2.896)"
      />
      <Circle id="layer2-2" data-name="layer2" cx="2.483" cy="2.483" r="2.483" transform="translate(7.345 17.135)" />
      <Path id="layer1" d="M33.07,14.125,24.348,17.29,21.4,9.066,30.121,5.9Z" transform="translate(-12.689)" />
      <Path
        id="layer1-2"
        data-name="layer1"
        d="M33.855,9.5l1.4,4-2.793,1.055L31,10.462"
        transform="translate(-19.31 -2.483)"
      />
    </G>
  </Svg>
);

export const SurveyIcon = (props: SvgProps) => (
  <Svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24" {...props}>
    <G
      transform="translate(-3.198 1.006)"
      fill="none"
      stroke={props.color ?? '#202020'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    >
      <Path d="M17.2,14H16V26h9.6V14H24.4m-6,3h4.8m-4.8,3h4.8m-4.8,3h4.8" transform="translate(-5.601 -8.404)" />
      <Path
        d="M18.527,8H21.8a.6.6,0,0,1,.6.6v15a.6.6,0,0,1-.6.6H8.6a.6.6,0,0,1-.6-.6V8.6A.6.6,0,0,1,8.6,8h3.269"
        transform="translate(0 -4.204)"
      />
      <Path
        d="M25.691,3.2h-.63a1.5,1.5,0,0,0-2.939,0h-.63a1.41,1.41,0,0,0-1.5,1.5v.9h7.2V4.7a1.44,1.44,0,0,0-1.5-1.5Z"
        transform="translate(-8.394)"
      />
    </G>
  </Svg>
);

export const InventoryIcon = (props: SvgProps) => (
  <Svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 24 24">
    <G
      fill="none"
      stroke={props.color ?? '#202020'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      transform="translate(1.002 1.002)"
    >
      <Path d="M16,11V2h9v9M19.6,4.4h1.8" transform="translate(-9.801)" />
      <Path d="M32,32h9v9H32m3.6-6.6h1.8" transform="translate(-21.002 -21.002)" />
      <Path d="M2,32h9v9H2Zm3.6,2.4H7.4" transform="translate(0 -21.002)" />
    </G>
  </Svg>
);

export const TextToolItem = (props: SvgProps) => (
  <Svg width={props.width ?? 32} height={props.height ?? 32} viewBox="0 0 32 32">
    <G transform="translate(4 4)">
      <Path d="M0,0H24V24H0Z" fill="none" />
      <G
        transform="translate(-3.033 0.361)"
        fill="none"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      >
        <Path d="M7,12.57V10H18.567v2.57M12.783,10V24.137m-3.213,0H16" transform="translate(0 -5.43)" />
        <Path d="M57,2V21.278" transform="translate(-33.935 0)" />
      </G>
    </G>
  </Svg>
);

const ProductsMenuScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();

  const [_selectedTab, { setSelectedTab }] = SelectedProductsTabContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  if (pageState.type !== 'Loaded') {
    return;
  }

  const cardItems: { icon: ReactJSXElement; text: string; click: () => void }[] = [
    {
      icon: <BoxIcon color={colors.BLACK} width={24} height={24} />,
      text: t('productsTitle'),
      click: () => {
        navigateProductsCallback();
        setSelectedTab('products');
      },
    },
    {
      icon: <LabelTagIcon />,
      text: t('brandsTitle'),
      click: () => {
        navigateProductsCallback();
        setSelectedTab('brands');
      },
    },
    // {
    //   icon: <HandTruckIcon />,
    //   text: t('suppliersTitle'),
    //   click: () => {
    //     navigateProductsCallback();
    //     setSelectedTab('suppliers');
    //   },
    // },
    // {
    //   icon: <SurveyIcon />,
    //   text: t('documentsTitle'),
    //   click: () => {
    //     navigateProductsCallback();
    //     setSelectedTab('documents');
    //   },
    // },
    // {
    //   icon: <InventoryIcon />,
    //   text: t('inventoriesTitle'),
    //   click: () => {
    //     navigateProductsCallback();
    //     setSelectedTab('inventories');
    //   },
    // },
  ];

  const navigateProductsCallback = React.useCallback(() => {
    navigation.navigate('ProductsDashboardScreen');
  }, [navigation]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack={true} onBack={() => navigation.navigate('MenuScreen')} title={t('productsAndInventory')} />

      <View style={{ paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1>{t('productsAndInventory')}</H1>

        <Spacer size={38} />
        <FormCard dropShaddow rounded paddings="none" style={{ paddingHorizontal: 20 }}>
          {cardItems.map((cardItem, index) => (
            <View key={index}>
              <TouchableOpacity onPress={cardItem.click}>
                <Row style={{ justifyContent: 'space-between', paddingVertical: 20 }}>
                  <Row>
                    {cardItem.icon}
                    <H3s style={[styles.text.semibold, { marginLeft: 16 }]}>{cardItem.text}</H3s>
                  </Row>
                  <Icon type="arrow-right" />
                </Row>
              </TouchableOpacity>
              {index !== cardItems.length - 1 && <Line />}
            </View>
          ))}
        </FormCard>
      </View>
    </ModalScreenContainer>
  );
};

export default pipe(ProductsMenuScreen, Authorized);

import * as MeroApi from '@mero/api-sdk';
import { AppointmentStatus, colors, Icon, Line, Row, sizes, Spacer } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import UserAppointmentDate from '../UserAppointmentDate';
import UserAppointmentRecurrenceRule from '../UserAppointmentRecurrenceRule';
import UserAppointmentSummary from '../UserAppointmentSummary';
import { styles } from './styles';

const BoostIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7262">
      <Path data-name="Rectangle 55" fill="none" d="M0 0h24v24H0z" />
      <Path
        d="M10 16.137a4.246 4.246 0 0 1-3.828 2.427 4.279 4.279 0 0 1 2.452-3.828l.3.3a3.845 3.845 0 0 0-1.251 2.019A3.837 3.837 0 0 0 9.7 15.836Zm-.666-6.616a5.047 5.047 0 0 0-3.363 1.524A5.367 5.367 0 0 0 5 12.363a2.536 2.536 0 0 1 3-.234 13.123 13.123 0 0 1 1.334-2.608Zm3.036 10.208a5.332 5.332 0 0 0 1.319-.971 5.023 5.023 0 0 0 1.519-3.373 14.27 14.27 0 0 1-2.608 1.344 2.538 2.538 0 0 1-.23 3Zm6.599-13.974q-.375-.026-.736-.026c-5.447 0-8.438 4.332-9.392 7.589l2.583 2.583C14.8 14.819 19 11.947 19 6.571v-.034c0-.255-.011-.516-.031-.782ZM15.5 10.396a1.167 1.167 0 1 1 1.167-1.167 1.166 1.166 0 0 1-1.167 1.167Z"
        fill="#7431f7"
      />
    </G>
  </Svg>
);

export type Props = {
  /**
   * Appointment to display.
   */
  appointment: MeroApi.calendar.UserAppointment;
  /**
   * Do not show right arrow (item not clickable)
   */
  hideArrow?: boolean;
  isBoost?: boolean;
};

export default function UserAppointmentListItem({
  appointment,
  hideArrow,
  isBoost = false,
}: Props): React.ReactElement {
  const [isInPast, setIsInPast] = React.useState(false);
  React.useEffect(() => {
    setIsInPast(appointment.start.getTime() < Date.now());
  }, []);
  return (
    <View style={[styles.container]}>
      <View style={[styles.header]}>
        <View>
          <AppointmentStatus status={appointment.status} inPast={isInPast} />
          <Spacer size="4" />
          <Row>
            <UserAppointmentDate date={appointment.start} timezone={appointment.timezone} />
            {isBoost && <BoostIcon />}
          </Row>
          {!!appointment.recurrenceRule && (
            <>
              <Spacer size="4"></Spacer>
              <UserAppointmentRecurrenceRule
                recurrenceRule={appointment.recurrenceRule}
              ></UserAppointmentRecurrenceRule>
            </>
          )}
        </View>
        {!hideArrow ? (
          <View style={styles.iconContainer}>
            <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE}></Icon>
          </View>
        ) : null}
      </View>

      <Spacer size="16" />
      <Line color="#E9ECEF" />
      <Spacer size="16" />

      <UserAppointmentSummary appointment={appointment}></UserAppointmentSummary>
    </View>
  );
}

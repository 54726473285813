import { isDefined } from '@mero/api-sdk';
import {
  customizeService,
  mergedService,
  MergedService,
  mergeServices,
  SavedService,
  ServiceId,
} from '@mero/api-sdk/dist/services';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import { groupServices, ServicesGroup } from '../../../components/GroupedServicesList';
import SelectServiceScreenView from '../../../components/SelectServiceScreen';

import { StackScreenProps } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../../../contexts/SelectBookingServiceContext';
import { SelectBookingServiceStackParamList } from '../../../types';

type SelectServiceProps = CurrentBusinessProps &
  StackScreenProps<SelectBookingServiceStackParamList, 'SelectBookingServiceScreen'>;

const SelectBookingServiceScreen: React.FC<SelectServiceProps> = ({ page, route }: SelectServiceProps) => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const { bookingPageId } = route.params ?? {};

  const [{ servicesFilter }, { setSelectedService }] = SelectBookingServiceContext.useContext();
  const [selectedWorkerState] = SelectBookingPerformerContext.useContext();

  const selectServiceCallback = React.useCallback(
    (service: MergedService) => {
      setSelectedService(service);
      goBack();
    },
    [setSelectedService, goBack],
  );

  const groupsWithServices: ServicesGroup[] = React.useMemo(() => {
    const { serviceGroups, services: pageServices } = page.details;

    // when no worker is selected, the full list of page services,
    // will be shown
    if (selectedWorkerState.type === 'selected') {
      if (!bookingPageId || page.details._id === bookingPageId) {
        type ServiceById = { [K in ServiceId]?: SavedService };
        const workerServicesMap = selectedWorkerState.selected.services.reduce(
          (acc: ServiceById, s): ServiceById => ({ ...acc, [s._id]: s }),
          {},
        );

        const servicesToDisplay = pageServices
          .map((pageService) => {
            const workerService = workerServicesMap[pageService._id];
            if (workerService !== undefined) {
              return mergedService.fromSavedService(customizeService(pageService, workerService));
            } else {
              return undefined;
            }
          })
          .filter(
            (service): service is Exclude<typeof service, undefined> =>
              service !== undefined && servicesFilter(selectedWorkerState.selected, service),
          );

        return groupServices(servicesToDisplay, serviceGroups, 'Alte Servicii');
      } else {
        const servicesToDisplay = selectedWorkerState.selected.services
          .filter((s) => s.pageId === bookingPageId)
          .map(mergedService.fromSavedService);

        return groupServices(servicesToDisplay, [], 'Alte Servicii');
      }
    } else {
      type ServicesById = { [K in ServiceId]?: SavedService[] };
      const workersServicesMap = page.workers.reduce((acc: ServicesById, worker) => {
        worker.services.forEach((ws) => {
          const services = acc[ws._id] ?? [];
          services.push(ws);
          acc[ws._id] = services;
        });

        return acc;
      }, {});

      const servicesToDisplay = pageServices
        .map((pageService) => mergeServices(pageService, workersServicesMap[pageService._id] ?? []))
        .filter(isDefined);

      return groupServices(servicesToDisplay, serviceGroups, 'Alte Servicii');
    }
  }, [page, servicesFilter, selectedWorkerState]);

  return (
    <SelectServiceScreenView
      services={groupsWithServices}
      onBackPressed={goBack}
      onServiceSelected={selectServiceCallback}
    />
  );
};

export default pipe(SelectBookingServiceScreen, CurrentBusiness);

import { DateInterval } from '@mero/api-sdk';
import { CheckoutReportByClientSortType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutReportByClientSortType';
import { CheckoutSaleOwnerSalesReportItem } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutSaleOwnerSalesReportItem';
import { CheckoutSaleOwnerSalesReportTotals } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutSaleOwnerSalesReportTotals';
import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { colors, SmallBody, styles as meroStyles } from '@mero/components';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';

import { useIsFocused } from '@react-navigation/native';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import { nameGenerator } from '../../../../../../utils/string';
import { Interval } from '../ActiveIntervalView';
import ProDetailsModal from './ProDetailsModal';
import Table, { Column } from './Table';

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  activeInterval: Interval;
  itemType: 'all' | 'services' | 'products' | 'memberships';
  updateSort: (sort: { readonly by: CheckoutReportByClientSortType; readonly dir: SortDirection } | undefined) => void;
};

const Pros: React.FC<Props> = ({ pageId, dateInterval, activeInterval, itemType, updateSort }) => {
  const { t } = useTranslation('reports');
  const isFocused = useIsFocused();
  const [reports, setReports] = React.useState<CheckoutSaleOwnerSalesReportItem<MeroUnits.Any>[]>([]);
  const [total, setTotal] = React.useState<CheckoutSaleOwnerSalesReportTotals<MeroUnits.Any>>();
  const [nextPage, setNextPage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sort, setSort] = React.useState<
    | {
        readonly by: CheckoutReportByClientSortType;
        readonly dir: SortDirection;
      }
    | undefined
  >(undefined);
  const [selectedPro, setSelectedPro] = React.useState<UserId>();
  const lastRequest = React.useRef<symbol>(Symbol());
  const loadingNextPage = React.useRef('');

  const getData = async (page?: string) => {
    setIsLoading(true);
    const request = Symbol();
    lastRequest.current = request;
    try {
      const response = await meroApi.checkoutReports.getSalesByOwnerReport({
        pageId,
        unit: 'RON',
        interval: dateInterval,
        sort,
        page,
      });

      if (request === lastRequest.current) {
        setTotal(response.data.totals);
        setNextPage(response.next);
        setReports((prev) => (typeof page === 'string' ? [...prev, ...response.data.items] : response.data.items));
      }
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortBy = async (field: string, direction: SortDirection) => {
    switch (field) {
      case 'name':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'percentage':
        setSort({ by: 'gross', dir: direction });
        break;
      case 'appointments':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'all':
        setSort({ by: 'appointments-count', dir: direction });
        break;
      case 'services':
        setSort({ by: 'services-count', dir: direction });
        break;
      case 'products':
        setSort({ by: 'products-count', dir: direction });
        break;
      case 'memberships':
        setSort({ by: 'memberships-count', dir: direction });
        break;
      case 'net':
        setSort({ by: 'net', dir: direction });
        break;
      case 'gross':
        setSort({ by: 'gross', dir: direction });
        break;
      case 'vat':
        setSort({ by: 'vat', dir: direction });
        break;
      case 'protocol':
        setSort({ by: 'protocol', dir: direction });
        break;
      case 'discount':
        setSort({ by: 'discount', dir: direction });
        break;
      case 'total':
        setSort({ by: 'total', dir: direction });
        break;
      default:
        getData();
        break;
    }
  };

  const onLoadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      loadingNextPage.current = nextPage;
      getData(nextPage);
    }
  };

  React.useEffect(() => {
    getData();
  }, [itemType, JSON.stringify(sort), JSON.stringify(dateInterval)]);

  // React.useEffect(() => {
  //   if (isFocused) {
  //     getData();
  //   }
  // }, [isFocused]);

  React.useEffect(() => {
    updateSort(sort);
  }, [sort]);

  const { columns, data, fixedRows } = React.useMemo(() => {
    const data = reports.map((report) => ({
      _id: report.saleOwner._id,
      name: nameGenerator(report.saleOwner, t('unknownUser')),
      percentage: `${scaledToString(report.percents.total)} %`,
      appointmentsCount: report.appointmentsCount,
      servicesCount: report.servicesCount,
      productsCount: report.productsCount,
      membershipsCount: report.membershipsCount,
      servicesValue: `${scaledToString(report.servicesTotals.total.amount)} ${t(report.servicesTotals.total.unit)}`,
      membershipConsumption: `${scaledToString(report.membershipConsumption.amount)} ${t(
        report.membershipConsumption.unit,
      )}`,
      net: `${scaledToString(report.deliveredTotals.net.amount)} ${t(report.totals.net.unit)}`,
      gross: `${scaledToString(report.deliveredTotals.gross.amount)} ${t(report.totals.gross.unit)}`,
      vat: `${scaledToString(report.deliveredTotals.vat.amount)} ${t(report.totals.vat.unit)}`,
      protocol: `${scaledToString(report.deliveredTotals.protocol.amount)} ${t(report.totals.protocol.unit)}`,
      discount: `${scaledToString(report.deliveredTotals.discount.amount)} ${t(report.totals.discount.unit)}`,
      total: `${scaledToString(report.deliveredTotals.total.amount)} ${t(report.totals.total.unit)}`,
    }));

    const totalPlaceholderId = 'total' as UserId;

    const fixedRows = total
      ? [
          {
            _id: totalPlaceholderId,
            name: t('totalRow'),
            percentage: `100 %`,
            appointmentsCount: total.appointmentsCount,
            servicesCount: total.servicesCount,
            productsCount: total.productsCount,
            membershipsCount: total.membershipsCount,
            servicesValue: `${scaledToString(total.servicesTotals.total.amount)} ${t(total.servicesTotals.total.unit)}`,
            membershipConsumption: `${scaledToString(total.membershipConsumption.amount)} ${t(
              total.membershipConsumption.unit,
            )}`,
            net: `${scaledToString(total.deliveredTotals.net.amount)} ${t(total.deliveredTotals.net.unit)}`,
            gross: `${scaledToString(total.deliveredTotals.gross.amount)} ${t(total.deliveredTotals.gross.unit)}`,
            vat: `${scaledToString(total.deliveredTotals.vat.amount)} ${t(total.deliveredTotals.vat.unit)}`,
            discount: `${scaledToString(total.deliveredTotals.discount.amount)} ${t(
              total.deliveredTotals.discount.unit,
            )}`,
            protocol: `${scaledToString(total.deliveredTotals.protocol.amount)} ${t(
              total.deliveredTotals.protocol.unit,
            )}`,
            total: `${scaledToString(total.deliveredTotals.total.amount)} ${t(total.deliveredTotals.total.unit)}`,
          },
        ]
      : [];

    const moreSpace = itemType === 'all' ? 2 : 2;

    const columns: Column<(typeof data)[number]>[] = [
      {
        field: 'name',
        headerName: t('proNameColumn'),
        width: '16%',
        minWidth: 150,
        sortable: false,
        CustomComponent: ({ children, _id }) => (
          <TouchableOpacity onPress={() => setSelectedPro(_id)} disabled={_id === totalPlaceholderId}>
            <SmallBody style={[meroStyles.text.semibold, _id !== totalPlaceholderId && { color: colors.DARK_BLUE }]}>
              {children}
            </SmallBody>
          </TouchableOpacity>
        ),
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'percentage',
        headerName: '%',
        width: '7%',
        minWidth: 100,
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'appointmentsCount',
        headerName: t('appointmentsCountColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'servicesCount',
        headerName: t(`servicesCountColumn`),
        width: `7%`,
        minWidth: 100,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        // hidden: itemType !== 'all' && itemType !== 'services',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'productsCount',
        headerName: t(`productsCountColumn`),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        hidden: itemType !== 'all' && itemType !== 'products',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'membershipsCount',
        headerName: t(`membershipsCountColumn`),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        // hidden: itemType !== 'all' && itemType !== 'memberships',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'servicesValue',
        headerName: t(`servicesValueColumn`),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        // hidden: itemType !== 'all' && itemType !== 'memberships',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'membershipConsumption',
        headerName: t(`membershipConsumptionColumn`),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy(itemType, direction),
        hidden: itemType !== 'all' && itemType !== 'memberships',
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'net',
        headerName: t('netTotalColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('net', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'gross',
        headerName: t('grossTotalColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('gross', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'vat',
        headerName: t('vatColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('vat', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'protocol',
        headerName: t('protocolColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'discount',
        headerName: t('discountColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: false,
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'total',
        headerName: t('totalWithVatColumn'),
        width: `7%`,
        minWidth: 150,
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('total', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    return {
      columns,
      data,
      fixedRows,
    };
  }, [reports]);

  const selectedReportLine = React.useMemo(
    () => (selectedPro ? reports.find((report) => report.saleOwner._id === selectedPro) : undefined),
    [selectedPro],
  );

  return (
    <>
      <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, zIndex: -1 }}>
        <Table
          columns={columns}
          data={data}
          loading={isLoading}
          loadMore={onLoadMore}
          fixedRows={fixedRows}
          defaultSortBy="total"
          defaultSortDir="ASC"
        />
      </FormCard>
      {selectedPro && selectedReportLine ? (
        <ProDetailsModal
          details={selectedReportLine}
          initialInterval={activeInterval}
          onClose={() => setSelectedPro(undefined)}
        />
      ) : null}
    </>
  );
};

export default Pros;

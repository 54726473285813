import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { RADICAL_RED } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ClientBlocked: React.FC<Props> = ({ size = 11, color = RADICAL_RED, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 11 11" disabled={disabled} onPress={onPress}>
    <Path
      d="M-5239.5-8969a5.506,5.506,0,0,1-5.5-5.5,5.507,5.507,0,0,1,5.5-5.5,5.506,5.506,0,0,1,5.5,5.5A5.505,5.505,0,0,1-5239.5-8969Zm-3-6a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z"
      transform="translate(5245.001 8980)"
      fill={color}
    />
  </Svg>
);

export default React.memo(ClientBlocked);

import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Journal = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <G transform="translate(-4 -4) translate(7 7)" data-name="Group 6840">
        <Path data-name="Rectangle 2639" transform="translate(-3 -3)" fill="none" d="M0 0H24V24H0z" />
        <Path
          data-name="Path 9067"
          d="M15 4.5v6.75h-3.75V15H4.5V4.5H15M15 3H4.5A1.5 1.5 0 003 4.5V15a1.5 1.5 0 001.5 1.5H12l4.5-4.5V4.5A1.5 1.5 0 0015 3zm-5.25 8.25H6v-1.5h3.75zm3.75-3H6v-1.5h7.5z"
          transform="translate(-.75 -.75)"
          fill={color || '#52577f'}
        />
      </G>
    </Svg>
  );
};

export default Journal;

import { BoostClient } from '@mero/api-sdk/dist/clients';
import {
  Body,
  BottomNotification,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Line,
  MeroHeader,
  Row,
  sizes,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Switch,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { Path } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import H3s from '@mero/components/lib/components/Text/H3s';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { MarketingContext } from '../../../../../contexts/MarketingContext';
import { AuthorizedStackParamList, MarketingSettingsStackParamList } from '../../../../../types';
import ActiveIntervalView, { getIntervals, Interval } from '../PageReportsScreen/ActiveIntervalView';
import ClientCard from './ClientCard';

const TooltipIcon: React.FC<{ color?: string }> = ({ color = '#52577f' }) => (
  <Svg width={18} height={18}>
    <Path data-name="Path 8313" d="M0 0h18v18H0Z" fill="none" />
    <Path
      data-name="Path 8314"
      d="M8.3 5.5h1.4v1.4H8.3Zm0 2.8h1.4v4.2H8.3ZM9 2a7 7 0 1 0 7 7 7 7 0 0 0-7-7Zm0 12.6A5.6 5.6 0 1 1 14.6 9 5.607 5.607 0 0 1 9 14.6Z"
      fill={color}
    />
  </Svg>
);

const ClientTabs = {
  new: { label: 'newClients', value: 'new' },
  claimed: { label: 'claimedClients', value: 'claimed' },
} as const;

export type ClientTabs = keyof typeof ClientTabs;

const ClientTabsOptions = [ClientTabs.new, ClientTabs.claimed];

const EmptyState = {
  new: 'emptyNew',
  claimed: 'emptyClaimed',
} as const;

const ClientType = {
  new: 'newClientsCount',
  claimed: 'claimedClientsCount',
} as const;

export type Props2 = StackScreenProps<MarketingSettingsStackParamList, 'Boost'>;
export type Props = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    StackNavigationProp<MarketingSettingsStackParamList, 'Boost'>,
    StackNavigationProp<AuthorizedStackParamList, 'Menu'>
  >;
};

const BoostScreen: React.FC<Props> = ({ page, navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('marketing');
  const insets = useSafeAreaInsets();

  const [pageState] = CurrentBusinessContext.useContext();

  const [{ analytics, clients }, { getPageBoostIntervalStats }] = MarketingContext.useContext();
  const [selectedTab, setSelectedTab] = React.useState<ClientTabs>('new');
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState('');

  const [activeInterval, setActiveInterval] = React.useState<Interval>(getIntervals()[0]);

  const updateData = async () => {
    if (pageState.type === 'Loaded') {
      await getPageBoostIntervalStats({
        pageId: pageState.page.details._id,
        from: activeInterval.value.start.toJSDate(),
        to: activeInterval.value.end.toJSDate(),
      });
    }
  };

  React.useEffect(() => {
    updateData();
  }, [activeInterval, pageState.type]);

  const profitPercentage = React.useMemo(() => {
    if (analytics) {
      const total = analytics?.cost + analytics?.profit;
      return total ? analytics?.profit / total : 0;
    }

    return 0;
  }, [analytics]);

  const showTooltip = (text: string) => () => {
    setTooltipVisible(true);
    setTooltipText(text);
  };

  const rightAction = {
    text: t('ok'),
    onPress: () => setTooltipVisible(false),
  };

  const navigateBoostClientDetails = React.useCallback(
    (client: BoostClient): void => {
      if (client.client) {
        // FIXME: get page details from server-side
        navigation.navigate('ClientDetails', {
          screen: 'DetailsScreen',
          params: { pageId: page.details._id, clientId: client.client?._id },
        });
      } else {
        // FIXME: handle this case?
      }
    },
    [navigation],
  );

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER, position: 'relative' }}>
        <MeroHeader canGoBack onBack={goBack} title={t('boost')} />
        <ScrollView style={{ paddingTop: 16, flex: 1, paddingHorizontal: 16 }}>
          <H1 style={{ paddingHorizontal: 24 }}>{t('boost')}</H1>
          <Spacer size={32} />
          <Row>
            <ActiveIntervalView
              fullWidth
              activeInterval={activeInterval}
              setActiveInterval={setActiveInterval}
              showFutureDates={true}
            />
          </Row>

          <Spacer size={16} />
          <FormCard rounded paddings="none" dropShaddow>
            <Spacer size={16} />
            <Row style={{ paddingHorizontal: 16, justifyContent: 'space-between' }}>
              <Column>
                <Row style={{ alignItems: 'center' }}>
                  <SmallBody style={{ fontFamily: 'open-sans-semibold', color: '#7431F7' }}>
                    {t('profitBoost').toUpperCase()}
                  </SmallBody>
                  <TouchableOpacity style={{ paddingLeft: 6 }} onPress={showTooltip(t('profitBoostTooltip'))}>
                    <TooltipIcon color="#7431F7" />
                  </TouchableOpacity>
                </Row>
                <Spacer size={6} />
                <H2s style={{ color: '#7431F7' }}>
                  {analytics?.profit ?? 0} {t('currency')}
                </H2s>
              </Column>
              <Column>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', color: '#a8aabe' }}>
                  {t('costBoost').toUpperCase()}
                </SmallBody>
                <Spacer size={6} />
                <H2s style={{ color: '#a8aabe' }}>
                  {analytics?.cost ?? 0} {t('currency')}
                </H2s>
              </Column>
            </Row>
            <Spacer size={16} />
            <Row style={{ height: 4 }}>
              <Column style={{ flex: profitPercentage, backgroundColor: '#7431F7' }} />
              <Column style={{ flex: 1 - profitPercentage, backgroundColor: '#a8aabe' }} />
            </Row>
            <Column style={{ padding: 16 }}>
              <Row style={{ alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                  {t('newClientsBoost').toUpperCase()}
                </SmallBody>
                <TouchableOpacity style={{ paddingLeft: 6 }} onPress={showTooltip(t('newClientsBoostTooltip'))}>
                  <TooltipIcon />
                </TouchableOpacity>
              </Row>
              <Spacer size={6} />
              <H2s>{analytics?.newClientsCount ?? 0}</H2s>
            </Column>
            <Column style={{ padding: 16 }}>
              <Row style={{ alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                  {t('appointmentsFromBoost').toUpperCase()}
                </SmallBody>
                <TouchableOpacity style={{ paddingLeft: 6 }} onPress={showTooltip(t('appointmentsFromBoostTooltip'))}>
                  <TooltipIcon />
                </TouchableOpacity>
              </Row>
              <Spacer size={6} />
              <H2s>{analytics?.totalBoostBookingsCount ?? 0}</H2s>
            </Column>
            <Column style={{ padding: 16 }}>
              <Row style={{ alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                  {t('valueAppointments').toUpperCase()}
                </SmallBody>
                <TouchableOpacity style={{ paddingLeft: 6 }} onPress={showTooltip(t('valueAppointmentsTooltip'))}>
                  <TooltipIcon />
                </TouchableOpacity>
              </Row>
              <Spacer size={6} />
              <H2s>
                {analytics?.totalValueFromBoostBookings ?? 0} {t('currency')}
              </H2s>
            </Column>
            <Column style={{ padding: 16 }}>
              <Row style={{ alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                  {t('averageAppointmentValue').toUpperCase()}
                </SmallBody>
                <TouchableOpacity style={{ paddingLeft: 6 }} onPress={showTooltip(t('averageAppointmentValueTooltip'))}>
                  <TooltipIcon />
                </TouchableOpacity>
              </Row>
              <Spacer size={6} />
              <H2s>
                {analytics?.averageValueFromBoostBookings ?? 0} {t('currency')}
              </H2s>
            </Column>
            <Spacer size={8} />
          </FormCard>
          <Spacer size={32} />
          <Switch
            height={sizes[32]}
            textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
            buttons={ClientTabsOptions.map((tab) => ({ ...tab, label: t(tab.label).toUpperCase() }))}
            defaultValue={selectedTab}
            onChange={(value) => {
              switch (value) {
                case 'new': {
                  setSelectedTab('new');
                  break;
                }
                case 'claimed': {
                  setSelectedTab('claimed');
                  break;
                }
                default: {
                  setSelectedTab('new');
                }
              }
            }}
          />
          <Spacer size={24} />
          {clients[selectedTab].length > 0 ? (
            <>
              <H3s>{t(ClientType[selectedTab], { count: clients[selectedTab].length })}</H3s>
              <Spacer size={24} />
              {clients[selectedTab].map((client) => (
                <Column key={client.user._id}>
                  <TouchableOpacity
                    onPress={() => {
                      navigateBoostClientDetails(client);
                    }}
                  >
                    <ClientCard client={client} withButtons={selectedTab === 'new'} onUpdate={updateData} />
                    <Spacer size={16} />
                  </TouchableOpacity>
                  <Line />
                  <Spacer size={16} />
                </Column>
              ))}
            </>
          ) : (
            <Body style={{ textAlign: 'center' }}>{t(EmptyState[selectedTab])}</Body>
          )}
          <Spacer size={48} />
        </ScrollView>
      </ModalScreenContainer>
      {tooltipVisible && (
        <BottomNotification
          type="info"
          text={tooltipText}
          style={{ paddingBottom: insets.bottom + 16, maxWidth: 500 }}
          rightAction={rightAction}
          leftAction={{ text: '', onPress: () => void 0 }}
        />
      )}
    </>
  );
};

export default CurrentBusiness(BoostScreen);

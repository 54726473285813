import { Dimensions, StyleSheet } from 'react-native';

import { colors, sizes } from '../../../../styles';

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: { backgroundColor: colors.ALABASTER, overflow: 'hidden', flexDirection: 'row', flex: 1 },
  boostDescription: { textAlign: 'center' },
  labelWrapper: { flex: 2, paddingHorizontal: 6 },
  claimClientDescription: {
    fontFamily: 'open-sans-semibold',
    color: colors.COMET,
    textAlign: 'center',
    paddingVertical: 12,
  },
  addBookingButton: { flex: 1 },
  optionsButton: {
    flex: 1,
    borderRadius: 25,
  },
  buttonsWrapper: {
    paddingVertical: sizes[24],
    gap: sizes[8],
  },
  contentContainer: { backgroundColor: colors.ALABASTER, padding: sizes[24] },
  infoColumn: { borderRightWidth: 1, borderRightColor: colors.ATHENS_GRAY, width: 318, height },
  menuColumn: { paddingTop: sizes[24], width: 222, maxWidth: 222, paddingLeft: sizes[24], paddingRight: sizes[8] },
  contentColumn: { width: 400, height, paddingTop: sizes[24] },
  contentTitle: {
    fontFamily: 'merriweather-bold',
    fontSize: 20,
  },
});

export default styles;

import { PageId, ProductImage } from '@mero/api-sdk';
import {
  Body,
  Button,
  Column,
  FormCard,
  H1,
  H2s,
  Icon,
  Row,
  SafeAreaView,
  Spacer,
  colors,
  useShowError,
  Header,
} from '@mero/components';
import * as Device from 'expo-device';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, Dimensions, Image, Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import NewImage from '../MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/NewImage';
import {
  NewImageType,
  Status,
  multipleSelect,
  singleSelect,
} from '../MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/PageGallerySettingsScreen';
import UploadImageButton from '../MenuScreen/screens/LocationProfile/PageGallerySettingsScreen/UploadImageButton';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductImagesContext } from '../../../contexts/ProductImagesContext';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import { ProductEditStackParamList } from '../../../types';
import log from '../../../utils/log';
import { styles } from './styles';

type Props = {
  navigation: StackNavigationProp<ProductEditStackParamList, 'ProductGalleryScreen'>;
  route: RouteProp<ProductEditStackParamList, 'ProductGalleryScreen'>;
};

const isProductImage = (image: ProductImage | NewImageType): image is ProductImage => !image.hasOwnProperty('status');

const convertMbToBytes = (mb: number) => mb * 1024 * 1024;

const ProductGalleryScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const { width } = Dimensions.get('window');
  const showError = useShowError();

  const [, { update }] = ProductsContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();
  const [, { updateImage }] = SelectedProductContext.useContext();

  const [images, { setImages, removeImage, appendImages, setNewImage, setImageStatus }] =
    ProductImagesContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isUploading, setIsUploading] = React.useState(false);

  const scrollViewRef = React.useRef<ScrollView>(null);

  React.useEffect(() => {
    const init = async () => {
      if (pageState.type === 'Loaded' && route.params.productId !== 'undefined' && route.params.productId) {
        const product = await meroApi.pro.products.getProductById({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        });

        if (product) {
          const sortedGalleryItems = product.gallery.sort((item1, item2) => item1.order - item2.order);
          const newImages = sortedGalleryItems.map((element) => element.image);
          setImages(newImages);
        }
        setIsLoading(false);
      }
    };
    init();
  }, [pageState.type]);

  const localRemoveImage = (image: NewImageType) => {
    removeImage(image._id);
  };

  const supportsMultiple = () => {
    if (Platform.OS === 'ios' && Device.osVersion) {
      return Device.osVersion.split('.')[0] >= '14';
    }

    if (Platform.OS === 'web') {
      return true;
    }

    return false;
  };

  const navigateImageMenuCallback = React.useCallback(
    (imageId: string) => {
      navigation.navigate('ProductImageMenu', { imageId, productId: route.params.productId });
    },
    [navigation],
  );

  const addImages = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsUploading(true);
    try {
      // No permissions request is necessary for launching the image library
      const result = supportsMultiple() ? await multipleSelect() : await singleSelect();
      if (result.length > 0) {
        const timestamp = new Date().getTime();
        const newImages = result.map((asset) => ({
          _id: Platform.OS === 'web' ? uuidv4() : `${timestamp}-${asset}`,
          uri: asset.uri,
          status: (asset.fileSize ?? 0) > convertMbToBytes(20) ? Status.TO_BIG : Status.LOADING,
        }));
        appendImages(newImages);
        setTimeout(() => {
          scrollViewRef.current?.scrollToEnd({ animated: true });
        }, 1000);
        await uploadImages(newImages, pageState.page.details._id);
      }
    } catch (error) {
      log.error('Error while adding images', error);
      showError(error, t('failedToAddImages'));
    }
    setIsUploading(false);
  };

  const uploadImages = async (newImages: NewImageType[], pageId: PageId) => {
    setIsUploading(true);

    const toUploadImages = newImages.filter((image) => image.status === Status.LOADING);

    for (let i = 0; i < toUploadImages.length; i++) {
      const asset = toUploadImages[i];
      log.debug(`Uploading image ${i + 1} of ${toUploadImages.length}`, asset);
      const profilePhotoUri = Platform.OS === 'ios' ? asset.uri.replace('file://', '') : asset.uri;
      const response = await fetch(profilePhotoUri);
      const blob = await response.blob();

      try {
        const newProductImage = await meroApi.pro.products.uploadGalleryImage({
          pageId: pageId,
          image: { platform: Platform.OS, blob, uri: profilePhotoUri },
          productId: route.params.productId,
        });

        if (images.length === 0 && i == 0) {
          updateImage(newProductImage);
        }
        setNewImage({ imageId: asset._id, newImage: newProductImage });
        reload();
      } catch (error: unknown) {
        log.debug('Failed to upload image', error);
        setImageStatus({ imageId: asset._id, status: Status.FAILED });
      }
    }
    setIsUploading(false);
  };

  const handleImagePress = (image: ProductImage) => {
    const indexOfImage = images.findIndex((img) => img._id === image._id);
    update({ selectedImage: { image, position: indexOfImage } });
    navigateImageMenuCallback(image._id);
  };

  const retryUpload = async (image: NewImageType) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      setImageStatus({ imageId: image._id, status: Status.LOADING });
      if (!isUploading) {
        await uploadImages(
          [
            {
              ...image,
              status: Status.LOADING,
            },
          ],
          pageState.page.details._id,
        );
      }
    } catch (error) {
      log.error('Error while adding images', error);
      showError(error, t('failedToAddImages'));
    }
  };

  const imageSize = ((width > 768 ? 500 : width) - 2 * 16 - 2 * 16 - 2 * 6) / 3;

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('photoGallery')}
      />
      {isLoading ? null : images.length === 0 ? (
        <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <H2s>{t('noProductPhoto')}</H2s>
          <Spacer size={8} />
          <Body style={{ textAlign: 'center' }}>{t('productPhotoDescription')}</Body>
          <Spacer size={32} />
          <UploadImageButton addImages={addImages} />
        </Column>
      ) : (
        <ScrollView ref={scrollViewRef} style={{ flex: 1, paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 8 }}>{t('photoGallery')}</H1>
          <Spacer size={8} />
          <Body style={{ paddingHorizontal: 8 }}>{t('productPhotoDescription')}</Body>
          <Spacer size={24} />
          <FormCard
            paddings="none"
            style={{ paddingTop: 10, paddingLeft: 10, paddingBottom: 16, paddingRight: 16, flex: 1 }}
            dropShaddow
            rounded
          >
            <Column>
              <Row style={{ flexWrap: 'wrap' }}>
                {images.map((image) => {
                  if (isProductImage(image)) {
                    return (
                      <TouchableOpacity
                        key={image._id}
                        style={{
                          width: imageSize,
                          height: imageSize,
                          marginLeft: 6,
                          marginTop: 6,
                          borderRadius: 4,
                          overflow: 'hidden',
                          position: 'relative',
                        }}
                        onPress={() => handleImagePress(image)}
                        disabled={isUploading}
                      >
                        <Image source={{ uri: image.small }} style={{ width: imageSize, height: imageSize }} />
                      </TouchableOpacity>
                    );
                  }

                  return (
                    <NewImage
                      key={image._id}
                      image={image}
                      size={imageSize}
                      disabled={isLoading}
                      onRetry={retryUpload}
                      onRemove={localRemoveImage}
                    />
                  );
                })}
              </Row>
            </Column>
          </FormCard>
          <Spacer size={96} />
        </ScrollView>
      )}
      {images.length === 0 ? null : (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button text={t('addPhotos')} onClick={addImages} disabled={isLoading || isUploading} />
            ) : (
              <Button
                disabled={isLoading || isUploading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('addPhotos')}
                onClick={addImages}
              />
            )}
          </SafeAreaView>
        </FormCard>
      )}
    </ModalScreenContainer>
  );
};

export default ProductGalleryScreen;

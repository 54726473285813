import { Body, colors, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { NormalizedBlockedTimeEvent } from '../../../NormalizedEvent';
import { styles as commonStyles } from '../../styles';

type Props = {
  readonly event: NormalizedBlockedTimeEvent;
  readonly showTime: boolean;
  readonly now: DateTime;
};

const BlockedTimeCalendarEntryView: React.FC<Props> = ({ event, showTime }) => {
  const eventDuration = React.useMemo(() => event.end.diff(event.start, 'minute'), [event.end, event.start]);

  const { t } = useTranslation('calendar');

  const reason = event.extra.reason;

  const reasonStr = React.useMemo(() => {
    switch (reason.type) {
      case 'break': {
        return t('blockedTimeReasonBreak');
      }
      case 'dayOff': {
        return t('blockedTimeReasonDayOff');
      }
      case 'vacation': {
        return t('blockedTimeReasonVacation');
      }
      case 'sickLeave': {
        return t('blockedTimeReasonSickLeave');
      }
      case 'training': {
        return t('blockedTimeReasonTraining');
      }
      case 'custom': {
        return reason.reason;
      }
    }
  }, [reason]);

  return (
    <View
      style={[
        commonStyles.eventCell,
        eventDuration.minutes < 20 ? commonStyles.smallCell : undefined,
        {
          backgroundColor: '#F9E0D2',
          borderLeftColor: colors.WHITE,
          borderRightColor: colors.WHITE,
          borderTopColor: colors.WHITE,
          borderBottomColor: colors.WHITE,
        },
        { height: '100%' },
      ]}
    >
      {(eventDuration.minutes <= 20 || false) && showTime ? (
        <>
          <Body style={commonStyles.eventTitle}>
            {event.title}
            <Title style={[commonStyles.eventTitle]}> Timp blocat:</Title>{' '}
            <Body style={[commonStyles.eventComment]}>{reasonStr}</Body>
          </Body>
        </>
      ) : (
        <>
          <Body style={[commonStyles.eventTitle]}>{event.title}</Body>
          <Title style={[commonStyles.eventTitle]}>
            Timp blocat:<Body style={[commonStyles.eventComment]}> {reasonStr}</Body>
          </Title>
        </>
      )}
    </View>
  );
};

export default React.memo(BlockedTimeCalendarEntryView);

import { MeroUnits, Money, OnlinePaymentsAccountBalance, ScaledNumber } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type AccountBalanceContextState =
  | {
      readonly type: 'New';
      readonly accountBalance: OnlinePaymentsAccountBalance;
    }
  | {
      readonly type: 'Loading';
      readonly accountBalance: OnlinePaymentsAccountBalance;
    }
  | {
      readonly type: 'Loaded';
      readonly accountBalance: OnlinePaymentsAccountBalance;
    }
  | {
      readonly type: 'Failed';
      readonly accountBalance: OnlinePaymentsAccountBalance;
      readonly error: unknown;
    };

const defaultState = (): AccountBalanceContextState => ({
  type: 'New',
  accountBalance: {
    total: Money.of(ScaledNumber.zero(), MeroUnits.RON.code),
    pendingTotal: Money.of(ScaledNumber.zero(), MeroUnits.RON.code),
    availableTotal: Money.of(ScaledNumber.zero(), MeroUnits.RON.code),
  },
});

export const AccountBalanceContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        accountBalance: OnlinePaymentsAccountBalance;
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          accountBalance: result.accountBalance,
        };
      } else {
        return state;
      }
    },
    setFailed: (
      state,
      payload: {
        error: unknown;
      },
    ) => {
      return {
        type: 'Failed',
        accountBalance: state.accountBalance,
        error: payload.error,
      };
    },
    mutate: (s, fn: (s: AccountBalanceContextState) => AccountBalanceContextState): AccountBalanceContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({ pageId }: { pageId: PageId; silent?: boolean }) => {
      try {
        log.debug('Start reloading account balance');

        const accountBalance = await meroApi.pro.onlinePayments.getAccountBalance(pageId);

        dispatch.trySetResult({
          accountBalance,
        });
      } catch (error) {
        dispatch.trySetResult({
          accountBalance: {
            total: Money.of(ScaledNumber.fromNumber(0, 2), MeroUnits.RON.code),
            pendingTotal: Money.of(ScaledNumber.fromNumber(0, 2), MeroUnits.RON.code),
            availableTotal: Money.of(ScaledNumber.fromNumber(0, 2), MeroUnits.RON.code),
          },
        });
        log.exception(error);
      }
    };

    return {
      reloadAsync: async (payload: { pageId: PageId }): Promise<void> => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({ pageId: payload.pageId }).catch(log.exception);

            return {
              ...state,
              type: 'Loading',
            };
          }

          return state;
        });
      },

      reload: (payload: { pageId: PageId }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload(payload).catch(log.exception);

            return {
              type: 'Loading',
              accountBalance: state.accountBalance,
            };
          }

          return state;
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { reload }] = AccountBalanceContext.useContext();

  const pageId = currentBusinessState.type === 'Loaded' ? currentBusinessState.page.details._id : undefined;

  React.useEffect(() => {
    if (pageId) {
      reload({ pageId });
    }
  }, [pageId]);

  return <>{children}</>;
};

export const withAccountBalanceContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSubscriptionContextProvider(props: P) {
    return (
      <AccountBalanceContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </AccountBalanceContext.Provider>
    );
  };
};

import { createModel } from '@mero/components';

export const backNavigationContext = createModel(
  {
    canGoBack: false,
    goBack: (): void => {
      // pass, noop by default
    },
  },
  {
    setCanGoBack: (s, canGoBack: boolean) => ({ ...s, canGoBack }),
    setGoBack: (s, goBack: () => void) => ({ ...s, goBack }),
    removeGoBack: (s) => ({
      ...s,
      goBack: () => {
        // pass
      },
    }),
  },
  () => ({}),
);

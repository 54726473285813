import { PageId } from '@mero/api-sdk/dist/pages';
import { ServiceId } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  Header,
  Icon,
  Line,
  SmallBody,
  Spacer,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AddNewProContext } from '../../../../../contexts/AddNewProContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsSettingsAddNewStackParamList } from '../../../../../types';
import { PriceType } from '../../../ServicesScreen/ServiceScreen';
import { getPrice } from '../../../ServicesScreen/ServicesMobileScreen';
import { formatDuration } from './AddProServicesScreen';
import { styles } from './ProScheduleScreen.styles';

export type Props = StackScreenProps<ProsSettingsAddNewStackParamList, 'ProAddNewServicesSelect'>;

const AddProServicesSelectScreen: React.FC<Props> = ({ route, navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();

  const [pageState] = CurrentBusinessContext.useContext();
  const [{ services }, { updateServices }] = AddNewProContext.useContext();

  const block = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedServices, setSelectedServices] = React.useState<Record<ServiceId, boolean>>({});
  const [groupedServices, setGroupedServices] = React.useState<GroupWithServices[]>([]);
  const [allServices, setAllServices] = React.useState<ServiceId[]>([]);

  const getGroupedServices = async (pageId: PageId, workerServices: [ServiceId, boolean][]) => {
    setIsLoading(true);
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(pageId);

      setSelectedServices(Object.fromEntries(workerServices));
      setGroupedServices([
        ...groupedServices.grouped.filter((g) => g.services.length > 0),
        ...(groupedServices.others.length === 0
          ? []
          : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: groupedServices.others }]),
      ]);
      setAllServices([
        ...groupedServices.grouped.map((g) => g.services.map((s) => s._id)).flat(),
        ...groupedServices.others.map((s) => s._id),
      ]);
      setIsLoading(false);
    } catch {}
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getGroupedServices(
        pageState.page.details._id,
        services.map((service) => [service._id, true]),
      );
    }
  }, [pageState.type]);

  const totalServicesCount = React.useMemo(
    () => groupedServices.reduce((acc, group) => acc + group.services.length, 0),
    [groupedServices],
  );

  const selectedServicesCount = React.useMemo(
    () => Object.values(selectedServices).filter((v) => v).length,
    [selectedServices],
  );

  const toggleServices = (servicesIds: ServiceId[], newValue?: boolean) => {
    const newSelectedServices = { ...selectedServices };
    servicesIds.forEach((serviceId) => {
      newSelectedServices[serviceId] = newValue ?? !newSelectedServices[serviceId];
    });
    setSelectedServices(newSelectedServices);
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    const workerServices = services
      .filter((service) => selectedServices[service._id])
      .filter((service) => service.pageId === pageState.page.details._id)
      .map((s) => ({
        ...s,
        price: {
          ...s.price,
          type: PriceType[s.price.type],
        },
      }));
    const workerServiceIds = workerServices.map((service) => service._id);
    const pageServices = pageState.page.details.services
      .filter((service) => selectedServices[service._id] && !workerServiceIds.includes(service._id))
      .map((s) => ({
        ...s,
        price: {
          ...s.price,
          type: PriceType[s.price.type],
        },
        isCustom: false,
        pageId: pageState.page.details._id,
      }));

    //@ts-expect-error @TODO Price type has a different format...
    updateServices([...workerServices, ...pageServices]);

    navigation.navigate('ProAddNewServices');
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        text={t('selectServices')}
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
      />
      <ScrollView>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('selectServices')}</H1>
          <Body style={{ padding: 8 }}>{t('selectServicesDescription')}</Body>
          <Spacer size={32} />
          {groupedServices.length > 0 && (
            <FormCard rounded>
              <Spacer size={16} />
              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center' }}
                onPress={() => toggleServices(allServices, totalServicesCount !== selectedServicesCount)}
              >
                <Checkbox
                  value={totalServicesCount === selectedServicesCount}
                  onValueChange={() => toggleServices(allServices, totalServicesCount !== selectedServicesCount)}
                />
                <Body style={{ fontSize: 17, paddingLeft: 12 }}>{t('allServices', { count: totalServicesCount })}</Body>
              </TouchableOpacity>
              <Spacer size={24} />
              <Line />
              {groupedServices.map((groupedService, groupIndex) => {
                const allServicesSelected = groupedService.services.every((service) => selectedServices[service._id]);
                const servicesIds = groupedService.services.map((service) => service._id);
                return (
                  <Column
                    key={groupedService.group._id}
                    style={{
                      paddingTop: 24,
                      paddingBottom: 16,
                      borderBottomWidth: groupIndex < groupedServices.length - 1 ? 1 : 0,
                      borderBottomColor: '#E9ECEF',
                    }}
                  >
                    <TouchableOpacity
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                      onPress={() => toggleServices(servicesIds, !allServicesSelected)}
                    >
                      <Checkbox
                        value={allServicesSelected}
                        onValueChange={() => toggleServices(servicesIds, !allServicesSelected)}
                      />
                      <H3s style={{ paddingLeft: 12 }}>
                        {groupedService.group.name} ({groupedService.services.length})
                      </H3s>
                    </TouchableOpacity>
                    {groupedService.services.map((service) => (
                      <TouchableOpacity
                        key={service._id}
                        style={{ flexDirection: 'row', paddingTop: 24 }}
                        onPress={() => toggleServices([service._id])}
                      >
                        <Checkbox
                          value={selectedServices[service._id]}
                          onValueChange={() => toggleServices([service._id])}
                        />
                        <Column>
                          <Body style={{ fontSize: 17, paddingLeft: 12 }}>{service.name}</Body>
                          <SmallBody style={{ paddingLeft: 12 }}>
                            {formatDuration(service.durationInMinutes)},{' '}
                            {service.price.type === 'fixed' && service.price?.promo ? (
                              <>
                                <SmallBody>
                                  {service.price.promo} lei{' '}
                                  <SmallBody
                                    style={{
                                      textDecorationLine: 'line-through',
                                      textDecorationStyle: 'solid',
                                    }}
                                  >
                                    ({service.price.fixed} lei)
                                  </SmallBody>
                                </SmallBody>
                              </>
                            ) : (
                              <SmallBody>{getPrice(service.price)}</SmallBody>
                            )}
                          </SmallBody>
                        </Column>
                      </TouchableOpacity>
                    ))}
                  </Column>
                );
              })}
            </FormCard>
          )}
        </Column>
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading || selectedServicesCount === 0}
              text={t('continue')}
              onClick={saveChanges}
            />
          ) : (
            <Button
              disabled={block.current || isLoading || selectedServicesCount === 0}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('continue')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AddProServicesSelectScreen;

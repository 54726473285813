import { apiError, ApiError, CalendarEntryDetails } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as Apply from 'fp-ts/lib/Apply';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type CalendarEntryState =
  | {
      type: 'New';
    }
  | {
      type: 'Loading';
      pageId: PageId;
      entryId: AppointmentId;
      occurrenceIndex: number;
    }
  | {
      type: 'Loaded';
      pageId: PageId;
      entry: CalendarEntryDetails.Any;
    }
  | {
      type: 'NotFound';
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
    };

const defaultState = (): CalendarEntryState => ({
  type: 'New',
});

export const CalendarEntryContext = createModelContext(
  defaultState(),
  {
    setLoading: (_, payload: { pageId: PageId; entryId: AppointmentId; occurrenceIndex: number }) => ({
      type: 'Loading',
      pageId: payload.pageId,
      entryId: payload.entryId,
      occurrenceIndex: payload.occurrenceIndex,
    }),
    setLoaded: (_, payload: { pageId: PageId; entry: CalendarEntryDetails.Any }) => ({
      type: 'Loaded',
      pageId: payload.pageId,
      entry: payload.entry,
    }),
    setNotFound: () => ({
      type: 'NotFound',
    }),
    setFailed: (_, error: ApiError<unknown> | undefined) => ({ type: 'Failed', error: error }),
    mutate: (s, fn: (s: CalendarEntryState) => CalendarEntryState): CalendarEntryState => fn(s),
  },
  (dispatch) => {
    return {
      init: (payload: { pageId: PageId; entryId: string; occurrenceIndex: number }): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            const params = Apply.sequenceT(E.either)(
              PageId.decode(payload.pageId),
              AppointmentId.decode(payload.entryId),
              t.number.decode(payload.occurrenceIndex),
            );

            if (E.isRight(params)) {
              const [pageId, entryId, occurrenceIndex] = params.right;

              const init = async (): Promise<void> => {
                try {
                  log.debug(`Load calendar entry with id ${entryId} (#${occurrenceIndex}) for pageId ${pageId}`);
                  const entry = await meroApi.calendar.getCalendarEntryById({ pageId, entryId, occurrenceIndex });

                  log.debug(`CalendarEntry ${entry._id} loaded`, { entry });

                  dispatch.setLoaded({ entry, pageId });
                } catch (e) {
                  log.error(`Failed to fetch calendar entry by pageId: ${pageId}, entryId: ${entryId}`, e);
                  if (apiError(t.unknown).is(e)) {
                    dispatch.setFailed(e);
                  } else {
                    dispatch.setFailed(undefined);
                  }
                }
              };

              init().catch(log.exception);

              return {
                type: 'Loading',
                pageId: pageId,
                entryId: entryId,
                occurrenceIndex: occurrenceIndex,
              };
            } else {
              return {
                type: 'NotFound',
              };
            }
          } else {
            return state;
          }
        });
      },
      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            const reload = async (): Promise<void> => {
              try {
                log.debug(`Reload calendar entry with id ${state.entry._id}`);

                const entry = await meroApi.calendar.getCalendarEntryById({
                  pageId: state.pageId,
                  entryId: state.entry._id,
                  occurrenceIndex: state.entry.occurrenceIndex,
                });

                log.debug(`CalendarEntry ${entry._id} loaded`);

                dispatch.setLoaded({ entry, pageId: state.pageId });
              } catch (e) {
                if (apiError(t.unknown).is(e)) {
                  dispatch.setFailed(e);
                } else {
                  log.error(`Failed to fetch calendar entry by entryId: ${state.entry._id}`, e);
                  dispatch.setFailed(undefined);
                }
              }
            };

            reload().catch(log.exception);

            return {
              type: 'Loading',
              pageId: state.pageId,
              entryId: state.entry._id,
              occurrenceIndex: state.entry.occurrenceIndex,
            };
          } else {
            return state;
          }
        });
      },
    };
  },
);

export type HasCalendarEntryId = {
  readonly calendarId: string;
  readonly calendarEntryId: string;
};

export type HasCalendarEntryState = {
  readonly calendarEntryState: CalendarEntryState;
};

export type PropsWithState<P> = P & HasCalendarEntryState;

export type OutputComponentProps<P extends HasCalendarEntryState> = Omit<P, keyof HasCalendarEntryState> & {
  pageId: PageId;
  calendarEntryId: string;
  occurrenceIndex: number;
};

export const withCalendarEntryContext = <P extends HasCalendarEntryState>(
  Component: React.ComponentType<P>,
): React.FunctionComponent<OutputComponentProps<P>> =>
  function WithCalendarEntryContext(props) {
    const [state, dispatch] = CalendarEntryContext.useContext();

    React.useEffect(() => {
      dispatch.init({
        pageId: props.pageId,
        entryId: props.calendarEntryId,
        occurrenceIndex: props.occurrenceIndex,
      });
    }, [dispatch]);

    // FIXME: find a type safe way to exclude a property for generic type argument
    const newProps: HasCalendarEntryState = {
      calendarEntryState: state,
    };
    // @ts-expect-error
    const allProps: PropsWithState<P> = { ...props, ...newProps };

    return <Component {...allProps} />;
  };

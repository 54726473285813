/**
 * Application config
 */
const config = {
  logLevel: 'debug',
  /**
   * Old PRO website base url (angular)
   */
  marketPlaceUrl: 'https://dev.mero.ro',
  proUrl: 'https://pro1.dev.mero.ro',
  linkingPrefixes: ['https://pro.dev.mero.ro'],
  apiBaseUrl: 'https://pro.dev.mero.ro/api/v2.0',
  // apiBaseUrl: 'http://localhost:8080/api/v2.0',
  checkoutUrl: 'https://sandbox.payu.ro/order/lu.php',
  socketBaseUrl: 'https://dev.mero.ro',
  apiClientId: 'mero-pro-app-dev',
  apiSecret: '5fe60848bdf6368714059585',
  facebookAppId: '101682737080649',
  meroSmsUrl: 'https://bit.ly/mero-sms',
  tosLinkUrl: 'https://pro.dev.mero.ro/termeni-si-conditii?embed=1',
  privacyLinkUrl: 'https://pro.dev.mero.ro/confidentialitate?embed=1',
  appStoreLink: 'https://apps.apple.com/app/id1554148874?action=write-review',
  stores: {
    ios: 'id1554148874',
    android: 'ro.mero.pro.app',
  },
  sentry: {
    dsn: 'https://7c3ae7ba6f314ae49f3d47004519a42e@o524911.ingest.sentry.io/5642084',
    debug: false,
    environment: 'development',
  },
  google: {
    analytics: 'G-9HG1N45XMQ',
    iosClientId: '304677258199-m7oh70kmnsi88p3tpig8577n4qcp4q14.apps.googleusercontent.com',
    androidClientId: '304677258199-3upddhhbrs4p0us577hkrt2hoo4quk9f.apps.googleusercontent.com',
    clientId: '304677258199-suhlb04uem6kicsqc9qcmn1kluvujidl.apps.googleusercontent.com',
    iosStandaloneAppClientId: '304677258199-mjbe3j61ejkpihbh1tc874l9iplpnbhp.apps.googleusercontent.com',
    androidStandaloneAppClientId: '304677258199-2d9ioev84epigon2lq8gt3caa5kic5u8.apps.googleusercontent.com',
    recaptchaSiteKey: '6LdY_aUfAAAAAKpqWFjpAyeHywhfSFmzX9L2pDap',
    maps: {
      web: 'AIzaSyCmNxh-26b9DA-cC11fb732noSi9TrkgVY',
      mobile: 'AIzaSyDUKepDB27J44D0uTY5QotXThJWRKcmglQ',
    },
  },
  intercom: {
    // Make sure same value is used in app.{env}.json
    appId: 'rfg7bs3u',
  },
  contactUsUrl: 'https://asistenta.mero.ro/ro/articles/4942975-contacteaza-ne',
  giftCardUrl: 'https://asistenta.mero.ro/ro/articles/5756305-carduri-cadou',
  pageSubscriptionUrl: (pageId: string, token: string): string =>
    `https://pro1.dev.mero.ro/drawer/page/subscription?hideLayout=true&pageId=${encodeURIComponent(
      pageId,
    )}&nvb=${encodeURIComponent(token)}`,
  startTrialCallLink: 'https://bit.ly/apel-mero-pro',
  amplitudeApiKey: '3ac28797196e18ac587b9e05b8b545c1',
  appDownloadUrl: 'https://pro.dev.mero.ro/download-app',
  stripe: {
    mero_pro_apps_srl: 'pk_test_KuyU1J5YInAyHImva0lbINYy00E9NLbgBz',
    mero_programari_srl:
      'pk_test_51LwPKzCh4Yz209LxW8H0Nf5rpIpvZwbmkkKCJmumpBWGGts8yyysm0hxBjlQZ66gLCYUVBEe7DRyYwVLb7xVMu5H00mNQIGrRU',
  },
  stripeRedirectUri: {
    giftCards: 'https://pro.dev.mero.ro/page/current/gift-cards',
    onlinePayments: 'https://pro.dev.mero.ro/page/current/online-payments',
  },
};

export default config;

import { MembershipPurchaseId } from '@mero/api-sdk';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  Line,
  ModalOverlay,
  SimpleListItem,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import log from '../../../../utils/log';

type Props = {
  onSuccess: () => Promise<void>;
  onCancel: () => void;
  membershipPurchaseId: MembershipPurchaseId;
};

const CancelMembershipDialog: React.FC<Props> = ({ onSuccess, onCancel, membershipPurchaseId }) => {
  const { t } = useTranslation('membership');
  const showError = useShowError();

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const cancel = () => {
    onCancel();
  };

  const onDelete = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.memberships.cancelPurchasedMembership({
          pageId: pageState.page.details._id,
          membershipPurchaseId,
        });
        await onSuccess();
      }
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
      showError(error);
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('close'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('confirmMembershipDelete'),
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : onDelete,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
          style={{
            maxWidth: 400,
          }}
        >
          <H1>{t('confirmDeleteMembership')}</H1>
          <Spacer size="8" />
          <Title>{t('checkAndConfirmDelete')}</Title>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteMembershipCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns="membership" t={t} i18nKey="deleteMembershipCheck2">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns="membership" t={t} i18nKey="deleteMembershipCheck3">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default CancelMembershipDialog;

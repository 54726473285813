import { Firstname, Lastname, PhoneNumber, unsafeDecode } from '@mero/api-sdk';
import { BusinessCategory, BusinessCategoryId } from '@mero/api-sdk/dist/services';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  normalize,
  Select,
  SmallBody,
  Spacer,
  useShowError,
  useToast,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import config from '../../../config';
import { AuthContext, meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../../types';
import log from '../../../utils/log';
import { styles } from './styles';

const INPUT_POSITIONS = {
  name: 50,
  phone: 150,
  email: 250,
} as const;

export type Props = StackScreenProps<AuthorizedStackParamList, 'CreateLocation'>;

const CreateLocationScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('location');
  const { isPhone } = useMediaQueries();

  const goBack = useGoBack();
  const showError = useShowError();
  const toast = useToast();

  const block = React.useRef(false);

  const [, { reloadAsync, setCurrentPage }] = CurrentBusinessContext.useContext();
  const [userState] = AuthContext.useContext();
  const [showErrors, setShowErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [businessCategories, setBusinessCategories] = React.useState<BusinessCategory[]>([]);

  const [name, setName] = React.useState({
    input: '',
    decoded: NonEmptyString.decode(''),
  });
  const nameValid = E.isRight(name.decoded);

  const [businessCategory, setBusinessCategory] = React.useState<BusinessCategoryId | undefined>(undefined);

  React.useLayoutEffect(() => {
    const fetchBusinessCategories = async () => {
      try {
        const response = await meroApi.pages.getBusinessCategories();
        setBusinessCategories(response);
      } catch (error) {
        showError(error, t('somethingWentWrong'));
      }
    };

    fetchBusinessCategories();
  }, []);
  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const businessCategoriesFormatted = React.useMemo(
    () => businessCategories.map((category) => ({ label: category.name, value: category._id })),
    [businessCategories],
  );

  if (userState.type !== 'Authorized') {
    return null;
  }

  const saveChanges = async () => {
    if (block.current) {
      return;
    }

    if (!nameValid || !businessCategory) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    block.current = true;
    try {
      const pageId = await meroApi.pages.createPage({
        categoryId: businessCategory,
        name: name.input,
        firstname: (userState.user.profile.firstname as Firstname) ?? '',
        lastname: (userState.user.profile.lastname as Lastname) ?? '',
        phoneNo: userState.user.phone as PhoneNumber,
        email: userState.user.email,
        tags: [Platform.OS],
      });

      log.debug(`Onboarding: created new page with ID ${pageId}`);

      await reloadAsync();

      setCurrentPage(pageId);

      navigation.navigate('Home', {
        screen: 'HomeTabs',
        params: {
          screen: 'MenuTab',
          params: {
            screen: 'MenuScreen',
          },
        },
      });

      toast.show({
        type: 'success',
        text: t('locationCreated'),
      });
    } catch (error) {
      showError(error, t('saveChangesError'));
    } finally {
      setIsLoading(false);
      block.current = false;
    }
  };

  const generateLink = (name: string) => {
    const normalized = normalize(name).replace(/[^a-zA-Z0-9]/g, '-');
    return `${config.marketPlaceUrl}/${normalized}`;
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        text={t('addLocationTitle')}
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
      />

      <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, marginBottom: 96 }}>
        <H1 style={{ textAlign: 'center' }}>{t('addLocation')}</H1>
        <Spacer size={8} />
        <Body style={{ textAlign: 'center' }}>{t('addLocationDescription')}</Body>
        <Spacer size={32} />
        <FormCard paddings={'none'} style={{ padding: 16 }} dropShaddow rounded>
          <InputWithLabel label={t('name')} isError={showErrors && !nameValid} errorText={t('nameError')}>
            <TypeSafeTextInput
              codec={NonEmptyString}
              value={name.input}
              showError={showErrors}
              onChange={setName}
              placeholder={t('namePlaceholder')}
              onFocus={() => scrollTo('name')}
              styles={{
                container: {
                  borderBottomEndRadius: 0,
                  borderBottomStartRadius: 0,
                  borderBottomWidth: 0,
                },
              }}
            />
            <Column
              style={{
                backgroundColor: colors.COMET,
                paddingHorizontal: 12,
                paddingVertical: 4,
                borderBottomEndRadius: 4,
                borderBottomStartRadius: 4,
              }}
            >
              <SmallBody style={{ color: colors.WHITE, fontFamily: 'open-sans-semibold', fontSize: 12 }}>
                {name.input ? t('placeNameHint2', { value: generateLink(name.input) }) : t('placeNameHint1')}
              </SmallBody>
            </Column>
          </InputWithLabel>
          <Spacer size={16} />
          <InputWithLabel
            label={t('selectActivity')}
            isError={showErrors && !businessCategory}
            errorText={t('selectActivityError')}
          >
            <Select
              placeholder={t('selectActivityPlaceholder')}
              items={businessCategoriesFormatted}
              value={businessCategory}
              onChange={setBusinessCategory}
              isError={showErrors && !businessCategory}
            />
          </InputWithLabel>
        </FormCard>
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('continue')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('continue')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default CreateLocationScreen;

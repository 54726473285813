import { createModelContext } from '@mero/components';
import * as React from 'react';

import { AppStorage } from '../../app-storage';

export const ProductsTabsOptions = [
  {
    label: 'productsTitle',
    value: 'products',
  },
  {
    label: 'brandsTitle',
    value: 'brands',
  },
  {
    label: 'suppliersTitle',
    value: 'suppliers',
  },
  {
    label: 'documentsTitle',
    value: 'documents',
  },
  {
    label: 'inventoriesTitle',
    value: 'inventories',
  },
] as const;

export type SelectedProductsTabState = (typeof ProductsTabsOptions)[number]['value'];

const defaultState = (): SelectedProductsTabState => 'products';

export const SelectedProductsTabContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    update(_state, newTab) {
      return newTab;
    },
  },
  (dispatch) => {
    const saveSelectedTabToLocalStorage = async (tab: SelectedProductsTabState) => {
      await AppStorage.setSelectedProductsTab(tab);
    };

    return {
      reset: dispatch.reset,
      setSelectedTab: (tab: SelectedProductsTabState): void => {
        saveSelectedTabToLocalStorage(tab);
        dispatch.update(tab);
      },
      update: dispatch.update,
    };
  },
);

export const withSelectedProductsTabContext = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSelectedProductsTabContext(props: P) {
    return (
      <SelectedProductsTabContext.Provider>
        <Content {...props} />
      </SelectedProductsTabContext.Provider>
    );
  };
};

import { jwtDecode } from 'jwt-decode';
import base64 from 'react-native-base64';

export const decodeToken = (token: string) => {
  if (typeof atob === 'undefined') {
    global.atob = function (input) {
      // On native, we use react-native-base64 since jwt-decode uses atob
      return base64.decode(input);
    };
  }

  return jwtDecode(token);
};

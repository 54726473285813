import { colors, Logo, MeroHeader } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

const SignInHeader: React.FC = () => {
  return (
    <MeroHeader
      TitleComponent={function SignInLogo() {
        return (
          <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <Logo color={colors.BLACK} height={20} />
          </View>
        );
      }}
    />
  );
};

export default SignInHeader;

import { ProductId, ProductImage, ProductStatus } from '@mero/api-sdk';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  HSpacer,
  Icon,
  Line,
  MeroHeader,
  Row,
  Spacer,
  styles,
  useShowError,
  useToast,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ScrollView, TouchableOpacity } from 'react-native';

import { FlashyLabel } from '../../../components/FlashyLabel';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductContext } from '../../../contexts/SelectedProductContext';
import {
  ProductEditStackParamList,
  HomeTabsParamsList,
  HomeDrawerParamsList,
  AuthorizedStackParamList,
  RootStackParamList,
} from '../../../types';
import log from '../../../utils/log';
import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import { DefaultProductImage } from './DefaultProductImage';
import { BoxIcon, InventoryIcon, LabelTagIcon } from './ProductsMenuScreen';

type Props = {
  route: RouteProp<ProductEditStackParamList, 'ProductMenu'>;
  navigation: CompositeNavigationProp<
    StackNavigationProp<ProductEditStackParamList, 'ProductScreen'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProductsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const ProductMenu: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('products');
  const goBack = useGoBack();
  const toast = useToast();
  const showError = useShowError();
  const isFocused = useIsFocused();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SearchProductsContext.useContext();
  const [product, { update }] = SelectedProductContext.useContext();

  const productPricing = useMemo(() => {
    if (!product) {
      return;
    }
    const unit = t(product.details.price.retailPrice.unit);
    const price = `${scaledToString(product.details.price.retailPrice.amount)} ${t(unit)}`;
    const discountedPrice = `${scaledToString(product.details.price.discountedPrice.amount)} ${t(unit)}`;

    return {
      price: price,
      discountedPrice: discountedPrice !== price ? discountedPrice : undefined,
    };
  }, [product?.details]);

  const loadProduct = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const [product, stock] = await Promise.all([
        meroApi.pro.products.getProductById({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        }),
        meroApi.pro.inventories.getProductTotalStock({
          pageId: pageState.page.details._id,
          productId: route.params.productId,
        }),
      ]);

      let image: ProductImage | undefined;
      if (product.gallery.length) {
        const sortedGallery = product.gallery.sort((image1, image2) => image1.order - image2.order);
        image = sortedGallery[0].image;
      }

      update({ details: product, stock: ScaledNumber.toNumber(stock.quantity), image: image });
    } catch (e) {
      log.error('Failed to fetch product', e);
    }
  };

  useEffect(() => {
    if (isFocused) {
      loadProduct();
    }
  }, [isFocused, pageState.type === 'Loaded' && pageState.page.details._id]);

  const archiveProduct = async (params: { isArchived: boolean }) => {
    if (pageState.type !== 'Loaded' || !product) {
      return;
    }

    try {
      await meroApi.pro.products.updateProductArchivedStatus({
        pageId: pageState.page.details._id,
        isArchived: params.isArchived,
        productId: product.details._id,
      });

      reload();
      loadProduct();
      toast.show({
        type: 'success',
        text: t(params.isArchived ? 'productMovedToArchived' : 'productMovedOutOfArchived'),
      });
    } catch (error) {
      showError(error);
    }
  };

  const navigateDeleteProductCallback = useCallback(
    (productId: ProductId) => {
      navigation.navigate('DeleteProductScreen', { productId });
    },
    [navigation],
  );

  const navigateProductCallback = useCallback(
    (productId: ProductId) => {
      navigation.navigate('ProductScreen', { productId });
    },
    [navigation],
  );

  const navigateProductPriceCallback = useCallback(
    (productId: ProductId) => {
      navigation.navigate('ProductPrice', { productId });
    },
    [navigation],
  );

  const navigateProductStockCallback = useCallback(
    (productId: ProductId) => {
      navigation.navigate('ProductStock', { productId });
    },
    [navigation],
  );

  return (
    product && (
      <ModalScreenContainer edges={['top', 'bottom']} style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack={true} onBack={goBack} canClose={false} />

        <ScrollView style={{ flex: 1 }}>
          <Spacer size={30} />
          <Column alignItems="center">
            {product.image ? (
              <Image source={{ uri: product.image.medium }} style={{ width: 120, height: 120, borderRadius: 12 }} />
            ) : (
              <DefaultProductImage width={120} height={120} />
            )}
            <Spacer size={16} />
            <H1 style={{ textAlign: 'center', marginHorizontal: 16 }}>{product.details.name}</H1>
            <Body style={styles.text.hint}>{`${getMeasure(product.details.measure)}${
              product.details.barcode ? ` - ${product.details.barcode.value}` : ``
            }`}</Body>
            <Spacer size={12} />
            <Row justifyContent="center">
              <FlashyLabel
                type={product.stock > 0 ? 'success' : 'info'}
                text={`${product.stock.toLocaleString()} ${t('inStock')}`}
              />
              {product.details.status === ProductStatus.Inactive.code && (
                <>
                  <HSpacer left={8} />
                  <FlashyLabel type="info" text={t('archived')} />
                </>
              )}
            </Row>
          </Column>

          <Spacer size={32} />
          <FormCard dropShaddow rounded paddings="none" style={{ paddingHorizontal: 20 }}>
            <TouchableOpacity onPress={() => navigateProductCallback(product.details._id)}>
              <Row justifyContent="space-between" alignItems="center" style={{ paddingVertical: 20 }}>
                <Row>
                  <BoxIcon color={colors.BLACK} width={34} height={34} />
                  <H3s style={[styles.text.semibold, { marginLeft: 16, marginTop: 6 }]}>{t('productDetails')}</H3s>
                </Row>
                <Icon type="arrow-right" />
              </Row>
            </TouchableOpacity>

            <Line />
            <TouchableOpacity onPress={() => navigateProductPriceCallback(product.details._id)}>
              <Row justifyContent="space-between" style={{ paddingVertical: 20 }}>
                <Row>
                  <LabelTagIcon width={32} height={32} />
                  <Column style={{ marginLeft: 16 }}>
                    <H3s style={styles.text.semibold}>{t('price')}</H3s>
                    {productPricing && (
                      <Row>
                        {productPricing.discountedPrice && (
                          <Body style={[styles.text.hint, { marginRight: 8 }]}>{productPricing.discountedPrice}</Body>
                        )}
                        <Body
                          style={[
                            styles.text.hint,
                            !!productPricing.discountedPrice && {
                              textDecorationLine: 'line-through',
                              color: colors.COMET,
                            },
                          ]}
                        >
                          {!!productPricing.discountedPrice ? `(${productPricing.price})` : productPricing.price}
                        </Body>
                      </Row>
                    )}
                  </Column>
                </Row>
                <Icon type="arrow-right" />
              </Row>
            </TouchableOpacity>

            <Line />
            <TouchableOpacity onPress={() => navigateProductStockCallback(product.details._id)}>
              <Row justifyContent="space-between" style={{ paddingVertical: 20 }}>
                <Row>
                  <InventoryIcon width={32} height={32} />
                  <Column style={{ marginLeft: 16 }}>
                    <H3s style={styles.text.semibold}>{t('manageInventory')}</H3s>
                    <Body style={styles.text.hint}>
                      {product.stock.toLocaleString()} {t('inStock')}
                    </Body>
                  </Column>
                </Row>
                <Icon type="arrow-right" />
              </Row>
            </TouchableOpacity>
          </FormCard>

          <Column style={{ marginVertical: 32 }}>
            <Column justifyContent="center" alignItems="center">
              <Button
                expand={false}
                backgroundColor={colors.ALABASTER}
                color={colors.DARK_BLUE}
                text={
                  product.details.status === ProductStatus.Inactive.code ? t('unarchiveProduct') : t('archiveProduct')
                }
                onPress={() =>
                  archiveProduct(
                    product.details.status === ProductStatus.Inactive.code
                      ? { isArchived: false }
                      : { isArchived: true },
                  )
                }
              />
            </Column>
            <Column justifyContent="center" alignItems="center">
              <Button
                expand={false}
                backgroundColor={colors.ALABASTER}
                color={colors.RADICAL_RED}
                text={t('deleteProduct')}
                onPress={() => navigateDeleteProductCallback(product.details._id)}
              />
            </Column>
          </Column>
        </ScrollView>
      </ModalScreenContainer>
    )
  );
};

export default ProductMenu;

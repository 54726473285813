import { ProBootState } from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { logCatch } from '../../utils/log';
import { meroApi } from '../AuthContext';

type Props = ProBootState;

export type State =
  | { type: 'New' }
  | { type: 'Loading' }
  | ({ type: 'Reloading' } & Props)
  | ({ type: 'Loaded' } & Props)
  | { type: 'Error'; error: Error };

const defaultState = (): State => ({
  type: 'New',
});

export const AppContext = createModelContext(
  defaultState(),
  {
    setLoading: (state, payload: Partial<Props>) => {
      return {
        ...state,
        ...payload,
        type: 'Loading',
      };
    },
    setReloading: (state, payload: Props) => {
      return {
        ...state,
        ...payload,
        type: 'Reloading',
      };
    },
    setError: (state, payload: Partial<Props> & { error: Error }) => {
      return {
        ...state,
        ...payload,
        type: 'Error',
        error: payload.error,
      };
    },
    trySetResult: (state, result: Props) => {
      if (state.type === 'Loading' || state.type === 'Reloading') {
        return {
          ...state,
          ...result,
          type: 'Loaded',
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },

    setResults: (state, payload: Partial<Props>) => {
      return {
        ...state,
        ...payload,
      };
    },

    run: (state, fn: (state: State) => void) => {
      fn(state);

      return state;
    },
    mutate: (state, fn: (state: State) => State) => fn(state),
  },
  (dispatch) => {
    return {
      init: () => {
        dispatch.run(async () => {
          try {
            dispatch.setLoading({});
            const proBootState = await meroApi.pro.boot.getBootState().catch(logCatch('getBootState'));

            dispatch.trySetResult(proBootState);
          } catch (error: any) {
            dispatch.setError({ error });
          }
        });
      },
    };
  },
);

export const AppContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <AppContext.Provider>{children}</AppContext.Provider>;
};

export const Initializer: React.FC = () => {
  const [, dispatch] = AppContext.useContext();

  React.useEffect(() => {
    dispatch.init();
  }, []);

  return null;
};

export const withAppContextProvider = <P extends JSX.IntrinsicAttributes & Record<string, unknown>>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithAppEventsContextProvider(props: P) {
    return (
      <AppContextProvider>
        <Initializer />
        <Content {...props} />
      </AppContextProvider>
    );
  };
};

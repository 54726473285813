import { ResponsiveImage } from '@mero/api-sdk';
import { PageId, PageImage } from '@mero/api-sdk/dist/pages';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Header,
  Icon,
  Row,
  Spacer,
  useShowError,
} from '@mero/components';
import * as Device from 'expo-device';
import * as ImagePicker from 'expo-image-picker';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, Platform, Dimensions } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { styles } from '../../../../../../components/AddBookingScreen/styles';
import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../../contexts/CurrentBusiness';
import { SelectedImageContext } from '../../../../../../contexts/SelectedImageContext';
import { PageGallerySettingsStackParamList } from '../../../../../../types';
import log from '../../../../../../utils/log';
import MoreOptionsMenu from './MoreOptionsMenu';
import NewImage from './NewImage';
import SavedImage from './SavedImage';
import UploadImageButton from './UploadImageButton';

export type Props = StackScreenProps<PageGallerySettingsStackParamList, 'PageGallerySettings'>;

export enum Status {
  LOADING,
  FAILED,
  TO_BIG,
  SUCCESS,
}

export type NewImageType = {
  _id: string;
  status: Status;
  uri: string;
};

const convertMbToBytes = (mb: number) => mb * 1024 * 1024;

const isResponsiveImage = (image: ResponsiveImage | NewImageType): image is ResponsiveImage =>
  !image.hasOwnProperty('status');

const isNewImage = (image: ResponsiveImage | NewImageType): image is NewImageType => image.hasOwnProperty('status');

export const singleSelect = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
  });

  return result.canceled ? [] : result.assets;
};

export const multipleSelect = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
    allowsMultipleSelection: true,
  });

  return result.canceled ? [] : result.assets;
};

const PageGallerySettingsScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('gallery');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const showError = useShowError();
  const { width } = Dimensions.get('window');

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const [_selectedImage, { updateImage }] = SelectedImageContext.useContext();

  const scrollViewRef = React.useRef<ScrollView>(null);

  const [images, setImages] = React.useState<(PageImage | NewImageType)[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isUploading, setIsUploading] = React.useState(false);
  const [showMoreMenu, setShowMoreMenu] = React.useState(false);

  const uploadImages = async (newImages: NewImageType[], pageId: PageId) => {
    setIsUploading(true);

    const toUploadImages = newImages.filter((image) => image.status === Status.LOADING);

    for (let i = 0; i < toUploadImages.length; i++) {
      const asset = toUploadImages[i];
      log.debug(`Uploading image ${i + 1} of ${toUploadImages.length}`, asset);
      const profilePhotoUri = Platform.OS === 'ios' ? asset.uri.replace('file://', '') : asset.uri;
      const response = await fetch(profilePhotoUri);
      const blob = await response.blob();

      try {
        const newResponsiveImage = await meroApi.pages.uploadGalleryImage({
          pageId: pageId,
          image: { platform: Platform.OS, blob, uri: profilePhotoUri },
        });

        setImages((prevImages) =>
          prevImages.map((img) => {
            if (img._id === asset._id) {
              return newResponsiveImage;
            }

            return img;
          }),
        );
      } catch (error: unknown) {
        log.debug('Failed to upload image', error);
        setImages((prevImages) =>
          prevImages.map((img) => {
            if (img._id === asset._id) {
              return {
                ...img,
                status: Status.FAILED,
              };
            }
            return img;
          }),
        );
      }
    }
    setIsUploading(false);
  };

  const supportsMultiple = () => {
    if (Platform.OS === 'ios' && Device.osVersion) {
      return Device.osVersion.split('.')[0] >= '14';
    }

    if (Platform.OS === 'web') {
      return true;
    }

    return false;
  };

  const addImages = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsUploading(true);
    try {
      // No permissions request is necessary for launching the image library
      const result = supportsMultiple() ? await multipleSelect() : await singleSelect();

      if (result.length > 0) {
        const timestamp = new Date().getTime();
        const newImages = result.map((asset) => ({
          _id: Platform.OS === 'web' ? uuidv4() : `${timestamp}-${asset}`,
          uri: asset.uri,
          status: (asset.fileSize ?? 0) > convertMbToBytes(20) ? Status.TO_BIG : Status.LOADING,
        }));
        setImages((prevImages) => [...prevImages, ...newImages]);

        setTimeout(() => {
          scrollViewRef.current?.scrollToEnd({ animated: true });
        }, 1000);

        await uploadImages(newImages, pageState.page.details._id);
      }
    } catch (error) {
      log.error('Error while adding images', error);
      showError(error, t('failedToAddImages'));
    }

    setIsUploading(false);
  };

  const handleImagePress = (image: ResponsiveImage) => {
    const indexOfImage = images.findIndex((img) => img._id === image._id);
    updateImage({ image, position: indexOfImage });
    goToSelectedImageMenu(image._id);
  };

  const retryUpload = async (image: NewImageType) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      setImages((prevImages) =>
        prevImages.map((img) => {
          if (img._id === image._id) {
            return {
              ...img,
              status: Status.LOADING,
            };
          }

          return img;
        }),
      );

      if (!isUploading) {
        await uploadImages(
          [
            {
              ...image,
              status: Status.LOADING,
            },
          ],
          pageState.page.details._id,
        );
      }
    } catch (error) {
      log.error('Error while adding images', error);
      showError(error, t('failedToAddImages'));
    }
  };

  const localRemoveImage = (image: NewImageType) => {
    setImages((prevImages) => prevImages.filter((img) => img._id !== image._id));
  };

  const goToOrderPage = () => {
    reloadAsync();
    navigation.navigate('PageGalleryOrder');
  };

  const goToDeletePage = () => {
    reloadAsync();
    navigation.navigate('PageMultipleDelete');
  };

  const goToSelectedImageMenu = (imageId: string) => {
    reloadAsync();
    navigation.navigate('SelectedImageMenu', { imageId });
  };

  const closeMoreMenu = () => {
    setShowMoreMenu(false);
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setImages(pageState.page.details.images);
      setIsLoading(false);
    }
  }, [pageState]);

  React.useEffect(() => {
    return () => {
      reloadAsync();
    };
  }, []);

  React.useEffect(() => {
    if (
      pageState.type === 'Loaded' &&
      !isUploading &&
      images.some((image) => isNewImage(image) && image.status === Status.LOADING)
    ) {
      uploadImages(images.filter(isNewImage), pageState.page.details._id);
    }
  }, [isUploading]);

  const imageSize = ((width > 768 ? 500 : width) - 2 * 16 - 2 * 16 - 2 * 6) / 3;
  const responsiveImagesCount = React.useMemo(() => images.filter((img) => isResponsiveImage(img)).length, [images]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('title')}
        RightComponent={() =>
          responsiveImagesCount < 2 || isLoading || isUploading ? null : (
            <Button
              onClick={() => setShowMoreMenu(true)}
              expand={false}
              size="small"
              text={t('more')}
              backgroundColor={colors.ALABASTER}
              color={colors.DARK_BLUE}
              containerStyle={{ marginRight: 16 }}
            />
          )
        }
      />
      {isLoading ? null : images.length === 0 ? (
        <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <H2s>{t('noPhoto')}</H2s>
          <Spacer size={8} />
          <Body style={{ textAlign: 'center' }}>{t('description')}</Body>
          <Spacer size={32} />
          <UploadImageButton addImages={addImages} />
        </Column>
      ) : (
        <ScrollView ref={scrollViewRef} style={{ flex: 1, paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 8 }}>{t('title')}</H1>
          <Spacer size={8} />
          <Body style={{ paddingHorizontal: 8 }}>{t('description')}</Body>
          <Spacer size={24} />
          <FormCard
            paddings="none"
            style={{ paddingTop: 10, paddingLeft: 10, paddingBottom: 16, paddingRight: 16, flex: 1 }}
            dropShaddow
            rounded
          >
            <Column>
              <Row style={{ flexWrap: 'wrap' }}>
                {images.map((image) => {
                  if (isResponsiveImage(image)) {
                    return (
                      <SavedImage
                        key={image._id}
                        image={image}
                        size={imageSize}
                        onPress={handleImagePress}
                        disabled={isUploading}
                      />
                    );
                  }

                  return (
                    <NewImage
                      key={image._id}
                      image={image}
                      size={imageSize}
                      disabled={isLoading}
                      onRetry={retryUpload}
                      onRemove={localRemoveImage}
                    />
                  );
                })}
              </Row>
            </Column>
          </FormCard>
          <Spacer size={96} />
        </ScrollView>
      )}
      {images.length === 0 ? null : (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button text={t('addPhotos')} onClick={addImages} disabled={isLoading || isUploading} />
            ) : (
              <Button
                disabled={isLoading || isUploading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('addPhotos')}
                onClick={addImages}
              />
            )}
          </SafeAreaView>
        </FormCard>
      )}

      {showMoreMenu ? (
        <MoreOptionsMenu onOrder={goToOrderPage} onDelete={goToDeletePage} onDismiss={closeMoreMenu} />
      ) : null}
    </ModalScreenContainer>
  );
};

export default PageGallerySettingsScreen;

import { CheckoutReportBySaleOwnerAndServiceSortType, DateInterval } from '@mero/api-sdk';
import { CheckoutSaleOwnerServiceSalesReportPage } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutReportBySaleOwnerAndServicePage';
import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { colors, Column, SmallBody, styles as meroStyles } from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';
import Body from '@mero/components/lib/components/Text/Body';

import { useIsFocused } from '@react-navigation/native';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log, { logCatch } from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import { nameGenerator } from '../../../../../../utils/string';
import Table, { Column as TableColumn } from './Table';

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  saleOwnerId: UserId;
  updateSort: (
    sort:
      | {
          by: CheckoutReportBySaleOwnerAndServiceSortType;
          dir: SortDirection;
        }
      | undefined,
  ) => void;
};

const Services: React.FC<Props> = ({ pageId, dateInterval, saleOwnerId, updateSort }) => {
  const { t } = useTranslation('reports');
  const [reports, setReports] = React.useState<CheckoutSaleOwnerServiceSalesReportPage<MeroUnits.Any>['items']>([]);
  const [nextPage, setNextPage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState<
    | {
        by: CheckoutReportBySaleOwnerAndServiceSortType;
        dir: SortDirection;
      }
    | undefined
  >(undefined);
  const lastRequest = React.useRef<symbol>(Symbol());
  const loadingNextPage = React.useRef('');

  const getData = async (page?: string) => {
    setIsLoading(true);
    const request = Symbol();
    lastRequest.current = request;
    try {
      const { data: newReports, next: nextPage } = await meroApi.checkoutReports
        .getSalesByServiceAndOwnerReport({
          pageId,
          unit: 'RON',
          interval: dateInterval,
          saleOwnerId,
          sort,
          page,
        })
        .catch(logCatch('getSalesByServiceAndOwnerReport'));

      if (request === lastRequest.current) {
        setNextPage(nextPage);
        setReports((prev) => (typeof page === 'string' ? [...prev, ...newReports.items] : newReports.items));
      }
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortBy = async (field: string, direction: SortDirection) => {
    switch (field) {
      case 'date':
        setSort({ by: 'finished-at', dir: direction });
        break;
      case 'serviceTotal':
        setSort({ by: 'service-total', dir: direction });
        break;
      case 'membership':
        setSort({ by: 'membership-consumption', dir: direction });
        break;
      case 'protocol':
        setSort({ by: 'protocol', dir: direction });
        break;
      case 'total':
        setSort({ by: 'total', dir: direction });
        break;
      default:
        setSort(undefined);
        break;
    }
  };

  const onLoadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      loadingNextPage.current = nextPage;
      getData(nextPage);
    }
  };

  React.useEffect(() => {
    getData();
  }, [saleOwnerId, JSON.stringify(sort), JSON.stringify(dateInterval)]);

  React.useEffect(() => {
    updateSort(sort);
  }, [sort]);

  const { columns, data, fixedRows } = React.useMemo(() => {
    const data = reports.map((report) => ({
      date: DateTime.fromJSDate(report.finishedAt).toFormat('dd.MM.yyyy&&HH:mm'),
      client: `${nameGenerator(report.client ?? {}, t('noName'))}&&${
        report.client?.phone ? formatPhoneNumber(report.client.phone) : ''
      }`,
      service: report.service.name,
      serviceTotal: `${scaledToString(report.serviceTotal.amount)} ${t(report.serviceTotal.unit)}`,
      membership: `${scaledToString(report.membershipConsumption.amount)} ${t(report.membershipConsumption.unit)}`,
      protocol: `${scaledToString(report.protocol.amount)} ${t(report.protocol.unit)}`,
      total: `${scaledToString(report.total.amount)} ${t(report.total.unit)}`,
    }));

    const fixedRows: typeof data = [];

    const columns: TableColumn<(typeof data)[number]>[] = [
      {
        field: 'date',
        headerName: t('proServiceDateName'),
        width: '10%',
        sortable: true,
        CustomComponent: ({ date }) => {
          const [dateStr, timeStr] = date.split('&&');
          return (
            <Column>
              <SmallBody>{dateStr}</SmallBody>
              <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{timeStr}</SmallBody>
            </Column>
          );
        },
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('date', direction),
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'client',
        headerName: t('proServiceClientName'),
        width: '10%',
        sortable: false,
        CustomComponent: ({ client }) => {
          const [name, phone] = client.split('&&');
          return (
            <Column>
              <SmallBody>{name}</SmallBody>
              {phone ? <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{phone}</SmallBody> : null}
            </Column>
          );
        },
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'service',
        headerName: t('proServicesServiceName'),
        width: '20%',
        sortable: false,
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'serviceTotal',
        headerName: t('proServicesServiceTotal'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('serviceTotal', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'membership',
        headerName: t('proServicesMembership'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('membership', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'protocol',
        headerName: t('proServicesProtocol'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('gross', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'total',
        headerName: t('proServicesTotal'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('total', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    return {
      columns,
      data,
      fixedRows,
    };
  }, [reports]);

  return (
    <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, zIndex: -1 }}>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        loadMore={onLoadMore}
        defaultSortBy="date"
        defaultSortDir="ASC"
        style={{
          row: {
            alignItems: 'center',
          },
        }}
        ListEmptyComponent={
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 16 }}>
            <Body style={[meroStyles.text.semibold]}>{t('proServicesEmpty')}</Body>
          </Column>
        }
      />
    </FormCard>
  );
};

export default Services;

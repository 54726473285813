import { ModalOverlay, SafeAreaView } from '@mero/components';
import * as React from 'react';
import { Modal, Pressable } from 'react-native';

import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import AddCheckoutButton from '../AddCheckoutButton';
import AddBlockedTimeButton from './AddBlockedTimeButton';
import AddBookingButton from './AddBookingButton';

type Props = {
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
  readonly onDismiss?: () => void;
};

const AddBookingOptionsModal: React.FC<Props> = ({ onAddBooking, onAddBlockedTime, onAddCheckout, onDismiss }) => {
  const tabBarHeight = useBottomTabBarHeight();
  const { isPhone } = useMediaQueries();
  const [pageState] = CurrentBusinessContext.useContext();
  const [checkoutState] = CheckoutsContext.useContext();

  return (
    <Modal animationType="none" transparent={true} visible={true}>
      <ModalOverlay>
        <Pressable style={{ flex: 1 }} onPress={onDismiss}>
          <SafeAreaView
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              paddingHorizontal: 24,
              alignItems: 'flex-end',
            }}
          >
            {checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled && (
              <AddCheckoutButton style={{ marginBottom: 24 }} onPress={onAddCheckout} />
            )}
            <AddBlockedTimeButton style={{ marginBottom: 24 }} onPress={onAddBlockedTime} />
            {pageState.type === 'Loaded' && pageState.page.permissions.bookings.canWriteOwnBookings() ? (
              <AddBookingButton style={{ marginBottom: (isPhone ? tabBarHeight : 0) + 35 }} onPress={onAddBooking} />
            ) : null}
          </SafeAreaView>
        </Pressable>
      </ModalOverlay>
    </Modal>
  );
};

export default AddBookingOptionsModal;

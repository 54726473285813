import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  sizes,
  SmallBody,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import { flow, pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, ScrollView, Pressable, StyleSheet } from 'react-native';

import InvoiceItem from '../../../../../components/InvoiceItem';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsProps, withBillingDetails } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessProps, CurrentBusiness } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import {
  SubscriptionContext,
  SubscriptionInfoProps,
  withSubscriptionInfo,
} from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { nameGenerator } from '../../../../../utils/string';
import ConfirmCancelAutoCharge from './ConfirmCancelAutoCharge';
import ProformaDetails from './ProformaDetails';
import SubscriptionValidity from './SubscriptionValidity';

type Props = StackScreenProps<SubscriptionStackParamList, 'Subscription'> &
  CurrentBusinessProps &
  SubscriptionInfoProps &
  BillingDetailsProps;

const PageSubscriptionSettingsScreen: React.FC<Props> = ({
  page,
  subscriptionInfo,
  pendingOrders,
  invoiceArchive,
  billingDetails,
  navigation,
}: Props) => {
  const { t } = useTranslation('subscription');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();

  const [, { reload, reloadAsync }] = SubscriptionContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();
  const [pendingPaymentRetrial, setPendingPaymentRetrial] = React.useState(1);

  const [showCancelAutoCharge, setShowCancelAutoCharge] = React.useState(false);
  const [pendingPayment, setPendingPayment] = React.useState(false);
  const order = pendingOrders.length > 0 ? pendingOrders[0] : undefined;
  const now = new Date();
  const isAutoChargeOn = subscriptionInfo.autoCharge ?? false;
  const paymentExpires = subscriptionInfo.paymentExpires;
  const isExpired = paymentExpires.getTime() < now.getTime();
  const isPositiveTotal = (order?.total ?? 0) > 0;
  const isAboutToExpire = useMemo(
    () => !isExpired && paymentExpires.getTime() - now.getTime() < 48 * 3600 * 1000,
    [isExpired, paymentExpires, now],
  );

  const goToBillingDetails = React.useCallback(() => {
    navigation.navigate('BillingDetailsList');
  }, [navigation]);

  const goToBillingCycle = React.useCallback(() => {
    navigation.navigate('BillingCycleList');
  }, [navigation]);

  const handleDownload = async (url: string) => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('downloadInvoiceError'),
      });
    }
  };

  const cancelAutomaticRenewal = async () => {
    try {
      await meroApi.payments.setSubscriptionAutoCharge({
        subscriptionId: subscriptionInfo._id,
        autoCharge: false,
      });

      reload({ pageId: page.details._id });
    } catch (error) {
      log.error('Error canceling automatic renewal', error);
      toast.show({
        type: 'error',
        text: t('cancelAutomaticRenewalError'),
      });
    }
  };

  const checkPaymentStatus = async () => {
    if (pendingOrders.length === 0) {
      setPendingPayment(false);
      return;
    }

    const lastSuccessfulPayment = await AppStorage.getLastPaymentId();
    const orderId = pendingOrders[0]._id;

    setPendingPayment(orderId === lastSuccessfulPayment);
  };

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  useEffect(() => {
    checkPaymentStatus();
  }, [pendingOrders, isFocused]);

  React.useEffect(() => {
    if (isFocused) {
      reloadAsync({ pageId: page.details._id });
    }
  }, [isFocused]);

  useEffect(() => {
    let timeout = 0;

    if (pendingPayment && pendingPaymentRetrial < 5) {
      timeout = window.setTimeout(() => {
        reloadAsync({
          pageId: page.details._id,
        });
        setPendingPaymentRetrial((prev) => prev + 1);
      }, 250 * pendingPaymentRetrial);
    } else if (!pendingPayment && pendingPaymentRetrial > 1) {
      reloadAsync({ pageId: page.details._id, withoutDelay: true });
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [pendingPayment, pendingPaymentRetrial]);

  const subscriptionBillingDetails = useMemo(
    () => billingDetails.find((b) => b.usage?.includes('MeroSubscription')),
    [billingDetails],
  );

  const payWithCreditCard = async () => {
    if (!order) {
      return;
    }
    if (!subscriptionBillingDetails) {
      return navigation.navigate('BillingDetailsList', { then: 'payWithCard', meroCompany: order.meroCompany });
    } else {
      return navigation.navigate('CardPayment', { meroCompany: order.meroCompany });
    }
  };

  const getLabel = () => {
    if (pendingPayment) {
      return (
        <Column style={[styles.pendingPaymentWrapper, styles.banner]}>
          <Label style={styles.label}>{t('pendingPayment')}</Label>
        </Column>
      );
    }

    if (isExpired && subscriptionInfo.autoChargeError) {
      return (
        <Column style={[styles.autoRenewalWrapper, styles.banner]}>
          <Label style={styles.label}>
            {t('automaticRenewalError')}
            {subscriptionInfo.autoChargeError ? `: ${subscriptionInfo.autoChargeError}` : ''}
          </Label>
        </Column>
      );
    }

    if (!isExpired && isPositiveTotal && isAboutToExpire && !isAutoChargeOn) {
      return (
        <Column style={[styles.aboutToExpireWrapper, styles.banner]}>
          <Label style={styles.label}>{t('payToAvoidAccessRestrictions')}</Label>
        </Column>
      );
    }

    return null;
  };

  return (
    <ModalScreenContainer style={styles.container}>
      <MeroHeader canGoBack onBack={goBack} title={t('subscriptionAndBilling')} />
      <Column style={styles.content}>
        <H1 style={styles.heading}>{t('subscriptionAndBilling')}</H1>
        <ScrollView style={styles.scrollContainer}>
          <SubscriptionValidity subscriptionInfo={subscriptionInfo} now={now} onContactUsPressed={onContactUsPressed} />

          {isPositiveTotal && (
            <>
              <Spacer size={sizes[24]} />
              <FormCard dropShaddow paddings="none" style={styles.formCard} rounded>
                {getLabel()}
                <Spacer size={sizes[16]} />
                <Column>
                  {order ? <ProformaDetails order={order} subscriptionInfo={subscriptionInfo} now={now} /> : null}
                </Column>
              </FormCard>

              {/*{pendingPayment || (subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError) ? null : (*/}
              {/*  <>*/}
              {/*    <Line />*/}
              {/*    <Spacer size={sizes[16]} />*/}
              {/*  </>*/}
              {/*)}*/}

              {pendingPayment ? null : subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError ? null : (
                <>
                  <Column style={styles.payOnlineWrapper}>
                    <Spacer size={32} />
                    <Button text={t('payOnline')} onPress={payWithCreditCard} disabled={order?.total === 0} />
                    <Spacer size={8} />
                  </Column>
                </>
              )}
            </>
          )}

          {subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError ? (
            <Column style={styles.cancelAutoRenewalWrapper}>
              <Button
                onPress={() => setShowCancelAutoCharge(true)}
                backgroundColor={colors.ALABASTER}
                color={colors.DARK_BLUE}
                text={t('cancelAutoRenewal')}
              />
            </Column>
          ) : null}

          <Column style={styles.billingWrapper}>
            <H1 style={styles.billingHeading}>{t('billing')}</H1>
            <Pressable style={styles.card} onPress={goToBillingCycle}>
              <Row>
                <Column flex={1}>
                  <Title>{t('billingCycle')}</Title>
                  <SmallBody>{t(subscriptionInfo.billingCycle)}</SmallBody>
                </Column>
                <Icon type="arrow-right" />
              </Row>
            </Pressable>
            {subscriptionBillingDetails ? (
              <Pressable style={styles.card} onPress={goToBillingDetails}>
                <Row>
                  <Column flex={1}>
                    <Title>{t('billingInfo')}</Title>
                    <SmallBody>
                      {subscriptionBillingDetails.billing.type === 'Company'
                        ? subscriptionBillingDetails.billing.name
                        : nameGenerator(subscriptionBillingDetails.billing, t('noName'))}
                    </SmallBody>
                    <SmallBody style={styles.companyEmail}>
                      {subscriptionBillingDetails.billing.type === 'Company'
                        ? subscriptionBillingDetails.billing.contact.email
                        : subscriptionBillingDetails.billing.email}
                    </SmallBody>
                  </Column>
                  <Icon type="arrow-right" />
                </Row>
              </Pressable>
            ) : (
              <Column style={styles.card}>
                <Pressable onPress={goToBillingDetails}>
                  <Row justifyContent="space-between" style={styles.titleWrapper}>
                    <Title style={styles.billingInfoTitle}>{t('billingInfo')}</Title>
                    <Icon type="arrow-right" />
                  </Row>
                  <Body style={styles.missingBillingInfo}>{t('missingBillingInfo')}</Body>
                </Pressable>
              </Column>
            )}
            {invoiceArchive.length > 0 ? (
              invoiceArchive.map((invoice, index) => (
                <Fragment key={invoice._id}>
                  <InvoiceItem invoice={invoice} onPress={() => handleDownload(invoice.downloadUrl)} />
                  {invoiceArchive.length > 1 && index !== invoiceArchive.length - 1 && <Line />}
                </Fragment>
              ))
            ) : (
              <Column style={styles.invoicesListEmptyState}>
                <H2s style={styles.emptyStateTitle}>{t('noBills')}</H2s>
                <Body style={styles.emptyStateBody}>{t('noBillsText')}</Body>
              </Column>
            )}
          </Column>
        </ScrollView>
      </Column>
      {showCancelAutoCharge && (
        <ConfirmCancelAutoCharge
          onConfirm={flow(() => setShowCancelAutoCharge(false), cancelAutomaticRenewal)}
          onDismiss={() => setShowCancelAutoCharge(false)}
        />
      )}
    </ModalScreenContainer>
  );
};

const styles = StyleSheet.create({
  invoicesListEmptyState: { paddingTop: 32, alignItems: 'center', paddingBottom: 32 },
  emptyStateTitle: { textAlign: 'center' },
  emptyStateBody: { textAlign: 'center' },
  card: {
    marginBottom: 24,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.ATHENS_GRAY,
    paddingVertical: 12,
    paddingLeft: 12,
    paddingRight: 8,
  },
  titleWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  billingCycleWrapper: {
    marginBottom: 24,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.ATHENS_GRAY,
    paddingVertical: 12,
    paddingLeft: 12,
    paddingRight: 8,
  },
  pendingPaymentWrapper: {
    backgroundColor: colors.YELLOW_ORANGE,
  },
  banner: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  autoRenewalWrapper: {
    backgroundColor: colors.RADICAL_RED,
  },
  aboutToExpireWrapper: {
    backgroundColor: colors.OUTRAGEOUS_ORANGE,
  },
  label: { color: colors.WHITE },
  container: { backgroundColor: colors.ALABASTER },
  content: { paddingTop: 16, flex: 1 },
  heading: { paddingHorizontal: 24 },
  scrollContainer: { marginTop: 32, borderRadius: 6, flex: 1 },
  formCard: { marginLeft: sizes[16], marginRight: sizes[16], padding: 0 },
  payOnlineWrapper: { marginLeft: sizes[16], marginRight: sizes[16] },
  cancelAutoRenewalWrapper: { paddingHorizontal: 16, paddingTop: 24 },
  billingWrapper: { backgroundColor: colors.WHITE, marginTop: 46, paddingHorizontal: 24, flex: 1 },
  billingHeading: { paddingTop: 32, paddingBottom: 24 },
  companyEmail: { color: colors.COMET },
  billingInfoTitle: { flex: 1 },
  missingBillingInfo: { flex: 1, color: colors.COMET },
});

export default pipe(PageSubscriptionSettingsScreen, withBillingDetails, withSubscriptionInfo, CurrentBusiness);

import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { Body, H1, MeroHeader, SafeAreaView, sizes, Spacer, styles as meroStyles } from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { View } from 'react-native';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AuthorizedStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import ClientDetailsFailedScreenView from '../ClientDetailsScreen/ClientDetailsFailedScreenView';
import { styles } from './styles';

type ScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<AuthorizedStackParamList, 'ClientSalesReportScreen'>,
  StackNavigationProp<RootStackParamList, 'Authorized'>
>;

type Props = {
  readonly navigation: ScreenNavigationProp;
  readonly clientId: ClientId;
  readonly pageId: PageId;
};

const ClientSalesReportScreen: React.FC<Props> = () => {
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  return (
    <SafeAreaView style={styles.container}>
      <MeroHeader canGoBack onBack={goBack} title="John Smith" />
      <H1 style={[meroStyles.layout.paddingHor24, { marginTop: sizes[16] }]}>Raport vânzări</H1>
      <Spacer size="32" />
      <View style={meroStyles.layout.padding16}>
        <Body>ClientSalesReportScreen</Body>
      </View>
    </SafeAreaView>
  );
};

type InitProps = {
  navigation: ScreenNavigationProp;
  route: RouteProp<AuthorizedStackParamList, 'ClientSalesReportScreen'>;
};

const ClientSalesReportScreenInit: React.FC<InitProps> = ({ navigation, route }: InitProps) => {
  const clientId = pipe(
    route.params.clientId,
    ClientId.decode,
    E.getOrElseW(() => undefined),
  );

  const pageId = pipe(
    route.params.pageId,
    PageId.decode,
    E.getOrElseW(() => undefined),
  );

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  if (clientId && pageId) {
    return <ClientSalesReportScreen navigation={navigation} clientId={clientId} pageId={pageId} />;
  } else {
    if (!clientId) {
      log.error(`ClientSalesReportScreen: Failed to decode ClientId from "${route.params.clientId}"`);
    }

    if (!pageId) {
      log.error(`ClientSalesReportScreen: Failed to decode PageId from "${route.params.pageId}"`);
    }

    // FIXME: use custom error page here (ClientSalesReportFailedScreenView)
    return <ClientDetailsFailedScreenView error={new Error('Link invalid')} onBack={goBack} />;
  }
};

export default ClientSalesReportScreenInit;

import { ScaledNumber } from '@mero/shared-sdk';

export const nameGenerator = (struct: { firstname?: string; lastname?: string }, defaultValue: string) => {
  const { firstname, lastname } = struct;
  return firstname || lastname ? `${firstname ?? ''}${lastname ? ` ${lastname}` : ''}` : defaultValue;
};

export const scaledToString = (value: ScaledNumber, locale?: string): string => {
  if (value.scale === 0 || value.scale === undefined) {
    return value.value.toLocaleString(locale);
  } else {
    const div = Math.pow(10, value.scale);
    const absValue = Math.abs(value.value);
    const sign = value.value < 0 ? '-' : '';
    const intStr = Math.trunc(absValue / div).toLocaleString(locale);
    const decimalStr = (absValue % div).toString();
    const padding = '0'.repeat(Math.max(0, Math.min(value.scale, 2) - decimalStr.length));
    const decimalPoint = (1.1).toLocaleString(locale).substring(1, 2);

    //Use a maximum of 2 decimals
    return `${sign}${intStr}${decimalPoint}${padding}${decimalStr.substring(0, Math.min(2, value.scale))}`;
  }
};

import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Column, Row, SmallBody, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import Svg, { G, Path, Circle } from 'react-native-svg';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { meroApi } from '../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../types';
import log from '../../utils/log';

const SmsReminderIcon = () => (
  <Svg width={25.769} height={23.074}>
    <G
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      transform="translate(-1.1 -4.1)"
    >
      <Path d="M24.125 16a7.306 7.306 0 0 0 .291-2.034C24.415 9.026 19.392 5 13.208 5S2 9.026 2 13.966A8.173 8.173 0 0 0 5.4 20.4l-2.57 4.94 7.306-2.74a13.823 13.823 0 0 0 3.072.332l1.453-.083M20.195 18.111v2.615m2.421 0h-2.421" />
      <Circle cx={5.707} cy={5.707} r={5.707} data-name="layer1" transform="translate(14.555 14.86)" />
    </G>
  </Svg>
);

const CloseIcon = () => (
  <Svg width={24} height={24} data-name="Group 7426">
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path
      d="m6.376 16.68 4.62-4.619-4.62-4.62a.754.754 0 1 1 1.066-1.066l4.62 4.62 4.62-4.62a.754.754 0 0 1 1.065 1.066l-4.62 4.62 4.62 4.619a.754.754 0 0 1-1.066 1.066l-4.62-4.619-4.619 4.62a.754.754 0 1 1-1.065-1.066Z"
      fill={colors.BLACK}
    />
  </Svg>
);

const ManualNotification: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('notifications');
  const [pageState] = CurrentBusinessContext.useContext();

  const isFocused = useIsFocused();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [notificationsReminders, setNotificationsReminders] = React.useState({
    sent: 0,
    total: 0,
  });

  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const width = Dimensions.get('window').width;

  const maxWidth = width - 48;

  const { sent, total } = notificationsReminders;

  const progress = React.useMemo(() => {
    return Math.ceil((100 / total) * sent);
  }, [sent, total]);

  const getNotificationSettings = React.useCallback(async (pageId: PageId) => {
    try {
      const smsReminders = await meroApi.notifications.getUpcomingManualSmsReminders({ pageId });
      const sent = smsReminders.filter((reminder) => reminder.manualSMSStatus?.type === 'sent').length;
      const validReminders = smsReminders.filter((reminder) => reminder.manualSMSStatus?.type !== 'deleted');
      const total = validReminders.length;
      setNotificationsReminders({ sent, total });
      setShow(total !== sent);
    } catch (error) {
      log.error('Failed to get notifications settings', JSON.stringify(error));
    }
  }, []);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused && (Platform.OS !== 'web' || Dimensions.get('window').width <= 768)) {
      getNotificationSettings(pageState.page.details._id);
    }
  }, [pageState.type, isFocused]);

  return show ? (
    <View
      style={{
        flexDirection: isExpanded ? 'column' : 'row-reverse',
        width: maxWidth,
      }}
      pointerEvents="box-none"
    >
      {isExpanded ? (
        <View style={{ flex: 1, alignItems: 'flex-end' }} pointerEvents="box-none">
          {children}
        </View>
      ) : (
        children
      )}
      <View style={{ flex: 1 }} pointerEvents="box-none">
        {isExpanded ? (
          <>
            <View style={{ height: 24 }} pointerEvents="box-none" />
            <TouchableOpacity
              style={{ backgroundColor: colors.YELLOW_ORANGE, padding: 16, flex: 1, borderRadius: 12 }}
              onPress={() => navigation.navigate('ManualSmsReminder')}
            >
              <Row style={{ flex: 1, alignItems: 'center' }}>
                <SmallBody style={{ flex: 1, fontFamily: 'open-sans-semibold' }}>
                  {t('reminder', { total, sent })}
                </SmallBody>
                <TouchableOpacity onPress={() => setIsExpanded(false)}>
                  <CloseIcon />
                </TouchableOpacity>
              </Row>
              <Spacer size={8} />
              <Row style={{ height: 8, borderRadius: 4, overflow: 'hidden' }}>
                <Column style={{ width: `${progress}%`, backgroundColor: colors.BLACK }} />
                <Column style={{ width: `${100 - progress}%`, backgroundColor: colors.WHITE }} />
              </Row>
            </TouchableOpacity>
          </>
        ) : (
          <TouchableOpacity
            style={{
              width: 52,
              height: 52,
              backgroundColor: colors.YELLOW_ORANGE,
              borderRadius: 26,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => setIsExpanded(true)}
          >
            <SmsReminderIcon />
          </TouchableOpacity>
        )}
      </View>
    </View>
  ) : (
    <>{children}</>
  );
};

export default ManualNotification;

import { Body, Icon, Row } from '@mero/components';
import * as React from 'react';

type Props = {
  label: string;
};

const DisabledSelect: React.FC<Props> = ({ label }) => (
  <Row
    style={{
      height: 47,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: '#DEE2E6',
      backgroundColor: '#E9ECEF',
      alignItems: 'center',
      paddingHorizontal: 14,
    }}
  >
    <Body style={{ flex: 1 }}>{label}</Body>
    <Icon type="dropdown" />
  </Row>
);

export default DisabledSelect;

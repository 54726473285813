import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { WHITE } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationPaymentIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#2dce89',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" fill={backgroundColor} />
    <G transform="translate(7 7)">
      <Path d="M0,0H18V18H0Z" fill="none" />
      <Path
        d="M15.5,4H3.5A1.488,1.488,0,0,0,2.007,5.5L2,14.5A1.5,1.5,0,0,0,3.5,16h12A1.5,1.5,0,0,0,17,14.5v-9A1.5,1.5,0,0,0,15.5,4Zm0,10.5H3.5V10h12Zm0-7.5H3.5V5.5h12Z"
        transform="translate(-0.5 -1)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationPaymentIcon);

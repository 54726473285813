import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const CheckmarkCircled = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fill={color || 'white'}
        d="M 12 2 C 6.4860328 2 2 6.4860368 2 12 C 2 17.513963 6.4860328 22 12 22 C 17.513967 22 22 17.513963 22 12 C 22 6.4860368 17.513967 2 12 2 z M 12 3.5 C 16.703308 3.5 20.5 7.2966955 20.5 12 C 20.5 16.703304 16.703308 20.5 12 20.5 C 7.2966924 20.5 3.5 16.703304 3.5 12 C 3.5 7.2966955 7.2966924 3.5 12 3.5 z M 16.248047 8.9804688 A 0.750075 0.750075 0 0 0 15.732422 9.2070312 L 11 13.9375 L 8.2753906 11.214844 A 0.750075 0.750075 0 1 0 7.2148438 12.275391 L 10.46875 15.53125 A 0.750075 0.750075 0 0 0 11.53125 15.53125 L 16.792969 10.267578 A 0.750075 0.750075 0 0 0 16.248047 8.9804688 z"
      />
    </Svg>
  );
};

export default CheckmarkCircled;

import { MembershipConsumptionPerTransaction } from '@mero/api-sdk/dist/memberships/membershipConsumptionPerTransaction';
import {
  Body,
  colors,
  Column,
  H2s,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
} from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import { getItemPrice } from '../../MenuScreen/screens/PageMembershipSettingsScreen/MembershipItemsScreen';

import FormCard from '@mero/components/lib/components/FormCard';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { AuthorizedStackParamList } from '../../../../types';
import { scaledToString } from '../../../../utils/scaled';

type Props = {
  readonly item: MembershipConsumptionPerTransaction<MeroUnits.Any>;
};

const MembershipAppointmentListItemView: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('clients');
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const actionDate = React.useMemo(() => DateTime.fromJSDate(item.transaction.date), [item.transaction.date]);

  return (
    <FormCard dropShaddow rounded paddings="none" style={{ marginHorizontal: 16 }}>
      <Spacer size={16} />
      <TouchableOpacity
        style={{ flexDirection: 'row', paddingHorizontal: 16 }}
        onPress={() =>
          navigation.navigate('CombineCheckout', {
            screen: 'CheckoutStack',
            params: {
              screen: 'ProceedDetailsScreen',
              params: {
                checkoutTransactionId: item.transaction._id,
                backMode: 'one',
              },
            },
          })
        }
      >
        <Column style={{ flex: 1 }}>
          <H2s>{capitalize(actionDate.setLocale('ro').toFormat('ccc dd MMM'))}</H2s>
          <Spacer size={4} />
          <SmallBody style={[{ fontSize: 12, color: colors.COMET }, meroStyles.text.semibold]}>
            {t('membershipCharged', { code: item.transaction.code.toUpperCase() })}
          </SmallBody>
        </Column>
        <Icon type="next" color={colors.DARK_BLUE} />
      </TouchableOpacity>
      <Spacer size={16} />
      <Line />
      {item.consumption.map((consumption, index) => {
        return consumption.items.map((consumedItem, index) => {
          const servicePrice = getItemPrice(consumedItem.item.service.price);
          const actualPrice = ScaledNumber.toNumber(consumedItem.item.service.effectivePrice.amount);
          const hasDiscount = actualPrice < servicePrice;
          const percentage = hasDiscount ? ((servicePrice - actualPrice) / servicePrice) * 100 : 0;
          return (
            <React.Fragment key={index}>
              <Spacer size={16} />
              <Row style={{ flexDirection: 'row', paddingHorizontal: 16 }}>
                <Column style={{ flex: 1 }}>
                  <Title>{consumedItem.item.service.name}</Title>
                  <SmallBody style={{ color: colors.COMET }}>
                    {t('membershipServiceUsed', {
                      count: consumedItem.quantity,
                      name: `${consumedItem.saleOwner.profile.firstname} ${consumedItem.saleOwner.profile.lastname}`,
                    })}
                  </SmallBody>
                </Column>
                <Column>
                  <Body style={{ textAlign: 'right' }}>
                    {scaledToString(
                      ScaledNumber.fromNumber(actualPrice, consumedItem.item.service.effectivePrice.amount.scale ?? 2),
                    )}{' '}
                    {t(consumedItem.item.service.effectivePrice.unit)}
                  </Body>
                  {hasDiscount && (
                    <Row style={{ justifyContent: 'flex-end' }}>
                      <SmallBody style={{ color: colors.COMET, textDecorationLine: 'line-through' }}>
                        {scaledToString(ScaledNumber.fromNumber(servicePrice, 0))}{' '}
                        {t(consumedItem.item.service.effectivePrice.unit)}
                      </SmallBody>
                      <SmallBody style={{ color: colors.COMET }}>
                        {` `}(-{percentage.toFixed(0)}%)
                      </SmallBody>
                    </Row>
                  )}
                </Column>
              </Row>
              <Spacer size={16} />
              {index < consumption.items.length - 1 && (
                <>
                  <Line />
                  <Spacer size={16} />
                </>
              )}
            </React.Fragment>
          );
        });
      })}
    </FormCard>
  );
};

export default React.memo(MembershipAppointmentListItemView);

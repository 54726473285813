import log from './log';

export const clone = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const splitWithDefault = <T extends string>(value: string | null | undefined, defaultValue: T[]): T[] => {
  try {
    return value ? (value.split(',') as T[]) : defaultValue;
  } catch (error) {
    log.debug('Error parsing value', error);
    return defaultValue;
  }
};

import { Body, ConfirmBox, H1, ModalOverlay, Spacer, styles } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

type Props = {
  onSave: () => void;
  onClose: () => void;
};

const ConfirmTransactionDialog: React.FC<Props> = ({ onSave, onClose }) => {
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { t } = useTranslation('cashRegistry');

  const saveTransactionLeftAction = {
    text: t('cancelButton'),
    onPress: onClose,
    flex: 10,
  };

  const saveTransactionRightAction = {
    text: t('saveChanges'),
    onPress: () => {
      setSaveInProgress(true);
      onSave();
      setSaveInProgress(false);
      onClose();
    },
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('confirmAction')}
          icon="info"
          canClose={!saveInProgress}
          onClose={onClose}
          leftAction={saveTransactionLeftAction}
          rightAction={saveTransactionRightAction}
        >
          <H1>{t('saveTransactionConfirmation')}</H1>
          <Spacer size="8" />
          <Body>{t('modifiedSoldWarning')}</Body>
          <Spacer size="16" />
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default ConfirmTransactionDialog;

import { ServiceGroupId } from '@mero/api-sdk/dist/services';
import {
  AvoidKeyboard,
  Body,
  colors,
  ConfirmBox,
  DismissKeyboard,
  H1,
  ModalOverlay,
  SmallBody,
  Spacer,
  TypeSafeTextInput,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { KeyboardAvoidingView } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: (groupId: ServiceGroupId) => void;
  onCancel: () => void;
};

const AddCategoryDialog: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const { isDesktop } = useMediaQueries();

  const [showErrors, setShowErrors] = React.useState(false);
  const [saveInProgress, setSaveInProgress] = React.useState(false);

  const [categoryName, setCategoryName] = React.useState({
    input: '',
    decoded: NonEmptyString.decode(''),
  });
  const categoryNameValid = E.isRight(categoryName.decoded);

  const cancelAddCategory = () => {
    onCancel();
  };

  const addNewCategory = async () => {
    try {
      if (categoryNameValid && pageState.type === 'Loaded') {
        setSaveInProgress(true);
        const groupId = await meroApi.pages.createServiceGroup({
          pageId: pageState.page.details._id,
          name: categoryName.input,
        });
        onSuccess(groupId);
      } else {
        setShowErrors(true);
      }
    } catch (error) {
    } finally {
      setSaveInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: 'Anulează',
    onPress: cancelAddCategory,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: 'Adaugă',
    onPress: addNewCategory,
    flex: 15,
  };

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="center">
        <DismissKeyboard
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <KeyboardAvoidingView
            style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}
          >
            <ConfirmBox
              type="info"
              headerTitle="Adaugă categorie"
              canClose={!saveInProgress}
              onClose={cancelAddCategory}
              leftAction={addCategoryLeftAction}
              rightAction={addCategoryRightAction}
              style={[isDesktop && { maxWidth: 368 }]}
            >
              <AvoidKeyboard>
                <H1>Adaugă categorie</H1>
                <Spacer size="8" />
                <Body>Categoria te ajuta să organizezi serviciile similare în același grup.</Body>
                <Spacer size="16" />
                <TypeSafeTextInput
                  codec={NonEmptyString}
                  value={categoryName.input}
                  showError={showErrors}
                  onChange={setCategoryName}
                  placeholder="Introdu numele categoriei"
                />
                {showErrors && !categoryNameValid && (
                  <>
                    <Spacer size={2} />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>
                      Trebuie să introduci un nume pentru noua categorie
                    </SmallBody>
                  </>
                )}
                <Spacer size={8} />
              </AvoidKeyboard>
            </ConfirmBox>
          </KeyboardAvoidingView>
        </DismissKeyboard>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default AddCategoryDialog;

import { ApiError, DefinedTrimedString, ProductBrand, apiError } from '@mero/api-sdk';
import {
  DismissKeyboard,
  H1,
  Spacer,
  InputWithLabel,
  TypeSafeTextInput,
  useShowError,
  Avatar,
  Column,
  FormCard,
  Header,
  Icon,
  colors,
  SafeAreaView,
  Button,
  useToast,
} from '@mero/components';
import * as ImagePicker from 'expo-image-picker';
import * as E from 'fp-ts/lib/Either';
import * as type from 'io-ts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { EditIcon } from '../MenuScreen/screens/ProsDashboardScreen/ProProfileDetailsScreen';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { AuthorizedStackParamList } from '../../../types';
import { styles } from './styles';

type Props = {
  route: RouteProp<AuthorizedStackParamList, 'ProductBrandScreen'>;
};

const PlusIcon = (props: SvgProps) => (
  <Svg width={10.5} height={10.5} {...props}>
    <Path
      fill="#52577F"
      d="M9.844 4.594H5.906V.656a.656.656 0 1 0-1.312 0v3.938H.656a.656.656 0 1 0 0 1.312h3.938v3.937a.656.656 0 1 0 1.312 0V5.906h3.937a.656.656 0 1 0 0-1.312Z"
    />
  </Svg>
);

const ProductBrandScreen: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('products');
  const showError = useShowError();
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const [pageState] = CurrentBusinessContext.useContext();
  const [searchState, { reload }] = SearchProductsContext.useContext();
  const block = React.useRef(false);

  const [state, { reloadBrands, update }] = ProductsContext.useContext();
  const [brand, setBrand] = React.useState<ProductBrand | undefined>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [brandImage, setBrandImage] = React.useState<string | undefined>(undefined);

  const [name, setName] = React.useState({
    input: '',
    decoded: DefinedTrimedString.decode(''),
  });
  const isNameValid = E.isRight(name.decoded);

  React.useEffect(() => {
    if (state.type === 'Loaded') {
      const brand = state.brands.find((brand) => brand._id === route.params.brandId);
      if (brand) {
        setBrand(brand);
        brand.image && setBrandImage(brand.image.thumbnail);
        setName({
          input: brand.name,
          decoded: DefinedTrimedString.decode(brand.name),
        });
      }
    }
  }, [state.type]);

  const createOrUpdateBrand = async () => {
    if (pageState.type !== 'Loaded' || searchState.type !== 'Loaded' || state.type !== 'Loaded') {
      return;
    }

    if (!E.isRight(name.decoded)) {
      return setShowErrors(true);
    }

    try {
      setIsLoading(true);

      let result = brand;
      if (brand && brand.name !== name.decoded.right) {
        result = await meroApi.pro.products.updateBrand({
          pageId: pageState.page.details._id,
          brandId: brand._id,
          name: name.decoded.right,
        });
      }

      if (!brand) {
        result = await meroApi.pro.products.createBrand({
          pageId: pageState.page.details._id,
          name: name.decoded.right,
        });
      }

      if (result && brandImage && result.image?.thumbnail !== brandImage) {
        const brandPhotoUri = Platform.OS === 'ios' ? brandImage.replace('file://', '') : brandImage;
        const response = await fetch(brandPhotoUri);
        const blob = await response.blob();

        await meroApi.pro.products.uploadBrandImage({
          brandId: result._id,
          pageId: pageState.page.details._id,
          image: { platform: Platform.OS, blob, uri: brandPhotoUri, keywords: [] },
        });
      }

      reload();
      await reloadBrands(pageState.page.details._id);
      result &&
        update({
          selectedBrand: {
            _id: result._id,
            name: result.name,
            pageId: result.pageId,
          },
        });
      // toast.show({
      //   type: 'success',
      //   text: !brand ? t('addBrandSuccess') : t('updateBrandSuccess'),
      // });
      goBack();
    } catch (e) {
      if (e instanceof ApiError && e.code === 133) {
        toast.show({
          type: 'error',
          text: t('duplicateBrandName'),
        });
      } else {
        showError(e);
      }
    }
    setIsLoading(false);
  };

  const addPhoto = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      const brandImage = result.assets[0].uri;

      setBrandImage(brandImage);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={brand ? t('updateBrand') : t('addBrand')}
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
      />
      <DismissKeyboard
        style={{
          flex: 1,
          alignSelf: 'stretch',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <KeyboardAvoidingView style={{ flex: 1, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, width: '100%' }}>
            <H1 style={{ paddingHorizontal: 8 }}>{brand ? t('updateBrand') : t('addBrand')}</H1>
            <Spacer size={32} />
            <FormCard paddings={'none'} style={{ padding: 16 }} dropShaddow rounded>
              <Column style={{ alignItems: 'center' }}>
                <TouchableOpacity onPress={addPhoto}>
                  <Avatar size={60} source={brandImage} firstname={brand?.name ?? 'B'} lastname={''} />
                  <Column style={styles.brandIcon}>{brand ? <EditIcon /> : <PlusIcon />}</Column>
                </TouchableOpacity>
                <Spacer size={12} />
              </Column>
              <Spacer size={32} />
              <InputWithLabel label={t('brandName')} isError={showErrors && !isNameValid} errorText={t('nameError')}>
                <TypeSafeTextInput
                  codec={DefinedTrimedString}
                  value={name.input}
                  showError={showErrors && !isNameValid}
                  onChange={setName}
                  placeholder={t('brandNamePlaceholder')}
                />
              </InputWithLabel>
              <Spacer size="16" />
            </FormCard>
          </View>
        </KeyboardAvoidingView>
      </DismissKeyboard>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={createOrUpdateBrand} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={createOrUpdateBrand}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProductBrandScreen;

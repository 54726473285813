import * as React from 'react';

import ContextInit from './ContextInit';
import { IntercomContext } from './context';

export { IntercomState, IntercomContext } from './context';

export const withIntercomContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithIntercomContextProvider(props: P) {
    return (
      <IntercomContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </IntercomContext.Provider>
    );
  };
};

import Constants from 'expo-constants';

import devConfig from './config.dev';
import prodConfig from './config.prod';

export const isDevConfig = Constants?.expoConfig?.slug === 'mero-pro-dev';

const config = isDevConfig ? devConfig : prodConfig;

export default config;

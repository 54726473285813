import { Line, ModalOverlay, SafeAreaView, Title, colors } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity } from 'react-native';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { styles } from './styles';

type Props = {
  readonly onDismiss: () => void;
  readonly onAcceptAppointment?: () => void;
  readonly onRejectAppointment?: () => void;
  readonly onEditAppointment?: () => void;
  readonly onBookAgain?: () => void;
  readonly onCanceledByClient?: () => void;
  readonly onMarkAsNoShow?: () => void;
  readonly onDeleteAppointment?: () => void;
};

const BookingOptionsMenu: React.FC<Props> = ({
  onDismiss,
  onAcceptAppointment,
  onEditAppointment,
  onRejectAppointment,
  onBookAgain,
  onCanceledByClient: onCancelByClient,
  onMarkAsNoShow,
  onDeleteAppointment,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <SafeAreaView
        edges={['bottom']}
        style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottomOptions]}
      >
        {onAcceptAppointment !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onAcceptAppointment}>
              <Title style={{ color: colors.SHAMROCK }}>Confirmă programare</Title>
            </TouchableOpacity>
            <Line />
          </>
        ) : null}

        {onEditAppointment !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onEditAppointment}>
              <Title>Modifică programare</Title>
            </TouchableOpacity>
            <Line />
          </>
        ) : null}

        {onRejectAppointment !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onRejectAppointment}>
              <Title style={{ color: colors.RADICAL_RED }}>Refuză programare</Title>
            </TouchableOpacity>
            <Line />
          </>
        ) : null}

        {onBookAgain !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onBookAgain}>
              <Title>Programează din nou</Title>
            </TouchableOpacity>
            <Line />
          </>
        ) : null}

        {onCancelByClient !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onCancelByClient}>
              <Title style={{ color: colors.RADICAL_RED }}>Marchează anulat de client</Title>
            </TouchableOpacity>
            <Line />
          </>
        ) : null}

        {onMarkAsNoShow !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onMarkAsNoShow}>
              <Title style={{ color: colors.OUTRAGEOUS_ORANGE }}>Marchează client neprezentat</Title>
            </TouchableOpacity>
          </>
        ) : null}

        {onDeleteAppointment !== undefined ? (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDeleteAppointment}>
              <Title style={{ color: colors.RADICAL_RED }}>Anulează programare</Title>
            </TouchableOpacity>
          </>
        ) : null}
      </SafeAreaView>
    </ModalOverlay>
  );
};

export default BookingOptionsMenu;

import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-around',
    backgroundColor: colors.WHITE,
  },
  infoContainer: {
    flex: 1,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: colors.COMET,
  },
  coupon: {
    alignItems: 'center',
    paddingTop: 16,
  },
  couponText: {
    paddingLeft: 8,
    flex: 1,
  },
});

import { CheckoutCashRegisterDriverDetails, CheckoutSavedCashRegister } from '@mero/api-sdk';
import * as MeroApi from '@mero/api-sdk';
import { CheckoutCashRegisterDriverId } from '@mero/api-sdk/dist/checkout/cashRegisters/checkoutCashRegisterDriverId';
import { CheckoutCashRegisterId } from '@mero/api-sdk/dist/checkout/cashRegisters/checkoutCashRegisterId';
import { CheckoutCompanyId } from '@mero/api-sdk/dist/checkout/companies';
import {
  Body,
  Button,
  colors,
  ConfirmBox,
  H1,
  ModalOverlay,
  Select,
  Spacer,
  TextInput,
  Text,
  styles as meroStyles,
  Row,
  Column,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FlashyLabel } from '../../../../../components/FlashyLabel';
import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';

import { meroApi } from '../../../../../contexts/AuthContext';
import log from '../../../../../utils/log';

type Props = {
  activeCashRegister?: CheckoutSavedCashRegister;
  drivers: CheckoutCashRegisterDriverDetails[];
  companyId: CheckoutCompanyId;
  onSuccess: () => void;
  onCancel: () => void;
  page: MeroApi.pages.PageDetails;
};

const CUSTOM_VATS = 5;
const CUSTOM_VAT_DRIVERS = ['fiscal-net'];

type CustomVat = 'vatGroup0' | 'vatGroup5' | 'vatGroup9' | 'vatGroup19' | 'vatGroupNoVat';

const getLetters = (count: number, isUpperCase = true): string[] => {
  const startCharCode = 'a'.charCodeAt(0); // ASCII code for 'a' is 97
  return Array.from({ length: count }, (_, i) =>
    isUpperCase ? String.fromCharCode(startCharCode + i).toLocaleUpperCase() : String.fromCharCode(startCharCode + i),
  );
};

const EditCashRegisterDialog: React.FC<Props> = ({
  onSuccess,
  onCancel,
  drivers,
  activeCashRegister,
  companyId,
  page,
}) => {
  const { t } = useTranslation('checkout');

  const [showErrors, setShowErrors] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedCashRegister, setSelectedCashRegister] = React.useState<
    { _id?: CheckoutCashRegisterId; name?: string; driverId: CheckoutCashRegisterDriverId } | undefined
  >(
    activeCashRegister
      ? {
          _id: activeCashRegister._id,
          name: activeCashRegister.name,
          driverId: activeCashRegister.driver._id,
        }
      : undefined,
  );
  const supportsCustomVat = selectedCashRegister && CUSTOM_VAT_DRIVERS.includes(selectedCashRegister.driverId);

  const customVats = React.useMemo(
    () => [
      {
        value: 'vatGroup0',
        label: t('vatGroup0'),
      } as const,
      {
        value: 'vatGroup5',
        label: t('vatGroup5'),
      } as const,
      {
        value: 'vatGroup9',
        label: t('vatGroup9'),
      } as const,
      {
        value: 'vatGroup19',
        label: t('vatGroup19'),
      } as const,
      {
        value: 'vatGroupNoVat',
        label: t('vatGroupNoVat'),
      } as const,
    ],
    [],
  );

  const selects = React.useMemo(() => {
    return getLetters(CUSTOM_VATS).map((letter, index) => ({
      label: t(`customVatValue`, { letter, index: index + 1 }),
      value: index + 1,
    }));
  }, []);

  const getSelectedVat = React.useCallback(() => {
    if (activeCashRegister?.driver.config?.fiscalNet) {
      return Object.entries(activeCashRegister.driver.config.fiscalNet).reduce((acc, [key, value]) => {
        if (value) {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {} as Record<CustomVat, number | undefined>);
    }

    return {
      vatGroup0: undefined,
      vatGroup5: undefined,
      vatGroup9: undefined,
      vatGroup19: undefined,
      vatGroupNoVat: undefined,
    };
  }, []);

  const [selectedVat, setSelectedVat] = React.useState<Record<CustomVat, number | undefined>>(getSelectedVat());
  const [showCustomVat, setShowCustomVat] = React.useState(
    Object.values(selectedVat).some((value) => value !== undefined),
  );

  const cancel = () => {
    onCancel();
  };

  const formattedDrivers = React.useMemo(
    () => drivers.map((driver) => ({ label: driver.name, value: driver.driverId })),
    [drivers],
  );

  const onSave = async () => {
    try {
      if (!selectedCashRegister?.driverId) {
        setShowErrors(true);
        return;
      }
      setIsSaving(true);
      if (activeCashRegister?._id) {
        await meroApi.checkout.updateCashRegister({
          pageId: page._id,
          companyId,
          cashRegisterId: activeCashRegister._id,
          name: selectedCashRegister.name ?? '',
          driverId: selectedCashRegister.driverId,
          driverConfig: supportsCustomVat ? { fiscalNet: selectedVat } : undefined,
        });
      } else {
        await meroApi.checkout.registerCashRegister({
          pageId: page._id,
          companyId,
          name: selectedCashRegister.name ?? '',
          driverId: selectedCashRegister.driverId,
          driverConfig: supportsCustomVat ? { fiscalNet: selectedVat } : undefined,
        });
        await meroApi.checkout.updateCompanyEmitReceiptStatus({
          pageId: page._id,
          companyId,
          emitReceiptStatus: {
            type: 'Allowed',
          },
        });
      }
      onSuccess();
    } catch (error) {
      log.error('Failed to save', JSON.stringify(error));
    } finally {
      setIsSaving(false);
    }
  };

  const onDelete = async () => {
    try {
      if (activeCashRegister?._id) {
        setIsSaving(true);
        await meroApi.checkout.deleteCashRegister({
          pageId: page._id,
          companyId,
          cashRegisterId: activeCashRegister._id,
        });
        await meroApi.checkout.updateCompanyEmitReceiptStatus({
          pageId: page._id,
          companyId,
          emitReceiptStatus: {
            type: 'Disabled',
          },
        });
        onSuccess();
      }
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
    } finally {
      setIsSaving(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('receiptDialogConfirmButton'),
    onPress: !selectedCashRegister || isSaving ? undefined : onSave,
    flex: 15,
  };

  const getCustomVatValue = (index: number) => {
    const vat = Object.entries(selectedVat).find(([, value]) => value === index);
    return vat ? vat[0] : undefined;
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('receiptDialogTitle')}
          icon="info"
          canClose={!isSaving}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
          style={{
            maxWidth: 400,
          }}
        >
          <H1>{t('receiptDialogTitle')}</H1>
          <Spacer size="4" />
          <FlashyLabel type="info" text={t('receiptDialogLabel')} />
          <Spacer size="8" />
          <Body>{t('receiptDialogDescription')}</Body>
          <Spacer size="16" />
          <InputWithLabel label={t('cashRegisterName')}>
            <TextInput
              placeholder={t('cashRegisterNamePlaceholder')}
              value={selectedCashRegister?.name ?? ''}
              onChange={(value) => {
                if (selectedCashRegister) {
                  setSelectedCashRegister({ ...selectedCashRegister, name: value });
                } else {
                  setSelectedCashRegister({
                    name: value,
                    driverId: '' as CheckoutCashRegisterDriverId,
                  });
                }
              }}
            />
          </InputWithLabel>
          <Spacer size={16} />
          <InputWithLabel
            label={t('selectPrinter')}
            isError={showErrors && !selectedCashRegister?.driverId}
            errorText={t('selectPrinterError')}
          >
            <Select
              items={formattedDrivers}
              placeholder={t('selectPrinterPlaceholder')}
              value={selectedCashRegister?.driverId}
              onChange={(value) => {
                if (selectedCashRegister && value) {
                  setSelectedCashRegister({ ...selectedCashRegister, driverId: value });
                } else if (value) {
                  setSelectedCashRegister({ driverId: value });
                }
              }}
            />
          </InputWithLabel>
          {!showCustomVat && supportsCustomVat ? (
            <>
              <Spacer size={16} />
              <Text
                link
                color={colors.DARK_BLUE}
                onPress={() => setShowCustomVat(true)}
                style={meroStyles.text.semibold}
              >
                {t('addCustomVat')}
              </Text>
            </>
          ) : null}
          {showCustomVat && supportsCustomVat ? (
            <Row flexWrap="wrap" justifyContent="space-between">
              {selects.map((select, index) => (
                <Column
                  key={`${select}_${index}`}
                  style={{
                    width: '50%',
                    paddingTop: 16,
                    ...(index % 2 === 0 ? { paddingRight: 4 } : { paddingLeft: 4 }),
                  }}
                >
                  <InputWithLabel label={select.label}>
                    <Select
                      items={customVats}
                      placeholder={t('selectCustomVatPlaceholder')}
                      value={getCustomVatValue(index + 1)}
                      onChange={(value) =>
                        setSelectedVat((prev) => {
                          const prevValue = Object.entries(prev).find(([, v]) => v === index + 1);
                          if (prevValue) {
                            return { ...prev, [prevValue[0]]: undefined, [value]: index + 1 };
                          }

                          return { ...prev, [value]: index + 1 };
                        })
                      }
                    />
                  </InputWithLabel>
                </Column>
              ))}
            </Row>
          ) : null}

          {activeCashRegister && (
            <>
              <Spacer size={32} />
              <Button
                text={t('receiptDialogDisableButton')}
                backgroundColor={colors.WHITE}
                color={colors.RADICAL_RED}
                onPress={onDelete}
              />
            </>
          )}
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default EditCashRegisterDialog;

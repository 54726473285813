import {
  CheckoutReportBySaleOwnerAndProductSortType,
  CheckoutSaleOwnerProductSalesReportPage,
  DateInterval,
} from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { UserId } from '@mero/api-sdk/dist/users';
import { colors, Column, SmallBody, styles as meroStyles } from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import { MeroUnits, SortDirection } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import FormCard from '@mero/components/lib/components/FormCard';
import Body from '@mero/components/lib/components/Text/Body';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log, { logCatch } from '../../../../../../utils/log';
import { scaledToString } from '../../../../../../utils/scaled';
import { nameGenerator } from '../../../../../../utils/string';
import Table, { Column as TableColumn } from './Table';

export type Props = {
  pageId: PageId;
  dateInterval: DateInterval;
  saleOwnerId: UserId;
  availability: 'consumable' | 'offlineRetail';
  updateSort: (
    sort:
      | {
          by: CheckoutReportBySaleOwnerAndProductSortType;
          dir: SortDirection;
        }
      | undefined,
  ) => void;
};

const ProProducts: React.FC<Props> = ({ pageId, dateInterval, saleOwnerId, updateSort, availability }) => {
  const { t } = useTranslation('reports');
  const [reports, setReports] = React.useState<CheckoutSaleOwnerProductSalesReportPage<MeroUnits.Any>['items']>([]);
  const [nextPage, setNextPage] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, setSort] = React.useState<
    | {
        by: CheckoutReportBySaleOwnerAndProductSortType;
        dir: SortDirection;
      }
    | undefined
  >(undefined);
  const lastRequest = React.useRef<symbol>(Symbol());
  const loadingNextPage = React.useRef('');

  const getData = async (page?: string) => {
    setIsLoading(true);
    const request = Symbol();
    lastRequest.current = request;
    try {
      const { data: newReports, next: nextPage } = await meroApi.checkoutReports
        .getSalesByProductAndOwnerReport({
          pageId,
          unit: 'RON',
          interval: dateInterval,
          saleOwnerId,
          sort,
          page,
          availability,
        })
        .catch(logCatch('getSalesByProductAndOwnerReport'));

      if (request === lastRequest.current) {
        setNextPage(nextPage);
        setReports((prev) => (typeof page === 'string' ? [...prev, ...newReports.items] : newReports.items));
      }
    } catch (error) {
      log.error('Failed to get data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortBy = async (field: string, direction: SortDirection) => {
    switch (field) {
      case 'date':
        setSort({ by: 'finished-at', dir: direction });
        break;
      case 'productTotal':
        setSort({ by: 'product-total', dir: direction });
        break;
      case 'membership':
        setSort({ by: 'membership-consumption', dir: direction });
        break;
      case 'protocol':
        setSort({ by: 'protocol', dir: direction });
        break;
      case 'total':
        setSort({ by: 'total', dir: direction });
        break;
      default:
        setSort(undefined);
        break;
    }
  };

  const onLoadMore = () => {
    if (nextPage && loadingNextPage.current !== nextPage) {
      loadingNextPage.current = nextPage;
      getData(nextPage);
    }
  };

  React.useEffect(() => {
    getData();
  }, [saleOwnerId, JSON.stringify(sort), JSON.stringify(dateInterval), availability]);

  React.useEffect(() => {
    updateSort(sort);
  }, [sort]);

  const { columns, data, fixedRows } = React.useMemo(() => {
    const data = reports.map((report) => ({
      date: DateTime.fromJSDate(report.finishedAt).toFormat('dd.MM.yyyy&&HH:mm'),
      client: `${nameGenerator(report.client ?? {}, t('noName'))}&&${
        report.client?.phone ? formatPhoneNumber(report.client.phone) : ''
      }`,
      product: report.product.name,
      quantity: scaledToString(report.quantity),
      gross: `${scaledToString(report.totals.gross.amount)} ${t(report.totals.gross.unit)}`,
      discount: `${scaledToString(report.totals.discount.amount)} ${t(report.totals.discount.unit)}`,
      net: `${scaledToString(report.totals.net.amount)} ${t(report.totals.net.unit)}`,
      total: `${scaledToString(report.totals.total.amount)} ${t(report.totals.total.unit)}`,
    }));

    const fixedRows: typeof data = [];

    const columns: TableColumn<(typeof data)[number]>[] = [
      {
        field: 'date',
        headerName: t('proProductDateName'),
        width: '10%',
        sortable: true,
        CustomComponent: ({ date }) => {
          const [dateStr, timeStr] = date.split('&&');
          return (
            <Column>
              <SmallBody>{dateStr}</SmallBody>
              <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{timeStr}</SmallBody>
            </Column>
          );
        },
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('date', direction),
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'client',
        headerName: t('proProductClientName'),
        width: '25%',
        sortable: false,
        CustomComponent: ({ client }) => {
          const [name, phone] = client.split('&&');
          return (
            <Column>
              <SmallBody>{name}</SmallBody>
              {phone ? <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{phone}</SmallBody> : null}
            </Column>
          );
        },
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'product',
        headerName: t('proProductName'),
        width: '35%',
        sortable: false,
        style: {
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'quantity',
        headerName: t('proProductQuantity'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('quantity', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
      {
        field: 'total',
        headerName: t('proProductTotal'),
        width: '15%',
        sortable: true,
        sortingMode: 'server',
        sortComparator: (direction: SortDirection) => () => sortBy('total', direction),
        style: {
          header: {
            textAlign: 'right',
          },
          cell: {
            textAlign: 'right',
          },
          fixedCell: {
            fontFamily: 'open-sans-semibold',
          },
        },
      },
    ];

    return {
      columns,
      data,
      fixedRows,
    };
  }, [reports]);

  return (
    <FormCard rounded dropShaddow paddings="none" style={{ paddingTop: 4, zIndex: -1 }}>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        loadMore={onLoadMore}
        defaultSortBy="date"
        defaultSortDir="ASC"
        style={{
          row: {
            alignItems: 'center',
          },
        }}
        ListEmptyComponent={
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 16 }}>
            <Body style={[meroStyles.text.semibold]}>{t('proProductsEmpty')}</Body>
          </Column>
        }
      />
    </FormCard>
  );
};

export default ProProducts;

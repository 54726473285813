import { ImageServiceRef, WorkerId } from '@mero/api-sdk';
import { isDefined } from '@mero/api-sdk';
import { MergedService, mergeServices } from '@mero/api-sdk/dist/services';
import { ServiceId } from '@mero/api-sdk/dist/services';
import {
  colors,
  Icon,
  Spacer,
  Body,
  Row,
  FormCard,
  styles as meroStyles,
  SmallBody,
  Button,
  SafeAreaView,
  H1,
  useShowError,
  Select,
  useToast,
  formatPriceText,
  formatDurationInMinutes,
  MeroHeader,
  Column,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, Image, View } from 'react-native';

import { ServicesGroup } from '../../../../../../components/GroupedServicesList';
import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SelectServiceScreenView from '../../../../../../components/SelectServiceScreen';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../../contexts/CurrentBusiness';
import { SelectedImageContext } from '../../../../../../contexts/SelectedImageContext';
import { PageGallerySettingsStackParamList } from '../../../../../../types';
import log from '../../../../../../utils/log';
import { styles } from './SelectedImageMenu.styles';

export type Props = StackScreenProps<PageGallerySettingsStackParamList, 'AttachedServices'>;

const AttachedServicesScreen: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('gallery');
  const showError = useShowError();
  const toast = useToast();
  const { isPhone } = useMediaQueries();
  const goBack = useGoBack();

  const scrollViewRef = React.useRef<ScrollView>(null);

  const imageId = route.params.imageId;

  const [pageState, { getPageWorkersAndServices, reloadAsync }] = CurrentBusinessContext.useContext();
  const [, { updateImageServices }] = SelectedImageContext.useContext();

  const [imageUri, setImageUri] = React.useState<string | null>(null);
  const [servicesRefs, setServicesRefs] = React.useState<ImageServiceRef[]>([]);
  const [groupedServices, setGroupedServices] = React.useState<ServicesGroup[]>([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [showAllServices, setShowAllServices] = React.useState(false);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setIsLoading(false);
      getPageWorkersAndServices(pageState.page.details._id);
      getServices();
      const image = pageState.page.details.images.find((image) => image._id === imageId);
      if (!image) {
        return;
      }
      setImageUri(image?.medium ?? null);
      if (!image.services?.length) {
        return;
      }
      setServicesRefs(image.services);
    }
  }, [pageState.type]);

  const scrollToEnd = () =>
    setTimeout(() => {
      scrollViewRef.current?.scrollToEnd({ animated: true });
    }, 200);

  const save = async () => {
    if (pageState.type !== 'Loaded' || !servicesRefs) {
      return;
    }
    setIsLoading(true);
    try {
      await meroApi.pages.updateImageServices({
        pageId: pageState.page.details._id,
        imageId,
        services: servicesRefs,
      });
      updateImageServices({ servicesRefs });
      reloadAsync();
      toast.show({ type: 'success', text: t('changesSaved') });
    } catch (error) {
      log.error('Error while updating services', error);
      showError(error, t('saveChangesError'));
    }
    goBack();
  };

  const getServices = React.useCallback(async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(pageState.page.details._id);

      const services = groupedServices.grouped
        .map((group) => ({
          group: group.group,
          services: group.services
            .map((service) => mergeServices(service, pageState.page.details.services))
            .filter(isDefined),
        }))
        .filter((group) => group.services.length > 0);

      setGroupedServices(
        groupedServices.others.length > 0
          ? [
              ...services,
              {
                group: {
                  name: 'Alte Servicii',
                },
                services: groupedServices.others
                  .map((service) => mergeServices(service, pageState.page.details.services))
                  .filter(isDefined),
              },
            ]
          : services,
      );
    } catch (e) {
      showError(e);
    }
  }, [pageState.type]);

  const getSelectWorkersForService = (serviceId: ServiceId) => {
    if (pageState.type !== 'Loaded') {
      return [];
    }

    const selectOptions = pageState.page.workers
      .filter((worker) => worker.services.some((s) => s._id === serviceId))
      .map(({ _id, user: { firstname, lastname } }) => ({
        label: `${firstname} ${lastname}`,
        value: _id,
      }));
    return selectOptions;
  };

  const getUnselectedServices = () => {
    const selectedServiceIds = servicesRefs.map((s) => s.serviceId);

    return groupedServices
      .map((group) => {
        return {
          ...group,
          services: group.services.filter((service) => !selectedServiceIds.includes(service._id)),
        };
      })
      .filter((group) => group.services.length > 0);
  };

  const removeServiceRef = (serviceId: ServiceId) => {
    setServicesRefs((prev) => [...prev.filter((s) => s.serviceId !== serviceId)]);
  };

  const updateWorkerInServiceRef = (serviceId: ServiceId, workerId: WorkerId) => {
    setServicesRefs((prev) => {
      const index = prev.findIndex((s) => s.serviceId === serviceId);

      return [...prev.slice(0, index), { ...prev[index], workerId }, ...prev.slice(index + 1)];
    });
  };

  const addServiceRef = (serviceId: ServiceId) => {
    if (servicesRefs.find((ref) => ref.serviceId === serviceId)) {
      return setShowAllServices(false);
    }
    setServicesRefs((prev) => [...prev, { serviceId, workerId: undefined }]);
    setShowAllServices(false);
    scrollToEnd();
  };

  if (pageState.type !== 'Loaded') {
    return;
  }

  if (showAllServices) {
    return (
      <SelectServiceScreenView
        services={getUnselectedServices()}
        onBackPressed={() => setShowAllServices(false)}
        onServiceSelected={(service: MergedService) => addServiceRef(service._id)}
      />
    );
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER, flex: 1 }}>
      <MeroHeader title={t('imageAttachedServices')} canGoBack={true} onBack={goBack} />
      <ScrollView ref={scrollViewRef} style={{ paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
        <Spacer size={24} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('imageAttachedServices')}</H1>

        <Spacer size={16} />
        <Row>
          <Column style={{ flex: 1, marginRight: 16 }}>
            {isPhone ? (
              <SmallBody style={{ paddingHorizontal: 8, fontWeight: '500' }}>
                {t('imageAttachedServicesDescription')}
              </SmallBody>
            ) : (
              <Body style={{ paddingHorizontal: 8, fontWeight: '500' }}>{t('imageAttachedServicesDescription')}</Body>
            )}
          </Column>

          {imageUri && (
            <Column>
              <Image source={{ uri: imageUri }} style={{ width: 70, height: 70, borderRadius: 4 }} />
            </Column>
          )}
        </Row>
        <Spacer size={24} />

        {servicesRefs.map((serviceRef) => {
          const { serviceId, workerId } = serviceRef;
          const service = pageState.page.details.services.find((service) => service._id === serviceId);

          if (!service) {
            return undefined;
          }
          return (
            <View key={serviceId}>
              <Column
                style={{
                  padding: 16,
                  borderWidth: 1,
                  borderColor: colors.GEYSER,
                  borderRadius: 6,
                  backgroundColor: colors.WHITE,
                }}
              >
                <Row style={{ justifyContent: 'space-between' }}>
                  <Column>
                    <Body style={{ fontWeight: '600' }}>{service?.name}</Body>
                    <Spacer size="4" />
                    <SmallBody style={[meroStyles.text.hint]}>
                      {formatPriceText(service.price) && `${formatPriceText(service.price)}, `}
                      {formatDurationInMinutes(service.durationInMinutes)}
                    </SmallBody>
                  </Column>
                  <TouchableOpacity onPress={() => removeServiceRef(serviceId)} style={{ padding: 8 }}>
                    <Icon type="close" color={colors.DARK_BLUE}></Icon>
                  </TouchableOpacity>
                </Row>

                <Spacer size={16} />
                <Select
                  placeholder={t('addWorker')}
                  items={getSelectWorkersForService(serviceId)}
                  value={workerId}
                  onChange={(workerId) => updateWorkerInServiceRef(serviceId, workerId)}
                />
              </Column>
              <Spacer size="16" />
            </View>
          );
        })}

        {servicesRefs.length !== 0 && <Spacer size="8" />}
        {getUnselectedServices().length !== 0 && isPhone && (
          <Button
            size="medium"
            text={`+ ${t('addService')}`}
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            disabled={isLoading}
            onClick={() => setShowAllServices(true)}
          />
        )}
        {getUnselectedServices().length !== 0 && !isPhone && (
          <Button
            size="medium"
            text={`+ ${t('addService')}`}
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            disabled={isLoading}
            expand={false}
            containerStyle={{ alignSelf: 'center' }}
            onClick={() => setShowAllServices(true)}
          />
        )}
        <Spacer size={144} />
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              disabled={isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={save}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default AttachedServicesScreen;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import NotificationsEditTemplateScreen from '../screens/Authorized/MenuScreen/screens/PageNotificationsSettings/NotificationsEditTemplateScreen';
import NotificationsMessagesFormatScreen from '../screens/Authorized/MenuScreen/screens/PageNotificationsSettings/NotificationsMessagesFormatScreen';
import PageNotificationsSettings from '../screens/Authorized/MenuScreen/screens/PageNotificationsSettings/PageNotificationsSettingsScreen';
import SelectManualDeliveryMethodScreen from '../screens/Authorized/MenuScreen/screens/PageNotificationsSettings/SelectManualDeliveryMethodScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { PageNotificationsContext } from '../contexts/PageNotificationsContext';
import { NotificationsSettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<NotificationsSettingsStackParamList>();

function NotificationsSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('notifications');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <PageNotificationsContext.Provider>
      <Stack.Navigator
        initialRouteName="NotificationsSettings"
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen
          name="NotificationsSettings"
          component={PageNotificationsSettings}
          options={{ title: t('notifications'), cardStyle: modalStyle }}
        />
        <Stack.Screen
          name="NotificationsSettingsManualMethod"
          component={SelectManualDeliveryMethodScreen}
          options={{ title: t('deliveryMethod'), cardStyle: modalStyle }}
        />
        <Stack.Screen
          name="NotificationsMessagesFormat"
          component={NotificationsMessagesFormatScreen}
          options={{ title: t('deliveryMethod'), cardStyle: modalStyle }}
        />
        <Stack.Screen
          name="NotificationsEditTemplate"
          component={NotificationsEditTemplateScreen}
          options={{ title: t('deliveryMethod'), cardStyle: modalStyle }}
        />
      </Stack.Navigator>
    </PageNotificationsContext.Provider>
  );
}

export default NotificationsSettingsStackNavigator;

/**
 * Application config
 */
const config = {
  logLevel: 'error',
  /**
   * Old PRO website base url (angular)
   */
  marketPlaceUrl: 'https://mero.ro',
  proUrl: 'https://pro1.mero.ro',
  linkingPrefixes: ['https://pro.mero.ro'],
  apiBaseUrl: 'https://pro.mero.ro/api/v2.0',
  socketBaseUrl: 'https://mero.ro',
  checkoutUrl: 'https://secure.payu.ro/order/lu.php',
  apiClientId: 'mero-pro-app',
  apiSecret: '5ff746ba954f8f241d731400',
  facebookAppId: '1428825953830854',
  meroSmsUrl: 'https://bit.ly/mero-sms',
  tosLinkUrl: 'https://pro.mero.ro/termeni-si-conditii?embed=1',
  privacyLinkUrl: 'https://pro.mero.ro/confidentialitate?embed=1',
  appStoreLink: 'https://apps.apple.com/app/id1554148874?action=write-review',
  stores: {
    ios: 'id1554148874',
    android: 'ro.mero.pro.app',
  },
  sentry: {
    dsn: 'https://7c3ae7ba6f314ae49f3d47004519a42e@o524911.ingest.sentry.io/5642084',
    debug: false,
    environment: 'production',
  },
  google: {
    analytics: 'G-9HG1N45XMQ',
    iosClientId: '986672784441-2ha078btfnh6uvb347993tl1g6fju9kp.apps.googleusercontent.com',
    androidClientId: '986672784441-3p3tiho7cr5on9j4b4jcm8qjbnnik6e5.apps.googleusercontent.com',
    clientId: '986672784441-i7rfd8j0b23hu8jdrlf17jaa5qv2i4fd.apps.googleusercontent.com',
    iosStandaloneAppClientId: '986672784441-ogjeup8ovgmua89fk4grfnnlfm51msqh.apps.googleusercontent.com',
    androidStandaloneAppClientId: '986672784441-51uk1o9h5gfnmalo95gdu64n5eu4d8it.apps.googleusercontent.com',
    recaptchaSiteKey: '6LdY_aUfAAAAAKpqWFjpAyeHywhfSFmzX9L2pDap',
    maps: {
      web: 'AIzaSyAKLL9iaNf1Dp9-DuDIoNfIUmTTZi0vvFg',
      mobile: 'AIzaSyAO_jVmWvRHUNaFix8kcXNuKw2m4JgA9MU',
    },
  },
  intercom: {
    // Make sure same value is used in app.{env}.json
    appId: 'hn9ltyr9',
  },
  contactUsUrl: 'https://asistenta.mero.ro/ro/articles/4942975-contacteaza-ne',
  giftCardUrl: 'https://asistenta.mero.ro/ro/articles/5756305-carduri-cadou',
  pageSubscriptionUrl: (pageId: string, token: string): string =>
    `https://pro.mero.ro/drawer/page/subscription?hideLayout=true&pageId=${encodeURIComponent(
      pageId,
    )}&nvb=${encodeURIComponent(token)}`,
  startTrialCallLink: 'https://bit.ly/apel-mero-pro',
  amplitudeApiKey: '1ad2493e11fd03f15e55b1978857c9b2',
  appDownloadUrl: 'https://pro.mero.ro/download-app',
  stripe: {
    mero_pro_apps_srl: 'pk_live_H2Ncc40Lej60X9AF20nBHEXH00u9fsgcgq',
    mero_programari_srl:
      'pk_live_51LwPKzCh4Yz209LxX0hPozk3LMEEURbR8P6st7A6UH6PVAZ4KCFGXUS2QGZFQjUJQyzhsqpMS1ihbaHwBg6yWQiK001Wxmr1cR',
  },
  stripeRedirectUri: {
    giftCards: 'https://pro.mero.ro/page/current/gift-cards',
    onlinePayments: 'https://pro.mero.ro/page/current/online-payments',
  },
};

export default config;

import { Button, Spacer, ModalOverlay, Row, Title, Column, colors, HSpacer } from '@mero/components';
import { DateString } from '@mero/shared-sdk/dist/common';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateTime, Zone } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import Select from '@mero/components/lib/components/Select';

import { LocalDateObject } from '../../contexts/CalendarContext';
import DayTimeSelect2, { DayTime } from '../DayTimeSelect2';
import Calendar from '../MeroCalendar';
import { styles } from './styles';

export const generateYears = (payload?: { start?: number; end?: number }) => {
  const startYear = payload?.start ?? 1900;
  const endYear = payload?.end ?? new Date().getFullYear();

  return Array.from({ length: endYear - startYear + 1 }, (_, i) => endYear - i);
};

export type Props = {
  readonly mode?: 'datetime' | 'date';
  readonly withYear?: boolean;
  /**
   * Selected date
   */
  readonly selected?: Date;

  readonly minDate?: Date;

  readonly maxDate?: Date;

  readonly timeZone: Zone;

  /**
   * Time select in minutes
   */
  readonly timeStepMinutes?: 5 | 10 | 15 | 20 | 30 | 60; // TODO: export type from DayTimeSelect

  readonly onDateSelected?: (selected: Date) => void;

  readonly onClosePress?: () => void;
};

const SelectDateTimeModal: React.FC<Props> = ({
  mode = 'datetime',
  withYear = false,
  selected,
  minDate,
  maxDate,
  timeZone,
  timeStepMinutes = 5,
  onDateSelected,
  onClosePress,
}: Props) => {
  const [date, setDate] = React.useState(
    DateTime.fromJSDate(selected ?? new Date(), { zone: timeZone }).set({ second: 0, millisecond: 0 }),
  );
  const minDateObject: LocalDateObject | undefined = React.useMemo(() => {
    if (minDate === undefined) {
      return undefined;
    }
    return DateTime.fromJSDate(minDate, { zone: timeZone });
  }, [minDate, timeZone]);

  const maxDateObject: LocalDateObject | undefined = React.useMemo(() => {
    if (maxDate === undefined) {
      return undefined;
    }
    return DateTime.fromJSDate(maxDate, { zone: timeZone });
  }, [maxDate, timeZone]);

  const dateString = React.useMemo(
    (): DateString =>
      pipe(
        date.toFormat('yyyy-MM-dd'),
        DateString.decode,
        E.fold(() => {
          throw new Error(`Failed parse DateString from DateTime (${date.toISO()}), how is that even possible?`);
        }, identity),
      ),
    [date],
  );

  const saveCallback = React.useCallback(() => {
    if (onDateSelected !== undefined && dateString !== undefined) {
      onDateSelected(date.set({ second: 0, millisecond: 0 }).toJSDate());
    }
  }, [onDateSelected, date]);

  const dateSelectedCallback = React.useCallback(
    (d: DateString) => {
      const day = DateTime.fromFormat(d, 'yyyy-MM-dd', { zone: timeZone });
      setDate(day.set({ hour: date.hour, minute: date.minute, second: 0, millisecond: 0 }));
    },
    [date, setDate, timeZone],
  );

  const dayTimeSelectedCallback = React.useCallback(
    ({ hour, minute }: DayTime) => {
      setDate(date.set({ hour: hour, minute: minute, second: 0, millisecond: 0 }));
    },
    [date, setDate],
  );

  const setYear = (value: number) => {
    setDate(date.set({ year: value }));
  };

  const years = React.useMemo(
    () =>
      withYear
        ? generateYears({ start: minDateObject?.year, end: maxDateObject?.year }).map((year) => ({
            value: year,
            label: year.toString(),
          }))
        : [],
    [withYear],
  );

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <View style={styles.content}>
        {withYear && <Select items={years} value={date.year} onChange={setYear} />}
        <Calendar
          selected={dateString}
          minDate={minDateObject}
          maxDate={maxDateObject}
          onDateSelected={dateSelectedCallback}
          style={{ minHeight: 370 }}
        />
        {mode === 'datetime' ? (
          <>
            <Spacer size="16" />
            <Row style={{ alignItems: 'center' }}>
              <Title>Ora: </Title>
              <Column style={{ flex: 1 }}>
                <DayTimeSelect2
                  value={{ hour: date.hour, minute: date.minute }}
                  step={timeStepMinutes}
                  onChange={dayTimeSelectedCallback}
                />
              </Column>
            </Row>
          </>
        ) : null}
        <Spacer size="16" />
        <Row>
          <Column style={{ flex: 1 }}>
            <Button
              size="medium"
              text="Anulează"
              onClick={onClosePress}
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
            />
          </Column>
          <HSpacer left={8} right={8} />
          <Column style={{ flex: 1 }}>
            <Button size="medium" text="Salvează" onClick={saveCallback} />
          </Column>
        </Row>
      </View>
    </ModalOverlay>
  );
};

export default SelectDateTimeModal;

import { BlockedTimeReason } from '@mero/api-sdk/dist/calendar/blocked-time-reason';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import Body from '@mero/components/lib/components/Text/Body';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { styles } from './styles';

export type Props = {
  /**
   * Selected BlockedTimeReason
   */
  readonly reason?: BlockedTimeReason;
  readonly onDismiss: () => void;
  readonly onOptionSelected?: (newReason: BlockedTimeReason) => void;
};

const BlockedTimeReasonOptionsScreen: React.FC<Props> = ({
  reason,
  onDismiss,
  onOptionSelected,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('blockedTime');

  const selectCallback = (newReason: BlockedTimeReason) => () => {
    if (onOptionSelected) {
      onOptionSelected(newReason);
    }
  };

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={selectCallback({ type: 'break' })}>
            <SimpleListItem icon={reason?.type === 'break' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonBreak')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback({ type: 'dayOff' })}
          >
            <SimpleListItem icon={reason?.type === 'dayOff' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonDayOff')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback({ type: 'vacation' })}
          >
            <SimpleListItem icon={reason?.type === 'vacation' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonVacation')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback({ type: 'sickLeave' })}
          >
            <SimpleListItem icon={reason?.type === 'sickLeave' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonSickLeave')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback({ type: 'training' })}
          >
            <SimpleListItem icon={reason?.type === 'training' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonTraining')}</Body>
            </SimpleListItem>
          </TouchableOpacity>

          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={selectCallback({ type: 'custom', reason: reason?.type === 'custom' ? reason.reason : '' })}
          >
            <SimpleListItem icon={reason?.type === 'custom' ? 'checked' : 'unchecked'}>
              <Body>{t('reasonCustom')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default BlockedTimeReasonOptionsScreen;

import { Body, colors, Column, ConfirmBox, H1, SimpleListItem, Spacer, styles as meroStyles } from '@mero/components';
import { Platform } from 'expo-modules-core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

export type Props = {
  readonly onDismiss: () => void;
  readonly onConfirm: () => void;
};

const ConfirmCancelAutoCharge: React.FC<Props> = ({ onDismiss, onConfirm }: Props): React.ReactElement | null => {
  const { t } = useTranslation('subscription');
  const [confirmed, setConfirmed] = React.useState(false);
  const { isPhone } = useMediaQueries();

  const toggleConfirmedCallback = React.useCallback(() => {
    setConfirmed(!confirmed);
  }, [confirmed, setConfirmed]);

  const leftAction = {
    text: t('cancel'),
    onPress: onDismiss,
  };

  const rightAction = {
    text: t('confirmCancel'),
    onPress: !confirmed ? undefined : onConfirm,
  };

  return (
    <ModalOverlay style={{ flex: 1 }}>
      <Column
        //@ts-expect-error - web only
        style={
          isPhone && Platform.OS === 'web'
            ? { height: '100vh', justifyContent: 'center', alignItems: 'center' }
            : { flex: 1, justifyContent: 'center', alignItems: 'center' }
        }
      >
        <ConfirmBox
          type="info"
          icon="info"
          headerTitle={t('importantAction')}
          canClose={true}
          onClose={onDismiss}
          leftAction={leftAction}
          rightAction={rightAction}
        >
          <H1>{t('cancelAutoCharge')}</H1>
          <Spacer size="8" />
          <Body>{t('checkAndConfirm')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleConfirmedCallback}>
            <SimpleListItem
              icon={confirmed ? 'checked' : 'unchecked'}
              iconColor={colors.DARK_BLUE}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('cancelAutoChargeText')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </Column>
    </ModalOverlay>
  );
};

export default ConfirmCancelAutoCharge;

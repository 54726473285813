import {
  ApiError,
  AppointmentCancelledByWorkerNotificationType,
  AppointmentMadeByPageNotificationType,
  AppointmentModifiedNotificationType,
  AppointmentReminderNotificationType,
  CustomizedNotificationTemplateId,
  CustomizedNotificationType,
} from '@mero/api-sdk';
import {
  colors,
  Column,
  FormCard,
  H1,
  MeroHeader,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  Row,
  Button,
  SafeAreaView,
  Body,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image, ScrollView, TextInput, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { useShowError } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { NotificationsSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { styles } from '../WorkerScheduleSettingsScreen.styles';
import { MESSAGE_LENGTH } from './NotificationsMessagesFormatScreen';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<NotificationsSettingsStackParamList, 'NotificationsEditTemplate'>;

export const LABELS: Record<CustomizedNotificationType, string> = {
  [AppointmentMadeByPageNotificationType.value]: 'appointmentMadeByPageNotification',
  [AppointmentCancelledByWorkerNotificationType.value]: 'appointmentCancelledByWorkerNotification',
  [AppointmentModifiedNotificationType.value]: 'appointmentModifiedNotification',
  [AppointmentReminderNotificationType.value]: 'appointmentReminderNotification',
};

type TagPositions = Record<symbol, { start: number; end: number }>;

const TAGS = {
  [AppointmentMadeByPageNotificationType.value]: [
    {
      key: 'tagPage',
      value: '<%- pageName %>',
    },
    {
      key: 'tagStatus',
      value: '<%- status %>',
    },
    {
      key: 'tagDateTime',
      value: '<%- dateTime %>',
    },
    {
      key: 'tagPageAddress',
      value: '<%- pageAddress %>',
    },
    {
      key: 'tagWorkerFirstName',
      value: '<%- workerFirstName %>',
    },
  ],
  [AppointmentCancelledByWorkerNotificationType.value]: [
    {
      key: 'tagPage',
      value: '<%- pageName %>',
    },
    {
      key: 'tagStatus',
      value: '<%- status %>',
    },
    {
      key: 'tagDateTime',
      value: '<%- dateTime %>',
    },
    {
      key: 'tagWorkerFirstName',
      value: '<%- workerFirstName %>',
    },
  ],
  [AppointmentModifiedNotificationType.value]: [
    {
      key: 'tagPage',
      value: '<%- pageName %>',
    },
    {
      key: 'tagStatus',
      value: '<%- status %>',
    },
    {
      key: 'tagDateTimeChange',
      value: '<%- dateTimeChange %>',
    },
    {
      key: 'tagPageAddress',
      value: '<%- pageAddress %>',
    },
    {
      key: 'tagWorkerFirstName',
      value: '<%- workerFirstName %>',
    },
  ],
  [AppointmentReminderNotificationType.value]: [
    {
      key: 'tagPage',
      value: '<%- pageName %>',
    },
    {
      key: 'tagDateTime',
      value: '<%- dateTime %>',
    },
    {
      key: 'tagPageAddress',
      value: '<%- pageAddress %>',
    },
    {
      key: 'tagWorkerFirstName',
      value: '<%- workerFirstName %>',
    },
  ],
};

function insertAt(originalString: string, insertString: string, position: number): string {
  const start = originalString.substring(0, position);
  const end = originalString.substring(position);

  return start + insertString + end;
}

function checkIfTemplateIsValid(text: string, tags: string[]): boolean {
  // Regular expression to find all opening and closing tags
  const openingTagPattern = /\[\[/g;
  const closingTagPattern = /\]\]/g;

  // Extract all matches for opening and closing tags
  const openingMatches = [...text.matchAll(openingTagPattern)].map((match) => match.index);
  const closingMatches = [...text.matchAll(closingTagPattern)].map((match) => match.index);

  // Check if every opening tag has a corresponding closing tag before the next opening tag
  const isValid =
    openingMatches.length === closingMatches.length &&
    openingMatches.every((openingMatch, index) => {
      const closingMatch = closingMatches[index];

      return openingMatch !== undefined && closingMatch !== undefined && openingMatch < closingMatch;
    });

  if (!isValid) {
    return false;
  }

  // Extract valid values
  const validPattern = /\[\[([^\[\]]+?)\]\]/g;
  const validMatches = [...text.matchAll(validPattern)];
  const extractedValues = validMatches.map((match) => match[1]);

  return extractedValues.every((t) => tags.includes(t));
}

function removeSubstring(text: string, start: number, end: number): string {
  const part1 = text.slice(0, start);
  const part2 = text.slice(end);
  return part1 + part2;
}

function findAllIndexes(text: string, substring: string, start = 0, indexes: number[] = []): number[] {
  const index = text.indexOf(substring, start);

  if (index === -1) {
    return indexes;
  }

  return findAllIndexes(text, substring, index + 1, [...indexes, index]);
}

const NotificationsEditTemplateScreen: React.FC<Props> = ({ route, page }) => {
  const [, { openChat }] = IntercomContext.useContext();

  const { t } = useTranslation('notifications');
  const { isPhone } = useMediaQueries();

  const showError = useShowError();
  const goBack = useGoBack();

  const { type, id } = route.params;

  const tags = React.useMemo(
    () =>
      TAGS[type].map((tag) => ({
        ...tag,
        label: t(tag.key),
      })),
    [],
  );

  const inputRef = React.useRef<TextInput>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const block = React.useRef(false);

  const currentRequest = React.useRef(Symbol('request'));
  const tagPositions = React.useRef<TagPositions>({});
  const isTemplateDelete = React.useRef(false);
  const [messageTemplate, setMessageTemplate] = React.useState('');
  const [cursorPosition, setCursorPosition] = React.useState({ start: 0, end: 0 });
  const [forceRerender, setForceRerender] = React.useState(0);
  const [clones, setClones] = React.useState<CustomizedNotificationTemplateId[]>([]);

  const [preview, setPreview] = React.useState('');
  const [estimatedMessageLength, setEstimatedMessageLength] = React.useState(
    Math.floor(Math.random() * (260 - 100 + 1)) + 100,
  );

  const convertTags = (text: string, fromValue = true) => {
    return tags.reduce(
      (acc, tag) => acc.replaceAll(fromValue ? tag.value : t(tag.label), fromValue ? t(tag.label) : tag.value),
      text,
    );
  };

  const resetToDefault = async () => {
    try {
      const defaultTemplate = await meroApi.notifications.getDefaultNotificationTemplate({
        pageId: page.details._id,
        type,
      });
      onMessageTemplateChange(convertTags(defaultTemplate.template));
    } catch (error) {
      showError(error);
    }
  };

  const onMessageTemplateChange = (text: string) => {
    const positions = tags.reduce((acc: TagPositions, tag) => {
      const pos = findAllIndexes(text, tag.label);
      if (pos.length > 0) {
        return {
          ...acc,
          ...Object.fromEntries(pos.map((p) => [Symbol(tag.label), { start: p, end: p + tag.label.length }])),
        };
      }

      return {
        ...acc,
        [Symbol(tag.label)]: {
          start: 0,
          end: 0,
        },
      };
    }, {});

    setMessageTemplate(text);
    tagPositions.current = positions;
  };

  const getCustomTemplate = async (id: CustomizedNotificationTemplateId) => {
    try {
      const templates = await meroApi.notifications.getPageCustomizedNotificationTemplates(page.details._id);
      const template = templates.find((t) => t._id === id);
      if (!template) {
        throw new Error(t('templateDoesNotExist'));
      }

      onMessageTemplateChange(convertTags(template.template));
      setEstimatedMessageLength(template.estimatedMessageLength);
    } catch (error) {
      showError(error);
    }
  };

  const getDefaultTemplate = async () => {
    try {
      const [templates, custom] = await Promise.all([
        meroApi.notifications.getAllNotificationTemplatesForPage({ pageId: page.details._id }),
        meroApi.notifications.getPageCustomizedNotificationTemplates(page.details._id),
      ]);

      const template = templates.find((t) => t.type === Number(type));
      if (!template) {
        throw new Error(t('templateDoesNotExist'));
      }

      setPreview(template.text);
      onMessageTemplateChange(convertTags(template.template));
      setEstimatedMessageLength(template.estimatedMessageLength);
    } catch (error) {
      showError(error);
    }
  };

  const insertTag = (tag: (typeof tags)[number]) => {
    onMessageTemplateChange(insertAt(messageTemplate, tag.label, cursorPosition.start));
    const selection = cursorPosition;
    inputRef.current?.focus();
    window.setTimeout(
      () =>
        setCursorPosition({
          start: selection.start + tag.label.length,
          end: selection.end + tag.label.length,
        }),
      250,
    );
  };

  React.useEffect(() => {
    let timeout = 0;
    if (messageTemplate.length > 0) {
      const request = Symbol('request');
      currentRequest.current = request;
      timeout = window.setTimeout(async () => {
        try {
          const preview = await meroApi.notifications.createCustomizedNotificationTemplatePreview({
            pageId: page.details._id,
            type,
            template: convertTags(messageTemplate, false),
          });

          if (request === currentRequest.current) {
            setPreview(preview.text);
            setEstimatedMessageLength(preview.estimatedMessageLength);
          }
        } catch (error) {
          log.error('Failed to load preview', JSON.stringify(error));
          showError(error);
        }
      }, 250);
    }

    return () => window.clearTimeout(timeout);
  }, [messageTemplate]);

  React.useEffect(() => {
    if (id) {
      getCustomTemplate(id);
    } else {
      getDefaultTemplate();
    }
  }, [id]);

  const saveChanges = async () => {
    setIsLoading(true);
    block.current = true;
    try {
      if (id) {
        await meroApi.notifications
          .updateCustomizedNotificationTemplate({
            pageId: page.details._id,
            templateId: id,
            template: convertTags(messageTemplate, false),
          })
          .catch(async (e) => {
            if (e instanceof ApiError && e.code === 1) {
              await meroApi.notifications.deleteCustomizedNotificationTemplate({
                pageId: page.details._id,
                templateId: id,
              });
            } else {
              throw e;
            }
          });
      } else {
        await meroApi.notifications.createCustomizedNotificationTemplate({
          pageId: page.details._id,
          type,
          template: convertTags(messageTemplate, false),
        });
      }
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
      block.current = false;
    }
  };

  const tagContent = React.useMemo(() => tags.map((t) => t.label.replace('[[', '').replace(']]', '')), []);
  const isValidTemplate = React.useMemo(() => checkIfTemplateIsValid(messageTemplate, tagContent), [messageTemplate]);
  const notificationTag = React.useMemo(() => {
    if (!isValidTemplate) {
      return { color: colors.RADICAL_RED, text: t('invalidTemplate') };
    }

    if (estimatedMessageLength > MESSAGE_LENGTH) {
      return {
        color: colors.YELLOW_ORANGE,
        text: t('countCharacters', {
          total: estimatedMessageLength,
          limit: MESSAGE_LENGTH,
          smsCount: Math.ceil(estimatedMessageLength / MESSAGE_LENGTH),
        }),
      };
    }

    return {
      color: colors.SHAMROCK,
      text: t('countCharacters', {
        total: estimatedMessageLength,
        limit: MESSAGE_LENGTH,
        smsCount: Math.ceil(estimatedMessageLength / MESSAGE_LENGTH),
      }),
    };
  }, [isValidTemplate, estimatedMessageLength]);
  const unusedTags = React.useMemo(
    () => tags.filter((t) => !messageTemplate.includes(t.label)),
    [messageTemplate, tags],
  );

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader canGoBack onBack={goBack} title={t(LABELS[type])} />
        <KeyboardAvoidingView style={{ flex: 1 }}>
          <ScrollView style={{ paddingHorizontal: 16 }}>
            <Spacer size={16} />
            <H1>{t(LABELS[type])}</H1>
            <Spacer size={24} />
            <SmallBody style={[meroStyles.text.semibold, { color: colors.COMET }]}>
              {t('previewMessageLabel')}
            </SmallBody>
            <Spacer size={8} />
            <Column
              style={{
                backgroundColor: colors.ATHENS_GRAY,
                paddingHorizontal: 16,
                paddingVertical: 8,
                borderRadius: 24,
              }}
            >
              <Body>{preview}</Body>
            </Column>
            <Spacer size={24} />
            <FormCard dropShaddow rounded paddings="none" style={{ padding: 16 }}>
              <Title>{t('messageContent')}</Title>
              <Spacer size={8} />
              <Column>
                <Column
                  style={{
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    borderTopWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderColor: isValidTemplate ? colors.GEYSER : colors.RADICAL_RED,
                    padding: 12,
                  }}
                >
                  <TextInput
                    ref={inputRef}
                    style={[meroStyles.text.body]}
                    value={messageTemplate}
                    onChangeText={isTemplateDelete.current ? undefined : onMessageTemplateChange}
                    onSelectionChange={(event) => {
                      const { selection } = event.nativeEvent;
                      const tag = Object.getOwnPropertySymbols(tagPositions.current)
                        .map((s) => tagPositions.current[s])
                        .find(
                          (p) =>
                            (p.start < selection.start && p.end > selection.start) ||
                            (p.start < selection.end && p.end > selection.end) ||
                            (selection.start < p.start && selection.end > p.start) ||
                            (selection.start < p.end && selection.end > p.end),
                        );
                      if (tag) {
                        setCursorPosition({
                          start: selection.start > cursorPosition.start ? tag.end : tag.start,
                          end: selection.start > cursorPosition.start ? tag.end : tag.start,
                        });
                      } else {
                        setCursorPosition(event.nativeEvent.selection);
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.nativeEvent.key === 'Backspace') {
                        const tag = Object.getOwnPropertySymbols(tagPositions.current)
                          .map((s) => tagPositions.current[s])
                          .find((p) => p.start < cursorPosition.end - 1 && p.end > cursorPosition.end - 1);

                        if (tag) {
                          isTemplateDelete.current = true;
                          onMessageTemplateChange(removeSubstring(messageTemplate, tag.start, tag.end));
                          window.setTimeout(
                            () =>
                              setCursorPosition({
                                start: tag.start,
                                end: tag.start,
                              }),
                            50,
                          );
                          return;
                        }
                      }
                      isTemplateDelete.current = false;
                      setForceRerender((p) => p + 1);
                    }}
                    selection={cursorPosition}
                    multiline
                    numberOfLines={3}
                  />
                </Column>
                <Column
                  style={{
                    backgroundColor: notificationTag.color,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    paddingHorizontal: 12,
                    paddingVertical: 4,
                  }}
                >
                  <SmallBody
                    style={{
                      color: colors.WHITE,
                      fontSize: 12,
                      fontFamily: 'open-sans-semibold',
                    }}
                  >
                    {notificationTag.text.toLocaleUpperCase()}
                  </SmallBody>
                </Column>
              </Column>
              {unusedTags.length > 0 && (
                <>
                  <Row flexWrap="wrap" style={{ marginLeft: -8 }}>
                    {unusedTags.map((tag) => (
                      <TouchableOpacity
                        key={tag.key}
                        style={{
                          paddingHorizontal: 8,
                          paddingVertical: 4,
                          backgroundColor: colors.SKY_BLUE,
                          borderRadius: 4,
                          marginTop: 16,
                          marginLeft: 8,
                        }}
                        onPress={() => insertTag(tag)}
                      >
                        <SmallBody style={{ color: colors.DARK_BLUE }}>{tag.label}</SmallBody>
                      </TouchableOpacity>
                    ))}
                  </Row>
                </>
              )}
            </FormCard>
            <Spacer size={16} />
            <Row>
              <Image style={{ width: 18, height: 18 }} source={require(`../../../../../assets/images/info-icon.png`)} />
              <SmallBody style={{ paddingLeft: 8, color: colors.COMET }}>
                <Trans ns={'notifications'} t={t} i18nKey="messageContentDescription">
                  0
                  <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]} onPress={openChat}>
                    1
                  </SmallBody>
                </Trans>
              </SmallBody>
            </Row>
            <Spacer size={32} />
            <Button
              backgroundColor={colors.ALABASTER}
              color={colors.DARK_BLUE}
              size="medium"
              text={t('resetToDefault')}
              onClick={resetToDefault}
            />
            <Spacer size={96} />
          </ScrollView>
        </KeyboardAvoidingView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {isPhone ? (
              <Button disabled={block.current || isLoading} text={t('saveChanges')} onClick={saveChanges} />
            ) : (
              <Button
                disabled={block.current || isLoading}
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={t('saveChanges')}
                onClick={saveChanges}
              />
            )}
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
    </>
  );
};

export default pipe(NotificationsEditTemplateScreen, CurrentBusiness, Authorized);

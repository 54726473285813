import { MembershipPurchaseId } from '@mero/api-sdk';
import {
  colors,
  Column,
  FormCard,
  SmallBody,
  styles as meroStyles,
  Label,
  H2s,
  Spacer,
  Row,
  Checkbox,
  Title,
  Body,
  formatDurationInMinutes,
  Line,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { CheckoutSubStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import { AvailableMembershipWithItems, SelectedItem } from './SelectMembershipPaymentScreen';

const MembershipIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" transform="translate(0 .5)" />
      <G fill="none">
        <Path d="M5.5 6h21v21h-21Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(8.125 10.594)"
        >
          <Path d="M12.9 0v.258a1.29 1.29 0 1 1-2.58 0V0H5.159v.258a1.29 1.29 0 0 1-2.58 0V0H0v11.35h15.478V0ZM9.287 4.127h4.127M9.287 6.191h4.127M9.287 8.254h2.064" />
          <Circle cx={1.398} cy={1.398} r={1.398} data-name="layer1" transform="translate(3.265 3.355)" />
          <Path
            d="M4.643 6.191a2.605 2.605 0 0 0-2.578 2.657v.443h5.159v-.439a2.605 2.605 0 0 0-2.581-2.661Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

type Props = CurrentBusinessProps & {
  readonly onSelect: (uiKey: string, membershipId: MembershipPurchaseId) => void;
  readonly selectedItems: SelectedItem;
  readonly item: AvailableMembershipWithItems;
};

const ClientMembershipsListItemView: React.FC<Props> = ({ onSelect, selectedItems, item }) => {
  const { t } = useTranslation('checkout');

  const hasDebt = React.useMemo(() => ScaledNumber.toNumber(item.membership.debt.amount) > 0, [item.membership.debt]);

  const status = React.useMemo(() => {
    if (item.membership.validFor.type === 'Unlimited') return 'membershipActive';
    const now = DateTime.local();

    const to = DateTime.fromJSDate(item.membership.validFor.to);

    if (now > to) return 'membershipExpired';

    return 'membershipActive';
  }, []);

  return (
    <FormCard paddings="none" dropShaddow rounded style={{ paddingTop: 16, marginHorizontal: 16 }}>
      <Column style={{ flex: 1, paddingHorizontal: 16 }}>
        <Column style={{ flex: 1 }}>
          <Label
            style={[
              { color: status === 'membershipExpired' ? colors.RADICAL_RED : colors.SHAMROCK },
              meroStyles.text.semibold,
            ]}
          >
            {t(status)}
          </Label>
          <H2s>{item.membership.name}</H2s>
          <Spacer size={6} />
          <SmallBody>
            {DateTime.fromJSDate(item.membership.validFor.from).setLocale('ro').toFormat('dd MMM yyyy')} -{' '}
            {item.membership.validFor.type === 'Unlimited'
              ? t('Unlimited')
              : DateTime.fromJSDate(item.membership.validFor.to).setLocale('ro').toFormat('dd MMM yyyy')}
          </SmallBody>
        </Column>
        {hasDebt && (
          <Row
            style={{
              backgroundColor: colors.OUTRAGEOUS_ORANGE,
              paddingHorizontal: 12,
              paddingVertical: 4,
              borderRadius: 4,
              marginTop: 16,
            }}
          >
            <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>{t('debtAmount')}</Label>
            <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
              {scaledToString(item.membership.debt.amount)} {t(item.membership.debt.unit)}
            </Label>
          </Row>
        )}
      </Column>
      <Spacer size={16} />
      <Line />
      <Spacer size={16} />
      <Column style={{ paddingHorizontal: 16 }}>
        {item.items
          .map((membershipItem, index) => {
            switch (membershipItem.type) {
              case 'Service': {
                const isDisabled =
                  membershipItem.availableQuantity.type === 'Limited' &&
                  membershipItem.availableQuantity.remaining === 0;
                return (
                  <TouchableOpacity
                    style={{ flexDirection: 'row', marginBottom: 16 }}
                    key={index}
                    onPress={() => onSelect(membershipItem.uiKey, item.membership._id)}
                    disabled={isDisabled}
                  >
                    <Checkbox
                      disabled={isDisabled}
                      value={selectedItems[membershipItem.uiKey] === item.membership._id}
                      onValueChange={() => onSelect(membershipItem.uiKey, item.membership._id)}
                    />
                    <Column flex={1} style={{ paddingLeft: 12 }}>
                      <Title style={{ color: isDisabled ? '#A8AABE' : colors.BLACK }}>
                        {membershipItem.service.name}
                      </Title>
                      <Spacer size={2} />
                      {isDisabled ? (
                        <SmallBody style={{ color: '#A8AABE' }}>{t('serviceNotAvailable')}</SmallBody>
                      ) : (
                        <>
                          <SmallBody>{nameGenerator(membershipItem.saleOwner?.profile ?? {}, '')}</SmallBody>
                          <SmallBody>{t('membershipServiceItem', { count: membershipItem.quantity })}</SmallBody>
                          {/*{item.availableQuantity.type === 'Limited' && (*/}
                          {/*  <>*/}
                          {/*    <Spacer size={4} />*/}
                          {/*    <SmallBody>*/}
                          {/*      {t('servicePay', {*/}
                          {/*        count: item.quantity,*/}
                          {/*        total: item.availableQuantity.remaining,*/}
                          {/*      })}*/}
                          {/*    </SmallBody>*/}
                          {/*  </>*/}
                          {/*)}*/}
                        </>
                      )}
                    </Column>
                    <Column>
                      <Body style={{ color: isDisabled ? '#A8AABE' : colors.BLACK }}>
                        {formatDurationInMinutes(membershipItem.service.durationInMinutes)}
                      </Body>
                    </Column>
                  </TouchableOpacity>
                );
              }
              case 'Product':
                return null;
            }
          })
          .filter(Boolean)}
      </Column>
    </FormCard>
  );
};

export default React.memo(pipe(ClientMembershipsListItemView, CurrentBusiness));

import { colors, styles as S } from '@mero/components';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  invoiceButton: { paddingVertical: 16, flexDirection: 'row', alignItems: 'flex-start' },
  invoiceIconWrapper: {
    borderRadius: 99,
    paddingHorizontal: 0,
    paddingVertical: 0,
    backgroundColor: colors.ATHENS_GRAY,
    height: 32,
    width: 32,
  },
  invoiceIcon: { width: 12, height: 15 },
  text: { lineHeight: 28, width: '100%' },
  label: { lineHeight: 16, color: colors.SHAMROCK, ...S.text.semibold },
  date: { marginTop: 2, width: '100%' },
  contentWrapper: {
    gap: 16,
  },
});

export default styles;

import { Title, colors } from '@mero/components';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Instance } from '@popperjs/core';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, TouchableWithoutFeedback, useWindowDimensions, View } from 'react-native';
import Svg, { SvgProps, Path, G, Circle } from 'react-native-svg';

import HSpacer from '@mero/components/lib/components/HSpacer';

import { useClickOutsideWeb } from '../../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { CalendarContext } from '../../../../contexts/CalendarContext';
import { CheckoutsContext } from '../../../../contexts/CheckoutsContext';
import { Props } from './types';

const AddAppointmentIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8343" />
    <G
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(2 2.057)"
    >
      <Circle cx={8} cy={8} r={8} transform="translate(2 1.943)" />
      <Path d="M9.929 5.786v8.457m4.229-4.229H5.7" />
    </G>
  </Svg>
);

const BlockTimeIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <Path
      fill="#080de0"
      d="M19.333 20H4.667A.66.66 0 0 1 4 19.349V5.609a.661.661 0 0 1 .667-.653H8V3.653a.667.667 0 0 1 1.334 0v1.3h5.333v-1.3a.667.667 0 0 1 1.333 0v1.3h3.334a.66.66 0 0 1 .666.656v13.74a.66.66 0 0 1-.667.651Zm-14-12.785V18.7h13.333V7.215Z"
    />
    <Path
      fill="none"
      stroke="#080de0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m14.99 10.082-5.98 5.98m5.981 0-5.98-5.98"
    />
  </Svg>
);

const CheckoutIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G stroke="#080de0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

const TOOLTIP_WIDTH = 243;
const SPACER_SIZE = 8;

type ContentProps = {
  readonly onAddBooking?: () => void;
  readonly onAddBlockedTime?: () => void;
  readonly onAddCheckout?: () => void;
};

const Content: React.FC<ContentProps> = ({ onAddBooking, onAddBlockedTime, onAddCheckout }) => {
  const { t } = useTranslation('calendar');
  const [, { setFloatingMenuOpenId }] = CalendarContext.useContext();
  const contentRef = React.useRef<View>(null);

  const [checkoutState] = CheckoutsContext.useContext();

  useClickOutsideWeb({
    ref: contentRef,
    isVisible: true,
    onClickOutside() {
      setFloatingMenuOpenId();
    },
  });

  const checkoutEnabled = checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled;
  return (
    <View ref={contentRef} style={{ borderRadius: 12, overflow: 'hidden', width: TOOLTIP_WIDTH }}>
      <TouchableOpacity
        onPress={onAddBooking}
        style={{ padding: 16, backgroundColor: colors.DARK_BLUE, flexDirection: 'row' }}
      >
        <AddAppointmentIcon />
        <HSpacer left={8} />
        <Title style={{ color: colors.WHITE }}>{t('newAppointment')}</Title>
      </TouchableOpacity>
      <TouchableOpacity onPress={onAddBlockedTime} style={{ padding: 16, flexDirection: 'row' }}>
        <BlockTimeIcon />
        <HSpacer left={8} />
        <Title style={{ color: colors.DARK_BLUE }}>{t('blockTime')}</Title>
      </TouchableOpacity>
      {checkoutEnabled && (
        <TouchableOpacity onPress={onAddCheckout} style={{ padding: 16, flexDirection: 'row' }}>
          <CheckoutIcon />
          <HSpacer left={8} />
          <Title style={{ color: colors.DARK_BLUE }}>{t('newCheckout')}</Title>
        </TouchableOpacity>
      )}
    </View>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    padding: 0,
    fontSize: 11,
    overflow: 'hidden',
    marginLeft: -200,
  },
}));

const FloatMenu: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  children,
  onAddCheckout,
  onAddBooking,
  onAddBlockedTime,
  onPress,
}) => {
  const { isPhone } = useMediaQueries();
  const windowWidth = useWindowDimensions().width;

  const [{ floatingMenuOpenId }, { setFloatingMenuOpenId }] = CalendarContext.useContext();

  const isOpen = floatingMenuOpenId === id;

  const closeMenu = () => setFloatingMenuOpenId();

  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const updatePosition = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    popperRef.current?.update();
  };

  return isPhone ? (
    <TouchableWithoutFeedback onPress={onPress}>{children}</TouchableWithoutFeedback>
  ) : (
    <>
      <LightTooltip
        title={
          <Content
            onAddCheckout={flow(closeMenu, () => onAddCheckout?.())}
            onAddBlockedTime={flow(closeMenu, () => onAddBlockedTime?.())}
            onAddBooking={flow(closeMenu, () => onAddBooking?.())}
          />
        }
        placement="right"
        style={{ backgroundColor: colors.WHITE, height: '100%' }}
        open={isOpen}
        onClose={() => setFloatingMenuOpenId()}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              const clientHeight = areaRef.current?.clientHeight ?? 0;

              if (positionRef.current.x + TOOLTIP_WIDTH + SPACER_SIZE > windowWidth) {
                return new DOMRect(
                  positionRef.current.x - TOOLTIP_WIDTH - 2 * SPACER_SIZE,
                  (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
                  0,
                  0,
                );
              }

              return new DOMRect(
                positionRef.current.x + SPACER_SIZE,
                (areaRef.current?.getBoundingClientRect().y ?? 0) + clientHeight / 2,
                0,
                0,
              );
            },
          },
        }}
      >
        <div
          ref={areaRef}
          style={{ cursor: 'pointer' }}
          onClick={flow(updatePosition, onPress, () => setFloatingMenuOpenId(isOpen ? undefined : id))}
        >
          {children}
        </div>
      </LightTooltip>
    </>
  );
};

export default FloatMenu;

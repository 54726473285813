import { Body, colors, Column, HSpacer, Icon, Line, Row, SmallBody, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { MenuIcon } from '../MenuItem';
import { styles } from '../MenuItem/styles';

type Props = {
  readonly fontSize?: number;
  readonly hasBorderRadius?: boolean;
  readonly options: {
    readonly title: string;
    readonly onPress: () => void;
    readonly show: boolean;
    readonly badge?: string;
    readonly icon?: MenuIcon | React.ReactElement;
  }[];
};

const MenuItemGroup: React.FC<Props> = ({ options, hasBorderRadius, fontSize }) => {
  return (
    <Column style={{ backgroundColor: colors.WHITE, borderRadius: hasBorderRadius ? 12 : 0 }}>
      {options.map((option, index) => (
        <View key={index}>
          <TouchableOpacity onPress={option.onPress}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between', padding: 16 }}>
              <Row style={{ alignItems: 'center' }}>
                {option.icon && (
                  <>
                    {typeof option.icon === 'string' ? <Icon type={option.icon} /> : option.icon}
                    <HSpacer left={16} />
                  </>
                )}
                <Body style={[meroStyles.text.semibold, { fontSize: fontSize ?? 16 }]}>{option.title}</Body>
                {option.badge !== undefined ? (
                  <View style={styles.menuItemBadge}>
                    <SmallBody style={styles.menuItemBadgeText}>{option.badge}</SmallBody>
                  </View>
                ) : null}
              </Row>
              <Icon type="next" size={24} color={colors.DARK_BLUE} />
            </Row>
          </TouchableOpacity>
          {index < options.length - 1 && <Line />}
        </View>
      ))}
    </Column>
  );
};

export default MenuItemGroup;

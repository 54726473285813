import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function SendIcon() {
  return (
    <Svg width={32} height={32} viewBox="26 489.95 32 32">
      <G data-name="Group 7735">
        <Path d="M26 505.9a16 16 0 110 .1z" fill="#f2f2fe" fillRule="evenodd" data-name="Ellipse 693" />
        <G data-name="Group 7729">
          <G
            data-name="Orion_copy (1)"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="#080de0"
            fill="transparent"
          >
            <Path d="M38.777 509.223h-2.69v-8.837h8.838v2.69" data-name="layer2" />
            <Path d="M38.777 503.075h8.837v8.838h-8.837v-8.838z" data-name="layer1" />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default SendIcon;

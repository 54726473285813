import { ResponsiveImage } from '@mero/api-sdk';
import * as React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export const MoreIcon = () => (
  <Svg width={24} height={24}>
    <Path data-name="Path 3234" d="M0 0h24v24H0Z" fill="none" />
    <Path
      data-name="Path 3235"
      d="M6 10a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2Zm12 0a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2Zm-6 0a2 2 0 1 0 2 2 2.006 2.006 0 0 0-2-2Z"
      fill="#fff"
    />
  </Svg>
);

type Props = {
  image: ResponsiveImage;
  size: number;
  disabled: boolean;
  onPress: (image: ResponsiveImage) => void;
};

const SavedImage: React.FC<Props> = ({ image, onPress, size, disabled }) => {
  return (
    <TouchableOpacity
      key={image._id}
      style={{
        width: size,
        height: size,
        marginLeft: 6,
        marginTop: 6,
        borderRadius: 4,
        overflow: 'hidden',
        position: 'relative',
      }}
      onPress={() => onPress(image)}
      disabled={disabled}
    >
      <Image source={{ uri: image.small }} style={{ width: size, height: size }} />
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 4,
          right: 4,
          width: 32,
          height: 32,
          backgroundColor: `rgba(0, 0, 0, 0.6)`,
          borderRadius: 16,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={() => onPress(image)}
        disabled={disabled}
      >
        <MoreIcon />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

export default SavedImage;

import * as React from 'react';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../hooks/useGoBack';

import { RootStackParamList } from '../../types';
import TermsOfServiceScreenView from './TermsOfServiceScreenView';

type Props = StackScreenProps<RootStackParamList, 'TermsOfServiceScreen'>;

const TermsOfServiceScreen: React.FC<Props> = ({ navigation, route }) => {
  const isEmbed = route.params?.embed === '1';
  const goBack = useGoBack(
    React.useCallback(() => {
      navigation.navigate('Index');
    }, [navigation]),
  );

  return <TermsOfServiceScreenView onClose={goBack} isEmbed={isEmbed} />;
};

export default TermsOfServiceScreen;

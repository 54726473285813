import * as MeroApi from '@mero/api-sdk';
import {
  Body,
  colors,
  HSpacer,
  Icon,
  NotificationCalendarClockIcon,
  sizes,
  SmallBody,
  styles as meroStyles,
} from '@mero/components';
import { formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from '../styles';

export type Props = {
  notification: MeroApi.notifications.AppointmentOpenningNotification;
};

const AppointmentOpenningNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  // TODO - fix timezone
  const date = DateTime.fromJSDate(payload.date, { zone: 'Europe/Bucharest' });

  return (
    <View style={styles.notificationContainer}>
      <NotificationCalendarClockIcon />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={meroStyles.text.semibold}>
            {payload.worker.firstname} {payload.worker.lastname ?? ''}
          </Body>{' '}
          are timp disponibil în data de{' '}
          <Body style={meroStyles.text.semibold}>{date.toFormat('d LLL yyyy', { locale: 'ro' })}</Body>. Grăbește-te
          să-ți faci programare.
        </Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentOpenningNotification;

import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Column, Icon, Row, SmallBody, Spacer } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, TouchableOpacity, View } from 'react-native';

import {
  DEFAULT_BUSINESS_HOURS,
  MINIMUM_APPOINTMENT_COUNT,
  MINIMUM_PHOTO_COUNT,
  MINIMUM_SERVICE_COUNT,
} from '../../screens/Authorized/MenuScreen/screens/LocationProfile/EnableOnlineProfile';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { useCompletedSteps } from '../../hooks/useCompletedSteps';
import { useMediaQueries } from '../../hooks/useMediaQueries';

import { AppStorage } from '../../app-storage';
import { meroApi } from '../../contexts/AuthContext';
import { CurrentBusinessContext, CurrentPageInfo } from '../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../types';
import log from '../../utils/log';

const CompleteProfile = () => {
  const { t } = useTranslation('location');
  const { isPhone } = useMediaQueries();
  const isFocused = useIsFocused();
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();

  const screenWidth = Dimensions.get('window').width;

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [steps, setSteps] = useCompletedSteps();
  const [isVisible, setIsVisible] = React.useState(false);

  const progress = React.useMemo(() => {
    const totalSteps = Object.keys(steps).length;
    const completedSteps = Object.values(steps).filter((step) => step).length;
    return Math.ceil((100 / totalSteps) * completedSteps);
  }, [steps]);

  const getSavedVisibility = (pageId: PageId) => {
    AppStorage.getPublishBannerHideState(pageId)
      .then((state) => setIsVisible((currentState) => currentState && !state))
      .catch((error) => log.error('Error while getting publish banner state', error));
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      setIsVisible(
        !pageState.page.details.isSearchable &&
          !pageState.page.details.published &&
          pageState.page.permissions.page.canManageDetails(),
      );
    }
  }, [pageState.type, JSON.stringify(steps), isFocused]);

  React.useEffect(() => {
    const updateStepsStatus = async (page: CurrentPageInfo) => {
      try {
        const today = DateTime.utc().startOf('day');
        const from = today.plus({ days: -14 });
        const to = today.plus({ month: 1 });

        await reloadAsync();

        const [scheduleChanged, analytics] = await Promise.all([
          AppStorage.getScheduleChanged(),
          meroApi.analytics.getAnalyticsNew({
            pageId: page.details._id,
            from: from.toJSDate(),
            to: to.toJSDate(),
          }),
        ]);

        const updatedSteps = {
          step1: true,
          step2: Boolean(page.details.location),
          step3: page.details.images.length >= MINIMUM_PHOTO_COUNT,
          step4:
            JSON.stringify(page.details.businessHours) !== JSON.stringify(DEFAULT_BUSINESS_HOURS) ||
            scheduleChanged === page.details._id,
          step5: page.details.services.length >= MINIMUM_SERVICE_COUNT,
          step6: analytics.bookings.totalCount >= MINIMUM_APPOINTMENT_COUNT,
        };

        if (Object.values(updatedSteps).every((step) => step)) {
          getSavedVisibility(page.details._id);
        }

        setSteps(updatedSteps);
      } catch (error) {
        log.debug('Error while updating steps status', error);
      }
    };

    if (isFocused && pageState.type === 'Loaded') {
      updateStepsStatus(pageState.page);
    }
  }, [isFocused, pageState.type]);

  if (!isVisible) {
    return null;
  }

  const onPress = () => {
    navigation.navigate('OnlineLink');
  };

  const padding = 24;
  const menuWidth = 60;
  const buttonWidth = 52;

  return (
    <View
      style={{
        width: isPhone ? screenWidth - 2 * padding : screenWidth - 2 * padding - menuWidth - buttonWidth - 8,
      }}
      pointerEvents="box-none"
    >
      {isPhone && <View style={{ height: 24 }} pointerEvents="box-none" />}
      <TouchableOpacity
        style={{
          padding: 16,
          backgroundColor: '#52577F',
          borderRadius: 12,
          width: isPhone ? '100%' : 343,
          alignSelf: 'flex-start',
        }}
        onPress={onPress}
      >
        <Row justifyContent="space-between" alignItems="center">
          <SmallBody style={{ fontFamily: 'open-sans-bold', color: colors.WHITE }}>
            {progress < 100 ? t('finishProfile').toUpperCase() : t('profileCompleted')}{' '}
            <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.WHITE }}>{progress}%</SmallBody>
          </SmallBody>
          <Icon type="down" color={colors.WHITE} />
        </Row>
        <Spacer size={8} />
        <Row style={{ height: 8, borderRadius: 4, overflow: 'hidden' }}>
          <Column style={{ width: `${progress}%`, backgroundColor: '#2DCE89' }} />
          <Column style={{ width: `${100 - progress}%`, backgroundColor: '#D8D8D8' }} />
        </Row>
      </TouchableOpacity>
    </View>
  );
};

export default CompleteProfile;

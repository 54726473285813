import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Platform } from 'react-native';

import { withNovaAuthContextProvider } from '../screens/Anonymous/NovaAuthContext';
import SignInOtpScreen from '../screens/Anonymous/SignInOtpScreen';
import SignInPhoneScreen from '../screens/Anonymous/SignInPhoneScreen';
import SignInScreen from '../screens/Anonymous/SignInScreen';
import SignUpOtpScreen from '../screens/Anonymous/SignUpOtpScreen';
import SignUpPhoneScreen from '../screens/Anonymous/SignUpPhoneScreen';
import SignUpScreen from '../screens/Anonymous/SignUpScreen';
import { withSigningContextProvider } from '../screens/Anonymous/SigningContext';

import { createStackNavigator, StackNavigationProp, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { AnonymousStackParamList, RootStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<AnonymousStackParamList>();

type AnonymousStackNavigationProp = StackNavigationProp<RootStackParamList, 'Anonymous'>;

type Props = {
  navigation: AnonymousStackNavigationProp;
};

const AnonymousStackNavigator: React.FC<Props> = () => {
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopSignInModalCard
    : styles.tabletSignInModalCard;

  return (
    <Stack.Navigator
      initialRouteName="SignInScreen"
      screenOptions={{
        cardStyle: modalStyle,
        cardOverlayEnabled: false,
        ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="SignInScreen"
        component={SignInScreen}
        options={{
          title: 'Autentificare profesioniști',
        }}
      />
      <Stack.Screen
        name="SignInPhoneScreen"
        component={SignInPhoneScreen}
        options={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          title: 'Introdu numărul tău de telefon',
        }}
      />
      <Stack.Screen
        name="SignInOtpScreen"
        component={SignInOtpScreen}
        options={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          title: 'Introdu codul format din 6 cifre',
        }}
      />
      <Stack.Screen name="SignUpScreen" component={SignUpScreen} />
      <Stack.Screen
        name="SignUpPhoneScreen"
        component={SignUpPhoneScreen}
        options={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          title: 'Creează gratuit cont de profesionist',
        }}
      />
      <Stack.Screen
        name="SignUpOtpScreen"
        component={SignUpOtpScreen}
        options={{
          ...(isPhone ? TransitionPresets.SlideFromRightIOS : TransitionPresets.ModalFadeTransition),
          title: 'Introdu numărul tău de telefon',
        }}
      />
    </Stack.Navigator>
  );
};

export default pipe(AnonymousStackNavigator, withNovaAuthContextProvider, withSigningContextProvider);

import { colors, Icon, Label, styles as meroStyles } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

export const ClientWarnedLabel: React.FC = () => {
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: '#FBEBE7',
        alignSelf: 'flex-start',
        alignItems: 'center',
        height: 24,
        paddingRight: 9,
        paddingLeft: 7,
        borderRadius: 4,
      }}
    >
      <Icon type="client-warned" />
      <Label style={[meroStyles.text.semibold, { marginLeft: 7, color: colors.OUTRAGEOUS_ORANGE }]}>AVERTIZAT</Label>
    </View>
  );
};

export default ClientWarnedLabel;

import { PhoneNumber } from '@mero/api-sdk';
import {
  Button as MeroButton,
  colors,
  DismissKeyboard,
  H1,
  Icon,
  InputWithLabel,
  MeroHeader,
  Spacer,
  styles as meroStyles,
  TypeSafeTextInput,
  useShowError,
} from '@mero/components';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { Recaptcha, RecaptchaRefProps } from '../../../../Anonymous/components/Recaptcha';

import { StackScreenProps } from '@react-navigation/stack';

import config from '../../../../../config';
import { authApi, meroApi } from '../../../../../contexts/AuthContext';
import { ChangePhoneParamsList } from '../../../../../types';
import log from '../../../../../utils/log';
import { jsonEncode } from '../../../OnboardingScreen/NativeOnboardingScreen';
import { styles } from './ChangePhoneNumberScreen.styles';

export type Props = StackScreenProps<ChangePhoneParamsList, 'ChangePhoneNumber'>;

const ChangePhoneNumberScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('changePhone');
  const showError = useShowError();

  const [showErrors, setShowErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState<string | undefined>(undefined);
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | undefined>(undefined);

  const [submitRequested, setSubmitRequested] = React.useState(false);

  const [phone, setPhone] = React.useState({
    input: '',
    decoded: PhoneNumber.decode(''),
  });

  const phoneInfo = parsePhoneNumber(phone.input, { regionCode: 'RO' });
  const phoneIsValid = E.isRight(phone.decoded) && phoneInfo.valid;

  const recaptchaRef = React.useRef<RecaptchaRefProps>(null);

  const onBackPressed = React.useCallback(() => navigation.goBack(), [navigation]);

  const checkPhoneNumberStatus = async () => {
    if (!recaptchaToken) {
      setSubmitRequested(true);
      recaptchaRef.current?.run();
      return;
    }

    if (!phoneInfo.valid) {
      setShowErrors(true);
      setErrorText(t('errorInvalidPhone'));
      return;
    }

    setIsLoading(true);
    try {
      const found = await meroApi.users.findUsers({ phone: phoneInfo.number.international as PhoneNumber });

      if (found.length > 0) {
        setShowErrors(true);
        setErrorText(t('errorPhoneAlreadyUsed'));
        return;
      }

      const res = await authApi.authentications().createSmsOtpCodeRequest({
        userPhone: phoneInfo.number.international as PhoneNumber,
        recaptchaSiteKey: config.google.recaptchaSiteKey,
        recaptchaToken,
        recaptchaAction: '',
      });

      navigation.navigate('Otp', {
        d: jsonEncode({
          phone: phoneInfo.number.international as PhoneNumber,
          recaptchaToken,
          otpAuthId: res.auth._id,
        }),
      });
    } catch (error) {
      log.error('Error while checking phone number status', error);
      showError(error, t('errorFailedToSendOtp'));
    } finally {
      setIsLoading(false);
    }
  };

  const onRecaptchaToken = React.useCallback(setRecaptchaToken, []);

  React.useEffect(() => {
    if (submitRequested && recaptchaToken) {
      setSubmitRequested(false);
      checkPhoneNumberStatus();
    }
  }, [recaptchaToken, submitRequested]);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']} style={styles.container}>
      <MeroHeader canGoBack={true} onBack={onBackPressed} />
      <DismissKeyboard style={styles.dismissKeyboard}>
        <H1 style={meroStyles.text.alignCenter}>{t('phoneDescription')}</H1>
        <Spacer size={32} />
        <View style={{ width: '100%', maxWidth: 480 }}>
          <InputWithLabel
            label={t('phoneNumber')}
            isError={showErrors && !(phoneIsValid && !errorText)}
            errorText={errorText || t('errorInvalidPhone')}
          >
            <TypeSafeTextInput
              codec={PhoneNumber}
              showError={showErrors}
              textContentType="telephoneNumber"
              keyboardType="phone-pad"
              placeholder={t('phoneNumberPlaceholder')}
              value={phone.input}
              onChange={flow(setPhone, () => {
                setShowErrors(false);
                setErrorText('');
              })}
            />
          </InputWithLabel>
          <Spacer size={24} />
          <MeroButton
            size="large"
            text={isLoading ? t('phoneButtonLoading') : t('phoneButton')}
            onClick={checkPhoneNumberStatus}
            disabled={isLoading}
            RightComponent={() => <Icon type="next" color={colors.WHITE} />}
          />
        </View>
        <Recaptcha ref={recaptchaRef} onToken={onRecaptchaToken} />
      </DismissKeyboard>
    </ModalScreenContainer>
  );
};

export default ChangePhoneNumberScreen;

import * as React from 'react';

import { RouteProp } from '@react-navigation/native';

import config from '../../../../config';
import { MenuStackParamList } from '../../../../types';
import MenuScreenWebView from '../MenuScreenWebView';

type Props = {
  route: RouteProp<MenuStackParamList, 'WorkerNotificationsSettingsScreen'>;
};

const WorkerNotificationsSettingsScreen: React.FC<Props> = ({ route }) => {
  return (
    <MenuScreenWebView
      baseUrl={`${config.proUrl}/drawer/page/workers/${encodeURIComponent(
        route.params.workerId,
      )}/appointment-reminders`}
      title="Notificäri clienți"
    />
  );
};

export default WorkerNotificationsSettingsScreen;

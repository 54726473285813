import { H3s, Line, ModalOverlay, SafeAreaView, Spacer, colors, Title, SmallBody } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import { styles } from './styles';

type MenuItemColor = 'red' | 'orange' | 'black';

const MenuItemColorMap: { [key in MenuItemColor]: string } = {
  red: colors.RADICAL_RED,
  orange: colors.OUTRAGEOUS_ORANGE,
  black: colors.BLACK,
};

export type MenuItem = {
  readonly title: string;
  readonly onPress: () => void;
  readonly color?: MenuItemColor;
};

type Props = {
  readonly title?: string;
  readonly subtitle?: string;
  readonly onDismiss: () => void;
  readonly menuItems: MenuItem[];
};

const OptionsMenuModal: React.FC<Props> = ({ title, subtitle, menuItems, onDismiss }) => {
  const hasTitle = title !== undefined || subtitle !== undefined;

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']} style={styles.optionsMenu}>
          {hasTitle ? (
            <View style={styles.optionsMenuItem}>
              <Spacer size="8" />
              {title !== undefined ? <H3s>{title}</H3s> : null}
              {subtitle !== undefined ? (
                <>
                  <Spacer size="6" />
                  <SmallBody style={{ color: colors.COMET }}>{subtitle}</SmallBody>
                </>
              ) : null}
            </View>
          ) : null}

          {menuItems.map(({ title, color = 'black', onPress }, index) => (
            <View key={`menu-item-${index}`}>
              {index > 0 || hasTitle ? <Line /> : null}
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onPress}>
                <Title style={{ color: MenuItemColorMap[color] }}>{title}</Title>
              </TouchableOpacity>
            </View>
          ))}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default OptionsMenuModal;

import { AnyPurchasedMembershipHistoryRecord } from '@mero/api-sdk/dist/memberships/purchasedMembershipHistoryRecord';
import { ServicesPaidUsingMembership } from '@mero/api-sdk/dist/memberships/purchasedMembershipHistoryRecord';
import { colors, Column, SmallBody, Title, Body } from '@mero/components';
import { formatPhoneNumber, formatTimeDiff } from '@mero/shared-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Circle, G, Path } from 'react-native-svg';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ClientStackParamList } from '../../../../types';
import { styles } from '../../NotificationsScreen/NativeNotificationsScreen/UserNotification/styles';

const HistoryIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Group 5901" {...props}>
    <Circle cx={16} cy={16} r={16} fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path fill="none" d="M7 7h18v18H7z" data-name="Rectangle 2638" />
    </G>
    <G data-name="Group 6849">
      <G data-name="Group 6848">
        <G data-name="Group 6847">
          <Path
            fill="#52577f"
            d="M22.75 14.59h-5.085l2.055-2.115a5.279 5.279 0 0 0-7.41-.075 5.156 5.156 0 0 0 0 7.343 5.265 5.265 0 0 0 7.41 0 4.885 4.885 0 0 0 1.53-3.668h1.5a7.006 7.006 0 0 1-1.98 4.717 6.79 6.79 0 0 1-9.54 0 6.63 6.63 0 0 1-.015-9.435 6.74 6.74 0 0 1 9.488 0L22.75 9.25ZM16.375 13v3.188L19 17.75l-.54.908-3.21-1.908V13Z"
            data-name="Path 9066"
          />
        </G>
      </G>
    </G>
  </Svg>
);
type Props = {
  readonly item: AnyPurchasedMembershipHistoryRecord;
};

const MembershipHistoryListItemView: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('clients');
  const navigation = useNavigation<NavigationProp<ClientStackParamList>>();

  const generatedText = React.useMemo(() => {
    switch (item.type) {
      case 'MembershipPurchasedByClient':
        return (
          <Body style={{ color: colors.COMET }}>
            {item.payload.online ? (
              <Trans ns="clients" t={t} i18nKey="MembershipPurchasedByClientOnline">
                <Title style={{ color: colors.COMET }}>0</Title>1
              </Trans>
            ) : (
              t('MembershipPurchasedByClientOffline')
            )}
          </Body>
        );
      case 'PaymentCollectedByPro':
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey={item.payload.partialPayment ? 'PaymentCollectedByProPartial' : 'PaymentCollectedByProFull'}
              values={{
                name: `${item.payload.byProUser.firstname} ${item.payload.byProUser.lastname}`,
                phone: formatPhoneNumber(item.payload.byProUser.phone),
                code: item.payload.transactionCode,
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1
            </Trans>
          </Body>
        );
      case 'PaymentCancelledByPro':
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey="PaymentCancelledByPro"
              values={{
                name: `${item.payload.byProUser?.firstname} ${item.payload.byProUser?.lastname}`,
                phone: item.payload.byProUser?.phone ? formatPhoneNumber(item.payload.byProUser?.phone) : '',
                code: item.payload.transactionCode,
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1
            </Trans>
          </Body>
        );
      case 'AppointmentPaidUsingMembership': {
        const date = DateTime.fromJSDate(item.payload.start).setLocale('ro');
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey={item.type}
              values={{
                name: `${item.payload.byProUser.firstname} ${item.payload.byProUser.lastname}`,
                phone: formatPhoneNumber(item.payload.byProUser.phone),
                date: date.toFormat('dd MMM yyyy'),
                hour: date.toFormat('HH:mm'),
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1<Title style={{ color: colors.COMET }}>2</Title>3
              <Title style={{ color: colors.COMET }}>4</Title>
            </Trans>
          </Body>
        );
      }
      case 'ServicesPaidUsingMembership': {
        const date = DateTime.fromJSDate(item.date).setLocale('ro');
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey={item.type}
              values={{
                name: `${item.payload.byProUser.firstname} ${item.payload.byProUser.lastname}`,
                phone: formatPhoneNumber(item.payload.byProUser.phone),
                date: date.toFormat('dd MMM yyyy'),
                hour: date.toFormat('HH:mm'),
                service: item.payload.services.map((service) => service.name).join(', '),
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1<Title style={{ color: colors.COMET }}>2</Title>3
              <Title style={{ color: colors.COMET }}>4</Title>
            </Trans>
          </Body>
        );
      }
      case 'ServicesMembershipPaymentCancelled': {
        const date = DateTime.fromJSDate(item.date).setLocale('ro');
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey={item.type}
              values={{
                name: `${item.payload.byProUser.firstname} ${item.payload.byProUser.lastname}`,
                phone: formatPhoneNumber(item.payload.byProUser.phone),
                date: date.toFormat('dd MMM yyyy'),
                hour: date.toFormat('HH:mm'),
                service: item.payload.services.map((service) => service.name).join(', '),
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1<Title style={{ color: colors.COMET }}>2</Title>3
              <Title style={{ color: colors.COMET }}>4</Title>
            </Trans>
          </Body>
        );
      }
      case 'MembershipCancelledByPro': {
        const date = DateTime.fromJSDate(item.date).setLocale('ro');
        return (
          <Body style={{ color: colors.COMET }}>
            <Trans
              ns="clients"
              t={t}
              i18nKey={item.type}
              values={{
                name: `${item.payload.byProUser.firstname} ${item.payload.byProUser.lastname}`,
                phone: formatPhoneNumber(item.payload.byProUser.phone),
                date: date.toFormat('dd MMM yyyy'),
                hour: date.toFormat('HH:mm'),
              }}
            >
              <Title style={{ color: colors.COMET }}>0</Title>1<Title style={{ color: colors.COMET }}>2</Title>3
              <Title style={{ color: colors.COMET }}>4</Title>
            </Trans>
          </Body>
        );
      }
      default:
        null;
    }
  }, []);

  return (
    <TouchableOpacity style={{ flexDirection: 'row', paddingHorizontal: 16, marginHorizontal: 16 }}>
      <Column>
        <HistoryIcon />
      </Column>
      <Column style={{ flex: 1, paddingLeft: 16 }}>
        {generatedText}
        <SmallBody style={styles.timeText}>{formatTimeDiff(item.date, new Date())}</SmallBody>
      </Column>
    </TouchableOpacity>
  );
};

export default React.memo(MembershipHistoryListItemView);

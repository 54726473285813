import * as React from 'react';
import { Svg, G, Path } from 'react-native-svg';

type Props = {
  readonly size?: number;
};

const BrokenLinkIcon: React.FC<Props> = ({ size = 88 }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 88 88">
      <G transform="translate(-0.9 -0.952)">
        <Path
          d="M37.6,57.741a6.7,6.7,0,0,1,0,9.405L25.777,78.974a6.7,6.7,0,0,1-9.405,0l-5.7-5.7a6.7,6.7,0,0,1,0-9.405L22.5,52.04a6.7,6.7,0,0,1,9.405,0l3.135-5.985a13.4,13.4,0,0,0-17.386,1.283L5.968,59.166a13.253,13.253,0,0,0,0,18.811l5.7,5.7a13.253,13.253,0,0,0,18.811,0L42.307,71.849A13.4,13.4,0,0,0,43.59,54.463ZM52.14,31.8a6.7,6.7,0,0,1,0-9.405L63.968,10.571a6.7,6.7,0,0,1,9.405,0l5.7,5.7a6.7,6.7,0,0,1,0,9.405L67.246,37.5a6.7,6.7,0,0,1-9.405,0L54.705,43.49a13.4,13.4,0,0,0,17.386-1.283L83.919,30.379a13.253,13.253,0,0,0,0-18.811l-5.7-5.7a13.253,13.253,0,0,0-18.811,0L47.438,17.7a13.4,13.4,0,0,0-1.283,17.386Z"
          transform="translate(0.065)"
          fill="#92b5e4"
        />
        <Path
          d="M20.426,36.222H1.9M33.252,20.546V2.02m-11.4,22.8L8.74,11.711M68.878,53.323H87.4M56.053,69V87.525m11.4-22.8L80.564,77.834M37.669,57.741a6.7,6.7,0,0,1,0,9.405L25.841,78.974a6.7,6.7,0,0,1-9.405,0l-5.7-5.7a6.7,6.7,0,0,1,0-9.405L22.564,52.04a6.7,6.7,0,0,1,9.405,0L35.1,46.055a13.4,13.4,0,0,0-17.386,1.283L6.033,59.166a13.253,13.253,0,0,0,0,18.811l5.7,5.7a13.253,13.253,0,0,0,18.811,0L42.372,71.849a13.4,13.4,0,0,0,1.283-17.386ZM52.2,31.8a6.7,6.7,0,0,1,0-9.405L64.033,10.571a6.7,6.7,0,0,1,9.405,0l5.7,5.7a6.7,6.7,0,0,1,0,9.405L67.311,37.5a6.7,6.7,0,0,1-9.405,0L54.77,43.49a13.4,13.4,0,0,0,17.386-1.283L83.984,30.379a13.253,13.253,0,0,0,0-18.811l-5.7-5.7a13.253,13.253,0,0,0-18.811,0L47.5,17.7A13.4,13.4,0,0,0,46.22,35.082Z"
          fill="none"
          stroke="#2e4369"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </G>
    </Svg>
  );
};

export default BrokenLinkIcon;

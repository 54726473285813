import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg';

export const DefaultProductImage = (props?: SvgProps) => (
  <Svg width={props?.width ?? 48} height={props?.height ?? 48} viewBox="0 0 48 48">
    <Defs>
      <ClipPath id="clip-path">
        <Rect width="48" height="48" rx="8" transform="translate(2986 1353)" fill="#fff" />
      </ClipPath>
    </Defs>
    <G transform="translate(-2986 -1353)" clipPath="url(#clip-path)">
      <Path
        d="M1.13,0H46.477c.624,0,1.13.853,1.13,1.9V45.7c0,1.052-.506,1.9-1.13,1.9H1.13c-.624,0-1.13-.853-1.13-1.9V1.9C0,.853.506,0,1.13,0Z"
        transform="translate(2986 1353)"
        fill="rgba(233,236,239,0.5)"
      />
      <G transform="translate(2998 1365)">
        <G
          transform="translate(1.001 1.001)"
          fill="none"
          stroke="#cdcfdd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.3"
        >
          <Path d="M20,5.989,11,2,2,5.989V16.008L11,20l9-3.989Z" />
          <Path d="M2,10.19l9,3.989,9-3.989M6.919,8l9,3.989M11,14.179V24.2" transform="translate(0 -4.2)" />
          <Path d="M45.6,31.6,42,33.19v3.39l3.6-1.59Z" transform="translate(-28.002 -20.721)" />
        </G>
      </G>
    </G>
  </Svg>
);

import { colors, Column, Row } from '@mero/components';
import { useMemo } from 'react';
import * as React from 'react';
import { Text as TextRN } from 'react-native';

import { LocalDateObject } from '../../../../contexts/CalendarContext';
import { ViewMode } from '../../index';
import { getDatesInWeek, getWeekDays } from '../utils';
import { styles } from './styles';

type Props = {
  readonly selectedDate: LocalDateObject;
  readonly currentDate: LocalDateObject;
  readonly activeMode: ViewMode;
};

const CalendarHeader: React.FC<Props> = ({ selectedDate, currentDate, activeMode }) => {
  const weekDates = React.useMemo((): LocalDateObject[] => getDatesInWeek({ date: selectedDate }), [selectedDate]);
  const weekDays = useMemo(() => getWeekDays(), []);

  return activeMode.value === 'week' ? (
    <Row style={styles.headerStyle}>
      {weekDays.map((day, index) => {
        const isToday = LocalDateObject.equals(currentDate, weekDates[index]);

        const textStyle = [styles.weekday, ...(isToday ? [{ color: colors.DARK_BLUE }] : [])];
        return (
          <Column
            key={day + index}
            style={[styles.weekdays, ...(index !== weekDays.length - 1 ? [styles.withBorder] : [])]}
          >
            <TextRN allowFontScaling={false} style={textStyle} numberOfLines={1}>
              {day}
            </TextRN>
            <TextRN
              allowFontScaling={false}
              style={[textStyle, { fontFamily: 'open-sans-semibold', fontSize: 15 }]}
              numberOfLines={1}
            >
              {LocalDateObject.formatDay(weekDates[index], true)}
            </TextRN>
          </Column>
        );
      })}
    </Row>
  ) : null;
};

export default CalendarHeader;

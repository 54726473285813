import {
  Body,
  Button,
  FormCard,
  H1,
  Header,
  Icon,
  SafeAreaView,
  Spacer,
  Title,
  colors,
  useShowError,
  useToast,
  TextInput,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsStackParamList } from '../../../../../types';
import { styles } from './styles';

export const generatePercentageWithStep = (step = 5) => {
  return Array(Math.ceil(100 / step))
    .fill(0)
    .map((_, index) => ((index + 1) * step > 100 ? 100 : (index + 1) * step))
    .map((v) => ({ value: v, label: String(v) }));
};

export type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<PageOnlinePaymentsStackParamList, 'PageOnlinePaymentsTermsAndConditions'>;

const PageOnlinePaymentsTermsAndConditions: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('onlinePayments');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  const [advancePaymentTos, setAdvancePaymentTos] = React.useState(
    onlinePaymentsState.status.type === 'Enabled' ? onlinePaymentsState.status.settings.advancePaymentTos : '',
  );
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (onlinePaymentsState.status.type === 'Enabled') {
      setAdvancePaymentTos(onlinePaymentsState.status.settings.advancePaymentTos);
    }
  }, [onlinePaymentsState.status]);

  const save = async () => {
    if (onlinePaymentsState.status.type !== 'Enabled' || !onlinePaymentsState.status.settings.requireAdvancePayment) {
      return;
    }
    setIsLoading(true);
    try {
      await meroApi.pages.updatePageOnlinePaymentsSettings({
        pageId: page.details._id,
        settings: {
          appointmentPayments: onlinePaymentsState.status.settings.appointmentPayments,
          advancePaymentTos,
          requireAdvancePayment: onlinePaymentsState.status.settings.requireAdvancePayment,
        },
      });
      await reloadAsync({ pageId: page.details._id });
      toast.show({ type: 'success', text: t('changesSaved') });
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('termsAndConditionsTitle')}
      />
      <ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('termsAndConditionsSubtitle')}</H1>
        <Spacer size={8} />
        <Body style={{ paddingHorizontal: 8 }}>{t('termsAndConditionsText')}</Body>
        <Spacer size={24} />
        <FormCard paddings="none" rounded dropShaddow style={{ paddingVertical: 24, paddingHorizontal: 16 }}>
          <Title>{t('termsAndConditionsLabel')}</Title>
          <Spacer size={8} />
          <TextInput numberOfLines={10} multiline={true} value={advancePaymentTos} onChange={setAdvancePaymentTos} />
        </FormCard>
        <Spacer size={96} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={save} disabled={isLoading} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={save}
              disabled={isLoading}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageOnlinePaymentsTermsAndConditions, CurrentBusiness, Authorized);

import { DateInterval } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Column, Icon, Line, Spacer, useShowError } from '@mero/components';
import { MeroUnits, UserId } from '@mero/shared-sdk';
import { flow } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity, View } from 'react-native';

import Body from '@mero/components/lib/components/Text/Body';

import { useClickOutsideWeb } from '../../../../../../hooks/useClickOutsideWeb';

import { meroApi } from '../../../../../../contexts/AuthContext';
import log from '../../../../../../utils/log';

type Props = {
  pageId: PageId;
  unit: MeroUnits.Any;
  interval: DateInterval;
  saleOwnerId?: UserId;
};

const PaymentsDownloadSelector: React.FC<Props> = ({ pageId, unit, interval, saleOwnerId }) => {
  const { t } = useTranslation('reports');
  const showError = useShowError();

  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const filtersSelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: filtersSelectRef,
    isVisible: isFilterOpen,
    onClickOutside() {
      setIsFilterOpen(false);
    },
  });

  const download = (format: 'xlsx' | 'csv') => async () => {
    try {
      const { downloadUrl } = await meroApi.checkoutReports.exportPaymentsByTypeAndOwnerReport({
        pageId,
        unit,
        interval,
        saleOwnerId,
        format,
      });

      Linking.openURL(downloadUrl).catch((error) => {
        showError(error.message);
      });
    } catch (error) {
      log.error('Failed to download report', error);
      showError(t('failedToDownloadReport'));
    }
  };

  const closeFilter = () => {
    setIsFilterOpen(false);
  };

  return (
    <Column style={{ position: 'relative' }}>
      <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setIsFilterOpen(!isFilterOpen)}>
        <Body style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>{t('clientDownload')}</Body>
        <Icon type="down" color={colors.DARK_BLUE} />
      </TouchableOpacity>
      {isFilterOpen && (
        <View
          ref={filtersSelectRef}
          style={{
            position: 'absolute',
            top: 36,
            right: 0,
            paddingHorizontal: 16,
            backgroundColor: '#ffffff',
            borderRadius: 16,
            minWidth: 300,
            shadowColor: '#000000',
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.16,
            shadowRadius: 16,
            elevation: 16,
            zIndex: 2,
          }}
        >
          <Spacer size="16" />
          <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={flow(closeFilter, download('xlsx'))}>
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('clientDownloadXlxs')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
          <Line />
          <Spacer size="16" />
          <TouchableOpacity style={{ flex: 2, flexDirection: 'row' }} onPress={flow(closeFilter, download('csv'))}>
            <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>{t('clientDownloadCsv')}</Body>
          </TouchableOpacity>
          <Spacer size="16" />
        </View>
      )}
    </Column>
  );
};

export default PaymentsDownloadSelector;

import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { RecaptchaRefProps } from '.';
import log from '../../../../utils/log';

export type Props = {
  onToken: (token: string) => void;
};

export const RecaptchaWebImpl = React.forwardRef<RecaptchaRefProps, Props>(function RecaptchaWebImplFwd(
  { onToken }: Props,
  ref,
) {
  const recaptchaAction = React.useMemo(() => 'PRO_WEB_LOGIN', []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  /**
   * Method used to load recaptcha token.
   */
  const loadRecaptchaToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      log.debug('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha(recaptchaAction);
    onToken(token);
  }, [executeRecaptcha, onToken]);

  React.useImperativeHandle(ref, () => ({
    run: () => loadRecaptchaToken(),
  }));

  return <></>;
});

import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  roundedCorners: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

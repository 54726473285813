import { Body, colors, Line, ModalOverlay, Row, SafeAreaView, Spacer, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import Button from '@mero/components/lib/components/Button';
import Column from '@mero/components/lib/components/Layout/Column';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { styles } from './Menu.styles';

export type Props = {
  message: string;
  manualSMSSent: boolean;
  onResend: () => void;
  onDelete: () => void;
  onDismiss: () => void;
};

const Menu: React.FC<Props> = ({
  message,
  manualSMSSent,
  onResend,
  onDelete,
  onDismiss,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation('notifications');
  const [showMessage, setShowMessage] = React.useState(false);

  const { isPhone } = useMediaQueries();

  const Menu = React.useMemo(
    () => (
      <>
        {!manualSMSSent && (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => setShowMessage(true)}>
              <Title>{t('previewMessage')}</Title>
            </TouchableOpacity>
            <Line />
          </>
        )}
        {manualSMSSent && (
          <>
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onResend, onDismiss)}>
              <Title>{t('sendAgain')}</Title>
            </TouchableOpacity>
            <Line />
          </>
        )}
        <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={flow(onDelete, onDismiss)}>
          <Title style={{ color: colors.RADICAL_RED }}>{t('deleteReminder')}</Title>
        </TouchableOpacity>
      </>
    ),
    [manualSMSSent],
  );

  const Message = React.useMemo(
    () => (
      <Column style={{ paddingHorizontal: 24, paddingVertical: 16, backgroundColor: colors.COMET }}>
        <Body style={{ color: colors.WHITE }}>{t('previewMessage').toUpperCase()}</Body>
        <Body style={{ color: colors.WHITE }}>{message}</Body>
        <Spacer size={16} />
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button
            backgroundColor={colors.WHITE}
            color={colors.DARK_BLUE}
            size="small"
            padding={60}
            onClick={onDismiss}
            expand={false}
            text={t('done')}
          />
        </Row>
        <Spacer size={16} />
      </Column>
    ),
    [message],
  );

  return (
    <ModalOverlay style={{ zIndex: 1000 }}>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <View
          style={[
            styles.optionsListContainer,
            !isPhone && styles.modalBorderBottom,
            showMessage && { backgroundColor: colors.COMET },
          ]}
        >
          <SafeAreaView edges={['bottom']}>{showMessage ? Message : Menu}</SafeAreaView>
        </View>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default Menu;

import {
  ASAP,
  In2Weeks,
  MoreThan2Weeks,
  NotDecided,
} from '@mero/api-sdk/dist/pages/onboardingLaunchEta/onboardingLaunchEta';
import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  Row,
  SafeAreaView,
  Spacer,
  useShowError,
  useToast,
} from '@mero/components';
import { pipe } from 'fp-ts/function';
import { TFunction } from 'i18next';
import * as ts from 'io-ts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../components/ModalScreenContainer';
import StaticFlatList from '../../../../components/StaticFlatList';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps } from '../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import {
  NewPlaceDetails,
  OnboardingContext,
  Question1,
  Question2,
  Question3,
  withOnboardingContextProvider,
} from '../../../../contexts/OnboardingContext';
import { OnboardingStackParamList } from '../../../../types';
import { jsonDecode } from '../NativeOnboardingScreen';
import { styles } from './styles';

export type Props = AuthorizedProps & StackScreenProps<OnboardingStackParamList, 'OnboardingQuestion3Screen'>;

const getAnswers = (t: TFunction<'login', undefined>) => [
  {
    title: `answer3.1`,
    value: ASAP,
  } as const,
  {
    title: `answer3.2`,
    value: In2Weeks,
  } as const,
  {
    title: `answer3.3`,
    value: MoreThan2Weeks,
  } as const,
  {
    title: `answer3.4`,
    value: NotDecided,
  } as const,
];

const OnboardingQuestion3Screen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('login');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();

  const { d } = route.params;

  const [, { reload }] = CurrentBusinessContext.useContext();
  const [onboardingState, { save }] = OnboardingContext.useContext();

  const [selectedAnswer, setSelectedAnswer] = React.useState<Question3['q3'] | undefined>(undefined);

  const newPlaceDetailsQ1Q2 = React.useMemo(() => {
    try {
      return jsonDecode(d, ts.intersection([NewPlaceDetails, Question1, Question2]));
    } catch (e) {
      goBack();
    }
  }, [d]);

  const selectAnswer = (value: Question3['q3']) => () => {
    setSelectedAnswer(value);
  };

  const onNext = () => {
    if (onboardingState.type !== 'Loaded') {
      showError(
        new Error(`Cannot save onboarding form: unexpected state type ${onboardingState.type}`),
        'Nu se poate crea contul la moment, vă rugăm să verificați datele si încercați in cateva minute',
      );
      return;
    }

    if (!newPlaceDetailsQ1Q2) {
      toast.show({
        type: 'error',
        text: t('invalidDataError'),
      });
      goBack();
      return;
    }

    if (selectedAnswer) {
      save({ ...newPlaceDetailsQ1Q2, q3: selectedAnswer });
      return;
    }

    toast.show({
      type: 'error',
      text: t('question2Error'),
    });
  };

  React.useEffect(() => {
    if (onboardingState.type === 'LoadFailed' || onboardingState.type === 'Failed') {
      showError(onboardingState.error);
    } else if (onboardingState.type === 'Saved') {
      reload(onboardingState.pageId);
      navigation.replace('OnboardingSuccessScreen');
    }
  }, [onboardingState]); // Execute only on onboarding state changes

  const ANSWERS = React.useMemo(() => getAnswers(t), [t]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('stepXofY', { x: 3, y: 3 })}
      />
      <Column>
        <Spacer size={16} />
        <H1 style={{ textAlign: 'center' }}>{t('question3')}</H1>
        <Spacer size={8} />
        <Body style={{ textAlign: 'center', paddingHorizontal: 24 }}>{t('question3Description')}</Body>
        <Spacer size={32} />
        <FormCard dropShaddow paddings="none" style={{ marginHorizontal: 16 }} rounded>
          <StaticFlatList
            data={ANSWERS}
            renderItem={({ item }) => (
              <Row style={{ padding: 16 }}>
                <Column>
                  <Checkbox
                    value={selectedAnswer === item.value}
                    color="blue"
                    onValueChange={selectAnswer(item.value)}
                  />
                </Column>
                <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={selectAnswer(item.value)}>
                  <Body style={{ fontSize: 17 }}>{t(item.title)}</Body>
                </TouchableOpacity>
              </Row>
            )}
            keyExtractor={(item) => item.value}
            ItemSeparatorComponent={() => <Line />}
          />
        </FormCard>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('continue')} onClick={onNext} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text={t('continue')} onClick={onNext} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(OnboardingQuestion3Screen, Authorized, withOnboardingContextProvider);

import { optionull } from '@mero/api-sdk';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { useShowError, useToast } from '@mero/components';
import * as Ap from 'fp-ts/lib/Apply';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/lib/Option';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateFromISOString } from 'io-ts-types';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';

import AddBlockedTimeScreenView from '../../../components/AddBlockedTimeScreen';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAnalytics } from '../../../hooks/useAnalytics';
import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import {
  BlockedTimeCreateContext,
  withBlockedTimeCreateContextProvider,
} from '../../../contexts/BlockedTimeCreateContext';
import {
  BlockedTimeFormContext,
  addDefaultEventDuration,
  withBlockedTimeFormContextProvider,
} from '../../../contexts/BlockedTimeFormContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import ConfirmOverrideBlockedTimeModal from '../BlockedTimeEditScreen/ConfirmOverrideBlockedTimeModal';

type BookingCreateScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'BlockedTimeCreateScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = CurrentBusinessProps &
  AuthorizedProps & {
    navigation: BookingCreateScreenNavigationProp;
    route: RouteProp<BookingStackParamList, 'BlockedTimeCreateScreen'>;
  };

const BlockedTimeCreateScreen: React.FC<Props> = ({ page, route, navigation }: Props) => {
  type Params = Partial<{
    date: DateTime;
    workerId: WorkerId;
  }>;

  const params: Params = React.useMemo(
    () =>
      pipe(
        Ap.sequenceS(E.Applicative)({
          workerId: pipe(route.params?.workerId, optionull(WorkerId.JSON).decode),
          date: pipe(
            route.params?.date,
            optionull(DateFromISOString).decode,
            E.map((d) => (d ? DateTime.fromJSDate(d) : d)),
          ),
        }),
        E.fold(() => ({}), identity),
      ),
    [route.params],
  );

  const showError = useShowError();
  const toast = useToast();
  const [, { pushEvent }] = AppEventsContext.useContext();
  useAnalytics({
    screenName: 'new_blocked_time_screen',
    eventName: 'screen_shown',
    onDidMount: false,
  });

  // FIXME: use calendar timezone
  const timezone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
  const renderTs = DateTime.now().setZone(timezone);

  const [, { reload: reloadCalendar }] = CalendarContext.useContext();

  const [
    formState,
    {
      reset: resetBlockedTimeForm,
      setStart,
      setEnd,
      setIsFullDay,
      setPerformer,
      setRecurrenceRule,
      setReason,
      confirmOverride,
    },
  ] = BlockedTimeFormContext.useContext();
  const [formInitialized, setFormInitialized] = React.useState(false);

  const { start: formStart, end: formEnd, isFullDay, recurrenceRule, performer, reason } = formState;

  const start = formStart ?? params.date ?? renderTs;
  const end = formEnd ?? addDefaultEventDuration(start);

  // Booking client
  const [selectPerformerState, { reset: resetPerformerSelect }] = SelectBookingPerformerContext.useContext();
  const [createState, { createBlockedTime, reset: resetCreateBlockedTime }] = BlockedTimeCreateContext.useContext();
  const [showConfirmOverride, setShowConfirmOverride] = React.useState(false);

  const selectWorkerCallback = React.useCallback(() => {
    navigation.push('SelectBookingPerformerScreen');
  }, [navigation]);

  const saveBlockedTime = async (override = false): Promise<void> => {
    if (createState.type === 'Ready' || createState.type === 'Failed') {
      if (formState.type === 'Valid') {
        const selectedWorkerId = formState.performer._id;
        const selectedWorker = page.workers.find((w) => w._id === selectedWorkerId);

        if (selectedWorker) {
          createBlockedTime({
            calendarId: selectedWorker.calendar._id,
            pageId: page.details._id,
            start: formState.start.set({ second: 0, millisecond: 0 }).toJSDate(),
            end: formState.end.set({ second: 0, millisecond: 0 }).toJSDate(),
            isFullDay: formState.isFullDay,
            recurrenceRule: formState.recurrenceRule,
            workerId: selectedWorkerId,
            reason: formState.reason,
            override: override,
          });
        } else {
          log.error(
            `Failed to select selected worker with id ${selectedWorkerId} before creating appointment on page ${page.details._id}`,
          );
        }
      } else {
        toast.show({
          type: 'error',
          text: 'Please fill in all the required fields',
        });
      }
    } else {
      log.error(`Cannot save booking: invalid createBookingState with type="${createState.type}"`);
    }
  };

  const saveBlockedTimeCallback = React.useCallback(() => {
    saveBlockedTime();
  }, [createBlockedTime, saveBlockedTime]);

  const confirmBlockedTimeOverrideCallback = React.useCallback(() => {
    setShowConfirmOverride(false);
    confirmOverride();
    saveBlockedTime(true);
  }, [saveBlockedTimeCallback]);

  const goBackFn = useGoBack();
  const goBack = React.useCallback(() => {
    setFormInitialized(false);
    goBackFn();
  }, [setFormInitialized, goBackFn]);

  useEscPressWeb({
    onPress: goBack,
  });

  // Initialize form date
  React.useEffect(() => {
    if (!formInitialized) {
      setFormInitialized(true);

      resetBlockedTimeForm({
        timezone: timezone,
        start: start,
        end: end,
        isFullDay: false,
        performer: pipe(
          page.workers,
          A.findFirst((w) => w._id === params.workerId),
          O.getOrElseW(() => (page.workers.length === 1 ? page.workers[0] : undefined)),
        ),
        reason: undefined,
        allWorkers: page.workers,
      });
    }
  }, [formInitialized]);

  // Handle performer selection result
  React.useEffect(() => {
    if (selectPerformerState.type === 'selected') {
      resetPerformerSelect();
      setPerformer(selectPerformerState.selected);
    }
  }, [selectPerformerState, resetPerformerSelect, setPerformer]);

  // Blocked time created state handling
  React.useEffect(() => {
    if (createState.type === 'Created') {
      resetCreateBlockedTime();
      resetBlockedTimeForm({});
      setFormInitialized(false);
      reloadCalendar();
      pushEvent({
        type: 'AppointmentCreated',
        calendarId: createState.calendarId,
        appointmentId: createState.appointmentId,
      });
      goBack();

      toast.show({
        type: 'success',
        text: 'Timpul blocat a fost adaugat',
      });
    } else if (createState.type === 'Failed') {
      resetCreateBlockedTime();
      if (createState.isOverride) {
        setShowConfirmOverride(true);
      } else {
        showError(createState.error);
      }
    }
  }, [createState]);

  return (
    <>
      <AddBlockedTimeScreenView
        mode="add"
        timezone={timezone}
        start={start}
        onStartChanged={setStart}
        end={end}
        onEndChanged={setEnd}
        isFullDay={isFullDay}
        onIsFullDayChanged={setIsFullDay}
        onClosePress={goBack}
        performer={performer}
        showPerformerSelect={performer === undefined || page.workers.length > 1}
        onSelectWorkerPress={selectWorkerCallback}
        recurrenceRule={recurrenceRule}
        onRecurrenceRuleChanged={setRecurrenceRule}
        reason={reason}
        onReasonChanged={setReason}
        onSaveBlockedTime={createState.type === 'Creating' ? undefined : () => saveBlockedTimeCallback()}
      />

      {showConfirmOverride ? (
        <ConfirmOverrideBlockedTimeModal
          onDismiss={() => {
            setShowConfirmOverride(false);
          }}
          onConfirm={confirmBlockedTimeOverrideCallback}
        />
      ) : null}
    </>
  );
};

export default pipe(
  BlockedTimeCreateScreen,
  withBlockedTimeFormContextProvider,
  withBlockedTimeCreateContextProvider,
  CurrentBusiness,
  Authorized,
);

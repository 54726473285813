import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const History = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <G data-name="Group 8731">
        <G data-name="Group 6846">
          <Path data-name="Rectangle 2638" fill="none" transform="translate(-7 -7) translate(7 7)" d="M0 0H24V24H0z" />
        </G>
        <G data-name="Group 6849">
          <G data-name="Group 6848">
            <G data-name="Group 6847">
              <Path
                data-name="Path 9066"
                d="M16.5 8.34h-5.085l2.055-2.115a5.279 5.279 0 00-7.41-.075 5.156 5.156 0 000 7.343 5.265 5.265 0 007.41 0A4.885 4.885 0 0015 9.825h1.5a7.006 7.006 0 01-1.98 4.717 6.79 6.79 0 01-9.54 0 6.63 6.63 0 01-.015-9.435 6.74 6.74 0 019.488 0L16.5 3zm-6.375-1.59v3.188L12.75 11.5l-.54.908L9 10.5V6.75z"
                transform="translate(-7 -7) translate(7 7) translate(5.25 5.25) translate(-3.001 -3)"
                fill={color || '#52577f'}
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default History;

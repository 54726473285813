import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  card: {
    marginBottom: sizes[16],
    padding: sizes[16],
  },
});

export default styles;

import { dateStringUtils, DefinedString, Email, PageId, PhoneNumber, PurchasedMembership } from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import {
  ClientHistoryRecord,
  ClientHistoryRecordId,
  ClientId,
  ClientNoteHistoryRecord,
  ClientNoteHistoryType,
  ClientProfile,
  SavedClient,
} from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { useShowError } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, Linking, Text, Pressable } from 'react-native';

import ClaimDialog from '../../MenuScreen/screens/MarketingSettingsScreen/ClaimDialog';

import { MeroHeader, Title, Spacer, Button, Row, Column, ListMenu, H1 } from '../../../../components/shared';
import ClientCallOptionsModal from '../components/ClientCallOptionsModal';
import ClientConfirmBlockModal from '../components/ClientConfirmBlockModal';
import ClientConfirmDeleteModal from '../components/ClientConfirmDeleteModal';
import ClientConfirmNoteDeleteModal from '../components/ClientConfirmNoteDeleteModal';
import ClientNoteAddModal from '../components/ClientNoteAddModal';
import ClientNoteEditModal from '../components/ClientNoteEditModal';
import ClientNoteOptionsModal from '../components/ClientNoteOptionsModal';
import ClientOptionsModal from '../components/ClientOptionsModal';
import ClientRemarkModal from '../components/ClientRemarkModal';
import ClientRemarkOptionsModal from '../components/ClientRemarkOptionsModal';
import ClientViewCommissionModal from '../components/ClientViewCommissionModal';
import ClientBookingsList from './components/ClientBookingsList';
import ClientBoostDetails from './components/ClientBoostDetails';
import ClientDetailsListItem from './components/ClientDetailsListItem';
import ClientHistoryList from './components/ClientHistoryList';
import ClientMembershipsList from './components/ClientMembershipsList';
import ClientNotesList from './components/ClientNotesList';
import ClientProfileInfo from './components/ClientProfileInfo';
import ClientStats from './components/ClientStats';

import { useIsFocused } from '@react-navigation/native';

import * as icons from '../../../../assets/icons';
import { meroApi } from '../../../../contexts/AuthContext';
import { ClientDetailsContext } from '../../../../contexts/ClientDetailsContext';
import { colors } from '../../../../styles';
import log from '../../../../utils/log';
import { formattedBirthday } from '../../../../utils/text';
import styles from './styles.web';

export enum MenuItemKey {
  BOOKINGS = 'bookings',
  SUBSCRIPTIONS = 'subscriptions',
  REVIEWS = 'reviews',
  GALLERY = 'gallery',
  JOURNAL = 'journal',
  HISTORY = 'history',
}

export interface MenuItem {
  icon: keyof typeof icons;
  value: string;
  key: MenuItemKey;
}

interface Props {
  readonly now: DateTime;
  readonly pageId: PageId;
  readonly clientId: ClientId;
  client: ClientProfile;
  readonly history: ClientHistoryRecord[];
  readonly totalAppointments: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly appointments: UserAppointment[];
  readonly memberships: PurchasedMembership<MeroUnits.Any>[];
  readonly onBack: () => void;
  readonly onViewSalesReport?: () => void;
  readonly onNewMembership: () => void;
  readonly onNewBooking?: () => void;
  readonly onEdit: () => void;
  readonly onCopyToClipboard: (text: PhoneNumber | Email) => void;
  readonly onSendWhatsappMessage: (phone: PhoneNumber) => void;
  readonly onShare: (text: string) => void;
  readonly onSendEmail: (email: Email) => void;
  readonly onDelete: (client: SavedClient) => void;
  readonly onChangeIsBlocked: (client: SavedClient, isBlocked: boolean) => void;
  readonly onChangeIsWarned: (client: SavedClient, isWarned: boolean) => void;
  readonly onOpenClientAppointment: (appointment: UserAppointment) => void;
  readonly onDeleteNote: (noteId: ClientHistoryRecordId) => void;
  readonly onUpdateNote: (note: ClientNoteHistoryRecord, newText: DefinedString) => void;
  readonly onAddNote: (client: SavedClient, text: DefinedString) => void;
}

const ClientDetailsLoadedScreenViewWeb: React.FC<Props> = ({
  now,
  client,
  clientId,
  pageId,
  history: fullHistory,
  totalAppointments,
  appointments,
  memberships,
  onBack,
  onNewBooking,
  onNewMembership,
  onEdit,
  onCopyToClipboard,
  onSendWhatsappMessage,
  onShare,
  onDelete,
  onChangeIsBlocked,
  onChangeIsWarned,
  onOpenClientAppointment,
  onDeleteNote,
  onUpdateNote,
  onSendEmail,
  onAddNote,
}: Props) => {
  const { firstname, lastname } = client.user;
  const { t } = useTranslation('clients');
  const showError = useShowError();
  const isFocused = useIsFocused();
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemKey>(MenuItemKey.BOOKINGS);
  const [, { reload: reloadClientDetails, loadMoreAppointments, loadMoreMemberships }] =
    ClientDetailsContext.useContext();

  const fullName = React.useMemo(
    () => `${firstname ?? ''} ${lastname ?? ''}`.trim() || 'Fără nume',
    [firstname, lastname],
  );

  const [showClientOptionsMenu, setShowClientOptionsMenu] = React.useState(false);
  const [showViewCommissionModal, setShowViewCommissionModal] = React.useState(false);
  const [showClientCallOptionsMenu, setShowClientCallOptionsMenu] = React.useState(false);
  const [showClientNoteOptionsModalMenu, setShowClientNoteOptionsModalMenu] = React.useState<
    ClientNoteHistoryRecord | undefined
  >(undefined);
  const [showClientDeleteConfirmModal, setShowClientDeleteConfirmModal] = React.useState(false);
  const [showClientBlockConfirmModal, setShowClientBlockConfirmModal] = React.useState(false);
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    ClientHistoryRecordId | undefined
  >(undefined);
  const [showClientNoteEditModal, setShowClientNoteEditModal] = React.useState<ClientNoteHistoryRecord | undefined>(
    undefined,
  );
  const [showClientNoteAddModal, setShowClientNoteAddModal] = React.useState(false);
  const [showClaimDialog, setShowClaimDialog] = React.useState(false);
  const [showClientRemarkModal, setShowClientRemarkModal] = React.useState(false);
  const [showRemarkOptionsModal, setShowRemarkOptionsModal] = React.useState(false);
  const [reports, setReports] = React.useState<PageClientStats<MeroUnits.Any>>();

  const isBoost = client.boostStatus.isBoost;
  const hideBoostDetails = client.boostStatus.isBoost && client.boostStatus.hideBoostDetails;
  const isClaimPending = isBoost && client.boostStatus.isClaimPending;
  const canClaim = isBoost && client.boostStatus.canClaim;
  const canAcceptCommission = isBoost && client.boostStatus.canAcceptCommission;

  const clientPhone = parsePhoneNumber(client.user.phone);

  const { notes, history } = React.useMemo(
    () =>
      fullHistory.reduce(
        (acc: { notes: ClientNoteHistoryRecord[]; history: ClientHistoryRecord[] }, item: ClientHistoryRecord) => {
          if (item.type === ClientNoteHistoryType.value) {
            acc.notes.push(item);
          } else {
            acc.history.push(item);
          }
          return acc;
        },
        {
          notes: [],
          history: [],
        },
      ),
    [fullHistory],
  );

  const onNoteOptionsPressCallback = React.useCallback(
    (note: ClientNoteHistoryRecord) => {
      setShowClientNoteOptionsModalMenu(note);
    },
    [setShowClientNoteOptionsModalMenu],
  );

  const toggleShowCommissionModal = () => {
    setShowViewCommissionModal(!showViewCommissionModal);
  };

  const toggleClaimDialog = () => {
    setShowClaimDialog(!showClaimDialog);
  };

  const getReportsData = async () => {
    try {
      const reports = await meroApi.clients.getPageClientStats({
        pageId,
        clientId,
        currency: 'RON',
      });
      setReports(reports);
    } catch (error) {
      log.error('Failed to get reports data', error);
      showError(error);
    }
  };

  const PROFILE_MENU_ITEMS: MenuItem[] = useMemo(() => {
    return [
      {
        key: MenuItemKey.BOOKINGS,
        value: t('bookings', { bookingsCount: appointments.length }),
        icon: 'Calendar',
      } as MenuItem,
      {
        key: MenuItemKey.SUBSCRIPTIONS,
        value: t('subscriptions', { subscriptionsCount: memberships.length }),
        icon: 'Identity',
      } as MenuItem,
      { key: MenuItemKey.JOURNAL, value: t('journal'), icon: 'Journal' } as MenuItem,
      { key: MenuItemKey.HISTORY, value: t('history'), icon: 'History' } as MenuItem,
    ];
  }, [appointments.length, memberships.length]);

  const getContent = (tab: MenuItemKey) => {
    switch (tab) {
      case MenuItemKey.BOOKINGS: {
        return (
          <ClientBookingsList
            HeaderElement={<Text style={styles.contentTitle}>{t('bookingsTitle')}</Text>}
            onEndReached={() => loadMoreAppointments(clientId)}
            totals={totalAppointments}
            appointments={appointments}
            boostAppointmentId={isBoost ? client.boostStatus.boostAppointmentId : undefined}
            now={now}
            onItemPress={onOpenClientAppointment}
          />
        );
      }
      case MenuItemKey.JOURNAL: {
        return (
          <ClientNotesList
            notes={notes}
            onNoteOptionsPress={onNoteOptionsPressCallback}
            HeaderElement={
              <Column>
                <Row justifyContent="space-between" alignItems="center">
                  <Text style={styles.contentTitle}>{t('journal')}</Text>

                  <Pressable onPress={() => setShowClientNoteAddModal(true)}>
                    <Title style={{ color: colors.DARK_BLUE }}>{t('addNote')}</Title>
                  </Pressable>
                </Row>
                <Spacer size={16} />
              </Column>
            }
          />
        );
      }
      case MenuItemKey.HISTORY: {
        return (
          <ClientHistoryList
            HeaderElement={
              <>
                <Text style={styles.contentTitle}>{t('history')}</Text>
                <Spacer size={16} />
              </>
            }
            history={history}
            now={now.toJSDate()}
          />
        );
      }
      case MenuItemKey.SUBSCRIPTIONS: {
        return (
          <ClientMembershipsList
            pageId={pageId}
            onEndReached={() => loadMoreMemberships(clientId, pageId)}
            HeaderElement={
              <Column>
                <Row justifyContent="space-between" alignItems="center">
                  <Text style={styles.contentTitle}>{t('memberships')}</Text>

                  <Pressable onPress={onNewMembership}>
                    <Title style={{ color: colors.DARK_BLUE }}>{t('addMembership')}</Title>
                  </Pressable>
                </Row>
                <Spacer size={16} />
              </Column>
            }
            memberships={memberships}
            clientId={clientId}
          />
        );
      }

      case MenuItemKey.GALLERY: {
        return <Title>Gallery</Title>;
      }

      case MenuItemKey.REVIEWS: {
        return <Title>Reviews</Title>;
      }
    }
  };

  React.useEffect(() => {
    isFocused && getReportsData();
  }, [isFocused]);

  return (
    <>
      <View style={styles.container}>
        <Column style={styles.infoColumn}>
          <MeroHeader canGoBack onBack={onBack} />
          <ScrollView>
            <View style={styles.contentContainer}>
              <ClientProfileInfo
                profilePicture={client.user.photo?.medium}
                firstName={client.user.firstname || ''}
                lastName={client.user.lastname || ''}
                fullName={fullName}
                phone={client.user.phone}
                isBlocked={client.isBlocked}
                isWarned={client.isWarned}
                isBoost={isBoost}
                hideBoostDetails={hideBoostDetails}
              />
              <Spacer size={16} />
              {hideBoostDetails ? (
                <ClientBoostDetails
                  isClaimPending={isClaimPending}
                  canClaim={canClaim}
                  onClaimDialog={toggleClaimDialog}
                  onViewCommission={toggleShowCommissionModal}
                  canAcceptCommission={canAcceptCommission}
                />
              ) : null}
              {reports ? (
                <ClientStats
                  completedBookingsCount={reports.completedBookings}
                  cancelledBookingsCount={reports.cancelledBookings}
                  lastCompletedBookingDate={reports.lastCompletedBookingTs}
                  noShowBookingsCount={reports.noShowBookings}
                  clientTotalCharged={reports.totalCheckoutCharge}
                  clientDebt={reports.debt}
                />
              ) : null}
              <Row style={styles.buttonsWrapper} justifyContent="center" alignItems="center" flex={1}>
                <Button
                  onPress={() => {
                    setShowClientOptionsMenu(true);
                  }}
                  size="medium"
                  backgroundColor={colors.SKY_BLUE}
                  color={colors.DARK_BLUE}
                  containerStyle={styles.optionsButton}
                  text={t('optionsButton')}
                />
                {onNewBooking && (
                  <Button
                    containerStyle={styles.addBookingButton}
                    size="medium"
                    text={t('addBooking')}
                    onPress={onNewBooking}
                  />
                )}
              </Row>
            </View>
            {/*<Spacer size="24" color={colors.ALABASTER} />*/}

            <ClientDetailsListItem
              label={t('email')}
              value={client.user.email as Email | undefined}
              onCopyPress={client.user.email ? () => onCopyToClipboard(client.user.email as Email) : undefined}
              onSendPress={client.user.email ? () => onSendEmail(client.user.email as Email) : undefined}
            />

            <ClientDetailsListItem
              label={t('phone')}
              value={clientPhone.number?.international}
              onCopyPress={() => onCopyToClipboard(clientPhone.number?.international as PhoneNumber)}
              onWhatAppPress={() => onSendWhatsappMessage(clientPhone.number?.national as PhoneNumber)}
            />
            <ClientDetailsListItem
              label={t('birthday')}
              value={client.user.birthday ? formattedBirthday(dateStringUtils.toDate(client.user.birthday)) : undefined}
            />
          </ScrollView>
        </Column>
        <Column flex={1} style={styles.menuColumn}>
          <ListMenu activeItem={activeMenuItem} items={PROFILE_MENU_ITEMS} onItemPress={setActiveMenuItem} />
        </Column>
        <Column flex={1} style={styles.contentColumn}>
          {getContent(activeMenuItem)}
        </Column>
      </View>

      {showClientOptionsMenu ? (
        <ClientOptionsModal
          isBlocked={client.isBlocked}
          isWarned={client.isWarned}
          onDismiss={() => {
            setShowClientOptionsMenu(false);
          }}
          onChangeIsBlocked={(isBlocked) => {
            setShowClientOptionsMenu(false);
            if (isBlocked) {
              // Show client block confirmation
              setShowClientBlockConfirmModal(true);
            } else {
              // Unblock client
              onChangeIsBlocked(client, false);
            }
          }}
          onChangeIsWarned={(isWarned) => {
            onChangeIsWarned(client, isWarned);
          }}
          onDelete={
            // Only allow deleting clients that are not boosted
            !isBoost
              ? () => {
                  setShowClientOptionsMenu(false);
                  setShowClientDeleteConfirmModal(true);
                }
              : undefined
          }
          onAddRemark={
            client.remark
              ? undefined
              : () => {
                  setShowClientOptionsMenu(false);
                  setShowClientRemarkModal(true);
                }
          }
          onEdit={
            // Only allow editing clients that are not boosted or boost details unlocked
            !hideBoostDetails
              ? () => {
                  setShowClientOptionsMenu(false);
                  onEdit();
                }
              : undefined
          }
        />
      ) : null}

      {showRemarkOptionsModal ? (
        <ClientRemarkOptionsModal
          onDismiss={() => {
            setShowRemarkOptionsModal(false);
          }}
          onDelete={() => {
            setShowRemarkOptionsModal(false);
            meroApi.clients
              .updateClientRemark({
                clientId,
                remark: '',
                showRemarkOnCalendar: client.showRemarkOnCalendar,
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => {
                reloadClientDetails(client._id, pageId);
              });
          }}
          onEdit={() => {
            setShowRemarkOptionsModal(false);
            setShowClientRemarkModal(true);
          }}
        />
      ) : null}

      {showViewCommissionModal && (
        <ClientViewCommissionModal
          client={client}
          onDismiss={toggleShowCommissionModal}
          onConfirm={() => {
            reloadClientDetails(client._id, pageId);
            toggleShowCommissionModal();
          }}
        />
      )}

      {!hideBoostDetails && showClientCallOptionsMenu ? (
        <ClientCallOptionsModal
          phone={client.user.phone}
          email={client.user.email as Email | undefined}
          birthday={client.user.birthday ? dateStringUtils.toDate(client.user.birthday) : undefined}
          onDismiss={() => {
            setShowClientCallOptionsMenu(false);
          }}
          // onSendEmail={(phone) => {
          //   setShowClientCallOptionsMenu(false);
          //   onSendEmail(client.user.email)
          // }}
          onCopy={(phone) => {
            setShowClientCallOptionsMenu(false);
            onCopyToClipboard(phone);
          }}
          onOpen={(url: string) => {
            setShowClientCallOptionsMenu(false);
            Linking.openURL(url).catch(log.error);
          }}
          onShare={() => {
            setShowClientCallOptionsMenu(false);
            onShare(`${client.user.firstname} ${client.user.lastname} - ${client.user.phone}`);
          }}
        />
      ) : null}

      {showClientNoteOptionsModalMenu !== undefined ? (
        <ClientNoteOptionsModal
          note={showClientNoteOptionsModalMenu}
          onDismiss={() => {
            setShowClientNoteOptionsModalMenu(undefined);
          }}
          onEdit={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteEditModal(note);
          }}
          onDelete={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteDeleteConfirmModal(note._id);
          }}
        />
      ) : null}

      {showClientDeleteConfirmModal ? (
        <ClientConfirmDeleteModal
          deleteInProgress={false}
          onDismiss={() => {
            setShowClientDeleteConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientDeleteConfirmModal(false);
            onDelete(client);
          }}
        />
      ) : null}

      {showClientBlockConfirmModal ? (
        <ClientConfirmBlockModal
          phone={client.user.phone}
          onDismiss={() => {
            setShowClientBlockConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientBlockConfirmModal(false);
            // Client should be blocked
            onChangeIsBlocked(client, true);
          }}
        />
      ) : null}

      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            onDeleteNote(showClientNoteDeleteConfirmModal);
          }}
        />
      ) : null}

      {showClientNoteEditModal !== undefined ? (
        <ClientNoteEditModal
          note={showClientNoteEditModal}
          onDismiss={() => {
            setShowClientNoteEditModal(undefined);
          }}
          onSave={(note, newText) => {
            setShowClientNoteEditModal(undefined);
            onUpdateNote(note, newText);
          }}
        />
      ) : null}

      {showClientNoteAddModal ? (
        <ClientNoteAddModal
          onDismiss={() => {
            setShowClientNoteAddModal(false);
          }}
          onAdd={(text) => {
            setShowClientNoteAddModal(false);
            onAddNote(client, text);
          }}
        />
      ) : null}

      {showClaimDialog && (
        <ClaimDialog
          onSuccess={() => reloadClientDetails(client._id, pageId)}
          onCancel={toggleClaimDialog}
          userId={client.user._id}
        />
      )}
      {showClientRemarkModal && (
        <ClientRemarkModal
          remark={client.remark}
          onDismiss={() => setShowClientRemarkModal(false)}
          onAdd={(remark) => {
            meroApi.clients
              .updateClientRemark({
                clientId: clientId,
                remark,
                showRemarkOnCalendar: client.showRemarkOnCalendar,
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => {
                setShowClientRemarkModal(false);
                reloadClientDetails(client._id, pageId);
              });
          }}
        />
      )}
    </>
  );
};

export default ClientDetailsLoadedScreenViewWeb;

import { Column, Line, Title } from '@mero/components';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { StarFilter } from './PageReviewsScreen';
import { styles } from './SelectStarsFilterMenu.styles';

export type Props = {
  readonly list: StarFilter[];
  readonly onSelect: (item: StarFilter) => void;
  readonly onDismiss: () => void;
};

const SelectStarsFilterMenu: React.FC<Props> = ({ list, onSelect, onDismiss }: Props): React.ReactElement | null => {
  const { t } = useTranslation('reviews');
  const { isPhone } = useMediaQueries();

  const onStarSelect = (item: StarFilter) => () => {
    onSelect(item);
  };

  return (
    <ModalOverlay>
      <MobileWebModalWrapper position="bottom">
        <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
        <Column style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
          <SafeAreaView edges={['bottom']}>
            {list.map((item, index) => (
              <Column key={`${item}_${index}`}>
                <TouchableOpacity
                  style={styles.optionsMenuItem}
                  delayPressIn={0}
                  onPress={flow(onStarSelect(item), onDismiss)}
                >
                  <Title>{t(item)}</Title>
                </TouchableOpacity>
                <Column style={{ paddingHorizontal: 24 }}>
                  <Line />
                </Column>
              </Column>
            ))}
          </SafeAreaView>
        </Column>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default SelectStarsFilterMenu;

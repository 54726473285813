import * as Eq from 'fp-ts/Eq';
import * as O from 'fp-ts/Option';
import * as React from 'react';

/**
 * Memoize a function result  with a custom equality function.
 * @param fn function to compute the result
 * @param deps list of dependencies to check when recomputing the result
 * @param equals equality predicate
 */
export default function useMemoEq<Data, Deps>(fn: () => Data, deps: Deps, eq: Eq.Eq<Deps>): Data {
  const dataRef = React.useRef<O.Option<Data>>(O.none);
  const depsRef = React.useRef<Deps>(deps);

  if (O.isNone(dataRef.current) || !eq.equals(depsRef.current, deps)) {
    const newData = fn();
    // save new data
    dataRef.current = O.some(newData);
    // save last deps
    depsRef.current = deps;
    return newData;
  } else {
    return dataRef.current.value;
  }
}

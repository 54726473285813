import {
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import { ResponsiveImage } from '@mero/shared-sdk/dist/assets';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Image, FlatList } from 'react-native';

import { styles } from '../../../../../../components/AddBookingScreen/styles';
import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../../contexts/CurrentBusiness';
import { PageGallerySettingsStackParamList } from '../../../../../../types';
import log from '../../../../../../utils/log';

export type Props = StackScreenProps<PageGallerySettingsStackParamList, 'PageMultipleDelete'>;

const PageMultipleDeleteScreen: React.FC<Props> = () => {
  const { t } = useTranslation('gallery');
  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();

  const showError = useShowError();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [images, setImages] = React.useState<ResponsiveImage[]>([]);
  const [selectedImages, setSelectedImages] = React.useState<Record<string, boolean>>({});

  const [isLoading, setIsLoading] = React.useState(false);

  const onSelectImage = (imageId: string) => () => {
    setSelectedImages((prev) => ({ ...prev, [imageId]: !prev[imageId] }));
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    setIsLoading(true);

    try {
      const imageIds = Object.keys(selectedImages).filter((imageId) => selectedImages[imageId]);
      await Promise.all(
        imageIds.map((imageId) => meroApi.pages.deleteImage({ pageId: pageState.page.details._id, imageId })),
      );
      reloadAsync();
    } catch (error) {
      showError(error, t('failedToChangeOrder'));
      log.error('Error reordering images', error);
    }

    goBack();
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      setImages(pageState.page.details.images);
    }
  }, [pageState]);

  const isOneSelected = React.useMemo(() => Object.values(selectedImages).some((v) => v), [selectedImages]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        text={t('multipleDelete')}
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
      />
      <Column style={{ flex: 1, paddingHorizontal: 16 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 8 }}>{t('multipleDelete')}</H1>
        <Spacer size={24} />
        <FormCard paddings="none" style={{ padding: 16, flex: 1, marginBottom: 96 }} dropShaddow rounded>
          <FlatList
            data={images}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={onSelectImage(item._id)}
                  style={{ alignItems: 'center', flexDirection: 'row' }}
                >
                  <Column>
                    <Checkbox value={selectedImages[item._id]} color="blue" onValueChange={onSelectImage(item._id)} />
                  </Column>
                  <Column style={{ flex: 1, paddingLeft: 16 }}>
                    <Image source={{ uri: item.small }} style={{ width: 70, height: 70, borderRadius: 4 }} />
                  </Column>
                  {index === 0 ? (
                    <Column
                      style={{
                        borderRadius: 4,
                        backgroundColor: '#E9ECEF',
                        paddingHorizontal: 8,
                        paddingVertical: 4,
                        marginTop: 16,
                      }}
                    >
                      <Title style={{ fontSize: 12 }}>{t('mainImage').toUpperCase()}</Title>
                    </Column>
                  ) : null}
                </TouchableOpacity>
              );
            }}
            keyExtractor={(item) => item._id}
            ItemSeparatorComponent={() => (
              <>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
              </>
            )}
          />
        </FormCard>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              text={t('deleteSelected')}
              backgroundColor={colors.RADICAL_RED}
              disabledBackgroundColor="#F9E9EC"
              onClick={saveChanges}
              disabled={isLoading || !isOneSelected}
            />
          ) : (
            <Button
              disabled={isLoading || !isOneSelected}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              backgroundColor={colors.RADICAL_RED}
              disabledBackgroundColor="#F9E9EC"
              text={t('deleteSelected')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default PageMultipleDeleteScreen;

import * as React from 'react';
import { TextInput, TextInputProps } from 'react-native';

export type Props = Omit<TextInputProps, 'onBlur'> & {
  onBlur?: (value: string) => void;
  onFocus?: () => string;
};

export const TextInputWithInternalState: React.FC<Props> = (props) => {
  const timer = React.useRef(0);

  const [value, setValue] = React.useState(props.value);

  const onBlur = () => {
    props.onBlur?.(value ?? '');
    timer.current = window.setTimeout(() => setValue(props.value), 200);
  };

  const onFocus = () => {
    setValue(props.onFocus?.() ?? props.value);
  };

  React.useEffect(() => {
    window.clearTimeout(timer.current);
    setValue(props.value);
  }, [props.value]);

  return <TextInput {...props} onBlur={onBlur} onFocus={onFocus} value={value} onChangeText={setValue} />;
};

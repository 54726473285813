import {
  CheckoutCashRegistry,
  MeroUnits,
  CheckoutCompanyId,
  PageId,
  SavedCheckoutCompany,
  apiError,
  CheckoutCashRegistryTransactionList,
  CheckoutCashRegistryId,
  DateString,
  CheckoutCashRegistryTransaction,
  ApiError,
  CheckoutCashRegistryTransactionId,
} from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { DateTime } from 'luxon';
import React from 'react';

import { getIntervals } from '../../screens/Authorized/MenuScreen/screens/PageReportsScreen/ActiveIntervalView';
import { Interval } from '../../screens/Authorized/MenuScreen/screens/PageReportsScreen/PageReportsScreen';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

const SELECTED_COMPANY = 'SELECTED_COMPANY';

type StateNew = {
  readonly type: 'New';
};

type StateInitializing = {
  readonly type: 'Initializing';
};

type StateLoaded = {
  readonly type: 'Loaded';
  readonly isCompanyMenuOpen: boolean;
  readonly isOptionsMenuOpen: boolean;
  readonly isIntervalMenuOpen: boolean;
  readonly companies: SavedCheckoutCompany[];
  readonly selectedCompany?: SavedCheckoutCompany;
  readonly cashRegistry?: CheckoutCashRegistry<MeroUnits.Any>;
  readonly transactionList?: CheckoutCashRegistryTransactionList<MeroUnits.Any>;
  readonly intervals: Interval[];
  readonly activeInterval: Interval;
};

type StateFailed = {
  readonly type: 'Failed';
  readonly error?: ApiError<unknown>;
};

type State = StateNew | StateInitializing | StateLoaded | StateFailed;

const defaultState = (): State => ({
  type: 'New',
});

export const CashRegistryContext = createModelContext(
  defaultState(),
  {
    trySetInitializing(state) {
      if (state.type === 'New') {
        return {
          type: 'Initializing',
        };
      }

      return state;
    },
    setLoaded(
      _,
      params: {
        cashRegistry?: CheckoutCashRegistry<MeroUnits.Any>;
        isCompanyMenuOpen: boolean;
        isOptionsMenuOpen: boolean;
        companies: SavedCheckoutCompany[];
        selectedCompany?: SavedCheckoutCompany;
        transactionList?: CheckoutCashRegistryTransactionList<MeroUnits.Any>;
        intervals: Interval[];
        activeInterval: Interval;
        isIntervalMenuOpen: boolean;
      },
    ) {
      return {
        type: 'Loaded',
        ...params,
      };
    },
    setFailed: (_, error: ApiError<unknown> | undefined) => ({
      type: 'Failed',
      error: error,
    }),
    update(
      state,
      params: Partial<{
        cashRegistry?: CheckoutCashRegistry<MeroUnits.Any>;
        isCompanyMenuOpen: boolean;
        isOptionsMenuOpen: boolean;
        companies: SavedCheckoutCompany[];
        selectedCompany?: SavedCheckoutCompany;
        transactionList: CheckoutCashRegistryTransactionList<MeroUnits.Any>;
        intervals: Interval[];
        activeInterval: Interval;
        isIntervalMenuOpen: boolean;
      }>,
    ) {
      return {
        ...state,
        ...params,
      };
    },
    reset: defaultState,
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const getCompanies = async (pageId: PageId) => {
      try {
        const companies = await meroApi.checkout.listPageCompanies({ pageId });
        return companies;
      } catch (e) {
        if (apiError(t.unknown).is(e)) {
          dispatch.setFailed(e);
          log.exception(e);
        } else {
          dispatch.setFailed(undefined);
          log.exception(e);
        }
      }
    };
    const getCashRegistryForCompany = async (params: { pageId: PageId; companyId: CheckoutCompanyId }) => {
      try {
        const cashRegistry = await meroApi.checkout.getCashRegistryForCompany(params);
        return cashRegistry;
      } catch (e) {
        if (apiError(t.unknown).is(e)) {
          if (e.code === 94) {
            return undefined;
          } else {
            dispatch.setFailed(e);
            log.exception(e);
          }
        } else {
          dispatch.setFailed(undefined);
          log.exception(e);
        }
      }
    };

    const listCashRegistryTransactions = async (params: {
      pageId: PageId;
      companyId: CheckoutCompanyId;
      cashRegistryId: CheckoutCashRegistryId;
      fromDate: DateString;
      toDate: DateString;
    }) => {
      try {
        const transactionList = await meroApi.checkout.listCashRegistryTransactions(params);
        return transactionList;
      } catch (e) {
        if (apiError(t.unknown).is(e)) {
          dispatch.setFailed(e);
          log.exception(e);
        } else {
          dispatch.setFailed(undefined);
          log.exception(e);
        }
      }
    };

    const toDateString = (date: DateTime) => {
      return pipe(
        date.toFormat('yyyy-MM-dd'),
        DateString.decode,
        E.fold(() => {
          throw new Error(`Failed parse DateString`);
        }, t.identity),
      );
    };

    const generateIntervals = (custom?: Interval) => {
      return getIntervals({ custom });
    };

    return {
      init: (pageId: PageId): void => {
        dispatch.run(async (s) => {
          dispatch.trySetInitializing();
          const intervals: Interval[] = generateIntervals();

          const companies = await getCompanies(pageId);

          if (!companies || companies.length == 0) {
            dispatch.setLoaded({
              cashRegistry: undefined,
              isCompanyMenuOpen: false,
              companies: [],
              selectedCompany: undefined,
              transactionList: undefined,
              intervals,
              activeInterval: intervals[0],
              isIntervalMenuOpen: false,
              isOptionsMenuOpen: false,
            });
          } else {
            const item = await AsyncStorage.getItem(SELECTED_COMPANY);
            const companyId = item && JSON.parse(item);
            let selectedCompany: SavedCheckoutCompany = companies[0];

            if (companyId) {
              const found = companies.find((company) => company._id === companyId);
              if (found) {
                selectedCompany = found;
              }
            }

            const cashRegistry = await getCashRegistryForCompany({ pageId, companyId: selectedCompany._id });

            let transactionList: CheckoutCashRegistryTransactionList<MeroUnits.Any> | undefined;
            if (cashRegistry) {
              transactionList = await listCashRegistryTransactions({
                pageId,
                companyId: selectedCompany._id,
                cashRegistryId: cashRegistry._id,
                fromDate: toDateString(intervals[0].value.start),
                toDate: toDateString(intervals[0].value.end),
              });
            }

            dispatch.setLoaded({
              cashRegistry,
              isCompanyMenuOpen: false,
              companies,
              selectedCompany,
              transactionList,
              intervals,
              activeInterval: intervals[0],
              isIntervalMenuOpen: false,
              isOptionsMenuOpen: false,
            });
          }
        });
      },
      setCompanies: (params: { pageId: PageId; companies: SavedCheckoutCompany[] }) => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            if (params.companies.length === 0) {
              dispatch.update({
                cashRegistry: undefined,
                isCompanyMenuOpen: false,
                companies: [],
                selectedCompany: undefined,
                transactionList: undefined,
              });
            } else {
              const selectedCompany = params.companies[0];
              const cashRegistry = await getCashRegistryForCompany({
                pageId: params.pageId,
                companyId: selectedCompany._id,
              });

              let transactionList: CheckoutCashRegistryTransactionList<MeroUnits.Any> | undefined;
              if (cashRegistry) {
                transactionList = await listCashRegistryTransactions({
                  pageId: params.pageId,
                  companyId: selectedCompany._id,
                  cashRegistryId: cashRegistry._id,
                  fromDate: toDateString(s.activeInterval.value.start),
                  toDate: toDateString(s.activeInterval.value.end),
                });
              }

              await AsyncStorage.setItem(SELECTED_COMPANY, JSON.stringify(selectedCompany._id));

              dispatch.update({
                cashRegistry,
                companies: params.companies,
                selectedCompany,
                transactionList,
              });
            }
          }
        });
      },
      setSelectedCompany: (params: { pageId: PageId; company: SavedCheckoutCompany }): void => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded') {
            dispatch.update({ isCompanyMenuOpen: false, isOptionsMenuOpen: false });

            const cashRegistry = await getCashRegistryForCompany({
              pageId: params.pageId,
              companyId: params.company._id,
            });

            let transactionList: CheckoutCashRegistryTransactionList<MeroUnits.Any> | undefined;
            if (cashRegistry) {
              transactionList = await listCashRegistryTransactions({
                pageId: params.pageId,
                companyId: params.company._id,
                cashRegistryId: cashRegistry._id,
                fromDate: toDateString(s.activeInterval.value.start),
                toDate: toDateString(s.activeInterval.value.end),
              });
            }

            await AsyncStorage.setItem(SELECTED_COMPANY, JSON.stringify(params.company._id));
            dispatch.update({
              cashRegistry,
              selectedCompany: params.company,
              transactionList,
            });
          }
        });
      },
      addInterval: (interval: Interval) => {
        dispatch.update({ intervals: generateIntervals(interval) });
      },
      loadTransactions: (params: { pageId: PageId; activeInterval?: Interval }) => {
        dispatch.run(async (s) => {
          if (s.type === 'Loaded' && s.cashRegistry && s.selectedCompany) {
            try {
              const transactionList = await listCashRegistryTransactions({
                pageId: params.pageId,
                companyId: s.selectedCompany._id,
                cashRegistryId: s.cashRegistry._id,
                fromDate: toDateString(params.activeInterval?.value.start ?? s.activeInterval.value.start),
                toDate: toDateString(params.activeInterval?.value.end ?? s.activeInterval.value.end),
              });
              dispatch.update({ transactionList });
            } catch (e) {
              if (apiError(t.unknown).is(e)) {
                dispatch.setFailed(e);
                log.exception(e);
              } else {
                dispatch.setFailed(undefined);
                log.exception(e);
              }
            }
          }
        });
      },
      update: dispatch.update,
      reset: dispatch.reset,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init, reset }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  React.useEffect(() => {
    const state = async () => {
      if (pageState.type === 'Loaded') {
        if (!pageState.page.permissions.checkout.hasAny()) {
          return reset();
        }

        init(pageState.page.details._id);
      }
    };
    state();
  }, [pageState]);

  return <>{children}</>;
};

export const withCashRegistryContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithCashRegistryContextProvider(props: P) {
    return (
      <CashRegistryContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </CashRegistryContext.Provider>
    );
  };
};

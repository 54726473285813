import { colors, HSpacer, Row, SmallBody } from '@mero/components';
import * as React from 'react';
import { FlatList, LayoutChangeEvent, Pressable } from 'react-native';

export type Props<T> = {
  selectedTab: T;
  setSelectedTab: (tab: T) => void;
  tabsOptions: { label: string; value: T }[];
};

const ScrollableSwitch = <T,>({ tabsOptions, selectedTab, setSelectedTab }: Props<T>) => {
  const [internalSelectedTab, setInternalSelectedTab] = React.useState<T>(selectedTab || tabsOptions[0].value);
  const previousActiveTab = React.useRef<T>();

  const categoryListRef = React.useRef<FlatList>(null);
  const categoryItemWidth = React.useRef<number[]>([]);

  const setTab = (tab: T) => {
    setInternalSelectedTab((prev) => {
      previousActiveTab.current = prev;
      return tab;
    });
    setSelectedTab(tab);
  };

  const measureItem = (index: number) => (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    categoryItemWidth.current[index] = width;
  };

  const scrollToIndex = (index: number) => {
    const previousIndex = tabsOptions.findIndex((category) => category.value === previousActiveTab.current);
    let offset = index * 24 - (previousIndex > index ? 0 : 32);

    for (let i = 0; i < index; i++) {
      offset += categoryItemWidth.current[i] || 0;
    }

    categoryListRef.current?.scrollToOffset({ offset, animated: true });
  };

  React.useEffect(() => {
    if (!tabsOptions.length || !internalSelectedTab) return;

    const index = tabsOptions.findIndex((category) => category.value === internalSelectedTab);
    if (index === 0) {
      categoryListRef.current?.scrollToOffset({ offset: 0 });
      return;
    } else if (index === -1) {
      return;
    }
    scrollToIndex(index);
  }, [internalSelectedTab, tabsOptions.length]);

  return (
    <FlatList
      ref={categoryListRef}
      contentContainerStyle={{ alignItems: 'center' }}
      horizontal
      showsHorizontalScrollIndicator={false}
      data={tabsOptions}
      onScrollToIndexFailed={(info) => {
        const wait = new Promise((resolve) => setTimeout(resolve, 500));
        wait.then(() => {
          categoryListRef.current?.scrollToIndex({ index: info.index, animated: false });
        });
      }}
      renderItem={({ item, index }) => (
        <Pressable
          style={({ pressed }) => [
            {
              opacity: pressed ? 0.4 : 1,
              paddingHorizontal: 16,
            },
            item.value === internalSelectedTab
              ? {
                  backgroundColor: colors.DARK_BLUE,
                  paddingVertical: 6,
                  borderRadius: 100,
                }
              : null,
          ]}
          onPress={() => setTab(item.value)}
          onLayout={measureItem(index)}
        >
          <SmallBody
            style={[
              item.value === selectedTab ? { color: colors.WHITE } : { color: colors.DARK_BLUE },
              { fontFamily: 'open-sans-semibold' },
            ]}
          >
            {item.label}
          </SmallBody>
        </Pressable>
      )}
      keyExtractor={(item) => String(item.value)}
      ItemSeparatorComponent={() => <HSpacer right={24} />}
    />
  );
};

export default ScrollableSwitch;

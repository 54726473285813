import { DefinedString } from '@mero/api-sdk';
import { Body, colors, ConfirmBox, H1, Line, ModalOverlay, SimpleListItem, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  reason: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const DeleteLocationDialog: React.FC<Props> = ({ reason, onSuccess, onCancel }) => {
  const { t } = useTranslation('location');

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        // await meroApi.pages.deletePage(pageState.page.details._id);

        await meroApi.pages.requestPageDeletion({
          pageId: pageState.page.details._id,
          deletionReason: (reason || 'no reason selected') as DefinedString,
        });

        await AppStorage.addPageToDelete(pageState.page.details._id);
        onSuccess();
      }
    } catch (error) {
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('deleteCancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('deleteConfirm'),
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : confirm,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('deleteLocation')}</H1>
          <Spacer size="8" />
          <Body>{t('checkToConfirm')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns={'location'} t={t} i18nKey="deleteCheck1">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check3')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check3 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>
                <Trans ns={'location'} t={t} i18nKey="deleteCheck3">
                  0<Title>1</Title>
                </Trans>
              </Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteLocationDialog;

import { MembershipTemplateId } from '@mero/api-sdk';
import { Body, colors, ConfirmBox, H1, Line, ModalOverlay, SimpleListItem, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  membershipId: MembershipTemplateId;
};

const DeleteMembershipDialog: React.FC<Props> = ({ onSuccess, onCancel, membershipId }) => {
  const { t } = useTranslation('membership');

  const [pageState] = CurrentBusinessContext.useContext();

  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
    check2: false,
  });

  const cancel = () => {
    onCancel();
  };

  const onDelete = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setDeleteInProgress(true);
        await meroApi.memberships.deleteMembershipTemplate({
          pageId: pageState.page.details._id,
          membershipTemplateId: membershipId,
        });
        onSuccess();
      }
    } catch (error) {
      log.error('Failed to delete', JSON.stringify(error));
    } finally {
      setDeleteInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('deleteMembership'),
    onPress: Object.values(checks).some((v) => !v) || deleteInProgress ? undefined : onDelete,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('irreversibleAction')}
          icon="info"
          canClose={!deleteInProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
          style={{
            maxWidth: 400,
          }}
        >
          <H1>{t('confirmDelete')}</H1>
          <Spacer size="8" />
          <Title>{t('checkToConfirm')}</Title>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
          <Spacer size={16} />
          <Line />
          <Spacer size={16} />
          <TouchableOpacity onPress={toggleCheck('check2')} disabled={deleteInProgress}>
            <SimpleListItem
              icon={checks.check2 ? 'checked' : 'unchecked'}
              iconColor={deleteInProgress ? colors.ATHENS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('deleteCheck2')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DeleteMembershipDialog;

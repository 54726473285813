import { Money } from '@mero/api-sdk';
import { ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Column, Label, Spacer, FormCard, Row, Title } from '../../../../../../components/shared';

import { sizes } from '../../../../../../styles';
import { scaledToString } from '../../../../../../utils/scaled';
import styles from './styles';

interface ClientStatsProps {
  completedBookingsCount: number;
  cancelledBookingsCount: number;
  noShowBookingsCount: number;
  lastCompletedBookingDate?: Date;
  clientTotalCharged: Money<ScaledNumber, 'EUR' | 'RON'>;
  clientDebt: Money<ScaledNumber, 'EUR' | 'RON'>;
}

interface ClientStatItemProps {
  title: string;
  value: string | number;
}

const ClientStatItem: React.FC<ClientStatItemProps> = ({ title, value }) => (
  <Column gap={sizes[4]} style={styles.cardItem}>
    <Label style={styles.itemTitle}>{title}</Label>
    <Title>{value}</Title>
  </Column>
);

const ClientStats: React.FC<ClientStatsProps> = ({
  completedBookingsCount,
  cancelledBookingsCount,
  noShowBookingsCount,
  lastCompletedBookingDate,
  clientTotalCharged,
  clientDebt,
}) => {
  const { t } = useTranslation('clients');

  return (
    <FormCard rounded dropShaddow paddings="none" style={styles.form}>
      <Row justifyContent="space-around" gap={sizes[4]}>
        <ClientStatItem title={t('appointmentReports').toLocaleUpperCase()} value={completedBookingsCount} />
        <ClientStatItem title={t('canceledReports').toLocaleUpperCase()} value={cancelledBookingsCount} />
        <ClientStatItem title={t('noShowReports').toLocaleUpperCase()} value={noShowBookingsCount} />
      </Row>
      <Spacer size={16} />
      <Row justifyContent="space-around" gap={sizes[4]}>
        <ClientStatItem
          title={t('lastAppointmentReports').toLocaleUpperCase()}
          value={lastCompletedBookingDate ? DateTime.fromJSDate(lastCompletedBookingDate).toFormat('dd.MM.yy') : '-'}
        />
        <ClientStatItem
          title={t('revenueReports').toLocaleUpperCase()}
          value={`${scaledToString(clientTotalCharged.amount)} ${t(clientTotalCharged.unit)}`}
        />
        <ClientStatItem
          title={t('unpaidReports').toLocaleUpperCase()}
          value={`${scaledToString(clientDebt.amount)} ${t(clientDebt.unit)}`}
        />
      </Row>
    </FormCard>
  );
};

export default ClientStats;

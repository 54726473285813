import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const PinStar = ({ size = 22.8, color = BLACK, disabled = false, onPress }: Props) => (
  <Svg width={40} height={60} viewBox="0 0 42 62" disabled={disabled} onPress={onPress}>
    <Path d="M21 1A20 20 0 001 21c0 18 20 40 20 40s20-22 20-40A20 20 0 0021 1z" fill="#f2f2fe" />
    <Path
      d="M4 18A19.9 19.9 0 018.4 5.4 20 20 0 001 21c0 18 20 40 20 40l2.8-3.3C21.5 55.2 4 34.8 4 18z"
      fill="#000032"
      opacity={0.2}
    />
    <Path d="M21 27.7l-7.4 5.4 2.8-8.7L9 19h9.2l2.8-8.7 2.8 8.7H33l-7.4 5.4 2.8 8.7-7.4-5.4z" fill="#fc6" />
    <Path
      d="M21 1A20 20 0 001 21c0 18 20 40 20 40s20-22 20-40A20 20 0 0021 1z"
      fill="none"
      stroke="#3b3f5c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <Path
      data-name="stroke"
      d="M21 27.7l-7.4 5.4 2.8-8.7L9 19h9.2l2.8-8.7 2.8 8.7H33l-7.4 5.4 2.8 8.7-7.4-5.4z"
      fill="none"
      stroke="#3b3f5c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
);

export default React.memo(PinStar);

import { dateStringUtils } from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { useShowError, useToast } from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import AddClientScreenView, { ClientDetailsOut } from '../../../components/AddClientScreen';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { ClientDetailsContext, ClientDetailsContextProvider } from '../../../contexts/ClientDetailsContext';
import {
  ClientUpdateDetailsContext,
  withClientUpdateDetailsContextProvider,
} from '../../../contexts/ClientUpdateDetailsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import ClientDetailsNotFoundScreenView from '../ClientDetailsScreen/ClientDetailsNotFoundScreenView';
import EditClientFailedScreenView from './EditClientFailedScreenView';

type ScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<AuthorizedStackParamList, 'ClientEditScreen'>,
  StackNavigationProp<RootStackParamList, 'Authorized'>
>;

type Props = {
  readonly navigation: ScreenNavigationProp;
  readonly clientId: ClientId;
  readonly pageId: PageId;
};

export const ClientEditScreen: React.FC<Props> = ({ clientId, pageId, navigation }) => {
  const { t } = useTranslation('clients');
  const showError = useShowError();
  const toast = useToast();
  const [, { pushEvent }] = AppEventsContext.useContext();
  const [state] = ClientDetailsContext.useContext();
  const [clientUpdateDetailsState, { updateClientDetails, tryReset: tryResetUpdateClientDetails }] =
    ClientUpdateDetailsContext.useContext();

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  // ClientUpdateDetailsContext state changes effect
  React.useEffect(() => {
    if (clientUpdateDetailsState.type === 'Updated') {
      tryResetUpdateClientDetails();
      toast.show({
        type: 'success',
        text: t('savedClientDetails'),
      });
      pushEvent({
        type: 'PageClientUpdated',
        clientId: clientId,
        pageId: pageId,
      });
      goBack();
    } else if (clientUpdateDetailsState.type === 'Failed') {
      tryResetUpdateClientDetails();
      showError(clientUpdateDetailsState.error);
    }
  }, [clientUpdateDetailsState, clientId, pageId, toast, pushEvent, goBack, tryResetUpdateClientDetails, showError]);

  const updateClientCallback = React.useCallback(
    (client: ClientDetailsOut) => {
      if (clientUpdateDetailsState.type === 'Ready') {
        updateClientDetails({
          clientId: clientId,
          firstname: client.firstname,
          lastname: client.lastname,
          remark: client.remark,
          showRemarkOnCalendar: client.showRemarkOnCalendar,
          email: client.email,
          birthday: client.birthday,
        });
      }
    },
    [clientUpdateDetailsState, clientId, updateClientDetails],
  );

  const error = state.type === 'Failed' ? state.error : undefined;

  React.useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  switch (state.type) {
    case 'New': {
      return <AddClientScreenView key={state.type} mode="edit" />;
    }
    case 'Loading': {
      return <AddClientScreenView key={state.type} mode="edit" />;
    }
    case 'Loaded': {
      return (
        <AddClientScreenView
          key={state.type}
          clientDetails={{
            firstname: state.client.user.firstname,
            lastname: state.client.user.lastname,
            phoneNumber: state.client.user.phone,
            remark: state.client.remark,
            email: state.client.user.email,
            birthday: state.client.user.birthday ? dateStringUtils.toDate(state.client.user.birthday) : undefined,
            showRemarkOnCalendar: state.client.showRemarkOnCalendar,
          }}
          mode="edit"
          onBackPressed={goBack}
          onSubmit={updateClientCallback}
        />
      );
    }
    case 'NotFound': {
      return <ClientDetailsNotFoundScreenView onBack={goBack} clientId={clientId} />;
    }
    case 'Failed': {
      return <AddClientScreenView key={state.type} mode="edit" />;
    }
  }
};

type InitProps = CurrentBusinessProps & {
  readonly navigation: ScreenNavigationProp;
  readonly route: RouteProp<AuthorizedStackParamList, 'ClientEditScreen'>;
};

const ClientEditScreenInit: React.FC<InitProps> = ({ navigation, route }: InitProps) => {
  const clientId = pipe(
    route.params.clientId,
    ClientId.decode,
    E.getOrElseW(() => undefined),
  );

  const pageId = pipe(
    route.params.pageId,
    PageId.decode,
    E.getOrElseW(() => undefined),
  );

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  if (clientId && pageId) {
    return (
      <ClientDetailsContextProvider clientId={clientId} pageId={pageId}>
        <ClientEditScreen navigation={navigation} clientId={clientId} pageId={pageId} />
      </ClientDetailsContextProvider>
    );
  } else {
    if (!clientId) {
      log.error(`ClientDetailsScreen: Failed to decode ClientId from "${route.params.clientId}"`);
    }

    if (!pageId) {
      log.error(`ClientDetailsScreen: Failed to decode PageId from "${route.params.pageId}"`);
    }

    return <EditClientFailedScreenView error={new Error('Link invalid')} onClose={goBack} />;
  }
};

export default CurrentBusiness(withClientUpdateDetailsContextProvider(ClientEditScreenInit));

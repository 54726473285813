import { Button, Spacer, H1, styles as meroStyles, Body } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

import ModalScreenContainer from '../../components/ModalScreenContainer';

import { StackNavigationProp } from '@react-navigation/stack';

import { IntercomContext } from '../../contexts/IntercomContext';
import { RootStackParamList } from '../../types';
import log from '../../utils/log';

type Props = {
  readonly navigation: StackNavigationProp<RootStackParamList, 'ImpersonateScreen'>;
};

const ChatRedirectScreen: React.FC<Props> = ({ navigation }): React.ReactElement => {
  const [intercomState, { openChat }] = IntercomContext.useContext();

  const goHome = React.useCallback(() => {
    navigation.replace('Index');
  }, [navigation]);

  React.useEffect(() => {
    if (intercomState.type === 'Ready') {
      log.debug('Going to open intercom chat');
      openChat();
      goHome();
    } else {
      log.debug('Intercom not ready yet');
    }
  }, [intercomState]);

  return (
    <ModalScreenContainer style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <View style={[meroStyles.layout.hrPadding16, { width: '100%' }]}>
        <H1 style={meroStyles.text.alignCenter}>Chat Asistenta</H1>
        <Spacer size="m" />
        <Body style={meroStyles.text.alignCenter}>Opening support chat ...</Body>
        <Spacer size="m" />
        <View style={{ flexDirection: 'row', width: '100%', maxWidth: 360, alignSelf: 'center' }}>
          <Button text="Acasă" size="small" onClick={goHome} containerStyle={{ flex: 1, marginRight: 4 }} />
          <Button
            text="Contact Asistenta"
            size="small"
            onClick={openChat}
            disabled={intercomState.type !== 'Ready'}
            containerStyle={{ flex: 1, marginLeft: 4 }}
          />
        </View>
      </View>
    </ModalScreenContainer>
  );
};

export default ChatRedirectScreen;

import { CalendarSlotsInterval } from '@mero/api-sdk/dist/calendar';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Spacer,
  H1,
  Body,
  FormCard,
  Column,
  Icon,
  Title,
  SmallBody,
  Row,
  Button,
  Avatar,
  Header,
} from '@mero/components';
import { colors, sizes } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View, TouchableOpacity } from 'react-native';

import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import ModalScreenContainer from '../ModalScreenContainer';

export type Props = {
  /**
   * List of the workers available for given service
   */
  readonly slotsInterval: CalendarSlotsInterval;

  readonly workerDetails?: SavedWorker;
  /**
   * Page back button pressed
   */
  readonly onBackPressed?: () => void;
  /**
   * Booking interval selected
   */
  readonly onIntervalSelected: (slotsInterval: CalendarSlotsInterval) => void;
};

const SelectCalendarSlotsIntervalScreen: React.FC<Props> = ({
  slotsInterval,
  workerDetails,
  onBackPressed,
  onIntervalSelected,
}: Props) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('pros');

  const [selectedInterval, setSelectedInterval] = React.useState<CalendarSlotsInterval>(slotsInterval);

  const onSubmit = React.useCallback(() => {
    onIntervalSelected(selectedInterval);
  }, [selectedInterval, onIntervalSelected]);

  const selectServiceDuration = React.useCallback(() => {
    setSelectedInterval({ type: 'ServiceDuration' });
  }, []);

  const selectCustom15m = React.useCallback(() => {
    setSelectedInterval({ type: 'CustomInterval', intervalInMinutes: 15 });
  }, []);

  const selectCustom30m = React.useCallback(() => {
    setSelectedInterval({ type: 'CustomInterval', intervalInMinutes: 30 });
  }, []);

  const selectCustom60m = React.useCallback(() => {
    setSelectedInterval({ type: 'CustomInterval', intervalInMinutes: 60 });
  }, []);

  const selectSticksToAppointments = React.useCallback(() => {
    setSelectedInterval({ type: 'SticksToAppointments' });
  }, []);

  const intervalTextServiceDuration = React.useMemo(() => getSlotIntervalOptionTexts({ type: 'ServiceDuration' }), []);
  const intervalTextCustom15m = React.useMemo(
    () => getSlotIntervalOptionTexts({ type: 'CustomInterval', intervalInMinutes: 15 }),
    [],
  );
  const intervalTextCustom30m = React.useMemo(
    () => getSlotIntervalOptionTexts({ type: 'CustomInterval', intervalInMinutes: 30 }),
    [],
  );
  const intervalTextCustom60m = React.useMemo(
    () => getSlotIntervalOptionTexts({ type: 'CustomInterval', intervalInMinutes: 60 }),
    [],
  );
  const intervalTextSticksToAppointments = React.useMemo(
    () => getSlotIntervalOptionTexts({ type: 'SticksToAppointments' }),
    [],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={onBackPressed} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('profileCalendar')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={workerDetails?.profilePhoto?.thumbnail}
              firstname={workerDetails?.user.firstname ?? ''}
              lastname={workerDetails?.user.lastname ?? ''}
            />
          </Column>
        )}
      />
      <ScrollView>
        <Spacer size="16" />
        <H1 style={styles.hrPadding}>{t('customBookingIntervalTitle')}</H1>
        <Spacer size="8" />
        <Body style={styles.hrPadding}>{t('customBookingIntervalSubtitle')}</Body>
        <Spacer size="16" />
        <View style={styles.hrPadding}>
          <FormCard rounded paddings="inputs">
            <IntervalOption
              isActive={selectedInterval.type === 'ServiceDuration'}
              title={intervalTextServiceDuration.title}
              subtitle={intervalTextServiceDuration.subtitle}
              onPress={selectServiceDuration}
            />
            <Spacer size="16" />
            <IntervalOption
              isActive={selectedInterval.type === 'CustomInterval' && selectedInterval.intervalInMinutes === 15}
              title={intervalTextCustom15m.title}
              subtitle={intervalTextCustom15m.subtitle}
              onPress={selectCustom15m}
            />
            <Spacer size="16" />
            <IntervalOption
              isActive={selectedInterval.type === 'CustomInterval' && selectedInterval.intervalInMinutes === 30}
              title={intervalTextCustom30m.title}
              subtitle={intervalTextCustom30m.subtitle}
              onPress={selectCustom30m}
            />
            <Spacer size="16" />
            <IntervalOption
              isActive={selectedInterval.type === 'CustomInterval' && selectedInterval.intervalInMinutes === 60}
              title={intervalTextCustom60m.title}
              subtitle={intervalTextCustom60m.subtitle}
              onPress={selectCustom60m}
            />
            <Spacer size="16" />
            <IntervalOption
              isActive={selectedInterval.type === 'SticksToAppointments'}
              title={intervalTextSticksToAppointments.title}
              subtitle={intervalTextSticksToAppointments.subtitle}
              onPress={selectSticksToAppointments}
            />
            <Spacer size={96} />
          </FormCard>
        </View>
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text="OK" onClick={onSubmit} />
          ) : (
            <Button expand={false} containerStyle={{ alignSelf: 'center' }} text="OK" onClick={onSubmit} />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export const getSlotIntervalOptionTexts = (
  slotInterval: CalendarSlotsInterval,
): { title: string; subtitle?: string } => {
  switch (slotInterval.type) {
    case 'ServiceDuration': {
      return {
        title: 'În funcție de durata serviciului',
        subtitle: 'Ex: serviciu de 1h 30m, va rezulta în intervalele 9:00, 10:30, 12:00, 13:30',
      };
    }
    case 'CustomInterval': {
      if (slotInterval.intervalInMinutes === 60) {
        return {
          title: 'Din oră în oră',
          subtitle: 'Ex: 9:00, 10:00, 11:00',
        };
      } else {
        return {
          title: `Din ${slotInterval.intervalInMinutes} în ${slotInterval.intervalInMinutes} minute`,
          // FIXME: format sumbtitle dinamically
          subtitle:
            slotInterval.intervalInMinutes === 15
              ? 'Ex: 9:00, 9:15, 9:30, 9:45, 10:00'
              : slotInterval.intervalInMinutes === 30
              ? 'Ex: 9:00, 9:30, 10:00'
              : undefined,
        };
      }
    }
    case 'SticksToAppointments': {
      return {
        title: 'Afișează doar intervale lipite de programări existente',
        subtitle: 'Un interval înainte și unul după fiecare programare existentă, în funcție de programul de lucru',
      };
    }
  }
};

type IntervalOptionProps = {
  readonly isActive: boolean;
  readonly title: string;
  readonly subtitle?: string;
  readonly onPress?: () => void;
};

const IntervalOption: React.FC<IntervalOptionProps> = ({ isActive, title, subtitle, onPress }) => {
  return (
    <TouchableOpacity
      style={{}}
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      <Row>
        <Column>
          {isActive ? (
            <Icon type="checked" disabled color={colors.DARK_BLUE} />
          ) : (
            <Icon type="unchecked" disabled color={colors.DARK_BLUE} />
          )}
        </Column>
        <View style={{ paddingLeft: 12, flex: 1 }}>
          <Title>{title}</Title>
          {subtitle ? <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{subtitle}</SmallBody> : null}
        </View>
      </Row>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
  },
  hrPadding: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export default SelectCalendarSlotsIntervalScreen;

import {
  WorkerActivityNotification,
  NotificationId,
  AppointmentRequestedNotificationType,
} from '@mero/api-sdk/dist/notifications';
import { createModelContext } from '@mero/components';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export type UserNotificationsContextState =
  | {
      readonly type: 'New';
      readonly activity: [];
    }
  | {
      readonly type: 'Loading';
      readonly activity: WorkerActivityNotification[];
    }
  | {
      readonly type: 'Loaded';
      readonly activity: WorkerActivityNotification[];
      readonly hasMore: boolean;
      readonly lastNotificationId: NotificationId | undefined;
    }
  | {
      readonly type: 'Failed';
      readonly activity: WorkerActivityNotification[];
      readonly error: unknown;
      readonly hasMore: boolean;
      readonly lastNotificationId: NotificationId | undefined;
    };

const defaultState = (): UserNotificationsContextState => ({
  type: 'New',
  activity: [],
});

const PageLimit = 24;

export const UserNotificationsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        activity: WorkerActivityNotification[];
        hasMore: boolean;
        lastNotificationId: NotificationId | undefined;
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          activity: result.activity,
          hasMore: result.hasMore,
          lastNotificationId: result.lastNotificationId,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    tryMarkAsSeenAt: (state, pos: number) => {
      if (state.type === 'Loaded' && pos < state.activity.length) {
        const notification: WorkerActivityNotification = {
          ...state.activity[pos],
          seen: true,
        };

        return {
          ...state,
          activity: pipe(
            state.activity,
            A.updateAt(pos, notification),
            O.getOrElse(() => state.activity),
          ),
        };
      }

      return state;
    },
    setFailed: (
      _,
      payload: {
        error: unknown;
        activity: WorkerActivityNotification[];
        hasMore: boolean;
        lastNotificationId: NotificationId | undefined;
      },
    ) => {
      return {
        type: 'Failed',
        activity: payload.activity,
        error: payload.error,
        hasMore: payload.hasMore,
        lastNotificationId: payload.lastNotificationId,
      };
    },
    tryResetError: (state) => {
      if (state.type === 'Failed') {
        return {
          type: 'Loaded',
          activity: state.activity,
          hasMore: state.hasMore,
          lastNotificationId: state.lastNotificationId,
        };
      }

      return state;
    },
    mutate: (
      s,
      fn: (s: UserNotificationsContextState) => UserNotificationsContextState,
    ): UserNotificationsContextState => fn(s),
  },
  (dispatch) => {
    const reload = async () => {
      try {
        log.debug('Start reloading notifications');

        const activity = await meroApi.notifications.fetch({
          type: 'worker_activity',
          limit: PageLimit,
        });

        const activityNotifications: WorkerActivityNotification[] = activity
          .filter(WorkerActivityNotification.is)
          // filter out pending appointment requests
          .filter((notification) =>
            notification.type === AppointmentRequestedNotificationType.value
              ? notification.payload.appointment.status !== 'pending'
              : true,
          );

        dispatch.trySetResult({
          activity: activityNotifications,
          hasMore: activity.length >= PageLimit,
          lastNotificationId: pipe(
            activity,
            A.last,
            O.map((n) => n._id),
            O.getOrElseW(() => undefined),
          ),
        });
        log.debug(
          `${activityNotifications.length} notifications (re)loaded (last id: ${activityNotifications[0]?._id})`,
        );
      } catch (error) {
        dispatch.setFailed({
          error: error,
          activity: [],
          hasMore: false,
          lastNotificationId: undefined,
        });

        log.exception(error);
      }
    };

    return {
      init: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              activity: [],
            };
          } else {
            return state;
          }
        });
      },
      loadMore: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' && state.hasMore && state.lastNotificationId !== undefined) {
            const loadMore = async (): Promise<void> => {
              try {
                log.debug(`Load more notifications fromId ${state.lastNotificationId}`);
                const notifications = await meroApi.notifications.fetch({
                  type: 'worker_activity',
                  fromId: state.lastNotificationId,
                  limit: PageLimit,
                });

                const activityNotifications: WorkerActivityNotification[] = notifications.filter(
                  WorkerActivityNotification.is,
                );

                dispatch.trySetResult({
                  activity: state.activity.concat(activityNotifications),
                  hasMore: notifications.length >= PageLimit,
                  lastNotificationId: pipe(
                    notifications,
                    A.last,
                    O.map((n) => n._id),
                    O.getOrElseW(() => undefined),
                  ),
                });
                log.debug(`Loaded ${activityNotifications.length} more notification(s)`);
              } catch (error) {
                dispatch.setFailed({
                  error: error,
                  activity: state.activity,
                  hasMore: state.hasMore,
                  lastNotificationId: state.lastNotificationId,
                });
              }
            };

            loadMore().catch(log.exception);

            return {
              type: 'Loading',
              activity: state.activity,
            };
          } else {
            return state;
          }
        });
      },
      reload: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' || state.type === 'Failed') {
            reload().catch(log.exception);

            return {
              type: 'Loading',
              activity: state.activity,
            };
          }

          return state;
        });
      },
      tryResetError: dispatch.tryResetError,
      tryMarkAsSeenAt: dispatch.tryMarkAsSeenAt,
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = UserNotificationsContext.useContext();

  React.useEffect(() => {
    init();
  }, [init]);

  return <>{children}</>;
};

export const withUserNotificationsContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithUserNotificationsContextProvider(props: P) {
    return (
      <UserNotificationsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </UserNotificationsContext.Provider>
    );
  };
};

import { UserId } from '@mero/api-sdk/dist/users';
import { createModelContext } from '@mero/components';
import * as React from 'react';

export type State =
  | {
      type: 'individual';
    }
  | {
      type: 'global';
      userId: UserId;
    };
const defaultState = (): State => ({ type: 'individual' });

export const PageNotificationsContext = createModelContext(
  defaultState(),
  {
    mutate: (state, fn: (state: State) => State) => fn(state),
  },
  (dispatch) => {
    return {
      updateState: (payload: State) => {
        dispatch.mutate(() => payload);
      },
    };
  },
);

import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../../styles/text';

export type SmallBodyProps = React.PropsWithChildren<TextProps>;

const SmallBody: React.FC<SmallBodyProps> = (props: SmallBodyProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text {...props} style={[styles.smallBody, props.style]}>
    {props.children}
  </Text>
);

export default SmallBody;

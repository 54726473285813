import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { colors, sizes, Icon, IconProps } from '@mero/components';
import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import ClientBlocked from '@mero/components/lib/components/Icon/ClientBlocked';
import ClientWarned from '@mero/components/lib/components/Icon/ClientWarned';
import Line from '@mero/components/lib/components/Line';
import ClientBlockedLabel from '@mero/components/lib/components/Mero/ClientBlockedLabel';
import ClientBoostLabel from '@mero/components/lib/components/Mero/ClientBoostedLabel';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import Spacer from '@mero/components/lib/components/Spacer';
import SmallBody from '@mero/components/lib/components/Text/SmallBody';
import Title from '@mero/components/lib/components/Text/Title';

export type ClientListItemProps = {
  /**
   * Service data to display
   */
  readonly client: Pick<
    ClientPreview,
    | 'firstname'
    | 'lastname'
    | 'isBlocked'
    | 'isWarned'
    | 'isFavourite'
    | 'photo'
    | 'phone'
    | 'isBoost'
    | 'hideBoostDetails'
  >;
  /**
   * Item press handler
   */
  readonly onPress?: () => void;

  readonly icon?: IconProps['type'];
  readonly onIconPress?: () => void;
  /**
   * When set to true will add a horizontal line at the bottom of the element
   */
  readonly hasLine?: boolean;
  /**
   * Custom styles TouchableOpacity container
   */
  readonly style?: StyleProp<ViewStyle>;
  /**
   * Custom styles for SimpleListItem
   */
  readonly itemStyle?: StyleProp<ViewStyle>;

  readonly alignContent?: 'middle' | 'top';

  readonly disabled?: boolean;
};

const ClientListItem: React.FC<React.PropsWithChildren<ClientListItemProps>> = ({
  client,
  onPress,
  icon,
  onIconPress,
  hasLine,
  style,
  itemStyle,
  alignContent = 'top',
  disabled = false,
  children,
}) => (
  <TouchableOpacity onPress={onPress} disabled={onPress === undefined || disabled} style={style}>
    <SimpleListItem
      icon={onPress !== undefined ? 'next' : undefined}
      iconColor={colors.DARK_BLUE}
      IconComponent={
        disabled
          ? () => null
          : icon !== undefined
          ? () => (
              <TouchableOpacity onPress={onIconPress} disabled={onIconPress === undefined}>
                <Icon type={icon} size={sizes[24]} color={colors.DARK_BLUE} />
              </TouchableOpacity>
            )
          : undefined
      }
      iconPosition="right"
      alignContent={alignContent}
      style={itemStyle}
      image={{
        firstname: client.firstname,
        lastname: client.lastname,
        src: client.photo?.medium,
      }}
    >
      <Title numberOfLines={1} ellipsizeMode="tail">
        {`${client.firstname ?? ''} ${client.lastname ?? ''}`.trim() || 'Fără nume'}{' '}
        {client.isBlocked ? <ClientBlocked /> : client.isWarned ? <ClientWarned /> : null}
      </Title>
      <Spacer size="xxs" />

      {client.phone && !client.hideBoostDetails ? (
        <SmallBody style={{ color: colors.COMET }} numberOfLines={1} ellipsizeMode="tail">
          {client.phone}
        </SmallBody>
      ) : null}

      {client.isBlocked ? (
        <>
          <Spacer size="4" />
          <ClientBlockedLabel />
        </>
      ) : null}

      {client.hideBoostDetails ? (
        <>
          <Spacer size="4" />
          <ClientBoostLabel />
        </>
      ) : null}

      {children}
    </SimpleListItem>
    {hasLine ? <Line /> : null}
  </TouchableOpacity>
);

export default React.memo(ClientListItem);

import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import BoostMarketingScreen from '../screens/Authorized/MenuScreen/screens/MarketingSettingsScreen/BoostMarketingScreen';
import BoostScreen from '../screens/Authorized/MenuScreen/screens/MarketingSettingsScreen/BoostScreen';
import ConfigureBoostScreen from '../screens/Authorized/MenuScreen/screens/MarketingSettingsScreen/ConfigureBoostScreen';
import MarketingSettingsScreen from '../screens/Authorized/MenuScreen/screens/MarketingSettingsScreen/MarketingSettingsScreen';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withMarketingContextProvider } from '../contexts/MarketingContext';
import { MarketingSettingsStackParamList } from '../types';
import { styles } from './styles';

const MenuStack = createStackNavigator<MarketingSettingsStackParamList>();

const MenuStackNavigator: React.FC = () => {
  const { t } = useTranslation('marketing');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <MenuStack.Navigator
      initialRouteName="MarketingSettings"
      screenOptions={{
        headerShown: false,
        cardOverlayEnabled: true,
      }}
    >
      <MenuStack.Screen
        name="MarketingSettings"
        component={MarketingSettingsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <MenuStack.Screen name="Boost" component={BoostScreen} options={{ title: t('boost'), cardStyle: modalStyle }} />
      <MenuStack.Screen
        name="BoostMarketing"
        component={BoostMarketingScreen}
        options={{
          title: t('boostMarketing'),
          cardStyle: modalStyle,
          ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
        }}
      />
      <MenuStack.Screen
        name="ConfigureBoost"
        component={ConfigureBoostScreen}
        options={{ title: t('configureBoost'), cardStyle: modalStyle }}
      />
    </MenuStack.Navigator>
  );
};

export default pipe(MenuStackNavigator, withMarketingContextProvider);

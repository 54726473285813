import { ClientNoteHistoryRecord } from '@mero/api-sdk/dist/clients';
import { Button, colors, Spacer } from '@mero/components';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from './ClientEmptyListView';
import ClientNotesListItemView from './ClientNotesListItemView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly notes: ClientNoteHistoryRecord[];
  readonly onNoteOptionsPress: (note: ClientNoteHistoryRecord) => void;
  readonly onAddNote: () => void;
};

const ClientNotesListView: React.FC<Props> = ({ HeaderElement, notes, onNoteOptionsPress, onAddNote }) => {
  const renderItem = React.useCallback(({ item }: { item: ClientNoteHistoryRecord }) => {
    return <ClientNotesListItemView note={item} onOptionsPress={onNoteOptionsPress} />;
  }, []);

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={notes}
      keyboardShouldPersistTaps="handled"
      keyExtractor={clientNotesItemKeyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există notițe" subtitle="Notițele clientului vor fi afișate aici">
          <Spacer size="32" />
          <Button
            text="Adaugă notiță"
            onClick={onAddNote}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={{ flex: 1, flexWrap: 'wrap', width: 200 }}
          />
        </ClientEmptyListView>
      }
    />
  );
};

const clientNotesItemKeyExtractor = (note: ClientNoteHistoryRecord): string => note._id;

export default ClientNotesListView;

import { LoginButton, useShowError } from '@mero/components';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import * as Sentry from 'sentry-expo';

import log from '../../../../utils/log';
import { SigningContext } from '../../SigningContext';

WebBrowser.maybeCompleteAuthSession();

export type Props = {
  title?: string;
};

export default function AppleSignInButton({ title }: Props): React.ReactElement {
  const [signingState, signing] = SigningContext.useContext();
  const signInProgress = signingState.type !== 'Ready';
  const showError = useShowError();

  const signInWithApple = () => {
    const doSignIn = async () => {
      try {
        const isAvailable = await AppleAuthentication.isAvailableAsync();
        log.debug('Apple auth isAvailable', isAvailable);
        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        });

        if (credential.authorizationCode) {
          log.debug('Apple Sign In succeed', credential);
          await signing.loginApple({
            authorizationCode: credential.authorizationCode,
            identityToken: credential.identityToken ?? undefined,
            credential,
          });
        } else {
          log.warn('No apple authorization code available!');
        }
        // signed in
      } catch (e: any) {
        if (e.code === 'ERR_CANCELED') {
          // handle that the user canceled the sign-in flow
        } else {
          // handle other errors
          showError(e, 'Failed to sign in with Apple');
          Sentry.Native.captureException(e);
        }
      }
    };

    doSignIn();
  };
  return (
    <LoginButton icon="apple" text={title ?? 'Continuă cu Apple'} onClick={signInWithApple} disabled={signInProgress} />
  );
}

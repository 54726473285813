import { Body, ConfirmBox, H1, ModalOverlay, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  readonly deleteInProgress?: boolean;
  readonly onConfirm: () => void;
  readonly onDismiss: () => void;
};

const ConfirmDeleteBlockedTimeModal: React.FC<Props> = ({ deleteInProgress, onConfirm, onDismiss }) => {
  const { t } = useTranslation('blockedTime');

  const confirmDeleteLeftAction = {
    text: t('cancelButton'),
    onPress: deleteInProgress ? undefined : onDismiss,
    flex: 10,
  };

  const confirmDeleteRightAction = {
    text: t('confirmDeleteButton'),
    onPress: deleteInProgress ? undefined : onConfirm,
    flex: 15,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        icon="info"
        headerTitle={t('irreversibleAction')}
        canClose={!deleteInProgress}
        onClose={onDismiss}
        leftAction={confirmDeleteLeftAction}
        rightAction={confirmDeleteRightAction}
      >
        <H1>{t('confirmDeleteTitle')}</H1>
        <Spacer size="8" />
        <Body>{t('confirmDeleteText')}</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ConfirmDeleteBlockedTimeModal;

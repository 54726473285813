import { colors, Header, Icon } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import OnlineLink from '../MenuScreen/screens/LocationProfile/OnlineLinkComponent';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import useGoBack from '../../../hooks/useGoBack';

import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';

const OnlineLinkScreen = () => {
  const { t } = useTranslation('location');

  const [pageState] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        RightComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingRight: 16 }}>
            <Icon type="close" />
          </TouchableOpacity>
        )}
        text={t('onlineLink')}
      />
      <OnlineLink />
    </ModalScreenContainer>
  );
};

export default OnlineLinkScreen;

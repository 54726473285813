import { colors } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

type Props = {
  readonly isBoostClient: boolean | undefined;
};

const BoostIcon: React.FC<Props> = ({ isBoostClient = false }) => {
  return (
    <>
      {isBoostClient && (
        <View style={{ marginRight: 4 }}>
          <Svg width={12} height={12}>
            <Path
              d="M4.283 8.921A3.64 3.64 0 0 1 1 11a3.668 3.668 0 0 1 2.1-3.281l.255.26a3.3 3.3 0 0 0-1.066 1.732A3.289 3.289 0 0 0 4.03 8.663ZM3.715 3.25A4.326 4.326 0 0 0 .832 4.557 4.6 4.6 0 0 0 0 5.686a2.174 2.174 0 0 1 2.573-.2A11.248 11.248 0 0 1 3.715 3.25ZM6.317 12a4.57 4.57 0 0 0 1.13-.832 4.305 4.305 0 0 0 1.3-2.891 12.232 12.232 0 0 1-2.23 1.148 2.176 2.176 0 0 1-.2 2.575ZM11.973.022Q11.652 0 11.343 0C6.674 0 4.11 3.713 3.292 6.5l2.214 2.219C8.4 7.791 12 5.33 12 .722V.693c0-.219-.01-.442-.027-.671ZM9 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1Z"
              fill={colors.BLACK}
            />
          </Svg>
        </View>
      )}
    </>
  );
};

export default BoostIcon;

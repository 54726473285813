import { MembershipConsumptionPerTransaction } from '@mero/api-sdk/dist/memberships/membershipConsumptionPerTransaction';
import { colors, Line, Spacer } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientEmptyListView from './ClientEmptyListView';
import MembershipAppointmentListItemView from './MembershipAppointmentListItemView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly data: MembershipConsumptionPerTransaction<MeroUnits.Any>[];
};

const MembershipAppointmentListView: React.FC<Props> = ({ HeaderElement, data }) => {
  const renderItem = React.useCallback(({ item }: { item: MembershipConsumptionPerTransaction<MeroUnits.Any> }) => {
    return <MembershipAppointmentListItemView item={item} />;
  }, []);

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={data}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView
          title="Nu există ședințe efectuate"
          subtitle="Ședințele efectuate de către client vor fi afișate aici"
        />
      }
      ItemSeparatorComponent={() => (
        <>
          <Spacer size={16} />
        </>
      )}
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

const keyExtractor = (item: MembershipConsumptionPerTransaction<MeroUnits.Any>): string => item.transaction._id;

export default MembershipAppointmentListView;

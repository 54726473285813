import { ReviewScore } from '@mero/api-sdk';
import { Icon, Row, colors } from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { Path } from 'react-native-svg';

type Props = {
  score: number;
  setScore: (newRating: ReviewScore) => void;
  gap: number;
};

const EmptyStar = () => (
  <Svg width="32" height="30.9" viewBox="0 0 32 30.9">
    <Path
      d="M14,0l3.85,9.552L28,10.313l-7.771,6.664L22.652,27,14,21.566,5.348,27,7.771,16.977,0,10.313l10.15-.761Z"
      transform="translate(0.75 0.75)"
      fill="#fff"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Svg>
);

const FullStar = () => (
  <Svg width="32" height="30.9" viewBox="0 0 32 30.9">
    <Path
      d="M14,0l3.85,9.552L28,10.313l-7.771,6.664L22.652,27,14,21.566,5.348,27,7.771,16.977,0,10.313l10.15-.761Z"
      transform="translate(0.75 0.75)"
      fill="#fbac40"
      stroke="#fbac40"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </Svg>
);

const StarRating: React.FC<Props> = ({ score, setScore, gap }) => {
  return (
    <Row style={{ flex: 1, alignItems: 'center', gap: gap }}>
      {[...Array(5)].map((_, index) => (
        <TouchableOpacity key={index} onPress={() => pipe(setScore((index + 1) as ReviewScore))}>
          {index < score ? <FullStar /> : <EmptyStar />}
        </TouchableOpacity>
      ))}
    </Row>
  );
};

export default StarRating;

import * as React from 'react';
import Svg, { Path, Defs, Rect, ClipPath, G } from 'react-native-svg';

import { BLACK } from '../../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Phone: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Defs>
      <ClipPath id="clip-path-phone">
        <Rect width="32" height="32" transform="translate(40 566)" fill="none" />
      </ClipPath>
    </Defs>
    <G transform="translate(-40 -566)" clipPath="url(#clip-path-phone)">
      <G transform="translate(40 566)">
        <Path d="M0,0H32V32H0Z" fill="none" />
        <Path
          d="M19.247,1.344,8.763,1.333a2.1,2.1,0,0,0-2.1,2.1V22.3a2.1,2.1,0,0,0,2.1,2.1H19.247a2.1,2.1,0,0,0,2.1-2.1V3.43A2.094,2.094,0,0,0,19.247,1.344Zm0,18.86H8.763V5.527H19.247Z"
          transform="translate(2 3)"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Phone);
